
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { forkJoin, from, Observable, of, switchMap } from 'rxjs';
import { saveAttachments } from 'src/app/helpers/Attachment_Save';
import { AttachmentResponse, CustomUploadFile } from 'src/Models/Attachment_Files_Class/AttachmentFilesClass';
import { ApplicationUserResponse } from 'src/Models/InternalUser/applicationUserResponse';
import { ChecklistItem } from 'src/Models/Project-Management/projectManagement';
import { AttachmentService } from 'src/Service/Attachment/attachment.service';
import { InternalUserService } from 'src/Service/Internaluser/internal-user.service';
import { ToDoInformationService } from 'src/Service/Project-Management/to-do-information.service';


@Component({
  selector: 'Todo-checklist-row',
  templateUrl: './project-management-site-diaries-checklist.component.html',
  styleUrls: ['./project-management-site-diaries-checklist.component.css']
})
export class ProjectManagementSiteDiariesChecklistComponent implements OnInit {
  // ----------------------------------------------
  TestAttachment: AttachmentResponse;
  internalRes: ApplicationUserResponse[] = [];
  toDoFormCheckList: FormGroup
  selectedFilesAttachment: CustomUploadFile[] = [];

  // ----------------------------------------------
  pageId: number = 30007;
  @Input() toDoInformationId: number;
  constructor(
    private internalUserService: InternalUserService,
    private _AttachmentService: AttachmentService,
    private _fb: FormBuilder,
    private _toDoInformationService: ToDoInformationService,
    private changeDetectorRef: ChangeDetectorRef

  ) { }

  ngOnInit(): void {
    this.createForm()
    this.onGetAllAssigneeUser();
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes['toDoInformationId'] && changes['toDoInformationId'].currentValue > 0) {
      console.log('toDoInformationId updated, resubmitting items');

      this._toDoInformationService.getByIdToDoCheckList(this.toDoInformationId).subscribe(res => {
        if (res && res.result?.length > 0) {
          this.patchChecklistItems(res.result);
        }
      });
    }
  }


  get toDoFormCheckListArray(): FormArray {
    return this.toDoFormCheckList.get('toDoFormCheckListArray') as FormArray;
  }

  createForm(): void {
    this.toDoFormCheckList = this._fb.group({
      toDoFormCheckListArray: this._fb.array([])
    });
  }


  addItem(): void {
    const checklistItem = this._fb.group({
      globalId: ['00000000-0000-0000-0000-000000000000'],
      toDoInformationId: [0],
      serialNumber: [this.toDoFormCheckListArray.length + 1],
      name: ['', Validators.maxLength(256)],
      isComplete: [false],
      applicationUserId: [0],
      showAttachments: [false],
      showAssignees: [false],
      attachments: [[]],
      nameError: new FormControl<string>('')
    });

    this.toDoFormCheckListArray.push(checklistItem);

    console.log();


  }

  removeItem(index: number): void {
    if (this.toDoFormCheckListArray.length > 0) {
      this.toDoFormCheckListArray.removeAt(index);
    }
  }


  resetChecklistForm(): void {
    this.toDoFormCheckList.reset();
  }


  toggleAssignees(index: number): void {
    const item = this.toDoFormCheckListArray.at(index) as FormGroup;
    const currentValue = item.get('showAssignees')?.value;
    // Toggle the value of showAssignees
    item.patchValue({
      showAssignees: !currentValue
    });
  }

  removeAllItems(): void {
    while (this.toDoFormCheckListArray.length !== 0) {
      this.toDoFormCheckListArray.removeAt(0);
      // this.IsCheckDisable.emit(false);

    }
  }

  toggleAttachments(index: number): void {
    const item = this.toDoFormCheckListArray.at(index);
    item.patchValue({
      showAttachments: !item.get('showAttachments')?.value  // Toggle attachments
    });
  }


  onGetAllAssigneeUser() {
    this.internalUserService.getData().subscribe(res => {
      this.internalRes = res.result;

    })
  }




  drop(event: CdkDragDrop<FormArray>): void {
    const fromIndex = event.previousIndex;
    const toIndex = event.currentIndex;
    moveItemInArray(this.toDoFormCheckListArray.controls, fromIndex, toIndex);

    // Reorder serial numbers
    this.toDoFormCheckListArray.controls.forEach((group, index) => {
      group.patchValue({ serialNumber: index + 1 });
    });
  }

  completeAll(): void {
    this.toDoFormCheckListArray.controls.forEach((control) => {
      control.get('isComplete')?.setValue(true);
      // this.IsCheckDisable.emit(false);

    });
  }

  areAllItemsComplete(): boolean {
    const allComplete = this.toDoFormCheckListArray.controls.every(control => control.get('isComplete')?.value === true);
    // this.IsCheckDisable.emit(!allComplete);
    return allComplete;
  }



  onSubmit(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.toDoFormCheckList.valid) {
        const observables = this.toDoFormCheckListArray.controls.map((control, index) => {
          const item = control.value;
          const itemAttachments = control.get('attachments')?.value;
          const isTextFilled = item.name.trim() !== '';
          const areAttachmentsValid = itemAttachments && itemAttachments.length > 0;

          if (!isTextFilled && !areAttachmentsValid) {
            return null; // Skip invalid items
          }

          const payload = {
            globalId: item.globalId,
            toDoInformationId: this.toDoInformationId,
            serialNumber: item.serialNumber || index + 1, // Preserve original serialNumber
            name: item.name,
            isComplete: item.isComplete,
            applicationUserId: item.applicationUserId || 0,
          };

          return this._toDoInformationService.postDataToDoCheckList(payload).pipe(
            switchMap(response => {
              control.get('globalId')?.setValue(response.result.globalId);

              if (areAttachmentsValid) {
                return from(saveAttachments(itemAttachments, this._AttachmentService, response.result.id, this.pageId)).pipe(
                  switchMap(() => of(response))
                );
              }
              return of(response);
            })
          );
        }).filter(Boolean);

        if (observables.length === 0) {
          console.log('No valid items to save');
          return resolve(); // Resolve if no items to save
        }

        forkJoin(observables).subscribe(
          () => {
            const validControls = this.toDoFormCheckListArray.controls.filter(control => {
              const name = control.get('name')?.value.trim();
              const attachments = control.get('attachments')?.value;
              return name !== '' || (attachments && attachments.length > 0);
            });

            this.removeAllItems(); // Clear all items first
            // validControls.forEach((control, index) => {
            //   control.get('serialNumber')?.setValue(index + 1); // Reset index
            //   control.get('nameError')?.setValue(''); // Clear any residual error messages
            //   this.toDoFormCheckListArray.push(control); // Add back the valid item
            // });

            this.changeDetectorRef.detectChanges();
            resolve(); // Resolve the promise after successful submission
          },
          (err) => {
            console.error('Submission failed', err);
            reject(err); // Reject if submission fails
          }
        );
      } else {
        console.log('Form is invalid');
        reject('Form is invalid');
      }
    });
  }


  patchChecklistItems(checklistItems: ChecklistItem[]): void {
    this.selectedFilesAttachment = [];
    this.toDoFormCheckListArray.clear();
    checklistItems.forEach((item, index) => {
      const checklistItem = this._fb.group({
        toDoInformationId: [item.ToDoParameterId],
        serialNumber: [item.SerialNumber],
        name: [item.Name, Validators.maxLength(256)],
        isComplete: [item.IsComplete],
        applicationUserId: [item.ApplicationUserId],
        globalId: [item.GlobalId],
        showAssignees: [false],
        showAttachments: [false],
        attachments: [[]]
      });
      this.toDoFormCheckListArray.push(checklistItem);
      if (item.ApplicationUserId > 0) {
        this.toggleAssignees(index);
      }

      if (item.AttachmentId > 0) {
        this.toggleAttachments(index);
      }
      this.fetchAttachmentSequentially(item.AttachmentId, index);
    });
  }



  fetchAttachmentSequentially(attachmentId: number, index: number): void {
    this.fetchAttachments(attachmentId).subscribe(
      (res) => {
        const attachments = res?.result || {}; // Ensure attachments is an object
        this.TestAttachment = attachments; // Update TestAttachment
        if (attachments.attachmentParameters && Array.isArray(attachments.attachmentParameters)) {
          this.toDoFormCheckListArray.at(index).get('attachments')?.setValue(attachments.attachmentParameters);
          this.onFilesUploaded(attachments.attachmentParameters, index);
        }
      },
      (error) => {
        console.error('Error fetching attachment:', error);
      }
    );
  }

  onFilesUploaded(files: CustomUploadFile[], index: number): void {
    const existingAttachments = this.toDoFormCheckListArray.at(index).get('attachments')?.value || [];
    const mergedAttachments = [
      ...existingAttachments,
      ...files.filter(newFile =>
        !existingAttachments.some(existing => existing.id === newFile.id) // Prevent duplicates
      )
    ];

    this.toDoFormCheckListArray.at(index).get('attachments')?.setValue(mergedAttachments);
    this.selectedFilesAttachment = mergedAttachments;
  }

  fetchAttachments(AttachmentId: number): Observable<any> {
    if (AttachmentId) {
      return this._AttachmentService.getLeadByAttachmentId(AttachmentId);
    } else {
      return of(this.TestAttachment = null); // Return an empty array if no AttachmentId
    }
  }







}
