<app-header></app-header>
<nz-layout>
  <nz-page-header>
    <nz-page-header-title>
      <h1 nz-typography>Customer Contacts</h1>
    </nz-page-header-title>
    <nz-page-header-extra>
      <nz-space *ngIf="!checkboxChecked">
        <button *nzSpaceItem nz-button nzType="default" class="me-0"
          (click)="exportToExcel()"
          [nzLoading]="isLoadingOne">Export</button>
        <button *nzSpaceItem nz-button nzType="default" class="me-0"
          (click)="openFilter(CUSTOMER_CONTACT, PAGE_ID)">
          <i class="bi bi-funnel pe-1"></i>
          Filter <span *ngIf="isFilterApplied"> (1) </span>
        </button>
        <button *nzSpaceItem nz-button nzType="primary" class="me-0"
          nzType="primary" (click)="showNewCustomerStandard()">New
          Contact</button>
      </nz-space>

      <nz-space *ngIf="checkboxChecked">
        <p> {{ getSelectedCheckboxes() }} </p>
        <button *nzSpaceItem nz-button nzType="default" class="me-0"
          (click)="deleteSelectedItems()">Delete</button>
        <button *nzSpaceItem nz-button nzType="default" class="me-0"
          (click)="toggleCheckbox()">Cancel</button>
        <button *nzSpaceItem nz-button nzType="default" class="me-0">
          <i class="bi bi-funnel pe-1"></i>
          Merge Contacts
        </button>
      </nz-space>

    </nz-page-header-extra>
  </nz-page-header>
  <nz-content>
    <nz-content>
      <kendo-grid
        [data]="displayedData"
        [pageable]="false"
        [height]="650"
        [sortable]="true"
        [resizable]="true"
        [reorderable]="true">
        <kendo-grid-column
          *ngFor="let column of displayedColumns; let i = index"
          [title]="column"
          [field]="column"
          [minResizableWidth]="150"
          [maxResizableWidth]="800"
          [width]="getColumnWidthSize[i]"
          [sticky]="isColumnSticky[i]"
          [headerClass]="'pinned-header'">
          <ng-template kendoGridHeaderTemplate let-columnIndex="columnIndex">
            <div class="d-flex align-items-center">
              <span>{{ column }}</span>
              <button (click)="toggleColumnSticky(columnIndex)"
                class="btn btn-link">
                <i class="bi" [ngClass]="{
                        'bi-pin-fill': isColumnSticky[columnIndex],
                        'bi-pin': !isColumnSticky[columnIndex]
                      }"></i>
              </button>
            </div>
          </ng-template>
          <ng-template kendoGridColumnMenuTemplate
            let-columnIndex="columnIndex">
            <button (click)="toggleColumnSticky(columnIndex)"
              class="btn btn-link">
              <i class="bi" [ngClass]="{
                      'bi-pin-fill': isColumnSticky[columnIndex],
                      'bi-pin': !isColumnSticky[columnIndex]
                    }"></i>
            </button>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem
            let-rowIndex="rowIndex">

            <ng-container *ngIf="column === 'DisplayName'">
              <a class="text-decoration-none blue-text"
                (click)="EditCustomer(dataItem.Id)">{{ dataItem.DisplayName }}</a>
            </ng-container>
            <ng-container *ngIf="column === 'Activation Status'">
              <nz-tag
              nz-popover
              [nzPopoverContent]="Content"
              class="d-flex align-items-center mt-1"
              [ngClass]="getStatusClass(dataItem?.StatusCode)"
              style="line-height: 1; border-radius: 10px; border: none; padding: inherit; height: 20px; width: fit-content; font-weight: 700; cursor: pointer;"
              (mouseenter)="setHovered(dataItem, true)"
              (mouseleave)="setHovered(dataItem, false)"
            >
              {{ dataItem?.StatusDescription }}
              <span class="ms-2" nz-icon nzType="info-circle" [nzTheme]="isHovered(dataItem) ? 'fill' : 'outline'"></span>
            </nz-tag>

            <ng-template #Content>
              <p style="width: 240px;">
                <span>
                  {{ dataItem?.StatusName }}
                  <ng-container *ngIf="dataItem?.StatusCode === 'CCA' || dataItem?.StatusCode === 'CCP'">
                    {{ dataItem?.CustomerOn | date : "MMM dd, YYYY" }}
                  </ng-container>
                </span>
              </p>
              </ng-template>

            </ng-container>
            <ng-container *ngIf="column === 'StreetAddress'">{{
              dataItem.StreetAddress }}</ng-container>
            <ng-container *ngIf="column === 'Suburb'">{{
              dataItem.Suburb}}</ng-container>
            <ng-container *ngIf="column === 'State'">{{ dataItem.State
              }}</ng-container>
            <ng-container *ngIf="column === 'PostalCode'">{{ dataItem.PostalCode
              }}</ng-container>
            <ng-container *ngIf="column === 'Phone'">{{ dataItem.PrimaryPhoneNo
              }}</ng-container>
              <ng-container *ngIf="column === 'IsLeadOpportunitie'">{{ dataItem.TotalLeadOpportunities
              }}</ng-container>
              <ng-container *ngIf="column === 'IsJob'">{{ dataItem.TotalJobs
              }}</ng-container>
            <ng-container *ngIf="column === 'CellPhone'">{{ dataItem.CellPhone
              }}</ng-container>
            <ng-container *ngIf="column === 'PrimaryEmail'">
              <span>{{ dataItem.Emails }} </span>
            </ng-container>
            <ng-template #Content>
              <p style="width: 220px; height: auto;">
                <span>
                  {{ dataItem?.statusSetup?.name }}
                  <ng-container
                    *ngIf="dataItem?.StatusCode === StatusCode.ACTIVE.code || dataItem?.StatusCode === StatusCode.PENDING.code">
                    {{ dataItem?.createdOn | date: 'MMM dd, yyyy' }}
                  </ng-container>
                </span>

              </p>
            </ng-template>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
    </nz-content>
  </nz-content>

  <nz-footer class="custom-footer">
    <div class="container-fluid ">
      <div class="row d-flex justify-content-between align-items-center  ">
        <div class="row mb-0 mt-2">
          <div class="col">
            <nz-pagination
              class="hide-buttons"
              style="float: right"
              [nzPageIndex]="current"
              [nzTotal]="totalRecords"
              [nzPageSize]="pageSize"
              [nzShowSizeChanger]="true"
              [nzPageSizeOptions]="pageSizeOptions"
              (nzPageIndexChange)="onPageChange($event)"
              (nzPageSizeChange)="onPageSizeChange($event)"
              [nzShowTotal]="totalTemplate"
              [nzSimple]="false"></nz-pagination>
            <ng-template #totalTemplate let-total="total" let-range="range">
              {{ range[0] }}-{{ range[1] }} of {{ total }} items
            </ng-template>
            <nz-pagination
              *ngIf="!isAllDataShownCustomerContact()"
              style="float: right"
              [nzPageIndex]="current"
              [nzTotal]="totalRecords"
              [nzPageSize]="pageSize"
              [nzShowSizeChanger]="true"
              [nzPageSizeOptions]="pageSizeOptions"
              (nzPageIndexChange)="onPageChange($event)"
              (nzPageSizeChange)="onPageSizeChange($event)"
              nzSimple></nz-pagination>

            <ng-container *ngIf="PAGE_ID">
              <div class="row d-flex justify-content-start mb-1 ">
                <div class="col-md-3">
                  <div class="input-group">
                    <nz-select
                      [(ngModel)]="selectedValueControl"
                      name="standardFilter"
                      (ngModelChange)="onFilterChange($event)"
                      nzShowSearch
                      [nzAutoFocus]="true"
                      nzPlaceHolder="Standard Filter">
                      <nz-option
                        *ngFor="let standard of getStandardViewFilterData"
                        [nzLabel]="standard.name + (standard.isSetAsDefault ? ' ' : '')"
                        [nzValue]="standard.id"
                        nzCustomContent>
                        <div class="option-content">
                          <span *ngIf="standard.icon" nz-icon
                            [nzType]="standard.icon"></span>
                          <span>{{ standard.name }}</span>
                          <span *ngIf="standard.isSetAsDefault"
                            class="status-text badge info margin-left-xss">Default</span>

                        </div>
                      </nz-option>
                    </nz-select>
                  </div>
                </div>
                <div class="col-md-2">
                  <button nz-button class="border-0"
                    (click)="openStandardViewFilterComponent()">
                    <span nz-popover
                      class="text-primary text-dark fw-bold fs-5" nz-icon
                      nzType="ellipsis"
                      nzTheme="outline"></span>
                  </button>
                </div>
                <div class="col-md-2">
                  <div class="mt-2" *ngIf="selectedFilter === 1">
                    <p>Your filter has unsaved changes.</p>
                  </div>
                  <div class="mt-2" *ngIf="selectedFilter === 0">
                    <p>Your filter has unsaved changes.</p>
                  </div>
                </div>
                <div class="col-md-3">
                  <button
                    (click)="openStandardViewFilterComponent('saveAs')"
                    type="button" nz-button
                    nzType="default" class="me-2" style="font-size: 12px;"
                    *ngIf="selectedFilter === 1">Save
                    As</button>

                  <button (click)="reInitReset()" nz-button nzType="default"
                    style="font-size: 12px;"
                    *ngIf="selectedFilter === 1"
                    type="button">Reset</button>

                  <button (click)="saveEditStandardView()" nz-button
                    nzType="primary" type="button" class="me-2"
                    style="font-size: 12px;"
                    *ngIf="selectedFilter === 0">Save</button>

                  <button
                    (click)="openStandardViewFilterComponent('saveAs')"
                    nz-button nzType="default"
                    class="me-2" style="font-size: 12px;"
                    *ngIf="selectedFilter === 0" type="button">Save
                    As</button>

                  <button (click)="reInitReset()" nz-button nzType="default"
                    style="font-size: 12px;"
                    *ngIf="selectedFilter === 0"
                    type="button">Reset</button>

                </div>

              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </nz-footer>
</nz-layout>

<app-loading-indicator></app-loading-indicator>
