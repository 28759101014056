<nz-page-header class="p-0">
    <nz-page-header-title class="p-0">
    </nz-page-header-title>
    <nz-page-header-extra>
      <nz-space>
        <button nz-button nzType="default" nz-popover
        [nzPopoverContent]="contentTemplate" nzPopoverPlacement="left"
         class="me-1"><h5>
          <i class="bi bi-gear-fill fs-6"></i>
        <ng-template #contentTemplate>
          <div class="rounded-2">
            <p style="font-size: 13px">
              Schedule Setting
            </p>
          </div>
        </ng-template>
        </h5>
        </button>
        <nz-divider nzType="vertical" class="divder"></nz-divider>
        <nz-switch [(ngModel)]="switchValue1"></nz-switch>
          <span class="ms-1 me-2 spanset">Schedule Online</span>
          <button nz-button nzType="default" class="ms-2 rounded-1" >
              <i class="bi bi-mortarboard-fill"></i>&nbsp;&nbsp;Learn More</button>
          <button nz-button nz-dropdown [nzTrigger]="'click'" [nzDropdownMenu]="menu4" class="ms-2 rounded-1">
              More Action<span nz-icon nzType="down"></span>
            </button>
            <nz-dropdown-menu #menu4="nzDropdownMenu">
              <ul nz-menu>
                <li nz-menu-item nzDisabled>Export to Excel</li>
                <li nz-menu-item nzDisabled>Export to PDF</li>

              </ul>
            </nz-dropdown-menu>
          <button nz-button nzType="default"(click)="openFilter(PROJECT_MANG_SCHED_BASELINE, PAGE_ID)" class="ms-2 rounded-1" >
              <i class="bi bi-funnel"></i>&nbsp;&nbsp;Filter</button>
          <button  nz-button nzType="primary" class="ms-2 rounded-1"
          >Reset Baseline</button>
      </nz-space>
    </nz-page-header-extra>

  </nz-page-header>


<div class="row mb-0" style="margin-top:100px;">



    <div class="col-lg-8 offset-lg-2">
       <div class="col text-center mb-1">
        <svg width="80px" height="80px" viewBox="0 0 32 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" class="">
            <path d="M12.5 17.5C12.5 16.6716 11.8284 16 11 16C10.1716 16 9.5 16.6716 9.5 17.5C9.5 18.3284 10.1716 19 11 19C11.8284 19 12.5 18.3284 12.5 17.5Z"></path>
            <path d="M12.5 22.5C12.5 21.6716 11.8284 21 11 21C10.1716 21 9.5 21.6716 9.5 22.5C9.5 23.3284 10.1716 24 11 24C11.8284 24 12.5 23.3284 12.5 22.5Z"></path>
            <path d="M16 21C16.8284 21 17.5 21.6716 17.5 22.5C17.5 23.3284 16.8284 24 16 24C15.1716 24 14.5 23.3284 14.5 22.5C14.5 21.6716 15.1716 21 16 21Z"></path>
            <path d="M17.5 17.5C17.5 16.6716 16.8284 16 16 16C15.1716 16 14.5 16.6716 14.5 17.5C14.5 18.3284 15.1716 19 16 19C16.8284 19 17.5 18.3284 17.5 17.5Z"></path>
            <path d="M22.5 17.5C22.5 16.6716 21.8284 16 21 16C20.1716 16 19.5 16.6716 19.5 17.5C19.5 18.3284 20.1716 19 21 19C21.8284 19 22.5 18.3284 22.5 17.5Z"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M22.9933 3.88338C22.9355 3.38604 22.5128 3 22 3C21.4477 3 21 3.44772 21 4V5H11V4L10.9933 3.88338C10.9355 3.38604 10.5128 3 10 3C9.44772 3 9 3.44772 9 4V5H6L5.85074 5.00549C4.81588 5.08183 4 5.94564 4 7V27L4.00549 27.1493C4.08183 28.1841 4.94564 29 6 29H26L26.1493 28.9945C27.1841 28.9182 28 28.0544 28 27V7L27.9945 6.85074C27.9182 5.81588 27.0544 5 26 5H23V4L22.9933 3.88338ZM6 13H26V27H6V13ZM9 7H6V11H26V7H23V8C23 8.55228 22.5523 9 22 9C21.4872 9 21.0645 8.61396
             21.0067 8.11662L21 8V7H11V8C11 8.55228 10.5523 9 10 9C9.48716 9 9.06449 8.61396 9.00673 8.11662L9 8V7Z"></path>
            </svg>
       </div>
        <span class="text-center"><h1 class="fw-medium famly-inn mb-2" style="font-size: 24px">Balance multiple projects with</h1></span>
        <span class="text-center"><h1 class="fw-medium famly-inn mb-3" style="font-size: 24px">Schedule</h1></span>
        <p class="text-center famly-inn mb-1" style="font-size: 14px">Assign people, manage dependencies, and communicate your</p>
        <p class="text-center famly-inn mb-1" style="font-size: 14px">project schedule to keep everyone working towards a common</p>
        <p class="text-center famly-inn mb-4" style="font-size: 14px">goal.</p>
    <div class="col mt-2 text-center" >
        <button nz-button nzType="default" class="me-2 " ><i class="bi bi-box-arrow-up-right me-2"></i>Learn How</button>
        <button nz-button nzType="primary">Add a Schedule Item</button>
    </div>
</div>
</div>TTTT
