<nz-modal [(nzVisible)]="Taghandle" [nzTitle]="NewDaily1" [nzFooter]="CWarranty" (nzOnCancel)="TagClose()"
[nzBodyStyle]="{
    background: '#f1f4fa',
    padding: '14px',
    maxHeight: '72vh',
    'overflow-y': 'auto',
    'overflow-x': 'hidden',
  }"
  [nzStyle]="{ top: '25px'}"
  [nzWidth]="'84%'">
    <ng-template #NewDaily1>
        <div class="row p-1 m-0">
          <h2 class="WarrantyServiceTitleSet famly-inn p-0 m-0">
          Warranty Service
          </h2>
        </div>
    </ng-template>
  <div class="content" *nzModalContent>
    <!-- <div class="spinner-wrapper" *ngIf="isLoading">
      <img src="/assets/Loder/new-ajax-loader.gif">
    </div> -->
  <div class="card mb-3 border-0">
        <div class="card-head border-bottom pr-0 mr-0">
          <div nz-row>
            <div nz-col nzSpan="12" class="p-2 d-flex align-items-center">
              <h3 class="Card-Title-Set famly-inn ps-2">Appointment Scheduling</h3>
            </div>
            <div nz-col nzSpan="12" class="d-flex justify-content-end align-items-center">

            </div>
          </div>
        </div>
        <div class="card-body">
          <div nz-row>
            <div nz-col nzSpan="24">
              <label class="para-lable-selection famly-inn">Assigned to<span class="text-danger">*</span></label>
            </div>
        </div>
        <div nz-row class="Test mb-2">
          <div nz-col nzSpan="10">
            <nz-form-control [nzValidateStatus]="showAssignError ? 'error' : ''">
              <nz-select
                [nzCustomTemplate]="defaultTemplate"
                nzShowSearch
                [(ngModel)]="test"
                [nzStatus]="showAssignError ? 'error' : ''"
                [compareWith]="compareFn"
                (ngModelChange)="onAssigneeChange($event)">
                <nz-option nzValue="me" nzLabel="Me"></nz-option>
                <ng-container *ngFor="let group of selectedUsers">
                  <nz-option-group [nzLabel]="group.groupName">
                    <ng-container *ngFor="let user of group.users">
                      <nz-option
                        [nzValue]="{ id: user.id, groupName: group.groupName }"
                        [nzLabel]="user.name">
                        <span class="color-name">{{ user.name }}</span>
                      </nz-option>
                    </ng-container>
                  </nz-option-group>
                </ng-container>
              </nz-select>
              <ng-template #defaultTemplate let-selected>
                <div class="color-item d-flex align-items-center">
                  <nz-avatar
                    [nzText]="initials"
                    style="color:#f56a00; background-color:#fde3cf;">
                  </nz-avatar>
                  <span class="color-name famly-inn">{{ selected.nzLabel }}</span>
                </div>
              </ng-template>
            </nz-form-control>
          </div>
        </div>
        <div nz-row style="margin-top: -8px;">
          <div nz-col nzSpan="24">
            <div *ngIf="showAssignError" class="error-message">
              The service must be assigned.
            </div>
          </div>
        </div>

          <div nz-row class="mb-1 mt-1" *ngIf="isCheckboxVisible">  <!-- Only show when Sub Vendor is selected -->
            <div nz-col nzSpan="24">
              <label nz-checkbox  [(ngModel)]="appointmentData.isSubVendorScheduleAppointment" name="isSubVendorScheduleAppointment"  [disabled]="!isCheckboxEnabled" (ngModelChange)="onCheckboxChange()">
                  <ng-container *ngIf="holdWarrantyServiceAllResponce; else HelpLabelElse">
                  <span class="selectionCheckBoxSelection famly-inn" *ngIf="holdWarrantyServiceAllResponce?.isSubVendorScheduleAppointment === false">
                       Sub/Vendor Schedule Appointment (Sub/Vendor Will Be Notified)
                  </span>
                  <span *ngIf="holdWarrantyServiceAllResponce?.isSubVendorScheduleAppointment === true">
                    Sub/Vendor Schedule Appointment
                  </span>
                </ng-container>
                <ng-template #HelpLabelElse>
                  <span class="selectionCheckBoxSelection famly-inn">
                    Sub/Vendor Schedule Appointment (Sub/Vendor Will Be Notified)
                  </span>
                </ng-template>
             </label>
            </div>
          </div>
          <div nz-row >
            <div nz-col nzSpan="24">
              <label class="para-lable-selection famly-inn">Service on<span class="text-danger">*</span></label>
            </div>
        </div>
        <div nz-row class="mb-1" *ngIf="appointmentData.isSubVendorScheduleAppointment">
            <div nz-col nzSpan="4">
                <ng-container *ngIf="holdWarrantyServiceAllResponce ; else helpSetSubtract">
                  <div style="color:#202227;font-weight: bold;" *ngIf="holdWarrantyServiceAllResponce?.isSubVendorScheduleAppointment === false">--</div>
                </ng-container>
                <ng-template #helpSetSubtract>
                  <div style="color:#202227;font-weight: bold;">--</div>
                </ng-template>
                <div style="color:#202227;" *ngIf="holdWarrantyServiceAllResponce?.isSubVendorScheduleAppointment === true">TBD</div>
            </div>
        </div>
        <div nz-row class="mb-1" *ngIf="!appointmentData.isSubVendorScheduleAppointment">
          <div nz-col nzSpan="4">
          <nz-form-control [nzValidateStatus]="showServiceOnError ? 'error' : ''">
            <nz-date-picker 
                nzPlaceHolder=" " 
                [(ngModel)]="appointmentData['serviceOn']" 
                name="serviceOn"
                [nzStatus]="showServiceOnError ? 'error' : ''"
                [nzFormat]="'MMM d,YYYY'" 
                nzSuffixIcon="false" 
                (ngModelChange)="updateCheckboxState($event)">
            </nz-date-picker>
          </nz-form-control>
            <div nz-row>
              <div nz-col nzSpan="24">
                <div *ngIf="showServiceOnError" class="error-message">
                  Required.
                </div>
              </div>
            </div>
        </div>
        </div>
        <div nz-row *ngIf="!appointmentData.isSubVendorScheduleAppointment">
          <div nz-col nzSpan="24">
            <label class="para-lable-selection famly-inn">Start Time</label>
          </div>
        </div>
        <div nz-row class="mb-1" *ngIf="!appointmentData.isSubVendorScheduleAppointment">
          <div nz-col nzSpan="4">
            <nz-time-picker
              [(ngModel)]="appointmentData['startTime']"
              (ngModelChange)="onTimeChange()"
              name="startTime"
              nzFormat="hh:mm a"
              nzPlaceHolder=" "
            ></nz-time-picker>
          </div>
        </div>
        <div nz-row *ngIf="!appointmentData.isSubVendorScheduleAppointment">
          <div nz-col nzSpan="24">
            <label class="para-lable-selection famly-inn">End Time</label>
          </div>
        </div>
        <div nz-row class="mb-3" *ngIf="!appointmentData.isSubVendorScheduleAppointment">
          <div nz-col nzSpan="4">
            <nz-form-control [nzValidateStatus]="showTimeError ? 'error' : ''">
              <nz-time-picker
                [(ngModel)]="appointmentData['endTime']"
                (ngModelChange)="onTimeChange()"
                name="endTime"
                [nzStatus]="showTimeError ? 'error' : ''"
                nzFormat="hh:mm a"
                nzPlaceHolder=" "
              ></nz-time-picker>
            </nz-form-control>
          </div>
        </div>
        <div nz-row style="margin-top: -12px;">
          <div nz-col nzSpan="24">
            <div *ngIf="showTimeError" class="error-message">
              End time must be after the start time.
            </div>
          </div>
        </div>



        <div nz-row *ngIf="isCheckboxVisible">
          <div nz-col nzSpan="24">
            <label class="para-lable-selection famly-inn">Notes to Sub/Vendor</label>
          </div>
        </div>
        <div nz-row *ngIf="isCheckboxVisible" class="mb-2">
          <div nz-col nzSpan="24">
            <textarea
            [(ngModel)]="appointmentData['internalNotes']"
            name="internalNotes"
            nz-input [nzAutosize]="{ minRows: 2, maxRows: 12 }"
            class="formField" style="resize: none;"
            [ngClass]="{'border-danger': appointmentData['internalNotes']?.length > 512}"
            required>
            </textarea>
            <!-- Dynamic over character message -->
            <div *ngIf="appointmentData['internalNotes']?.length > 512" class="text-danger mt-1">
              {{ appointmentData['internalNotes'].length - 512 }} characters!
            </div>
            <!-- Remaining characters message -->
            <div *ngIf="appointmentData['internalNotes']?.length >= 412 && appointmentData['internalNotes']?.length <= 512" class="text-info mt-1">
              {{ 512 - appointmentData['internalNotes'].length }} characters remaining.
            </div>
          </div>
        </div>
        <div nz-row>
          <div nz-col nzSpan="24">
            <label class="para-lable-selection famly-inn">Internal Appointment Notes</label>
          </div>
        </div>
        <div nz-row class="mb-2">
          <div nz-col nzSpan="24">
            <textarea
            [(ngModel)]="appointmentData['internalNotes']"
            name="internalNotes"
            nz-input [nzAutosize]="{ minRows: 2, maxRows: 12 }"
            class="formField" style="resize: none;"
            [ngClass]="{'border-danger': appointmentData['internalNotes']?.length > 512}"
            required>
            </textarea>
            <!-- Dynamic over character message -->
            <div *ngIf="appointmentData['internalNotes']?.length > 512" class="text-danger mt-1">
               {{ appointmentData['internalNotes'].length - 512 }} characters!
            </div>
            <!-- Remaining characters message -->
            <div *ngIf="appointmentData['internalNotes']?.length >= 412 && appointmentData['internalNotes']?.length <= 512" class="text-info mt-1">
              {{ 512 - appointmentData['internalNotes'].length }} characters remaining.
            </div>
          </div>
        </div>
      </div>
  </div>
  <div class="card mb-3 border-0">
        <div class="card-head border-bottom pr-0 mr-0">
          <div nz-row>
            <div nz-col nzSpan="12" class="p-2 d-flex align-items-center">
              <h3 class="Card-Title-Set famly-inn ps-2">Attachments</h3>
            </div>
            <div nz-col nzSpan="12" class="d-flex justify-content-end align-items-center">
            </div>
          </div>
        </div>
        <div class="card-body">
          <div nz-row>
            <div nz-col nzSpan="24">
              <app-create-attachments-common [TestAttachment__]="TestAttachment" (filesUploaded)="onFilesUploaded($event)"></app-create-attachments-common>
            </div>
          </div>
        </div>
  </div>
  <div class="card mb-3 border-0" *ngIf="!appointmentData.isSubVendorScheduleAppointment">
        <div class="card-head border-bottom pr-0 mr-0">
          <div nz-row>
            <div nz-col nzSpan="12" class="p-2 d-flex align-items-center">
              <h3 class="Card-Title-Set famly-inn ps-2">Appointment Status</h3>
            </div>
            <div nz-col nzSpan="12" class="d-flex justify-content-end align-items-center">
            </div>
          </div>
        </div>
        <div class="card-body" *ngIf="warrantyResponse || responseHeaderId > 0 ||!isformSave ">
          <div nz-row *ngIf="isCheckboxVisible">
            <div nz-col nzSpan="24">
              <label nz-checkbox  [(ngModel)]="appointmentData.isBuilderAccepted" name="isBuilderAccepted" *ngIf=" !isformSaved ">
                <span class="selectionCheckBoxSelection famly-inn">
                  Submit this appointment to Sub/Vendor for acceptance?
                </span>
                <i class="bi bi-info-circle-fill text-secondary"
                nz-popover
                [nzPopoverContent]="SubVendor"
                nzPopoverPlacement="top">
                </i>
                <ng-template #SubVendor>
                    <div class="iconPopoverSetted">
                        <span>
                          Checking this box will notify
                          the Sub/Vendor of this appointment
                          time. They will be prompted to
                          accept or decline it in Buildertrend.
                        </span>
                    </div>
                </ng-template>
            </label>
            </div>
          </div>
         <ng-container *ngIf="isShowOwner !== false">
          <div nz-row *ngIf="!holdWarrantyServiceAllResponce">
            <div nz-col nzSpan="24">
              <label nz-checkbox [(ngModel)]="appointmentData.isOwnerAccepted" name="isOwnerAccepted" *ngIf=" !isformSaved ">
                <span class="selectionCheckBoxSelection famly-inn">
                  Submit this appointment to OWNER for acceptance?
                </span>
                <i class="bi bi-info-circle-fill text-secondary"
                nz-popover
                [nzPopoverContent]="Daiyly"
                nzPopoverPlacement="top">
                </i>
                <ng-template #Daiyly>
                    <div class="iconPopoverSetted">
                        <span>
                          Checking this box will notify the
                          Owner of this appointment time.
                          They will be prompted to accept
                          or decline it in PersistentSolutions.
                        </span>
                    </div>
                </ng-template>
            </label>
            </div>
          </div>
         </ng-container>
          <div nz-row [nzGutter]="16" *ngIf="holdWarrantyServiceAllResponce || acceptedBuilderById === true">
            <div nz-col nzSpan="4" >
              <span >Owner</span>
            </div>
            <div nz-col nzSpan="20">
              <td>
                <span class="me-2">
                  <svg
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 32 32"
                    width="1em"
                    height="1em"
                    aria-hidden="true"
                    focusable="false"
                    class=""
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M14.65 3.87a2 2 0 0 1 2.7 0l10 9.09.01.01a2.04 2.04 0 0 1 .64 1.45V26h2a1 1 0 1 1 0 2H2a1 1 0 1 1 0-2h2V14.42a2.04 2.04 0 0 1 .64-1.45l.01-.01 10-9.09ZM14 26h4v-6h-4v6Zm6 0v-6a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v6H6V14.45a.04.04 0 0 1 0-.02l10-9.08 10 9.08V26h-6Z"
                    ></path>
                  </svg>
                </span>
                <span class="fw-bold" style="color:#666d7c"> Never Accepted </span>
              </td>
            </div>
          </div>
          <div nz-row [nzGutter]="16" *ngIf="holdWarrantyServiceAllResponce || acceptedBuilderById === true">
            <div nz-col nzSpan="4">
              <span >Builder</span>
            </div>
            <div nz-col nzSpan="20">
              <span>
                <span class="me-2">
                  <svg
                    width="1em"
                    height="1em"
                    viewBox="0 0 32 32"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    focusable="false"
                    class=""
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M26.2634 8.64228C28.1005 10.8645 28.5175 13.9798 27.335 16.629C26.7764 17.8803 25.9083 18.9214 24.8485 19.6733C27.2432 20.5337 29.3529 22.1913 30.836 24.4513C31.1391 24.9131 31.0104 25.533 30.5487 25.836C30.0869 26.139 29.467 26.0104 29.1639 25.5487C27.3484 22.7822 24.4367 21.1079 21.3137 21.005L20.9314 20.9964C20.8569 20.9988 20.7823 21 20.7075 21C20.1552 21 19.7075 20.5524 19.7074 20.0001C19.7073 19.4478 20.155 19.0001 20.7073 19C22.7647 18.9997 24.6401 17.7596 25.5086 15.8138C26.3821 13.8569 26.0736 11.5516 24.7219 9.91661C23.3804 8.2939 21.2576 7.62833 19.2794 8.20399C18.7491 8.3583 18.1941 8.05351 18.0398 7.52323C17.8855 6.99294 18.1903 6.43796 18.7206 6.28364C21.4692 5.4838 24.416 6.40773 26.2634 8.64228ZM11.5 5.99999C15.6421 5.99999 19 9.35785 19 13.5C19 16.2061 17.5667 18.5776 15.4179 19.8965C17.576 20.8048 19.4702 22.3728 20.8356 24.45C21.139 24.9115 21.0108 25.5315 20.5493 25.8349C20.0878 26.1382 19.4677 26.0101 19.1644 25.5486C17.2865 22.6918 14.2404 20.9999 10.9999 21C7.75926 21.0001 4.71335 22.6923 2.83566 25.5492C2.53232 26.0108 1.91228 26.139 1.45075 25.8357C0.989226 25.5323 0.860989 24.9123 1.16433 24.4507C2.66084 22.1738 4.79285 20.5085 7.21174 19.6524C5.27007 18.2983 3.99999 16.0474 3.99999 13.5C3.99999 9.35785 7.35785 5.99999 11.5 5.99999ZM5.99999 13.5C5.99999 10.4624 8.46242 7.99999 11.5 7.99999C14.5376 7.99999 17 10.4624 17 13.5C17 16.5376 14.5376 19 11.5 19C8.46242 19 5.99999 16.5376 5.99999 13.5Z"
                    ></path>
                  </svg>
                </span>
                <span class="fw-bold" style="color: #31ce8d" [(ngModel)]="appointmentData['isBuilderAccepted']"
                name="isBuilderAccepted"> Internally Accepted</span> -
                {{ holdWarrantyServiceAllResponce?.serviceOn | date: 'MMM d, yyyy'   }} {{ holdWarrantyServiceAllResponce?.serviceOn | date: 'h:mm a' }}{{builderOnAccept}}
              </span>
            </div>
          </div>
        </div>
  </div>
  <div class="card mb-3 border-0" *ngIf="!appointmentData.isSubVendorScheduleAppointment">
        <div class="card-head border-bottom pr-0 mr-0">
          <div nz-row>
            <div nz-col nzSpan="12" class="p-2 d-flex align-items-center">
              <h3 class="Card-Title-Set famly-inn ps-2">Final Work Approval</h3>
            </div>
            <div nz-col nzSpan="12" class="d-flex justify-content-end align-items-center">
            </div>
          </div>
        </div>
        <div class="card-body">
          <div nz-row>
            <div nz-col nzSpan="24">
              <label nz-checkbox [(ngModel)]="appointmentData.isBuilderOverride" name="isBuilderOverride" >
                <span class="selectionCheckBoxSelection famly-inn">
                  Builder Override
                </span>
              </label>
            </div>
          </div>
          <div nz-row >
            <div nz-col nzSpan="24">
              <label class="para-lable-selection famly-inn">
                Owner Feedback
                <i class="bi bi-info-circle-fill text-secondary"
                   nz-popover
                   [nzPopoverContent]="contentTemplate"
                   nzPopoverPlacement="top">
                </i>
                <ng-template #contentTemplate>
                  <div class="iconPopoverSetted">
                    <span>
                      Feedback will be available after the appointment date
                    </span>
                </div>
                </ng-template>
              </label>
            </div>
          </div>
          <div nz-row class="mt-1 mb-1" *ngIf="!appointmentData.isBuilderOverride">
            <div nz-col nzSpan="24">
              <span class="selectionCheckBoxSelection famly-inn">Not Submitted</span>
            </div>
          </div>
          <div nz-row *ngIf="appointmentData.isBuilderOverride">
            <div nz-col nzSpan="5">
            <nz-select
              [(ngModel)]="appointmentData['ownerFeedbackId']"
              name="ownerFeedbackId"
              (ngModelChange)="onFeedbackChange()"
              [ngClass]="{'border-danger': showError}">
              <nz-option
                *ngFor="let option of ownerFeedbackOption"
                [nzLabel]="option.name"
                [nzValue]="option.id">
              </nz-option>
            </nz-select>
            <div *ngIf="showError" class="text-danger">Owner Feedback is required.</div>
            </div>
          </div>

          <div nz-row [nzGutter]="6" *ngIf="(showCompletionFields || showMeetwarrantyFields) && !hideForNeedsReworkFields && !hideReadyForNextPhaseFields ">
            <div nz-col nzSpan="3">
                <label class="para-lable-selection">Completion Date </label>
                <nz-date-picker
                  [disabled]="isFormSaved3 || !appointmentData.isBuilderOverride" [nzFormat]="'MMM d,YYYY'"
                  [(ngModel)]="appointmentData['completionOn']" name="completionOn" nzPlaceHolder=" "
                  nzSuffixIcon="false"></nz-date-picker>
            </div>
            <div nz-col nzSpan="3">
              <label class="para-lable-selection">Time </label>
              <nz-select
               style="width: 137px;"
               [disabled]="isFormSaved3 || !appointmentData.isBuilderOverride"
               [(ngModel)]="appointmentData['completionTime']" name="completionTime">
                 <nz-option
                     *ngFor="let time of timeOptions"
                     [nzLabel]="time"
                     [nzValue]="time">
                 </nz-option>
             </nz-select>
            </div>
        </div>
        <div nz-row class="mt-1">
            <div nz-col nzSpan="24">
                <label class="para-lable-selection">Appointment Notes</label>
            </div>
        </div>
        <div nz-row>
          <div nz-col nzSpan="24">
            <textarea
              [(ngModel)]="appointmentData['appointmentNotes']"
              name="appointmentNotes"
              [disabled]="isFormSaved3 || !appointmentData.isBuilderOverride"
              nz-input [nzAutosize]="{ minRows: 2, maxRows: 12 }"
              class="formField" style="resize: none;"
              [ngClass]="{'border-danger': appointmentData['internalNotes']?.length > 512}"
              required>
            </textarea>
            <div *ngIf="appointmentData['appointmentNotes']?.length > 512" class="text-danger mt-1">
              {{ appointmentData['appointmentNotes'].length - 512 }} characters!
            </div>
            <div *ngIf="appointmentData['appointmentNotes']?.length >= 412 && appointmentData['appointmentNotes']?.length <= 512" class="text-info mt-1">
              {{ 512 - appointmentData['appointmentNotes'].length }} characters remaining.
            </div>
          </div>
        </div>
      <div nz-row>
          <div nz-col nzSpan="12">
              <label class="para-lable-selection">Feedback Left on</label>
          </div>
      </div>
      <div nz-row>
          <div nz-col nzSpan="12">
            <span *ngIf="(!isfeedbackData || !holdWarrantyServiceAllResponce?.isBuilderOverride === true) && !FeedValueOn">
              N/A
            </span>
              <span *ngIf="isfeedbackData && holdWarrantyServiceAllResponce?.isBuilderOverride === true">
                {{ holdWarrantyServiceAllResponce.serviceOn | date: 'MMM d, yyyy' }}, {{ holdWarrantyServiceAllResponce.serviceOn | date: 'h:mm a' }}
              </span>
              <span >{{ FeedValueOn }}</span>
           </div>

      </div>
    </div>
  </div>
  </div>
<ng-template #CWarranty>
        <div nz-row [nzGutter]="16">
          <div nz-col [nzSpan]="8">
            <div class="text-start  justify-content-start align-items-start" *ngIf="warrantiesResponse">
              <span *ngIf="footerResponse">
                <app-footer-label [footerData]="footerResponse"></app-footer-label>
              </span>
            </div>
          </div>
            <div nz-col [nzSpan]="16">
              <nz-space>
                <ng-container *ngIf="holdWarrantyServiceAllResponce || _warrantyServiceGetby_HoldId > 0">
                  <button
                  nz-button
                  nzType="default"
                  *nzSpaceItem
                  (click)="ConfirmDelete()"
                  >
                  Delete
                </button>
                </ng-container>
                <button
                  nz-button
                  nzType="primary"
                  *nzSpaceItem
                  [nzLoading]="isSave"
                  (click)="savewarrantyserviceform()">
                  Save
                </button>

                <button
                  nz-button
                  nzType="primary"
                  *nzSpaceItem
                  [nzLoading]="isSaveAndClose"
                  (click)="saveAndClosewarrantyserviceform()">
                  Save & Close
                </button>
               </nz-space>
            </div>
          </div>
</ng-template>
</nz-modal>
