

<nz-card nzTitle="Conversation Information" nzBorderless>

  <div nz-row class="mb-3">
    <div nz-col nzSpan="24">
      <label class="sub-text">Job</label>
      <p nz-typography>{{componentSelectedJobName}}</p>
    </div>
  </div>

  <div nz-row class="mb-3">
    <div nz-col nzSpan="24">
      <label class="sub-text">Type</label>
      <p nz-typography>{{componentTypeDynamic}}</p>
    </div>
  </div>

  <div nz-row class="mb-3">
    <div nz-col nzSpan="24">
      <label class="sub-text">Title</label>
      <p nz-typography>{{ getFormattedTitle(componentTitleDynamic) }}</p>
    </div>
  </div>

  <div nz-row class="mb-3">
    <div nz-col nzSpan="24">
      <messaging-common
      style="width: 100%;"
      [Job_ID]="JobInfoId"
      [Messaging_ID]="Messaging_Id"
      [header_ID]="conversationId"
      [Form_Name_ID]="formNameId"
      (messageSaved)="onMessageSaved()"
      >
      </messaging-common>

    </div>
  </div>

</nz-card>
