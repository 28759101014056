<nz-modal
  nzWidth="65%"
  [nzVisible]="NewDailyhandle"
  [nzTitle]="NewDaily1"
  [nzFooter]="null"
  (nzOnCancel)="NewDailyCancel()"
  [nzBodyStyle]="{
    background: '#f1f4fa',
    padding: '14px',
    'max-height': '78vh',
    'overflow-y': 'auto'
  }"
  [nzStyle]="{ top: '18px' }"
>
  <ng-template #NewDaily1>
    <div class="row p-0 m-0">
      <h1 nz-typography>Contact List</h1>
    </div>
  </ng-template>
  <div class="content famly-inn" *nzModalContent>
    <div class="card border-0 mt-0 p-3">
      <div class="card-head border-bottom pb-2">
        <div class="row py-2">
          <div class="col-12">
            <label class="sub-text ms-2">Search by name/email</label>
          </div>
        </div>
        <div class="row p-2">
          <div class="col-6">
            <div class="input-group">
              <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
                <input type="text" class="formField" nz-input [(ngModel)]="searchTerm"  />
              </nz-input-group>
              <ng-template #suffixIconButton>
                <button nz-button nzType="default"  nzSearch (click)="onSearch()">
                  <span nz-icon nzType="search"></span>
                </button>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row shadow-sm p-1 bg-body rounded">
          <div class="col-12 p-0 m-0">
            <nz-table
            #sortTable
            [nzData]="gridData"
            [nzBordered]="false"
            nzSize="small"
            [nzScroll]="{ x: '1625px' }"
            [nzPageSize]="pageSize"
            [nzScroll]="{ y: '600px', x: '1100px' }"
            [nzPageSizeOptions]="[20, 50, 75, 100, 250]"
            [nzShowSizeChanger]="true"
            [nzLoading]="isLoading"
            nzPaginationType="default"
            [nzFrontPagination]="false"
            [nzTotal]="totalRecords"
            [nzPageIndex]="currentPage"
            [nzPageSize]="pageSize"
            [nzPaginationPosition]="'bottom'"
            [nzShowTotal]="rangeTemplate"
            (nzPageSizeChange)="onPageSizeChange($event)"
            (nzPageIndexChange)="onPageIndexChange($event)"
          >
            <thead style="background-color: #f1f4fa; border-bottom: #ccc">
              <tr>
                <th nzLeft style="border-radius: 8px 0px 0px 0px" nzWidth="110px" class="sub-text1"></th>
                <th
                  class="sub-text1"
                  nzWidth="10%"
                  *ngFor="let column of listOfColumns"
                  [nzSortFn]="column.compare"
                  [nzSortPriority]="column.priority"
                >
                  {{ column.name }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="ms-2 bard" style="border: solid 2px #ccc" *ngFor="let item of sortTable.data">
                <td nzLeft style="width: 15%">
                  <button (click)="onSelectCustomer(item)" nz-button nzType="text" class="same-but ms-2">
                    <span>Select</span>
                  </button>
                </td>
                <td style="width: 17%">
                  <label class="pira-label" nz-popover [nzPopoverContent]="displayName" nzPopoverPlacement="top">
                    {{ item.DisplayName }}
                  </label>
                  <ng-template #displayName>
                    <p style=" font-size: 14px; ">{{ item.DisplayName }}</p>
                  </ng-template>
                </td>
                <td style="width: 17%">
                  <nz-tag
                  nz-popover
                  [nzPopoverContent]="Content"
                  class="d-flex align-items-center mt-1"
                  [ngClass]="getStatusClass(item?.StatusCode)"
                  style="line-height: 1; border-radius: 10px; border: none; padding: inherit; height: 20px; width: fit-content; font-weight: 700; cursor: pointer;"
                  (mouseenter)="setHovered(item, true)"
                  (mouseleave)="setHovered(item, false)"
                >
                  {{ item?.StatusDescription }}
                  <span class="ms-2" nz-icon nzType="info-circle" [nzTheme]="isHovered(item) ? 'fill' : 'outline'"></span>
                </nz-tag>

                <ng-template #Content>
                  <p style="width: 240px;">
                    <span>
                      {{ item?.StatusName }}
                      <ng-container *ngIf="item?.StatusCode === 'CCA' || item?.StatusCode === 'CCP'">
                        {{ item?.CustomerOn | date : "MMM dd, YYYY" }}
                      </ng-container>
                    </span>
                  </p>
                </ng-template>



                </td>
                <td style="width: 17%">
                  <label class="pira-label" nz-popover [nzPopoverContent]="primaryEmail" nzPopoverPlacement="top">
                    {{ item.PrimaryEmail }}
                  </label>
                </td>
                <ng-template #primaryEmail>
                  <p style=" font-size: 14px; ">{{ item.PrimaryEmail }}</p>
                </ng-template>
                <td style="width: 17%">
                  <label class="pira-label">{{ item.Phone }}</label>
                </td>
                <td style="width: 17%">
                  <label class="pira-label">{{ item.CellPhone }}</label>
                </td>
                <td style="width: 17%">
                  <label class="pira-label">{{ item.StreetAddress }}</label>
                </td>
                <td style="width: 17%">
                  <label class="pira-label">{{ item.CountryCode }}</label>
                </td>
                <td style="width: 17%">
                  <label class="pira-label">{{ item.State }}</label>
                </td>
                <td style="width: 17%">
                  <label class="pira-label">{{ item.State }}</label>
                </td>
                <td style="width: 17%">
                  <label class="pira-label">{{ item.Suburb }}</label>
                </td>
              </tr>
            </tbody>
          </nz-table>
          <ng-template #rangeTemplate let-range="range" let-total>
            <div style="font-size: 14px; color: #333; padding: 10px; text-align: right;" class="me-5">
               {{ range[0] }}-{{ range[1] }} of {{ total }} items
            </div>
          </ng-template>


          </div>
        </div>
      </div>
    </div>
  </div>
</nz-modal>
