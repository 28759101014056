<nz-layout>
  <nz-sider class="border fixed" nzCollapsible [nzCollapsedWidth]="10"
    [(nzCollapsed)]="isCollapsed" [nzWidth]="280"
    [nzTrigger]="null">
    <div class="row">
      <app-side-menu-bar [isAllJobOption]="true"
        (getSelectedJobId)="getSelectedJobId($event)"
        (toggleCollapse)="toggleCollapse()"></app-side-menu-bar>
    </div>
  </nz-sider>
  <nz-layout>
    <nz-page-header class="p-4">
      <nz-page-header-title>
        <nz-breadcrumb>
          <nz-breadcrumb-item>{{ localStorage.getJobName()
            }}</nz-breadcrumb-item>
        </nz-breadcrumb>
        <h1 nz-typography>
          To-Dos's
        </h1>
      </nz-page-header-title>

      <ng-template #topExtra>
        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul nz-menu class="border-solid" style="width:11rem">
            <li nz-menu-item class="p-2 border-bottom">
              <span class=" ms-3">
                Learn More
              </span>
            </li>
            <li nz-menu-item class="p-2 border-bottom "
              (click)="ToDodsSetting()">
              <span class=" ms-3">
                To-Do Settings
              </span>
            </li>
            <li nz-menu-item class="p-2 border-bottom">
              <span class=" ms-3">
                Export
              </span>
            </li>

            <li nz-menu-item class="p-2 border-bottom"
              (click)="ImportModelOpen()">
              <span class=" ms-3">
                Import
              </span>
            </li>
          </ul>
        </nz-dropdown-menu>
        <nz-page-header-extra>
          <button
            nz-button
            nzType="default"
            style="padding: 5px 10px 30px 10px;"
            class="ms-2 rounded-2"
            nz-dropdown
            [nzTrigger]="'click'"
            [nzDropdownMenu]="menu">
            <span style="font-size: 16px;"><i
                class="bi bi-three-dots"></i></span>
          </button>
          <nz-space>

            <button nz-button nzType="default"
              (click)="openFilter(PROJECT_MANG_Todos, PAGE_ID)" class="ms-2 ">
              <i class="bi bi-funnel"></i>&nbsp;&nbsp;Filter (1)
            </button>

            <button (click)="openDrawer('ToDos',null)" nz-button
              nzType="primary" class="ms-2 fs-6 button-Daily">
              <i class="bi bi-plus me-1"></i>
              To-Do
            </button>

          </nz-space>
          <app-project-management-site-diaries-related-to-do-st
            *ngIf="isDrawerVisible"
            [visible]="isDrawerVisible"
            (closeDrawer)="closeDrawer()"
            [selectedJobId]="selectedJobId"
            [selectedJobName]="selectedJobName"
            [selectedToDoId]="selectedToDoId"></app-project-management-site-diaries-related-to-do-st>
        </nz-page-header-extra>
      </ng-template>

      <nz-page-header-extra *ngIf="mySelection?.length > 0; else topExtra">
        <nz-space>
          <button nz-button nzType="default" class="ms-2 ">
            Print
          </button>
          <button nz-button nzType="default" class="ms-2 ">
            Mark Complete
          </button>
          <button nz-button nzType="default" class="ms-2 ">
            Assign To
          </button>
          <button (click)="copyToDos()" nz-button nzType="default"
            class="ms-2 ">
            Copy
          </button>
          <!-- <button (click)="showDeleteConfirm()" nz-button nzType="default" class="ms-2 ">
            Delete
          </button> -->
        </nz-space>
      </nz-page-header-extra>
    </nz-page-header>

    <nz-content class="custom-content ">
      <div class="container h-100" *ngIf="TodoGridData?.length === 0 || null">
        <div class="row">
          <app-todos-empty-content></app-todos-empty-content>
        </div>
      </div>

      <div class="container-fluid" *ngIf="TodoGridData?.length > 0">
        <div class="row">

          <kendo-grid *ngIf="TodoGridData?.length > 0"
            [selectable]="{ mode: 'multiple', checkboxOnly: true }"
            (columnResize)="saveColumnSizes($event)"
            [data]="TodoGridData" kendoGridSelectBy="id" width="100%"
            [pageSize]="5" [resizable]="true" [reorderable]="true"
            [sortable]="true" [style.maxHeight.px]="500"
            [(selectedKeys)]="mySelection">

            <kendo-grid-checkbox-column [headerClass]="{ 'text-center': true }"
              [width]="60"
              [class]="{ 'text-center': true }" [resizable]="false"
              [columnMenu]="false"
              [showSelectAll]="true"></kendo-grid-checkbox-column>

            <kendo-grid-column
              *ngFor="let column of displayedColumns; let i = index"
              [title]="column"
              [field]="column" [minResizableWidth]="250"
              [maxResizableWidth]="800" [width]="160"
              [sticky]="isColumnSticky[i]" [headerClass]="'pinned-header'"
              class="pinned-header" [sortable]="true">
              <ng-template kendoGridHeaderTemplate
                let-columnIndex="columnIndex">
                <div class="d-flex align-items-center">
                  <span class="fw-bold">{{ column }}</span>
                  <button (click)="toggleColumnSticky(columnIndex)"
                    class="btn btn-link">
                    <i class="bi" [ngClass]="{
                        'bi-pin-fill': isColumnSticky[columnIndex],
                        'bi-pin': !isColumnSticky[columnIndex]
                      }"></i>
                  </button>
                </div>
              </ng-template>
              <ng-template kendoGridColumnMenuTemplate
                let-columnIndex="columnIndex">
                <button (click)="toggleColumnSticky(columnIndex)"
                  class="btn btn-link">
                  <i class="bi" [ngClass]="{
                      'bi-pin-fill': isColumnSticky[columnIndex],
                      'bi-pin': !isColumnSticky[columnIndex]
                    }"></i>
                </button>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem
                let-rowIndex="rowIndex">
                <ng-container *ngIf="column === 'Title'">
                  <div class="ellipsis">
                    <a class="text-primary"
                      (click)="openDrawer('ToDos', dataItem.Id)">{{
                      dataItem.Title }}</a>
                  </div>
                </ng-container>

                <ng-container *ngIf="column === 'Complete'">
                  <div class="ellipsis">
                    <div class="round me-3 p-1">
                      <label nz-checkbox
                        class="custom-checkbox d-flex align-items-center"
                        [(ngModel)]="dataItem.IsCompleted"
                        (ngModelChange)="circleCheckBox(dataItem.GlobalId,$event)">
                      </label>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="column === 'Due'">
                  <div class="ellipsis">
                    <p nz-typography>{{
                      dataItem.DueDate | date: 'MMM d, yyyy'
                      | date : "MMM d, yyyy"
                      }}</p>
                  </div>
                </ng-container>
                <ng-container *ngIf="column === 'Asssigned To'">
                  <ng-container *ngIf="dataItem.AssigneUser">
                    <div class="ellipsis">
                      <ng-container
                        *ngFor="let user of dataItem.AssigneUser.split(','); let i = index">
                        <div *ngIf="i < 2 || showAllAssign" class="mb-1">
                          <a class="text-dark">{{ user }}</a>
                        </div>
                      </ng-container>

                      <a *ngIf="dataItem.AssigneUser.split(',').length > 2"
                        (click)="toggleAssign()"
                        nz-button nzType="text">
                        {{ showAllAssign ? 'Show Less' : 'Show More' }}
                      </a>
                    </div>
                  </ng-container>
                </ng-container>

                <ng-container *ngIf="column === 'CheckList'">
                  <div class="ellipsis text-center">
                    <ng-container
                      *ngIf="dataItem?.IsCheckList > 0; else emptyIcon">
                      <i class="bi bi-list-check fs-4 fw-dark"></i>
                    </ng-container>
                    <ng-template #emptyIcon>
                      <!-- Show an empty space when IsCheckList is null or empty -->
                      &nbsp;
                    </ng-template>
                  </div>
                </ng-container>

                <ng-container *ngIf="column === 'Comments'">
                  <div class="ellipsis text-center">
                    <a (click)="conversationModelOpen(dataItem)"
                      class="text-primary">{{dataItem.TotalComment}}</a>
                  </div>
                </ng-container>
                <ng-container *ngIf="column === 'Priority'">
                  <div class="ellipsis text-center">
                    <nz-tag
                      *ngIf="dataItem?.PrioritySetupName"
                      [ngClass]="'status-text ' + getPriorityStatus(dataItem?.PrioritySetupName)">
                      {{ dataItem?.PrioritySetupName }}
                    </nz-tag>
                  </div>

                </ng-container>

                <ng-container *ngIf="column === 'Realted Items'">
                  <div class="ellipsis">
                    <span class="RelatedItemTag"
                      *ngIf="dataItem.relatedItemsParsed?.length > 0">
                      <button nz-button nzType="text" nzTrigger="click"
                        nz-dropdown [nzDropdownMenu]="menu4">
                        <span class="fw-bold">Related Item</span>
                        <span nz-icon nzType="down"></span>
                      </button>
                      <nz-dropdown-menu #menu4="nzDropdownMenu">
                        <ul nz-menu>
                          <li nz-menu-item
                            *ngFor="let item of dataItem.relatedItemsParsed">
                            <span
                              *ngFor="let key of getKeys(item); let last = last">
                              <button nz-button nzType="link"
                                (click)="EditSiteDiariesDailyLogByRelatedItemsDropDown(item[key].id,key)">
                                <strong class="button-text text-primary">
                                  {{ key }}: {{ item[key].name }}<span
                                    *ngIf="!last">, </span>
                                </strong>
                              </button>
                            </span>
                          </li>
                        </ul>
                      </nz-dropdown-menu>
                    </span>
                  </div>
                </ng-container>

                <ng-container *ngIf="column === 'Files'">
                  <button
                    nz-button
                    nzType="link"
                    nz-dropdown
                    *ngIf="dataItem?.TotalAttachments > 0"
                    [nzDropdownMenu]="menu4"
                    nzTrigger="click"
                    class="attachment-button rounded-1"
                  >
                    <i class="fa-solid fa-paperclip mr-1"></i>
                    <span>{{ dataItem?.TotalAttachments }}</span>
                    <i class="fa-solid fa-angle-down ml-1"></i>
                  </button>

                  <nz-dropdown-menu #menu4="nzDropdownMenu">
                    <ul nz-menu>
                      <li nz-menu-item *ngFor="let attachment of dataItem.parsedAttachments">
                        <!-- Display the icon based on file type -->
                        <i [class]="getFileIcon(attachment.extension)" class="me-1"></i>
                        <span nz-typography>{{ attachment.name }}</span>
                      </li>
                    </ul>
                  </nz-dropdown-menu>
                </ng-container>



                <ng-container *ngIf="column === 'Created By'">
                  <div class="ellipsis">
                    <p nz-typography>{{
                      dataItem?.CreatedBy
                      }}</p>
                  </div>
                </ng-container>
                <ng-container *ngIf="column === 'Tags'">
                  <ng-container *ngIf="dataItem.TagNames">
                    <div class="tag-list">
                      <ng-container
                        *ngFor="let tag of dataItem.TagNames.split(','); let i = index">
                        <div *ngIf="i < 3 || showAllTags" class="mb-1">
                          <nz-tag class=" fw-medium">{{ tag }}</nz-tag>
                        </div>
                      </ng-container>

                      <a *ngIf="dataItem.TagNames.split(',').length > 3"
                        nz-typography (click)="toggleTags()">
                        {{ showAllTags ? 'ShowLess' : 'ShowMore' }}
                      </a>
                    </div>
                  </ng-container>

                </ng-container>

                <ng-container *ngIf="column === 'RFIs'">
                  <div class="ellipsis">
                    <a class="text-primary">{{
                      dataItem?.TotalRfi
                      }}</a>
                  </div>
                </ng-container>
                <ng-container *ngIf="column === 'Completed Date'">
                  <div class="ellipsis">
                    <p nz-typography>{{
                      dataItem?.CompletedOn
                      }}</p>
                  </div>
                </ng-container>
                <ng-container *ngIf="column === 'Notes'">
                  <div class="ellipsis">
                    <p nz-typography>{{
                      dataItem?.Notes
                      }}</p>
                  </div>
                </ng-container>
                <ng-container *ngIf="column === 'Reminder'">
                  <div class="ellipsis">
                    <p nz-typography>{{
                      dataItem?.ReminderSetupName
                      }}</p>
                  </div>
                </ng-container>
                <ng-container *ngIf="column === 'Created Date'">
                  <div class="ellipsis">
                    <a class="text-primary">{{dataItem?.CreatedOn }}</a>
                  </div>
                </ng-container>
              </ng-template>
            </kendo-grid-column>
          </kendo-grid>
          <!-- Todo Grid End -->
        </div>
      </div>

    </nz-content>

    <nz-footer class="custom-footer" *ngIf="TodoGridData?.length > 0">
      <div class="container-fluid">
        <div class="row">
          <div class="row mb-0 mt-2">
            <div class="col-10">
              <nz-pagination
                class="hide-buttons"
                style="float: right"
                [nzPageIndex]="currentPage"
                [nzTotal]="totalRecords"
                [nzPageSize]="pageSize"
                [nzShowSizeChanger]="true"
                [nzPageSizeOptions]="pageSizeOptions"
                (nzPageIndexChange)="onPageChange($event)"
                (nzPageSizeChange)="onPageSizeChange($event)"
                [nzShowTotal]="totalTemplate"
                [nzSimple]="false"></nz-pagination>
              <ng-template #totalTemplate let-total="total" let-range="range">
                {{ range[0] }}-{{ range[1] }} of {{ total }} items
              </ng-template>
              <nz-pagination
                *ngIf="!isAllDataShownTodoPagination()"
                style="float: right"
                [nzPageIndex]="currentPage"
                [nzTotal]="totalRecords"
                [nzPageSize]="pageSize"
                [nzShowSizeChanger]="true"
                [nzPageSizeOptions]="pageSizeOptions"
                (nzPageIndexChange)="onPageChange($event)"
                (nzPageSizeChange)="onPageSizeChange($event)"
                nzSimple></nz-pagination>

              <ng-container *ngIf="PAGE_ID">
                <div class="row d-flex justify-content-start mb-1">
                  <div class="col-md-3">
                    <div class="input-group">
                      <nz-select [(ngModel)]="selectedValueControl"
                        name="standardFilter"
                        (ngModelChange)="onFilterChange($event)" nzShowSearch>
                        <nz-option
                          *ngFor="let standard of getStandardViewFilterData"
                          [nzLabel]="standard.name + (standard.isSetAsDefault ? ' ' : '')"
                          [nzValue]="standard.id"
                          nzCustomContent>
                          <div class="option-content">
                            <!-- Customize content with icons and text -->
                            <span *ngIf="standard.icon" nz-icon
                              [nzType]="standard.icon"></span>
                            <span>{{ standard.name }}</span>
                            <span *ngIf="standard.isSetAsDefault"
                              class="status-text badge info margin-left-xss">Default</span>

                          </div>
                        </nz-option>
                      </nz-select>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <button nz-button nz-popover
                      class="text-primary text-dark fw-bold fs-5 border-0"
                      nz-popover
                      [nzPopoverContent]="contentTemplate"
                      nzPopoverPlacement="top" nzTheme="outline"
                      (click)="openStandardViewFilterComponent()">
                      <i nz-icon nzType="ellipsis" nzTheme="outline"></i>

                      <!-- <span
                        nz-popover
                        class="text-primary text-dark fw-bold fs-5"
                        nz-icon
                        nzType="ellipsis"
                        nzTheme="outline"s
                      ></span> -->
                    </button>
                  </div>
                  <div class="col-md-2">
                    <div class="mt-2" *ngIf="selectedFilter === 1">
                      <p>Your filter has unsaved changes.</p>
                    </div>
                    <div class="mt-2" *ngIf="selectedFilter === 0">
                      <p>Your filter has unsaved changes.</p>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <button (click)="openStandardViewFilterComponent('saveAs')"
                      type="button" nz-button nzType="default"
                      class="me-2" style="font-size: 12px"
                      *ngIf="selectedFilter === 1">
                      Save As
                    </button>

                    <button (click)="reInitReset()" nz-button nzType="default"
                      style="font-size: 12px"
                      *ngIf="selectedFilter === 1" type="button">
                      Reset
                    </button>

                    <button (click)="saveEditStandardView()" nz-button
                      nzType="primary" type="button" class="me-2"
                      style="font-size: 12px" *ngIf="selectedFilter === 0">
                      Save
                    </button>

                    <button (click)="openStandardViewFilterComponent('saveAs')"
                      nz-button nzType="default" class="me-2"
                      style="font-size: 12px" *ngIf="selectedFilter === 0"
                      type="button">
                      Save As
                    </button>

                    <button (click)="reInitReset()" nz-button nzType="default"
                      style="font-size: 12px"
                      *ngIf="selectedFilter === 0" type="button">
                      Reset
                    </button>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </nz-footer>

  </nz-layout>
</nz-layout>

<ng-template #contentTemplate>
  <div>
    <p>Manage Saved Views</p>
  </div>
</ng-template>
