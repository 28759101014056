<div class="Conatiner" *ngIf="filterResponseData && filterResponseData.length > 0">
  <form (ngSubmit)="onFilterApply()">
    <div class="row mt-2 mb-2">
      <div class="col-12">
        <div class="row gx-3">


          <div class="col-10 p-0">
            <div class="input-group">
              <nz-select [(ngModel)]="formFieldValues['standardFilter']" name="standardFilter"
                (ngModelChange)="onChangeStandardFilter($event)" nzShowSearch [nzAutoFocus]="true"
                nzPlaceHolder="Standard Filter" r>
                <nz-option *ngFor="let filter of filterResponseData"
                  [nzLabel]="filter.name + (filter.isSetAsDefault ? ' ' : '')" [nzValue]="filter.id" nzCustomContent>
                  <div class="option-content">
                    <!-- Customize content with icons and text -->
                    <span *ngIf="filter.icon" nz-icon [nzType]="filter.icon"></span>
                    <span>{{ filter.name }}</span>
                    <span *ngIf="filter.isSetAsDefault" class="status-text badge info margin-left-xss">Default</span>

                  </div>
                </nz-option>
              </nz-select>
            </div>
          </div>

          <div class="col-1 p-0 m-0">
            <span (click)="openStandardFilterComponent()" nz-popover [nzPopoverContent]="contentTemp"
              nzPlacement="'bottomRight'" class="text-primary text-dark fw-bold fs-5" nz-icon nzType="ellipsis"
              nzTheme="outline"></span>
            <ng-template #contentTemp>
              <p style="height: 20px">Manage Saved Filters</p>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
    <nz-divider class="p-0 m-0"></nz-divider>


    <div class="content">

      <div class="row mt-2" *ngIf="selectedFilter === 1">
        <div class="col">
          <p>Your filter has unsaved changes.</p>
        </div>
      </div>
      <div class="row mt-2" *ngIf="selectedFilter === 0">
        <div class="col">
          <p>Your filter has unsaved changes.</p>
        </div>
      </div>
      <div class="col d-flex mt-3">
        <button (click)="openStandardFilterComponent()" type="button" nz-button nzType="default" class="me-2"
          style="font-size: 12px;" *ngIf="selectedFilter === 1">Save As</button>
        <button (click)="reInitReset()" nz-button nzType="default" style="font-size: 12px;" *ngIf="selectedFilter === 1"
          type="button">Reset</button>
        <button (click)="editSaveFilterData()" nz-button nzType="primary" type="button" class="me-2"
          style="font-size: 12px;" *ngIf="selectedFilter === 0">Save</button>
        <button (click)="openStandardFilterComponent()" nz-button nzType="default" class="me-2" style="font-size: 12px;"
          *ngIf="selectedFilter === 0" type="button">Save As</button>
        <button (click)="reInitReset()" nz-button nzType="default" style="font-size: 12px;" *ngIf="selectedFilter === 0"
          type="button">Reset</button>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-12">
            <label class="sub-text" for="companyName">Keyword
              <i class="bi bi-info-circle-fill text-secondary" nz-popover [nzPopoverContent]="receiveextMessages"
                nzPopoverPlacement="top"></i>
              <ng-template #receiveextMessages>
                <div class="rounded-5" style="width: 250px; font-size: 13px;">
                  <div class="row">
                    <div class="col-12">
                      <p>Search within  <strong> Notes</strong> and <strong> Title</strong></p>
                    </div>
                  </div>
                </div>
              </ng-template>
            </label>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="row p-0 m-0">
              <div class="col-12 p-0 m-0">
                <input type="text" [(ngModel)]="formFieldValues['keyword']" name="keyword"
                  class="form-control formField" />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <label class="sub-text" for="companyName">Created By</label>
            <nz-tree-select style="width: 100%" [(ngModel)]="formFieldValues['createdBy']" name="createdBy"
              [nzNodes]="combinedNodes" [nzShowSearch]="true" nzCheckable nzPlaceHolder="Select CreatedBy"
              nzDropdownClassName="dropdownStyle" (ngModelChange)="onCombineChange($event)">
            </nz-tree-select>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <label class="sub-text" for="companyName">Dates</label>
          <nz-select nzShowSearch nzAllowClear [(ngModel)]="formFieldValues['date']" name="date">
            <nz-option *ngFor="let option of date" [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
          </nz-select>

          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <label class="sub-text" for="companyName">Tags</label>
            <!-- tags -->
            <nz-tree-select [(ngModel)]="formFieldValues['tags']" name="tags" style="width: 100%" [nzNodes]="TagsNodes"
              nzShowSearch nzAllowClear="false" nzCheckable (ngModelChange)="ontagsChange($event)"
              nzPlaceHolder="Select tags" nzDropdownClassName="dropdownStyle">
            </nz-tree-select>

          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12 p-0 text-end">
        <button nz-button nzType="default" type="button" (click)="resetFilter()">Clear All</button>
        <button class="ms-1" nz-button nzType="primary" type="submit" [disabled]="isFormDirty">Apply</button>
      </div>
    </div>
  </form>
</div>
