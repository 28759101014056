import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appDollarFormatter]'
})
export class DollarFormatterDirective {
  private maxDigits: number = 9;
  private decimalPlaces: number = 4;

  constructor(private el: ElementRef) {
    this.setInitialValue();
  }

  @HostListener('input', ['$event']) onInput(event: Event) {
    const input = this.el.nativeElement as HTMLInputElement;
    let value = input.value;
    let cursorPosition = input.selectionStart || 0;
    value = value.replace(/[^0-9.]/g, '');
    const [integerPart, decimalPart] = value.split('.');
    const formattedIntegerPart = integerPart ? integerPart.slice(0, this.maxDigits) : '';
    const formattedDecimalPart = (decimalPart || '').slice(0, this.decimalPlaces);
    value = formattedIntegerPart;
    if (formattedDecimalPart) {
      value += '.' + formattedDecimalPart;
    }
    const oldLength = input.value.length;
    input.value = this.formatNumber(value);
    const newLength = input.value.length;
    cursorPosition += newLength - oldLength;
    this.adjustCursorPosition(input, cursorPosition);
    if (input.value === '0.00') {
      input.value = '';
    }
  }

  @HostListener('blur', ['$event']) onBlur(event: Event) {
    const input = this.el.nativeElement as HTMLInputElement;
    if (input.value === '' || input.value === '0.00') {
      input.value = '0.00';
    } else {
      input.value = this.formatNumber(input.value);
    }
  }

  @HostListener('focus', ['$event']) onFocus(event: Event) {
    const input = this.el.nativeElement as HTMLInputElement;
    if (input.value !== '') {
      input.select();
    }
  }

  private setInitialValue() {
    const input = this.el.nativeElement as HTMLInputElement;
    input.value = '0.00';
  }

  private formatNumber(value: string): string {
    let [integerPart, decimalPart] = value.split('.');
    if (!integerPart) {
      integerPart = '0';
    }
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    if (!decimalPart) {
      decimalPart = '';
    }
    decimalPart = decimalPart.padEnd(this.decimalPlaces, '0');
    return integerPart + '.' + decimalPart;
  }

  private adjustCursorPosition(input: HTMLInputElement, cursorPosition: number) {
    setTimeout(() => {
      const value = input.value;
      const decimalIndex = value.indexOf('.');
      if (decimalIndex !== -1 && cursorPosition > decimalIndex) {
        cursorPosition = decimalIndex;
      }
      input.selectionStart = cursorPosition;
      input.selectionEnd = cursorPosition;
    }, 0);
  }
}
