import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DailyLogDashboard, DailyLogInformation, DailyLogRelatedItemsResult, DailyLogResult, DailyLogSetup, DailyLogSetupResponse } from 'src/Models/Project-Management/projectManagement';
import { BehaviorSubject, Observable, catchError, throwError } from 'rxjs';
import { ResponseModel, TablePageResponse } from 'src/Models/responseMessage.model';
import { environment } from 'src/environments/environment.prod';
import { CreateLookupFilterRequest, FilterSearchParameter } from 'src/app/shared/component/Models/LookupModels';

@Injectable({
  providedIn: 'root'
})
export class SiteDiariesService {

  private PROJECT_MANAGEMENT_SITE_DIARIES_URL: string = environment.apiUrl + 'DailyLogInformation/';
  private PROJECT_MANAGMENT_SITE_DIARIES_DailyLog_URL: string = environment.apiUrl + 'DailyLogSetup/';
  private PROJECT_MANAGMENT_SITE_DIARIES_DailyLog_FETCH: string = environment.apiUrl + 'DailyLogSetup/getData-ByCompany/';

  private base_Url: string = environment.apiUrl + 'LookupFilter/';
  private PROJECT_MANAGEMENT_RELATED_SITE_DIARIES_URL: string = environment.apiUrl + 'DailyLog/';


  selectedFilterId = new BehaviorSubject<number>(0);
  $selectedFilterId = this.selectedFilterId.asObservable();

  isUserAppliedFilter = new BehaviorSubject<boolean>(false);
  $isUserAppliedFilter = this.isUserAppliedFilter.asObservable();


  getSiteDiariesData = new BehaviorSubject<ResponseModel<TablePageResponse<DailyLogDashboard>>>(null);
  $getSitedata = this.getSiteDiariesData.asObservable();

  constructor(private http: HttpClient) { }

  postDailyLog(siteDiariesFormData: DailyLogInformation): Observable<ResponseModel<DailyLogResult>> {
    const apiUrl = `${this.PROJECT_MANAGEMENT_SITE_DIARIES_URL}`;
    return this.http.post<any>(apiUrl, siteDiariesFormData).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }

  relatedPostDailyLog(siteDiariesFormData: DailyLogInformation): Observable<ResponseModel<DailyLogResult>> {
    const apiUrl = `${this.PROJECT_MANAGEMENT_RELATED_SITE_DIARIES_URL}`;
    return this.http.post<any>(apiUrl, siteDiariesFormData).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }



  deleteDailyLog(globalId: string): Observable<ResponseModel<any>> {
    return this.http.delete<ResponseModel<any>>(`${this.PROJECT_MANAGEMENT_SITE_DIARIES_URL}?globalId=${globalId}`);
  }



  postDailyLogData(DailyLogFormData: DailyLogSetup): Observable<ResponseModel<DailyLogSetupResponse>> {
    const apiUrl = `${this.PROJECT_MANAGMENT_SITE_DIARIES_DailyLog_URL}`;
    return this.http.post<any>(apiUrl, DailyLogFormData).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }


  getDailyLogDataByCompany(): Observable<ResponseModel<DailyLogSetupResponse>> {
    return this.http.get<ResponseModel<DailyLogSetupResponse>>(this.PROJECT_MANAGMENT_SITE_DIARIES_DailyLog_FETCH);
  }
  getDailydataAppliedFilterData(appliedFilter: CreateLookupFilterRequest[]) {
    const apiUrl = `${this.base_Url}GetAll-ByLookupFilter`;
    return this.http.post<any>(apiUrl, appliedFilter).subscribe(
      (data) => {
        this.getSiteDiariesData.next(data);
      },
      (error) => {
        console.error('Error fetching internal users:', error);
      }
    );
  }



  getAllSiteDiariesByFilter(filterSearchParameter: FilterSearchParameter) {
    const apiUrl = `${this.base_Url}GetAll-ByFilter`;
    return this.http.post<any>(apiUrl, filterSearchParameter).subscribe(
      (data) => {
        this.getSiteDiariesData.next(data);
      },
      (error) => {
        console.error('Error fetching internal users:', error);
      }
    );
  }


  setSelectedFilterId(filterId: number) {
    this.selectedFilterId.next(filterId);
  }

  userAppliedFilter(filter: boolean) {
    this.isUserAppliedFilter.next(filter);
  }



  getByIdDailyLog(id: number): Observable<ResponseModel<DailyLogResult>> {
    return this.http.get<ResponseModel<DailyLogResult>>(`${this.PROJECT_MANAGEMENT_SITE_DIARIES_URL}getBy-Id?Id=${id}`);
  }



  // getAllWarrantyByFilter(filterSearchParameter: FilterSearchParameter) {
  //   const apiUrl = `${this.Url_LookUpFilter}GetAll-ByFilter`;
  //   return this.http.post<any>(apiUrl, filterSearchParameter).subscribe(
  //     (data) => {
  //       this.getWarrantyData.next(data);
  //     },
  //     (error) => {
  //       console.error('Error fetching internal users:', error);
  //     }
  //   );
  // }


  // const apiUrl = `${this.base_Url}GetAll-ByLookupFilter`;
  // return this.http.post<any>(apiUrl, appliedFilter).subscribe(
  //   (data) => {
  //     this.getSiteDiariesData.next(data);
  //   },
  //   (error) => {
  //     console.error('Error fetching internal users:', error);
  //   }
  // );

  // getLookUpNameSetup(lookupNameSetup: LookupNameSetup): Observable<ResponseModelArray<LookupNameSetupResponse>> {
  //   const apiUrl = this.base_Url;
  //   return this.http.post<ResponseModelArray<LookupNameSetupResponse>>(apiUrl, lookupNameSetup).pipe(
  //     catchError((error: any) => {
  //       console.error('Error:', error);
  //       return throwError(error);
  //     })
  //   );
  // }


  private PM_TODO_RELATED_DAILY_LOG_URL: string = `${environment.apiUrl}ToDo`;


  dailyLogRelatedTodo(id: number): Observable<ResponseModel<DailyLogRelatedItemsResult[]>> {
    const url = `${this.PM_TODO_RELATED_DAILY_LOG_URL}/getBy-TodoParameterId?id=${id}`;
    return this.http.get<ResponseModel<DailyLogRelatedItemsResult[]>>(url);
  }

}
