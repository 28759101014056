import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { NzSelectSizeType } from 'ng-zorro-antd/select';
import { Subscription } from 'rxjs';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { CustomField } from 'src/Models/CompanySettings/Selections/CustomFieldId';
import { SelectionSetup } from 'src/Models/CompanySettings/Selections/SelectionSetup';
import { CustomFieldService } from 'src/Service/CompanyInFormation/Selections/custom-field.service';
import { DataTypeService } from 'src/Service/CompanyInFormation/Selections/data-type.service';
import { FileNameSetupService } from 'src/Service/CompanyInFormation/Selections/file-name-setup.service';
import { RequireSignatureOnSelectionSetupService } from 'src/Service/CompanyInFormation/Selections/require-signature-on-selection-setup.service';
import { SelectionBuilderViewService } from 'src/Service/CompanyInFormation/Selections/selection-builder-view.service';
import { SelectionOwnerViewService } from 'src/Service/CompanyInFormation/Selections/selection-owner-view.service';
import { SelectionsSScheduleSetupModalService } from 'src/Service/CompanyInFormation/Selections/selections-sschedule-setup-modal.service';
import { SelectionsService } from 'src/Service/CompanyInFormation/Selections/selections.service';
import { SubVendorService } from 'src/Service/CompanyInFormation/Selections/sub-vendor.service';
import { ApplicationUserService } from 'src/Service/Job-List/Job-Information/application-user.service';
import { ModalBillsPosBudgetService } from 'src/Service/CompanyInFormation/Selections/modal-bills-pos-budget.service';
import { ApiResponseModel } from 'src/Models/apiResponse.model';
// import { FormBuilder, FormGroup } from '@angular/forms';
// import { Subscription } from 'rxjs';
// import { SelectionSetup } from 'src/Models/CompanySettings/Selections/SelectionSetup';
// import { ApiResponseModel } from 'src/Models/apiResponse.model';
// import { ModalBillsPosBudgetService } from 'src/Service/CompanyInFormation/Selections/modal-bills-pos-budget.service';
// import { RequireSignatureOnSelectionSetupService } from 'src/Service/CompanyInFormation/Selections/require-signature-on-selection-setup.service';
// import { SelectionBuilderViewService } from 'src/Service/CompanyInFormation/Selections/selection-builder-view.service';
// import { SelectionOwnerViewService } from 'src/Service/CompanyInFormation/Selections/selection-owner-view.service';
// import { SelectionsService } from 'src/Service/CompanyInFormation/Selections/selections.service';

@Component({
  selector: 'app-budget',
  templateUrl: './budget.component.html',
  styleUrls: ['./budget.component.css']
})
export class BudgetComponent implements OnInit{
  listOfOption: Array<{ label: string; value: string }> = [];
  size: NzSelectSizeType = 'default';
  singleValue = 'a10';
  multipleValue = [];
  tagValue = ['a10', 'c12', 'tag'];
  date = null;
  checked = false;
  checked1 = false;
  checked2 = false;
  checked3 = false;
  checked4 = false;
  checked5 = false;
  checked6 = false;
  checked7 = false;
  checked8 = false;
  checked9 = false;
  checked10 = false;
  checked11 = false;
  checked12 = false;
  checked13 = false;
  checked14 = false;
  checked15 = false;
  checked16 = false;
  checked17 = false;
  text: string | undefined;
  demoValue = 0;
  customfieldinfo:boolean = false;
  private subscription: Subscription;
  schedule: boolean = false;
  selectedValue :any;
  // <------scheduleSetup------->
  selectionSetupForm: FormGroup;
  // <------scheduleSetup------->
  CustomFieldSetupForm: FormGroup;
  CustomField: CustomField[] = [];
  Selectionsetup: SelectionSetup[] = [];
  form: FormGroup;
id: number;
data: any;
  constructor(private SelectionsScheduleSetupModalService: ModalBillsPosBudgetService,private fb:FormBuilder,
    private RequireSignatureOnSelectionSetupservice : RequireSignatureOnSelectionSetupService,
    private SelectionBuilderViewservice : SelectionBuilderViewService,
    private SelectionOwnerViewservice : SelectionOwnerViewService,
    private Selectionsservice : SelectionsService,
    private DataTypeservice : DataTypeService,
    private applicuserservices : ApplicationUserService,
    private SubVendorservices : SubVendorService,
    private FileNameSetupservices : FileNameSetupService,
    private CustomFieldservices : CustomFieldService    ){
    this.subscription = this.SelectionsScheduleSetupModalService.showModal$.subscribe(() => {
      this.openformselectionSetup(this.id);
    });
    // this.getSelectionBuilder();
    // this.getSelectionOwner();
    // this.getRequireSignatureOnSelectionSetup();
    // this.getDataType();
    // this.getapplicationUser();
    // this.getSubVendor();
    // this.getFileNameSetup();
    // this.getselectionSetup();
  }
  ngOnInit(): void {
    this.newItemFormControl = this.fb.control('');
    this.initForm();
    const children: Array<{ label: string; value: string }> = [];
    for (let i = 10; i < 36; i++) {
      children.push({ label: i.toString(36) + i, value: i.toString(36) + i });
    }
    this.listOfOption = children;
  }
  // <---------FormControlName--------->
  private initForm(): void {
      this.selectionSetupForm = this.fb.group({
        companyNameId: [1],
        selectionBuilderViewId: [''],
        selectionOwnerViewId: [''],
        defaultToExpandCategoryLocationView: [''],
        alertUserNumber: [''],
        allowancesOnNewJob: [''],
        showAllowancesToSubsVendors: [''],
        showVendorContactInfoToOwner: [''],
        showLineItemsToOwner: [''],
        defaultToAllowOwnerToPeekAtLockedSelectionsForAllNewJobs: [''],
        onNewJobsUpdateJobRunningTotalWhenASelectionIsApproved: [''],
        requireSignatureOnSelectionSetupId: [''],
        selectionsApprovalDisclaimer: [''],
        approvedChoicesOnly: [''],
        pricing: [''],
        subsVendors: [''],
        addedBy: [''],
        installers: [''],
        choiceDescription: [''],
        selectionDescription: [''],
        lineItems: [''],
        discussions: [''],
        customFields: [''],
      });
  }
  // <---------Selections------------->
  // saveSelections() {
  //   if (this.selectionSetupForm && this.selectionSetupForm.valid) {
  //     const formData: SelectionSetup = this.selectionSetupForm.value;
  //     this.Selectionsservice.postData(formData).subscribe(
  //       (response) => {
  //         // console.log('Data saved successfully 124:', response);
  //         alert('Data saved successfully')
  //         // this.addphase = false;
  //         this.selectionSetupForm.reset();
  //       },
  //       (error) => {
  //         console.error('Error saving data:', error);
  //         alert('Data not saved');
  //       }
  //     );
  //   } else {
  //     console.log('Form is invalid or not initialized. Please check.');
  //   }
  // }

  // <--------Get By Id----------------->
  selectionSetup: SelectionSetup[] = [];

selectionSetupId: any;
// openformselectionSetup(id: number): void {
// this.selections = true;

//   this.getDataById(id);
// }
// jobupdates: boolean = false;
  dataToUpdate: any;
openformselectionSetup(customerId: number) {
  this.selections = true;

  this.Selectionsservice.getDataId(customerId).subscribe(
    (data: ApiResponseModel) => {
      console.log('Data by UpdateLead ID:', data.result);
      this.dataToUpdate = data.result; // Pass the data to the child component
    },
    (error) => {
      console.error('Error fetching data by ID:', error);
    }
  );
}
// getselectionSetup() {
//   this.Selectionsservice.getData().subscribe(
//     (data: any) => {
//       // console.log('Data received Selection Setup Get By Id hivgivgrugvrwgbuvrguhivgivgrugvrwgbuvrguhivgivgrugvrwgbuvrguhivgivgrugvrwgbuvrguhivgivgrugvrwgbuvrguhivgivgrugvrwgbuvrguhivgivgrugvrwgbuvrgu:', data);
//       this.selectionSetup = data.result; // Assuming that the user data is in the 'result' property
//     },
//     (error) => {
//       console.error('Error fetching data:', error);
//     }
//   );
// }
isUpdateform: boolean = false;

SelectionSetupgetDataById(id: number): void {
  this.Selectionsservice.getDataId(id).subscribe((data: any) => {
    console.log('getDataById',data.result);

    // Log the retrieved data to the console
    this.isUpdateform = !!data;
    this.selectionSetupPatchFormValues(data.result); // Assuming the response structure matches the form structure
    this.selectionSetupId = data;
  });
}
selectionSetupPatchFormValues(data: any) {
  this.selectionSetupForm.patchValue({
    globalId: data.globalId,
    companyNameId: data.companyNameId,
    selectionBuilderViewId: data.selectionBuilderViewId,
    selectionOwnerViewId: data.selectionOwnerViewId,
    defaultToExpandCategoryLocationView: data.defaultToExpandCategoryLocationView,
    alertUserNumber: data.alertUserNumber,
    showAllowancesToSubsVendors: data.showAllowancesToSubsVendors,
    showVendorContactInfoToOwner: data.showVendorContactInfoToOwner,
    showLineItemsToOwner: data.showLineItemsToOwner,
    defaultToAllowOwnerToPeekAtLockedSelectionsForAllNewJobs: data.defaultToAllowOwnerToPeekAtLockedSelectionsForAllNewJobs,
    onNewJobsUpdateJobRunningTotalWhenASelectionIsApproved: data.onNewJobsUpdateJobRunningTotalWhenASelectionIsApproved,
    requireSignatureOnSelectionSetupId: data.requireSignatureOnSelectionSetupId,
    selectionsApprovalDisclaimer: data.selectionsApprovalDisclaimer,
    approvedChoicesOnly: data.approvedChoicesOnly,
    pricing: data.pricing,
    subsVendors: data.subsVendors,
    addedBy: data.addedBy,
    installers: data.installers,
    choiceDescription: data.choiceDescription,
    selectionDescription: data.selectionDescription,
    lineItems: data.lineItems,
    discussions: data.discussions,
    customFields: data.customFields,
  });
}
  // addNewPhaseFormRef: any;
  // isUpdateForm: boolean = false;
  // getid: any;
  // openform(id: number): void {
  // this.selections = true;

  //   this.getDataById(id);
  // }

  // getDataById(id: number): void {
  //   this.Selectionsservice.getDataId(id).subscribe((data: any) => {
  //     console.log('getDataById',data.result);

  //     // Log the retrieved data to the console
  //     this.isUpdateForm = !!data;
  //     this.patchFormValues(data.result); // Assuming the response structure matches the form structure
  //     this.getid = data;
  //   });
  // }
  // patchFormValues(data: any) {
  //   this.selectionSetupForm.patchValue({
  //     globalId: data.globalId,
  //     name: data.name, // Replace 'name' with the actual property in your data
  //     displayOrder: data.displayOrder, // Replace 'displayOrder' with the actual property in your data
  //     colorId: data.colorId, // Replace 'colorId' with the actual property in your data
  //   });
  // }

  // UpdatePhase() {
  //   const formData = this.selectionSetupForm.value;
  //   this.Selectionsservice.updateData(formData).subscribe(
  //     (response) => {
  //       console.log('User updated successfully:', response);
  //       console.log('from data update time :', formData);
  //       alert('User updated successfully');
  //       // this.getPhase();
  //       this.closeSelections();
  //       this.selectionSetupForm.reset();
  //     },
  //     (error) => {
  //       console.error('Error updating user:', error);
  //     }
  //   );
  // }
  // <------SelectionBuilder------->
  SelectionBuilder: { id: number, name: string }[] = [];
  getSelectionBuilder() {
  this.SelectionBuilderViewservice.getData().subscribe(
    (res: any) => {
      // console.log('SelectionBuilder type data', res);
      this.SelectionBuilder = res.result.SelectionBuilder || [];
      this.SelectionBuilder = res.result.map((SelectionBuilder: any) => ({ id: SelectionBuilder.id, name: SelectionBuilder.name }));
    },
    (error) => {
      console.error('Error fetching SelectionBuilder:', error);
    }
  );
}
  // <------SelectionOwner------->
  SelectionOwner: { id: number, name: string }[] = [];
  getSelectionOwner() {
  this.SelectionOwnerViewservice.getData().subscribe(
    (res: any) => {
      // console.log('SelectionOwner type data', res);

      // Assuming res.result.Builder contains the array of Builder from the API response
      this.SelectionOwner = res.result.SelectionOwner || [];

      // Map both id and name properties
      this.SelectionOwner = res.result.map((SelectionOwner: any) => ({ id: SelectionOwner.id, name: SelectionOwner.name }));

      // Set the selectedValue to '--Choose Builder--' initially
    },
    (error) => {
      console.error('Error fetching SelectionOwner:', error);
    }
  );
}
  // <------RequireSignatureOnSelectionSetup------->
  RequireSignatureOnSelectionSetup: { id: number, name: string }[] = [];
  getRequireSignatureOnSelectionSetup() {
  this.RequireSignatureOnSelectionSetupservice.getData().subscribe(
    (res: any) => {
      // console.log('RequireSignatureOnSelectionSetup type data', res);

      // Assuming res.result.Builder contains the array of Builder from the API response
      this.RequireSignatureOnSelectionSetup = res.result.RequireSignatureOnSelectionSetup || [];

      // Map both id and name properties
      this.RequireSignatureOnSelectionSetup = res.result.map((RequireSignatureOnSelectionSetup: any) => ({ id: RequireSignatureOnSelectionSetup.id, name: RequireSignatureOnSelectionSetup.name }));

      // Set the selectedValue to '--Choose Builder--' initially
    },
    (error) => {
      console.error('Error fetching RequireSignatureOnSelectionSetup:', error);
    }
  );
}
// <------DataType------->
DataType: { id: number, name: string }[] = [];
getDataType() {
  this.DataTypeservice.getData().subscribe(
      (res: any) => {
          // console.log('Data Type:', res.result);
          this.DataType = res.result.map((dataType: any) => ({ id: dataType.id, name: dataType.name }));
              if (this.DataType && this.DataType.length > 0) {
                this.selectedDataType = this.DataType[0].id;
              }
      },
      (error) => {
          console.error('Error fetching DataType:', error);
      }
  );
}
User: {  id: number,firstName: string , middleName : string, lastName:string }[] = [];
// <------appuser------->
getapplicationUser() {
  this.applicuserservices.getData().subscribe(
    (res: any) => {
      // console.log('applicuserservicesetetewt role type data', res);
      this.User = res.result.User || [];
      this.User = res.result.map((User: any) => ({ id: User.id, firstName: User.firstName, middleName: User.middleName, lastName: User.lastName }));
    },
    (error) => {
      console.error('applicuserservices Error fetching roles:', error);
    }
  );
}
SubVendor: {  id: number,companyName: string }[] = [];
// <------appuser------->
getSubVendor() {
  this.SubVendorservices.getData().subscribe(
    (res: any) => {
      // console.log('applicuserservices role type data', res);
      this.SubVendor = res.result.SubVendor || [];
      this.SubVendor = res.result.map((SubVendor: any) => ({ id: SubVendor.id, companyName: SubVendor.companyName }));
    },
    (error) => {
      console.error('applicuserservices Error fetching roles:', error);
    }
  );
}
  // <------FileNameSetup------->
  FileNameSetup: { id: number, name: string }[] = [];
  getFileNameSetup() {
  this.FileNameSetupservices.getData().subscribe(
    (res: any) => {
      // console.log('SelectionOwner type data', res);

      // Assuming res.result.Builder contains the array of Builder from the API response
      this.FileNameSetup = res.result.FileNameSetup || [];

      // Map both id and name properties
      this.FileNameSetup = res.result.map((FileNameSetup: any) => ({ id: FileNameSetup.id, name: FileNameSetup.name }));

      // Set the selectedValue to '--Choose Builder--' initially
    },
    (error) => {
      console.error('Error fetching FileNameSetup:', error);
    }
  );
}
listOfData = [
  {
    key: '1',
    name: 'John Brown',
    age: 32,
    address: 'New York No. 1 Lake Park'
  }
];
drop(event: CdkDragDrop<string[]>): void {
  moveItemInArray(this.listOfData, event.previousIndex, event.currentIndex);
}
selectedDataType: number | null = null;
showSingleLineTextCard: boolean = false;
showMultiLineTextwithExpandableTextboxCard: boolean = false;
showCheckboxCard: boolean = false;
showWholeNumberCard: boolean = false;
showListofUsersCard: boolean = false;
showListofSubsVendorsCard: boolean = false;
showDateCard: boolean = false;
showCurrencyCard: boolean = false;
showFileCard: boolean = false;
showDropdownListCard: boolean = false;
showDropdownMListCard: boolean = false;
showLinkCard: boolean = false;
onDataTypeChange() {
  this.showSingleLineTextCard = false;
  this.showMultiLineTextwithExpandableTextboxCard = false;
  this.showCheckboxCard = false;
  this.showWholeNumberCard = false;
  this.showListofUsersCard = false;
  this.showListofSubsVendorsCard = false;
  this.showDateCard = false;
  this.showFileCard = false;
  this.showDropdownListCard = false;
  this.showDropdownMListCard = false;
  this.showLinkCard = false;
  // Check karen ke selected data type kya hai aur us ke mutabiq card visibility flag set karen
  switch (this.selectedDataType) {
    case 2:
      this.showSingleLineTextCard = true;
      break;
    case 3:
      this.showMultiLineTextwithExpandableTextboxCard = true;
      break;
    case 4:
      this.showCheckboxCard = true;
      break;
    case 5:
      this.showWholeNumberCard = true;
      break;
    case 6:
      this.showListofUsersCard = true;
      break;
    case 7:
      this.showListofSubsVendorsCard = true;
      break;
    case 8:
      this.showDateCard = true;
      break;
    case 9:
      this.showCurrencyCard = true;
      break;
    case 10:
      this.showFileCard = true;
      break;
    case 11:
      this.showDropdownListCard = true;
      break;
    case 12:
      this.showDropdownMListCard = true;
      break;
    case 13:
      this.showLinkCard = true;
      break;
  }
}
autoAdjustTextAreaHeight(event: Event): void {
  const textarea = event.target as HTMLTextAreaElement;
  textarea.style.height = '54'; // Reset height to auto to calculate the actual height needed
  textarea.style.height = `${Math.min(textarea.scrollHeight, 200)}px`; // Set the height with a maximum of 200px
}
selections: boolean = false;
// Selections(){
//   this.selections = true;
// }
closeSelections(){
  this.selections = false;
}
Schedule(){
  this.schedule = true;
}
closeSchedule(){
  this.schedule = false;
}
CustomFieldInfo(){
  this.customfieldinfo = true;
}
handleCancel2(): void {
  this.customfieldinfo = false;
}
isInputVisible: boolean = false;
toggleVisibility() {
  this.isInputVisible = true;
}
newItemFormControl: FormControl;
newItemText: string = '';
items: { text: string; editMode: boolean }[] = [];
dropdownItems: string[] = [];
addItem() {
  const newItemText = this.newItemFormControl.value;

  if (newItemText.trim() !== '') {
    this.items.push({ text: newItemText, editMode: false });
    this.dropdownItems = [...this.dropdownItems, newItemText];
    this.newItemFormControl.setValue('');
  }
}
isEditingMode = false;
isEditing: boolean = false;
editedIndex: number = -1;
isNewItemVisible: boolean = true;
isEditItemVisible: boolean = false;
editedText: string = '';
enterEditMode(index: number, item: { text: string; editMode: boolean }): void {
  this.isNewItemVisible = false;
  this.isEditItemVisible = true;
  this.editedIndex = index;
  this.editedText = item.text;
  this.isEditingMode = true;

  // Set the editedText in the input field
  this.newItemFormControl.setValue(this.editedText);
}
closeEdit() {
  this.isEditItemVisible = false;
  this.editedIndex = -1;
  this.isNewItemVisible = true;
}
displayText() {
  if (this.editedIndex !== -1) {
    this.items[this.editedIndex].text = this.editedText;
    this.closeEdit();
  }
}
editItem(item: { text: string; editMode: boolean }) {
  // Set editMode to true for the selected item
  item.editMode = true;
}
deleteItem(index: number): void {
  const deletedItem = this.items[index];

  // Remove from the items array
  this.items.splice(index, 1);

  // Check if the deleted item's text is not the default value
  if (deletedItem.text !== 'No Default') {
    // Remove from the dropdownItems array only if it's present
    const dropdownIndex = this.dropdownItems.indexOf(deletedItem.text);
    if (dropdownIndex !== -1) {
      this.dropdownItems.splice(dropdownIndex, 1);
    }
  }
}

}
