<nz-modal nzWidth="47%" [(nzVisible)]="VisibleInCostCategorySetup" [nzTitle]="modalTitleInCostCategorySetup"
    [nzFooter]="modalFooterInCostCategorySetup" (nzOnCancel)="closeModalInCostCategorySetup()"
    [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '75vh', 'overflow-y': 'auto', 'overflow-x': 'hidden'}"
    [nzStyle]="{ top: '30px' }">
    <ng-template #modalTitleInCostCategorySetup>
        <div class="row p-0 m-0">
            <h2 class="p-0 m-0 famly-inn ant-typography-Modal">Cost category</h2>
        </div>
    </ng-template>
    <form nz-form [formGroup]="costCategoryFormField">
        <div class="content" *nzModalContent>
            <div class="card border-0 mt-0">
                <div class="card-body">
                    <div class="row gx-2">
                        <div class="col-6">
                            <label class="sub-text">Title<span class="ms-0 text-danger fw-bold">*</span></label>
                            <input nz-input formControlName="title" name="title" type="text" class="formField" />
                        </div>
                        <div class="col-6">
                            <label class="sub-text">Category</label>
                            <nz-select nzShowSearch formControlName="costCodeId" name="costCodeId">
                                <nz-option [nzLabel]="'-- None --'" [nzValue]="0"></nz-option>
                                <nz-option *ngFor="let option of costCategory" [nzLabel]="option.title"
                                    [nzValue]="option.id"></nz-option>
                            </nz-select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <label class="sub-text">Details</label>
                            <textarea formControlName="detail" name="detail" nz-input
                                [nzAutosize]="{ minRows: 2, maxRows: 10 }" class="formField"
                                style="resize: none;"></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ng-template #modalFooterInCostCategorySetup>
            <div class="mt-2 mb-2 align-items-center">
              <nz-button-group>
                <button nzType="primary" dizable nz-button (click)="saveCostCategoryAndClose()">Save & close</button>
                <button nzTrigger="click" nzType="primary" nz-button nz-dropdown [nzDropdownMenu]="menu1" nzPlacement="bottomRight">
                  <span nz-icon nzType="down" nzTheme="outline" style="color: white;"></span>
                </button>
                <nz-dropdown-menu #menu1="nzDropdownMenu">
                  <ul nz-menu>
                    <li nz-menu-item (click)="saveCostCategory()">Save</li>
                    <li nz-menu-item (click)="saveCostCategoryAndNew()">Save & New</li>
                  </ul>
                </nz-dropdown-menu>
              </nz-button-group>
            </div>
            </ng-template>
    </form>
</nz-modal>