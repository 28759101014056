<nz-modal nzWidth="92%" [(nzVisible)]="VisibleInChangeOrderSetup" [nzTitle]="modalTitleInChangeOrderSetup" [nzFooter]="modalFooterInChangeOrderSetup"
    (nzOnCancel)="closeModalInChangeOrderSetup()"
    [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '73vh', 'overflow-y': 'auto', 'overflow-x': 'hidden'}"
    [nzStyle]="{ top: '18px' }">
    <ng-template #modalTitleInChangeOrderSetup>
        <div class="row p-0 m-0">
            <h2 class="p-0 m-0 famly-inn">Change Orders
            </h2>
        </div>
    </ng-template>
    <form nz-form [formGroup]="changeOrderFormFields">
        <div class="content" *nzModalContent>
            <div class="row famly-inn gx-3">
                <div class="col-12">
                    <div class="card border-0 mt-0">
                        <div class="card-head border-bottom">
                            <div class="row pt-2 pb-2">
                                <h3 class="ms-3 famly-inn">General Options</h3>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12">
                                    <div class="row">
                                        <label class="sub-text"> Change Order Prefix

                                            <span class="text-danger fw-bolder">*</span>
                                        </label>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-1">
                                            <input formControlName="changeOrderPrefix" name="changeOrderPrefix"
                                                type="text" class="form-control formField" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card border-0 mt-3">
                    <div class="card-head border-bottom">
                        <div class="row mb-0 p-2 d-flex align-items-center">
                            <div class="col">
                                <span class="fw-medium fs-6 ms-2">Summary Screen Settings</span>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <div class="row">
                                    <label class="sub-text"># of Days</label>
                                </div>
                                <div class="row gx-2">
                                    <div class="col-md-1">
                                        <nz-input-number formControlName="noOfDays" name="noOfDays"
                                            style="width: 100px; border-radius: 4px;"></nz-input-number>
                                    </div>
                                    <div class="col-1">
                                        <i class="bi bi-info-circle-fill text-secondary ms-3" nz-popover
                                            [nzPopoverContent]="Code" nzPopoverPlacement="top"></i>
                                        <ng-template #Code>
                                            <div class="rounded-2">
                                                <p style="height: 30px; width: 210px;font-size: 13px;">
                                                    Recency of the change orders that show up on the summary screen.
                                                </p>
                                            </div>
                                        </ng-template>
                                    </div>
                                </div>
                                <div class="row">
                                    <label class="sub-text">Max Items

                                    </label>
                                </div>
                                <div class="row">
                                    <div class="col-md-1">
                                        <nz-input-number formControlName="maxItem" name="maxItem"
                                            style="width: 100px; border-radius: 4px;" [nzMin]="1" [nzMax]="10"
                                            [nzStep]="1"></nz-input-number>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card border-0 mt-3">
                    <div class="card-head border-bottom">
                        <div class="row mb-0 p-2 d-flex align-items-center">
                            <div class="col">
                                <span class="fw-medium fs-6 ms-2">Notification Defaults</span>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <div class="row mt-1">
                                    <div class="col-9 d-flex align-items-center">
                                        <label for="exampleCheck2" class="pira-label ms-0 me-1">
                                            Alert users
                                            <nz-input-number formControlName="alertUserNumber" name="alertUserNumber"
                                                style="width: 100px; border-radius: 4px;" [nzMin]="1" [nzMax]="10"
                                                [nzStep]="1"></nz-input-number>
                                            <span class="ms-2">days before upcoming deadline</span></label>
                                        <i class="bi bi-info-circle-fill text-secondary ms-1" nz-popover
                                            [nzPopoverContent]="upcoming" nzPopoverPlacement="top"></i>
                                        <ng-template #upcoming>
                                            <div class="rounded-2">
                                                <p style="height: 60px; width: 210px; font-size: 13px;">
                                                    The time that automatic email reminders are generated before a CO
                                                    deadline
                                                    can also be adjusted.
                                                </p>
                                            </div>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card border-0 mt-3">
                    <div class="card-head border-bottom">
                        <div class="row mb-0 p-2 d-flex align-items-center">
                            <div class="col">
                                <span class="fw-medium fs-6 ms-2">Owner Options</span>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <div class="row mb-3">
                                    <div class="col">
                                        <label nz-checkbox formControlName="allowOwnerChangeOrderRequestsByDefault"
                                            name="allowOwnerChangeOrderRequestsByDefault"> Allow Owner Change Order
                                            requests by default</label>
                                        <i class="bi bi-info-circle-fill text-secondary ms-1" nz-popover
                                            [nzPopoverContent]="ChangeOrder" nzPopoverPlacement="top"></i>
                                        <ng-template #ChangeOrder>
                                            <div class="rounded-2">
                                                <p style="height: auto; width: 210px; font-size: 13px;">
                                                    This setting can be changed in the Job Settings Setup or the Change
                                                    Order
                                                    Setup.
                                                </p>
                                            </div>
                                        </ng-template>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col">
                                        <label nz-checkbox formControlName="showOwnerinternallyDeclinedChangeOrders"
                                            name="showOwnerinternallyDeclinedChangeOrders"> Show Owner internally
                                            declined Change Orders</label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <label nz-checkbox formControlName="showLineItemsToOwner"
                                            name="showLineItemsToOwner">Show line items to Owner</label>
                                        <i class="bi bi-info-circle-fill text-secondary ms-1" nz-popover
                                            [nzPopoverContent]="Owner" nzPopoverPlacement="top"></i>
                                        <ng-template #Owner>
                                            <div class="rounded-2">
                                                <p style="height: 190px; width: 210px; font-size: 13px;">
                                                    This setting will enable the 'Show Line Items to Owner' flag on all
                                                    new
                                                    Change Order by default. This can be individually enabled/disabled
                                                    on each
                                                    Change Order. Description, Quantity, Unit Price and Price will be
                                                    shown for
                                                    each line item, but the Owner will Not be permitted to see builder
                                                    cost Or
                                                    markup.
                                                </p>
                                            </div>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <nz-card nzBorderless="true" class="mt-2" nzTitle="Approval Options">

                    <div class="row mb-2">
                        <div class="col-3">
                            <label class="famly-inn fw-bold">
                                Require signature on Change Orders
                            </label>
                            <nz-select nzShowSearch formControlName="requireSignatureOnChangeOrderSetupId"
                                name="requireSignatureOnChangeOrderSetupId">
                                <nz-option *ngFor="let option of keywordOptions" [nzLabel]="option?.name"
                                    [nzValue]="option?.id">
                                </nz-option>
                            </nz-select>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-12">
                            <label class="famly-inn fw-bold mt-3">
                                Change Order approval disclaimer
                            </label>
                            <div class="border-container" *ngIf="!showInitialField === true">
                                <span class="placeholder-text" formControlName="changeOrderApprovalDisclaimer">
                                    {{ changeOrderFormFields.get('changeOrderApprovalDisclaimer')?.value }}
                                </span>
                            </div>

                        </div>
                    </div>
                    <div class="row mb-2" *ngIf="!showInitialField === true">
                        <div class="col-12">
                            <button nz-button nzType="default" (click)="showFirstFieldy()">Change</button>
                        </div>
                    </div>
                    <div class="row" *ngIf="showInitialField === true">
                        <div class="col-12">
                            <div class="editor-container">
                                <ckeditor formControlName="changeOrderApprovalDisclaimer" [editor]="Editor"
                                    [config]="config" class="editor-size" id="changeOrdereditor">
                                </ckeditor>
                            </div>
                        </div>
                    </div>
                </nz-card>
                <nz-card nzBorderless="true" class="mt-2" nzTitle="Default Change Order Description">
                    <div class="row mb-2">
                        <div class="col-12">
                            <ckeditor formControlName="defaultChangeOrderDescription" name="changeOrderDescription"
                                [editor]="Editor" [config]="config" class="size" id="changeOrdereditor">
                            </ckeditor>

                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-12">
                            <p-editor [(ngModel)]="text" [style]="{ height: '120px' }">
                            </p-editor>
                        </div>
                    </div>
                </nz-card>


                <div class="card border-0 mt-3">
                    <div class="card-head border-bottom">
                        <div class="row mb-0 p-2 d-flex align-items-center">
                            <div class="col">
                                <span class="fw-medium fs-6 ms-2">Display on Printout</span>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">

                                <div class="row mb-3">
                                    <div class="col">
                                        <label nz-checkbox formControlName="price"
                                            name="defaultChangeOrderDescription">Price</label>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col">
                                        <label nz-checkbox formControlName="discussions"
                                            name="discussions">Discussions</label>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col">
                                        <label nz-checkbox formControlName="lineItems" name="lineItems">Line
                                            Items</label>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col">
                                        <label nz-checkbox formControlName="performingSubsVendors"
                                            name="performingSubsVendors">Performing Subs/Vendors</label>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col">
                                        <label nz-checkbox formControlName="signatureLines"
                                            name="signatureLines">Signature Lines</label>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col">
                                        <label nz-checkbox formControlName="customFields" name="customFields">Custom
                                            Fields</label>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="card border-0 mt-3">
                    <div class="card-head border-bottom">
                        <div class="row mb-0 p-2 d-flex align-items-center">
                            <div class="col">
                                <span class="fw-medium fs-6 ms-2">Change Order Custom Fields</span>
                            </div>
                            <div class="col text-end">
                                <button nz-button nzType="primary">Add New Field</button>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-12 d-flex alighn-item-center justify-content-center">
                                        <svg class="ant-empty-img-simple" width="64" height="41" viewBox="0 0 64 41"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <g transform="translate(0 1)" fill="none" fill-rule="evenodd">
                                                <ellipse class="ant-empty-img-simple-ellipse" cx="32" cy="33" rx="32"
                                                    ry="7">
                                                </ellipse>
                                                <g class="ant-empty-img-simple-g" fill-rule="nonzero">
                                                    <path
                                                        d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z">
                                                    </path>
                                                    <path
                                                        d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                                                        class="ant-empty-img-simple-path"></path>
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 d-flex alighn-item-center justify-content-center">
                                        <span class="ant-empty-normal">No Data</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ng-template #modalFooterInChangeOrderSetup>
            <div nz-row class="p-1 justify-content-end">
              <div nz-col nzSpan="24">
                <nz-space>
                  <ng-container *nzSpaceItem>
                    <button
                      nz-button
                      nzType="primary"
                      class="align-items-center"
                      [nzLoading]="isSave"
                      (click)="saveChangeOrder()"
                      [disabled]="DisabledNextActivity"
                      [class.disabled]="DisabledNextActivity"
                      >
                      Save
                    </button>
                  </ng-container>
                </nz-space>
              </div>
            </div>
          </ng-template>
    </form>
</nz-modal>