import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import {  selectionresposne, SelectionSetup } from 'src/Models/CompanySettings/Selections/SelectionSetup';

import { ApiResponseModel } from 'src/Models/apiResponse.model';
import { ResponseModel, ResponseModelArray } from 'src/Models/responseMessage.model';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class SelectionsService {

  private baseUrl: string = environment.apiUrl + 'SelectionSetup/';


  constructor(private http: HttpClient) {}

  postCompanySelection(saleSetupData: selectionresposne): Observable<ResponseModel<selectionresposne>> {
    const apiUrl = `${this.baseUrl}`;
    return this.http.post<ResponseModel<selectionresposne>>(apiUrl, saleSetupData).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }


  private Selection_Setup_Fetch: string = environment.apiUrl + 'SelectionSetup/getCompany-SelectionSetup/';

  getAllselection(): Observable<ResponseModel<selectionresposne>> {
    return this.http.get<ResponseModel<selectionresposne>>(this.Selection_Setup_Fetch);
  }

  getData(): Observable<SelectionSetup[]> {
    return this.http.get<SelectionSetup[]>(`${this.baseUrl}getAll`);
  }

  updateData(data: SelectionSetup): Observable<SelectionSetup> {
    return this.http.put<SelectionSetup>(`${this.baseUrl}`, data);
  }

  deleteData(id: number): Observable<SelectionSetup> {
    return this.http.delete<SelectionSetup>(
      `${this.baseUrl}?globalId=${id}`
    );
  }
  getDataId(id: number): Observable<ApiResponseModel> {
    return this.http.get<ApiResponseModel>(
      `${this.baseUrl}getBy-Id?id=${id}`
    );
  }
}
