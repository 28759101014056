import { DataSource } from '@angular/cdk/collections';
import { WarrantyGetallbyId, WarrantyInformation } from 'src/Models/Warranty/warranty';
import { Warranty, WarrantyServiceResponse } from './../../Models/Warranty/warranty';
import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { NZ_MODAL_DATA, NzModalService } from 'ng-zorro-antd/modal';
import { LeadOpportunitiesService } from 'src/Service/lead-opportunities.service';
import { LeadOpportunityAttachmentsUploadFilesViewAllComponent } from '../lead-opportunities/Components/Modal/lead-opportunity-attachments-upload-files-view-all/lead-opportunity-attachments-upload-files-view-all.component';
import { LeadOpportunityAttachmentsUploadFilesComponent } from '../lead-opportunities/Components/Modal/lead-opportunity-attachments-upload-files/lead-opportunity-attachments-upload-files.component';
import { ProjectManagementSiteDiariesCreateFilesComponent } from '../project-management/components/Site-Diaries/project-management-site-diaries-create-files/project-management-site-diaries-create-files.component';
import { WarrantyNewClaimEditWarrantyCategoryComponent } from '../project-management/components/Warranty/warranty-new-claim-edit-warranty-category/warranty-new-claim-edit-warranty-category.component';
import { WarrantyNewClaimWarrantyCategoryComponent } from '../project-management/components/Warranty/warranty-new-claim-warranty-category/warranty-new-claim-warranty-category.component';
import { LookupNameSetupResponse, ScheduleItemParameterResponse } from 'src/Models/LeadTags';
import { CustomerInformationService } from 'src/Service/customer-information.service';
import { WarrantyService } from 'src/Service/Project-Management/warranty/warranty.service';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { ProjectManagementWarrantyPreDefinedCodes } from '../shared/component/Models/LookUpStandardFilterCodes';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ApplicationUserResponse } from 'src/Models/InternalUser/applicationUserResponse';
import { ApplicationUserForComboResponse } from 'src/Models/InternalUser/InternalUserJob';
import { AttachmentService } from 'src/Service/Attachment/attachment.service';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { saveAttachments } from '../helpers/Attachment_Save';
import { AttachmentResponse, } from 'src/Models/Attachment_Files_Class/AttachmentFilesClass';
import { SubVendorService } from 'src/Service/CompanyInFormation/Selections/sub-vendor.service';
import { AuditDetailsResponse } from 'src/Models/Footer/footer.model';
import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';
import { forkJoin, map } from 'rxjs';
import { InternalUserService } from 'src/Service/Internaluser/internal-user.service';
import { SubVendorResponse } from 'src/Models/SubVendor/SubvendorResponse.model';
import { DatePipe } from '@angular/common';
import { LoadingIndicatorService } from 'src/shared/Shared-Services/loading-indicator.service';

@Component({
  selector: 'app-completed-warranty-service',
  templateUrl: './completed-warranty-service.component.html',
  styleUrls: ['./completed-warranty-service.component.css']
})
export class CompletedWarrantyServiceComponent {
  @Input() warrrantyserviceId: number;
  @Input() isShowOwner: boolean;
  @Output() cancel = new EventEmitter<void>();
  @Output() WarrantyResponse = new EventEmitter<WarrantyGetallbyId>();
  @Output() warrantyResponse = new EventEmitter<boolean>();
  initializationComplete = new EventEmitter<boolean>();
  @Input() isEditMode: boolean = false;
  @Output() responseHeaderId: number;
  @Input() responseHeaderIdByWarrantyClaim: number;
  @Input() acceptedBuilderById: boolean;
  @Input() footerData: AuditDetailsResponse | null = null;
  @Output() UploadFile = new EventEmitter<File>();
  public warrantyServiceData: WarrantyServiceResponse | null = null;
  savedResponseData: WarrantyServiceResponse;
  warrantiesResponse: WarrantyServiceResponse;
  footerResponse: AuditDetailsResponse;
  TestAttachment: AttachmentResponse;
  WarrantyService: WarrantyServiceResponse;
  formFieldValues: any = {};
  timeOptions: string[] = [
    '08:00 am',
    '08:30 am',
    '09:00 am',
    '09:30 am',
    '10:00 am',
    '10:30 am',
    '11:00 am',
    '11:30 am',
    '12:00 am',
    '12:30 am',
    '08:00 pm',
    '08:30 pm',
    '09:00 pm',
    '09:30 pm',
    '10:00 pm',
    '10:30 pm',
    '11:00 pm',
    '11:30 pm',
    '12:00 pm',
    '12:30 pm'
  ];
  isData: any = {};
  appointmentData: any = {};
  selectedUsers: any[] = [];
  selectedFiles: File[] = [];
  isOwnerAccepted: { name: string; };
  isBuilderAccepted: { name: string; };
  warrantiesData: WarrantyServiceResponse[];
  selectedFilesAttachment: CustomUploadFile[] = [];
  scheduleItemOption: ScheduleItemParameterResponse[] = [];
  serviceCoordinator: ApplicationUserForComboResponse[] = [];
  lookupSetNameResponse: LookupNameSetupResponse[] = [];
  ownerFeedbackOption: LookupNameSetupResponse[] = [];
  subVenRes: SubVendorResponse[] = [];
  internalRes: ApplicationUserResponse[] = [];
  test: any;
  InterUserId: number
  SubVenId: number
  initials: string = '';
  formName: string = "Completed Warranty Service";
  PAGE_ID: number = 10002;
  isdeleteEditData: true;
  responseHeader: number;
  deleteButtonEnabled: boolean;
  selectedValue = null;
  date = null;
  isFormSaved = true;
  checked = false;
  isOWNER = true;
  isSubVendor = true;
  uploadfiles = true;
  isFormSaved2 = false;
  isformSaved = false;
  isButtonDisableds = false;
  isLoading: boolean = false
  isDeleteLoading: boolean = false;
  isCheckboxVisible: boolean = false;
  isformSave = true;
  isFormSaved3 = false;
  Taghandle = true;
  showEditForm = false;
  showDeleteButton = false;
  isCheckedvendor: boolean = false;
  showCheckIcon: boolean = false;
  isSaveAndClose: boolean = false;
  isSave: boolean = false;
  isSaveAndNew: boolean = false;
  isfeedbackData: boolean = false;
  isSaveButtonClicked: boolean = false;
  showCompletionFields: boolean = false;
  showMeetwarrantyFields: boolean = false;
  hideForNeedsReworkFields: boolean = false;
  hideReadyForNextPhaseFields: boolean = false;
  isCheckClicked: boolean = true;
  isCheckClicked2: boolean = true;
  submitted: boolean = false;
  dateFlag: boolean = false;
  isSaveCheckClicked: boolean = true;
  showError = false;
  time: Date | null = null;
  time2: Date | null = null;
  defaultOpenValue = new Date(0, 0, 0, 0, 0, 0);
  defaultOpenValue2 = new Date(0, 0, 0, 0, 0, 0);
  selectedOption: number;
  preDefinedDataCodes = {
    OwnerFeedback: ProjectManagementWarrantyPreDefinedCodes.OwnerFeedback,
  };
  holdWarrantyServiceAllResponce: WarrantyServiceResponse;
  completeAllDataByServiceWarranty: WarrantyServiceResponse;
  FeedValueOn: string | null = '';
  builderOnAccept: string | null = '';
  warranties: WarrantyGetallbyId[];
  _warrantyServiceGetby_HoldId: number;

  constructor(
    private leadOppService: LeadOpportunitiesService,
    private _AttachmentService: AttachmentService,
    private modal: NzModalService,
    private datePipe: DatePipe,
    private warrantyService: WarrantyService,
    private CustomerInformationService: CustomerInformationService,
    public localStorageService: AccessLocalStorageService,
    private internalUserService: InternalUserService,
    private subVendorService: SubVendorService,
    private LookupFilter: LookupFilterService,
    public toastsService: NzMessageService,
    private cdr: ChangeDetectorRef,
    private _loadingIndicatorService: LoadingIndicatorService,
  ) {
  }

  ngOnInit(): void {
    if (this.warrrantyserviceId) {
      this._loadingIndicatorService.show();
    }
    this.isLoading = true;
    this.warrantyService.getWarrantyDatas()?.subscribe(Newdata => {
    this.warranties = Newdata
    const _letSuppose = Newdata?.map(x => x.FeedBackOn)
    console.log('?.map(c => c.FeedBackOn)', _letSuppose);
    });
    this.initLoad();
    this.ForCombo();
    this.filedsBindRecord();
    this.onGetAllAssigneeUser();
    this.fetchWarrntyservicegetbyid()
    const currentDate = new Date();
    this.appointmentData = {
      globalId: "00000000-0000-0000-0000-000000000000",
      warrantyInformationId: this.responseHeaderIdByWarrantyClaim,
      assignToUserId: 0,
      assignToSubVendorId: 0,
      completionTime: currentDate,
      completionOn: currentDate,
      serviceOn: currentDate,
      startTime: null,
      endTime: null,
      statusSetupId: 0,
      internalNotes: null,
      attachmentId: 0,
      isAppointmentToOwner: true,
      isBuilderOverride: false,
      isSubVendorScheduleAppointment: false,
      ownerFeedbackId: 71007,
      appointmentNotes: null,
      isOwnerAccepted: this.isShowOwner,
      ownerAcceptedOn: null,
      isBuilderAccepted: false,
      builderAcceptedOn: null,
    };
    setTimeout(() => {
      this.showCheckIcon = true;
    }, 4000);
    this.leadOppService.getSelectedFiles().subscribe(files => {
      this.selectedFiles = files;
    });
  }
  initLoad() {
    this.LookupFilter.getLookUpValuesByFormNameId(10002).subscribe(
      (res) => {
        this.lookupSetNameResponse = res.result;
        this.serviceCoordinator = res.result.filter(
          (x) => x.code === this.preDefinedDataCodes.OwnerFeedback.code
        );
        console.log('show all owner data', this.serviceCoordinator);
        if (this.serviceCoordinator.length > 0) {
          this.selectedOption = this.serviceCoordinator[0].id;
        }
      },
      (error) => {
        console.error('Error fetching lookup values', error);
      }
    );

  }
  patchTimeWarrantyService(WarrantyServiceResponses: WarrantyServiceResponse) {
    if (!WarrantyServiceResponses) {
      console.error('No Warranty Service response data available for patching.');
      return;
    }
    const selectedUserAssign = this.internalRes.find(user => user.id === WarrantyServiceResponses?.assignToUserId);
    if (selectedUserAssign) { 
      this.test = { id: selectedUserAssign.id, groupName: 'Internal Users' };
      this.onAssigneeChange(this.test);
    }
    const DataSet = WarrantyServiceResponses;
    this.appointmentData = { 
      ...this.appointmentData,
      globalId: DataSet?.globalId,
      warrantyInformationId: DataSet?.warrantyInformationId,
      assignToUserId: DataSet?.assignToUserId || 0,
      assignToUser: DataSet?.assignToUser?.fullName,
      assignToSubVendorId: DataSet?.assignToSubVendorId || 0,
      serviceOn: DataSet?.serviceOn,
      startTime: DataSet?.startTime || null,
      endTime: DataSet?.endTime || null,
      statusSetupId: DataSet?.statusSetupId,
      internalNotes: DataSet?.internalNotes,
      attachmentId: DataSet?.attachmentId,
      isAppointmentToOwner: DataSet?.isAppointmentToOwner,
      isBuilderOverride: DataSet?.isBuilderOverride,
      ownerFeedbackId: DataSet?.ownerFeedbackId,
      completionOn: DataSet?.completionOn,
      completionTime: DataSet?.completionTime,
      appointmentNotes: DataSet?.appointmentNotes,
      isOwnerAccepted: DataSet?.isOwnerAccepted,
      ownerAcceptedOn: DataSet?.ownerAcceptedOn,
      isBuilderAccepted: DataSet?.isBuilderAccepted,
      builderAcceptedOn: DataSet?.builderAcceptedOn,
    };
    this.fetchAttachments(DataSet?.attachmentId);
    this.initializationComplete.emit(false);
  }

  fetchAttachments(AttachmentId: number) {
    if (AttachmentId) {
      this._AttachmentService.getLeadByAttachmentId(AttachmentId).subscribe(
        (res) => {
          this.TestAttachment = res.result
          // this.TestAttachments = res.result?.attachmentParameters
          // const setFilePath = this.IMG_BASE + res.result?.attachmentParameters?.map(x => x.filePath)
          // this.FileSetName = setFilePath
          console.log('WarrantyGet By ID Responce', res.result);
        },
      );
    } else {
      this.TestAttachment = undefined;
    }
    this.initializationComplete.emit(false);
  }
  TagClose(): void {
    this.cancel.emit();
    this.Taghandle = false;
  }
  Add(): void {
    const modalRef = this.modal.create({
      nzContent: WarrantyNewClaimWarrantyCategoryComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }

  Edit(): void {
    const modalRef = this.modal.create({
      nzContent: WarrantyNewClaimEditWarrantyCategoryComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  showTimeError: boolean = false;



  // savewarrantyserviceform(actionType: 'save' | 'saveAndClose' = 'save'): Promise<void> {
  //   this.isFormSaved = true; // Ensure this is false before save starts
  //   return new Promise((resolve, reject) => {
  //     if (this.isSave || this.isSaveAndClose) {
  //       return reject('Already processing');
  //     }
  //     this.isSave = actionType === 'save';
  //     this.isSaveAndClose = actionType === 'saveAndClose';

  //     // assignTo fields
  //     if (!this.SubVenId) {
  //       this.appointmentData.assignToSubVendorId = 0;
  //       this.appointmentData.assignToUserId = this.InterUserId || this.test?.id;
  //     } else if (!this.InterUserId) {
  //       this.appointmentData.assignToUserId = 0;
  //       this.appointmentData.assignToSubVendorId = this.SubVenId;
  //     }

  //     // Notes Error
  //     const _StoreNOtesOppint = this.appointmentData?.appointmentNotes;
  //     const _StoreinternalNotes = this.appointmentData?.internalNotes;
  //     const _vendorNotes = this.appointmentData?.su;

  //     if (_StoreinternalNotes?.length > 512) {
  //         return reject('Internal notes exceed character limit.');
  //     }
  //     if (_StoreNOtesOppint?.length > 512) {
  //         return reject('Appointment notes exceed character limit.');
  //     }
  //     if (_vendorNotes?.length > 512) {
  //         return reject('Appointment notes exceed character limit.');
  //     }

  //     // Check if assignTo fields are populated
  //     if (!this.appointmentData.assignToUserId && !this.appointmentData.assignToSubVendorId) {
  //       this.isSave = false;
  //       this.isSaveAndClose = false;
  //       this.showAssignError = true;
  //       this.cdr.detectChanges();
  //       this.toastsService.error('Please assign to a user or subvendor.');
  //       return reject('AssignTo fields are missing');
  //     } else {
  //       this.showAssignError = false;
  //     }
  //     // Time validation should only occur if the 'assign' validation passes
  //     const startTime = this.appointmentData['startTime'];
  //     const endTime = this.appointmentData['endTime'];
  //     const currentTime = new Date();

  //     if (startTime && endTime) {
  //       if (startTime === endTime || endTime <= startTime) {
  //         this.isSave = false;
  //         this.isSaveAndClose = false;
  //         this.toastsService.error('End time should be greater than the start time.');
  //         this.showTimeError = true;
  //         return reject('Invalid time range');
  //       }

  //       // Check if startTime or endTime matches the current time
  //       const areTimesEqual = (time1: Date, time2: Date) =>
  //         time1.getHours() === time2.getHours() && time1.getMinutes() === time2.getMinutes();

  //       if (areTimesEqual(startTime, currentTime) || areTimesEqual(endTime, currentTime)) {
  //         this.isSave = false;
  //         this.isSaveAndClose = false;
  //         this.toastsService.error('Start time or end time cannot be the same as the current time.');
  //         this.showTimeError = true;
  //         return reject('Start time or end time matches current time');
  //       }
  //     } else if (!startTime || !endTime) {
  //       this.showTimeError = false;
  //     }

  //     // Handle owner feedback status
  //     const selectedFeedBackStatus = this.ownerFeedbackOption.find(option => option.id === this.appointmentData.ownerFeedbackId);
  //     if (selectedFeedBackStatus) {
  //       switch (selectedFeedBackStatus.id) {
  //         case 71001:
  //         case 71002:
  //           this.appointmentData.statusSetupId = 580;
  //           break;
  //         case 71003:
  //         case 71004:
  //           this.appointmentData.statusSetupId = 581;
  //           break;
  //         case 71005:
  //         case 71006:
  //           this.appointmentData.statusSetupId = 579;
  //           break;
  //         case 71007:
  //           this.appointmentData.statusSetupId = 583;
  //           break;
  //         default:
  //           this.appointmentData.statusSetupId = null;
  //       }
  //     }

  //     // Call the service to save data
  //     this.warrantyService.postWarrantyServiceData(this.appointmentData)?.subscribe(
  //       (response) => {
  //         const result = response.result;
  //         this.holdWarrantyServiceAllResponce = result;
  //         console.log('this.holdWarrantyServiceAllResponce', this.holdWarrantyServiceAllResponce);
  //         const HoldIdByWarrServ = response?.result?.id;
  //         this.appointmentData.globalId = result?.globalId;
  //         this.toastsService.success('New Warranty Service Saved Successfully!');
  //         this.isfeedbackData = true;
  //         if (result?.id) {
  //           this.handleAdditionalWarrantyService(HoldIdByWarrServ);
  //         }

  //         // attachments Save
  //         if (this.selectedFilesAttachment?.length > 0) {
  //           saveAttachments(this.selectedFilesAttachment, this._AttachmentService, HoldIdByWarrServ, 30005);
  //         }

  //         resolve();
  //       },
  //       (error) => {
  //         console.error('Error saving data:', error);
  //         reject(error);
  //       }
  //     ).add(() => {
  //       this.resetFlags();
  //     });
  //   });
  // }

  // saveAndClosewarrantyserviceform(): Promise<void> {
  //   return this.savewarrantyserviceform('saveAndClose').then(() => {
  //     this.resetForm();
  //     this.warrantyserviceformCancel();
  //   }).catch((error) => {
  //     console.error('Error during save and close:', error);
  //   });
  // }

  // resetForm() {
  //   this.appointmentData = {};
  // }


  // resetFlags() {
  //   this.isSave = false;
  //   this.isSaveAndClose = false;
  //   this.isSaveButtonClicked = false;
  // }


//   validateTimes(): string | null {
//     const { startTime, endTime } = this.appointmentData;
//     const currentTime = new Date();

//     if (!startTime || !endTime) return null;
//     if (startTime >= endTime) return 'End time should be greater than the start time.';

//     const timesAreEqual = (t1: Date, t2: Date) => t1.getHours() === t2.getHours() && t1.getMinutes() === t2.getMinutes();
//     if (timesAreEqual(startTime, currentTime) || timesAreEqual(endTime, currentTime)) {
//         return 'Start time or end time cannot be the same as the current time.';
//     }

//     return null;
// }
// showTimeErrors = false;
// timeValidationError = false;
// timeValidationErrorMessage = '';
// validateTimess(): void {
//   const startTime = this.appointmentData['startTime'];
//   const endTime = this.appointmentData['endTime'];
//   const currentTime = new Date();

//   if (startTime && endTime) {
//     if (endTime <= startTime) {
//       this.timeValidationError = true;
//       this.timeValidationErrorMessage = 'End time must be after the start time.';
//     } else if (startTime === currentTime || endTime === currentTime) {
//       this.timeValidationError = true;
//       this.timeValidationErrorMessage = 'Start time or end time cannot be the same as the current time.';
//     } else {
//       this.timeValidationError = false;
//       this.timeValidationErrorMessage = '';
//     }
//   } else {
//     this.timeValidationError = false;
//     this.timeValidationErrorMessage = '';
//   }
// }


  async savewarrantyserviceform(actionType: 'save' | 'saveAndClose' = 'save'): Promise<void> {
    if (this.isSave || this.isSaveAndClose) {
      return Promise.reject('Already processing');
    }
    this.isFormSaved = false;
    this.isSave = actionType === 'save';
    this.isSaveAndClose = actionType === 'saveAndClose';

  //   if (this.showError) {
  //     this.toastsService.error('Owner Feedback is required.');
  //     this.resetFlags();
  //     return Promise.reject('Owner Feedback validation failed');
  // }

  if (!this.setAssignmentFields()) {
      this.showAssignError = true;
      this.toastsService.error('Please assign to a user or subvendor.');
      this.resetFlags();
      return Promise.reject('AssignTo fields are missing');
    }
    this.showAssignError = false;

    if (!this.serviceOnRequiredField()) {
      // this.toastsService.error('Please ServiceOn ReQ');
      this.showServiceOnError = true;
      this.resetFlags();
      return Promise.reject('AssignTo fields are missing');
    }
    this.showServiceOnError = false;

    // Validate notes character limit
    const notesValidationError = this.validateNotes();
    if (notesValidationError) {
      this.resetFlags();
      return Promise.reject(notesValidationError);
    }

    // Validate start and end times
    this.validateTimes();
    if (this.showTimeError) {
      this.isSave = false;
      this.isSaveAndClose = false;
      this.toastsService.error('End time should be after the start time, and times cannot match the current time.');
      return Promise.reject('Validation error: incorrect start or end time');
    }
    
    // Update status setup ID based on feedback
    this.updateStatusSetupId();

    try {
      const response = await this.warrantyService.postWarrantyServiceData(this.appointmentData).toPromise();
      this.handleSuccessfulSave(response);
      if (this.selectedFilesAttachment?.length > 0) {
        await saveAttachments(this.selectedFilesAttachment, this._AttachmentService, response.result.id, 30005);
      }
    } catch (error) {
      console.error('Error saving data:', error);
      return Promise.reject(error);
    } finally {
      this.resetFlags();
    }
  }

  private setAssignmentFields(): boolean {
    if (!this.SubVenId) {
      this.appointmentData.assignToSubVendorId = 0;
      this.appointmentData.assignToUserId = this.InterUserId || this.test?.id;
    } else if (!this.InterUserId) {
      this.appointmentData.assignToUserId = 0;
      this.appointmentData.assignToSubVendorId = this.SubVenId;
    }
    return !!this.appointmentData.assignToUserId || !!this.appointmentData.assignToSubVendorId;
  }

  private serviceOnRequiredField(): boolean {
    if (this.appointmentData.serviceOn === null || this.appointmentData.serviceOn === undefined) {
      this.appointmentData.serviceOn = 0; // You can set a default value here if needed
      this.showServiceOnError = true; // Show error flag
      return false; // Validation failed
    }
    this.showServiceOnError = false; // Hide error flag if valid
    return true; // Validation passed
  }

  private validateNotes(): string | null {
    const { appointmentNotes, internalNotes, su: vendorNotes } = this.appointmentData;
    if (internalNotes?.length > 512) return 'Internal notes exceed character limit.';
    if (appointmentNotes?.length > 512) return 'Appointment notes exceed character limit.';
    if (vendorNotes?.length > 512) return 'Vendor notes exceed character limit.';
    return null;
  }

  public onTimeChange(): void {
    this.validateTimes();
  }
  private validateTimes(): void {
    const { startTime, endTime } = this.appointmentData;
    const currentTime = new Date();
    if (!startTime || !endTime) {
      this.showTimeError = false;
      return;
    }
    if (startTime >= endTime) {
      this.showTimeError = true;
      return;
    }
    const timesAreEqual = (t1: Date, t2: Date) => t1.getHours() === t2.getHours() && t1.getMinutes() === t2.getMinutes();
    if (timesAreEqual(startTime, currentTime) || timesAreEqual(endTime, currentTime)) {
      this.showTimeError = true;
      return;
    }
    this.showTimeError = false;
  }


  private updateStatusSetupId(): void {
    const selectedStatus = this.ownerFeedbackOption.find(option => option.id === this.appointmentData.ownerFeedbackId);
    if (!selectedStatus) return;

    switch (selectedStatus.id) {
      case 71001:
      case 71002:
        this.appointmentData.statusSetupId = 580;
        break;
      case 71003:
      case 71004:
        this.appointmentData.statusSetupId = 581;
        break;
      case 71005:
      case 71006:
        this.appointmentData.statusSetupId = 579;
        break;
      case 71007:
        this.appointmentData.statusSetupId = 583;
        break;
      default:
        this.appointmentData.statusSetupId = null;
    }
  }

  private handleSuccessfulSave(response: any): void {
    this.holdWarrantyServiceAllResponce = response.result;
    this.appointmentData.globalId = response.result.globalId;
    this.toastsService.success('New Warranty Service Saved Successfully!');
    this.isfeedbackData = true;

    if (response.result.id) {
      this.handleAdditionalWarrantyService(response.result.id);
    }
  }

  saveAndClosewarrantyserviceform(): Promise<void> {
    return this.savewarrantyserviceform('saveAndClose')
      .then(() => {
        this.resetForm();
        this.warrantyserviceformCancel();
      })
      .catch(error => console.error('Error during save and close:', error));
  }

  resetForm(): void {
    this.appointmentData = { ...this.appointmentData }; // assuming initialAppointmentData is defined with default values
  }

  resetFlags(): void {
    this.isSave = false;
    this.isSaveAndClose = false;
    this.isSaveButtonClicked = false;
  }



  showAssignError = false;
  showServiceOnError = false;
  showStartTimeError = false;
  showEndTimeError = false;

  validateTime(): void {
    const startTime = this.appointmentData['startTime'];
    const endTime = this.appointmentData['endTime'];
    this.showStartTimeError = false;
    this.showEndTimeError = false;
    if (!startTime || !endTime) {
      return;
    }
    if (endTime <= startTime) {
      this.showEndTimeError = true;
    } else {
      this.showEndTimeError = false;
    }
  }


  handleAdditionalWarrantyService(HoldIdByWarrServ: number): void {
    this.warrantyService.WarrantyWarrantyService(this.responseHeaderIdByWarrantyClaim).subscribe(
      (res) => {
        if (Array.isArray(res.result)) {
          this.NOordata = res?.result;
          console.log('this.NOordata', this.NOordata);

          // this.warrantyService.updateWarrantyData(res?.result);
          this.warrantyResponse.emit(true);
          this.WarrantyResponse.emit(res?.result);
          this.warrantyService.updateWarrantyData(res?.result);
        }
      },
      (error) => {
        console.error('Error fetching warranty details:', error);
      }
    );
  }





  warrantyserviceformCancel(): void {
    this.cancel.emit();
    this.Taghandle = false;
  }

  onFilesUploaded(files: CustomUploadFile[]): void {
    this.selectedFilesAttachment = files
  }
  closeForm() {
    this.isSaveAndClose = false;
  }
  NOordata: WarrantyGetallbyId;

  saveFileIdToDatabase(fileId: string): void {
  }

  ForCombo() {
    this.CustomerInformationService.getDataforcombo().subscribe((res) => {
      const serviceCoordinator = res.result;
      this.serviceCoordinator = serviceCoordinator;
    });
  }

  checkBoxToggle() {
    this.isCheckClicked = this.isCheckClicked;
    if (this.isCheckClicked) {
      this.submitted = false;
    }
  }

  filedsBindRecord() {
    this.LookupFilter.getLookUpValuesByFormNameId(this.PAGE_ID).subscribe(
      (res) => {
        this.lookupSetNameResponse = res.result;
        console.log('warranty service data success', this.lookupSetNameResponse);
        let ownerFeedbackValues = res.result.filter(
          (x) => x.code === this.preDefinedDataCodes.OwnerFeedback.code
        );
        this.ownerFeedbackOption = ownerFeedbackValues;


        const serviceCompletedOption = this.ownerFeedbackOption.find(option => option.name === 'Service Completed' );
        if (serviceCompletedOption) {
          this.appointmentData['ownerFeedbackId'] = serviceCompletedOption.id;
        }
      }
    );
  }
  // onFeedbackChange(newValue: string) {
  //   this.appointmentData['ownerFeedbackId'] = newValue; // Update the model with the new value
  //   console.log('New ownerFeedbackId:', this.appointmentData['ownerFeedbackId']);
  // }

  logButtonToggle(): void {
    this.appointmentData.isBuilderOverride = !this.appointmentData.isBuilderOverride; // Toggle isBuilderOverride
  }

  updateCheckboxState(selectedDate: Date): void {
    this.appointmentData['serviceOn'] = selectedDate;
    this.showServiceOnError = false;
    this.isCheckClicked = false;
    this.isCheckClicked2 = false;
  }

  isDateAfterCurrent(): boolean {
    const selectedDate = new Date(this.appointmentData['serviceOn']);
    const currentDate = new Date();
    return selectedDate > currentDate;
  }
  checkCharacterLimits(): void {
    const { internalNotes, appointmentNotes } = this.formFieldValues;
    this.isButtonDisableds = false;
    if (internalNotes?.length > 512 || appointmentNotes?.length > 512) {
      this.isButtonDisableds = true;
    }
  }
  onAssignToUserChange() {
    this.showError = false; // Reset the error when the user selects an option
  }

  ConfirmDelete(): void {
    if (this.isDeleteLoading) {
      return;
    }
    this.modal.confirm({
      nzTitle: 'Delete Warranty Service?',
      nzContent: 'Are you sure you want to permanently delete this Warranty Service?',
      nzOkText: 'Delete',
      nzOkDanger: true,
      nzCancelText: 'Cancel',
      nzOnOk: () => {
        this.isDeleteLoading = true;
        return this.deleteActivity(this.appointmentData.globalId)
          .then(() => {
            this.TagClose();
            this.removeItemFromList(this.appointmentData.globalId);
            this.onSaveCompleteService.emit();
            this.cdr.detectChanges();  // Force immediate change detection
          })
          .catch(() => {
            this.isDeleteLoading = false;
          });
      },
      nzCentered: false,
      nzBodyStyle: { 'border-radius': '30px', 'height': 'auto', 'padding-top': '15' },
      nzStyle: { top: '200px' },
      nzClassName: 'ant-modal-confirm-custom',
      nzCloseIcon: '',
      nzIconType: '',
    });
  }
  @Output() onSaveCompleteService = new EventEmitter<WarrantyServiceResponse>();
  removeItemFromList(globalId: string): void {
    this.warranties = this.warranties.filter(item => item.GlobalId !== globalId);
    this.cdr.detectChanges();
  }

  deleteActivity(globalId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!globalId) {
        this.toastsService.error('No record selected for deletion.');
        this.isDeleteLoading = false;
        reject();
        return;
      }

      this.warrantyService.deleteWarrantyServiceData(globalId).subscribe(
        () => {
          this.isDeleteLoading = false;
          this.toastsService.success('Deleted User!');

          this.WarrantyResponse.emit();
          this.TagClose();
          if (this.warrrantyserviceId) {
            this.fetchWarrntyservicegetbyid()
          }
          resolve();
          reject();
        },
        () => {
          this.isDeleteLoading = false;
          this.toastsService.error('An error occurred while deleting the record. Please try again.');
          reject();
        }
      );
    });
  }

  compareFn = (o1: any, o2: any) => {
    return o1 && o2 ? o1.id === o2.id && o1.groupName === o2.groupName : o1 === o2;
  }


  // onAssigneeChange(selectedValue: any) {
  //   if (selectedValue === 'me') {
  //     const userId = this.localStorageService.getUserLoginId();
  //     const selectedUser = this.internalRes.find(user => user.id === userId);

  //     if (selectedUser) {
  //       this.test = { id: selectedUser.id, groupName: 'Internal Users' };
  //       this.onShortName(this.test?.id);
  //       // Set isBuilderAccepted to true when 'internal select' is chosen
  //       this.appointmentData.isBuilderAccepted = true;
  //       console.log('this.appointmentData.isBuilderAccepted = true;',this.appointmentData.isBuilderAccepted = true);

  //     }
  //     // Only hide the error if a valid selection is made
  //     this.showAssignError = false;
  //   } else if (selectedValue && selectedValue.id) {
  //     this.onShortName(selectedValue.id);
  //     // Set isBuilderAccepted to true if selectedValue belongs to Internal Users
  //     if (selectedValue.groupName === 'Internal Users') {
  //        this.appointmentData.isBuilderAccepted = true;
  //       console.log('this.appointmentData.isBuilderAccepted = true;',this.appointmentData.isBuilderAccepted);

  //     } else {
  //        this.appointmentData.isBuilderAccepted = false; // Set it to false if not Internal Users
  //     }
  //     this.showAssignError = false; // Hide error for valid user selection
  //   } else {
  //     this.showAssignError = true; // Show error if no valid selection is made
  //     this.appointmentData.isBuilderAccepted = false; // Reset if there's no valid selection
  //   }

  //   if (selectedValue && selectedValue.groupName === "Sub Vendors") {
  //     this.SubVenId = selectedValue.id;
  //     this.isCheckboxVisible = true;
  //   } else {
  //     this.isCheckboxVisible = false;
  //   }
  // }

  isCheckboxEnabled = true; // Ensure checkbox is enabled by default

  onAssigneeChange(selectedValue: any) {
    if (selectedValue === 'me') {
      const userId = this.localStorageService.getUserLoginId();
      const selectedUser = this.internalRes.find(user => user.id === userId);

      if (selectedUser) {
        this.test = { id: selectedUser.id, groupName: 'Internal Users' };
        this.onShortName(this.test?.id);
        this.appointmentData.isBuilderAccepted = true;
        console.log('this.appointmentData.isBuilderAccepted = true;', this.appointmentData.isBuilderAccepted);
      }
      this.showAssignError = false;
    } else if (selectedValue && selectedValue.id) {
      this.onShortName(selectedValue.id);
      if (selectedValue.groupName === 'Internal Users') {
        this.appointmentData.isBuilderAccepted = true;
        console.log('this.appointmentData.isBuilderAccepted = true;', this.appointmentData.isBuilderAccepted);
      } else {
        this.appointmentData.isBuilderAccepted = false;
      }
      this.showAssignError = false;
    } else {
      this.showAssignError = true;
      this.appointmentData.isBuilderAccepted = false;
    }

    if (selectedValue && selectedValue.groupName === "Sub Vendors") {
      this.SubVenId = selectedValue.id;
      this.isCheckboxVisible = true;
      this.isCheckboxEnabled = true; // Enable checkbox for Sub Vendors
    } else {
      this.isCheckboxVisible = false;
      this.isCheckboxEnabled = true; // Keep checkbox enabled regardless
    }
  }





  onShortName(selectedValue: any) {
    if (selectedValue) {
      const userIdAndNames = this.selectedUsers.flatMap(group =>
        group.users.map(user => ({
          id: user.id,
          name: user.name
        }))
      );
      let data = userIdAndNames.find(user => user.id === selectedValue);
      this.initials = this.getInitials(data?.name);
    }
  }

  getInitials(fullName: string | undefined): string {
    if (!fullName) return '';
    const nameParts = fullName.split(' ');
    const firstInitial = nameParts[0]?.charAt(0) || '';
    const lastInitial = nameParts.length > 1 ? nameParts[nameParts.length - 1]?.charAt(0) || '' : '';
    return (firstInitial + lastInitial).toUpperCase();
  }

  onGetAllAssigneeUser() {
    const internalUser$ = this.internalUserService.getData().pipe(map(res => res.result));
    const subVendor$ = this.subVendorService.getData().pipe(map(res => res.result));

    forkJoin([internalUser$, subVendor$]).subscribe(
      ([internalRes, subVenRes]) => {
        this.internalRes = internalRes.filter(ii => ii?.isActive === true);
        this.subVenRes = subVenRes.filter(ii => ii?.isActive === true);

        this.selectedUsers = [
          {
            groupName: 'Internal Users',
            users: this.internalRes.map(user => ({
              id: user?.id,
              name: user?.fullName,
            }))
          },
          {
            groupName: 'Sub Vendors',
            users: this.subVenRes.map(vendor => ({
              id: vendor?.id,
              name: vendor?.companyName,
            }))
          }
        ];

      },
      (error) => {
        console.error('Error fetching users or vendors:', error);
      }
    );
    this.initializationComplete.emit(false);

  }

  onFeedbackChange() {
    const selectedFeedbackId = this.appointmentData['ownerFeedbackId'];
    // Show fields based on feedback ID
    this.showCompletionFields = selectedFeedbackId === 71001;
    this.showMeetwarrantyFields = selectedFeedbackId === 71005;
    // Hide for "Needs Re-Work" (id: 71002)
    this.hideForNeedsReworkFields = selectedFeedbackId === 71002;
    // Hide for "Ready for Next Phase" (id: 71006)
    this.hideReadyForNextPhaseFields = selectedFeedbackId === 71006;
    // Show error if "Unassigned" (id: 71007) is selected
    this.showError = selectedFeedbackId === 71007;
  }

  onCheckboxChange() {
    this.appointmentData.isSubVendorScheduleAppointment = this.appointmentData.isSubVendorScheduleAppointment;
    console.log('appointmentData.isSubVendorScheduleAppointment state:', this.appointmentData.isSubVendorScheduleAppointment);
  }

  private fetchWarrntyservicegetbyid(): void {
    this.warrantyService.ServiceGetbyId(this.warrrantyserviceId)?.subscribe((res) => {
      if (res && res.result) {
        this.completeAllDataByServiceWarranty = res.result;
        this._warrantyServiceGetby_HoldId = res.result?.id;
        let _storeBuildetAcceptOn = this.datePipe.transform(res?.result?.builderAcceptedOn, 'MMM d, y, h:mm a');
        this.builderOnAccept = _storeBuildetAcceptOn
        let _storefeedbackOn = this.datePipe.transform(res?.result?.serviceOn, 'MMM d, y, h:mm a');
        this.FeedValueOn = _storefeedbackOn

        this.WarrantyService = res.result as WarrantyServiceResponse;
        this.patchTimeWarrantyService(this.WarrantyService)
        this._loadingIndicatorService.hide();
        // this.appointmentData.patchValue(this.appointmentData.value); 

      } else {
        console.error('Invalid response from API:', res);
      }
    });
  }
}
interface CustomUploadFile extends NzUploadFile {
  loading?: boolean;
  error?: boolean;
  addedBy: number
  createdOn: Date;
  modifiedOn: Date;
  id: number;
  globalId: string;
}
