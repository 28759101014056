import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { NgZorroModule } from "src/shared/ng-zorro/ng-zorro.module";
import { SharedModule } from "src/shared/shared.module";
import { AddSubVendorComponent } from "./Components/add-sub-vendor/add-sub-vendor.component";
import { SubsVendorsDashboardComponent } from "./Components/subs-vendors-dashboard/subs-vendors-dashboard.component";
import { SubsVendorsJobAccessComponent } from "./Components/subs-vendors-job-access/subs-vendors-job-access.component";
import { VendorStandardViewComponent } from "./Components/vendor-standard-view/vendor-standard-view.component";
import { SubsVendorsInformationRoutingModule } from "./subs-vendors-information-routing.module";
import { SubVendorAccountingComponent } from './Components/sub-vendor-accounting/sub-vendor-accounting.component';
import { SubVendorAccountinComponent } from './Components/sub-vendor-accountin/sub-vendor-accountin.component';
import { SubVendorTradeAgreementComponent } from './Components/sub-vendor-trade-agreement/sub-vendor-trade-agreement.component';
import { InviteSubsVendorComponent } from './Components/invite-subs-vendor/invite-subs-vendor.component';
// import { ChartsModule } from '@progress/kendo-angular-charts';
@NgModule({
  declarations: [

    SubsVendorsDashboardComponent,
    AddSubVendorComponent,
    SubsVendorsJobAccessComponent,
    VendorStandardViewComponent,
    SubVendorAccountingComponent,
    SubVendorAccountinComponent,
    SubVendorTradeAgreementComponent,
    InviteSubsVendorComponent,

  ],
  imports: [
    CommonModule,
    SubsVendorsInformationRoutingModule,
    NgZorroModule,
    ReactiveFormsModule,
    RouterModule,
    FormsModule,
    SharedModule,
    NgxIntlTelInputModule

  ],
  exports:[
  ]

})
export class SubsVendorsInformationModule { }
