import { subVendor } from './../../../../Models/SubVendors.model';
import { Messaging } from './../../../../Models/Warranty/warranty';
import { ContactType } from './../../../../Models/CustomerInfromation/CustomerInformation';
import { Customer } from './../../../../Models/customer.model';
import { BaseResponse } from 'src/Models/InternalUser/applicationUserResponse';
export class InternalUserPreDefinedCodes {
     static standardFilter = { code: "IUSTD" };
     static status = { code: "IUUS" };
     static username = { code: "IUNAM" };
     static role = { code: "IUR" };

}
export class CustomerContactInformationPreDefinedCodes {

}
export class SubsVendors {
     static Division = { code: "SVD" };
}

export class LeadOpportunityListViewPreDefinedCodes {
     static StandardFilter = { code: "LOLVSTD" };
     static Keyword = { code: "LOLVKN" };
     static Salesperson = { code: "LOLVSP" };
     static Status = { code: "LOLVSTS" };
     static AgeofLead = { code: "LOLVAOL" };
     static Tags = { code: "LOLVT" };
     static Source = { code: "LOLVS" };
     static ProjectType = { code: "LOLVPT" };
     static ProposalStatus = { code: "LOLVPS" };
     static SoldDate = { code: "LOLVSD" };
     static MostRecentClick = { code: "LOLVMRC" };
     static OfLinksClicked = { code: "LOLVOLC" };
     static DoyouhaveLand = { code: "LOLVDYHL" };
}
export enum LeadOpportunityListViewPreDefinedParentCodes {
     PARENT_CODE = "LOLVKS",
}

export class CompanychangeOrderPredifinedcodes {
     static COSRSCO = { code: "COSRSCO" };
     static PMCORSOCO = { code: "PMCORSOCO" };
}
export class jobSetupDefaultOrderPredifinedcodes {
     static defaultCostSetting = { code: "CSJDCS" };
}


export class LeadActivityActivityViewPreDefinedCodes {
     static StandardFilter = { code: "LOAVSTD" };
     static Keyword = { code: "LOAVK" };
     static ActivityStatus = { code: "LOAVASTS" };
     static MostRecentClick = { code: "LOAVMRC" };
     static OfLinksClicked = { code: "LOAVOLC" };
     static LeadStatus = { code: "LOAVLSTS" };
     static ActivityType = { code: "LOAVAT" };
     static AssignedUser = { code: "LOAVAU" };
     static ActivityDate = { code: "LOAVAD" };
     static Reminder = { code: "LOAVR" };
}

export class LeadActivityActivityCalanderPreDefinedCodes {
     static activityStatus = { code: "LOACSTS" };
     static LeadStatus = { code: "LOACLSTS" };
     static activityType = { code: "LOACAT" };
}



export class LeadActivityTemplatesActivityTemplatesPreDefinedCodes {
     static StandardFilter = { code: "LOATSTD" };
     static Keyword = { code: "LOATK" };
     static Salesperson = { code: "LOATSP" };
     static NextActivity = { code: "LOATNA" };
}
export class LeadProposalsPreDefinedCodes {
     static StandardFilter = { code: "LOLPSTD" };
     static Keyword = { code: "LOLPK" };
     static ProposalStatus = { code: "LOLPPSTS" };
     static OpportunityStatus = { code: "LOLPOSTS" };
     static ProposalAmount = { code: "LOLPPA" };
     static LastUpdated = { code: "LOLPLU" };
     static Salesperson = { code: "LOLPSP" };
     static Source = { code: "LOLPS" };
     static JobType = { code: "LOLPJT" };
     static Markup = { code: "LOLPIM" };
     static CostType = { code: "LOLPCT" };
     static MarkAs = { code: "LOLPMA" };
}
export class LeadProposalsTemplatesPreDefinedCodes {

     static StandardFilter = { code: "LOPTSTD" };
     static Keyword = { code: "LOPTK" };
     static Salesperson = { code: "LOPTSP" };

}
export class LeadOpportunityMapPreDefinedCodes {
     static StandardFilter = { code: "LOMPSTD" };
     static Keyword = { code: "LOLMKN" };
     static Salesperson = { code: "LOLMSP" };
     static Status = { code: "LOLMSTS" };
     static AgeofLead = { code: "LOLMAOL" };
     static Tags = { code: "LOLMT" };
     static hasvalidemail = { code: "LOLMHVE" };
     static Source = { code: "LOLMS" };
     static ProjectType = { code: "LOLMPT" };
     static ProposalStatus = { code: "LOLMPS" };
     static SoldDate = { code: "LOLMSD" };
     static MostRecentClick = { code: "LOLMMRC" };
     static OfLinksClicked = { code: "LOLMOLC" };
     static DoyouhaveLand = { code: "LOLMDYHL" };
     static AssignCleintsourcename  = { code: "LOLMACSN" };
     static LandTitle = { code: "LOLMLT" };
     static IfNoDateOfTitle = { code: "LOLMINDOT" };
     static LandSize = { code: "LOLMLS" };
     static LandWith = { code: "LOLMLWM" };
     static LandLength = { code: "LOLMLL" };
     static Easement = { code: "LOLME" };
     static CornerLot = { code: "LOLMCL" };
     static Pos = { code: "LOLMPOS" };
     static Mcp = { code: "LOLMMCP" };
     static DeveloperEngineering = { code: "LOLMDE" };
     static DesignGuidelines = { code: "LOLMDG" };
     static Level1CompactionReport = { code: "LOLML1CR" };
     static SitingDone = { code: "LOLMSD" };
     static ReadEngineeringPlan = { code: "LOLMREP" };
     static DepositOf = { code: "LOLMD300" };
     static JobConversion = { code: "LOLMJC" };
     static EmailToClient = { code: "LOLMETC" };




}
/////////////////////////////////////// //soban on work Financial Module All  Forms////////////////////////////////
export class TenderPriDefinedCodes {
     static BidPackageStatus = { code: "FTBBPSTS" };
     static BidStatus = { code: "FTBBSTS" };
     static DeadlineDate = { code: "FTDD" };
     static BudgetedStatus = { code: "FTBBDSTS" };
     static HasRelatedPO = { code: "FTHRPO" };
     static standardFilter = { code: "FTBSTD" };
}


export class PurchaseOrderPridefinedCodes {
     static WorkStatus = { code: "FPOWSTS" };
     static WorkCompleteDate = { code: "FPOWCD" };
     static PaymentStatus = { code: "FPOPSTS" };
     static POStatus = { code: "FPOPOSTS" };
     static POType = { code: "FPOPOT" };
     static MaterialsLabor = { code: "FPOML" };
     static VarianceCode = { code: "FPOVC" };
     static CreatedDate = { code: "FPOCD" };
     static EstCompletionDate = { code: "FPOECD" };
     static Costcode = { code: "FPOCC" };

     static standardFilter = { code: "FPOSTD" };
  static performedBy: any;
  static response: any;
}


export class BillsPridefinedCodes {
     static BillStatus = { code: "FBBSTS" };
     static WorkStatus = { code: "FBWSTS" };
     static POStatus = { code: "FBPOSTS" };
     static Dates = { code: "FBD" };
     static LienWaivers = { code: "FBLW" };
     static SentToAccounting = { code: "FBSTA" };
     static standardFilter = { code: "FBSTD" };
     static InvoiceDate: any;
     static PayTo: any;
}


export class BudgetPridefinedCodes {
     static ApprovalStatus = { code: "FBUASTS" };
     static standardFilter = { code: "FBUSTD" };
     static BidPackageStatus: any;
}


export class CostInboxPridefinedCodes {
     static Status = { code: "FCISTS" };
     static Job = { code: "FCIJ" };
     static PurchaseDate = { code: "FCIPD" };
     static UploadDate = { code: "FCIUD" };
     static TotalAmount = { code: "FCITA" };
     static standardFilter = { code: "FCISTD" };
}


export class OwnerInvoicePridefinedCodes {
     static Status = { code: "FOIISTS" };
     static PaidDate = { code: "FOIIPD" };
     static DeadlineDate = { code: "FOIIDD" };
     static standardFilter = { code: "FOIISTD" };
}


export class PaymentPridefinedCodes {
     static DateRecorded = { code: "FOIPDR" };
     static Status = { code: "FOIPS" };
     static PaymentMethod = { code: "FOIPPM" };
     static standardFilter = { code: "FOIPSTD" };
}

export class CreditMemosPridefinedCodes {
     static CreatedDate = { code: "FOICMCD" };
     static Status = { code: "FOICMSTS" };
     static AppliedDate = { code: "FOICMAD" };
     static standardFilter = { code: "FOICMSTD" };
}

export class EstimatedPridefinedCodes {
     static CostType = { code: "FECT" }
}









export class financialtendersfilterfieldPreDefinedCodes {

     static FTBSTD = { code: "FTBSTD" };
     static FTBBPSTS = { code: "FTBBPSTS" };
     static FTBBSTS = { code: "FTBBSTS" };
     static FTDD = { code: "FTDD" };
     static FTHRPO = { code: "FTHRPO" };
     static FTBBDSTS = { code: "FTBBDSTS" };

}



export class ProjectManegmentScheduleItemDetailsPreDefinedCodes {
     static Reminder = { code: "PMSIR" };
     static Type = { code: "PMSIT" };
}
export class ProjectManegmentSchedulePreDefinedCodes {
     static standardFilter = { code: "PMSSSTD" };
     static Title = { code: "PMSSTN" };
     static AssignedTo = { code: "PMSSAT" };
     static Status = { code: "PMSSSTS" };
     static Phases = { code: "PMSSP" };
     static Date = { code: "PMSSD" };
     static Tags = { code: "PMSST" };
}


export class ProjectManegmentScheduleBaseLinePreDefinedCodes {
     static Title = { code: "PMSDBLT" };
     static Status = { code: "PMSBLSTS" };
     static Phases = { code: "PMSBLP" };
     static Date = { code: "PMSBLD" };
     static standardFilter = { code: "PMSDBLSTD" };
}


export class ProjectManegmentScheduleWorkDayExceptionPreDefinedCodes {
     static Type = { code: "PMSWDET" };
     static Category = { code: "PMSWDEC" };
     static standardFilter = { code: "PMSWDESTD" };
}

export class ProjectManegmentSiteDiriesPreDefinedCodes {
     static standardFilter = { code: "PMSDSTD" };
     static Keyword = { code: "PMSDKN" };
     static Date = { code: "PMSDDLD" };
     static Tags = { code: "PMSDDLT" };
     static CreatedBy = { code: "PMSDDLCB" };
}


export class ProjectManegmentToDosPreDefinedCodes {
     static standeredFilter = { code: "PMTDSTD" };
     static Keyword = { code: "PMTDKN" };
     static AssignedTo = { code: "PMTDAT" };
     static UsersAssignedTo = { code: "PMTDUAT" };
     static Status = { code: "PMTDSTS" };
     static Priority = { code: "PMTDP" };
     static Tags = { code: "PMTDT" };
     static DueDate = { code: "PMTDDD" };
     static Reminder = { code: "PMTDR" };
     static CompletionDate = { code: "PMTDCD" };
}


export class ProjectManegmentVariationPreDefinedCodes {
     static Search = { code: "PMVS" };
     static ApprovalStatus = { code: "PMVCOASTS" };
     static AddedDate = { code: "PMVCOADD" };
     static ApprovalDate = { code: "PMVCOAD" };
     static standardFilter = { code: "PMVSTD" };
}

export class ProjectManegmentCostItemPreDefinedCodes {
     static CostType = { code: "PMVCCICT" };

}

export class JobSummaryPreDefinedCodes {
     static ItemType = { code: "JSIT" };
     static standardFilter = { code: "JSSTD" };



}
export class JobListPreDefinedCodes {
     static standardFilter = { code: "JJLSTD" };
     static Keywords = { code: "JJLK" };
     static ProjectManagers = { code: "JJLPM" };
     static Status = { code: "JJLSTS" };
     static CreatedDate = { code: "JJLCD" };
     static CreatedBy = { code: "JJLCB" };
     static JobType = { code: "JJLJT" };
     static Owners = { code: "JJLO" };
     static MapStatus = { code: "JJLMSTS" };
     static ActualStart = { code: "JJLAS" };
     static ActualCompletion = { code: "JJLAC" };
     static Groups = { code: "JJLG" };
     static PlansLodged = { code: "JJLPL" };
     static PlansApprovedIssued = { code: "JJLPAI" };



     // static Status = { code: "JJLSTS" };
     // static JobType = { code: "JJLJT" };
     // static MapStatus = { code: "JJLMSTS" };
     // static ActualStart = { code: "JJLAS" };
     // static ActualCompletion = { code: "JJLAC" };
     // static Groups = { code: "JJLG" };
     // static PlansLodged = { code: "JJLPL" };
     // static test = { code: "JJLT" };
     // static PlansApprovedIssued = { code: "JJLPAI" };
     // static standardFilter = { code: "JJlSTD" };
}






export class CustomerinformationPreDefinedCodes {
     static keyword = { code: "CIKNM" };
     static contactType = { code: "CICT" };
     static standardFilter = { code: "CISTD" };
}

export enum CustomerInformationPreDefinedParentCodes {
     PARENT_CODE = "CIK",
}


// Selection  START

export class ProjectManagementSelectionCardViewwPreDefinedCodes {
     static standardFilter = { code: "PMSCVSTD" };
     static Title = { code: "PMSCVT" };
     static SelectionStatus = { code: "PMSCVSSTS" };
     static Locations = { code: "PMSCVL" };
     static Categories = { code: "PMSCVC" };
     static subvendor = { code: "PMSCVSV" };
     static deadline = { code: "PMSCVD" };
}

export class MessagingRFIsPreDefinedCodes {
     static StandardFilter = { code: 'MRFIsSTD' };
     static Search = { code: 'MRFIsS' };
     static CreatedBy = { code: "MRFIsCB" };
     static AssignedUsers = { code: "MRFIsAU" };
     static Status = { code: "MRFIsSTS" };
     static DueDate = { code: "MRFIsDD" };
     static RelatedTypes = { code: "MRFIsRT" };
     static Reminder = { code: "MRFIsR" };
}

export class ProjectManagementSelectionListViewwPreDefinedCodes {
     static Title = { code: "PMSLT" };
     static SelectionStatus = { code: "PMSLSSTS" };
     static Locations = { code: "PMSLL" };
     static Categories = { code: "PMSLC" };
     static Deadline = { code: "PMSLD" };
     static subVendor = { code: "PMSCVSV" };
     static standardFilter = { code: "PMSLSTD" };
}
export class ProjectManagementSelectionHierarchyPreDefinedCodes {
     static Categories = { code: "PMSHC" };
     static Locations = { code: "PMSHL" };
     static standardFilter = { code: "PMSHSTD" };
}
export class ProjectManagementAllowanceAssignedSelectionsPreDefinedCodes {
     static Title = { code: "PMSAAST" };
     static SelectionStatus = { code: "PMSASSSTS" };
     static AllowanceStatus = { code: "PMSASASTS" };
     static standardFilter = { code: "PMSAASSTD" };
}
export class ProjectManagementAllowanceUnassignedSelectionsPreDefinedCodes {
     static Title = { code: "PMSAUST" };
     static SelectionStatus = { code: "PMSAUSSSTS" };
     static Locations = { code: "PMSAUSL" };
     static SubsVendors = { code: "PMSAASSV" };
     static Categories = { code: "PMSAUSC" };
     static Deadline = { code: "PMSAUSD" };
     static standardFilter = { code: "PMSAUSSTD" };
}

// Warranty  START

export class ProjectManagementWarrantyPreDefinedCodes {
     static standardFilter = { code: "PMWSTD" };
     static OwnerFeedback = { code: "PMWOF" };
     static Search = { code: "PMWS" };
     static Status = { code: "PMWSTS" };
     static Classifications = { code: "PMWC" };
     static Category = { code: "PMWCG" };
     static Priority = { code: "PMWP" };
     static Coordinator = { code: "PMWC" };
     static AssignedTo = { code: "PMWC" };
     static ScheduledDate = { code: "PMWSD" };
     static AddedDate = { code: "PMWAD" };
     static AddedBy = { code: "PMWAB" };


}



// TimeClock  START

export class ProjectManagementTimeClockPreDefinedCodes {
     static standardFilter = { code: "PMTCSTD" };
     static Tags = { code: "PMTCT" };
     static ShiftStatusType = { code: "PMTCSSTST" };
     static CostCodes = { code: "PMTCCC" };
     static ApprovalStatus = { code: "PMTCASTS" };
     static ApprovalDate = { code: "PMTCAD" };
     static OutofBoundsStatus = { code: "PMTCOOBSTS" };
     static User = { code: "PMTCU" };
     static Date = { code: "PMTCD" };
}



export class SubVendorPreDefinedCodes {
     static keyword = { code: "SVKNM" };
     static standardFilter = { code: 'SVSTD' };
     static status = { code: 'SVSTS' };
     static division = { code: 'SVD' };

}

export enum SubVendorPreDefinedParentCodes {
    PARENT_CODE  = "SVK",
}


export class JobScratchPreDefinedCodes {
     static ContactType = { code: "JCT" };
     static JobGroup = { code: "JSCG" };
     static WorkDays = { code: "JSCWD" };
     static TimeFrame = { code: "JSCTF" };
     static PercentageType = { code: "JOPT" };
     static ChangeJobStatus = { code: "JJLSTS" };
}
export class companySettingRFIsPreDefinedCodes {
     static RFIDFR = { code: "RFIDFR" };
     static RFISPDR = { code: "RFISPDR" };
     static MRFIsR = { code : "MRFIsR"};
     static CORFIsSPDRRUT = { code : "CORFIsSPDRRUT"};


}


export class MessagingCommentsPreDefinedCodes {
     static standardFilter = { code: 'MCSTD' };
     static Keywords = { code: 'MCk' };
     static Type = { code: "MCT" };
     static UserType = { code: "MCUT" };
     static Date = { code: "MCD" };
}
export class CompanySettingsSchedulePreDefineCodes {
     static defaultBuilderView = { code: "CSSDBV" };
     static defaultOwnerView = { code: "CSSDOV" };
}

export class CompanySettingsTimeClockPreDefineCodes {
     static _WeakStart = { code: "CSTCWS" };
     static _HourlyRateTracking = { code: "CSTCHRT" };
     static _SelectRadius = { code: "CSTCSR" };
     static _SelectRadiusKMT = { code: "CSTCSRKMT" };
     static CSTCK = { code: "CSTCK" };
}

export class CompanySettingsEstimatePreDefineCodes {
  static CSEGPW = { code: "CSEGPW" };
  static CSEH = { code: "CSEH" };
  static CSEC = { code: "CSEC" };
  static CSESCN = { code: "CSESCN" };
}


export class CompanySettingssalesPreDefineCodes {
  static DefaultLeadActivityCalendarView = { code: "CSSDLACV" };
  static DefaultGrouping  = { code: "CSDG" };
  static Header  = { code: "CSSH" };
  static Content = { code: "CSSC" };
  static ShowContactName  = { code: "CSSSCN" };
  static ShowCoDefaultWhattoDisplayntactName  = { code: "CSSDWD" };
}



export class CustomerStatusCode{
  static ACTIVE = { code: "CCA" , description: 'ACTIVE'};
  static INACTIVE  = { code: "CCI" , description: 'INACTIVE',name : 'This user has not been invited to Buildertrend. Once they Accept an invite they will become Active.' };
  static DISABLED  = { code: "CCD" , description: 'DISABLED' };
  static PENDING  = { code: "CCP" , description: 'PENDING' };

}
// Define the LeadStatusCode class with static properties
export class LeadStatusCode {
  static AwaitingMessage = { code: "AwaitingMessage", description: "AwaitingMessage" };
  static Queued = { code: "Queued For Send", description: "Queued For Send" };
  static PastDue = { code: "Past Due", description: "Past Due" };
  static Completed = { code: "Complete", description: "Complete" };
  static Pending = { code: "Pending", description: "Pending" };
}

// Define the statussetupColorMap using the code property
export const statussetupColorMap = {
  [LeadStatusCode.Completed.code]: { backgroundColor: '#c4fae2', textColor: '#005c35' },
  [LeadStatusCode.Pending.code]: { backgroundColor: '#fbe9b1', textColor: '#653200' },
  [LeadStatusCode.PastDue.code]: { backgroundColor: '#8b1439', textColor: '#fdddd5' },
  [LeadStatusCode.Queued.code]: { backgroundColor: '#c7d0d9', textColor: '#202227' },
};



export class MessagingSurveyIndividualCode{
     static standardFilter = { code: "MSSTD" };
     static releasedDate = { code: "MSRIRD" };
     static surveyTitle = { code: "MSIST" };
}


export class ProjectManagementSelectioncardViewwPreDefinedCodes {
  static Sort = { code: "PMSCVS" };

}


export class SelectionStatusCode {
  static PendingNoChoice = { code: "Pending: No Choices", description: "Pending: No Choices", statusid: 433 };
  static PendingAvailable = { code: "Pending: Available", description: "Pending: Available", statusid: 437 };
  static ExpiredAvailable = { code: "Expired: Available", description: "Expired: Available", statusid: 440 };
  static ExpiredAvailables = { code: "Expired", description: "Expired", statusid: 440 };
  static ExpiredNoChoices = { code: "Expired: No Choices", description: "Expired: No Choices", statusid: 440 };
  static UnReleased = { code: "UnReleased", description: "UnReleased", statusid: 431 };
  static PendingOverdue = { code: "Pending: Overdue", description: "Pending: Overdue", statusid: 438 };
  static PriceTBD = { code: "Pending: TBD Choices Remaining", description: "Pending: TBD Choices Remaining",statusid: 434 };
  static SubVendorPrice = { code: "Sub/Vendor Price Needed", description: "Sub/Vendor Price Needed",statusid: 436 };
  static Selected = { code: "Selected", description: "Selected", statusid: 441 };
}


export class warrntyStatusCode {
  static ReadyNextPhase = { code: "Ready Next Phase", description: "Ready Next Phase", statusid: 579 };
  static RescheduleAppt = { code: "Reschedule Appt", description: "Reschedule Appt", statusid: 580 };
  static NeedsRework = { code: "Needs Rework", description: "Needs Rework", statusid: 581 };
  static AwaitingFeedback = { code: "Awaiting Feedback", description: "Awaiting Feedback", statusid: 583 };
}

export class warrntyStatusCodes {
  static ReadyNextPhase = { code: "Ready for Next Phase", description: "Ready for Next Phase" };
  static RescheduleAppt = { code: "Sub Reschedule", description: "Sub Reschedule" };
  static NeedsRework = { code: "Owner Reschedule", description: "Owner Reschedule" };
  static AwaitingFeedback = { code: "Service Completed", description: "Service Completed" };
}





  export class Selectionsetupcode {
     static CSDBV = { code: "CSDBV" };
     static CSDOV = { code: "CSDOV" };
     static CSRSS = { code: "CSRSS" };
}





  export const SelectionStatusCodeColorMap  = {
    [SelectionStatusCode.PendingNoChoice.code]: { backgroundColor: '#fbe9b1', textColor: '#653200' },
    [SelectionStatusCode.PendingAvailable.code]: { backgroundColor: '#fbe9b1', textColor: '#653200' },
    [SelectionStatusCode.ExpiredAvailable.code]: { backgroundColor: '#fbe9b1', textColor: '#653200' },
    [SelectionStatusCode.ExpiredNoChoices.code]: { backgroundColor: '#fbe9b1', textColor: '#653200' }, // corrected from ##fbe9b1
    [SelectionStatusCode.UnReleased.code]: { backgroundColor: '#c7d0d9', textColor: '#202227' },
    [SelectionStatusCode.PendingOverdue.code]: { backgroundColor: '#fbe9b1', textColor: '#653200' },
    [SelectionStatusCode.Selected.code]: { backgroundColor: '#c4fae2', textColor: '#005c35' } // corrected from ##c4fae2
  };






export class StatusSetupProposal extends BaseResponse{
  companyParameterId: number;
  companyParameter: any; // You can replace 'any' with the appropriate type if known
  formNameId: number;
  formName: any; // You can replace 'any' with the appropriate type if known
  lookUpStandardId: number;
  lookUpStandard: any; // You can replace 'any' with the appropriate type if known
  parentCode: string;
  code: string;
  name: string;
  description: string;
  isFieldValue: boolean;
}


