import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, NonNullableFormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { LookupNameSetupResponse, LookupNameSetup } from 'src/Models/LeadTags';
import { LeadOpportunitiesService } from 'src/Service/lead-opportunities.service';

@Component({
  selector: 'app-category-select',
  templateUrl: './category-select.component.html',
  styleUrls: ['./category-select.component.css']
})
export class CategorySelectComponent {

  @Output() tagSaved = new EventEmitter<void>();

  @Input() selectedEditCategory: LookupNameSetupResponse;
  @Output() categoryDeleted = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();
  Tags: boolean = false
  pageId: number = 30;
  isLoadingOne: boolean = false
  maxNameLength = 50;
  deleteLoading = false;
  @Output() categorySaved = new EventEmitter<LookupNameSetupResponse>();  
  @Output() phaseSaved = new EventEmitter<void>();
  @Output() categorySavedSelected = new EventEmitter<any>();  
  @Output() phaseSavedAndNew =new EventEmitter<any>();  

  constructor(private fb: NonNullableFormBuilder,
    private leadOppService: LeadOpportunitiesService,
    public toastService: NzMessageService,
    private modal: NzModalService // Inject NzModalService
    
  ) {

  }


  CategoryNzSelectForm: FormGroup<{
    id: FormControl<string>;
    globalId: FormControl<string>;
    name: FormControl<string>;
    companyParameterId: FormControl<number>;
    formNameId: FormControl<number>;
    lookUpStandardId: FormControl<number>;
    parentCode: FormControl<string>;
    code: FormControl<string>;
    description: FormControl<string>;
    isFieldValue: FormControl<boolean>;
  }>;

  ngOnInit(): void {
    this.initializeForm();

    if (this.selectedEditCategory) {
      this.patchFormValues(this.selectedEditCategory); // Patch form with selectedTag values if available
    }
  }

  onCancel(): void {
    this.cancel.emit(); 
  }
  
  initializeForm() {
    this.CategoryNzSelectForm = this.fb.group({
      id: [''],
      globalId: ['00000000-0000-0000-0000-000000000000'],
      name: ['', [Validators.required, Validators.maxLength(this.maxNameLength)]],
      companyParameterId: [1],
      formNameId: this.pageId,
      lookUpStandardId: [69],
      parentCode: ['PMSLC'],
      code: ['PMSLC'],
      description: [''],
      isFieldValue: [false],

    });
  }


  // Method to patch form values based on the selected tag
  patchFormValues(selectedCategory: any): void {
    this.CategoryNzSelectForm.patchValue({
      id: selectedCategory?.id.toString(),
      globalId: selectedCategory?.globalId,
      name: selectedCategory?.name,
      companyParameterId: selectedCategory?.companyParameterId ?? 1,
      formNameId: selectedCategory?.formNameId,
      lookUpStandardId: selectedCategory?.lookUpStandardId,
      parentCode: selectedCategory?.parentCode,
      code: selectedCategory?.code,
      description: selectedCategory?.description,
      isFieldValue: selectedCategory?.isFieldValue,
    });


  }

  get nameControl(): FormControl<string> {
    return this.CategoryNzSelectForm.get('name') as FormControl<string>;
  }

  getOverCharacters(): number {
    const value = this.nameControl.value || '';
    return value.length - this.maxNameLength;
  }

  // Method to check if the character count exceeds the limit
  isOverLimit(): boolean {
    return this.nameControl.value.length > this.maxNameLength;
  }

CategoryNzSelectDropdownSaveMethod() {
  if (this.CategoryNzSelectForm.valid) {
    this.isLoadingOne = true;
    this.leadOppService.getLookUpNameSetup(this.CategoryNzSelectForm.value as LookupNameSetup).subscribe(
      response => {
        this.initializeForm(); // Reset the form on success
        this.isLoadingOne = false;
        this.toastService.success('Saved successfully Category');
        this.categorySaved.emit(response.result);  // Ensure this emits the correct data
        this.cancel.emit(); // Emit the cancel event
      },
      (error) => {
        this.isLoadingOne = false;
        if (error.status === 500) {
          this.toastService.error('Internal Server Error. Please try again later.');
        } else if (error.status === 400) {
          this.toastService.error('Bad Request. Please check your input.');
        } else {
          this.toastService.error('Failed to save trade. Please try again.');
        }
      }
    );
  } else {
    Object.values(this.CategoryNzSelectForm.controls).forEach(control => {
      if (control.invalid) {
        control.markAsDirty();
        control.updateValueAndValidity({ onlySelf: true });
      }
    });
  }
}



  updateConfirmValidator(): void {
    Promise.resolve().then(() => this.CategoryNzSelectForm.controls.name.updateValueAndValidity());
  }

  confirmationValidator: ValidatorFn = (control: AbstractControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true };
    } else if (control.value !== this.CategoryNzSelectForm.controls.name.value) {
      return { confirm: true, error: true };
    }
    return {};
  };




  AddNewPhaseCancel() {
    this.Tags = false
  }


  showCategoryConfirmDelete(): void {
    this.modal.confirm({
      nzTitle: 'Delete Schedule Item Tag?',
      nzContent: 'Are you sure you want to delete this Schedule Item Tag',
      nzOkText: 'Delete',
      nzCentered: true,
      nzOkDanger: true,
      nzOkLoading: this.deleteLoading, 
      nzOnOk: () => {
        this.deleteLoading = true; 
        return this.onDeleteCategory().then(() => {
          this.deleteLoading = false; 
        }).catch(() => {
          this.deleteLoading = false; 
        });
      },
      nzCancelText: 'Cancel',
      nzOnCancel: () => console.log('Delete action canceled')
    });
  }
  
  onDeleteCategory(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.selectedEditCategory && this.selectedEditCategory.globalId) {
        this.leadOppService.deleteDataLookupNameSetup(this.selectedEditCategory.globalId).subscribe(
          () => {
            this.toastService.success('Deleted successfully');
            this.cancel.emit(); 
            this.phaseSaved.emit();
            this.categoryDeleted.emit();
            resolve(); 
          },
          err => {
            this.toastService.error('Failed to delete phase');
            reject(err); 
          }
        );
      } else {
        reject('No phase selected to delete');
      }
    });
  }

}
