import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';
import { NzStatus } from 'ng-zorro-antd/core/types';
import { ChangeOrderGetById, ChangeOrderInformationResponse } from 'src/Models/ChangeOrder/ChangeOrder';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { LeadProposalsService } from 'src/Service/LeadProp/lead-proposals.service';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { CostItemParameterResponse, CostItemResponse } from 'src/Models/LeadProposal/LeadProposalModels';
import { CostCodeCategoryResponse } from 'src/Models/InternalUser/applicationUserResponse';
import { LeadProposalsPreDefinedCodes } from 'src/app/shared/component/Models/LookUpStandardFilterCodes';
import { ChangeOrderSetupResponse, LookupNameSetupResponse } from 'src/Models/LeadTags';
import { Editor, EditorConfig, initializeEditor } from 'src/app/helpers/ckeditor-config';
import { SignatureComponent } from 'src/app/project-management/components/Variations/signature/signature.component';
import { SignatureSetupResponse } from 'src/Models/SignaturePad/SignaturePad';
import { ChangeOrderInformationService } from 'src/Service/Change_Order/change-order-information.service';
import { CostItemService } from 'src/Service/Cost_Item/cost-item.service';
import { AttachmentService } from 'src/Service/Attachment/attachment.service';
import { BehaviorsubjectService } from 'src/Service/behaviorsubject/behaviorsubject.service';
import { saveAttachments } from 'src/app/helpers/Attachment_Save';
import { LeadOpportunitiesService } from 'src/Service/lead-opportunities.service';
import { AttachmentResponse, CustomUploadFile } from 'src/Models/Attachment_Files_Class/AttachmentFilesClass';
import { JobInformationService } from 'src/Service/Job-List/Job-Information/job-information.service';
import { JobShortInformation } from 'src/Models/Job-Scratch/JobFromScratch';
import { SubVendorService } from 'src/Service/CompanyInFormation/Selections/sub-vendor.service';
interface Person {
  key: string;
  name: string;
  age: number;
  address: string;
}
@Component({
  selector: 'app-change-order',
  templateUrl: './change-order.component.html',
  styleUrls: ['./change-order.component.css']
})

export class ChangeOrderComponent  implements OnInit, AfterViewInit {
  @ViewChild('carouselContainer', { static: false }) carouselContainer!: ElementRef<HTMLDivElement>;
  @ViewChild('titleInput') titleInput: ElementRef<HTMLInputElement>;
  @Output() onSaveComplete = new EventEmitter();
  @Input() ChangeOrder: ChangeOrderInformationResponse;
  initializationComplete = new EventEmitter<boolean>();
  @Input() onSelectedJob_Id: number;
  @Output() cancel = new EventEmitter<void>();
  @Input() ChangeOrderResponseId: number;
  isVisibleInChangeOrder: boolean = true;
  ChangeOrderFormFieldsValue: any = {};
  CostItemFormFieldsValue: any = {};
  builderCostFeild: any = '0.00';
  ownerPriceFeild: any = '0.00';
  LineItemsBTN: boolean = false;
  FlateFreeBTN: boolean = false;
  validationStates: { [key: string]: NzStatus } = {};
  validationStatesItem: { [key: string]: NzStatus } = {};
  validationMessages: { [key: string]: string } = {};
  validationMessagesItem: { [key: string]: string } = {};
  touchedFields: { [key: string]: boolean } = {};
  touchedFieldsItem: { [key: string]: boolean } = {};
  isSubmitted: boolean = false;
  isRowVisible1: boolean = false;
  isRowVisible: boolean = false;
  markupOption: LookupNameSetupResponse[] = [];
  items: any[] = [];
  leadOptions: CostCodeCategoryResponse[] = [];
  JobGroupNodes: NzTreeNodeOptions[] = [];
  leadOpportunitiesCost: CostItemParameterResponse[];
  formFieldValuesChangeOrders: any = {};
  AutoNumberExistingFormFieldsValues: any = {};
  totalOwnerPrice: number = 0;
  totalBuilderCost: number = 0;
  estimatedProfit: number = 0;
  showNavButtons = false;
  currentIndex = 0;
  isSaveAndClose: boolean = false;
  isSaveAndRelease: boolean = false;
  isSaveAndUnrelease: boolean = false;
  isSaveAndAndResetToPending: boolean = false;
  isSave: boolean = false;
  DisabledNextActivity: boolean = false;
  tabs = [1, 2, 3,];
  rowAddItemGrid: boolean = false;
  ChangeOrderInformationResponse: ChangeOrderGetById;
  public Editor = Editor;
  public config = EditorConfig;
  JobInfoId: number;
  ChangeOrderInfoId: number;
  Messaging_Id: number;
  formNameId: number = 7;
  priceInformationradioValue = 'B';
  isDeleteLoading: boolean = false;
  TestAttachment: AttachmentResponse;
  selectedFilesAttachment: CustomUploadFile[] = [];
  SignatureRes: SignatureSetupResponse;
  currentDate: Date = null;
  Approved: boolean = false;
  Declined: boolean = false;
  isSetPriceLater: boolean = false;
  showBTN: boolean = false
  GetByIdRes: ChangeOrderGetById;
  test: any = undefined
  onGetByIdRes: ChangeOrderGetById;
  _ChangeOrderSetupResponse: ChangeOrderSetupResponse;
  combinedString: string;
  status: number = 0;
  statusText: string = '';
  statusClass: string = '';
  isFlatFeeConfirmed: boolean = false;
  onTooltipValue: string[] = [];
  onJobShortInformation: JobShortInformation;
  SubVenNodes: NzTreeNodeOptions[] = [];

  preDefinedDataCodes = {
    costType: LeadProposalsPreDefinedCodes.CostType,
    markup: LeadProposalsPreDefinedCodes.Markup,
  };
  listOfData: Person[] = [
    {
      key: '1',
      name: 'John Brown',
      age: 32,
      address: 'New York No. 1 Lake Park'
    },
  ];

  constructor(private modal: NzModalService,
    private _ChangeOrderInformationService: ChangeOrderInformationService,
    private _CostItemService: CostItemService,
    private _AttachmentService: AttachmentService,
    private _BehaviorSubjectService: BehaviorsubjectService,
    public localStorageService: AccessLocalStorageService,
    private _NzMessageService: NzMessageService,
    private LookupFilterService: LookupFilterService,
    private leadProposalsService: LeadProposalsService,
    private _LeadOpportunitiesService: LeadOpportunitiesService,
    private cd: ChangeDetectorRef,
    private jobInfoService: JobInformationService,
    private _SubVendorService: SubVendorService,
  ){}
  ngAfterViewInit(): void {
    initializeEditor(this.Editor, this.config);
  }
  ngOnInit(): void {
    this.createChangeOrderForm();
    this.getLeadData();
    this.onGetCostType();
    this.GetAllBySubVendor();
    if (this.ChangeOrderResponseId > 0) {
      this.ChangeOrderGetById(this.ChangeOrderResponseId);
    }
    if (this.onSelectedJob_Id > 0) {
      this.fetchChangeOrderAndJob(this.onSelectedJob_Id);
    }
  }
  GetAllBySubVendor(){
    this._SubVendorService.getData().subscribe((res) => {
      this.SubVenNodes = [
        {
          title: 'Check All',
          value: 'check_all',
          key: 'check_all',
          selectable: false,
          isLeaf: false,
          expanded: true,
          children: res?.result?.map((sub) => ({
            title: sub?.companyName,
            key: sub?.id.toString(),
            value: sub?.id.toString(),
            isLeaf: true,
          }))
        }
      ];
    });
  }
  private focusTitleInput(): void {
    setTimeout(() => {
      if (this.titleInput) {
        this.titleInput.nativeElement.focus();
      }
    }, 1000);
  }
  CancelChangeOrder() {
   this.isVisibleInChangeOrder = false;
    this.cancel.emit();
  }
  onFilesUploaded(files: CustomUploadFile[]): void {
    this.selectedFilesAttachment = files
  }
  createChangeOrderForm() {
    this.ChangeOrderFormFieldsValue = {
      globalId: '00000000-0000-0000-0000-000000000000',
      jobSetupId: 0,
      changeOrderSetupId: 0,
      jobInformationId: 0,
      title: '',
      idNumber: '',
      autoAssignNumber: '',
      rfiFullNumber: '',
      approvalDeadlineOn: null,
      isShowLineItemsToOwner: false,
      builderCost: 0,
      ownerPrice: 0,
      internalNotes: '',
      subVendorNotes: '',
      ownerNotes: '',
      approvalSignature: '',
      ownerComments: '',
      changeOrderDescription: '',
      // comments: '',
      // costItemId: 0,
      // messagingId: 0,
      rfiInformationId: 0,
      statusId: 0,
      approved: false,
      decline: false,
      signatureSetupId: 0,
      scheduleItemParameterId: 0,
      ownerReason: '',
      isConfirmOwner: false,
      ownerSignatureSetupId: 0,
      ownerApproved: false,
      ownerDecline: false,
      createChangeOrderSubVendorRequests: [],
    };
  }
  ChangeOrderGetById(_Id: number){
    if (_Id) {
      this._ChangeOrderInformationService.getDataIdChangeOrder(_Id).subscribe(
        (res) => {
          this.GetByIdRes = res.result
          this.patchFormValues(res.result);
          this.ChangeOrderCostItemGetById(res.result?.CostItemId);
          if (res.result?.JobInformationId > 0) {
            this.fetchChangeOrderAndJob(res.result?.JobInformationId);
            this.ChangeOrderTooltipValueGetById(res.result?.JobInformationId);
          }
        },
      )
    }
  }
  ChangeOrderCostItemGetById(_Id: number){
    this._CostItemService.getDataIdCostItem(_Id).subscribe(
      (res) => {
        if (res.result) {
         this.patchCostItemFormValues(res.result);
        }
      },
    )
  }
  ChangeOrderTooltipValueGetById(_Id: number){
    if (this.ChangeOrderResponseId) {
      this._ChangeOrderInformationService.onChangeOrderGetByTooltipValue(_Id).subscribe(
        (res) => {
          let apiResponse: string = res?.result
          let trimmedArray: string[] = apiResponse.split('\r\n');
          this.onTooltipValue = trimmedArray
        },
      )
    }
  }
  patchFormValues(ChangeOrderInfoResponse: ChangeOrderGetById) {
    this.priceInformationradioValue = 'A'
    this.isFlatFeeConfirmed = true;
    this.currentDate = ChangeOrderInfoResponse?.ApprovalDeadlineOn
    this.Approved = ChangeOrderInfoResponse?.Approved;
    this.Declined = ChangeOrderInfoResponse?.Decline;
    if(this.Approved === true || this.Declined === true){
      this.test = true
      this.CheckApplicationId(this.localStorageService.getUserLoginId());
    } else {
      this.SignatureRes = undefined
    }
    this.setStatusInfo(ChangeOrderInfoResponse?.StatusId);
    this.onGetByIdRes = ChangeOrderInfoResponse;
    this.JobInfoId = ChangeOrderInfoResponse?.JobInformationId;
    this.ChangeOrderInfoId = ChangeOrderInfoResponse?.Id;
    this.Messaging_Id = ChangeOrderInfoResponse?.MessagingId;
    this.ChangeOrderFormFieldsValue = {
      globalId: ChangeOrderInfoResponse?.GlobalId || '00000000-0000-0000-0000-000000000000',
      jobSetupId: ChangeOrderInfoResponse?.JobSetupId || 0,
      changeOrderSetupId: ChangeOrderInfoResponse?.ChangeOrderSetupId || 0,
      jobInformationId: ChangeOrderInfoResponse?.JobInformationId || 0,
      title: ChangeOrderInfoResponse?.Title || '',
      idNumber: ChangeOrderInfoResponse?.idNumber || '',
      autoAssignNumber: ChangeOrderInfoResponse?.AutoAssignNumber || '',
      rfiFullNumber: ChangeOrderInfoResponse?.RfiFullNumber || '',
      approvalDeadlineOn: ChangeOrderInfoResponse?.ApprovalDeadlineOn || null,
      isShowLineItemsToOwner: ChangeOrderInfoResponse?.IsShowLineItemsToOwner || false,
      builderCost: ChangeOrderInfoResponse?.BuilderCost || 0,
      ownerPrice: ChangeOrderInfoResponse?.OwnerPrice || 0,
      internalNotes: ChangeOrderInfoResponse?.InternalNotes || '',
      subVendorNotes: ChangeOrderInfoResponse?.SubVendorNotes || '',
      ownerNotes: ChangeOrderInfoResponse?.OwnerNotes || '',
      approvalSignature: ChangeOrderInfoResponse?.ApprovalSignature || '',
      ownerComments: ChangeOrderInfoResponse?.OwnerComments || '',
      changeOrderDescription: ChangeOrderInfoResponse?.ChangeOrderDescription || '',
      // comments: ChangeOrderInfoResponse?.Comments || '',
      // costItemId: ChangeOrderInfoResponse?.CostItemId || 0,
      // attachmentId: ChangeOrderInfoResponse?.AttachmentId || 0,
      // messagingId: ChangeOrderInfoResponse?.MessagingId || 0,
      rfiInformationId: ChangeOrderInfoResponse?.RFIId || 0,
      statusId: ChangeOrderInfoResponse?.StatusId || 0,
      approved: ChangeOrderInfoResponse?.Approved || false,
      decline: ChangeOrderInfoResponse?.Decline || false,
      signatureSetupId: ChangeOrderInfoResponse?.SignatureSetupId || 0,
      scheduleItemParameterId: ChangeOrderInfoResponse?.ScheduleItemParameterId || 0,
      ownerReason: ChangeOrderInfoResponse?.OwnerReason || '',
      isConfirmOwner: ChangeOrderInfoResponse?.IsConfirmOwner || false,
      ownerSignatureSetupId: ChangeOrderInfoResponse?.OwnerSignatureSetupId || 0,
      ownerApproved: ChangeOrderInfoResponse?.IsOwnerApproved || false,
      ownerDecline: ChangeOrderInfoResponse?.IsOwnerDecline || false,
      createChangeOrderSubVendorRequests: ChangeOrderInfoResponse?.SubVendorIds? ChangeOrderInfoResponse?.SubVendorIds.split(',').map(id => id.trim()): [],

    };
    this.fetchAttachments(ChangeOrderInfoResponse?.AttachmentId);
    this.initializationComplete.emit(false);
  }
  patchCostItemFormValues(costItemResponse: CostItemResponse) {
    this.CostItemFormFieldsValue = {
      globalId: costItemResponse?.globalId || '00000000-0000-0000-0000-000000000000',
      headerId: costItemResponse?.headerId || 0,
      companyParameterId: costItemResponse?.companyParameterId || 0,
      formNameId: costItemResponse?.formNameId || 0,
      // isFlatFee: costItemResponse?.isFlatFee || 0,
      // isLineItems: costItemResponse?.isLineItems || 0,
      builderCost: costItemResponse?.builderCost || 0,
      ownerPrice: costItemResponse?.ownerPrice || 0,
    }
    // if(costItemResponse?.isFlatFee === true){
    //   this.priceInformationradioValue = 'A'
    //   this.isFlatFeeConfirmed = true;
    // } else {
    //   this.priceInformationradioValue = 'B'
    //   this.isFlatFeeConfirmed = false;
    // }
    // this.priceInformationradioButtons();
    // this.CostItemFormFieldsValue = costItemResponse?.costItemParameters.map((CostItemParam) => ({
    //     title: CostItemParam?.title || '',
    //     description: CostItemParam?.description || '',
    //     internalNotes: CostItemParam?.internalNotes || '',
    //   }))
    // console.log('this.CostItemFormFieldsValue',this.CostItemFormFieldsValue);
    // this.CostItemFormFieldsValue = {
    //   globalId: costItemResponse?.globalId || '00000000-0000-0000-0000-000000000000',
    //   headerId: costItemResponse?.headerId || 0,
    //   companyParameterId: costItemResponse?.companyParameterId || 0,
    //   formNameId: costItemResponse?.formNameId || 0,
    //   isFlatFee: costItemResponse?.isFlatFee || 0,
    //   isLineItems: costItemResponse?.isLineItems || 0,
    //   builderCost: costItemResponse?.builderCost || 0,
    //   ownerPrice: costItemResponse?.ownerPrice || 0,
    //   createCostItemParameterRequests: costItemResponse?.createCostItemParameterRequests.map((CostItemParam) => ({
    //     title: CostItemParam?.title || '',
    //     description: CostItemParam?.description || '',
    //     internalNotes: CostItemParam?.internalNotes || '',
    //     unit: CostItemParam?.unit || 0,
    //     unitCost: CostItemParam?.unitCost || 0,
    //     builderCost: CostItemParam?.builderCost || 0,
    //     markupValue: CostItemParam?.markupValue || 0,
    //     markupId: CostItemParam?.markupId || 0,
    //     ownerPrice: CostItemParam?.ownerPrice || 0,
    //     margin: CostItemParam?.margin || 0,
    //     profit: CostItemParam?.profit || 0,
    //     costCodeId: CostItemParam?.costCodeId || 0,
    //     costTypeId: CostItemParam?.costTypeId || 0,
    //     markAsId: CostItemParam?.markAsId || 0,
    //     costTypeItemParameters: CostItemParam?.costTypeItemParameters || []
    //   }))
    // }
  }
  fetchAttachments(AttachmentId: number) {
    if (AttachmentId) {
      this._AttachmentService.getLeadByAttachmentId(AttachmentId).subscribe(
        (res) => {
          this.TestAttachment = res.result
        },
      );
    } else {
      this.TestAttachment = undefined;
    }
  }
  saveChangeOrder(actionType: 'save' | 'saveAndClose' | 'saveAndRelease' | 'saveAndUnrelease' | 'saveAndAndApprove' | 'saveAndAndDeclined' | 'saveAndAndResetToPending' = 'save'): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.isSave || this.isSaveAndClose || this.isSaveAndRelease || this.isSaveAndUnrelease || this.isSaveAndAndResetToPending) {
        return reject('Already processing');
      }

      switch (actionType) {
        case 'saveAndRelease':
          this.isSaveAndRelease = true;
          break;
        case 'saveAndClose':
          this.isSaveAndClose = true;
          break;
        case 'saveAndUnrelease':
          this.isSaveAndUnrelease = true;
          break;
        case 'saveAndAndApprove':
          this.isSave = true;
          break;
        case 'saveAndAndDeclined':
          this.isSave = true;
          break;
        case 'saveAndAndResetToPending':
          this.isSaveAndAndResetToPending = true;
          break;
        default:
          this.isSave = true;
      }
      this.currentDate = new Date();
      const approvalDeadlineDate = new Date(this.ChangeOrderFormFieldsValue.approvalDeadlineOn);

      if (actionType === 'saveAndRelease') {
        this.SignatureRes = undefined
        this.ChangeOrderFormFieldsValue.decline = false
        this.ChangeOrderFormFieldsValue.approved = false
        if (approvalDeadlineDate >= this.currentDate || this.ChangeOrderFormFieldsValue.approvalDeadlineOn === null) {
          this.ChangeOrderFormFieldsValue.statusId = 362;
        } else {
          this.ChangeOrderFormFieldsValue.statusId = 363;
        }
      } else if (actionType === 'saveAndClose') {
        if(this.ChangeOrderInformationResponse?.StatusId === 363 || this.GetByIdRes?.StatusId === 363){
          this.ChangeOrderFormFieldsValue.statusId = 363;
        } else if(this.ChangeOrderInformationResponse?.StatusId === 364 || this.GetByIdRes?.StatusId === 364){
          this.ChangeOrderFormFieldsValue.statusId = 364;
        } else if(this.ChangeOrderInformationResponse?.StatusId === 365 || this.GetByIdRes?.StatusId === 365){
          this.ChangeOrderFormFieldsValue.statusId = 365;
        } else if(this.ChangeOrderInformationResponse?.StatusId === 362 || this.GetByIdRes?.StatusId === 362){
          this.ChangeOrderFormFieldsValue.statusId = 362;
        } else {
          this.ChangeOrderFormFieldsValue.statusId = 361;
        }
      } else if (actionType === 'saveAndUnrelease') {
        this.SignatureRes = undefined
        this.ChangeOrderFormFieldsValue.decline = false
        this.ChangeOrderFormFieldsValue.approved = false
        this.ChangeOrderFormFieldsValue.statusId = 361;
      } else if (actionType === 'saveAndAndApprove') {
        this.ChangeOrderFormFieldsValue.statusId = 364;
      } else if (actionType === 'saveAndAndDeclined') {
        this.ChangeOrderFormFieldsValue.statusId = 365;
      } else if(actionType === 'saveAndAndResetToPending'){
        this.ChangeOrderFormFieldsValue.decline = false
        this.ChangeOrderFormFieldsValue.approved = false
        this.ChangeOrderFormFieldsValue.statusId = 362;
      } else if(actionType === 'save'){
        if(this.ChangeOrderInformationResponse?.StatusId === 363 || this.GetByIdRes?.StatusId === 363){
          this.ChangeOrderFormFieldsValue.statusId = 363;
        } else if(this.ChangeOrderInformationResponse?.StatusId === 364 || this.GetByIdRes?.StatusId === 364){
          this.ChangeOrderFormFieldsValue.statusId = 364;
        } else if(this.ChangeOrderInformationResponse?.StatusId === 365 || this.GetByIdRes?.StatusId === 365){
          this.ChangeOrderFormFieldsValue.statusId = 365;
        } else if(this.ChangeOrderInformationResponse?.StatusId === 362 || this.GetByIdRes?.StatusId === 362){
          this.ChangeOrderFormFieldsValue.statusId = 362;
        } else {
          this.ChangeOrderFormFieldsValue.statusId = 361;
        }
      }
      this.DisabledNextActivity = true;

      this.isSubmitted = true;
      this.checkErrors();
      if (this.rowAddItemGrid === true) {
        this.checkErrorsItem();
      }
      if (Object.values(this.validationStates).includes('error')) {
        setTimeout(() => {
          const errorElements = document.querySelectorAll('.error-message');
          if (errorElements.length > 0) {
            const lastErrorElement = errorElements[errorElements.length - 1];
            lastErrorElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        }, 100);

        this.resetFlags();
        this.DisabledNextActivity = false;
        return reject('Validation errors present');
      }
      this.AutoNumberExistingFormFieldsValues = {
        jobInformationId:this.onJobShortInformation?.id,
        autoAssignNumber: this.ChangeOrderFormFieldsValue.autoAssignNumber,
        formNameId: 7,
      }

      this._ChangeOrderInformationService.PostChangeOrderAndAutoNumberExisting(this.AutoNumberExistingFormFieldsValues).subscribe(res => {
       if (res?.result === true && this.ChangeOrderFormFieldsValue.globalId == '00000000-0000-0000-0000-000000000000') {
         this._NzMessageService.error('The Custom CO# has already been used for this jobsite');
         this.resetFlags();
         this.DisabledNextActivity = false;
         return reject('Validation errors present');
       } else {
        this.combinedString = `${this._ChangeOrderSetupResponse?.changeOrderPrefix} ${this.onJobShortInformation?.JobPrefix}`;
        this.ChangeOrderFormFieldsValue.idNumber = this.combinedString;
        this.ChangeOrderFormFieldsValue.jobInformationId = this.onJobShortInformation?.id;


         let selectedSubVen = Array.isArray(this.ChangeOrderFormFieldsValue.createChangeOrderSubVendorRequests)
         ? this.ChangeOrderFormFieldsValue.createChangeOrderSubVendorRequests.map((id: string) => ({ subVendorId: id }))
         : [];
  
         let formDataToSend = {
           ...this.ChangeOrderFormFieldsValue,
           createChangeOrderSubVendorRequests: selectedSubVen,
         };
         this._ChangeOrderInformationService.PostChangeOrder(formDataToSend).subscribe(
           (res) => {
             this.ChangeOrderInformationResponse = res.result;
             this.Approved = res.result?.Approved;
             this.Declined = res.result?.Decline;
             this.setStatusInfo(this.ChangeOrderInformationResponse?.StatusId);
             this.JobInfoId = res.result?.JobInformationId;
             this.ChangeOrderInfoId = res.result?.Id;
             this.ChangeOrderFormFieldsValue.globalId = res.result.GlobalId;
             let costTypeInformation = this.CostItemFormFieldsValue.costTypeItemParameters?.map(
               (id: string) => ({ costTypeId: id })
             );
   
             let formDataToSend = {
               ...this.CostItemFormFieldsValue,
               costTypeItemParameters: costTypeInformation,
             };
   
   
             let costItemRequest: CostItemResponse = {
               globalId: this.CostItemFormFieldsValue.globalId,
               headerId: res.result?.Id,
               companyParameterId: 1,
               formNameId: 7,
               isFlatFee: this.FlateFreeBTN,
               isLineItems: this.LineItemsBTN,
               builderCost: this.CostItemFormFieldsValue?.builderCostFeild,
               ownerPrice: this.CostItemFormFieldsValue?.ownerPriceFeild,
               isSetPriceLater  : this.isSetPriceLater,
               costItemParameters: this.items,
             };
   
             this._CostItemService.postCostItem(costItemRequest).subscribe(
               (response) => {
                 this.isSubmitted = false;
                 if (this.selectedFilesAttachment?.length > 0) {
                   saveAttachments(this.selectedFilesAttachment, this._AttachmentService, res.result?.Id, 7)
                     .then((attachmentResponseId) => {
                       if (attachmentResponseId > 0) {
                         this.selectedFilesAttachment = []
                         this.ChangeOrderGetById(this.ChangeOrderInformationResponse?.Id);
                         this._BehaviorSubjectService.setLeadActivityTypeResponse(res.result);
                       }
                       resolve();
                     })
                     .catch((error) => {
                       reject(error);
                     });
                 } else {
                   this.ChangeOrderGetById(this.ChangeOrderInformationResponse?.Id);
                   this._BehaviorSubjectService.setLeadActivityTypeResponse(res.result);
                   resolve();
                 }
               },
               (error) => reject(error)
             );
             if(this.SignatureRes?.id === undefined || this.test === true){
               this._NzMessageService.success('Change Order saved successfully');
             }
           },
           (error) => {
             this._NzMessageService.error('An error has occurred. Please try again.');
             reject(error);
           }
         ).add(() => {
           this.resetFlags();
           this.isSaveAndRelease = false;
           this.isSaveAndUnrelease = false;
           this.isSave = false;
           this.DisabledNextActivity = false;
         });
       }
     });
    });
  }
  resetFlags(): void {
    this.isSave = false;
    this.isSaveAndRelease = false;
    this.isSaveAndUnrelease = false;
    this.isSaveAndAndResetToPending = false;
    this.isSaveAndClose = false;
  }
  saveChangeOrderAndRelease(): Promise<void>{
    return this.saveChangeOrder('saveAndRelease');
  }
  saveChangeOrderAndClose(): Promise<void>{
    return this.saveChangeOrder('saveAndClose').then(() => {
      this.CancelChangeOrder();
      this.isSaveAndClose = false;
      this.resetForm();
    }).catch((error) => {
      console.error('Error during save and close:', error);
    })
  }
  UnreleaseChangeOrderConfirm(): void {
    if (this.isSaveAndUnrelease) {
      return;
    }

    this.modal.confirm({
      nzTitle: `Unrelease Change Order?`,
      nzContent: `Are you sure you want to reset this Change Order to Unreleased?`,
      nzOkText: `Unrelease`,
      nzCancelText: 'Cancel',
      nzOnOk: () => {
        this.isSaveAndUnrelease = false;
        return this.saveChangeOrderAndUnrelease()
          .then(() => {
          })
          .catch(() => {});
      },
      nzCentered: true,
      nzOkLoading: this.isSaveAndUnrelease,
      nzBodyStyle: { 'border-radius': '50px', 'height': 'auto', 'padding-top': '15' },
      nzCloseIcon: '',
      nzIconType: ''
    });
  }
  saveChangeOrderAndUnrelease(): Promise<void>{
    return this.saveChangeOrder('saveAndUnrelease').then(() => {
      this.isSaveAndUnrelease = false;
      this.SignatureRes = undefined
    }).catch((error) => {
      this.isSaveAndUnrelease = false;
      console.error('Error during save and close:', error);
    });
  }
  resetForm(): void {
    this.ChangeOrderFormFieldsValue = {}
  }
  ResetChangeOrderConfirm(): void {
    if (this.isSaveAndAndResetToPending) {
      return;
    }
    this.isSaveAndAndResetToPending = true;
    this.modal.confirm({
      nzTitle: `Reset Change Order?`,
      nzContent: `Are you sure you want to reset this Change Order to Pending?`,
      nzOkText: `Reset`,
      nzCancelText: 'Cancel',
      nzOnOk: () => {
        this.isSaveAndAndResetToPending = false;
        return this.saveChangeOrderAndResetToPendig()
          .then(() => {
          })
          .catch(() => {});
      },
      nzOnCancel: () => {
        this.isSaveAndAndResetToPending = false;
      },
      nzCentered: true,
      nzOkLoading: this.isSaveAndUnrelease,
      nzBodyStyle: { 'border-radius': '50px', 'height': 'auto', 'padding-top': '15' },
      nzCloseIcon: '',
      nzIconType: ''
    });
  }
  saveChangeOrderAndResetToPendig(): Promise<void>{
    return this.saveChangeOrder('saveAndAndResetToPending').then(() => {
      this.showBTN = true
      this.SignatureRes = undefined;
    }).catch((error) => {
      this.SignatureRes = undefined;
      console.error('Error during save and close:', error);
    });
  }
  deleteChangeOrderConfirm(): void {
    this.modal.confirm({
      nzTitle: `Delete Change Order?`,
      nzContent: `Are you sure you want to permanently delete this Change Order?`,
      nzOkText: `Delete`,
      nzOkDanger: true,
      nzCancelText: 'Cancel',
      nzOnOk: () => {
        this.isDeleteLoading = true;
        return this.delete(this.ChangeOrderFormFieldsValue.globalId)
          .then(() => {
            this.CancelChangeOrder();
          })
          .catch(() => {

          });
      },
      nzCentered: true,
      nzOkLoading: this.isDeleteLoading,
      nzBodyStyle: { 'border-radius': '50px', 'height': 'auto', 'padding-top': '15' },
      nzCloseIcon: '',
      nzIconType: ''
    });
  }
  delete(id: number): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!id) {
        this._NzMessageService.error('No record selected for deletion.');
        this.isDeleteLoading = false;
        reject();
        return;
      }

      this._ChangeOrderInformationService.deleteData(id).subscribe(
        (res) => {
          this._BehaviorSubjectService.setLeadActivityTypeResponse(res);
          this.CancelChangeOrder();
          this.isDeleteLoading = false;
          resolve();
        },
        (error) => {
          this.isDeleteLoading = false;
          this._NzMessageService.error('An error occurred while deleting the record. Please try again.');
          reject();
        }
      );
    });
  }
  setStatusInfo(Status_Id: number) {
    this.status = Status_Id

    switch (this.status) {
      case 361:
        this.statusText = 'Pending (not released) as of';
        this.statusClass = 'Pending-not-released';
        this.currentDate = new Date();
        break;
      case 362:
        this.statusText = 'Pending Action as of';
        this.statusClass = 'pending';
        this.currentDate = new Date();
        break;
      case 363:
        this.statusText = 'Expired as of';
        this.statusClass = 'danger';
        const formDateValue = this.ChangeOrderFormFieldsValue.approvalDeadlineOn;
        if(formDateValue < this.currentDate){
          this.currentDate = formDateValue
        }
        break;
      case 364:
        this.statusText = 'Manually Approved by Muhammad Yousuf on';
        this.statusClass = 'success';
        this.currentDate = new Date();
        break;
      case 365:
        this.statusText = 'Manually Declined by Muhammad Yousuf on';
        this.statusClass = 'danger';
        this.currentDate = new Date();
        break;
      default:
        this.statusText = '';
        this.statusClass = '';
        break;
    }
  }
  // priceInformationradioButtons(){
  //   switch (this.priceInformationradioValue) {
  //     case 'A':
  //       this.LineItemsBTN = false
  //       this.FlateFreeBTN = true
  //       break;
  //       case 'B':
  //         this.FlateFreeBTN = false
  //         this.LineItemsBTN = true
  //      break;
  //    }
  // }
  onRadioChange(value : any): void {
    if(value === 'A') {
      this.modal.confirm({
        nzTitle: 'Switch to Flat Fee?',
        nzContent: 'Upon save, all line items will be removed permanently and any invoice previously created from this change order will be unlinked.',
        nzOkText: 'Use Flat Fee',
        nzCancelText: 'Cancel',
        nzOnOk: () => {
         this.FlateFreeBTN = true;
         this.isFlatFeeConfirmed = true;
        },
        nzOnCancel: () => {
          this.isFlatFeeConfirmed = false;
          this.LineItemsBTN = true;
          this.priceInformationradioValue = 'B';
        }
      });
  } else if (value === 'B') {
    this.isFlatFeeConfirmed = false;
    this.priceInformationradioValue = 'B';
  }
  }
  getLeadData() {
    this.leadProposalsService.getAllLeadData().subscribe(data => {
      this.leadOptions = data.result;
    });
  }
  onGetCostType(){
    this.LookupFilterService.getLookUpValuesByFormNameId(37).subscribe(
      (res) => {
        let JobGroupValues = res.result.filter(
          (x) => x.code === this.preDefinedDataCodes.costType.code
        );
        let markupValues = res.result.filter((x) => x.code === this.preDefinedDataCodes.markup.code);
        this.markupOption = markupValues;
        // this.items.map(mk => mk.markup) = this.markupOption[0].id
        // console.log(this.items.markup,this.items.markup);
        this.JobGroupNodes = [
          {
            title: 'Check All',
            value: 'check_all',
            key: 'check_all',
            selectable: false,
            isLeaf: false,
            expanded: true,
            children: JobGroupValues.map((JobGroup) => ({
              title: JobGroup.name,
              key: JobGroup.id.toString(),
              value: JobGroup.id.toString(),
              isLeaf: true,
            }))
          }
        ];
        this.initializationComplete.emit(false);
      });
  }
  deleteRow(index: number): void {
    this.modal.confirm({
      nzTitle: `Delete Line Item?`,
      nzContent: `Are you sure you want to delete the selected Change Order Line Item?`,
      nzOkText: `Delete`,
      nzOkDanger: true,
      nzCancelText: 'Cancel',
      nzOnOk: () => {
        return this.deleteItem(index)
      },
      nzCentered: true,
      nzBodyStyle: { 'border-radius': '50px', 'height': 'auto', 'padding-top': '15' },
      nzCloseIcon: '',
      nzIconType: ''
    });
  }
  deleteItem(index: number){
    this.items.splice(index, 1);
  }
  addNewRow(): void {
    this.rowAddItemGrid = true;

    // const hasErrors = this.items.some((item, index) => {
    //   if (!item.costTypeId) {
    //     this.validationStatesItem['costTypeId_' + index] = 'error';
    //     this.validationMessagesItem['costTypeId_' + index] = 'Required';
    //     return true;
    //   }
    //   return false;
    // });

    // if (hasErrors) {
    //   this.checkErrorsItem();
    //   return;
    // }

    // if (this.isSubmitted === true) {
    //   this.onErrorNull();
    //   this.checkErrorsItem();
    // }
    this.CostItemFormFieldsValue = {
      globalId: '00000000-0000-0000-0000-000000000000',
      title: '',
      description: '',
      internalNotes: '',
      quantity: 0,
      unit: 0,
      unitCost: 0,
      builderCost: 0,
      markupValue: 0,
      markupId: 0,
      ownerPrice: 0,
      margin: 0,
      profit: 0,
      costCodeId: 0,
      costTypeId: 0,
      markAsId: 0,
      costTypeItemParameters: []
    };
    console.log('this.CostItemFormFieldsValueewqeqwe',this.CostItemFormFieldsValue);
    this.items.push(this.CostItemFormFieldsValue);
  }
  updateBuilderCost(item: any): void {
    const unitCost = parseFloat(item.unitCost) || 0;
    const quantity = item.quantity || 1;
    const markupValue = parseFloat(item.markupValue) || 0;

    item.builderCost = unitCost * quantity;

    if (item.markupId === 40417) {
      item.ownerPrice = item.builderCost + (item.builderCost * (markupValue / 100));
    } else if (item.markupId === 40418 || item.markupId === 40419) {
      item.ownerPrice = item.builderCost + markupValue;
    } else {
      item.ownerPrice = item.builderCost;
    }

    item.margin = item.ownerPrice > 0
      ? ((item.ownerPrice - item.builderCost) / item.ownerPrice) * 100
      : 0;

    item.margin = parseFloat(item.margin.toFixed(2));

    item.profit = item.ownerPrice - item.builderCost;
    if (item.profit < 0) {
      item.profit = 0;
    }

    this.updateTotals();
  }
  updateTotals(): void {
    this.totalOwnerPrice = this.items.reduce((sum, item) => sum + item.ownerPrice, 0);
    this.totalBuilderCost = this.items.reduce((sum, item) => sum + item.builderCost, 0);
    this.estimatedProfit = this.items.reduce((sum, item) => sum + item.profit, 0);
  }
  innerCardTabs(tab: number): string {
    switch (tab) {
      case 1:
        return 'Internal Notes';
      case 2:
        return 'Sub/Vendor Notes';
      case 3:
        return 'Owner Notes';
      default:
        return '';
    }
  }
  readonly validationRules = {
    title: {
      required: 'Required',
      maxLength: { limit: 50, message: 'Title' }
    },
    autoAssignNumber: {
      maxLength: { limit: 20, message: 'ID#' }
    },
    internalNotes: {
      maxLength: { limit: 500, message: 'Internal Notes' }
    },
    subVendorNotes: {
      maxLength: { limit: 500, message: 'Sub Notes' }
    },
    ownerNotes: {
      maxLength: { limit: 500, message: 'Owner Notes' }
    },
    comments: {
      maxLength: { limit: 512, message: 'Comments' }
    },
    isLineItems: {
      message: 'Line Items field must have at least 1 items',
    },
  };
  getFieldLabel(field: string): string {
    const labels: { [key: string]: string } = {
      title: 'Title',
      autoAssignNumber: 'ID#',
      isLineItems: 'Line Items',
      isFlatFree: '',
      internalNotes: 'Internal Notes',
      subVendorNotes: 'Sub Notes',
      ownerNotes: 'Owner Notes',
      comments: 'Comments',
    };
    return labels[field] || field;
  }
  getErrorTip(field: string): string {
   const rules = this.validationRules[field];
   const input = this.ChangeOrderFormFieldsValue[field];
   const inputValue = typeof input === 'string' ? input.trim() : '';

   if (rules.required && !input) {
     return 'Required';
   }

    if (rules.maxLength && inputValue.length > rules.maxLength.limit) {
     const excessLength = inputValue.length - rules.maxLength.limit;
     const unit = excessLength === 1 ? 'character' : 'characters';
     return `${excessLength} ${unit} over.`;
   }
   if (field === 'isLineItems') {
    return rules?.message;
    }

    return '';
  }
  get errorFields(): { field: string; label: string; message: string }[] {
    return Object.keys(this.validationStates)
      .filter(field => this.validationStates[field] === 'error')
      .map(field => ({
        field,
        label: this.getFieldLabel(field),
        message: this.validationMessages[field]
      }));
  }
  checkErrors() {
   this.validationStates = {};
   this.validationMessages = {};

   for (let field in this.validationRules) {
     if (this.validationRules.hasOwnProperty(field)) {
       if (this.touchedFields[field] || this.isSubmitted) {
         const errorTip = this.getErrorTip(field);
         if (errorTip) {
           this.validationStates[field] = 'error';
           this.validationMessages[field] = errorTip;
         } else {
           this.validationStates[field] = null;
           this.validationMessages[field] = '';
         }
       }
     }
   }
   this.onErrorNull();
  }
  onErrorNull(){
    if (this.priceInformationradioValue === 'A' || this.rowAddItemGrid === true) {
      delete this.validationStates['isLineItems'];
      delete this.validationMessages['isLineItems'];
    }
  }
  onFieldChange(field: string, value: any) {
    if (typeof value === 'string') {
      this.ChangeOrderFormFieldsValue[field] = value.trim();
    } else {
      this.ChangeOrderFormFieldsValue[field] = value;
    }
    this.touchedFields[field] = true;
    this.checkErrors();
    if (this.rowAddItemGrid === true) {
      this.checkErrorsItem();
    }
  }
  readonly validationRulesItem = {
    title: {
      maxLength: { limit: 10, message: 'Title' }
    },
    costCodeId: {
      required: 'Required',
      maxLength: {message: 'Cost Code' }
    },
  };
  getFieldLabelItem(field: string): string {
    const labels: { [key: string]: string } = {
      costCodeId: 'Cost Code',
      title: 'Title',
    };
    return labels[field] || field;
  }
  getErrorTipItem(field: string, index: number): string {
    const rules = this.validationRulesItem[field];
    const input = this.items[index][field];
    const inputValue = typeof input === 'string' ? input.trim() : '';

    if (rules.required && !input) {
      return 'Required';
    }

    if (rules.maxLength && inputValue.length > rules.maxLength.limit) {
      const excessLength = inputValue.length - rules.maxLength.limit;
      const unit = excessLength === 1 ? 'character' : 'characters';
      return `${excessLength} ${unit} over.`;
    }

    return '';
  }
  get errorFieldsItem(): { label: string; message: string }[] {
    return Object.keys(this.validationStatesItem)
      .filter(field => this.validationStatesItem[field] === 'error')
      .map(field => ({
        label: '',
        message: this.validationMessagesItem[field]
      }));
  }
  checkErrorsItem() {
    this.validationStatesItem = {};
    this.validationMessagesItem = {};

    this.items.forEach((item, index) => {
      for (let field in this.validationRulesItem) {
        if (this.validationRulesItem.hasOwnProperty(field)) {
          if (this.touchedFieldsItem[field + '_' + index] || this.isSubmitted) {
            const errorTip = this.getErrorTipItem(field, index);
            if (errorTip) {
              this.validationStatesItem[field + '_' + index] = 'error';
              this.validationMessagesItem[field + '_' + index] = `Cost Code ${index + 1}: ${errorTip}`;
            }
          }
        }
      }
    });
  }
  onFieldChangeItem(field: string, value: any, index: number) {
    if (typeof value === 'string') {
      this.items[index][field] = value.trim();
    } else {
      this.items[index][field] = value;
    }

    this.touchedFieldsItem[field + '_' + index] = true;
    this.checkErrorsItem();
  }
  onApproveChangeOrder(params: string): void {
    let ApplicationId = this.localStorageService.getUserLoginId();
      const modalRef = this.modal.create({
        nzContent: SignatureComponent,
        nzFooter: null
      });
      modalRef.componentInstance.Params = params;
      modalRef.componentInstance.ApplicationIdRes = ApplicationId;
      modalRef.componentInstance.cancel.subscribe(() => {
        modalRef.destroy();
      });
      modalRef.componentInstance.SignatureResponse.subscribe(res => {
        this.ChangeOrderFormFieldsValue.signatureSetupId = res?.id
        this.SignatureRes = res
      })
      modalRef.componentInstance.Approve_DeclinedResponse.subscribe(res => {
        this.test = this.SignatureRes?.id
        this.saveChangeOrderAndApprove(res);
      })
  }
  saveChangeOrderAndApprove(res: any): Promise<void>{
    if(res === 'saveAndAndApprove'){
      this.ChangeOrderFormFieldsValue.decline = false
      this.ChangeOrderFormFieldsValue.approved = true
    } else {
      this.ChangeOrderFormFieldsValue.approved = false
      this.ChangeOrderFormFieldsValue.decline = true
    }
    return this.saveChangeOrder(res).then(() => {
      this.SignatureRes = undefined;
    }).catch((error) => {
      console.error('Error during save and close:', error);
    })
  }
  CheckApplicationId(_Id: number){
    this._LeadOpportunitiesService.getSignatureSetupByUserId(_Id).subscribe(
      (response) => {
        this.SignatureRes = response.result
      });
  }
  fetchChangeOrderAndJob(_Id: number): void {
    this.jobInfoService.onGetJobShortInformation().subscribe((res) => {
    this.onJobShortInformation = res?.result?.find(item => item?.id === _Id);
    });
    this._ChangeOrderInformationService.getCompanyChangeOrder().subscribe(res => {
      this._ChangeOrderSetupResponse = res?.result
    },(error) => {
      this._NzMessageService.error('An error has occurred. Please try again.');
    });
  }
}
