<nz-modal [(nzVisible)]="isVisibleInJob" [nzTitle]="modalTitle" [nzFooter]="modalFooter" [nzBodyStyle]="{
  background: '#f1f4fa',
  padding: '14px',
  maxHeight: '69vh',
  overflowY: 'auto'
}" [nzStyle]="{ top: '25px'}" [nzWidth]="'85%'" (nzOnCancel)="CancelJobScartch()">
  <ng-template #modalTitle>
    <div class="row">
      <h2 class="p-0 m-0 famly-inn ant-typography-Modal">Jobs</h2>
    </div>
  </ng-template>
  <div class="content" *nzModalContent>
    <nz-alert class="rounded-3 mb-3 mt-2 error-message" *ngIf="isSubmitted && errorFields.length > 0" nzType="error" nzShowIcon [nzMessage]="message" [nzDescription]="errorDescriptionTemplate"></nz-alert>
    <ng-template #message>
      <p class="mt-1">Please correct the following fields:</p>
    </ng-template>
    <ng-template #errorDescriptionTemplate>
      <ul>
        <li class="famly-inn p-0 m-0 ">Job Info:
          <ul>
            <li class="famly-inn p-0 m-0" *ngFor="let error of errorFields">
              <span class="d-flex align-items-center">
                <span>{{ error.label }}:</span>
                <span class="ms-1"> {{ error.message }}</span>
              </span>
            </li>
          </ul>
        </li>
      </ul>
    </ng-template>

    <div class="card-container">
      <div class="spinner-wrapper" *ngIf="isLoading">
        <img src="/assets/Loder/new-ajax-loader.gif">
      </div>
      <nz-tabset nzType="card" class="p-0 m-0">
        <nz-tab *ngFor="let tab of tabJob" [nzTitle]="getTabJob(tab)">
          <ng-container *ngIf="tab === 1">
          <div class="card border-0 mt-0">
            <div class="card-head border-bottom">
              <div class="row pt-2 pb-2">
                <div class="col d-flex align-items-center">
                  <h2 class="ms-3 famly-inn ant-typography-crad-Header">Job Information</h2>
                  <ng-container *ngIf="onSTSChange?.length > 0">
                    <nz-tag
                      [ngClass]="{
                        'status-text-info': onSTSChange === 'Open',
                        'status-text-badge': onSTSChange === 'Closed',
                        'status-text-pending-Pre': onSTSChange === 'Presale'
                      }"
                      class="d-flex align-items-center ms-2 mt-0">
                      {{ onSTSChange }}
                    </nz-tag>
                  </ng-container>
                </div>
              </div>
            </div>
            <div class="card-body p-0 m-0">
              <div class="row" style="padding: 0px 15px 0px 15px;">
                <div class="col-6">
                  <div class="row gx-2">
                    <div class="col-8">
                      <label class="sub-text">Job Name<span class="text-danger fw-bolder ms-1">*</span></label>
                      <nz-form-item>
                        <nz-form-control>
                          <input  #titleInput nz-input [(ngModel)]="JobFormFieldsValue.name" name="name"
                            required (ngModelChange)="onFieldChange('name', $event)"
                            [nzStatus]="validationStates['name']" class="formField" />
                        </nz-form-control>
                        <div *ngIf="validationStates['name'] === 'error'" class="text-danger">
                          {{ validationMessages['name'] }}
                        </div>
                      </nz-form-item>
                    </div>
                    <div class="col-4">
                      <label class="sub-text">Job Prefix
                        <i
                        class="bi bi-info-circle-fill text-secondary"
                        nz-popover
                        [nzPopoverContent]="contentTemplate"
                        nzPopoverPlacement="top"></i>
                        <ng-template #contentTemplate>
                          <div class="rounded-2">
                            <p style="height: auto; width: 200px">
                              Attach a unique prefix to financial, RFI, and
                              Warranty items associated with this job.
                            </p>
                          </div>
                        </ng-template>
                      </label>
                      <nz-form-control>
                        <input
                          nz-input
                          [(ngModel)]="JobFormFieldsValue.jobPrefix"
                          name="jobPrefix"
                          #stateInput="ngModel"
                          (ngModelChange)="onFieldChange('jobPrefix', $event)"
                          [nzStatus]="validationStates['jobPrefix']"
                          class="formField"
                        />
                      </nz-form-control>
                      <div
                        *ngIf="validationStates['jobPrefix'] === 'error'"
                        class="text-danger"
                      >
                        {{ validationMessages["jobPrefix"] }}
                      </div>
                    </div>
                  </div>
                  <div nz-row>
                    <div nz-col nzSpan="8">
                      <label class="sub-text">Job Type<span class="text-danger ms-1">*</span></label>
                    </div>
                    <div nz-col nzSpan="4">
                    </div>
                  </div>
                  <div nz-row>
                    <div nz-col nzSpan="12">
                      <nz-form-item>
                        <nz-form-control [nzErrorTip]="validationMessages['jobInformationTypeParameterId']" [nzValidateStatus]="validationStates['jobInformationTypeParameterId']">
                          <nz-select
                          (ngModelChange)="onFieldChange('jobInformationTypeParameterId', $event)"
                            nzShowSearch
                            [(ngModel)]="JobFormFieldsValue.jobInformationTypeParameterId"
                            id="jobInformationTypeParameterId"
                            name="jobInformationTypeParameterId"
                          >
                            <nz-option *ngFor="let option of jobTypeList" [nzLabel]="option.Name" [nzValue]="option.Id"></nz-option>
                          </nz-select>
                        </nz-form-control>
                      </nz-form-item>
                    </div>
                    <div nz-col nzSpan="4" class="d-flex justify-content-end">
                      <a (click)="jobsComponentsOpen()" nz-button nzType="link">Manage</a>
                    </div>
                  </div>
                  <div nz-row>
                    <div nz-col nzSpan="14">
                      <label class="sub-text">Contract Type<span class="text-danger fw-bolder ms-1">*</span><i class="bi bi-info-circle-fill text-secondary ms-1" nz-popover [nzPopoverContent]="ContractTypeTemplate" nzPopoverPlacement="top"></i></label>
                      <ng-template #ContractTypeTemplate>
                        <div class="rounded-2">
                          <p style="height: auto; width: 200px">
                            Buildertrend will create workflows to best fit your financial structure.
                          </p>
                        </div>
                      </ng-template>
                    </div>
                  </div>
                  <div nz-row class="pt-2">
                    <div nz-col nzSpan="24">
                      <nz-radio-group [(ngModel)]="contractTyperadioValue" (ngModelChange)="contractTyperadioButtons()">
                        <div class="row">
                          <div class="col-12">
                            <label nz-radio nzValue="A"><strong class="famly-inn">Fixed Price</strong></label>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <label style="font-size: 14px; color: #202227;margin-left: 24px;" class="famly-inn">You will set the Contract Price for the owner.</label>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <label nz-radio nzValue="B"><strong class="famly-inn">Open Book</strong></label>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <label style="font-size: 14px; color: #202227;margin-left: 24px;" class="famly-inn">Price will be determined by your actual costs plus markup/margin (includes Cost Plus and Time & Materials contracts).</label>
                          </div>
                        </div>
                      </nz-radio-group>
                    </div>
                  </div>
                  <div nz-row>
                    <div nz-col nzSpan="20">
                      <label class="sub-text">Job Group</label>
                    </div>
                    <div nz-col nzSpan="2">
                    </div>
                    <div nz-col nzSpan="2">
                    </div>
                  </div>
                  <div nz-row>
                    <div nz-col nzSpan="20">
                      <nz-tree-select
                      [(ngModel)]="JobFormFieldsValue['createJobGroupParameterRequests']"
                      name="createJobGroupParameterRequests"
                      style="width: 100%"
                      [nzNodes]="JobGroupNodes"
                      (ngModelChange)="onTesting($event)"
                      [nzShowSearch]="true"
                      nzAllowClear="false"
                      nzCheckable
                      nzDropdownClassName="dropdownStyle"
                      [nzAllowClear]="false">
                      </nz-tree-select>
                    </div>
                    <div nz-col nzSpan="2">
                      <a nz-button nzType="link" (click)="jobGroupSetupOpen(null, 'JobGroupAdd')">Add</a>
                    </div>
                    <div nz-col nzSpan="2">
                      <a nz-button nzType="link"(click)="jobGroupSetupOpen(JobFormFieldsValue.createJobGroupParameterRequests, 'JobGroupEdit')" [disabled]="!onEditBtn === true">Edit</a>
                    </div>
                  </div>
                  <div nz-row class="pt-1">
                    <div nz-col nzSpan="24">
                      <span class="famly-inn ant-typography-heading-bold">Address</span>
                    </div>
                  </div>
                  <div nz-row class="pt-1">
                    <div nz-col nzSpan="24">
                      <label class="sub-text">Street Address</label>
                      <nz-form-control>
                        <input
                          nz-input
                          [(ngModel)]="JobFormFieldsValue.streetAddress"
                          name="streetAddress"
                          #stateInput="ngModel"
                          (ngModelChange)="onFieldChange('streetAddress', $event)"
                          [nzStatus]="validationStates['streetAddress']"
                          class="formField"
                        />
                      </nz-form-control>
                      <div
                        *ngIf="validationStates['streetAddress'] === 'error'"
                        class="text-danger"
                      >
                        {{ validationMessages["streetAddress"] }}
                      </div>
                    </div>
                  </div>
                  <div nz-row>
                    <div nz-col nzSpan="24">
                      <label class="sub-text">Suburb</label>
                      <nz-form-control>
                        <input
                          nz-input
                          [(ngModel)]="JobFormFieldsValue.suburb"
                          name="suburb"
                          #stateInput="ngModel"
                          (ngModelChange)="onFieldChange('suburb', $event)"
                          [nzStatus]="validationStates['suburb']"
                          class="formField"
                        />
                      </nz-form-control>
                      <div
                        *ngIf="validationStates['suburb'] === 'error'"
                        class="text-danger"
                      >
                        {{ validationMessages["suburb"] }}
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <label class="sub-text">State</label>
                      <nz-form-control>
                        <input
                          nz-input
                          [(ngModel)]="JobFormFieldsValue.state"
                          name="state"
                          #stateInput="ngModel"
                          (ngModelChange)="onFieldChange('state', $event)"
                          [nzStatus]="validationStates['state']"
                          class="formField"
                        />
                      </nz-form-control>
                      <div
                        *ngIf="validationStates['state'] === 'error'"
                        class="text-danger"
                      >
                        {{ validationMessages["state"] }}
                      </div>
                    </div>
                    <div class="col-6">
                      <div nz-row>
                        <div nz-col nzSpan="21">
                          <label class="sub-text">Postal Code</label>
                        </div>
                        <div nz-col nzSpan="3">
                        </div>
                      </div>
                      <div nz-row>
                        <div nz-col nzSpan="21">
                          <nz-form-control>
                            <input
                              nz-input
                              [(ngModel)]="JobFormFieldsValue.postalCode"
                              name="postalCode"
                              #stateInput="ngModel"
                              (ngModelChange)="onFieldChange('postalCode', $event)"
                              [nzStatus]="validationStates['postalCode']"
                              class="formField"
                            />
                          </nz-form-control>
                          <div
                            *ngIf="validationStates['postalCode'] === 'error'"
                            class="text-danger"
                          >
                            {{ validationMessages["postalCode"] }}
                          </div>
                        </div>
                        <div nz-col nzSpan="3" style="display: flex;justify-content: center;align-items: center;">
                          <i
                          class="bi bi-info-circle-fill text-secondary"
                          nz-popover
                          [nzPopoverContent]="contentTemplatepostalCode"
                          nzPopoverPlacement="top"></i>
                          <ng-template #contentTemplatepostalCode>
                            <div class="rounded-2">
                              <p style="height: auto; width: 200px">
                                Automated sales tax is determined by the job's address. If the tax rate is different than your jobsite's actual tax rate, reset the job through
                                <span class="sub-text">
                                  Update Customer/Job in the Accounting
                                </span>
                                tab.
                              </p>
                            </div>
                          </ng-template>
                        </div>
                      </div>
                    </div>
                  </div>
                   <div nz-row class="pt-1">
                    <div nz-col nzSpan="24">
                      <span class="famly-inn ant-typography-heading-bold">Schedule</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <label class="sub-text">Projected Start</label>
                    </div>
                    <div class="col-6">
                      <label class="sub-text">Actual Start</label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4">
                      <nz-date-picker
                        [(ngModel)]="JobFormFieldsValue.projectedStart"
                        name="projectedStart"
                        (ngModelChange)="onDateChange('projectedStart')"
                        nzSuffixIcon="false"
                          [nzFormat]="'MMM d, YYYY'"
                          nzPlaceHolder=" "
                          autocomplete="off"
                      ></nz-date-picker>
                    </div>
                    <div class="col-2"></div>
                    <div class="col-4">
                      <nz-date-picker
                      *ngIf="!JobFormFieldsValue?.updateActualDatesBasedOnSchedule"
                        [(ngModel)]="JobFormFieldsValue.actualStart"
                        name="actualStart"
                        (ngModelChange)="onDateChange('actualStart')"
                        nzSuffixIcon="false"
                        [nzFormat]="'MMM d, YYYY'"
                        nzPlaceHolder=" "
                        autocomplete="off"
                      ></nz-date-picker>
                      <label *ngIf="JobFormFieldsValue.updateActualDatesBasedOnSchedule" class="sub-text">--</label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <label class="sub-text">Projected Completion</label>
                    </div>
                    <div class="col-6">
                      <label class="sub-text">Actual Completion</label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4">
                      <nz-form-control>
                        <nz-date-picker
                          [(ngModel)]="JobFormFieldsValue.projectedCompletion"
                          name="projectedCompletion"
                          required
                          (ngModelChange)="onDateChange('projectedCompletion')"
                          [nzStatus]="projectedCompletionError ? 'error' : ''"
                          class="formField"
                          nzSuffixIcon="false"
                          [nzFormat]="'MMM d, YYYY'"
                          nzPlaceHolder=" "
                          autocomplete="off"
                        ></nz-date-picker>
                      </nz-form-control>
                      <div *ngIf="projectedCompletionError" class="text-danger">
                        Completion date must be after the start date.
                      </div>
                    </div>
                    <div class="col-2"></div>
                    <div class="col-4">
                      <nz-form-control>
                        <nz-date-picker
                          *ngIf="!JobFormFieldsValue?.updateActualDatesBasedOnSchedule"
                          [(ngModel)]="JobFormFieldsValue.actualCompletion"
                          name="actualCompletion"
                          required
                          (ngModelChange)="onDateChange('actualCompletion')"
                          [nzStatus]="actualCompletionError ? 'error' : ''"
                          class="formField"
                          nzSuffixIcon="false"
                          [nzFormat]="'MMM d, YYYY'"
                          nzPlaceHolder=" "
                          autocomplete="off"
                        ></nz-date-picker>
                      </nz-form-control>
                      <div *ngIf="actualCompletionError" class="text-danger">
                        Completion date must be after the start date.
                      </div>
                      <label *ngIf="JobFormFieldsValue.updateActualDatesBasedOnSchedule" class="sub-text">--</label>
                    </div>
                  </div>
                  <nz-row class="pt-2">
                    <div nz-col nzSpan="24">
                      <label nz-checkbox [(ngModel)]="JobFormFieldsValue.updateActualDatesBasedOnSchedule" (ngModelChange)="onCheckboxChange($event)">
                        Update actual dates based on Schedule
                      </label>
                    </div>
                  </nz-row>
                  <div class="row">
                    <div class="col-12">
                      <label class="sub-text famly-inn">Job Schedule Color</label>
                      <div class="input-group">
                        <nz-select
                          nzShowSearch
                          [nzCustomTemplate]="defaultTemplate"
                          [(ngModel)]="JobFormFieldsValue['colorId']"
                          name="colorId"
                          [style.width.%]="100"
                          (ngModelChange)="updateSelectedColor($event)"
                        >
                          <nz-option
                            nzCustomContent
                            *ngFor="let option of colors"
                            [nzLabel]="option.name"
                            [nzValue]="option.id"
                          >
                            <span
                              class="color-circle-drop"
                              [style.backgroundColor]="option?.colorCollection"
                            ></span>
                            <ng-container>
                              <span class="color-name">{{
                                option.name
                              }}</span>
                            </ng-container>
                          </nz-option>
                        </nz-select>
                        <ng-template #defaultTemplate let-selected>
                          <div class="color-item d-flex align-items-center">
                            <span
                              class="color-circle-drop"
                              [style.backgroundColor]="selectedColor"
                            ></span>
                            <span class="color-name">{{
                              selected.nzLabel
                            }}</span>
                          </div>
                        </ng-template>
                      </div>
                    </div>
                  </div>
                  <div nz-row>
                    <div nz-col nzSpan="24">
                      <label class="sub-text">Work Days<span class="text-danger fw-bolder ms-1">*</span></label>
                    </div>
                  </div>
                  <ng-container *ngIf="jobInformationResponse?.WorkDayIds?.length > 0; else onTagWorkDaysShow">
                    <div class="pt-2">
                      <span class="Normal-text famly-inn" *ngFor="let data of testingNewArray">
                        {{ data }}
                      </span>
                    </div>
                  </ng-container>
                  <ng-template #onTagWorkDaysShow>
                    <div nz-row>
                      <div nz-col nzSpan="24">
                        <nz-form-control>
                          <nz-tree-select
                            [(ngModel)]="JobFormFieldsValue.createWorkDayParameterRequests"
                            name="createWorkDayParameterRequests"
                            style="width: 100%"
                            [nzNodes]="WorkDaysNodes"
                            (ngModelChange)="onWorkDaysChange($event)"
                            [nzShowSearch]="true"
                            [nzAllowClear]="true"
                            nzCheckable
                            nzDropdownClassName="dropdownStyle"
                            [nzStatus]="WorkDaysError ? 'error' : ''"
                            >
                          </nz-tree-select>
                        </nz-form-control>
                        <div *ngIf="JobFormFieldsValue.createWorkDayParameterRequests?.length === 0 && WorkDaysError"  class="text-danger">
                          Must select at least one workday
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </div>
                <div class="col-6">
                  <div class="row">
                    <div class="col-4">
                      <label class="sub-text">Change Job Status</label>
                      <nz-select
                        nzShowSearch
                        [(ngModel)]="JobFormFieldsValue.statusSetupId"
                        name="statusSetupId"
                      >
                        <nz-option *ngFor="let option of ChangeJobStatus" [nzLabel]="option.name" [nzValue]="option.id"></nz-option>
                      </nz-select>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <label class="sub-text">Contract Price</label>
                      <div class="input-group">
                        <nz-input-group nzAddOnBefore="$">
                          <input
                          nz-input
                          [(ngModel)]="JobFormFieldsValue.contractPrice"
                          appDollarFormatter
                          name="contractPrice"
                          #stateInput="ngModel"
                          class="form-control formField"
                        />
                        </nz-input-group>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <label class="sub-text">Project Managers</label>
                      <nz-tree-select
                      [(ngModel)]="JobFormFieldsValue['createJobInformationUserParameterRequests']"
                      name="createJobInformationUserParameterRequests"
                      style="width: 100%"
                      [nzNodes]="projectManagerNodes"
                      (ngModelChange)="onProjectManagersChange($event)"
                      [nzShowSearch]="true"
                      nzAllowClear="false"
                      nzCheckable
                      nzDropdownClassName="dropdownStyle"
                      [nzAllowClear]="false">
                      </nz-tree-select>
                    </div>
                  </div>
                  <ng-container *ngIf="notifyShow === true">
                    <nz-row class="pt-2">
                      <div nz-col nzSpan="24">
                        <label nz-checkbox [(ngModel)]="JobFormFieldsValue.updateActualDatesBasedOnSchedule" (ngModelChange)="onCheckboxChange($event)">
                          Notify new project managers on save
                        </label>
                      </div>
                    </nz-row>
                  </ng-container>
                  <div class="row">
                    <div class="col-12">
                        <label class="sub-text">Square Feet</label>
                        <nz-input-number
                        [(ngModel)]="JobFormFieldsValue.squareFeet"
                        name="squareFeet"
                        [nzPrecision]="precision"
                        [nzPrecisionMode]="customPrecisionFn"
                        (ngModelChange)="onSquareFeetChange($event)"
                        nzPlaceHolder=""
                      ></nz-input-number>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <label class="sub-text">Permit #</label>
                      <nz-form-control>
                        <input
                          type="text"
                          nz-input
                          [(ngModel)]="JobFormFieldsValue.permitNo"
                          name="permitNo"
                          (ngModelChange)="onFieldChange('permitNo', $event)"
                          [nzStatus]="validationStates['permitNo']"
                          class="formField"
                        />
                      </nz-form-control>
                      <div
                        *ngIf="validationStates['permitNo'] === 'error'"
                        class="text-danger"
                      >
                        {{ validationMessages["permitNo"] }}
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <label class="sub-text">Lot Info</label>
                      <nz-form-control>
                        <textarea
                        class="k-resize-none"
                        [(ngModel)]="JobFormFieldsValue['lotInformation']"
                        name="lotInformation"
                        (ngModelChange)="onFieldChange('lotInformation', $event)"
                          [nzStatus]="validationStates['lotInformation']"
                        rows="2"
                        nz-input
                      ></textarea>
                      </nz-form-control>
                      <div
                        *ngIf="validationStates['lotInformation'] === 'error'"
                        class="text-danger"
                      >
                        {{ validationMessages["lotInformation"] }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card border-0 mt-0">
                <div class="card-head border-bottom">
                  <div class="row pt-3">
                    <h2 class="ms-3 famly-inn ant-typography-crad-Header">Job Notes</h2>
                  </div>
                </div>
                <div class="card-body p-0 m-0">
                  <div class="container">
                    <div class="row">
                      <div class="col-12">
                        <label class="sub-text">Notes for Internal Users</label>
                        <nz-form-control>
                          <textarea
                          class="k-resize-none"
                          [(ngModel)]="JobFormFieldsValue['notesForInternalUsers']"
                          name="notesForInternalUsers"
                          (ngModelChange)="onFieldChange('notesForInternalUsers', $event)"
                            [nzStatus]="validationStates['notesForInternalUsers']"
                          rows="2"
                          nz-input
                        ></textarea>
                        </nz-form-control>
                        <div
                          *ngIf="validationStates['notesForInternalUsers'] === 'error'"
                          class="text-danger"
                        >
                          {{ validationMessages["notesForInternalUsers"] }}
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <label class="sub-text">Notes for Subs/Vendors</label>
                        <nz-form-control>
                          <textarea
                          class="k-resize-none"
                          [(ngModel)]="JobFormFieldsValue['notesForSubsVendors']"
                          name="notesForSubsVendors"
                          (ngModelChange)="onFieldChange('notesForSubsVendors', $event)"
                            [nzStatus]="validationStates['notesForSubsVendors']"
                          rows="2"
                          nz-input
                        ></textarea>
                        </nz-form-control>
                        <div
                          *ngIf="validationStates['notesForSubsVendors'] === 'error'"
                          class="text-danger"
                        >
                          {{ validationMessages["notesForSubsVendors"] }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <nz-collapse [nzBordered]="false">
                    <nz-collapse-panel #p [ngStyle]="{background: '#ffffff', 'border-radius': '4px', 'margin-bottom': '0px', 'margin-top': '0px',border: '0px'}" nzTitle="Custom Column" [nzExpandedIcon]="expandedIcon" [nzHeader]="tileetwe">
                      <hr class="p-0 m-0"/>
                      <div class="container" #p>
                        <div class="row">
                          <div class="col-3">
                            <label class="sub-text">Plans - Lodged
                              <i class="bi bi-info-circle-fill text-secondary" nz-popover [nzPopoverContent]="PlansLodged" nzPopoverPlacement="right"></i>
                              <ng-template #PlansLodged>
                                <div class="rounded-2">
                                  <p style="height: auto; width: 200px">
                                    Update when Plans are lodged for approval
                                  </p>
                                </div>
                              </ng-template>
                              <nz-date-picker [nzFormat]="'MMM d,YYYY'"nzSuffixIcon="false" [nzPlaceHolder]="' '"></nz-date-picker>
                            </label>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-3">
                            <label class="sub-text">Plans Approved - Issued
                              <i class="bi bi-info-circle-fill text-secondary" nz-popover [nzPopoverContent]="PlansApprovedIssued" nzPopoverPlacement="right"></i>
                              <ng-template #PlansApprovedIssued>
                                <div class="rounded-2">
                                  <p style="height: auto; width: 200px">
                                    Update when Plans have been approved and issued
                                  </p>
                                </div>
                              </ng-template>
                            </label>
                            <nz-date-picker [nzFormat]="'MMM d,YYYY'"nzSuffixIcon="false" [nzPlaceHolder]="' '"></nz-date-picker>
                          </div>
                        </div>
                      </div>
                      <ng-template #tileetwe>
                        <div class="row">
                          <h2 class="ms-3 famly-inn ant-typography-crad-Header">Custom Fields (19)</h2>
                        </div>
                      </ng-template>
                      <ng-template #expandedIcon let-active>
                        {{ active }}
                        <span nz-icon nzType="caret-right" class="ant-collapse-arrow" [nzRotate]="p.nzActive ? 90 : -0"></span>
                      </ng-template>
                    </nz-collapse-panel>
                  </nz-collapse>
                </div>
              </div>
            </div>
          </div>
          </ng-container>
          <ng-container *ngIf="tab === 2">
            <div class="card border-0 mt-0">
              <div class="card-head border-bottom">
                <div class="row pt-2 pb-2">
                  <div class="col-6">
                    <span class="famly-inn ant-typography-heading-bold ms-3">Owner Information</span>
                  </div>
                  <div class="col-6 d-flex justify-content-end">
                    <div *ngIf="onViewOwnerSite === false" nz-popover [nzPopoverContent]="ViewOwnerSiteTemplate" nzPopoverPlacement="bottom">
                      <button class="me-2" nz-button nzType="default" [disabled]="true">
                        View Owner Site
                      </button>
                    </div>
                    <div *ngIf="onViewOwnerSite === true">
                      <button class="me-2" nz-button nzType="default">
                        View Owner Site
                      </button>
                    </div>
                    <ng-template #ViewOwnerSiteTemplate>
                      <div class="rounded-2">
                        <p style="padding: 0; margin: 0; width: 200px">
                          Add a homeowner contact to the job to view owner site
                        </p>
                      </div>
                    </ng-template>
                    <ng-container *ngIf="chooseExistingContactCustomer.length > 0;">
                      <button class="me-2" nzPlacement="bottomRight" nzTrigger="click" nz-dropdown [nzDropdownMenu]="menu" nz-button nzType="default">Add Owner
                        <span nz-icon nzType="down" nzTheme="outline"></span>
                      </button>
                      <nz-dropdown-menu #menu="nzDropdownMenu">
                        <ul nz-menu>
                          <li nz-menu-item (click)="showNewCustomerStandard(null)">New Contact</li>
                          <li nz-menu-item (click)="chooseExistingContactCustomerOpen()">Choose Existing Contacts</li>
                        </ul>
                      </nz-dropdown-menu>
                    </ng-container>
                  </div>
                </div>
              </div>
              <div class="card-body p-0 m-0">
                <div class="container">
                    <div class="pt-3" *ngIf="chooseExistingContactCustomer.length > 0; else CardContent">
                      <div class="row">
                        <div class="col-4 pt-2" *ngFor="let data of chooseExistingContactCustomer; let i = index">
                          <div nz-row>
                            <div nz-col nzSpan="24">
                              <div class="card" style="padding: 16px;">
                                <div nz-row>
                                  <div nz-col nzSpan="8" (click)="showNewCustomerStandard(data?.Id)">
                                    <nz-avatar
                                      [nzText]="initials[i]"
                                      style="color:#383c45; background-color:#e2e7f0;height: 80px;width: 80px;display: flex;justify-content: center;align-items: center;">
                                    </nz-avatar>
                                  </div>
                                  <div nz-col nzSpan="16">
                                    <div nz-row>
                                      <div nz-col nzSpan="8" (click)="showNewCustomerStandard(data?.Id)">
                                        <nz-tag
                                          nz-popover
                                          [nzPopoverContent]="Content"
                                          class="status-text d-flex align-items-center mt-1"
                                          [ngClass]="getStatusClass(data?.StatusCode || data?.StatusCode)"
                                          (mouseenter)="onTagMouseEnter(i)"
                                          (mouseleave)="onTagMouseLeave(i)"
                                        >
                                          {{
                                            getStatusDescription(data?.StatusCode) || data?.StatusName
                                          }}
                                          <span nz-icon nzType="info-circle" [nzTheme]="isHoveredMap[i] ? 'fill' : 'outline'"></span>
                                        </nz-tag>
                                        <ng-template #Content>
                                          <p style="width: 220px; height: auto;">
                                            <span>
                                              {{ data?.StatusName || data?.StatusName }}
                                              <ng-container *ngIf="data?.StatusCode === StatusCode.ACTIVE.code || StatusCode.PENDING.code">
                                                {{ data?.CustomerOn | date : 'MMM dd,YYYY' }}
                                              </ng-container>
                                            </span>
                                          </p>
                                        </ng-template>
                                      </div>
                                      <div nz-col nzSpan="16" style="display: flex; justify-content: end; align-items: center;color: #8f9ba8">
                                        <span (click)="deleteJobOwnerCutomerInfomationConfirm(data)" nz-icon nzType="close" nzTheme="outline"></span>
                                      </div>
                                    </div>
                                    <div nz-row (click)="showNewCustomerStandard(data?.Id)">
                                      <div nz-col nzSpan="24">
                                        <div class="text-overflow-auto Name">
                                          <span class="famly-inn">{{ data?.DisplayName || data?.DisplayName || data?.DisplayName }}</span>
                                        </div>
                                      </div>
                                    </div>
                                    <div nz-row (click)="showNewCustomerStandard(data?.Id)">
                                      <div nz-col nzSpan="24">
                                        <div class="text-overflow-auto">
                                          <ng-container *ngIf="data?.PrimaryEmail || data?.PrimaryEmail; else primaryEmailContent">
                                            <span class="PhoneAndEmail">{{ data?.PrimaryEmail || data?.PrimaryEmail }}</span>
                                          </ng-container>
                                          <ng-template #primaryEmailContent>
                                            <span class="PhoneAndEmail">--</span>
                                          </ng-template>
                                        </div>
                                      </div>
                                    </div>
                                    <div nz-row (click)="showNewCustomerStandard(data?.Id)">
                                      <div nz-col nzSpan="24">
                                        <div class="text-overflow-auto">
                                          <ng-container *ngIf="data?.Phone || data?.CellPhone; else PhoneContent">
                                            <span class="PhoneAndEmail">{{ data?.Phone || data?.CellPhone}}</span>
                                          </ng-container>
                                          <ng-template #PhoneContent>
                                            <span class="PhoneAndEmail">(---) --- - ----</span>
                                          </ng-template>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <ng-template #CardContent>
                      <div nz-row class="pt-3">
                        <div nz-col nzSpan="24" class="d-flex align-content-center justify-content-center">
                          <svg style="font-size: 78px" width="1em" height="1em" viewBox="0 0 32 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" class=""><path fill-rule="evenodd" clip-rule="evenodd" d="M27.9945 4.85074C27.9182 3.81588 27.0544 3 26 3H8L7.85074 3.00549C6.81588 3.08183 6 3.94564 6 5V7.049L4 7.05L3.88338 7.05673C3.38604 7.11449 3 7.53716 3 8.05C3 8.60228 3.44772 9.05 4 9.05L6 9.049V12.049L4 12.05L3.88338 12.0567C3.38604 12.1145 3 12.5372 3 13.05C3 13.6023 3.44772 14.05 4 14.05L6 14.049V17.049L4 17.05L3.88338 17.0567C3.38604 17.1145 3 17.5372 3 18.05C3 18.6023 3.44772 19.05 4 19.05L6 19.049V22.049L4 22.05L3.88338 22.0567C3.38604 22.1145 3 22.5372 3 23.05C3 23.6023 3.44772 24.05 4 24.05L6 24.049V27L6.00549 27.1493C6.08183 28.1841 6.94564 29 8 29H26L26.1493 28.9945C27.1841 28.9182 28 28.0544 28 27V5L27.9945 4.85074ZM8 5H26V27H8V5ZM17 9C19.7614 9 22 11.2386 22 14C22 15.4644 21.3704 16.7819 20.3671 17.6963C21.71 18.2746 22.8989 19.1983 23.8 20.3993C24.1315 20.8411 24.042 21.4679 23.6003 21.7994C23.1585 22.1308 22.5317 22.0414 22.2002 21.5996C21.03 20.0398 19.225 19.0918 17.287 19.0063L16.99 18.999L16.7337 19.0054C14.7877 19.0851 12.974 20.0344 11.7996 21.5998C11.4682 22.0416 10.8413 22.1311 10.3996 21.7997C9.95776 21.4682 9.86829 20.8414 10.1997 20.3996C11.101 19.1982 12.2903 18.2743 13.6334 17.695C12.6296 16.7819 12 15.4644 12 14C12 11.2386 14.2386 9 17 9ZM14 14C14 12.3431 15.3431 11 17 11C18.6569 11 20 12.3431 20 14C20 15.6508 18.6666 16.9902 17.0181 16.9999L16.992 17L16.8237 16.9949C15.2489 16.9037 14 15.5977 14 14Z"></path></svg>
                        </div>
                      </div>
                      <div nz-row class="pt-2">
                        <div nz-col nzSpan="24">
                          <h3 style="font-size: 24px;" class="p-0 m-0 mt-2 react text-center" nz-typography>Connect your clients to their projects</h3>
                        </div>
                      </div>
                      <div nz-row class="pt-3">
                        <div nz-col nzSpan="24">
                          <p class="react pt-2 text-center" style="font-size: 16px">
                            Create a client contact and assign them to jobs and lead opportunities effortlessly.
                          </p>
                        </div>
                      </div>
                      <div class="row pt-3 pb-5">
                        <div class="d-flex align-content-center justify-content-center">
                          <div nz-row>
                            <div nz-col nzSpan="9">
                              <button (click)="showNewCustomerStandard(null)" nz-button nzType="primary"><span>New Contact</span></button>
                            </div>
                            <div nz-col nzSpan="12">
                              <button (click)="chooseExistingContactCustomerOpen()" nz-button nzType="default"><span>Choose from Existing Contacts</span></button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                </div>
                <div class="card border-0 mt-0">
                  <div class="card-head border-bottom">
                    <div class="row pt-2 pb-2">
                      <h2 class="ms-3 famly-inn ant-typography-crad-Header">
                        Prefrences
                        <i class="bi bi-info-circle-fill text-secondary ms-1" nz-popover [nzPopoverContent]="PrefrencesTemplate" nzPopoverPlacement="top"></i>
                      </h2>
                      <ng-template #PrefrencesTemplate>
                        <div class="rounded-2">
                          <p style="height: auto; width: 200px">
                            Preferences will apply to all owners on the job
                          </p>
                        </div>
                      </ng-template>
                    </div>
                  </div>
                  <div class="card-body p-0 m-0">
                    <div class="container">
                      <div nz-row class="pt-3">
                        <div nz-col nzSpan="24">
                          <span class="famly-inn ant-typography-heading-bold">Viewing Permissions</span>
                        </div>
                      </div>
                      <div class="row pt-2">
                        <div class="col-5">
                          <div nz-row>
                            <div nz-col nzSpan="24">
                              <label >Allow Owner to view the Schedule</label>
                            </div>
                          </div>
                          <div nz-row class="ms-4">
                            <div nz-col nzSpan="24">
                              <label class="sub-text">TimeFrame</label>
                              <nz-select
                                nzShowSearch
                                [(ngModel)]="JobFormFieldsValue.timeFrameSetupId"
                                name="timeFrameSetupId"
                              >
                                <nz-option *ngFor="let option of TimeFrameValuesType" [nzLabel]="option.name" [nzValue]="option.id"></nz-option>
                              </nz-select>
                            </div>
                          </div>
                          <nz-row class="pt-2">
                            <div nz-col nzSpan="24">
                              <label nz-checkbox [(ngModel)]="JobFormFieldsValue.isShowJobPriceSummary" name="isShowJobPriceSummary" (ngModelChange)="onCheckboxChange($event)">
                                Show Job Price Summary
                              </label>
                            </div>
                          </nz-row>
                          <nz-row class="pt-2">
                            <div nz-col nzSpan="24">
                              <label nz-checkbox [(ngModel)]="JobFormFieldsValue.isShowOwnerInvoices" name="isShowOwnerInvoices" (ngModelChange)="onCheckboxChange($event)">
                                Show Owner Invoices
                              </label>
                            </div>
                          </nz-row>
                          <nz-row class="pt-2">
                            <div nz-col nzSpan="24">
                              <label nz-checkbox [(ngModel)]="JobFormFieldsValue.isAllowOwnerToPreviewLockedSelections" name="isAllowOwnerToPreviewLockedSelections" (ngModelChange)="onCheckboxChange($event)">
                                Allow Owner to Preview Locked Selections
                              </label>
                            </div>
                          </nz-row>
                          <nz-row class="pt-2">
                            <div nz-col nzSpan="24">
                              <label nz-checkbox [(ngModel)]="JobFormFieldsValue.isAllowOwnerToViewProjectManagerPhoneNumber" name="isAllowOwnerToViewProjectManagerPhoneNumber" (ngModelChange)="onCheckboxChange($event)">
                                Allow Owner to view Project Manager's phone number
                              </label>
                            </div>
                          </nz-row>
                          <ng-container>
                            <nz-row class="pt-2">
                              <div nz-col nzSpan="24">
                                <label [(ngModel)]="onShowtheBudget" (ngModelChange)="toggleFields()" nz-checkbox>
                                  Show the Budget
                                </label>
                              </div>
                            </nz-row>
                          </ng-container>
                          <ng-container *ngIf="isMarkVisibility">
                            <div nz-row class="pt-2 ms-4">
                              <div nz-col nzSpan="24">
                                <nz-radio-group [(ngModel)]="showtheBudgetradioButtons" (ngModelChange)="onShowtheBudgetradioButtons()">
                                  <div class="row">
                                    <div class="col-12">
                                      <label nz-radio nzValue="A"><label >Use the Job Costing Budget format</label></label>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-12">
                                      <label nz-radio nzValue="B"><label >Use the classic budget format</label></label>
                                    </div>
                                  </div>
                                </nz-radio-group>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-12">
                                <label class="sub-text">Display Budget Columns to Owner</label>
                                <nz-tree-select
                                  style="width: 100%"
                                  [nzShowSearch]="true"
                                  nzAllowClear="false"
                                  nzCheckable
                                  nzDropdownClassName="dropdownStyle"
                                  [nzAllowClear]="false">
                                </nz-tree-select>
                              </div>
                            </div>
                          </ng-container>
                          <nz-row class="pt-2">
                            <div nz-col nzSpan="24">
                              <label nz-checkbox>
                                Show Purchase Orders/Bills
                              </label>
                            </div>
                          </nz-row>
                        </div>
                        <div class="col-4">
                          <div nz-row>
                            <div nz-col nzSpan="24">
                              <label >Allow Owner to see</label>
                            </div>
                          </div>
                          <div nz-row class="pt-2 ms-4">
                            <div nz-col nzSpan="24">
                              <nz-radio-group [(ngModel)]="AllowOwnertoseeradioButtons" (ngModelChange)="onAllowOwnertoseeradioButtons()">
                                <div class="row">
                                  <div class="col-12">
                                    <label nz-radio nzValue="A"><label >Schedule Items</label></label>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-12">
                                    <label nz-radio nzValue="B"><label >Phases</label></label>
                                  </div>
                                </div>
                              </nz-radio-group>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div nz-row class="pt-3">
                        <div nz-col nzSpan="24">
                          <span class="famly-inn ant-typography-heading-bold">Action Permissions</span>
                        </div>
                      </div>
                      <nz-row class="pt-2">
                        <div nz-col nzSpan="24">
                          <label nz-checkbox [(ngModel)]="JobFormFieldsValue.isAllowOwnerToSubmitChangeOrderRequests" name="isAllowOwnerToSubmitChangeOrderRequests" (ngModelChange)="onCheckboxChange($event)">
                            Allow Owner to submit Change Order requests
                          </label>
                        </div>
                      </nz-row>
                       <ng-container>
                        <nz-row class="pt-2">
                          <div nz-col nzSpan="24">
                            <label nz-checkbox [(ngModel)]="JobFormFieldsValue.isAllowOwnerToAddWarrantyClaims" name="isAllowOwnerToAddWarrantyClaims" (ngModelChange)="onAllowOwnertoaddWarrantyclaimstoggleFields()">
                              Allow Owner to add Warranty claims
                            </label>
                          </div>
                        </nz-row>
                      </ng-container>
                      <ng-container *ngIf="isonAllowOwnertoaddWarrantyVisibility">
                        <div nz-row class="pt-2 ms-4">
                          <div nz-col nzSpan="3">
                            <label class="sub-text">Until</label>
                            <nz-date-picker
                              [(ngModel)]="JobFormFieldsValue.untilOn" name="untilOn"
                              nzSuffixIcon="false"
                              [nzFormat]="'MMM d, YYYY'"
                              nzPlaceHolder=" "
                              autocomplete="off">
                            </nz-date-picker>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
                <div class="card border-0 mt-0">
                  <div class="card-head border-bottom">
                    <div class="row pt-4 pb-2">
                      <h2 class="ms-3 famly-inn ant-typography-crad-Header">Notification</h2>
                    </div>
                  </div>
                  <div class="card-body p-0 m-0">
                    <div class="container">
                      <notification></notification>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container class="ant-tabs-content p-2" *ngIf="tab === 3">
            <nz-card
              nzTitle="Internal Users Viewing Access"
              nzBorderless
              [nzExtra]="extraTemplate"
            >
              <ng-template #extraTemplate>
                <nz-space>
                  <button
                    *nzSpaceItem
                    nz-button
                    nzType="default"
                    class="text-end"
                    (click)="newInternalUserModalOpen()"
                  >
                    New Internal User
                  </button>
                  <button
                    *nzSpaceItem
                    nz-button
                    nzType="default"
                    class="text-end"
                    [disabled]="setOfCheckedId.size === 0"
                    (click)="removeUserRows()"
                  >
                    Remove
                  </button>
                </nz-space>
              </ng-template>

              <div nz-row [nzGutter]="5" class="mb-3">
                <div nz-col nzSpan="22">
                  <nz-tree-select
                    [nzNodes]="internalUserNodes"
                    nzAllowClear="false"
                    nzCheckable
                    nzCheckAll="true"
                    [(ngModel)]="selectedUserIds"
                    [nzVirtualHeight]="'250px'"
                    [nzDisabled]="selectedUserIds.includes('All users have been added to this job.') && UserdisableField"
                    [nzPlaceHolder]="selectedUserIds.includes('All users have been added to this job.') ? '' : null"
                  ></nz-tree-select>
                </div>
                <div nz-col nzSpan="2">
                  <button nz-button nzType="primary" [disabled]="selectedUserIds.length === 0 || selectedUserIds.includes('All users have been added to this job.')" (click)="addSelectedUsers()">Add</button>
                </div>
              </div>



              <div class="row">


                <nz-table
                #rowSelectionTable
                nzShowSizeChanger
                [nzData]="listOfData"
                (nzCurrentPageDataChange)="onCurrentPageDataChange($event)"
                [nzFrontPagination]="false"
                #smallTable nzSize="small"
              >
                <thead>
                  <tr>
                    <th class="col-1"
                      [(nzChecked)]="checked"
                      [nzIndeterminate]="indeterminate"
                      (nzCheckedChange)="onAllChecked($event)"
                    ></th>
                    <th class="col-7">Name</th>
                    <th class="col-1"
                      [(nzChecked)]="checked"
                      [nzIndeterminate]="indeterminate"
                      (nzCheckedChange)="onAllChecked($event)"
                    ></th>
                    <th class="col-4">Notifications</th>

                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let data of selectedUsers">
                    <td class="col-1" [nzChecked]="setOfCheckedId.has(data.id)" (nzCheckedChange)="onItemChecked(data.id, $event)"></td>
                    <td class="col-7"

                    >
                      <nz-avatar
                        class="avatar-style adjust"
                        [nzText]="internalUserAvatarText(data?.firstName, data?.lastName)"
                        style="color: #f56a00; background-color: #fde3cf"

                      ></nz-avatar>

                        <span  class="ant-popover1 company-name"
                      nzSize="large"
                      nz-popover
                      [nzPopoverContent]="contentTemplate"> {{ data?.firstName }}  {{ data?.lastName }}</span>
                    </td>
                    <td class="col-1" [nzChecked]="setOfCheckedId.has(data.id)" (nzCheckedChange)="onItemChecked(data.id, $event)"></td>
                    <td class="col-4">
                    </td>
                    <ng-template #contentTemplate>
                      <div nz-row style="width: 330px; height: auto">
                        <div nz-col nzSpan="24" class="ms-2">
                          <div nz-row [nzGutter]="60">
                            <div nz-col nzSpan="4">
                              <nz-avatar
                                class="ant-avatar1 avatar-style"
                                [nzText]="generateInitials(data?.firstName)"

                              ></nz-avatar>
                            </div>
                            <div nz-col nzSpan="15" class="ms-2 mt-2">
                              <span class="fw-bold fs-7 company-name" *ngIf="data?.firstName || data?.lastName">
                               {{data?.firstName}}   {{data?.lastName}}
                              </span>
                              <div class="p-0 m-0" *ngIf="data?.email">
                                <i class="bi bi-envelope smf p-0"></i
                                ><span class="text-primary ms-2 p-0 mail"
                                  >{{data?.email}}</span
                                >
                              </div>
                            </div>
                          </div>
                          <div nz-row>
                            <div nz-col nzSpan="24" class="ms-0">
                              <nz-space>
                                <button
                                  *nzSpaceItem
                                  nz-button
                                  nz-popover
                                  [nzPopoverContent]="Content1"
                                  nzType="text"
                                  nzSize="small"
                                >
                                  <span
                                    class="fs-4"
                                    nz-icon
                                    nzSize="large"
                                    nzType="message"
                                    nzTheme="outline"
                                  ></span>
                                </button>
                                <ng-template #Content1>
                                  Send a Messages
                                </ng-template>
                                <button
                                  *nzSpaceItem
                                  nz-button
                                  nz-popover
                                  [nzPopoverContent]="Content3"
                                  nzType="text"
                                  nzSize="small"
                                >
                                  <span
                                    class="fs-4"
                                    nzSize="large"
                                    nz-icon
                                    nzType="user"
                                    nzTheme="outline"
                                  ></span>
                                </button>

                                <ng-template #Content3>
                                <p *ngIf="data?.cellPhone">{{data?.cellPhone}}</p>
                               </ng-template>
                                <button
                                  *nzSpaceItem
                                  nz-button
                                  nz-popover
                                  [nzPopoverContent]="Content2"
                                  nzType="text"
                                  nzSize="small"
                                >
                                  <span
                                    class="fs-4"
                                    nzSize="large"
                                    nz-icon
                                    nzType="phone"
                                    nzTheme="outline"
                                  ></span>
                                </button>
                                <ng-template #Content2>
                                  {{data?.phoneNumber}}
                                </ng-template>
                              </nz-space>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                </tr>

                </tbody>
              </nz-table>


              </div>
            </nz-card>
          </ng-container>

          <ng-container class="ant-tabs-content p-2" *ngIf="tab === 4">
            <nz-card
              nzTitle="Subs/Vendors Viewing Access"
              nzBorderless
              [nzExtra]="extraTemplate"
            >
              <ng-template #extraTemplate>
                <nz-space>
                  <button nz-button nzType="default" class="text-end" [disabled]="setOfCheckedId.size === 0" (click)="removeVendorRows()">
                    Remove
                  </button>

                </nz-space>
              </ng-template>
              <div nz-row [nzGutter]="5" class="mb-3">
                <div nz-col nzSpan="22">
                  <nz-tree-select
                    [nzNodes]="subVendorsNodes"
                    nzAllowClear="false"
                    nzCheckable
                    nzCheckAll="true"
                    [nzVirtualHeight]="'250px'"
                    [(ngModel)]="selectedVenodorIds"
                    [nzDisabled]="selectedVenodorIds.includes('All Sub/Vendors have been added to this job.') && disableField"
                    [nzPlaceHolder]="selectedVenodorIds.includes('All Sub/Vendors have been added to this job.') ? 'All Sub/Vendors have been added to this job.' : null"
                  ></nz-tree-select>
                </div>
                <div nz-col nzSpan="2">
                  <button nz-button nzType="primary" [disabled]="selectedVenodorIds.length === 0 || selectedVenodorIds.includes('All Sub/Vendors have been added to this job.')"  (click)="addSelectedVendors()">Add</button>
                </div>
              </div>




              <div class="row">


                <nz-table
                #rowSelectionTable
                nzShowSizeChanger
                [nzData]="selectedVenodors"
                (nzCurrentPageDataChange)="onCurrentPageDataChange($event)"
                [nzFrontPagination]="false"
                #smallTable nzSize="small"
            >

                <thead>
                  <tr>
                    <th class="col-1"
                    [(nzChecked)]="checked"
                    [nzIndeterminate]="indeterminate"
                    (nzCheckedChange)="onAllChecked($event)"
                    >
                </th>

                    <th class="col-11">Name</th>

                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let data of  selectedVenodors">
                    <td class="col-1" [nzChecked]="setOfCheckedId.has(data.id)" (nzCheckedChange)="onItemChecked(data.id, $event)"></td>
                    <td class="col-11">
                      <nz-avatar
                        [nzText]="generateInitials(data.companyName)"
                        class="avatar-style"
                      ></nz-avatar>
                      <span nz-popover [nzPopoverContent]="SubVendorContent" class="company-name">{{ data?.companyName }}</span>
                    </td>
                  <ng-template #SubVendorContent>
                    <div nz-row style="width: 330px; height: auto" >
                      <div nz-col nzSpan="24" class="ms-2">
                        <div nz-row [nzGutter]="50">
                          <div nz-col nzSpan="5">

                           <nz-avatar
                           class="ant-avatar1"
                           [nzText]="generateInitials(data.companyName)"
                         ></nz-avatar>
                          </div>
                          <div nz-col nzSpan="15" class="ms-2 mt-2">
                            <span class="fw-bold fs-7" *ngIf="data?.companyName"> {{data?.companyName}} </span>
                            <div class="p-0 m-0" *ngIf="data?.subVenderManageEmails?.[0]?.email">
                              <i class="bi bi-envelope smf p-0"></i
                              ><span class="text-primary ms-2 p-0 mail"
                                >{{ data?.subVenderManageEmails?.[0]?.email }}</span
                              >
                            </div>
                            <div class="p-0 m-0" *ngIf="data?.primaryContactFirstLastName">
                              <i class="bi bi-person smf p-0"></i>
                              <span class="text-primary ms-2 p-0 mail">
                                {{data?.primaryContactFirstLastName}}
                              </span>
                            </div>

                          </div>
                        </div>
                        <div nz-row>
                          <div nz-col nzSpan="24" class="ms-0">
                            <nz-space>
                              <button
                                *nzSpaceItem
                                nz-button
                                nz-popover
                                [nzPopoverContent]="SubsVendor1"
                                nzType="text"
                                nzSize="small"
                              >
                                <span
                                  class="fs-4"
                                  nz-icon
                                  nzSize="large"
                                  nzType="message"
                                  nzTheme="outline"
                                ></span>
                              </button>
                              <ng-template  #SubsVendor1>
                                Send a Messages
                              </ng-template>
                              <button
                                *nzSpaceItem
                                nz-button
                                nz-popover
                                [nzPopoverContent]="SubsVendor2"
                                nzType="text"
                                nzSize="small"
                              >
                                <span
                                  class="fs-4"
                                  nzSize="large"
                                  nz-icon
                                  nzType="user"
                                  nzTheme="outline"
                                ></span>
                              </button>
                              <ng-template #SubsVendor2 >
                              <p *ngIf="data?.cellPhone">{{data?.cellPhone}}</p>
                              </ng-template>
                              <button
                                *nzSpaceItem
                                nz-button
                                nz-popover
                                [nzPopoverContent]="SubsVendor3"
                                nzType="text"
                                nzSize="small"
                              >
                                <span
                                  class="fs-4"
                                  nzSize="large"
                                  nz-icon
                                  nzType="phone"
                                  nzTheme="outline"
                                ></span>
                              </button>
                              <ng-template #SubsVendor3>
                                <p *ngIf="data?.businessPhone">{{data?.businessPhone}}</p>
                              </ng-template>
                            </nz-space>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </tr>

                </tbody>
              </nz-table>





              </div>
            </nz-card>
          </ng-container>

          <ng-container class="ant-tabs-content p-2" *ngIf="tab === 5">
            <div class="card border-0 mt-0">
              <div class="card-body p-0 m-0">
                <div class="card border-0 mt-0">
                  <div class="card-head border-bottom">
                    <div class="row pt-4 pb-2">
                      <h2 class="ms-3 famly-inn ant-typography-crad-Header">Project Management Options</h2>
                    </div>
                  </div>
                  <div class="card-body p-0 m-0">
                    <div class="container">
                      <div nz-row class="pt-3">
                        <div nz-col nzSpan="24">
                          <span span class="famly-inn ant-typography-heading-bold">Time Clock</span>
                        </div>
                      </div>
                      <nz-row class="pt-2">
                        <div nz-col nzSpan="24">
                          <label nz-checkbox [(ngModel)]="JobFormFieldsValue.isEnableGeofencingOnTimeClockShifts" name="isEnableGeofencingOnTimeClockShifts">
                            Enable geofencing on Time Clock shifts
                          </label>
                        </div>
                      </nz-row>
                      <div nz-row class="pt-3">
                        <div nz-col nzSpan="24">
                          <span span class="famly-inn ant-typography-heading-bold">Selections</span>
                        </div>
                      </div>
                      <nz-row class="pt-2">
                        <div nz-col nzSpan="24">
                          <label nz-checkbox [(ngModel)]="JobFormFieldsValue.isAllowCreationOfAllowances" name="isAllowCreationOfAllowances">
                            Allow creation of allowances
                          </label>
                        </div>
                      </nz-row>
                      <nz-row class="pt-2">
                        <div nz-col nzSpan="24">
                          <label nz-checkbox [(ngModel)]="JobFormFieldsValue.isUpdateJobRunningTotalWhenaSelectionIsApproved" name="isUpdateJobRunningTotalWhenaSelectionIsApproved">
                            Update Job Running Total when a selection is approved
                          </label>
                        </div>
                      </nz-row>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body p-0 m-0">
                <div class="card border-0 mt-0">
                  <div class="card-head border-bottom">
                    <div class="row pt-4 pb-2">
                      <h2 class="ms-3 famly-inn ant-typography-crad-Header">Financial Options</h2>
                    </div>
                  </div>
                  <div class="card-body p-0 m-0">
                    <div class="container">
                      <div nz-row class="pt-3">
                        <div nz-col nzSpan="24">
                          <span span class="famly-inn ant-typography-heading-bold">Margin and Markup</span>
                        </div>
                      </div>
                      <nz-row class="pt-2">
                        <div nz-col nzSpan="24">
                          <label >
                            This percentage below will be applied to all new line items on Estimates and Change Orders. To update existing line items, use checked actions.
                          </label>
                        </div>
                      </nz-row>
                      <div class="row">
                        <div class="col-5">
                          <div nz-row [nzGutter]="5">
                            <div nz-col nzSpan="12">
                              <label class="sub-text">Percentage Type</label>
                            </div>
                            <div nz-col nzSpan="7">
                              <label class="sub-text">Percentage </label>
                            </div>
                            <div nz-col nzSpan="2">
                            </div>
                          </div>
                          <div nz-row [nzGutter]="5">
                            <div nz-col nzSpan="12">
                              <nz-select
                                nzShowSearch
                                [(ngModel)]="JobFormFieldsValue['percentageTypeId']"
                                name="percentageTypeId"
                              >
                              <nz-option
                              *ngFor="let option of PercentageType"
                              [nzLabel]="option.name"
                              [nzValue]="option.id"
                            ></nz-option>
                              </nz-select>
                            </div>
                            <div nz-col nzSpan="7">
                              <div class="input-group">
                                <nz-input-group nzAddOnAfter="%">
                                  <input
                                  nz-input
                                  [(ngModel)]="JobFormFieldsValue.percentageValue"
                                  appDollarFormatter
                                  name="percentageValue"
                                  #stateInput="ngModel"
                                  class="form-control formField"
                                />
                                </nz-input-group>
                              </div>
                            </div>
                            <div nz-col nzSpan="2">
                              <button nz-button nzType="default">
                                <span role="img" data-icon-name="BTIcon" class="anticon"><svg width="1em" height="1em" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" class=""><path fill-rule="evenodd" clip-rule="evenodd" d="M6.02809 5.9375H3.125C2.77982 5.9375 2.5 6.21732 2.5 6.5625C2.5 6.90768 2.77982 7.1875 3.125 7.1875H6.02809C6.29701 8.09109 7.13406 8.75 8.125 8.75C9.11594 8.75 9.95299 8.09109 10.2219 7.1875H16.875C17.2202 7.1875 17.5 6.90768 17.5 6.5625C17.5 6.21732 17.2202 5.9375 16.875 5.9375H10.2219C9.95299 5.03391 9.11594 4.375 8.125 4.375C7.13406 4.375 6.29701 5.03391 6.02809 5.9375ZM7.1875 6.5625C7.1875 6.04473 7.60723 5.625 8.125 5.625C8.64277 5.625 9.0625 6.04473 9.0625 6.5625C9.0625 7.08027 8.64277 7.5 8.125 7.5C7.60723 7.5 7.1875 7.08027 7.1875 6.5625Z"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M3.125 12.8125H11.0281C11.297 11.9089 12.1341 11.25 13.125 11.25C14.1159 11.25 14.953 11.9089 15.2219 12.8125H16.875C17.2202 12.8125 17.5 13.0923 17.5 13.4375C17.5 13.7827 17.2202 14.0625 16.875 14.0625H15.2219C14.953 14.9661 14.1159 15.625 13.125 15.625C12.1341 15.625 11.297 14.9661 11.0281 14.0625H3.125C2.77982 14.0625 2.5 13.7827 2.5 13.4375C2.5 13.0923 2.77982 12.8125 3.125 12.8125ZM13.125 12.5C12.6072 12.5 12.1875 12.9197 12.1875 13.4375C12.1875 13.9553 12.6072 14.375 13.125 14.375C13.6428 14.375 14.0625 13.9553 14.0625 13.4375C14.0625 12.9197 13.6428 12.5 13.125 12.5Z"></path></svg></span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div nz-row class="pt-2">
                        <div nz-col nzSpan="24">
                          <span span class="famly-inn ant-typography-heading-bold">Purchase Orders</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-3">
                          <label class="sub-text">Individual Purchase Order Limit</label>
                          <div class="input-group">
                            <nz-input-group nzAddOnBefore="$">
                              <input
                              nz-input
                              [(ngModel)]="JobFormFieldsValue.individualBillPoLimit"
                              appDollarFormatter
                              name="individualBillPoLimit"
                              #stateInput="ngModel"
                              class="form-control formField"
                            />
                            </nz-input-group>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-3">
                          <label class="sub-text">Total Job Purchase Order Limit</label>
                          <div class="input-group">
                            <nz-input-group nzAddOnBefore="$">
                              <input
                              nz-input
                              [(ngModel)]="JobFormFieldsValue.totalJobBillPoLimit"
                              totalJobBillPoLimit
                              name="contractPrice"
                              #stateInput="ngModel"
                              class="form-control formField"
                            />
                            </nz-input-group>
                          </div>
                        </div>
                      </div>
                      <div nz-row class="pt-2">
                        <div nz-col nzSpan="24">
                          <span span class="famly-inn ant-typography-heading-bold">Budget</span>
                        </div>
                      </div>
                      <nz-row class="pt-2">
                        <div nz-col nzSpan="24">
                          <label nz-checkbox [(ngModel)]="JobFormFieldsValue.includeTimeClockLaborInTheBudget" name="includeTimeClockLaborInTheBudget">
                            Include Time Clock labor in the Budget
                          </label>
                        </div>
                      </nz-row>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body p-0 m-0">
                <div class="card border-0 mt-0">
                  <div class="card-head border-bottom">
                    <div class="row pt-4 pb-2">
                      <h2 class="ms-3 famly-inn ant-typography-crad-Header">Template Options</h2>
                    </div>
                  </div>
                  <div class="card-body p-0 m-0">
                    <div class="container">
                      <nz-row class="pt-2">
                        <div nz-col nzSpan="24">
                          <label nz-checkbox  [(ngModel)]="JobFormFieldsValue.isMakeThisJobAWorkingTemplate" name="isMakeThisJobAWorkingTemplate">
                            Make this job a working template
                          </label>
                        </div>
                      </nz-row>
                      <div class="row">
                        <div class="col-3">
                          <label class="sub-text">Template Name</label>
                            <input
                              type="text"
                              nz-input
                              [(ngModel)]="JobFormFieldsValue.templateName"
                              name="templateName"
                              class="formField"
                            />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="tab === 6">
            <div class="card-head border-bottom">
              <div class="row pt-2 pb-2">
                  <h3 class="ms-3 famly-inn ant-typography">Xero Connection</h3>
              </div>
            </div>
            <div class="card-body">
              <div nz-row>
                <div nz-col nzSpan="24" class="ms-1">
                  <div nz-row>
                    <div nz-col nzSpan="24">
                      <img src="https://buildertrend.net/images/Accounting/Xero/logo-icon-128.png" style="max-height: 40px; max-width: 148px"/>
                    </div>
                  </div>
                  <div nz-row class="mt-3">
                    <div nz-col nzSpan="24">
                      <span>Job is not linked to a Xero Contact.</span>
                    </div>
                  </div>
                  <nz-radio-group [(ngModel)]="radioValue" (ngModelChange)="onFieldReadioChange()">
                    <div class="row">
                      <div class="col-12">
                        <label nz-radio nzValue="A">Create new Contact in Xero</label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <label nz-radio nzValue="B">Choose Xero Contact</label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <label nz-radio nzValue="C">Do not create a new Contact in Xero</label>
                      </div>
                    </div>
                  </nz-radio-group>
                </div>
              </div>
            </div>
          </ng-container>
        </nz-tab>
      </nz-tabset>
    </div>
  </div>
  <ng-template #modalFooter>
    <div nz-row class="p-1 justify-content-end">
      <div nz-col nzSpan="24">
        <nz-space>
          <ng-container *nzSpaceItem>
            <button
              nz-button
              nzType="default"
              class="align-items-center"
              *ngIf="jobInformationResponse !== undefined"
              [nzLoading]="isDeleteLoading"
               (click)="deleteJobConfirm()"
              [disabled]="DisabledNextActivity"
              [class.disabled]="DisabledNextActivity"
            >
            Delete
            </button>
          </ng-container>
          <ng-container *nzSpaceItem>
            <button
              nz-button
              nzType="default"
              class="align-items-center"
              [disabled]="DisabledNextActivity"
              [class.disabled]="DisabledNextActivity"
            >
            Print
            </button>
          </ng-container>
          <ng-container *nzSpaceItem>
            <button
              nz-button
              nzType="default"
              *ngIf="jobInformationResponse !== undefined"
              class="align-items-center"
              [disabled]="DisabledNextActivity"
              [class.disabled]="DisabledNextActivity"
            >
            Copy to Template
            </button>
          </ng-container>
          <ng-container *nzSpaceItem>
            <button
              nz-button
              nzType="primary"
              class="align-items-center"
              [disabled]="DisabledNextActivity"
              [class.disabled]="DisabledNextActivity"
            >
            Save & Select Job
            </button>
          </ng-container>
          <ng-container *nzSpaceItem>
            <button
              nz-button
              nzType="primary"
              class="align-items-center"
              [nzLoading]="isSaveAndNew"
              [disabled]="DisabledNextActivity"
              [class.disabled]="DisabledNextActivity"
              nz-dropdown
              nzTrigger="click"
              [nzDropdownMenu]="menu"
              nzPlacement="topLeft">
              <span>Save & New</span>
              <i style="font-size: 14px;" nz-icon nzType="down"></i>
            </button>

          </ng-container>
          <ng-container *nzSpaceItem>
            <button
              nz-button
              nzType="primary"
              class="align-items-center"
              [nzLoading]="isSave"
              (click)="saveJob()"
              [disabled]="DisabledNextActivity"
              [class.disabled]="DisabledNextActivity"
            >
              Save
            </button>
          </ng-container>
          <ng-container *nzSpaceItem>
            <button
              nz-button
              nzType="primary"
              class="align-items-center"
              [nzLoading]="isSaveAndClose"
              (click)="saveJobAndClose()"
              [disabled]="DisabledNextActivity"
              [class.disabled]="DisabledNextActivity"
            >
              Save & Close
            </button>
          </ng-container>
        </nz-space>
      </div>
    </div>

    <nz-dropdown-menu #menu="nzDropdownMenu">
      <ul nz-menu>
        <li (click)="saveJobAndNew()" nz-menu-item>From Scratch</li>
        <li nz-menu-item>From a Template</li>
      </ul>
    </nz-dropdown-menu>
  </ng-template>
</nz-modal>
