<section class="ant-layout BTListLayoutBDS react">
    <div class="ListSection react">
        <div class="ShiftListSummaryContainer">
          <div class="ShiftListSummary">
            <ng-container *ngIf="ITimeClock_ClockInRes?.IsClockIn === true">
              <div class="CurrentShift ShiftListSummarySection Info" style="min-width: 410px;    background-color: #fafdff;border: 1px solid #0763fb;">
                <div class="ShiftListSummaryTitle">Your Current Shift</div>
                  <div class="CurrentShiftContent">
                    <div
                      class="SummaryTimeDisplay Active"
                      style="overflow: hidden; min-width: 100px"
                      [ngClass]="{'Disable': isBreakStarted, 'Active': !isBreakStarted}"
                    >
                      <div class="SummaryTimeDisplayLabel">
                        <div class="text-overflow-auto">
                          <span class="BTPopover PopoverSpacing">{{ _ShiftInformationResponse?.JobName }}</span>
                        </div>
                      </div>
                      <div class="SummaryTimeDisplayTime">{{ TimingDifference }}</div>
                      <div class="SummaryTimeDisplayTimeAlt">{{ TimingDifference }} HR</div>
                    </div>
                    <div *ngIf="TestShift_Break_Total === true">
                      <div class="SummaryTimeDisplay">
                        <div class="SummaryTimeDisplayLabel">
                          <div class="text-overflow-auto">
                            <ng-container *ngIf="!isBreakStarted || TestShift_Break_Total === true; else _YourCurrentShift">
                              <span class="BTPopover PopoverSpacing">Shift Break Total</span>
                            </ng-container>
                            <ng-template #_YourCurrentShift>
                              <span class="BTPopover PopoverSpacing">Current Break</span>
                            </ng-template>
                          </div>
                        </div>
                        <div class="SummaryTimeDisplayTime">{{ ITimeClock_ClockInRes?.DiffBreakTime }}</div>
                        <div class="SummaryTimeDisplayTimeAlt">{{ ITimeClock_ClockInRes?.DiffBreakTime }}HR</div>
                      </div>
                    </div>
                    <div  *ngIf="!TestShift_Break_Total === true || isBreakStarted === true" class="SummaryTimeDisplay" [ngClass]="{'Active': isBreakStarted, 'Disable': !isBreakStarted}">
                      <div class="SummaryTimeDisplayLabel">
                        <div class="text-overflow-auto">
                          <ng-container *ngIf="!isBreakStarted; else _YourCurrentShift">
                            <span class="BTPopover PopoverSpacing">Shift Break Total</span>
                          </ng-container>
                          <ng-template #_YourCurrentShift>
                            <span class="BTPopover PopoverSpacing">Current Break</span>
                          </ng-template>
                        </div>
                      </div>
                      <div class="SummaryTimeDisplayTime">{{ timeElapsed }}</div>
                      <div class="SummaryTimeDisplayTimeAlt">{{ timeElapsed }}HR</div>
                    </div>
                    <div class="flex align-items-center justify-content-end flex-grow-1 margin-left-xs">
                      <span class="click-btn" *ngIf="!isBreakStarted" (click)="onStart_Time('Start_Break')">Start Break</span>
                      <span class="click-btn" *ngIf="isBreakStarted" (click)="onStart_Time('End_Break')">End Break</span>
                    </div>
                  </div>
                  <div class="SummaryLabel">
                   <span style="white-space: nowrap">{{ _ShiftInformationResponse?.FormattedUserClockStatus }}</span>,
                  </div>
                </div>
            </ng-container>
            <div 
              class="ShiftTotals ShiftListSummarySection" 
              [ngClass]="EndTimeError ? 'Error' : 'Active'" 
              style="margin-left: 8px;">
            <!-- <div 
              class="ShiftTotals ShiftListSummarySection" 
              [ngClass]="{'UnActive': EndTimeError === true | 'Active': EndTimeError === false}" 
              style="margin-left: 8px;"> -->
              <div class="ShiftListSummaryTitle">Your Time Clock Totals</div>
              <ng-container *ngIf="ITimeClock_ClockInRes?.IsClockIn === true || ITimeClock_ClockInRes?.IsClockIn ===  false && ITimeClock_ClockInRes?.EndTime; else YourTimeClockTotals">
                  <div class="ShiftTotalsContent">
                    <div class="SummaryTimeDisplay" [ngClass]="EndTimeError ? 'Error' : 'Active'" >
                      <div class="SummaryTimeDisplayLabel">
                        <div class="text-overflow-auto">
                          <span class="BTPopover PopoverSpacing">Daily Total</span>
                        </div>
                      </div>
                      <div class="SummaryTimeDisplayTime">{{ ITimeClock_ClockInRes?.DailyTime }}</div>
                      <div class="SummaryTimeDisplayTimeAlt">{{ ITimeClock_ClockInRes?.DailyTime }} HR</div>
                    </div>
                    <div class="SummaryTimeDisplay" *ngIf="ITimeClock_ClockInRes?.DiffBreakTime !== '00:00' || isBreakStarted">
                      <div class="SummaryTimeDisplayLabel">
                        <div class="text-overflow-auto">
                          <span class="BTPopover PopoverSpacing">Daily Break Total</span>
                        </div>
                      </div>
                      <div class="SummaryTimeDisplayTime">{{ ITimeClock_ClockInRes?.DiffBreakTime }}</div>
                      <div class="SummaryTimeDisplayTimeAlt">{{ ITimeClock_ClockInRes?.DiffBreakTime }} HR</div>
                    </div>
                    <div class="SummaryTimeDisplay">
                      <div class="SummaryTimeDisplayLabel">
                        <div class="text-overflow-auto">
                          <span class="BTPopover PopoverSpacing">Weekly Total</span>
                        </div>
                      </div>
                      <div class="SummaryTimeDisplayTime">{{ ITimeClock_ClockInRes?.WeeklyTime }}</div>
                      <div class="SummaryTimeDisplayTimeAlt">{{ ITimeClock_ClockInRes?.WeeklyTime }} HR</div>
                    </div>
                  </div>
                  <div class="SummaryLabel">
                    <div class="flex">
                      <div>01.98 HR</div>
                      until daily overtime.
                    </div>
                  </div>
              </ng-container>
              <ng-template #YourTimeClockTotals>
                  <div class="ShiftTotalsContent">
                    <div class="ShiftTotalsEmptyState flex align-items-center">
                      <span
                        role="img"
                        size="60"
                        data-testid="EmptyStateIcon"
                        data-icon-name="BTIcon"
                        class="anticon EmptyStateIcon margin-right-xs"
                        style="font-size: 60px"
                        ><svg
                          width="1em"
                          height="1em"
                          viewBox="0 0 32 32"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                          focusable="false"
                          class=""
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M16 3C23.1797 3 29 8.8203 29 16C29 23.1797 23.1797 29 16 29C8.8203 29 3 23.1797 3 16C3 8.8203 8.8203 3 16 3ZM16 5C9.92487 5 5 9.92487 5 16C5 22.0751 9.92487 27 16 27C22.0751 27 27 22.0751 27 16C27 9.92487 22.0751 5 16 5ZM16.9933 8.88338C16.9355 8.38604 16.5128 8 16 8C15.4477 8 15 8.44772 15 9V16L15.0067 16.1166C15.0645 16.614 15.4872 17 16 17H23L23.1166 16.9933C23.614 16.9355 24 16.5128 24 16L23.9933 15.8834C23.9355 15.386 23.5128 15 23 15H17V9L16.9933 8.88338Z"
                          ></path></svg>
                        </span>
                      <div>
                        <div><strong>No shifts recorded for you this week.</strong></div>
                        <div>
                          Clock in to track your daily and weekly hours and breaks on the
                          Job.
                        </div>
                      </div>
                    </div>
                  </div>
              </ng-template>
            </div>
            <div
              class="UserCount ShiftListSummarySection flex flex-column"
              style="margin-left: 8px;margin-top: 10px;  border: 1px solid #c7d0d9;background-color: #fff;">
              <div class="ShiftListSummaryTitle">Clocked In Users</div>
              <div class="flex align-items-center padding-top-xs" style="height: 100%">
                  <ng-container *ngIf="ITimeClock_ClockInRes?.IsClockIn === true; else ClockedInUsers">
                      <div class="UserCountBadge"><div>1</div></div>
                      <div class="UserCountContent">User on <span>{{ ITimeClock_ClockInRes?.FormattedUserStatus }}</span>
                      </div>
                  </ng-container>
                  <ng-template #ClockedInUsers>
                    <!-- <div class="UserCountContent">No users clocked in on test 12 as of 7:21 PM</div> -->
                    <div class="UserCountContent">User on<span class="ms-1">{{ _AccessLocalStorageService.getJobName() }}</span>as of <span class="ms-1">{{ currentTime_ }}</span></div>
                  </ng-template>
              </div>
            </div>
          </div>
        </div>
  </div>
</section>
