import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NonNullableFormBuilder, FormGroup, FormControl, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { LookupNameSetupResponse, LookupNameSetup } from 'src/Models/LeadTags';
import { LeadOpportunitiesService } from 'src/Service/lead-opportunities.service';

@Component({
  selector: 'app-location-select',
  templateUrl: './location-select.component.html',
  styleUrls: ['./location-select.component.css']
})
export class LocationSelectComponent {
  @Output() tagSaved = new EventEmitter<void>();

  @Input() _selectedEditLocation: LookupNameSetupResponse;
  @Output() locationDeleted = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();
  Tags: boolean = false
  pageId: number = 30;
  isLoadingOne: boolean = false
  maxNameLength = 50;
  deleteLoading = false;
  @Output() LocationSaved = new EventEmitter<LookupNameSetupResponse>();  
  @Output() phaseSaved = new EventEmitter<void>();
  @Output() LocationSavedSelected = new EventEmitter<any>();  
  @Output() phaseSavedAndNew =new EventEmitter<any>();  

  constructor(private fb: NonNullableFormBuilder,
    private leadOppService: LeadOpportunitiesService,
    public toastService: NzMessageService,
    private modal: NzModalService     
  ) {

  }


  LocationNzSelectForm: FormGroup<{
    id: FormControl<string>;
    globalId: FormControl<string>;
    name: FormControl<string>;
    companyParameterId: FormControl<number>;
    formNameId: FormControl<number>;
    lookUpStandardId: FormControl<number>;
    parentCode: FormControl<string>;
    code: FormControl<string>;
    description: FormControl<string>;
    isFieldValue: FormControl<boolean>;
  }>;

  ngOnInit(): void {
    this.initializeForm();

    if (this._selectedEditLocation) {
      this.patchFormValues(this._selectedEditLocation); // Patch form with selectedTag values if available
    }
  }

  onCancel(): void {
    this.cancel.emit(); 
  }
  
  initializeForm() {
    this.LocationNzSelectForm = this.fb.group({
      id: [''],
      globalId: ['00000000-0000-0000-0000-000000000000'],
      name: ['', [Validators.required, Validators.maxLength(this.maxNameLength)]],
      companyParameterId: [1],
      formNameId: this.pageId,
      lookUpStandardId: [68],
      parentCode: ['PMSLL'],
      code: ['PMSLL'],
      description: [''],
      isFieldValue: [false],

    });
  }


  // Method to patch form values based on the selected tag
  patchFormValues(selectedLocation: any): void {
    this.LocationNzSelectForm.patchValue({
      id: selectedLocation?.id.toString(),
      globalId: selectedLocation?.globalId,
      name: selectedLocation?.name,
      companyParameterId: selectedLocation?.companyParameterId ?? 1,
      formNameId: selectedLocation?.formNameId,
      lookUpStandardId: selectedLocation?.lookUpStandardId,
      parentCode: selectedLocation?.parentCode,
      code: selectedLocation?.code,
      description: selectedLocation?.description,
      isFieldValue: selectedLocation?.isFieldValue,
    });


  }

  get nameControl(): FormControl<string> {
    return this.LocationNzSelectForm.get('name') as FormControl<string>;
  }

  getOverCharacters(): number {
    const value = this.nameControl.value || '';
    return value.length - this.maxNameLength;
  }

  // Method to check if the character count exceeds the limit
  isOverLimit(): boolean {
    return this.nameControl.value.length > this.maxNameLength;
  }

  LocationNzSelectDropdownSaveMethod() {
  if (this.LocationNzSelectForm.valid) {
    this.isLoadingOne = true;
    this.leadOppService.getLookUpNameSetup(this.LocationNzSelectForm.value as LookupNameSetup).subscribe(
      response => {
        this.initializeForm(); // Reset the form on success
        this.isLoadingOne = false;
        this.toastService.success('Saved successfully Location');
        this.LocationSaved.emit(response.result);  // Ensure this emits the correct data
        this.cancel.emit(); // Emit the cancel event
      },
      (error) => {
        this.isLoadingOne = false;
        if (error.status === 500) {
          this.toastService.error('Internal Server Error. Please try again later.');
        } else if (error.status === 400) {
          this.toastService.error('Bad Request. Please check your input.');
        } else {
          this.toastService.error('Failed to save trade. Please try again.');
        }
      }
    );
  } else {
    Object.values(this.LocationNzSelectForm.controls).forEach(control => {
      if (control.invalid) {
        control.markAsDirty();
        control.updateValueAndValidity({ onlySelf: true });
      }
    });
  }
}



  updateConfirmValidator(): void {
    Promise.resolve().then(() => this.LocationNzSelectForm?.controls.name.updateValueAndValidity());
  }

  confirmationValidator: ValidatorFn = (control: AbstractControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true };
    } else if (control.value !== this.LocationNzSelectForm?.controls.name.value) {
      return { confirm: true, error: true };
    }
    return {};
  };




  AddNewPhaseCancel() {
    this.Tags = false
  }


  showLocationConfirmDelete(): void {
    this.modal.confirm({
      nzTitle: 'Delete Schedule Item Tag?',
      nzContent: 'Are you sure you want to delete this Schedule Item Tag',
      nzOkText: 'Delete',
      nzCentered: true,
      nzOkDanger: true,
      nzOkLoading: this.deleteLoading, 
      nzOnOk: () => {
        this.deleteLoading = true; 
        return this.onDeleteLocation().then(() => {
          this.deleteLoading = false; 
        }).catch(() => {
          this.deleteLoading = false; 
        });
      },
      nzCancelText: 'Cancel',
      nzOnCancel: () => console.log('Delete action canceled')
    });
  }
  
  onDeleteLocation(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this._selectedEditLocation && this._selectedEditLocation.globalId) {
        this.leadOppService.deleteDataLookupNameSetup(this._selectedEditLocation.globalId).subscribe(
          () => {
            this.toastService.success('Deleted successfully');
            this.cancel.emit(); 
            this.phaseSaved.emit();
            this.locationDeleted.emit();

            resolve(); 
          },
          err => {
            this.toastService.error('Failed to delete phase');
            reject(err); 
          }
        );
      } else {
        reject('No phase selected to delete');
      }
    });
  }
}
