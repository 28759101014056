import { Component } from '@angular/core';

@Component({
  selector: 'app-time-clock-empty',
  templateUrl: './time-clock-empty.component.html',
  styleUrls: ['./time-clock-empty.component.css']
})
export class TimeClockEmptyComponent {

}
