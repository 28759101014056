<nz-modal [(nzVisible)]="isVisibleInClockIn" [nzTitle]="modalTitleInClockIn" [nzFooter]="modalFooterInClockIn" [nzBodyStyle]="{
  background: '#f1f4fa',
  padding: '14px',
  maxHeight: '69vh',
  'overflow-y': 'auto',
  'overflow-x': 'hidden',
}" [nzStyle]="{ top: '25px'}" [nzWidth]="'33%'" (nzOnCancel)="CancelClockIn()">
  <ng-template #modalTitleInClockIn>
    <div class="row">
      <ng-container *ngIf="argument === 'ClockIn'">
        <h2 class="p-0 m-0 famly-inn ant-typography-Modal">Clock In</h2>
      </ng-container>
      <ng-container *ngIf="argument === 'ClockInUser'">
        <h2 class="p-0 m-0 famly-inn ant-typography-Modal">Clock In Multiple</h2>
      </ng-container>
      <ng-container *ngIf="argument === 'ClockOut'">
        <h2 class="p-0 m-0 famly-inn ant-typography-Modal">Clock Out</h2>
      </ng-container>
    </div>
  </ng-template>
  <div class="content" *nzModalContent>
    <nz-alert class="rounded-3 mb-3 mt-0 error-message" *ngIf="isSubmitted && errorFields.length > 0" nzType="error" nzShowIcon [nzMessage]="message" [nzDescription]="errorDescriptionTemplate"></nz-alert>
    <ng-template #message>
      <p class="mt-1">Please correct the following fields:</p>
    </ng-template>
    <ng-template #errorDescriptionTemplate>
      <ul *ngFor="let error of errorFields" class="m-0">
        <li class="famly-inn p-0 m-0 ">
          <span class="ms-0">{{ error.label }}:</span>
          <span class="ms-1">{{ error.message }}</span>
        </li>
      </ul>
    </ng-template>

    <div class="card border-0 mt-0">
      <div class="card-head border-bottom">
        <div class="row pt-2 pb-2">
          <h2 class="ms-3 famly-inn ant-typography-crad-Header">Current Shift</h2>
        </div>
      </div>
      <div class="card-body">
        <ng-container *ngIf="argument === 'ClockOut'; else ClockOut">
          <div nz-row class="full-height d-flex justify-content-center">
            <div nz-col>
              <div nz-row>
                <div nz-col>
                  <div nz-row>
                    <div nz-col nzSpan="24">
                      <label class="sub-text">Current Time</label>
                    </div>
                  </div>
                  <div nz-row>
                    <div nz-col nzSpan="24" class="d-flex justify-content-center">
                      <label style="font-size: 16px;font-weight: 500;" class="sub-text">{{ currentTime_ }}</label>
                    </div>
                  </div>
                </div>
                <div nz-col>
                  <div nz-row>
                    <div nz-col nzSpan="24">
                      <nz-divider nzType="vertical"></nz-divider>
                    </div>
                  </div>
                </div>
                <div nz-col>
                  <div nz-row>
                    <div nz-col nzSpan="24">
                      <label class="sub-text">Hours Worked</label>
                    </div>
                  </div>
                  <div nz-row>
                    <div nz-col nzSpan="24" class="d-flex justify-content-center">
                      <label style="font-size: 16px;font-weight: 500;" class="sub-text"><span>{{ TimingDifference }}</span></label>
                    </div>
                  </div>
                </div>
                <div nz-col>
                  <div nz-row>
                    <div nz-col nzSpan="24">
                      <nz-divider nzType="vertical"></nz-divider>
                    </div>
                  </div>
                </div>
                <div nz-col>
                  <div nz-row>
                    <div nz-col nzSpan="24">
                      <label class="sub-text">Breaks Taken</label>
                    </div>
                  </div>
                  <div nz-row>
                    <div nz-col nzSpan="24" class="d-flex justify-content-center">
                      <ng-container *ngIf="ITimeClock_ClockInRes?.DiffBreakTime === '00:00'; else showDiffBreakTime">
                        <label style="font-size: 16px; font-weight: 500;" class="sub-text">{{ timeElapsed }}</label>
                      </ng-container>
                      <ng-template #showDiffBreakTime>
                        <label style="font-size: 16px; font-weight: 500;" class="sub-text">{{ ITimeClock_ClockInRes?.DiffBreakTime }}</label>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-template #ClockOut>
          <div nz-row>
            <div nz-col [nzXs]="24" class="d-flex justify-content-center">
              <h2 style="font-size: 20px"  class="famly-inn ant-typography-crad-Header">{{ currentTime_  }}</h2>
            </div>
          </div>
        </ng-template>
        <div nz-row class="pt-3">
          <div nz-col [nzXs]="24">
              <label class="sub-text">Job<span class="ms-1 text-danger text-bold">*</span></label>
              <ng-container *ngIf="argument === 'ClockOut'; else _jobInformationId">
                <div nz-row>
                  <div nz-col [nzXs]="24">
                    <span class="ant-tag">{{ ITimeClock_ClockInRes?.JobName }}</span>
                  </div>
                </div>
              </ng-container>
              <ng-template #_jobInformationId>
                <nz-form-item>
                  <nz-form-control [nzErrorTip]="validationMessages['jobInformationId']" [nzValidateStatus]="validationStates['jobInformationId']">
                    <nz-select
                      (ngModelChange)="onFieldChange('jobInformationId', $event)"
                      nzShowSearch
                      [(ngModel)]="timeClockFormFields.jobInformationId" name="jobInformationId"
                      id="jobInformationId"
                      name="jobInformationId"
                    >
                    <ng-container *ngIf="onJob_Id === null">
                      <nz-option nzLabel="-- Please Select a Job --" [nzValue]="0"></nz-option>
                    </ng-container>
                    <nz-option *ngFor="let option of jobList" [nzLabel]="option.name" [nzValue]="option.id"></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </ng-template>
          </div>
        </div>
        <div nz-row>
          <div nz-col [nzXs]="24">
              <label class="sub-text">User<span class="ms-1 text-danger text-bold">*</span></label>
          </div>
        </div>
        <ng-container *ngIf="argument === 'ClockInUser' else ClockIn">
          <nz-form-item>
            <nz-form-control [nzErrorTip]="validationMessages['applicationUserIds']" [nzValidateStatus]="validationStates['applicationUserIds']">
              <nz-tree-select
              [(ngModel)]="timeClockFormFields.applicationUserIds" name="applicationUserIds"
              [nzNodes]="UserNodes"
              style="width: 100%"
              [nzShowSearch]="true"
              [nzAllowClear]="true"
              (ngModelChange)="onFieldChange('applicationUserIds', $event)"
              nzCheckable>
              </nz-tree-select>
            </nz-form-control>
          </nz-form-item>
        </ng-container>
        <ng-template #ClockIn>
          <div nz-row style="justify-content: space-between;" class="IsCompleted_Assignee">
            <div nz-col>
              <div style="display: flex; align-items: center; gap: 10px;">
                  <div nz-col class="ant-avt-Assingee">
                      <nz-avatar nzText="UF"></nz-avatar>
                  </div>
                  <div nz-col>
                      <div class="text-overflow-auto BTUser isCurrentUser" style="font-size: 14px;">Umar Farooq</div>
                  </div>
              </div>
            </div> 
          </div>
        </ng-template>
        <div nz-row>
          <div nz-col [nzXs]="24">
              <label class="sub-text">Cost Code</label>
              <nz-select nzShowSearch  [(ngModel)]="timeClockFormFields.costCodeId" name="costCodeId">
                  <nz-option *ngFor="let option of CostCodeList" [nzLabel]="option.title" [nzValue]="option.id"></nz-option>
              </nz-select>
          </div>
        </div>
        <div nz-row class="pt-2">
          <div nz-col nzSpan="2" class="d-flex justify-content-center align-items-center">
            <span class="Click-Btn famly-inn ms-2">Add</span>
          </div>
          <div nz-col nzSpan="2" class="d-flex justify-content-center align-items-center">
            <span class="Click-Btn famly-inn ms-2">Edit</span>
          </div>
        </div>
        <div nz-row>
          <div nz-col nzSpan="24">
            <label class="sub-text">Tags</label>
          </div>
        </div>
        <div nz-row>
          <div nz-col nzSpan="20">
            <nz-tree-select
              [(ngModel)]="timeClockFormFields.createShiftTagParameterRequests"
              name="createShiftTagParameterRequests"
              [nzNodes]="TagNodes"
              style="width: 100%"
              [nzShowSearch]="true"
              [nzAllowClear]="true"
              nzCheckable>
            </nz-tree-select>
          </div>
          <div nz-col nzSpan="2" class="d-flex justify-content-end align-items-center">
            <span class="Click-Btn famly-inn" (click)="TimeClockTagOpen(null, 'TimeClockAdd')">Add</span>
          </div>
          <div nz-col nzSpan="2" class="d-flex justify-content-end align-items-center">
            <span
              [class.disabled]="isEditButtonDisabled()"
              class="Click-Btn famly-inn"
              (click)="!isEditButtonDisabled() && TimeClockTagOpen(timeClockFormFields.createShiftTagParameterRequests, 'TimeClockEdit')">
              Edit
            </span>
          </div>
        </div>
        <div nz-row>
          <div nz-col [nzXs]="24">
              <label class="sub-text">Notes</label>
              <nz-form-control>
                <textarea [(ngModel)]="timeClockFormFields.notes" name="notes" 
                  nz-input [nzAutosize]="{ minRows: 2, maxRows: 10 }"
                  class="formField" style="resize: none;"
                  [nzStatus]="validationStates['notes']"
                  (ngModelChange)="onFieldChange('notes', $event)"
                  ></textarea>
              </nz-form-control>
              <div
                *ngIf="validationStates['notes'] === 'error'"
                class="text-danger"
              >
                {{ validationMessages["notes"] }}
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #modalFooterInClockIn>
    <div nz-row class="p-1 justify-content-end">
      <div nz-col nzSpan="24">
        <nz-space>
          <ng-container *nzSpaceItem>
            <button
              *ngIf="argument === 'ClockOut'"
              nz-button
              nzType="primary"
              class="align-items-center"
              (click)="saveClockIn()"
              [nzLoading]="isSave"
              [disabled]="DisabledNextActivity"
              [class.disabled]="DisabledNextActivity">
              Clock Out
            </button>
          </ng-container>
          <ng-container *nzSpaceItem>
            <button
              *ngIf="argument === 'ClockInUser'"
              nz-button
              nzType="primary"
              class="align-items-center"
              (click)="saveClockIn()"
              [nzLoading]="isSave"
              [disabled]="DisabledNextActivity"
              [class.disabled]="DisabledNextActivity">
              Clock In Users
            </button>
          </ng-container>
          <ng-container *nzSpaceItem>
            <button
              *ngIf="argument === 'ClockIn'"
              nz-button
              nzType="primary"
              class="align-items-center"
              (click)="saveClockIn()"
              [nzLoading]="isSave"
              [disabled]="DisabledNextActivity"
              [class.disabled]="DisabledNextActivity">
              Clock In
            </button>
          </ng-container>
        </nz-space>
      </div>
    </div>
  </ng-template>
</nz-modal>
