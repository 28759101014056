import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ITimeClockSetup, ITimeClockShiftGetById } from 'src/Models/_Time_Clock/_Time_Clock';
import { TimeClockSService } from 'src/Service/_Time_Clock/time-clock-s.service';
import { ProjectManagementTimeClockDbComponent } from '../project-management-time-clock-db/project-management-time-clock-db.component';
import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';

@Component({
  selector: 'app-time-clock-crads',
  templateUrl: './time-clock-crads.component.html',
  styleUrls: ['./time-clock-crads.component.css']
})
export class TimeClockCradsComponent implements OnChanges, OnInit {
  @ViewChild(ProjectManagementTimeClockDbComponent) onChangesGrid!: ProjectManagementTimeClockDbComponent;
  @Output() onStartTime_Changes = new EventEmitter<ITimeClockShiftGetById>();
  @Input() _ShiftInformationResponse: ITimeClockShiftGetById; 
  // @Input() _EndTimeError: boolean; 
  onStartBreakTimeRes: ITimeClockShiftGetById;
  TimingDifference: any;
  startTime: Date | null = null;
  endTime: Date | null = null;
  timerInterval: any;
  timeElapsed: string = '00.00';
  isBreakStarted: boolean = false;
  TestShift_Break_Total: boolean = false;
  EndTimeError: boolean = false;
  ITimeClock_ClockInRes: ITimeClockShiftGetById;
  ITimeClockSetupResponse: ITimeClockSetup;
  timeDifferenceInterval: any;
  JobName: string;
  currentTime_: string;

  constructor(
    public _TimeClockSService: TimeClockSService,
    public _AccessLocalStorageService: AccessLocalStorageService,
  ) {
    this.updateTime();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['_ShiftInformationResponse'] && this._ShiftInformationResponse) {
      this.onGetClockOut();
      if(this._ShiftInformationResponse?.StartTime !== undefined && this._ShiftInformationResponse?.EndTime !== undefined){
        localStorage.removeItem('Your_Current_Shift_Time');
      }
      if (this._ShiftInformationResponse?.StartTime !== undefined && !this._ShiftInformationResponse?.EndTime) {
        localStorage.setItem('Your_Current_Shift_Time', this._ShiftInformationResponse?.StartTime);
        this.startLiveTimeDifferenceUpdate(this._ShiftInformationResponse?.StartTime);
      }
    }
  }
  ngOnInit() {
    this.onGetClockOut();
    const storedStartTime = this._AccessLocalStorageService.onGetStartTime();
    const storedEndTime = this._AccessLocalStorageService.onGetEndTime();
    
    if (storedStartTime) {
      this.startTime = new Date(storedStartTime);
      this.isBreakStarted = true;
      this.updateTimer();
    }

    if (storedEndTime) {
      this.endTime = new Date(storedEndTime);
      this.isBreakStarted = false;
      clearInterval(this.timerInterval);
      this.calculateTimeElapsed();
    }
  }
  startLiveTimeDifferenceUpdate(startTime: string) {
    this.On_CulateTimeDifference(startTime);
  
    if (this.timeDifferenceInterval) {
      clearInterval(this.timeDifferenceInterval);
    }
  
    this.timeDifferenceInterval = setInterval(() => {
      this.On_CulateTimeDifference(startTime);
    }, 1000);
  }
  
  On_CulateTimeDifference(startTime: string) {
    const Test_Case = new Date(startTime);
    const diffMs = new Date().getTime() - Test_Case.getTime();
    const diffMinutes = Math.floor((diffMs / (1000 * 60)) % 60);
    const diffHours = Math.floor(diffMs / (1000 * 60 * 60));
    this.TimingDifference = `${diffHours} ${diffMinutes}`;
  }
  ngOnDestroy(): void {
    if (this.timeDifferenceInterval) {
      clearInterval(this.timeDifferenceInterval);
    }
  }
  onStart_Time(argument: string) {
    this._TimeClockSService.updateStartTime(this._ShiftInformationResponse?.GlobalId).subscribe(res => {
      if(argument === 'Start_Break'){
        this.onStartTime();
      } else if(argument === 'End_Break'){
        this.onEndTime();
        this.onStartTime_Changes.emit(res.result);
      }
      this.onGetClockOut();
      this.onStartBreakTimeRes = res?.result;
    });
  }
  onStartTime() {
    this.startTime = new Date();
    this._AccessLocalStorageService.setStatBreakTime(this.startTime);
    this.isBreakStarted = true;
    this.updateTimer();
  }
  onEndTime() {
    if (this.startTime) {
      this.endTime = new Date();
      this._AccessLocalStorageService.setEndBreakTime(this.endTime);
      this.isBreakStarted = false;
      clearInterval(this.timerInterval);

      this.endTime = null
      this.calculateTimeElapsed();
      this.timeElapsed = '00.00'
      this._AccessLocalStorageService.clearStartAndEndTime();
    }
  }
  updateTimer() {
    this.timerInterval = setInterval(() => {
      this.calculateTimeElapsed();
    }, 1000);
  }
  calculateTimeElapsed() {
    if (this.startTime) {
      const currentTime = this.endTime || new Date();
      const diffMs = currentTime.getTime() - this.startTime.getTime();
      const diffSeconds = Math.floor((diffMs / 1000) % 60);
      const diffMinutes = Math.floor((diffMs / (1000 * 60)) % 60);
      const diffHours = Math.floor(diffMs / (1000 * 60 * 60));
      this.timeElapsed = `${diffHours} ${diffMinutes}`;
    }
  }
  onGetClockOut(){
    this._TimeClockSService.GetClockOut().subscribe(res => {
      this.ITimeClock_ClockInRes = res.result
      if(this.ITimeClock_ClockInRes?.DiffBreakTime === "00:00"){
        this.TestShift_Break_Total = false;
      } else {
        this.TestShift_Break_Total = true;
      }
      if (res.result?.StartTime?.length > 0 && res.result?.EndTime) {
        this.fetchTimeClockSetupForm();
        const startTime = new Date(res.result?.StartTime);
        const endTime = new Date(res.result?.EndTime);
        const timeDifference = endTime.getTime() - startTime.getTime();
      
        const diffInHours = Math.floor(timeDifference / (1000 * 60 * 60));
        const diffInMinutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
        const diffInSeconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
      
        const formattedDifference = `${diffInHours} hours, ${diffInMinutes} minutes, ${diffInSeconds} seconds`;
        const thresholdHours = this.ITimeClockSetupResponse?.afterNoOfHoursOverTimeDaily || 2;
        const thresholdMinutes = thresholdHours * 60;
      
        const totalMinutesDifference = (diffInHours * 60) + diffInMinutes;
        if (totalMinutesDifference > thresholdMinutes) {
          this.EndTimeError = true;
        } else {
          this.EndTimeError = false;
        }
      }
      this.onChangesGrid?.onAfterResponse();
    });
  }
  fetchTimeClockSetupForm(): void {
    this._TimeClockSService.getCompanyTimeClockSetup().subscribe(res => {
      this.ITimeClockSetupResponse = res?.result
    });
  }
  OnTest(){
    this.EndTimeError = false;
    localStorage.removeItem('Your_Current_Shift_Time');
  }
  updateTime() {
    const now = new Date();
    this.currentTime_ = now.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
    });
  }
}