import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, throwError } from 'rxjs';
import { ChecklistItem, CreateIsCompletedRequest, CreateToDoRequest, CreateToDoSetupRequest, TodoGridResponse, ToDoItemResult, ToDoRelatedItemsResult, ToDoSetupResponse } from 'src/Models/Project-Management/projectManagement';
import { CopyTodoRequest, ToDoParameter } from 'src/Models/Warranty/warranty';
import { ResponseModel, TablePageResponse, ResponseModelArray } from 'src/Models/responseMessage.model';
import { CreateLookupFilterRequest, FilterSearchParameter } from 'src/app/shared/component/Models/LookupModels';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class ToDoInformationService {
  [x: string]: any;

  // private ToDo_TO_DO_URL: string = environment.apiUrl + 'ToDoSetup/';
  private PROJECT_MANAGMENT_TO_DO_URL: string = `${environment.apiUrl}ToDo/IsCompleted?globalId=`;
  private PROJECT_MANAGeMENT_TO_DO_URLs: string = `${environment.apiUrl}ToDo`;
  // private PROJECT_MANAGMENT_TO_DO_Delete_URL: string = environment.apiUrl + 'ToDo/DeleteParameter-ByToDoId?ToDoId';
  private PRO_MANAG_TO_DO_CheckList_URL: string = environment.apiUrl + 'ToDo/create-ToDoCheckList';
  private base_Url: string = environment.apiUrl + 'LookupFilter/';

  gettoDosData = new BehaviorSubject<ResponseModel<TablePageResponse<TodoGridResponse>>>(null);
  $getToDoInformation = this.gettoDosData.asObservable();

  isUserAppliedFilter = new BehaviorSubject<boolean>(false);
  $isUserAppliedFilter = this.isUserAppliedFilter.asObservable();

  selectedFilterId = new BehaviorSubject<number>(0);
  $selectedFilterId = this.selectedFilterId.asObservable(); private Comp_Set_TO_DO_Setup_URL: string = environment.apiUrl + 'ToDoSetup/';
  private Comp_Set_TO_DO_Get_Setup_URL: string = environment.apiUrl + 'ToDoSetup/';


  constructor(private http: HttpClient) { }


  getAlltodoUsersByFilter(filterSearchParameter: FilterSearchParameter) {
    const apiUrl = `${this.base_Url}GetAll-ByFilter`;
    return this.http.post<any>(apiUrl, filterSearchParameter).subscribe(
      (data) => {
        this.gettoDosData.next(data);
      },
      (error) => {
        console.error('Error fetching internal users:', error);
      }
    );
  }



  postDataToDoModel(ToDosFormData: CreateToDoRequest): Observable<ResponseModel<ToDoItemResult>> {
    const apiUrl = `${this.PROJECT_MANAGeMENT_TO_DO_URLs}`;
    return this.http.post<any>(apiUrl, ToDosFormData).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }

  // Circle Is Complete For Todo Grid
  updateIsComplete(globalId: string, requestBody: any): Observable<any> {
    const endpoint = `${this.PROJECT_MANAGMENT_TO_DO_URL}${globalId}`;
    return this.http.put(endpoint, requestBody);
  }


  postDataToDoToDoSetupCompSet(ToDoSetupCompSet: CreateToDoSetupRequest): Observable<ResponseModel<ToDoSetupResponse>> {
    const apiUrl = `${this.Comp_Set_TO_DO_Setup_URL}`;
    return this.http.post<any>(apiUrl, ToDoSetupCompSet).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }

  getData(): Observable<ResponseModelArray<ToDoSetupResponse>> {
    return this.http.get<ResponseModelArray<ToDoSetupResponse>>(`${this.Comp_Set_TO_DO_Get_Setup_URL}getData-ByCompany`);
  }


  setSelectedFilterId(filterId: number) {
    this.selectedFilterId.next(filterId);
  }


  // getAlltodoUsersByFilter(filterSearchParameter: FilterSearchParameter) {
  //   const apiUrl = `${this.base_Url}GetAll-ByFilter`;
  //   return this.http.post<ResponseModel<TablePageResponse<TodoGridResponse>>>(apiUrl, filterSearchParameter)
  //     .subscribe(
  //       (data: ResponseModel<TablePageResponse<TodoGridResponse>>) => {
  //         // Ensure the response structure matches ResponseModel with result
  //         if (data?.result?.rows && Array.isArray(data.result.rows)) {
  //           this.gettoDosData.next(data); // Pass the full ResponseModel object
  //         } else {
  //           console.error('Unexpected data format:', data);
  //         }
  //       },
  //       (error) => {
  //         console.error('Error fetching internal users:', error);
  //       }
  //     );
  // }




  getAppliedFilterData(appliedFilter: CreateLookupFilterRequest[]) {
    const apiUrl = `${this.base_Url}GetAll-ByLookupFilter`;
    return this.http.post<any>(apiUrl, appliedFilter).subscribe(
      (data) => {
        this.gettoDosData.next(data);
      },
      (error) => {
        console.error('Error fetching internal users:', error);
      }
    );
  }

  updateIsCompleted(internalUserFormData: CreateIsCompletedRequest): Observable<ResponseModel<ToDoParameter>> {
    const apiUrl = `${this.PROJECT_MANAGMENT_TO_DO_URL}IsCompleted`;
    return this.http.put<ResponseModel<ToDoParameter>>(apiUrl, internalUserFormData);
  }

  deleteRowTodoRange(globalIds: string[]): Observable<ResponseModel<ToDoParameter>> {
    const apiUrl = `${this.PROJECT_MANAGMENT_TO_DO_URL}Delete-TodoParameters`;
    return this.http.delete<ResponseModel<ToDoParameter>>(apiUrl, { body: globalIds });
  }


  postCopyToDos(CopyTodoRequest: CopyTodoRequest): Observable<ResponseModel<ToDoParameter>> {
    const apiUrl = `${this.PROJECT_MANAGMENT_TO_DO_URL}CopyTodoFromTodo`;
    return this.http.put<any>(apiUrl, CopyTodoRequest).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }


  private PROJECT_MANAGMENT_TO_DO_Delete_URL: string = environment.apiUrl + 'ToDo/DeleteParameter-ByToDoId?ToDoId=';

  deleteToDo(ToDoId: number): Observable<ResponseModel<any>> {
    return this.http.delete<ResponseModel<any>>(`${this.PROJECT_MANAGMENT_TO_DO_Delete_URL}${ToDoId}`);
  }



  // getdataTodo(): Observable<ResponseModel<todoCompanyParameter>> {
  //   return this.http.get<ResponseModel<todoCompanyParameter>>(
  //     `${this.ToDo_TO_DO_URL}getData-ByCompany}`
  //   );
  // }

  // getDataTodoSetup(): Observable<ResponseModelArray<ToDoSetupResponse>> {
  //   return this.http.get<ResponseModelArray<ToDoSetupResponse>>(`${this.Comp_Set_TO_DO_Gets_Setup_URL}getData-ByCompany`);
  // }
  private Todo_Setup_Fetch: string = environment.apiUrl + 'ToDoSetup/getData-ByCompany/';

  getCompanytodo(): Observable<ResponseModel<ToDoSetupResponse>> {
    return this.http.get<ResponseModel<ToDoSetupResponse>>(this.Todo_Setup_Fetch);
  }


  // ToDoInformationCheckListItemResponse

  postDataToDoCheckList(ToDoCheckList: any): Observable<ResponseModel<any>> {
    const apiUrl = `${this.PRO_MANAG_TO_DO_CheckList_URL}`;
    return this.http.post<any>(apiUrl, ToDoCheckList).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }


  getByIdToDo(id: number): Observable<ResponseModel<ToDoItemResult>> {
    const url = `${this.PROJECT_MANAGeMENT_TO_DO_URLs}/getBy-Id?id=${id}`;
    return this.http.get<ResponseModel<ToDoItemResult>>(url);
  }



  getByIdToDoCheckList(id: number): Observable<ResponseModel<ChecklistItem[]>> {
    const url = `${this.PROJECT_MANAGeMENT_TO_DO_URLs}/getToDoCheckListBy-ToDoParameterId?id=${id}`;
    return this.http.get<ResponseModel<ChecklistItem[]>>(url);
  }

 private PM_TODO_RELATED_DAILY_LOG_URL: string = `${environment.apiUrl}DailyLog`;


  toDoRelatedDailyLog(id: number): Observable<ResponseModel<ToDoRelatedItemsResult[]>> {
    const url = `${this.PM_TODO_RELATED_DAILY_LOG_URL}/getDailyLogParameter-byDailyLogId?id=${id}`;
    return this.http.get<ResponseModel<ToDoRelatedItemsResult[]>>(url);
  }

}
