import { Component } from '@angular/core';

@Component({
  selector: 'app-budget-dashboard',
  templateUrl: './budget-dashboard.component.html',
  styleUrls: ['./budget-dashboard.component.css']
})
export class BudgetDashboardComponent {
  isCollapsed = false;
  selectedJobId: number = 0;

  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }
    // Selected Job Id From Left Side Job List
getSelectedJobId(item: number){
  this.selectedJobId = item;
}
}
