import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { LookupNameSetupResponse } from 'src/Models/LeadTags';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { JobTypeService } from 'src/Service/Job-List/Job-Information/job-type.service';
import { LeadOpportunitiesService } from 'src/Service/lead-opportunities.service';
import { JobScratchPreDefinedCodes } from '../../Models/LookUpStandardFilterCodes';
import { TimeClockSService } from 'src/Service/_Time_Clock/time-clock-s.service';

@Component({
  selector: 'app-add-edit-job-group',
  templateUrl: './add-edit-job-group.component.html',
  styleUrls: ['./add-edit-job-group.component.css']
})
export class AddEditJobGroupComponent implements OnInit{
  @Output() cancel = new EventEmitter<void>();
  @ViewChild('titleInput') titleInput: ElementRef<HTMLInputElement>;
  @Output()  TagGroupOnCompleted = new EventEmitter();
  @Input() onSaveTag: any;
  @Input() selectedValue: number;
  onTagGroupForm: FormGroup;
  isLoadingOne: boolean = false;
  isLoadingEdit: boolean = false;
  confirmModal?: NzModalRef;
  isDeleteTagsLoading: any;
  tagGroupVisible: boolean = true;
  lookupSetNameResponse: LookupNameSetupResponse[] = [];
  TagGroupResponse: LookupNameSetupResponse;
  DisabledNextActivity: boolean = false;
  isSave = false;
  isDeleteLoading: boolean = false;
  onCheckNameSame: string[] = [];
  isJobGroupLoaded = false;

  constructor(
    private _TimeClockSService: TimeClockSService,
    private LeadOpportunitiesService: LeadOpportunitiesService,
    private fb: FormBuilder,
    public toastService: NzMessageService,
    private modal: NzModalService,
  ){}
  ngAfterViewInit(): void {
    this.focusTitleInput();
  }
  private focusTitleInput(): void {
    setTimeout(() => {
      if (this.titleInput) {
        this.titleInput.nativeElement.focus();
      }
    }, 1000);
  }
  ngOnInit(): void {
    this.iniliazeForm();
    this.initLoad();
  }
  iniliazeForm() {
    this.onTagGroupForm = this.fb.group({
      globalId: ['00000000-0000-0000-0000-000000000000'],
      name: ['', [Validators.required, Validators.maxLength(50)]],
      companyParameterId: 1,
      formNameId: this.onSaveTag?.formNameId,
      isFieldValue: false,
      lookUpStandardId: this.onSaveTag?.lookUpStandardId,
      parentCode: this.onSaveTag?.code,
      code: this.onSaveTag?.code,
      description:'',
    });
  }
  initLoad(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this._TimeClockSService.ShowCode(this.onSaveTag?.code).subscribe(
        (res) => {
          this.lookupSetNameResponse = res.result?.filter(item => item?.id === this.selectedValue);
          this.showTagEdit(this.lookupSetNameResponse[0]);
          resolve();
        },
        (error) => {
          console.error('Error loading lookup values:', error);
          reject(error);
        }
      );
    });
  }
  showTagEdit(_LookupNameSetupResponse: LookupNameSetupResponse): void {
    if (_LookupNameSetupResponse?.id > 0) {
      this.onTagGroupForm.patchValue({
        name: _LookupNameSetupResponse?.name,
        globalId: _LookupNameSetupResponse?.globalId,
        id: _LookupNameSetupResponse?.id
      });
    } else {
      console.error('No matching data found for ID:', this.selectedValue);
    }
  }
  saveJob(actionType: 'save' = 'save'): Promise<void> {
    let DataSet = this.onTagGroupForm.value;
  
    if (this.onTagGroupForm.invalid) {
      this.onTagGroupForm.markAllAsTouched();
      return Promise.reject('Form is invalid');
    }
  
    return new Promise((resolve, reject) => {
      if (this.isSave) {
        return reject('Already processing');
      }
  
      if (actionType === 'save') {
        this.isSave = true;
      }
      this.DisabledNextActivity = true;

      let name = this.lookupSetNameResponse.map(nn => nn?.name);
      if (name.includes(this.onTagGroupForm.value.name)) {
        this.toastService.error("This Job Group exists.");
        this.DisabledNextActivity = false;
        this.isSave = false;
        reject('Duplicate job group');
        return;
      }

      this.LeadOpportunitiesService.getLookUpNameSetup(DataSet).subscribe(
        (response) => {
          this.TagGroupResponse = response.result;
          this.TagGroupOnCompleted.emit(this.TagGroupResponse);
          const tagName = this.onTagGroupForm.get('name')?.value;
          this.toastService.success(`${tagName} saved successfully`);
          this.cancel.emit();
          this.tagGroupVisible = false;
          resolve();
        },
        (error) => {
          console.error('Error saving data:', error);
          this.toastService.error('An error has occurred. Please try again.');
          this.DisabledNextActivity = false;
          reject(error);
        }
      ).add(() => {
        this.resetFlags();
      });
    });
  }
  resetFlags() {
    this.isSave = false;
  }
  deleteJobConfirm(): void {
    if (this.isDeleteLoading) {
      return;
    }
  
    this.modal.confirm({
      nzTitle: `Delete job group?`,
      nzContent: `This job group is not currently being used and will be deleted.`,
      nzOkText: `Delete`,
      nzOkDanger: true,
      nzCancelText: 'Cancel',
      nzOnOk: () => {
        this.isDeleteLoading = true;
        return this.deleteSubVendor(this.selectedValue)
          .then(() => {
            this.SubVendorNewLeadModelCancel();
          })
          .catch(() => {
  
          });
      },
      nzCentered: true,
      nzOkLoading: this.isDeleteLoading,
      nzBodyStyle: { 'border-radius': '50px', 'height': 'auto', 'padding-top': '15' },
      nzCloseIcon: '',
      nzIconType: ''
    });
  }
  deleteSubVendor(id: number): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!id) {
        this.toastService.error('No record selected for deletion.');
        this.isDeleteLoading = false;
        reject();
        return;
      }
      resolve();
      
      this.LeadOpportunitiesService.deleteDataLookupNameSetup(this.onTagGroupForm.value.globalId).subscribe(
        (res) => {
          this.TagGroupOnCompleted.emit(res?.result);
          this.CancelJobJobGroup();
          this.isDeleteLoading = false;
        },
        (error) => {
          this.isDeleteLoading = false;
          this.toastService.error('An error occurred while deleting the record. Please try again.');
          reject();
        }
      );
    });
  }
  SubVendorNewLeadModelCancel(): void {
    this.CancelJobJobGroup();
    this.tagGroupVisible = false;
    this.LeadOpportunitiesService.clearSelectedFiles();
  }
  isVisibleInJobGroup: boolean = true;
  CancelJobJobGroup() {
   this.isVisibleInJobGroup = false;
    this.cancel.emit();
  }
  onInputChange(event: any) {
    const value = event.trim();
    const control = this.onTagGroupForm.get('name');
    
    control?.setValue(value, { emitEvent: false });
    if (value.length > 0) {
      control?.markAsTouched();
    } else {
      control?.markAsUntouched();
    }
  }
}
