<nz-space class="d-flex align-items-center color-text">
  <div *nzSpaceItem nz-col>Created By</div>
  <div
    *nzSpaceItem
    nz-col
    nz-popover
    class="d-flex align-items-center justify-content-center"
    nzPopoverPlacement="top"
    [nzPopoverContent]="ChatTemplate"
  >
    <nz-avatar
      style="
        background-color: #ffb9a8;
        font-size: 16px;
        font-weight: medium;
        color: #8b1439;
      "
      [nzText]="footerData?.initials"></nz-avatar>
    <h5 class="mt-2 ms-1"><strong style="font-size: 14px;">{{ footerData?.fullName }}</strong></h5>
  </div>
  <div *nzSpaceItem nz-col>
    on
     {{ footerData?.createdOnFormatted }}
  </div>

  <ng-template #ChatTemplate>
    <div
      nz-row
      style="
        width: 400px;
        padding: 10px;
        padding-bottom: 0px;
        height: 80px;
        border-radius: 0px;
      "
    >
      <nz-space>
        <div *nzSpaceItem nz-col nzSpan="4">
          <nz-avatar
            [nzSize]="64"
            style="
              background-color: #ffb9a8;
              font-size: 24px;
              font-weight: medium;
              color: #8b1439;
              top: 3px;
            "
            [nzText]="footerData?.initials"
          ></nz-avatar>
        </div>
        <div *nzSpaceItem nz-col nzSpan="20" style="left: 10px; top: 5px">
          <div class="row">
            <div class="col">
              <h2 class="popoverheadSet">
                {{ footerData?.fullName }}
              </h2>
              <div class="row">
                <div class="col d-flex">
                  <i class="bi bi-envelope me-2"></i>
                  <p>
                    <a class="text-primary">
                      {{ footerData?.email }}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nz-space>
    </div>
    <div class="row ps-3 pb-3">
      <div class="col">
        <button
          class="p-auto m-auto"
          nz-button
          nzType="text"
          nzSize="small"
          nz-popover
          [nzPopoverContent]="ChatTemplate"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1.5em"
            height="1.5em"
            viewBox="0 0 32 32"
            fill="currentColor"
            aria-hidden="true"
            focusable="false"
          >
            <path
              fill="currentColor"
              fill-rule="evenodd"
              d="M5.64 5.5a10 10 0 0 1 15.34 3.98 10 10 0 0 1 7.73 14.44l-.1.2.77 2.72c.16.56.03 1.16-.34 1.6l-.1.12c-.42.4-1 .58-1.57.48l-.15-.04-2.71-.77-.2.1a10 10 0 0 1-7.62.6l-.32-.12a10 10 0 0 1-5.78-5.47 9.95 9.95 0 0 1-3.3-1l-.22-.1-2.7.76a1.8 1.8 0 0 1-1.47-.23l-.14-.1-.12-.1c-.4-.43-.58-1-.48-1.58l.04-.15.77-2.7-.06-.13A10 10 0 0 1 5.18 5.88l.23-.2.22-.17h.01V5.5Zm15.97 6.1v.05a9.98 9.98 0 0 1-8.8 11.7 8 8 0 0 0 11.05 2.93 1 1 0 0 1 .65-.13l.14.03 2.67.76-.76-2.67a1 1 0 0 1 .04-.66l.06-.13a8 8 0 0 0-5.05-11.87ZM6.88 7.1a8 8 0 1 1 1.03 13.3l-.23-.14-.12-.06-.12-.04a1.01 1.01 0 0 0-.53 0l-2.67.77.76-2.67.03-.14a1 1 0 0 0-.13-.65 8 8 0 0 1 1.97-10.4l.01.03Z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
        <ng-template #ChatTemplate>
          <p style="height: auto; padding: 5px">Send Message</p>
        </ng-template>

        <button
          class="p-auto m-auto"
          nz-button
          nzType="text"
          nzSize="small"
          nz-popover
          [nzPopoverContent]="UserTemplate"
        >
          <svg
            width="1.5em"
            height="1.5em"
            viewBox="0 0 32 32"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            focusable="false"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M25 12C25 7.02944 20.9705 3 16 3C11.0294 3 6.99999 7.02944 6.99999 12C6.99999 15.2833 8.75811 18.1559 11.3834 19.7276C7.90068 20.8541 4.89273 23.2358 3.00773 26.4986C2.73145 26.9768 2.89515 27.5884 3.37336 27.8647C3.85157 28.141 4.46321 27.9773 4.73949 27.4991C7.06305 23.4772 11.3553 21 16.0001 21L16.3725 21.0053C20.8734 21.1338 24.9993 23.5851 27.2606 27.4993C27.5369 27.9775 28.1485 28.1412 28.6267 27.865C29.1049 27.5887 29.2687 26.9771 28.9924 26.4988C27.1073 23.2358 24.0991 20.8539 20.6157 19.7279C23.2419 18.1559 25 15.2833 25 12ZM16.029 18.9999L15.9711 19L15.7593 18.9959C12.0048 18.8691 8.99999 15.7855 8.99999 12C8.99999 8.13401 12.134 5 16 5C19.866 5 23 8.13401 23 12C23 15.8563 19.8817 18.9843 16.029 18.9999Z"
            ></path>
          </svg>
        </button>
        <ng-template #UserTemplate>
          <p style="height: auto; padding: 5px">Manage Internal User</p>
        </ng-template>

        <button
          class="p-auto m-auto"
          nz-button
          nzType="text"
          nzSize="small"
          nz-popover
          [nzPopoverContent]="CallTemplate"
        >
          <svg
            viewBox="64 64 896 896"
            focusable="false"
            data-icon="phone"
            width="1.5em"
            height="1.5em"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              d="M877.1 238.7L770.6 132.3c-13-13-30.4-20.3-48.8-20.3s-35.8 7.2-48.8 20.3L558.3 246.8c-13 13-20.3 30.5-20.3 48.9 0 18.5 7.2 35.8 20.3 48.9l89.6 89.7a405.46 405.46 0 01-86.4 127.3c-36.7 36.9-79.6 66-127.2 86.6l-89.6-89.7c-13-13-30.4-20.3-48.8-20.3a68.2 68.2 0 00-48.8 20.3L132.3 673c-13 13-20.3 30.5-20.3 48.9 0 18.5 7.2 35.8 20.3 48.9l106.4 106.4c22.2 22.2 52.8 34.9 84.2 34.9 6.5 0 12.8-.5 19.2-1.6 132.4-21.8 263.8-92.3 369.9-198.3C818 606 888.4 474.6 910.4 342.1c6.3-37.6-6.3-76.3-33.3-103.4zm-37.6 91.5c-19.5 117.9-82.9 235.5-178.4 331s-213 158.9-330.9 178.4c-14.8 2.5-30-2.5-40.8-13.2L184.9 721.9 295.7 611l119.8 120 .9.9 21.6-8a481.29 481.29 0 00285.7-285.8l8-21.6-120.8-120.7 110.8-110.9 104.5 104.5c10.8 10.8 15.8 26 13.3 40.8z"
            ></path>
          </svg>
        </button>
        <ng-template #CallTemplate>
          <p style="height: auto; padding: 5px">Call:{{ footerData?.cellPhone }}</p>
        </ng-template>
      </div>
    </div>
  </ng-template>
</nz-space>
