export class CreateCostItem {
  companyParameterId: number;
  formNameId: number;
  headerId: number;
  isFlatFee: boolean;
  isLineItems: boolean;
  builderCost: string;
  ownerPrice: string;
  isSetPriceLater: boolean;
  createCostItemParameterRequests: CostItemParameterRequest[];

  constructor(
    companyParameterId: number,
    formNameId: number,
    headerId: number,
    isFlatFee: boolean,
    isLineItems: boolean,
    builderCost: string,
    ownerPrice: string,
    isSetPriceLater: boolean,
    createCostItemParameterRequests: CostItemParameterRequest[]
  ) {
    this.companyParameterId = companyParameterId;
    this.formNameId = formNameId;
    this.headerId = headerId;
    this.isFlatFee = isFlatFee;
    this.isLineItems = isLineItems;
    this.builderCost = builderCost;
    this.ownerPrice = ownerPrice;
    this.isSetPriceLater = isSetPriceLater;
    this.createCostItemParameterRequests = createCostItemParameterRequests;
  }
}

export class CostItemParameterRequest {
  globalId: string;
  title: string;
  description: string;
  quantity: string;
  unit: string;
  unitCost: string;
  builderCost: string;
  markupValue: string;
  markupId: number;
  ownerPrice: string;
  margin: string;
  profit: string;
  costCodeId: number;
  costTypeId: number;
  markAsId: number;
  internalNotes: string;
  includeQuantity: boolean;
  includeOwnerPrice: boolean;
  isCatalog: boolean;
  isNew: boolean;
  isTitleAndCostCodeReadOnly:boolean;
  costTypeItemParameters?: CostTypeItemParameter[];



  constructor(
    globalId: string,
    title: string,
    description: string,
    internalNotes: string,
    quantity: string,
    unit: string,
    unitCost: string,
    builderCost: string,
    markupValue: string,
    markupId: number,
    ownerPrice: string,
    margin: string,
    profit: string,
    costCodeId: number,
    costTypeId: number,
    markAsId: number,
    includeQuantity: boolean,
    includeOwnerPrice: boolean,
    isCatalog: boolean,
    isNew: boolean,
    isTitleAndCostCodeReadOnly:boolean,
  ) {
    this.globalId = globalId;
    this.title = title;
    this.description = description;
    this.internalNotes = internalNotes;
    this.quantity = quantity;
    this.unit = unit;
    this.unitCost = unitCost;
    this.builderCost = builderCost;
    this.markupValue = markupValue;
    this.markupId = markupId;
    this.ownerPrice = ownerPrice;
    this.margin = margin;
    this.profit = profit;
    this.costCodeId = costCodeId;
    this.costTypeId = costTypeId;
    this.markAsId = markAsId;
    this.includeQuantity = includeQuantity;
    this.includeOwnerPrice = includeOwnerPrice;
    this.isCatalog = isCatalog;
    this.isNew = isNew;
    this.isTitleAndCostCodeReadOnly = isTitleAndCostCodeReadOnly;
    this.costTypeItemParameters = [];
  }
}

export class CostTypeItemParameter {
  costTypeId: number;

  constructor(costTypeId: number) {
    this.costTypeId = costTypeId;
  }
}



export interface CompanyParameterChild {
  companyParameterId?: number;
  formNameId?: number;
  headerId?: number;
  isFlatFee?: boolean;
  isLineItems?: boolean;
  builderCost?: string;
  ownerPrice?: string;
  isSetPriceLater?: boolean;
}




export class CostItemResponseChild {
  companyParameterId: number;
  companyParameter: any | null;
  formNameId: number;
  formName: any | null;
  isFlatFee: boolean;
  isLineItems: boolean;
  builderCost: string;
  ownerPrice: string;
  isSetPriceLater: boolean;
  id: number;
  costItemParameters: CostItemParameterResponse[];

}

export class CostItemParameterResponse {
  costItemId: number;
  categoryId?: number | null;
  category?: any | null;
  costCodeId: number;
  costCode: any | null;
  title: string;
  description: string;
  unit: string;
  markupId: number;
  markup: any | null;
  internalNotes: string | null;
  includeQuantity: boolean | null;
  includeOwnerPrice: boolean | null;
  isCatalog: boolean | null;
  costTypeId: number;
  markAsId: number;
  quantity: string;
  unitCost: string;
  builderCost: string;
  markupValue: string;
  ownerPrice: string;
  margin: string;
  profit: string;
  isNew: boolean;
  isTitleAndCostCodeReadOnly: boolean;
  costTypeItemParameters:  CostTypeItemParameter[]; // Define the exact type if possible
  createdBy?: number;
  createdOn?: string; // Or Date if you plan to handle it as a date object
  modifiedBy?: number | null;
  modifiedOn?: string | null; // Or Date if necessary
  isActive?: boolean;
  id: number;
  globalId: string;
}
