import { ApplicationModuleResponse, ApplicationUserResponse, BaseResponse } from "../InternalUser/applicationUserResponse";
import { ColorResponse } from "../Job-List/Job-Information/Jobinformation";
import { Email, LookupNameSetupResponse } from "../LeadTags";
import { SubVendorResponse } from "../SubVendor/SubvendorResponse.model";
import { FormNameResponse } from "../SubVendor/subvendorFilter/SubVendorFilterResponse.model";
import { JobInformationTypeParameterResponse } from "./CustomerInformation";

    export class CustomerInformationResponse extends BaseResponse {
        public firstName?: string;
        public lastName?: string;
        public displayName: string;
        public streetAddress?: string;
        public suburb?: string;
        public state?: string;
        public postalCode?: string;
        public phone?: string;
        public countryCode?: string;
        public dialCode?: string;
        public cellPhone?: string;
        public primaryEmail?: string;
        public label?: string;
        public status?: boolean;
        public pictureUrl?: string;
        public receivedTextMessage?: string;
        public isJob?: boolean;
        public isLeadOpportunitie?: boolean;
        public StatusSetupCode ?: string;
        public contactTypeId?: number;
        public statusSetupId?: number; // TypeScript uses `number` for both `int` and `long` in C#
        public statusSetup?: LookupNameSetupResponse;
        public contactType?: ContactTypeResponse;
        public customerEmailParameters?: CustomerEmailParameterResponse[];
        customerinformationId: any;
    }


export class ContactTypeResponse extends BaseResponse {
    public Name: string;
}




export class CustomerEmailParameterResponse extends BaseResponse {
    public CustomerInformationId?: number;
    public CustomerInformation: CustomerInformationResponse;
    public email?: string | null;
    public label?: string | null;
}

// export class JobInformationResponse extends BaseResponse {
//     name: string;
//     jobPrefix: string;
//     contactTypeId?: number;
//     contactType: ContactTypeResponse;
//     jobInformationTypeParameterId?: number;
//     jobInformationTypeParameter: JobInformationTypeParameterResponse;
//     streetAddress: string;
//     suburb: string;
//     state: string;
//     postalCode: string;
//     projectedStart?: Date;
//     projectedCompletion?: Date;
//     actualStart?: Date;
//     actualCompletion?: Date;
//     updateActualDatesBasedOnSchedule?: boolean;
//     colorId?: number;
//     color: ColorResponse;
//     notesForInternalUsers: string;
//     notesForSubsVendors: string;
//     customerInformationId?: number;
//     customerInformation: CustomerInformationResponse;
//     customerInformationStatus?: boolean;
//     statusSetupId?: number;
//     statusSetup: StatusSetupResponse;
//     contractPrice: number;
//     squareFeet: number;
//     permitNo: number;
//     lotInformation: string;
//     jobGroupParameters: JobGroupParameterResponse[];
//     workDayParameters: WorkDayParameterResponse[];
    // jobInformationUserParameters: JobInformationUserParameterResponse[];
//     jobInformationOwnerParameters: JobInformationOwnerParameterResponse[];
//     override globalId: string;
// }

// export interface JobInformationOwnerParameterResponse extends BaseResponse {
//     jobInformationId?: number;
//     jobInformation?: JobInformationResponse;
//     customerInformationId?: number;
//     customerInformation?: CustomerInformationResponse;
//   }


// export class JobInformationTypeParameterResponse extends BaseResponse {
//     JobSetupId?: number;
//     JobSetup: JobSetupResponse;
//     JobTypeId?: number;
//     JobType: JobTypeResponse;
// }


// export class ColorResponse extends BaseResponse {
//     name: string;
//     colorCollection: string;
// }

// export class JobGroupParameterResponse extends BaseResponse {
//     JobInformationId?: number;
//     JobInformation: JobInformationResponse;
//     JobGroupId?: number;
//     JobGroup: JobGroupResponse;
// }

// export class WorkDayParameterResponse extends BaseResponse {
//     JobInformationId?: number;
//     JobInformation: JobInformationResponse;
//     WorkDayId?: number;
//     WorkDay: WorkDayResponse;
// }
export class CostSettingResponse extends BaseResponse {
    Name: string;
}

export class JobTypeGroupResponse extends BaseResponse {
    Name: string;
}


export class JobTypeResponse extends BaseResponse {
    name: string;
    jobTypeGroupId?: number;
    jobTypeGroup: JobTypeGroupResponse;
}

export class JobGroupResponse extends BaseResponse {
    name: string;
}

export class WorkDayResponse extends BaseResponse {
    name: string;
}




export class ResponseForColumn {
    ColumnType: string;
    ColumnName: string;
    DisplayColumnName: string;
}

export class CountryCodeResponse extends BaseResponse {
    Name: string;
    IconUrl: string;
}


export class LeadOpportunityResponse extends BaseResponse {
    title?: string;
    StreetAddress?: string;
    Suburb?: string;
    State?: string;
    PostalCode?: string;
    Confidence?: number;
    ProjectedSalesDate?: Date;
    EstimatedRevenueFrom?: number;
    EstimatedRevenueTo?: number;
    ProjectTypeSetupId?: number;
    ProjectTypeSetup?: ProjectTypeSetupResponse;
    Notes?: string;
    StatusSetupId?: number;
    StatusSetup?: StatusSetupResponse;
    CustomerInformationId?: number;
    CustomerInformation?: CustomerInformationResponse;
    LeadOpportunitySalesUserParameters?: LeadOpportunitySalesUserParameterResponse[];
    LeadOpportunitySourceParameters?: LeadOpportunitySourceParameterResponse[];
    LeadOpportunityTagsParameters?: LeadOpportunityTagsParameterResponse[];
    LeadOpportunityAttachments?: LeadOpportunityAttachmentsResponse[];
}



export class ProjectTypeSetupResponse extends BaseResponse {
    name: string;
}

export class StatusSetupResponse extends BaseResponse {
    FormNameId?: number;
    FormName?: FormNameResponse;
    Name: string;
}

export class LeadOpportunitySalesUserParameterResponse extends BaseResponse {
    LeadOpportunityId?: number;
    LeadOpportunity?: LeadOpportunityResponse;
    ApplicationUserId?: number;
    ApplicationUser?: ApplicationUserResponse;
}

export class LeadOpportunitySourceParameterResponse extends BaseResponse {
    LeadOpportunityId?: number;
    LeadOpportunity?: LeadOpportunityResponse;
    SourceSetupId?: number;
    SourceSetup?: SourceSetupResponse;
}

export class SourceSetupResponse extends BaseResponse {
    Name: string;
}

export class LeadOpportunityTagsParameterResponse extends BaseResponse {
    LeadOpportunityId?: number;
    LeadOpportunity?: LeadOpportunityResponse;
    TagSetupId?: number;
    TagSetup?: TagSetupResponse;
}

export class TagSetupResponse extends BaseResponse {
    Name: string;
}

export class LeadOpportunityAttachmentsResponse extends BaseResponse {
    LeadOpportunityId?: number;
    LeadOpportunity?: LeadOpportunityResponse;
    Name: string;
    Extension: string;
    Size: string;
    FilePath: string;
}



export class JobInformationResponse  extends BaseResponse{
    name: string;
    jobPrefix?: string;
    contractTypeId?: number;
    contractType?: LookupNameSetupResponse;
    jobInformationTypeParameterId: number;
    jobInformationTypeParameter?: JobInformationTypeParameterResponse;
    streetAddress?: string;
    suburb?: string;
    state?: string;
    postalCode?: string;
    projectedStart?: Date;
    projectedCompletion?: Date;
    actualStart?: Date;
    actualCompletion?: Date;
    updateActualDatesBasedOnSchedule?: boolean;
    colorId?: number;
    color?: ColorResponse;
    notesForInternalUsers?: string;
    notesForSubsVendors?: string;
    customerInformationId?: number;
    customerInformation?: CustomerInformationResponse;
    customerInformationStatus?: boolean;
    statusSetupId?: number;
    statusSetup?: LookupNameSetupResponse;
    contractPrice?: number;
    squareFeet?: number;
    permitNo?: number;
    lotInformation?: string;
    timeFrameSetupId?: number;
    timeFrameSetup?: LookupNameSetupResponse;
    isScheduleItems?: boolean;
    isPhases?: boolean;
    isShowJobPriceSummary?: boolean;
    isShowOwnerInvoices?: boolean;
    isAllowOwnerToPreviewLockedSelections?: boolean;
    isAllowOwnerToViewProjectManagerPhoneNumber?: boolean;
    isAllowOwnerToSubmitChangeOrderRequests?: boolean;
    isAllowOwnerToAddWarrantyClaims?: boolean;
    isEnableGeofencingOnTimeClockShifts?: boolean;
    isAllowCreationOfAllowances?: boolean;
    isUpdateJobRunningTotalWhenaSelectionIsApproved?: boolean;
    individualBillPoLimit?: number;
    totalJobBillPoLimit?: number;
    includeTimeClockLaborInTheBudget?: boolean;
    includeTimeClockLaborInTotalCostsAndPaidColumnsonBudget?: boolean;
    isMakeThisJobAWorkingTemplate?: boolean;
    isMakeScheduleOnline?: boolean;
    templateName?: string;

    leadOpportunityId?: number;
    leadOpportunity?: LeadOpportunityResponse;

    percentageTypeId?: number;
    percentageType?: LookupNameSetupResponse;
    percentageValue?: number;

    jobGroupParameters?: JobGroupParameterResponse[];
    workDayParameters?: WorkDayParameterResponse[];
    jobInformationAdvancedNotifications?: JobInformationAdvancedNotificationsResponse[];
    jobInternalUserParameters?: JobInternalUserParameterResponse[];
    jobSubVendorUserParameters?: JobSubVendorUserParameterResponse[];
    jobInformationUserParameters?: JobInformationUserParameterResponse[];
    jobInformationOwnerParameters?: JobInformationOwnerParameterResponse[];
    selected?: boolean;
  }

  export class JobGroupParameterResponse   extends BaseResponse{
    jobInformationId?: number;
    jobInformation?: JobInformationResponse;
    jobGroupId?: number;
    jobGroup?: LookupNameSetupResponse;
  }

  export class WorkDayParameterResponse  extends BaseResponse{
    jobInformationId?: number;
    jobInformation?: JobInformationResponse;
    workDayId?: number;
    workDay?: WorkDayResponse;
  }

  export class JobInformationAdvancedNotificationsResponse  extends BaseResponse{
    jobInformationId?: number;
    jobInformation?: JobInformationResponse;
    applicationModuleId?: number;
    applicationModule?: ApplicationModuleResponse;
    isEmail?: boolean;
    isText?: boolean;
    isPush?: boolean;
  }

  export class JobInternalUserParameterResponse  extends BaseResponse{
    jobInformationId?: number;
    jobInformation?: JobInformationResponse;
    applicationUserId?: number;
    applicationUser?: ApplicationUserResponse;
    isNotification?: boolean;
    isStatus?: boolean;
  }

  export class JobSubVendorUserParameterResponse  extends BaseResponse{
    jobInformationId?: number;
    jobInformation?: JobInformationResponse;
    subVendorId?: number;
    subVendor?: SubVendorResponse;
    isStatus?: boolean;
  }

  export class JobInformationUserParameterResponse  extends BaseResponse{
    jobInformationId?: number;
    applicationUserId?: number;
    applicationUser?: ApplicationUserResponse;
  }

  export class JobInformationOwnerParameterResponse  extends BaseResponse{
    jobInformationId?: number;
    jobInformation?: JobInformationResponse;
    customerInformationId?: number;
    customerInformation?: CustomerInformationResponse;
  }


  export class sideMenuBarResult {
    id: number;
    name: string;
    colorId: number;
    colorCollection: string;
    JobPrefix: string;
    StreetAddress: string;


}










//   For Multiple email in edit time



// CreateCustomerEmailParameterRequest.ts



  // UpdateCustomerRequest.ts

  export class UpdateCustomerRequest {
    globalId!: string;  // Use string for Guid type in TypeScript
    StatusSetupCode ?: string;
    createCustomerEmailParameterRequests?: CreateCustomerEmailParameterRequest[];
  }


  export class CreateCustomerEmailParameterRequest {
    customerInformationId?: number;
    email?: string;
    label?: string;


  }

// get data cutomer combo //

export class Customerres {
  displayName: string;
  createdBy: number;
  createdOn: string; // ISO date format string
  modifiedBy: number | null; // Nullable number
  modifiedOn: string | null; // Nullable ISO date format string
  isActive: boolean;
  id: number;
  globalId: string;


}



// pagination-parameters.model.ts
export class PaginationParametersCustomer {
  pageNumber: number;
  pageSize: number;
  searchParameter: string;

}



export class CustomerResponseResult {
  Id: number;
  GlobalId: string;
  CompanyParameterId: number;
  FirstName: string;
  LastName: string;
  DisplayName: string;
  StreetAddress: string;
  Suburb: string;
  State: string;
  PostalCode: string;
  Phone: string;
  DialCode: string;
  CountryCode: string;
  CellPhone: string;
  PrimaryEmail: string;
  Label: string;
  Status: boolean;
  StatusSetupId: number;
  CustomerEmailParameters:Email[];
  StatusCode: string;
  StatusName: string;
  StatusDescription: string;
  CustomerOn: Date;

}



export class CustomerSavedResult {
  Id: number;
  GlobalId: string;
  CompanyParameterId: number;
  FirstName: string;
  LastName: string;
  DisplayName: string;
  StreetAddress: string;
  Suburb: string;
  State: string;
  PostalCode: string;
  Phone: string;
  DialCode: string;
  CountryCode: string;
  CellPhone: string;
  PrimaryEmail: string;
  Label: string;
  Status: boolean;
  StatusSetupId: number;
  StatusCode?: string;
  StatusName?: string;
  StatusDescription?: string;
  Emails: Email[];
  CreatedOn: Date;
  ReceivedTextMessage: string;
  IsLeadOpportunitie: boolean;
  IsJob: boolean;
  ContactTypeId: number;
  IsActive:boolean;
  CreatedBy: number;
}

export class CustomerContactGridResponse {
  Id: number;
  GlobalId: string;
  DisplayName: string;
  StatusSetupId: number;
  StatusName: string;
  StatusDescription: string;
  StatusCode: string;
  PrimaryPhoneNo: string;
  CellPhone: string;
  StreetAddress: string;
  State: string;
  PostalCode: string;
  Suburb: string;
  TotalJobs: number;
  TotalLeadOpportunities: number;
  Emails: string;
  FirstName: string;
  LastName: string;
  CustomerOn: Date;



}
