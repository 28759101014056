import { AccessLocalStorageService } from './../../../../Service/access-local-storage.service';
import { LeadOpportunitiesWorkSheetAddformCatalogComponent } from './../Catalog-Modal/lead-opportunities-work-sheet-addform-catalog/lead-opportunities-work-sheet-addform-catalog.component';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { FileUploadComponent } from '../file-upload/file-upload.component';
import { EstimatedCostComponent } from '../estimated-cost/estimated-cost.component';
import { NewProposalsCostCodesComponent } from '../new-proposals-cost-codes/new-proposals-cost-codes.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CostItemParameterResponse, CreateCostItemParameterRequest, LeadProposalResponse, UpdateStatusPayload } from 'src/Models/LeadProposal/LeadProposalModels';
import { LeadProposalsPreDefinedCodes } from 'src/app/shared/component/Models/LookUpStandardFilterCodes';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { LeadProposalsService } from 'src/Service/LeadProp/lead-proposals.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { CustomerInformationService } from 'src/Service/customer-information.service';
import { LeadOpportunityResponse, LeadOpportunityResult, LookupNameSetupResponse } from 'src/Models/LeadTags';
import { LeadProposalsFService } from 'src/Service/Sales-lead-proposals-F/lead-proposals-f.service';
import { ProjectManagementSiteDiariesCreateFilesComponent } from 'src/app/project-management/components/Site-Diaries/project-management-site-diaries-create-files/project-management-site-diaries-create-files.component';
import { LeadOpportunitiesService } from 'src/Service/lead-opportunities.service';
import { Subscription } from 'rxjs';
import { LeadProposalColumnsComponent } from '../lead-proposal-columns/lead-proposal-columns.component';
import { CostCodeCategoryResponse } from 'src/Models/InternalUser/applicationUserResponse';
import { HttpResponse } from '@angular/common/http';
import { ProposalStatusEnum, StatusSetupProposal } from 'src/app/Core/Enums/proposalstatus';
import { SignaturePadComponent } from 'src/app/project-management/signature-pad/signature-pad.component';
import { SignatureSetupResponse } from 'src/Models/SignaturePad/SignaturePad';
import { BlockQuote, Bold, ClassicEditor, EditorConfig, Essentials, Font, FontBackgroundColor, FontColor, FontFamily, FontSize, Heading, HorizontalLine, Image, ImageUpload, Indent, Italic, Link, List, MediaEmbed, Paragraph, Table, Undo, UndoEditing } from 'ckeditor5';
import { NzStatus } from 'ng-zorro-antd/core/types';
import { NzImageService } from 'ng-zorro-antd/image';
import { environment } from 'src/environments/environment.prod';
import { FooterService } from 'src/Service/Footer/footer.service';
import { AuditDetailsResponse, FilterFooterAudit } from 'src/Models/Footer/footer.model';
import { PercentageMarkupComponent } from './percentage-markup/percentage-markup.component';
import { SalesSettings } from 'src/Models/LeasSalesPeople';
import { BehaviorsubjectService } from 'src/Service/behaviorsubject/behaviorsubject.service';
import { AttachmentService } from 'src/Service/Attachment/attachment.service';
import { AttachmentResponse, CustomUploadFile } from 'src/Models/Attachment_Files_Class/AttachmentFilesClass';
import { saveAttachments } from 'src/app/helpers/Attachment_Save';
import { CompanyParameterChild } from 'src/Models/costitem/costitem.modal';
import { CostItemCommonComponent } from 'src/app/cost-item-common/cost-item-common.component';




@Component({
  selector: 'new-proposal',
  templateUrl: './new-proposal.component.html',
  styleUrls: ['./new-proposal.component.css']
})
export class NewProposalComponent implements OnInit, OnDestroy {
formattedValue: any;
onValueChange() {
throw new Error('Method not implemented.');
}
  confirmModal?: NzModalRef;
  validationMessages: { [key: string]: string } = {};
  changedFields: Set<string> = new Set<string>();
  public Editor = ClassicEditor;
  @Output() leadProposalDeleted = new EventEmitter<string>();
  ProposalStatusEnum = ProposalStatusEnum;
  @Input() getProposalTemplatesDataId: number;
  @Output() leadProposalSaveResponse = new EventEmitter<LeadProposalResponse>();
  @Output() leadOpportunityProposalSaveResponse: LeadOpportunityResult;
  @Output() leadProposalsResponse: LeadProposalResponse[] = [];
  @Output() saveProposalInLeadOpportunity: boolean = false;
  @Output() leadProposalGridOnCompleted = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();
  tableFormFieldValues: any[] = [];
  tableCostTypeFormFieldValues: any = {};
  TestAttachment: AttachmentResponse;
  selectedFilesAttachment: CustomUploadFile[] = [];
  markupOption: LookupNameSetupResponse[] = [];
  proposalOption: LookupNameSetupResponse[] = [];
  markAsOption: LookupNameSetupResponse[] = [];
  selectedItems: any[] = [];
  IMG_BASE: string = environment.IMG_BUCKET_URL;
  attachments: AttachmentResponse[] = [];
  testFile: AttachmentResponse[] = [];
  isSave: boolean = false;
  isSaveAndClose: boolean = false;
  isSaveAndRelease: boolean = false;
  isSaveAndApproval: boolean = false;
  isDecline: boolean = false;
  isDeclineProposal: boolean = false; // this flag is only check decline time status not update
  private isDataEmitted = false; // Flag to check if data has been emitted
  isSubmitted: boolean = false;
  DisabledNextActivity: boolean = false;
  getProposalTemplatesData: LeadProposalResponse;
  footerResponse: AuditDetailsResponse; // Add the Footer Dynamic
  getStatusProposal: StatusSetupProposal;
  statusUpdateForDecline: StatusSetupProposal;
  NewProposalResponse: LeadProposalResponse[] = []
  assignedUser: { label: string; value: string }[] = [];
  selectedFiles: File[] = [];
  selectedDataSet: CreateCostItemParameterRequest[] = [];
  isContentExceedsTwoLines: boolean = false;
  leadOptions: CostCodeCategoryResponse[] = [];
  public leadOpportunityRes: LeadOpportunityResponse;
  tabs = [1, 2, 3, 4, 5, 6, 7, 8];
  editorValue: string = '';
  closingText: string = '';
  test: number = 0;
  public pageId: 37;
  formFieldValues: any = {};
  initialFormFieldValues: any = {};
  selectedGroup: string = 'Group';
  inputValue: '';
  index: number = 0;
  LeadProposalPreviewComponent: boolean = true;
  showValidationMessage: boolean = false;
  leadproposal: FormGroup;
  selectedValue = null;
  isFromLeadProposal: boolean = false;
  leadOpportunitiesCost: CostItemParameterResponse[];
  isVisible10 = false;
  isVisibleLead = true;
  isFieldsVisible = false;
  isSectionVisible = false;
  tbodyList: any[] = [];
  i = 0;
  flag: string = '';
  costCodeId: any;
  preDefinedDataCodes = {
    costType: LeadProposalsPreDefinedCodes.CostType,
    markup: LeadProposalsPreDefinedCodes.Markup,
    markAs: LeadProposalsPreDefinedCodes.MarkAs,
  };
  isLoading: boolean = false;
  isDeleteLoading: boolean = false;
  isUnRelease: boolean = false;
  private subscription: Subscription;
  isCustomSelected: boolean = false;
  signatureId: number;
  signatureResponse: SignatureSetupResponse;
  signatureResponseDecline: SignatureSetupResponse;
  showAddGroupButton: boolean;
  dataset: SalesSettings;

  constructor(private modal: NzModalService,
    private leadProposalsService: LeadProposalsService,
    private LookupFilterService: LookupFilterService,
    private customerInformationService: CustomerInformationService,
    public leadProposalsFService: LeadProposalsFService,
    private _AttachmentService: AttachmentService,
    private toastService: NzMessageService,
    private _BehaviorsubjectService: BehaviorsubjectService,
    private leadOppService: LeadOpportunitiesService,
    private FooterService: FooterService,
    private cdr: ChangeDetectorRef
  ) { }
  ngOnInit(): void {
    localStorage.setItem('isFromLeadProposal', 'true');
    this.leadProposalGetById();
    this.getLeadData();
    this.ForCombo();
    this.initLoad();
    this.getFilesProposal();

    this.leadProFormGroup();

    this.leadOppService.getDataByCompany().subscribe(
      (response) => {
        this.dataset = response.result;
        if (this.dataset) {
          this.formFieldValues.introductoryText = this.dataset.DefaultIntroductoryText;
          this.formFieldValues.closingText = this.dataset.DefaultClosingText;
          if (this.saveProposalInLeadOpportunity) {
            this.saveLeadProposal(null);
          }
        }
      },
      (error) => {
        console.error('Error fetching data from getDataByCompany', error);
      }
    );


  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  leadProFormGroup(): void {
    this.formFieldValues = {
      id: 0,
      globalId: '00000000-0000-0000-0000-000000000000',
      leadOpportunityId: 0,
      title: '', // Title will be set later
      approvalDeadlineOn: null,
      internalNotes: '',
      IsTemplate: false,
      salesPersonId: 0,
      StatusSetupCode: '',
      introductoryText: '',
      signatureSetupId: 0,
      closingText: '',
    };

    let baseTitle = 'Proposal For ' + this.leadOpportunityProposalSaveResponse?.Title;
    this.formFieldValues.title = baseTitle;
    if (this.leadProposalsResponse && this.leadProposalsResponse.length > 0) {
      let counter = 1;
      let titleExists = this.leadProposalsResponse.some(
        proposal => proposal.title === this.formFieldValues.title
      );
      while (titleExists) {
        this.formFieldValues.title = `${baseTitle} (${counter})`;
        counter++;
        titleExists = this.leadProposalsResponse.some(
          proposal => proposal.title === this.formFieldValues.title
        );
      }
    }
    if (this.leadOpportunityProposalSaveResponse && this.leadOpportunityProposalSaveResponse?.Id) {
      this.formFieldValues.leadOpportunityId = this.leadOpportunityProposalSaveResponse?.Id;
    }
  }

  getFilesProposal() {
    this.leadOppService.getSelectedFiles().subscribe(files => {
      this.selectedFiles = files;
    });
    this.setInitialFormFieldValues();
  }

  leadProposalGetById() {
    if (this.getProposalTemplatesDataId) {
      this.isLoading = true;
      this.leadProposalsFService.getleadProposalsId(this.getProposalTemplatesDataId).subscribe(
        (res) => {
          this.getProposalTemplatesData = res.result;

          if (this.getProposalTemplatesData) {
            this.patchFormValues(this.getProposalTemplatesData);
            this.fetchAttachments(this.getProposalTemplatesData.attachmentId); // Fetch attachments only if proposal data is fetched successfully

          }
          this.isLoading = false;
        },
        (error) => {
          console.error('Error fetching lead proposal data', error);
          this.isLoading = false;
        }
      );
      this.storeInitialFormFieldValues();
    }
  }

  patchFormValues(LeadOpportunitiesNewProposalResponse: LeadProposalResponse) {
    let DataSet = LeadOpportunitiesNewProposalResponse;
    this.formFieldValues['id'] = LeadOpportunitiesNewProposalResponse.id;
    this.formFieldValues['globalId'] = LeadOpportunitiesNewProposalResponse.globalId;
    this.formFieldValues['title'] = LeadOpportunitiesNewProposalResponse.title;
    this.formFieldValues['approvalDeadlineOn'] = DataSet.approvalDeadlineOn;
    this.formFieldValues['salesPersonId'] = DataSet.salesPersonId.toString();
    this.formFieldValues['internalNotes'] = DataSet.internalNotes;
    this.formFieldValues['introductoryText'] = DataSet.introductoryText;
    this.editorValue = DataSet.introductoryText;
    this.closingText = DataSet.closingText;
    this.formFieldValues['closingText'] = DataSet.closingText;
    this.formFieldValues['signatureSetupId'] = LeadOpportunitiesNewProposalResponse.signatureSetupId;
    this.formFieldValues['leadOpportunityId'] = LeadOpportunitiesNewProposalResponse.leadOpportunityId;
    this.getStatusProposal = LeadOpportunitiesNewProposalResponse.statusSetup;
    this.signatureResponse = LeadOpportunitiesNewProposalResponse?.signatureSetup;
    this.createAndModifiedByData(LeadOpportunitiesNewProposalResponse.id); // patch time Calling this Footer
    this.setInitialFormFieldValues();

    this.costItemId = LeadOpportunitiesNewProposalResponse.costItemId

  }




  setInitialFormFieldValues(): void {
    this.initialFormFieldValues = JSON.parse(JSON.stringify(this.formFieldValues));
  }

  costItemId: number;
  companyParameter: CompanyParameterChild;
  @ViewChild(CostItemCommonComponent) childComponent!: CostItemCommonComponent;


  async saveLeadProposal(statusCode: ProposalStatusEnum, callback?: () => void): Promise<void> {
    if (this.isSave || this.isSaveAndClose || this.isSaveAndRelease || this.isSaveAndApproval || this.isDecline) {
      return Promise.reject('Already processing');
    }

    // Set flags based on the callback provided
    if (callback) {
      this.isSaveAndClose = callback.name === 'saveAndCloseProposal';
      this.isSaveAndRelease = callback.name === 'saveAndReleasedProposal';
      this.isSaveAndApproval = callback.name === 'saveAndApproveProposal';
      this.isDecline = callback.name === 'declineProposal';
    } else {
      this.isSave = true;
    }

    this.DisabledNextActivity = true;
    this.isSubmitted = true;
    this.checkErrors(true);

    // Handle validation errors
    if (Object.values(this.validationStates).includes('error')) {
      this.scrollToFirstError();
      this.resetFlags();
      this.DisabledNextActivity = false;
      return;
    }

    // Set signature ID
    this.formFieldValues.signatureSetupId = this.signatureResponse?.id ?? this.signatureResponseDecline?.id ?? 0;
    this.formFieldValues.StatusSetupCode = statusCode || this.getStatusProposal?.code || 'LOLPPSTSU';

    // Ensure `companyParameter` is initialized
    if (!this.companyParameter) {
      this.companyParameter = {};
    }

    try {
      const postResponse = await this.leadProposalsService.postLeadProposal(this.formFieldValues).toPromise();
      this.handlePostResponse(postResponse);

      if (this.selectedFilesAttachment?.length > 0) {
        this.saveAttachments(postResponse.result.id);
      }

      await this.childComponent.saveCostItem();

      this.toastService.success('Saved Successfully!');
      this.storeInitialFormFieldValues();

      if (callback) {
        callback();
        this.leadProposalSaveResponse.emit(postResponse.result);
      }

    } catch (error) {
      console.error('Error saving data:', error);
      this.toastService.error('Error saving lead data.');
      throw error;

    } finally {
      this.resetFlags();
      this.isSubmitted = false;
      this.DisabledNextActivity = false;
      this.isDeclineProposal = false;
    }
  }

  // Helper functions
  private handlePostResponse(response: any) {
    this.getProposalTemplatesData = response.result;
    this.getStatusProposal = this.isDecline ? undefined : response.result.statusSetup;
    this.statusUpdateForDecline = this.isDecline ? response.result.statusSetup : undefined;
    this.formFieldValues.globalId = response.result.globalId;

    // Set values in companyParameter
    Object.assign(this.companyParameter, {
      companyParameterId: 1,
      headerId: response.result.id,
      formNameId: 37,
      isFlatFee: false,
      isLineItems: false,
      builderCost: '0.00',
      ownerPrice: '0.00',
      isSetPriceLater: false,
    });
  }

  private scrollToFirstError() {
    setTimeout(() => {
      const errorElements = document.querySelectorAll('.top-of-modal');
      if (errorElements.length > 0) {
        const lastErrorElement = errorElements[errorElements.length - 1];
        lastErrorElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 100);
  }


  // Helper function for saving attachments
  private saveAttachments(leadProposalId: number): void {
    saveAttachments(this.selectedFilesAttachment, this._AttachmentService, leadProposalId, 37);
  }







  saveAndCloseProposal(): Promise<void> {
    return new Promise((resolve, reject) => {
      const statusCode: any = this.getStatusProposal?.code;
      this.saveLeadProposal(statusCode, () => {
        this.resetForm(); // Reset the form
        this.isVisibleLead = false; // Close the modal or hide the view
        this.cancel.emit(); // Emit cancel event if needed
        resolve(); // Resolve the promise
      }).catch((error) => {
        reject(error); // Reject the promise with the error
      });
    });
  }


  saveAndReleasedProposal(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.saveLeadProposal(ProposalStatusEnum.Released, () => {
        resolve(); // Resolve the promise
      }).catch((error) => {
        reject(error); // Reject the promise with the error
      });
    });
  }



  fetchAttachments(AttachmentId: number) {
    if (AttachmentId) {
      this._AttachmentService.getLeadByAttachmentId(AttachmentId).subscribe(
        (res) => {
          this.TestAttachment = res.result
        },
      );
    } else {
      this.TestAttachment = undefined;
    }
  }


  onFilesUploaded(files: CustomUploadFile[]): void {
    this.selectedFilesAttachment = files
  }


  ReleaseConfirmCard(): void {
    const customerInformationId = this.leadOpportunityProposalSaveResponse?.CustomerInformationId;
    const inGridcustomerInformationId = this.getProposalTemplatesData?.leadOpportunity?.customerInformationId;
    if (!customerInformationId && !inGridcustomerInformationId) {
      this.modal.confirm({
        nzTitle: `There is no customer contact assigned to this lead.`,
        nzContent: 'Please set up a customer contact on this lead opportunity if you wish to send this proposal.',
        nzOkText: `Ok`,
        nzCancelText: null,
        nzOnOk: () => {
        },
        nzCentered: true,
        nzBodyStyle: { 'border-radius': '50px', 'height': 'auto', 'padding-top': '15px', 'padding': '20px' },
        nzCloseIcon: '',
        nzIconType: ''
      });
    } else {
      this.saveAndReleasedProposal()
        .then(() => {
        })
        .catch(() => {
        });
    }
  }
  // Dropdown




  saveAndApproveProposal(): void {
    const modalRef = this.modal.create({
      nzContent: SignaturePadComponent,
      nzFooter: null
    });

    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });

    modalRef.componentInstance.signatureSaved.subscribe(async (data) => {
      this.signatureResponse = data;
      try {
        await new Promise<void>((resolve, reject) => {
          this.saveLeadProposal(ProposalStatusEnum.Approved, resolve).catch(reject);
        });
      } catch (error) {
        this.toastService.error('Error saving lead proposal.');
        console.error('Error:', error);
      } finally {
        modalRef.destroy();
      }
    });
  }


  /// after approved visibility of buttons and hidden buttons
  approveAllowButtons(): boolean {
    return this.getStatusProposal?.code !== this.ProposalStatusEnum.Approved;
  }
  /// after Decline visibility of buttons and hidden buttons

  declineAllowButtons(): boolean {
    return this.getStatusProposal?.code !== this.ProposalStatusEnum.Declined && this.statusUpdateForDecline?.code !== this.ProposalStatusEnum.Declined;
  }

  ReleaseAllowButtons(): boolean {
    return this.getStatusProposal?.code !== this.ProposalStatusEnum.Released;
  }


  declineProposal() {
    const modalRef = this.modal.create({
      nzContent: SignaturePadComponent,
      nzFooter: null
    });

    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });

    modalRef.componentInstance.signatureSaved.subscribe(async (data) => {
      this.signatureResponseDecline = data;
      this.isDeclineProposal = true; // Set the decline proposal flag

      try {
        await new Promise<void>((resolve, reject) => {
          this.saveLeadProposal(ProposalStatusEnum.Declined, resolve).catch(reject);
        });
      } catch (error) {
        this.toastService.error('Error declining lead proposal.');
        console.error('Error:', error);
      } finally {
        modalRef.destroy();
      }
    });
  }


  private emitData(data: LeadProposalResponse): void {
    if (!this.isDataEmitted && data) {
      this.leadProposalSaveResponse.emit(data);
      this.isDataEmitted = true;
    }
  }


  resetFlags() {
    this.isSave = false;
    this.isSaveAndClose = false;
    this.isSaveAndRelease = false;
    this.isSaveAndApproval = false;
    this.isDecline = false;
  }

  UnReleasedProposal(UnReleaseCode: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const payload: UpdateStatusPayload = {
        globalId: this.getProposalTemplatesData.globalId,
        statusSetupCode: UnReleaseCode
      };

      this.leadProposalsService.updateUnReleaseStatus(payload).subscribe({
        next: (res) => {
          this.toastService.success('Un Release Proposal!');
          this.getStatusProposal = res.result;

          resolve(); // Resolve the promise
        },
        error: (error) => {
          this.toastService.error('Error updating proposal status.');
          reject(error); // Reject the promise with the error
        },
        complete: () => {
          this.isUnRelease = false; // Reset the unrelease flag
        }
      });
    });
  }

  UnReleaseConfirmCard(): void {
    if (this.isUnRelease) {
      return;
    }

    this.modal.confirm({
      nzTitle: `Unrelease Lead Proposal?`,
      nzContent: 'This will disable the link sent to the Contact Email and will reset the status of this proposal.',
      nzOkText: `Unrelease`,
      nzCancelText: 'Cancel',
      nzOnOk: () => {
        this.isUnRelease = true;
        return this.UnReleasedProposal(ProposalStatusEnum.Unreleased)
          .then(() => {
            // Additional actions if needed
          })
          .catch((error) => {
            console.error('Error during unrelease operation:', error);
          });
      },
      nzCentered: true,
      nzOkLoading: this.isUnRelease,
      nzBodyStyle: { 'border-radius': '50px', 'height': 'auto', 'padding-top': '15px' },
      nzCloseIcon: '',
      nzIconType: ''
    });
  }


  resetFormFields: any = {};
  getFormattedDateTime(): string {
    const date = new Date();

    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };

    return date.toLocaleString('en-US', options);
  }

  resetFormGroup(): void {
    this.resetFormFields = {
      saveApprovedProposalAsAPdf: true,
      pdfTitle: `Approved Proposal ${this.getFormattedDateTime()}`,
      resetAllProposalsForThisLead: true
    };
  }

  isResetVisible = false;
  showConfirmResetModal(): void {
    this.isResetVisible = true;
  }

  handleCancel(): void {
    this.isResetVisible = false;
  }


  isTitleSet = false; // Flag to track if title is set

  resetProposal(): void {
    // Check if pdfTitle is empty or consists of only spaces
    if (!this.resetFormFields.pdfTitle) {
      this.resetFormFields.pdfTitle = `Approved Proposal ${this.getFormattedDateTime()}`;
      this.isTitleSet = true; // Set flag indicating the title is now set
      return; // Exit without calling the API
    }

    // Proceed with the proposal reset logic only if the title is already set
    if (this.resetFormFields.pdfTitle) {
      const payload: UpdateStatusPayload = {
        globalId: this.getProposalTemplatesData.globalId,
        statusSetupCode: ProposalStatusEnum.Reset
      };

      this.leadProposalsService.updateUnReleaseStatus(payload).subscribe({
        next: (res) => {
          this.toastService.success('Suuccessfuly Reset');
          this.getStatusProposal = res.result;
          this.isResetVisible = false; // Close the modal after success
          this.isTitleSet = false; // Reset the flag for future use
        },
        error: () => {
          this.toastService.error('Error updating proposal status.');
        }
      });
    }
  }




  deleteLeadProposal(globalId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!globalId) {
        this.toastService.error('No record selected for deletion.');
        this.isDeleteLoading = false;
        reject();
        return;
      }

      this.leadProposalsService.deleteProposal(globalId).subscribe({
        next: (res: HttpResponse<void>) => {
          this.isDeleteLoading = false;
          // if (res.status === 200) { // Check for HTTP status code 200
          this.toastService.success(`${this.getProposalTemplatesData.title} Deleted!`);
          this._BehaviorsubjectService.setLeadActivityTypeResponse(res);
          this.leadProposalSaveResponse.emit();
          resolve();
          // } else {
          this.toastService.error('Failed to delete the record. Please try again.');
          reject();
          // }
        },
        error: () => {
          this.isDeleteLoading = false;
          this.toastService.error('An error occurred while deleting the record. Please try again.');
          reject();
        }
      });
    });
  }

  deleteLeadConfirm(): void {
    if (this.isDeleteLoading) {
      return;
    }

    this.modal.confirm({
      nzTitle: `Delete Lead Proposal?`,
      nzContent: 'Are you sure you want to permanently delete this lead proposal and all line items on it?',
      nzOkText: `Delete`,
      nzOkDanger: true,
      nzCancelText: 'Cancel',
      nzOnOk: () => {
        this.isDeleteLoading = true;
        return this.deleteLeadProposal(this.formFieldValues.globalId)
          .then(() => {
            this.handleCancelLeadProposal();
          })
          .catch(() => {

          });
      },
      nzCentered: true,
      nzOkLoading: this.isDeleteLoading,
      nzBodyStyle: { 'border-radius': '50px', 'height': 'auto', 'padding-top': '15' },
      nzCloseIcon: '',
      nzIconType: ''
    });
  }


  resetForm(): void {
    this.setInitialFormFieldValues();
    this.formFieldValues = {
      id: 0,
      globalId: '',
      leadOpportunityId: 0,
      title: '',
      approvalDeadlineOn: '',
      internalNotes: '',
      IsTemplate: true,
      salesPersonId: 0,
      introductoryText: '',
      closingText: '',
    };
  }



  getLeadData() {
    this.leadProposalsService.getAllLeadData().subscribe(data => {
      this.leadOptions = data.result;
    });
    this.setInitialFormFieldValues();
  }



  initLoad() {
    this.LookupFilterService.getLookUpValuesByFormNameId(37).subscribe(res => {
      let proposalStatus = res.result.filter(x => x.code === this.preDefinedDataCodes.costType.code);
      let markupValues = res.result.filter((x) => x.code === this.preDefinedDataCodes.markup.code);
      let markAsOptionValues = res.result.filter(x => x.code === this.preDefinedDataCodes.markAs.code);
      this.proposalOption = proposalStatus;
      this.markupOption = markupValues;
      this.markAsOption = markAsOptionValues;

    });
    this.setInitialFormFieldValues();

  }


  leadProposal() {
    this.isVisible10 = true;
  }


  handleCancelLeadProposal(): void {
    this.cancel.emit();
    this.leadOppService.clearSelectedFiles();
    this.isVisibleLead = false;
    this.emitData(this.getProposalTemplatesData);
  }

  openLeadProposaltModal(): void {
    this.isVisibleLead = true;
  }
  cancelLeadProposaltModal(): void {
    this.cancel.emit();
  }
  handleLeadSelection(value: any): void {
    this.isVisibleLead = false;
    if (value === 'jack' || value === 'lucy' || value === 'tom') {
      this.isVisible10 = true;
    } else {
    }
  }
  showMoreFeild() {
    this.isFieldsVisible = !this.isFieldsVisible;
  }
  leadproposalTabs(tab: number): string {
    switch (tab) {
      case 1:
        return 'Worksheet';
      case 2:
        return 'Format';
      case 3:
        return 'Preview';
      default:
        return '';
    }
  }

  showDocUpload() {
    const modalRef = this.modal.create({
      nzContent: FileUploadComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  showEstimatedCost() {
    const modalRef = this.modal.create({
      nzContent: EstimatedCostComponent,
      nzFooter: null,
    });

    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });

    modalRef.componentInstance.onSaveEstimatedCostComplete.subscribe(() => {
    });
  }

  leadProposalItemEdit(costItemParameterDataSet: CostItemParameterResponse) {
    const modalRef = this.modal.create({
      nzContent: EstimatedCostComponent,
      nzFooter: null
    });
    modalRef.componentInstance.getProposalItemData = costItemParameterDataSet;
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    modalRef.componentInstance.onSaveEstimatedCostComplete.subscribe(() => {
    });
  }





  showProposalCostCode() {
    const modalRef = this.modal.create({
      nzContent: NewProposalsCostCodesComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();

    });
  }

  toggleSection() {
    this.isSectionVisible = !this.isSectionVisible;
  }
  ForCombo() {
    this.customerInformationService.getDataforcombo().subscribe((res) => {
      const assignedUser = res.result;
      this.assignedUser = assignedUser.map((AUser) => ({
        label: AUser.fullName,
        value: AUser.id.toString(),
      }));
    });
    this.setInitialFormFieldValues();

  }









  // Formatter function to format the number to "1.000"
  formatNumber = (value: number | string): string => {
    if (value === null || value === undefined) return '';
    // Ensure the value is a number
    const numValue = typeof value === 'string' ? parseFloat(value) : value;
    return numValue.toFixed(3); // Format number with 3 decimal places
  };

  // Parser function to parse the formatted string back to a number
  parseNumber = (value: string): string => {
    if (!value) return '';
    // Convert the string to a number and then back to a string to remove commas or other non-numeric characters
    return parseFloat(value.replace(/,/g, '')).toString();
  };

  public config: EditorConfig = {
    toolbar: {
      items: [
        'undo', 'redo', '|',
        'heading', '|', 'bold', 'italic', '|',
        'link', 'insertTable', 'mediaEmbed', '|',
        'bulletedList', 'numberedList', 'indent', 'outdent', '|',
        'fontSize', 'fontFamily', 'fontBackgroundColor', 'fontColor', 'blockQuote',
        'image', 'horizontalLine', 'undoEditing'
      ]
    },
    plugins: [
      Bold,
      Essentials,
      Heading,
      Indent,
      Italic,
      Link,
      List,
      MediaEmbed,
      Paragraph,
      Table,
      Undo,
      Font,
      FontSize,
      FontFamily,
      FontBackgroundColor,
      FontColor,
      BlockQuote,
      Image,
      HorizontalLine,
      UndoEditing,
      ImageUpload
    ],
    fontSize: {
      options: [
        9, 11, 13, 'default', 17, 19, 21, 25, 28, 32, 36, 40, 44, 48, 52, 56, 60
      ]
    },
    fontFamily: {
      options: [
        'default', 'Arial, Helvetica, sans-serif', 'Courier New, Courier, monospace',
        'Georgia, serif', 'Times New Roman, Times, serif', 'Verdana, Geneva, sans-serif'
      ]
    }
  };

  formatDate(date: Date): string {
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${month} ${day}, ${year}`;
  }

  readonly validationRules = {
    title: {
      required: 'Required',
      maxLength: { limit: 100, message: 'Title exceeds the limit.' }
    },
    approvalDeadlineOn: {
      dateNotInPast: 'Date must be on or after ' + this.formatDate(new Date()),
    },
    internalNotes: {
      maxLength: { limit: 512, message: 'Internal Notes exceeds the limit.' }
    }
  };

  // Function to get the label for a field
  getFieldLabel(field: string): string {
    const labels: { [key: string]: string } = {
      title: 'Title',
      approvalDeadlineOn: 'Approval Deadline',
      internalNotes: 'Internal Notes',
    };
    return labels[field] || field;
  }

  checkErrors(validateAllFields = false) {
    this.validationStates = {};
    this.validationMessages = {};

    // Determine which fields to validate
    const fieldsToValidate = validateAllFields ? Object.keys(this.validationRules) : Array.from(this.changedFields);

    for (const field of fieldsToValidate) {
      if (this.validationRules.hasOwnProperty(field)) {
        const errorTip = this.getErrorTip(field);
        if (errorTip) {
          this.validationStates[field] = 'error';
          this.validationMessages[field] = errorTip;
        } else {
          this.validationStates[field] = null;
          this.validationMessages[field] = '';
        }
      }
    }
  }

  validateAllFields() {
    this.checkErrors(true);
  }

  validateChangedFields(field: string) {
    this.changedFields.add(field);
    this.checkErrors();
  }

  getErrorTip(field: string): string {
    const rules = this.validationRules[field];
    const input = this.formFieldValues[field] || '';

    if (rules.required && !input.trim()) {
      return `Required.`;
    }

    if (rules.maxLength && input.length > rules.maxLength.limit) {
      const excessLength = input.length - rules.maxLength.limit;
      const unit = excessLength === 1 ? 'character' : 'characters';
      return `${excessLength} ${unit} over.`;
    }

    // Validate the date for approvalDeadlineOn
    if (field === 'approvalDeadlineOn' && input) {
      const selectedDate = new Date(input);
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Set today's time to midnight for comparison
      if (selectedDate < today) {
        return rules.dateNotInPast;
      }
    }
    return ''; // No error
  }
  get errorFields(): { field: string; label: string; message: string }[] {
    return Object.keys(this.validationStates)
      .filter(field => this.validationStates[field] === 'error')
      .map(field => ({
        field,
        label: this.getFieldLabel(field),
        message: this.validationMessages[field]
      }));
  }
  onFieldChange(field: string, value: any) {
    if (field === 'approvalDeadlineOn') {
      this.formFieldValues[field] = value instanceof Date ? value.toISOString() : value;
    } else {
      this.formFieldValues[field] = value;
    }
    this.changedFields.add(field);
    this.checkErrors();
  }
  async storeInitialFormFieldValues(): Promise<void> {
    await new Promise(resolve => setTimeout(() => {
      this.initialFormFieldValues = JSON.parse(JSON.stringify(this.formFieldValues));
      resolve(true);
    }, 0));
  }


  isFormDirty(): boolean {
    return JSON.stringify(this.formFieldValues) !== JSON.stringify(this.initialFormFieldValues);
  }


  showConfirmCardLeadProposal(): void {
    const dontSaveText = 'Dont Save'; // Constant to store the 'Don't Save' button text
    if (this.isFormDirty()) {
      this.confirmModal = this.modal.confirm({
        nzTitle: '<b>Save Changes?</b>',
        nzContent: '<p>You have unsaved changes, do you want to save your changes before closing?</p>',
        nzOkText: 'Save',
        nzOkType: 'primary',
        nzCancelText: dontSaveText,
        nzOkLoading: this.isSaveAndClose,
        nzClosable: true, // Modal can be closed with the close button
        nzOnOk: () => {
          this.resetFlags();
          this.isSaveAndClose = true;
          this.confirmModal.destroy();
          return this.saveAndCloseProposal()
            .then(() => {
              this.isSaveAndClose = false;
              this.saveAndCloseProposal();
            })
            .catch((error) => {
              this.isSaveAndClose = false;
              console.error('Error saving Customer:', error);
              // Optionally show an error message to the user
            });
        },
        nzOnCancel: () => {
          const clickedButton = (event.target as HTMLElement).textContent?.trim();
          if (clickedButton === dontSaveText) {
            // Close both the confirm card and the modal
            this.confirmModal.destroy();
            this.resetForm();
            this.handleCancelLeadProposal();
          }
          else {
            this.confirmModal.destroy();
          }
        },

        nzStyle: { top: '250px' },
        nzClassName: 'custom-modal-content',
      });
    } else {
      this.resetForm();
      this.handleCancelLeadProposal();
    }
  }


  CreateNewFile(): void {
    const modalRef = this.modal.create({
      nzContent: ProjectManagementSiteDiariesCreateFilesComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  validationStates: { [key: string]: NzStatus } = {};




  createAndModifiedByData(id: number) {
    const payload: FilterFooterAudit = {
      id: id,
      formCode: 'LOLP'
    }
    this.FooterService.createdAndModifyUser(payload).subscribe(res => {
      this.footerResponse = res.result;

    })
  }



  showCatalogModel(flag: String) {
    const modalRef = this.modal.create({
      nzContent: LeadOpportunitiesWorkSheetAddformCatalogComponent,
      nzFooter: null,
      nzData: {
        CompanySetting: flag
      },
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }

  editColumns() {
    const modalRef = this.modal.create({
      nzContent: LeadProposalColumnsComponent,
      nzFooter: null
    });
    // modalRef.componentInstance.getProposalItemData = costItemParameterDataSet;
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }


  panels = [
      {
        active: false,
        disabled: false,
        name: 'More Details'
      }
  ];

  readonly customStyle = {
    background: '#f7f7f7',
    'border-radius': '4px',
    'margin-bottom': '24px',
  };
}
