import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { BreakInformation, ITimeClock_ClockIn, ITimeClockSetup, ITimeClockShiftGetById } from 'src/Models/_Time_Clock/_Time_Clock';
import { LookupNameSetupResponse } from 'src/Models/LeadTags';
import { ResponseModel, ResponseModelArray } from 'src/Models/responseMessage.model';

@Injectable({
  providedIn: 'root'
})
export class TimeClockSService {

  private This_Url: string = environment.apiUrl + 'TimeClockSetup/';
  private This_Url_ShiftInformation: string = environment.apiUrl + 'ShiftInformation/';

  constructor(private http: HttpClient) {}

  getCompanyTimeClockSetup(): Observable<ResponseModel<ITimeClockSetup>> {
    return this.http.get<ResponseModel<ITimeClockSetup>>(`${this.This_Url}getData-ByCompany`);
  }
  PostTimeClockSetup(createCompanyITimeClockSetup: ITimeClockSetup): Observable<ResponseModel<ITimeClockSetup>> {
    const apiUrl = `${this.This_Url}`;
    return this.http.post<ResponseModel<ITimeClockSetup>>(apiUrl, createCompanyITimeClockSetup).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }
  PostTimeClockIn(createCompanyITimeClockSetup: ITimeClockShiftGetById): Observable<ResponseModel<ITimeClockShiftGetById>> {
    const apiUrl = `${this.This_Url_ShiftInformation}`;
    return this.http.post<ResponseModel<ITimeClockShiftGetById>>(apiUrl, createCompanyITimeClockSetup).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }
  GetClockOut(): Observable<ResponseModel<ITimeClockShiftGetById>> {
    return this.http.get<ResponseModel<ITimeClockShiftGetById>>(`${this.This_Url_ShiftInformation}GetValidate-CheckIn`);
  }
  getDataIdChangeOrder(id: number): Observable<ResponseModel<ITimeClockShiftGetById>> {
    return this.http.get<ResponseModel<ITimeClockShiftGetById>>(`${this.This_Url_ShiftInformation}getBy-Id?id=${id}`);
  }
  deleteData(globalId: string): Observable<ResponseModel<ITimeClockShiftGetById>> {
    return this.http.delete<ResponseModel<ITimeClockShiftGetById>>(
      `${this.This_Url_ShiftInformation}?globalId=${globalId}`
    );
  }
  private baseUrl: string = environment.apiUrl + 'LookupNameSetup/';
  ShowCode(code: string): Observable<ResponseModelArray<LookupNameSetupResponse>> {
    return this.http.post<ResponseModelArray<LookupNameSetupResponse>>(`${this.baseUrl}getAll-byStandardCode`, { code });
  }
  updateStartTime(_globalId: string): Observable<ResponseModel<ITimeClockShiftGetById>> {
    return this.http.put<ResponseModel<ITimeClockShiftGetById>>(`${this.This_Url_ShiftInformation}Update-BreakTimes?globalId=${_globalId}`, null);
  }
  getBreakBy(id: number): Observable<ResponseModelArray<BreakInformation>> {
    return this.http.get<ResponseModelArray<BreakInformation>>(`${this.This_Url_ShiftInformation}getBreakBy-ShiftInformationId?id=${id}`);
  }
}
