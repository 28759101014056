import { ChangeOrderComponent } from './../Variations/change-order/change-order.component';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { LeadOpportunityResponse } from 'src/Models/LeadTags';
import { NewscheduleItemComponent } from '../schedules-Components/schedule-common/newschedule-item/newschedule-item.component';
import { ProjectManagementSiteDiariesNewDailyLogComponent } from '../Site-Diaries/project-management-site-diaries-new-daily-log/project-management-site-diaries-new-daily-log.component';
import { WarrantyNewClaimComponent } from '../Warranty/warranty-new-claim/warranty-new-claim.component';
import { SelectionComponent } from '../Selection-Common/selection/selection.component';
import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';
import { JobInformationService } from 'src/Service/Job-List/Job-Information/job-information.service';

import { NewRFIsComponent } from 'src/app/messaging/Components/RFIs/new-rfis/new-rfis.component';
import { JobShortInformation } from 'src/Models/Job-Scratch/JobFromScratch';

@Component({
  selector: 'app-project-management-select-dialog-box',
  templateUrl: './project-management-select-dialog-box.component.html',
  styleUrls: ['./project-management-select-dialog-box.component.css'],
})
export class ProjectManagementSelectDialogBoxComponent implements OnInit {
  initializationComplete = new EventEmitter<boolean>();
  @Output() cancel = new EventEmitter<void>();
  @Output() cancelWithData = new EventEmitter<JobShortInformation>();

  @Input() argument: string;
  leadOptions: LeadOpportunityResponse[] = [];
  selectedJob: JobShortInformation;
  selectedLeadId: any;
  jobInfoOptions: JobShortInformation[] = [];
  selectedJobId: any;
  selectedJobName: any;

  constructor(
    private jobInfoService: JobInformationService,
    private modal: NzModalService,
    public AccessLocalStorageService: AccessLocalStorageService,
  ) { }

  ngOnInit() {
    this.getLeadData();
  }

  getLeadData() {
    this.jobInfoService.onGetJobShortInformation().subscribe((Data) => {
      this.jobInfoOptions = Data.result;
      this.initializationComplete.emit(false);
    });
  }
  onLeadSelect(select_Job: number): void {
    this.selectedJob = this.jobInfoOptions.find((x) => x.id === select_Job);
    this.openLeadActivityFormModal(this.selectedJob);
    this.cancel.emit();

    if (this.argument === 'ToDos') {
      this.cancelWithData.emit(this.selectedJob);
    }
  }

  openLeadActivityFormModal(selectedJob: JobShortInformation) {
    let component: any;
    let width = '97%';
    let top = '22px';

    // Determine the component based on the argument
    switch (this.argument) {
      case 'Schedule':
        component = NewscheduleItemComponent;
        break;
      case 'SiteDiaries':
        component = ProjectManagementSiteDiariesNewDailyLogComponent;
        break;
      case 'ChangeOrder':
        component = ChangeOrderComponent;
        break;
      case 'Selection':
        component = SelectionComponent;
        break;
      case 'Warranties':
        component = WarrantyNewClaimComponent;
        break;
      case 'RFIs':
        component = NewRFIsComponent;
        break;
      default:
        console.error('Unknown type:', this.argument);
        return;
    }

    // Create the modal with the component
    const modalRef = this.modal.create({
      nzContent: component,
      nzWidth: width,
      nzBodyStyle: {
        background: '#f1f4fa',
        padding: '0',
        margin: '0',
        'overflow-x': 'hidden',
      },
      nzStyle: { top },
      nzFooter: null,
    });

    // Pass data to the modal instance based on the argument
    this.setModalInstanceData(modalRef, selectedJob);
  }




  private setModalInstanceData(modalRef: any, selectedJob: any) {
    switch (this.argument) {
      case 'Schedule':
        modalRef.componentInstance.selectedJobId = selectedJob.id;
        modalRef.componentInstance.selectedJobName = selectedJob.name;
        modalRef.componentInstance.selectedColorId = selectedJob.colorId;
        break;
      case 'SiteDiaries':
        modalRef.componentInstance.selectedJobId = selectedJob.id;
        modalRef.componentInstance.selectedJobName = selectedJob.name;
        modalRef.componentInstance.selectedColorId = selectedJob.colorId;
        break;
      case 'ChangeOrder':
        modalRef.componentInstance.onSelectedJob_Id = selectedJob?.id;
        break;
      case 'Selection':
        // Additional logic for Selection if needed
        break;
      case 'Warranties':
        modalRef.componentInstance.Onselected_JobId = selectedJob?.id;
        modalRef.componentInstance.Onselected_JobName = selectedJob?.name;
        break;
      case 'RFIs':
        modalRef.componentInstance.onSelectedJob_Id = selectedJob?.id;
        break;
    }

    // Handle the cancel event
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }






}
