import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { Subscription } from 'rxjs';
import { AttachmentResponse, CreateAttachmentForDocumentRequest } from 'src/Models/Attachment_Files_Class/AttachmentFilesClass';
import { AttachmentService } from 'src/Service/Attachment/attachment.service';
import { FileDecumentService } from 'src/Service/FileDocumnet/file-decument.service';
import { LeadOpportunitiesService } from 'src/Service/lead-opportunities.service';

@Component({
  selector: 'app-project-management-site-diaries-upload-files',
  templateUrl: './project-management-site-diaries-upload-files.component.html',
  styleUrls: ['./project-management-site-diaries-upload-files.component.css']
})
export class ProjectManagementSiteDiariesUploadFilesComponent implements OnInit{
  @Output() cancel = new EventEmitter<void>();
  @Input() attachments: AttachmentResponse[];
  subscription: Subscription;
  attachmentResponse: any;
  uploadfiles=true;
  selectedFiles: File[] = [];
  showCheckIcon: boolean = false;
  savedId: string;
  constructor(
    private leadOppService:LeadOpportunitiesService,
    private _AttachmentService: AttachmentService,
    private FileDecumentService : FileDecumentService,
  @Inject(NZ_MODAL_DATA) public responseData: any,

  ){
    console.log("HeaderId",responseData);
    
  }

  ngOnInit(): void {

    
    

    this.FileDecumentService.currentSavedId.subscribe(savedId => {
      this.savedId = savedId
      console.log('Saved ID in DocumentsDashboardComponent12:', savedId);
    });

    setTimeout(() => {
      this.showCheckIcon = true;
    },4000);
  
  }

  UploadFiles(): void {
  this.cancel.emit();
  }

  chooseFile(): void {
    const input = document.getElementById('files');
    if (input) {
      input.click();
    }
  }
  previewUrl: string | ArrayBuffer | null = null;
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onFileSelected(event: any): void {
    const files: FileList = event.target.files;
    for (let i = 0; i < files.length; i++) {
      const file = files.item(i)!;
      this.selectedFiles.push(file);
      if (this.isImageFile(file.type)) {
        this.previewFile(file);
      }
    }
  }

  previewFile(file: File): void {
    const reader = new FileReader();
    reader.onload = (e) => {
      this.previewUrl = e.target?.result;
    };
    reader.readAsDataURL(file);
  }
  isImageFile(fileType: string): boolean {
    return fileType.startsWith('image/');
  }

  clearAll(): void {
    this.selectedFiles = [];
  }

  removeFile(file: File): void {
    const index = this.selectedFiles.indexOf(file);
    if (index !== -1) {
      this.selectedFiles.splice(index, 1);
    }
}



saveAttachment() {
  this.leadOppService.setSelectedFiles(this.selectedFiles);
  this.cancel.emit();
}



save(): void {
  if (this.savedId && this.selectedFiles && this.selectedFiles.length > 0) {
    const filesToUpload = this.selectedFiles.filter(file => file instanceof File);
    if (filesToUpload.length > 0) {
      const attachmentRequest: CreateAttachmentForDocumentRequest = {
        headerId: parseInt(this.savedId, 10), 
        companyParameterId: 1,
        formNameId: 40013,
        documentFiles: filesToUpload
      };

      console.log('Selected Files:', this.selectedFiles);
      this._AttachmentService.CreateAttachmentForDocument(attachmentRequest).subscribe(
        (attachmentResponse) => {
          console.log('Attachment created successfully:', attachmentResponse);
          this.saveFileIdToDatabase(attachmentResponse.fileId);
          this.cancel.emit();
        },
        (attachmentError) => {
          console.error('Error creating attachment:', attachmentError);
        }
      );
    } else {
      console.warn('No files to upload.');
    }
  } else {
    console.warn('No savedId or files to upload.');
  }
}

saveFileIdToDatabase(fileId: string) {
}

}