import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { forkJoin, map } from 'rxjs';
import { saveAttachments } from 'src/app/helpers/Attachment_Save';
import { Editor, EditorConfig } from 'src/app/helpers/ckeditor-config';
import validateForm from 'src/app/helpers/validationForm';
import { CreateAttachmentsCommonComponent } from 'src/app/shared/component/create-attachments-common/create-attachments-common.component';
import { environment } from 'src/environments/environment.prod';
import { RfiInformationAnswerApprovedGetById, RFIInformationAnswerGetById, RfiInformationAnswerResponse, RFIInformationResponse } from 'src/Models/_RFIs/RFIs_Infor';
import { AttachmentParameterResponse, AttachmentResponse, CustomUploadFile } from 'src/Models/Attachment_Files_Class/AttachmentFilesClass';
import { ApplicationUserResponse } from 'src/Models/InternalUser/applicationUserResponse';
import { SubVendorResponse } from 'src/Models/SubVendor/SubvendorResponse.model';
import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';
import { AttachmentService } from 'src/Service/Attachment/attachment.service';
import { BehaviorsubjectService } from 'src/Service/behaviorsubject/behaviorsubject.service';
import { SubVendorService } from 'src/Service/CompanyInFormation/Selections/sub-vendor.service';
import { InternalUserService } from 'src/Service/Internaluser/internal-user.service';
import { RFIsInforServiceService } from 'src/Service/RFIs_InforService/rfis-infor-service.service';

@Component({
  selector: 'app-rfi-new-answer',
  templateUrl: './rfi-new-answer.component.html',
  styleUrls: ['./rfi-new-answer.component.css']
})
export class RFINewAnswerComponent implements OnInit, OnChanges {
  @ViewChild(CreateAttachmentsCommonComponent) attachmentsComponent!: CreateAttachmentsCommonComponent;
  @Output() RFIsresponse = new EventEmitter<void>();
  @Output() _onApprovedAndIsComplete = new EventEmitter<void>();
  @Output() RFIs_Response_Is_Complete = new EventEmitter<any>(); 
  @Input() test_You : any; 
  // @Input() test_You_Two : boolean; 
  @Input() Job_ID: number;
  IMG_BASE: string = environment.IMG_BUCKET_URL;
  isSaveYourAnswer: boolean = false;
  isSaveAndApprove: boolean = false;
  DisabledNextPostAnswer: boolean = false;
  DisabledNextPostApprove: boolean = false;
  RFIsAnswerFormValueForm!: FormGroup;
  selectedFilesAttachment: CustomUploadFile[] = [];
  Files: CustomUploadFile[] = [];
  AttachmentReset_: AttachmentResponse;
  public Editor = Editor;
  public config = EditorConfig;
  initials: string = '';
  internalRes: ApplicationUserResponse[] = [];
  subVenRes: SubVendorResponse[] = [];
  selectedUsers: any[] = [];
  InterUserId: number
  SubVenId: number
  test: any;
  isDeleteLoading: boolean = false
  showApprovedMessage : boolean = false
  RFIsInfoAnswerRes: RFIInformationAnswerGetById[] = [];
  seaFilteredData: RFIInformationAnswerGetById = undefined;
  _IdRFIsInfoAnswerRes: number;
  TestAttachment__: AttachmentResponse[] = [];
  RFIsInfoAnswerApprove: RfiInformationAnswerApprovedGetById[] = [];
  seaFIsApproved: boolean;
  // seaFilteredData: RfiInformationAnswerApprovedGetById = undefined;

  constructor(  
    private fb: FormBuilder,
    private _RFIsInforService: RFIsInforServiceService,
    private _AttachmentService: AttachmentService,
    public toastsService: NzMessageService,
    private internalUserService: InternalUserService,
    private subVendorService: SubVendorService,
    public localStorageService: AccessLocalStorageService,
    private modal: NzModalService,
    private _BehaviorsubjectService: BehaviorsubjectService,
  ){ }

  ngOnInit(): void {
    this.createRFIsAnswerForm();
    if (this.test_You?.RFIId > 0) {
      this.RFIsInfoAnswerGetById(this.test_You?.RFIId);
    }
    this.onGetAllAssigneeUser();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['test_You'] && changes['test_You'].currentValue) {
      this.RFIsAnswerFormValueForm?.get('rfiInformationId')?.setValue(this.test_You?.RFIId);
      this.RFIsInfoAnswerGetById(this.test_You?.RFIId);
      this.seaFIsApproved = this.test_You?.IsComplete;
    } else {
      this.seaFIsApproved = false; 
    }
    this.onGetAllAssigneeUser();
  }
  createRFIsAnswerForm(){
    this.RFIsAnswerFormValueForm = this.fb.group({
      response: ['', Validators.required],
      JobInformationId: [this.Job_ID],
      rfiInformationId: [0],
      reassignUserId: [0],
      reassignCustomerId: [0],
      reassignSubVendorId: [0],
      attachmentId: [0],
      isApproved: [false],
    });
  }
  onGetAllAssigneeUser() {
    const internalUser$ = this.internalUserService.getData().pipe(map(res => res.result));
    const subVendor$ = this.subVendorService.getData().pipe(map(res => res.result));
  
    forkJoin([internalUser$, subVendor$]).subscribe(
      ([internalRes, subVenRes]) => {
        this.internalRes = internalRes.filter(ii => ii?.isActive === true);
        this.subVenRes = subVenRes.filter(ii => ii?.isActive === true);
  
        this.selectedUsers = [
          {
            groupName: 'Internal Users',
            users: this.internalRes.map(user => ({
              id: user?.id,
              name: user?.fullName,
            }))
          },
          {
            groupName: 'Sub Vendors',
            users: this.subVenRes.map(vendor => ({
              id: vendor?.id,
              name: vendor?.companyName,
            }))
          }
        ];
  
        const userId = this.localStorageService.getUserLoginId();
        const selectedUser = this.internalRes.find(user => user.id === userId);
  
        if (selectedUser) {
          this.test = { id: selectedUser.id, groupName: 'Internal Users' };
          this.onAssigneeChange(this.test);
        }
      },
      (error) => {
        console.error('Error fetching users or vendors:', error);
      }
    );
  }
  compareFn = (o1: any, o2: any) => {
    return o1 && o2 ? o1.id === o2.id && o1.groupName === o2.groupName : o1 === o2;
  }
  onAssigneeChange(selectedValue: any) {
    if (selectedValue === 'me') {
      const userId = this.localStorageService.getUserLoginId();
      const selectedUser = this.internalRes.find(user => user.id === userId);

      if (selectedUser) {
        this.test = { id: selectedUser.id, groupName: 'Internal Users' };
        this.onShortName(this.test?.id);
      }
    } else {
      this.onShortName(selectedValue.id || selectedValue);
    }
    if(selectedValue.groupName === "Internal Users"){
      this.InterUserId = selectedValue.id;
    } else if(selectedValue.groupName === "Sub Vendors"){
      this.SubVenId = selectedValue.id;
    }
  }
  onShortName(selectedValue: any){
    if(selectedValue){
      const userIdAndNames = this.selectedUsers.flatMap(group => 
        group.users.map(user => ({
          id: user.id,
          name: user.name
        }))
      );
      let data = userIdAndNames.find(user => user.id === selectedValue);
      this.initials = this.getInitials(data?.name);
    }
  }
  getInitials(fullName: string | undefined): string {
    if (!fullName) return '';
    const nameParts = fullName.split(' ');
    const firstInitial = nameParts[0]?.charAt(0) || '';
    const lastInitial = nameParts.length > 1 ? nameParts[nameParts.length - 1]?.charAt(0) || '' : '';
    return (firstInitial + lastInitial).toUpperCase();
  }
  saveYourAnswer(actionType: 'saveYourAnswer' = 'saveYourAnswer'): Promise<void> {
    return new Promise((resolve, reject) => {
      let data = this.RFIsAnswerFormValueForm;

      if (data.valid) {

        if (this.isSaveYourAnswer) {
          return reject('Already processing');
        }
        if (actionType === 'saveYourAnswer') {
          this.isSaveYourAnswer = true;
        }

        this.DisabledNextPostAnswer = true;
        data.value.rfiInformationId = this.test_You?.RFIId
        this._RFIsInforService.PostRfisNetwork(data.value).subscribe(
          (response) => {
            this._IdRFIsInfoAnswerRes = response.result?.rfiInformationId
            // Save attachments if any
            if (this.Files?.length > 0) {
              saveAttachments(this.Files, this._AttachmentService, response.result?.id, 30009)
                .then((attachmentResponseId) => {
                  if (attachmentResponseId > 0) {
                    this.Files = []
                      this.onApprovedAndIsComplete();
                      this.RFIs_Response_Is_Complete.emit(response.result);
                      this._BehaviorsubjectService.setLeadActivityTypeResponse(response.result);
                      this.RFIsInfoAnswerGetById(response.result?.rfiInformationId);
                      this.onIdRFIsChange();
                  }
                  resolve();
                })
                .catch((error) => {
                  reject(error);
                })
            } else {
                this.RFIs_Response_Is_Complete.emit(response.result);
                this._BehaviorsubjectService.setLeadActivityTypeResponse(response.result);
                this.RFIsInfoAnswerGetById(response.result?.rfiInformationId);
                resolve();
              }
            this.toastsService.success('Response Submitted');
            this.DisabledNextPostAnswer = false;
            this.RFIsAnswerFormValueForm.get('response')?.reset();
            this.DisabledNextPostAnswer = false;
          },
          (error) => {
            this.toastsService.error('An error has occurred. Please try again.');
            console.error('An error has occurred. Please try again.', error);
            validateForm.validateAllFormFields(data);
            this.DisabledNextPostAnswer = false;
            reject(error);
          }
        ).add(() => {
          this.isSaveYourAnswer = false;
        });
      } else {
      validateForm.validateAllFormFields(data);
      reject('Form is invalid');
      }
    });
  }
  deleteFile(): void { 
  }
  onFilesUploaded(files: CustomUploadFile[]): void {
    this.Files = files
  }
  getFileIcon(file: CustomUploadFile): string | null {
    const fileType = file.type;
    if (fileType.includes('pdf')) {
      return 'assets/Attachment/documentPDF.svg';
    } else if (fileType.includes('excel') || fileType.includes('spreadsheet')) {
      return 'assets/Attachment/documentXLS.svg';
    } else if (fileType.includes('word')) {
      return 'assets/Attachment/documentDOC.svg';
    } else if (fileType.includes('zip')) {
      return 'assets/Attachment/documentZIP.svg';
    } else if (fileType.includes('text') || fileType.includes('json')) {
      return 'assets/Attachment/documentGeneric.svg';
    } else if (fileType.includes('ppt') || fileType.includes('presentation')) {
      return 'assets/Attachment/documentPPT.svg';
    } else if (fileType.includes('video')) {
      return null;
    } else if (fileType.includes('image')) {
      return null;
    } else {
      return null;
    }
  }

  saveRFIsAndApprove(actionType: string, _global_Id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.isSaveAndApprove) {
        return reject('Already processing');
      }
      if (actionType === 'saveAndApprove') {
        this.isSaveAndApprove = true;
      }
      this.DisabledNextPostApprove = true;
      this._RFIsInforService.RFIsInfoAnswerApproved(_global_Id).subscribe(
        (response) => {
          this.RFIsInfoAnswerApprove = response.result;
          const filtered = response.result?.filter(item => item?.IsApproved === true);
          if (filtered.length > 0) {
            this.seaFIsApproved = true;
            this.RFIsInfoAnswerGetById(filtered[0]?.RfiInformationId);
            if (this.seaFilteredData?.AttachmentId) {
              this.fetchAttachments(this.seaFilteredData.AttachmentId, 0);
            }
            this.onApprovedAndIsComplete();
          } else {
          }
          this.DisabledNextPostApprove = false;
        },
        (error) => {
          this.toastsService.error('An error has occurred. Please try again.');
          console.error('An error has occurred. Please try again.:', error);
          this.DisabledNextPostApprove = false;
          reject(error);
        }
      ).add(() => {
      });
    });
  }
  deleteChangeOrderConfirm(globalId: string): void {
    this.modal.confirm({
      nzTitle: `Delete RFI Response?`,
      nzContent: `Are you sure you want to permanently delete this RFI Response?`,
      nzOkText: `Delete`,
      nzOkDanger: true,
      nzCancelText: 'Cancel',
      nzOnOk: () => {
        this.isDeleteLoading = true;
        return this.deleteAnswer(globalId)
          .then(() => {
          })
          .catch(() => {
          });
      },
      nzCentered: true,
      nzOkLoading: this.isDeleteLoading,
      nzBodyStyle: { 'border-radius': '50px', 'height': 'auto', 'padding-top': '15' },
      nzCloseIcon: '',
      nzIconType: ''
    });
  }
  deleteAnswer(_globalId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!_globalId) {
        this.toastsService.error('No record selected for deletion.');
        this.isDeleteLoading = false;
        reject();
        return;
      }

      this._RFIsInforService.deleteData(_globalId).subscribe(
        (res) => {
          this.RFIsInfoAnswerGetById(this._IdRFIsInfoAnswerRes);
          this.isDeleteLoading = false;
          resolve();
        },
        (error) => {
          this.isDeleteLoading = false;
          this.toastsService.error('An error occurred while deleting the record. Please try again.');
          reject();
        }
      );
    });
  }
  RFIsInfoAnswerGetById(_Id: number): void {
    this._RFIsInforService.RFIsInfoAnswergetDataId(_Id).subscribe((res) => {
      this.RFIsInfoAnswerRes = res.result.filter(xx =>xx.IsApproved === false);
      const filtered = res.result?.filter(item => item?.IsApproved === true);
      this.seaFilteredData = filtered[0];
      this.isSaveAndApprove = false;
      this.RFIsInfoAnswerRes.forEach((data: any, index: number) => {
        data.isApprovedMessageVisible = false;
        data.attachmentData = null;
  
        if (data.AttachmentId) {
          this.fetchAttachments(data.AttachmentId, index);
        }
      });
  });
  }
  fetchAttachments(AttachmentId: number, index: number): void {
    if (AttachmentId) {
      this._AttachmentService.getLeadByAttachmentId(AttachmentId).subscribe(
        (res) => {
          this.processAttachments(res.result, index);
        },
        (error) => {
          console.error('Error fetching attachments:', error);
        }
      );
    } else {
      this.RFIsInfoAnswerRes[index].attachmentData = undefined;
    }
  }
  processAttachments(Files: AttachmentResponse, index: number): void {
    if (Files?.attachmentParameters && Files.attachmentParameters.length > 0) {
      const attachmentParameters = Files?.attachmentParameters;
      this.selectedFilesAttachment = attachmentParameters.map(att => ({
        uid: att.id?.toString() || '',
        name: att.name || '',
        status: 'done',
        url: this.IMG_BASE + att.filePath,
        thumbUrl: this.IMG_BASE + att.filePath,
        type: this.getMimeType(att.extension),
        originFileObj: new File([new Blob()], att.name || '', {
          type: this.getMimeType(att.extension),
          lastModified: new Date(att.modifiedOn).getTime()
        }),
        loading: false,
        error: false,
        addedBy: att.createdBy || 0,
        createdOn: new Date(att.createdOn),
        modifiedOn: new Date(att.modifiedOn),
        id: att.id || 0,
        globalId: att.globalId || ''
      }));
      this.RFIsInfoAnswerRes[index].isApprovedMessageVisible = true;
      this.RFIsInfoAnswerRes[index].attachmentData = this.selectedFilesAttachment;
    } else {
      this.selectedFilesAttachment = [];
      console.log('No attachments available.');
    }
  }
  getMimeType(extension: string): string {
    switch (extension) {
      case '.pdf':
        return 'application/pdf';
      case '.doc':
      case '.docx':
        return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      case '.json':
        return 'application/json';
      case '.txt':
        return 'text/plain';
      case '.xls':
      case '.xlsx':
        return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      case '.ppt':
      case '.pptx':
        return 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
      case 'jpg':
      case 'jpeg':
        return 'image/jpeg';
      case 'png':
        return 'image/png';
      case 'mp4':
      case 'avi':
        return 'video/mp4';
      default:
        return 'application/octet-stream';
    }
  }
  onIdRFIsChange(): void {
    this.attachmentsComponent.clearAttachment();
  }
  onFieldChange(): void {
    // this.testwfwefw = []
  }
  onApprovedAndIsComplete(){
    this._onApprovedAndIsComplete.emit();
  }
}
