<nz-modal
[(nzVisible)]="selectionModelhandle"
[nzTitle]="SelectionModelTitle"
[nzFooter]="SelectionModelFooter"
[nzBodyStyle]="{
  background: '#f1f4fa',
  padding: '14px',
  maxHeight: '72vh',
  'overflow-y': 'auto',
  'overflow-x': 'hidden',
}" [nzStyle]="{ top: '25px'}" [nzWidth]="'96%'"
(nzOnCancel)="selectionModelCancel()">
<ng-template #SelectionModelTitle>
  <!-- <div  class="row" *ngIf="dataSetForselection">
    <h5 class="ListViewJobname p-0 m-0">{{ dataSetForselection}}</h5>
  </div> -->
  <div  class="row" *ngIf="JobData?.JOB_NAME">
    <h5 class="ListViewJobname p-0 m-0" >{{ JobData?.JOB_NAME }}</h5>
  </div>
  <div class="d-flex align-items-center" style="margin-left: -13px;" >
    <h1 class="p-0 m-0 react" id="textToCopy">Selection</h1>
  </div>
</ng-template>

  <div class="content content-set-background " *nzModalContent>
    <div class="top-of-modal mb-2"></div>
    <nz-alert
      class="mb-3 error-message"
      *ngIf="isSubmitted && errorFields.length > 0"
      nzType="error"
      nzShowIcon
      [nzMessage]="message"
      [nzDescription]="errorDescriptionTemplate"
    ></nz-alert>
    <ng-template #message>
      <p class="mt-1">Please correct the following fields:</p>
    </ng-template>
    <ng-template #errorDescriptionTemplate>
      <ul>
        <li class="sub-text" *ngFor="let error of errorFields">
          <span class="d-flex align-items-center">
            <p>{{ error.label }}:</p>
            <p>{{ error.message }}</p>
          </span>
        </li>
      </ul>
    </ng-template>
      <!-- <div class="spinner-wrapper" *ngIf="isLoading">
        <nz-spin nzSimple [nzSpinning]="true" [nzSize]="'large'"></nz-spin>
      </div> -->

    <div class="ng-container ">
      <div class="spinner-wrapper" *ngIf="isLoading">
        <img src="/assets/Loder/new-ajax-loader.gif">
      </div>
<nz-card class="custom-card-extra custom-card-body" [nzSize]="'small'" nzBorderless="true" [nzTitle]="Choice"  [nzExtra]="extraTemplate" >
  <ng-template #Choice>
    <span class="Card-Title-Set">Selection Details</span>
  </ng-template>
  <div class="row">
    <div class="col-6 mb-3" style="padding-left: 8px;">
      <div class="col-12">
        <div class="row">
          <div class="col-12">
            <label class="para-lable-selection">Title <span class="text-danger">*</span></label>
          </div>
          <div class="col-11">
            <input
              [(ngModel)]="newSelectionFormFiedls['title']"
              name="title"
              type="text"
              #stateInput="ngModel"
              nz-input
              class="formField col-11"
              (ngModelChange)="onFieldChange('title', $event)"
              [ngClass]="{
                'border-danger': newSelectionFormFiedls['title']?.length > 50
              }"
              required
              pattern="^.{50}$"
            />
            <div
              *ngIf="
                stateInput.invalid &&
                (stateInput.dirty || stateInput.touched)
              "
              class="text-danger"
            >

            </div>
            <div
              *ngIf="newSelectionFormFiedls['title']?.length > 50"
              class="text-danger"
            >
              {{ newSelectionFormFiedls['title'].length - 50 }} characters over.
            </div>
          </div>
        </div>

          <div class="row">
            <div class="col-12 mt-2">
              <label class="selectionCheckBoxSelection" [(ngModel)]="newSelectionFormFiedls['isRequireOwnerToMakeSelection']" name="isRequireOwnerToMakeSelection" nz-checkbox>Requare owner to make a selection</label>
            </div>
          </div>
        <div nz-row class="mt-1">
          <div nz-col nzSpan="24">
              <label class="para-lable-selection">Category</label>
          </div>
        </div>
        <div nz-row >
          <div nz-col nzSpan="16">
            <nz-select style="width: 100%" [(ngModel)]="newSelectionFormFiedls['catagorySetupId']" name="catagorySetupId" nzShowSearch [lang]="true" nzAutoFocus="true" nzPlaceHolder="Unassigned"
              >
              <nz-option *ngFor="let option of categoryOption" [nzLabel]="option.name" [nzValue]="option.id"></nz-option>
            </nz-select>
          </div>
          <div nz-col nzSpan="2" style="display: flex;justify-content: center;align-items: center;">
            <a class="BT-Link famly-inn"    (click)="newCategorySelectionOpen()">Add</a>
          </div>
          <div nz-col nzSpan="2" style="display: flex;justify-content: center;align-items: center;">
            <a
            class="BT-Link ms-1 famly-inn"
            [ngClass]="{'disabled-link': newSelectionFormFiedls?.catagorySetupId === 71389}"
            (click)="newSelectionFormFiedls?.catagorySetupId !== 71389 && newCategorySelectionEdit()">
            Edit
            </a>
          </div>
        </div>



        <div nz-row class="mt-2">
          <div nz-col nzSpan="24">
              <label class="para-lable-selection">Location</label>
          </div>
        </div>
        <div nz-row >
          <div nz-col nzSpan="16">
            <nz-select style="width: 100%" [(ngModel)]="newSelectionFormFiedls['locationSetupId']" name="locationSetupId" nzShowSearch [lang]="true" nzAutoFocus="true" nzPlaceHolder="Unassigned"
            >

            <nz-option *ngFor="let option of locationOption" [nzLabel]="option.name" [nzValue]="option.id"></nz-option>
          </nz-select>
          </div>
          <div nz-col nzSpan="2" style="display: flex;justify-content: center;align-items: center;">
            <a class="BT-Link famly-inn"  (click)="newLocationSelectionOpen()">Add</a>
          </div>
          <div nz-col nzSpan="2" style="display: flex;justify-content: center;align-items: center;">
            <a class="BT-Link ms-1 famly-inn"
            [ngClass]="{'disabled-link': newSelectionFormFiedls?.locationSetupId === 392}"
            (click)="newSelectionFormFiedls?.locationSetupId !== 392 && newLocationSelectionEdit()">Edit</a>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6 mb-3">
      <div class="row" style="margin-top: 4px;">
        <div class="col-12 ">
          <nz-radio-group [(ngModel)]="shareSingleradioValue" (ngModelChange)="shareSingleradioButtons()">
            <label  nz-radio-button nzValue="A">Single</label>
            <!-- [(ngModel)]="newSelectionFormFiedls['isSingle']" name="isSingle"  -->
            <label  nz-radio-button nzValue="B">Shared</label>
            <!-- [(ngModel)]="newSelectionFormFiedls['isShared']" name="isShared"  -->
          </nz-radio-group>
        </div>
      </div>
      <div class="row" *ngIf="showSelector">
        <div class="col-10 mt-2">
          <label class="para-lable-selection">Single Allowance</label>

  <nz-input-group nzAddOnBefore="$">
    <input
    nz-input
    name="singleAllowanceNumber"
    type="numbers"
    [(ngModel)]="newSelectionFormFiedls['singleAllowanceNumber']"
    appDollarFormatter
    #stateInput="ngModel"
    max="99999999999999"
    class="form-control formField"
  />
</nz-input-group>
        </div>
      </div>
      <div nz-row class="mt-2"  *ngIf="ShowSelector2">
        <div nz-col nzSpan="24">
            <label class="para-lable-selection">Shared Allowance<span class="text-danger fw-bolder ms-1">*</span></label>
        </div>
      </div>
      <div nz-row   *ngIf="ShowSelector2">
        <div nz-col nzSpan="18">
          <nz-select [(ngModel)]="newSelectionFormFiedls.sharedAllowanceId"
                     (blur)="onSharedAllowanceBlur()"
                     (focus)="onSharedAllowanceFocus()"
                     [ngClass]="{'is-invalid': showSharedAllowanceError}"
                     style="width: 100%;">
              <nz-option *ngFor="let option of shareAllowanceOption" [nzLabel]="option.title" [nzValue]="option.id"></nz-option>
              <div *ngIf="showSharedAllowanceError" class="text-danger">
                Required
            </div>
            </nz-select>
        </div>
        <div nz-col nzSpan="2" style="display: flex;justify-content: center;align-items: center;">
          <a class="BT-Link famly-inn" (click)="Editaloownce()">Add</a>
        </div>
        <div nz-col nzSpan="2" style="display: flex;justify-content: center;align-items: center;">
          <a class="BT-Link ms-1 famly-inn">Edit</a>
        </div>
      </div>




        <div nz-row class="mt-1">
          <div nz-col nzSpan="24">
              <label class="para-lable-selection">Deadline</label>
          </div>
        </div>
        <div nz-row *ngIf="View_Display === true">
          <div nz-col nzSpan="24">
              <nz-radio-group [(ngModel)]="radioValue" (ngModelChange)="radioButtons()">
                <label nz-radio-button nzValue="A">Choose Date</label>
                <label nz-radio-button nzValue="B">Link to Schedule Item</label>
              </nz-radio-group>
          </div>
        </div>
          <ng-container *ngIf="View_Display === false && this.newSelectionFormFiedls.scheduleItemParameterId > 0 && this.selectInfoResponseDueBYID_?.IsLinkedToScheduleItem === true">
            <div nz-row [ngClass]="{'to-padding-Schedule': !View_Display}">
              <div nz-col nzSpan="24">
                  <label class="para-lable-selection">Schedule Item</label>
              </div>
            </div>
            <div nz-row>
              <div nz-col nzSpan="24">
                <div class="ValueDisplay">{{ scheduleItem }}</div>
              </div>
            </div>
            <div nz-row>
              <div nz-col nzSpan="24">
                  <label class="para-lable-selection">Due</label>
              </div>
            </div>
            <div nz-row>
              <div nz-col nzSpan="24">
                <div class="ValueDisplay"><span>{{ this.selectInfoResponseDueBYID_?.DueNumberDays }}</span><span class="ms-2">Days</span>
                  <span class="ms-2" *ngIf="View_Display === false && this.selectInfoResponseDueBYID_?.IsBefore === true">Before</span>
                  <span class="ms-2" *ngIf="View_Display === false && this.selectInfoResponseDueBYID_?.IsAfter === true">After</span>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="View_Display === false">
            <div nz-row  [ngClass]="{'to-padding-Schedule': !View_Display && this.selectInfoResponseDueBYID_?.IsChooseDate}">
              <div nz-col nzSpan="24">
                  <label class="para-lable-selection">Due Date</label>
              </div>
            </div>
            <div nz-row>
              <div nz-col nzSpan="24">
                <div class="ValueDisplay">{{ this.selectInfoResponseDueBYID_?.DedlineOn | date: 'MMM d, yyyy' }}</div>
              </div>
            </div>
          </ng-container>
          <!-- <div nz-row class="pt-1" *ngIf="View_Display === false">
            <div nz-col nzSpan="24">
                <label class="para-lable-selection">Reminder</label>
            </div>
          </div>
          <ng-container *ngIf="!onReminderDirective; else Reminder">
            <div nz-row class="Tag_Custome pt-1" *ngIf="View_Display === false">
              <div nz-col nzSpan="24">
                <nz-tag>None</nz-tag>
              </div>
            </div>
          </ng-container>
          <ng-template #Reminder>
            <div nz-row class="Tag_Custome pt-1" *ngIf="View_Display === false">
              <div nz-col nzSpan="24">
                <nz-tag>{{ onReminderTag }}</nz-tag>
              </div>
            </div>
          </ng-template> -->
          <div nz-row [nzGutter]="10"  *ngIf="radioValue === 'A'">
            <div nz-col nzSpan="6" *ngIf="View_Display === true">
                <label class="para-lable-selection"><span>Due Date</span></label>
                  <nz-date-picker
                    [(ngModel)]="newSelectionFormFiedls.dedlineOn"
                    name="dueOn"
                    (ngModelChange)="onFieldChange('dedlineOn', $event)"
                    nzSuffixIcon="false"
                    [nzFormat]="'MMM d, YYYY'"
                    nzPlaceHolder=" "
                    autocomplete="off">
                  </nz-date-picker>
            </div>
            <div nz-col nzSpan="6">
                <label class="para-lable-selection"><span>Time</span></label>
                <nz-time-picker
                  [(ngModel)]="newSelectionFormFiedls['time']"
                  name="time"
                  nzFormat="hh:mm a"
                  nzPlaceHolder=" ">
                </nz-time-picker>
            </div>
          </div>
        <ng-container *ngIf="View_Display === true">
          <div nz-row *ngIf="radioValue === 'B'">
            <div nz-col nzSpan="24">
                <label class="para-lable-selection">Schedule Item<span class="text-danger fw-bolder ms-1">*</span></label>
            </div>
          </div>
          <div nz-row *ngIf="radioValue === 'B'">
            <div nz-col nzSpan="20">
              <nz-select nzShowSearch [(ngModel)]="newSelectionFormFiedls.scheduleItemParameterId" name="scheduleItemParameterId"  (ngModelChange)="onFieldisComplete('scheduleItemParameterId', $event)">
                <nz-option *ngFor="let option of scheduleList"
                [nzLabel]="option.Title"
                [nzValue]="option.Id"></nz-option>
              </nz-select>
            </div>
            <div nz-col nzSpan="2" class="d-flex justify-content-center align-items-center">
              <span class="BT-Link famly-inn" (click)="onOpenScheduleItem(null)">Add</span>
            </div>
            <div nz-col nzSpan="2" class="d-flex justify-content-center align-items-center">
              <span
                [ngClass]="{ 'disabled': isEditButtonDisabled(), 'BT-Link': true, 'famly-inn': true }"
                (click)="!isEditButtonDisabled() && onOpenScheduleItem(newSelectionFormFiedls.scheduleItemParameterId)">
                Edit
              </span>
            </div>
          </div>
          <div nz-row *ngIf="radioValue === 'B'">
            <div nz-col nzSpan="24">
              <label class="para-lable-selection">Due</label>
            </div>
          </div>
          <div nz-row [nzGutter]="3" *ngIf="radioValue === 'B'">
            <div nz-col nzSpan="4">
              <nz-input-number
              [nzMax]="999"
              [(ngModel)]="newSelectionFormFiedls.dueNumberDays"
              (ngModelChange)="onDaysChange($event); onFieldScheduleItem()"
              (input)="onFieldScheduleItem()"
              (keydown)="validateNumberInput($event)"
              name="dueNumberDays"
            ></nz-input-number>

            </div>
            <div nz-col nzSpan="2">
              <label class="famly-inn">Days</label>
            </div>
            <div nz-col nzSpan="8" *ngIf="radioValue === 'B'">
              <nz-radio-group [(ngModel)]="DaysradioValue" (ngModelChange)="DaysradioButtons()">
                <div nz-row>
                  <div nz-col nzSpan="12">
                    <label nz-radio nzValue="A" (change)="onFieldScheduleItem()"><span class="famly-inn">Before</span></label>
                  </div>
                  <div nz-col nzSpan="12">
                    <label nz-radio nzValue="B"  (change)="onFieldScheduleItem()" ><span class="famly-inn">After</span></label>
                  </div>
                </div>
              </nz-radio-group>
            </div>
          </div>
          <div nz-row [nzGutter]="10">
            <div nz-col nzSpan="6">
              <div nz-row *ngIf="radioValue === 'B'">
                <div nz-col nzSpan="24">
                  <label class="para-lable-selection">Due Date</label>
                </div>
              </div>
              <ng-container *ngIf="ScheduleItem_start_end_On === true">
                <div nz-row *ngIf="radioValue === 'B'">
                  <div nz-col nzSpan="24">
                      <div class="ValueDisplay">{{ newSelectionFormFiedls.dedlineOn | date: 'mediumDate' }}</div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="ScheduleItem_start_end_On === false">
                <div nz-row *ngIf="radioValue === 'B'">
                  <div nz-col nzSpan="24">
                      <div class="ValueDisplay">{{ dueDate || '--' }}</div>
                  </div>
                </div>
              </ng-container>
            </div>
            <div nz-col nzSpan="6">
              <div nz-row *ngIf="radioValue === 'B'">
                <div nz-col nzSpan="24">
                  <label class="para-lable-selection">Time</label>
                </div>
              </div>
              <div nz-row >
                <div nz-col nzSpan="24" *ngIf="radioValue === 'B'">
                  <nz-time-picker
                  [(ngModel)]="newSelectionFormFiedls['time']"
                  name="time"
                  nzFormat="hh:mm a"
                  nzPlaceHolder=" ">
                </nz-time-picker>
                </div>
              </div>
          </div>
          </div>




        </ng-container>
    </div>
  </div>

</nz-card>

<ng-template #extraTemplate>
  <ng-container>
    <div class="status-Color status-tag" *ngIf="Approvehide?.selectionChoiceParameterStatus || selectionResponses?.StatusName || selectionStatusS?.length > 0" [ngClass]="statusClass">
      {{ Approvehide?.selectionChoiceParameterStatus || selectionResponses?.StatusName || selectionStatusS }}
    </div>
  </ng-container>
</ng-template>







<div class="card-container mt-2">
  <nz-tabset nzType="card">
    <nz-tab *ngFor="let tab of selectioTabs" [nzTitle]="selectioninnercardTabs(tab)">

      <ng-container *ngIf="tab === 1">
        <!-- <app-selection-choice></app-selection-choice> -->
        <div class="row mb-5 mt-3" *ngIf="!selectionReponse && !newSelectionId">
          <div class="col-12">
            <div class="row">
              <div class="col text-center mt-2">
                <nz-empty [nzNotFoundContent]="CustomContent"></nz-empty>
                <ng-template #CustomContent>
                  <div class="row">
                    <div class="col text-center">
                      <span class="pira-label">Adding choice will be available after saving</span>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
        <nz-card *ngIf="selectionReponse || newSelectionId > 0" [nzSize]="'small'" nzBorderless="true" [nzTitle]="Choices" [nzExtra]="ChooiceTabTemplate">
          <ng-template #Choices>
            <h5 class="famly-inn Card-Title-Set">Selection Choices</h5>
          </ng-template>
          <div class="row">
            <div class="col-12">
              <label class="selectionCheckBoxSelection" nz-checkbox>Allow Multiple Selected Choices</label>
            </div>
          </div>
          <nz-card class="" [nzSize]="'small'" nzBorderless="true" [nzTitle]="ChoiceOrdering">
            <ng-template #ChoiceOrdering>
              <h5 class="para-lable-selection">Choice Ordering</h5>
              <nz-button-group class="me-2" [nzSize]="size">
                <button (click)="showDeleteConfirm()" nz-button nzType="default">
                  Auto
                </button>
                <button nz-button nzType="default">
                 Manual
                </button>
              </nz-button-group>
            </ng-template>
            <div class="row">
              <div class="col-12 p-0 text-lg-end">
                <button [disabled]="!cardChecked" nz-button nz-dropdown [nzType]="'default'" [nzTrigger]="'click'"  [nzDropdownMenu]="menucheckedoptions">
                  Checked Options
                   <span nz-icon nzType="down"></span>
                 </button>
                 <nz-dropdown-menu #menucheckedoptions="nzDropdownMenu">
                   <ul nz-menu>
                     <li nz-menu-item>Copy Checked</li>
                     <li nz-menu-item *ngIf="selectionResponses?.StatusSetupId !== 441" (click)="ConfirmDelete()">Delete Checked</li>
                   </ul>
                 </nz-dropdown-menu>
              </div>
            </div>
            <div class="row mb-2" style="margin-left: -23px;">
              <div class="col-12">
                <label [(ngModel)]="selectAll" class="pira-label" nz-checkbox (change)="toggleSelectAll()">Select All</label>
              </div>
            </div>
            <!-- <div class="row">
              <div class="col-lg-6">
                <div>
                  <div class="card" (click)="openSelectionChoice($event)">
                    <div class="card-body">
                      <div class="text-center text-success">
                        <span role="img" aria-label="plus-circle" data-icon-name="BTIcon" type="plus-circle" class="anticon anticon-plus-circle" style="font-size: 24px;">
                          <svg viewBox="64 64 896 896" focusable="false" data-icon="plus-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                            <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm192 472c0 4.4-3.6 8-8 8H544v152c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V544H328c-4.4 0-8-3.6-8-8v-48c0-4.4 3.6-8 8-8h152V328c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v152h152c4.4 0 8 3.6 8 8v48z"></path>
                          </svg>
                        </span>
                      </div>
                      <h2 class="ant-typography text-center ant-typography-bold">
                        <span class="text-success">Create New Choice</span>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
            <div nz-row [nzGutter]="10" class="pt-2" style="margin-left: -10px;">
              <div nz-col nzSpan="24">
                <ng-container *ngIf="CNChoicesradioValue === 'A'; else Test">
                  <div class="row mb-3">
                    <div class="col-lg-6" style="padding-left: 0px;padding-right: 10px;">
                      <div *ngIf="selectionResponses?.StatusSetupId !== 441" class="card" (click)="openSelectionChoice($event,selectionResponses?.Id)">
                        <div class="card-body" >
                          <div class="text-center text-success" >
                            <span role="img" aria-label="plus-circle" data-icon-name="BTIcon" type="plus-circle" class="anticon anticon-plus-circle" style="font-size: 24px;">
                              <svg viewBox="64 64 896 896" focusable="false" data-icon="plus-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                                <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm192 472c0 4.4-3.6 8-8 8H544v152c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V544H328c-4.4 0-8-3.6-8-8v-48c0-4.4 3.6-8 8-8h152V328c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v152h152c4.4 0 8 3.6 8 8v48z"></path>
                              </svg>
                            </span>
                          </div>
                          <h2 class="ant-typography text-center ant-typography-bold">
                            <span class="text-success">Create New Choice</span>
                          </h2>
                        </div>
                      </div>
                  </div>
                    <div class="col-lg-6" *ngFor="let item of selectionData" style="padding-left: 0px;padding-right: 0px;">
                      <nz-card   [nzSize]="'small'"  [nzTitle]="CreateNewChoice" [nzExtra]="extraTemplate">
                        <ng-template #CreateNewChoice>
                          <label class="pira-label ms-2" nz-checkbox [(ngModel)]="cardChecked" (change)="onCardCheckboxChange()"></label>
                        </ng-template>
                        <div nz-row [nzGutter]="50" class="mt-3 ms-2">
                          <div nz-col nzSpan="10">
                            <div nz-row>
                              <div nz-col nzSpan="24" style="background-color: #f1f4fa;">
                                <!-- Check if there are any file details; if none, show fallback image -->
                                <div *ngIf="item.fileDetails && item.fileDetails.length > 0; else fallbackImage" class="Thumbnail--fallback" style="width: 100%; height: 157px; margin-left: 45px;">
                                  <!-- Loop through each file and display the image -->
                                  <div *ngFor="let file of item.fileDetails" class="Thumbnail--fallback Thumbnail--fallback-default" style="width: 100%; height: 150px;">
                                    <img
                                      alt="Thumbnail Image"
                                      [src]="isImageExtensionValid(file.extension) ? file.filePath : 'assets/warrantyImages/BTLOGOSELECTION.svg'"
                                      style="margin-left: -45px; height: 158px; width: 100%;"
                                      class="Thumbnail--BTLogo-light"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <!-- Fallback template when no file details are available -->
                            <ng-template #fallbackImage>
                              <div class="Thumbnail--fallback Thumbnail--fallback-default" style="width: 100%; height: 150px; margin-left: 45px;">
                                <img
                                  alt="Thumbnail Image"
                                  src="assets/warrantyImages/BTLOGOSELECTION.svg"
                                  style="margin-left: -45px; height: 158px; width: 100%;"
                                  class="Thumbnail--BTLogo-light"
                                />
                              </div>
                            </ng-template>


                            <div nz-row class="">
                              <div nz-col nzSpan="24" *ngIf="item?.allFilePaths?.length > 0">
                                <button
                                [nzTrigger]="'click'"
                                nz-button
                                class="text-primary border-0"
                                nz-dropdown
                                [nzDropdownMenu]="menu4"
                              >
                                <span class="text-primary">
                                  <svg viewBox="64 64 896 896" focusable="false" data-icon="paper-clip" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                                    <path d="M779.3 196.6c-94.2-94.2-247.6-94.2-341.7 0l-261 260.8c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0012.7 0l261-260.8c32.4-32.4 75.5-50.2 121.3-50.2s88.9 17.8 121.2 50.2c32.4 32.4 50.2 75.5 50.2 121.2 0 45.8-17.8 88.8-50.2 121.2l-266 265.9-43.1 43.1c-40.3 40.3-105.8 40.3-146.1 0-19.5-19.5-30.2-45.4-30.2-73s10.7-53.5 30.2-73l263.9-263.8c6.7-6.6 15.5-10.3 24.9-10.3h.1c9.4 0 18.1 3.7 24.7 10.3 6.7 6.7 10.3 15.5 10.3 24.9 0 9.3-3.7 18.1-10.3 24.7L372.4 653c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0012.7 0l215.6-215.6c19.9-19.9 30.8-46.3 30.8-74.4s-11-54.6-30.8-74.4c-41.1-41.1-107.9-41-149 0L463 364 224.8 602.1A172.22 172.22 0 00174 724.8c0 46.3 18.1 89.8 50.8 122.5 33.9 33.8 78.3 50.7 122.7 50.7 44.4 0 88.8-16.9 122.6-50.7l309.2-309C824.8 492.7 850 432 850 367.5c.1-64.6-25.1-125.3-70.7-170.9z"></path>
                                  </svg>
                                  <span class="text-primary"> ({{ item?.fileCount }})</span>
                                </span>
                                <span nz-icon nzType="down"></span>
                              </button>
                              <nz-dropdown-menu #menu4="nzDropdownMenu">
                                <ul nz-menu>
                                  <li nz-menu-item *ngFor="let file of item?.allFilePaths" nzWidth="100px" style="white-space: pre-line;">
                                    {{ file.filePath }}
                                  </li>
                                </ul>
                              </nz-dropdown-menu>


                              </div>
                            </div>
                          </div>
                          <div nz-col nzSpan="14">
                            <div nz-row style="margin-left: -6px;">
                              <div nz-col nzSpan="24">
                                <div class="ant-row BTRow-xs" style="row-gap: 0px;">
                                  <div class="ant-col statusMessage">
                                  <nz-tag
                                  *ngIf="Approvehide?.selectionChoiceParameterStatus ||   item?.StatusName  || selectionStatusS?.length > 0 || isSetPriceLater || isRequestFromSubVendor"
                                  [ngClass]="statusClass">
                                 {{ Approvehide?.selectionChoiceParameterStatus || item?.StatusName || selectionStatusS }}
                                 </nz-tag>
                                 <span *ngIf="isSetPriceLater && item?.IsSetPriceLater">Price TBD</span>
                                 <span *ngIf="isRequestFromSubVendor && item?.IsRequestFromSubVendor">Sub/Vendor Price Needed</span>


                                  </div>

                                </div>
                              </div>
                            </div>

                            <div nz-row>
                              <div nz-col nzSpan="24">
                                <h3 class="set-add-heading-choice" (click)="FormEditForchoice(item?.Id)">
                                  {{item.Title}}</h3>
                              </div>
                            </div>
                            <div nz-row>
                              <div nz-col nzSpan="24">
                                <span class="set-value-dollar-choicecard">{{item?.OwnerPrice || '$0.00'}}</span>
                              </div>
                            </div>
                            <div nz-row>
                              <div nz-col nzSpan="24">
                                <p class="">{{item?.Description}}</p>
                              </div>
                            </div>
                            <div nz-row>
                              <div nz-col nzSpan="24">
                                <span
                                nz-tooltip
                                [nzTooltipTitle]="Approvalcomments"
                                [nzTooltipPlacement]="['topLeft', 'leftTop']"
                                [nzTooltipColor]="'black'"
                                role="img"
                                aria-label="message"
                                data-icon-name="BTIcon"
                                type="message"
                                class="anticon anticon-message BTPopover PopoverSpacing"
                                style="color: rgb(0, 79, 214);">
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="message" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                                  <path d="M464 512a48 48 0 1096 0 48 48 0 10-96 0zm200 0a48 48 0 1096 0 48 48 0 10-96 0zm-400 0a48 48 0 1096 0 48 48 0 10-96 0zm661.2-173.6c-22.6-53.7-55-101.9-96.3-143.3a444.35 444.35 0 00-143.3-96.3C630.6 75.7 572.2 64 512 64h-2c-60.6.3-119.3 12.3-174.5 35.9a445.35 445.35 0 00-142 96.5c-40.9 41.3-73 89.3-95.2 142.8-23 55.4-34.6 114.3-34.3 174.9A449.4 449.4 0 00112 714v152a46 46 0 0046 46h152.1A449.4 449.4 0 00510 960h2.1c59.9 0 118-11.6 172.7-34.3a444.48 444.48 0 00142.8-95.2c41.3-40.9 73.8-88.7 96.5-142 23.6-55.2 35.6-113.9 35.9-174.5.3-60.9-11.5-120-34.8-175.6zm-151.1 438C704 845.8 611 884 512 884h-1.7c-60.3-.3-120.2-15.3-173.1-43.5l-8.4-4.5H188V695.2l-4.5-8.4C155.3 633.9 140.3 574 140 513.7c-.4-99.7 37.7-193.3 107.6-263.8 69.8-70.5 163.1-109.5 262.8-109.9h1.7c50 0 98.5 9.7 144.2 28.9 44.6 18.7 84.6 45.6 119 80 34.3 34.3 61.3 74.4 80 119 19.4 46.2 29.1 95.2 28.9 145.8-.6 99.6-39.7 192.9-110.1 262.7z">
                                  </path>
                                </svg>
                              </span>
                              </div>
                            </div>
                          </div>
                        </div>

                      </nz-card>

                      <ng-template #extraTemplate>
                        <button
                        nz-button
                        nzType="default"
                        class="me-2"
                        (click)="OpenAproveStatus()"
                        *ngIf="selectionResponses?.StatusSetupId !== 441 && !isSetPriceLater"
                      >
                        <span style="color: rgb(6, 162, 96); margin-right: 5px; font-size: 15px;">
                          <svg viewBox="64 64 896 896" focusable="false" data-icon="check" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                            <path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"></path>
                          </svg>
                        </span>
                        Approve!
                      </button>


                      </ng-template>
                    </div>
                  </div>
                </ng-container>

                <ng-template #Test>

                    <div class="row">
                      <nz-table #basicTable [nzData]="selectionData" *ngIf="selectionData?.length > 0; else noDataTemplate">
                        <thead class="back-color-set">
                          <tr>
                            <th nzWidth="20px"><label nz-checkbox></label></th>
                            <th nzWidth="250px"></th>
                            <th>Choice</th>
                            <th>Details</th>
                            <th>Status / Alerts</th>
                            <th>Files</th>
                            <th>Price</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                            <!-- <tr  *ngFor="let card of cards">
                              <td nzWidth="20px"><label nz-checkbox></label></td>
                              <td nzWidth="250px">
                                <ng-container >
                                  <div class="Thumbnail--fallback" style="width: 100px; height: 100px;">
                                    <div class="Thumbnail--fallback Thumbnail--fallback-default" style="width: 100px; height: 100px;">
                                      <img alt="Buildertrend Logo"
                                      [src]="holdPath ? holdPath : 'data:image/svg+xml,%3c?xml%20version=%271.0%27%20encoding=%27UTF-8%27?%3e%3csvg%20id=%27Layer_1%27%20data-name=%27Layer%201%27%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20viewBox=%270%200%201000%201232%27%3e%3cdefs%3e%3cstyle%3e%20.cls-1%20{%20fill:%20%23acb8c3;}%20.cls-1,%20.cls-2%20{%20stroke-width:%200px;}%20.cls-2%20{%20fill:%20%230e0f10;}%20%3c/style%3e%3c/defs%3e%3cpath%20class=%27cls-2%27%20d=%27M411.001,230.943h-209.645V.334H0v637.164c0,.006.001.01.001.017.009,224.526,182.025,406.538,406.554,406.538s406.552-182.021,406.552-406.554c0-223.042-179.631-404.159-402.106-406.555ZM406.555,842.087c-112.992,0-204.588-91.598-204.588-204.589s91.595-204.588,204.588-204.588,204.588,91.597,204.588,204.588-91.598,204.589-204.588,204.589Z%27/%3e%3cpath%20class=%27cls-1%27%20d=%27M696.61,120.656l-40.89,70.823c156.484,87.466,262.307,254.732,262.307,446.74,0,282.479-228.995,511.474-511.474,511.474-95.891,0-185.603-26.413-262.302-72.324l-40.882,70.809c88.79,52.989,192.485,83.488,303.184,83.488,327.228,0,593.447-266.219,593.447-593.447,0-221.94-122.486-415.78-303.39-517.564Z%27/%3e%3c/svg%3e'" width="120px" height="90px" class="Thumbnail--BTLogo-light"
                                      >

                                    </div>
                                  </div>
                               </ng-container>
                              </td>
                              <td><h3 class="set-add-heading-choice">{{card?.title}}</h3></td>
                              <td><p class="">{{card?.description}}</p></td>
                              <td><nz-tag class="badge-set">UNRELEASED</nz-tag></td>
                              <td>
                                <button
                                [nzTrigger]="'click'"
                                nz-button
                                class="text-primary border-0"
                                nz-dropdown
                                [nzDropdownMenu]="menu4"
                              >
                                <i
                                  class="fa-solid fa-link text-primary me-"
                                ></i
                                ><span class="text-primary">
                                  <svg viewBox="64 64 896 896" focusable="false" data-icon="paper-clip" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M779.3 196.6c-94.2-94.2-247.6-94.2-341.7 0l-261 260.8c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0012.7 0l261-260.8c32.4-32.4 75.5-50.2 121.3-50.2s88.9 17.8 121.2 50.2c32.4 32.4 50.2 75.5 50.2 121.2 0 45.8-17.8 88.8-50.2 121.2l-266 265.9-43.1 43.1c-40.3 40.3-105.8 40.3-146.1 0-19.5-19.5-30.2-45.4-30.2-73s10.7-53.5 30.2-73l263.9-263.8c6.7-6.6 15.5-10.3 24.9-10.3h.1c9.4 0 18.1 3.7 24.7 10.3 6.7 6.7 10.3 15.5 10.3 24.9 0 9.3-3.7 18.1-10.3 24.7L372.4 653c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0012.7 0l215.6-215.6c19.9-19.9 30.8-46.3 30.8-74.4s-11-54.6-30.8-74.4c-41.1-41.1-107.9-41-149 0L463 364 224.8 602.1A172.22 172.22 0 00174 724.8c0 46.3 18.1 89.8 50.8 122.5 33.9 33.8 78.3 50.7 122.7 50.7 44.4 0 88.8-16.9 122.6-50.7l309.2-309C824.8 492.7 850 432 850 367.5c.1-64.6-25.1-125.3-70.7-170.9z"></path></svg>
                                <span class="text-primary">1</span>
                                </span>
                                <span nz-icon nzType="down"></span>
                              </button>
                              <nz-dropdown-menu #menu4="nzDropdownMenu">
                                <ul nz-menu>
                                  <li nz-menu-item>
                                    {{holdName}}
                                  </li>
                                </ul>
                              </nz-dropdown-menu>
                              </td>
                              <td>
                                <span class="set-value-dollar-choicecard">{{card?.ownerPrice}}</span>
                              </td>
                              <td>
                                <button nz-button nzType="default" class="me-2" (click)="ApproveStatus()">
                                  <span style="color: rgb(6, 162, 96);margin-right: 5px;font-size: 15px;"><svg viewBox="64 64 896 896" focusable="false" data-icon="check" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"></path></svg></span>
                                  Approve12
                                </button>
                              </td>
                            </tr> -->
                            <tr *ngFor="let item of selectionData">
                              <td nzWidth="20px"><label nz-checkbox [(ngModel)]="cardChecked" (change)="onCardCheckboxChange()"></label></td>
                              <td nzWidth="250px">
                                <ng-container >
                                  <div class="Thumbnail--fallback" style="width: 100px; height: 100px;">
                                    <div class="Thumbnail--fallback Thumbnail--fallback-default" style="width: 100px; height: 100px;">
                                      <!-- [src]="firstFilePath  ? firstFilePath :  'data:image/svg+xml,%3c?xml%20version=%271.0%27%20encoding=%27UTF-8%27?%3e%3csvg%20id=%27Layer_1%27%20data-name=%27Layer%201%27%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20viewBox=%270%200%201000%201232%27%3e%3cdefs%3e%3cstyle%3e%20.cls-1%20{%20fill:%20%23acb8c3;}%20.cls-1,%20.cls-2%20{%20stroke-width:%200px;}%20.cls-2%20{%20fill:%20%230e0f10;}%20%3c/style%3e%3c/defs%3e%3cpath%20class=%27cls-2%27%20d=%27M411.001,230.943h-209.645V.334H0v637.164c0,.006.001.01.001.017.009,224.526,182.025,406.538,406.554,406.538s406.552-182.021,406.552-406.554c0-223.042-179.631-404.159-402.106-406.555ZM406.555,842.087c-112.992,0-204.588-91.598-204.588-204.589s91.595-204.588,204.588-204.588,204.588,91.597,204.588,204.588-91.598,204.589-204.588,204.589Z%27/%3e%3cpath%20class=%27cls-1%27%20d=%27M696.61,120.656l-40.89,70.823c156.484,87.466,262.307,254.732,262.307,446.74,0,282.479-228.995,511.474-511.474,511.474-95.891,0-185.603-26.413-262.302-72.324l-40.882,70.809c88.79,52.989,192.485,83.488,303.184,83.488,327.228,0,593.447-266.219,593.447-593.447,0-221.94-122.486-415.78-303.39-517.564Z%27/%3e%3c/svg%3e'" -->
                                      <img alt="Persistent Logo"
                                      [src]="isImageExtensionValid(fileDetails[0]?.filePath) ? fileDetails[0]?.filePath : 'assets/warrantyImages/BTLOGOSELECTION.svg'"
                                      style=" height: 90px; width:100%; "
                                      class="Thumbnail--BTLogo-light"
                                      >
                                    </div>
                                  </div>
                               </ng-container>
                              </td>
                              <td><h3 class="set-add-heading-choice">{{item?.Title}}</h3></td>
                              <td><p class="">{{item?.Description}}</p></td>
                              <td><nz-tag class="badge-set">UNRELEASED</nz-tag></td>
                              <td>
                                <button
                                [nzTrigger]="'click'"
                                nz-button
                                class="text-primary border-0"
                                nz-dropdown
                                [nzDropdownMenu]="menu4"
                              >
                                <i
                                  class="fa-solid fa-link text-primary me-"
                                ></i
                                ><span class="text-primary">
                                  <svg viewBox="64 64 896 896" focusable="false" data-icon="paper-clip" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M779.3 196.6c-94.2-94.2-247.6-94.2-341.7 0l-261 260.8c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0012.7 0l261-260.8c32.4-32.4 75.5-50.2 121.3-50.2s88.9 17.8 121.2 50.2c32.4 32.4 50.2 75.5 50.2 121.2 0 45.8-17.8 88.8-50.2 121.2l-266 265.9-43.1 43.1c-40.3 40.3-105.8 40.3-146.1 0-19.5-19.5-30.2-45.4-30.2-73s10.7-53.5 30.2-73l263.9-263.8c6.7-6.6 15.5-10.3 24.9-10.3h.1c9.4 0 18.1 3.7 24.7 10.3 6.7 6.7 10.3 15.5 10.3 24.9 0 9.3-3.7 18.1-10.3 24.7L372.4 653c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0012.7 0l215.6-215.6c19.9-19.9 30.8-46.3 30.8-74.4s-11-54.6-30.8-74.4c-41.1-41.1-107.9-41-149 0L463 364 224.8 602.1A172.22 172.22 0 00174 724.8c0 46.3 18.1 89.8 50.8 122.5 33.9 33.8 78.3 50.7 122.7 50.7 44.4 0 88.8-16.9 122.6-50.7l309.2-309C824.8 492.7 850 432 850 367.5c.1-64.6-25.1-125.3-70.7-170.9z"></path></svg>
                                <span class="text-primary">({{ item.fileCount }})</span>
                                </span>
                                <span nz-icon nzType="down"></span>
                              </button>
                              <nz-dropdown-menu #menu4="nzDropdownMenu">
                                <ul nz-menu>
                                  <li nz-menu-item >
                                    {{item?.FilePath}}
                                  </li>
                                </ul>
                              </nz-dropdown-menu>
                              </td>
                              <td>
                                <span class="set-value-dollar-choicecard">{{item?.OwnerPrice}}</span>
                              </td>
                              <td>
                                <button nz-button nzType="default" class="me-2" (click)="OpenAproveStatus()"
                                *ngIf="!selectionResponses?.StatusName?.includes('Selected') && !isButtonDisabled"
                                >
                                  <span style="color: rgb(6, 162, 96);margin-right: 5px;font-size: 15px;"><svg viewBox="64 64 896 896" focusable="false" data-icon="check" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"></path></svg></span>
                                  Approve
                                </button>

                                <!-- settt  -->
                              </td>
                            </tr>
                        </tbody>
                      </nz-table>
                      <ng-template #noDataTemplate>
                        <nz-empty [nzNotFoundContent]="null"></nz-empty>
                      </ng-template>
                    </div>
                    <ng-template #extraTemplate>
                      <button nz-button nzType="default">Approve</button>
                    </ng-template>

                  <div class="col-lg-12">
                    <div class="card"   *ngIf="!selectionResponses?.StatusName?.includes('Selected') && !isButtonDisabled"  (click)="openSelectionChoice($event,selectionResponses?.Id)" style="height: 140px;">
                      <div class="card-body" style="padding-top: 30px;">
                        <div class="text-center text-success">
                          <span role="img" aria-label="plus-circle" class="anticon anticon-plus-circle" style="font-size: 24px;">
                            <svg viewBox="64 64 896 896" focusable="false" data-icon="plus-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                              <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm192 472c0 4.4-3.6 8-8 8H544v152c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V544H328c-4.4 0-8-3.6-8-8v-48c0-4.4 3.6-8 8-8h152V328c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v152h152c4.4 0 8 3.6 8 8v48z"></path>
                            </svg>
                          </span>
                        </div>
                        <h2 class="ant-typography text-center ant-typography-bold">
                          <span class="text-success">Create New Choice</span>
                        </h2>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
          </nz-card>
        </nz-card>
        <ng-template #ChooiceTabTemplate>

          <nz-button-group class="me-2" [nzSize]="size">
            <button (click)="CNChoicesradioButtons('A')" nz-button nzType="default">
              <span><span ><svg viewBox="64 64 896 896" focusable="false" data-icon="appstore" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M864 144H560c-8.8 0-16 7.2-16 16v304c0 8.8 7.2 16 16 16h304c8.8 0 16-7.2 16-16V160c0-8.8-7.2-16-16-16zm0 400H560c-8.8 0-16 7.2-16 16v304c0 8.8 7.2 16 16 16h304c8.8 0 16-7.2 16-16V560c0-8.8-7.2-16-16-16zM464 144H160c-8.8 0-16 7.2-16 16v304c0 8.8 7.2 16 16 16h304c8.8 0 16-7.2 16-16V160c0-8.8-7.2-16-16-16zm0 400H160c-8.8 0-16 7.2-16 16v304c0 8.8 7.2 16 16 16h304c8.8 0 16-7.2 16-16V560c0-8.8-7.2-16-16-16z"></path></svg></span></span>
            </button>
            <button (click)="CNChoicesradioButtons('B')" nz-button nzType="default">
              <span><span ><svg viewBox="64 64 896 896" focusable="false" data-icon="unordered-list" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M912 192H328c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h584c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 284H328c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h584c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 284H328c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h584c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM104 228a56 56 0 10112 0 56 56 0 10-112 0zm0 284a56 56 0 10112 0 56 56 0 10-112 0zm0 284a56 56 0 10112 0 56 56 0 10-112 0z"></path></svg></span></span>
            </button>
          </nz-button-group>
          <button class="me-2" nz-button nz-dropdown [nzType]="'primary'" [nzTrigger]="'click'"  [nzDropdownMenu]="menu4" *ngIf="selectionResponses?.StatusSetupId !== 441">
            Add Choices
            <span nz-icon nzType="down"></span>
          </button>
          <nz-dropdown-menu #menu4="nzDropdownMenu">
            <ul nz-menu>
              <li nz-menu-item (click)="openSelectionChoice($event,selectionResponses?.Id)">New</li>
              <li nz-menu-item>From Other Selection</li>
            </ul>
          </nz-dropdown-menu>
        </ng-template>
      </ng-container>
      <ng-container *ngIf="tab === 2">
        <div nz-row style="padding: 5px 5px 0px 18px;">
          <div nz-col nzSpan="24">
            <label class="para-lable-selection">Public Instructions</label>
          </div>
        </div>
        <div nz-row style="padding: 5px 15px 5px 15px;">
          <div nz-col nzSpan="24" class="editor-container">
          <ckeditor
            [(ngModel)]="newSelectionFormFiedls.publicInstructions"
            name="publicInstructions"
            [editor]="Editor"
            [config]="config"
            class="size"
            id="changeOrdereditor">
          </ckeditor>
          </div>
        </div>
        <div nz-row style="padding: 0px 0px 0px 18px;">
          <div nz-col nzSpan="24">
            <label class="para-lable-selection">Internal Notes</label>
          </div>
        </div>
        <div nz-row style="padding: 5px 15px 5px 15px;">
          <div nz-col nzSpan="24" class="editor-container">
            <ckeditor
            [(ngModel)]="newSelectionFormFiedls.internalNotes"
            name="internalNotes"
            [editor]="Editor"
            [config]="config"
            class="size"
            id="changeOrdereditor">
          </ckeditor>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="tab === 3">
      <!-- <app-selection-lock-and-unlock></app-selection-lock-and-unlock> -->
      <nz-card [nzSize]="'small'" [nzTitle]="locktitlemanage"  nzBordered="false">
        <ng-template #locktitlemanage>
            <span class="Card-Title-Set">Lock or Unlock This Selection</span>
        </ng-template>
        <div class="row">
          <div class="col">
         <span class="pira-label"> Approving any of the following chooice will unlock this Selection for approval</span>
        </div>
        </div>
        <div class="row">
          <div class="col mt-3 ">
           <button nz-button class="ant-btn-primary rounded-2" >Add/Edit Chooice</button>
          </div>
        </div>
        </nz-card>
      </ng-container>
      <ng-container *ngIf="tab === 4">
        <!-- <app-selection-files-discussion></app-selection-files-discussion> -->

        <!-- <nz-card nzBorderless="true" [nzTitle]="filestitle">
          <ng-template #filestitle>
            <h5  class="pira-label">Attachments</h5>
          </ng-template>
            <div class="row ">
              <div class="col-12 p-0 ">
                <button class="me-2 ms-2" nz-button (click)="uploadFiles()">Add</button>
                <button nz-button (click)="createNew()">Create New Doc</button>
                <button (click)="viewAll()" *ngIf="selectedFiles.length > 0" nz-button nzType="default"
                  class="rounded-1 me-2">View All ({{ selectedFiles.length }})</button>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-1 filUpload me-3" *ngFor="let file of selectedFiles">
                <div class="row gx-0 mt-2 p-0 m-0">
                  <div class="col-12 mt-2">
                    <span class="m-0 p-0d d-flex align-items-center justify-content-center"
                      style="border-radius: 6px; object-fit: cover;" nz-icon nzType="file-text"
                      nzTheme="outline"></span>
                  </div>
                </div>
                <div class="row p-0 m-0">
                  <div class="col-12">
                    <label class="ListViewJobname">{{file.name}}</label>
                  </div>
                </div>
                <div class="row p-0 m-0">
                  <div class="col-12">
                    <i nzTrigger="click" nzType="down" nz-dropdown [nzDropdownMenu]="menu4"
                      class="fa-solid fa-chevron-down fw-bolder"></i>
                    <nz-dropdown-menu #menu4="nzDropdownMenu">
                      <ul nz-menu>
                        <li nz-menu-item><i class="fa-solid fa-pen me-2"></i>Edit Online</li>
                        <li (click)="removeFile(file)" nz-menu-item><i
                            class="fa-regular fa-trash-can me-2"></i>Delete</li>
                      </ul>
                    </nz-dropdown-menu>
                  </div>
                </div>
              </div>
            </div>
          </nz-card> -->


<!-- Attachment Work -->

<nz-card [nzSize]="'small'" [nzTitle]="__TitleAttach" [nzBordered]="false">
  <ng-template #__TitleAttach>
    <span class="Card-Title-Set">Attachments</span>
  </ng-template>
  <div nz-row>
    <div nz-col nzSpan="24">
      <app-create-attachments-common [TestAttachment__]="TestAttachment" (filesUploaded)="onFilesUploaded($event)"></app-create-attachments-common>
    </div>
  </div>
</nz-card>
<ng-container *ngIf="SelectionHeaderId > 0; else MessagingComponent">
  <messaging-common
    [Job_ID]="jobID"
    [Messaging_ID]="Messaging_Id"
    [header_ID]="SelectionHeaderId"
    [Form_Name_ID]="formNameId"
  >
  </messaging-common>
</ng-container>
<ng-template #MessagingComponent>
  <nz-card [nzSize]="'small'" [nzTitle]="__TitleMessaging" [nzBordered]="false">
    <ng-template #__TitleMessaging>
      <span class="Card-Title-Set">Messaging</span>
    </ng-template>
    <div nz-row>
      <div nz-col nzSpan="24">
        <span class="Card-Title-Set" style="margin-left: -11px;">
          Messaging available after save
        </span>
      </div>
    </div>
  </nz-card>
</ng-template>


<!-- Attachment Work -->
          <!-- <nz-card nzBorderless="true" [nzTitle]="messagingtitle"  *ngIf="selectionReponse === -1">
            <ng-template #messagingtitle>
              <h5 class="pira-label">Messaging</h5>
            </ng-template>
            <div class="row">
              <div class="col">
                <h5 class="pira-label">Message available after save</h5>
              </div>
            </div>
          </nz-card>

           <ng-container *ngIf="selectionReponse">
            <messaging-common
            [Job_ID]="JobInfoId"
            [header_ID]="ChangeOrderInfoId"
            [Form_Name_ID]="formNameId"
            >
            </messaging-common>
          </ng-container> -->




      </ng-container>
      <ng-container *ngIf="tab === 5">
        <div class="row">
          <div class="col-12">
            <nz-card
             [nzSize]="'small'"
             [nzBordered]="false"
             [nzTitle]=" Ownertitle">
             <ng-template #Ownertitle>
                <span class="Card-Title-Set">Owner</span>
             </ng-template>
              <div class="row">
                <div class="col">
                  <span>{{selectionResponses?.CustomerName}}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-12 mt-2">
                  <label class="sub-text">Allowed to</label>
                </div>
                <nz-radio-group [(ngModel)]="radioValues">
                  <label [(ngModel)]="newSelectionFormFiedls['isApproveChoicesOnly']" name="isApproveChoicesOnly"  nz-radio nzValue="A" class="mt-2 ms-3">Approve Choices Only</label><br>
                  <label [(ngModel)]="newSelectionFormFiedls['isAddEditAndApproveChoices']" name="isAddEditAndApproveChoices" nz-radio nzValue="B" class="mt-2 ms-3">Add/Edit & Approve Choices</label>
                </nz-radio-group>
              </div>
            </nz-card>
            <nz-card [nzTitle]="Venderstitle" nzBordered="false">
              <ng-template #Venderstitle>
                <h5>Venders</h5>
              </ng-template>
              <div class="row">
                <div class="col-5 ">
                  <label class="sub-text">
                    Venders <i class="bi bi-info-circle-fill text-secondary  ms-1"></i>
                  </label>
                  <nz-tree-select  style="width: 100%" [(ngModel)]="newSelectionFormFiedls['createSelectionSubVenderParameterRequests']" name="createSelectionSubVenderParameterRequests"
                  [nzNodes]="vendersNodes" nzShowSearch nzAllowClear="false" nzCheckable nzCheckAll="true"
                  nzPlaceHolder="Select Status">
                </nz-tree-select>
                </div>
              </div>
              <div class="row ">
                <div class="col-12 mt-2">
                  <label class="sub-text">Allowed to</label>
                </div>
                <nz-radio-group [(ngModel)]="radioValuess">
                  <label [(ngModel)]="newSelectionFormFiedls['isApproveChoicesOnly']" name="isApproveChoicesOnly"  nz-radio nzValue="A" class="mt-2 ms-3">Approve Choices Only</label><br>
                  <label [(ngModel)]="newSelectionFormFiedls['isAddEditAndApproveChoices']" name="isAddEditAndApproveChoices" nz-radio nzValue="B" class="mt-2 ms-3">Add/Edit & Approve Choices</label>
                </nz-radio-group>
              </div>
              <!-- <div class="row mt-1">
                <label nz-radio nzValue="A">View Pricing /Request Only</label>
              </div>
              <div class="row mt-1">
                <label nz-radio nzValue="B">Add/Edit Choices</label>
              </div> -->
            </nz-card>
            <nz-card [nzTitle]="Installerstitle" nzBordered="false">
              <ng-template #Installerstitle>
                <h5>Installers</h5>
              </ng-template>
              <div class="row">
                <div class="col">
                  <label class="sub-text">
                    Installers <i class="bi bi-info-circle-fill text-secondary  ms-1"></i>
                  </label>
                  <div class="row">
                    <div class="col-5 mt-2">
                      <nz-tree-select  style="width: 100%" [(ngModel)]="newSelectionFormFiedls['createSelectionInstallerParameterRequests']" name="createSelectionInstallerParameterRequests"
                        [nzNodes]="installarNodes" nzShowSearch nzAllowClear="false" nzCheckable nzCheckAll="true"
                        nzPlaceHolder="Select Status">
                      </nz-tree-select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col mt-1">
                <label class="sub-text">Allowed to</label>
              </div>
              <div class="col mt-2">
                <label>View Only</label>
              </div>
            </nz-card>
          </div>
        </div>
      </ng-container>
    </nz-tab>
  </nz-tabset>
</div>
</div>
</div>
<ng-template #SelectionModelFooter>
  <div class="row">
    <div class="col-4">
     <span *ngIf="selectionReponse > 0">
      <span *ngIf="footerResponse">
        <app-footer-label
        [footerData]="footerResponse"
        ></app-footer-label>
      </span>
     </span>
    </div>
    <div class="col-8">
      <nz-space>
          <ng-container *nzSpaceItem>
            <button
              nz-button
              nzType="default"
              *ngIf="selectionResponses?.StatusSetupId === 441"
              [disabled]="DisabledNextActivity"
              (click)="resetVendorSelectedModal()"
            >
              Reset to Pending
            </button>
          </ng-container>
          <ng-container *nzSpaceItem>
            <button
            nz-button
            nzType="default"
            *ngIf="selectionResponses?.StatusSetupId !== 441 && selectionResponses"
            class=""
            (click)="deleteLeadConfirm()"
            [disabled]="DisabledNextActivity"
            [nzLoading]="isDeleteLoading"
          >
            Delete
          </button>
          </ng-container>
          <ng-container *nzSpaceItem>
            <button
            nz-button
            nzType="default"
            *ngIf="selectionResponses !== undefined"
            [disabled]="DisabledNextActivity"
          >
            Print
          </button>
          </ng-container>
          <ng-container *nzSpaceItem>
            <button
            nz-button
            nzType="primary"
            *ngIf="!isReleased && selectionResponses?.StatusSetupId !== 441"
            [disabled]="DisabledNextActivity"
            (click)="saveAndRelease()"
          >
            Save & Release
          </button>
        </ng-container>
        <ng-container *nzSpaceItem>
          <button
          nz-button
          [nzType]="getButtonType()"
          nzType="default"
          *ngIf="isReleased || selectionResponses?.StatusSetupId === 441"
          [disabled]="DisabledNextActivity"
          (click)="onUnrelease()"
        >
          Unrelease
        </button>
        </ng-container>
        <ng-container *nzSpaceItem>
          <button
          nz-button
          nzType="primary"
          [nzLoading]="isSaveAndNew"
          [disabled]="DisabledNextActivity"
          (click)="saveAndNewSelection()"
        >
          Save & New
        </button>
        </ng-container>
        <ng-container *nzSpaceItem>
          <button
          nz-button
          nzType="primary"
          [disabled]="!newSelectionFormFiedls.title"
          [nzLoading]="isSave"
          (click)="saveSelection()"
          [disabled]="isButtonDisableds"
          [disabled]="DisabledNextActivity"
        >
          Save
        </button>
        </ng-container>
        <ng-container *nzSpaceItem>
            <button
            nz-button
            [nzType]="getButtonType()"
            nzType="primary"
            [nzLoading]="isSaveAndClose"
            [disabled]="DisabledNextActivity"
            (click)="saveAndCloseSelection()"
          >
            Save & Close
          </button>
        </ng-container>

      </nz-space>
    </div>
  </div>
</ng-template>



</nz-modal>

<nz-modal [(nzVisible)]="isConfirmUnreleaseVisible" nzTitle="Unrelease Selection?" (nzOnCancel)="onCancelUnrelease()" (nzOnOk)="confirmUnrelease()">
  <p>Are you sure you want to reset this selection to unreleased?</p>
  <div nz-modal-footer>
    <button nz-button nzType="default" (click)="onCancelUnrelease()">Cancel</button>
    <button nz-button nzType="primary" (click)="confirmUnrelease()">Unrelease</button>
  </div>
</nz-modal>



<!-- new modal open for Approve Form  -->

<nz-modal nzWidth="35%" [(nzVisible)]="Approve" [nzTitle]="ApprovalComments" [nzFooter]="divsionTradeFooterdivsionTradeFooter"
  [nzBodyStyle]="{ background: '#f1f4fa', padding: '14px' }" [nzStyle]="{ top: '15px' }"
  (nzOnCancel)="OpenAproveStatuscancel()">
  <ng-template #ApprovalComments>
    <div class="row p-0 m-0">
      <h1 class="p-0 m-0">Approval Comments</h1>
    </div>
  </ng-template>
  <div class="content" *nzModalContent>
    <form [formGroup]="ApproveForm">
      <nz-card [nzBorderless]="false" nzTitle="Approval Comments">
        <div class="row">
          <div class="col">
            <div>
              <textarea
              formControlName="ApprovalComments"
              id="ApprovalComments"
              class="ant-input"
              style="height: 54px; min-height: 54px; max-height: 274px; overflow-y: hidden; resize: none;"
              spellcheck="false">
            </textarea>
            </div>
          </div>
        </div>
      </nz-card>
    </form>
  </div>
  <ng-template #divsionTradeFooterdivsionTradeFooter>
    <div class="mt-2 align-items-center">


      <button nz-button nzType="primary" class="rounded-1 me-0" (click)="handleApprove()" >
        Confirm Approval
      </button>
      <button nz-button nzType="default" class="rounded-1 me-0" (click)="OpenAproveStatuscancel()" >
        cancel
      </button>
    </div>
  </ng-template>
</nz-modal>

<!-- new modal open for Approve Form  -->
