<nz-modal
  nzWidth="50%"
  [(nzVisible)]="handleCancelLeadToJobModal"
  [nzTitle]="modalTitle"
  [nzFooter]="modalFooter"
  [nzBodyStyle]="{
    background: '#f1f4fa',
    padding: '14px',
    'max-height': '540px',
    'overflow-x': 'hidden',
    'overflow-y': 'auto'

  }"
  [nzStyle]="{ top: '15px' }"
  (nzOnCancel)="handleCancelLeadToJob()"
>
  <ng-template #modalTitle>
    <div class="row p-0 m-0">
      <h1 class="p-0 m-0">Lead To Job</h1>
    </div>
  </ng-template>
  <div class="content" *nzModalContent>
    <!-- <form > -->
    <nz-card class="mb-3" [nzTitle]="CardTitle">
      <ng-template #CardTitle>
        <div class="row p-0 m-0">
          <h3 class="p-0 m-0">Copy Lead Info to New Job</h3>
        </div>
      </ng-template>

      <div class="row">
        <div class="col-md-6">
          <div class="col-10 mb-2">
            <label class="sub-text">Job Type<strong class="text-danger">*</strong></label>
            <nz-select [style.width.%]="100" [(ngModel)]="leadToJobForm.jobTypeId" name="jobTypeId">
              <nz-option *ngFor="let jobType of jobTypeList" [nzLabel]="jobType.label" [nzValue]="jobType.value"></nz-option>
            </nz-select>
          </div>

          <div class="col-10 mb-3">
            <label class="sub-text">Contract Type</label>
            <nz-select [style.width.%]="100" [(ngModel)]="leadToJobForm.contractTypeId" name="contractTypeId">
              <nz-option
              *ngFor="let option of ContactType"
              [nzLabel]="option.name"
              [nzValue]="option.id"
            ></nz-option> </nz-select>

          </div>
          <div class="col-10 mb-3">
            <label
            [(ngModel)]="leadToJobForm.updateStatusToSold" name="updateStatusToSold"
              nz-checkbox
              [(ngModel)]="checked"
              (click)="handleCheckboxChange()"
              >Update Status to Sold</label
            >
          </div>

          <div class="col-10 mb-3" *ngIf="checkboxChecked">
            <div class="label-block">
              <label class="sub-text">Sold Date</label>
            </div>
            <div class="date-picker-block">
              <nz-date-picker
              [(ngModel)]="leadToJobForm.soldDate" name="soldDate"
                nzSuffixIcon="false"
                nzPlaceHolder=" "
              ></nz-date-picker>
            </div>
          </div>
        </div>

        <div class="col-md-6"></div>
      </div>
    </nz-card>

    <nz-card class="mb-3" [nzTitle]="CopyCardTitle">
      <ng-template #CopyCardTitle>
        <div class="row p-0 m-0">
          <h3 class="p-0 m-0">Copy Options</h3>
        </div>
      </ng-template>

      <div class="row" *ngIf="leadOpportunityDetail?.StreetAddress || leadOpportunityDetail?.Suburb || leadOpportunityDetail?.State || leadOpportunityDetail?.PostalCode">
        <div class="col-md-12">
          <label class="sub-text mb-2" >Address</label>

          <div class="col-12 mb-3">
            <label nz-checkbox [(ngModel)]="leadToJobForm.copyJobAddress" name="copyJobAddress">Copy Job Address</label>
          </div>
          <div class="col-12 mb-3 ms-4">
            <p class="famly-inn mb-2" *ngIf="leadOpportunityDetail?.StreetAddress">{{leadOpportunityDetail?.StreetAddress}}</p>

            <p class="famly-inn mb-2" *ngIf="leadOpportunityDetail?.Suburb">{{leadOpportunityDetail?.Suburb}}</p>
            <p class="famly-inn mb-2" *ngIf="leadOpportunityDetail?.State || leadOpportunityDetail?.PostalCode  ">{{leadOpportunityDetail?.State}} {{leadOpportunityDetail?.PostalCode}} </p>
          </div>
        </div>

        <!-- <div class="col-md-6"></div> -->
        <nz-divider></nz-divider>
      </div>


      <div class="row" *ngIf="selectedCustomer">
        <div class="col-md-12">
          <label class="sub-text mb-2">Contact</label>
          <nz-alert
            class="mb-3"
            nzType="warning"
            nzMessage="Selecting this contact will grant them access to the job. Please invite them so that they can log in to Persistent Solution."
          ></nz-alert>

          <div class="col-10 mb-3">
            <label nz-checkbox [(ngModel)]="leadToJobForm.useLeadCustomerContact" name="useLeadCustomerContact">Use Lead Customer Contact</label>
          </div>

          <div nz-row [nzGutter]="85" >
            <div nz-col nzSpan="1">
              <nz-avatar  [nzSize]="64" *ngIf="isDataAvailable(selectedCustomer)" [nzText]="getAvatarText()"></nz-avatar>
               <nz-avatar [nzSize]="64" *ngIf="!isDataAvailable(selectedCustomer)" [nzIcon]="'user'"></nz-avatar>
            </div>
            <div nz-col nzSpan="20">
              <div class="row">
                <div class="col-12">
                  <p class="famly-inn mb-1" *ngIf="selectedCustomer?.firstName || selectedCustomer?.lastName">
                    {{selectedCustomer?.firstName}} {{selectedCustomer?.lastName}}
                  </p>

                  <p class="famly-inn mb-1" *ngIf="selectedCustomer?.displayName">
                    {{selectedCustomer?.displayName}}
                  </p>
                  <p class="famly-inn mb-1" *ngIf="selectedCustomer?.streetAddress">
                    {{selectedCustomer?.streetAddress}}
                  </p>
                  <p class="famly-inn mb-1" *ngIf="selectedCustomer?.suburb">
                    {{selectedCustomer?.suburb}}
                  </p>
                  <!-- <p class="famly-inn mb-1" *ngIf="selectedCustomer?.state || selectedCustomer?.PostalCode">
                    {{selectedCustomer?.state}} {{selectedCustomer?.PostalCode}}
                  </p> -->
                  <p class="famly-inn mb-1" *ngIf="selectedCustomer?.phone">
                    Phone: <a class="text-primary">{{selectedCustomer?.phone}}</a>
                  </p>
                  <p class="famly-inn mb-1" *ngIf="selectedCustomer?.cellPhone">
                    Cell: <a class="text-primary">{{selectedCustomer?.cellPhone}}</a>
                  </p>
                  <p class="famly-inn mb-1" *ngIf="selectedCustomer?.primaryEmail">
                    Primary Email:
                    <i class="bi bi-info-circle-fill me-1 text-secondary" nz-popover [nzPopoverContent]="contentTemplate"
                      nzPopoverPlacement="top"></i>
                    <ng-template #contentTemplate>
                      <div class="rounded-2">
                        <p style="height: auto; width: 180px">
                          This email activates and maintains the account
                        </p>
                      </div>
                    </ng-template>
                    <a class="text-primary" *ngIf="selectedCustomer?.primaryEmail">{{selectedCustomer?.primaryEmail}}</a>
                  </p>
                  <p class="famly-inn mb-1"
                    *ngIf="selectedCustomer?.customerEmailParameters && selectedCustomer?.customerEmailParameters.length > 0">
                    Additional Email: <a class="text-primary">{{ selectedCustomer?.customerEmailParameters[0].email }}</a>
                  </p>

                </div>
              </div>
            </div>
          </div>

          </div>
      </div>
          <nz-divider></nz-divider>
          <nz-divider></nz-divider>

          <div class="row">
            <div class="col-md-12">
          <nz-radio-group class="mb-3" [(ngModel)]="leadToJobForm.selectedOption">
            <label nz-radio nzValue="Create new Contact in Xero">Create new Contact in Xero</label>
            <label nz-radio nzValue="Choose Xero Contact">Choose Xero Contact</label>
            <label nz-radio nzValue="Do not create a new Contact in Xero"
              >Do not create a new Contact in Xero</label
            >
          </nz-radio-group>

          <div class="row mb-3" *ngIf="leadOpportunitypyProposal && leadOpportunitypyProposal.length > 1">
            <div class="col-12 mb-3">
                <label class="sub-text">Proposal</label>
                <nz-select [style.width.%]="100" nzAllowClear="true" [(ngModel)]="leadToJobForm.proposalId" name="proposalId">

                    <nz-option *ngFor="let contractType of leadOpportunitypyProposal" [nzLabel]="contractType.title" [nzValue]="contractType.id"></nz-option>
                </nz-select>
            </div>

            <div class="selected-checkBoxs" *ngIf="leadToJobForm.proposalId">
              <div class="row  mb-3">
                <div class="col-12">
                <label nz-checkbox [(ngModel)]="leadToJobForm.copyProposalLineItemsToEstimate" name="copyProposalLineItemsToEstimate">
                  Copy Proposal Line Items to Estimate</label>
              </div>
              </div>
              <div class="row  mb-3">
                <div class="col-12">
                <label nz-checkbox [(ngModel)]="leadToJobForm.copyProposalFilesToDocsSection" name="copyProposalFilesToDocsSection">Copy Proposal Files to Docs Section</label>
              </div>

              </div>
              <div class="row  mb-3">
                <div class="col-12">
                <label nz-checkbox [(ngModel)]="leadToJobForm.copyProposalIntroductoryandClosingTextToEstimate" name="copyProposalIntroductoryandClosingTextToEstimate">Copy Proposal Introductory and Closing Text to Estimate</label>
              </div>

            </div>
          </div>
        </div>



      <nz-divider></nz-divider>


          <label class="sub-text mb-2">Files</label>

          <div class="row p-3">
            <label nz-checkbox [(ngModel)]="leadToJobForm.copyLeadFilesToDocsSection" name="copyLeadFilesToDocsSection">Copy Lead Files to Docs Section (5)</label>
          </div>

          <div class="col-10 p-2">
            <label nz-checkbox [(ngModel)]="leadToJobForm.showOwner" name="showOwner">Show Owner</label>
          </div>
          <div class="col-10 p-2">
            <label nz-checkbox [(ngModel)]="leadToJobForm.showSubs" name="showSubs">Show Subs</label>
          </div>
        </div>
      </div>
    </nz-card>
  <!-- </form> -->
  </div>
  <ng-template #modalFooter>
    <div class="row">
      <div class="col d-flex justify-content-end">
        <div>
          <button
            nz-button
            nzType="default"
            class="rounded-1 me-2"
            (click)="handleCancelLeadToJob()"
          >
            Cancel
          </button>
        </div>
        <div>
          <button type="submit" nz-button [disabled]="!leadToJobForm.jobTypeId" (click)="scratchModalOpen()" nzType="primary">Save</button>
        </div>
      </div>
    </div>
  </ng-template>
</nz-modal>

<app-loading-indicator></app-loading-indicator>
