<nz-modal [(nzVisible)]="isVisibleInSignature" [nzTitle]="InSignature" [nzFooter]="modalFooterInSignature" [nzBodyStyle]="{
  background: '#f1f4fa',
  padding: '14px',
  maxHeight: '69vh',
  'overflow-y': 'auto',
  'overflow-x': 'hidden',
}" [nzStyle]="{ top: '25px'}" [nzWidth]="'60%'" (nzOnCancel)="CancelSignature()">
  <ng-template #InSignature>
    <div class="row">
      <h2 class="p-0 m-0 famly-inn ant-typography-Modal">Signature</h2>
    </div>
  </ng-template>
  <div class="content" *nzModalContent>
    <nz-alert *ngIf="showErrorMessage" class="rounded-3 mb-3 mt-2 error-message" nzType="error" nzShowIcon [nzMessage]="message" [nzDescription]="errorDescriptionTemplate"></nz-alert>
    <ng-template #message>
      <h2 class="famly-inn ant-typography-crad-Header pt-2">Please correct the following fields:</h2>
    </ng-template>
    <ng-template #errorDescriptionTemplate>
      <ul>
        <li class="famly-inn p-0 m-0">
          <span class="d-flex align-items-center">
            <p class="p-0 m-0">Signature Canvas: Signature cannot be blank. Please draw your signature on the canvas.</p>
          </span>
        </li>
      </ul>
    </ng-template>
    <div class="card-container">
      <div class="card border-0 mt-0">
        <div class="card-head border-bottom">
          <div class="row">
            <ng-container *ngIf="Params === 'saveAndAndApprove'; else Declin">
              <h2 class="famly-inn ant-typography-crad-Header">Approve Change Order</h2>
            </ng-container>
            <ng-template #Declin>
              <h2 class="famly-inn ant-typography-crad-Header">Decline Change Order</h2>
            </ng-template>
          </div>
        </div>
        <div class="card-body">
          <div nz-row [nzGutter]="24">
            <div nz-col nzSpan="24">
              <label class="sub-text">Draw your signature<span class="text-danger fw-bolder ms-1">*</span></label>
            </div>
          </div>
          <div nz-row [nzGutter]="24">
            <div nz-col nzSpan="24">
              <canvas #canvasRef id="signatureCanvas" class="signature-canvas"
              (mousedown)="startDrawing($event)"
              (mousemove)="draw($event)"
              (mouseup)="stopDrawing(); CheckBoxTest()"
              (mouseleave)="stopDrawing()">
              </canvas>
            </div>
          </div>
          <div nz-row [nzGutter]="24">
            <div nz-col nzSpan="12">
              <label nz-checkbox [(ngModel)]="isSignatureSaved" [disabled]="!EnableUpdate" (ngModelChange)="UpdateSignature($event)">
                Save Signature
              </label>
            </div>
            <div nz-col nzSpan="12" class="d-flex justify-content-end align-content-center">
              <span class="famly-inn BT-Link" (click)="clearCanvas()">Clear</span>
            </div>
          </div>
          <div nz-row class="pt-3" *ngIf="showErrorMessage">
            <div nz-col nzSpan="24">
              <p class="famly-inn text-danger">Signature cannot be blank. Please draw your signature on the canvas.</p>
            </div>
          </div>
        </div>
      </div>  
    </div>
  </div>
  <ng-template #modalFooterInSignature>
    <div nz-row class="p-1 justify-content-end">
      <div nz-col nzSpan="24">
        <nz-space>
          <ng-container *nzSpaceItem>
            <button nz-button nzType="default" class="align-items-center" (click)="CancelSignature()">
              <span>Cancel</span>
            </button>
          </ng-container>
          <ng-container *nzSpaceItem>
            <ng-container *ngIf="Params === 'saveAndAndApprove'; else Declin">
              <button nz-button nzType="primary" class="align-items-center" (click)="Signature()">
                Approve
              </button>
            </ng-container>
            <ng-template #Declin>
              <button nz-button nzType="primary" class="align-items-center" (click)="Signature()">
                Decline
              </button>
            </ng-template>
          </ng-container>
        </nz-space>
      </div>
    </div>
  </ng-template>
</nz-modal>