import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Editor, EditorConfig } from 'src/app/helpers/ckeditor-config';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { LeadOpportunitiesService } from 'src/Service/lead-opportunities.service';
import { CompanychangeOrderPredifinedcodes } from 'src/app/shared/component/Models/LookUpStandardFilterCodes';
import { LookupNameSetupResponse } from 'src/Models/LeadTags';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ChangeOrderInformationService } from 'src/Service/Change_Order/change-order-information.service';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-change-orders',
  templateUrl: './change-orders.component.html',
  styleUrls: ['./change-orders.component.css']
})
export class ChangeOrdersComponent implements OnInit {
  @Output() cancel = new EventEmitter<void>();
  @Output()  changeOrderOnCompleted = new EventEmitter();
  VisibleInChangeOrderSetup: boolean = true;
  changeOrderFormFields: FormGroup;
  keywordOptions: LookupNameSetupResponse[] = [];
  PAGE_ID: number = 7;
  text: string | undefined;
  showInitialField: boolean = false;
  isSave = false;
  DisabledNextActivity: boolean = false;
  public Editor = Editor;
  public config = EditorConfig;

  preDefinedDataCodes = {
    ChangeorderCode: CompanychangeOrderPredifinedcodes.PMCORSOCO,
  };

  constructor(
    private fb: FormBuilder,
    private changeOrderInformationService: ChangeOrderInformationService,
    private lookUpFilterService: LookupFilterService,
    public toastsService: NzMessageService
  ) {}

  ngOnInit(): void {
    this.ChangeOrderSetupFormGroup();
    this.onGetAllRequiresignature();
    this.fetchChangeOrderForm();
  }
  ChangeOrderSetupFormGroup(): void {
    this.changeOrderFormFields = this.fb.group({
      globalId: ['00000000-0000-0000-0000-000000000000'],
      companyNameId: [1],
      changeOrderPrefix: [0],
      noOfDays: [0],
      maxItem: [0],
      allowOwnerChangeOrderRequestsByDefault: [false],
      showOwnerinternallyDeclinedChangeOrders: [false],
      showLineItemsToOwner: [false],
      requireSignatureOnChangeOrderSetupId: [0],
      changeOrderApprovalDisclaimer: ['I confirm that my action here represents my electronic signature and is binding.'],
      defaultChangeOrderDescription: [''],
      price: [false],
      discussions: [false],
      lineItems: [false],
      performingSubsVendors: [false],
      signatureLines: [false],
      customFields: [false],
    });
  }
  onGetAllRequiresignature() {
    this.lookUpFilterService.getLookUpValuesByFormNameId(this.PAGE_ID).subscribe(res => {
      let statusValues = res.result.filter(x => x.code === this.preDefinedDataCodes.ChangeorderCode.code);

      this.keywordOptions = statusValues
    });
  }
  fetchChangeOrderForm(): void {
    this.changeOrderInformationService.getCompanyChangeOrder().subscribe(res => {
      const changeOrder = res.result;
  
      if (changeOrder) {
        this.changeOrderFormFields.patchValue({
          changeOrderPrefix: changeOrder.changeOrderPrefix,
          noOfDays: changeOrder.noOfDays,
          maxItem: changeOrder.maxItem,
          allowOwnerChangeOrderRequestsByDefault: changeOrder.allowOwnerChangeOrderRequestsByDefault,
          showOwnerinternallyDeclinedChangeOrders: changeOrder.showOwnerinternallyDeclinedChangeOrders,
          showLineItemsToOwner: changeOrder.showLineItemsToOwner,
          requireSignatureOnChangeOrderSetupId: changeOrder.requireSignatureOnChangeOrderSetupId,
          changeOrderApprovalDisclaimer: changeOrder.changeOrderApprovalDisclaimer,
          defaultChangeOrderDescription: changeOrder.defaultChangeOrderDescription,
          price: changeOrder.price,
          discussions: changeOrder.discussions,
          lineItems: changeOrder.lineItems,
          performingSubsVendors: changeOrder.performingSubsVendors,
          signatureLines: changeOrder.signatureLines,
          customFields: changeOrder.customFields
        });
      }
    });
  }
  showFirstFieldy() {
    this.showInitialField = true;
  }
  closeModalInChangeOrderSetup(): void {
    this.VisibleInChangeOrderSetup = false;
    this.cancel.emit();
  }
  saveChangeOrder(actionType: 'save' = 'save'): Promise<void> {
   let data = this.changeOrderFormFields.value;
 
   return new Promise((resolve, reject) => {
       if (this.isSave) {
           return reject('Already processing');
       }
 
       if (actionType === 'save') {
           this.isSave = true;
       }
 
       this.DisabledNextActivity = true;
 
       this.changeOrderInformationService.PostChangeOrderSetup(data).subscribe(
           (response) => {
               this.changeOrderOnCompleted.emit(response.result);
               this.cancel.emit();
               this.DisabledNextActivity = false;
               this.isSave = false;
               resolve(); 
           },
           (error) => {
               console.error('Error saving data:', error);
               this.toastsService.error('An error has occurred. Please try again.');
               this.DisabledNextActivity = false;
               this.isSave = false;
               reject(error); 
           }
       ).add(() => {
           this.resetFlags();
       });
   });
  }
  resetFlags() {
    this.isSave = false;
  }
}
