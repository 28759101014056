import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NzStatus } from 'ng-zorro-antd/core/types';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { CostCode } from 'src/Models/CostCode/costCode';
import { JobInformationResponse } from 'src/Models/Job-Scratch/JobFromScratch';
import { LookupNameSetupResponse } from 'src/Models/LeadTags';
import { ITimeClockShiftGetById } from 'src/Models/_Time_Clock/_Time_Clock';
import { CostCodeService } from 'src/Service/Internaluser/cost-code.service';
import { InternalUserService } from 'src/Service/Internaluser/internal-user.service';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { JobInformationService } from 'src/Service/Job-List/Job-Information/job-information.service';
import { TimeClockSService } from 'src/Service/_Time_Clock/time-clock-s.service';
import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';
import { AddEditJobGroupComponent } from 'src/app/shared/component/Modal/add-edit-job-group/add-edit-job-group.component';

@Component({
  selector: 'app-time-clock-clock-in',
  templateUrl: './time-clock-clock-in.component.html',
  styleUrls: ['./time-clock-clock-in.component.css'],
})
export class TimeClockClockInComponent implements OnInit {
  @Output() ClockInUserOnCompleted = new EventEmitter<ITimeClockShiftGetById>();
  initializationComplete = new EventEmitter<boolean>();
  @Output() cancel = new EventEmitter<void>();
  @Input() ITimeClock_ClockInRes: ITimeClockShiftGetById;
  @Input() onJob_Id: number;
  @Input() argument: string;
  @Input() _globalelId: string;
  isVisibleInClockIn: boolean = true;
  ITimeClockShiftGetByIdRes: ITimeClockShiftGetById;
  jobList: JobInformationResponse[] = [];
  UserNodes: NzTreeNodeOptions[] = [];
  CostCodeList: CostCode[] = [];
  PAGE_ID: number = 11;
  TagNodes: NzTreeNodeOptions[] = [];
  onSaveTimeClockTag: any;
  timeClockFormFields: any = {};
  currentTime_: string;
  isSave: boolean = false;
  DisabledNextActivity: boolean = false;
  isSubmitted: boolean = false;
  validationStates: { [key: string]: NzStatus } = {};
  validationMessages: { [key: string]: string } = {};
  touchedFields: { [key: string]: boolean } = {};
  selectedIdsAsNumbers: number[] = [];
  TimingDifference: any;
  minutes: number;

  preDefinedDataCodes = {
    tags: { code: 'PMTCT' },
  };
  constructor(
    private _JobInformationService: JobInformationService,
    public _AccessLocalStorageService: AccessLocalStorageService,
    public _CostCodeService: CostCodeService,
    public _LookupFilterServices: LookupFilterService,
    private _InternalUserService: InternalUserService,
    public modal: NzModalService,
    public _TimeClockSService: TimeClockSService,
    private _NzMessageService: NzMessageService,
  ) {
    this.updateTime();
    setInterval(() => this.updateTime(), 1000);
  }
  updateTime() {
    const now = new Date();
    this.currentTime_ = now.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
    });
  }
  ngOnInit(): void {
    this.TimeClockFormGroup();
    this.GetAll();
    this.initLoad();
    if(this.ITimeClock_ClockInRes?.GlobalId?.length > 0){
      this.patchFormValues(this.ITimeClock_ClockInRes);
    }

    if(this.argument !== 'ClockIn'){
      if(this.ITimeClock_ClockInRes?.Id > 0){
        this.ClockInGetById(this.ITimeClock_ClockInRes?.Id);
      }
    }
    let on_start_Time = this._AccessLocalStorageService?.onGetStartTime()
    if(on_start_Time){
      this.startLiveBreakDifferenceUpdate(on_start_Time);
    }
  }
  TimeClockFormGroup(): void {
    this.timeClockFormFields = {
      globalId: '00000000-0000-0000-0000-000000000000',
      timeClockSetupId: 0,
      jobSetupId: 0,
      jobInformationId: 0,
      startOn: null,
      endOn: null,
      startTime: null,
      endTime: null,
      startDateModifiedOn: null,
      startDateModifiedBy: 0,
      endDateModifiedOn: null,
      endDateModifiedBy: 0,
      timeClockTagSetupId: 0,
      notes: '',
      shiftTypeSetupId: 0,
      shiftHours: 0,
      shiftMinutes: 0,
      overTimeHours: 0,
      overTimeMinutes: 0,
      doubleOvertimeHours: 0,
      doubleOvertimeMinutes: 0,
      costCodeId: 0,
      rate: '',
      hours: 0,
      statusSetupId: 0,
      builderCost: '',
      isClockIn: false,
      clockOn: null,    
      applicationUserIds: 0,
      createShiftTagParameterRequests: [],
      createShiftInformationBreakParameterRequests: []
    }
  }
  GetAll(){
    this._JobInformationService.onGetJobInformation().subscribe((res) => {
      this.jobList = res.result?.filter(ii =>ii?.statusSetupId === 10021);
      if(this.onJob_Id === null){
        this.timeClockFormFields.jobInformationId =  0
      } else {
        this.timeClockFormFields.jobInformationId =  this.onJob_Id
      }
    });
    this._CostCodeService.getCategoryData().subscribe(res => {
      this.CostCodeList = res.result?.filter(item => item?.timeClockLaborCode === true);
      this.timeClockFormFields.costCodeId = this.CostCodeList[0]?.id;
    });
  }
  initLoad() {
    this._InternalUserService.getData().subscribe((res) => {
      this.UserNodes = [
        {
          title: 'Check All',
          value: 'check_all',
          key: 'check_all',
          selectable: false,
          isLeaf: false,
          expanded: true,
          children: res?.result?.map((tags) => ({
            title: tags?.fullName,
            key: tags?.id.toString(),
            value: tags?.id.toString(),
            isLeaf: true,
          }))
        }
      ];
    });
    this._LookupFilterServices.getLookUpValuesByFormNameId(this.PAGE_ID).subscribe((res) => {
      let tags = res.result?.filter(
        (x) => x.code === this.preDefinedDataCodes.tags.code
      );

      this.TagNodes = [
        {
          title: 'Check All',
          value: 'check_all',
          key: 'check_all',
          selectable: false,
          isLeaf: false,
          expanded: true,
          children: tags?.map((tags) => ({
            title: tags?.name,
            key: tags?.id.toString(),
            value: tags?.id.toString(),
            isLeaf: true,
          }))
        }
      ];
      this.initializationComplete.emit(false);
    });
  }
  TimeClockTagOpen(selectedValue: LookupNameSetupResponse, argument: string) {
    this.onSaveTimeClockTag = {
      formNameId: 11,
      lookUpStandardId: 94,
      code: 'PMTCT',
      openArgument: argument,
    }
    const selectedId: number = Number(selectedValue); 
    const modalRef = this.modal.create({
      nzContent: AddEditJobGroupComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.onSaveTag = this.onSaveTimeClockTag;
    modalRef.componentInstance.selectedValue = selectedId;
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    modalRef.componentInstance.TagGroupOnCompleted.subscribe((data: LookupNameSetupResponse) =>{
      this.initLoad();
      if (typeof data === 'boolean') {
        if (data === true) {
            this.timeClockFormFields.createShiftTagParameterRequests = null;
        }
    } else {
        if (data.id > 0) {
          this.timeClockFormFields.createShiftTagParameterRequests = data.id.toString();
        } else {
          this.timeClockFormFields.createShiftTagParameterRequests = null;
        }
    }
  });
  }
  isEditButtonDisabled(): boolean {
    const selectedValues = this.timeClockFormFields.createShiftTagParameterRequests;
    return !selectedValues || (Array.isArray(selectedValues) && selectedValues.length !== 1);
  }
  CancelClockIn() {
    this.isVisibleInClockIn = false;
     this.cancel.emit();
  }
  saveClockIn(actionType: 'save' = 'save', setAsNewLead: boolean = false): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.isSave) {
        return reject('Already processing');
      }
  
      switch (actionType) {
        case 'save':
          this.isSave = true;
          break;
        default:
          this.isSave = true;
      }
  
      this.DisabledNextActivity = true;
      const userId = this._AccessLocalStorageService.getUserLoginId();
      this.timeClockFormFields.statusSetupId = 672;
      if(this.argument === 'ClockIn'){
        this.timeClockFormFields.isClockIn = true
        this.timeClockFormFields.startOn = new Date();
        this.timeClockFormFields.startTime = new Date();
        this.timeClockFormFields.endOn = null;
        this.timeClockFormFields.endTime = null;
        this.timeClockFormFields.globalId = '00000000-0000-0000-0000-000000000000';
        this.timeClockFormFields.applicationUserIds = [userId];
      } else if(this.argument === 'ClockOut'){
        this.timeClockFormFields.endOn = new Date();
        this.timeClockFormFields.endTime = new Date();
        this.timeClockFormFields.applicationUserIds = [userId];
        this.timeClockFormFields.globalId = this.ITimeClock_ClockInRes?.GlobalId;
        this.timeClockFormFields.isClockIn = false;
      }
      this.isSubmitted = true;
      this.checkErrors();
  
      if (Object.values(this.validationStates).includes('error')) {
        setTimeout(() => {
          const errorElements = document.querySelectorAll('.error-message');
          if (errorElements.length > 0) {
            const lastErrorElement = errorElements[errorElements.length - 1];
            lastErrorElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        }, 100);
      
        this.resetFlags();
        this.DisabledNextActivity = false;
        return reject('Validation errors present');
      }

      let selectedAssignees = Array.isArray(this.timeClockFormFields.createShiftTagParameterRequests)
      ? this.timeClockFormFields.createShiftTagParameterRequests.map((id: string) => ({ shiftTagSetupId: id }))
      : [];

      let formDataToSend = {
        ...this.timeClockFormFields,
        createShiftTagParameterRequests: selectedAssignees,
      };
      this._TimeClockSService.PostTimeClockIn(formDataToSend).subscribe(
        (response) => {
          this.ClockInUserOnCompleted.emit(response?.result);
          this.timeClockFormFields.applicationUserIds = response?.result?.ApplicationUserId?.toString();
          this.DisabledNextActivity = false;
          resolve(); 
        },
        (error) => {
          console.error('Error saving data:', error);
          this._NzMessageService.error('An error has occurred. Please try again.');
          this.DisabledNextActivity = false;
          reject(error);
        }
      ).add(() => {
        this.resetFlags();
        this.CancelClockIn();
        this.isSubmitted = false;
      });
    });
  }
  resetFlags(): void {
    this.isSave = false;
  }
  readonly validationRules = {
    jobInformationId: {
      required: 'Required',
      maxLength: {message: 'Job' }
    },
    applicationUserIds: {
      required: 'Required',
      maxLength: {message: 'Users' }
    },
    notes: {
      maxLength: { limit: 2500, message: 'Notes' }
     },
  };
  getFieldLabel(field: string): string {
    const labels: { [key: string]: string } = {
      jobInformationId: 'Job',
      applicationUserIds: 'Users',
      Notes: 'Notes',
    };
    return labels[field] || field;
  }
  getErrorTip(field: string): string {
   const rules = this.validationRules[field];
   const input = this.timeClockFormFields[field];
   const inputValue = typeof input === 'string' ? input.trim() : '';

   if (rules.required && !input) {
     return 'Required';
   }

    if (rules.maxLength && inputValue.length > rules.maxLength.limit) {
     const excessLength = inputValue.length - rules.maxLength.limit;
     const unit = excessLength === 1 ? 'character' : 'characters';
     return `${excessLength} ${unit} over.`;
   }

    return '';
  }
  get errorFields(): { field: string; label: string; message: string }[] {
    return Object.keys(this.validationStates)
      .filter(field => this.validationStates[field] === 'error')
      .map(field => ({
        field,
        label: this.getFieldLabel(field),
        message: this.validationMessages[field]
      }));
  }
  checkErrors() {
    this.validationStates = {};
    this.validationMessages = {};
  
    for (const field in this.validationRules) {
      if (this.validationRules.hasOwnProperty(field)) {
        if (this.touchedFields[field] || this.isSubmitted) {
          const errorTip = this.getErrorTip(field);
          if (errorTip) {
            this.validationStates[field] = 'error';
            this.validationMessages[field] = errorTip;
          } else {
            this.validationStates[field] = null;
            this.validationMessages[field] = '';
          }
        }
      }
    }
  }
  onFieldChange(field: string, value: any) {
    if (typeof value === 'string') {
      this.timeClockFormFields[field] = value.trim();
    } else {
      this.timeClockFormFields[field] = value;
    }
    this.touchedFields[field] = true;
    this.checkErrors();
    if (field === 'applicationUserIds') {
      this.onUserChanges(value);
    } 
    if(value?.length === 0){
      this.timeClockFormFields.applicationUserIds = 0
    }
  }
  onUserChanges(value: number[]) {
    this.selectedIdsAsNumbers = value.map(id => Number(id));
  }
  patchFormValues(ITimeClockShiftGetByIdRes: ITimeClockShiftGetById): void {
    this.timeClockFormFields = {
      globalId: ITimeClockShiftGetByIdRes?.GlobalId,
      applicationUserIds: ITimeClockShiftGetByIdRes?.ApplicationUserId,
      jobInformationId: ITimeClockShiftGetByIdRes?.JobInformationId,
      startOn: new Date(ITimeClockShiftGetByIdRes?.StartOn)  || null,
      endOn: null,
      startTime: new Date(ITimeClockShiftGetByIdRes?.StartTime) || null,
      endTime: null,
      notes: ITimeClockShiftGetByIdRes?.Notes || '',
      shiftTypeSetupId: ITimeClockShiftGetByIdRes?.ShiftTypeSetupId,
      shiftHours: ITimeClockShiftGetByIdRes?.ShiftHours,
      shiftMinutes: ITimeClockShiftGetByIdRes?.ShiftMinutes,
      overTimeHours: ITimeClockShiftGetByIdRes?.OverTimeHours,
      overTimeMinutes: ITimeClockShiftGetByIdRes?.OverTimeMinutes,
      doubleOvertimeHours: ITimeClockShiftGetByIdRes?.DoubleOvertimeHours,
      doubleOvertimeMinutes: ITimeClockShiftGetByIdRes?.DoubleOvertimeMinutes,
      costCodeId: ITimeClockShiftGetByIdRes?.CostCodeId,
      rate: ITimeClockShiftGetByIdRes?.Rate,
      hours: ITimeClockShiftGetByIdRes?.Hours,
      statusSetupId: ITimeClockShiftGetByIdRes?.StatusSetupId,
      builderCost: ITimeClockShiftGetByIdRes?.BuilderCost,
      createShiftTagParameterRequests: ITimeClockShiftGetByIdRes?.TagIds? ITimeClockShiftGetByIdRes.TagIds.split(',').map(id => id.trim()): [],
    };
    this.initializationComplete.emit(false);
  }
  // updateClockOut(actionType: 'save' = 'save',): Promise<void> {
  //   return new Promise((resolve, reject) => {
  //     if (this.isSave) {
  //       return reject('Already processing');
  //     }
  
  //     switch (actionType) {
  //       case 'save':
  //         this.isSave = true;
  //         break;
  //       default:
  //         this.isSave = true;
  //     }
  
  //     this.DisabledNextActivity = true;
  //     if(this.argument === 'ClockIn'){
  //       const userId = this._AccessLocalStorageService.getUserLoginId();
  //       this.timeClockFormFields.applicationUserIds = [userId];
  //     }
      
  //     this.isSubmitted = true;
  //     this.checkErrors();
  
  //     if (Object.values(this.validationStates).includes('error')) {
  //       setTimeout(() => {
  //         const errorElements = document.querySelectorAll('.error-message');
  //         if (errorElements.length > 0) {
  //           const lastErrorElement = errorElements[errorElements.length - 1];
  //           lastErrorElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
  //         }
  //       }, 100);
      
  //       this.resetFlags();
  //       this.DisabledNextActivity = false;
  //       return reject('Validation errors present');
  //     }
  //     this._TimeClockSService.updateData(this.ITimeClock_ClockInRes?.GlobalId).subscribe(
  //       (response) => {
  //         this.ClockInUserOnCompleted.emit(response?.result);
  //         this.timeClockFormFields.applicationUserIds = response?.result?.applicationUserIds.toString();
  //         this.DisabledNextActivity = false;
  //         resolve(); 
  //       },
  //       (error) => {
  //         console.error('Error saving data:', error);
  //         this._NzMessageService.error('An error has occurred. Please try again.');
  //         this.DisabledNextActivity = false;
  //         reject(error);
  //       }
  //     ).add(() => {
  //       this.resetFlags();
  //       this.CancelClockIn();
  //       this.isSubmitted = false;
  //     });
  //   });
  // }
  ClockInGetById(_Id: number){
    if (_Id) {
      this._TimeClockSService.getDataIdChangeOrder(_Id).subscribe(
        (res) => {
          this.ITimeClockShiftGetByIdRes = res.result;
          this.startLiveTimeDifferenceUpdate(this.ITimeClockShiftGetByIdRes?.StartTime);
          this.patchFormValues(res.result);
        },
      )
    }
  }
  timeDifferenceInterval: any;
  breakDifferenceInterval: any;
  startLiveTimeDifferenceUpdate(startTime: string) {
    this.On_CulateTimeDifference(startTime);
  
    if (this.timeDifferenceInterval) {
      clearInterval(this.timeDifferenceInterval);
    }
  
    this.timeDifferenceInterval = setInterval(() => {
      this.On_CulateTimeDifference(startTime);
    }, 1000);
  }
  startLiveBreakDifferenceUpdate(startTime: string) {
    this.calculateTimeElapsed(startTime);
  
    if (this.breakDifferenceInterval) {
      clearInterval(this.breakDifferenceInterval);
    }
  
    this.breakDifferenceInterval = setInterval(() => {
      this.calculateTimeElapsed(startTime);
    }, 1000);
  }
  On_CulateTimeDifference(startTime: string) {
    const Test_Case = new Date(startTime);
    const diffMs = new Date().getTime() - Test_Case.getTime();
    const diffSeconds = Math.floor((diffMs / 1000) % 60);
    const diffMinutes = Math.floor((diffMs / (1000 * 60)) % 60);
    const diffHours = Math.floor(diffMs / (1000 * 60 * 60));
    this.TimingDifference = `${diffHours} ${diffMinutes} ${diffSeconds}`;
  }
  timeElapsed: string = '00.00';
  calculateTimeElapsed(_Timing: string) {
    const Test_Case = new Date(_Timing);
    const diffMs = new Date().getTime() - Test_Case.getTime();
    const diffSeconds = Math.floor((diffMs / 1000) % 60);
    const diffMinutes = Math.floor((diffMs / (1000 * 60)) % 60);
    const diffHours = Math.floor(diffMs / (1000 * 60 * 60));
    this.timeElapsed = `${diffHours} ${diffMinutes} ${diffSeconds}`;
  }
}