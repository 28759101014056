<div class="d-flex justify-content-center" style="background: rgb(255, 255, 255); min-height: 324.333px;">
    <div nz-row class="d-flex justify-content-center" style="padding-top: 20px; padding-bottom: 48px; row-gap: 0px;">
      <div nz-col [nzXs]="20" [nzSm]="16" [nzMd]="17" style="row-gap: 0px;">
        <div nz-row class="d-flex justify-content-center">
          <div nz-col [nzXs]="8" [nzLg]="7" [nzXl]="6">
            <img style="width: 78px;height: 78px;" class="text-dark" src="assets/header/icons/Variations.svg"/>
          </div>
        </div>
        <div nz-row class="d-flex justify-content-center" style="row-gap: 0px;text-align: center;">
          <div nz-col>
            <h2 class="famly-inn ant-typography-crad-Header" style="font-size: 24px;line-height: 38px;">Take control of the unexpected with Change Orders</h2>
          </div>
        </div>
        <div nz-row class="d-flex justify-content-center" style="row-gap: 0px;text-align: center;">
          <div nz-col>
            <span class="famly-inn" style="color: #202227;overflow-wrap: break-word;">Manage time, cost, and other project changes more efficiently with Change Orders.</span>
          </div>
        </div>
        <div nz-row class="d-flex justify-content-center" style="row-gap: 0px;margin-left: -4px;margin-right: -4px;margin-top: 24px !important;">
          <div nz-col style="padding-left: 4px; padding-right: 4px;">
            <button nz-button nzType="default">
              <i class="bi bi-box-arrow-up-right"></i>
              <span class="BTN-Font-Famliy ms-2">Learn How</span>
            </button>
          </div>
          <div nz-col class="text-center" style="padding-left: 4px; padding-right: 4px;">
            <button
              nz-button
              nzType="primary"
              class="align-items-center"
              >
              <span class="BTN-Font-Famliy">
                Add a Change Order
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>