import { Component, ViewChild, TemplateRef, Input, OnInit, ViewContainerRef, ChangeDetectorRef, SimpleChanges, OnChanges, EventEmitter, Output } from '@angular/core';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { CalendarOptions } from '@fullcalendar/core';
import { CompanySettingssalesPreDefineCodes } from 'src/app/shared/component/Models/LookUpStandardFilterCodes';
import { LookupNameSetupResponse } from 'src/Models/LeadTags';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { LeadActivityMetaData } from 'src/Models/LeadActivity/leadActivityClass';
import { LoadingIndicatorService } from 'src/shared/Shared-Services/loading-indicator.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { LeadOpportunitiesNewLeadOpportunitiesComponent } from '../Modal/lead-opportunities-new-lead-opportunities/lead-opportunities-new-lead-opportunities.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { LeadActivitiesService } from 'src/Service/Sales-Lead-Activities/lead-activities.service';
import { en_US, NzI18nService, zh_CN } from 'ng-zorro-antd/i18n';

@Component({
  selector: 'app-activity-full-calendar',
  templateUrl: './activity-full-calendar.component.html',
  styleUrls: ['./activity-full-calendar.component.css']
})
export class ActivityFullCalendarComponent implements OnInit, OnChanges {
  @Input() _LeadActivityMetaData_Res: LeadActivityMetaData[] = []; 
  @Output() On_updateIsComplete: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('calendar') calendarComponent!: FullCalendarComponent;
  @ViewChild('eventTemplate', { read: TemplateRef }) eventTemplate!: TemplateRef<any>;
  @Input() Testing: boolean;
  defaultCalendarView: LookupNameSetupResponse[] = [];
  CalendarWMY: number;
  CalendarDate = new Date();
  hideDetails: boolean = false;
  isLoading: boolean = false
  IsCompleted: boolean;
  preDefinedDataCodes = {
    defaultLeadActivityCalendarView: CompanySettingssalesPreDefineCodes.DefaultLeadActivityCalendarView,
  };
  activeDateElement: HTMLElement | null = null;
  on_LeadActivity: any[] = [];

  calendarOptions: CalendarOptions = {
    plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
    initialView: 'dayGridMonth',
    timeZone: 'UTC',
    dayMaxEvents: false,
    headerToolbar: {
      left: '',
      center: '',
      right: ''
    },
    events: [],
    eventContent: this.renderEventContent.bind(this),
  };
  constructor(
    private LookupFilterService: LookupFilterService,
    private viewContainerRef: ViewContainerRef,
    private cdr: ChangeDetectorRef,
    private _LoadingIndicatorService: LoadingIndicatorService,
    private _LeadActivitiesService: LeadActivitiesService,
    private _NzMessageService: NzMessageService,
    private _NzModalService: NzModalService,
    private i18n: NzI18nService,
  ) {}
  ngOnInit(): void {
    this._LoadingIndicatorService.show()
    this.isLoading = true;
    this.initLoad();
  }
  initLoad() {
    this.LookupFilterService.getLookUpValuesByFormNameId(20002).subscribe(
      (res) => {
        let lastValues = res.result.filter(
          (x) => x.code === this.preDefinedDataCodes.defaultLeadActivityCalendarView.code
        );
        this.defaultCalendarView = lastValues;
        this.CalendarWMY = this.defaultCalendarView[0]?.id;
        this._LoadingIndicatorService.hide()
        this.isLoading = false;
    });
  }
  renderEventContent(arg: any): any {
    this.updateActiveDateStyle(new Date());
    const viewType = this.calendarComponent.getApi().view.type;
    const event = arg.event;

    const view = this.viewContainerRef.createEmbeddedView(this.eventTemplate, { event, hideDetails: this.hideDetails });
    const div = document.createElement('div');
    div.appendChild(view.rootNodes[0]);

    return { domNodes: [div] };
  }
  onViewChange(event: any): void {
    let selectedView = '';
    const First_Case = this.defaultCalendarView.find(item => item?.id === event);
    if (First_Case.name === 'Month') {
        selectedView = 'dayGridMonth';
        this.hideDetails = false;
    } else if (First_Case.name === 'Week') {
        selectedView = 'timeGridWeek';
        this.hideDetails = false;
    } else if (First_Case.name === 'Day') {
        selectedView = 'timeGridDay';
        this.hideDetails = false;
    }
    if (this.calendarComponent) {
        this.calendarComponent.getApi().changeView(selectedView);
        this.updateCalendarOptions();
        this.triggerResize();
    }
  }
  updateCalendarOptions(): void {
    if (this.calendarComponent) {
      this.calendarComponent.getApi().setOption('events', this.calendarOptions.events);
    }
  }
  triggerResize(): void {
    if (this.calendarComponent) {
      this.calendarComponent.getApi().updateSize();
    }
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.triggerResize();
    });
  }
  updateActiveDateStyle(date: Date): void {
    if (this.activeDateElement) {
        this.activeDateElement.style.backgroundColor = '';
        this.activeDateElement.style.color = '';
        this.activeDateElement.style.borderRadius = '';
    }

    const newActiveDateElement = document.querySelector(
        `[data-date='${date.toISOString().split('T')[0]}'] .fc-daygrid-day-number`
    ) as HTMLElement;

    const todayCellElement = document.querySelector(
        `[data-date='${date.toISOString().split('T')[0]}'].fc-day-today`
    ) as HTMLElement;

    if (todayCellElement) {
        todayCellElement.style.backgroundColor = 'transparent';
    }

    if (newActiveDateElement) {
        newActiveDateElement.style.backgroundColor = '#004fd6';
        newActiveDateElement.style.setProperty('color', '#fff', 'important');
        newActiveDateElement.style.borderRadius = '50%';
        newActiveDateElement.style.display = 'inline-flex';
        newActiveDateElement.style.justifyContent = 'center';
        newActiveDateElement.style.alignItems = 'center';
        newActiveDateElement.style.width = '30px';
        newActiveDateElement.style.height = '30px';
        newActiveDateElement.style.margin = '5px 0';

        this.activeDateElement = newActiveDateElement;
        this.cdr.detectChanges();
      }
      // this.isLoading = false;
      // this._LoadingIndicatorService.hide();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['_LeadActivityMetaData_Res'] && this._LeadActivityMetaData_Res) {
      this.loadActivities(this._LeadActivityMetaData_Res);
    }
  }
  loadActivities(_LeadActivityMetaData_Res: LeadActivityMetaData[]): void {
    this.on_LeadActivity = _LeadActivityMetaData_Res.map(activity => ({
      title: activity?.LeadActivityTypeName,
      leadOpportunityTitle: activity?.LeadOpportunityTitle,
      date: activity?.ActivityOn,
      id: activity?.Id,
      backgroundColor: activity?.LeadActivityColorCollection,
      Icon: activity?.LeadActivityTypIconSource,
      extendedProps: {
        datestartTime: activity?.StartTime,
        dateEndTime: activity?.EndTime,
        title: activity?.LeadOpportunityTitle,
        assignee: activity?.AssignedUserName,
        globalId: activity?.GlobalId,
        leadOppId: activity?.LeadOpportunityId,
        IsMarkup: activity?.MarkComplete,
      }
    }));
    this.calendarOptions = {
      ...this.calendarOptions,
      events: this.on_LeadActivity,
    };
    if (this.calendarComponent) {
      const calendarApi = this.calendarComponent.getApi();
      calendarApi.removeAllEvents();
      calendarApi.addEventSource(this.on_LeadActivity);
      this.triggerResize();
    }
  }
  circleCheckBox(globalId: string, isComplete: boolean) {
    const progressPayload = {
      globalId: globalId,
      isCompleted: isComplete,
    };
    this._LeadActivitiesService.updateIsComplete(progressPayload).subscribe(
      (res) => {
        this.On_updateIsComplete.emit();
        if (progressPayload?.isCompleted === true) {
          this._NzMessageService.success('Lead Activity Marked Complete');
        } else if (progressPayload?.isCompleted === false) {
          this._NzMessageService.success('Lead Activity Marked Incomplete');
        }
      },
      () => {
        this._NzMessageService.error('Update failed! Please try again.');
      }
    );
  }
  leadOpportunityFormEdit(LeadOpId: number,LeadActiId: number ): void {
    const modalRef = this._NzModalService.create({
      nzContent: LeadOpportunitiesNewLeadOpportunitiesComponent,
      nzFooter: null
    });
    modalRef.componentInstance.leadOpportunityResponseId = LeadOpId;
    modalRef.componentInstance.leadActivityResponseId = LeadActiId;
    modalRef.componentInstance.JobMenuButton = true;
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  };
  onMonthChange(event: Date): void {
   if (this.calendarComponent) {
       const calendarApi = this.calendarComponent.getApi();
       calendarApi.gotoDate(event);
   }
  }
  goToToday(): void {
    if (this.calendarComponent) {
        const calendarApi = this.calendarComponent.getApi();
        calendarApi.gotoDate(new Date());
        this.CalendarDate = new Date();
    }
  }
  onPrevMonth(): void {
    const prevMonth = new Date(this.CalendarDate);
    prevMonth.setMonth(prevMonth.getMonth() - 1);
    this.CalendarDate = prevMonth;
    this.onMonthChange(this.CalendarDate);
  }
  onNextMonth(): void {
    const nextMonth = new Date(this.CalendarDate);
    nextMonth.setMonth(nextMonth.getMonth() + 1);
    this.CalendarDate = nextMonth;
    this.onMonthChange(this.CalendarDate);
  }
}