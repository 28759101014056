import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { CompanySettingsScheduleSetup, ScheduleSetup } from 'src/Models/CompanySettings/Schedule/PhaseSetup/ScheduleSetup';
import { ResponseModel, ResponseModelArray } from 'src/Models/responseMessage.model';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class ScheduleSetupService {
  private baseUrl: string = environment.apiUrl + 'ScheduleSetup/';


  constructor(private http: HttpClient) { }

  postData(data: ScheduleSetup): Observable<ScheduleSetup> {
    return this.http.post<any>(`${this.baseUrl}`, data);
  }

  getData(): Observable<ScheduleSetup[]> {
    return this.http.get<ScheduleSetup[]>(`${this.baseUrl}getAll`);
  }

  updateData(data: ScheduleSetup): Observable<ScheduleSetup> {
    return this.http.put<ScheduleSetup>(`${this.baseUrl}`, data);
  }

  deleteData(id: number): Observable<ScheduleSetup> {
    return this.http.delete<ScheduleSetup>(
      `${this.baseUrl}?globalId=${id}`
    );
  }
  getDataId(id: number): Observable<ScheduleSetup> {
    return this.http.get<ScheduleSetup>(
      `${this.baseUrl}getBy-Id?id=${id}`
    );
  }


  // Method to get schedule setup data by company
getScheduleDataByCompany(): Observable<ResponseModel<CompanySettingsScheduleSetup>> {
  return this.http
    .get<ResponseModel<CompanySettingsScheduleSetup>>(`${this.baseUrl}getData-ByCompany`)
    .pipe(catchError(this.handleError));
}

  // Generic error handling for HTTP requests
  private handleError(error: any): Observable<never> {
    console.error('An error occurred while fetching schedule setup data:', error);
    return throwError(() => new Error('Failed to load schedule setup data. Please try again later.'));
  }
}