<div
  class="card mt-0"
  style="
    border-radius: 0px 0px 4px 4px;
    border: 0;
    border-top: 1px solid #e2e7f0;
  "
>
  <div class="card-head">
    <div class="row">
      <div class="col-6">
        <h2 class="famly-inn ant-typography-crad-Header">RFIs</h2>
      </div>
      <div class="col-6 d-flex justify-content-end align-content-center">
        <button (click)="createRFI(null)" nz-button nzType="default">
          Create RFI
        </button>
      </div>
    </div>
  </div>
  <div class="card-body">
    <ng-container *ngIf="RFIsGetById_Response?.length > 0">
      <div class="card mt-3 border-0" *ngFor="let data of RFIsGetById_Response">
        <div class="BTLink underline" (click)="createRFI(data?.RFIId)">
          <div class="ant-card ant-card-bordered RfiContainer-Card">
            <div class="ant-card-body">
              <div class="FormattedStatus">
                <span style="font-size: 12px; color: rgb(78, 85, 95)"
                  >Created by {{ data?.UserName }}</span
                >
              </div>
              <div class="Status">
                <span style="font-size: 12px; color: rgb(78, 85, 95)">
                  <img
                    src="assets/ChangeOrderInformation/video.512x436.png"
                    class="Icon BTPopover PopoverSpacing"
                    alt=""
                  />
                  Due
                  <span style="white-space: nowrap">{{ data?.DueOn | date: 'MMMM d, yyyy' }}</span>
                </span>
              </div>
              <strong
                >test - {{ data?.AutoAssignNumber }}- {{ data?.Title }}</strong
              >
              <p>
                <span class="TextWrap"
                  ><div [innerHTML]="data?.Question"></div
                ></span>
              </p>
              <div
                class="CardSection CardSection-size-default CardSection-border CardSection-footer"
              >
                <span style="font-size: 12px; color: rgb(78, 85, 95)"
                  >{{ data?.TotalResponse }}Responses</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
