

<nz-layout>
  <nz-page-header>
    <nz-page-header-title>
      <h1 nz-typography>{{ getTabTitles(activeTab) }}</h1>
    </nz-page-header-title>
  </nz-page-header>

  <nz-page-header class="p-0">
    <nz-page-header-extra
      class="d-flex"
      *ngIf="
        listView.length === 0 &&
        activityView.length === 0 &&
        activityTemplates.length === 0 &&
        proposalTemplates.length === 0
      "
    >
      <nz-space class="mb-2">
        <nz-switch
          [(ngModel)]="switchValue"
          [hidden]="
            isFirstSwicth() ||
            isTwoSwicth() ||
            isSecondSwicth() ||
            isFourthSwicth() ||
            isThreeSwicth() ||
            isFifthSwicth()
          "
        ></nz-switch>
        <!-- saim start work || 4/27/2024 || Saim_Assign_Work -->
      </nz-space>
      <nz-space>
        <button
          (click)="LeadSettings()"
          nz-popover
          [nzPopoverContent]="Summary"
          nzPopoverPlacement="left"
          nz-button
          nzType="default"
          class="ms-2 "
          [hidden]="
            isTwoAddLeadSetting() ||
            isSixthAddLeadSetting() ||
            isSecondAddLeadSetting() ||
            isThreeAddLeadSetting() ||
            isFourthAddLeadSetting() ||
            isFifthAddLeadSetting()
          "
        >
          <i class="bi bi-gear-fill fs-6"></i>
        </button>
        <ng-template #Summary1>
          <div class="rounded-2">
            <p style="font-size: 13px">Lead Activity Settings</p>
          </div>
        </ng-template>
      </nz-space>
      <nz-space>
        <button
          (click)="LeadSettings()"
          nz-popover
          [nzPopoverContent]="Summary1"
          nzPopoverPlacement="left"
          nz-button
          nzType="default"
          class="ms-2 "
          [hidden]="
            isFirstAddLeadActivitySettings2() ||
            isSixthAddLeadActivitSettings2() ||
            isFourthAddLeadActivitSettings2() ||
            isFifthAddLeadActivitSettings2() ||
            isTwoAddLeadActivitSettings2()
          "
        >
          <i class="bi bi-gear-fill fs-6"></i>
        </button>
        <ng-template #Summary>
          <div class="rounded-2">
            <p style="font-size: 13px">Lead Settings</p>
          </div>
        </ng-template>
      </nz-space>

      <nz-space>
        <button
          (click)="LeadSettings()"
          nz-popover
          [nzPopoverContent]="Summary3"
          nzPopoverPlacement="left"
          nz-button
          nzType="default"
          class="ms-2 "
          [hidden]="
            isFirstAddLeadActivitySettings2() ||
            isThreeAddLeadActivitSettings2() ||
            isSixthAddLeadActivitSettings2() ||
            isSecondAddLeadActivitSettings2() ||
            isTwoAddLeadActivitSettings2()
          "
        >
          <i class="bi bi-gear-fill fs-6"></i>
        </button>
        <ng-template #Summary3>
          <div class="rounded-2">
            <p style="font-size: 13px">Lead Proposal Settings</p>
          </div>
        </ng-template>
      </nz-space>

      <!-- saim End work || 4/27/2024 || Saim_Assign_Work -->

      <nz-space>
        <nz-divider
          [hidden]="isTwodivider()"
          nzType="vertical"
          class="divder"
        ></nz-divider>

        <button
          nz-button
          nzType="default"
          class=""
          [hidden]="
            isTwoAddLearnMore() ||
            isThreeLearnMore() ||
            isFourthLearnMore() ||
            isFifthLearnMore() ||
            isSixthLearnMore()
          "
        >
          <i class="fas fa-graduation-cap"></i>&nbsp;&nbsp;Learn More
        </button>
        <button
          class=""
          nz-button
          nzType="default"
          (click)="exportToExcel()"
          [hidden]="isTwoAddExport() || isSixthAddExport()"
        >
          Export
        </button>
        <button
          class=""
          nz-button
          (click)="OpenImportModel()"
          nzType="default"
          [hidden]="
            isSecondImport() ||
            isFourthImport() ||
            isTwoImport() ||
            isThreeImport() ||
            isFifthImport() ||
            isSixthImport()
          "
        >
          Import
        </button>
        <button
          class=""
          nz-button
          nzType="default"
          (click)="leadOpportunityAddContactModalOpen()"
          [hidden]="
            isSecondAddContactToFromWebsite() ||
            isFourthAddContactToFromWebsite() ||
            isTwoAddContactToFromWebsite() ||
            isThreeAddContactToFromWebsite() ||
            isFifthAddContactToFromWebsite() ||
            isSixthAddContactToFromWebsite()
          "
        >
          Add Contact To From Website
        </button>
        <button
          class=""
          nz-button
          class=""
          nz-button
          nzType="default"
          (click)="leadOpportunityLogCompleted(true, false, 'ACTIVITY')"
          [hidden]="
            isFirstLogCompletedActivity() ||
            isFourthLogCompletedActivity() ||
            isThreeLogCompletedActivity() ||
            isFifthLogCompletedActivity() ||
            isSixthLogCompletedActivity()
          "
        >
          Log Completed Activity
        </button>
        <button
        class=""
        nz-button
        nzType="default"
        (click)="openFilter(Selected_Filter_Type, PAGE_ID)"
        [hidden]="isTwoAddFilter()"
      >
        <i class="bi bi-funnel"></i>&nbsp;&nbsp;Filter
      </button>

        <button
          class=""
          nz-button
          nzType="default"
          [hidden]="
            isFirstMapAll() ||
            isTwoMapAll() ||
            isSecondMapAll() ||
            isFourthMapAll() ||
            isThreeMapAll() ||
            isFifthMapAll()
          "
        >
          Map All
        </button>
        <button
          class=""
          nz-button
          nzType="primary"
          (click)="leadOpportunityNewLeadModalOpen()"
          [hidden]="
            isSecondTabActive() ||
            isFourthTabActive() ||
            isTwoTabActive() ||
            isThreeTabActive() ||
            isFifthTabActive()
          "
        >
          New Lead Opportunity
        </button>

        <button
          class=""
          nz-button
          nzType="primary"
          (click)="leadActivityDialogBoxOpen('ACTIVITY')"
          [hidden]="
            isFirstTabActive() ||
            isFourthTabActive() ||
            isThreeTabActive() ||
            isSixthAddLeadSetting() ||
            isFifthTabActive()
          "
        >
          Schedule New Activity
        </button>

        <button
          class=""
          nz-button
          nzType="primary"
          (click)="NewLeadTemplate()"
          [hidden]="
            isSecondTabActive() ||
            isFirstTabActive() ||
            isTwoTabActive() ||
            isFifthTabActive() ||
            isSixthAddLeadSetting() ||
            isSecondTabActive() ||
            isSecondTabActive() ||
            isFourthTabActive() ||
            isFifthTabActive()
          "
        >
          New Activity Template
        </button>

        <button
          class=""
          nz-button
          nzType="primary"
          (click)="leadActivityDialogBoxOpen('PROPOSAL')"
          [hidden]="
            isFirstTabActive() ||
            isSecondTabActive() ||
            isTwoTabActive() ||
            isThreeTabActive() ||
            isSixthAddLeadSetting() ||
            isFifthTabActive()
          "
        >
          New Proposal
        </button>
        <!-- (click)="NewProposalTemplate('NewProposalTemplate')" -->
        <button
          class=""
          nz-button
          nzType="primary"
          (click)="NewProposalTemplate()"
          [hidden]="
            isFirstTabActive() ||
            isSecondTabActive() ||
            isTwoTabActive() ||
            isFourthTabActive() ||
            isThreeTabActive() ||
            isSixthAddLeadSetting() ||
            isFourthTabActive()
          "
        >
          New Proposal Template
        </button>
      </nz-space>
    </nz-page-header-extra>

<nz-page-header-extra *ngIf="listView.length > 0" class="d-flex">
  <nz-space>
    <th class="d-flex align-items-center sub-text fs-6 p-0 m-0">
      {{ listView.length }}
      <div class="ms-2">selected</div>
    </th>
    <a (click)="new()" class="ms-2 d-flex align-items-center">Cancel</a>
    <button nz-button nzType="default" class="ms-2 ">Email</button>
    <button nz-button nzType="default" class="ms-1 ">Delete</button>
    <button nz-button nzType="default" class="ms-1 ">Print</button>
    <button nz-button nzType="default" class="ms-1 ">Import Activity</button>
    <button nz-button nzType="default" class="ms-1 ">Import</button>
    <button nz-button nzType="default" class="ms-1 ">Update Status</button>
    <button nz-button nzType="default" class="ms-1 ">Assign</button>
  </nz-space>
</nz-page-header-extra>






    <nz-page-header-extra *ngIf="activityView?.length > 0" class="d-flex">
      <nz-space>
        <th class="d-flex align-items-center sub-text fs-6 p-0 m-0">
          {{ activityView?.length }}
          <div class="ms-2">selected</div>
        </th>

        <a (click)="new()" class="ms-2 d-flex align-items-center"
          >Cancle</a>
        <button nz-button nzType="default" class="ms-1 " (click)="leadActivityDelete()">
          Delete
        </button>
        <button nz-button nzType="default" class="ms-1 " (click)="leadActivityAssignUser()" >
          Reassign
        </button>
        <button nz-button nzType="default" class="ms-1" (click)="assignDisplayColor()">
          Assign Display Color
        </button>
      </nz-space>
    </nz-page-header-extra>


    <nz-page-header-extra *ngIf="activityTemplates.length > 0" class="d-flex">
      <nz-space>
        <th class="d-flex align-items-center sub-text fs-6 p-0 m-0">
          {{ activityTemplates?.length }}
          <div class="ms-2">selected</div>
        </th>
        <a (click)="new()" class="ms-2 d-flex align-items-center"
          >Cancle</a
        >
        <button nz-button nzType="default" class="ms-1 ">
          Delete
        </button>
      </nz-space>
    </nz-page-header-extra>

    <nz-page-header-extra *ngIf="proposalTemplates.length > 0" class="d-flex">
      <nz-space>
        <th class="d-flex align-items-center sub-text fs-6 p-0 m-0">
          {{ proposalTemplates?.length }}
          <div class="ms-2">selected</div>
        </th>
        <a (click)="new()" class="ms-2 d-flex align-items-center"
          >Cancle</a
        >
        <button nz-button nzType="default" class="ms-1 ">
          Delete
        </button>
      </nz-space>
    </nz-page-header-extra>

  </nz-page-header>

  <nz-content class="custom-content">
    <div class="spinner-wrapper" *ngIf="isLoading">
      <nz-spin nzSimple [nzSpinning]="true" [nzSize]="'large'"></nz-spin>
    </div>

    <div class="container-fuild mb-4 m-3">
      <nz-tabset [nzSelectedIndex]="tab"
      (nzSelectedIndexChange)="selectTab($event)"
      nzType="card"
        [nzSelectedIndex]="activeTab"
        (nzSelectedIndexChange)="handleTabChange($event)"
      >
        <nz-tab
          *ngFor="let tab of tabs"
          [nzTitle]="getTabTitle(tab)"
          class="rounded-2"
        >
        <ng-container *ngIf="tab === 1">
          <kendo-grid
          [data]="LeadListViewData"
          [selectable]="{ mode: 'multiple', checkboxOnly: true }"
          [pageSize]="5"
          [resizable]="true"
          [reorderable]="true"
          width="100%"
          [sortable]="true"
          [style.maxHeight.px]="435"
          [height]="500"
          (selectionChange)="selectionChange($event)"
        >
          <kendo-grid-checkbox-column
            [width]="45"
            [headerClass]="{ 'text-center': true }"
            [class]="{ 'text-center': true }"
            [resizable]="false"
            [columnMenu]="false"
            [showSelectAll]="true"
          ></kendo-grid-checkbox-column>
              <!-- Specific Columns  Start -->
              <kendo-grid-column
                [minResizableWidth]="50"
                [maxResizableWidth]="600"
                [width]="70"
                [sticky]="false"
                [headerClass]="'pinned-header'"
              >
                <ng-template
                  kendoGridCellTemplate
                  let-dataItem
                  let-rowIndex="rowIndex"
                >
                  <div class="custom-tag-cell">
                    <nz-tag
                      [nzColor]="'#c4fae2'"
                      style="
                        color: #202227;
                        font-weight: 700;
                        line-height: 1;
                        border-radius: 12px;
                        line-height: 16px;
                        padding: 0.2em 0.6em;
                      "
                      >New</nz-tag
                    >
                  </div>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column
                [minResizableWidth]="50"
                [maxResizableWidth]="600"
                [width]="70"
                [sticky]="false"
                [headerClass]="'pinned-header'"
              >
                <ng-template
                  kendoGridCellTemplate
                  let-dataItem
                  let-rowIndex="rowIndex"
                >
                  <a
                    (click)="
                      listViewGridPlusIconLeadOpportunitiesNewLeadOpportunities(
                        dataItem.Id
                      )
                    "
                    class="justify-content-center align-items-center fs-5"
                    nz-icon
                    nzType="plus-circle"
                    nzTheme="fill"
                  ></a>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column
                [minResizableWidth]="50"
                [maxResizableWidth]="600"
                [width]="60"
                [sticky]="false"
                [headerClass]="'pinned-header'"
              >
                <ng-template
                  kendoGridCellTemplate
                  let-dataItem
                  let-rowIndex="rowIndex"
                >
                  <ng-container
                    *ngIf="dataItem?.CustomerInformationEmailAddress"
                  >
                    <a
                      class="justify-content-center align-items-center fs-6"
                      nz-icon
                      nzType="mail"
                      nzTheme="fill"
                      [href]="
                        'mailto:' + dataItem.CustomerInformationEmailAddress
                      "
                    ></a>
                  </ng-container>
                </ng-template>
              </kendo-grid-column>
              <!-- Specific Columns  End -->

              <!-- Other Columns -->
              <kendo-grid-column
                *ngFor="let column of displayedColumns; let i = index"
                [title]="column"
                [field]="column"
                [minResizableWidth]="250"
                [maxResizableWidth]="800"
                [width]="getColumnWidthSize[i]"
                [sticky]="isColumnSticky[i]"
                [headerClass]="'pinned-header'"
                [width]="240"
              >
                <ng-template
                  kendoGridHeaderTemplate
                  let-rowIndex="rowIndex"
                  let-columnIndex="columnIndex"
                >
                  <div class="d-flex align-items-center">
                    <span>{{ column }}</span>
                    <button
                      (click)="toggleColumnSticky(columnIndex)"
                      class="btn btn-link"
                    >
                      <i
                        class="bi"
                        [ngClass]="{
                          'bi-pin-fill': isColumnSticky[columnIndex],
                          'bi-pin': !isColumnSticky[columnIndex]
                        }"
                      ></i>
                    </button>
                  </div>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>
                  <!-- Conditionally display content based on column -->
                  <ng-container *ngIf="column === 'Opportunity Title'">
                    <a
                      class="text-over-Flow"
                      (click)="leadOpportunityFormEdit(dataItem.Id)"
                      >{{ dataItem?.Title }}</a
                    >
                  </ng-container>
                  <ng-container *ngIf="column === 'Created Date'">
                    {{ dataItem?.CreatedOn | date : "MMM d, yyyy, h:mm a" }}
                  </ng-container>

                  <ng-container *ngIf="column === 'Customer Contact'">
                    <a
                      class="text-over-Flow"
                      (click)="EditCustomer(dataItem?.CustomerInformationId)"
                      >{{ dataItem?.CustomerInformationName }}</a
                    >
                  </ng-container>

                  <ng-container
                    *ngIf="
                      column === 'Status' &&
                      statusColorMap[dataItem?.StatusSetupName]
                    "
                  >
                    <nz-tag
                      [nzColor]="statusColorMap?.[dataItem?.StatusSetupName]?.backgroundColor"
                      [ngStyle]="{
                        color:
                          statusColorMap[dataItem?.StatusSetupName]?.textColor
                      }"
                      style="
                        font-weight: 700;
                        line-height: 1;
                        border-radius: 12px;
                        line-height: 16px;
                        padding: 0.2em 0.6em;
                      "
                    >
                      {{ dataItem?.StatusSetupName }}
                    </nz-tag>
                  </ng-container>

                  <!-- //{{ dataItem?.CreatedOn | date: 'MMM d, yyyy' }} -->
                  <ng-container *ngIf="column === 'Age'">
                    <strong>({{ getAge(dataItem?.CreatedOn) }})</strong>
                  </ng-container>

                  <ng-container
                  *ngIf="column === 'Confidence'"
                  class="confidence-container"
                >
                  <div class="confidence-container">
                    <strong class="confidence-text"></strong>
                    <nz-progress
                      class="progress-bar"
                      [nzWidth]="100"
                      [nzPercent]="dataItem?.Confidence"
                    ></nz-progress>
                  </div>
                </ng-container>

                  <ng-container
                    *ngIf="column === 'Estimated Revenue Min'"
                    style="width: 300px"
                  >
                    {{
                      dataItem?.EstimatedRevenueFrom
                        | currency : "USD" : "symbol"
                    }}
                  </ng-container>

                  <ng-container
                    *ngIf="column === 'Estimated Revenue Max'"
                    style="width: 300px"
                  >
                    {{
                      dataItem?.EstimatedRevenueTo | currency : "USD" : "symbol"
                    }}
                  </ng-container>

                  <ng-container
                    style="width: 300px"
                    *ngIf="column === 'Last Contacted'"
                  >
                    <span *ngIf="dataItem?.CreatedOn">
                      {{ dataItem?.CreatedOn | date : "MMM d, yyyy" }} by
                      {{ dataItem?.CreatedByUser }}
                    </span>
                  </ng-container>

                  <ng-container
                    style="width: 300px"
                    *ngIf="column === 'Salesperson'"
                  >
                    <ng-container
                      *ngIf="dataItem?.LeadOpportunitySalesUsers?.length"
                    >
                      <ng-container
                        *ngFor="let user of dataItem.LeadOpportunitySalesUsers"
                      >
                        {{ user }}
                      </ng-container>
                    </ng-container>
                  </ng-container>

                  <ng-container style="width: 300px" *ngIf="column === 'Source'">
                    <ng-container *ngIf="dataItem?.LeadOpportunitySourceNames">
                      <ng-container *ngFor="let source of dataItem.LeadOpportunitySourceNames.split(',')">
                        <div>{{ source.trim() }}</div>
                      </ng-container>
                    </ng-container>
                  </ng-container>

                  <ng-container
                    style="width: 300px"
                    *ngIf="column === 'Project Type'"
                  >
                    {{ dataItem?.ProjectTypeSetupName }}
                  </ng-container>
                  <ng-container
                    style="width: 300px"
                    *ngIf="column === 'Proposal Status'"
                  >
                    <nz-tag
                      *ngIf="dataItem?.ProposalStatus?.length"
                      [nzColor]="'#c7d0d9'"
                      style="
                        color: #202227;
                        font-weight: 700;
                        line-height: 1;
                        border-radius: 12px;
                        line-height: 16px;
                        padding: 0.2em 0.6em;
                      "
                      >{{ dataItem?.ProposalStatus }}
                    </nz-tag>
                  </ng-container>

                  <!-- Feature Set Filed -->
                  <ng-container
                    style="width: 300px"
                    *ngIf="column === 'Activity links Clicked'"
                  >
                    {{ dataItem?.IsAutoAccess }}
                  </ng-container>

                  <ng-container
                    style="width: 300px"
                    *ngIf="column === 'Cell phone'"
                  >
                    {{ dataItem?.CustomerInformationCellPhone }}
                  </ng-container>
                  <ng-container
                    style="width: 300px"
                    *ngIf="column === 'State (Contact)'"
                  >
                    {{ dataItem?.CustomerInformationState }}
                  </ng-container>
                  <ng-container
                    style="width: 300px"
                    *ngIf="column === 'Street (Contact)'"
                  >
                    {{ dataItem?.CustomerInformationStreetAddress }}
                  </ng-container>
                  <ng-container
                    style="width: 300px"
                    *ngIf="column === 'Suburb (Contact)'"
                  >
                    {{ dataItem?.CustomerInformationSuburb }}
                  </ng-container>
                  <ng-container
                    style="width: 300px"
                    *ngIf="column === 'Zip Code (Contact)'"
                  >
                    {{ dataItem?.CustomerInformationZipCode }}
                  </ng-container>

                  <ng-container
                    style="width: 300px"
                    *ngIf="column === 'Email Address'"
                  >
                    {{ dataItem?.CustomerInformationEmailAddress }}
                    <!-- <ng-container *ngFor="let customerParam of dataItem?.customerInformation?.customerEmailParameters">
                      {{ customerParam?.email}}
                    </ng-container> -->
                  </ng-container>
                  <ng-container
                    *ngIf="column === 'Estimated Revenue'"
                    style="
                      width: 300px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    <span style="margin-right: 10px">
                      {{
                        dataItem?.EstimatedRevenueFrom
                          | currency : "USD" : "symbol"
                      }}
                    </span>
                    -
                    <span>
                      {{
                        dataItem?.EstimatedRevenueTo
                          | currency : "USD" : "symbol"
                      }}
                    </span>
                  </ng-container>

                  <!-- Files -->

                  <ng-container *ngIf="column === 'Files'">
                    <ng-container
                      *ngIf="
                        dataItem?.AttachmentCount &&
                        dataItem?.AttachmentCount > 0
                      "
                    >
                      <a
                        nz-button
                        nzType="text"
                        nz-dropdown
                        [nzDropdownMenu]="menu"
                        nzTrigger="click"
                      >
                        <span
                          nz-icon
                          nzType="paper-clip"
                          nzTheme="outline"
                        ></span>
                        {{ dataItem?.AttachmentCount }}
                        <span nz-icon nzType="down"></span>
                      </a>
                      <nz-dropdown-menu #menu="nzDropdownMenu">
                        <ul nz-menu>
                          <li nz-menu-item>1st menu item</li>
                          <li nz-menu-item>2nd menu item</li>
                          <li nz-submenu nzTitle="sub menu">
                            <ul>
                              <li nz-menu-item>3rd menu item</li>
                              <li nz-menu-item>4th menu item</li>
                            </ul>
                          </li>
                        </ul>
                      </nz-dropdown-menu>
                    </ng-container>
                  </ng-container>

                  <ng-container
                    style="width: 300px"
                    *ngIf="column === 'First Name'"
                  >
                    <a
                      class="text-decoration-none blue-text"
                      (click)="EditCustomer(dataItem?.CustomerInformation)"
                      >{{ dataItem?.CustomerInformationFirstName }}</a
                    >
                  </ng-container>
                  <ng-container
                    style="width: 300px"
                    *ngIf="column === 'Last Name'"
                  >
                    <a
                      class="text-decoration-none blue-text"
                      (click)="EditCustomer(dataItem?.CustomerInformation)"
                      >{{ dataItem?.CustomerInformationLastName }}</a
                    >
                  </ng-container>

                  <ng-container style="width: 300px" *ngIf="column === 'Map'">
                    <img
                      nz-popover
                      [nzPopoverContent]="Summary"
                      class="ViewableByIcon me-3"
                      src="assets/ProjectManagerSitesDiaries/map-marker-default.svg"
                      alt="Open in Maps"
                      style="height: 18px; width: 16px"
                    />
                    <ng-template #Summary>
                      <div class="rounded-2">
                        <p style="font-size: 13px">Mapped</p>
                      </div>
                    </ng-template>
                  </ng-container>

                  <ng-container
                    style="width: 300px"
                    *ngIf="column === 'Proposal Last Viewed'"
                  >
                    <ng-container
                      *ngIf="dataItem?.LeadProposals?.length === 1"
                      style="width: 300px"
                    >
                      <span>{{
                        dataItem?.ProposalLastViewed | date : "MMM d, yyyy"
                      }}</span>
                      <span class="ms-2">{{
                        dataItem?.ProposalLastViewed | date : "h:mm a"
                      }}</span>
                    </ng-container>
                  </ng-container>
                  <ng-container
                    style="width: 300px"
                    *ngIf="column === 'Next Activity'"
                  >
                    <ng-container style="width: 300px; color: red">
                      <span>{{
                        dataItem?.NextActivity | date : "MMM d, yyyy"
                      }}</span>
                      <span class="ms-2">{{ dataItem?.CreatedByUser }}</span>
                    </ng-container>
                  </ng-container>

                  <ng-container style="width: 300px" *ngIf="column === 'Notes'">
                    {{ dataItem?.Notes }}
                  </ng-container>

                  <ng-container
                    style="width: 300px"
                    *ngIf="column === 'State (Opportunity)'"
                  >
                    {{ dataItem?.State }}
                  </ng-container>
                  <ng-container
                    style="width: 300px"
                    *ngIf="column === 'Street (Opportunity)'"
                  >
                    {{ dataItem?.StreetAddress }}
                  </ng-container>
                  <ng-container
                    style="width: 300px"
                    *ngIf="column === 'Suburb (Opportunity)'"
                  >
                    {{ dataItem?.Suburb }}
                  </ng-container>
                  <ng-container
                    style="width: 300px"
                    *ngIf="column === 'Zip Code (Opportunity)'"
                  >
                    {{ dataItem?.PostalCode }}
                  </ng-container>
                  <ng-container style="width: 300px" *ngIf="column === 'Phone'">
                    {{ dataItem?.CustomerInformation?.Phone }}
                  </ng-container>
                  <ng-container
                    style="width: 300px"
                    *ngIf="column === 'Projected Sales Date'"
                  >
                    {{ dataItem?.ProjectedSalesDate | date : "MMM d, yyyy" }}
                  </ng-container>
                  <ng-container style="width: 300px" *ngIf="column === 'Tags'">
                    <ng-container *ngIf="dataItem?.LeadOpportunityTagNames">
                      <ng-container *ngFor="let tag of dataItem.LeadOpportunityTagNames.split(',')">
                        <nz-tag>{{ tag.trim() }}</nz-tag>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                  <!-- Default display for other columns -->
                  <ng-container
                    *ngIf="column !== 'Has Opportunity Been Contacted?'"
                  >
                    {{ dataItem[column] }}
                  </ng-container>
                </ng-template>
              </kendo-grid-column>
            </kendo-grid>

            <nz-footer class="custom-footer" *ngIf="tab === 1 && LeadListViewData?.length > 0">
              <div class="container-fluid">
                <div
                  class="row d-flex justify-content-between align-items-center"
                >
                  <div class="row mb-0 mt-2 p-2">
                    <div class="col">
                    <!-- sufyan Work -->
                      <nz-pagination
                      class="hide-buttons"
                      style="float: right"
                      [nzPageIndex]="currentPageListView"
                      [nzTotal]="totalRecords"
                      [nzPageSize]="pageSizeListView"
                      [nzShowSizeChanger]="true"
                      [nzPageSizeOptions]="pageSizeOptions"
                      (nzPageIndexChange)="onPageChangeListView($event)"
                      (nzPageSizeChange)="onPageSizeChangeListView($event)"
                      [nzShowTotal]="totalTemplate"
                      [nzSimple]="false"
                    ></nz-pagination>
                    <ng-template #totalTemplate let-total="total" let-range="range">
                      {{ range[0] }}-{{ range[1] }} of {{ total }} items
                    </ng-template>
                    <nz-pagination
                      *ngIf="!isAllDataShownListView()"
                      style="float: right"
                      [nzPageIndex]="currentPageListView"
                      [nzTotal]="totalRecords"
                      [nzPageSize]="pageSizeListView"
                      [nzShowSizeChanger]="true"
                      [nzPageSizeOptions]="pageSizeOptions"
                      (nzPageIndexChange)="onPageChangeListView($event)"
                      (nzPageSizeChange)="onPageSizeChangeListView($event)"
                      nzSimple
                    ></nz-pagination>
                    <!-- sufyan Work -->
                      <ng-container *ngIf="PAGE_ID">
                        <div class="row d-flex justify-content-start">
                          <!-- <div class="col-md-3">
                            <nz-select
                              (ngModelChange)="onFilterChange($event)"
                              [(ngModel)]="selectedValueControl"
                              nzShowSearch
                            >
                              <nz-option
                                *ngFor="
                                  let standard of getStandardViewFilterData
                                "
                                [nzLabel]="standard.name"
                                [nzValue]="standard.id"
                              ></nz-option>
                            </nz-select>
                          </div> -->
                          <div class="col-md-2 p-0">
                            <div class="input-group">
                              <nz-select
                              style="width: 100%"
                               [(ngModel)]="selectedValueControl"
                                name="standardFilter"
                                (ngModelChange)="onFilterChange($event)"
                                nzShowSearch
                                >
                                <nz-option  *ngFor="let standard of getStandardViewFilterData"
                                  [nzLabel]="standard.name + (standard.isSetAsDefault ? ' ' : '')"
                                  [nzValue]="standard.id"
                                  nzCustomContent>
                                  <div class="option-content">
                                    <!-- Customize content with icons and text -->
                                    <span *ngIf="standard.icon" nz-icon [nzType]="standard.icon"></span>
                                    <span>{{ standard.name }}</span>
                                  <span  *ngIf="standard.isSetAsDefault" class="status-text badge info margin-left-xss">Default</span>

                                  </div>
                                </nz-option>
                              </nz-select>
                            </div>
                          </div>

                          <div class="col-md-1 p-0">
                            <button
                              nz-button
                              class="border-0"
                              (click)="openStandardViewFilterComponent()"
                            >
                              <span
                               nz-popover
                                [nzPopoverContent]="contentTemplate"
                                [nzPopoverPlacement]="'top'"
                                class="text-primary text-dark fw-bold fs-5"
                                nz-icon
                                nzType="ellipsis"

                              ></span>
                            </button>

                          </div>
                          <div class="col-md-3 p-0">
                            <div class="mt-2" *ngIf="selectedFilter === 1">
                              <p>Your filter has unsaved changes.</p>
                            </div>
                            <div class="mt-2" *ngIf="selectedFilter === 0">
                              <p>Your filter has unsaved changes.</p>
                            </div>
                          </div>

                          <div class="col-md-3 p-0">
                            <button
                              (click)="
                                openStandardViewFilterComponent('saveAs')
                              "
                              type="button"
                              nz-button
                              nzType="default"
                              class="me-2"
                              style="font-size: 12px"
                              *ngIf="selectedFilter === 1"
                            >
                              Save As
                            </button>

                            <button
                              (click)="reInitReset()"
                              nz-button
                              nzType="default"
                              style="font-size: 12px"
                              *ngIf="selectedFilter === 1"
                              type="button"
                            >
                              Reset
                            </button>

                            <button
                              (click)="saveEditStandardView()"
                              nz-button
                              nzType="primary"
                              type="button"
                              class="me-2"
                              style="font-size: 12px"
                              *ngIf="selectedFilter === 0"
                            >
                              Save
                            </button>

                            <button
                              (click)="
                                openStandardViewFilterComponent('saveAs')
                              "
                              nz-button
                              nzType="default"
                              class="me-2"
                              style="font-size: 12px"
                              *ngIf="selectedFilter === 0"
                              type="button"
                            >
                              Save As
                            </button>

                            <button
                              (click)="reInitReset()"
                              nz-button
                              nzType="default"
                              style="font-size: 12px"
                              *ngIf="selectedFilter === 0"
                              type="button"
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                      </ng-container>

                    </div>
                  </div>
                </div>
              </div>
            </nz-footer>

          </ng-container>

          <ng-container *ngIf="tab === 2">

            <kendo-grid
            [selectable]="{ mode: 'multiple', checkboxOnly: true }"
            (columnResize)="saveColumnSizes($event)"
            [data]="getrfiUserDatas"
            kendoGridSelectBy="Id"
            [pageSize]="5"
            [resizable]="true"
            [reorderable]="true"
            width="100%"
            [sortable]="true"
            [style.maxHeight.px]="600"

            [(selectedKeys)]="ids"
           (selectionChange)="selectionChange($event)"
          >
            <kendo-grid-checkbox-column
            [width]="55"
            [headerClass]="{ 'text-center': true }"
            [class]="{ 'text-center': true }"
            [resizable]="false"
            [columnMenu]="false"
            [showSelectAll]="true"
          ></kendo-grid-checkbox-column>
              <kendo-grid-column
                *ngFor="let column of displayedColumns; let i = index"
                [title]="column"
                [field]="column"
                [minResizableWidth]="150"
                [maxResizableWidth]="800"
                [width]="getColumnWidthSize[i]"
                [sticky]="isColumnSticky[i]"
                [headerClass]="'pinned-header'"
              >
                <ng-template
                  kendoGridHeaderTemplate
                  let-columnIndex="columnIndex"
                >
                  <div class="d-flex align-items-center">
                    <span>{{ column }}</span>
                    <button
                      (click)="toggleColumnSticky(columnIndex)"
                      class="btn btn-link"
                    >
                      <i
                        class="bi"
                        [ngClass]="{
                          'bi-pin-fill': isColumnSticky[columnIndex],
                          'bi-pin': !isColumnSticky[columnIndex]
                        }"
                      ></i>
                    </button>
                  </div>
                </ng-template>
                <ng-template
                  kendoGridCellTemplate
                  let-dataItem
                  let-rowIndex="rowIndex"
                >
                <ng-container *ngIf="column === 'Status'">

                  <nz-tag
                  (click)="OnClickActivity(dataItem.Id)"
                  [nzColor]="statussetupColorMap?.[dataItem?.ActivityStatus]?.backgroundColor"
                  [ngStyle]="{
                    color:
                      statussetupColorMap[dataItem?.ActivityStatus]?.textColor
                  }"
                  style="
                    font-weight: 700;
                    line-height: 1;
                    border-radius: 12px;
                    line-height: 16px;
                    padding: 0.2em 0.6em;
                  "
                >
                  {{ dataItem?.ActivityStatus }}
                </nz-tag>
                </ng-container>

                  <ng-container *ngIf="column === 'Type'">
                    <div class="row gx-3">
                      <div class="col-md-3 mt-1">
                        <span
                          class="color-circle"
                          [style.background-color]="
                            dataItem?.LeadActivityTypeColorCollection
                          "
                        ></span>
                      </div>
                      <div class="col-md-9 mt-2">
                        <a
                          class="text-over-Flow"
                          (click)="OnClickActivity(dataItem.Id)"
                          >{{ dataItem?.LeadActivityTypeName }}</a
                        >
                      </div>
                    </div>

                    <div class="row iconset">
                      <div class="col-12">
                        <i
                          style="font-size: 16px; left: 24px; font-weight: 600;"
                          [class]="dataItem?.LeadActivityTypIconSource"
                        ></i>
                      </div>
                    </div>
                    <nz-tag
                    *ngIf="dataItem?.TypeStatus"
                    [nzColor]="typestatussetupColorMap[dataItem?.TypeStatus]?.backgroundColor"
                    [ngStyle]="{
                      color: typestatussetupColorMap[dataItem?.TypeStatus]?.textColor
                    }"
                    style="
                      vertical-align: middle;
                      text-align: center;
                      white-space: nowrap;
                      text-transform: uppercase;
                      padding: .2em .6em;
                      min-width: 16px;
                      line-height: 16px;
                      font-size: 10px;
                      font-weight: 700;
                      letter-spacing: .08em;
                      border-radius: 12px;
                    "
                  >
                    {{ dataItem?.TypeStatus }}
                  </nz-tag>



                  </ng-container>
                  <ng-container *ngIf="column === 'Opportunity Title'">
                    <a
                      class="text-over-Flow"
                      (click)="
                        leadOpportunityFormEdit(dataItem.LeadOpportunityId)
                      "
                      >{{ dataItem?.LeadOpportunityTitle }}</a
                    >
                  </ng-container>
                  <ng-container *ngIf="column === 'Assigned User'">
                    <div class="text-over-Flow">
                      {{ dataItem?.AssignedUserName }}
                      <!-- {{ dataItem?.assignedUser?.fullName }}{{ dataItem?.assignedUser?.middleName }}{{ dataItem?.assignedUser?.lastName }} -->
                    </div>
                  </ng-container>
                  <ng-container *ngIf="column === 'Attendees'">
                    <div class="text-over-flow">
                      <ng-container *ngIf="isAttendeesExpanded(dataItem.Id); else showLimited">
                        <span *ngFor="let attendee of dataItem?.AttendeeNames?.split(',') || []; let i = index">
                          {{ attendee }}
                          <span *ngIf="i !== dataItem?.AttendeeNames?.split(',').length - 1">, </span>
                        </span>
                      </ng-container>
                      <ng-template #showLimited>
                        <span *ngFor="let attendee of dataItem?.AttendeeNames?.split(',')?.slice(0, 1) || []; let i = index">
                          {{ attendee }}
                          <span *ngIf="i !== 1 && i < dataItem?.AttendeeNames?.split(',').length - 1">, </span>
                        </span>
                      </ng-template>
                    </div>

                    <!-- Show button only if there are more than 2 attendees -->
                    <ng-container *ngIf="dataItem?.AttendeeNames && dataItem?.AttendeeNames.split(',').length > 2">
                      <button
                        (click)="toggleAttendees(dataItem?.Id)"
                        nz-button
                        nzType="default"
                        [ngStyle]="{
                          border: 'none',
                          padding: '0',
                          background: 'none',
                          color: '#004fd6'
                        }"
                      >
                        {{
                          isAttendeesExpanded(dataItem?.Id)
                            ? "Show Less"
                            : "Show More"
                        }}
                      </button>
                    </ng-container>
                  </ng-container>





                  <ng-container *ngIf="column === ' Contact Date'">
                    {{dataItem?.ProjectedSalesDate}}
                  </ng-container>
                  <ng-container *ngIf="column === ' Activity Notes'">
                    {{ dataItem?.Description }}
                  </ng-container>

                  <ng-container *ngIf="column === 'Cell Phone'">
                    <a>{{ dataItem?.CustomerCellPhone }}</a>
                  </ng-container>
                  <ng-container *ngIf="column === 'Suburb'">
                    {{ dataItem?.Suburb }}
                  </ng-container>
                  <ng-container *ngIf="column === ' Email Address'">
                    {{ dataItem?.CustomerEmailAddress }}
                  </ng-container>
                  <ng-container *ngIf="column === 'Email'">
                    <a
                      class="justify-content-center align-items-center fs-6"
                      nz-icon
                      nzType="mail"
                      nzTheme="fill"
                      (click)="LeadEmailWizard(dataItem.Id)"
                    ></a>
                  </ng-container>
                  <ng-container *ngIf="column === 'Last Contacted'">
                    {{ dataItem?.LastContacted }}

                  </ng-container>
                  <ng-container *ngIf="column === 'Phone'">
                    <!-- <a>{{ dataItem?.leadOpportunity?.CustomerCellPhone }}</a> -->
                    <a>{{ dataItem?.CustomerPhoneNumber }}</a>
                  </ng-container>
                  <ng-container *ngIf="column === 'State'">
                    {{ dataItem?.State }}
                  </ng-container>
                  <ng-container *ngIf="column === 'Street Address'">
                    {{ dataItem?.CustomerStreetAddress }}
                  </ng-container>
                  <ng-container *ngIf="column === 'Postal Code'">
                    {{ dataItem?.PostalCode }}
                  </ng-container>
                  <ng-container *ngIf="column === 'Project Type'">
                  </ng-container>
                  <ng-container *ngIf="column === '# of Links Clicked'">
                  </ng-container>
                  <ng-container
                    *ngIf="column === 'Confidence'"
                    class="confidence-container"
                  >
                    <div class="confidence-container">
                      <strong class="confidence-text"></strong>
                      <nz-progress
                        class="progress-bar"
                        [nzWidth]="100"
                        [nzPercent]="dataItem?.Confidence"
                      ></nz-progress>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="column === 'Most Recent Click'">
                  </ng-container>
                  <ng-container *ngIf="column === 'Project Type'">
                    {{dataItem?.ProjectTypeSetupName}}
                  </ng-container>
                  <ng-container *ngIf="column === 'Age'"> </ng-container>
                  <ng-container *ngIf="column === 'Projected Sales Date'">
                    {{ dataItem?.ActivityOn | date : "MMM d, yyyy" }}
                  </ng-container>

                  <ng-container *ngIf="column === 'Opportunity Status'">
                    <nz-tag
                      [nzColor]="'#fdddd5'"
                      style="
                        color: #8b1439;
                        font-weight: 700;
                        line-height: 1;
                        border-radius: 12px;
                        line-height: 16px;
                        padding: 0.2em 0.6em;
                      "
                    >
                      {{ dataItem?.LeadOpportunityStatus }}
                    </nz-tag>
                  </ng-container>
                  <ng-container *ngIf="column === 'Communication'">
                  </ng-container>
                  <ng-container *ngIf="column === 'Opportunity Notes'">
                    {{dataItem?.OpertunityNotes}}
                  </ng-container>
                  <ng-container *ngIf="column === 'Created By'"> </ng-container>
                  <ng-container *ngIf="column === 'Created Date'">
                    {{ dataItem?.CreatedByOn | date : "MMM d, yyyy" }}
                    {{ dataItem?.CreatedByOn | date : "h:mm a" }}
                  </ng-container>
                </ng-template>
              </kendo-grid-column>
            </kendo-grid>

            <nz-footer class="custom-footer" *ngIf="tab === 2 && getrfiUserDatas?.length > 0">
              <div class="container-fluid">
                <div
                  class="row d-flex justify-content-between align-items-center"
                >
                  <div class="row mb-0 mt-2 p-2">
                    <div class="col">
                      <!-- sufyan Work -->
                      <nz-pagination
                      class="hide-buttons"
                      style="float: right"
                      [nzPageIndex]="currentPageActivityView"
                      [nzTotal]="totalRecords"
                      [nzPageSize]="pageSizeActivityView"
                      [nzShowSizeChanger]="true"
                      [nzPageSizeOptions]="pageSizeOptions"
                      (nzPageIndexChange)="onPageChangeActivityView($event)"
                      (nzPageSizeChange)="onPageSizeChangeActivityView($event)"
                      [nzShowTotal]="totalTemplate"
                      [nzSimple]="false"
                    ></nz-pagination>
                    <ng-template #totalTemplate let-total="total" let-range="range">
                      {{ range[0] }}-{{ range[1] }} of {{ total }} items
                    </ng-template>
                    <nz-pagination
                      *ngIf="!isAllDataShownActivityView()"
                      style="float: right"
                      [nzPageIndex]="currentPageActivityView"
                      [nzTotal]="totalRecords"
                      [nzPageSize]="pageSizeActivityView"
                      [nzShowSizeChanger]="true"
                      [nzPageSizeOptions]="pageSizeOptions"
                      (nzPageIndexChange)="onPageChangeActivityView($event)"
                      (nzPageSizeChange)="onPageSizeChangeActivityView($event)"
                      nzSimple
                    ></nz-pagination>
                      <!-- sufyan Work -->
                      <ng-container *ngIf="PAGE_ID">
                        <div class="row d-flex justify-content-start">
                          <!-- <div class="col-md-3">
                            <nz-select
                              (ngModelChange)="onFilterChange($event)"
                              [(ngModel)]="selectedValueControl"
                              nzShowSearch
                            >
                              <nz-option
                                *ngFor="
                                  let standard of getStandardViewFilterData
                                "
                                [nzLabel]="standard.name"
                                [nzValue]="standard.id"
                              ></nz-option>
                            </nz-select>
                          </div> -->
                          <div class="col-md-2 p-0">
                            <div class="input-group">
                              <nz-select
                              [(ngModel)]="selectedValueControl"
                                name="standardFilter"
                                (ngModelChange)="onFilterChange($event)"
                                nzShowSearch
                                [nzAutoFocus]="true"
                                nzPlaceHolder="Standard Filter"
                                nzAllowClear>
                                <nz-option  *ngFor="let standard of getStandardViewFilterData"
                                  [nzLabel]="standard.name + (standard.isSetAsDefault ? ' ' : '')"
                                  [nzValue]="standard.id"
                                  nzCustomContent>
                                  <div class="option-content">
                                    <!-- Customize content with icons and text -->
                                    <span *ngIf="standard.icon" nz-icon [nzType]="standard.icon"></span>
                                    <span>{{ standard.name }}</span>
                                  <span  *ngIf="standard.isSetAsDefault" class="status-text badge info margin-left-xss">Default</span>

                                  </div>
                                </nz-option>
                              </nz-select>
                            </div>
                          </div>

                          <div class="col-md-1 p-0">
                            <button
                              nz-button
                              class="border-0"
                              (click)="openStandardViewFilterComponent()"
                            >
                              <span
                                nz-popover
                                class="text-primary text-dark fw-bold fs-5"
                                nz-icon
                                nzType="ellipsis"
                                nzTheme="outline"
                              ></span>
                            </button>

                          </div>
                          <div class="col-md-2 p-0">
                            <div class="mt-2" *ngIf="selectedFilter === 1">
                              <p>Your filter has unsaved changes.</p>
                            </div>
                            <div class="mt-2" *ngIf="selectedFilter === 0">
                              <p>Your filter has unsaved changes.</p>
                            </div>
                          </div>

                          <div class="col-md-3 p-0">
                            <button
                              (click)="openStandardViewFilterComponent('saveAs')"
                              type="button"
                              nz-button
                              nzType="default"
                              class="me-2"
                              style="font-size: 12px"
                              *ngIf="selectedFilter === 1"
                            >
                              Save As
                            </button>

                            <button
                              (click)="reInitReset()"
                              nz-button
                              nzType="default"
                              style="font-size: 12px"
                              *ngIf="selectedFilter === 1"
                              type="button"
                            >
                              Reset
                            </button>

                            <button
                              (click)="saveEditStandardView()"
                              nz-button
                              nzType="primary"
                              type="button"
                              class="me-2"
                              style="font-size: 12px"
                              *ngIf="selectedFilter === 0"
                            >
                              Save
                            </button>
                            <button
                              (click)="openStandardViewFilterComponent('saveAs')"
                              nz-button
                              nzType="default"
                              class="me-2"
                              style="font-size: 12px"
                              *ngIf="selectedFilter === 0"
                              type="button"
                            >
                              Save As
                            </button>

                            <button
                              (click)="reInitReset()"
                              nz-button
                              nzType="default"
                              style="font-size: 12px"
                              *ngIf="selectedFilter === 0"
                              type="button"
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </nz-footer>
          </ng-container>

          <ng-container *ngIf="tab === 3">
              <app-activity-full-calendar [_LeadActivityMetaData_Res]="this.getrfiUserDatas" (On_updateIsComplete)="onIsCompleteChanges()"></app-activity-full-calendar>
          </ng-container>

          <ng-container *ngIf="tab === 4">
            <kendo-grid
              [selectable]="{ mode: 'multiple', checkboxOnly: true }"
              (columnResize)="saveColumnSizes($event)"
              [data]="getProposalTemplatesData"
              kendoGridSelectBy="id"
              [pageSize]="5"
              [resizable]="true"
              [reorderable]="true"
              width="100%"
              [sortable]="true"
              [style.maxHeight.px]="600"
              [(selectedKeys)]="activityTemplates"
              (selectionChange)="selectionChange(activityTemplates)"
            >
              <kendo-grid-checkbox-column
                [width]="55"
                [headerClass]="{ 'text-center': true }"
                [class]="{ 'text-center': true }"
                [resizable]="false"
                [columnMenu]="false"
                [showSelectAll]="true"
              ></kendo-grid-checkbox-column>
              <kendo-grid-column
                *ngFor="let column of displayedColumns; let i = index"
                [title]="column"
                [field]="column"
                [minResizableWidth]="150"
                [maxResizableWidth]="800"
                [width]="getColumnWidthSize[i]"
                [sticky]="isColumnSticky[i]"
                [headerClass]="'pinned-header'"
              >
                <ng-template
                  kendoGridHeaderTemplate
                  let-columnIndex="columnIndex"
                >
                  <div class="d-flex align-items-center">
                    <span>{{ column }}</span>
                    <button
                      (click)="toggleColumnSticky(columnIndex)"
                      class="btn btn-link"
                    >
                      <i
                        class="bi"
                        [ngClass]="{
                          'bi-pin-fill': isColumnSticky[columnIndex],
                          'bi-pin': !isColumnSticky[columnIndex]
                        }"
                      ></i>
                    </button>
                  </div>
                </ng-template>

                <ng-template
                  kendoGridCellTemplate
                  let-dataItem
                  let-rowIndex="rowIndex"
                >
                  <ng-container *ngIf="column === 'Template Name'">
                    <a
                      class="text-over-Flow"
                      (click)="leadActivityTemplatesFormEdit(dataItem.Id)"
                      >{{ dataItem?.LeadActivityTemplateName }}</a
                    >
                  </ng-container>
                  <ng-container *ngIf="column === 'TemplateDescription'">
                    {{ dataItem?.Description }}
                  </ng-container>
                  <ng-container *ngIf="column === '# of Activity'">
                    {{ dataItem?.LeadActivityCount }}
                  </ng-container>
                  <ng-container *ngIf="column === 'Created By'">
                    {{ dataItem?.CreatedByUserName }}
                  </ng-container>
                  <ng-container *ngIf="column === 'Created Date'">
                    {{ dataItem?.CreatedOn }}
                  </ng-container>
                  <ng-container *ngIf="column === 'Next Activity'">



                    <p
                    nz-popover
                    nzPopoverContent="{{ dataItem?.NextActivity }}"
                    [ngStyle]="{ color: isTodayActivity(dataItem?.NextActivity) ? '#000000' : '#d33a5a' }">
                    {{ dataItem?.NextActivity }}
                  </p>



                  </ng-container>
                </ng-template>
              </kendo-grid-column>
            </kendo-grid>

            <nz-footer class="custom-footer" *ngIf="tab === 4 && getProposalTemplatesData?.length > 0">
              <div class="container-fluid">
                <div
                  class="row d-flex justify-content-between align-items-center"
                >
                  <div class="row mb-0 mt-2 p-2">
                    <div class="col">
                       <!-- sufyan working  -->
                       <nz-pagination
                       class="hide-buttons"
                       style="float: right"
                       [nzPageIndex]="currentPageactivityTemplates"
                       [nzTotal]="totalRecords"
                       [nzPageSize]="pageSizeActivityTemplates"
                       [nzShowSizeChanger]="true"
                       [nzPageSizeOptions]="pageSizeOptions"
                       (nzPageIndexChange)="onPageChangeActivityTemplates($event)"
                       (nzPageSizeChange)="onPageSizeChangeActivityTemplates($event)"
                       [nzShowTotal]="totalTemplate"
                       [nzSimple]="false"
                     ></nz-pagination>
                     <ng-template #totalTemplate let-total="total" let-range="range">
                       {{ range[0] }}-{{ range[1] }} of {{ total }} items
                     </ng-template>
                     <nz-pagination
                       *ngIf="!isAllDataShownActivityTemplate()"
                       style="float: right"
                       [nzPageIndex]="currentPageactivityTemplates"
                       [nzTotal]="totalRecords"
                       [nzPageSize]="pageSizeActivityTemplates"
                       [nzShowSizeChanger]="true"
                       [nzPageSizeOptions]="pageSizeOptions"
                       (nzPageIndexChange)="onPageChangeActivityTemplates($event)"
                       (nzPageSizeChange)="onPageSizeChangeActivityTemplates($event)"
                       nzSimple
                     ></nz-pagination>
                     <!-- sufyan working -->
                      <ng-container *ngIf="PAGE_ID">
                        <div class="row d-flex justify-content-start">
                          <!-- <div class="col-md-3">
                            <nz-select
                              (ngModelChange)="onFilterChange($event)"
                              [(ngModel)]="selectedValueControl"
                              nzShowSearch
                            >
                              <nz-option
                                *ngFor="
                                  let standard of getStandardViewFilterData
                                "
                                [nzLabel]="standard.name"
                                [nzValue]="standard.id"
                              ></nz-option>
                            </nz-select>
                          </div> -->
                          <div class="col-md-2 p-0">
                            <div class="input-group">
                              <nz-select
                              [(ngModel)]="selectedValueControl"
                                name="standardFilter"
                                (ngModelChange)="onFilterChange($event)"
                                nzShowSearch
                                >
                                <nz-option  *ngFor="let standard of getStandardViewFilterData"
                                  [nzLabel]="standard.name + (standard.isSetAsDefault ? ' ' : '')"
                                  [nzValue]="standard.id"
                                  nzCustomContent>
                                  <div class="option-content">
                                    <!-- Customize content with icons and text -->
                                    <span *ngIf="standard.icon" nz-icon [nzType]="standard.icon"></span>
                                    <span>{{ standard.name }}</span>
                                  <span  *ngIf="standard.isSetAsDefault" class="status-text badge info margin-left-xss">Default</span>

                                  </div>
                                </nz-option>
                              </nz-select>
                            </div>
                          </div>

                          <div class="col-md-1 p-0">
                            <button
                              nz-button
                              class="border-0"
                              (click)="openStandardViewFilterComponent()"
                            >
                              <span
                              nz-popover
                              [nzPopoverContent]="contentTemplate"
                              [nzPopoverPlacement]="'top'"
                                class="text-primary text-dark fw-bold fs-5"
                                nz-icon
                                nzType="ellipsis"
                                nzTheme="outline"
                              ></span>
                            </button>
                          </div>
                          <div class="col-md-3 p-0">
                            <div class="mt-2" *ngIf="selectedFilter === 1">
                              <p>Your filter has unsaved changes.</p>
                            </div>
                            <div class="mt-2" *ngIf="selectedFilter === 0">
                              <p>Your filter has unsaved changes.</p>
                            </div>
                          </div>
                          <div class="col-md-3 p-0">
                            <button
                              (click)="
                                openStandardViewFilterComponent('saveAs')
                              "
                              type="button"
                              nz-button
                              nzType="default"
                              class="me-2"
                              style="font-size: 12px"
                              *ngIf="selectedFilter === 1"
                            >
                              Save As
                            </button>
                            <button
                              (click)="reInitReset()"
                              nz-button
                              nzType="default"
                              style="font-size: 12px"
                              *ngIf="selectedFilter === 1"
                              type="button"
                            >
                              Reset
                            </button>
                            <button
                              (click)="saveEditStandardView()"
                              nz-button
                              nzType="primary"
                              type="button"
                              class="me-2"
                              style="font-size: 12px"
                              *ngIf="selectedFilter === 0"
                            >
                              Save
                            </button>
                            <button
                              (click)="
                                openStandardViewFilterComponent('saveAs')
                              "
                              nz-button
                              nzType="default"
                              class="me-2"
                              style="font-size: 12px"
                              *ngIf="selectedFilter === 0"
                              type="button"
                            >
                              Save As
                            </button>
                            <button
                              (click)="reInitReset()"
                              nz-button
                              nzType="default"
                              style="font-size: 12px"
                              *ngIf="selectedFilter === 0"
                              type="button"
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </nz-footer>
          </ng-container>

          <ng-container *ngIf="tab === 5" class="button-container">
            <kendo-grid
              (columnResize)="leadProposalsSaveColumnSizes($event)"
              [data]="getLeadProposalData"
              [selectable]="{ mode: 'multiple', checkboxOnly: true }"
              [pageSize]="5"
              [resizable]="true"
              [reorderable]="true"
              width="100%"
              [sortable]="true"
              [style.maxHeight.px]="435"
              [height]="500"
            >

              <kendo-grid-column
                *ngFor="let column of displayedColumns; let i = index"
                [title]="column"
                [field]="column"
                [minResizableWidth]="150"
                [maxResizableWidth]="800"
                [width]="getColumnWidthSize[i]"
                [sticky]="isColumnSticky[i]"
                [headerClass]="'pinned-header'"
              >
                <ng-template
                  kendoGridHeaderTemplate
                  let-columnIndex="columnIndex"
                >
                  <div class="d-flex align-items-center">
                    <span>{{ column }}</span>
                    <button
                      (click)="toggleColumnSticky(columnIndex)"
                      class="btn btn-link"
                    >
                      <i
                        class="bi"
                        [ngClass]="{
                          'bi-pin-fill': isColumnSticky[columnIndex],
                          'bi-pin': !isColumnSticky[columnIndex]
                        }"
                      ></i>
                    </button>
                  </div>
                </ng-template>
                <ng-template
                  kendoGridCellTemplate
                  let-dataItem
                  let-rowIndex="rowIndex"
                >
                  <ng-container *ngIf="column === 'Proposal Title'">
                    <a
                      class="text-over-Flow"
                      (click)="leadProposalFormEdit(dataItem.Id,dataItem.LeadOpportunityId)"
                      >{{ dataItem?.LeadProposalsTitle }}</a
                    >
                  </ng-container>
                  <ng-container *ngIf="column === 'Opportunity Title'">
                    <a
                      class="text-over-Flow"
                      (click)="
                        leadOpportunityFormEdit(dataItem.LeadOpportunityId)
                      "
                      >{{ dataItem?.LeadOpportunityTitle }}</a
                    >
                  </ng-container>
                  <ng-container *ngIf="column === 'Customer Contact'">
                    <a
                      class="text-over-Flow"
                      (click)="EditCustomer(dataItem?.CustomerInformationId)"
                      >{{ dataItem?.CustomerInformationDisplayName }}</a
                    >
                  </ng-container>
                  <ng-container *ngIf="column === 'Salesperson'">
                    {{ dataItem?.LeadOpportunitySalesPersonFullName }}
                  </ng-container>
                  <ng-container *ngIf="column === 'Owner Price'">
                    {{ dataItem?.CostItemOwnerPrice }}
                  </ng-container>
                  <ng-container *ngIf="column === 'Proposal Status'">
                    {{ dataItem?.ModifiedOn | date : "MMM d, yyyy" }}
                  </ng-container>
                  <ng-container *ngIf="column === 'CreatedBy'"> </ng-container>
                  <ng-container *ngIf="column === 'ModifiedOn'"> </ng-container>
                  <ng-container *ngIf="column === 'Opportunity Status'">
                    {{ dataItem?.ProposalStatus }}
                  </ng-container>
                  <ng-container *ngIf="column === 'Updated'">
                    {{ dataItem?.ModifiedOn | date : "MMM d, yyyy" }}
                  </ng-container>
                  <ng-container *ngIf="column === 'Last Viewed'">
                    {{ dataItem?.LeadProposal?.LastViewed }}
                  </ng-container>
                  <ng-container *ngIf="column === 'Project Type'">
                    {{ dataItem?.ProjectTypeName }}
                  </ng-container>
                  <ng-container *ngIf="column === 'Takeoff Status'">
                  </ng-container>
                </ng-template>
              </kendo-grid-column>
            </kendo-grid>

            <nz-footer class="custom-footer" *ngIf="tab === 5 && getLeadProposalData?.length > 0">
              <div class="container-fluid">
                <div
                  class="row d-flex justify-content-between align-items-center"
                >
                  <div class="row mb-0 mt-2 p-2">
                    <div class="col">
                      <!-- sufyan working  -->
                      <nz-pagination
                      class="hide-buttons"
                      style="float: right"
                      [nzPageIndex]="currentPageLeadProposals"
                      [nzTotal]="totalRecords"
                      [nzPageSize]="pageSizeLeadProposals"
                      [nzShowSizeChanger]="true"
                      [nzPageSizeOptions]="pageSizeOptions"
                      (nzPageIndexChange)="onPageChangeLeadProposals($event)"
                      (nzPageSizeChange)="onPageSizeChangeLeadProposals($event)"
                      [nzShowTotal]="totalTemplate"
                      [nzSimple]="false"
                    ></nz-pagination>
                    <ng-template #totalTemplate let-total="total" let-range="range">
                      {{ range[0] }}-{{ range[1] }} of {{ total }} items
                    </ng-template>
                    <nz-pagination
                      *ngIf="!isAllDataShownLeadProposals()"
                      style="float: right"
                      [nzPageIndex]="currentPageLeadProposals"
                      [nzTotal]="totalRecords"
                      [nzPageSize]="pageSizeLeadProposals"
                      [nzShowSizeChanger]="true"
                      [nzPageSizeOptions]="pageSizeOptions"
                      (nzPageIndexChange)="onPageChangeLeadProposals($event)"
                      (nzPageSizeChange)="onPageSizeChangeLeadProposals($event)"
                      nzSimple
                    ></nz-pagination>
                    <!-- sufyan working -->
                      <ng-container *ngIf="PAGE_ID">
                        <div class="row d-flex justify-content-start">
                          <!-- <div class="col-md-3">
                            <nz-select
                              (ngModelChange)="onFilterChange($event)"
                              [(ngModel)]="selectedValueControl"
                              nzShowSearch
                            >
                              <nz-option
                                *ngFor="
                                  let standard of getStandardViewFilterData
                                "
                                [nzLabel]="standard.name"
                                [nzValue]="standard.id"
                              ></nz-option>
                            </nz-select>
                          </div> -->
                          <div class="col-md-2 p-0">
                            <div class="input-group">
                              <nz-select
                              [(ngModel)]="selectedValueControl"
                                name="standardFilter"
                                (ngModelChange)="onFilterChange($event)"
                                nzShowSearch
                                >
                                <nz-option  *ngFor="let standard of getStandardViewFilterData"
                                  [nzLabel]="standard.name + (standard.isSetAsDefault ? ' ' : '')"
                                  [nzValue]="standard.id"
                                  nzCustomContent>
                                  <div class="option-content">
                                    <!-- Customize content with icons and text -->
                                    <span *ngIf="standard.icon" nz-icon [nzType]="standard.icon"></span>
                                    <span>{{ standard.name }}</span>
                                  <span  *ngIf="standard.isSetAsDefault" class="status-text badge info margin-left-xss">Default</span>

                                  </div>
                                </nz-option>
                              </nz-select>
                            </div>
                          </div>

                          <div class="col-md-1 p-0">
                            <button
                              nz-button
                              class="border-0"
                              (click)="openStandardViewFilterComponent()"
                            >
                              <span
                              nz-popover
                              [nzPopoverContent]="contentTemplate"
                              [nzPopoverPlacement]="'top'"
                                class="text-primary text-dark fw-bold fs-5"
                                nz-icon
                                nzType="ellipsis"
                                nzTheme="outline"
                              ></span>
                            </button>
                          </div>
                          <div class="col-md-3 p-0">
                            <div class="mt-2" *ngIf="selectedFilter === 1">
                              <p>Your filter has unsaved changes.</p>
                            </div>
                            <div class="mt-2" *ngIf="selectedFilter === 0">
                              <p>Your filter has unsaved changes.</p>
                            </div>
                          </div>
                          <div class="col-md-3 p-0">
                            <button
                              (click)="
                                openStandardViewFilterComponent('saveAs')
                              "
                              type="button"
                              nz-button
                              nzType="default"
                              class="me-2"
                              style="font-size: 12px"
                              *ngIf="selectedFilter === 1"
                            >
                              Save As
                            </button>
                            <button
                              (click)="reInitReset()"
                              nz-button
                              nzType="default"
                              style="font-size: 12px"
                              *ngIf="selectedFilter === 1"
                              type="button"
                            >
                              Reset
                            </button>
                            <button
                              (click)="saveEditStandardView()"
                              nz-button
                              nzType="primary"
                              type="button"
                              class="me-2"
                              style="font-size: 12px"
                              *ngIf="selectedFilter === 0"
                            >
                              Save
                            </button>
                            <button
                              (click)="
                                openStandardViewFilterComponent('saveAs')
                              "
                              nz-button
                              nzType="default"
                              class="me-2"
                              style="font-size: 12px"
                              *ngIf="selectedFilter === 0"
                              type="button"
                            >
                              Save As
                            </button>
                            <button
                              (click)="reInitReset()"
                              nz-button
                              nzType="default"
                              style="font-size: 12px"
                              *ngIf="selectedFilter === 0"
                              type="button"
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </nz-footer>
          </ng-container>

          <ng-container *ngIf="tab === 6" class="text-end">
            <kendo-grid
              [selectable]="{ mode: 'multiple', checkboxOnly: true }"
              (columnResize)="saveColumnSizes($event)"
              [data]="getLeadProposalisTemplateData"
              kendoGridSelectBy="id"
              [pageSize]="5"
              [resizable]="true"
              [reorderable]="true"
              width="100%"
              [sortable]="true"
              [style.maxHeight.px]="600"
              [(selectedKeys)]="proposalTemplates"
              (selectionChange)="selectionChange(proposalTemplates)"
            >
              <kendo-grid-checkbox-column
                [width]="55"
                [headerClass]="{ 'text-center': true }"
                [class]="{ 'text-center': true }"
                [resizable]="false"
                [columnMenu]="false"
                [showSelectAll]="true"
              ></kendo-grid-checkbox-column>
              <kendo-grid-column
                *ngFor="let column of displayedColumns; let i = index"
                [title]="column"
                [field]="column"
                [minResizableWidth]="150"
                [maxResizableWidth]="800"
                [width]="getColumnWidthSize[i]"
                [sticky]="isColumnSticky[i]"
                [headerClass]="'pinned-header'"
              >
                <ng-template
                  kendoGridHeaderTemplate
                  let-columnIndex="columnIndex"
                >
                  <div class="d-flex align-items-center">
                    <span>{{ column }}</span>
                    <button
                      (click)="toggleColumnSticky(columnIndex)"
                      class="btn btn-link"
                    >
                      <i
                        class="bi"
                        [ngClass]="{
                          'bi-pin-fill': isColumnSticky[columnIndex],
                          'bi-pin': !isColumnSticky[columnIndex]
                        }"
                      ></i>
                    </button>
                  </div>
                </ng-template>
                <ng-template
                  kendoGridCellTemplate
                  let-dataItem
                  let-rowIndex="rowIndex"
                >
                  <ng-container *ngIf="column === 'Template Name'">
                    <a
                      class="text-over-Flow"
                      (click)="leadProposalTemplatesFormEdit(dataItem?.Id)"
                      >{{ dataItem?.LeadProposalsTitle }}</a
                    >
                  </ng-container>
                  <ng-container *ngIf="column === 'TemplateDescription'">
                    {{ dataItem?.TemplateDescription }}
                  </ng-container>
                  <ng-container
                    *ngIf="column === 'Total Builder Cost'"
                    style="width: 300px"
                  >
                    {{ dataItem.CostItemBuilderCost }}
                  </ng-container>
                  <ng-container
                    *ngIf="column === 'Markup'"
                    style="width: 300px"
                  >
                    {{ dataItem.Markup }}
                  </ng-container>
                  <ng-container
                    *ngIf="column === 'Total Owner Price'"
                    style="width: 300px"
                  >
                    {{ dataItem?.CostItemOwnerPrice }}
                  </ng-container>
                  <ng-container *ngIf="column === 'Created By'">
                    {{ dataItem?.CreatedByUser }}
                  </ng-container>
                  <ng-container *ngIf="column === 'Created Date'">
                    {{ dataItem?.CreatedByOn | date : "MMM d, yyyy" }}
                    {{ dataItem?.CreatedByOn | date : "h:mm a" }}
                  </ng-container>
                </ng-template>
              </kendo-grid-column>
            </kendo-grid>

            <nz-footer class="custom-footer" *ngIf="tab === 6 && getLeadProposalisTemplateData?.length > 0">
              <div class="container-fluid">
                <div
                  class="row d-flex justify-content-between align-items-center"
                >
                  <div class="row mb-0 mt-2 p-2">
                    <div class="col">
                       <!-- sufyan working  -->
                       <nz-pagination
                       class="hide-buttons"
                       style="float: right"
                       [nzPageIndex]="currentPageProposalTemplates"
                       [nzTotal]="totalRecords"
                       [nzPageSize]="pageSizeProposalTemplates"
                       [nzShowSizeChanger]="true"
                       [nzPageSizeOptions]="pageSizeOptions"
                       (nzPageIndexChange)="onPageChangeProposalTemplates($event)"
                       (nzPageSizeChange)="onPageSizeChangeProposalTemplates($event)"
                       [nzShowTotal]="totalTemplate"
                       [nzSimple]="false"
                     ></nz-pagination>
                     <ng-template #totalTemplate let-total="total" let-range="range">
                       {{ range[0] }}-{{ range[1] }} of {{ total }} items
                     </ng-template>
                     <nz-pagination
                       *ngIf="!isAllDataShownProposalTemplate()"
                       style="float: right"
                       [nzPageIndex]="currentPageProposalTemplates"
                       [nzTotal]="totalRecords"
                       [nzPageSize]="pageSizeProposalTemplates"
                       [nzShowSizeChanger]="true"
                       [nzPageSizeOptions]="pageSizeOptions"
                       (nzPageIndexChange)="onPageChangeProposalTemplates($event)"
                       (nzPageSizeChange)="onPageSizeChangeProposalTemplates($event)"
                       nzSimple
                     ></nz-pagination>
                     <!-- sufyan working -->

                      <ng-container *ngIf="PAGE_ID">
                        <div class="row d-flex justify-content-start">
                          <!-- <div class="col-md-3">
                            <nz-select
                              (ngModelChange)="onFilterChange($event)"
                              [(ngModel)]="selectedValueControl"
                              nzShowSearch
                            >
                              <nz-option
                                *ngFor="
                                  let standard of getStandardViewFilterData
                                "
                                [nzLabel]="standard.name"
                                [nzValue]="standard.id"
                              ></nz-option>
                            </nz-select>
                          </div> -->
                          <div class="col-md-2 p-0">
                            <div class="input-group">
                              <nz-select
                              [(ngModel)]="selectedValueControl"
                                name="standardFilter"
                                (ngModelChange)="onFilterChange($event)"
                                nzShowSearch
                                >
                                <nz-option  *ngFor="let standard of getStandardViewFilterData"
                                  [nzLabel]="standard.name + (standard.isSetAsDefault ? ' ' : '')"
                                  [nzValue]="standard.id"
                                  nzCustomContent>
                                  <div class="option-content">
                                    <!-- Customize content with icons and text -->
                                    <span *ngIf="standard.icon" nz-icon [nzType]="standard.icon"></span>
                                    <span>{{ standard.name }}</span>
                                  <span  *ngIf="standard.isSetAsDefault" class="status-text badge info margin-left-xss">Default</span>

                                  </div>
                                </nz-option>
                              </nz-select>
                            </div>
                          </div>

                          <div class="col-md-1 p-0">
                            <button
                              nz-button

                              class="border-0"
                              (click)="openStandardViewFilterComponent()"
                            >
                              <span
                              nz-popover
                              [nzPopoverContent]="contentTemplate"
                              [nzPopoverPlacement]="'top'"
                                class="text-primary text-dark fw-bold fs-5"
                                nz-icon
                                nzType="ellipsis"
                                nzTheme="outline"
                              ></span>
                            </button>
                          </div>
                          <div class="col-md-3 p-0">
                            <div class="mt-2" *ngIf="selectedFilter === 1">
                              <p>Your filter has unsaved changes.</p>
                            </div>
                            <div class="mt-2" *ngIf="selectedFilter === 0">
                              <p>Your filter has unsaved changes.</p>
                            </div>
                          </div>
                          <div class="col-md-3 p-0">
                            <button
                              (click)="
                                openStandardViewFilterComponent('saveAs')
                              "
                              type="button"
                              nz-button
                              nzType="default"
                              class="me-2"
                              style="font-size: 12px"
                              *ngIf="selectedFilter === 1"
                            >
                              Save As
                            </button>
                            <button
                              (click)="reInitReset()"
                              nz-button
                              nzType="default"
                              style="font-size: 12px"
                              *ngIf="selectedFilter === 1"
                              type="button"
                            >
                              Reset
                            </button>
                            <button
                              (click)="saveEditStandardView()"
                              nz-button
                              nzType="primary"
                              type="button"
                              class="me-2"
                              style="font-size: 12px"
                              *ngIf="selectedFilter === 0"
                            >
                              Save
                            </button>
                            <button
                              (click)="
                                openStandardViewFilterComponent('saveAs')
                              "
                              nz-button
                              nzType="default"
                              class="me-2"
                              style="font-size: 12px"
                              *ngIf="selectedFilter === 0"
                              type="button"
                            >
                              Save As
                            </button>
                            <button
                              (click)="reInitReset()"
                              nz-button
                              nzType="default"
                              style="font-size: 12px"
                              *ngIf="selectedFilter === 0"
                              type="button"
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </nz-footer>
          </ng-container>

          <ng-container *ngIf="tab === 7">
            <lead-opportunity-map-graph [data]="getLeadMapDataResponse"></lead-opportunity-map-graph>
          </ng-container>

          <nz-footer class="custom-footer" *ngIf="tab === 7 && getLeadMapDataResponse?.length > 0">
            <div class="container-fluid">
              <div
                class="row d-flex justify-content-between align-items-center"
              >
                <div class="row mb-0 mt-2 p-2">
                  <div class="col">
                  <!-- sufyan Work -->
                    <nz-pagination
                    class="hide-buttons"
                    style="float: right"
                    [nzPageIndex]="LeadMapPage"
                    [nzTotal]="totalRecords"
                    [nzPageSize]="pageSizeMap"
                    [nzShowSizeChanger]="true"
                    [nzPageSizeOptions]="pageSizeOptions"
                    (nzPageIndexChange)="onPageChangeMap($event)"
                    (nzPageSizeChange)="onPageSizeChangeMap($event)"
                    [nzShowTotal]="totalTemplate"
                    [nzSimple]="false"
                  ></nz-pagination>
                  <ng-template #totalTemplate let-total="total" let-range="range">
                    {{ range[0] }}-{{ range[1] }} of {{ total }} items
                  </ng-template>
                  <nz-pagination
                    *ngIf="!isAllDataShownListView()"
                    style="float: right"
                    [nzPageIndex]="LeadMapPage"
                    [nzTotal]="totalRecords"
                    [nzPageSize]="pageSizeMap"
                    [nzShowSizeChanger]="true"
                    [nzPageSizeOptions]="pageSizeOptions"
                    (nzPageIndexChange)="onPageChangeMap($event)"
                    (nzPageSizeChange)="onPageSizeChangeMap($event)"
                    nzSimple
                  ></nz-pagination>
                  <!-- sufyan Work -->
                    <ng-container >
                      <div class="row d-flex justify-content-start">

                        <div class="col-md-3">

                        </div>

                        <div class="col-md-1">


                        </div>
                        <div class="col-md-2">

                        </div>

                        <div class="col-md-3">




                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </nz-footer>
        </nz-tab>


      </nz-tabset>

      <ng-template #modalFooter>
        <div class="mt-2 align-items-center">
          <button nz-button nzType="default" class=" me-0">
            Print
          </button>
          <button nz-button nzType="default" class=" me-0">
            Delete
          </button>
          <button nz-button nzType="primary" class=" me-0">
            Save & New
          </button>
          <button nz-button nzType="primary" class=" me-0">
            Save
          </button>
          <button nz-button nzType="primary" class=" me-0">
            Save & Close
          </button>
        </div>
      </ng-template>
    </div>
  </nz-content>
</nz-layout>

<nz-modal
  [(nzVisible)]="isVisibleAddModal"
  nzTitle="Upload Files"
  [nzBodyStyle]="{
    background: '#f1f4fa',
    padding: '13px',
    'max-height': '430px',
    'overflow-y': 'auto'
  }"
  [nzStyle]="{ top: '15px' }"
  (nzOnCancel)="handleCancelAddModal()"
  (nzOnOk)="handleOkAddModal()"
>
  <ng-container *nzModalContent class="upload-modal-content">
    <nz-card nzTitle="Contact Information">
      <div class="d-flex align-items-center justify-content-center h-100">
        <div>
          <i class="bi bi-cloud-arrow-up-fill" style="font-size: 3rem"></i>
          <div class="row">
            <div class="col">
              <div class="styling demo">
                <b>Drop files here to upload, or browse for files.</b>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="mt-2">
                <button
                  nz-button
                  nzType="primary"
                  class="m-2"
                  style="font-size: 12px"
                >
                  Browser Buildertrend
                </button>
                <nz-upload
                  nzAction="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  nzDirectory
                >
                  <button nz-button>
                    <span nz-icon nzType="upload"></span>
                    Upload Directory
                  </button>
                </nz-upload>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nz-card>
  </ng-container>
</nz-modal>

<nz-drawer
  [nzClosable]="false"
  nzWidth="18%"
  [nzVisible]="filterDrawerVisible"
  nzPlacement="right"
  nzTitle="Filter Drawer"
  (nzOnClose)="closeFilterDrawer()"
>
  <ng-container *nzDrawerContent>
    <div class="row famly-inn">
      <div class="col d-flex align-items-center gx-2">
        <nz-select class="custom-select" [(ngModel)]="lucy">
          <nz-option nzValue="jack" nzLabel="Jack"></nz-option>
          <nz-option nzValue="lucy" nzLabel="Lucy"></nz-option>
        </nz-select>
        <div class="ml-2">
          <i class="bi bi-three-dots"></i>
        </div>
      </div>
      <div class="col">
        <div class="row text-start align-items-center">
          <div class="col-auto">
            <span>Keyword</span>
          </div>
          <div class="col-auto">
            <i
              nz-icon
              nzType="info-circle"
              nz-popover
              nzPopoverContent="Searches Notes, Email Subjects, Opportunity Title and Customer Contacts within Lead Activities"
              class="ms-1"
            ></i>
          </div>
        </div>

        <div class="row mt-1">
          <nz-input-number
            [(ngModel)]="demoValue"
            [nzMin]="1"
            [nzMax]="10"
            [nzStep]="1"
          ></nz-input-number>
        </div>
        <div class="row mt-2 sub-text">Activity Status</div>
        <div class="row mt-1">
          <nz-select
            [nzMaxTagCount]="3"
            [nzMaxTagPlaceholder]="tagPlaceHolder"
            nzMode="multiple"
            nzPlaceHolder="Please select"
            [(ngModel)]="listOfSelectedValue"
          >
            <nz-option
              *ngFor="let item of listOfOption"
              [nzLabel]="item"
              [nzValue]="item"
            ></nz-option>
          </nz-select>
          <ng-template #tagPlaceHolder let-selectedList
            >and {{ selectedList.length }} more selected</ng-template
          >
        </div>
        <div class="row mt-2 sub-text">Most Recent Click</div>
        <div class="row mt-1">
          <nz-select ngModel="lucy">
            <nz-option nzValue="jack" nzLabel="Jack"></nz-option>
            <nz-option nzValue="lucy" nzLabel="Lucy"></nz-option>
          </nz-select>
        </div>
        <div class="row mt-2 sub-text"># of Links Clicked</div>
        <div class="row mt-1">
          <nz-select ngModel="lucy">
            <nz-option nzValue="jack" nzLabel="Jack"></nz-option>
            <nz-option nzValue="lucy" nzLabel="Lucy"></nz-option>
          </nz-select>
        </div>
        <div class="row mt-2 sub-text">Lead Status</div>
        <div class="row mt-1">
          <nz-select
            [nzMaxTagCount]="3"
            [nzMaxTagPlaceholder]="tagPlaceHolder"
            nzMode="multiple"
            nzPlaceHolder="Please select"
            [(ngModel)]="listOfSelectedValue"
          >
            <nz-option
              *ngFor="let item of listOfOption"
              [nzLabel]="item"
              [nzValue]="item"
            ></nz-option>
          </nz-select>
          <ng-template #tagPlaceHolder let-selectedList
            >and {{ selectedList.length }} more selected</ng-template
          >
        </div>
        <div class="row mt-2 sub-text">Activity Type</div>
        <div class="row mt-1">
          <nz-select
            [nzMaxTagCount]="3"
            [nzMaxTagPlaceholder]="tagPlaceHolder"
            nzMode="multiple"
            nzPlaceHolder="Please select"
            [(ngModel)]="listOfSelectedValue"
          >
            <nz-option
              *ngFor="let item of listOfOption"
              [nzLabel]="item"
              [nzValue]="item"
            ></nz-option>
          </nz-select>
          <ng-template #tagPlaceHolder let-selectedList
            >and {{ selectedList.length }} more selected</ng-template
          >
        </div>
        <div class="row mt-2 sub-text">Assigned User</div>
        <div class="row mt-1">
          <nz-select
            [nzMaxTagCount]="3"
            [nzMaxTagPlaceholder]="tagPlaceHolder"
            nzMode="multiple"
            nzWidh
            nzPlaceHolder="Please select"
            [(ngModel)]="listOfSelectedValue"
          >
            <nz-option
              *ngFor="let item of listOfOption"
              [nzLabel]="item"
              [nzValue]="item"
            ></nz-option>
          </nz-select>
          <ng-template #tagPlaceHolder let-selectedList
            >and {{ selectedList.length }} more selected</ng-template
          >
        </div>

        <div class="row mt-4 sub-text">
          Include Attendees in Assigned User Filter
        </div>
        <div class="row mt-3">
          <label nz-checkbox [(ngModel)]="checked"></label>
        </div>
        <div class="row mt-2 sub-text">Most Recent Click</div>
        <div class="row mt-1">
          <nz-select ngModel="lucy">
            <nz-option nzValue="jack" nzLabel="Jack"></nz-option>
            <nz-option nzValue="lucy" nzLabel="Lucy"></nz-option>
          </nz-select>
        </div>
      </div>
    </div>
  </ng-container>
</nz-drawer>






<app-loading-indicator></app-loading-indicator>
<ng-template #contentTemplate>
  <div>
    <p>Manage Saved Views</p>

  </div>
</ng-template>


<!-- <estimated-cost></estimated-cost> -->
