import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, throwError } from 'rxjs';
import { AttachmentResponse } from 'src/Models/Attachment_Files_Class/AttachmentFilesClass';
import { CreateFileInformationRequest, FileInformationResponse } from 'src/Models/FilesModules/FilesDocument';
import { ResponseModel, ResponseModelArray } from 'src/Models/responseMessage.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FileDecumentService {

  constructor(private http: HttpClient) {}
  private Fileurl: string = environment.apiUrl + 'FileInformation/';




  PostFileDocument(CreateFile: CreateFileInformationRequest): Observable<ResponseModel<FileInformationResponse>> {
    const apiUrl = `${this.Fileurl}`;
    return this.http.post<any>(apiUrl, CreateFile).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }

  getData(): Observable<ResponseModelArray<FileInformationResponse>> {
    return this.http.get<ResponseModelArray<FileInformationResponse>>(`${this.Fileurl}GetAll`);
  }
  // getDatabyJob(JobInformationId: number): Observable<ResponseModelArray<FileInformationResponse>> {
  //   return this.http.get<ResponseModelArray<FileInformationResponse>>(
  //     `${this.Fileurl}getBy-JobInformation?JobInformationId=${JobInformationId}`
  //   );
  // }
  

 
 

  getDatabyJob(JobInformationId: number): Observable<ResponseModelArray<FileInformationResponse>> {
    return this.http.get<ResponseModelArray<FileInformationResponse>>(`${this.Fileurl}getBy-JobInformation?JobInformationId=${JobInformationId}`);
}

  private savedIdSource = new BehaviorSubject<string>('');
  currentSavedId = this.savedIdSource.asObservable();



  private attachmentResponseSubject = new BehaviorSubject<AttachmentResponse | null>(null);
  public attachmentResponse$ = this.attachmentResponseSubject.asObservable();


  changeSavedId(savedId: string) {
    this.savedIdSource.next(savedId);
  }



  setAttachmentResponse(response: AttachmentResponse): void {
    this.attachmentResponseSubject.next(response);
  }


  

}
