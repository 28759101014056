
<form nz-form [formGroup]="toDoFormCheckList">
<div cdkDropList  formArrayName="toDoFormCheckListArray"
(cdkDropListDropped)="drop($event)">
  <div
  *ngFor="let group of toDoFormCheckListArray.controls; let i = index;"
  [formGroupName]="i" cdkDrag>
    <div nz-row [nzGutter]="20" class="p-1">
      <!-- Grip Icon -->
      <div nz-col nzSpan="1">
        <i style="cursor: grab; font-size: 20px; margin-top: 6px;"
          class="fa-solid fa-grip-vertical" cdkDragHandle></i>
      </div>

      <!-- Complete Checkbox -->
      <div nz-col [nzSpan]="1">
        <label nz-checkbox formControlName="isComplete"
          class="custom-checkbox d-flex align-items-center"></label>
      </div>

      <!-- Task Name -->
      <div nz-col [nzSpan]="group.get('showAssignees')?.value ? 14 : 20">
          <nz-form-item>
            <nz-form-control [nzErrorTip]="group.get('nameError')?.value">
              <textarea formControlName="name" nz-input
                [nzAutosize]="{ minRows: 1, maxRows: 4 }"
                style="overflow: hidden; resize: none;"></textarea>
            </nz-form-control>
          </nz-form-item>
      </div>

      <!-- Assignee Selector -->
      <div nz-col  *ngIf="group.get('showAssignees')?.value" [nzSpan]="6">
        <nz-select formControlName="applicationUserId">
          <nz-option
            *ngFor="let user of internalRes"
            [nzValue]="user?.id"
            [nzLabel]="user?.fullName">
          </nz-option>
        </nz-select>



      </div>

      <!-- Dropdown Menu -->
      <div nz-col [nzSpan]="2">
        <button nzType="text" nz-button nz-dropdown [nzTrigger]="'click'"
          [nzDropdownMenu]="menu">
          <i class="fa-solid fa-ellipsis-vertical"></i>
        </button>

        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul nz-menu>
            <li nz-menu-item (click)="toggleAssignees(i)">
              <i class="fa-solid fa-users me-2"></i>
              {{ group.get('showAssignees')?.value ? 'Remove Assignees' :
              'Add Assignees' }}
            </li>
            <li nz-menu-item (click)="toggleAttachments(i)">
              <i class="fa-solid fa-paperclip me-2"></i>
              {{ group.get('showAttachments')?.value ? 'Remove Attachments'
              : 'Add Attachments' }}
            </li>
            <li nz-menu-item (click)="removeItem(i)">
              <i class="fa-regular fa-trash-can me-2"></i> Delete Item
            </li>
          </ul>
        </nz-dropdown-menu>
      </div>

      <!-- Attachments -->

      <div nz-row class="mt-1 p-4" *ngIf="group.get('showAttachments')?.value">
        <div nz-col [nzSpan]="24">
          <h6 nz-typography>attachment</h6>
          <app-create-attachments-common
          [TestAttachment__]="TestAttachment"
          (filesUploaded)="onFilesUploaded($event, i)">
        </app-create-attachments-common>

        </div>
      </div>

    </div>
  </div>
</div>
</form>
