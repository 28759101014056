import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CustomerResponseResult, PaginationParametersCustomer } from 'src/Models/CustomerInfromation/CustomerAndLeadOpportunityResponse';
import { CustomerInformationService } from 'src/Service/customer-information.service';
import { CustomerStatusCode } from 'src/app/shared/component/Models/LookUpStandardFilterCodes';


@Component({
  selector: 'app-contact-list',
  templateUrl: './contact-list.component.html',
  styleUrls: ['./contact-list.component.css']
})
export class ContactListComponent implements OnInit {
  @Output() selectedCustomer = new EventEmitter<CustomerResponseResult>();
  @Output() selectedCustomerInJob = new EventEmitter<CustomerResponseResult[]>();
  @Output() cancel = new EventEmitter<void>();
  NewDailyhandle = true;

  gridData: CustomerResponseResult[] = [];


  currentPage = 1;
  totalRecords = 0;
  pageSize = 50;
  isLoading: boolean = false;
  searchTerm = '';

  constructor(private customerInformation: CustomerInformationService) { }

  ngOnInit(): void {
    this.loadData();
  }

  loadData(): void {
    this.isLoading = true;
    const paginationParams: PaginationParametersCustomer = {
      pageNumber: this.currentPage,
      pageSize: this.pageSize,
      searchParameter: this.searchTerm
    };

    this.customerInformation.getAllCustomers(paginationParams).subscribe(RES => {
      if (RES.result && RES.result) {
        this.gridData = RES.result.rows;
        this.totalRecords = RES.result.rowCount;
      }
      this.isLoading = false;
    });
  }

  onSearch(): void {
    this.filterData(this.searchTerm);
  }

  filterData(search: string): void {
    this.isLoading = true;
    const paginationParams: PaginationParametersCustomer = {
      pageNumber: this.currentPage,
      pageSize: this.pageSize,
      searchParameter: search
    };

    this.customerInformation.getAllCustomers(paginationParams).subscribe(RES => {
      if (RES.result) {
        this.gridData = RES.result.rows;
        this.totalRecords = RES.result.rowCount;
      }
      this.isLoading = false;
    });
  }


  onPageSizeChange(newSize: number): void {
    this.pageSize = newSize;
    this.currentPage = 1; // Reset to the first page when page size changes
    this.loadData(); // Fetch data with new page size
  }

  onPageIndexChange(newPage: number): void {
    this.currentPage = newPage;
    this.loadData(); // Fetch data for the new page
  }




  NewDailyCancel(): void {
    this.cancel.emit();
    this.NewDailyhandle = false;
  }



  hoverStates: { [key: string]: boolean } = {};

  setHovered(item: CustomerResponseResult, isHovered: boolean): void {
    if (item && item.Id) {
      this.hoverStates[item.Id] = isHovered;
    }
  }

  isHovered(item: CustomerResponseResult): boolean {
    return item.Id ? !!this.hoverStates[item.Id] : false;
  }

  getStatusClass(statusCode: string): string {
    let className: string;


    switch (statusCode) {
      case CustomerStatusCode.ACTIVE.code:
        className = 'status-text active';
        break;
      case CustomerStatusCode.INACTIVE.code:
        className = 'status-text inactive';
        break;
      case CustomerStatusCode.DISABLED.code:
        className = 'status-text disabled';
        break;
      case CustomerStatusCode.PENDING.code:
        className = 'status-text pending';
        break;
      default:
        className = 'status-text inactive'; // Default class
    }

    return className;
  }

  listOfColumns: any[] = [
    {
      name: 'Name',
      sortOrder: null,
      compare: (a: CustomerResponseResult, b: CustomerResponseResult) => a.DisplayName.localeCompare(b.DisplayName),
      priority: false
    },
    {
      name: 'Status',
      sortOrder: null,
      compare: (a: CustomerResponseResult, b: CustomerResponseResult) => a.StatusCode.localeCompare(b.StatusCode),
      priority: false

    },
    {
      name: 'Email',
      sortOrder: null,
      compare: (a: CustomerResponseResult, b: CustomerResponseResult) => a.PrimaryEmail.localeCompare(b.PrimaryEmail),
      priority: false
    },
    {
      name: 'Phone',
      sortOrder: null,
      compare: (a: CustomerResponseResult, b: CustomerResponseResult) => a.Phone.localeCompare(b.Phone),
      priority: false
    },
    {
      name: 'Cell',
      sortOrder: null,
      compare: (a: CustomerResponseResult, b: CustomerResponseResult) => a.CellPhone.localeCompare(b.CellPhone),
      priority: false
    },
    {
      name: 'Address',
      sortOrder: null,
      compare: (a: CustomerResponseResult, b: CustomerResponseResult) => a.StreetAddress.localeCompare(b.StreetAddress),
      priority: false
    },
    {
      name: 'City',
      sortOrder: null,
      compare: (a: CustomerResponseResult, b: CustomerResponseResult) => a.CountryCode.localeCompare(b.CountryCode),
      priority: false
    },
    {
      name: 'State',
      sortOrder: null,
      compare: (a: CustomerResponseResult, b: CustomerResponseResult) => a.State.localeCompare(b.State),
      priority: false
    },
    {
      name: 'Zip',
      sortOrder: null,
      compare: (a: CustomerResponseResult, b: CustomerResponseResult) => a.State.localeCompare(b.State), // Fix the sort function if needed
      priority: false
    },
    {
      name: 'Suburb',
      sortOrder: null,
      compare: (a: CustomerResponseResult, b: CustomerResponseResult) => a.Suburb.localeCompare(b.Suburb),
      priority: false
    }
  ];







  onSelectCustomer(dataItem: CustomerResponseResult) {
    this.selectedCustomer.emit(dataItem);
    let data: CustomerResponseResult[] = [dataItem];
    this.selectedCustomerInJob.emit(data);
    this.cancel.emit();
  }

}
