import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { CustomerInformationResponse, CustomerResponseResult, JobGroupResponse } from 'src/Models/CustomerInfromation/CustomerAndLeadOpportunityResponse';
import { jobTypeGroupResponse } from 'src/Models/CustomerInfromation/CustomerInformation';
import { JobInformationResponse } from 'src/Models/Job-Scratch/JobFromScratch';
import { LeadOpportunityResponse, LeadOpportunityResult, LookupNameSetupResponse } from 'src/Models/LeadTags';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { JobInformationService } from 'src/Service/Job-List/Job-Information/job-information.service';
import { JobTypeService } from 'src/Service/Job-List/Job-Information/job-type.service';
import { ContactListComponent } from 'src/app/lead-opportunities/Components/contact-list/contact-list.component';
import { JobScratchPreDefinedCodes } from 'src/app/shared/component/Models/LookUpStandardFilterCodes';

@Component({
  selector: 'app-job-create-from-template',
  templateUrl: './job-create-from-template.component.html',
  styleUrls: ['./job-create-from-template.component.css']
})
export class JobCreateFromTemplateComponent implements OnInit {
  pageId: number = 30002;
  @Output() cancel = new EventEmitter<void>();
  @Input() leadToJobFormData: any;

  //-----  leadToJobFormData: any; issue is after reciveing objects LeadOpportunityResponse bind then templete properties not read and show error thats why used [any] ----///

  @Input() leadOpportunityResponse: LeadOpportunityResult;
  Jobvillsvisible = true;
  selectedValue = 'none';
  newJobTemplateForm: any = {};
  jobTypeList: jobTypeGroupResponse[] = [];
  lookupSetNameResponse: LookupNameSetupResponse[] = [];
  jobGroupForm: FormGroup;
  jobGroupList: JobGroupResponse[] = [];
  JobGroupNodes: NzTreeNodeOptions[] = [];
  contactTypeOption: LookupNameSetupResponse[] = [];
  preDefinedDataCodes = {
    contactType: JobScratchPreDefinedCodes.ContactType,
    JobGroup: JobScratchPreDefinedCodes.JobGroup,
    WorkDays: JobScratchPreDefinedCodes.WorkDays,
  };
  isLoadingOne: boolean = false;
  isLoadingEdit: boolean = false;
  confirmModal?: NzModalRef;
  isDeleteTagsLoading: any;
  jobGroupVisible: boolean = false;
  sourceTemplateOptions:JobInformationResponse[] = [];
  selectedOptionClicked: boolean = false;
  workDaysNodes: NzTreeNodeOptions[] = [];
  PAGE_ID: number = 30002;
  public selectedCustomer: CustomerResponseResult;
  // public selectedCustomers: CustomerInformationResponse[] = [];
  firstName: string;
  lastName: string;
  showAllFields: boolean = false;
  showExistingFields: boolean = false;


  constructor(

    private jobTypeService: JobTypeService,
    private LookupFilterService: LookupFilterService,
    private fb: FormBuilder,
    public toastsService: NzMessageService,
    private modal: NzModalService,
    private jobInformationService: JobInformationService,
    private LookupFilter: LookupFilterService,
  ) {

  }

  ngOnInit(): void {

    this.newJobTemplateForm = {
      globalId: '00000000-0000-0000-0000-000000000000',
      name: '',
      leadOpportunityId: 0,
      jobInformationTypeParameterId: 0,
      createJobGroupParameterRequests: [],
      customerInformationId: 0,
      contractTypeID: '',
      newStartDate: '',
      isMakeScheduleOnline: false,
      firstName: '',
      lastName: '',
      displayName: '',
      jobTemplateId: '',
      createWorkDayParameterRequests: ['1193','1194','1195','1196','1197'],
      createJobInformationUserParameterRequests: [],
    };


    if (this.leadToJobFormData) {
      this.newJobTemplateForm.name = this.leadToJobFormData?.title;
      this.newJobTemplateForm.jobInformationTypeParameterId = this.leadToJobFormData?.jobTypeId;
      this.newJobTemplateForm.contactTypeId = this.leadToJobFormData?.contractTypeId;
    }


    this.initLoad();
    this.getAllJobTemplateData();
    this. getAllJobTempWorkDayData();
    this.iniliazeForm();




  }

  //// --------  Job Group Form Initilize Start ------ /////



  iniliazeForm() {
    this.jobGroupForm = this.fb.group({
      id: [''],
      globalId: ['00000000-0000-0000-0000-000000000000'],
      name: ['', Validators.required],
      companyParameterId: 1,
      formNameId: 30002,
      isFieldValue:false,
      lookUpStandardId: 204,
      parentCode: "JSCG",
      code: "JSCG",
      description:'',
    });
  }

  //// --------  Job Group Form Initilize End ------ /////


  //// --------  All DropDown Binding Methods Start ------ /////


  initLoad() {
    this.jobTypeService.getAllDataJobType().subscribe((res) => {
      console.log('job type',res);
        this.jobTypeList = res.result;
    });

    this.LookupFilterService.getLookUpValuesByFormNameId(this.pageId).subscribe(
      (res) => {
        this.lookupSetNameResponse = res.result;

        let JobGroupValues = res.result.filter(
          (x) => x.code === this.preDefinedDataCodes.JobGroup.code
        );
        this.JobGroupNodes = JobGroupValues.map((JobGroup) => ({
          title: JobGroup.name,
          key: JobGroup.id.toString(),
          isLeaf: true,
        }));


        let contactTypeValues = res.result.filter(
          (x) => x.code === this.preDefinedDataCodes.contactType.code
        );
        this.contactTypeOption = contactTypeValues;

      });


  }

  getAllJobTemplateData(){
    this.jobInformationService.getDataAllJobTemplate().subscribe((response)=>{
      this.sourceTemplateOptions = response.result;
    })
  }
  getAllJobTempWorkDayData(){
    this.LookupFilter.getLookUpValuesByFormNameId(this.PAGE_ID).subscribe(
      (res) => {

        let WorkDaysValues = res.result.filter(
          (x) => x.code === this.preDefinedDataCodes.WorkDays.code
        );

        this.workDaysNodes = WorkDaysValues.map((WorkDays) => ({
          title: WorkDays.description,
          key: WorkDays.id.toString(),
          isLeaf: true,
        }));

      }
    );
  }
  onOptionClicked($event: any) {
    this.selectedOptionClicked = true;
  }


  showContactListComp(event: any) {
    if (event === 'newCostumer') {
      // Set a flag to indicate that fields should be shown
      this.showAllFields = true;
      this.showExistingFields = false; // Reset the existing fields flag
      this.selectedCustomer = null; // Reset selected customer
      this.newJobTemplateForm.customerInformationId = 0;
    } else if (event === 'searchContact') {
      const modalRef = this.modal.create({
        nzContent: ContactListComponent,
        nzFooter: null,
      });

      modalRef.componentInstance.cancel.subscribe(() => {
        modalRef.destroy();
      });

      modalRef.componentInstance.selectedCustomer.subscribe((data:CustomerResponseResult) => {
        this.selectedCustomer = data;
        this.showExistingFields = true;

        console.log('selectedCustomer', this.selectedCustomer);

        if (this.selectedCustomer) {
          this.firstName = this.selectedCustomer.FirstName;
          this.lastName = this.selectedCustomer.LastName;
          this.newJobTemplateForm.customerInformationId = this.selectedCustomer.Id;
        }
      });
    } else {
      // Reset the flag if neither "Create New Contact" nor "Search Existing Contact" is selected
      this.showAllFields = false;
      this.showExistingFields = false;
      // Reset selected customer
      this.selectedCustomer = null;
      // Reset customerInformationId
      this.newJobTemplateForm.customerInformationId = null;
    }
  }


  patchDisplayName() {
    // Combine firstName and lastName to form displayName
    this.newJobTemplateForm.displayName = this.newJobTemplateForm.firstName + ' ' + this.newJobTemplateForm.lastName;
  }


  showFields() {
    return this.newJobTemplateForm['createJobInformationOwnerParameterRequests'] === 'searchContact';
  }


  showFieldsFlag: boolean = false;

  toggleFields() {
    this.showFieldsFlag = !!this.selectedCustomer; // Set showFieldsFlag based on whether a customer is selected
  }




  //// --------  All DropDown Binding Methods End ------ /////


  /////  ------------  Job Group Visibililty Method Start ------------ //////


  secondModelcancel() {
    this.jobGroupVisible = false;
  }
  secondShowWarrranty() {
    this.jobGroupVisible = true;
  }

  JobCancel(): void {
    this.cancel.emit();
  }


  /////  ------------  Job Group Visibililty Method End ------------ //////



  saveTemplateJob() {

    let WorkDays = this.newJobTemplateForm.createWorkDayParameterRequests.map(
      (id: string) =>
      ({ workDayId: id })
    );
    let jobGroup = this.newJobTemplateForm.createJobGroupParameterRequests.map(
      (id: string) => ({ jobGroupId: id })
    );


    this.newJobTemplateForm.createWorkDayParameterRequests = WorkDays;
    this.newJobTemplateForm.createJobGroupParameterRequests = jobGroup;


    console.log('from DAta',this.newJobTemplateForm);

    this.jobInformationService.PostJobTemplateForm(this.newJobTemplateForm).subscribe((response) => {
      this.toastsService.success('New Form Template Successfully!');
      this.Jobvillsvisible = false;
       this.cancel.emit();
    });


  }



}
