import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NewRFIsComponent } from 'src/app/messaging/Components/RFIs/new-rfis/new-rfis.component';
import { RfiInformationPostById } from 'src/Models/_RFIs/RFIs_Infor';
import { RFIsInforServiceService } from 'src/Service/RFIs_InforService/rfis-infor-service.service';
import { NzModalService } from 'ng-zorro-antd/modal';
@Component({
  selector: 'app-rfis-edit-common',
  templateUrl: './rfis-edit-common.component.html',
  styleUrls: ['./rfis-edit-common.component.css']
})
export class RfisEditCommonComponent implements OnInit {
  @Output() cancel = new EventEmitter<void>();
  initializationComplete = new EventEmitter<boolean>();
  @Input() Job_ID: number;
  @Input() Formname_Id: number;
  @Input() header_ID : number;
  @Input() RFIs_Info: number;
  isVisibleInRelatedRFIs: boolean = true;
  RFIsGetById_Response: RfiInformationPostById[] = [];

  constructor(private modal: NzModalService,
    private _RFIsInforService: RFIsInforServiceService,
    ){}

  ngOnInit(): void {
    this.RFIsGetById(this.header_ID);
  }
  CancelRelatedRFIs() {
    this.isVisibleInRelatedRFIs = false;
     this.cancel.emit();
  }
  createRFI(_Id: number): void {
    const modalRef = this.modal.create({
      nzWidth: '0%',
      nzBodyStyle: { 'background': '#f1f4fa', 'padding': '0', 'margin': '0', 'overflow-x': 'hidden' },
      nzStyle: { top: '0px' },
      nzContent: NewRFIsComponent,
      nzFooter: null
    });
    modalRef.componentInstance.Form_Name_Id = this.Formname_Id;
    modalRef.componentInstance.onSelectedJob_Id = this.Job_ID;
    modalRef.componentInstance.header_ID = this.header_ID;
    modalRef.componentInstance.RFIsResponseId = _Id
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    modalRef.componentInstance.RFIs_Response_Is_Complete.subscribe(res => {
      this.RFIsGetById(res?.rfiId || res?.id);
    })
  }
  RFIsGetById(_Id: number) {
    if(_Id){
      this._RFIsInforService.RFIsgetRFIInformation(_Id).subscribe(
        (res) => {
          if (res?.result) {
            this.RFIsGetById_Response = Array.isArray(res.result) ? res.result : [res.result];
            this.initializationComplete.emit(false);
          }
        },
      );
    } else {
      this.initializationComplete.emit(false);
    }
  }
}
