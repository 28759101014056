import { Component, Input, OnInit, AfterViewInit, SimpleChanges, OnChanges } from '@angular/core';
import * as L from 'leaflet';
import axios from 'axios';  // Import axios for HTTP requests
import { LeadOpportunityMetaData } from 'src/Models/LeadTags';
import { LeadOpportunitiesNewLeadOpportunitiesComponent } from '../../Modal/lead-opportunities-new-lead-opportunities/lead-opportunities-new-lead-opportunities.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ListViewFService } from 'src/Service/Sales-List-View-F/list-view-f.service';

interface NominatimResponse {
  lat: string;
  lon: string;
}

@Component({
  selector: 'lead-opportunity-map-graph',
  templateUrl: './lead-opportunity-map-graph.component.html',
  styleUrls: ['./lead-opportunity-map-graph.component.css']
})
export class LeadOpportunityMapGraphComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() data: LeadOpportunityMetaData[] = [];
  private map!: L.Map;
  private citiesLayer!: L.LayerGroup;

  constructor(
    private modal: NzModalService,
    private graphService: ListViewFService
  ) { }

  ngOnInit(): void {
    this.data;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] && this.data.length > 0) {
      console.log('Data changed:', this.data);
      if (!this.map) {
        this.initMap();
      }
      this.addMarkers();
      this.createLayersControl();
    }
  }

  ngAfterViewInit(): void { }

  private initMap(): void {
    const centerCoords: [number, number] = [20.0, 0.0]; // Approximate center of the world

    this.map = L.map('map').setView(centerCoords, 2); // Set zoom level to cover the whole world

    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(this.map);

    this.citiesLayer = L.layerGroup().addTo(this.map);
  }

  private createLayersControl(): void {
    const osm = L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 19 });
    const osmHot = L.tileLayer('https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png', { maxZoom: 19 });
    const openTopoMap = L.tileLayer('https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png', { maxZoom: 19 });

    const parksLayer = L.layerGroup([
      L.marker([39.75, -105.09]).bindPopup('This is Crown Hill Park.'),
      L.marker([39.68, -105.00]).bindPopup('This is Ruby Hill Park.')
    ]);



    // L.control.layers(baseMaps, overlayMaps).addTo(this.map);
  }

  private async addMarkers(): Promise<void> {
    const maxMarkers = 10;  // Adjust as needed
    console.log('Map Data', this.data);

    const addresses = this.data.filter(item => item.StreetAddress).slice(0, maxMarkers);
    console.log('Finding address data', addresses);

    for (const item of addresses) {
      try {
        const latLng = await this.getLatLngFromAddress(item.StreetAddress, item.State, item.PostalCode);
        if (latLng) {
          const popupContent = this.getPopupContent(item);
          this.addMarker(latLng, popupContent,item.Id);
        } else {
          console.log(`No coordinates found for address: ${item.StreetAddress}`);
        }
      } catch (error) {
        console.error('Error adding marker:', error);
      }
    }
  }

  private async getLatLngFromAddress(address: string, state?: string, postalCode?: string): Promise<[number, number] | null> {
    try {
      const fullAddress = `${address}`;
      const response = await axios.get<NominatimResponse[]>('https://nominatim.openstreetmap.org/search', {
        params: {
          q: fullAddress,
          format: 'json',
          limit: 1
        }
      });

      if (response.data.length > 0) {
        const { lat, lon } = response.data[0];
        return [parseFloat(lat), parseFloat(lon)];
      }
    } catch (error) {
      console.error('Error fetching latitude and longitude:', error);
    }
    return null;
  }

  private getPopupContent(item: LeadOpportunityMetaData): string {
    return `
      <div class="popup-content d-flex align-items-center">
        <div class="leftSide me-3">
          <div class="popup-icon">
            <img src="assets/location-icon.png" alt="Location Icon" height="32px" width="32px" />
          </div>
        </div>
        <div class="rightSide">
          <div class="popup-details">
            <a class="popup-title" >${item.Title}</a><br/>
            <div class="customer-name-full">${item.CustomerInformationName}</div>
            ${item.StreetAddress ? `<div class="street-address">${item.StreetAddress}</div>` : '<div>--</div>'}
            <div class="suburb">${item.Suburb}</div>
            ${item.State ? `<div class="state">${item.State}</div>` : ''}
          </div>
        </div>
      </div>
    `;
  }

  private addMarker(latLng: [number, number], popupContent: string, id: number): L.Marker {
    const defaultIcon = L.icon({
      iconUrl: 'assets/location-icon.png',
      iconSize: [32, 32],
      iconAnchor: [15, 40],
      popupAnchor: [0, -40]
    });

    const marker = L.marker(latLng, { icon: defaultIcon })
      .bindPopup(popupContent, { className: 'custom-popup' })
      .on('mouseover', (event: L.LeafletEvent) => {
        const markerElement = event.target.getElement();
        if (markerElement) {
          markerElement.classList.add('bounce'); // Add bouncing effect
          marker.setOpacity(0.7);
        }
      })
      .on('mouseout', (event: L.LeafletEvent) => {
        const markerElement = event.target.getElement();
        if (markerElement) {
          markerElement.classList.remove('bounce'); // Remove bouncing effect
          marker.setOpacity(1);
        }
      });

    this.citiesLayer.addLayer(marker);
    return marker;
  }





  async panToLocation(item: LeadOpportunityMetaData): Promise<void> {
    if (item.StreetAddress) {
      const latLng = await this.getLatLngFromAddress(item.StreetAddress, item.State, item.PostalCode);
      if (latLng) {
        const popupContent = this.getPopupContent(item);

        this.citiesLayer.clearLayers(); // Clear previous markers

        // Pass item.Id as the third argument to addMarker
        const marker = this.addMarker(latLng, popupContent, item.Id);
        this.map.flyTo(latLng, 15, {
          animate: true,
          duration: 1.5
        });

        setTimeout(() => {
          marker.openPopup();
        }, 1500);
      }
    }
  }


  leadOpportunityFormEdit(Id: number): void {
    const modalRef = this.modal.create({
      nzContent: LeadOpportunitiesNewLeadOpportunitiesComponent,
      nzFooter: null
    });
    modalRef.componentInstance.leadOpportunityResponseId = Id;
    modalRef.componentInstance.JobMenuButton = true;

    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });

    modalRef.componentInstance.leadOpportunityOnCompleted.subscribe(() => {
      this.graphService.triggerDataUpdate();
    });
  }
}
