import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NZ_MODAL_DATA, NzModalService } from 'ng-zorro-antd/modal';
import { WarrantyNewClaimWarrantyCategoryComponent } from '../warranty-new-claim-warranty-category/warranty-new-claim-warranty-category.component';
import { WarrantyNewClaimEditWarrantyCategoryComponent } from '../warranty-new-claim-edit-warranty-category/warranty-new-claim-edit-warranty-category.component';
import { ProjectManagementWarrantyPreDefinedCodes } from 'src/app/shared/component/Models/LookUpStandardFilterCodes';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { CustomerInformationService } from 'src/Service/customer-information.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoadingIndicatorService } from 'src/shared/Shared-Services/loading-indicator.service';
import { LeadOpportunitiesService } from 'src/Service/lead-opportunities.service';
import { LookupNameSetupResponse, ScheduleItemParameterResponse, } from 'src/Models/LeadTags';
import { LeadOpportunityAttachmentsUploadFilesComponent } from 'src/app/lead-opportunities/Components/Modal/lead-opportunity-attachments-upload-files/lead-opportunity-attachments-upload-files.component';
import { CompletedWarrantyServiceComponent } from 'src/app/completed-warranty-service/completed-warranty-service.component';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { WarrantyService } from 'src/Service/Project-Management/warranty/warranty.service';
import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';
import { NzSizeDSType, NzStatus } from 'ng-zorro-antd/core/types';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { AppointmentResposne, ResultItemResponse, WarrantyClaimform, WarrantyGetallbyId, WarrantyInformationResponse, WarrantyServiceResponse } from 'src/Models/Warranty/warranty';
import { Subscription } from 'rxjs';
import { AttachmentService } from 'src/Service/Attachment/attachment.service';
import { saveAttachments } from 'src/app/helpers/Attachment_Save';
import { AuditDetailsResponse, FilterFooterAudit } from 'src/Models/Footer/footer.model';
import { FooterService } from 'src/Service/Footer/footer.service';
import { AttachmentResponse, CustomUploadFile } from 'src/Models/Attachment_Files_Class/AttachmentFilesClass';
import { JobInformationService } from 'src/Service/Job-List/Job-Information/job-information.service';
import { JobShortInformation } from 'src/Models/Job-Scratch/JobFromScratch';
import { CreateAttachmentsCommonComponent } from 'src/app/shared/component/create-attachments-common/create-attachments-common.component';
import { BehaviorsubjectService } from 'src/Service/behaviorsubject/behaviorsubject.service';

@Component({
  selector: 'app-warranty-new-claim',
  templateUrl: './warranty-new-claim.component.html',
  styleUrls: ['./warranty-new-claim.component.css']
})
export class WarrantyNewClaimComponent implements OnInit {
  @Output() cancel = new EventEmitter<void>();
  @Output() onSaveCompleteService = new EventEmitter();
  @Output() onSaveCompletewarranty = new EventEmitter<WarrantyClaimform>();
  @Output() onSaveComplete = new EventEmitter<WarrantyClaimform>();
  @Input() warrrantyId: number;
  @Input() comments: any;
  @Input() responseHeaderId: number;
  @Input() warrrantyserviceId: number;
  @Input() Onselected_JobId: number;
  @Input() Onselected_JobName: string;
  @Output() byIdSetWarrantClaim: number;
  initializationComplete = new EventEmitter<boolean>();
  public dataWarrantyService: WarrantyGetallbyId | null = null;
  public isFieldTouched: boolean = false;
  public responseHeaderIdRes: WarrantyInformationResponse;
  public HeaderId: number;
  private dataSubscription: Subscription;
  public isTagVisible = false;
  public ModalTitle: ModalTitle;

  currentIndex = 0;
  Messaging_Id: number;
  WarrantyIds: number;
  formNameId: number = 10002;
  JobInfoId: number;
  job_Warn_ID: number;
  formNameID: number = 10002;
  messagingId: number;
  messagingIdRow: number;
  newResponseHeaderId: number;
  mainSaveResponseId: number | undefined;
  PAGE_ID: number = 30005;
  PAGEID: number = 10002;
  _warrantyResponceId: number;
  scheduleId: number;
  selectedColorId: number;
  selectedOption: string;
  userImage: string;
  statusMessage: string;
  selectedJobName: string;
  rfiInformationId: string;
  job_Warn_Name: string;
  job_Warn_Names: string;
  compareDate: string = 'Date';
  selectedValues = 0;
  tooltipData: any | null = null;
  tooltipMessage: string | null = null;


  newWarrantyId: any;
  TagsForm: FormGroup;
  small: NzSizeDSType;
  selectedValue = null;
  warrantyResponses: WarrantyClaimform;
  footerResponse: AuditDetailsResponse;
  WarrantyClaimformesponses: WarrantyClaimform;
  onJobShortInformation: JobShortInformation;
  onJobInformationWarranties: JobShortInformation;
  TestAttachment: AttachmentResponse;
  testingHide: CustomUploadFile;

  isDeleteTagsLoading: boolean;
  showMessageAfterSave: boolean;
  _IsOwnerFlag: boolean;
  isFeedbackRequired: boolean;
  Taghandle = true;
  isformSaved = true;
  isFormSaved = false;
  isFormSubmitted = false;
  showNavButtons = false;
  isSave: boolean = false;
  isSaved: boolean = false;
  editModeActivated = false;
  resetTags: boolean = false;
  isLoading: boolean = false;
  isSubmitted: boolean = false;
  resetSource: boolean = false;
  isSaveAndNew: boolean = false;
  isLoadingOne: boolean = false;
  isLoadingEdit: boolean = false;
  isSaveAndClose: boolean = false;
  WarrantyNewClaimComponent = true;
  resetProjectType: boolean = false;
  isEditCardVisible: boolean = false;
  fromScheduleButton: boolean = false;
  showAdditionalFields: boolean = false;
  DisabledNextActivity: boolean = false;

  feedOn: Date[];
  warrantiess: number[];
  formFieldValues: any = {};
  selectedFiles: File[] = [];
  fileList: NzUploadFile[] = [];
  warrantyFieldValues: any = {};
  initialFormFieldValues: any = {};
  warranties: WarrantyGetallbyId[] = [];
  attachments: AttachmentResponse[] = [];
  attendeesNodes: NzTreeNodeOptions[] = [];
  jobInfoOptions: JobShortInformation[] = [];
  Appointment: AppointmentResposne[] = [];
  warrantydata: WarrantyServiceResponse[] = [];
  selectedFiless: CustomUploadFile[] = [];
  ScheduleJobdata: ResultItemResponse[] = [];
  touchedFields: { [key: string]: boolean } = {};
  responseData: WarrantyServiceResponse[] = [];
  AutoNumberExistingFormFieldsValues: any = {};
  validationStates: { [key: string]: NzStatus } = {};
  validationMessages: { [key: string]: string } = {};
  changedFields: Set<string> = new Set<string>();
  selectedFilesAttachment: CustomUploadFile[] = [];
  categoryOption: LookupNameSetupResponse[] = [];
  warrantyServiceData: WarrantyServiceResponse[] = [];
  classificationResponse: LookupNameSetupResponse[] = [];
  lookupSetNameResponse: LookupNameSetupResponse[] = [];
  priorityOption: { label: string; value: string }[] = [];
  serviceCoordinator: { label: string; value: string }[] = [];
  classificationOption: LookupNameSetupResponse[] = [];
  scheduleItemOption: ScheduleItemParameterResponse[] = [];
  coordinator: { title: string; key: string; isLeaf: boolean; }[];
  confirmModal: import("ng-zorro-antd/modal").NzModalRef<unknown, any>;
  @ViewChild(CreateAttachmentsCommonComponent) attachmentsComponent!: CreateAttachmentsCommonComponent;
  @ViewChild('carouselContainer', { static: false }) carouselContainer!: ElementRef<HTMLDivElement>;

  isFieldEmpty: any = {
    title: false,
    classificationSetupId: false
  };

  preDefinedDataCodes = {
    Category: ProjectManagementWarrantyPreDefinedCodes.Category,
    Priority: ProjectManagementWarrantyPreDefinedCodes.Priority,
    Coordinator: ProjectManagementWarrantyPreDefinedCodes.Coordinator,
    Classifications: ProjectManagementWarrantyPreDefinedCodes.Classifications
  };

  constructor(private modal: NzModalService,
    private FooterService: FooterService,
    private LookupFilter: LookupFilterService,
    private toastService: NzMessageService,
    private CustomerInformationService: CustomerInformationService,
    private cdn: ChangeDetectorRef,
    public toastsService: NzMessageService,
    private fb: FormBuilder,
    private loadingIndicatoreService: LoadingIndicatorService,
    private jobInfoService: JobInformationService,
    private warrantyService: WarrantyService,
    private leadOppService: LeadOpportunitiesService,
    private _AttachmentService: AttachmentService,
    public localStorage: AccessLocalStorageService,
    private localStorageService: AccessLocalStorageService,
    private _BehaviorSubjectService: BehaviorsubjectService,
    @Inject(NZ_MODAL_DATA) public JobData: any,
    @Inject(NZ_MODAL_DATA) public DataRfis: any,
    @Inject(NZ_MODAL_DATA) public JOBDATA: any,
    @Inject(NZ_MODAL_DATA) public leadData: any,
    @Inject(NZ_MODAL_DATA) public WarrantyInformation: any,
    @Inject(NZ_MODAL_DATA) public JOB_NAME: any,

  ) {
  }
  ngOnInit(): void {
    if(this.warrrantyId){
      this.getWarrantiesById(this.warrrantyId);
    }
    if (this.Onselected_JobId > 0) {
      this.fetchWarrantiesClaimJob(this.Onselected_JobId);
    }
    if(this.Onselected_JobId){
      this.getScheduleJob(this.Onselected_JobId);
    }
    if (this.warrrantyId) {
      this.fetchActivityResponse(this.warrrantyId);
    }
    this.ForCombo();
    this.filedsBindRecord();
    this.initLoadWarrantycategory();
    this.getScheduleItem();
    this.job_Warn_ID = this.localStorageService.getJobId();
    this.job_Warn_Names = this.localStorageService.getJobName();
    this.messagingId = this.WarrantyInformation?.WarrantyInformation?.messagingId;
    this.messagingIdRow = this.WarrantyInformation?.WarrantyInformation?.id;
    const storeComments = this.WarrantyInformation?.WarrantyInformation?.messaging?.messagingParameters.map(param => param.comments)
    this.comments = storeComments?.flat() || [];
    
    this.warrantyService.getWarrantyDatas().subscribe(data => {
      this.responseData = data;
      const userId = data?.map(x => x.OwnerFeedbackId);
      console.log('Received warranty data IDs:', userId);
      if (userId?.length > 0) {
        const imageUrl = this.getImageForId(userId[0]);
        console.log('Setting image for user ID:', userId[0], ' Image URL:', imageUrl);
        this.userImage = imageUrl;
      }
    });

    this.leadOppService.getSelectedFiles().subscribe(files => {
      this.selectedFiles = files;
    });

    this.warrantyService.getWarrantyDatas()?.subscribe(Newdata => {
      this.warrantiess = Newdata?.map(x => x.Id)
      this.warranties = Newdata?.map(warranty => {
        console.log('show all comple closed data @#', this.warranties);
        return {
          ...warranty,
          imageUrl: this.getImageForId(warranty?.OwnerFeedbackId) // Assign imageUrl for each warranty
        };
      });
      const userIds = Newdata?.map(x => x?.OwnerFeedbackId);
      const _feedBackOnDate = Newdata?.map(x => x?.FeedBackOn);
      this.feedOn = _feedBackOnDate
      console.log('Received warranty data IDs:', userIds);
      const _userBuliderAccep = Newdata?.map(x => x?.IsBuilderAccepted);
      console.log('Received warranty data _userBuliderAccep:', _userBuliderAccep);
      if (this.warranties?.length > 0) {
        const imageUrl = this.getImageForId(userIds[0]);
        console.log('Setting image for user ID:', userIds[0], ' Image URL:', imageUrl);
        this.userImage = imageUrl;
      }
    });
    this.warrantyFieldValues['autoAssignNumber'] = this.Onselected_JobName;
    this.warrantyFieldValues = {
      globalId: '00000000-0000-0000-0000-000000000000',
      warrantySetupId: 1,
      jobInformationId: this.Onselected_JobId,
      assignToUserId: null,
      autoAssignNumber: '',
      completionDate: '',
      completionTime: '',
      idNumber: '',
      title: '',
      warrantyCategorySetupId: 71402,
      prioritySetupId: null,
      problemDescription: '',
      internalComments: '',
      isShowOwner: true,
      coordinatorUserId: 0,
      classificationSetupId: 0,
      orignalScheduleItemParameterId: null,
      addedCost: '',
      statusSetupId: '',
      followUpOn: null,
    };
    this.warrantyFieldValues['title'] = this.WarrantyInformation?.WarrantyInformation?.title || '';
    this.initializationComplete.emit(false);
  }

  filedsBindRecord() {
    this.LookupFilter.getLookUpValuesByFormNameId(this.PAGEID)?.subscribe(
      (res) => {
        this.lookupSetNameResponse = res?.result;

        let priorityValues = res?.result?.filter(
          (x) => x.code === this.preDefinedDataCodes.Priority.code
        );
        this.priorityOption = priorityValues.map((Priority) => ({
          label: Priority.name,
          value: Priority.id.toString(),
        }));

        let classificationOption = res?.result?.filter(
          (x) => x.code === this.preDefinedDataCodes.Classifications.code
        );
        this.classificationOption = classificationOption;
        {
          console.log("'Meets Warranty Standards (closed)' classification not found.");
        }
      });
  }

  initLoadWarrantycategory(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.LookupFilter.getLookUpValuesByFormNameId(this.PAGEID)?.subscribe(
        (res) => {
          this.lookupSetNameResponse = res.result;
          let _categorywarrtyValues = res.result.filter(
            (x) => x.code === this.preDefinedDataCodes.Category.code
          );
          this.categoryOption = _categorywarrtyValues
          this.setInitialFormFieldValues();
          resolve();
        },
        (error) => {
          console.error('Error loading lookup values:', error);
          reject(error);
        }
      );
    });
  }

  setInitialFormFieldValues(): void {
    this.initialFormFieldValues = JSON.parse(JSON.stringify(this.warrantyFieldValues));
  }

  onFieldChanges(field: string, value: any) {
    if (field === 'classificationSetupId') {
      const selectedOption = this.classificationOption.find(option => option.id === value);
      if (selectedOption &&
        (selectedOption.name === 'Meets Warranty Standards(closed)' || selectedOption.name === 'Service Completed (closed)')) {
        this.showAdditionalFields = true;
        console.log('Additional fields shown');
      } else {
        this.showAdditionalFields = false;
        console.log('Additional fields hidden');
      }
    }
  }


  ForCombo() {
    this.CustomerInformationService.getDataforcombo().subscribe((res) => {
      const serviceCoordinator = res.result;
      const attendees = res.result;
      this.serviceCoordinator = serviceCoordinator.map((AUser) => ({
        label: AUser.fullName,
        value: AUser.id.toString(),
      }));
      this.attendeesNodes = attendees.map((Adees) => ({
        title: Adees.fullName,
        key: Adees.id.toString(),
        isLeaf: true,
      }));
    });

  }
  ngOnDestroy() {
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
  }
  Add(): void {
    const modalRef = this.modal.create({
      nzContent: WarrantyNewClaimWarrantyCategoryComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }

  // selectionChoiceModel
  Edit(): void {
    const modalRef = this.modal.create({
      nzContent: WarrantyNewClaimEditWarrantyCategoryComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  UploadFiles(): void {
    const modalRef = this.modal.create({
      nzContent: LeadOpportunityAttachmentsUploadFilesComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }

  OpenLogCompletedService(flag: boolean, id: number | undefined): void {
       this.isLoading = true;
      if (!id || !this.warrantyFieldValues) {
        this.toastService.error('Invalid data or warranty');
        return;
      }
      const selectedClassification = this.warrantyResponses?.ClassificationSetupId;
      if (selectedClassification && ([589, 592, 596].includes(selectedClassification))
      ) {
        this.toastService.error('You cannot create warranty services for this warranty');
        return;
      }
      const modalRef = this.modal.create({
        nzContent: CompletedWarrantyServiceComponent,
        nzFooter: null,
      });
      modalRef.componentInstance.isSaveButtonClicked = flag;
      modalRef.componentInstance.isShowOwner = this._IsOwnerFlag;
      // modalRef.componentInstance.responseHeaderId = this.responseHeaderId;
      modalRef.componentInstance.responseHeaderIdByWarrantyClaim = id;
      modalRef.componentInstance.isCheckClicked = true;
      modalRef.componentInstance.dateFlag = false;
      modalRef.componentInstance.cancel.subscribe(() => {
        modalRef.destroy();
      });
      modalRef.componentInstance.initializationComplete.subscribe((res) => {
        this.isLoading = res
      });
      modalRef.componentInstance.WarrantyResponse.subscribe((data: any) => {
        this.dataWarrantyService = data;
        console.log("RESPONSE DATA:", this.dataWarrantyService);
        this.warranties.push(this.dataWarrantyService);  // Add the response data to your service data array
      });
    
  }

  showTags(fieldType: string, pCode: string, code: string, stndId: number): void {
    this.filedsBindRecord();
    this.loadingIndicatoreService.show();
    setTimeout(() => {
      this.loadingIndicatoreService.hide();
      this.isTagVisible = true;
      this.isLoadingEdit = false;
    }, 1000);

    // Update the dynamic title based on the field type
    switch (fieldType) {
      case 'Category':
        this.ModalTitle = { title: 'Add Warranty Category', deleteTitle: 'Add Warranty Category' };
        this.TagsForm.controls['code'].setValue(code);
        this.TagsForm.controls['parentCode'].setValue(pCode);
        this.TagsForm.controls['lookUpStandardId'].setValue(stndId);

        break;
      default:
        this.ModalTitle = { title: 'Add/Edit Field', deleteTitle: 'Add/Edit Field' };

        break;
    }

  }

  showTagEdit(selectedValue: LookupNameSetupResponse, fieldType: string): void {
    if (selectedValue) {
      const selectedId: number = Number(selectedValue); // Convert the selected ID to number
      this.isTagVisible = true;
      this.isLoadingEdit = true;
      const selectedObjectFromLookup = this.lookupSetNameResponse.find(item => item.id === selectedId);
      if (selectedObjectFromLookup) {
        const selectedData = selectedObjectFromLookup;
        if (selectedData) {
          this.TagsForm.controls['name'].setValue(selectedData.name);
          this.TagsForm.controls['globalId'].setValue(selectedData.globalId);
          this.TagsForm.controls['isFieldValue'].setValue(selectedData.isFieldValue);
          this.TagsForm.controls['companyParameterId'].setValue(selectedData.companyParameterId);
          this.TagsForm.controls['formNameId'].setValue(selectedData.formNameId);
          this.TagsForm.controls['lookUpStandardId'].setValue(selectedData.lookUpStandardId);
          this.TagsForm.controls['code'].setValue(selectedData.code);
          this.TagsForm.controls['description'].setValue(selectedData.description);
          this.TagsForm.controls['parentCode'].setValue(selectedData.parentCode);
        }
      }
    }

    switch (fieldType) {
      case 'Category':
        this.ModalTitle = { title: 'Edit Warranty Category', deleteTitle: 'Edit Warranty Category' };
        this.resetSource = true;
        this.resetTags = false;
        this.resetProjectType = false;
        break;

      default:
        this.ModalTitle = { title: 'Add/Edit Field', deleteTitle: 'Add/Edit Field' };
        break;
    }
  }

  TagClose(): void {
    this.cancel.emit();
  }
  getScheduleItem() {
    this.leadOppService.getAllDataScheduleItems()?.subscribe(res => {
      if (Array.isArray(res.result)) {
        this.scheduleItemOption = res.result;
      }
    });
  }

  saveFileIdToDatabase(): void {
  }
  generateAutoAssignNumber(): string {
    return 'AUTO-123456';  // You can replace this with actual logic
  }

  saveWarrantyForm(actionType: 'save' | 'saveAndClose' | 'saveAndNew' = 'save'): Promise<void> {
    this.isFormSaved = false;
    this.isFeedbackRequired = false;

    return new Promise((resolve, reject) => {
      if (this.isSave || this.isSaveAndClose || this.isSaveAndNew) {
        return reject('Already processing');
      }

      if (actionType === 'saveAndNew') {
        this.isSaveAndNew = true;
      } else if (actionType === 'saveAndClose') {
        this.isSaveAndClose = true;
      } else {
        this.isSave = true;
      }
      this.isSubmitted = true;
      this.DisabledNextActivity = true;
      this.checkErrors();

      if (Object.values(this.validationStates).includes('error')) {
        setTimeout(() => {
          const errorElements = document.querySelectorAll('.error-message');
          if (errorElements.length > 0) {
            const lastErrorElement = errorElements[errorElements.length - 1];
            lastErrorElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        }, 100);

        this.resetFlags();
        this.DisabledNextActivity = false;
        this.loadingIndicatoreService.hide();
        throw new Error('Validation errors present');
      }


      const selectedClassification = this.classificationOption.find(
        option => option.id === this.warrantyFieldValues.classificationSetupId
      );
      if (selectedClassification && [592, 596, 589].includes(selectedClassification.id)) {
        const matchingWarranty = this.warranties.find(warranty =>
          warranty.OwnerFeedbackId
        );
        if (matchingWarranty) {
          this.isFeedbackRequired = true;
          this.toastsService.error("An error has occurred. Please try again.");
          this.loadingIndicatoreService.hide();
          this.isSave = false;
          this.isSubmitted = true;
          this.DisabledNextActivity = false;
          this.checkErrors();
          return;
        }
      }
      if (selectedClassification) {
        switch (selectedClassification.id) {
          case 596: case 589: case 592:
            this.warrantyFieldValues.statusSetupId = 582;
            this.statusMessage = 'Status: Closed';
            break;
          case 584: case 585: case 586: case 587: case 588: case 590: case 591: case 593: case 594: case 595:
            this.warrantyFieldValues.statusSetupId = 578;
            this.statusMessage = 'Status: Open';
            break;
          default:
            this.warrantyFieldValues.statusSetupId = null;
            this.statusMessage = 'Status: Open';
        }
      }
      const formDataToSend = {
        ...this.warrantyFieldValues,
      };
      if (!this.warrantyFieldValues.statusSetupId) {
        formDataToSend.statusSetupId = null;
      }
      this.AutoNumberExistingFormFieldsValues = {
        jobInformationId: this.onJobInformationWarranties?.id,
        autoAssignNumber: this.warrantyFieldValues.autoAssignNumber,
        formNameId: 10002,
      }
      this.warrantyService.PostChangeOrderAndAutoNumberExisting(this.AutoNumberExistingFormFieldsValues).subscribe(res => {
        if (res?.result === true && this.warrantyFieldValues.globalId == '00000000-0000-0000-0000-000000000000') {
          this.toastService.error('The Custom CO# has already been used for this jobsite');
          this.resetFlags();
          this.DisabledNextActivity = false;
          return reject('Validation errors present');
        } else {
          this.warrantyService.postWarrantyData(formDataToSend).subscribe(
            (response) => {
              this.toastsService.success('Warranty saved successfully!');
              this.onSaveComplete.emit(this.WarrantyClaimformesponses);
              this.warrantyResponses = response?.result;
              this._warrantyResponceId = response?.result?.Id
              this._IsOwnerFlag = this.warrantyResponses?.IsShowOwner;
              this.JobInfoId = response.result?.JobInformationId;
              console.log('this.warrantyResponses', this.JobInfoId);

              if (response.result.Id > 0) {
                this.responseHeaderId = response.result.Id;
                console.log('ye hai _id', this.responseHeaderId);
              }

              this.warrantyFieldValues.globalId = response.result?.GlobalId;
              this.isFormSaved = true;

              if (this.selectedFilesAttachment?.length > 0) {
                saveAttachments(this.selectedFilesAttachment, this._AttachmentService, response.result?.Id, 10002)
                  .then((attachmentResponseId) => {
                    if (attachmentResponseId > 0) {
                      this.selectedFilesAttachment = []
                      this.fetchActivityResponse(response.result?.Id);
                      this._BehaviorSubjectService.setLeadActivityTypeResponse(response.result);
                    }
                    resolve();
                  })
                  .catch((error) => {
                    reject(error);
                  });
              } else {
                this.fetchActivityResponse(response.result?.Id);
                this._BehaviorSubjectService.setLeadActivityTypeResponse(response.result);
                resolve();
              }
              if (actionType === 'saveAndNew') {
                this.warrantyResponses = null;
                this.warranties = null;
                this.isFormSaved = false;
                this.resetForm();
              }
              else if (actionType === 'saveAndClose') {
                this.closeForm();
              }
              else {
                this.warrantyResponses = response?.result;
              }
              resolve();
            },
            (error) => {
              this.toastsService.error('Failed to save warranty. Please try again.');
              this.isFormSaved = false;
              reject(error);
            }
          ).add(() => {
            this.DisabledNextActivity = false;
            this.resetFlags();
          });
        }
      });
    });
  }

  saveAndClosewwarranty(): Promise<void> {
    return this.saveWarrantyForm('saveAndClose').then(() => {
      this.resetForm();

      this.WarrantyFromCancel();
    }).catch((error) => {
      console.error('Error during save and close:', error);
    });
  }

  saveAndNewwarranty() {
    this.saveWarrantyForm('saveAndNew').then(() => {
      this.warrantyResponses = null;
      this.resetForm();
      this.warrantyFieldValues = {}; // Reset the form values
      this.warranties = null;
      this.ForCombo();
      this.resetAttachments();
    }).catch((error) => {
      console.error('Error during save and new:', error);
    });
  }

  onFilesUploaded(files: CustomUploadFile[]): void {
    this.selectedFilesAttachment = files
  }

  closeForm() {
    this.isSaveAndClose = false;
  }

  resetFlags() {
    this.isSave = false;
    this.isSaveAndClose = false;
    this.isSaveAndNew = false;
  }

  resetForm(): void {
    const savedStatusSetupId = this.warrantyFieldValues.statusSetupId;

    this.warrantyFieldValues = {
      classificationSetupId: null,
      statusSetupId: savedStatusSetupId ?? null,
      autoAssignNumber: '',
      globalId: '',
    };

    this.warrantyResponses = null;
    this.isSubmitted = false;

    // Reset validation states, messages, and touched fields
    this.validationStates = {};
    this.validationMessages = {};
    this.touchedFields = {};

    // Refresh view if needed
    this.cdn.detectChanges();

    // Re-check errors for a clean state
    this.checkErrors = null;
  }
  resetAttachments() {
    this.TestAttachment = null;
    this.selectedFilesAttachment = [];
  }

  onWarrantyChange(): void {
    this.attachmentsComponent.clearAttachment();
  }



  deleteWarantyConfirm(): void {
    if (this.isDeleteTagsLoading) {
      return;
    }
    this.modal.confirm({
      nzTitle: `Delete Warranty?`,
      nzContent: 'Are you sure you want to permanently delete this Warranty?',
      nzOkText: `Delete`,
      nzOkDanger: true,
      nzCancelText: 'Cancel',
      nzOnOk: () => {
        this.isDeleteTagsLoading = true;
        return this.deleteActivity(this.warrantyFieldValues.globalId)
          .then(() => {
            this.WarrantyFromCancel();
          })
          .catch(() => {

          });
      },
      nzCentered: true,
      nzOkLoading: this.isDeleteTagsLoading,
      nzBodyStyle: { 'border-radius': '50px', 'height': 'auto', 'padding-top': '15' },
      nzCloseIcon: '',
      nzIconType: ''
    });
  }

  deleteActivity(globalId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!globalId) {
        this.toastsService.error('No record selected for deletion.');
        this.isDeleteTagsLoading = false;
        reject();
        return;
      }

      this.warrantyService.deleteWarrantyData(globalId).subscribe(
        () => {
          this.isDeleteTagsLoading = false;
          this.toastsService.success(`Deleted User!`);
          this.onSaveComplete.emit(this.warrantyResponses);
          this.WarrantyFromCancel();
          resolve();
          reject();
        },
        () => {
          this.isDeleteTagsLoading = false;
          this.toastsService.error('An error occurred while deleting the record. Please try again.');
          reject();
        }
      );
    });
  }


  WarrantyFromCancel(): void {
    this.cancel.emit();
    this.Taghandle = false;
  }

  FormEditWarrantyservice(Id: number, ID_s: number, Falg: boolean): void {
    this.isLoading = true;
    const modalRef = this.modal.create({
      nzContent: CompletedWarrantyServiceComponent,
      nzFooter: null
    });
    modalRef.componentInstance.warrrantyserviceId = Id;
    modalRef.componentInstance.responseHeaderIdByWarrantyClaim = ID_s;
    modalRef.componentInstance.acceptedBuilderById = Falg;
    modalRef.componentInstance.isShowOwner = this._IsOwnerFlag;
    // modalRef.componentInstance.feedbackOnDate = this.warranties;
    modalRef.componentInstance.cancel.subscribe(() => {
      if (this.warrrantyId) {
        this.getWarrantiesById(this.warrrantyId);
      }
      this.cdn.detectChanges();
      modalRef.destroy();
    });
    modalRef.componentInstance.initializationComplete.subscribe((res) => {
      this.isLoading = res
    });
  }

  NewPatch(warrantyResponses: WarrantyClaimform) {
    if (!WarrantyService) {
      console.error('No warranty service data available for patching.');
      return;
    }
    this.Messaging_Id = warrantyResponses?.MessagingId;
    this.JobInfoId = warrantyResponses?.JobInformationId;
    this._warrantyResponceId = warrantyResponses?.Id;
    this.WarrantyIds = warrantyResponses?.Id;
    this.responseHeaderId = warrantyResponses?.Id;
    const DataSet = warrantyResponses;
    this.warrantyFieldValues = {
      ...this.warrantyFieldValues,
      globalId: DataSet?.GlobalId || '00000000-0000-0000-0000-000000000000',
      id: DataSet?.Id,
      warrantySetupId: DataSet?.WarrantySetupId,
      jobInformationId: DataSet?.JobInformationId,
      autoAssignNumber: DataSet?.AutoAssignNumber,
      orignalScheduleItemParameterId: DataSet?.OrignalScheduleItemParameterId,
      idNumber: DataSet?.IdNumber,
      title: DataSet?.Title || this.warrantyFieldValues.title,
      warrantyCategorySetupId: DataSet?.WarrantyCategorySetupId,
      prioritySetupId: DataSet?.PrioritySetupId?.toString(),
      problemDescription: DataSet?.ProblemDescription,
      internalComments: DataSet?.InternalComments,
      isShowOwner: DataSet?.IsShowOwner,
      coordinatorUserId: DataSet?.CoordinatorUserId?.toString(),
      classificationSetupId: DataSet?.ClassificationSetupId,
      addedCost: DataSet?.AddedCost,
      followUpOn: DataSet?.FollowUpOn,
    };
    console.log("show all patch data", DataSet);

    this.fetchAttachments(DataSet?.AttachmentId);

    this.createAndModifiedByData(this.warrrantyId);
  }

  fetchAttachments(AttachmentId: number) {
    if (AttachmentId) {
      this._AttachmentService.getLeadByAttachmentId(AttachmentId).subscribe(
        (res) => {
          this.TestAttachment = res.result
        },
      );
    } else {
      this.TestAttachment = undefined;
    }
  }
  private fetchActivityResponse(_Id: number): void {
    if (_Id) {
      this.warrantyService.WarrantyGetbyId(_Id)?.subscribe((res) => {
        if (res && res?.result) {
          console.log("API response:", res);
          this.warrantyResponses = res?.result;
          this._IsOwnerFlag = this.warrantyResponses?.IsShowOwner
          const setPatchIdCheck = res?.result?.Id
          this.byIdSetWarrantClaim = setPatchIdCheck
          this.NewPatch(this.warrantyResponses);
          this.fetch_WarrantyServiceData(this.warrantyResponses?.Id);
          if (res.result?.JobInformationId > 0) {
            this.fetchWarrantiesClaimJob(res.result?.JobInformationId);
            this.getTooltipWarrantyClaim(res.result?.JobInformationId);
          }
        } else {
          console.error('Invalid response from API:', res);
        }
      });
    }
  }

  public fetch_WarrantyServiceData(_ID_SERVICE : number): void {
    this.warrantyService.WarrantyWarrantyServicegetById(_ID_SERVICE).subscribe((res) => {
      if (res && res?.result) {
        console.log("API response:", res);
        this.warrantyResponses = res?.result;
      } else {
        console.error('Invalid response from API:', res);
      }
    });
  }

  getWarrantiesById(_Id_byWarnService:number): void {
    this.warrantyService.WarrantyWarrantyService(_Id_byWarnService).subscribe(response => {
      if (response && response.result) {
        if (Array.isArray(response.result) && response.result.length > 0) {
          this.warranties = response.result.map(warranty => ({
            ...warranty,
            imageUrl: this.getImageForId(warranty.OwnerFeedbackId)
          }));
        } else if (response.result.OwnerFeedbackId) {
          this.warranties = [{
            ...response.result,
            imageUrl: this.getImageForId(response.result.OwnerFeedbackId)
          }];
        } else {
          this.warranties = [];
        }
      } else {
        this.warranties = [];
      }
      this.cdn.detectChanges();
    }, error => {
      console.error('Error fetching warranties:', error);
      this.warranties = [];
      this.cdn.detectChanges();
    });
  }

  fetchWarrantiesClaimJob(_Id: number): void {
    this.jobInfoService.onGetJobShortInformation().subscribe((res) => {
      this.onJobInformationWarranties = res?.result?.find(item => item?.id === _Id);
      this.Onselected_JobName = this.localStorageService.getJobName();
      console.log('job_Warn_Name New', this.job_Warn_Name);
    });
  }


  createAndModifiedByData(id: number) {
    const payload: FilterFooterAudit = {
      id: id,
      formCode: 'PMW'
    }
    this.FooterService.createdAndModifyUser(payload)?.subscribe(res => {
      this.footerResponse = res?.result;

    })
  }

  onDateChange(date: Date | null): void {
    if (date) {
      const selectedDate = new Date(date);
      const currentTime = new Date();
      selectedDate.setHours(currentTime.getHours(), currentTime.getMinutes(), 0, 0);

      this.warrantyFieldValues['completionDate'] = selectedDate;
      this.touchedFields['completionDate'] = true;

      this.onFieldChange('completionTime', selectedDate);
    }
  }


  getTooltipWarrantyClaim(_Id_Job: number): void {
    this.warrantyService.onWarrantyGetByTooltipValue(_Id_Job).subscribe({
      next: (response) => {
        if (response.messageType === 1) {
          this.tooltipMessage = response?.result
            ?.split(',')
            .map(item => item.trim())
            .join(',\n');
        }
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  getScheduleJob(_Id_Job_Warranty): void {
    this.warrantyService.GetScheduleInJob(_Id_Job_Warranty).subscribe({
      next: (response) => {
        if (Array.isArray(response.result)) {
          this.ScheduleJobdata = response?.result;
        };
      }

    });
  }


  getImageForId(id: number): string {
    let imageUrl: string;
    switch (id) {
      case 71001:
        imageUrl = 'assets/warrantyImages/checked.png'; // Checked image
        break;
      case 71002:
        imageUrl = 'assets/warrantyImages/subNoShow.png';  // Needs rework image
        break;
      case 71003:
        imageUrl = 'assets/warrantyImages/ownerNoShow.png'; // Owner no-show image
        break;
      case 71004:
        imageUrl = 'assets/warrantyImages/needsRework.png'; // Pending/await image
        break;
      case 71005:
        imageUrl = 'assets/warrantyImages/checked.png';  // Ready for next phase image
        break;
      case 71006:
        imageUrl = 'assets/warrantyImages/ReadyForNextPhase.png'; // Sub no-show image
        break;
      case 71007:
        imageUrl = 'assets/warrantyImages/pendingAwait.png'; // Sub no-show image
        break;
      default:
        imageUrl = 'assets/warrantyImages/pendingAwait.png'; // Fallback if no ID matches
    }
    return imageUrl;
  }

  readonly validationRules = {
    title: {
      required: 'Required',
      maxLength: { limit: 50, message: 'Title' }
    },
    classificationSetupId: {
      required: 'ClassificationSetup is Required',
    },
    problemDescription: {
      maxLength: { limit: 4000, message: 'problemDescription' }
    },
    internalComments: {
      maxLength: { limit: 4000, message: 'internalComments' }
    },
  };

  getFieldLabel(field: string): string {
    const labels: { [key: string]: string } = {
      title: 'Title',
      classificationSetupId: 'Classification',
      completionTime: 'CompletionTime'
    };
    return labels[field] || field;
  }

  getErrorTip(field: string): string {
    const rules = this.validationRules[field];
    const input = this.warrantyFieldValues[field];
    const inputValue = typeof input === 'string' ? input.trim() : '';

    if (rules.required && !input) {
      return 'Required';
    }

    if (rules.maxLength && inputValue.length > rules.maxLength.limit) {
      const excessLength = inputValue.length - rules.maxLength.limit;
      const unit = excessLength === 1 ? 'character' : 'characters';
      return `${excessLength} ${unit} over.`;
    }
    return '';
  }

  get errorFields(): { field: string; label: string; message: string }[] {
    return Object.keys(this.validationStates)
      .filter(field => this.validationStates[field] === 'error')
      .map(field => ({
        field,
        label: this.getFieldLabel(field),
        message: this.validationMessages[field]
      }));
  }

  checkErrors() {
    this.validationStates = {};
    this.validationMessages = {};

    for (let field in this.validationRules) {
      if (this.validationRules.hasOwnProperty(field)) {
        if (this.touchedFields[field] || this.isSubmitted) {
          const errorTip = this.getErrorTip(field);
          if (errorTip) {
            this.validationStates[field] = 'error';
            this.validationMessages[field] = errorTip;
          } else {
            this.validationStates[field] = null;
            this.validationMessages[field] = '';
          }
        }
      }
    }
    if (this.isCompletionRequired()) {
      this.addCompletionValidation();
      this.validateCompletionFields();
    }
    else {
      this.removeCompletionValidation();
    }
    // Check for feedback requirement and add custom error message
    if (this.isFeedbackRequired) {
      this.validationStates['classificationSetupId'] = 'error';
      this.validationMessages['classificationSetupId'] = "Please provide feedback on past appointments before classifying this request as 'Service Completed (closed)'";
    }
  }


  readonly completionDateRules = { required: 'Completion Date is Required' };
  readonly completionTimeRules = { required: 'Completion Time is Required' };

  onFieldChange(field: string, value: any) {
    this.warrantyFieldValues[field] = typeof value === 'string' ? value.trim() : value;
    this.touchedFields[field] = true;
    this.DisabledNextActivity = false;

    if (field === 'title' || field === 'classificationSetupId') {
      this.clearErrorIfFieldFilled(field);
    }
    if (field === 'classificationSetupId') {
      this.isFeedbackRequired = false;
      if (this.isCompletionRequired()) {
        this.addCompletionValidation();
      } else {
        this.removeCompletionValidation();
      }
      this.validateCompletionFields();
    }
    this.checkErrors();

    if (field === 'idNumber') {
      this.touchedFields['title'] = true;
      this.checkErrors();
    }
  }
  clearErrorIfFieldFilled(field: string) {
    if (this.warrantyFieldValues[field]) {
      this.validationStates[field] = null;
      this.validationMessages[field] = '';
    }
  }
  isCompletionRequired(): boolean {
    const selectedOption = this.classificationOption.find(
      option => option.id === this.warrantyFieldValues['classificationSetupId']
    );
    return selectedOption && (
      selectedOption.name === 'Meets Warranty Standards(closed)' ||
      selectedOption.name === 'Service Completed (closed)'
    );
  }
  addCompletionValidation() {
    this.validationRules['completionDate'] = this.completionDateRules;
    this.validationRules['completionTime'] = this.completionTimeRules;
    this.showAdditionalFields = true;
  }
  removeCompletionValidation() {
    delete this.validationRules['completionDate'];
    delete this.validationRules['completionTime'];
    this.validationStates['completionDate'] = null;
    this.validationMessages['completionDate'] = '';
    this.validationStates['completionTime'] = null;
    this.validationMessages['completionTime'] = '';
    this.showAdditionalFields = false;
  }

  validateCompletionFields() {
    const dateError = this.getErrorTip('completionDate');
    const timeError = this.getErrorTip('completionTime');

    if (dateError) {
      this.validationStates['completionDate'] = 'error';
      this.validationMessages['completionDate'] = dateError;
    } else {
      this.validationStates['completionDate'] = null; // Reset if valid
    }

    if (timeError) {
      this.validationStates['completionTime'] = 'error';
      this.validationMessages['completionTime'] = timeError;
    } else {
      this.validationStates['completionTime'] = null; // Reset if valid
    }
  }


  onCategoryWarrantyOpen(_Id: number): void {
    // this.isLoading = true;
    const modalRef = this.modal.create({
      nzWidth: '0%',
      nzContent: WarrantyNewClaimWarrantyCategoryComponent,
      nzFooter: null,
      nzClosable: false,
      nzMask: true,
      nzMaskStyle: {
        display: 'none',
      },
    });
    modalRef.componentInstance._OnWarranty_Id = _Id;
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });

    modalRef.componentInstance.TagGroupOnCompleted.subscribe((data: LookupNameSetupResponse | boolean) => {
      this.initLoadWarrantycategory();
      debugger
      if (typeof data === 'boolean') {
        if (data === true) {
          this.warrantyFieldValues.warrantyCategorySetupId = 71402;

        }
      } else {
        if (data.id > 0) {
          this.warrantyFieldValues.warrantyCategorySetupId = data.id;
        } else {
          this.warrantyFieldValues.warrantyCategorySetupId = null;
        }
      }
    });
  }
  isEditButtonDisabled(): boolean {
    const selectedValues = this.warrantyFieldValues.warrantyCategorySetupId;
    return !selectedValues || (Array.isArray(selectedValues) && selectedValues.length !== 1);
  }
}

interface ModalTitle {
  title?: string;
  deleteTitle?: string; // Optional property for cases where deleteTitle is not needed
}
