import { ReminderSetupService } from './../services/reminder-setup.service';
import { SendPastDueRfiIReminderuptoService } from './../services/send-past-due-rfi-ireminderupto.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { rfiReminder } from 'src/Models/rfisReminder,model';
import { sendPastDueRfiReminderUpToId } from 'src/Models/sendPastDueRFISReminder.model';
import { companySettingRFIsPreDefinedCodes } from 'src/app/shared/component/Models/LookUpStandardFilterCodes';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { LookupNameSetupResponse } from 'src/Models/LeadTags';
import { NzModalService } from 'ng-zorro-antd/modal';
import { RFIsInforServiceService } from 'src/Service/RFIs_InforService/rfis-infor-service.service';

@Component({
  selector: 'app-rfis',
  templateUrl: './rfis.component.html',
  styleUrls: ['./rfis.component.css']
})
export class RFIsComponent implements OnInit {
  @Output() RFIsOnCompleted = new EventEmitter();
  @Output() cancel = new EventEmitter<void>();
  PAGE_ID: number = 26;
  rFIsFormField: FormGroup;
  DefaultRFIreminders: LookupNameSetupResponse[] = [];
  SendPastDueRFIReminderupto: LookupNameSetupResponse[] = [];
  VisibleInRFIsSetup: boolean = true;
  isSave = false;
  DisabledNextActivity: boolean = false;
  customfieldinfo:boolean = false;


  preDefinedDataCodes = {
    DefaultRFIreminders: companySettingRFIsPreDefinedCodes.MRFIsR,
    SendPastDueRFIReminderupto: companySettingRFIsPreDefinedCodes.CORFIsSPDRRUT,
  };
  constructor(
    private fb: FormBuilder,
    private rfisService: RFIsInforServiceService,
    private LookupFilterService: LookupFilterService,
    private toastssService: NzMessageService,
  ) { }
  ngOnInit() {
    this.RFIsSetupFormGroup();
    this.initLoad();
    this.fetchCompanyRFIsData();
  }
  RFIsSetupFormGroup(): void {
    this.rFIsFormField = this.fb.group({
      globalId: ['00000000-0000-0000-0000-000000000000'],
      companyParameterId: [1],
      rfiReminderId: [null],
      sendPastDueRfiReminderUpToId: [null]
    });
  }
  CustomFieldInfo(){
    this.customfieldinfo = true;
  }
  handleCancel2(): void {
    this.customfieldinfo = false;
  }
  initLoad(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.LookupFilterService.getLookUpValuesByFormNameId(this.PAGE_ID).subscribe(
        (res) => {
          // -----------single Select ---------------
          let DefaultRFIsremindersOptions = res.result.filter(
            (x) => x.code === this.preDefinedDataCodes.DefaultRFIreminders.code
          );
          let SendPastDueRFIReminderuptoOptions = res.result.filter(
            (x) => x.code === this.preDefinedDataCodes.SendPastDueRFIReminderupto.code
          );
          // -----------single Select -------------
          this.DefaultRFIreminders = DefaultRFIsremindersOptions;
          this.SendPastDueRFIReminderupto = SendPastDueRFIReminderuptoOptions;
  
          resolve();
        },
        (error) => {
          console.error('Error fetching lookup values:', error);
          reject(error);
        }
      );
    });
  }
  fetchCompanyRFIsData(): void { 
    this.rfisService.getCompanyRFIsSetup().subscribe(res => {
      const fetchRFIsData = res.result;
      this.rFIsFormField.patchValue({
        companyParameterId: fetchRFIsData.companyParameterId,
        rfiReminderId: fetchRFIsData.rfiReminderId,
        sendPastDueRfiReminderUpToId: fetchRFIsData.sendPastDueRfiReminderUpToId,
      });
    });
  }
  closeModalInRFIsSetup(): void {
    this.VisibleInRFIsSetup = false;
    this.cancel.emit();
  }
  rFIsSetupSave(actionType: 'save' = 'save'): Promise<void> {
    console.log('Save Time', this.rFIsFormField);
    let data = this.rFIsFormField.value;

    return new Promise((resolve, reject) => {
      if (this.isSave) {
        return reject('Already processing');
      }

      if (actionType === 'save') {
        this.isSave = true;
      }

      this.DisabledNextActivity = true;

      this.rfisService.PostRfisSetup(data).subscribe(
        (response) => {
          this.RFIsOnCompleted.emit(response.result);
          this.cancel.emit();
          this.DisabledNextActivity = false;
          this.isSave = false;
          resolve();
        },
        (error) => {
          console.error('Error saving data:', error);
          this.toastssService.error('An error has occurred. Please try again.');
          this.DisabledNextActivity = false;
          this.isSave = false;
          reject(error);
        }
      ).add(() => {
        this.resetFlags();
      });
    });
  }
  resetFlags() {
    this.isSave = false;
  }
}
