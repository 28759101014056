import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ProjectManagementSiteDiariesCreateFilesComponent } from 'src/app/project-management/components/Site-Diaries/project-management-site-diaries-create-files/project-management-site-diaries-create-files.component';
import { LeadOpportunityAttachmentsUploadFilesComponent } from '../lead-opportunity-attachments-upload-files/lead-opportunity-attachments-upload-files.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { LeadOpportunitiesService } from 'src/Service/lead-opportunities.service';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { AttachmentAnnotationComponent } from '../../attachment-annotation/attachment-annotation.component';
import { CustomUploadFile } from 'src/Models/Attachment_Files_Class/AttachmentFilesClass';

@Component({
  selector: 'app-lead-opportunity-attachments-upload-files-view-all',
  templateUrl: './lead-opportunity-attachments-upload-files-view-all.component.html',
  styleUrls: ['./lead-opportunity-attachments-upload-files-view-all.component.css']
})
export class LeadOpportunityAttachmentsUploadFilesViewAllComponent implements OnInit{
    // _________________________Abdul Rehman  Start__________________________
  constructor(private modal: NzModalService,private leadOppService:LeadOpportunitiesService,
  ){}
  @Output() selectedFilessResponse: CustomUploadFile[];
  responeData: CustomUploadFile[];
  @Output() cancel = new EventEmitter<void>();
  uploadfiles=true;
  selectedFiles: File[] = [];
  ngOnInit(): void {
    this.responeData = this.selectedFilessResponse;
    this.leadOppService.getSelectedFiles().subscribe(files => {
      this.selectedFiles = files;
    });
  }
  UploadFilesClose(): void {
    this.cancel.emit();
    }
    @Output() Test = new EventEmitter<CustomUploadFile[]>();

    uploadFiles(): void {
      const modalRef = this.modal.create({
        nzContent: LeadOpportunityAttachmentsUploadFilesComponent,
        nzFooter: null,
      });
      modalRef.componentInstance.cancel.subscribe(() => {
        modalRef.destroy();
      });
      modalRef.componentInstance.UploadFile.subscribe((data: CustomUploadFile[]) => {
        data.filter(file => this.isImageFile(file));
        this.Test.emit(data);
        this.responeData = [...data, ...this.responeData];
      });
    }
    isImageFile(file: CustomUploadFile): boolean {
      const fileName = file.name.toLowerCase();
      const fileType = file.type;
  
      return (
        fileType === 'image/png' ||
        fileType === 'image/jpeg' ||
        fileName.endsWith('.png') ||
        fileName.endsWith('.jpeg')
      );
    }
  CreateNewFile(): void {
    const modalRef = this.modal.create({
      nzContent: ProjectManagementSiteDiariesCreateFilesComponent,
      nzFooter: null, 
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy(); 
    });
    }
    isGridView: boolean = true; 
    isListView: boolean = false;
    grid() {
      this.isGridView = true;
      this.isListView = false;
    }
    list() {
      this.isGridView = false;
      this.isListView = true;
    }
    @Output() removeFile = new EventEmitter<CustomUploadFile>();
    onRemoveFile(file: CustomUploadFile): void {
      this.removeFile.emit(file);
      this.responeData = this.responeData.filter(item => item.uid !== file.uid);
    }
    // removeFile(file: CustomUploadFile): void {
    //   this.selectedFilessResponse = this.selectedFilessResponse.filter(item => item.uid !== file.uid);
    // }
    // _________________________Abdul Rehman  End__________________________
    getFileIcon(file: CustomUploadFile): string | null {
      const fileType = file.type;
      if (fileType.includes('pdf')) {
        return 'assets/Attachment/documentPDF.svg';
      } else if (fileType.includes('excel') || fileType.includes('spreadsheet')) {
        return 'assets/Attachment/documentXLS.svg';
      } else if (fileType.includes('word')) {
        return 'assets/Attachment/documentDOC.svg';
      } else if (fileType.includes('zip')) {
        return 'assets/Attachment/documentZIP.svg';
      } else if (fileType.includes('text')|| fileType.includes('json')) {
        return 'assets/Attachment/documentGeneric.svg';
      } else if (fileType.includes('ppt') || fileType.includes('presentation')) {
        return 'assets/Attachment/documentPPT.svg';
      } else if (fileType.includes('video')) {
        return null;
      } else if (fileType.includes('image')) {
        return null;
      } else {
        return null;
      }
    }
    uploadFilesUpdate(fileq: CustomUploadFile): void {
      const modalRef = this.modal.create({
        nzContent: AttachmentAnnotationComponent,
        nzFooter: null,
      });
      modalRef.componentInstance.DrawerImge = fileq;
      modalRef.componentInstance.cancel.subscribe(() => {
        modalRef.destroy();
      });
      modalRef.componentInstance.imageDataRes.subscribe((data: any) => {
        const newImageUrl = data;
        let test1234 = this.selectedFilessResponse.forEach(activity => {
          activity.thumbUrl = newImageUrl;
        });
        console.log(test1234);
      });
    }
    assignedUserSelecte(){

    }
}
