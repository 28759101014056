import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators,FormControl } from '@angular/forms';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { ProjectManegmentToDosPreDefinedCodes } from 'src/app/shared/component/Models/LookUpStandardFilterCodes';
import { LookupNameSetupResponse, ScheduleItemParameterResponse } from 'src/Models/LeadTags';
import { scheduleItemByJob, ToDoItemResult, ToDoRelatedItemsResult } from 'src/Models/Project-Management/projectManagement';
import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';
import { CustomerInformationService } from 'src/Service/customer-information.service';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { LeadOpportunitiesService } from 'src/Service/lead-opportunities.service';
import { ScheduleService } from 'src/Service/Project-Management/schedule.service';
import { AuditDetailsResponse, FilterFooterAudit } from 'src/Models/Footer/footer.model';
import { AttachmentService } from 'src/Service/Attachment/attachment.service';
import { AttachmentResponse, CustomUploadFile } from 'src/Models/Attachment_Files_Class/AttachmentFilesClass';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { LoadingIndicatorService } from 'src/shared/Shared-Services/loading-indicator.service';
import { forkJoin, Subject } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FooterService } from 'src/Service/Footer/footer.service';
import { ToDoInformationService } from 'src/Service/Project-Management/to-do-information.service';
import { NewscheduleItemComponent } from '../../schedules-Components/schedule-common/newschedule-item/newschedule-item.component';
import { ResponseScheduleItemResult } from 'src/Models/Project-Management/schedule';
import { saveAttachments } from 'src/app/helpers/Attachment_Save';
import { ToDoTagsComponent } from '../../To-Do\'s/to-do-tags/to-do-tags.component';
import { CreateAttachmentsCommonComponent } from 'src/app/shared/component/create-attachments-common/create-attachments-common.component';
import { ProjectManagementSiteDiariesChecklistComponent } from '../project-management-site-diaries-checklist/project-management-site-diaries-checklist.component';
import { ProjectManagementSiteDiariesNewDailyLogComponent } from '../project-management-site-diaries-new-daily-log/project-management-site-diaries-new-daily-log.component';
import { SelectionResponse } from 'src/Models/Selection/selectionClasses';

@Component({
  selector: 'app-project-management-site-diaries-related-to-do-st',
  templateUrl: './project-management-site-diaries-related-to-do-st.component.html',
  styleUrls: ['./project-management-site-diaries-related-to-do-st.component.css']
})
export class ProjectManagementSiteDiariesRelatedToDoStComponent implements OnInit, OnChanges {
  @Output() closeDrawer = new EventEmitter<void>();
  @Output() saveComplete = new EventEmitter<number>();
  @Input() visible: boolean = false;
  selectInfoResponseDueBYID_: SelectionResponse;
  closeDrawerIdSubject = new Subject<number>();
  radioValue = 'A';
  DaysradioValue = 'A';
  ScheduleItem_start_end_On: boolean = false
  dueDateTest: string;
  scheduleItem: string = null;
  changedFields: Set<string> = new Set<string>();
  dueDate: string;

  combinedNodes: NzTreeNodeOptions[] = [];
  tagsNodes: NzTreeNodeOptions[] = [];
  priorityOption: LookupNameSetupResponse[] = [];
  reminderOption: LookupNameSetupResponse[] = [];
  scheduleItemOption: scheduleItemByJob[] = [];
  scheduleItemOptions: ScheduleItemParameterResponse[] = [];
  checklistItems: Array<any> = [];
  isSingleTagSelected: boolean = false;
  todoForm: FormGroup;
  PAGE_id: number = 6;
  showPriorityField: boolean = false;
  showReminderField: boolean = false;
  showTagsField: boolean = false;
  isLinkToAdd: boolean = true;
  isCompleteAllClicked: boolean = false;
  tagOptions: any;
  JobInfoId: number;
  toDoId: number = 0;
  Messaging_Id: number;
  CompletedBy: string;
  formNameId: number = 6;
  tagResponse: any;
  timeOptions: string[] = [
    '08:00 am',
    '08:30 am',
    '09:00 am',
    '09:30 am',
    '10:00 am',
    '10:30 am',
    '11:00 am',
    '11:30 am',
    '12:00 am',
    '12:30 am',
    '08:00 pm',
    '08:30 pm',
    '09:00 pm',
    '09:30 pm',
    '10:00 pm',
    '10:30 pm',
    '11:00 pm',
    '11:30 pm',
    '12:00 pm',
    '12:30 pm'
  ];
  @ViewChild(CreateAttachmentsCommonComponent) attachmentsComponent!: CreateAttachmentsCommonComponent;
  showAttachment: boolean = false;
  footerResponse: AuditDetailsResponse;
  scheduleList: scheduleItemByJob[];

  // Job variables
  @Input() selectedJobId: number; // Receive job ID from parent
  @Input() selectedJobName: string; // Receive job name from parent
  @Input() selectedToDoId: number;
  isPatching = true;


  @Input() relatedHeaderId: number = 0;
  @Input() relatedFormNameId: number = 6;

  @Input() selectedDailyLogNotes: string;

  @Input() selectedScheduleNotes: string;


  toDoResponse: ToDoItemResult;
  toDoRFIId: number;
  headerId: number = 0;
  relatedDailyLogList: ToDoRelatedItemsResult[] = [];
  CompletedOn: Date;
  Time: string
  @ViewChild('titleInput') titleInput!: ElementRef;
  constructor(
    public localStorage: AccessLocalStorageService,
    private modal: NzModalService,
    private customerInformationService: CustomerInformationService,
    private LookupFilterService: LookupFilterService,
    private leadOppService: LeadOpportunitiesService,
    private fb: FormBuilder,
    private _AttachmentService: AttachmentService,
    private _loadingIndicatorService: LoadingIndicatorService,
    private _scheduleService: ScheduleService,
    public _toastService: NzMessageService,
    private _footerService: FooterService,
    private _toDoInformationService: ToDoInformationService,
    public accessLocalStorageService: AccessLocalStorageService,


  ) { }

  preDefinedDataCodes = {
    Tags: ProjectManegmentToDosPreDefinedCodes.Tags,
    Priority: ProjectManegmentToDosPreDefinedCodes.Priority,
    Reminder: ProjectManegmentToDosPreDefinedCodes.Reminder
  }

  isInitialized = false;


  ngOnInit(): void {


    if (!this.isInitialized) {
      this.initializeForm();
      this.loadDropdownData();
      this.toggleDateFields()
      this.setupFormValueChangeHandler();
      this.isInitialized = true;

      // Autofocus on title input after a short delay
      setTimeout(() => {
        this.titleInput.nativeElement.focus();
      }, 0); // 0 delay to push execution to the end of the call stack
    }
  }



  ngOnChanges(changes: SimpleChanges): void {
    if (changes['visible'] && changes['visible'].currentValue && !this.isInitialized) {
      this.showPriorityField = false;
      this.showTagsField = false;
      this.showReminderField = false;
      this.showAttachment = false;
      this.isLinkToAdd = true;
      // Autofocus on title input only if modal is visible
      setTimeout(() => {
        this.titleInput.nativeElement.focus();
      }, 0);
    }
  }
  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    return !isWhitespace ? null : { whitespace: true };
  }


  initializeForm() {
    const dailyLogNotes = this.selectedDailyLogNotes ? `[To-Do Notes]\n${this.selectedDailyLogNotes}` : '';

    // Conditionally set notes
    this.todoForm = this.fb.group({
      id: [null],
      globalId: ['00000000-0000-0000-0000-000000000000'],
      jobInformationId: [this.selectedJobId],
      isCompleted: [false],
      relatedScheduleItemParameterId: [this.relatedHeaderId],
      completedBy: [1],
      title: ['', [Validators.required, Validators.maxLength(75), this.noWhitespaceValidator] ],
      notes: [this.selectedScheduleNotes || dailyLogNotes, Validators.maxLength(2000)],
      isChooseDate: [true],
      isLinkedScheduleItem: [false],
      scheduleItemParameterId: [0], // Will be dynamically required
      dueNumberDays: [0],
      dueDate: [null],
      beforeOrAfter: [null],
      daysInput: [null],
      after: [null],
      time: [null],
      prioritySetupId: [0],
      reminderSetupId: [0],
      attachmentId: [null],
      createToDoParameterAssignOwnersRequests: [null],
      createToDoInformationTagParameterRequests: [null]
    });

    // Subscribe to changes in dueDate
    this.todoForm.get('dueDate')?.valueChanges.subscribe(value => {
      if (value === null) {
        this.showReminderField = false;
        this.todoForm.get('reminderSetupId')?.setValue(0);
      }
    });

    // Updated handling for isChooseDate
    this.todoForm.get('isChooseDate')?.valueChanges.subscribe(isChooseDate => {
      if (isChooseDate) {
        const currentScheduleValue = this.todoForm.get('scheduleItemParameterId')?.value;
        if (currentScheduleValue === 0) {
          this.todoForm.get('scheduleItemParameterId')?.setValue(0);
          this.todoForm.get('beforeOrAfter')?.setValue(null);
          this.todoForm.get('beforeOrAfter')?.disable;
          this.todoForm.get('dueNumberDays')?.setValue(0);
          this.todoForm.get('dueNumberDays')?.disable;

        }

      }
    });

    // Updated handling for isLinkedScheduleItem
    this.todoForm.get('isLinkedScheduleItem')?.valueChanges.subscribe(isLinkedScheduleItem => {
      const scheduleItemControl = this.todoForm.get('scheduleItemParameterId');
      if (isLinkedScheduleItem) {
        const currentScheduleValue = scheduleItemControl?.value;
        if (currentScheduleValue === 0) {
          scheduleItemControl?.setValue(0);
        }
        this.todoForm.get('beforeOrAfter')?.setValue('before');
      }
    });

    this.setupScheduleFieldValueChanges();
  }



  validateScheduleItem(isLinkedScheduleItem: boolean) {
    return (control: AbstractControl) => {
      const scheduleItemValue = control.value;
      if (isLinkedScheduleItem && scheduleItemValue === 0) {
        return { required: true };
      }
      return null;
    };
  }


  setupScheduleFieldValueChanges() {
    const scheduleItemControl = this.todoForm.get('scheduleItemParameterId');
    const isLinkedScheduleItemControl = this.todoForm.get('isLinkedScheduleItem');

    // Update validators when isLinkedScheduleItem changes
    isLinkedScheduleItemControl?.valueChanges.subscribe(isLinkedScheduleItem => {
      scheduleItemControl?.setValidators([this.validateScheduleItem(isLinkedScheduleItem)]);

      // Update the validation and reset other fields
      scheduleItemControl?.updateValueAndValidity();

      if (isLinkedScheduleItem) {
        this.todoForm.get('beforeOrAfter')?.setValue('before'); // Reset before/after selection
      } else {
        scheduleItemControl?.clearValidators();
        scheduleItemControl?.setErrors(null);
      }

      if (this.hasAttemptedSubmit) {
        this.errorMessages = this.getFormValidationErrors();
        if (this.todoForm.get('isLinkedScheduleItem')?.value) {
          this.errorMessages = this.getFormValidationErrors(); // Update error messages on selection
        }
      }
    });


    const isLinkedScheduleItem = isLinkedScheduleItemControl?.value;
    if (isLinkedScheduleItem && scheduleItemControl?.value === 0) {
      scheduleItemControl?.setValidators([this.validateScheduleItem(isLinkedScheduleItem)]);
      scheduleItemControl?.setErrors({ required: true });
      scheduleItemControl?.updateValueAndValidity();
    }
  }


  // DropDown and Multi-Select Methods Start

  loadDropdownData() {
    this._loadingIndicatorService.show();
    this.isPatching = true; // Set the flag to true before patching starts

    // Use forkJoin to combine multiple API calls
    forkJoin({
      scheduleItems: this.leadOppService.getAllDataScheduleItems(),
      lookUpValues: this.LookupFilterService.getLookUpValuesByFormNameId(this.PAGE_id),
      reminderValues: this.LookupFilterService.getLookUpValuesByFormNameId(this.PAGE_id),
      combinedData: this.customerInformationService.getDataforcombo()
    }).subscribe({
      next: (responses) => {
        // Access responses by their keys
        const scheduleItemsRes = responses.scheduleItems;
        const lookUpValuesRes = responses.lookUpValues;
        const reminderValuesRes = responses.reminderValues;
        const combinedDataRes = responses.combinedData;

        // Set schedule item options
        this.scheduleItemOptions = scheduleItemsRes.result;

        // Process look up values
        const priorityOptionValues = lookUpValuesRes.result.filter((x) => x.code === this.preDefinedDataCodes.Priority.code);
        this.priorityOption = priorityOptionValues;

        const reminderOptionValues = reminderValuesRes.result.filter((x) => x.code === this.preDefinedDataCodes.Reminder.code);
        this.reminderOption = reminderOptionValues;

        // Populate tags
        this.initLoad();
        // Process combined nodes
        this.combinedNodes = this.createCombinedNodes(combinedDataRes.result);

        // After loading dropdown data, check if selectedJobId is available and fetch schedules
        if (this.selectedJobId) {
          this.getScheduleByJob(this.selectedJobId);
        }

        if (this.selectedToDoId > 0) {
          this._toDoInformationService.getByIdToDo(this.selectedToDoId).subscribe(res => {
            if (res.result) {
              this.patchedToDo(res.result);
            }
          });
        } else {
          this._loadingIndicatorService.hide(); // Hide loading if no toDo is selected
        }
      },
      error: (error) => {
        console.error('Error loading dropdown data:', error);
        this._loadingIndicatorService.hide(); // Hide loading even on error
      }
    });

  }

  patchedToDo(toDoData: ToDoItemResult) {


    this.toDoId = toDoData?.Id;



    this.toDoResponse = toDoData;
    this.selectedJobId = toDoData.JobInformationId;
    this.selectedJobName = toDoData.JobName;
    this.Messaging_Id = toDoData.MessagingId;
    this.JobInfoId = toDoData?.JobInformationId;
    this.formNameId = 6;
    this.toDoRFIId = toDoData.RFIId;

    this.JobInfoId = toDoData.JobInformationId;

    if (toDoData?.AttachmentId) {
      this.fetchAttachments(toDoData?.AttachmentId);
    }

    this.LoginPerson = toDoData.CompletedBy;
    // this.getFormattedLoginPerson(this.LoginPerson);

    this.todoForm.patchValue({
      id: toDoData.Id,
      globalId: toDoData.GlobalId,
      jobInformationId: toDoData.JobInformationId,
      isCompleted: toDoData.IsCompleted,
      title: toDoData.Title,
      notes: toDoData.Notes,
      isChooseDate: toDoData.IsChooseDate,
      isLinkedScheduleItem: toDoData.IsLinkedScheduleItem,
      scheduleItemParameterId: toDoData.ScheduleItemParameterId,
      dueNumberDays: toDoData.DueNumberDays,
      dueDate: toDoData.DueDate,
      before: toDoData.Before,
      after: toDoData.After,
      time: toDoData.Time,
      prioritySetupId: toDoData.PrioritySetupId,
      reminderSetupId: toDoData.ReminderSetupId,
      attachmentId: toDoData.AttachmentId,
      beforeOrAfter: toDoData.Before,
      relatedScheduleItemParameterId: toDoData.RelatedScheduleItemParameterId
    });

    // Handle AssignedIds and map them as an array of strings
    if (this.combinedNodes && toDoData.AssignedIds) {
      const assigneeIdsArray = toDoData.AssignedIds.split(',').map(id => id.trim()); // Keep them as strings
      this.todoForm.patchValue({
        createToDoParameterAssignOwnersRequests: assigneeIdsArray
      });
    }

    if (this.tagsNodes && toDoData.TagIds) {
      const tagIdsArray = toDoData.TagIds.split(',').map(id => id.trim()); // Keep them as strings
      this.todoForm.patchValue({
        createToDoInformationTagParameterRequests: tagIdsArray
      });

      if (tagIdsArray.length > 0) {
        this.showTagsField = true; // Show the tags field if there are valid tag IDs
      } else {
        this.showTagsField = false; // Hide if no valid tags
      }
    }

    this.createAndModifiedByData(toDoData.Id)
    // Show/hide additional fields
    this.showReminderField = toDoData.ReminderSetupId > 0;
    this.showPriorityField = toDoData.PrioritySetupId > 0;
    this.showAttachment = toDoData.AttachmentId > 0;

    if (toDoData.DailyLogId > 0) {
      this.getAllRelatedDailyLogs(toDoData.DailyLogId);
    }

    // After patching all fields, hide the loading indicator
    this.isPatching = false;
    this._loadingIndicatorService.hide();
  }





  getScheduleByJob(jobId: number): Promise<void> {
    return new Promise((resolve, reject) => {
      this._scheduleService.getAllPredecessorsDataScheduleItem(jobId).subscribe({
        next: res => {
          this.scheduleList = res.result;
          resolve(); // Resolve the promise after the schedule list is updated
        },
        error: err => reject(err) // Reject in case of error
      });
    });
  }



  createTagNodes(tagOptions: any) {
    return [
      {
        title: 'Check All',
        value: 'check_all',
        key: 'check_all',
        selectable: false,
        isLeaf: false,
        expanded: true,
        children: tagOptions.map((tag) => ({
          title: tag.name,
          value: tag.id.toString(),
          key: tag.id.toString(),
          isLeaf: true,
        })),
      },
    ];
  }

  createCombinedNodes(combinedData: any) {
    return [
      {
        title: 'Check All',
        value: 'check_all',
        key: 'check_all',
        selectable: false,
        isLeaf: false,
        expanded: true,
        children: combinedData.map((status) => ({
          title: status.fullName,
          value: status.id.toString(),
          key: status.id.toString(),
          isLeaf: true,
        }))
      }
    ];
  }




  onAssigneesChange(selectedValues: string[] | null): void {
    if (!selectedValues) {
      return; // Exit the function if selectedValues is null or undefined
    }

    const checkAllIndex = selectedValues.indexOf('check_all');

    if (checkAllIndex !== -1) {
      // Select all child values when "Check All" is selected
      const allValues = this.combinedNodes[0].children.map((child) => child['value']);
      this.todoForm.get('createToDoParameterAssignOwnersRequests')?.setValue(allValues);
      this.isSingleTagSelected = false; // Reset the single tag selection flag
    } else {
      // Filter out deselected values
      const allValues = this.combinedNodes[0].children.map((child) => child['value']);
      const filteredValues = selectedValues.filter((value) => allValues.includes(value));
      this.todoForm.get('createToDoParameterAssignOwnersRequests')?.setValue(filteredValues);
      this.isSingleTagSelected = filteredValues.length === 1;
    }

    // Update form control state
    const assigneeControl = this.todoForm.get('createToDoParameterAssignOwnersRequests');
    if (assigneeControl) {
      assigneeControl.markAsTouched();
      assigneeControl.updateValueAndValidity();
    }
  }

  // DropDown and Multi-Select Methods End


  // Toggle Method Start





  togglePriorityField() {
    this.showPriorityField = !this.showPriorityField;
  }

  toggleTagsField() {
    this.showTagsField = !this.showTagsField;
  }

  toggleReminderField() {
    this.showReminderField = !this.showReminderField;
  }

  toggleAttachment() {
    this.showAttachment = !this.showAttachment;
  }


  // Toggle Method End






  TestAttachment: AttachmentResponse;
  selectedFilesAttachment: CustomUploadFile[] = [];


  fetchAttachments(AttachmentId: number) {
    if (AttachmentId) {
      this._AttachmentService.getLeadByAttachmentId(AttachmentId).subscribe(
        (res) => {
          this.TestAttachment = res.result
        },
      );
    } else {
      this.TestAttachment = undefined;
    }
  }


  onFilesUploaded(files: CustomUploadFile[]): void {
    this.selectedFilesAttachment = files
  }

  // add assigner and attachment and delete method start//////


  // Error Methods Start


  titleErrorMessage: string = '';
  allNotesErrorMessage: string = '';




  setupFormValueChangeHandler() {
    // Track changes for the title field
    this.todoForm.get('title')?.valueChanges.subscribe(value => {
      this.setFieldErrorMessage('title', value, 75); // Max length 75 for title
      if (this.hasAttemptedSubmit) {
        this.errorMessages = this.getFormValidationErrors(); // Update error messages
      }
    });

    // Track changes for the notes field
    this.todoForm.get('notes')?.valueChanges.subscribe(value => {
      this.setFieldErrorMessage('notes', value, 2000); // Max length 2000 for notes
      if (this.hasAttemptedSubmit) {
        this.errorMessages = this.getFormValidationErrors(); // Update error messages
      }
    });
    // this.childComponent.toDoFormCheckListArray?.get('name')?.valueChanges.subscribe(value => {
    //   this.setFieldErrorMessage('name', value, 256); // Max length 2000 for notes
    //   this.errorMessages = this.getFormValidationErrors(); // Update error messages
    // });

  }



  getFormValidationErrors(): string[] {
    const errorMessages: string[] = [];

    // Check parent form controls
    Object.keys(this.todoForm.controls).forEach((key) => {
      const control = this.todoForm.get(key);
      const controlErrors = control?.errors;
      const fieldLabel = this.getFieldLabel(key);

      if (controlErrors) {
        if (controlErrors['required']) {
          errorMessages.push(`${fieldLabel}: Required`);
        }
        if (controlErrors['maxlength']) {
          const maxLength = controlErrors['maxlength'].requiredLength;
          const actualLength = controlErrors['maxlength'].actualLength;
          const overCount = actualLength - maxLength;
          errorMessages.push(`${fieldLabel}: ${overCount} character${overCount > 1 ? 's' : ''} over`);
        }
      }
    });

    // // Check child component form array
    // this.childComponent.toDoFormCheckListArray.controls.forEach((control, index) => {
    //   const controlErrors = control.get('name')?.errors;
    //   const fieldLabel = this.getFieldLabel('name');

    //   if (controlErrors) {
    //     if (controlErrors['maxlength']) {
    //       const maxLength = controlErrors['maxlength'].requiredLength;
    //       const actualLength = controlErrors['maxlength'].actualLength;
    //       const overCount = actualLength - maxLength;
    //       errorMessages.push(`Item ${index + 1} - ${fieldLabel}: ${overCount} character${overCount > 1 ? 's' : ''} over`);
    //     }
    //   }
    // });

    return errorMessages;
  }


  // Updated setFieldErrorMessage method to handle both required and maxlength validations for both parent and child forms
  setFieldErrorMessage(fieldName: string, value: string, maxLength: number, isChildControl = false): void {
    const currentLength = value ? value.length : 0;
    const overCount = currentLength - maxLength;

    let message = '';
    if (!value) {
      message = 'Required';
    } else if (overCount > 0) {
      message = `${overCount} character${overCount > 1 ? 's' : ''} over`;
    }

    if (isChildControl) {
      // Set error message in child component for 'name' field
      // if (fieldName === 'name') {
      //   this.childComponent.setNameErrorMessage(message);
      // }
    } else {
      // Handle error messages for parent component fields
      switch (fieldName) {
        case 'title':
          this.titleErrorMessage = message;
          break;
        case 'notes':
          this.allNotesErrorMessage = message;
          break;
        // case 'name':
        //   this.childComponent.nameMessage = message;
        //   break;
      }
    }
  }


  getFieldLabel(fieldName: string): string {
    const fieldLabels: { [key: string]: string } = {
      title: 'Title',
      notes: 'Notes',
      name: 'Checklist item',
      scheduleItemParameterId: 'Schedule Item',


    };
    return fieldLabels[fieldName] || fieldName;
  }



  // Error Methods End

  // Save Save And New , Save And Close Methods Start

  isSaving: boolean = false;
  isSaveAndNew: boolean = false;
  isSaveAndClose: boolean = false;
  DisabledNextActivity: boolean = false;
  uploadedAttachments: string[] = [];
  errorMessages: string[] = [];
  hasAttemptedSubmit = false;
  LoginPerson: string;
  @ViewChild(ProjectManagementSiteDiariesChecklistComponent) childComponent!: ProjectManagementSiteDiariesChecklistComponent;

  saveButton(actionType: 'save' | 'saveAndClose' | 'saveAndNew' = 'save'): Promise<void> {
    return new Promise((resolve, reject) => {
      // Check if already saving
      if (this.isSaving || this.isSaveAndClose || this.isSaveAndNew) {
        return reject('Already processing');
      }

      this.hasAttemptedSubmit = true;
      this.errorMessages = [];

      // Validate the form
      if (this.todoForm.invalid) {
        Object.values(this.todoForm.controls).forEach(control => {
          control.markAsDirty();
          control.updateValueAndValidity();
        });
        // Object.values(this.childComponent.toDoFormCheckListArray.controls).forEach(control => {
        //   control.markAsDirty();
        //   control.updateValueAndValidity();
        // });

        this.errorMessages = this.getFormValidationErrors(); // Get error messages
        this.scrollToTop();
        return reject('Form is invalid'); // Reject here
      }

      this.DisabledNextActivity = true; // Disable next activity

      // Set appropriate flags based on the actionType
      if (actionType === 'saveAndNew') {
        this.isSaveAndNew = true;
      } else if (actionType === 'saveAndClose') {
        this.isSaveAndClose = true;
      } else {
        this.isSaving = true;
      }


      // Determine before and after values based on beforeOrAfter selection
      const beforeOrAfterValue = this.todoForm.get('beforeOrAfter')?.value;
      const beforeValue = beforeOrAfterValue === 'before' ? true : false;
      const afterValue = beforeOrAfterValue === 'after' ? true : false;

      // Update the form values for before and after
      this.todoForm.patchValue({
        before: beforeValue,
        after: afterValue,
      });


      // Construct the tag and assignee parameter requests
      const selectedTags = this.todoForm.get('createToDoInformationTagParameterRequests')?.value || [];
      const tagRequests = Array.isArray(selectedTags) ? selectedTags.map(tagId => ({ tagSetupId: tagId })) : [];

      const selectedAssignees = this.todoForm.get('createToDoParameterAssignOwnersRequests')?.value || [];
      const assigneeRequests = Array.isArray(selectedAssignees) ? selectedAssignees.map(userId => ({ applicationUserId: userId })) : [];

      const formValue = { ...this.todoForm.value };
      delete formValue.beforeOrAfter; // Remove beforeOrAfter from the payload

      // Build the final payload
      const payload = {
        formNameId: this.relatedFormNameId,
        jobInformationId: this.selectedJobId,
        companyParameterId: 1,
        headerId: this.relatedHeaderId,
        createToDoParameterRequest: {
          ...formValue,
          createToDoInformationTagParameterRequests: tagRequests,
          createToDoParameterAssignOwnersRequests: assigneeRequests,
        }
      };


      this._toDoInformationService.postDataToDoModel(payload).subscribe({
        next: (response) => {


          this.isSaving = false;
          this.DisabledNextActivity = false;
          this.toDoResponse = response.result;
          this.JobInfoId = this.toDoResponse.JobInformationId;
          this.Messaging_Id = this.toDoResponse.MessagingId;
          this.toDoId = this.toDoResponse.Id;
          this.toDoRFIId = this.toDoResponse.RFIId;
          this.LoginPerson = this.toDoResponse.CompletedBy;

          this.saveComplete.emit(this.toDoResponse.ToDoId); // Emit the saved Id


          this.todoForm.patchValue({ globalId: this.toDoResponse.GlobalId });
          this.todoForm.patchValue({ id: this.toDoResponse.Id });
          this.todoForm.patchValue({ RelatedScheduleItemParameterId: this.toDoResponse.RelatedScheduleItemParameterId });


          // Check for duplicate attachments
          const newAttachments = this.selectedFilesAttachment.filter(file =>
            !this.uploadedAttachments.includes(file.name)
          );

          if (newAttachments.length > 0) {
            saveAttachments(newAttachments, this._AttachmentService, this.toDoResponse.Id, this.formNameId).then(() => {
              this.uploadedAttachments.push(...newAttachments.map(file => file.name));
            });
          }


          if (response) {
            this.createAndModifiedByData(this.toDoResponse.Id);
          }
          this.childComponent.toDoInformationId = this.toDoId;
          this.closeDrawerIdSubject.next(this.toDoResponse.ToDoId);
          this.childComponent.onSubmit().then(
            () => {
              this._toastService.success('Saved Successfully'); // Show success message

              this.resetFlags(); // Reset flags after successful save
              resolve(); // Resolve promise on success
            },
            (error) => {
              console.error('Error saving checklist items:', error);
              this._toastService.error('Failed to save checklist items.');
              this.resetFlags();
              reject('Error saving checklist items');
            }
          );
          resolve(); // Resolve promise on success
        },
        error: (error) => {
          this.isSaving = false;
          this.DisabledNextActivity = false; // Re-enable next activity on error

          if (error?.status === 500) {
            this._toastService.error('Server error: Unable to save. Please try again later.');
          } else {
            this._toastService.error('Error saving Todo. Please check your input.');
          }

          console.error('Error saving schedule:', error);
          this.resetFlags(); // Reset flags after failure
          reject('Error saving schedule'); // Reject promise on error
        }
      });
    });
  }






  saveAndNewToDo() {
    this.saveButton('saveAndNew')
      .then(() => {
        this.resetForm();
        this.toDoResponse = null;

      })
      .catch((error) => {
        console.error('Error during save and new:', error);
      });
  }


  saveAndCloseToDo() {
    return this.saveButton('saveAndClose')
      .then(() => {
        this.closeDrawer.emit();
        this.resetForm();
        this.toDoResponse = null;
        this.close();
      })
      .catch((error) => {
        console.error('Error during save and close:', error);
      });
  }

  resetFlags() {
    this.isSaving = false;
    this.isSaveAndClose = false;
    this.isSaveAndNew = false;
    this.DisabledNextActivity = false;
  }

  resetForm(): void {
    this.todoForm.reset({
      globalId: '00000000-0000-0000-0000-000000000000',
      jobInformationId: this.selectedJobId,
      isCompleted: false,
      completedBy: 1,
      title: '',
      notes: '',
      isChooseDate: true,
      isLinkedScheduleItem: false,
      scheduleItemParameterId: 0,
      dueNumberDays: 0,
      dueDate: null,
      before: true,
      after: null,
      time: null,
      prioritySetupId: 0,
      reminderSetupId: 0,
      attachmentId: null,
      beforeOrAfter: ['before'],
      createToDoParameterAssignOwnersRequests: null,
      createToDoInformationTagParameterRequests: null
    });

    this.toDoResponse = null;

    // Reset the child component form if it exists
    // if (this.childComponent) {
    //   this.childComponent.resetChecklistForm();
    //   this.childComponent.removeAllItems();
    // }

    // Also reset the visibility of the reminder field
    this.showPriorityField = false;
    this.showTagsField = false;
    this.showReminderField = false;
    this.showAttachment = false;
    this.isLinkToAdd = true;

    this.refreshErrorStates();
    this.onToDoResetAttachment();
    this.resetFlags();


  }


  onToDoResetAttachment(): void {
    this.attachmentsComponent?.clearAttachment();
  }











  // Method to scroll to the top of the form
  scrollToTop() {
    const formElement = document.getElementById('todoForm'); // Replace with your form's ID
    if (formElement) {
      formElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  refreshErrorStates(): void {
    this.titleErrorMessage = '';
    this.allNotesErrorMessage = '';
    // this.childComponent.nameMessage = '';
    this.hasAttemptedSubmit = false;
    this.errorMessages = [];

    // Reset parent form controls
    Object.values(this.todoForm.controls).forEach(control => {
      control.markAsPristine();
      control.markAsUntouched();
      control.updateValueAndValidity();
    });
  }


  createAndModifiedByData(id: number) {
    const payload: FilterFooterAudit = {
      id: id,
      formCode: 'PMTD'
    }
    this._footerService.createdAndModifyUser(payload).subscribe(res => {
      this.footerResponse = res.result;
    })
  }



  // Save Save And New , Save And Close Methods End




  isItemClicked = false;
  isAssigneeAdded: boolean = false;
  isAttachmentAdded: boolean = false;





  viewTabs = [1, 2, 3,];
  tabs = [
    { id: 1, title: 'To-Do Information', show: true },
    { id: 2, title: 'Checklist', show: true },
    { id: 3, title: 'Related Items', show: true },

  ];



  isDeleteLoading: boolean = false; // Step 1: Add loading flag
  confirmModal?: NzModalRef;

  deleteTodo() {
    const id = this.todoForm.get('id').value;
    this.isDeleteLoading = true; // Start loading

    this._toDoInformationService.deleteToDo(id).subscribe(
      res => {
        this._toastService.success('Deleted Successfully');
        this.isDeleteLoading = false; // End loading
        this.saveComplete.emit(this.toDoResponse.ToDoId); // Emit the saved Id
      },
      err => {
        this._toastService.error('Deletion failed'); // Handle error appropriately
        this.isDeleteLoading = false; // End loading
      }
    );
  }

  showConfirmDeleteTodo(): void {
    this.confirmModal = this.modal.confirm({
      nzTitle: 'Delete To-Do?',
      nzContent: 'Are you sure you want to permanently delete this To-Do?',
      nzOkDanger: true,
      nzOkText: 'Delete',
      nzOkLoading: this.isDeleteLoading, // Step 2: Set loading flag here
      nzCentered: true,
      nzIconType: ' ',
      nzAutofocus: 'cancel',
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          this.isDeleteLoading = true; // Show loading spinner
          this.deleteTodo(); // Call deleteSchedule here

          setTimeout(() => {
            this.isDeleteLoading = false; // Hide loading spinner after delay
            this.close();
            resolve(); // Resolve the promise to close the modal
          }, 1000); // Delay in milliseconds (1 second)
        }),
      nzOnCancel: () => '',
    });
  }


  close(): void {
    this.visible = false;
    this.closeDrawer.emit();
    this.showPriorityField = false;
    this.showTagsField = false;
    this.showReminderField = false;
    this.showAttachment = false;
    this.isLinkToAdd = true;

  }




  // Schedule Item Add And Edit


  newScheduleOpen(): void {
    const modalRef = this.modal.create({
      nzContent: NewscheduleItemComponent,
      nzFooter: null,
      nzTitle: '<h1 nz-typography>Add New Phase</h1>',
      nzWidth: '30%',
      nzBodyStyle: {
        'background-color': '#f1f4fa',
        'padding': '20px',
      },
      nzStyle: { top: '10px' },
      nzClosable: true,
      nzMaskClosable: true,
      nzOnCancel: () => {
        modalRef.componentInstance.onCancel();
      }
    });

    modalRef.componentInstance.selectedJobId = this.accessLocalStorageService.getJobId();
    modalRef.componentInstance.selectedJobName = this.accessLocalStorageService.getJobName();
    modalRef.componentInstance.selectedColorId = this.accessLocalStorageService.getJobColorId();


    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
      if (this.selectedJobId) {
        this.getScheduleByJob(this.selectedJobId);
      }
    });

    modalRef.componentInstance.scheduleSaved.subscribe(async (schedule: ResponseScheduleItemResult) => {
      if (this.selectedJobId) {
        await this.getScheduleByJob(this.selectedJobId);
        this.todoForm.patchValue({ scheduleItemParameterId: schedule.Id });
      }
      modalRef.destroy();
    });

  }

  newScheduleEdit(): void {
    const selectedScheduleItemId = this.todoForm.get('scheduleItemParameterId')?.value;
    const selectedScheduleItem = this.scheduleList.find(res => res.Id === selectedScheduleItemId);

    if (selectedScheduleItemId > 0 && selectedScheduleItem) {
      const modalRef = this.modal.create({
        nzContent: NewscheduleItemComponent,
        nzFooter: null,
        nzTitle: '<h1 nz-typography>Edit Phase</h1>',
        nzWidth: '30%',
        nzBodyStyle: {
          'background-color': '#f1f4fa',
          'padding': '20px',
        },
        nzStyle: { top: '10px' },
        nzClosable: true,
        nzMaskClosable: true,
        nzOnCancel: () => {
          modalRef.componentInstance.onCancel();
        }
      });

      // Pass data to the modal instance
      modalRef.componentInstance.scheduleId = selectedScheduleItemId;
      modalRef.componentInstance.selectedJobId = this.accessLocalStorageService.getJobId();
      modalRef.componentInstance.selectedJobName = this.accessLocalStorageService.getJobName();
      modalRef.componentInstance.selectedColorId = this.accessLocalStorageService.getJobColorId();


      modalRef.componentInstance.cancel.subscribe(() => {
        modalRef.destroy();
        if (this.selectedJobId) {
          this.getScheduleByJob(this.selectedJobId);

        }
      });

      modalRef.componentInstance.scheduleSaved.subscribe(async (schedule: ResponseScheduleItemResult) => {
        if (this.selectedJobId) {
          await this.getScheduleByJob(this.selectedJobId);
          this.todoForm.patchValue({ scheduleItemParameterId: schedule.Id });
        }
        modalRef.destroy();
      });


      modalRef.componentInstance.deleteScheduleModal.subscribe(async () => {
        if (this.selectedJobId) {
          await this.getScheduleByJob(this.selectedJobId);
          this.todoForm.patchValue({ scheduleItemParameterId: 0 });
        }
        modalRef.destroy();
      });
    }

  }


  // Main tab 3 Related Items


  // Related Daily Log Get all

  getAllRelatedDailyLogs(dailyLogId: number) {
    this._toDoInformationService.toDoRelatedDailyLog(dailyLogId).subscribe(res => {
      this.relatedDailyLogList = res.result;
    })
  }




  createToDoAndOpenDailyLog() {
    // Check if toDoResponse.Id exists
    if (this.toDoResponse && this.toDoResponse.Id) {
      // Open the modal directly if toDoResponse.Id exists
      this.openDailyLogModal();
    } else {
      // Otherwise, call the saveButton and then open the modal
      this.saveButton('save')
        .then(() => {
          this.openDailyLogModal();  // Call the modal opening function after saving
        })
        .catch((error) => {
          console.error('Error during save and new:', error);
        });
    }
  }





  // Method to open the modal and pass the necessary data
  openDailyLogModal() {
    const modalRef = this.modal.create({
      nzFooter: null,
      nzTitle: null,
      nzContent: ProjectManagementSiteDiariesNewDailyLogComponent,
    });

    // Pass data to the child component
    modalRef.componentInstance.selectedJobId = this.selectedJobId;
    modalRef.componentInstance.selectedJobName = this.selectedJobName;
    modalRef.componentInstance.selectedJobStreetAddress = this.localStorage.getJobAddress();
    modalRef.componentInstance.relatedFormNameId = this.formNameId;
    modalRef.componentInstance.relatedHeaderId = this.toDoId;
    modalRef.componentInstance.selectedTodoNotes = this.toDoResponse?.Notes;


    // Listen for the cancel event and get the ID
    modalRef.componentInstance.cancelForTodo.subscribe((id: number) => {
      modalRef.destroy(); // Close the modal
      if (id > 0) {
        this.getAllRelatedDailyLogs(id); // Fetch related daily logs using the ID
      }
    });

    modalRef.componentInstance.cancel.subscribe((id: number) => {
      modalRef.destroy(); // Close the modal
    });
  }


  EditSiteDiariesDailyLogByRelatedItems(id: number): void {
    if (id) {
      const modalRef = this.modal.create({
        nzContent: ProjectManagementSiteDiariesNewDailyLogComponent,
        nzFooter: null,
      });
      modalRef.componentInstance.selectedDailyLogId = id;
      modalRef.componentInstance.selectedJobId = this.selectedJobId;
      modalRef.componentInstance.selectedJobName = this.selectedJobName;




      modalRef.componentInstance.cancel.subscribe(() => {
        modalRef.destroy();
      });
    }

  }



  newScheduleTagOpen(): void {
    const modalRef = this.modal.create({
      nzContent: ToDoTagsComponent,
      nzFooter: null,
      nzTitle: '<h1 nz-typography>Add To-Do Tag</h1>',
      nzWidth: '35%',
      nzBodyStyle: {
        'background-color': '#f1f4fa',
        padding: '20px',
      },
      nzStyle: { top: '10px' },
    });

    modalRef.componentInstance.cancelTag.subscribe(() => {
      modalRef.close();
    });


    modalRef.componentInstance.tagSavedSelected.subscribe((newTag: LookupNameSetupResponse) => {
      // Step 1: Call initLoad() and wait for it to complete
      this.initLoad().then(() => {
        // Step 2: Create the new tag node based on the saved tag
        const newTagNode = {
          title: newTag.name,
          key: newTag.id.toString(),
          value: newTag.id.toString(),
          isLeaf: true,
        };

        // Step 3: Check if the tag is already present in the tagsNodes array
        const existingTagNode = this.tagsNodes[0].children.find(
          (child: any) => child.value === newTag.id.toString()
        );

        if (!existingTagNode) {
          // Step 4: Add the new tag node to the tagsNodes array if not already present
          if (this.tagsNodes.length > 0 && this.tagsNodes[0].children) {
            this.tagsNodes[0].children.push(newTagNode);
          }
        }

        // Step 5: Update the form control value, but prevent duplicate values
        const currentValues = this.todoForm.get('createToDoInformationTagParameterRequests')?.value || [];
        const updatedValues = [...new Set([...currentValues, newTag.id.toString()])]; // Ensure no duplicates with Set

        this.todoForm.get('createToDoInformationTagParameterRequests')?.setValue(updatedValues);
        this.todoForm.get('createToDoInformationTagParameterRequests')?.markAsTouched();
        this.todoForm.get('createToDoInformationTagParameterRequests')?.updateValueAndValidity();

        // Step 6: Close the modal only after initLoad is completed
        modalRef.destroy();
      }).catch((err) => {
        console.error('Error during initLoad:', err);
        // Handle the error, if necessary
      });
    });
  }

  // Edit Mode Tags

  newScheduleTagEdit(): void {
    const selectedTagIds = this.todoForm.get('createToDoInformationTagParameterRequests')?.value;
    if (selectedTagIds && Array.isArray(selectedTagIds) && selectedTagIds.length === 1) {
      const selectedTagId = selectedTagIds[0].toString();
      const selectedTag = this.tagResponse.find((res) => res.id.toString() === selectedTagId);
      if (selectedTag) {
        // Create a modal to edit the selected tag
        const modalRef = this.modal.create({
          nzContent: ToDoTagsComponent,
          nzFooter: null,
          nzTitle: '<h1 nz-typography>Edit To-Do Tag</h1>',
          nzWidth: '35%',
          nzBodyStyle: {
            'background-color': '#f1f4fa',
            padding: '20px',
          },
          nzStyle: { top: '10px' },
        });

        // Pass the selected tag to the modal component
        modalRef.componentInstance.selectedTag = selectedTag;
        modalRef.componentInstance.cancelTag.subscribe(() => {
          modalRef.destroy();

        });

        modalRef.componentInstance.tagSavedSelected.subscribe((newTag: LookupNameSetupResponse) => {
          this.initLoad().then(() => {
            const newTagNode = {
              title: newTag.name,
              key: newTag.id.toString(),
              value: newTag.id.toString(),
              isLeaf: true,
            };
            // Step 3: Add the new tag node to the tagsNodes array
            if (this.tagsNodes.length > 0 && this.tagsNodes[0].children) {
              this.tagsNodes[0].children.push(newTagNode);
            }

            // Step 4: Update the form control value
            const updatedValues = [newTag.id.toString()];
            this.todoForm.get('createToDoInformationTagParameterRequests')?.patchValue(updatedValues);
            this.todoForm.get('createToDoInformationTagParameterRequests')?.markAsTouched();
            this.todoForm.get('createToDoInformationTagParameterRequests')?.updateValueAndValidity();

            // Step 5: Close the modal only after loadDropdownData is completed
            modalRef.destroy();
          }).catch((err) => {
            console.error('Error during loadDropdownData:', err);
            // Handle the error, if necessary
          });
        });



        // Subscribe to the tagSaved event when a tag is deleted
        modalRef.componentInstance.tagSaved.subscribe(() => {
          this.initLoad().then(() => {
            this.todoForm.patchValue({ createToDoInformationTagParameterRequests: null });
            modalRef.destroy();
          }).catch((err) => {
            console.error('Error during loadDropdownData:', err);
          });
        });

      } else {
        console.error('Tag not found! Ensure the selected ID exists in tagResponse.');
      }
    } else {
      console.error('No valid tag selected for editing! Ensure only one tag is selected.');
    }
  }

  isSingleItemSelected(): boolean {
    const value = this.todoForm.get('createToDoInformationTagParameterRequests')?.value;
    return value && Array.isArray(value) && value.length === 1; // Adjusted to check if it's an array with one element
  }



  onTagChange(selectedValues: string[]): void {
    const checkAllIndex = selectedValues.indexOf('check_all');

    if (checkAllIndex !== -1) {
      const allValues = this.tagsNodes[0].children.map(child => child['value']);
      this.todoForm.get('createToDoInformationTagParameterRequests')?.setValue(allValues);
      this.isSingleTagSelected = false; // Reset the single tag selection flag
    } else {
      const allValues = this.tagsNodes[0].children.map(child => child['value']);
      const filteredValues = selectedValues.filter(value => allValues.includes(value));
      this.todoForm.get('createToDoInformationTagParameterRequests')?.setValue(filteredValues);
      this.isSingleTagSelected = filteredValues.length === 1; // Set to true if only one tag is selected
    }
    this.todoForm.get('createToDoInformationTagParameterRequests')?.markAsTouched();
    this.todoForm.get('createToDoInformationTagParameterRequests')?.updateValueAndValidity();
  }


  lookupSetNameResponse: LookupNameSetupResponse[] = [];


  initLoad(): Promise<void> {
    return new Promise((resolve, reject) => {
      // Fetch lookup values
      this.LookupFilterService.getLookUpValuesByFormNameId(this.PAGE_id).subscribe(
        (res) => {
          this.lookupSetNameResponse = res.result;

          this.tagResponse = res.result.filter(
            (x) => x.code === this.preDefinedDataCodes.Tags.code
          );
          this.tagsNodes = [
            {
              title: 'Check All',
              value: 'check_all',
              key: 'check_all',
              selectable: false,
              isLeaf: false,
              expanded: true,
              children: this.tagResponse.map((tag) => ({
                title: tag.name,
                value: tag.id.toString(),
                key: tag.id.toString(),
                isLeaf: true,
              })),
            },
          ];

          // Resolve the promise after setting the tagsNodes
          resolve();
        },
        (error) => {
          // Reject the promise if an error occurs
          console.error('Error fetching lookup values:', error);
          reject(error);
        }
      );
    });
  }




  IsCheckDisable: boolean = false;
  // onCheckDisable(isDisabled?: boolean) {
  //   this._toDoInformationService.getCompanytodo().subscribe({
  //     next: (res) => {
  //           if(res?.result?.requireAllChecklistItemsToBeCompleteInOrderToCompleteAtoDo === true){
  //             this.IsCheckDisable = isDisabled;
  //             if (isDisabled) {
  //               this.todoForm.get('isCompleted')?.setValue(false);
  //             }
  //           }
  //     }
  //   });
  // }


  private hasFetchedCompanyTodo = false;
  requireAllChecklistItems = false;

  onCheckDisable(isDisabled: boolean) {
    if (this.hasFetchedCompanyTodo) {
      this.applyDisableCondition(isDisabled);
      return;
    }

    this._toDoInformationService.getCompanytodo().subscribe({
      next: (res) => {
        this.requireAllChecklistItems = res?.result?.requireAllChecklistItemsToBeCompleteInOrderToCompleteAtoDo || false;
        this.hasFetchedCompanyTodo = true;
        this.applyDisableCondition(isDisabled);
      },
      error: (error) => console.error('Error fetching company todo setup:', error)
    });
  }

  applyDisableCondition(isDisabled: boolean) {
    if (this.requireAllChecklistItems) {
      this.IsCheckDisable = isDisabled;
      if (isDisabled) this.todoForm.get('isCompleted')?.setValue(false);
    }
  }




  // Child Component CheckList Acesss

  @ViewChild(ProjectManagementSiteDiariesChecklistComponent) ChildRowAccess!: ProjectManagementSiteDiariesChecklistComponent;

  ngAfterViewInit() {
    // After view initializes, you can check the completion status
    this.showCompleteAllButton();
  }

  accessAddItem() {
    this.ChildRowAccess.addItem()
  }

  // Remove All CheckList Start

  showConfirmRemoveAll(): void {
    this.confirmModal = this.modal.confirm({
      nzTitle: 'Remove Checklist?',
      nzContent: 'All checklist items will be removed. The to-do must also be saved in order for this change to be made permanent.',
      nzOkDanger: true,
      nzClosable: false,
      nzCentered: true,
      nzWidth: '30%',
      nzIconType: '',
      nzOkText: 'Remove',
      nzCancelText: 'Cancel',
      nzOnOk: () =>
        new Promise<void>((resolve) => {
          this.removeAllItems();
          resolve();
        }).catch(() => console.log('Oops, error!')), // Handle any errors
    });
  }

  removeAllItems() {
    this.ChildRowAccess.removeAllItems()
  }


  get hasChecklistItems(): boolean {
    return this.ChildRowAccess?.toDoFormCheckListArray?.length > 0;
  }


  showCompleteAllButton(): boolean {
    return !this.childComponent?.areAllItemsComplete();
  }

  completeAll(){
    this.ChildRowAccess.completeAll();
  }



  toggleDateFields(){
    this.isLinkToAdd = true;
    this.todoForm.patchValue({
      isChooseDate: true,
      isLinkedScheduleItem: false
    });
  }
  toggleScheduleItemFields(){
    this.isLinkToAdd = false;
    this.todoForm.patchValue({
      isLinkedScheduleItem: true,
      isChooseDate: false,
    });
  }





  calculateDate(baseDate: Date | undefined, days: number, direction: number): Date | null {
    if (!baseDate) return null;
    const resultDate = new Date(baseDate);
    resultDate.setDate(resultDate.getDate() + days * direction);
    return resultDate;
  }


  daysInput: number = 0;

  onFieldScheduleItem() {
    const scheduleItemParameterId = this.todoForm.get('scheduleItemParameterId')?.value;
    const selectedScheduleItem = this.scheduleList.find(item => item.Id === scheduleItemParameterId);

    // Only proceed if a valid item is selected
    if (scheduleItemParameterId && selectedScheduleItem) {
      const start_On = selectedScheduleItem?.StartOn;
      const end_On = selectedScheduleItem?.EndOn;
      this.ScheduleItem_start_end_On = true;

      // Calculate the base date based on "before" or "after" selection
      const baseDate = this.DaysradioValue === 'A' ? end_On : start_On;
      const daysOffset = this.DaysradioValue === 'B' ? -this.daysInput : this.daysInput;

      // Calculate and set the new due date
      const calculatedDate = this.calculateDate(baseDate, daysOffset, 1);
      this.todoForm.get('dueDate')?.setValue(calculatedDate);
      this.todoForm.get('beforeOrAfter')?.enable();
      this.todoForm.get('beforeOrAfter')?.reset();

      // Disable radio group

      // Update due date display
      this.updateDueDate();
    } else {
      this.ScheduleItem_start_end_On = false;
      this.todoForm.get('beforeOrAfter')?.disable();
      this.todoForm.get('beforeOrAfter')?.reset();
      this.todoForm.get('daysInput')?.disable();
    }
  }

  updateDueDate() {

    let dueOn = this.todoForm.get('dueDate')?.value;

    if (typeof dueOn === 'string') {
      dueOn = new Date(dueOn);
    }

    if (dueOn instanceof Date && !isNaN(dueOn.getTime())) {
      let newDate = new Date(dueOn);

      if (this.DaysradioValue === 'A') {
        newDate.setDate(newDate.getDate() - this.todoForm.get('dueNumberDays')?.value);
      } else if (this.DaysradioValue === 'B') {
        newDate.setDate(newDate.getDate() + this.todoForm.get('dueNumberDays')?.value);
      }

      const formattedDate = newDate.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      });

      this.dueDateTest = formattedDate;
      this.todoForm.get('dueDate')?.setValue(newDate);
    } else {
      this.dueDateTest = null;
      this.todoForm.get('dueDate')?.setValue(null);
    }
  }

  onDaysChange(value: number) {


    if (!isNaN(value) && value <= 999) {
      this.todoForm.get('dueNumberDays')?.setValue(value);
    } else if (!isNaN(value) && value > 999) {
      this.todoForm.get('dueNumberDays')?.setValue(999);
    } else {
      this.todoForm.get('dueNumberDays')?.setValue(0);
    }
    this.updateDueDate();
  }

  onFieldisComplete(field: string, selectedId: number) {
    switch (field) {
      case 'scheduleItemParameterId':
        const selectedScheduleItem = this.scheduleList.find(option => option.Id === selectedId);
        this.scheduleItem = selectedScheduleItem?.Title;
        this.todoForm.get('scheduleItemParameterId')?.setValue(selectedId);
        this.onFieldScheduleItem();

        break;
      default:
    }
  }

  validateNumberInput(event: KeyboardEvent): void {
    const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Tab'];
    const isNumberKey = event.key >= '0' && event.key <= '9';

    if (!isNumberKey && !allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  }

  onFieldChange(field: string, value: any) {
    if (field === 'dueDate') {
      const formattedDate = value.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      });
      this.dueDate = formattedDate;
    }
    this.todoForm[field] = value;
    this.changedFields.add(field);
  }







  DaysradioButtons() {
    switch (this.DaysradioValue) {
      case 'A':
        this.todoForm.patchValue(({
          after : false,
          before : true
      }))
        break;
      case 'B':
        this.todoForm.patchValue(({
          after : true,
          before : false
      }))
        break;
    }
    this.updateDueDate();
    this.onFieldScheduleItem();
  }




}
