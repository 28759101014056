<nz-modal [nzWidth]="isLargeScreen ? '80%' : '80%'" [(nzVisible)]="schedule" [nzTitle]="schedule1"
  [nzFooter]="schedule2" (nzOnCancel)="closeSchedule()"
  [nzBodyStyle]="{ background: '#f1f4fa', padding: '14px', 'max-height': isLargeScreen ? '77vh' : '60vh', 'overflow-y': 'auto' }"
  [nzStyle]="{ top: '18px' }">
  <ng-template #schedule1>
    <div class="row">
      <h1 nz-typography class="p-0 m-0 ">Schedule</h1>
    </div>
  </ng-template>

  <div class="content" *nzModalContent>

    <form nz-form [formGroup]="scheduleForm">

      <nz-card nzTitle="Default Viewing Options" class="mb-3" nzBordered="false">
        <div nz-row>
          <div nz-col nzSpan="24">
            <label class="sub-text ms-1">Default builder view</label>
          </div>
        </div>
        <div nz-row>
          <div nz-col nzSpan="4">
            <nz-form-item>
              <nz-form-control>
                <nz-select nzShowSearch formControlName="builderViewId">
                  <nz-option *ngFor="let role of builder" [nzLabel]="role.name" [nzValue]="role.id"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div nz-row>
          <div nz-col nzSpan="24">
            <label class="sub-text ms-1">Default owner view</label>
          </div>
        </div>
        <div nz-row>
          <div nz-col nzSpan="4">
            <nz-form-item>
              <nz-form-control>
                <nz-select nzShowSearch formControlName="ownerViewId">
                  <nz-option *ngFor="let role of owner" [nzLabel]="role.name" [nzValue]="role.id"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div nz-row class="mt-2">
          <div nz-col nzSpan="24">
            <label class="sub-text ms-1 ">Default owner schedule display</label>
          </div>
        </div>
        <div nz-row>
          <div nz-col nzSpan="24">
            <nz-form-item>
              <nz-form-control>
                <nz-radio-group>
                  <div class="row mb-1">
                    <div class="col ms-1  ">
                      <label nz-radio nzValue="Phases" formControlName="isPhases">Phases
                        <i class="bi bi-info-circle-fill text-secondary ms-1" nz-popover [nzPopoverContent]="Include"
                          nzPopoverPlacement="top"></i>
                      </label>
                    </div>

                  </div>
                  <div class="row">
                    <div class="col ms-1">
                      <label nz-radio nzValue="ScheduleItems" formControlName="isScheduleItem">Schedule Items</label>
                    </div>
                  </div>
                </nz-radio-group>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <ng-template #Include>
          <div class="rounded-2">
            <p style="height: auto; width: 240px">
              With Phases Only selected, Owners will not be able to see any schedule item information including attached
              files, comments, or notes. Only phases with at least one schedule item viewable to Owner will show to the
              Owner.
            </p>
          </div>
        </ng-template>
      </nz-card>



      <nz-card nzTitle="Default Notification Options" class="mb-3" nzBordered="false">
        <div nz-row>
          <div nz-col nzSpan="24">
            <label class="sub-text ms-1">Default schedule item reminders</label>
          </div>
        </div>
        <div nz-row>
          <div nz-col nzSpan="8">
            <nz-form-item>
              <nz-form-control>
                <nz-select nzShowSearch formControlName="scheduleItemReminderId">
                  <nz-option *ngFor="let role of scheduleItemReminder" [nzLabel]="role.name"
                    [nzValue]="role.id"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div nz-row>  
          <div nz-col nzSpan="24">
            <nz-form-item>
              <nz-form-control>
                <label nz-checkbox formControlName="sendConfirmationRequestWithScheduleItemReminders">
                  Send confirmation request with schedule item reminders
                </label>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div nz-row>
          <div nz-col nzSpan="24">
            <nz-form-item>
              <nz-form-control>
                <label nz-checkbox formControlName="defaultNotifyLinkedSubsVendor">
                  Default notify linked subs/vendors
                </label>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </nz-card>

      <nz-card nzTitle="More Notification Options" class="mb-3" nzBordered="false">
        <div nz-row>
          <div nz-col nzSpan="24">
            <nz-form-item>
              <nz-form-control>
                <label nz-checkbox formControlName="showTimesForHourlyItemsOnCalendarMonthView">
                  Show times for hourly items on Calendar - Month view
                </label>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div nz-row>
          <div nz-col nzSpan="24">
            <nz-form-item>
              <nz-form-control>
                <label nz-checkbox formControlName="showJobNameOnCalendarMonthWeekDayViewsForAllListedJobs">
                  Show job name on Calendar - Month/Week/Day views for All Listed Jobs
                </label>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div nz-row>
          <div nz-col nzSpan="24">
            <nz-form-item>
              <nz-form-control>
                <label nz-checkbox formControlName="automaticallyMarkItemsComplete">
                  Automatically mark items complete
                </label>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div nz-row>
          <div nz-col nzSpan="24">
            <nz-form-item>
              <nz-form-control>
                <label nz-checkbox formControlName="newScheduleItemsDefaultToShowSubsVendors">
                  New Schedule Items - Default to show subs/vendors
                </label>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div nz-row>
          <div nz-col nzSpan="24">
            <nz-form-item>
              <nz-form-control>
                <label nz-checkbox formControlName="includeHeaderOnSchedulePdfExports">
                  Include header on schedule PDF exports
                </label>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </nz-card>








      <!-- Phase Card -->
      <nz-card nzTitle="Phases" [nzExtra]="ButtonSection" nzBordered="false">
        <ng-template #ButtonSection>
          <button nz-button nzType="primary" (click)="newSchedulePhaseOpen()">
            Add Phase
          </button>
        </ng-template>

          <nz-table [nzData]="phaseList" nzFrontPagination="false"  >
            <thead class="ant-table-thead border-0">
              <tr>
                <th style="width: 80px;"></th>
                <th style="width: 450px;">Phase Name</th>
                <th>Color</th>
              </tr>
            </thead>

            <tbody cdkDropList [cdkDropListData]="phaseList" (cdkDropListDropped)="drop($event)"  >
              <tr   *ngFor="let item of phaseList; let i = index; trackBy: trackByFn" cdkDragHandle 
                [ngClass]="{'replace-bg': i === previousIndex}" cdkDrag (cdkDragStarted)="onDragStarted(i)">
                <td>
                  <i style="cursor: grab;font-size:20px" nz-icon nzType="holder" nzTheme="outline"></i>
                </td>
                <td class=" text-primary">
                  <span class="hover-name" (click)="newSchedulePhaseEdit(item.Id)">
                    <a>
                      {{ item.Name }}
                    </a>
                  </span>
                  </td>
                  <td>
                    <span [style.background-color]="item.ColorCollection" class="circle  ">
                    </span>
                    <span>{{item.ColorName}}</span>
                  </td>
              </tr>
            </tbody>


          </nz-table>

      </nz-card>
    </form>
  </div>
</nz-modal>
<ng-template #schedule2>
  <button nz-button nzType="primary" class="rounded-1 me-0" (click)="saveScheduleCompanySetting()">Save</button>
</ng-template>
