<app-header></app-header>
<nz-layout>
  <div class="site-page-header-ghost-wrapper">
    <nz-page-header>
      <nz-page-header-title>
        <h2 class="p-0 m-0 famly-inn ant-typography-Modal">Cost Codes</h2>
      </nz-page-header-title>
      <nz-page-header-content>
        <nz-descriptions nzSize="small" [nzColumn]="1">
        </nz-descriptions>
        <div style="margin-top: 15px; text-align: right;">
          <button nz-button nzType="primary" nz-dropdown [nzDropdownMenu]="newDropdown" [nzTrigger]="'click'">
            <span nz-icon nzType="plus" nzTheme="outline"></span>
            <span class="ms-2">New</span>
          </button>
        </div>
      </nz-page-header-content>
      <nz-page-header-extra>
      </nz-page-header-extra>
    </nz-page-header>
  </div>
  <div class="grid-container" style="padding: 20px; height:fit-content">
    <nz-table 
    #basicTable 
    [nzData]="listOfData" 
    [nzBordered]="true" 
    [nzScroll]="{ y: '300px' }"
    [nzShowPagination]="false"
    style="border-collapse: collapse; width: 100%;"> 
    <thead>
      <tr >
        <th nzWidth="45%" style="border: none; padding: 12px 16px;">
          <label nz-checkbox></label>
          <span class="ms-2">Title</span>
        </th>
        <th nzWidth="45%" style="border: none; padding: 12px 16px;">Category</th>
        <th nzWidth="10%" style="border: none; padding: 12px 16px;">Actions</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let node of nodes">
        <tr>
          <td>
            <nz-tree [nzData]="[node]" [nzExpandAll]="true" ></nz-tree>
          </td>  
          <td>{{ node.title }}</td>
        <td class="action-buttons">
          <button nz-button nzType="link" class="icon-button">
            <span nz-icon nzType="edit"></span>
          </button>
          <button nz-button nzType="link" class="icon-button">
            <span nz-icon nzType="delete"></span>
          </button>
        </td>
      </tr>
      </ng-container>
    </tbody>
  </nz-table> 
  </div>
</nz-layout>

<nz-dropdown-menu #newDropdown="nzDropdownMenu">
  <ul nz-menu>
    <li nz-menu-item (click)="onCostCodeOpen()">Code</li>
    <li nz-menu-item (click)="onCategoryOpen()">Category</li>
  </ul>
</nz-dropdown-menu>






