import { CustomerStatusCode } from './../../../shared/component/Models/LookUpStandardFilterCodes';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { CustomerInformationService } from 'src/Service/customer-information.service';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LoadingIndicatorService } from 'src/shared/Shared-Services/loading-indicator.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzPlacementType } from 'ng-zorro-antd/dropdown';
import { Subscription } from 'rxjs';
import { ModalService } from 'src/Service/CompanyInFormation/modal.service';
import { CreateStandardViewRequestService } from 'src/Service/CompanyInFormation/create-standard-view-request.service';
import { CustomerInformation } from 'src/Models/CustomerInformation';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { SUbvendorStandardViewModalService } from 'src/Service/SubVendorsServices/subvendor-standard-view-modal.service';
import { FilterSideMenuBarService } from 'src/shared/Shared-Services/filter-side-menu-bar.service';
import { FiltersName } from 'src/app/Core/Enums/filtersName';
import { NewContactComponent } from '../new-contact/new-contact.component';
import { CustomerContactGridResponse, CustomerInformationResponse } from 'src/Models/CustomerInfromation/CustomerAndLeadOpportunityResponse';
import { ResponseModel, TablePageResponse } from 'src/Models/responseMessage.model';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { StandardViewForAllService } from 'src/Service/standard-view-for-all.service';
import { StandardColumnSetupResponse, StandardViewResponse } from 'src/Models/LookupStandardview/Standardview';
import { AddNewGridStandardViewComponent } from 'src/app/shared/component/Modal/add-new-grid-standard-view/add-new-grid-standard-view.component';
import { FilterSearchParameter, LookupFilterResponse } from 'src/app/shared/component/Models/LookupModels';
import { InternalUserService } from 'src/Service/Internaluser/internal-user.service';
import { ColumnsResizePinProperties } from 'src/Models/InternalUser/internalUserColumnsPinResize';

import * as XLSX from 'xlsx';
import { LeadOpportunitiesService } from 'src/Service/lead-opportunities.service';
import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';

@Component({
  selector: 'app-customer-contacts-dashboard',
  templateUrl: './customer-contacts-dashboard.component.html',
  styleUrls: ['./customer-contacts-dashboard.component.css']
})
export class CustomerContactsDashboardComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  private Customersubscription: Subscription;
  private CustomereFilterSubscription: Subscription;
  private displayColumnSubscriptio: Subscription;
  private CustomerstandardViewFilterSubscription: Subscription
  PAGE_ID: number = -1;
  current = 1;
  pageSizeOptions = [10, 20, 50, 75, 100]; // Page size options
  isSimple: boolean = false;
  listOfCurrentPageData: readonly CustomerInformationResponse[] = [];
  listOfData: readonly CustomerInformationResponse[] = [];
  CUSTOMER_CONTACT: string = FiltersName.CUSTOMER_CONTACT;
  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  allSelected: boolean = false;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.Pakistan];
  phoneForm = new FormGroup({
    phone: new FormControl(undefined, [Validators.required])
  });
  CustomerInformationform!: FormGroup;
  customerData: any;
  initials: string = '';
  csustomerInformation: CustomerInformation;
  listOfOption: Array<{ label: string; value: string }> = [];
  listOfTagOptions = [];
  isVisible = false;
  showCard = true;
  avatarText: string = '';
  selectedValue: string = '';
  selectedValue1: string = '';
  visible = false;
  idVisible = false;
  showCards = false;
  initialEmail: string | null = null;
  emailFormArrays: any = FormGroup;
  emailAddCount: number = 0;
  checked00 = false;
  indeterminate = false;
  getCustomerInformationResponseModelArray: ResponseModel<TablePageResponse<CustomerInformationResponse>>;
  setOfCheckedId = new Set<number>();
  displayNameInvalid: boolean = false;
  SavedRecordResponse: any;
  createdDateTime: any = '';
  loadingSave: boolean = false;
  initialLabel: string | null = null;
  initialFormData: any;
  isRecordSaved: boolean = false;
  recordSaved: boolean = false;
  CustomerEditMOde: boolean;
  selectedColumnName: string;
  filters: any[] = [];
  filtersdata: any[] = [];
  colunNameoption: { label: string, value: string }[] = [];
  colunNameoptionView: NzTreeNodeOptions[] = [];
  selected_View_Columns: string[] = [];
  displayedColumns: string[] = [];
  displayedData: CustomerContactGridResponse[] = [];
  StatusCode = CustomerStatusCode;
  transformedData: NzTreeNodeOptions[] = [];
  lookupFilterResponse: LookupFilterResponse[] = [];
  filterSearchParameter: FilterSearchParameter;
  columns: any[] = [];
  GetDataSubvendor: any[] = []; // Array to hold the data for nz-table display
  originalData: any[] = [];
  firstName: string = '';
  lastName: string = '';
  additionalEmails: FormArray;
  selectedCategory: string;
  searchText: string = '';
  confirmModal?: NzModalRef;
  nzOkText?: string | null;
  Allviewdata: any;
  selectedSearchParameter: string;
  displayColumnSubscription: Subscription;
  isFilterApplied: boolean = false;
  LeadOpportunityGetByidData: any;
  checked23 = false;
  checked24 = false;
  isAddFilterVisible = false;
  listOfPosition: NzPlacementType[] = ['bottomLeft', 'bottomCenter', 'bottomRight', 'topLeft', 'topCenter', 'topRight'];
  @Input() checked: boolean = false;
  @Output() checkedChange = new EventEmitter<boolean>();
  isChecked: boolean = this.checked;
  previousContactTypeIds: number[] = [];
  checkboxChecked: boolean = false;
  defaultFilterId_selected: number = 0
  defaultFilterIdSelected: number = 0;
  currentPage = 1;
  pageSize = 10;
  totalRecords = 100;
  selectedFilter: number = -1;
  selectedColNameSizePin: ColumnsResizePinProperties[] = [];
  fixedSelectedColumnNames: string[] = [];
  expandedRows: number[] = [];
  isColumnSticky: boolean[] = new Array(this.displayedColumns.length).fill(false);
  columnSizes: { columnName: string, widthSize: number }[] = [];
  getColumnWidthSize: number[] = [];
  getStandardViewColumnFilterData: StandardColumnSetupResponse[] = [];
  getStandardViewFilterData: StandardViewResponse[] = [];
  StandardData: any[] = [];
  selectedValueControl: any;
  filtersData: any[] = [];
  selectedValuefilter: any = null;
  selectedFilterData: any = null;
  selectionFrom: string = 'DB';

  constructor(private sidefilterService: FilterSideMenuBarService, private modalService: ModalService, private customerInformationService: CustomerInformationService, private fb: FormBuilder,
    private modal: NzModalService, private LookupFilterService: LookupFilterService,
    private _loadingIndicatorService: LoadingIndicatorService, private customerStandardView: CreateStandardViewRequestService,
    private toastService: NzMessageService,
    private standardViewService: StandardViewForAllService,
    private changeDetectorRef: ChangeDetectorRef,
    private internalUserService: InternalUserService,
    private sharedService: LeadOpportunitiesService,
    private accessLocalStorageService: AccessLocalStorageService,
    private modalServiceStandardView: SUbvendorStandardViewModalService) {

    if (this.CustomereFilterSubscription) {
      this.CustomereFilterSubscription.unsubscribe();
    }
    if (this.Customersubscription) {
      this.Customersubscription.unsubscribe();
    }
    if (this.CustomerstandardViewFilterSubscription) {
      this.CustomerstandardViewFilterSubscription.unsubscribe();
    }
    console.clear();
    // this.displayedColumns = [];
  }
  ngOnInit(): void {
    this.selectedFilter = -1;
    this.internalUserService.setSelectedFilterId(0);
    if (this.CustomereFilterSubscription) {
      this.CustomereFilterSubscription.unsubscribe();
    }
    if (this.Customersubscription) {
      this.Customersubscription.unsubscribe();
    }
    if (this.CustomerstandardViewFilterSubscription) {
      this.CustomerstandardViewFilterSubscription.unsubscribe();
    }
    this.PAGE_ID = 2

    this.displayedColumns = [];
    this.displayColumnSubscription = this.standardViewService.$displaySelectedColApply.subscribe(colName => {
      if (colName.length > 0) {
        this.displayedColumns = colName;
        this.changeDetectorRef.detectChanges();
      }
    });
    // Add subscriptions to the array
    this.subscriptions.push(
      this.standardViewService.$displaySelectedColApply.subscribe(colName => {
        if (colName.length > 0) {
          this.displayedColumns = colName;
          this.changeDetectorRef.detectChanges();
        }
      })
    );

    this.subscriptions.push(
      this.standardViewService.$selectedStandardFilterType.subscribe(standardType => {
        this.selectedFilter = standardType != -1 ? standardType : standardType;
      })
    );
    this.displayColumnSubscription = this.standardViewService.$displaySelectedColApply.subscribe(colName => {
      if (colName.length > 0) {
        this.displayedColumns = colName;
        this.changeDetectorRef.detectChanges();
      }
    });

    this.displayColumnSubscription = this.standardViewService.$selectedStandardFilterType.subscribe(standardType => {
      if (standardType != -1) {
        this.selectedFilter = standardType;
      } else {
        this.selectedFilter = standardType;
      }
    });

    this.getStandardFilterByFormPageId(this.PAGE_ID, this.selectionFrom);
    this.getStandardViewFilterByFormPageId(this.PAGE_ID);
    this.customerInformationService.$isUserAppliedFilter.subscribe(filter => {
      if (filter) {
        this.isFilterApplied = filter;
      } else {
        this.isFilterApplied = filter;
      }
    });

  }

  addAdditionalEmail(): void {
    const newEmailGroup = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      label: ['']
    });

    this.additionalEmails.push(newEmailGroup);
  }

  showInviteUser() {
    this.modalService.triggerShowModal();
  }

  updateDisplayNameAndAvatar() {
    const firstName = this.CustomerInformationform.get('firstName')?.value || '';
    const lastName = this.CustomerInformationform.get('lastName')?.value || '';

    if (!firstName && !lastName) {
      this.CustomerInformationform.get('displayName')?.setValue('');
      this.avatarText = ''; // Reset avatar text if both fields are empty
    } else {
      const displayName = `${firstName} ${lastName}`;
      this.CustomerInformationform.get('displayName')?.setValue(displayName);
      this.avatarText = this.extractInitials(displayName);
    }
  }

  extractInitials(fullName: string): string {
    const words = fullName.split(' ');
    return words.map(word => word[0]).join('').toUpperCase();
  }

  // only display fields
  updateAvatarFromDisplayName() {
    const displayName = this.CustomerInformationform.get('displayName')?.value || '';

    if (displayName) {
      this.avatarText = this.extractInitials(displayName);
    } else {
      this.avatarText = ''; // Reset avatar text if displayName is empty
    }


  }
  // Function to update initials based on the entered display name
  updateInitials() {
    const displayName = this.CustomerInformationform.get('displayName')?.value || '';
    this.initials = this.extractInitials(displayName);
  }

  updateAvatar(): void {
    const firstName = this.CustomerInformationform.get('firstName')?.value || '';
    const lastName = this.CustomerInformationform.get('lastName')?.value || '';
    const displayName = this.CustomerInformationform.get('displayName')?.value || '';

    if (!firstName && !lastName && displayName) {
      this.avatarText = displayName;
    } else if (firstName && lastName) {
      const initials = `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase();
      this.avatarText = initials;
    } else {
      this.avatarText = '';
    }
  }


  getAvatarText(): string {
    const initials = `${this.firstName.charAt(0) || ''}${this.lastName.charAt(0) || ''}`.toUpperCase();
    this.avatarText = (initials.trim() !== '') ? initials : null;
    return this.avatarText;
  }

  listOfSelection = [
    {
      text: 'Select All Row',
      onSelect: () => {
        this.onAllChecked(true);
      }
    },


    {
      text: 'Select Odd Row',
      onSelect: () => {
        this.listOfCurrentPageData.forEach((data, index) => this.updateCheckedSet(data.id, index % 2 !== 0));
        this.refreshCheckedStatus();
      }
    },

    {
      text: 'Select Even Row',
      onSelect: () => {
        this.listOfCurrentPageData.forEach((data, index) => this.updateCheckedSet(data.id, index % 2 === 0));
        this.refreshCheckedStatus();
      }
    }
  ];

  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }


  onAllChecked(value: boolean): void {
    this.listOfCurrentPageData.forEach(item => this.updateCheckedSet(item.id, value));
    this.refreshCheckedStatus();
  }



  refreshCheckedStatus(): void {
    this.checked00 = this.listOfCurrentPageData.every(item => this.setOfCheckedId.has(item.id));
    this.indeterminate = this.listOfCurrentPageData.some(item => this.setOfCheckedId.has(item.id)) && !this.checked00;
  }

  preventCheckboxClick(event: MouseEvent): void {
    event.stopPropagation(); // Prevent click event from bubbling up to the parent td element
  }



  removeAdditionalEmail(index: number): void {
    this.additionalEmails.removeAt(index);
  }

  toggleCheckbox() {
    this.checkboxChecked = !this.checkboxChecked;
  }


  // Function to compare current contactTypeIds with the previous value
  hasContactTypeIdsChanged(currentContactTypeIds: number[]): boolean {
    const sortedPrevious = this.previousContactTypeIds.sort().join(',');
    const sortedCurrent = currentContactTypeIds.sort().join(',');
    return sortedPrevious !== sortedCurrent;
  }



  StandardshowModal(): void {
    this.isVisible = true;
  }

  StandardhandleOk(): void {
    this.isVisible = false;
  }

  StandardhandleCancel(): void {
    this.isVisible = false;
  }
  isContentVisible: boolean = false;
  toggleContent(): void {
    this.isContentVisible = !this.isContentVisible; // Toggle visibility
  }
  closedtoogle() {
    this.isContentVisible = false;
  }

  ManageSavedViews() {
    this.managesavedviews = true;
  }
  handleManageSavedViews() {
    this.managesavedviews = false;
  } isInputVisible: boolean = false;
  managesavedviews: boolean = false;

  toggleVisibility() {
    this.isInputVisible = true;


  }

  closeManageSavedViews() {
    this.isInputVisible = false;
  }
  showConfirm2(): void {
    this.confirmModal = this.modal.confirm({
      nzTitle: 'Delete all schedule item on this Job ?',
      nzOkDanger: true,
      nzOkText: 'Delete',
      nzCancelText: 'Cancel',
      nzContent: 'Deleting this filter will remove it for everyone in your account0',
      nzIconType: 'delete',
      nzCloseIcon: 'false',
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
        }).catch(() => console.log('Oops errors!')),
      nzCentered: true
    });
  }
  showConfirm3(): void {
    this.confirmModal = this.modal.confirm({
      nzTitle: 'Edit Saved Filters?',
      nzOkText: 'Edit',
      nzCancelText: 'Cancel',
      nzContent: 'Editing this filter will change it for it for everyone in your account',
      nzIconType: 'edit',

      nzCloseIcon: 'false',
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
        }).catch(() => console.log('Oops errors!')),
      nzCentered: true
    });
  }

  toggleAddFilter() {
    this.isAddFilterVisible = !this.isAddFilterVisible;
  }
  closeAddFilter() {
    this.isAddFilterVisible = false;
  }
  SaveAsFilter() {
    this.isAddFilterVisible = true; // Show the form when SaveAsFilter is called
  }


  getDataForSelectStandard(): void {
    this.customerStandardView.getData().subscribe((res: any) => {
      this.StandardData = res.result.filter(standardData => standardData.formNameId === 2);
      if (this.StandardData && this.StandardData.length > 0) {
        // Find the item with isSetAsDefault = true
        const defaultItem = this.StandardData.find(standardData => standardData.isSetAsDefault);

        // Set the default selected value
        this.selectedValueControl = defaultItem ? defaultItem.id : this.StandardData[0].id;

        this.onDropdownChange(this.selectedValueControl);
      }
    });
  }




  onDropdownChange(selectedValue: any): void {
    // Check if StandardData is defined and not empty
    if (this.StandardData && this.StandardData.length > 0) {
      this.customerStandardView.getsDataById(selectedValue).subscribe((data: any) => {

      });
    }
  }


  CustomerdViewCustomer() {
    this.modalServiceStandardView.triggerShowModal();
  }




  openFilter(component: string, pageId: number) {
    this.selectionFrom = ''; // New Changes By Aamir Ali - 22-Apr2024
    this.isColumnSticky = new Array(this.displayedColumns.length).fill(false);
    this.sidefilterService.toggleFilter(component, pageId);
    this.customerInformationService.userAppliedFilter(false);
  }
  statuscode: CustomerStatusCode

  showNewCustomerStandard() {
    this.sharedService.setAgainRowHide(false);
    const modalRef = this.modal.create({
      nzContent: NewContactComponent,
      nzData:
      {
        statusdata: CustomerStatusCode.INACTIVE
        , nzFooter: null,
      }
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
      this.customerGridTableData(1, this.PAGE_ID, this.defaultFilterId_selected, this.currentPage, this.pageSize);

    });
    modalRef.componentInstance.statusCodeDashbord = CustomerStatusCode.INACTIVE
    modalRef.componentInstance.onSaveComplete.subscribe(() => {
      this.customerGridTableData(1, this.PAGE_ID, this.defaultFilterId_selected, this.currentPage, this.pageSize);
    });
  }

  EditCustomer(Id: number): void {
    const modalRef = this.modal.create({
      nzContent: NewContactComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.CustomerResponseId = Id;

    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
      this.customerGridTableData(1, this.PAGE_ID, this.defaultFilterId_selected, this.currentPage, this.pageSize);
    });
    modalRef.componentInstance.onSaveComplete.subscribe(() => {
      this.customerGridTableData(1, this.PAGE_ID, this.defaultFilterId_selected, this.currentPage, this.pageSize);
    });
  }

  hoverStates: { [key: string]: boolean } = {};

  isHovered(item: CustomerContactGridResponse): boolean {
    return item.Id ? !!this.hoverStates[item.Id] : false;
  }


  getStandardViewFilterByFormPageId(pageId: number) {
    if (this.CustomerstandardViewFilterSubscription) {
      this.CustomerstandardViewFilterSubscription.unsubscribe();
    }
    this.displayedColumns = [];
    this.fixedSelectedColumnNames = [];
    this.standardViewService.getStandardViewFilterByFormPageId(pageId);
    this.standardViewService.getStandardViewFilterColumnsByFormPageId(pageId);
    // this.standardViewService.$getStandardViewFilter.subscribe(result => {
    this.CustomerstandardViewFilterSubscription = this.standardViewService.$getStandardViewFilter.subscribe(result => {
      if (result) {
        this.displayedColumns = [];
        this.selected_View_Columns = [];
        this.getStandardViewFilterData = result.result;
        let defaultView = this.getStandardViewFilterData.find(view => view.isSetAsDefault === true).standardViewParameters;
        this.selectedValueControl = this.getStandardViewFilterData.find(view => view.isSetAsDefault === true).id;
        defaultView.forEach(viewItem => {
          if (viewItem.standardColumnSetup && viewItem.standardColumnSetup.displayColumnName) {
            this.selected_View_Columns.push(viewItem.standardColumnSetup.displayColumnName);
          }
        });
        this.displayedColumns = this.selected_View_Columns;
      }
    });

    this.standardViewService.$getStandardViewColumn.subscribe(result => {
      if (result) {
        this.getStandardViewColumnFilterData = result.result;
        let fixedCol = this.getStandardViewColumnFilterData.filter(x => x.isSelected === true);
        fixedCol.forEach(x => {
          this.fixedSelectedColumnNames.push(x.displayColumnName);
        });

      }
    });

  }

  onFilterChange(id: number) {
    this.selectedFilter = -1;
    this.displayedColumns = [];
    this.selected_View_Columns = [];
    let selectedView = this.getStandardViewFilterData.find(view => view.id === id).standardViewParameters;
    selectedView.forEach(viewItem => {
      if (viewItem.standardColumnSetup && viewItem.standardColumnSetup.displayColumnName) {
        this.selected_View_Columns.push(viewItem.standardColumnSetup.displayColumnName);
        this.displayedColumns = this.selected_View_Columns;
      }
    });
  }


  openStandardViewFilterComponent(isSaveAs?: string) {
    let isInputVisible: boolean = false;
    if (isSaveAs === 'saveAs') {
      isInputVisible = true;
    } else {
      isInputVisible = false;
    }
    this.selectedColNameSizePin = [];
    for (let i = 0; i < this.displayedColumns.length; i++) {
      const item = this.displayedColumns[i];
      const isPinValue = this.isColumnSticky[i];
      let widthSize = 0;
      if (this.columnSizes.length > 0) {
        for (let i = 0; i < this.columnSizes.length; i++) {
          const columnName = this.columnSizes[i].columnName;
          const columnNameSize = this.columnSizes[i].widthSize;
          if (columnName === item) {
            widthSize = columnNameSize;
            break;
          }
        }
      }

      if (widthSize === 0) {
        widthSize = 180;
      }

      this.selectedColNameSizePin.push({
        displayColumnName: item,
        resizeColumnWidth: widthSize, //  "200px", // Set default width here
        isPin: isPinValue == true ? isPinValue : false
      });
    }
    const modalRef = this.modal.create({
      nzContent: AddNewGridStandardViewComponent,
      nzData:
      {
        pageId: this.PAGE_ID,
        column: this.getStandardViewColumnFilterData,
        displayCol: this.displayedColumns,
        viewList: this.getStandardViewFilterData,
        selectedColNameSizePin: this.selectedColNameSizePin,
        selectedStandardView: this.selectedValueControl,
        fixedCol: this.fixedSelectedColumnNames,
        isSaveAs: isInputVisible
      },



    });

    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }

  toggleColumnSticky(index: number) {
    this.isColumnSticky[index] = !this.isColumnSticky[index];
    if (this.isColumnSticky[index]) {
      for (let i = 0; i < index; i++) {
        this.isColumnSticky[i] = true;
      }
    }
  }


  saveColumnSizes(event: any) {
    event.forEach(col => {
      const columnName = col.column.field;
      const widthSize = col.newWidth;
      const existingIndex = this.columnSizes.findIndex(item => item.columnName === columnName);

      if (existingIndex !== -1) {
        this.columnSizes[existingIndex].widthSize = widthSize;
      } else {
        this.columnSizes.push({ columnName, widthSize });
      }
    });

  }


  customerGridTableData(companyId: number, formNameId: number, lookUpFilterId: number, page: number, pageSize: number) {
    this.filterSearchParameter = {
      CompanyParameterId: companyId,
      FormNameId: formNameId,
      LookUpFilterId: lookUpFilterId,
      page: page,
      pageSize: pageSize,
      jobInformationId: null

    };
    this._loadingIndicatorService.show();
    this.customerInformationService.getAllCustomerinformationByFilter(this.filterSearchParameter);
    this.Customersubscription = this.customerInformationService.$getcustomerinformation.subscribe(result => {
      if (result) {
        this.totalRecords = result?.result?.rowCount;
        this.displayedData = result?.result?.rows;
        this._loadingIndicatorService.hide();

      }
    }
    )
  };



  getStandardFilterByFormPageId(pageId: number, type: string) {// New Changes By Aamir Ali - 22-Apr2024
    if (this.CustomereFilterSubscription) {
      this.CustomereFilterSubscription.unsubscribe();
    }
    if (this.Customersubscription) {
      this.Customersubscription.unsubscribe();
    }
    this.LookupFilterService.getStandardFilterByFormPageId(pageId);
    this.CustomereFilterSubscription = this.LookupFilterService.$getStandardFilter.subscribe(result => {
      if (result) {
        this.lookupFilterResponse = result.result;
        let defaultFilterId = result.result.find(filter => filter.isSetAsDefault === true).id;
        this.customerInformationService.setSelectedFilterId(defaultFilterId);
        let id = -1;
        if (type === this.selectionFrom) {
          this.defaultFilterIdSelected = defaultFilterId;
          this.accessLocalStorageService.setSelectedFilterId(defaultFilterId);

          // New Changes By Aamir Ali - 22-Apr2024
          id = this.accessLocalStorageService.getSelectedFilterId();
        } else {
          id = this.accessLocalStorageService.getSelectedFilterId();
        }

        if (id !== -1) {
          this.customerGridTableData(1, this.PAGE_ID, id, this.currentPage, this.pageSize);
        }

      }
    });
  }

  reInitReset() {
    this.ngOnInit();
    this.selectedFilter = -1;
  }

  onPageChange(page: number): void {
    this.currentPage = page;
    let filterId: number = 0;
    this.customerInformationService.$selectedFilterId.subscribe(v => {
      if (v) {
        filterId = v;
      }
    });
    this.isLoading = true;
    setTimeout(() => {
      this.defaultFilterIdSelected = filterId;
      this.customerGridTableData(1, this.PAGE_ID, filterId, page, this.pageSize);
      this.isLoading = false;

    }, 100);
  }

  onPageSizeChange(size: number): void {
    this.pageSize = size;
    let filterId: number = 0;
    this.customerInformationService.$selectedFilterId.subscribe(v => {
      if (v) {
        filterId = v;
      }
    });
    this.isLoading = true;
    setTimeout(() => {
      this.defaultFilterIdSelected = filterId;
      this.customerGridTableData(1, this.PAGE_ID, filterId, this.currentPage, this.pageSize);
      this.isLoading = false;

    }, 100);
  }


  isAllDataShownCustomerContact(): boolean {
    return this.totalRecords <= this.pageSize;
  }

  filterGetData() {
    this.customerInformationService.getData().subscribe(
      (res: any) => {
        this.filtersData = res.result;
        const desiredIndex = 0;
        if (desiredIndex < this.filtersData.length) {
          this.selectedValuefilter = this.filtersData[desiredIndex].id;
          this.selectedFilterData = this.filtersData[desiredIndex];
        } else {
          console.error('Desired index is out of range');
        }
      },
      (error) => {
        console.error('Error fetching data:', error);
      }
    );
  }
  isLoading: boolean = false;


  saveEditStandardView() {
    this.standardViewService.$createStandardViewRequest.subscribe(formData => {
      if (formData && formData !== null) {
        this.standardViewService.postData(formData)
          .subscribe(
            () => {
              setTimeout(() => {
                // this.loadingIndicateService.hide();
                this.isLoading = true;
                this.toastService.success('Standard View Updated Successfully');
                this.standardViewService.getStandardViewFilterByFormPageId(this.PAGE_ID);
                this.selectedFilter = -1;
              }, 2000);
            },
            (error) => {
              console.error('Error:', error);
              //this.loadingIndicateService.hide();
              this.isLoading = false;
            }
          );
      }
    });
  }

  CustomerData: CustomerInformationResponse;
  isLoadingOne = false;


  exportToExcel(): void {
    const currentDate = new Date().toLocaleDateString();
    const headings = ['DisplayName', 'StreetAddress', 'Suburb', 'State', 'PostalCode', 'Phone', 'CountryCodeId', 'CellPhone', 'PrimaryEmail', 'Label', 'Status', 'IsJob', 'IsLeadOpportunitie'];
    const dataToExport = [];
    dataToExport.push(['Exported on ' + currentDate]);
    dataToExport.push([]);
    dataToExport.push(headings);

    this.displayedData.forEach(row => {
      const rowData = [];
      headings.forEach(column => {
        switch (column) {
          case 'Name':
            rowData.push(`${row.FirstName} ${row.LastName}`);
            break;
          case 'DisplayName':
            rowData.push(row.DisplayName || '');
            break;
          case 'Phone':
            rowData.push(row.PrimaryPhoneNo || '');
            break;
          case 'Status':
            rowData.push(row.StatusCode ? 'Active' : 'INVITE PENDING');
            break;
          case 'State':
            rowData.push(row.State || '');
            break;
          case 'StreetAddress':
            rowData.push(row.StreetAddress || '');
            break;
          case 'CellPhone':
            rowData.push(row.CellPhone || '');
            break;
          case 'PostalCode':
            rowData.push(row.PostalCode || '');
            break;
          case 'IsJob':
            rowData.push(row.TotalJobs || '');
            break;
          case 'IIsLeadOpportunitiesJob':
            rowData.push(row.TotalLeadOpportunities || '');
            break;
          default:
            rowData.push('');
            break;
        }
      });
      dataToExport.push(rowData);
    });

    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(dataToExport);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'Users.xlsx');
  }


  getTotalCheckboxes(): number {
    return this.displayedData.length;
  }

  getSelectedCheckboxes(): number {
    return this.setOfCheckedId.size;
  }

  areAllChecked(): boolean {
    return this.setOfCheckedId.size === this.displayedData.length;
  }


  selectAll(value: boolean): void {
    this.allSelected = value;
    if (value) {
      this.displayedData.forEach(data => this.onItemChecked(data, true));
    } else {
      this.setOfCheckedId.clear();
      this.updateCheckboxChecked();
    }
  }

  onItemChecked(id: any, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id.globalId);
    } else {
      this.setOfCheckedId.delete(id.globalId);
    }
    this.updateCheckboxChecked();

  }

  deleteSelectedItems(): void {
    this.setOfCheckedId.forEach((globalId) => {
      this.customerInformationService.deleteData1(globalId.toString()).subscribe(() => {
        // If the deletion is successful, remove the globalId from the set of checked IDs
        this.setOfCheckedId.delete(globalId);
        // Update checkboxChecked
        this.updateCheckboxChecked();
      });
    });
  }

  updateCheckboxChecked(): void {
    this.checkboxChecked = this.setOfCheckedId.size > 0;
  }


  ngOnDestroy() {
    this.LookupFilterService.getStandardFilter.next(null); // New Changes By Aamir Ali - 31-May-2024
    localStorage.removeItem('Selected_Filter_Id'); // New Changes By Aamir Ali - 31-May-2024
    if (this.CustomereFilterSubscription) {
      this.CustomereFilterSubscription.unsubscribe();
    }
    if (this.Customersubscription) {
      this.Customersubscription.unsubscribe();
    }
    if (this.CustomerstandardViewFilterSubscription) {
      this.CustomerstandardViewFilterSubscription.unsubscribe();
    }
    console.clear();
    this.selectedFilter = -1;
  }


  isExpanded(rowIndex: number): boolean {
    return this.expandedRows.includes(rowIndex);
  }

  toggleAdditionalEmails(rowIndex: number): void {
    const index = this.expandedRows.indexOf(rowIndex);
    if (index > -1) {
      this.expandedRows.splice(index, 1); // Remove rowIndex if already expanded
    } else {
      this.expandedRows.push(rowIndex); // Add rowIndex if not expanded
    }
  }



  setHovered(dataItem: any, isHovered: boolean): void {
    dataItem.isHovered = isHovered;
  }

  getStatusClass(statusCode: string): string {
    switch (statusCode) {
      case CustomerStatusCode.ACTIVE.code:
        return 'status-text active';
      case CustomerStatusCode.INACTIVE.code:
        return 'status-text inactive';
      case CustomerStatusCode.DISABLED.code:
        return 'status-text disabled';
      case CustomerStatusCode.PENDING.code:
        return 'status-text pending';
      default:
        return 'status-text inactive'; // Default class
    }
  }
}
