<nz-layout>
  <nz-sider class="border" nzCollapsible [nzCollapsedWidth]="10" [(nzCollapsed)]="isCollapsed" [nzWidth]="280"
    [nzTrigger]="null">
    <div class="row">
      <app-side-menu-bar [isAllJobOption]="true" (getSelectedJobId)="getSelectedJobId($event)"
        (toggleCollapse)="toggleCollapse()"></app-side-menu-bar>
    </div>
  </nz-sider>

  <nz-layout>
    <nz-page-header>
      <nz-page-header-title>
        <nz-breadcrumb class="text-start" nz-page-header-breadcrumb>
          <h1 class="bds-header-info--job-name">{{ AccessLocalStorageService.getJobName()}}</h1>
        </nz-breadcrumb>
        <h2 class="p-0 m-0 famly-inn ant-typography-Modal">RFIs</h2>
      </nz-page-header-title>
      <nz-page-header-extra style="align-self: flex-end;">
        <nz-space>
          <button nz-button nzType="default" class="ms-2">
            Export
          </button>
          <button nz-button nzType="default" class="ms-2"
            (click)="openFilter(Messaging_RFIs, PAGE_ID)">
            <i class="bi bi-funnel"></i>&nbsp;&nbsp;Filter
          </button>
          <button (click)="createRFI('RFIs')" nz-button nzType="primary" class="ms-2">
            RFI
          </button>
        </nz-space>
      </nz-page-header-extra>
    </nz-page-header>
    <nz-content class="custom-content">
      <div class="spinner-wrapper" *ngIf="isLoading">
        <img src="/assets/Loder/new-ajax-loader.gif">
      </div>
      <ng-container *ngIf="this.onGetByFilterRFIs === null || this.onGetByFilterRFIs?.length === 0; else _onGetByFilterRFIs">
        <app-rfi-empty></app-rfi-empty>
      </ng-container>
      <ng-template #_onGetByFilterRFIs>
        <div class="kendo-grid-container">
          <div class="kendo-grid-scrollable">
            <kendo-grid
            [data]="onGetByFilterRFIs"
            [sortable]="true"
            [resizable]="true"
            [reorderable]="true"
            [scrollable]="'scrollable'"
          >
          
            <kendo-grid-column
            *ngFor="let column of displayedColumns"
            [field]="column"
            [title]="column"
            [width]="getColumnWidth(column)"
            [locked]="isColumnLocked(column)"
            [headerStyle]="{
              'background': '#f1f1f1',
              'border-bottom': '1px solid #ddd'
            }"
            [headerClass]="{ 'locked-column': isColumnLocked(column) }">
              <ng-template kendoGridHeaderTemplate>
                <span>{{ column }}</span>
                <span 
                  nz-icon 
                  nzType="pushpin" 
                  nzTheme="outline" 
                  (click)="toggleColumnLock(column)" 
                  [ngClass]="{ 'locked-icon': isColumnLocked(column), 'unlocked-icon': !isColumnLocked(column) }"
                ></span>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem>
                <ng-container [ngSwitch]="column">
                  <ng-container *ngSwitchCase="'RFI Is New'">
                    <ng-container *ngIf="dataItem?.StatusSetupId === 734">
                      <span class="status-text success">New</span>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngSwitchCase="'Job'">
                    <span class="text-overflow-auto famly-inn">{{ dataItem?.JobName }}</span>
                  </ng-container>
                  <ng-container *ngSwitchCase="'RFI #'">
                    <span (click)="EditRFIs(dataItem?.Id)" class="text-overflow-auto Click-Btn famly-inn">{{ dataItem?.RFINumber }}</span>
                  </ng-container>
                  <ng-container *ngSwitchCase="'Title'">
                    <span class="text-overflow-auto famly-inn">{{ dataItem?.Title }}</span>
                  </ng-container>
                  <ng-container *ngSwitchCase="'Created By'">
                    <span class="text-overflow-auto famly-inn">{{ dataItem?.CreatedBy }}</span>
                  </ng-container>
                  <ng-container *ngSwitchCase="'Quetion Files'">
                    <ng-container *ngIf="dataItem?.TotalQuestionFiles?.length > 0">
                      <a nz-button nzType="link" class="p-0 m-0" nz-dropdown [nzDropdownMenu]="menu4" nzTrigger="click">
                        <svg
                          viewBox="64 64 896 896"
                          focusable="false"
                          data-icon="paper-clip"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            d="M779.3 196.6c-94.2-94.2-247.6-94.2-341.7 0l-261 260.8c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0012.7 0l261-260.8c32.4-32.4 75.5-50.2 121.3-50.2s88.9 17.8 121.2 50.2c32.4 32.4 50.2 75.5 50.2 121.2 0 45.8-17.8 88.8-50.2 121.2l-266 265.9-43.1 43.1c-40.3 40.3-105.8 40.3-146.1 0-19.5-19.5-30.2-45.4-30.2-73s10.7-53.5 30.2-73l263.9-263.8c6.7-6.6 15.5-10.3 24.9-10.3h.1c9.4 0 18.1 3.7 24.7 10.3 6.7 6.7 10.3 15.5 10.3 24.9 0 9.3-3.7 18.1-10.3 24.7L372.4 653c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0012.7 0l215.6-215.6c19.9-19.9 30.8-46.3 30.8-74.4s-11-54.6-30.8-74.4c-41.1-41.1-107.9-41-149 0L463 364 224.8 602.1A172.22 172.22 0 00174 724.8c0 46.3 18.1 89.8 50.8 122.5 33.9 33.8 78.3 50.7 122.7 50.7 44.4 0 88.8-16.9 122.6-50.7l309.2-309C824.8 492.7 850 432 850 367.5c.1-64.6-25.1-125.3-70.7-170.9z"
                          ></path>
                        </svg>
                        <span>{{ dataItem?.TotalQuestionFiles.split(',').length }}</span>
                        <span nz-icon nzType="down"></span>
                      </a>
                      <nz-dropdown-menu #menu4="nzDropdownMenu">
                        <ul nz-menu>
                          <li *ngFor="let fileName of dataItem?.TotalQuestionFiles.split(','); let i = index" nz-menu-item>
                            {{ fileName?.trim() }}
                          </li>
                        </ul>
                      </nz-dropdown-menu>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngSwitchCase="'Assigned To'">
                    <span class="text-overflow-auto">{{ dataItem?.CreatedBy }}</span>
                  </ng-container>
                  <ng-container *ngSwitchCase="'Status'">
                    <ng-container *ngIf="dataItem?.StatusSetupId === 735">
                      <span class="status-text success">{{ dataItem?.StatusName }}</span>
                    </ng-container>
                    <ng-container *ngIf="dataItem?.StatusSetupId === 734">
                      <span class="status-text pending">{{ dataItem?.StatusName }}</span>
                    </ng-container>
                    <ng-container *ngIf="dataItem?.StatusSetupId === 736">
                      <span class="status-text pending">{{ dataItem?.StatusName }}</span>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngSwitchCase="'Responses'">
                    <span class="text-overflow-auto">{{ dataItem?.TotalResponse }}</span>
                  </ng-container>
                  <ng-container *ngSwitchCase="'Responses File'">
                    <ng-container *ngIf="dataItem?.ResponseFileNames?.length > 0">
                      <a nz-button nzType="link" class="p-0 m-0" nz-dropdown [nzDropdownMenu]="menu4" nzTrigger="click">
                        <svg
                          viewBox="64 64 896 896"
                          focusable="false"
                          data-icon="paper-clip"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            d="M779.3 196.6c-94.2-94.2-247.6-94.2-341.7 0l-261 260.8c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0012.7 0l261-260.8c32.4-32.4 75.5-50.2 121.3-50.2s88.9 17.8 121.2 50.2c32.4 32.4 50.2 75.5 50.2 121.2 0 45.8-17.8 88.8-50.2 121.2l-266 265.9-43.1 43.1c-40.3 40.3-105.8 40.3-146.1 0-19.5-19.5-30.2-45.4-30.2-73s10.7-53.5 30.2-73l263.9-263.8c6.7-6.6 15.5-10.3 24.9-10.3h.1c9.4 0 18.1 3.7 24.7 10.3 6.7 6.7 10.3 15.5 10.3 24.9 0 9.3-3.7 18.1-10.3 24.7L372.4 653c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0012.7 0l215.6-215.6c19.9-19.9 30.8-46.3 30.8-74.4s-11-54.6-30.8-74.4c-41.1-41.1-107.9-41-149 0L463 364 224.8 602.1A172.22 172.22 0 00174 724.8c0 46.3 18.1 89.8 50.8 122.5 33.9 33.8 78.3 50.7 122.7 50.7 44.4 0 88.8-16.9 122.6-50.7l309.2-309C824.8 492.7 850 432 850 367.5c.1-64.6-25.1-125.3-70.7-170.9z"
                          ></path>
                        </svg>
                        <span>{{ dataItem?.ResponseFileNames.split(',').length }}</span>
                        <span nz-icon nzType="down"></span>
                      </a>
                      <nz-dropdown-menu #menu4="nzDropdownMenu">
                        <ul nz-menu>
                          <li *ngFor="let fileName of dataItem?.ResponseFileNames.split(','); let i = index" nz-menu-item>
                            {{ fileName?.trim() }}
                          </li>
                        </ul>
                      </nz-dropdown-menu>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngSwitchCase="'Due'">
                    <span class="text-overflow-auto">{{ dataItem?.DueOn }}</span>
                  </ng-container>
                  <ng-container *ngSwitchCase="'Related Items'">
                    <span class="text-overflow-auto">{{ dataItem?.RelatedItemId }}</span>
                  </ng-container>
                  <ng-container *ngSwitchCase="'Created Date'">
                    <span class="text-overflow-auto">{{ dataItem?.CreatedOn }}</span>
                  </ng-container>
                </ng-container>
              </ng-template>
            </kendo-grid-column>
            </kendo-grid>
          </div>
        </div>
      </ng-template>
      <nz-footer class="custom-footer">
        <div class="StickyFooterWrapper react">
          <div class="StickyFooter react" style="left: -13px; right: -8px;padding: 0px 40px 0px 40px;">
            <!-- <div class="Sticky__Component Scrollbar" #customScrollbar style="bottom: 97px; overflow-x: scroll;">
              <div style="height: 1px; width: 3065px;"></div>
              <object tabindex="-1" type="text/html" aria-hidden="true" data="about:blank" style="display: block; position: absolute; top: 0px; left: 0px; width: 100%; height: 100%; border: none; padding: 0px; margin: 0px; opacity: 0; z-index: -1000; pointer-events: none;"></object>
            </div> -->
             <div class="ListActionBar bottom StickyLayoutHeader react" style="left: -13px; right: -8px;">
              <div nz-row style="margin-top: 10px;">
                <div nz-col nzSpan="14">
                  <ng-container *ngIf="PAGE_ID">
                    <div nz-row>
                      <div nz-col nzSpan="7">
                        <nz-select class="mt-1" (ngModelChange)="onFilterChange($event)" [(ngModel)]="selectedValueControl" nzShowSearch>
                          <nz-option *ngFor="let standard of getStandardViewFilterData" [nzLabel]="standard.name" [nzValue]="standard.id"></nz-option>
                        </nz-select>
                      </div>
                      <div nz-col nzSpan="1">
                        <a nz-popover [nzPopoverContent]="contentTemplate" nzPopoverTrigger="hover" class="react" nz-button nzType="link" (click)="openStandardViewFilterComponent()">
                          <i style="font-size: 1.43em;" class="bx bx-dots-horizontal-rounded mt-2"></i>
                        </a>
                        <ng-template #contentTemplate>
                          <p>Manage Saved Views</p>
                        </ng-template>
                      </div>
                    </div>
                  </ng-container>
                </div>
                <div nz-col nzSpan="10">
                  <div class="d-flex justify-content-end align-items-end">
                    <nz-pagination style="float: right;" [nzPageIndex]="currentPage" [nzTotal]="totalRecords"
                      [nzPageSize]="pageSize" nzShowSizeChanger (nzPageIndexChange)="onPageChange($event)"
                      (nzPageSizeChange)="onPageSizeChange($event)" [nzPageSizeOptions]="[10, 20, 50, 75, 100]">
                    </nz-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nz-footer>
    </nz-content>
  </nz-layout>
</nz-layout>