<nz-layout>
  <app-loading-indicator></app-loading-indicator>

  <nz-sider class="border" nzCollapsible [nzCollapsedWidth]="10"
    [(nzCollapsed)]="isCollapsed" [nzWidth]="280"
    [nzTrigger]="null">
    <div class="row">
      <app-side-menu-bar [isAllJobOption]="true"
        (getSelectedJobId)="getSelectedJobId($event)"
        (toggleCollapse)="toggleCollapse()"></app-side-menu-bar>
    </div>
  </nz-sider>

  <nz-layout>
    <nz-page-header class="p-4">
      <nz-page-header-title>
        <nz-breadcrumb>
          <nz-breadcrumb-item>{{localStorage.getJobName()}}</nz-breadcrumb-item>
        </nz-breadcrumb>
        <h1 nz-typography>
          Daily Logs
        </h1>
      </nz-page-header-title>

      <nz-page-header-extra>
        <nz-space>

          <button
          nz-button
          nzType="default"
          style="padding: 5px 10px 30px 10px;"
          class="ms-2 rounded-2 me-2 "
          nz-dropdown
          [nzTrigger]="'click'"
          [nzDropdownMenu]="menu">
          <span style="font-size: 16px; " ><i
              class="bi bi-three-dots"></i></span>
        </button>
            <nz-dropdown-menu #menu="nzDropdownMenu">
              <ul nz-menu class="border" style="width:11rem">
                <li nz-menu-item class="p-2  border-bottom ">
                  <span class=" ms-3">
                    Print
                  </span>
                </li>
                <li nz-menu-item class="p-2 border-bottom " (click)="DailyLogSetting()">
                  <span class=" ms-3">
                    Daily Log Settings
                  </span>
                </li>
                <li nz-menu-item class="p-2">
                  <span class=" ms-3">
                    Learn More
                  </span>
                </li>
              </ul>
            </nz-dropdown-menu>
          <button *nzSpaceItem
            nz-button
            nzType="default"
            (click)="openFilter(PROJECT_MANG_SITE_DIAR_DAILY_L, PAGE_ID)">
            <i class="bi bi-funnel"></i>&nbsp;&nbsp;
            Filter
          </button>

          <button *nzSpaceItem
            nz-button
            nzType="primary"
            (click)="newDailyLogModelOpen('SiteDiaries')">
            New Daily Log
          </button>
        </nz-space>
      </nz-page-header-extra>
    </nz-page-header>


    <nz-content class="custom-content ">

      <ng-container *ngIf="dailyLogResponse?.length > 0">
        <div class="container mb-5">

          <nz-card nzBordered class="border-1 mb-2" *ngFor="let dailyLog of dailyLogResponse">

            <div nz-row class=" p-0">
              <div nz-col [nzSpan]="18">
               <div class="mb-2">
                <h3 nz-typography (click)="EditSiteDiariesDailyLog(dailyLog.Id,dailyLog.JobInformationId,dailyLog.JobName)">
                  <a class="text-decoration-underline" >
                    {{dailyLog.Date | date : 'MMM d, YYYY'}}
                  </a>
                </h3>
               </div>
               <div class="mb-2" *ngIf="dailyLog.Title">
                <h3 nz-typography>
                  {{dailyLog.Title}}
                </h3>
              </div>
              <div class="mb-2" *ngIf="dailyLog.Notes">
                <p nz-typography>
                  {{dailyLog.Notes}}
                </p>
                </div>
               <div class="mb-2" *ngIf="dailyLog.WeatherNotes">
                <h6 nz-typography>
                  Weather Notes
                </h6>
                </div>
               <div class="mb-2" *ngIf="dailyLog.WeatherNotes">
                <p nz-typography>
                  {{dailyLog.WeatherNotes}}
                </p>
                </div>

                <div nz-row  >
                  <div nz-col *ngIf="dailyLog.WeatherIconPath">
                    <img
                      class="WeatherImage"
                      [src]="dailyLog?.WeatherIconPath ? dailyLog.WeatherIconPath : 'assets/ProjectManagerSitesDiaries/rainyx50.png'"
                      [alt]="dailyLog?.WeatherDescription || 'Weather Icon'"
                      style="height: 50px; margin-right: 16px; width: auto;" />
                  </div>

                  <div nz-col *ngIf="dailyLog.StartDegreeCentigrade || dailyLog.EndDegreeCentigrade">
                    <div nz-row style="row-gap: 0px;">
                      <div nz-col>
                        <strong style="font-size: 1em; font-weight: 700;">
                          {{dailyLog.StartDegreeCentigrade}} °C
                        </strong>
                      </div>
                    </div>
                    <div nz-row style="row-gap: 0px;">
                      <div nz-col>
                        <strong class="text-muted" style="font-size: 1em; font-weight: 700;">
                          {{dailyLog.EndDegreeCentigrade}} °C
                        </strong>
                      </div>
                    </div>
                  </div>
                </div>


              </div>

              <!-- Column 6 Block -->
              <div nz-col [nzSpan]="6" class="text-end">
                <!-- Use full width to display as a block -->
                <small nz-typography>{{dailyLog.CreatedBy}}</small><br />
                <!-- Added line break for block display -->
                <small nz-typography>
                  Viewable by
                  <i class="fs-6 ms-1 bi bi-house-fill"></i>
                  <i class="fs-6 ms-1 bi bi-hammer"></i>
                  <i class="fs-6 ms-1 bi bi-person-fill"></i>
                </small>
              </div>
            </div>

            <div nz-row class="mb-3 mt-3 p-0" *ngIf="dailyLog.AttachmentId && TestAttachments[dailyLog.AttachmentId]">
              <div nz-col [nzSpan]="24">
                <div class="row">
                  <!-- Pass the attachment corresponding to the dailyLog's AttachmentId -->
                  <app-create-attachments-common
                    [TestAttachment__]="TestAttachments[dailyLog.AttachmentId]"
                    (filesUploaded)="onFilesUploaded($event)"
                  ></app-create-attachments-common>
                </div>
              </div>
            </div>



            <div nz-row class="p-0 align-items-center">
              <div nz-col [nzSpan]="10">
                <button (click)="conversationModelOpen(dailyLog)" nz-button nzType="link" class="d-flex align-items-center justify-content-center rounded-1">
                  <a nz-icon nzType="message" class="fs-5" nzTheme="fill"></a>
                  {{ dailyLog.TotalComments && dailyLog.TotalComments > 0 ? dailyLog.TotalComments + ' Comments' : 'Add Comments' }}
                </button>
              </div>
              <div nz-col [nzSpan]="14" class="text-end" *ngIf="dailyLog.TagSetupName">
                <ng-container *ngFor="let tag of dailyLog.TagSetupName.split(','); let last = last">
                  <nz-tag nzBordered="true" nzColor="#f1f4fa" class="text-dark border" nzSize="small">
                    {{ tag.trim() }}
                  </nz-tag>
                </ng-container>
              </div>


            </div>

          </nz-card>

          <div class="row mb-5"></div>
          <div class="row mb-5"></div>
        </div>
      </ng-container>

      <div class="full-screen mt-4" *ngIf="dailyLogResponse?.length === 0">
        <div class="content-center text-center ">
          <i class="size" nz-icon nzType="file-done" nzTheme="outline"></i>
          <div class="mt-2">
            <h1 nz-typography>Track project progress with daily </h1>
          </div>
          <div><h1 nz-typography>
              logs
            </h1> </div>
          <div class="mt-2">
            <p>Document and share project updates with your team, subs, and
              homeowners.</p>
          </div>
          <div class="mt-2">
            <button nz-button nzType="default" class="ms-2">
              <i class="fas fa-graduation-cap"></i>&nbsp;&nbsp;Learn
              More</button>&nbsp;
            <button (click)="newDailyLogModelOpen('SiteDiaries')" nz-button
              nzType="primary" class="ms-2">New
              Add A Daily Log</button>

          </div>
        </div>
      </div>

    </nz-content>

    <nz-footer class="custom-footer"
          *ngIf="dailyLogResponse?.length > 0">
            <div class="row mb-0 mt-2 p-2">
              <div class="col-10 me-2">
                <!-- sufyan Work -->
                <nz-pagination
                  class="hide-buttons"
                  style="float: right;
                  margin-right: 63px;"
                  [nzPageIndex]="currentPage"
                  [nzTotal]="totalRecords"
                  [nzPageSize]="pageSize"
                  [nzShowSizeChanger]="true"
                  [nzPageSizeOptions]="pageSizeOptions"
                  (nzPageIndexChange)="onPageChangeDailyLog($event)"
                  (nzPageSizeChange)="onPageSizeChangeDailyLog($event)"
                  [nzShowTotal]="totalTemplate"
                  [nzSimple]="false"></nz-pagination>
                <ng-template #totalTemplate let-total="total"
                  let-range="range">
                  {{ range[0] }}-{{ range[1] }} of {{ total }} items
                </ng-template>
                <nz-pagination
                  *ngIf="!isAllDataShownSchedule()"
                  style="float: right"
                  [nzPageIndex]="currentPage"
                  [nzTotal]="totalRecords"
                  [nzPageSize]="pageSize"
                  [nzShowSizeChanger]="true"
                  [nzPageSizeOptions]="pageSizeOptions"
                  (nzPageIndexChange)="onPageChangeDailyLog($event)"
                  (nzPageSizeChange)="onPageSizeChangeDailyLog($event)"
                  nzSimple></nz-pagination>

              </div>
            </div>
        </nz-footer>
  </nz-layout>

</nz-layout>

