<nz-modal
    [(nzVisible)]="isVisibleInRelatedRFIs"
    [nzTitle]="InRelatedRFIs"
    [nzFooter]="null"
    [nzBodyStyle]="{
    background: '#f1f4fa',
    padding: '14px',
    maxHeight: '69vh',
    'overflow-y': 'auto',
    'overflow-x': 'hidden',
  }"
    [nzStyle]="{ top: '25px'}"
    [nzWidth]="'65%'"
    (nzOnCancel)="CancelRelatedRFIs()"
>
    <ng-template #InRelatedRFIs>
        <div class="row">
            <h2 class="p-0 m-0 famly-inn ant-typography-Modal">Related RFIs</h2>
        </div>
    </ng-template>
    <div
        class="content"
        *nzModalContent
    >
        <div class="card border-0">
            <div class="card-head border-bottom">
                <div class="row">
                    <div class="col-6">
                        <h2 class="famly-inn ant-typography-crad-Header">Related RFIs</h2>
                    </div>
                    <div class="col-6 d-flex justify-content-end align-content-center">
                        <button
                            (click)="createRFI(null)"
                            nz-button
                            nzType="default"
                        >
                            Create RFI
                        </button>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <ng-container *ngIf="RFIsGetById_Response?.length > 0">
                    <div
                        class="card mt-3 border-0"
                        *ngFor="let data of RFIsGetById_Response"
                    >
                        <div
                            class="BTLink underline"
                            (click)="createRFI(data?.RFIId)"
                        >
                            <div class="ant-card ant-card-bordered RfiContainer-Card">
                                <div class="ant-card-body">
                                    <div class="FormattedStatus">
                                        <span style="font-size: 12px; color: rgb(78, 85, 95)">Created by {{ data?.UserName }}</span>
                                    </div>
                                    <div class="Status">
                                        <span style="font-size: 12px; color: rgb(78, 85, 95)">
                                            <img
                                                src="assets/ChangeOrderInformation/video.512x436.png"
                                                class="Icon BTPopover PopoverSpacing"
                                                alt
                                            >
                                            Due
                                            <span style="white-space: nowrap">
                                                {{ data?.DueOn | date: 'MMMM d, yyyy' }}
                                            </span>
                                        </span>
                                    </div>
                                    <strong>
                                        test - {{ data?.AutoAssignNumber }}- {{ data?.Title }}
                                    </strong>
                                    <p>
                                        <span class="TextWrap">
                                            <div [innerHTML]="data?.Question"></div>
                                        </span>
                                    </p>
                                    <div class="CardSection CardSection-size-default CardSection-border CardSection-footer">
                                        <span style="font-size: 12px; color: rgb(78, 85, 95)">{{ data?.TotalResponse }}Responses</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</nz-modal>
