import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MessagingRoutingModule } from './messaging-routing.module';
import { MessagingDashboardComponent } from './Components/messaging/messaging-dashboard/messaging-dashboard.component';
import { SurveysDashboardComponent } from './Components/surveys/surveys-dashboard/surveys-dashboard.component';
import { SharedModule } from 'src/shared/shared.module';
import { SurveysEmptyContentComponent } from './Components/surveys/surveys-empty-content/surveys-empty-content.component';
import { SelectServeyComponent } from './Components/surveys/select-servey/select-servey.component';
import { NewSurveyComponent } from './Components/surveys/new-survey/new-survey.component';
import { CommentsDashboardComponent } from './Components/Comments/comments-dashboard/comments-dashboard.component';
import { RfisDashboardComponent } from './Components/RFIs/rfis-dashboard/rfis-dashboard.component';
import { RfiEmptyComponent } from './Components/RFIs/rfi-empty/rfi-empty.component';
import { CommentEmptyDataComponent } from './Components/Comments/comment-empty-data/comment-empty-data.component';
import { CommmentConversationComponent } from './Components/Comments/commment-conversation/commment-conversation.component';
import { NewRFIsComponent } from './Components/RFIs/new-rfis/new-rfis.component';
import { NotificationDashboardComponent } from './Components/Notification-History/notification-dashboard/notification-dashboard.component';
import { NotificationHistoryContentComponent } from './Components/Notification-History/notification-dashboard/notification-history-content/notification-history-content.component';
// import { MessagingComposeNewMessageComponent } from './Components/messaging/messaging-compose-new-message/messaging-compose-new-message.component';
import { MessagingManageEmailsComponent } from './Components/messaging/messaging-manage-emails/messaging-manage-emails.component';
import { InboxDetailsComponent } from './Components/messaging/inbox-details/inbox-details.component';
import {  } from '../shared/component/create-attachments-common/create-attachments-common.component';
import { RelateItemComponent } from '../messaging/Components/RFIs/relate-item/relate-item.component';
import { RFINewAnswerComponent } from './Components/RFIs/rfi-new-answer/rfi-new-answer.component';


@NgModule({
  declarations: [
    MessagingDashboardComponent,
    SurveysDashboardComponent,
    SurveysEmptyContentComponent,
    SelectServeyComponent,
    NewSurveyComponent,
    CommentsDashboardComponent,
    CommentEmptyDataComponent,
    CommmentConversationComponent,
    NotificationDashboardComponent,
    NotificationHistoryContentComponent,
    RfisDashboardComponent,
    RfiEmptyComponent,
    NewRFIsComponent,
    SurveysEmptyContentComponent,
    // MessagingComposeNewMessageComponent, [convert to share module]
    MessagingManageEmailsComponent,
    InboxDetailsComponent,
    RelateItemComponent,
    RFINewAnswerComponent,

  ],
  imports: [
    CommonModule,
    MessagingRoutingModule,
    SharedModule
  ]
})
export class MessagingModule { }
