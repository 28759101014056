import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { CompanySettingsTimeClockPreDefineCodes } from 'src/app/shared/component/Models/LookUpStandardFilterCodes';
import { ITimeClockSetup } from 'src/Models/_Time_Clock/_Time_Clock';
import { LookupNameSetupResponse } from 'src/Models/LeadTags';
import { TimeClockSService } from 'src/Service/_Time_Clock/time-clock-s.service';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { LoadingIndicatorService } from 'src/shared/Shared-Services/loading-indicator.service';

@Component({
  selector: 'app-time-clock',
  templateUrl: './time-clock.component.html',
  styleUrls: ['./time-clock.component.css'],
})
export class TimeClockComponent implements OnInit {
  @Output() cancel = new EventEmitter<void>();
  isVisibleInTimeClock: boolean = true;
  WeekStart: LookupNameSetupResponse[] = [];
  hourlyRateTracking: LookupNameSetupResponse[] = [];
  selectRadius: LookupNameSetupResponse[] = [];
  onLookUpNameSetup: LookupNameSetupResponse[] = [];
  selectRadiusKMT: LookupNameSetupResponse[] = [];
  PAGE_ID: number = 11;
  timeClockSetupFormFields: FormGroup;
  overtimeDaysGroupNodes: NzTreeNodeOptions[] = [];
  isSave: boolean = false;
  DisabledNextActivity: boolean = false;
  showOvertimeContent: boolean = false;
  showOverdoubletimeContent: boolean = false;
  ShowEmployeesvertime: boolean = false;
  ShowEmployeesWeeklyOvertime: boolean = false;
  ShowEmployeesAccrueDailyDoubleOvertime: boolean = false;
  ShowEmployeesAccrueWeeklyDoubleOvertime: boolean = false;
  _doubleOvertimeDays: string;
  _overtimeDays: string;
  ITimeClockSetupRes: ITimeClockSetup;
  Test_Case_One: boolean = false;
  Test_Case_Two: boolean = false;
  Test_Case_Three: boolean = false;
  Test_Case_Four: boolean = false;
  on_MustGreaterThanValue_Daily: boolean = false;
  on_MustGreaterThanValue_Weekly: boolean = false;
  on_Error_DoubleOvertimeDays: boolean = false;
  preDefinedDataCodes = {
    weakStart: CompanySettingsTimeClockPreDefineCodes._WeakStart,
    hourlyRateTracking: CompanySettingsTimeClockPreDefineCodes._HourlyRateTracking,
    selectRadius: CompanySettingsTimeClockPreDefineCodes._SelectRadius,
    selectRadiusKMT: CompanySettingsTimeClockPreDefineCodes._SelectRadiusKMT,
  };

  constructor(
    private LookupFilterServicee: LookupFilterService,
    private fb: FormBuilder,
    private toastssService: NzMessageService,
    private _TimeClockSService: TimeClockSService,
    private _loadingIndicatorService: LoadingIndicatorService,
  ){}
  
  ngOnInit(): void {
    this._loadingIndicatorService.show();
    this.TimeClockSetupFormGroup();
    this.initLoad();
    this.fetchTimeClockSetupForm();
  }
  TimeClockSetupFormGroup(): void {
    this.timeClockSetupFormFields = this.fb.group({
      companyParameterId: [1],
      weekStartId: [0],
      hourlyRateTrackingId: [0],
      isUseFormattingOTimeClockTotals: [false],
      isWhenAJobIsDeletedPromptToDeleteCorrespondingTimeCardItems: [false],
      isSendOneTimeNotificationToInternalUsersThatHaveBeenClockedInForOverHours: [false],
      isAutomaticallyEndBreaksAfterMinutesNo: [false],
      automaticallyEndBreaksAfterMinutesNo: [null],
      isShowGeneralJob: [false],
      isShowOvertime: [false],
      isEmployeesAccrueDailyOvertime: [false],
      afterNoOfHoursOverTimeDaily: [null],
      isEmployeesAccrueWeeklyOvertime: [false],
      afterNoOfHoursOverTimeWeekly: [null],
      overtimeDays: '',
      isShowDoubleOvertime: [false],
      isEmployeesAccrueDailyDoubleOvertime: [false],
      afterNoOfHoursDoubleOverTimeDaily: [null],
      isEmployeesAccrueWeeklyDoubleOvertime: [false],
      afterNoOfHoursDoubleOverTimeWeekly: [null],
      doubleOvertimeDays: '',
      radiusId: [null],
      radiusUnitId: [null],
      isDefaultTimeClockGeofencingToOnForAllNewJobs: [false],
    });
  }
  initLoad() {
    this.LookupFilterServicee.getLookUpValuesByFormNameId(this.PAGE_ID).subscribe((res) => {

      let weakStart = res.result.filter(
        (x) => x.code === this.preDefinedDataCodes.weakStart.code
      );
      this.WeekStart = weakStart;

      let hourlyRateTracking = res.result.filter(
        (x) => x.code === this.preDefinedDataCodes.hourlyRateTracking.code
      );
      this.hourlyRateTracking = hourlyRateTracking;

      let selectRadius = res.result.filter(
        (x) => x.code === this.preDefinedDataCodes.selectRadius.code
      );
      this.onLookUpNameSetup = selectRadius

      let selectRadiusKMT = res.result.filter(
        (x) => x.code === this.preDefinedDataCodes.selectRadiusKMT.code
      );
      this.selectRadiusKMT = selectRadiusKMT;
      this.overtimeDaysGroupNodes = [
        {
          title: 'Check All',
          value: 'check_all',
          key: 'check_all',
          selectable: false,
          isLeaf: false,
          expanded: true,
          children: this.WeekStart.map((JobGroup) => ({
            title: JobGroup.name,
            key: JobGroup.id.toString(),
            value: JobGroup.id.toString(),
            isLeaf: true,
          }))
        }
      ];
    });
  }
  onShowOvertimeChange(event: boolean){
    this.showOvertimeContent = event
    if(event === false){
      this.timeClockSetupFormFields.get('isEmployeesAccrueDailyOvertime')?.setValue(false);
      this.timeClockSetupFormFields.get('afterNoOfHoursOverTimeDaily')?.setValue(null);
      this.timeClockSetupFormFields.get('isEmployeesAccrueWeeklyOvertime')?.setValue(false);
      this.timeClockSetupFormFields.get('afterNoOfHoursOverTimeWeekly')?.setValue(null);
      this.timeClockSetupFormFields.get('overtimeDays')?.setValue(null);
    }
  }
  onShowEmployeesvertimeChange(event: boolean){
    this.ShowEmployeesvertime = event
    if(event === false){
      this.timeClockSetupFormFields.get('afterNoOfHoursOverTimeDaily')?.setValue(null);
    }
  }
  onShowEmployeesWeeklyOvertimeChange(event: boolean){
    this.ShowEmployeesWeeklyOvertime = event
    if(event === false){
      this.timeClockSetupFormFields.get('afterNoOfHoursOverTimeWeekly')?.setValue(null);
    }
  }
  onShowOverdoubletimeChange(event: boolean){
    this.showOverdoubletimeContent = event
    if(event === false){
      this.timeClockSetupFormFields.get('isEmployeesAccrueDailyDoubleOvertime')?.setValue(false);
      this.timeClockSetupFormFields.get('afterNoOfHoursDoubleOverTimeDaily')?.setValue(null);
      this.timeClockSetupFormFields.get('isEmployeesAccrueWeeklyDoubleOvertime')?.setValue(false);
      this.timeClockSetupFormFields.get('afterNoOfHoursDoubleOverTimeWeekly')?.setValue(null);
      this.timeClockSetupFormFields.get('doubleOvertimeDays')?.setValue(null);
    }
  }
  onShowEmployeesAccrueDailyDoubleOverTimeChange(event: boolean){
    this.ShowEmployeesAccrueDailyDoubleOvertime = event
    if(event === false){
      this.timeClockSetupFormFields.get('afterNoOfHoursDoubleOverTimeDaily')?.setValue(null);
    }
  }
  onShowEmployeesAccrueWeeklyDoubleOverTimeChange(event: boolean){
    this.ShowEmployeesAccrueWeeklyDoubleOvertime = event
    if(event === false){
      this.timeClockSetupFormFields.get('afterNoOfHoursDoubleOverTimeWeekly')?.setValue(null);
    }
  }
  fetchTimeClockSetupForm(): void {
    this._TimeClockSService.getCompanyTimeClockSetup().subscribe(res => {
      this.ITimeClockSetupRes = res.result;
      if(res.result === null){
        this._loadingIndicatorService.hide();
      }
      this.onShowEmployeesvertimeChange(this.ITimeClockSetupRes?.isEmployeesAccrueDailyOvertime);
      this.onShowEmployeesWeeklyOvertimeChange(this.ITimeClockSetupRes?.isEmployeesAccrueWeeklyOvertime);
      this.onShowEmployeesAccrueDailyDoubleOverTimeChange(this.ITimeClockSetupRes?.isEmployeesAccrueDailyDoubleOvertime);
      this.onShowEmployeesAccrueWeeklyDoubleOverTimeChange(this.ITimeClockSetupRes?.isEmployeesAccrueWeeklyDoubleOvertime);
      if (this.ITimeClockSetupRes) {
        this.timeClockSetupFormFields.patchValue({
          companyParameterId: this.ITimeClockSetupRes?.companyParameterId,
          weekStartId: this.ITimeClockSetupRes?.weekStartId,
          hourlyRateTrackingId: this.ITimeClockSetupRes?.hourlyRateTrackingId,
          isUseFormattingOTimeClockTotals: this.ITimeClockSetupRes?.isUseFormattingOTimeClockTotals,
          isWhenAJobIsDeletedPromptToDeleteCorrespondingTimeCardItems: this.ITimeClockSetupRes?.isWhenAJobIsDeletedPromptToDeleteCorrespondingTimeCardItems,
          isSendOneTimeNotificationToInternalUsersThatHaveBeenClockedInForOverHours: this.ITimeClockSetupRes?.isSendOneTimeNotificationToInternalUsersThatHaveBeenClockedInForOverHours,
          automaticallyEndBreaksAfterMinutesNo: this.ITimeClockSetupRes?.automaticallyEndBreaksAfterMinutesNo,
          isAutomaticallyEndBreaksAfterMinutesNo: this.ITimeClockSetupRes?.isAutomaticallyEndBreaksAfterMinutesNo,
          isShowGeneralJob: this.ITimeClockSetupRes?.isShowGeneralJob,
          isShowOvertime: this.ITimeClockSetupRes?.isShowOvertime,
          isEmployeesAccrueDailyOvertime: this.ITimeClockSetupRes?.isEmployeesAccrueDailyOvertime,
          afterNoOfHoursOverTimeDaily: this.ITimeClockSetupRes?.afterNoOfHoursOverTimeDaily || null,
          isEmployeesAccrueWeeklyOvertime: this.ITimeClockSetupRes?.isEmployeesAccrueWeeklyOvertime,
          afterNoOfHoursOverTimeWeekly: this.ITimeClockSetupRes?.afterNoOfHoursOverTimeWeekly || null,
          overtimeDays: this.ITimeClockSetupRes?.overtimeDays ? this.ITimeClockSetupRes.overtimeDays.split(', ') : [],
          isShowDoubleOvertime: this.ITimeClockSetupRes?.isShowDoubleOvertime,
          isEmployeesAccrueDailyDoubleOvertime: this.ITimeClockSetupRes?.isEmployeesAccrueDailyDoubleOvertime,
          afterNoOfHoursDoubleOverTimeDaily: this.ITimeClockSetupRes?.afterNoOfHoursDoubleOverTimeDaily || null,
          isEmployeesAccrueWeeklyDoubleOvertime: this.ITimeClockSetupRes?.isEmployeesAccrueWeeklyDoubleOvertime,
          afterNoOfHoursDoubleOverTimeWeekly: this.ITimeClockSetupRes?.afterNoOfHoursDoubleOverTimeWeekly || null,
          radiusId: this.ITimeClockSetupRes?.radiusId,
          radiusUnitId: this.ITimeClockSetupRes?.radiusUnitId,
          isDefaultTimeClockGeofencingToOnForAllNewJobs: this.ITimeClockSetupRes?.isDefaultTimeClockGeofencingToOnForAllNewJobs
        });
        const selectedValuesOvertime = this.ITimeClockSetupRes?.overtimeDays ? this.ITimeClockSetupRes.overtimeDays.split(', ') : []
        const selectedValues_doubleOver = this.ITimeClockSetupRes?.doubleOvertimeDays ? this.ITimeClockSetupRes.doubleOvertimeDays.split(', ') : []
        this.On_OvertimeDays(selectedValuesOvertime);
        this.On_DoubleOvertimeDays(selectedValues_doubleOver);
      }
    });
  }
  On_OvertimeDays(selectedValues: string[]){
    const selectedIds = selectedValues.map(selectedName => {
      const foundItem = this.WeekStart?.find(item => item?.name === selectedName);
        return foundItem ? foundItem.id.toString() : null;
      }).filter(id => id !== null);
      this.timeClockSetupFormFields.patchValue({
        overtimeDays: selectedIds,
      });
      console.log('this.timeClockSetupFormFields',this.timeClockSetupFormFields.value);
  }
  On_DoubleOvertimeDays(selectedValues: string[]){
    const selectedIds = selectedValues.map(selectedName => {
      const foundItem = this.WeekStart?.find(item => item?.name === selectedName);
      return foundItem ? foundItem.id.toString() : null;
    }).filter(id => id !== null);
    this.timeClockSetupFormFields.patchValue({
        doubleOvertimeDays: selectedIds,
    });
    this._loadingIndicatorService.hide();
  }
  saveTimeClockSetup(actionType: 'save' = 'save'): Promise<void> {
    let data = this.timeClockSetupFormFields.value;
  
    return new Promise((resolve, reject) => {
        if (this.isSave) {
            return reject('Already processing');
        }
  
        if (actionType === 'save') {
            this.isSave = true;
        }
        if(this.ShowEmployeesvertime === true && this.ShowEmployeesWeeklyOvertime === true && this.ShowEmployeesAccrueDailyDoubleOvertime === true && this.ShowEmployeesAccrueWeeklyDoubleOvertime === true){
          if(data.afterNoOfHoursOverTimeDaily === null){
            this.Test_Case_One = true;
            this.resetFlags();
            this.DisabledNextActivity = false;
            return reject('Validation errors present');
          } 
          if(data.afterNoOfHoursOverTimeWeekly === null){
            this.Test_Case_Two = true;
            this.resetFlags();
            this.DisabledNextActivity = false;
            return reject('Validation errors present');
          }
           if(data.afterNoOfHoursDoubleOverTimeDaily === null){
            this.Test_Case_Three = true;
            this.resetFlags();
            this.DisabledNextActivity = false;
            return reject('Validation errors present');
          }
           if(data.afterNoOfHoursDoubleOverTimeWeekly === null){
            this.Test_Case_Four = true;
            this.resetFlags();
            this.DisabledNextActivity = false;
            return reject('Validation errors present');
          }
        }
        if(this.ShowEmployeesvertime === true && this.ShowEmployeesWeeklyOvertime === true){
          if(data.afterNoOfHoursOverTimeDaily === null && data.afterNoOfHoursOverTimeWeekly === null){
            this.Test_Case_One = true;
            this.Test_Case_Two = true;
            this.resetFlags();
            this.DisabledNextActivity = false;
            return reject('Validation errors present');
          }
        }

        if(this.ShowEmployeesvertime === true){
          if(data.afterNoOfHoursOverTimeDaily === null){
            this.Test_Case_One = true;
            this.resetFlags();
            this.DisabledNextActivity = false;
            return reject('Validation errors present');
          }
        }
        if(this.ShowEmployeesWeeklyOvertime === true){
          if(data.afterNoOfHoursOverTimeWeekly === null){
            this.Test_Case_Two = true;
            this.resetFlags();
            this.DisabledNextActivity = false;
            return reject('Validation errors present');
          }
        }
        if(this.ShowEmployeesAccrueDailyDoubleOvertime === true){
          if(data.afterNoOfHoursDoubleOverTimeDaily === null){
            this.Test_Case_Three = true;
            this.resetFlags();
            this.DisabledNextActivity = false;
            return reject('Validation errors present');
          }
        }
        if(this.ShowEmployeesAccrueWeeklyDoubleOvertime === true){
          if(data.afterNoOfHoursDoubleOverTimeWeekly === null){
            this.Test_Case_Four = true;
            this.resetFlags();
            this.DisabledNextActivity = false;
            return reject('Validation errors present');
          }
        }
        if(this.on_MustGreaterThanValue_Daily === true){
          this.resetFlags();
          this.DisabledNextActivity = false;
          return reject('Validation errors present');
        } else if(this.on_MustGreaterThanValue_Weekly === true){
          this.resetFlags();
          this.DisabledNextActivity = false;
          return reject('Validation errors present');
        } else if(this.on_Error_DoubleOvertimeDays === true){
          this.resetFlags();
          this.DisabledNextActivity = false;
          return reject('Validation errors present');
        }
        this.DisabledNextActivity = true;

        data.overtimeDays = this._overtimeDays
        data.doubleOvertimeDays = this._doubleOvertimeDays
        this._TimeClockSService.PostTimeClockSetup(data).subscribe(
            (response) => {
                this.cancel.emit();
                this.DisabledNextActivity = false;
                this.isSave = false;
                resolve(); 
            },
            (error) => {
                console.error('Error saving data:', error);
                this.toastssService.error('An error has occurred. Please try again.');
                this.DisabledNextActivity = false;
                this.isSave = false;
                reject(error); 
            }
        ).add(() => {
            this.resetFlags();
        });
    });
  }
  resetFlags() {
    this.isSave = false;
  }
  onOvertimeDaysChanges(selectedId: number[]) {
    if (this.showOverdoubletimeContent === true && this.timeClockSetupFormFields.value.doubleOvertimeDays?.length > 0) {
      const hasMatch = selectedId.some((id: any) => 
        this.timeClockSetupFormFields.value.doubleOvertimeDays.includes(id)
      );
      if (hasMatch === false) {
        this.on_Error_DoubleOvertimeDays = false;
      } else {
        this.on_Error_DoubleOvertimeDays = true;
      }
    } else if(selectedId?.length === 0){
      this.on_Error_DoubleOvertimeDays = false;
    }
    const selectedIdsAsNumbers = selectedId.map(id => Number(id));
    const filteredDays = this.WeekStart.filter(day => selectedIdsAsNumbers.includes(day?.id));
    const overtimeDayData = filteredDays.map(day => {
        return {
            title: day.name,
            key: day.id,
        };
    });

    this._overtimeDays = overtimeDayData.map(item => item?.title.trim()).join(', ');
  }
  onDoubleOvertimeDaysChanges(selectedId: number[]) {
    this.On_DoubleOverTimeDays(selectedId);
    const selectedIdsAsNumbers = selectedId.map(id => Number(id));
    const filteredDays = this.WeekStart.filter(day => selectedIdsAsNumbers.includes(day?.id));
    const DoubleOvertimeDayData = filteredDays.map(day => {
        return {
            title: day.name,
            key: day.id,
        };
    });

    this._doubleOvertimeDays  = DoubleOvertimeDayData.map(item => item?.title.trim()).join(', ');
  }
  CancelTimeClock() {
    this.isVisibleInTimeClock = false;
     this.cancel.emit();
  }
  OnAfterNoOfHoursOverTimeDaily(value: any){
    if(this.ShowEmployeesvertime === true){
      if(value > 0){
        this.Test_Case_One = false;
      } else if(value === null || value === ""){
        this.Test_Case_One = true;
      }
    }
    if(this.ShowEmployeesAccrueDailyDoubleOvertime === true && value === this.timeClockSetupFormFields.value.afterNoOfHoursDoubleOverTimeDaily && value > this.timeClockSetupFormFields.value.afterNoOfHoursDoubleOverTimeDaily){
      this.on_MustGreaterThanValue_Daily = true;
    } else {
      this.on_MustGreaterThanValue_Daily = false;
    }
  }
  OnAfterNoOfHoursOverTimeWeekly(value: any){
    if(value === 0){
      this.timeClockSetupFormFields.get('afterNoOfHoursOverTimeWeekly')?.setValue(1);
    }
    if(this.ShowEmployeesWeeklyOvertime === true){
      if(value > 0){
        this.Test_Case_Two = false;
      } else if(value === null || value === ""){
        this.Test_Case_Two = true;
      }
    }
    if(this.ShowEmployeesAccrueWeeklyDoubleOvertime === true && value === this.timeClockSetupFormFields.value.afterNoOfHoursDoubleOverTimeWeekly && value > this.timeClockSetupFormFields.value.afterNoOfHoursDoubleOverTimeWeekly){
      this.on_MustGreaterThanValue_Weekly = true;
    } else {
      this.on_MustGreaterThanValue_Weekly = false;
    }
  }
  OnAfterNoOfHoursDoubleOverTimeDaily(value: any){
    if(this.ShowEmployeesAccrueDailyDoubleOvertime === true){
      if(value){
        this.Test_Case_Three = false;
      } else if(value !== undefined){
        this.Test_Case_Three = true;
        this.on_MustGreaterThanValue_Daily = false;
        return
      }
      if(value > this.timeClockSetupFormFields.value.afterNoOfHoursOverTimeDaily){
        this.on_MustGreaterThanValue_Daily = false;
      } else {
        this.on_MustGreaterThanValue_Daily = true;
      }
    }
  }
  OnAfterNoOfHoursDoubleOverTimeWeekly(value: any){
    if(this.ShowEmployeesAccrueWeeklyDoubleOvertime === true){
      if(value > 0){
        this.Test_Case_Four = false;
      } else if(value === null || value === ""){
        this.Test_Case_Four = true;
        this.on_MustGreaterThanValue_Weekly = false;
      }
       if(value < this.timeClockSetupFormFields.value.afterNoOfHoursOverTimeWeekly || this.timeClockSetupFormFields.value.afterNoOfHoursOverTimeWeekly === value){
        this.on_MustGreaterThanValue_Weekly = true;
      } else {
        this.on_MustGreaterThanValue_Weekly = false;
      }
    }
  }
  On_DoubleOverTimeDays(value: any[]) {
    if (!value || value?.length === 0) {
      this.on_Error_DoubleOvertimeDays = false;
      return;
    }
    if (this.showOverdoubletimeContent === true && this.timeClockSetupFormFields.value.overtimeDays) {
      const hasMatch = value.some((id: any) => 
        this.timeClockSetupFormFields.value.overtimeDays.includes(id)
      );
      if (hasMatch === false) {
        this.on_Error_DoubleOvertimeDays = false;
      } else {
        this.on_Error_DoubleOvertimeDays = true;
      }
    } else if(value?.length === 0){
      this.on_Error_DoubleOvertimeDays = false;
    }
  }
  onSelectKMChanges(value: any){
    let Case_One = this.selectRadiusKMT.find(ii =>ii?.id === value);
    if(Case_One.name === 'Kilometers'){
      this.selectRadius = this.onLookUpNameSetup.filter(item => item?.description === 'Kilometers');
      if (!this.ITimeClockSetupRes?.radiusId) {
        this.timeClockSetupFormFields.get('radiusId')?.setValue(this.selectRadius[0]?.id);
      }
    } else if(Case_One.name === 'Miles'){
      this.selectRadius = this.onLookUpNameSetup.filter(item => item?.description === 'Miles');
      if (!this.ITimeClockSetupRes?.radiusId) {
        this.timeClockSetupFormFields.get('radiusId')?.setValue(this.selectRadius[0]?.id);
      }
    }
  }
}