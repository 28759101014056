import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-allowance',
  templateUrl: './allowance.component.html',
  styleUrls: ['./allowance.component.css']
})
export class AllowanceComponent {

  @Output() cancel = new EventEmitter<void>();
  tabs = [1, 2];
  innerCardTabs(tab: number): string {
    switch (tab) {
      case 1:
        return 'Assinged Selection';
      case 2:
        return 'Unassinged Selection';
      default:
        return '';
    }
  }
  }
