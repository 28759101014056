
<nz-drawer
  [nzWidth]="'900px'"
  [nzVisible]="visible"
  nzPlacement="right"
  [nzTitle]="nzTitle"
  [nzExtra]="extra"
  (nzOnClose)="close()"
  nzClosable="false"
  [nzFooter]="TodoSFooter"
  [nzBodyStyle]="{
    'background': '#f1f4fa',
    'padding': '15px'
  }">

  <ng-template #nzTitle>
    <p nz-typography>{{selectedJobName}}</p>
    <h1 nz-typography>To-Do</h1>
  </ng-template>

  <ng-template #extra>
    <button nz-button nzType="text" *ngIf="toDoResponse"
      nz-popover
      [nzPopoverContent]="contentTemplate"
      nzPopoverPlacement="bottomRight"
      nzPopoverTrigger="click"
      nz-tooltip
      nzTooltipTitle="Copy Share Link" nzTooltipPlacement="right">
      <span class="fa-solid fa-paperclip"></span>
    </button>
    <button nz-button nzType="text" (click)="close()">
      <span nz-icon nzType="close" nzTheme="outline"></span>
    </button>

    <ng-template #contentTemplate>
      <div>
        <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
          <input type="text" nz-input disabled />
        </nz-input-group>
        <ng-template #suffixIconButton>
          <button nz-button nzType="default" nzSearch>Copy</button>
        </ng-template>
      </div>
    </ng-template>
  </ng-template>

  <form nz-form id="todoForm" [formGroup]="todoForm" *nzDrawerContent>
    <div class="content">

      <div nz-row nzAlign="middle"
        *ngIf="errorMessages && errorMessages?.length > 0">
        <div nz-col [nzSpan]="24">
          <nz-alert
            nzType="error"
            nzShowIcon
            [nzMessage]="message"
            [nzDescription]="errorDescriptionTemplate">
          </nz-alert>
        </div>
      </div>

      <ng-template #errorDescriptionTemplate>
        <ul>
          <li class="sub-text p-0 m-0"
            *ngFor="let error of errorMessages">
            {{ error }}
          </li>
        </ul>
      </ng-template>

      <ng-template #message>
        <strong class="mt-2">Please correct the following
          fields:</strong>
      </ng-template>

      <div class="card-container">
        <nz-tabset nzType="card">
          <ng-container *ngFor="let tab of tabs">
            <nz-tab *ngIf="tab.show" [nzTitle]="tab.title">

              <ng-container *ngIf="tab.id === 1">
                <nz-card nzTitle="To-Do Information" nzBorderless>

                  <div nz-row [nzGutter]="16" class="mb-2 ms-2"  >
                    <div nz-col nzSpan="24">
                      <nz-form-item >
                        <nz-form-control>
                          <label nz-checkbox
                          [nzDisabled]="IsCheckDisable"
                          class="custom-checkbox   d-flex align-items-center"
                          formControlName="isCompleted">
                      Complete
                      <span class="mt-1 ms-1" *ngIf="todoForm.get('isCompleted')?.value === true && toDoResponse">
                        By {{ LoginPerson }}
                      </span>
                    </label>
                        </nz-form-control>
                      </nz-form-item>
                    </div>
                  </div>

                  <div nz-row>
                    <!-- Left Side -->
                    <div nz-col class="gutter-row" [nzSpan]="12">
                      <div nz-col [nzSpan]="24">
                        <label class="sub-text">Title</label><span
                          class="text-danger">*</span>
                        <nz-form-item>
                          <nz-form-control
                            [nzErrorTip]="titleErrorMessage  || 'Required'">
                            <input nz-input formControlName="title"  #titleInput />
                          </nz-form-control>
                        </nz-form-item>
                      </div>

                      <div nz-col [nzSpan]="24" class="mb-2">
                        <label class="sub-text">Notes</label>
                        <nz-form-item>
                          <nz-form-control [nzErrorTip]="allNotesErrorMessage">
                            <textarea nz-input
                              [nzAutosize]="{ minRows: 2, maxRows: 10 }"
                              formControlName="notes"
                              style=" resize: none;"></textarea>
                          </nz-form-control>
                        </nz-form-item>
                      </div>

                      <!-- Priority Field -->
                      <div nz-col [nzSpan]="24" *ngIf="showPriorityField">
                        <label class="sub-text">Priority</label>
                        <nz-form-item>
                          <nz-form-control>
                            <nz-select
                              style="width: 100%"
                              nzShowSearch
                              [nzOpen]="showPriorityField && isPatching"
                              [nzAutoFocus]="showPriorityField"
                              formControlName="prioritySetupId">

                              <nz-option nzLabel="None"
                                [nzValue]="0"></nz-option>
                              <nz-option *ngFor="let option of priorityOption"
                                [nzLabel]="option.description"
                                [nzValue]="option.id"></nz-option>

                            </nz-select>
                          </nz-form-control>
                        </nz-form-item>
                      </div>

                      <!-- Tags Field -->
                      <div nz-row *ngIf="showTagsField">
                        <div nz-col [nzSpan]="24">
                          <label class="sub-text">Tags</label>
                        </div>

                        <!-- Tag Selector Section -->
                        <div nz-col [nzSpan]="17">
                          <nz-form-item>
                            <nz-form-control>
                              <nz-tree-select
                                [nzOpen]="showTagsField"
                                style="width: 100%"
                                nzCheckable
                                nzCheckAll="true"
                                nzAllowClear="false"
                                [nzNodes]="tagsNodes"
                                (ngModelChange)="onTagChange($event)"
                                formControlName="createToDoInformationTagParameterRequests">
                              </nz-tree-select>
                            </nz-form-control>
                          </nz-form-item>
                        </div>

                        <!-- Add/Edit Buttons Section -->
                        <div nz-col [nzSpan]="6" class="mt-2 ms-1">
                          <nz-space nzAlign="center">
                            <button nz-button nzType="link" class="add-button"
                              (click)="newScheduleTagOpen()">Add</button>
                            <button nz-button nzType="link" class="edit-button"
                              (click)="newScheduleTagEdit()"
                              [disabled]="!isSingleItemSelected()">
                              Edit</button>
                          </nz-space>
                        </div>
                      </div>

                      <!-- Reminder Field -->
                      <div nz-col [nzSpan]="24" *ngIf="showReminderField">
                        <label class="sub-text">Reminder</label>
                        <nz-form-item>
                          <nz-form-control>
                            <nz-select style="width: 100%"
                              nzShowSearch
                              [nzOpen]="showReminderField && isPatching"
                              [nzAutoFocus]="showReminderField"
                              formControlName="reminderSetupId">
                              <nz-option nzLabel="None"
                                [nzValue]="0"></nz-option>

                              <nz-option *ngFor="let option of reminderOption"
                                [nzLabel]="option.name"
                                [nzValue]="option.id"></nz-option>
                            </nz-select>
                          </nz-form-control>
                        </nz-form-item>
                      </div>

                      <!-- Additional Buttons (Attach, Priority, Tags, Reminder) -->
                      <div nz-col [nzSpan]="24">
                        <nz-form-item>
                          <nz-form-control>
                            <nz-space>
                              <button nz-button nzType="text"
                                (click)="toggleAttachment()"
                                *ngIf="!showAttachment"
                                class="d-flex align-items-center">
                                <span nz-icon nzType="link"
                                  nzTheme="outline"></span> Attach
                              </button>
                              <button nz-button nzType="text"
                                *ngIf="!showPriorityField"
                                (click)="togglePriorityField()"
                                class="d-flex align-items-center">
                                <span nz-icon nzType="info-circle"
                                  nzTheme="outline"></span> Priority
                              </button>
                              <button nz-button nzType="text"
                                *ngIf="!showTagsField"
                                (click)="toggleTagsField()"
                                class="d-flex align-items-center">
                                <span nz-icon nzType="tag"
                                  nzTheme="outline"></span> Tags
                              </button>
                              <button
                                nz-button
                                nzType="text"
                                [disabled]="!todoForm.get('dueDate')?.value"
                                *ngIf="!showReminderField"
                                nz-popover
                                [nzPopoverContent]="todoForm.get('dueDate')?.value ? '' : 'Select a due date to add a reminder'"
                                (click)="toggleReminderField()"
                                class="d-flex align-items-center">
                                <span nz-icon nzType="bell"
                                  nzTheme="outline"></span> Reminder
                              </button>

                            </nz-space>
                          </nz-form-control>
                        </nz-form-item>
                      </div>

                    </div>
                    <!-- Attachment Field -->

                    <!-- Right Side -->
                    <div nz-col class="gutter-row" [nzSpan]="12">
                      <div nz-col [nzSpan]="24">
                        <label class="sub-text">Assigned to</label>
                        <nz-form-item>
                          <nz-form-control>
                            <nz-tree-select style="width: 100%"
                              [nzNodes]="combinedNodes"
                              [nzShowSearch]="true" nzAllowClear="false"
                              nzCheckable
                              (ngModelChange)="onAssigneesChange($event)"
                              formControlName="createToDoParameterAssignOwnersRequests">
                            </nz-tree-select>
                          </nz-form-control>
                        </nz-form-item>
                      </div>

                      <div nz-col [nzSpan]="24">
                        <label class="sub-text">Due Date</label><br>
                        <nz-form-item>
                          <nz-form-control>
                            <!-- <nz-button-group class="full-width-group">
                              <button nz-button
                                nzType="default" (click)="toggleDateFields()">
                                Choose Date
                              </button>
                              <button nz-button nzType="default"
                                (click)="toggleScheduleItemFields()">
                                Link to add
                              </button>
                            </nz-button-group> -->

                            <nz-radio-group [(ngModel)]="radioValue" >
                              <label (click)="toggleDateFields()"  nz-radio-button nzValue="A">Choose Date</label>
                              <label  (click)="toggleScheduleItemFields()"    nz-radio-button nzValue="B">Link to Schedule Item</label>
                            </nz-radio-group>
                          </nz-form-control>
                        </nz-form-item>
                      </div>

                      <!-- Schedule Work Section -->
                       
                      <div nz-row  nzAlign="middle" *ngIf="!isLinkToAdd">
                        <div nz-col [nzSpan]="16" class="me-2">
                          <label class="sub-text">Schedule Item <span
                              class="text-danger">*</span></label>
                          <nz-form-item>
                            <nz-form-control
                              [nzErrorTip]="todoForm.get('scheduleItemParameterId')?.hasError('required') ? 'Schedule Item is required' : null">
                              <nz-select
                                formControlName="scheduleItemParameterId" (ngModelChange)="onFieldisComplete('scheduleItemParameterId', $event)">
                                <nz-option nzLabel="-- Select Schedule Item --"
                                  [nzValue]="0"></nz-option>
                                <nz-option *ngFor="let option of scheduleList"
                                  [nzLabel]="option.Title"
                                  [nzValue]="option.Id"></nz-option>
                              </nz-select>
                            </nz-form-control>
                          </nz-form-item>

                        </div>
                        <div nz-col [nzSpan]="4" class="mt-4">
                          <nz-space class="mt-2" nzAlign="center">
                            <button nz-button nzType="link"
                              (click)="newScheduleOpen()"
                              class="add-button">Add</button>
                            <button nz-button nzType="link"
                              (click)="newScheduleEdit()"
                              [disabled]="todoForm.get('scheduleItemParameterId')?.value <= 0"
                              class="edit-button">Edit</button>
                          </nz-space>
                        </div>
                      </div>

                      <div nz-col [nzSpan]="24" *ngIf="!isLinkToAdd">
                        <label class="sub-text">Due</label>
                        <div nz-row nzAlign="middle">
                          <div nz-col [nzSpan]="8">
                            <nz-form-item>
                              <nz-form-control>
                                <nz-input-number
                                [nzMax]="999"
                                (ngModelChange)="onDaysChange($event); onFieldScheduleItem()"
                                (input)="onFieldScheduleItem()"
                                (keydown)="validateNumberInput($event)"
                                  formControlName="dueNumberDays"></nz-input-number>
                              </nz-form-control>
                            </nz-form-item>
                          </div>
                          <div nz-col [nzSpan]="14" *ngIf="!isLinkToAdd">
                            <nz-form-item>
                              <nz-form-control>
                                  <nz-radio-group formControlName="beforeOrAfter"    [(ngModel)]="DaysradioValue"  (ngModelChange)="DaysradioButtons()" >
                                    <label class="me-1">Days</label>
                                    <label nz-radio
                                      nzValue="A"  (change)="onFieldScheduleItem()">Before</label>
                                    <label nz-radio nzValue="B"  (change)="onFieldScheduleItem()">After</label>
                                  </nz-radio-group>
                              </nz-form-control>
                            </nz-form-item>

                          </div>
                        </div>
                      </div>

                      <div nz-col [nzSpan]="24" 
                        class="d-flex align-items-center p-0">
                        <div nz-col [nzSpan]="10" class="gutter-row">
                          <label class="sub-text">Due Date</label>
                          <nz-form-item>
                            <nz-form-control *ngIf="isLinkToAdd">
                              <nz-date-picker [nzFormat]="'MMM d, YYYY'"
                              (ngModelChange)="onFieldChange('dueDate', $event)"
                                nzSuffixIcon="false" nzAllowClear
                                [nzPlaceHolder]="' '"
                                formControlName="dueDate"></nz-date-picker>
                            </nz-form-control>

                            <ng-container *ngIf="!isLinkToAdd">
                              <div nz-row>
                                <div nz-col nzSpan="24">
                                  <div class="ValueDisplay">
                                    {{ selectInfoResponseDueBYID_?.DedlineOn ? 
                                    (selectInfoResponseDueBYID_?.DedlineOn | date: 'MMM d, yyyy') : 
                                    (todoForm.get('dueDate')?.value | date: 'MMM d, yyyy') || '--' }}
                                  </div>
                                </div>
                              </div>
                            </ng-container>
                          
                          </nz-form-item>
                        </div>

                        <div nz-col [nzSpan]="10" class="gutter-row">
                          <label class="sub-text">Time</label>
                          <nz-select nzShowSearch
                            formControlName="time">
                            <nz-option
                              *ngFor="let time of timeOptions"
                              [nzLabel]="time"
                              [nzValue]="time">
                            </nz-option>
                          </nz-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </nz-card>
                <div nz-col [nzSpan]="24" *ngIf="showAttachment">
                  <nz-card nzTitle="Attachments" nzBorderless>
                    <app-create-attachments-common
                      [TestAttachment__]="TestAttachment"
                      (filesUploaded)="onFilesUploaded($event)"></app-create-attachments-common>

                  </nz-card>
                </div>
                
                <!-- Messaging Card Start -->

                <nz-card nzBorderless *ngIf="toDoResponse">
                  <messaging-common *ngIf="toDoResponse.Id > 0"
                    [Job_ID]="JobInfoId"
                    [Messaging_ID]="Messaging_Id"
                    [header_ID]="toDoId"
                    [Form_Name_ID]="formNameId">
                  </messaging-common>
                </nz-card>

                <!-- Rfi Card End -->

                <nz-card nzBorderless *ngIf="toDoResponse">
                  <ng-container *ngIf="toDoResponse.Id > 0">
                    <app-create-rfis-common
                      [Job_ID]="JobInfoId"
                      [header_ID]="toDoId"
                      [RFIs_Info]="toDoRFIId"
                      [Formname_Id]="formNameId">
                    </app-create-rfis-common>
                  </ng-container>
                </nz-card>

                <!-- Rfi Card End -->

              </ng-container>

              <ng-container *ngIf="tab.id === 2">
                <nz-card nzBorderless nzTitle="Checklist Items" [nzExtra]="ChecklistExtra">
                  <ng-template #ChecklistExtra>
                    <nz-space>
                      <ng-container *nzSpaceItem >
                        <button *ngIf="!hasChecklistItems" nz-button nzType="default" (click)="accessAddItem()">
                          Add Checklist
                        </button>
                      </ng-container>

                      <!-- Complete All Button -->
                      <ng-container *nzSpaceItem >
                      <button *ngIf="showCompleteAllButton()" nz-button nzType="default" class="margin-end" (click)="completeAll()">
                        Complete All
                      </button>
                    </ng-container  >
                    <ng-container *nzSpaceItem >
                      <button *ngIf="hasChecklistItems" nz-button nzType="default" (click)="showConfirmRemoveAll()">
                        Remove Checklist
                      </button>
                      </ng-container>
                    </nz-space>
                  </ng-template>

                  <!-- Initial Container, shown only if no checklist items are present -->
                  <div
                    class="initial-container"
                    *ngIf="!hasChecklistItems"
                    style="margin: 20px 0; text-align: center; margin-top: 40px;"
                  >
                    <div class="icon-list" style="display: flex; justify-content: center; gap: 10px; margin-bottom: 20px;">
                      <i nz-icon nzType="check-circle" style="font-size: 74px;"></i>
                    </div>
                    <h1 nz-typography style="font-size: 24px; margin-bottom: 10px;">Give your To-Do's more power</h1>
                    <p nz-typography style="margin-bottom: 20px;">
                      Add checklist items to assign specific and detailed tasks from your <br />To-Do.
                    </p>
                    <button nz-button nzType="primary" (click)="accessAddItem()">Add Checklist</button>
                  </div>

                  <!-- Checklist Row Component, shown only if checklist items are present -->
                  <Todo-checklist-row  [toDoInformationId]="toDoId"></Todo-checklist-row>

                  <!-- Add Checklist Item Button, shown only if checklist items are present -->
                  <button
                    (click)="accessAddItem()"
                    *ngIf="hasChecklistItems"
                    nz-button
                    nzType="text"
                    class="mt-4 d-flex align-items-center rounded-1"
                  >
                    <a class="d-flex align-items-start mt-2"
                    ><i class="fa-solid fa-circle-plus fs-5 pe-2 mt-1"></i> Add Checklist Item</a>
                  </button>
                </nz-card>

              </ng-container>

              <!-- TAB 3 RELATED ITEMS START -->

              <ng-container *ngIf="tab.id === 3">

                <nz-card nzTitle="Related Items" nzBorderless>
                  <div class="p-2">
                    <h4 nz-typography class="mb-3">Schedule Items</h4>
                    <ng-container *ngIf="toDoId > 0; else noScheduleItems">
                      <p *ngIf="!toDoResponse?.RelatedScheduleName" nz-typography>No related Schedule Items.</p>
                      <div nz-row class="mb-2" *ngIf="toDoResponse?.RelatedScheduleName">
                        <button [disabled]="toDoResponse?.RelatedScheduleName" nz-button   class="disabled d-flex align-items-center justify-content-between border-0 RelatedItemTag RelatedItemTag-actionable" >
                            <img src="assets/Empty-SVGs/Project-management/todos/todos.svg" style="width: 20px; height: 25px; margin-right: 7px;">
                             <p nz-typography>Schedule Item: </p>
                             <strong class="ms-1">{{toDoResponse?.RelatedScheduleName}}</strong>
                        </button>
                      </div>
                    </ng-container>
                    <ng-template #noScheduleItems>
                      <p nz-typography>Schedule items available after save.</p>

                    </ng-template>

                    <br>
                    <h4 nz-typography class="mb-3">Daily Logs</h4>

                    <!-- Conditional rendering for Daily Logs -->
                    <ng-container *ngIf="toDoId > 0; else noDailyLogs">
                      <p nz-typography *ngIf="!relatedDailyLogList || relatedDailyLogList.length === 0">No related Daily Log.</p>
                    </ng-container>
                    <ng-template #noDailyLogs>
                      <p nz-typography class="mb-3">Daily Logs available after save.</p>
                    </ng-template>


                    <div nz-row class="mb-2" *ngFor="let relatedLogs of relatedDailyLogList">
                      <button nz-button (click)="EditSiteDiariesDailyLogByRelatedItems(relatedLogs.Id)"  class="d-flex align-items-center justify-content-between border-0 RelatedItemTag RelatedItemTag-actionable" >
                          <img src="assets/Empty-SVGs/Project-management/todos/todos.svg" style="width: 20px; height: 25px; margin-right: 7px;">
                           <p nz-typography>Daily Log: </p>
                           <strong>{{relatedLogs.FormattedDate}}</strong>
                      </button>
                    </div>


                    <!-- Button text change based on toDoId > 0 -->
                    <button nz-button nzType="default" class="mt-3" (click)="createToDoAndOpenDailyLog()">
                      <ng-container *ngIf="toDoId > 0; else newDailyLogButton">
                        New Daily Log
                      </ng-container>
                      <ng-template #newDailyLogButton>
                        Save And Create Daily Log
                      </ng-template>
                    </button>
                  </div>
                </nz-card>

              </ng-container>
              <!-- TAB 3 RELATED ITEMS END -->
            </nz-tab>
          </ng-container>
        </nz-tabset>
      </div>
    </div>

  </form>

  <!-- TODO FOOTER START -->
  <ng-template #TodoSFooter>
    <div nz-row>
      <div nz-col [nzSpan]="12">
        <div *ngIf="toDoResponse"
          class="footerContent">
          <span *ngIf="footerResponse">
            <app-footer-label
              [footerData]="footerResponse"></app-footer-label>
          </span>
        </div>
      </div>
      <div nz-col [nzSpan]="12" class="text-end">
        <nz-space>
          <ng-container *ngIf="toDoResponse">
            <button *nzSpaceItem nz-button nzType="default" [nzLoading]="isDeleteLoading" [disabled]="DisabledNextActivity"
              (click)="showConfirmDeleteTodo()">Delete</button>
          </ng-container>
          <button [nzLoading]="isSaveAndNew" [disabled]="DisabledNextActivity"
            *nzSpaceItem
            nz-button
            nzType="primary"
            (click)="saveAndNewToDo()">
            Save & New
          </button>
          <button [nzLoading]="isSaving" [disabled]="DisabledNextActivity"
            *nzSpaceItem nz-button
            nzType="primary"
            (click)="saveButton()">
            Save
          </button>
          <button [nzLoading]="isSaveAndClose" [disabled]="DisabledNextActivity"
            *nzSpaceItem nz-button nzType="primary"
            (click)="saveAndCloseToDo()">
            Save & Close</button>
        </nz-space>
      </div>
    </div>
  </ng-template>
  <!-- TODO FOOTER END -->
</nz-drawer>
