import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BehaviorsubjectService {

  constructor() { }

  private leadActivityTypeResponseSource = new Subject<any>();
  leadActivityTypeResponse$ = this.leadActivityTypeResponseSource.asObservable();

  setLeadActivityTypeResponse(response: any) {
    this.leadActivityTypeResponseSource.next(response);
  }
}
