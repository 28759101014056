import { JobSummaryDbComponent } from './../../../../jobs-management/Jobs-Summary/job-summary-db/job-summary-db.component';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject, Observable, Subject, catchError, of, takeUntil } from 'rxjs';
import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { JobInformationService } from 'src/Service/Job-List/Job-Information/job-information.service';
import { JobListEmptyComponent } from 'src/app/jobs-management/Job-List/job-list-empty/job-list-empty.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { InternalUserService } from 'src/Service/Internaluser/internal-user.service';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { JobListPreDefinedCodes, JobScratchPreDefinedCodes } from '../../Models/LookUpStandardFilterCodes';
import { LookupNameSetupResponse } from 'src/Models/LeadTags';
import { JobInformationResponse, sideMenuBarResult } from 'src/Models/CustomerInfromation/CustomerAndLeadOpportunityResponse';
import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';
import { JobCreateFromScratchComponent } from 'src/app/jobs-management/Job-List/job-create-from-scratch/job-create-from-scratch.component';
import { MessagingComposeNewMessageComponent } from 'src/app/messaging/Components/messaging/messaging-compose-new-message/messaging-compose-new-message.component';

interface ItemData {
  gender: string;
  name: Name;
  email: string;
}

interface Name {
  title: string;
  first: string;
  last: string;
}
@Component({
  selector: 'app-side-menu-bar',
  templateUrl: './side-menu-bar.component.html',
  styleUrls: ['./side-menu-bar.component.css']
})
export class SideMenuBarComponent {
  projectManagerNodes: NzTreeNodeOptions[] = [];
  @Output() toggleCollapse = new EventEmitter<void>();
  @Input() isAllJobOption: boolean = true;
  @Output() getSelectedJobId = new EventEmitter<number>();

  isCollapsed = false;
  ds = new MyDataSource(this.http);
  searchText: string = '';
  jobList: any[] = [];
  @Input() filteredJobList: sideMenuBarResult[] = [];
  private destroy$ = new Subject<boolean>();
  contentHide: boolean = true;
  JobGroupNodes: NzTreeNodeOptions[] = [];
  PAGE_ID: number = 30002;
  JOB_LIST_PAGE_ID: number = 3;
  showNoResultsMessage = false;
  selectedItem: any;
  preDefinedDataCodes = {
    JobGroup: JobScratchPreDefinedCodes.JobGroup,
    Status: JobListPreDefinedCodes.Status,

  };
  jobListStatus: LookupNameSetupResponse[] = [];
  constructor(
    private http: HttpClient,
    private jobsService: JobInformationService,
    private modal: NzModalService,
    private internalUserService: InternalUserService,
    private LookupFilter: LookupFilterService,
    public localStorageService: AccessLocalStorageService,
  ) { }

  ngOnInit(): void {
    this.jobsService.onGetJobShortInformation().subscribe(res => {
      this.jobList = res.result;


      this.filteredJobList = [...this.jobList];


      // Get the ID from local storage
      const jobId = this.localStorageService.getJobId();

      // Find the job item in the job list based on the ID
      if (jobId) {
        const selectedJob = this.jobList.find(job => job.id === jobId);
        if (selectedJob) {
          this.selectedItem = selectedJob;
        }
      }
    });


    this.internalUserService.getData().subscribe(res => {
      const userList = res.result;
      this.projectManagerNodes = userList?.map((data) => ({
        title: data?.firstName + data?.lastName,
        value: data?.id.toString(),
        key: data?.id.toString(),
        isLeaf: true,
      }));
    })


    this.LookupFilter.getLookUpValuesByFormNameId(this.PAGE_ID).subscribe(
      (res) => {

        let JobGroupValues = res.result.filter(
          (x) => x.code === this.preDefinedDataCodes.JobGroup.code
        );
        this.JobGroupNodes = JobGroupValues.map((JobGroup) => ({
          title: JobGroup.name,
          key: JobGroup.id.toString(),
          isLeaf: true,
        }));


      }
    );

    this.LookupFilter.getLookUpValuesByFormNameId(this.JOB_LIST_PAGE_ID).subscribe(
      (res) => {
        this.jobListStatus = res.result
      }
    );

  }


  filterJobList(): void {
    this.filteredJobList = this.jobList.filter(job =>
      job.name.toLowerCase().includes(this.searchText.toLowerCase())
    );

    this.showNoResultsMessage = this.searchText.trim().length > 0 && this.filteredJobList.length === 0;
  }

  handleClick(): void {
    this.toggleCollapse.emit();
    this.contentHide = !this.contentHide;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }


  clearSearch(): void {
    this.searchText = '';
    this.filterJobList();
  }



  selectItem(item: sideMenuBarResult | sideMenuBarResult[]): void {
    if (Array.isArray(item)) {
      this.selectedItem = null;
      this.filteredJobList = [...this.jobList];
      this.localStorageService.setJobId(null); // Clear ID
      this.localStorageService.setJobName('All ' + this.jobList.length + ' Listed Jobs'); // Set name to "All {total number of jobs} Listed Jobs"
    } else {
      this.selectedItem = item;
      if (item && item.id) {
        this.getSelectedJobId.emit(item.id);
        this.localStorageService.setJobId(item.id);
        this.localStorageService.setJobName(item.name);
        this.localStorageService.setJobColorId(item.colorId);
        this.localStorageService.setJobAddress(item.StreetAddress);
        this.jobsService.getDataId(item.id).subscribe(res => {
          this.selectedItem = res.result;


        });
      }
    }
  }


  Jobsummary(data: JobInformationResponse): void {
    const modalRef = this.modal.create({
      nzContent: JobSummaryDbComponent,
      nzFooter: null,

    });

    modalRef.componentInstance.JobInformationResponse = data;
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }





  OpenJobModal(): void {
    const modalRef = this.modal.create({
      nzContent: JobListEmptyComponent,
      nzFooter: null,

    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });

  }


  openJobScratchModal() {

    const modalRef = this.modal.create({
      nzContent: JobCreateFromScratchComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }

  openMailModelOpen(): void {
    const modalRef = this.modal.create({
      nzContent: MessagingComposeNewMessageComponent,
      nzFooter: null,

    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }


}

class MyDataSource extends DataSource<ItemData> {
  private pageSize = 10;
  private cachedData: ItemData[] = [];
  private fetchedPages = new Set<number>();
  private dataStream = new BehaviorSubject<ItemData[]>(this.cachedData);
  private complete$ = new Subject<void>();
  private disconnect$ = new Subject<void>();


  constructor(private http: HttpClient,) {
    super();
  }

  completed(): Observable<void> {
    return this.complete$.asObservable();
  }

  connect(collectionViewer: CollectionViewer): Observable<ItemData[]> {
    this.setup(collectionViewer);
    return this.dataStream;
  }

  disconnect(): void {
    this.disconnect$.next();
    this.disconnect$.complete();
  }

  private setup(collectionViewer: CollectionViewer): void {
    this.fetchPage(0);
    collectionViewer.viewChange.pipe(takeUntil(this.complete$), takeUntil(this.disconnect$)).subscribe(range => {
      if (this.cachedData.length >= 50) {
        this.complete$.next();
        this.complete$.complete();
      } else {
        const endPage = this.getPageForIndex(range.end);
        this.fetchPage(endPage + 1);
      }
    });
  }

  private getPageForIndex(index: number): number {
    return Math.floor(index / this.pageSize);
  }

  private fetchPage(page: number): void {
    if (this.fetchedPages.has(page)) {
      return;
    }
    this.fetchedPages.add(page);

    this.http
      .get<{ results: ItemData[] }>(
        `https://randomuser.me/api/?results=${this.pageSize}&inc=name,gender,email,nat&noinfo`
      )
      .pipe(catchError(() => of({ results: [] })))
      .subscribe(res => {
        this.cachedData.splice(page * this.pageSize, this.pageSize, ...res.results);
        this.dataStream.next(this.cachedData);
      });
  }





}
