<nz-modal
  nzWidth="82%"
  [(nzVisible)]="toDosVisibility"
  [nzTitle]="toDosHeader"
  [nzFooter]="toDosFooter"
  (nzOnCancel)="closeToDos()"
  [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '72vh', 'overflow-y': 'auto'}"
  [nzStyle]="{ top: '20px' }">

  <ng-template #toDosHeader>
    <h2 class="p-0 m-0" nz-typography>To-Do's</h2>
  </ng-template>

  <div class="cantent" *nzModalContent>

    <form nz-form [formGroup]="todoForms" >
      <nz-card nzBorderless nzTitle="To-Do's Setup">
        <div nz-row>
          <div nz-col nzSpan="24">
            <nz-form-item>
              <nz-form-control>

                <div class="flex-container">
                  <label class="flex-label">Send Past Due To-Do Reminder up to</label>
                  <nz-select
                    style="width: 100px; margin-left: 8px;"
                    class="flex-select"
                    formControlName="sendPastDueToDoReminderUpToDay"
                  >
                    <nz-option nzLabel="None" nzValue="0"></nz-option> 
                    <nz-option nzLabel="1" nzValue="1"></nz-option>
                    <nz-option nzLabel="2" nzValue="2"></nz-option>
                    <nz-option nzLabel="3" nzValue="3"></nz-option>
                    <nz-option nzLabel="4" nzValue="4"></nz-option>
                    <nz-option nzLabel="5" nzValue="5"></nz-option>
                    <nz-option nzLabel="6" nzValue="6"></nz-option>
                    <nz-option nzLabel="7" nzValue="7"></nz-option>
                  </nz-select>
                  days.
                  <i
                    class="bi bi-info-circle-fill text-secondary me-3"
                    nz-popover
                    [nzPopoverContent]="TododTemplate"
                    nzPopoverPlacement="right"
                  ></i>
                  <ng-template #TododTemplate>
                    <div class="rounded-2">
                      <p style="height: auto; width: 220px">
                        The to-do assignees will be reminded that the to-do is past deadline for the amount of days selected.
                      </p>
                    </div>
                  </ng-template>
                </div>
                
                

              </nz-form-control>
            </nz-form-item>
          </div>
        </div>

        <div nz-row>
          <div nz-col nzSpan="24">
            <nz-form-item>
              <nz-form-control>
                <label nz-checkbox  formControlName="requireAllChecklistItemsToBeCompleteInOrderToCompleteAtoDo"
                  >
                  <span> Require all checklist items to be complete in
                    order to complete a To-Do</span>
                  <i class="bi bi-info-circle-fill text-secondary ms-3"
                    nz-popover [nzPopoverContent]="TododTemplate1"
                    nzPopoverPlacement="right"></i>
                  <ng-template #TododTemplate1>
                    <div class="rounded-2">
                      <p style="height: auto; width: 220px">Disable To-Do
                        completion until all checklist items are marked
                        complete..</p>
                    </div>
                  </ng-template>

                </label>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>

        <div nz-row>
          <div nz-col nzSpan="24">
            <nz-form-item>
              <nz-form-control>
                <label nz-checkbox  formControlName="moveCompletedChecklistItemsToTheBottom"
                 >
                  <span>Move completed checklist items to the
                    bottom</span>
                </label>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </nz-card>
    </form>

  </div>

  <ng-template #toDosFooter>
    <button nz-button nzType="primary" [nzLoading]="isSaving"
      (click)="saveButton()">Save</button>
  </ng-template>
</nz-modal>




<!-- <div class="card border-0 mt-3">
  <div class="card-head border-bottom">
    <div class="row py-2">
        <div class="col-6">
      <h3 nz-typography><span class="ms-3">To-Do's Custom Fields</span></h3>
        </div>
        <div class="col-6 text-end">
          <button   nz-button  class="button-Daily2 me-2">Add New Field</button>
        </div>
    </div>
</div>
  <div class="card-body">
  <div class="row">
    <div class="col-12">
      <div class="row">
        <div class="col-12 d-flex alighn-item-center justify-content-center">
          <svg class="ant-empty-img-simple" width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg"><g transform="translate(0 1)" fill="none" fill-rule="evenodd"><ellipse class="ant-empty-img-simple-ellipse" cx="32" cy="33" rx="32" ry="7"></ellipse><g class="ant-empty-img-simple-g" fill-rule="nonzero"><path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path><path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" class="ant-empty-img-simple-path"></path></g></g></svg>
        </div>
      </div>
      <div class="row">
        <div class="col-12 d-flex alighn-item-center justify-content-center">
      <span class="ant-empty-normal">No Data</span>
        </div>
      </div>
    </div>
  </div>
  </div>
</div> -->
