<nz-modal [(nzVisible)]="isVisibleInClockIn" [nzTitle]="modalTitleInClockIn" [nzFooter]="modalFooterInClockIn" [nzBodyStyle]="{
  background: '#f1f4fa',
  padding: '14px',
  maxHeight: '69vh',
  'overflow-y': 'auto',
  'overflow-x': 'hidden',
}" [nzStyle]="{ top: '25px'}" [nzWidth]="'68%'" (nzOnCancel)="CancelClockIn()">
  <ng-template #modalTitleInClockIn>
    <div class="row">
      <h2 class="p-0 m-0 famly-inn ant-typography-Modal">Shift</h2>
    </div>
  </ng-template>
    <div class="content" *nzModalContent>
      <nz-alert class="rounded-3 mb-3 mt-0 error-message" *ngIf="isSubmitted && errorFields.length > 0" nzType="error" nzShowIcon [nzMessage]="message" [nzDescription]="errorDescriptionTemplate"></nz-alert>
      <ng-template #message>
        <p class="mt-1">Please correct the following fields:</p>
      </ng-template>
      <ng-template #errorDescriptionTemplate>
        <ul *ngFor="let error of errorFields" class="m-0">
          <li class="famly-inn p-0 m-0 ">
            <span class="ms-0">{{ error.label }}:</span>
            <span class="ms-1">{{ error.message }}</span>
          </li>
        </ul>
      </ng-template>

      <div class="card" style="background-color: #fffbef;border: 1px solid #efcf61;" *ngIf="ITimeClockShiftGetByIdRes?.StatusSetupId === 671">
        <div nz-row class="cost-ant-alert">
          <div nz-col nzSpan="24">
            <span class="ms-2" style="vertical-align: sub;">This shift has been approved and is locked for editing. To make changes, click the Reset Approval button.</span>
          </div>
        </div>
      </div>
      <div class="card border-0" [ngClass]="{'Active': ITimeClockShiftGetByIdRes?.StatusSetupId === 671}">
        <div class="card-head border-bottom">
          <div class="row pt-2 pb-2">
            <h2 class="ms-3 famly-inn ant-typography-crad-Header">Shift Details</h2>
          </div>
        </div>
        <div class="card-body">
          <div nz-row class="padding-remove">
            <div nz-col [nzXs]="12">
                <label class="sub-text">Job<span class="ms-1 text-danger text-bold">*</span></label>
                <ng-container *ngIf="_OnITimeClockShiftGetById?.StatusSetupId === 671; else _jobInformationId">
                  <div nz-row>
                    <div nz-col [nzXs]="24">
                      <span class="ant-tag">{{ _OnITimeClockShiftGetById?.JobName }}</span>
                    </div>
                  </div>
                </ng-container>
                <ng-template #_jobInformationId>
                  <nz-form-item>
                    <nz-form-control [nzErrorTip]="validationMessages['jobInformationId']" [nzValidateStatus]="validationStates['jobInformationId']">
                      <nz-select
                        (ngModelChange)="onFieldChange('jobInformationId', $event)"
                        nzShowSearch
                        [(ngModel)]="timeClockFormFields.jobInformationId" name="jobInformationId"
                        id="jobInformationId"
                        name="jobInformationId"
                      >
                      <ng-container *ngIf="onJob_Id === null">
                        <nz-option nzLabel="-- Please Select a Job --" [nzValue]="0"></nz-option>
                      </ng-container>
                      <nz-option *ngFor="let option of jobList" [nzLabel]="option.name" [nzValue]="option.id"></nz-option>
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                </ng-template>
                <!-- <ng-container *ngIf="ITimeClock_ClockInRes?.StatusSetupId === 671">
                  <div nz-row>
                    <div nz-col [nzXs]="24">
                      <span>{{ ITimeClock_ClockInRes?.JobName }}</span>
                    </div>
                  </div>
                </ng-container> -->
            </div>
          </div>
          <div nz-row>
            <div nz-col  nzSpan="12">
              <label class="sub-text">User<span class="ms-1 text-danger text-bold">*</span></label>
             </div>
          </div> 
          <div nz-row>
            <div nz-col  nzSpan="12">
              <ng-container *ngIf="ITimeClockShiftGetByIdRes?.AssingeeUserName?.length > 0; else AssingeeUserName">
                <div nz-row>
                  <div nz-col [nzXs]="12">
                    <label class="sub-text famly-inn" style="font-size: 14px;font-weight: 500;">{{ ITimeClockShiftGetByIdRes?.AssingeeUserName }}</label>
                  </div>
                </div>
              </ng-container>
              <ng-template #AssingeeUserName>
                <nz-form-item>
                  <nz-form-control [nzErrorTip]="validationMessages['applicationUserIds']" [nzValidateStatus]="validationStates['applicationUserIds']">
                    <nz-tree-select
                    [(ngModel)]="timeClockFormFields.applicationUserIds" name="applicationUserIds"
                    [nzNodes]="UserNodes"
                    style="width: 100%"
                    [nzShowSearch]="true"
                    [nzAllowClear]="true"
                    (ngModelChange)="onFieldChange('applicationUserIds', $event)"
                    nzCheckable>
                    </nz-tree-select>
                  </nz-form-control>
                </nz-form-item>
              </ng-template>
             </div>
          </div> 
          <div nz-row>
            <div nz-col [nzXs]="4">
              <label class="sub-text">Start Time</label>
            </div>
            <div nz-col [nzXs]="4">
              <label class="sub-text">Start Date</label>
            </div>
          </div>
          <div nz-row [nzGutter]="12" class="padding-remove">
            <div nz-col nzSpan="4">
              <ng-container *ngIf="_OnITimeClockShiftGetById?.StatusSetupId === 671; else _StartTime">
                <div nz-row>
                  <div nz-col>
                    <span>{{ _OnITimeClockShiftGetById?.StartTime | date: 'h:mm a' }}</span>
                  </div>
                </div>
              </ng-container>
              <ng-template #_StartTime>
                <nz-form-item>
                  <nz-form-control [nzErrorTip]="validationMessages['startTime']" [nzValidateStatus]="validationStates['startTime']">
                    <nz-time-picker
                      [(ngModel)]="timeClockFormFields.startTime"
                      name="startTime"
                      [nzFormat]="'hh:mm a'" 
                      [nzUse12Hours]="true" 
                      nzPlaceHolder=" "
                      (ngModelChange)="onStartTimeChange($event)"
                    ></nz-time-picker>
                  </nz-form-control>
                </nz-form-item>
              </ng-template>
            </div>
            <div nz-col nzSpan="4">
              <ng-container *ngIf="_OnITimeClockShiftGetById?.StatusSetupId === 671; else _StartOn">
                <div nz-row>
                  <div nz-col>
                    <span>{{ _OnITimeClockShiftGetById?.StartOn | date: 'MMMM d, y'  }}</span>
                  </div>
                </div>
              </ng-container>
              <ng-template #_StartOn>
                <nz-form-item>
                  <nz-form-control [nzErrorTip]="validationMessages['startOn']" [nzValidateStatus]="validationStates['startOn']">
                    <nz-date-picker
                      [(ngModel)]="timeClockFormFields.startOn" name="startOn"
                      nzSuffixIcon="false"
                      [nzFormat]="'MMM d, YYYY'"
                      nzPlaceHolder=" "
                      autocomplete="off"
                      #datePicker
                      [nzShowToday]="false"
                      [nzAllowClear]="false">
                    </nz-date-picker>
                  </nz-form-control>
                </nz-form-item>
              </ng-template>
            </div>
          </div>
          <div nz-row class="pt-1" *ngIf="_OnITimeClockShiftGetById?.StartOnModification">
            <div nz-col>
              <span nz-icon nzType="edit" nzTheme="fill"></span>
            </div>
            <div nz-col>
              <span style="font-size: 12px; color: rgb(78, 85, 95);">{{ _OnITimeClockShiftGetById?.StartOnModification }}</span>
            </div>
          </div>
          <div nz-row>
            <div nz-col [nzXs]="4">
              <label class="sub-text">End Time</label>
            </div>
            <div nz-col [nzXs]="4">
              <label class="sub-text">End Date</label>
            </div>
          </div>
          <div nz-row [nzGutter]="12" class="padding-remove">
            <div nz-col [nzXs]="4">
              <ng-container *ngIf="_OnITimeClockShiftGetById?.StatusSetupId === 671; else _EndTime">
                <div nz-row>
                  <div nz-col>
                    <span>{{ _OnITimeClockShiftGetById?.EndTime | date: 'h:mm a' }}</span>
                  </div>
                </div>
              </ng-container>
              <ng-template #_EndTime>
                <nz-form-item>
                  <nz-form-control [nzValidateStatus]="endTimeError ? 'error' : null">
                    <nz-time-picker
                    [(ngModel)]="timeClockFormFields.endTime"
                    name="endTime"
                    [nzFormat]="'hh:mm a'" 
                    [nzUse12Hours]="true" 
                    (ngModelChange)="onEndTimeChange($event)"
                    nzPlaceHolder=" "
                  ></nz-time-picker>
                  </nz-form-control>
                </nz-form-item>
              </ng-template>
            </div>
            <div nz-col [nzXs]="4">
              <ng-container *ngIf="_OnITimeClockShiftGetById?.StatusSetupId === 671; else _endOn">
                <div nz-row>
                  <div nz-col>
                    <span>{{ _OnITimeClockShiftGetById?.EndOn | date: 'h:mm a' }}</span>
                  </div>
                </div>
              </ng-container>
              <ng-template #_endOn >
                <nz-form-item>
                  <nz-form-control [nzValidateStatus]="endTimeError ? 'error' : null">
                    <nz-date-picker [(ngModel)]="timeClockFormFields.endOn" nzSuffixIcon="false" [nzFormat]="'MMM d, YYYY'" nzPlaceHolder=" " autocomplete="off" (ngModelChange)="onEndDateChange($event)"
                    ></nz-date-picker>
                  </nz-form-control>
                </nz-form-item>
              </ng-template>
            </div>
          </div>
          <div nz-row class="pt-1" *ngIf="_OnITimeClockShiftGetById?.EndOnModification">
            <div nz-col>
              <span nz-icon nzType="edit" nzTheme="fill"></span>
            </div>
            <div nz-col>
              <span style="font-size: 12px; color: rgb(78, 85, 95);">{{ _OnITimeClockShiftGetById?.EndOnModification }}</span>
            </div>
          </div>
          <div nz-row *ngIf="endTimeError === true">
            <div nz-col nzSpan="8">
              <div class="alert">Must be after start time and date</div>
            </div>
          </div>
          <div nz-row class="pt-2">
            <div nz-col [nzXs]="12" class="shift-total-row">
              <h2 class="famly-inn ant-typography-crad-Header p-0 m-0">Shift Total</h2>
              <span>{{ totalHours }} hours</span>
              <span>{{ totalMinutes}} minutes</span>
              <!-- <span>({{ fractionalHours }} hours)</span> -->
              <span *ngIf="dateDifference > 0">Date Difference: {{ dateDifference }} days</span>
            </div>
          </div>
        </div>
      </div>
      <div class="card border-0 mt-3">
        <div class="card-head border-bottom">
          <div class="row pt-2 pb-2">
            <h2 class="ms-3 famly-inn ant-typography-crad-Header">Shift Details</h2>
          </div>
        </div>
        <div class="card-body ant-tabs-wrap">
          <nz-tabset nzType="card">
            <nz-tab *ngFor="let tab of tabs" [nzTitle]="innerCardTabs(tab)" style="background-color: #f1f4fa;">
              <ng-container *ngIf="tab === 1">
                <div nz-row>
                  <div nz-col nzSpan="12">
                    <label class="sub-text">Tags</label>
                  </div>
                </div>
                <div nz-row>
                  <div nz-col nzSpan="9">
                    <nz-tree-select
                      [(ngModel)]="timeClockFormFields.createShiftTagParameterRequests"
                      name="createShiftTagParameterRequests"
                      [nzNodes]="TagNodes"
                      style="width: 100%"
                      [nzShowSearch]="true"
                      [nzAllowClear]="true"
                      nzCheckable>
                    </nz-tree-select>
                  </div>
                  <div nz-col nzSpan="1" class="d-flex justify-content-end align-items-center">
                    <span class="click-btn famly-inn" (click)="TimeClockTagOpen(null, 'TimeClockAdd')">Add</span>
                  </div>
                  <div nz-col nzSpan="1" class="d-flex justify-content-end align-items-center">
                    <span
                      [class.disabled]="isEditButtonDisabled()"
                      class="click-btn famly-inn"
                      (click)="!isEditButtonDisabled() && TimeClockTagOpen(timeClockFormFields.createShiftTagParameterRequests, 'TimeClockEdit')">
                      Edit
                    </span>
                  </div>
                </div>
                <div nz-row>
                  <div nz-col  nzSpan="16">
                      <label class="sub-text">Notes</label>
                      <textarea [(ngModel)]="timeClockFormFields.notes" name="notes" nz-input [nzAutosize]="{ minRows: 2, maxRows: 10 }" class="formField" style="resize: none;"></textarea>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="tab === 2">
                <div nz-row [nzGutter]="12">
                  <div nz-col [nzXs]="4">
                    <label class="sub-text">Shift Type</label>
                  </div>
                  <div nz-col [nzXs]="4">
                    <label class="sub-text">Hours</label>
                  </div>
                  <div nz-col [nzXs]="4">
                    <label class="sub-text">Minutes</label>
                  </div>
                </div>
                <div nz-row [nzGutter]="12">
                  <div nz-col nz-col nzSpan="4">
                    <nz-select nzShowSearch [(ngModel)]="timeClockFormFields.shiftTypeSetupId" name="shiftTypeSetupId" (ngModelChange)="onshiftTypeChange($event)"> 
                      <nz-option *ngFor="let option of shiftTypeOp" [nzLabel]="option.name" [nzValue]="option.id"></nz-option>
                    </nz-select>
                  </div>
                  <div nz-col nz-col nzSpan="4">
                    <nz-input-number
                    [(ngModel)]="timeClockFormFields.shiftHours"
                    name="shiftHours"
                    style="width: 100%; border-radius: 4px;"
                    [nzDisabled]="onShiftType || ITimeClockShiftGetByIdRes?.StatusSetupId === 671"
                  ></nz-input-number>
                  </div>
                  <div nz-col nz-col nzSpan="4">
                    <div nz-row>
                      <div nz-col nzSpan="2">
                        <span>:</span>
                      </div>
                      <div nz-col nzSpan="22">
                        <nz-input-number [(ngModel)]="timeClockFormFields.shiftMinutes" name="shiftMinutes" style="width: 100%; border-radius: 4px;" [nzDisabled]="onShiftType || ITimeClockShiftGetByIdRes?.StatusSetupId === 671"></nz-input-number>
                      </div>
                    </div>
                  </div>
                  <div nz-col nzSpan="4" class="d-flex justify-content-start align-content-center">
                    <!-- <span class="famly-inn"><span>({{ timeClockFormFields.shiftHours }} hours)</span></span> -->
                  </div>
                </div>
                <ng-container *ngIf="timeClockFormFields.shiftTypeSetupId !== 10045">
                  <div nz-row [nzGutter]="12" class="pt-2" *ngIf="ITimeClockSetupResponse?.isShowOvertime === true">
                    <div nz-col nz-col nzSpan="4">
                      <h2 class="famly-inn ant-typography-crad-Header" style="font-size: 14px;padding-top: 6px;">Overtime</h2>
                    </div>
                    <div nz-col nz-col nzSpan="4">
                      <nz-input-number [nzDisabled]="ITimeClockShiftGetByIdRes?.StatusSetupId === 671" [(ngModel)]="timeClockFormFields.overTimeHours" name="overTimeHours" style="width: 100%; border-radius: 4px;"></nz-input-number>
                    </div>
                    <div nz-col nz-col nzSpan="4">
                      <div nz-row>
                        <div nz-col nzSpan="2">
                          <span>:</span>
                        </div>
                        <div nz-col nzSpan="22">
                          <nz-input-number [nzDisabled]="ITimeClockShiftGetByIdRes?.StatusSetupId === 671" [(ngModel)]="timeClockFormFields.overTimeMinutes" name="overTimeMinutes" style="width: 100%; border-radius: 4px;"></nz-input-number>
                        </div>
                      </div>
                    </div>
                    <div nz-col nzSpan="4" class="d-flex justify-content-start align-content-center">
                      <!-- <span class="famly-inn">({{ timeClockFormFields.overTimeHours }} hours)</span> -->
                    </div>
                  </div>
                  <div nz-row [nzGutter]="12" class="pt-2" *ngIf="ITimeClockSetupResponse?.isShowDoubleOvertime === true">
                    <div nz-col nz-col nzSpan="4">
                      <h2 class="famly-inn ant-typography-crad-Header" style="font-size: 14px;padding-top: 6px;">Double Overtime</h2>
                    </div>
                    <div nz-col nz-col nzSpan="4">
                      <nz-input-number [nzDisabled]="ITimeClockShiftGetByIdRes?.StatusSetupId === 671" [(ngModel)]="timeClockFormFields.doubleOvertimeHours" name="doubleOvertimeHours" style="width: 100%; border-radius: 4px;"></nz-input-number>
                    </div>
                    <div nz-col nz-col nzSpan="4">
                      <div nz-row>
                        <div nz-col nzSpan="2">
                          <span>:</span>
                        </div>
                        <div nz-col nzSpan="22">
                          <nz-input-number [nzDisabled]="ITimeClockShiftGetByIdRes?.StatusSetupId === 671" [(ngModel)]="timeClockFormFields.doubleOvertimeMinutes" name="doubleOvertimeMinutes" style="width: 100%; border-radius: 4px;"></nz-input-number>
                        </div>
                      </div>
                    </div>
                    <div nz-col nzSpan="4" class="d-flex justify-content-start align-content-center">
                      <!-- <span class="famly-inn">({{ timeClockFormFields.doubleOvertimeHours }} hours)</span> -->
                    </div>
                  </div>
                </ng-container>
                <nz-divider></nz-divider>
                <div nz-row>
                  <div nz-col>
                    <h2 class="famly-inn ant-typography-crad-Header" style="font-size: 14px">Total</h2>
                  </div>
                  <div nz-col [nzOffset]="5" class="shift-total-row">
                    <span>{{ totalHours }} hours</span>
                    <span>{{ totalMinutes }} minutes</span>
                    <!-- <span>({{ fractionalHours }} hours)</span> -->
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="tab === 3">
                <div class="pt-3" *ngFor="let breakItem of items; let i = index">
                  <div nz-row class="padding-remove">
                    <div nz-col nzSpan="10">
                      <div nz-row [nzGutter]="12">
                        <div nz-col nzSpan="10">
                          <nz-form-item>
                            <nz-form-control [nzValidateStatus]="BreakStartTime ? 'error' : null">
                              <nz-time-picker
                                [(ngModel)]="breakItem.breakStartTime"
                                name="breakStartTime"
                                [nzFormat]="'hh:mm a'" 
                                [nzUse12Hours]="true" 
                                (ngModelChange)="onBreakStartTimeChange($event)"
                              ></nz-time-picker>
                            </nz-form-control>
                          </nz-form-item>
                        </div>
                        <div nz-col nzSpan="10">
                          <nz-form-item>
                            <nz-form-control [nzValidateStatus]="BreakStartTime ? 'error' : null">
                              <nz-date-picker
                                [(ngModel)]="breakItem.breakStartOn"
                                nzSuffixIcon="false"
                                [nzFormat]="'MMM d, YYYY'"
                                nzPlaceHolder=" "
                                (ngModelChange)="getBreakDuration($event, breakItem.breakStartTime)"
                                autocomplete="off">
                              </nz-date-picker>
                            </nz-form-control>
                          </nz-form-item>
                        </div>
                      </div>
                      <div *ngIf="BreakStartTime" class="text-danger">
                        Break start must be within the shift times
                      </div>
                      <!-- <div *ngIf="BreakEndTime" class="text-danger">
                        Break start must be before break end
                      </div> -->
                    </div>
                    <div nz-col nzSpan="10">
                      <div nz-row [nzGutter]="12">
                        <div nz-col nzSpan="10">
                          <nz-form-item>
                            <nz-form-control [nzValidateStatus]="BreakStartTime ? 'error' : null">
                              <nz-time-picker
                              [(ngModel)]="breakItem.breakEndTime"
                              name="breakStartTime"
                              [nzFormat]="'hh:mm a'" 
                              [nzUse12Hours]="true" 
                              (ngModelChange)="onBreakEndTimeChange($event)"
                            ></nz-time-picker>
                            </nz-form-control>
                          </nz-form-item>
                        </div>
                        <div nz-col nzSpan="10">
                          <nz-form-item>
                            <nz-form-control [nzValidateStatus]="BreakStartTime ? 'error' : null">
                              <nz-date-picker
                                [(ngModel)]="breakItem.breakEndOn"
                                nzSuffixIcon="false"
                                [nzFormat]="'MMM d, YYYY'"
                                nzPlaceHolder=" "
                                autocomplete="off">
                              </nz-date-picker>
                            </nz-form-control>
                          </nz-form-item>
                        </div>
                      </div>
                    </div>
                    <div nz-col nzSpan="4" class="d-flex justify-content-end align-content-center">
                      <span style="font-size: 20px;" nz-icon nzType="delete" class="click-btn" nzTheme="fill" (click)="deleteItem(i)"></span>
                    </div>
                  </div>
                </div>
                <div nz-row class="pt-2">
                  <!-- <button nz-button nzType="primary" (click)="addBreak()" type="button">
                    <span class="BTN-Font-Famliy">Add Break</span>
                  </button> -->
                  <button [ngClass]="{'no-drop': OnAdBreak === false}" nz-button nzType="primary" (click)="OnAdBreak === true && addBreak()" type="button">
                    <span class="BTN-Font-Famliy">Add Break</span>
                  </button>
                </div>
                <ng-container *ngIf="this.items?.length > 0">
                  <nz-divider></nz-divider>
                  <div nz-row>
                    <div nz-col>
                      <h2 class="famly-inn ant-typography-crad-Header" style="font-size: 14px">Break Total</h2>
                    </div>
                    <div nz-col [nzOffset]="5" class="shift-total-row">
                      <span>0 hours</span>
                      <span>0 minutes</span>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="tab === 4">
                <div class="card" style="background-color: #fafdff;border: 1px solid #a8daff;" *ngIf="totalHours >= ITimeClockSetupResponse?.afterNoOfHoursOverTimeDaily">
                  <div nz-row class="cost-ant-alert">
                    <div nz-col nzSpan="24">
                      <span style="color: #0763fb;" nz-icon nzType="info-circle" nzTheme="fill"></span>
                      <span class="ms-2" style="vertical-align: sub;">There is overtime associated with this shift.</span>
                    </div>
                  </div>
                </div>
                <div nz-row [nzGutter]="12">
                  <div nz-col nzSpan="12">
                    <div nz-row>
                      <div nz-col [nzXs]="24">
                          <label class="sub-text">Cost Code</label>
                      </div>
                    </div>
                  </div>
                  <ng-container *ngIf="OnAdBreak=== true">
                    <div nz-col nzSpan="4">
                      <div nz-row>
                        <div nz-col [nzXs]="24">
                            <label class="sub-text">Rate</label>
                        </div>
                      </div>
                    </div>
                    <div nz-col nzSpan="3">
                      <div nz-row>
                        <div nz-col [nzXs]="24">
                            <label class="sub-text">Hours</label>
                          </div>
                      </div>
                    </div>
                    <div nz-col nzSpan="3">
                      <div nz-row>
                        <div nz-col [nzXs]="24">
                            <label class="sub-text">Builder Cost</label>
                        </div>
                      </div>
                    </div>
                    <div nz-col nzSpan="2">
                      <div nz-row>
                        <div nz-col [nzXs]="24">
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
                <div nz-row [nzGutter]="12">
                  <div nz-col nzSpan="12">
                    <ng-container *ngIf=" _OnITimeClockShiftGetById?.StatusSetupId === 671; else _costCodeId">
                      <div nz-row>
                        <div nz-col [nzXs]="24">
                          <span>{{ _OnITimeClockShiftGetById?.CostCodeName }}</span>
                        </div>
                      </div>
                    </ng-container>
                    <ng-template #_costCodeId>
                      <div nz-row>
                        <div nz-col [nzXs]="24">
                            <nz-select nzShowSearch [(ngModel)]="timeClockFormFields.costCodeId" name="costCodeId" (ngModelChange)="onCostCodeIdChange($event)">
                                <nz-option *ngFor="let option of CostCodeList" [nzLabel]="option.title" [nzValue]="option.id"></nz-option>
                            </nz-select>
                        </div>
                      </div>
                    </ng-template>
                  </div>
                  <ng-container *ngIf="OnAdBreak=== true">
                    <div nz-col nzSpan="4">
                      <div nz-row>
                        <div nz-col [nzXs]="24">
                            <nz-input-group nzAddOnBefore="%">
                              <input
                                [(ngModel)]="timeClockFormFields.rate" name="rate"
                                nz-input
                                class="formField"
                              />
                            </nz-input-group>
                        </div>
                      </div>
                    </div>
                    <div nz-col nzSpan="3">
                      <div nz-row>
                        <div nz-col [nzXs]="24">
                          <div>{{ timeDifference?.hours }} </div>
                        </div>
                      </div>
                    </div>
                    <div nz-col nzSpan="3">
                      <div nz-row>
                        <div nz-col [nzXs]="24">
                          {{ onBuilderCost }}
                        </div>
                      </div>
                    </div>
                    <div nz-col nzSpan="2">
                      <div nz-row>
                        <div nz-col [nzXs]="24">
                          <span class="anticon click-btn">
                            <svg viewBox="64 64 896 896" focusable="false" data-icon="fork" width="1em" height="1em" fill="currentColor" aria-hidden="true" style="transform: rotate(90deg);"><path d="M752 100c-61.8 0-112 50.2-112 112 0 47.7 29.9 88.5 72 104.6v27.6L512 601.4 312 344.2v-27.6c42.1-16.1 72-56.9 72-104.6 0-61.8-50.2-112-112-112s-112 50.2-112 112c0 50.6 33.8 93.5 80 107.3v34.4c0 9.7 3.3 19.3 9.3 27L476 672.3v33.6c-44.2 15-76 56.9-76 106.1 0 61.8 50.2 112 112 112s112-50.2 112-112c0-49.2-31.8-91-76-106.1v-33.6l226.7-291.6c6-7.7 9.3-17.3 9.3-27v-34.4c46.2-13.8 80-56.7 80-107.3 0-61.8-50.2-112-112-112zM224 212a48.01 48.01 0 0196 0 48.01 48.01 0 01-96 0zm336 600a48.01 48.01 0 01-96 0 48.01 48.01 0 0196 0zm192-552a48.01 48.01 0 010-96 48.01 48.01 0 010 96z"></path></svg>
                          </span>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
                <div nz-row *ngIf="_OnITimeClockShiftGetById?.StatusSetupId !== 671">
                  <div nz-col nzSpan="1">
                    <span class="ms-2 click-btn famly-inn" (click)="costCodeOpen(null)">Add</span>
                  </div>
                  <div nz-col nzSpan="2" *ngIf="timeClockFormFields.rate">
                    <span
                      [class.disabled]="isEditButtonDisabledFormCostCode()"
                      class="click-btn famly-inn"
                      >
                      <!-- (click)="!isEditButtonDisabledFormCostCode() && costCodeOpen(timeClockFormFields.costCodeId)" -->
                      Edit
                    </span>
                  </div>
                </div>
              </ng-container>
            </nz-tab>
          </nz-tabset>
        </div>
      </div>
    </div>
    <ng-template #modalFooterInClockIn>
      <div nz-row class="p-1 justify-content-end">
        <div nz-col nzSpan="24">
          <nz-space>
            <ng-container *nzSpaceItem>
              <button
                nz-button
                nzType="default"
                class="align-items-center"
                *ngIf="ITimeClockShiftGetByIdRes !== undefined"
                [nzLoading]="isDeleteLoading"
                 (click)="deleteJobConfirm()"
                [disabled]="DisabledNextActivity"
                [class.disabled]="DisabledNextActivity"
                >
                Delete
              </button>
            </ng-container>
            <ng-container *nzSpaceItem>
              <button
                *ngIf="ITimeClockShiftGetByIdRes?.StatusSetupId === 671"
                nz-button
                nzType="default"
                class="align-items-center"
                [nzLoading]="isResetLoading"
                 (click)="shiftResetConfirm()"
                [disabled]="DisabledNextActivity"
                [class.disabled]="DisabledNextActivity"
              >
              Reset Approval
              </button>
            </ng-container>
            <ng-container *nzSpaceItem>
              <button
                *ngIf="ITimeClockShiftGetByIdRes?.EndTime && ITimeClockShiftGetByIdRes?.StatusSetupId !== 671"
                nz-button
                nzType="primary"
                class="align-items-center"
                (click)="shiftApprovalConfirm()"
                [nzLoading]="isSaveAndApprove"
                [disabled]="DisabledNextActivity"
                [class.disabled]="DisabledNextActivity">
                Save & Approve
              </button>
            </ng-container>
            <ng-container *nzSpaceItem>
              <button
                nz-button
                nzType="primary"
                class="align-items-center"
                (click)="saveShift()"
                [nzLoading]="isSave"
                [disabled]="DisabledNextActivity"
                [class.disabled]="DisabledNextActivity">
                Save
              </button>
            </ng-container>
            <ng-container *nzSpaceItem>
              <button
                nz-button
                nzType="primary"
                class="align-items-center"
                (click)="saveShiftAndClose()"
                [nzLoading]="isSaveAndClose"
                [disabled]="DisabledNextActivity"
                [class.disabled]="DisabledNextActivity">
                Save & Close
              </button>
            </ng-container>
          </nz-space>
        </div>
      </div>
    </ng-template>
</nz-modal>
