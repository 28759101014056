import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NzStatus } from 'ng-zorro-antd/core/types';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { CostCodeCategoryResponse } from 'src/Models/InternalUser/applicationUserResponse';
import { CostItemParameterResponse, CostItemResponse, LeadProposalResponse } from 'src/Models/LeadProposal/LeadProposalModels';
import { LookupNameSetupResponse } from 'src/Models/LeadTags';
import { CostCodeService } from 'src/Service/Internaluser/cost-code.service';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { LeadProposalsService } from 'src/Service/LeadProp/lead-proposals.service';
import { LeadProposalsFService } from 'src/Service/Sales-lead-proposals-F/lead-proposals-f.service';
import { LeadProposalsPreDefinedCodes } from 'src/app/shared/component/Models/LookUpStandardFilterCodes';

@Component({
  selector: 'estimated-cost',
  templateUrl: './estimated-cost.component.html',
  styleUrls: ['./estimated-cost.component.css'],
})
export class EstimatedCostComponent implements OnInit {
  @Output() cancel = new EventEmitter<void>();
  @Output() leadProposalSaveResponse = new EventEmitter<LeadProposalResponse>();
  @Output() onSaveEstimatedCostComplete = new EventEmitter<any>();
  @Output() onUpdateEstimatedCostComplete = new EventEmitter();
  @Output() getProposalItemData: CostItemParameterResponse;
  CostInformation: boolean = true;
  includeItemInCatalog: boolean = false;
  leadOptions: CostCodeCategoryResponse[] = [];
  leadEstimatedCode: CostItemResponse;
  markupOption: LookupNameSetupResponse[] = [];
  proposalOption: LookupNameSetupResponse[] = [];
  markAsOption: LookupNameSetupResponse[] = [];
  initialFormFieldValues: any = {};
  modalErrorMessages: string[];

  proposalConformCardShow = false;
  confirmModal?: NzModalRef;


  formFieldValues: any = {};
  isSubmitted: boolean = false;

  preDefinedDataCodes = {
    costType: LeadProposalsPreDefinedCodes.CostType,
    markup: LeadProposalsPreDefinedCodes.Markup,
    markAs: LeadProposalsPreDefinedCodes.MarkAs,
  };

  constructor(
    private leadproposalsserv: LeadProposalsService,
    private lookupFilterService: LookupFilterService,
    private toastService: NzMessageService,
    public leadProposalsFService: LeadProposalsFService,
    private EstimateService :CostCodeService,
    private modal: NzModalService,

  ) { }



  ngOnInit(): void {
    this.leadProFormGroup();
    this.getLeadData();
    this.initLoad();
    this.setInitialFormFieldValues(); // Save initial values of the form
    if (this.getProposalItemData) {
      console.log('edit data', this.getProposalItemData);
      this.formFieldValues = { ...this.formFieldValues, ...this.getProposalItemData };
      this.updateBuilderCost(); // Recalculate totals based on the initial data
    }
  }

  getLeadData() {
    this.leadproposalsserv.getAllLeadData().subscribe(data => {
      this.leadOptions = data.result;
    });
  }



  initLoad() {
    this.lookupFilterService.getLookUpValuesByFormNameId(37).subscribe(res => {
      let proposalStatus = res.result.filter(x => x.code === this.preDefinedDataCodes.costType.code);
      let markupValues = res.result.filter((x) => x.code === this.preDefinedDataCodes.markup.code);
      let markAsOptionValues = res.result.filter(x => x.code === this.preDefinedDataCodes.markAs.code);
      this.proposalOption = proposalStatus;
      this.markAsOption = markAsOptionValues;
      this.markupOption = markupValues;
    });
  }


  totalOwnerPrice: number = 0;
  totalBuilderCost: number = 0;
  estimatedProfit: number = 0;

  leadProFormGroup(): void {
    this.formFieldValues = {
      globalId: '00000000-0000-0000-0000-000000000000',
      title: '',
      costItemId: 0,
      categoryId: 0,
      costCodeId: 0,
      description: '',
      unit: '',
      markupId: 40417,
      internalNotes: '',
      includeQuantity: false,
      includeOwnerPrice: false,
      isCatalog: false,
      costTypeId: 0,
      groupId: 0,
      markAsId: 0,
      quantity: 1,
      unitCost: '0.00', // Ensure unitCost is a number
      builderCost: 0,
      markupValue: '0.00', // Ensure markupValue is a string
      ownerPrice: 0,
      margin: '0.00',
      profit: 0,
    };
  }



  updateTotals(): void {
    this.totalOwnerPrice = this.formFieldValues.ownerPrice;
    this.totalBuilderCost = this.formFieldValues.builderCost;
    this.estimatedProfit = this.formFieldValues.profit;
  }


  onMarkupChange(selectedValue: number): void {
    const currentMarkupValue = parseFloat(this.formFieldValues.markupValue) || 0;
    const builderCost = parseFloat(this.formFieldValues.builderCost) || 1;

    if (selectedValue === 40417 && (this.formFieldValues.markupId === 40418 || this.formFieldValues.markupId === 40419)) {
      // Converting from dollar to percentage
      this.formFieldValues.markupValue = ((currentMarkupValue / builderCost) * 100).toFixed(2);
    } else if ((selectedValue === 40418 || selectedValue === 40419) && this.formFieldValues.markupId === 40417) {
      // Converting from percentage to dollar
      this.formFieldValues.markupValue = ((builderCost * currentMarkupValue) / 100).toFixed(2);
    }

    this.formFieldValues.markupId = selectedValue;
    this.updateBuilderCost();
  }

  updateBuilderCost(): void {
    const unitCost = parseFloat(this.formFieldValues.unitCost) || 0;
    const quantity = this.formFieldValues.quantity || 1;
    const markupValue = parseFloat(this.formFieldValues.markupValue) || 0;

    this.formFieldValues.builderCost = unitCost * quantity;

    if (this.formFieldValues.markupId === 40417) {
      this.formFieldValues.ownerPrice = this.formFieldValues.builderCost + (this.formFieldValues.builderCost * (markupValue / 100));
    } else if (this.formFieldValues.markupId === 40418 || this.formFieldValues.markupId === 40419) {
      this.formFieldValues.ownerPrice = this.formFieldValues.builderCost + markupValue;
    } else {
      this.formFieldValues.ownerPrice = this.formFieldValues.builderCost;
    }

    this.formFieldValues.margin = this.formFieldValues.ownerPrice > 0
      ? ((this.formFieldValues.ownerPrice - unitCost) / this.formFieldValues.ownerPrice) * 100
      : 0;

    this.formFieldValues.profit = this.formFieldValues.ownerPrice - this.formFieldValues.builderCost;
    if (this.formFieldValues.profit < 0) {
      this.formFieldValues.profit = 0;
    }

    this.updateTotals();
  }

  isSaveAndClose: boolean = false;
  isSaveAndNew: boolean = false;
  isSave = false;
  conformCardShow = false;

  DisabledNextActivity: boolean = false;


  saveButton(actionType: 'save' | 'saveAndClose' | 'saveAndNew' = 'save', setAsNewLead: boolean = false): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.isSave || this.isSaveAndClose || this.isSaveAndNew) {
        return reject('Already processing');
      }
  
      if (actionType === 'saveAndNew') {
        this.isSaveAndNew = true;
      } else if (actionType === 'saveAndClose') {
        this.isSaveAndClose = true;
      } else {
        this.isSave = true;
      }
  
      this.DisabledNextActivity = true;
  
      if (setAsNewLead) {
        this.formFieldValues.asNewLead = true;
      }
  
      this.isSubmitted = true;
      
      // Validate all fields instead of only changed fields
      this.validateAllFields();
      if(this.formFieldValues.isCatalog && !this.formFieldValues.title) {
        this.validationStates['title'] = 'error';
        this.validationMessages['title'] = 'Required'
      } else {
        this.validationStates['title'] = '';  // Reset title validation
        this.validationMessages['title'] = '';   
      }

        

      
  
      if (Object.values(this.validationStates).includes('error')) {
        setTimeout(() => {
          const errorElements = document.querySelectorAll('.top-of-modal');
          if (errorElements.length > 0) {
            const lastErrorElement = errorElements[errorElements.length - 1];
            lastErrorElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        }, 100);
  
        this.resetFlags();
        this.DisabledNextActivity = false;
  
        if (setAsNewLead) {
          this.formFieldValues.asNewLead = false;
        }
  
        return reject('Validation errors present');
      }
  
    
      let data = this.formFieldValues;
      this.onSaveEstimatedCostComplete.emit(data);
  
      this.toastService.success('Saved Successfully');

     
      this.DisabledNextActivity = false;
  
      this.storeInitialFormFieldValues();
  
      this.resetFlags();
      this.conformCardShow = false;
      this.proposalConformCardShow = false;
      this.modalErrorMessages = [];
      this.isSubmitted = false;
  
      if (setAsNewLead) {
        this.formFieldValues.asNewLead = false;
      }
  
      resolve();
    });
  }

  
  resetFlags() {
    this.isSave = false;
    this.isSaveAndClose = false;
    this.isSaveAndNew = false;
  }

  resetForm(): void {
    this.formFieldValues = {}
    this.setInitialFormFieldValues();
    this.validationStates = {};
    this.leadEstimatedCode = null;
    this.modalErrorMessages = [];
    this.isSubmitted = false;
  }

  saveAndCloseCost(): Promise<void> {
    return this.saveButton('saveAndClose').then(() => {
      this.resetForm();
    this.cancel.emit()
      this.CostInformation=false;
    }).catch((error) => {
      console.error('Error during save and close:', error);
    });
  }

  saveAndNewCost() {
    this.saveButton('saveAndNew').then(() => {
      this.resetForm();
    }).catch((error) => {
      console.error('Error during save and new:', error);
    }).finally(() => {
    });
  }


  isFormDirty(): boolean {
    return JSON.stringify(this.formFieldValues) !== JSON.stringify(this.initialFormFieldValues);
  }
  isFormSaved = false; 
  showConfirmCardCost(): void {

    const dontSaveText = 'Dont Save';
  
    if (!this.isFormDirty() || this.isFormSaved) {
      this.resetForm();
      this.close(); 
      return; 
    }
  
    this.confirmModal = this.modal.confirm({
      nzTitle: '<b>Save Changes?</b>',
      nzContent: '<p>This Estimate Cost has unsaved changes. Do you want to save your changes before closing?</p>',
      nzOkText: 'Save',
      nzOkType: 'primary',
      nzCancelText: dontSaveText,
      nzOkLoading: this.isSaveAndClose,
      nzClosable: true,
      nzOnOk: () => {
        this.isSubmitted = true;
        this.validateAllFields();

         
        if (this.hasValidationErrors()) {
          this.confirmModal.destroy();  // Close the confirm modal on error
          return Promise.reject('Validation failed');
        }
        this.confirmModal.destroy();
        this.resetFlags();
        this.isSaveAndClose = true;
        return this.saveAndCloseCost()
          .then(() => {
            this.isSaveAndClose = false;
            this.isFormSaved = true;
                  let data = this.formFieldValues;
      this.onSaveEstimatedCostComplete.emit(data);
            this.cancel.emit()
          })
          .catch((error) => {
            this.isSaveAndClose = false;
            console.error('Error saving lead:', error);
          });
      },
      nzOnCancel: () => {
        const clickedButton = (event.target as HTMLElement).textContent?.trim();
        if (clickedButton === dontSaveText) {
          this.confirmModal.destroy();
          this.resetForm();
          this.close();
        } else {
          this.confirmModal.destroy();
        }
      },
      nzStyle: { top: '250px' },
      nzClassName: 'custom-modal-content',
    });
  }
  

 
 
  hasValidationErrors(): boolean {
    return Object.values(this.validationStates).includes('error');
  }
  
  

  open(): void {
    this.CostInformation = true
  }
  close(): void {
    this.cancel.emit();
  }
  EstimateCostModelCancel(): void {
    this.cancel.emit();
    this.CostInformation = false;
  }


  async storeInitialFormFieldValues(): Promise<void> {
    await new Promise(resolve => setTimeout(() => {
      this.initialFormFieldValues = JSON.parse(JSON.stringify(this.formFieldValues));
      resolve(true);
    }, 0));
  }


  setInitialFormFieldValues(): void {
    this.initialFormFieldValues = JSON.parse(JSON.stringify(this.formFieldValues));
  }





  get errorFields(): { field: string; label: string; message: string }[] {
    return Object.keys(this.validationStates)
      .filter(field => this.validationStates[field] === 'error')
      .map(field => ({
        field,
        label: this.getFieldLabel(field),
        message: this.validationMessages[field]
      }));
  }

  readonly validationRules = {
    title: {
      required: 'Required',
      maxLength: { limit: 150, message: ' Title exceeds the limit.' }
    },
    costCodeId: {
      required: 'CostCode is Required', 
    },
    description: {
      maxLength: { limit: 2500, message: 'Description exceeds the limit.' }
    },
    internalNotes: {
      maxLength: { limit: 2500, message: 'Internel User exceeds the limit.' }
    },
  }


  getFieldLabel(field: string): string {
    const labels: { [key: string]: string } = {
      title: 'Title',
      costCodeId: 'CostCode',
      description: 'Description',
      internalNotes: 'InternalNotes',
    };
    return labels[field] || field;
  }


  // onFieldChange(field: string, value: string) {
  //   this.formFieldValues[field] = value;
    
  //   // Check if form is now dirty (any field is changed)
  //   if (this.isFormDirty()) {
  //     this.isFormSaved = false; // Mark form as not saved
  //   }
  
  //   this.changedFields.add(field);
  //   this.validateChangedFields(field); // validate only the changed field
  // }


  onFieldChange(field: string, value: any) {
    this.formFieldValues[field] = value;
  
    // Check if form is now dirty (any field is changed)
    if (this.isFormDirty()) {
      this.isFormSaved = false; // Mark form as not saved
    }
  
    this.changedFields.add(field);
  
    // Check if the checkbox is checked or unchecked and update the title validation
    if (field === 'isCatalog') {
      if (value) {
        // If checkbox is checked, make the title required
        this.validationStates['title'] = this.formFieldValues.title ? '' : 'error';
        this.validationMessages['title'] = this.formFieldValues.title ? '' : 'Required';
      } else {
        // If checkbox is unchecked, remove the validation from the title
        this.validationStates['title'] = '';
        this.validationMessages['title'] = '';
      }
    }
  
    // If the title field is changed, validate its value
    if (field === 'title') {
      if (this.formFieldValues.isCatalog) {
        this.validationStates['title'] = value ? '' : 'error';
        this.validationMessages['title'] = value ? '' : 'Required';
      }
    }
  
    this.validateChangedFields(field); // validate only the changed field
  }
  
  


  validationStates: { [key: string]: NzStatus } = {};
  validationMessages: { [key: string]: string } = {};
  changedFields: Set<string> = new Set<string>();
  checkErrors(validateAllFields = false) {
    const currentValidationStates = { ...this.validationStates };
    const fieldsToValidate = validateAllFields ? Object.keys(this.validationRules) : Array.from(this.changedFields);
  
    for (const field of fieldsToValidate) {
      if (this.validationRules.hasOwnProperty(field)) {
        const errorTip = this.getErrorTip(field);
        if (errorTip) {
          this.validationStates[field] = 'error';
          this.validationMessages[field] = errorTip;
        } else {
          this.validationStates[field] = null;
          this.validationMessages[field] = '';
        }
      }
    }
  
    this.validationStates = { ...currentValidationStates, ...this.validationStates };
    if (!validateAllFields) {
      this.changedFields.clear();
    }
  }
  

  getErrorTip(field: string): string {
    const rules = this.validationRules[field];
    const input = this.formFieldValues[field] || '';
  
    if (rules.required && !input) {
      return 'Required.'; // For dropdown, check if the value is empty
    }
  
    if (rules.maxLength && input.length > rules.maxLength.limit) {
      const excessLength = input.length - rules.maxLength.limit;
      const unit = excessLength === 1 ? 'character' : 'characters';
      return `${excessLength} ${unit} over.`;
    }
  
    return ''; // No error
  }
  

  validateAllFields() {
    this.checkErrors(true);
  }

  validateChangedFields(field: string) {
    this.checkErrors(); // Only validate the changed field
  }

}






// UpdateEstimatedCode() {
//   const dataSet = this.formFieldValues;
//   this.onSaveEstimatedCostComplete.emit(dataSet);
//   this.toastService.success('Saved Successfully');
//   this.close();
// }




// calculateOwnerPrice(): void {
//   const quantity = this.formFieldValues.quantity;
//   const unitCost = this.formFieldValues.unitCost;
//   const markup = this.formFieldValues.markupValue;

//   if (quantity && unitCost) {
//     // Calculate owner price
//     this.formFieldValues.ownerPrice = unitCost * quantity * (1 + markup / 100);

//     // Calculate margin
//     if (this.formFieldValues.ownerPrice !== 0) {
//       this.formFieldValues.margin = ((this.formFieldValues.ownerPrice - (unitCost * quantity)) / this.formFieldValues.ownerPrice) * 100;
//     } else {
//       this.formFieldValues.margin = 0;
//     }

//     // Calculate profit
//     this.formFieldValues.profit = this.formFieldValues.ownerPrice - (unitCost * quantity);
//   } else {
//     this.formFieldValues.ownerPrice = 0;
//     this.formFieldValues.margin = 0;
//     this.formFieldValues.profit = 0;
//   }
// }


// leadActivityType: any;
// NewCostCodes() {
//   const modalRef = this.modal.create({
//     nzContent: CosCodeModelComponent,
//     nzFooter: null,
//   });
//   modalRef.componentInstance.cancel.subscribe(() => {
//     modalRef.destroy();
//   });
//   modalRef.componentInstance.costCodeonSaveComplete.subscribe(() => {
//     this.getLeadData();
//     this.leadActivitiesService.leadActivityTypeResponse$.subscribe(
//       (response) => {
//         let dataSet = response.result
//         const AftrerleadVariable = dataSet.title;
//         this.formFieldValues.costCodeId = dataSet?.id;
//         this.leadActivityType.push({
//           title: AftrerleadVariable,
//           id: response.result.id,
//         });
//         // // const responseDataSet = response?.result;
//         // const AftrerleadVariable = response?.result?.title;
//         // console.log(AftrerleadVariable);
//         // this.formFieldValues.costCodeId = response?.result?.id;
//         // this.leadActivityType.push(
//         //   title : AftrerleadVariable,
//         //   id: response.result.id
//         // );

//       });
//   })
// }



// leadActivityType123: CostCodeCategoryResponse[] = [];

// editNewCostCodes() {
//   let type = this.formFieldValues['costCodeId'];
//   console.log("type", type);
//   let data = this.leadOptions.find(x => x.id === type);
//   const modalRef = this.modal.create({
//     nzContent: CosCodeModelComponent,
//     nzFooter: null,
//   });
//   modalRef.componentInstance.addLeadActivityTypeResponse = data;
//   modalRef.componentInstance.cancel.subscribe(() => {
//     modalRef.destroy();
//   });
//   modalRef.componentInstance.costCodeonSaveComplete.subscribe(() => {
//     this.getLeadData();
//     this.leadActivitiesService.leadActivityTypeResponse$.subscribe(
//       (response) => {
//         const responseDataSet = response?.result;
//         const AftrerleadVariable = responseDataSet?.title;
//         console.log(AftrerleadVariable);
//         this.formFieldValues.costCodeId = responseDataSet?.id;
//         this.leadActivityType.push({
//           title: AftrerleadVariable,
//           id: response.result.id,
//         });

//       });
//   })
// }

// if (this.getProposalItemData) {
// Update existing data in parent component
// } else {
// Push new data to parent component
//   this.onSaveEstimatedCostComplete.emit(data);
// }
// }

//   const data = this.formFieldValues;
//   const incompleteRows = !data.title || !data.costCodeId;
//   if (incompleteRows) {
//     this.showValidationMessage = true;
//     return;
//   }
//   this.showValidationMessage = false;
//   this.leadproposalsserv.postCostItemLeadProposals(data).subscribe(
//     (costItemResponse) => {
//       if(costItemResponse.result.id != null){
//         this.cancel.emit();
//         this.toastService.success('Saved Successfully');
//       } else {
//           this.toastService.error('Error saving cost item data.');
//       }
//     },
// );

