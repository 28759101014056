<form class="RelatedItemSelector">
    <div class="row mt-4">
      <div class="col-12">
        <label class="sub-text">Type</label>
        <nz-select nzShowSearch [(ngModel)]="RelateItemFormFieldsValue.relatedTypeId" name="relatedTypeId" 
                   (ngModelChange)="FilterForm(RelateItemFormFieldsValue.relatedTypeId)">
          <nz-option *ngFor="let option of realtedTypeList" [nzLabel]="option.name" [nzValue]="option.id" class="p-0 m-0"></nz-option>
        </nz-select>
      </div>
    </div>
  
    <div class="row">
      <div class="col-12">
        <label class="sub-text">Title</label>
        <nz-select nzShowSearch [(ngModel)]="selectedTitle" (ngModelChange)="CancelRelateItem(selectedTitle)"
                   [disabled]="FormTitle.length === 0"
                   name="title">
          <nz-option *ngFor="let option of FormTitle" [nzLabel]="option.name" [nzValue]="option.id" class="p-0 m-0"></nz-option>
        </nz-select>
      </div>
    </div>
  </form>