<nz-modal
  [(nzVisible)]="Taghandle"
  [nzTitle]="NewDaily1"
  [nzFooter]="NewDaily2"
  (nzOnCancel)="TagClose()"
  [nzBodyStyle]="{
  background: '#f1f4fa',
  padding: '14px',
  maxHeight: '70vh',
  'overflow-y': 'auto',
  'overflow-x': 'hidden',
}"
  [nzStyle]="{ top: '25px' }"
  [nzWidth]="'94%'"
>
  <ng-template #NewDaily1>
    <div class="row p-0 m-0">
      <nz-breadcrumb nz-page-header-breadcrumb>
        <nz-breadcrumb-item>{{ onJobInformationWarranties?.name }}</nz-breadcrumb-item>
      </nz-breadcrumb>
      <h2 class="WarrantyServiceTitleSet famly-inn p-0 m-0">Warranty</h2>
    </div>
  </ng-template>
  <div class="content" *nzModalContent>
    <div class="spinner-wrapper" *ngIf="isLoading">
      <img src="/assets/Loder/new-ajax-loader.gif">
    </div>
    <div class="top-of-modal mb-2"></div>
    <nz-alert
      class="mb-3 error-message"
      *ngIf="isSubmitted && errorFields.length > 0"
      nzType="error"
      nzShowIcon
      [nzMessage]="message"
      [nzDescription]="errorDescriptionTemplate"
    ></nz-alert>
    <ng-template #message>
      <p class="mt-1">Please correct the following fields:</p>
    </ng-template>
    <ng-template #errorDescriptionTemplate>
      <ul>
        <li class="famly-inn p-0 m-0" *ngFor="let error of errorFields">
          <span class="d-flex align-items-center">
            <p class="peding">{{ error.label }}:</p>
            <p class="peding">{{ error.message }}</p>
          </span>
        </li>
      </ul>
    </ng-template>
    <div nz-row class="mt-2 famly-inn">
      <div nz-col nzSpan="24">
        <div class="card mb-3 border-0">
          <div class="card-head border-bottom pr-0 mr-0">
            <div nz-row>
              <div nz-col nzSpan="12" class="p-2 d-flex align-items-center">
                <h3 class="Card-Title-Set famly-inn ps-2">Information</h3>
              </div>
              <div nz-col nzSpan="12" class="d-flex justify-content-end align-items-center">
              </div>
            </div>
          </div>
          <div class="card-body">
            <div nz-row>
              <div nz-col nzSpan="8">
                <div class="row">
                  <div class="col-12">
                    <label class="para-label-selection">
                      Claim #
                      <i
                        class="bi bi-info-circle-fill text-secondary ms-1"
                        nz-popover
                        [nzPopoverContent]="Daily"
                        nzPopoverPlacement="right"
                      ></i>
                      <ng-template #Daily>
                        <div style="border-radius: 10px; width: 210px; font-size: 13px; line-height: 25px;">
                          <div nz-row>
                            <div nz-col nzSpan="24">
                              <div class="famly-inn">
                                Change Order numbers are auto assigned based on your previous numbers used per job.
                              </div>
                            </div>
                          </div>
                          <div nz-row class="pt-2" *ngIf="tooltipMessage?.length > 0">
                            <div nz-col nzSpan="24">
                              <div class="famly-inn">Recently Added:</div>
                            </div>
                          </div>
                          <div nz-row class="pt-2">
                            <div nz-col nzSpan="24">
                              <div class="famly-inn">
                                <ng-container>
                                  <div style="white-space: pre-line;">{{ tooltipMessage }}</div>
                                </ng-container>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ng-template>
                    </label>
                  </div>
                </div>

                <div>
                  <nz-input-group  class="rounded-1" [nzAddOnBefore]="onJobInformationWarranties?.JobPrefix?.length > 0 ? SetPreFix : null">
                  <input
                    placeholder="(Auto Assign)"
                    type="text"
                    nz-input
                    class="formField"
                    [(ngModel)]="warrantyFieldValues.autoAssignNumber"
                    name="autoAssignNumber"
                    (ngModelChange)="onFieldChange('idNumber', $event)"
                  />
                </nz-input-group>
                
                <ng-template #SetPreFix>
                  <span>{{ onJobInformationWarranties?.JobPrefix }}</span>
                </ng-template>
                </div>

                <div class="row">
                  <div class="col-12">
                    <label class="para-lable-selection"
                      >Title <span class="text-danger fw-bold">*</span></label
                    >
                      <nz-form-control>
                        <input  #titleInput nz-input [(ngModel)]="warrantyFieldValues.title" name="title"
                          required (ngModelChange)="onFieldChange('title', $event)"
                          [nzStatus]="validationStates['title']" (blur)="touchedFields['title'] = true" class="formField" />
                      </nz-form-control>
                      <div *ngIf="validationStates['title'] === 'error'" class="error-message">
                        {{ validationMessages['title'] }}
                      </div>
                  </div>
                </div>
              <div nz-row class="mt-2">
                <div nz-col nzSpan="24">
                    <label class="para-lable-selection">Category</label>
                </div>
              </div>
              <div nz-row [nzGutter]="12">
                <div nz-col nzSpan="19">
                  <nz-select
                      style="width: 100%"
                      [(ngModel)]="
                        warrantyFieldValues['warrantyCategorySetupId']
                      "
                      name="warrantyCategorySetupId"
                      (ngModelChange)="
                        onFieldChange('title', warrantyFieldValues.title)
                      "
                      nzShowSearch
                      [lang]="true"
                      nzAutoFocus="true"
                    >
                      <nz-option
                        *ngFor="let option of categoryOption"
                        [nzLabel]="option.name"
                        [nzValue]="option.id"
                      >
                      </nz-option>
                    </nz-select>
                </div>
                <div nz-col nzSpan="2" class="d-flex justify-content-end align-items-center">
                  <span class="BT-Link famly-inn" (click)="onCategoryWarrantyOpen(null)">Add</span>
                </div>
                <div nz-col nzSpan="2" class="d-flex justify-content-end align-items-center">
                  <span
                  [class.disabled]="isEditButtonDisabled()"
                  class="BT-Link famly-inn"
                  [ngClass]="{'disabled-link': warrantyFieldValues.warrantyCategorySetupId === 71402}"
                  (click)="!isEditButtonDisabled() && onCategoryWarrantyOpen(warrantyFieldValues.warrantyCategorySetupId)">
                  Edit
                </span>
                </div>
                <!-- <div nz-col nzSpan="2" style="display: flex;justify-content: center;align-items: center;">
                  <a class="BT-Link famly-inn" (click)="newCategoryWarrantyOpen(null)">Add</a>
                </div>
                <div nz-col nzSpan="2" style="display: flex;justify-content: center;align-items: center;">
                  <a class="BT-Link ms-1 famly-inn"
                  [ngClass]="{'disabled-link': warrantyFieldValues.warrantyCategorySetupId === 71402}"
                  (click)="warrantyFieldValues.warrantyCategorySetupId = 71402 && newCategoryWarrantyEdit(warrantyFieldValues.warrantyCategorySetupId)">Edit</a>
                </div> -->
                <!-- [disabled]="
                !warrantyFieldValues['warrantyCategorySetupId'] ||
                warrantyFieldValues['warrantyCategorySetupId'] === 0" -->
              </div>
                <div class="row">
                  <div class="col-6">
                    <label class="para-lable-selection">Priority</label>
                    <nz-select
                      nzShowSearch
                      nzAllowClear
                      [(ngModel)]="warrantyFieldValues['prioritySetupId']"
                      (ngModelChange)="
                        onFieldChange('title', warrantyFieldValues.title)
                      "
                      name="prioritySetupId"
                    >
                      <nz-option
                        *ngFor="let option of priorityOption"
                        [nzLabel]="option.label"
                        [nzValue]="option.value"
                      ></nz-option>
                    </nz-select>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <label class="para-lable-selection">Problem Description</label>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                  <nz-form-control>
                      <textarea
                      [(ngModel)]="warrantyFieldValues.problemDescription"
                      name="problemDescription"
                      nz-input
                      [nzAutosize]="{ minRows: 2, maxRows: 12 }"
                      class="formField"
                      (ngModelChange)="onFieldChange('problemDescription', $event)"
                      style="resize: none;"
                      [nzStatus]="validationStates['problemDescription']"
                      >
                      </textarea>
                  </nz-form-control>
                  <div *ngIf="validationStates['problemDescription'] === 'error'" class="text-danger">
                    {{ validationMessages['problemDescription'] }}
                  </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <label class="para-lable-selection">Internal Comments</label>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                  <nz-form-control>
                      <textarea
                      [(ngModel)]="warrantyFieldValues.internalComments"
                      name="internalComments"
                      nz-input
                      [nzAutosize]="{ minRows: 2, maxRows: 12 }"
                      class="formField"
                      (ngModelChange)="onFieldChange('internalComments', $event)"
                      style="resize: none;"
                      [nzStatus]="validationStates['internalComments']"
                      >
                      </textarea>
                  </nz-form-control>
                  <div *ngIf="validationStates['internalComments'] === 'error'" class="text-danger">
                    {{ validationMessages['internalComments'] }}
                  </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card mb-3 border-0">
          <div class="card-head border-bottom pr-0 mr-0">
            <div nz-row>
              <div nz-col nzSpan="12" class="p-2 d-flex align-items-center">
                <h3 class="Card-Title-Set famly-inn ps-2">Assigned Information</h3>
              </div>
              <div nz-col nzSpan="12" class="d-flex justify-content-end align-items-center">
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row gx-5">
              <div class="col-6">
                <div class="row">
                  <div class="col-6">
                    <label class="para-lable-selection"
                      >Service Coordinator<i
                        class="bi bi-info-circle-fill text-secondary ms-1"
                        nz-popover
                        [nzPopoverContent]="Daiyly"
                        nzPopoverPlacement="right"
                      ></i>
                      <ng-template #Daiyly>
                        <div class="rounded-2">
                          <p style="width: 210px; font-size: 13px">
                            This Service Coordinator box allows you to limit<br />
                            communications on this warranty request to one
                            employee.
                          </p>
                        </div>
                      </ng-template>
                    </label>
                    <nz-select
                      [(ngModel)]="warrantyFieldValues['coordinatorUserId']"
                      name="coordinatorUserId"
                      style="width: 100%"
                      nzShowSearch
                    >
                      <nz-option
                        [nzLabel]="'All'"
                        [nzValue]="0"
                      ></nz-option>
                      <nz-option
                        *ngFor="let option of serviceCoordinator"
                        [nzLabel]="option.label"
                        [nzValue]="option.value"
                      ></nz-option>
                    </nz-select>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <label class="para-lable-selection">
                      Classification <span class="text-danger fw-bold">*</span>
                      <i
                        class="bi bi-info-circle-fill text-secondary ms-1"
                        nz-popover
                        [nzPopoverContent]="swarranty"
                        nzPopoverPlacement="top"
                      ></i>
                      <ng-template #swarranty>
                        <div class="rounded-2">
                          <p style="width: 210px; font-size: 13px">
                            Classifications designated 'closed' will close the claim regardless of its service appointment status.
                          </p>
                        </div>
                      </ng-template>
                    </label>
                      <nz-form-control>
                        <nz-select
                        nzShowSearch
                        nzAllowClear
                        [(ngModel)]="warrantyFieldValues.classificationSetupId" name="classificationSetupId"
                        (ngModelChange)="onFieldChange('classificationSetupId',$event)"
                        #classificationInput="ngModel"
                        [nzStatus]="validationStates['classificationSetupId']"
                        required
                      >
                      <nz-option
                        [nzLabel]="'Please Select One'"
                        [nzValue]="0"
                      ></nz-option>
                        <nz-option
                          *ngFor="let option of classificationOption"
                          [nzLabel]="option.name"
                          [nzValue]="option.id"
                        ></nz-option>
                      </nz-select>
                      </nz-form-control>
                  </div>
                  <div *ngIf="validationStates['classificationSetupId'] === 'error'" class="error-message mt-1">
                    {{ validationMessages['classificationSetupId'] || 'Please provide feedback on past appointments before classifying this request as \'Service Completed (closed)\'' }}
                  </div>
                </div>
                <ng-container *ngIf="showAdditionalFields">
                  <div class="row">
                    <div class="col-5">
                      <label class="para-lable-selection famly-inn">Completion Date</label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <nz-form-control>
                        <nz-date-picker
                        nzPlaceHolder=" "
                        nzSuffixIcon="false"
                        [nzStatus]="validationStates['completionDate']"
                        (ngModelChange)="onDateChange($event)"
                        [(ngModel)]="warrantyFieldValues['completionDate']"
                        name="completionDate"
                      ></nz-date-picker>
                      </nz-form-control>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-3">
                      <label class="para-lable-selection famly-inn">at</label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <nz-form-item>
                      <nz-form-control>
                      <nz-time-picker
                        [(ngModel)]="warrantyFieldValues['completionTime']"
                        name="completionTime"
                        [nzStatus]="validationStates['completionTime']"
                        nzFormat="HH:mm"
                        (ngModelChange)="onFieldChange('completionTime',$event)"
                        nzPlaceHolder=" "
                        >
                      </nz-time-picker>
                        <!-- [nzDisabled]="!warrantyFieldValues['completionDate']" -->
                      </nz-form-control>
                      <div *ngIf="validationStates['completionTime'] === 'error'" class="text-danger">
                        Required
                      </div>
                      </nz-form-item>
                    </div>
                  </div>
                </ng-container>
                <div class="row">
                  <div class="col-6">
                    <label class="para-lable-selection">Added Cost</label>
                      <div class="input-group">
                        <nz-input-group nzAddOnBefore="$">
                          <input
                          nz-input
                          name="addedCost"
                          type="numbers"
                          [(ngModel)]="warrantyFieldValues['addedCost']"
                          appDollarFormatter
                          #stateInput="ngModel"
                          class="form-control formField"
                        />
                      </nz-input-group>
                    </div>
                  </div>
              </div>
              </div>
              <div class="col-6">
                <div class="row mt-4">
                  <div class="col-12">
                    <label
                      [(ngModel)]="warrantyFieldValues['isShowOwner']"
                      name="isShowOwner"
                      nz-checkbox
                      >Show Owner?</label
                    >
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-6">
                    <label class="para-lable-selection"
                      >Orig. Item/User
                      <i
                        class="bi bi-info-circle-fill text-secondary ms-1"
                        nz-popover
                        [nzPopoverContent]="Da1iyly"
                        nzPopoverPlacement="right"
                      ></i>
                      <ng-template #Da1iyly>
                        <div class="rounded-2">
                          <p style="width: 210px; font-size: 13px">
                            Orig. Item/User does not schedule the user for this
                            claim. This dropdown represents the original user
                            who performed the work now under claim.
                          </p>
                        </div>
                      </ng-template>
                    </label>
                    <nz-select
                    [(ngModel)]="selectedValues"
                    name="orignalScheduleItemParameterId"
                    style="width: 100%"
                    nzShowSearch
                    [lang]="true"
                    nzAutoFocus="true"
                    nzPlaceHolder="Unassigned"
                  >
                    <nz-option
                      [nzLabel]="'-- N/A --'"
                      [nzValue]="0"
                    ></nz-option>
                    <nz-option
                      *ngFor="let option of ScheduleJobdata"
                      [nzLabel]="option.Title"
                      [nzValue]="option.Id"
                    ></nz-option>
                  </nz-select>

                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-5">
                    <label class="para-lable-selection">Follow-Up Date</label>
                  </div>
                </div>
                <div class="row" *ngIf="!showAdditionalFields">
                  <div class="col-12">
                    <nz-date-picker
                      [(ngModel)]="warrantyFieldValues['followUpOn']"
                      [nzFormat]="'MMM d,YYYY'"
                      name="followUpOn"
                      [nzPlaceHolder]="' '"
                      nzSuffixIcon="false"
                    ></nz-date-picker>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card mb-3 border-0" >
          <div class="card-head border-bottom pr-0 mr-0">
            <div nz-row>
              <div nz-col nzSpan="12" class="p-2 d-flex align-items-center">
                <h3 class="Card-Title-Set famly-inn ps-2">
                  Service Appointments
                </h3>
              </div>
              <div
                nz-col
                nzSpan="12"
                class="d-flex justify-content-end align-items-center pe-3"
              >
                <i
                class="bi bi-info-circle-fill text-secondary me-1"
                nz-popover
                [nzPopoverContent]="ScheduleWarranty"
                nzPopoverPlacement="right"
                *ngIf="isFormSaved || warrantyResponses"
              ></i>
              <ng-template #ScheduleWarranty>
                <div class="iconPopoverSetted">
                  <p>
                    The <strong >Schedule Service</strong> button allows you to schedule a date/time for a subs/vendors to perform a service at the job.
                  </p>
                  <p>
                    The <strong >Log Completed Service </strong>button allows you to enter information about a service that has already been completed.
                  </p>
              </div>
              </ng-template>
              <nz-space *ngIf="isFormSaved || warrantyResponses">
                <button *nzSpaceItem nz-button disabled nzType="default" class="rounded-1"
                  (click)="OpenLogCompletedService(false,warrantyResponses?.Id)">Log Completed Service</button>
                <button *nzSpaceItem nz-button nzType="primary" class="rounded-1"
                  (click)="OpenLogCompletedService(true,warrantyResponses?.Id)">Schedule Service Appointment</button>
              </nz-space>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div nz-row *ngIf="isFormSaved || warrantyResponses" class>
              <div nz-col nzSpan="24">

                <!-- <div *ngIf="warranties"> -->
                  <ng-container *ngIf="warranties?.length > 0; else noDataTemplate">
                <nz-table   #smallTable nzSize="small" [nzData]="warranties" [nzShowPagination]="false">
                  <thead >
                    <tr>
                      <th
                        nzWidth="150px"
                        style="padding: 15px; background-color: #f1f4fa"
                      >
                        Assigned to
                      </th>
                      <th
                        nzWidth="250px"
                        style="padding: 15px; background-color: #f1f4fa"
                      >
                        Scheduled for
                      </th>
                      <th
                        nzWidth="300px"
                        style="padding: 15px; background-color: #f1f4fa"
                      >
                        Appointment Status
                      </th>
                      <th
                        nzWidth="270px"
                        style="padding: 15px; background-color: #f1f4fa"
                      >
                        Feedback
                      </th>
                      <th
                        nzWidth="80px"
                        style="padding: 15px; background-color: #f1f4fa"
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let data of warranties">
                      <td nzWidth="150px">{{ data?.AssignToUserName }}</td>
                      <td nzWidth="250px">
                        <span class="setScheduledforCol">
                          <ng-container *ngIf="data?.IsSubVendorScheduleAppointment; else scheduledFor">
                            TBD
                          </ng-container>
                          <ng-template #scheduledFor>
                          {{ data?.ScheduleFor }}
                          </ng-template>
                        </span>
                      </td>

                      <td nzWidth="300px">
                        <div>
                          <span class="me-2">
                            <svg
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 32 32"
                              width="1em"
                              height="1em"
                              aria-hidden="true"
                              focusable="false"
                              class=""
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M14.65 3.87a2 2 0 0 1 2.7 0l10 9.09.01.01a2.04 2.04 0 0 1 .64 1.45V26h2a1 1 0 1 1 0 2H2a1 1 0 1 1 0-2h2V14.42a2.04 2.04 0 0 1 .64-1.45l.01-.01 10-9.09ZM14 26h4v-6h-4v6Zm6 0v-6a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v6H6V14.45a.04.04 0 0 1 0-.02l10-9.08 10 9.08V26h-6Z"
                              ></path>
                            </svg>
                          </span>
                          <span *ngIf="!data?.IsOwnerAccepted || data?.IsSubVendorScheduleAppointment !== true"
                            class="fw-bold" style="color:#666d7c">
                            Never Accepted
                          </span>
                          <span
                            *ngIf="data?.IsSubVendorScheduleAppointment"
                            class="fw-bold"
                            style="color: #cb4f00"> 
                            Pending
                            <span style="color:#202227; font-weight:400">
                              - TBD
                            </span>
                          </span>
                        </div>
                        <div>
                          <span class="me-1">
                            <svg
                              width="1em"
                              height="1em"
                              viewBox="0 0 32 32"
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                              aria-hidden="true"
                              focusable="false"
                              class=""
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M26.2634 8.64228C28.1005 10.8645 28.5175 13.9798 27.335 16.629C26.7764 17.8803 25.9083 18.9214 24.8485 19.6733C27.2432 20.5337 29.3529 22.1913 30.836 24.4513C31.1391 24.9131 31.0104 25.533 30.5487 25.836C30.0869 26.139 29.467 26.0104 29.1639 25.5487C27.3484 22.7822 24.4367 21.1079 21.3137 21.005L20.9314 20.9964C20.8569 20.9988 20.7823 21 20.7075 21C20.1552 21 19.7075 20.5524 19.7074 20.0001C19.7073 19.4478 20.155 19.0001 20.7073 19C22.7647 18.9997 24.6401 17.7596 25.5086 15.8138C26.3821 13.8569 26.0736 11.5516 24.7219 9.91661C23.3804 8.2939 21.2576 7.62833 19.2794 8.20399C18.7491 8.3583 18.1941 8.05351 18.0398 7.52323C17.8855 6.99294 18.1903 6.43796 18.7206 6.28364C21.4692 5.4838 24.416 6.40773 26.2634 8.64228ZM11.5 5.99999C15.6421 5.99999 19 9.35785 19 13.5C19 16.2061 17.5667 18.5776 15.4179 19.8965C17.576 20.8048 19.4702 22.3728 20.8356 24.45C21.139 24.9115 21.0108 25.5315 20.5493 25.8349C20.0878 26.1382 19.4677 26.0101 19.1644 25.5486C17.2865 22.6918 14.2404 20.9999 10.9999 21C7.75926 21.0001 4.71335 22.6923 2.83566 25.5492C2.53232 26.0108 1.91228 26.139 1.45075 25.8357C0.989226 25.5323 0.860989 24.9123 1.16433 24.4507C2.66084 22.1738 4.79285 20.5085 7.21174 19.6524C5.27007 18.2983 3.99999 16.0474 3.99999 13.5C3.99999 9.35785 7.35785 5.99999 11.5 5.99999ZM5.99999 13.5C5.99999 10.4624 8.46242 7.99999 11.5 7.99999C14.5376 7.99999 17 10.4624 17 13.5C17 16.5376 14.5376 19 11.5 19C8.46242 19 5.99999 16.5376 5.99999 13.5Z"
                              ></path>
                            </svg>
                          </span>
                          <span
                            *ngIf="data?.IsBuilderAccepted && (!data?.AssignToSubVendorId)"
                            class="fw-bold"
                            style="color: #31ce8d"
                          >
                            Internally Accepted</span
                          >
                          <span *ngIf="data?.AssignToSubVendorId && data?.IsSubVendorScheduleAppointment !== true" class="fw-bold"  style="color: #666d7c; margin-left: 4px;">
                          Never Accepted
                          </span>
                        <span
                            *ngIf="data?.IsSubVendorScheduleAppointment"
                            class="fw-bold"
                            style="color: #cb4f00;margin-left: 4px;"
                          > Pending
                          <span style="color:#202227; font-weight:400">- TBD</span>
                        </span>
                          <div *ngIf="data?.IsSubVendorScheduleAppointment !== true && data?.AssignToUserId">
                            {{ data?.ScheduleFor }}
                          </div>
                        </div>
                      </td>
                      <td nzWidth="270px">
                        <div nz-row [nzGutter]="6">
                          <div nz-col style="margin-top: 10px;" [nzSpan]="3">
                            <img
                            [src]="data?.imageUrl"
                            alt="Warranty Status Image"
                            style="width: 25px; height: 20;"
                          />
                          </div>
                          <div nz-col [nzSpan]="21">
                            <div nz-row>
                              <div nz-col [nzSpan]="24">
                                <span style="font-weight: 700; font-size: 14px;">{{ data?.FeedBackName }}</span>
                                <span class="ms-1 famly-inn">(ov)</span>
                              </div>
                            </div>
                            <div nz-row>
                              <div nz-col [nzSpan]="24" *ngIf="data?.FeedBackName !== 'Awaiting Feedback...'">
                                marked {{ data?.FeedBackOn }}
                              </div>
                            </div>
                          </div>

                        </div>

                      </td>
                      <td nzWidth="80px">
                        <button
                          nz-button
                          nzType="default"
                          (click)="FormEditWarrantyservice(data?.Id,warrantyResponses?.Id,true )"
                        >
                          <span
                            class="me-2"
                            style="
                              font-size: 16px;
                              border-radius: 3px;
                              padding-top: 0px;
                            "
                          >
                            <svg
                              viewBox="64 64 896 896"
                              focusable="false"
                              data-icon="edit"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                d="M880 836H144c-17.7 0-32 14.3-32 32v36c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-36c0-17.7-14.3-32-32-32zm-622.3-84c2 0 4-.2 6-.5L431.9 722c2-.4 3.9-1.3 5.3-2.8l423.9-423.9a9.96 9.96 0 000-14.1L694.9 114.9c-1.9-1.9-4.4-2.9-7.1-2.9s-5.2 1-7.1 2.9L256.8 538.8c-1.5 1.5-2.4 3.3-2.8 5.3l-29.5 168.2a33.5 33.5 0 009.4 29.8c6.6 6.4 14.9 9.9 23.8 9.9z"
                              ></path>
                            </svg>
                          </span>
                          Edit
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </nz-table>
              </ng-container>
              <ng-template #noDataTemplate>
                <nz-empty ></nz-empty>
              </ng-template>
              </div>
            </div>
            <p *ngIf="!warrantyResponses">
              Please save this warranty claim above before scheduling a service
              appointment.
            </p>
          </div>
    </div>
        <div class="card mb-3 border-0">
          <div class="card-head border-bottom pr-0 mr-0">
            <div nz-row>
              <div nz-col nzSpan="12" class="p-2 d-flex align-items-center">
                <h3 class="Card-Title-Set famly-inn ps-2">Attachments</h3>
              </div>
              <div nz-col nzSpan="12" class="d-flex justify-content-end align-items-center">
              </div>
            </div>
          </div>
          <div class="card-body">
            <div nz-row>
              <div nz-col nzSpan="24">
                <app-create-attachments-common
                  [TestAttachment__]="TestAttachment"
                  (filesUploaded)="onFilesUploaded($event)"
                ></app-create-attachments-common>
              </div>
            </div>
          </div>
      </div>
        <ng-container *ngIf="warrantyResponses?.Id; else MessagingComponent">
          <messaging-common
            [Job_ID]="JobInfoId"
            [Messaging_ID]="Messaging_Id"
            [header_ID]="warrantyResponses?.Id"
            [Form_Name_ID]="formNameId"
          >
          </messaging-common>
        </ng-container>
        <ng-template #MessagingComponent>
          <div class="card border-0 mt-3">
            <div class="card-head border-bottom">
              <div nz-row>
                <div nz-col nzSpan="12" class="p-2 d-flex align-items-center">
                  <h2 class="Card-Title-Set famly-inn ps-2">Messaging</h2>
                </div>
                <div nz-col nzSpan="12" class="d-flex justify-content-end align-items-center">
                </div>
              </div>
            </div>
            <div class="card-body">
              <div nz-row>
                <div nz-col nzSpan="24">
                  <h2 class="famly-inn ant-typography-crad-Header">
                    Messaging available after save
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
        <ng-container *ngIf="_warrantyResponceId > 0" class="mt-3">
          <app-create-rfis-common
          [Job_ID]="JobInfoId"
          [header_ID]="_warrantyResponceId"
          [RFIs_Info]="warrantyResponses?.RFIId"
          [Formname_Id]="formNameId"
          >
          </app-create-rfis-common>
        </ng-container>
        <!-- <div class="card mb-3 mt-3 border-0" *ngIf="!isFormSaved">
          <div class="card-head border-bottom pr-0 mr-0">
            <div nz-row>
              <div nz-col nzSpan="12" class="d-flex justify-content-end align-items-center pe-2">
                <button
                nz-button
                nzType="default"
              >
                Create RFI

              </button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div nz-row>
              <div nz-col nzSpan="24">
              </div>
            </div>
          </div>
      </div>     <div nz-col nzSpan="12" class="p-2 d-flex align-items-center">
                <h3 class="Card-Title-Set famly-inn ps-2">RFI'S</h3>
              </div>
           -->
      </div>
    </div>
  </div>
  <ng-template #NewDaily2>
    <div nz-row [nzGutter]="16">
      <div nz-col [nzSpan]="8">
        <div
          class="text-start justify-content-start align-items-start"
          *ngIf="warrantyServiceData"
        >
          <span *ngIf="footerResponse">
            <app-footer-label [footerData]="footerResponse"></app-footer-label>
          </span>
        </div>
      </div>
      <div nz-col [nzSpan]="16">
        <nz-space>
          <ng-container *ngIf="warrantyResponses">
            <button nz-button nzType="default">Print</button>
            <button nz-button [disabled]="DisabledNextActivity" nzType="default" (click)="deleteWarantyConfirm()">
              Delete
            </button>
          </ng-container>
          <button
            nz-button
            nzType="primary"
            [nzLoading]="isSaveAndNew"
            [disabled]="DisabledNextActivity"
            (click)="saveAndNewwarranty()"
          >
            Save & New
          </button>
          <button
            nz-button
            nzType="primary"
            [nzLoading]="isSave"
            [disabled]="DisabledNextActivity"
            (click)="saveWarrantyForm()"
          >
            Save
          </button>
          <button
            nz-button
            nzType="primary"
            [nzLoading]="isSaveAndClose"
            [disabled]="DisabledNextActivity"
            (click)="saveAndClosewwarranty()"
          >
            Save & Close
          </button>
        </nz-space>
      </div>
    </div>
  </ng-template>
</nz-modal>


