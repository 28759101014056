<section>
  <div class="ListSection react">
    <div class="GridContainer-Header StickyLayoutHeader" style="">
      <div nz-row>
        <div nz-col nzSpan="12" class="ListViewHeaderCol" style="background: #fff;">
          <h2 class="react">Jobs</h2>
        </div>
        <div nz-col nzSpan="12" class="ListViewHeaderCol">
        </div>
      </div>
    </div>
  </div>
  <div class="spinner-wrapper" *ngIf="isLoading1">
    <img src="/assets/Loder/new-ajax-loader.gif">
</div>
  <div class="GridContainer jobs-dashboard-wrapper" style="padding: 0px 14px 0px 14px; background-color: #fff; margin-top: 60px;">
    <loader-icon *ngIf="isLoading" class="loader-icon"></loader-icon>
    <nz-tabset nzType="card" [nzSelectedIndex]="activeTab">
      <nz-tab *ngFor="let tab of tabs" [nzTitle]="innerCardTabs(tab)" style="background-color: #f1f4fa">
        <ng-container *ngIf="tab === 1">
            <div class="conatiner" style="padding: 40px 40px 0px 27px">
              <div nz-row class="pb-0 Content-BT-Header">
                <div nz-col nzSpan="12" style="background: #fff;">
                </div>
                <div nz-col nzSpan="12" style="background: #fff;">
                  <div class="d-flex justify-content-end align-items-end">
                    <button nz-popover [nzPopoverContent]="Summary" nzPopoverPlacement="left" (click)="jobsListSettingOpen()" nz-button nzType="default" class="react me-2">
                      <span nz-icon nzType="setting" nzTheme="outline" class="fs-6"></span>
                    </button>
                    <ng-template #Summary>
                      <div class="rounded-2">
                        <p style="font-size: 13px">Job Settings</p>
                      </div>
                    </ng-template>
                    <nz-divider nzType="vertical" class="divder"></nz-divider>
                    <button nz-button nzType="default" class="react me-2"><span>Export</span></button>
                    <button nz-button nzType="default" class="react me-2" (click)="openFilter(JOBS_JOBS_LIST, PAGE_ID)">
                      <span class="bi bi-funnel ml-0 pl-0 mr-0 pr-0"></span>
                      <span class="ml-0 pl-0 mr-0 pr-0 ms-2">Filter</span>
                    </button>
                    <button nz-button nzType="primary" (click)="OpenChooseHowYouWouldLiketoStart()">
                      <span>New Job</span>
                    </button>
                  </div>
                </div>
              </div>
              <div class="kendo-grid-container">
                <div class="kendo-grid-scrollable">
                  <kendo-grid
                  [data]="displayedData"
                  [sortable]="true"
                  [resizable]="true"
                  [reorderable]="true"
                  [scrollable]="'scrollable'"
                >
                  <kendo-grid-column
                    [reorderable]="false"
                    [resizable]="false"
                    [width]="50"
                    [headerStyle]="{}"
                    [headerClass]="'checkbox-header'"
                    [field]="'checkbox'"
                    [title]="'Select'"
                  >
                    <ng-template kendoGridHeaderTemplate>
                      <div class="checkbox-container">
                      </div>
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <div class="checkbox-container">
                        <span nz-icon nzType="up" nzTheme="outline"></span>
                      </div>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column
                    [reorderable]="false"
                    [resizable]="false"
                    [width]="50"
                    [headerStyle]="{}"
                    [headerClass]="'checkbox-header'"
                    [field]="'checkbox'"
                    [title]="'Select'"
                  >
                    <ng-template kendoGridHeaderTemplate>
                      <div class="checkbox-container">
                        <label nz-checkbox
                          [ngModel]="allSelected"
                          (ngModelChange)="onHeaderCheckboxChange($event)">
                        </label>
                      </div>
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <div class="checkbox-container">
                        <label nz-checkbox
                          [(ngModel)]="dataItem.selected"
                          (ngModelChange)="onCheckboxChange()">
                        </label>
                      </div>
                    </ng-template>
                  </kendo-grid-column>
                
                  <kendo-grid-column
                  *ngFor="let column of displayedColumns"
                  [field]="column"
                  [title]="column"
                  [width]="getColumnWidth(column)"
                  [locked]="isColumnLocked(column)"
                  [headerStyle]="{
                    'background': '#f1f1f1',
                    'border-bottom': '1px solid #ddd'
                  }"
                  [headerClass]="{ 'locked-column': isColumnLocked(column) }"
                >

                    <ng-template kendoGridHeaderTemplate>
                      <span>{{ column }}</span>
                      <span 
                        nz-icon 
                        nzType="pushpin" 
                        nzTheme="outline" 
                        (click)="toggleColumnLock(column)" 
                        [ngClass]="{ 'locked-icon': isColumnLocked(column), 'unlocked-icon': !isColumnLocked(column) }"
                      ></span>
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <ng-container [ngSwitch]="column">
                        
                        <ng-container *ngSwitchCase="'Job Color'">
                          <span class="color-circle" [style.background-color]="dataItem?.JobColorCollection"></span>
                        </ng-container>
                
                        <ng-container *ngSwitchCase="'Job Name'">
                          <a (click)="EditJobScratch(dataItem?.JobInformationId)" nz-button nzType="link">
                            <span class="underdecor text-overflow-auto">{{ dataItem?.JobName }}</span>
                          </a>
                        </ng-container>
                        
                        <ng-container *ngSwitchCase="'Street Address'">
                          <span  class="text-overflow-auto">{{ dataItem?.StreetAddress }}</span>
                        </ng-container>
                
                        <ng-container *ngSwitchCase="'State'">
                          {{ dataItem?.State }}
                        </ng-container>
                        
                        <ng-container *ngSwitchCase="'Postal Code'">
                          {{ dataItem?.PostalCode }}
                        </ng-container>
                        
                        <ng-container *ngSwitchCase="'Suburb'">
                          {{ dataItem?.Suburb }}
                        </ng-container>
                        
                        <ng-container *ngSwitchCase="'Project Manager'">
                          <div [innerHTML]="formatAttendeeNames(dataItem.AttendeeNames, shouldShowMore(dataItem))"></div>
                          <ng-container *ngIf="hasMoreThanTwoAttendees(dataItem.AttendeeNames)">
                            <ng-template #showMoreButton>
                              <span class="Click-Btn" (click)="toggleShowMore(dataItem)">
                                {{ showMoreButtonText(dataItem) }}
                              </span>
                            </ng-template>
                            <ng-container *ngTemplateOutlet="showMoreButton"></ng-container>
                          </ng-container>
                        </ng-container>

                        <ng-container *ngSwitchCase="'Owners'">
                          <div *ngFor="let owner of getOwnersArray(dataItem?.CustomerDisplayNames)">
                            <span class="Click-Btn underdecor-Owner text-overflow-auto" (click)="EditCustomer(owner.id)">
                              {{owner.name}}
                            </span>
                            <br />
                          </div>
                        </ng-container>

                        <ng-container *ngSwitchCase="'Owner Phone'">
                          {{ dataItem?.CustomerCellPhones }}
                        </ng-container>

                        <ng-container *ngSwitchCase="'Owner Email'">
                          {{ dataItem?.CustomerPrimaryEmails }}
                        </ng-container>

                        <ng-container *ngSwitchCase="'Owner Street Address'">
                          {{ dataItem?.CustomerStreetAddresses }}
                        </ng-container>

                        <ng-container *ngSwitchCase="'Owner State'">
                          {{ dataItem?.CustomerStates }}
                        </ng-container>

                        <ng-container *ngSwitchCase="'Owner Postal Code'">
                          {{ dataItem?.CustomerPostalCodes }}
                        </ng-container>
                        
                        <ng-container *ngSwitchCase="'Owner Suburb'">
                          {{ dataItem?.CustomerSuburbs }}
                        </ng-container>
                
                        <ng-container *ngSwitchCase="'Schedule Status'">
                          <div class="text-center">
                            <span [ngClass]="{'online': dataItem?.ScheduleStatus, 'offline': !dataItem?.ScheduleStatus}">
                              {{ dataItem?.ScheduleStatus ? 'Online' : 'Offline' }}
                            </span>
                          </div>
                        </ng-container>
                
                        <ng-container *ngSwitchCase="'Map Status'">
                          {{ dataItem?.MapStatus }}
                        </ng-container>
                
                        <ng-container *ngSwitchCase="'Cost / Sqtf'">
                          {{ dataItem?.CostSqft }}
                        </ng-container>
                
                        <ng-container *ngSwitchCase="'Price / Sqtf'">
                          {{ dataItem?.PriceSqft }}
                        </ng-container>
                
                        <ng-container *ngSwitchCase="'Total / Sqtf'">
                          {{ dataItem?.TotalSqft }}
                        </ng-container>
                
                        <ng-container *ngSwitchCase="'Actual Completion'">
                          {{ dataItem?.ActualCompletion }}
                        </ng-container>
                
                        <ng-container *ngSwitchCase="'Actual Start'">
                          {{ dataItem?.ActualStart }}
                        </ng-container>
                
                        <ng-container *ngSwitchCase="'Allowanace Diffrece'">
                          {{ dataItem?.AllowanceDifference }}
                        </ng-container>
                
                        <ng-container *ngSwitchCase="'Applied Credit Memos'">
                          {{ dataItem?.AppliedCreditMemos }}
                        </ng-container>
                
                        <ng-container *ngSwitchCase="'Approved CO Price'">
                          {{ dataItem?.ApprovedCOPrice }}
                        </ng-container>
                
                        <ng-container *ngSwitchCase="'Contract Price'">
                          {{ dataItem?.ContractPrice }}
                        </ng-container>
                
                        <ng-container *ngSwitchCase="'Costs Outstanding'">
                          {{ dataItem?.CostsOutstanding }}
                        </ng-container>
                
                        <ng-container *ngSwitchCase="'Costs Paid'">
                          {{ dataItem?.CostsPaid }}
                        </ng-container>
                
                        <ng-container *ngSwitchCase="'Created Date'">
                          {{ dataItem?.CreatedDate }}
                        </ng-container>
                
                        <ng-container *ngSwitchCase="'Estimated Status'">
                          {{ dataItem?.EstimatedStatus }}
                        </ng-container>
                
                        <ng-container *ngSwitchCase="'Gross Profit'">
                          {{ dataItem?.GrossProfit }}
                        </ng-container>
                
                        <ng-container *ngSwitchCase="'Individual PO Limit'">
                          {{ dataItem?.IndividualPOLimit }}
                        </ng-container>
                
                        <ng-container *ngSwitchCase="'Job Address'">
                          {{ dataItem?.JobAddress }}
                        </ng-container>
                
                        <ng-container *ngSwitchCase="'Job Groups'">
                          {{ dataItem?.JobGroups }}
                        </ng-container>
                
                        <ng-container *ngSwitchCase="'Job Prefix'">
                          {{ dataItem?.JobPrefix }}
                        </ng-container>
                
                        <ng-container *ngSwitchCase="'Job Running Total'">
                          {{ dataItem?.JobRunningTotal }}
                        </ng-container>
                
                        <ng-container *ngSwitchCase="'Job Status'">
                          {{ dataItem?.JobStatus }}
                        </ng-container>
                
                        <ng-container *ngSwitchCase="'Job Type'">
                          {{ dataItem?.JobType }}
                        </ng-container>
                
                        <ng-container *ngSwitchCase="'Lot'">
                          {{ dataItem?.Lot }}
                        </ng-container>
                
                        <ng-container *ngSwitchCase="'Overall PO Limit'">
                          {{ dataItem?.OverallPOLimit }}
                        </ng-container>
                
                        <ng-container *ngSwitchCase="'Owner Balance'">
                          {{ dataItem?.OwnerBalance }}
                        </ng-container>
                
                        <ng-container *ngSwitchCase="'Payments Recieved'">
                          {{ dataItem?.PaymentsRecieved }}
                        </ng-container>
                
                        <ng-container *ngSwitchCase="'Permit'">
                          {{ dataItem?.Permit }}
                        </ng-container>
                
                        <ng-container *ngSwitchCase="'Projected Completion'">
                          {{ dataItem?.ProjectedCompletion }}
                        </ng-container>
                
                        <ng-container *ngSwitchCase="'Projected Start'">
                          {{ dataItem?.ProjectedStart }}
                        </ng-container>
                
                        <ng-container *ngSwitchCase="'Status Last Updated By'">
                          {{ dataItem?.StatusLastUpdatedBy }}
                        </ng-container>
                
                        <ng-container *ngSwitchCase="'Status Last Updated Date'">
                          {{ dataItem?.StatusLastUpdatedDate }}
                        </ng-container>
                
                        <ng-container *ngSwitchCase="'Takeoff Status'">
                          {{ dataItem?.TakeoffStatus }}
                        </ng-container>
                
                        <ng-container *ngSwitchCase="'Total Costs'">
                          {{ dataItem?.TotalCosts }}
                        </ng-container>
                
                        <ng-container *ngSwitchCase="'Warranty Claims Allowed Until Date'">
                          {{ dataItem?.WarrantyClaimsAllowedUntilDate }}
                        </ng-container>
                
                        <ng-container *ngSwitchCase="'Working Template'">
                          {{ dataItem?.WorkingTemplate }}
                        </ng-container>
                
                        <ng-container *ngSwitchCase="'Xero Status'">
                          {{ dataItem?.XeroStatus }}
                        </ng-container>
                
                        <ng-container *ngSwitchDefault>
                          {{ dataItem[column] }}
                        </ng-container>
                
                      </ng-container>
                    </ng-template>
                  </kendo-grid-column>
                  </kendo-grid>
                </div>
              </div>
            </div>
            <div class="StickyFooterWrapper react">
              <div class="StickyFooter react" style="left: -13px; right: -8px;padding: 0px 40px 0px 40px;">
                <!-- <div class="Sticky__Component Scrollbar" #customScrollbar style="bottom: 97px; overflow-x: scroll;">
                  <div style="height: 1px; width: 3065px;"></div>
                  <object tabindex="-1" type="text/html" aria-hidden="true" data="about:blank" style="display: block; position: absolute; top: 0px; left: 0px; width: 100%; height: 100%; border: none; padding: 0px; margin: 0px; opacity: 0; z-index: -1000; pointer-events: none;"></object>
                </div> -->
                 <div class="ListActionBar bottom StickyLayoutHeader react" style="left: -13px; right: -8px;">
                  <div nz-row style="margin-top: 10px;">
                    <div nz-col nzSpan="14">
                      <ng-container *ngIf="PAGE_ID">
                        <div nz-row>
                          <div nz-col nzSpan="7">
                            <nz-select class="mt-1" (ngModelChange)="onFilterChange($event)" [(ngModel)]="selectedValueControl" nzShowSearch>
                              <nz-option *ngFor="let standard of getStandardViewFilterData" [nzLabel]="standard.name" [nzValue]="standard.id"></nz-option>
                            </nz-select>
                          </div>
                          <div nz-col nzSpan="1">
                            <a nz-popover [nzPopoverContent]="contentTemplate" nzPopoverTrigger="hover" class="react" nz-button nzType="link" (click)="openStandardViewFilterComponent()">
                              <i style="font-size: 1.43em;" class="bx bx-dots-horizontal-rounded mt-2"></i>
                            </a>
                            <ng-template #contentTemplate>
                              <p>Manage Saved Views</p>
                            </ng-template>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                    <div nz-col nzSpan="10">
                      <div class="d-flex justify-content-end align-items-end">
                        <nz-pagination style="float: right;" [nzPageIndex]="currentPage" [nzTotal]="totalRecords"
                          [nzPageSize]="pageSize" nzShowSizeChanger (nzPageIndexChange)="onPageChange($event)"
                          (nzPageSizeChange)="onPageSizeChange($event)" [nzPageSizeOptions]="[10, 20, 50, 75, 100]">
                        </nz-pagination>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </ng-container>
        <ng-container *ngIf="tab === 2">
          <div class="container-fuild mt-5">
            <div class="row">
              <div class="col-12">
                <div class="row">
                  <div class="col-4" *ngIf="!hideField">
          
          
                    <div class="long-div" #divTarget>
                      <div class="long-div-inner">
          
                        <nz-page-header class="headset" *ngFor="let item of displayedData">
                          <!--title-->
                          <nz-page-header-title>
                          </nz-page-header-title>
                          <!--extra-->
                          <nz-page-header-content>
                                <div class="row">
                                    <div class="col fw-bold text-primary">
                                      <span>{{item.JobTypeName}} </span>
                                      <br>
                                      <span>{{item.PostalCode}}</span>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col">{{item.StreetAddress}}</div>
                                  </div>
                                  <div class="row">
                                    <div class="col-3" *ngIf="item.StreetAddress?.trim() != ''">
                                      <button nz-button nzType="default" class="fw-medium" (click)="MapChange(item.StreetAddress)">View</button>
                                    </div>
                                  </div>
                          </nz-page-header-content>
                        </nz-page-header>
        
        
                      </div>
                    </div>
          
          
          
                  </div>
                  <!-- <div class="col-8">
                    <iframe
                      src="https://maps.google.com/maps?q=saddar karachi&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed"
                      frameborder="0" scrolling="no" style="width: 100%; height: 100%;"></iframe>
          
                  </div> -->
        
                  <div class="col-8">
                    <iframe
                   [src]="mapUrl"
                      frameborder="0" scrolling="no" style="width: 100%; height: 100%;"></iframe>
                      <img
                      *ngIf="!mapUrl"
                      alt=""
                      src="https://maps.gstatic.com/mapfiles/transparent.png"
                      draggable="false"
                      style="width: 32px; height: 32px; user-select: none; border: 0px; padding: 0px; margin: 0px; max-width: none;">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <app-job-list-empty></app-job-list-empty> -->
        </ng-container>
      </nz-tab>
    </nz-tabset>
  </div>
</section>
<nz-modal
  nzClassName="jobs-dashboard-modal"
  nzWidth="47%"
  nzFooter="null"
  nzCentered
  [(nzVisible)]="ChooseHowYouWouldLike"
  [nzTitle]="surveys1"
  [nzFooter]="tempFooter"
  (nzOnCancel)="CloseChooseHowYouWouldLiketoStart()"
  [nzStyle]="{ 'max-height': '75vh', 'align-items': 'center' }"
>
  <ng-template #surveys1>
    <div nz-row>
      <div nz-col nzSpan="24">
        <h2 class="react">Choose How You Would Like to Start</h2>
      </div>
    </div>  
  </ng-template>
  <div class="cantent d-flex justify-content-center align-items-center" *nzModalContent>
    <div class="row">
      <div class="col-6">
        <button nz-button nzType="default" style="width: 235px;height: 122px;" (click)="JobCreateScratch()">
          <div class="row" style="padding: 10px 0px 4px 0px;">
            <div style="font-size: 42px;color: #004fd6;" nz-icon nzType="plus-circle" nzTheme="outline"></div>
          </div>
          <div class="row d-flex justify-content-center align-items-center">
            Create from Scratch
          </div>
        </button>
      </div>
      <div class="col-6">
        <button nz-button nzType="default" style="width: 235px;height: 122px;" (click)="OpenJobTEmplate()">
          <div class="row" style="padding: 10px 0px 4px 0px;">
            <svg style="font-size: 42px;color: #004fd6;" width="1em" height="1em" viewBox="0 0 32 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" class=""><path fill-rule="evenodd" clip-rule="evenodd" d="M27 3.99911C27.5128 3.99911 27.9355 4.38516 27.9933 4.88249L28 4.99911V20.9991C28 21.512 27.614 21.9346 27.1166 21.9924L27 21.9991H21.9999V26.9993C21.9999 27.5121 21.6138 27.9348 21.1165 27.9925L20.9999 27.9993H4.99988C4.48704 27.9993 4.06437 27.6132 4.00661 27.1159L3.99988 26.9993V10.9993C3.99988 10.4864 4.38592 10.0638 4.88326 10.006L4.99988 9.99926L9.99988 9.99911L10 4.99911C10 4.48628 10.386 4.06361 10.8834 4.00584L11 3.99911H27ZM19.999 25.999V11.999H5.999V25.999H19.999ZM11.999 5.99899H25.999V19.999L21.9999 19.9991V10.9993L21.9932 10.8826C21.9354 10.3853 21.5127 9.99926 20.9999 9.99926L11.9999 9.99911L11.999 5.99899Z"></path></svg>
          </div>
          <div class="row d-flex justify-content-center align-items-center">
            Create from Template
          </div>
        </button>
      </div>
    </div>
  </div>

  <ng-template #tempFooter>
    <nz-alert class="p-0 m-0" nzType="info" [nzMessage]="TextInfo">
      <ng-template #TextInfo>
        <p class="text-start">
          Get your jobs off the ground faster with our
          <strong>Recommended Templates</strong>. <br />
          <a class="text-primary"> Browse Recommended Templates</a>
        </p>
      </ng-template>
    </nz-alert>
  </ng-template>
</nz-modal>