  <div class="d-flex justify-content-center" style="background: rgb(255, 255, 255); min-height: 324.333px;">
    <div nz-row class="d-flex justify-content-center" style="padding-top: 20px; padding-bottom: 48px; row-gap: 0px;">
      <div nz-col [nzXs]="20" [nzSm]="16" [nzMd]="17" style="row-gap: 0px;">
        <div nz-row class="d-flex justify-content-center">
          <div nz-col [nzXs]="8" [nzLg]="7" [nzXl]="6">
               <span >
                <span role="img" size="78" data-testid="EmptyStateIcon" class="anticon EmptyStateIcon" style="font-size: 78px;"><svg width="1em" height="1em" viewBox="0 0 32 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" class=""><path fill-rule="evenodd" clip-rule="evenodd" d="M6.62239 4.3108C6.80548 3.27246 7.75896 2.56345 8.79135 2.66796L8.9393 2.68848L15.833 3.90402C16.8713 4.08711 17.5803 5.04059 17.4758 6.07298L17.4553 6.22093L16.0695 14.0738L23.5635 11.3463C24.552 10.9865 25.6401 11.4548 26.0678 12.3968L26.1269 12.5417L28.521 19.1195C28.6889 19.5806 28.6809 20.0858 28.5013 20.5395L28.5 26C28.5 27.0543 27.6841 27.9181 26.6492 27.9945L26.5 28H8.99996C8.71364 27.9999 8.43279 27.9781 8.15035 27.934C5.18673 27.4697 3.16007 24.6434 3.56499 21.6748L3.59898 21.4574L6.62239 4.3108ZM26.4995 21.9838L15.4635 25.9998L26.5 26L26.4995 21.9838ZM5.56859 21.8047L8.592 4.6581L15.4857 5.87363L12.4468 23.1078L12.4089 23.2934C11.9975 25.0679 10.2803 26.2426 8.45954 25.958C6.54197 25.6576 5.22375 23.7604 5.56859 21.8047ZM24.2475 13.2257L15.6685 16.3478L14.4164 23.4551C14.3626 23.7603 14.2845 24.0552 14.1846 24.3382L26.6417 19.8036L24.2475 13.2257ZM10.5 22.5C10.5 21.6716 9.82842 21 8.99999 21C8.17156 21 7.49999 21.6716 7.49999 22.5C7.49999 23.3284 8.17156 24 8.99999 24C9.82842 24 10.5 23.3284 10.5 22.5Z"></path></svg></span>
              </span>
          </div>
        </div>
        <div nz-row class="d-flex justify-content-center" style="row-gap: 0px;text-align: center;">
          <div nz-col>
            <h2 class="famly-inn ant-typography-crad-Header" style="font-size: 24px;line-height: 38px;">Manage project selections</h2>
          </div>
        </div>
        <div nz-row class="d-flex justify-content-center" style="row-gap: 0px;text-align: center;">
          <div nz-col>
            <span class="famly-inn" style="color: #202227;overflow-wrap: break-word;">Improve the selection process, and get decisions from customers sooner.</span>
          </div>
        </div>
        <div nz-row class="d-flex justify-content-center" style="row-gap: 0px;margin-left: -4px;margin-right: -4px;margin-top: 24px !important;">
          <div nz-col style="padding-left: 4px; padding-right: 4px;">
            <button nz-button nzType="default">
              <i class="bi bi-box-arrow-up-right"></i>
              <span class="BTN-Font-Famliy ms-2">Learn How</span>
            </button>
          </div>
          <div nz-col class="text-center" style="padding-left: 4px; padding-right: 4px;">
            <button
              nz-button
              nzType="primary"
              class="align-items-center"
              >
              <span class="BTN-Font-Famliy">
                Add a Selection
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>