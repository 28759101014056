import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AddLeadActivityTypeResponse, CreateAddLeadActivityTypeRequest,  } from 'src/Models/LeadActivity/leadActivityClass';
import { LeadActivityTypeService } from 'src/Service/Lead-Activity-Type/lead-activity-type.service';
import { LeadOpportunitiesService } from 'src/Service/lead-opportunities.service';
import { AddLeadActivityTypeComponent } from '../Modal/add-lead-activity-type/add-lead-activity-type.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ProjectManagementSiteDiariesCreateFilesComponent } from 'src/app/project-management/components/Site-Diaries/project-management-site-diaries-create-files/project-management-site-diaries-create-files.component';
import { LeadOpportunityAttachmentsUploadFilesViewAllComponent } from '../Modal/lead-opportunity-attachments-upload-files-view-all/lead-opportunity-attachments-upload-files-view-all.component';
import { LeadOpportunityAttachmentsUploadFilesComponent } from '../Modal/lead-opportunity-attachments-upload-files/lead-opportunity-attachments-upload-files.component';
import { LeadActivityResponse, LeadActivityResponses } from 'src/Models/LeadActivityTemplate/LeadActivityTemplate';
import { LeadStatusCode } from 'src/app/shared/component/Models/LookUpStandardFilterCodes';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzStatus } from 'ng-zorro-antd/core/types';
import { WProofreader } from '@webspellchecker/wproofreader-ckeditor5';
import { Bold, Essentials, Heading, Indent, IndentBlock, Italic, Link, List, MediaEmbed, Paragraph, Table, Undo, Font, FontSize, FontFamily, FontBackgroundColor, FontColor, BlockQuote, HorizontalLine, UndoEditing, ImageUpload, ClassicEditor } from 'ckeditor5';
import { NzUploadFile } from 'ng-zorro-antd/upload/interface';
import { AttachmentAnnotationComponent } from '../attachment-annotation/attachment-annotation.component';
import { NzImageService } from 'ng-zorro-antd/image';
import { BehaviorsubjectService } from 'src/Service/behaviorsubject/behaviorsubject.service';
import { AttachmentService } from 'src/Service/Attachment/attachment.service';
import { CreateAttachmentForDocumentRequest, CustomUploadFile } from 'src/Models/Attachment_Files_Class/AttachmentFilesClass';
@Component({
  selector: 'app-leademail-wizard',
  templateUrl: './leademail-wizard.component.html',
  styleUrls: ['./leademail-wizard.component.css']
})
export class LeademailWizardComponent {
  @Output() leadActivityOnSavedCompleted = new EventEmitter<LeadActivityResponse>();
  afterDeleteAddLeadActivityType: CreateAddLeadActivityTypeRequest;
  uniqueEncodedEmail: string | null = null;

  typeStatus: string;
  statusCode: { code: string; description: string; color?: string } | null = null;
  @Output() cancel = new EventEmitter<void>();
  leadActivityType: AddLeadActivityTypeResponse[] = [];
  activityResponse: LeadActivityResponse;
  activityResponses: LeadActivityResponses;
  @Input() activityResponseId: number;
  selectedFiles: File[] = [];
  showInitialCard: boolean = true;
  showNewDataCard: boolean = false;
  sendButtonVisible: boolean = false;
  Showemail: boolean = false;
  showBackButton = false;

  editorValue: string = '';
  scurrent = 3;
  sendMessageOption = 'now';
  sendDate: Date | null = null;
  activityType: string | null = null;
  isVisibles = true;
  formFieldValues: any = {};
  selectedColor: string;
  selectedDescription: string = '';
  isSaveAndClose: boolean = false;
  isSaveAndNew: boolean = false;
  isSave: boolean = false;
    primaryEmail: string;
    globalid: string;
    activityTypeId: number;
    selectedOption: string = 'now';
  isAttachmentQueued: boolean = false;
  ngOnInit() {
    this.leadActivityFormGroup();

    this.leadOpportunitiesService.getSelectedFiles().subscribe(files => {
      this.selectedFiles = files;
    });
    this.activityTypeGet();
    this.leadOpportunitiesService.ActivityGetbyId(this.activityResponseId).subscribe(
      (res) => {
        this.activityResponses = res.result;
        this.primaryEmail = this.activityResponses?.CustomerPrimaryEmail;
        this.activityTypeId = this.activityResponses?.ActivityTypeId;
        this.globalid = this.activityResponses.GlobalId;
        console.log("show all data primaryEmail", this.primaryEmail);
        console.log("show all data", this.activityResponse);

      },
      (error) => {
        console.error('Error fetching activity by ID:', error);
      }
    );
  }


constructor(
  private leadActivitiesService: LeadActivityTypeService,
  private cdn: ChangeDetectorRef,
  private modal: NzModalService,
  private toastService: NzMessageService,
  private nzImageService: NzImageService,
  private leadOpportunitiesService: LeadOpportunitiesService,
  private _AttachmentService: AttachmentService,
  private _BehaviorsubjectService: BehaviorsubjectService,
){this.generateUniqueEncodedEmail();}
leadActivityFormGroup(): void {
  this.formFieldValues = {
    globalId: this.globalid,
    activityTypeId: this.activityTypeId,
    subject: '',
    body: '',


  };

}




  showModals(): void {
    this.isVisibles = true;
  }
  handleOk(): void {
    console.log('Button ok clicked!');
    this.isVisibles = false;
  }

  handleCancels(): void {
    console.log('Button cancel clicked!');
    this.isVisibles = false;
    this.leadOpportunitiesService.clearSelectedFiles();
    this.cancel.emit();
  }





  changeActivityType(SelectedId: number): void {
    let TestingSelected = SelectedId
    const data = this.leadActivityType.find(x => x.id === TestingSelected);
    this.formFieldValues['colorId'] = data.colorId;
    this.selectedColor = data.color?.colorCollection;
    this.selectedDescription = data.icon?.sourceCollection;
  }


  activityTypeGet() {
    this.leadActivitiesService.getAddLeadActivityType().subscribe((res) => {
      const LeadActivityGet = res.result;
      this.leadActivityType = LeadActivityGet
      if (this.activityResponse?.activityTypeId) {
      } else {
        this.formFieldValues['activityTypeId'] = this.leadActivityType[0].id;
      }
    });
  }


  addNewLeadActivityModal() {
    const modalRef = this.modal.create({
      nzContent: AddLeadActivityTypeComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    modalRef.componentInstance.onSaveComplete.subscribe(() => {
      this.afterSaveActivityTypeGet();
    });
  }

  afterSaveActivityTypeGet() {
    this.leadActivitiesService.getAddLeadActivityType().subscribe((res) => {
      const LeadActivityGet = res.result;
      this.leadActivityType = LeadActivityGet;
      this.cdn.detectChanges();
    });

  }


  editNewLeadActivityModal() {
    let type = this.formFieldValues['activityTypeId'];
    let data = this.leadActivityType.find(x => x.id === type);
    const modalRef = this.modal.create({
      nzContent: AddLeadActivityTypeComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.addLeadActivityTypeResponse = data;
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    modalRef.componentInstance.onUpdateComplete.subscribe(() => {
      this.afterSaveActivityTypeGet();
    });
    modalRef.componentInstance.onUpdateCompleteNew.subscribe(() => {
      this.getAfterDelete();
      this.resetActivityTypeId();
    });
  }

  resetActivityTypeId(): void {
    this.formFieldValues['activityTypeId'] = null;
    this.cdn.detectChanges();
  }



  getAfterDelete() {
    this.leadActivitiesService.getAddLeadActivityType().subscribe((res) => {
      this.formFieldValues = {};
      this.afterDeleteAddLeadActivityType = res.result[0];
      this.selectedDescription = this.afterDeleteAddLeadActivityType?.icon?.sourceCollection;
      this.formFieldValues.activityTypeId = this.afterDeleteAddLeadActivityType?.id;
      this.formFieldValues.colorId = this.afterDeleteAddLeadActivityType?.colorId;
      this.selectedColor = this.afterDeleteAddLeadActivityType?.color?.colorCollection;
      this.leadActivityType = res.result;
      this.cdn.detectChanges();
    });
  }


  currentStep = 0;
  previousStep(): void {
    if (this.currentStep > 0) {
      this.currentStep--;
    }
    this.showInitialCardView();
  }




  showNewDataCardView() {
    this.showNewDataCard = true;
    this.showBackButton = true; // Show 'Back' button
    this.Showemail = false;

  }

  showInitialCardView() {
    this.currentStep = 0;
    this.showInitialCard = true;
    this.showNewDataCard = false;
    this.Showemail = false;
    this.showBackButton = false; // Hide 'Back' button
    this.sendButtonVisible = false; // Hide 'Send' button
  }
  // Method to show the new data card
  showNewCard() {
    this.currentStep = 1;
    this.showInitialCard = false;
    this.Showemail = false;
    this.showNewDataCard = true;
    this.formFieldValues.primaryEmail = this.primaryEmail;
    this.formFieldValues.activityTypeId = this.activityTypeId;
    this.formFieldValues.globalId = this.globalid;
  }

  // Method to handle clicking the "Online" button
  showOnlineCard() {
    this.showNewCard(); // Update this method to handle any additional logic
    this.sendButtonVisible = true; // Show the send button
    this.showBackButton = true; // Show the back button
  }

  showEmailCard() {
    this.currentStep = 1;
    this.showInitialCard = false;
    this.showNewDataCard = false;
    this.Showemail = true;
    this.formFieldValues.primaryEmail = this.primaryEmail;
    this.formFieldValues.activityTypeId = this.activityTypeId;
    this.formFieldValues.globalId = this.globalid;

  }

  //  composeNewMessage(): void {
  //   if (this.uniqueEncodedEmail) {
  //     const mailtoLink = `mailto:${this.uniqueEncodedEmail}`;
  //     window.location.href = mailtoLink;
  //   }
  // }



  composeNewMessage() {
    if (this.primaryEmail) {
      window.location.href = `mailto:${this.primaryEmail}`;
    }
  }


  generateUniqueEncodedEmail(): void {
    const uniqueId = this.generateUniqueId();
    const emailWithId = `${this.primaryEmail}?id=${uniqueId}`;
    this.uniqueEncodedEmail = this.encodeBase64(emailWithId);
  }

  generateUniqueId(): string {
    return Math.random().toString(36).substr(2, 9);
  }

  encodeBase64(text: string): string {
    return btoa(text);
  }



  clickshowwmial(){
      this.showEmailCard(); // Update this method to handle any additional logic
      this.sendButtonVisible = false; // Show the send button
      this.showBackButton = true; // Show the back button
    }





  saveLeadActivity(actionType: 'save' | 'saveAndClose' | 'saveAndNew' = 'save'): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.isSave || this.isSaveAndClose || this.isSaveAndNew) {
        return reject('Already processing');
      }

      if (actionType === 'saveAndNew') {
        this.isSaveAndNew = true;
      } else if (actionType === 'saveAndClose') {
        this.isSaveAndClose = true;
      } else {
        this.isSave = true;
     }

      // this.prepareTypeStatusForSave();
      // Map application user IDs
      const applicationUser = this.formFieldValues?.createLeadActivityUserParameterRequests?.map((id: string) => ({ applicationUserId: id }));

      // Add current status to the payload
      const formDataToSend = {
        ...this.formFieldValues,
        createLeadActivityUserParameterRequests: applicationUser,
        typeStatus: this.typeStatus // Add current status
      };

      this.leadOpportunitiesService.postLeadActivity(formDataToSend).subscribe(
        (response) => {
          this.formFieldValues.globalId = response.result?.GlobalId;
          this.activityResponses = response.result;

          if (this.selectedFiles?.length > 0) {
            const filesToUpload = this.selectedFiles.filter(file => file instanceof File);
            if (filesToUpload.length > 0) {
              const attachmentRequest: CreateAttachmentForDocumentRequest = {
                headerId: response.result.Id,
                companyParameterId: 1,
                formNameId: 4,
                documentFiles: filesToUpload
              };

              this._AttachmentService.CreateAttachmentForDocument(attachmentRequest).subscribe(
                (attachmentResponse) => {
                  this.saveFileIdToDatabase(attachmentResponse.fileId);
                  this.isAttachmentQueued = true;
                  this.statusCode = LeadStatusCode.AwaitingMessage;
                  this.statusCode = LeadStatusCode.Queued;
                  this.typeStatus = this.statusCode.code;
                  console.log("this.TypeStatus = LeadStatusCode.AwaitingMessage;", this.typeStatus);

                },
                (attachmentError) => {
                  console.error('Error creating attachment:', attachmentError);
                }
              );
            } else {
              console.warn('No files to upload.');
            }
          }

          // Update the status code and type status after saving the activity
          this.statusCode = LeadStatusCode.AwaitingMessage;
          this.typeStatus = this.statusCode.code;
          console.log('Saving activity completed. this.TypeStatus =', this.typeStatus);

          this.toastService.success('New User Saved Successfully!');
          this._BehaviorsubjectService.setLeadActivityTypeResponse(this.activityResponse);

          console.log('Emitting leadActivityOnSavedCompleted with:', this.activityResponse);
          this.leadActivityOnSavedCompleted.emit(this.activityResponse);

          resolve();
        },
        (error) => {
          console.error('Error saving data:', error);
          reject(error);
        }
      ).add(() => {
        this.resetFlags();
      });
    });


  }


  saveFileIdToDatabase(fileId: string) {
  }

  resetFlags() {
    this.isSave = false;
    this.isSaveAndClose = false;
    this.isSaveAndNew = false;
  }

  isSubmitted: boolean = false;
  sendEmaillead() {
    this.isSubmitted = true; // Set to true to show errors
    this.checkErrors(true); // Validate all fields

    // If there are validation errors, stop form submission
    if (Object.keys(this.validationStates).some(field => this.validationStates[field] === 'error')) {
      return;
    }

    const formDataToSend = {
      ...this.formFieldValues,
    };

    this.leadOpportunitiesService.postsendEmailLeadActivity(formDataToSend).subscribe(
      (response) => {
        this.activityResponse = response.result;
        if (this.selectedFiless?.length > 0) {
          const filesToUpload = this.selectedFiless.filter(i => i.originFileObj);
          const documentFiles = filesToUpload.map(f => f.originFileObj);
          if (this.selectedFiless.length > 0) {
            const attachmentRequest: CreateAttachmentForDocumentRequest = {
              headerId: response.result.id,
              companyParameterId: 1,
              formNameId: 4,
              documentFiles: documentFiles
            };
            this._AttachmentService.CreateAttachmentForDocument(attachmentRequest).subscribe(
              (attachmentResponse) => {
                this.saveFileIdToDatabase(attachmentResponse.fileId);
                this.toastService.success(' email sent!');
                this._BehaviorsubjectService.setLeadActivityTypeResponse(this.activityResponse);
                this.leadActivityOnSavedCompleted.emit(this.activityResponse);
              },
              (attachmentError) => {
                console.error('Error creating attachment:', attachmentError);
              }
            );
          } else {
            console.warn('No files to upload.');
          }


        }
      }
    );
  }


  // Function to get the label for a field
  getFieldLabel(field: string): string {
    const labels: { [key: string]: string } = {
      body: 'body',
      subject: 'subject ',

    };
    return labels[field] || field;
  }


get errorFields(): { field: string; label: string; message: string }[] {
  return Object.keys(this.validationStates)
    .filter(field => this.validationStates[field] === 'error')
    .map(field => ({
      field,
      label: this.getFieldLabel(field),
      message: this.validationMessages[field]
    }));
}




onFieldChange(field: string, value: string) {
  this.formFieldValues[field] = value;
  this.changedFields.add(field);
  this.checkErrors(); // Check errors for changed fields
}


validationStates: { [key: string]: NzStatus } = {};
validationMessages: { [key: string]: string } = {};
changedFields: Set<string> = new Set<string>();

readonly validationRules = {
  body: {
    required: 'Required',
    maxLength: {  message: 'Opportunity Title exceeds the limit.' }
  },
  subject: {
    required: 'Required',
    maxLength: {  message: 'Street Address exceeds the limit.' }
  },


};


checkErrors(validateAllFields = false) {
  this.validationStates = {};
  this.validationMessages = {};

  // Determine which fields to validate
  const fieldsToValidate = validateAllFields ? Object.keys(this.validationRules) : Array.from(this.changedFields);

  for (const field of fieldsToValidate) {
    if (this.validationRules.hasOwnProperty(field)) {
      const errorTip = this.getErrorTip(field);
      if (errorTip) {
        this.validationStates[field] = 'error';
        this.validationMessages[field] = errorTip;
      } else {
        this.validationStates[field] = null;
        this.validationMessages[field] = '';
      }
    }
  }
}


validateAllFields() {
  this.checkErrors(true);
}

validateChangedFields(field: string) {
  this.changedFields.add(field); // Add field to the changed fields set
  this.checkErrors(); // Validate changed fields
}




getErrorTip(field: string): string {
  const rules = this.validationRules[field];
  const input = this.formFieldValues[field] || '';

  if (rules.required && !input.trim()) {
    return rules.required;
  }

  if (rules.maxLength && input.length > rules.maxLength.limit) {
    const excessLength = input.length - rules.maxLength.limit;
    const unit = excessLength === 1 ? 'character' : 'characters';
    return `${excessLength} ${unit} over.`;
  }

  return ''; // No error
}
public Editor = ClassicEditor;
public config = {
  height: '400px',

  toolbar: {
    items: [
      'undo', 'redo', '|',
      'heading', '|', 'bold', 'italic', '|',
      'link', 'insertTable', 'mediaEmbed', '|',
      'bulletedList', 'numberedList', 'indent', 'outdent', '|',
      'fontSize', 'fontFamily', 'wproofreader', 'fontBackgroundColor', 'fontColor', 'blockQuote',
      'image', 'horizontalLine', 'undoEditing'
    ],
  },
  plugins: [

    Bold,
    Essentials,
    Heading,
    Indent,
    IndentBlock,
    Italic,
    Link,
    List,
    MediaEmbed,
    Paragraph,
    Table,
    Undo,
    Font,
    FontSize,
    FontFamily,
    WProofreader,
    FontBackgroundColor,
    FontColor,
    BlockQuote,
    HorizontalLine,



  ],


  fontSize: {
    options: [
      9, 11, 13, 'default', 17, 19, 21, 25, 28, 32, 36, 40, 44, 48, 52, 56, 60
    ]
  },
  fontFamily: {
    options: [
      'default', 'Arial, Helvetica, sans-serif', 'Courier New, Courier, monospace',
      'Georgia, serif', 'Times New Roman, Times, serif', 'Verdana, Geneva, sans-serif'
    ]
  },


  wproofreader: {
    lang: 'en_US',
    serviceId: 'ZSP0b1zgABGrqqr',
    srcUrl: 'https://svc.webspellchecker.net/spellcheck31/wscbundle/wscbundle.js'
  }
};



testingHide: CustomUploadFile;
selectedFiless: CustomUploadFile[] = [];
uploadFiles(): void {
  const modalRef = this.modal.create({
    nzContent: LeadOpportunityAttachmentsUploadFilesComponent,
    nzFooter: null,
  });
  modalRef.componentInstance.cancel.subscribe(() => {
    modalRef.destroy();
  });
  modalRef.componentInstance.UploadFile.subscribe((data: CustomUploadFile[]) => {
    data.filter(file => this.isImageFile(file));
    this.selectedFiless = [...data, ...this.selectedFiless];
    this.onResize();
  });
}

onResize() {
  this.updateNavButtonsVisibility();
}
isImageFile(file: CustomUploadFile): boolean {
  const fileName = file.name.toLowerCase();
  const fileType = file.type;

  return (
    fileType === 'image/png' ||
    fileType === 'image/jpeg' ||
    fileName.endsWith('.png') ||
    fileName.endsWith('.jpeg')
  );
}
@ViewChild('carouselContainer', { static: false }) carouselContainer!: ElementRef<HTMLDivElement>;
showNavButtons = false;
currentIndex = 0;

private updateNavButtonsVisibility() {
  requestAnimationFrame(() => {
    const container = this.carouselContainer.nativeElement;
    if (this.selectedFiless.length > 0) {
      this.showNavButtons = container.scrollWidth > container.clientWidth;
    } else {
      this.showNavButtons = false;
    }
    this.cdn.detectChanges();
  });
}

playVideo(file: CustomUploadFile): void {
  const videoElement: HTMLVideoElement = this.carouselContainer.nativeElement.querySelector(`#video-${file.id}`);
  if (videoElement) {
    videoElement.style.display = 'block'; // Show the video player
    videoElement.play();
  }
}


removeFile(file: CustomUploadFile): void {
  const fileToRemove = this.selectedFiless.find(item => item.uid === file.uid);
  if (fileToRemove) {
    this.selectedFiless = this.selectedFiless.filter(item => item.uid !== file.uid);
        this.leadOpportunitiesService.deleteLeadByAttachmentId(fileToRemove.globalId).subscribe(
          (res) => {
        console.log("show all delete",res.result);
        this.toastService.success('File deleted successfully!');
      },

)}
}


uploadFilesUpdate(fileq: CustomUploadFile): void {
  const modalRef = this.modal.create({
    nzContent: AttachmentAnnotationComponent,
    nzFooter: null,
  });
  modalRef.componentInstance.DrawerImge = fileq;
  modalRef.componentInstance.cancel.subscribe(() => {
    modalRef.destroy();
  });
  modalRef.componentInstance.imageDataRes.subscribe((data: any) => {
    const newImageUrl = data;
    let test1234 = this.selectedFiless.forEach(activity => {
      activity.thumbUrl = newImageUrl;
    });
    console.log(test1234);
  });
}

previous() {
  if (this.currentIndex > 0) {
    this.currentIndex--;
    this.scrollToCurrentIndex();
  }
}

next() {
  const container = this.carouselContainer.nativeElement;
  const maxIndex = this.selectedFiless.length - Math.floor(container.clientWidth / 130);
  if (this.currentIndex < maxIndex) {
    this.currentIndex++;
    this.scrollToCurrentIndex();
  }
}

CreateNewFile(): void {
  const modalRef = this.modal.create({
    nzContent: ProjectManagementSiteDiariesCreateFilesComponent,
    nzFooter: null,
  });
  modalRef.componentInstance.cancel.subscribe(() => {
    modalRef.destroy();
  });
}
viewAll(): void {
  const modalRef = this.modal.create({
    nzContent: LeadOpportunityAttachmentsUploadFilesViewAllComponent,
    nzFooter: null,
  });
  modalRef.componentInstance.cancel.subscribe(() => {
    modalRef.destroy();
  });
}

scrollToCurrentIndex() {
  const container = this.carouselContainer.nativeElement;
  const scrollAmount = this.currentIndex * 130; // 120px (width) + 10px (margin)
  container.scrollTo({ left: scrollAmount, behavior: 'smooth' });
}

getFileIcon(file: CustomUploadFile): string | null {
  const fileType = file.type;
  if (fileType.includes('pdf')) {
    return 'assets/Attachment/documentPDF.svg';
  } else if (fileType.includes('excel') || fileType.includes('spreadsheet')) {
    return 'assets/Attachment/documentXLS.svg';
  } else if (fileType.includes('word')) {
    return 'assets/Attachment/documentDOC.svg';
  } else if (fileType.includes('zip')) {
    return 'assets/Attachment/documentZIP.svg';
  } else if (fileType.includes('text')|| fileType.includes('json')) {
    return 'assets/Attachment/documentGeneric.svg';
  } else if (fileType.includes('ppt') || fileType.includes('presentation')) {
    return 'assets/Attachment/documentPPT.svg';
  } else if (fileType.includes('video')) {
    return null;
  } else if (fileType.includes('image')) {
    return null;
  } else {
    return null;
  }
}
onClick(): void {
let filteredImages = this.selectedFiless.filter(f =>
  f.name.includes('png') || f.name.includes('jpeg')
);
  const images = filteredImages.map(file => ({
    src: file.thumbUrl,
    alt: file.name
  }));
  this.nzImageService.preview(images,  {
    nzZoom: 1.0,
    nzRotate: 0
  });
}

}
