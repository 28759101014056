import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { CostCode } from 'src/Models/CostCode/costCode';
import { CostCodeService } from 'src/Service/Internaluser/cost-code.service';

@Component({
  selector: 'app-cost-code-cost-category',
  templateUrl: './cost-code-cost-category.component.html',
  styleUrls: ['./cost-code-cost-category.component.css']
})
export class CostCodeCostCategoryComponent implements OnInit {
  @Output() cancel = new EventEmitter<void>();
  VisibleInCostCategorySetup: boolean = true;
  isSave: boolean = false;
  isSaveAndClose: boolean = false;
  isSaveAndNew: boolean = false;
  DisabledNextActivity: boolean = false;
  costCategoryFormField: FormGroup;
  costCategory: CostCode[] = [];

  constructor(private fb: FormBuilder,
    private _CostCodeService: CostCodeService,
    private _NzMessageService : NzMessageService
  ) {}
  
  ngOnInit(): void {
    this.costCategoryFormFiled();
    this.onGetAll();
  }
  costCategoryFormFiled() {
    this.costCategoryFormField = this.fb.group({
      globalI: [['00000000-0000-0000-0000-000000000000']],
      companyParameterId: [1],
      title: ['', Validators.required],
      costCodeId: [0],
      detail: [''],
      isCategory: [true]
    });
  }
  onGetAll() {
    this._CostCodeService.getCategoryData().subscribe(res => {
      this.costCategory = res.result
    })
  }
  closeModalInCostCategorySetup() {
    this.cancel.emit();
    this.VisibleInCostCategorySetup = false;
  }
  saveCostCategory(actionType: 'save' | 'saveAndClose' | 'saveAndNew' = 'save'): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.isSave || this.isSaveAndClose || this.isSaveAndNew) {
        return reject('Already processing');
      }
      if(this.costCategoryFormField.invalid){
        this.resetFlags();
        this.DisabledNextActivity = false;
        return reject('Validation errors present');
      }

      switch (actionType) {
        case 'saveAndNew':
          this.isSaveAndNew = true;
          break;
        case 'saveAndClose':
          this.isSaveAndClose = true;
          break;
        default:
          this.isSave = true;
      }
      this.DisabledNextActivity = true;
      this._CostCodeService.postCostCategory(this.costCategoryFormField.value).subscribe(
        (res) => {
          resolve();
        },
        (error) => {
          this._NzMessageService.error('An error has occurred. Please try again.');
          reject(error);
        }
      ).add(() => {
        this.resetFlags();
        this.DisabledNextActivity = false;
      });
    });
  }
  resetFlags(): void {
    this.isSave = false;
    this.isSaveAndClose = false;
    this.isSaveAndNew = false;
  }
  saveCostCategoryAndClose(): Promise<void>{
    return this.saveCostCategory('saveAndClose').then(() => {
      this.closeModalInCostCategorySetup();
      this.costCategoryFormField.reset();
    }).catch((error) => {
      console.error('Error during save and close:', error);
    })
  }
  saveCostCategoryAndNew(): Promise<void>{
    return this.saveCostCategory('saveAndNew').then(() => {
      this.costCategoryFormField.reset();
    }).catch((error) => {
      console.error('Error during save and close:', error);
    })
  }
}