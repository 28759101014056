<div class="card-body bt-file-viewer-card p-0 m-0">
  <div class="row">
    <div class="col-12">
      <button nz-button class="button-default react" (click)="uploadFiles()"><span>Add</span></button>
      <button nz-button class="button-default react ms-2" (click)="CreateNewFile()"><span>Create New File</span></button>
      <button *ngIf="selectedFiles?.length > 0" nz-button class="button-default react ms-2" (click)="viewAll()"><span>View All ({{ selectedFiles?.length }})</span></button>
    </div>
  </div>
  <i *ngIf="showNavButtons" class="bx bx-chevron-left carousel-nav prev" (click)="previous()"></i>
  <i *ngIf="showNavButtons" class="bx bx-chevron-right carousel-nav next" (click)="next()"></i>
  <div class="carousel-container" #carouselContainer>
    <div class="carousel-view" *ngFor="let fileDetail of selectedFiles">
      <div class="bt-file-viewer">
        <img class="img-1" *ngIf="getFileIcon(fileDetail)" [src]="getFileIcon(fileDetail)" />
        <img (click)="onClick()" class="img-2" *ngIf="!getFileIcon(fileDetail) && !fileDetail.type.includes('video')" [src]="fileDetail.thumbUrl" />
        <ng-container *ngIf="fileDetail.type.includes('video')">
          <div class="video-container">
            <i nz-icon nzType="play-circle" nzTheme="outline" class="video-play-icon" (click)="playVideo(fileDetail)"></i>
            <video #videoPlayer id="video-{{ fileDetail.id }}" [src]="fileDetail.thumbUrl" (click)="playVideo(fileDetail)"></video>
          </div>
        </ng-container>
        <div nz-row class="action-row">
          <div nz-col nzSpan="24">
            <label class="BTFileUpload-SecondaryText p-0 m-0 ms-2">{{ fileDetail.name }}</label>
          </div>
          <div nz-col nzSpan="5">
            <i style="margin: 2px 0px 0px 6px;cursor: pointer;font-size: 21px;color: #626262;" nzTrigger="click" nz-dropdown [nzDropdownMenu]="menu4" class="bx bxs-chevron-down"></i>
            <nz-dropdown-menu #menu4="nzDropdownMenu">
              <ul nz-menu style="width: 170px;">
                <li nz-menu-item (click)="removeFile(fileDetail)">
                  <div class="dropdown-container">
                    <i style="cursor: pointer;font-size: 20px;color: #626262;" class='bx bx-trash'></i>
                    <span class="react">Delete</span>
                  </div>
                </li>
                <li nz-menu-item (click)="uploadFilesUpdate(fileDetail)" *ngIf="isImageFile(fileDetail)" >
                  <div class="dropdown-container">
                    <i style="cursor: pointer;font-size: 20px;color: #626262;" class='bx bxs-pencil'></i>
                    <span class="react">Edit Online</span>
                  </div>
                </li>
              </ul>
            </nz-dropdown-menu>
          </div>
          <div nz-col nzSpan="4" *ngIf="isImageFile(fileDetail)" >
            <i (click)="uploadFilesUpdate(fileDetail)" style="cursor: pointer;font-size: 26px;color: #626262;" class='bx bxs-pencil'></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>