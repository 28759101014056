import { NgModule } from '@angular/core';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { CommonModule, registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { InternalUserModule } from './internal-user/internal-user.module';
import { CompanySettingsModule } from './company-settings/company-settings.module';
import { CustomerInfromationModule } from './customer-infromation/customer-infromation.module';
import { LeadOpportunitiesModule } from './lead-opportunities/lead-opportunities.module';
import { ProjectManagementModule } from './project-management/project-management.module';
import { RouterModule } from '@angular/router';
import { SubsVendorsInformationModule } from './subs-vendors-information/subs-vendors-information.module';
import { FinancialModule } from './financial/financial.module';
import { MessagingModule } from './messaging/messaging.module';
import { FilesModule } from './files/files.module';
import { JobsManagementModule } from './jobs-management/jobs-management.module';
import { ReportsModule } from './reports/reports.module';
import { NgxCurrencyDirective, NgxCurrencyInputMode, provideEnvironmentNgxCurrency } from 'ngx-currency';
import { NgZorroModule } from 'src/shared/ng-zorro/ng-zorro.module';
import { AuthInterceptor } from './Core/inerceptors/auth.interceptor';
import { AuthLayoutComponent } from './Layouts/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './Layouts/main-layout/main-layout.component';
import { BillsComponent } from './common/bills/bills.component';
import { WarrantyComponent } from './common/warranty/warranty.component';
import { CustomFieldFormComponent } from './shared/component/custom-field-form/custom-field-form.component';
import { DisableAutofillDirective } from './Core/Directives/disable-autofill.directive';
import { AddEditJobGroupComponent } from './shared/component/Modal/add-edit-job-group/add-edit-job-group.component';
import { MessagingComposeNewMessageComponent } from './messaging/Components/messaging/messaging-compose-new-message/messaging-compose-new-message.component';
import { NZ_ICONS, NzIconModule, NzIconService } from 'ng-zorro-antd/icon';
import { UserOutline, LockOutline } from '@ant-design/icons-angular/icons';
import { CompanySettingJobEmailSampleComponent } from './shared/component/jobs/company-setting-job-email-sample/company-setting-job-email-sample.component';
import { SharedModule } from 'src/shared/shared.module';

registerLocaleData(en);

// bootstrapApplication(AppComponent, {
//   providers: [
//     provideEnvironmentNgxCurrency({
//       align: "left",
//       allowNegative: true,
//       allowZero: true,
//       decimal: ",",
//       precision: 2,
//       prefix: "$",
//       suffix: "",
//       thousands: ".",
//       nullable: true,
//       min: null,
//       max: null,
//       inputMode: NgxCurrencyInputMode.Financial,
//     }),
//   ],
// }).catch((err) => console.error(err));
@NgModule({
  declarations: [
    AppComponent,
    AuthLayoutComponent,
    MainLayoutComponent,
    BillsComponent,
    WarrantyComponent,
    CustomFieldFormComponent,
    DisableAutofillDirective,
    AddEditJobGroupComponent,
    MessagingComposeNewMessageComponent,
    CompanySettingJobEmailSampleComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CommonModule,
    SharedModule,
    NgxIntlTelInputModule,
    ReactiveFormsModule,
    InternalUserModule,
    CompanySettingsModule,
    CustomerInfromationModule,
    LeadOpportunitiesModule,
    ProjectManagementModule,
    SubsVendorsInformationModule,
    FinancialModule,
    MessagingModule,
    RouterModule,
    FilesModule,
    JobsManagementModule,
    ReportsModule,
    NgxCurrencyDirective,
    NgZorroModule,
    ProjectManagementModule
  ],
  exports: [
    AuthLayoutComponent,
    MainLayoutComponent,
    MessagingComposeNewMessageComponent,
    NgxCurrencyDirective,
    NgZorroModule,
    CustomFieldFormComponent,
  ],
  providers: [
    { provide: NZ_I18N, useValue: en_US },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: NZ_ICONS,
      useValue: [UserOutline, LockOutline]
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(iconService: NzIconService) {
  iconService.addIcon(UserOutline, LockOutline);
}
}
