<div *ngIf="isLoading !== true">
    <div class="CalendarContainer">
        <div class="ScheduleCustomHeader">
            <div
                nz-row
                [nzGutter]="12"
            >
                <div
                    nz-col
                    nzSpan="7"
                >
                    <div class="ToolbarLeft">
                        <nz-select
                            nzShowSearch
                            [(ngModel)]="CalendarWMY"
                            style="width: 110px;"
                            (ngModelChange)="onViewChange($event)"
                        >
                            <nz-option
                                *ngFor="let option of defaultCalendarView"
                                [nzLabel]="option.name"
                                [nzValue]="option.id"
                            ></nz-option>
                        </nz-select>
                        <button
                            nz-button
                            nzType="text"
                            class="ms-2"
                            (click)="goToToday()"
                        >
                            Today
                        </button>
                    </div>
                </div>
                <div
                    nz-col
                    nzSpan="12"
                >
                    <div class="CustomViewSelector">
                        <button
                            nz-button
                            nzType="text"
                            class="me-2"
                            (click)="onPrevMonth()"
                        >
                            <span
                                role="img"
                                data-icon-name="BTIcon"
                                class="ant-icon"
                            >
                                <svg
                                    width="1em"
                                    height="1em"
                                    fill="currentColor"
                                    viewBox="0 0 32 32"
                                    xmlns="http://www.w3.org/2000/svg"
                                    aria-hidden="true"
                                    focusable="false"
                                    class
                                >
                                    <path d="M20.2929 4.29289c.3905-.39052 1.0237-.39052 1.4142 0 .3605.36049.3882.92772.0832 1.32001l-.0832.09421L11.415 16l10.2921 10.2929c.3605.3605.3882.9277.0832 1.32l-.0832.0942c-.3605.3605-.9277.3882-1.32.0832l-.0942-.0832-11.00001-11c-.36048-.3605-.38821-.9277-.08319-1.32l.08319-.0942L20.2929 4.29289Z"></path>
                                </svg>
                            </span>
                        </button>
                        <nz-date-picker
                            nzMode="month"
                            [(ngModel)]="CalendarDate"
                            [nzFormat]="'MMM, YYYY'"
                            nzPlaceHolder=" "
                            autocomplete="off"
                            nzSuffixIcon="false"
                            (ngModelChange)="onMonthChange($event)"
                        >
                        </nz-date-picker>
                        <button
                            nz-button
                            nzType="text"
                            class="ms-2"
                            (click)="onNextMonth()"
                        >
                            <span
                                role="img"
                                data-icon-name="BTIcon"
                                class="ant-icon"
                            >
                                <svg
                                    width="1em"
                                    height="1em"
                                    fill="currentColor"
                                    viewBox="0 0 32 32"
                                    xmlns="http://www.w3.org/2000/svg"
                                    aria-hidden="true"
                                    focusable="false"
                                    class
                                >
                                    <path d="M10.2929 4.29289c.3605-.36048.9277-.38821 1.32-.08319l.0942.08319 11 11.00001c.3605.3605.3882.9277.0832 1.32l-.0832.0942-11 11c-.3905.3905-1.0237.3905-1.4142 0-.36049-.3605-.38822-.9277-.0832-1.32l.0832-.0942L20.585 16 10.2929 5.70711c-.36049-.36049-.38822-.92772-.0832-1.32001l.0832-.09421Z"></path>
                                </svg>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="ToolbarRight"></div>
        </div>
        <div id="calendar">
            <full-calendar
                #calendar
                [options]="calendarOptions"
            ></full-calendar>
        </div>
    </div>
</div>
<ng-template
    #eventTemplate
    let-event="event"
    let-hideDetails="hideDetails"
>
    <div
        #popoverComponent
        nz-popover
        [nzPopoverContent]="eventTemplate1"
        class="calWeekActivityItem"
        [style.backgroundColor]="event?.backgroundColor"
        
    >
        <div
            (click)="leadOpportunityFormEdit(event?.extendedProps?.leadOppId, event?.id)"
            *ngIf="!hideDetails"
            class="CustomItem completed BTPopover PopoverSpacing"
        >
            <span class="lead-activity-icon fa-stack">
                <i
                    id="iconBackground"
                    class="fa fa-circle fa-stack-2x"
                    style="color: rgb(255, 255, 255);"
                ></i>
                <i
                    id="iconImage"
                    class="fa fa-calendar fa-stack-1x"
                    style="color: rgb(32, 34, 39); font-size: 16px;"
                ></i>
            </span>
            <span *ngIf="event.extendedProps.IsMarkup === true"
                nz-icon
                nzType="check-circle"
                nzTheme="outline"
                style="color: rgb(255, 255, 255); font-size: 18px;"
            ></span>
            <span style="white-space: nowrap; margin-right: 8px; font-size: 15px; overflow: hidden;">{{ event?.extendedProps?.datestartTime | date: 'hh:mm a' }}</span>
            <span
                class="itemText bold"
                style="font-size: 15px;"
            >
            {{ event?.extendedProps?.assignee }}
            </span>
            <span
                class="itemText"
            >
                ({{ event?.extendedProps?.leadOpportunityTitle }})
            </span>
        </div>
    </div>
    <ng-template #eventTemplate1>
        <div class="ant-popover-inner-content">
            <div class="ScheduleItemPopover">
                <div
                    [style.backgroundColor]="event?.backgroundColor"
                    class="ScheduleItemTitleContainer"
                >
                    <strong style="color: rgb(255, 255, 255);">{{ event?.title }}</strong>
                </div>
                <div>
                    <span>Contact</span>
                    {{ event?.extendedProps?.leadOpportunityTitle }}
                </div>
                <div>
                    <span>Lead</span>
                    {{ event?.extendedProps?.leadOpportunityTitle }}
                </div>
                <div>
                    <span style="white-space: nowrap;">{{ event?.extendedProps?.datestartTime | date:'MMM dd, yyyy h:mm a' }}</span>
                </div>
                <div>
                    <span>Assignee</span>
                    {{ event?.extendedProps?.assignee }}
                </div>
                <label nz-checkbox [(ngModel)]="event?.extendedProps.IsMarkup" class="custom-checkbox d-flex align-items-center" (ngModelChange)="circleCheckBox(event?.extendedProps?.globalId, $event)">
                  <span class="mt-1 ms-1">Complete</span>
                </label>
            </div>
        </div>
    </ng-template>
</ng-template>
