import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { CostCode } from 'src/Models/CostCode/costCode';
import { ResponseModelArray } from 'src/Models/responseMessage.model';
import {  CostCodeResponse } from 'src/Models/InternalUser/applicationUserResponse';
import { CostCodeService } from 'src/Service/Internaluser/cost-code.service';

@Component({
  selector: 'app-cos-code-model',
  templateUrl: './cos-code-model.component.html',
  styleUrls: ['./cos-code-model.component.css'],
})
export class CosCodeModelComponent implements OnInit {
  @Output() cancel = new EventEmitter<void>();
  @Output() costCodeonSaveComplete = new EventEmitter<CostCodeResponse>();
  @Output() onSaveTag = new EventEmitter<any>();
  @Input() selectedCostCode: any; // Receive selected cost code
  @Input() onCostCode_Id: number;
  VisibleInCostcodeSetup: boolean = true;
  costCodeFormField: FormGroup;
  costCategory: CostCode[] = [];
  costSubCode: CostCode[] = [];
  listOfOptions: CostCode[];
  DisabledNextActivity: boolean = false;
  checked = false;
  isSave: boolean = false;
  isSaveAndClose: boolean = false;
  isSaveAndNew: boolean = false;
  _CostCodeResponse: CostCodeResponse;

  constructor(private fb: FormBuilder,
    private _CostCodeService: CostCodeService,
    private _NzMessageService: NzMessageService,
  ) { }
  ngOnInit(): void {
    this.costCodeFormFiled();
    this.loadCostCodes();
    if(this.onCostCode_Id){
      this.onCostCodeInGetById(this.onCostCode_Id);
    }
  }
  onCostCodeInGetById(_Id: number){
    this._CostCodeService.getDataIdCostCode(_Id).subscribe(
      (res) => {
        this._CostCodeResponse = res?.result;
      });
  }
  costCodeFormFiled() {
    this.costCodeFormField = this.fb.group({
      globalI: ['00000000-0000-0000-0000-000000000000'],
      companyParameterId: [1],
      title: ['', Validators.required],
      defaultLaborCost: [''],
      timeClockLaborCode: [false],
      costCodeId: [0, Validators.required],
      subCostCodeId: [0],
      detail: [''],
      isStatus: [false],
      isCategory: [false],
      isSubCodeOf: [false]
    });
  }
  loadCostCodes(): void {
    this._CostCodeService.getCategoryData().subscribe((res: ResponseModelArray<CostCode>) => {
      this.listOfOptions = res.result;
      if (res) {
        this.costCategory = res.result.filter(
          (cost) => cost.isCategory === true
        );
        this.costSubCode = res.result.filter(
          (cost) => cost.isSubCodeOf === true
        );
      }
    });
  }
  subCodeChanged(): void {
    const selectedSubCodeId = this.costCodeFormField['subCostCodeId'];
    const matchingSubCode = this.costSubCode.find(cost => cost.id === selectedSubCodeId);
    if (matchingSubCode && matchingSubCode.costCodeId) {
      const matchingCategory = this.costCategory.find(cost => cost.id === matchingSubCode.costCodeId);
      if (matchingCategory) {
        this.costCodeFormField['costCodeId'] = matchingCategory.id;
      }
    }
  }
  closeModalInCostcodeSetup() {
    this.cancel.emit();
    this.VisibleInCostcodeSetup = false;
  }
  toggleFields(): void {
    this.checked = !this.checked;
    const defaultLaborCostControl = this.costCodeFormField.get('defaultLaborCost');
    if (this.checked) {
      defaultLaborCostControl.enable();
      defaultLaborCostControl.setValidators(Validators.required);
    } else {
      defaultLaborCostControl.disable();
      defaultLaborCostControl.clearValidators();
    }
    defaultLaborCostControl.updateValueAndValidity();
  }
  saveCostCode(actionType: 'save' | 'saveAndClose' | 'saveAndNew' = 'save'): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.isSave || this.isSaveAndClose || this.isSaveAndNew) {
        return reject('Already processing');
      }
      if (this.costCodeFormField.invalid) {
        this.resetFlags();
        this.DisabledNextActivity = false;
        return reject('Validation errors present');
      }

      switch (actionType) {
        case 'saveAndNew':
          this.isSaveAndNew = true;
          break;
        case 'saveAndClose':
          this.isSaveAndClose = true;
          break;
        default:
          this.isSave = true;
      }
      this.DisabledNextActivity = true;
      this._CostCodeService.postCostCode(this.costCodeFormField.value).subscribe(
        (res) => {
          this.costCodeonSaveComplete.emit(res?.result);
          resolve();
        },
        (error) => {
          this._NzMessageService.error('An error has occurred. Please try again.');
          reject(error);
        }
      ).add(() => {
        this.resetFlags();
        this.DisabledNextActivity = false;
      });
    });
  }
  resetFlags(): void {
    this.isSave = false;
    this.isSaveAndClose = false;
    this.isSaveAndNew = false;
  }
  saveCostCodeAndClose(): Promise<void> {
    return this.saveCostCode('saveAndClose').then(() => {
      this.closeModalInCostcodeSetup();
      this.costCodeFormField.reset();
    }).catch((error) => {
      console.error('Error during save and close:', error);
    })
  }
  saveCostCodeAndNew(): Promise<void> {
    return this.saveCostCode('saveAndNew').then(() => {
      this.costCodeFormField.reset();
    }).catch((error) => {
      console.error('Error during save and close:', error);
    })
  }
}