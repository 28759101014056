import { SelectionChoiceParameterResponse, SelectionChoiceResult, SelectionInformationApprove, SelectionInformationResponse, SelectionResponse } from 'src/Models/Selection/selectionClasses';
import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzTreeNode, NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { LookupNameSetupResponse } from 'src/Models/LeadTags';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { SelectionService } from 'src/Service/Selection/selection.service';
import { LeadOpportunitiesService } from 'src/Service/lead-opportunities.service';
import { ProjectManagementSelectionCardViewwPreDefinedCodes, ProjectManagementSelectionListViewwPreDefinedCodes, SelectionStatusCode } from 'src/app/shared/component/Models/LookUpStandardFilterCodes';
import { ProjectManagementSiteDiariesCreateFilesComponent } from '../../Site-Diaries/project-management-site-diaries-create-files/project-management-site-diaries-create-files.component';
import { NZ_MODAL_DATA, NzModalService } from 'ng-zorro-antd/modal';
import { SubVendersService } from 'src/Service/SubVendorsServices/sub-venders.service';
import { NzButtonGroupSize, NzButtonType } from 'ng-zorro-antd/button';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LoadingIndicatorService } from 'src/shared/Shared-Services/loading-indicator.service';
import { AllowanceInformationResponse } from 'src/Models/Selection/AllowanceClasses';
import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';
import { SubVendorResponse } from 'src/Models/SubVendor/SubvendorResponse.model';
import { ApplicationUserForComboResponse } from 'src/Models/InternalUser/InternalUserJob';
import { NewAllowanceComponent } from '../../allowance/new-allowance/new-allowance.component';
import { AddchoiceSelectionChoiceComponent } from '../addchoice-selection-choice/addchoice-selection-choice.component';
import { NzStatus } from 'ng-zorro-antd/core/types';
import { environment } from 'src/environments/environment.prod';
import { AttachmentService } from 'src/Service/Attachment/attachment.service';
import { Subscription } from 'rxjs';
import { Editor, EditorConfig } from 'src/app/helpers/ckeditor-config';
import { AttachmentParameterResponse, AttachmentResponse, CustomUploadFile } from 'src/Models/Attachment_Files_Class/AttachmentFilesClass';
import { AuditDetailsResponse, FilterFooterAudit } from 'src/Models/Footer/footer.model';
import { FooterService } from 'src/Service/Footer/footer.service';
import { saveAttachments } from 'src/app/helpers/Attachment_Save';
import { TimeClockService } from 'src/Service/TimeClock/time-clock.service';
import { NewscheduleItemComponent } from '../../schedules-Components/schedule-common/newschedule-item/newschedule-item.component';
import { ResponseScheduleItemResult } from 'src/Models/Project-Management/schedule';
import { ScheduleService } from 'src/Service/Project-Management/schedule.service';
import { scheduleItemByJob, ScheduleItemParameterResponse } from 'src/Models/Project-Management/projectManagement';
import { SelectionChoiceServService } from 'src/Service/Selection/selection-choice-serv.service';
import { CategorySelectComponent } from '../category-select/category-select.component';
import { LocationSelectComponent } from '../location-select/location-select.component';


interface Person {
  key: string;
  name: string;
  age: number;
  address: string;
}

@Component({
  selector: 'app-selection',
  templateUrl: './selection.component.html',
  styleUrls: ['./selection.component.css']
})
export class SelectionComponent implements OnInit {

  dataSetForselection: string;
  isReleased: boolean = false;
  statusCode: { code: string; description: string; id?: number } | null = null;
  IMG_BASE: string = environment.IMG_BUCKET_URL;
  public Editor = Editor;
  public config = EditorConfig;
  isLoading: boolean = false;
  @Output() cancel = new EventEmitter<void>();
  @Output() onSaveComplete = new EventEmitter<SelectionResponse>();
  initializationComplete = new EventEmitter<boolean>();
  listOfData: Person[] = []
  CNChoicesradioValue = 'A'; // Initial value set to 'A'
  SelectionResponse: SelectionResponse;
  Messaging_Id: number;
  @Input() newSelectionId: number;
  selectionResponses: SelectionResponse;
  testingHide: CustomUploadFile;
  selectedFiless: CustomUploadFile[] = [];
  isButtonDisableds = false;
  isRowVisible: boolean = false;
  validationStates: { [key: string]: NzStatus } = {};
  validationMessages: { [key: string]: string } = {};
  changedFields: Set<string> = new Set<string>();
  isSubmitted: boolean = false;
  jobID: number;
  copiedText: string = '';
  isCopied: boolean = false;
  formNameId: number = 30;
  messagingId: number; //soofe work
  messagingIdRow: any; //soofe work
  selectionReponse: number = -1;
  @Input() comments: any;
  statusText: string = '';
  statusClass: string = '';
  // sufyan working add
  isSaveAndClose: boolean = false;
  issaveAndReleaseve: boolean = false;
  DisabledNextActivity: boolean = false;
  public ModalTitle: ModalTitle;
  resetCategory: boolean = false;
  resetLocation: boolean = false;
  isSaveAndNew: boolean = false;
  isSave: boolean = false;
  fileName: string;
  selectedValue = null;
  addedCost: number = 0;
  visible = false
  radioValue = 'A';
  demoValue = 0.00;
  date = null;
  time: Date | null = null;
  defaultOpenValue = new Date(0, 0, 0, 0, 0, 0);
  value: string[] = ['0-0-0'];
  showDateFields: boolean = true;
  showScheduleItemFields: boolean = false;
  selectionModelhandle = true;
  selectioTabs = [1, 2, 3, 4, 5];
  listOfOption: Array<{ label: string; value: string }> = [];
  categoryOption: LookupNameSetupResponse[] = [];
  locationOption: LookupNameSetupResponse[] = [];
  // scheduleItemOption: ScheduleItemParameterResponse[] = [];
  shareAllowanceOption: AllowanceInformationResponse[] = [];
  listOfTagOptions = [];
  customNotFoundContent = 'Adding choice will be available after saving';
  showSelector = true
  ShowSelector2 = false;
  newSelectionFormFiedls: any = {};
  pageId: number = 30;
  selectionResponce: SelectionInformationResponse;
  inputValue?: string;
  editorValue: string = '';
  radioValuess = 'A';
  radioValues = 'A';
  vendersNodes: NzTreeNodeOptions[] = [];
  vendorList: SubVendorResponse[] = [];
  selectedUsers: ApplicationUserForComboResponse[] = [];
  nzNodes: NzTreeNodeOptions[] | NzTreeNode[];
  subVendorsNodes: NzTreeNodeOptions[] = [];
  installarNodes: NzTreeNodeOptions[] = [];
  titleTouched: boolean = false;
  showTitleError: boolean = false;
  showSharedAllowanceError: boolean = false;
  sharedAllowanceTouched: boolean = false;
  sharedAllowanceId: string | null = null;
  selectedFiles: File[] = [];
  SelectInfoResponse: SelectionInformationResponse;
  size: NzButtonGroupSize = 'small';
  responceHoldingTitle: string;
  responceHoldingId: number;
  categoryNameSet: string;
  locationNameSet: string;
  private ChoiceSelectiondataSubscription: Subscription;
  responseDataChoice: SelectionChoiceParameterResponse[];
  footerResponse: AuditDetailsResponse;
  selectionRes: ScheduleItemParameterResponse[] = [];
  scheduleItem: string = null;
  CategoryField: FormGroup;
  isDeleteTagsLoading: boolean;
  lookupSetNameResponse: LookupNameSetupResponse[] = [];
  isLoadingOne: boolean = false;
  public isTagVisible = false;
  public Approve = false;
  isLoadingEdit: boolean = false;
  PAGE_ID: 30;
  confirmModal: import("ng-zorro-antd/modal").NzModalRef<unknown, any>;
  isButtonEnabled: boolean = false;
  JobInfoId: number;
  SelectionHeaderId: number
  preDefinedDataCodes = {
    Categories: ProjectManagementSelectionListViewwPreDefinedCodes.Categories,
    Locations: ProjectManagementSelectionListViewwPreDefinedCodes.Locations,
  };
  newChoiceFormFiedls: any = {};
  newChoiceFormFiedlsAttachment: any = {};
  cards: CardChoice[] = [];
  cardd: any[] = [];
  holdPath: string;
  holdName: any[];
  shareSingleradioValue = 'A';
  chooseAddLinkradioValue = 'A';
  dueDateTest: string;
  dueDate: string;
  selectInfoResponseDueBYID_: SelectionResponse;
  ApproveForm: FormGroup;
  selectionInformationStatus: string = '';
  selectionChoiceParameterStatus: string = '';
  Approvehide: SelectionInformationApprove | any;
  status: number;
  isSaveAndUnrelease: boolean = false;
  isApproved: boolean = false;
  TestAttachment: AttachmentResponse;
  selectedFilesAttachment: CustomUploadFile[] = [];
  selectAll: boolean = false;
  cardChecked: boolean = false;
  @Input() selectedJobId: number;
  @Input() selectedJobName: string;
  scheduleList: scheduleItemByJob[];
  View_Display: boolean = true;
  DaysradioValue = 'A';
  ScheduleItem_start_end_On: boolean = false
  ScheduleItem: ResponseScheduleItemResult;
  isDeleteLoading: boolean = false;
  public mySelection: string[] = [];
  isConfirmUnreleaseVisible: boolean = false;
  Approvalcomments: string;
  CHOICE_GlobalId: string;
  selectedChoice: number;
  selectionData: SelectionChoiceResult[];
  selectionDatas: SelectionChoiceResult[] = [];
  choiceSelectionRes: SelectionChoiceParameterResponse;
  fileDetails: { filePath: string; extension: string }[] = [];
  firstFilePath: string;
  IMG_BUCKET_URL: string = 'https://s3.persistentsolutions.co/dev-angular-cpms-assets/';
  // approve time work
  timeUpdated: boolean = false;
  isButtonDisabled: boolean = false;
  statusColor: {
    color: string; // Text color
    backgroundColor: string; // Background color
  };
  selectionStatusS: any = null;

  constructor(
    private toastService: NzMessageService,
    private _scheduleService: ScheduleService,
    private LookupFilterService: LookupFilterService,
    private modal: NzModalService,
    private selectionService: SelectionService,
    private leadOpportunitiesService: LeadOpportunitiesService,
    private _AttachmentService: AttachmentService,
    public toastsService: NzMessageService,
    private FooterService: FooterService,
    private changeDetectorRef: ChangeDetectorRef,
    private subVendorsService: SubVendersService,
    private loadingIndicatoreService: LoadingIndicatorService,
    private _SelectionChoiceServe: SelectionChoiceServService,
    public _AccessSelectlocalStorage: AccessLocalStorageService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    @Inject(NZ_MODAL_DATA) public JobData: any,
    @Inject(NZ_MODAL_DATA) public leadData: any,
    @Inject(NZ_MODAL_DATA) public SelectionInformation: any,
    @Inject(NZ_MODAL_DATA) public SelectionInformation2: any,
  ) {

  }

  ngOnInit(): void {
    this.selectionService.selectionStatus$.subscribe((SelectionStatus) => {
      console.log('SelectionStatus received:', SelectionStatus);
      if (SelectionStatus && SelectionStatus.length > 0) {
        console.log('show all data _PassStatus_Selection', SelectionStatus);
        this.selectionStatusS = SelectionStatus; // Store the data
        this.setStatusInfo(SelectionStatus.statusId);
      } else {
        console.log('Setting selectionStatusS to null');
        this.selectionStatusS = null;
      }
      this.cdr.detectChanges();
    });


    this.iniliazeFormApproveForm();
    // this.onGetAllSchedule();
    if (this.newSelectionId) {
      this.fetchSelectionMainResponse(this.newSelectionId);
    }
    const _jobId = this._AccessSelectlocalStorage.getJobId();

    if (_jobId) {
      this.getScheduleByJob(_jobId);
    }
    this.selectionService.getCards().subscribe((cards) => {
      this.selectionData = cards;
      this._SelectionChoiceServe.selectionData$.subscribe(res => {
        if (res) {
          this.selectionData = Array.isArray(res) ? res : [res];
          this.isSetPriceLater = res[0].IsSetPriceLater;
          this.isRequestFromSubVendor = res[0].IsRequestFromSubVendor;

          if (this.selectionData.length > 0) {
            this.selectedChoice = this.selectionData[0].Id;
            this.newSelectionFormFiedls.statusSetupId = 437; // Set status to Pending: Available
            this.fetchSelectionMainResponse(this.selectionResponses?.Id)

            console.log('Status set to 437: Pending: Available');
          } else {
            this.newSelectionFormFiedls.statusSetupId = 433; // Set status to Pending: No Choices
            console.log('Status set to 433: Pending: No Choices');
          }
        } else {
          this.newSelectionFormFiedls.statusSetupId = 440; // Set status to Expired: Available
          console.log('Status set to 440: Expired: Available');
          // this.saveSelection();
        }
        if (!res) {
          this.selectionData = [];
        }

        console.log("Received new Choice in Selection data All:", this.selectionData);

        this.selectionData.forEach((item) => {
          const filePaths = item.FilePath ? item.FilePath.split(', ') : [];
          item.fileCount = filePaths.length;
          const firstFilePath = filePaths[0];
          if (firstFilePath) {
            const extension = firstFilePath.split('.').pop().toLowerCase();
            item.fileDetails = [{ filePath: `${this.IMG_BUCKET_URL}${firstFilePath}`, extension }];
          } else {
            item.fileDetails = []; // No files, so set an empty array
          }
          item.allFilePaths = filePaths.map((filePath) => {
            const extension = filePath.split('.').pop().toLowerCase();
            return { filePath: `${filePath}`, extension };
          });
        });
      });
    });
    this.selectionService.getCardsChoice().subscribe((cardsss) => {
      this.cardd = cardsss;
    });
    this.ChoiceSelectiondataSubscription = this.selectionService.getChoiceSelectionDatas().subscribe(data => {
      this.responseDataChoice = data;
    });
    this.messagingId = this.SelectionInformation?.SelectionInformation?.messagingId;
    this.messagingIdRow = this.SelectionInformation2?.SelectionInformation2;
    const storeComments = this.SelectionInformation?.SelectionInformation?.messaging?.messagingParameters.map(param => param.comments)
    this.comments = storeComments?.flat() || [];
    // this.getDropDownMethod();
    // this.initLoad();
    // this.getScheduleItem();
    this.categoryNZSelectLoad();
    this.locationNZSelectLoad();
    this.getAllowanceInformation();
    this.subVendorsGetAll();
    this.getForINstallar();
    this.radioButtons();
    this.shareSingleradioButtons();
    this.chooseAddLinkradioButtons();
    this.jobID = this._AccessSelectlocalStorage.getJobId();
    this.leadOpportunitiesService.getSelectedFiles().subscribe(files => {
      this.selectedFiles = files;
    });
    const children: Array<{ label: string; value: string }> = [];
    for (let i = 10; i < 36; i++) {
      children.push({ label: i.toString(36) + i, value: i.toString(36) + i });
    }
    this.listOfOption = children;
    this.newSelectionFormFiedls = {
      globalId: '00000000-0000-0000-0000-000000000000',
      selectionSetupId: 0,
      jobInformationId: _jobId,
      title: '',
      isSingle: true,
      isShared: true,
      sharedAllowanceId: 0,
      isRequireOwnerToMakeSelection: false,
      catagorySetupId: 71389,
      locationSetupId: 392,
      singleAllowanceNumber: '0.00',
      isChooseDate: true,
      isLinkedToScheduleItem: false,
      scheduleItemParameterId: 0,
      dueNumberDays: 0,
      isBefore: true,
      isAfter: false,
      dedlineOn: null,
      time: null,
      isApproveChoicesOnly: true,
      isAddEditAndApproveChoices: false,
      publicInstructions: "",
      internalNotes: '',
      isAllowMultipleSelectedChoices: true,
      isAuto: true,
      isManual: true,
      isApprove: true,
      isDecline: true,
      statusSetupId: '',
      createSelectionSubVenderParameterRequests: [],
      createSelectionInstallerParameterRequests: [],
    };
    this.newSelectionFormFiedls['title'] = this.SelectionInformation?.SelectionInformation?.title;
    this.newSelectionFormFiedls['statusSetupId'] = this.SelectionInformation?.SelectionInformation?.statusSetupId;  // Set default status if not provided
    this.selectionReponse = this.SelectionInformation2?.SelectionInformation?.id
  }

  ngOnDestroy() {
    if (this.ChoiceSelectiondataSubscription) {
      this.ChoiceSelectiondataSubscription.unsubscribe();
    }
  }




  // getScheduleItem() {
  //   this.leadOpportunitiesService.getAllDataScheduleItem().subscribe(res => {
  //     this.scheduleItemOption = res.result
  //   });
  // }

  //   scheduleList: scheduleItemByJob[];
  //   getScheduleByJob(jobId: number): void {
  //     this._scheduleService.getAllPredecessorsDataScheduleItem(jobId).subscribe(res => {
  //       this.scheduleList = res.result;
  // });
  // }

  EditScheduleItem(): void {
    const modalRef = this.modal.create({
      nzContent: NewscheduleItemComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.selectedJobId;
    modalRef.componentInstance.selectedJobName;
    modalRef.componentInstance.selectedColorId;
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
      // this.getScheduleItem();
    });
    // modalRef.componentInstance.ScheduleItemSaved.subscribe((savedData: any) => {
    //   console.log('Received saved allowance data:', savedData);
    //   this.handleSavedAScheduleItemData(savedData);
    //   modalRef.destroy();
    // });
  }

  // handleSavedAScheduleItemData(savedData: any): void {
  //   console.log('Handling saved data:', savedData);

  //   // Push the newly saved data into the shareAllowanceOption array
  //   this.scheduleItemOption.push(savedData);

  //   // Trigger Angular change detection to update the view
  //   this.changeDetectorRef.detectChanges();
  // }



  getAllowanceInformation() {
    this.selectionService.getAllDataAllowanceInformation().subscribe(res => {
      this.shareAllowanceOption = res.result;
    })
  }



  fileList: NzUploadFile[] = [
    {
      uid: '-1',
      name: 'claimNoPhoto.gif',
      status: 'done',
      url: 'assets/claimNoPhoto.gif',
      download: false,
    },
  ];

  getButtonType(): NzButtonType {
    if (!this.newSelectionFormFiedls.title || (this.ShowSelector2 && (!this.newSelectionFormFiedls.sharedAllowanceId)) || (this.showScheduleItemFields && (!this.newSelectionFormFiedls.scheduleItemParameterId))) {
      return 'primary';
    } else {
      return 'primary';
    }
  }

  subVendorsGetAll() {
    this.subVendorsService.getData().subscribe(res => {
      this.vendorList = res.result.filter(data => data.automaticallyPermitAccessToNewJobs === true);
      this.vendersNodes = this.vendorList.map((data) => ({
        title: data.companyName,
        value: data.id.toString(),
        key: data.id.toString(),
        isLeaf: true,
      }));
    })
  }
  getForINstallar() {
    // this.internalUserService.getData().subscribe(result => {
    //   this.selectedUsers = result?.result.filter(data => data.isGrantExsistJobOrTemplate === true)
    //   this.installarNodes = this.selectedUsers.map((data) => ({
    //     title: data.fullName,
    //     value: data.id.toString(),
    //     key: data.id.toString(),
    //     isLeaf: true,
    //   }));
    // });
  }

  selectionCancel() {
    this.visible = false;
  }
  showSelection() {
    this.visible = true;
  }


  convertToRupees(value: number): string {
    // Your conversion logic here
    // For example, if 1 dollar = 75 rupees
    const conversionRate = 75;
    const result = (value * conversionRate).toFixed(2); // Convert to string with two decimal places
    return result;
  }

  updateConvertedValue(newValue: string): void {
    // Update the addedCost property as the user types, with a debounce of 300 milliseconds
    const inputParts = newValue.split('.');
    const integerValue = inputParts[1];
    const decimalValue = inputParts.length > 1 ? inputParts[1] : '';

    // Check if the decimal part has been manually modified by the user
    const isDecimalModifiedByUser = newValue.includes('.') && decimalValue.length > 0;

    // If the decimal part is not modified by the user, update the addedCost
    if (!isDecimalModifiedByUser) {
      this.addedCost = integerValue !== '' ? parseFloat(integerValue) / 75 : 0; // Parse the input for the integer part
    }

  }

  toggleDateFields() {
    this.showDateFields = !this.showDateFields;
    this.showScheduleItemFields = false; // Ensure the other set is hidden
  }

  toggleScheduleItemFields() {
    this.showScheduleItemFields = !this.showScheduleItemFields;
    this.showDateFields = false; // Ensure the other set is hidden
  }


  selectionModelCancel(): void {
    this.selectionModelhandle = false;
    this.cancel.emit();
  }

  open(): void {
    this.visible = true;
  }

  selectioninnercardTabs(tab: number): string {
    switch (tab) {
      case 1:
        return 'Chooice';
      case 2:
        return 'Description';
      case 3:
        return 'Lock/Unlock';
      case 4:
        return 'Files & Discussions';
      case 5:
        return 'Participation';

      default:
        return '';
    }
  }
  autoAdjustTextAreaHeight(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;
    textarea.style.height = '54'; // Reset height to auto to calculate the actual height needed
    textarea.style.height = `${Math.min(textarea.scrollHeight, 200)}px`; // Set the height with a maximum of 200px
  }

  Single() {
    this.showSelector = true
    this.ShowSelector2 = false
  }

  Shared() {
    this.showSelector = false
    this.ShowSelector2 = true
  }





  // uploadFiles(): void {
  //   const modalRef = this.modal.create({
  //     nzContent: ProjectManagementSiteDiariesUploadFilesComponent,
  //     nzFooter: null,
  //   });
  //   modalRef.componentInstance.cancel.subscribe(() => {
  //     modalRef.destroy();
  //   });
  //   this.isRowVisible = true;
  // }
  createNew(): void {
    const modalRef = this.modal.create({
      nzContent: ProjectManagementSiteDiariesCreateFilesComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    this.isRowVisible = true;
  }
  onTitleFocus() {
    this.titleTouched = true;
  }
  onTitleBlur() {
    if (!this.newSelectionFormFiedls.title && this.titleTouched) {
      this.showTitleError = true;
    } else {
      this.showTitleError = false;
    }
  }
  onSharedAllowanceBlur(): void {
    // Check if the shared allowance ID is empty or not selected
    if (!this.sharedAllowanceId) {
      // Set the error flag to true to show the error message
      this.showSharedAllowanceError = true;
      console.log('showSharedAllowanceError set to true');
    } else {
      // If the value is selected, set error flag to false
      this.showSharedAllowanceError = false;
      console.log('showSharedAllowanceError set to false');
    }
  }

  onSharedAllowanceFocus(): void {
    console.log('onSharedAllowanceFocus called');
    this.showSharedAllowanceError = false;
    console.log('showSharedAllowanceError reset to false');
  }

  removeFile(file: CustomUploadFile): void {
    const fileToRemove = this.selectedFiless.find(item => item.uid === file.uid);
    if (fileToRemove) {
      this.selectedFiless = this.selectedFiless.filter(item => item.uid !== file.uid);
      this.leadOpportunitiesService.deleteLeadByAttachmentId(fileToRemove.globalId).subscribe(
        (res) => {
          console.log("show all delete", res.result);
          this.toastService.success('File deleted successfully!');
        },

      )
    }
  }

  Editaloownce(): void {
    const modalRef = this.modal.create({
      nzContent: NewAllowanceComponent,
      nzFooter: null,
    });

    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    modalRef.componentInstance.allowanceSaved.subscribe((savedData: any) => {
      console.log('Received saved allowance data:', savedData);
      this.handleSavedAllowanceData(savedData);
      modalRef.destroy();
    });
  }

  handleSavedAllowanceData(savedData: any): void {
    console.log('Handling saved data:', savedData);

    // Push the newly saved data into the shareAllowanceOption array
    this.shareAllowanceOption.push(savedData);

    // Trigger Angular change detection to update the view
    this.changeDetectorRef.detectChanges();
  }


  copyText() {
    const textToCopy = document.getElementById('textToCopy')?.textContent;
    navigator.clipboard.writeText(textToCopy).then(() => {
      this.toastsService.success('Copied');
      this.copiedText = textToCopy;  // Set the copied text to display in the input field
      this.isCopied = true;  // Show the input fields

      setTimeout(() => {
        this.isCopied = false;
      }, 2000);  // 2000 milliseconds = 2 seconds

      console.log('Text copied to clipboard:', textToCopy);
    }).catch(err => {
      console.error('Could not copy text:', err);
    });
  }

  copyInputText() {
    navigator.clipboard.writeText(this.copiedText).then(() => {
      this.toastsService.success('Input text copied');
      console.log('Input text copied to clipboard:', this.copiedText);
    }).catch(err => {
      console.error('Could not copy input text:', err);
    });
  }

  checkCharacterLimits(): void {
    const { title, suburb, state, postalCode, subject } = this.newSelectionFormFiedls;
    this.isButtonDisableds = false;
    if (title?.length > 50 || suburb?.length > 50 || state?.length > 3 || postalCode?.length > 15 || subject?.length > 50) {
      this.isButtonDisableds = true;
    }
  }

  ConfirmDelete(): void {
    if (this.isDeleteLoading) {
      return;
    }
    const selectedCount = this.mySelection.length;
    this.modal.confirm({
      nzTitle: `Delete 1 Selection Choice?  ${selectedCount > 1 ? 's' : ''}`,
      nzContent: 'Are you sure you want to delete this selection choice?',
      nzOkText: `Delete ${selectedCount > 1 ? 's' : ''}`,
      nzOkDanger: true,
      // nzOnOk: () => this.(),
      nzCancelText: 'Cancel',
      nzOnOk: () => {
        this.isDeleteLoading = true;
        return this.deleteChoice(this.CHOICE_GlobalId)
          .then(() => {
            this.selectionCancel();
          })
          .catch(() => {

          });
      },
      nzCentered: false,
      nzBodyStyle: { 'border-radius': '30px', 'height': 'auto', 'padding-top': '15', },
      nzStyle: {
        top: '250px',
      },
      nzClassName: 'ant-modal-confirm-custom',
      nzCloseIcon: '',
      nzIconType: '',
    });
  }



  deleteChoice(globalId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!globalId) {
        this.toastsService.error('No record selected for deletion.');
        this.isDeleteLoading = false;
        reject();
        return;
      }

      // Ensure you are passing the correct GlobalId
      this.selectionService.deleteSelectionChoice(globalId).subscribe(
        () => {
          this.isDeleteLoading = false;
          this.toastsService.success(`User Deleted Choice!`);
          if (this.newSelectionId) {
            this.fetchActivityResponseChoice(this.newSelectionId)
          }
          resolve();
        },
        () => {
          this.isDeleteLoading = false;
          this.toastsService.error('An error occurred while deleting the record. Please try again.');
          reject();
        }
      );
    });
  }
  //

  showDeleteConfirm(): void {
    this.modal.confirm({
      nzTitle: 'Disable Manual Sort Order',
      nzContent: 'Are you sure you want to disable manual sort order? Doing so will reset your sort order.',
      nzOkText: 'Disable',
      nzOkType: 'primary',
      nzOnOk: () => console.log('OK'),
      nzCancelText: 'Cancel',
      nzOnCancel: () => console.log('Cancel'),
      nzClassName: 'ant-modal-confirm-custom',
      nzBodyStyle: { 'border-radius': '30px', 'height': 'auto', 'padding-top': '15', },
      nzStyle: {
        top: '250px',
      },
      nzCloseIcon: '',
      nzIconType: '',
    });
  }
  openSelectionChoice(data, selection_Id: number) {
    this.isLoading = true;
    this.isLoadingEdit = false;
    const modalRef = this.modal.create({
      nzContent: AddchoiceSelectionChoiceComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.SelectIdByChoice = this.newSelectionId;

    if (modalRef.componentInstance) {
      modalRef.componentInstance.CategorySendChoice = this.categoryNameSet;
      modalRef.componentInstance.SelectTitleSendChoice = this.responceHoldingTitle;
      modalRef.componentInstance.LocationSendChoice = this.locationNameSet;
      // modalRef.componentInstance.newSeletChoiceId = this.responceHoldingId;
      // modalRef.componentInstance.SelectIdAddChoice = this.selectionReponse;
      modalRef.componentInstance.GetStatusId = this._PassStatusId;
    }
    modalRef.componentInstance.newSeletChoiceId = selection_Id;
    modalRef.afterClose.subscribe(res => {
      if (selection_Id) {
        this.fetchSelectionMainResponse(selection_Id);
      }

      console.log('close response', res);
    });
    modalRef.componentInstance.initializationComplete.subscribe((res) => {
      this.isLoading = res
    });
    modalRef.componentInstance.cancel.subscribe(() => {

      modalRef.destroy();


    });
    modalRef.componentInstance.ChoiceSelectionAttachmentResponse.subscribe((res: AttachmentResponse) => {
      console.log('RES ATTACHMENT', res);
      if (!res) {
        console.error('Attachment response is null or undefined');
      } else if (typeof res === 'object' && 'id' in res) {
        console.log('RES ATTACHMENT.id', res.id); // Access the id directly from res
      } else {
        console.error('Response is not of expected type or ID is missing.');
      }
      modalRef.destroy();
    });

    modalRef.componentInstance.cancel.subscribe(() => {
      this.fetchSelectionMainResponse(this.newSelectionId);
      modalRef.destroy();

    });
  }

  FormEditForchoice(Id: number): void {
    this.isLoading = true;
    const modalRef = this.modal.create({
      nzContent: AddchoiceSelectionChoiceComponent,
      nzFooter: null
    });

    modalRef.componentInstance._newSeletChoiceId = Id;
    modalRef.componentInstance.SelectIdByChoice = this.newSelectionId;
    modalRef.componentInstance.onSaveComplete.emit()
    modalRef.componentInstance.ChoiceSelectionResponse.subscribe(() => {
    })
    modalRef.componentInstance.cancel.subscribe(() => {
      this.fetchSelectionMainResponse(this.newSelectionId);
      modalRef.destroy();
      this.fetchSelectionMainResponse(this.newSelectionId);
    });
    modalRef.componentInstance.initializationComplete.subscribe((res) => {
      this.isLoading = res
    });
  }

  onUnrelease(): void {
    // Show confirmation modal when the user clicks "Unrelease"
    this.modal.confirm({
      nzTitle: 'Unrelease Selection?',
      nzContent: 'Are you sure you want to reset this selection to UnReleased?',
      nzOkText: 'Unrelease',
      nzOkType: 'primary',
      nzOnOk: () => this.confirmUnrelease(),  // Call confirmUnrelease on confirmation
      nzCancelText: 'Cancel',
      nzOnCancel: () => this.onCancelUnrelease(),  // Hide modal on cancel
      nzClassName: 'ant-modal-confirm-custom', // Custom class for styling
      nzBodyStyle: { 'border-radius': '30px', 'padding': '15px' },
      nzStyle: { top: '250px' },
      nzCloseIcon: '',  // Remove close icon
      nzIconType: '',   // Remove icon type
    });
  }


  SelectionStatusCode = SelectionStatusCode;
  statussetupColorMap = {
    [SelectionStatusCode.PendingNoChoice.code]: { backgroundColor: '#fbe9b1', textColor: '#653200' },
    [SelectionStatusCode.PendingAvailable.code]: { backgroundColor: '#fbe9b1', textColor: '#653200' },
    [SelectionStatusCode.ExpiredAvailable.code]: { backgroundColor: '#fbe9b1', textColor: '#653200' },
    [SelectionStatusCode.ExpiredNoChoices.code]: { backgroundColor: '#fbe9b1', textColor: '#653200' }, // corrected from ##fbe9b1
    [SelectionStatusCode.UnReleased.code]: { backgroundColor: '#c7d0d9', textColor: '#202227' },
    [SelectionStatusCode.PendingOverdue.code]: { backgroundColor: '#fbe9b1', textColor: '#653200' },
    [SelectionStatusCode.Selected.code]: { backgroundColor: '#c4fae2', textColor: '#005c35' } // corrected from ##c4fae2
  };

  _PassStatusId: number;

  saveSelection(actionType: 'save' | 'saveAndClose' | 'saveAndNew' | 'saveAndRelease' | 'saveAndUnrelease' | 'resetToPending' = 'save'): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.isSave || this.isSaveAndClose || this.isSaveAndNew) {
        return reject('Already processing');
      }
      this.isSubmitted = true;
      switch (actionType) {
        case 'saveAndNew':
          this.isSaveAndNew = true;
          break;
        case 'saveAndClose':
          this.isSaveAndClose = true;
          break;
        case 'saveAndRelease':
          this.issaveAndReleaseve = true;
          break;
        case 'resetToPending':
          this.isSaveAndUnrelease = false;
          break;
        case 'saveAndUnrelease':
          this.isSaveAndUnrelease = true;
          break;
        case 'save':
          this.isSave = true;
          break;
      }
      const currentDate = new Date();
      const deadlineDate = this.newSelectionFormFiedls.dedlineOn ? new Date(this.newSelectionFormFiedls.dedlineOn) : null;
      const isTodayOrFuture = deadlineDate && (deadlineDate.toDateString() === currentDate.toDateString() || deadlineDate > currentDate);
      const time = this.newSelectionFormFiedls.time;
      if (actionType === 'saveAndRelease') {
        if (this.selectionData && this.selectionData.length > 0) {
          this.newSelectionFormFiedls.statusSetupId = 437;
        } else {
          if (deadlineDate < currentDate) {
            this.newSelectionFormFiedls.statusSetupId = 440;
          } else {
            this.newSelectionFormFiedls.statusSetupId = 433;
          }
        }


      } else if (this.newSelectionFormFiedls.isRequireOwnerToMakeSelection && deadlineDate < currentDate) {
        this.newSelectionFormFiedls.statusSetupId = 438;
      } else if (deadlineDate < currentDate) {
        this.newSelectionFormFiedls.statusSetupId = 440;
      } else if (this.selectionData?.length <= 0 && !deadlineDate && !time) {
        this.newSelectionFormFiedls.statusSetupId = 433;
      } else if (deadlineDate && time) {
        this.newSelectionFormFiedls.statusSetupId = 433;
      } else {
        this.newSelectionFormFiedls.statusSetupId = this.selectionData?.length ? 437 : 433;
      }


      if (actionType === 'resetToPending') {
        if (!deadlineDate) {
          this.newSelectionFormFiedls.statusSetupId = 437;
        }
        else if (this.newSelectionFormFiedls.isRequireOwnerToMakeSelection && deadlineDate < currentDate) {
          this.newSelectionFormFiedls.statusSetupId = 438;
        }
        else if (deadlineDate < currentDate) {
          this.newSelectionFormFiedls.statusSetupId = 440;
        }
        else {
          this.newSelectionFormFiedls.statusSetupId = this.selectionData?.length ? 437 : 433;
        }
      }

      if (this.selectionResponses?.StatusSetupId === 441) {
        this.newSelectionFormFiedls.statusSetupId = 441;
      } else if (this.selectionData && this.selectionData.length > 0) {

        if (deadlineDate < currentDate) {
          this.newSelectionFormFiedls.statusSetupId = 440;
        } else {
          this.newSelectionFormFiedls.statusSetupId = 437;
        }
      } else if (!deadlineDate && !time) {
        this.newSelectionFormFiedls.statusSetupId = 433;
      } else if (!deadlineDate) {
        this.newSelectionFormFiedls.statusSetupId = 431;
      } else if (this.newSelectionFormFiedls.isRequireOwnerToMakeSelection && deadlineDate < currentDate) {
        this.newSelectionFormFiedls.statusSetupId = 438;
      } else if (deadlineDate < currentDate) {
        this.newSelectionFormFiedls.statusSetupId = 440;
      } else if (isTodayOrFuture) {
        this.newSelectionFormFiedls.statusSetupId = this.selectionData?.length ? 437 : 433;
      } else {
        this.newSelectionFormFiedls.statusSetupId = 433;
      }


      if (['save', 'saveAndNew', 'saveAndClose', 'saveAndRelease'].includes(actionType) && this.isReleased) {
        if (!deadlineDate && !time) {
          this.newSelectionFormFiedls.statusSetupId = this.selectionData?.length ? 437 : 433;
        } else if (deadlineDate < currentDate) {
          this.newSelectionFormFiedls.statusSetupId = 440;
        } else if (isTodayOrFuture) {
          this.newSelectionFormFiedls.statusSetupId = this.selectionData?.length ? 437 : 433;
        }
      }

      if (['save', 'saveAndNew', 'saveAndClose'].includes(actionType) && !this.isReleased) {
        if (this.selectionData && this.selectionData.length > 0) {
          if (deadlineDate && time && new Date(deadlineDate) < currentDate) {
            this.newSelectionFormFiedls.statusSetupId = 440;
          } else if (!deadlineDate && !time) {
            this.newSelectionFormFiedls.statusSetupId = 437;
          } else {
            this.newSelectionFormFiedls.statusSetupId = 437;
          }
        } else {
          this.newSelectionFormFiedls.statusSetupId = 431;
        }
      }



      this.DisabledNextActivity = true;
      this.isSubmitted = true;
      this.checkErrors(true);

      if (Object.values(this.validationStates).includes('error')) {
        setTimeout(() => {
          const errorElements = document.querySelectorAll('.top-of-modal');
          if (errorElements.length > 0) {
            const lastErrorElement = errorElements[errorElements.length - 1];
            lastErrorElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        }, 100);
        this.DisabledNextActivity = false;
        this.resetFlags();
        console.log('Validation errors:', this.errorFields);
        return reject('Validation errors present');
      }
      this.cdr.detectChanges();

      const formDataToSend = {
        ...this.newSelectionFormFiedls,
      };

      this.selectionService.PostDataSelectionForm(formDataToSend).subscribe(
        (response) => {
          this.selectionReponse = response.result?.Id;
          this.newSelectionFormFiedls.globalId = response.result.GlobalId;
          this.newSelectionFormFiedls.statusSetupId = response.result.StatusName;
          if (this.newSelectionId) {
            this.fetchActivityResponseChoice(this.newSelectionId);

          }
          this._PassStatusId = response.result.StatusSetupId;
          this.JobInfoId = response.result?.JobInformationId;
          this.SelectionHeaderId = this.selectionReponse;
          this.selectInfoResponseDueBYID_ = response?.result;
          this.responceHoldingTitle = response.result.Title;
          this.responceHoldingId = response.result.Id;

          const selectedCategory = this.categoryOption.find(option => option.id === this.newSelectionFormFiedls.catagorySetupId);
          if (selectedCategory) {
            this.categoryNameSet = selectedCategory.name;
            console.log('Category Name:', this.categoryNameSet);
          }
          const selectedLocation = this.locationOption.find(option => option.id === this.newSelectionFormFiedls.locationSetupId);
          if (selectedLocation) {
            this.locationNameSet = selectedLocation.name;
            console.log('Location Name:', this.locationNameSet);
          }
          this.newSelectionFormFiedls.statusSetupId = response.result?.StatusSetupId || this.newSelectionFormFiedls.statusSetupId;
          this.statusCode = this.getStatusCode(this.newSelectionFormFiedls.statusSetupId);

          const handleResponse = (response) => {
            this.toastsService.success('New Selection Saved Successfully!');
            this.onSaveComplete.emit(this.selectionResponses);
            this.selectionResponses = response.result;
            this.setStatusInfo(this.selectionResponses?.StatusSetupId);
            this.resetFlags();
            if (response) {
              this.createAndModifiedByData(response.result.Id);
            }
          };
          if (this.selectedFilesAttachment?.length > 0) {
            saveAttachments(this.selectedFilesAttachment, this._AttachmentService, response.result.Id, 30)
              .then((attachmentResponseId) => {
                handleResponse(response);
                resolve();
              })
              .catch((error) => {
                console.error('Error uploading attachments:', error);
                this.resetFlags();
                reject(error);
              });
          } else {
            handleResponse(response);
            resolve();
          }
        },
        (error) => {
          console.error('Error saving data:', error);
          // this.resetFlags();
          // this.DisabledNextActivity = false;
          reject(error);
        }
      ).add(() => {
        this.resetFlags();
        this.isSaveAndUnrelease = false;
        this.isSave = false;
        this.DisabledNextActivity = false;
      });;
    }
    )
  }

  setStatusInfo(statusId: number) {
    this.status = statusId;
    switch (this.status) {
      case 440: // Expired
        this.selectionResponses.StatusName = this.selectionResponses.StatusName;
        this.statusClass = 'danger';
        break;
      case 441:
        this.selectionResponses.StatusName = this.selectionResponses.StatusName;
        this.statusClass = 'Selected';
        break;
      case 433: // Pending
        this.selectionResponses.StatusName = this.selectionResponses.StatusName;
        this.statusClass = 'Pending-No-Choices'; // Adjust based on your logic
        break;
      case 438: // Pending: Overdue
        this.selectionResponses.StatusName = this.selectionResponses.StatusName;
        this.statusClass = 'Pending-Overdue';
        break;
      case 431: // Unreleased
        this.selectionResponses.StatusName = this.selectionResponses.StatusName;
        this.statusClass = 'Unreleased';
        break;
      case 437: // Pending: Available
        this.selectionResponses.StatusName = this.selectionResponses.StatusName;
        this.statusClass = 'Pending-Available';
        break;
      case 434: // Pending: Available
        this.selectionResponses.StatusName = this.selectionResponses.StatusName;
        this.statusClass = 'Pending-TBD';
        break;
      case 436: // Pending: Available
        this.selectionResponses.StatusName = this.selectionResponses.StatusName;
        this.statusClass = 'Sub-Vendor';
        break;
      default:
        this.selectionResponses.StatusName = '';
        this.statusClass = ''; // Reset class
        break;
    }

  }

  // saveAndRelease(): Promise<void> {
  //   return new Promise((resolve, reject) => {
  //     try {
  //       const currentDate = new Date();
  //       const deadlineDate = new Date(this.newSelectionFormFiedls.dedlineOn);
  //       let timeValue = this.newSelectionFormFiedls.time;

  //       if (timeValue && typeof timeValue === 'string') {
  //         timeValue = new Date(timeValue);
  //       }
  //       const hours = timeValue instanceof Date ? timeValue.getHours() : 0;
  //       const minutes = timeValue instanceof Date ? timeValue.getMinutes() : 0;
  //       const selectedTime = new Date(deadlineDate);
  //       selectedTime.setHours(hours);
  //       selectedTime.setMinutes(minutes);
  //       if (deadlineDate.toDateString() === currentDate.toDateString()) {
  //         // Deadline is today
  //         if (selectedTime.getTime() >= currentDate.getTime()) {
  //           if (this.selectionData && this.selectionData.length > 0) {
  //             this.newSelectionFormFiedls.statusSetupId =447; // Pending: Available choices
  //           } else {
  //             this.newSelectionFormFiedls.statusSetupId = 433 // Pending: No choices
  //           }
  //         } else {
  //           if (this.newSelectionFormFiedls.statusSetupId === 447) {
  //             this.newSelectionFormFiedls.statusSetupId = 440; // Expired: Available choices
  //           } else {
  //             this.newSelectionFormFiedls.statusSetupId = 440; // Expired: No choices
  //           }
  //         }
  //       } else if (deadlineDate < currentDate) {
  //         // Deadline is in the past
  //         if (this.newSelectionFormFiedls.statusSetupId === 447) {
  //           this.newSelectionFormFiedls.statusSetupId = 440; // Expired: Available choices
  //         } else {
  //           this.newSelectionFormFiedls.statusSetupId = 440; // Expired: No choices
  //         }
  //       }
  //        else {
  //         // Deadline is in the future
  //         if (this.selectionData && this.selectionData.length > 0) {
  //           this.newSelectionFormFiedls.statusSetupId = 447; // Pending: Available choices
  //         } else {
  //           this.newSelectionFormFiedls.statusSetupId = 433; // Pending: No choices
  //         }
  //       }

  //       // Update status code reference
  //       this.statusCode = this.getStatusCode(this.newSelectionFormFiedls.statusSetupId);

  //       // Save and handle resolution
  //       this.saveSelection('saveAndRelease')
  //         .then(() => {
  //           this.isReleased = true; // Set release state after successful save
  //           this.changeDetectorRef.detectChanges(); // Ensure UI updates
  //           resolve();
  //         })
  //         .catch((error) => {
  //           console.error('Error during saveAndRelease:', error);
  //           reject(error); // Handle errors gracefully
  //         });
  //     } catch (error) {
  //       console.error('Unexpected error in saveAndRelease:', error);
  //       reject(error); // Catch unexpected errors
  //     }
  //   });
  // }
  saveAndRelease(): Promise<void> {
    debugger
    return new Promise((resolve, reject) => {
      try {
        const currentDate = new Date();
        const deadlineDate = this.newSelectionFormFiedls.dedlineOn ? new Date(this.newSelectionFormFiedls.dedlineOn) : null;
        let timeValue = this.newSelectionFormFiedls.time;

        if (timeValue && typeof timeValue === 'string') {
          timeValue = new Date(timeValue);
        }

        // Handle case where both deadlineDate and timeValue are null
        if (!deadlineDate && !timeValue) {
          // If both deadline date and time are null, check selectionData length
          if (this.selectionData && this.selectionData.length > 0) {
            this.newSelectionFormFiedls.statusSetupId = 437; // Pending: With Choices
          } else {
            this.newSelectionFormFiedls.statusSetupId = 433; // Pending: No choices
          }
        } else {
          // Regular deadline and time logic
          const selectedTime = deadlineDate ? new Date(deadlineDate) : new Date();
          const hours = timeValue instanceof Date ? timeValue.getHours() : 0;
          const minutes = timeValue instanceof Date ? timeValue.getMinutes() : 0;
          selectedTime.setHours(hours);
          selectedTime.setMinutes(minutes);

          if (deadlineDate && deadlineDate.toDateString() === currentDate.toDateString()) {
            // Deadline is today
            if (selectedTime.getTime() >= currentDate.getTime()) {
              if (this.selectionData && this.selectionData.length > 0) {
                this.newSelectionFormFiedls.statusSetupId = 447; // Pending: Available choices
              } else {
                this.newSelectionFormFiedls.statusSetupId = 433; // Pending: No choices
              }
            } else {
              this.newSelectionFormFiedls.statusSetupId = 440; // Expired
            }
          } else if (deadlineDate && deadlineDate < currentDate) {
            // Deadline is in the past
            this.newSelectionFormFiedls.statusSetupId = 440; // Expired
          } else {
            // Deadline is in the future
            if (this.selectionData && this.selectionData.length > 0) {
              this.newSelectionFormFiedls.statusSetupId = 447; // Pending: Available choices
            } else {
              this.newSelectionFormFiedls.statusSetupId = 433; // Pending: No choices
            }
          }
        }

        // Update status code reference
        this.statusCode = this.getStatusCode(this.newSelectionFormFiedls.statusSetupId);

        // Save and handle resolution
        this.saveSelection('saveAndRelease')
          .then(() => {
            this.isReleased = true; // Set release state after successful save
            this.changeDetectorRef.detectChanges(); // Ensure UI updates
            resolve();
          })
          .catch((error) => {
            console.error('Error during saveAndRelease:', error);
            reject(error); // Handle errors gracefully
          });
      } catch (error) {
        console.error('Unexpected error in saveAndRelease:', error);
        reject(error); // Catch unexpected errors
      }
    });
  }





  // processSelectionResponse(response: any) {
  //   this.selectionReponse = response.result?.Id;
  //   this.newSelectionFormFiedls.globalId = response.result.GlobalId;
  //   this.newSelectionFormFiedls.statusSetupId = response.result.StatusName;
  //   this.messagingId = this.selectionReponse ? this.selectionReponse : this.SelectionInformation2;
  //   this.messagingIdRow = this.selectionReponse ? this.selectionReponse : this.SelectionInformation2;
  //   this.JobInfoId = response.result?.JobInformationId;
  //   this.SelectionHeaderId = this.selectionReponse;
  // }


  onCancelUnrelease(): void {
    this.isConfirmUnreleaseVisible = false;
    this.changeDetectorRef.detectChanges();
  }

  confirmUnrelease(): void {
    console.log('Setting status to Unreleased');
    this.newSelectionFormFiedls.statusSetupId = SelectionStatusCode.UnReleased.statusid;
    this.statusCode = {
      code: SelectionStatusCode.UnReleased.code,
      description: SelectionStatusCode.UnReleased.description,
      id: SelectionStatusCode.UnReleased.statusid
    };
    this.isReleased = false;  // Update state to indicate it is unreleased
    this.isConfirmUnreleaseVisible = false;

    console.log('Current statusCode:', this.statusCode);
    this.changeDetectorRef.detectChanges();

    // Save the new selection status
    this.saveSelection('save')
      .then(() => {
      })
      .catch((error) => {
        console.error('Error during unrelease:', error);
      });
  }


  private getStatusCode(status: string) {
    switch (status) {
      case "Released":
        return SelectionStatusCode.UnReleased;
      case "UnReleased":
        return SelectionStatusCode.UnReleased;

      case "PendingNoChoice: No Choices":
        return SelectionStatusCode.PendingNoChoice;
      case "PendingAvailable: No Choices":
        return SelectionStatusCode.PendingAvailable;
      case "ExpiredAvailable: No Choices":
        return SelectionStatusCode.ExpiredAvailable;
      case "ExpiredNoChoices: No Choices":
        return SelectionStatusCode.ExpiredNoChoices;
      default:
        return null; // Handle other statuses as needed
    }
  }



  saveFileIdToDatabase(fileId: string) {
  }

  saveAndCloseSelection(): Promise<void> {
    return this.saveSelection('saveAndClose')
      .then(() => {
        this.resetForm(); // Reset form after save and close
        this.SelectionFromCancel(); // Trigger close functionality
        this.resetFlags(); // Reset flags after success
      })
      .catch((error) => {
        console.error('Error during save and close:', error);
        this.resetFlags(); // Reset flags in case of error
      });
  }

  saveAndNewSelection(): Promise<void> {
    return this.saveSelection('saveAndNew')
      .then(() => {
        this.selectionReponse = undefined; // Clear activity response for a new entry
        this.resetForm(); // Reset form for new entry
        this.resetFlags(); // Reset flags after success
      })
      .catch((error) => {
        console.error('Error during save and new:', error);
      });
  }


  SelectionFromCancel(): void {
    this.selectionModelhandle = false; // Set this to false to close the modal
    this.cancel.emit(); // Emit the cancel event
  }

  resetFlags() {
    this.isSave = false;
    this.isSaveAndClose = false;
    this.isSaveAndNew = false;
    this.issaveAndReleaseve = false; // Added to reset the new flag
    this.isSaveAndUnrelease = false; // Added to reset the new flag
  }

  resetForm(): void {
    this.newSelectionFormFiedls = {
      ...this.newSelectionFormFiedls, // Spread to retain existing fields
      globalId: '00000000-0000-0000-0000-000000000000',
      selectionSetupId: 0,
      title: '',
      isSingle: true,
      isShared: true,
      statusSetupId: 0,
      sharedAllowanceId: 0,
      isRequireOwnerToMakeSelection: false,
      catagorySetupId: 0,
      locationSetupId: 0,
      singleAllowanceNumber: '',
      isChooseDate: true,
      isLinkedToScheduleItem: false,
      scheduleItemParameterId: 0,
      dueNumberDays: null,
      isBefore: true,
      isAfter: false,
      dedlineOn: null,
      time: null,
      isApproveChoicesOnly: true,
      isAddEditAndApproveChoices: false,
      publicInstructions: '',
      internalNotes: '',
      isAllowMultipleSelectedChoices: true,
      isAuto: true,
      isManual: true,
      isApprove: true,
      isDecline: true,

      createSelectionSubVenderParameterRequests: [], // Reset this if necessary
      createSelectionInstallerParameterRequests: [], // Reset this if necessary
      // Keep jobInformationId and any other fields intact
      jobInformationId: this.newSelectionFormFiedls.jobInformationId
    };
  }

  initialFormFieldValues: any = {};
  setInitialFormFieldValues(): void {
    this.initialFormFieldValues = JSON.parse(JSON.stringify(this.newSelectionFormFiedls));
  }


  get exceededCharacters(): number {
    const nameControl = this.CategoryField.get('name');
    if (nameControl && nameControl.errors && nameControl.errors['maxlength']) {
      const currentLength = nameControl.value.length;
      const maxLength = nameControl.errors['maxlength'].requiredLength;
      return currentLength - maxLength;
    }
    return 0;
  }

  onGetByIdRes: SelectionResponse;

  Newpacth(selectionResponses: SelectionResponse) {
    this.dataSetForselection = selectionResponses?.JobName;
    if (!selectionResponses) {
      console.error('No selection response data available for patching.');
      return;
    }
    this.onGetByIdRes = selectionResponses;
    this.JobInfoId = selectionResponses?.JobInformationId;
    this.SelectionHeaderId = selectionResponses?.Id;
    this.Messaging_Id = selectionResponses?.MessagingId;
    this.setStatusInfo(selectionResponses?.StatusSetupId);
    const DataSet = selectionResponses;
    this.newSelectionFormFiedls = {
      ...this.newSelectionFormFiedls,
      title: DataSet?.Title,
      globalId: DataSet?.GlobalId || '00000000-0000-0000-0000-000000000000',
      selectionSetupId: DataSet?.SelectionSetupId,
      jobInformationId: DataSet?.JobInformationId,
      // jobInformationId: DataSet?.JobInformationId || this.newSelectionFormFiedls.get('jobInformationId')?.value,
      isSingle: DataSet?.IsSingle ?? true,
      isShared: DataSet?.IsShared ?? true,
      sharedAllowanceId: DataSet?.SharedAllowanceId,
      isRequireOwnerToMakeSelection: DataSet?.IsRequireOwnerToMakeSelection ?? false,
      locationSetupId: DataSet?.LocationSetupId,
      catagorySetupId: DataSet?.CatagorySetupId,
      singleAllowanceNumber: DataSet?.SingleAllowanceNumber,
      isChooseDate: DataSet?.IsChooseDate ?? true,
      isLinkedToScheduleItem: DataSet?.IsLinkedToScheduleItem,
      scheduleItemParameterId: DataSet?.ScheduleItemParameterId,
      dueNumberDays: DataSet?.DueNumberDays,
      statusSetupId: DataSet.StatusName,
      isBefore: DataSet?.IsBefore ?? true,
      isAfter: DataSet?.IsAfter ?? false,
      dedlineOn: DataSet?.DedlineOn || null,
      time: DataSet?.Time || null,
      attachmentId: DataSet?.AttachmentId,
      isApproveChoicesOnly: DataSet?.IsApproveChoicesOnly ?? true,
      isAddEditAndApproveChoices: DataSet?.IsAddEditAndApproveChoices ?? false,
      publicInstructions: DataSet?.PublicInstructions,
      internalNotes: DataSet?.InternalNotes,
      isAllowMultipleSelectedChoices: DataSet?.IsAllowMultipleSelectedChoices ?? true,
      isAuto: DataSet?.IsAuto ?? true,
      isManual: DataSet?.IsManual ?? true,
      isApprove: DataSet?.IsApprove ?? true,
      isDecline: DataSet?.IsDecline ?? true,
    };

    this.fetchAttachments(DataSet?.AttachmentId);
    console.log("show all patch time", DataSet.MessagingId);

  }



  fetchAttachments(AttachmentId: number) {
    if (AttachmentId) {
      this._AttachmentService.getLeadByAttachmentId(AttachmentId).subscribe(
        (res) => {
          this.TestAttachment = res.result;

        },
      );
    } else {
      this.TestAttachment = undefined;
    }
  }
  Hold_selection_Status: string;

  fetchSelectionMainResponse(_Id_selection: number): void {
    this.selectionService.SelectionGetbyId(_Id_selection)?.subscribe((res) => {
      if (res && res.result) {
        // Check if the result has a valid Id
        if (res.result?.Id > 0) {
          this.fetchActivityResponseChoice(res.result?.Id);
        }

        // Cast the result to SelectionResponse type
        this.selectionResponses = res.result as SelectionResponse;

        // Hold the StatusName in a variable
        this.Hold_selection_Status = this.selectionResponses.StatusName;

        // Log the Status Name if it exists
        if (this.Hold_selection_Status) {
          console.log('Hold_selection_Status:', this.Hold_selection_Status);
        }

        // Perform other actions
        this.Newpacth(this.selectionResponses);
        this.fetchAttachments(this.selectionResponses.AttachmentId);
        this.newSelectionFormFiedls.patchValue(this.newSelectionFormFiedls?.value);
        this.initializationComplete.emit(false);
      } else {
        console.error('Invalid response from API:', res);
      }
    });
  }



  isImageExtensionValid(extension: string): boolean {
    const validExtensions = ['jpg', 'jpeg', 'png', 'gif', 'svg'];
    return validExtensions.includes(extension.toLowerCase());
  }


  formattedFilePath: string = '';
  set: string;
  isSetPriceLater: boolean = false;
  isRequestFromSubVendor: boolean = false;

  fetchActivityResponseChoice(id: number): void {
    this.selectionService.getChoiceBy(this.newSelectionId)?.subscribe((res) => {
      console.log('response', res.result);
      if (Array.isArray(res.result)) {
        this.selectionData = res.result;
      } else if (res.result) {
        this.selectionData = [res.result];
      } else {
        this.selectionData = [];
      }

      if (Array.isArray(res.result) && res.result.length > 0) {
        this.selectedChoice = res.result[0].Id;
        this.CHOICE_GlobalId = res.result[0].GlobalId;
        this.Approvalcomments = res.result[0].ApprovalNotes;
        this.isSetPriceLater = res.result[0].IsSetPriceLater;
        this.isRequestFromSubVendor = res.result[0].IsRequestFromSubVendor;
      } else if (res.result && res.result.Id) {
        this.selectedChoice = res.result.Id;
        this.CHOICE_GlobalId = res.result.GlobalId;
        this.isSetPriceLater = res.result.IsSetPriceLater;
        this.isRequestFromSubVendor = res.result.IsRequestFromSubVendor;
      }

      // Reset fileDetails for all items
      this.fileDetails = [];
      this.selectionData.forEach((item) => {
        const filePaths = item.FilePath ? item.FilePath.split(', ') : [];

        // Handle file count and file details
        item.fileCount = filePaths.length;
        if (filePaths.length > 0) {
          const firstFilePath = filePaths[0];
          const extension = firstFilePath.split('.').pop().toLowerCase();
          item.fileDetails = [{ filePath: `${this.IMG_BUCKET_URL}${firstFilePath}`, extension }];
        } else {
          // No files, set fileDetails to an empty array
          item.fileDetails = [];
        }
        // Handling multiple file paths (if necessary)
        item.allFilePaths = filePaths.map((filePath) => {
          const extension = filePath.split('.').pop().toLowerCase();
          return { filePath: `${filePath}`, extension };
        });
      });
    });
  }



  CNChoicesradioButtons(value: string) {
    this.CNChoicesradioValue = value;
  }
  deleteLeadConfirm(): void {
    if (this.isDeleteLoading) {
      return;
    }
    this.modal.confirm({
      nzTitle: `Delete Selection?`,
      nzContent: 'Are you sure you want to permanently delete this Selection?',
      nzOkText: `Delete Selection `,
      nzOkDanger: true,
      nzCancelText: 'Cancel',
      nzOnOk: () => {
        this.isDeleteLoading = true;
        return this.deleteActivity(this.newSelectionFormFiedls.globalId)
          .then(() => {
            this.LeadActivityNewLeadModelCancel();
          })
          .catch(() => {

          });
      },
      nzCentered: true,
      nzOkLoading: this.isDeleteLoading,
      nzBodyStyle: { 'border-radius': '50px', 'height': 'auto', 'padding-top': '15' },
      nzCloseIcon: '',
      nzIconType: ''
    });
  }


  deleteActivity(globalId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!globalId) {
        this.toastsService.error('No record selected for deletion.');
        this.isDeleteLoading = false;
        reject();
        return;
      }

      this.selectionService.deleteSelectionData(globalId).subscribe(
        () => {
          this.isDeleteLoading = false;
          this.toastsService.success(`Deleted User!`);
          this.onSaveComplete.emit(this.selectionResponses);
          this.leadActivityFromCancel();
          resolve();
          reject();
        },
        () => {
          this.isDeleteLoading = false;
          this.toastsService.error('An error occurred while deleting the record. Please try again.');
          reject();
        }
      );
    });
  }

  leadActivityFromCancel(): void {
    this.cancel.emit();
    this.selectionModelhandle = false;
    this.leadOpportunitiesService.clearSelectedFiles();
  }

  LeadActivityNewLeadModelCancel(): void {
    this.cancel.emit();
    this.selectionModelhandle = false;
    this.leadOpportunitiesService.clearSelectedFiles();
  }

  validateNumberLength(event: KeyboardEvent): void {
    const input = event.target as HTMLInputElement;
    if (input.value.length >= 14 && event.key !== 'Backspace') {
      event.preventDefault();
    }
  }

  // sufiyan Error Show
  readonly validationRules = {
    title: {
      required: 'Required',
      maxLength: { limit: 50, message: 'Template Name exceeds the limit.' }
    }
  };
  getFieldLabel(field: string): string {
    const labels: { [key: string]: string } = {
      title: ' Title',
    };
    return labels[field] || field;
  }
  checkErrors(validateAllFields = false) {
    this.validationStates = {};
    this.validationMessages = {};
    const fieldsToValidate = validateAllFields ? Object.keys(this.validationRules) : Array.from(this.changedFields);
    for (const field of fieldsToValidate) {
      if (this.validationRules.hasOwnProperty(field)) {
        const errorTip = this.getErrorTip(field);
        if (errorTip) {
          this.validationStates[field] = 'error';
          this.validationMessages[field] = errorTip;
        } else {
          this.validationStates[field] = null;
          this.validationMessages[field] = '';
        }
      }
    }
  }
  get errorFields(): { field: string; label: string; message: string }[] {
    return Object.keys(this.validationStates)
      .filter(field => this.validationStates[field] === 'error')
      .map(field => ({
        field,
        label: this.getFieldLabel(field),
        message: this.validationMessages[field]
      }));
  }
  getErrorTip(field: string): string {
    const rules = this.validationRules[field];
    const input = this.newSelectionFormFiedls[field] || '';
    if (rules.required && !input.trim()) {
      return `Required.`;
    }
    if (rules.maxLength && input.length > rules.maxLength.limit) {
      const excessLength = input.length - rules.maxLength.limit;
      const unit = excessLength === 1 ? 'character' : 'characters';
      return `${excessLength} ${unit} over.`;
    }
    return '';
  }
  validateChangedFields(field: string) {
    this.changedFields.add(field); // Add field to the changed fields set
    this.checkErrors(); // Validate changed fields
  }
  onFieldChange(field: string, value: any) {
    if (field === 'dedlineOn') {
      const formattedDate = value.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      });
      this.dueDate = formattedDate;
    }
    this.newSelectionFormFiedls[field] = value;
    this.changedFields.add(field);
    this.checkErrors();
  }
  createAndModifiedByData(id: number) {
    const payload: FilterFooterAudit = {
      id: id,
      formCode: 'PMSCV',
    }
    this.FooterService.createdAndModifyUser(payload).subscribe(res => {
      this.footerResponse = res.result;
    })
  }
  // sufiyan Error Show

  shareSingleradioButtons() {
    switch (this.shareSingleradioValue) {
      case 'A':
        this.showSelector = true
        this.ShowSelector2 = false
        break;
      case 'B':
        this.showSelector = false
        this.ShowSelector2 = true
        break;
    }
  }
  chooseAddLinkradioButtons() {
    switch (this.chooseAddLinkradioValue) {
      case 'A':
        this.showDateFields = true;
        this.showScheduleItemFields = false;
        break;
      case 'B':
        this.showScheduleItemFields = true;
        this.showDateFields = false;
        break;
    }
  }

  OpenAproveStatus() {
    this.Approve = true;

  }
  OpenAproveStatuscancel() {
    this.Approve = false;
    this.cancel.emit()

  }

  iniliazeFormApproveForm() {
    this.ApproveForm = this.fb.group({
      id: [''],
      globalId: ['00000000-0000-0000-0000-000000000000'],
      ApprovalComments: ['', [Validators.required, Validators.maxLength(50)]],
    });
  }

  // approve Working
  handleApprove() {
    if (this.timeUpdated) {
      this.saveFormWithUpdatedTime();
    } else {
      this.instantlyHandleSelectionResponses();
    }
  }
  saveFormWithUpdatedTime() {
    console.log("Time has been updated. Saving the form with new time...");
    this.selectionResponses = this.newChoiceFormFiedls?.isApproved; // Assuming 'time' is a field in selectionResponses
    this.ApproveStatus();
    this.Approve = false;

  }
  instantlyHandleSelectionResponses() {
    console.log("Time has not been updated. Handling approval...");
    this.ApproveStatus();
    this.Approve = false;
  }
  ApproveStatus() {
    this.isButtonDisabled = true;

    const selectionData: SelectionInformationApprove = {
      selectionInformationId: this.selectionResponses?.Id,
      selectionChoiceParameterId: this.selectedChoice,
      approvalNotes: this.ApproveForm.value.ApprovalComments,
      statusId: 441
    };
    this.selectionService.PostApprove(selectionData).subscribe({
      next: (response) => {
        this.toastService.success('Approval successful');
        this.Approvehide = response?.result?.selectionChoiceParameterStatus;
        this.fetchSelectionMainResponse(this.selectionReponse || this.newSelectionId || this.selectionResponses?.Id);
        this.selectionInformationStatus = response.result.selectionInformationStatus;
        this.selectionChoiceParameterStatus = response.result.selectionChoiceParameterStatus;
        this.isApproved = true;
        this.cdr.detectChanges();
      },
      error: (error) => {
        console.error('Approval failed:', error);
        this.isButtonDisabled = false;
      }
    });
  }



  onFilesUploaded(files: CustomUploadFile[]): void {
    this.selectedFilesAttachment = files
  }

  limitToThreeDigits() {
    let value = this.newSelectionFormFiedls['dueNumberDays'];

    // If the value exceeds 3 digits, set it to 999
    if (value && value.toString().length > 3) {
      this.newSelectionFormFiedls['dueNumberDays'] = 999;
    }
  }

  toggleSelectAll(): void {
    this.cardChecked = this.selectAll;
  }
  onCardCheckboxChange(): void {
    if (!this.cardChecked) {
      this.selectAll = false;
    }
  }
  // // schedule Open And Selected Method
  // newScheduleOpen(): void {
  //   const modalRef = this.modal.create({
  //     nzContent: NewscheduleItemComponent,
  //     nzFooter: null,
  //     nzTitle: '<h1 nz-typography>Add New Phase</h1>',
  //     nzWidth: '30%',
  //     nzBodyStyle: {
  //       'background-color': '#f1f4fa',
  //       'padding': '20px',
  //     },
  //     nzStyle: { top: '10px' },
  //     nzClosable: true,
  //     nzMaskClosable: true,

  //   });

  //   modalRef.componentInstance.selectedJobId = this._AccessSelectlocalStorage.getJobId();
  //   modalRef.componentInstance.selectedJobName = this._AccessSelectlocalStorage.getJobName();
  //   modalRef.componentInstance.selectedColorId = this._AccessSelectlocalStorage.getJobColorId();


  //   modalRef.componentInstance.cancel.subscribe(() => {
  //     modalRef.destroy();
  //     if (this.selectedJobId) {
  //       this.getScheduleByJob(this.selectedJobId);
  //     }
  //   });

  //   modalRef.componentInstance.scheduleSaved.subscribe(async (schedule: ResponseScheduleItemResult) => {
  //     if (this.selectedJobId) {
  //       await this.getScheduleByJob(this.selectedJobId);
  //       // this.todoForm.patchValue({ scheduleItemParameterId: schedule.Id });
  //       this.newSelectionFormFiedls.scheduleItemParameterId = schedule?.Id
  //     }
  //     modalRef.destroy();
  //   });

  // }

  // newScheduleEdit(): void {
  //   const selectedScheduleItemId = this.newSelectionFormFiedls.scheduleItemParameterId;
  //   const selectedScheduleItem = this.selectionRes.find(res => res.id === selectedScheduleItemId);

  //   if (selectedScheduleItemId > 0 && selectedScheduleItem) {
  //     const modalRef = this.modal.create({
  //       nzContent: NewscheduleItemComponent,
  //       nzFooter: null,
  //       nzTitle: '<h1 nz-typography>Edit Phase</h1>',
  //       nzWidth: '30%',
  //       nzBodyStyle: {
  //         'background-color': '#f1f4fa',
  //         'padding': '20px',
  //       },
  //       nzStyle: { top: '10px' },
  //       nzClosable: true,
  //       nzMaskClosable: true,
  //       nzOnCancel: () => {
  //         modalRef.componentInstance.onCancel();
  //       }
  //     });

  //     // Pass data to the modal instance
  //     modalRef.componentInstance.scheduleId = selectedScheduleItemId;
  //     modalRef.componentInstance.selectedJobId = this._AccessSelectlocalStorage.getJobId();
  //     modalRef.componentInstance.selectedJobName = this._AccessSelectlocalStorage.getJobName();
  //     modalRef.componentInstance.selectedColorId = this._AccessSelectlocalStorage.getJobColorId();


  //     modalRef.componentInstance.cancel.subscribe(() => {
  //       modalRef.destroy();
  //       if (this.selectedJobId) {
  //         this.getScheduleByJob(this.selectedJobId);

  //       }
  //     });

  //     modalRef.componentInstance.scheduleSaved.subscribe(async (schedule: ResponseScheduleItemResult) => {
  //       if (this.selectedJobId) {
  //         await this.getScheduleByJob(this.selectedJobId);
  //         // this.todoForm.patchValue({ scheduleItemParameterId: schedule.Id });
  //         this.newSelectionFormFiedls.scheduleItemParameterId = schedule?.Id
  //       }
  //       modalRef.destroy();
  //     });


  //     modalRef.componentInstance.deleteScheduleModal.subscribe(async () => {
  //       if (this.selectedJobId) {
  //         await this.getScheduleByJob(this.selectedJobId);
  //         // this.todoForm.patchValue({ scheduleItemParameterId: 0 });
  //         this.newSelectionFormFiedls.scheduleItemParameterId = 0
  //       }
  //       modalRef.destroy();
  //     });
  //   }
  // }






  onOpenScheduleItem(selectedValue: any): void {
    const modalRef = this.modal.create({
      nzContent: NewscheduleItemComponent,
      nzFooter: null,
      nzTitle: '<h1 nz-typography>Add New Phase</h1>',
      nzWidth: '30%',
      nzBodyStyle: {
        'background-color': '#f1f4fa',
        'padding': '20px',
      },
      nzStyle: { top: '10px' },
      nzClosable: true,
      nzMaskClosable: true,
      nzOnCancel: () => {
        modalRef.componentInstance.onCancel();
      }
    });

    // Pass initial data to the modal
    modalRef.componentInstance.scheduleId = selectedValue;
    modalRef.componentInstance.selectedJobId = this._AccessSelectlocalStorage.getJobId();
    modalRef.componentInstance.selectedJobName = this._AccessSelectlocalStorage.getJobName();
    modalRef.componentInstance.selectedColorId = this._AccessSelectlocalStorage.getJobColorId();

    // Handle the cancel event from the modal
    modalRef.componentInstance.cancel.subscribe(async () => {
      await this.loadUpdatedScheduleList(); // Call a method to load and update the schedule list
      modalRef.destroy();
    });

    // Handle the event when a schedule item is saved
    modalRef.componentInstance.scheduleSaved.subscribe(async (data: ResponseScheduleItemResult | boolean) => {
      if (typeof data === 'boolean') {
        if (data === true) {
          this.newSelectionFormFiedls.scheduleItemParameterId = null; // Reset if saved successfully
        }
      } else {
        if (data.Id === 0) {
          this.newSelectionFormFiedls.scheduleItemParameterId = null; // Reset if no valid ID
        } else {
          this.newSelectionFormFiedls.scheduleItemParameterId = data.Id; // Update with the new ID
        }
      }

      // After save, refresh the list to reflect the latest changes
      await this.loadUpdatedScheduleList();
    });
  }

  // Helper method to load the updated schedule list and reset selection
  private async loadUpdatedScheduleList(): Promise<void> {
    await this.getScheduleByJob(this.jobID);

    // After reloading, update the scheduleItemParameterId as needed
    if (this.scheduleList.length === 0) {
      this.newSelectionFormFiedls.scheduleItemParameterId = null; // Reset if no items
    } else {
      // Optionally, set to the first item in the updated list
      this.newSelectionFormFiedls.scheduleItemParameterId = this.scheduleList[0]?.Id || null;
    }

    this.changeDetectorRef.detectChanges(); // Ensure changes are detected
  }



  // onGetAllSchedule() {
  //   this.selectionService.getAllDataScheduleItem().subscribe(
  //     (res) => {
  //       const onDataSetInSchedule = res.result?.filter(item => item?.jobInformationId === this._AccessSelectlocalStorage.getJobId());
  //       this.selectionRes = onDataSetInSchedule;
  //     });
  // }
  isEditButtonDisabled(): boolean {
    const selectedValues = this.newSelectionFormFiedls.scheduleItemParameterId;
    return !selectedValues || (Array.isArray(selectedValues) && selectedValues.length !== 1);
  }





  getScheduleByJob(jobId: number): Promise<void> {
    return new Promise((resolve, reject) => {
      this._scheduleService.getAllPredecessorsDataScheduleItem(jobId).subscribe({
        next: res => {
          this.scheduleList = res.result;
          this.changeDetectorRef.detectChanges();
          resolve(); // Resolve the promise after the schedule list is updated
        },
        error: err => reject(err) // Reject in case of error
      });
    });
  }

  // loadApi() {
  //   this.selectionService.getAllDataScheduleItem().subscribe(
  //     (res) => {
  //       this.selectionRes = res.result;
  //     });
  // }





  DaysradioButtons() {
    switch (this.DaysradioValue) {
      case 'A':
        this.newSelectionFormFiedls.isAfter = false
        this.newSelectionFormFiedls.isBefore = true;
        break;
      case 'B':
        this.newSelectionFormFiedls.isBefore = false
        this.newSelectionFormFiedls.isAfter = true;
        break;
    }
    this.updateDueDate();
    this.onFieldScheduleItem();
  }


  onFieldScheduleItem() {
    // Find the selected schedule item based on `scheduleItemParameterId`
    const selectedScheduleItem = this.scheduleList.find(item => item.Id === this.newSelectionFormFiedls.scheduleItemParameterId);
    const start_On = selectedScheduleItem?.StartOn;
    const end_On = selectedScheduleItem?.EndOn;

    if (this.newSelectionFormFiedls.scheduleItemParameterId && selectedScheduleItem) {
      this.ScheduleItem_start_end_On = true;

      // Calculate the date dynamically based on "Before" or "After" selection
      const baseDate = this.DaysradioValue === 'A' ? end_On : start_On;
      this.newSelectionFormFiedls.dedlineOn = this.calculateDate(baseDate, this.daysInput, this.DaysradioValue === 'B' ? -1 : 1);

    } else {
      this.ScheduleItem_start_end_On = false;
      this.newSelectionFormFiedls.dedlineOn = null;
    }

    this.updateDueDate();
  }

  daysInput: number = 0;
  calculateDate(baseDate: Date | undefined, days: number, direction: number): Date | null {
    if (!baseDate) return null;
    const resultDate = new Date(baseDate);
    resultDate.setDate(resultDate.getDate() + days * direction);
    return resultDate;
  }

  onDaysChange(value: number) {

    if (!isNaN(value) && value <= 999) {
      this.newSelectionFormFiedls.dueNumberDays = value;
    } else if (!isNaN(value) && value > 999) {
      this.newSelectionFormFiedls.dueNumberDays = 999;
    } else {
      this.newSelectionFormFiedls.dueNumberDays = 0;
    }
    this.updateDueDate();
  }

  validateNumberInput(event: KeyboardEvent): void {
    const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Tab'];
    const isNumberKey = event.key >= '0' && event.key <= '9';

    if (!isNumberKey && !allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  }

  // updateDueDates() {
  //   let dueOn = this.newSelectionFormFiedls.dedlineOn;
  //   if (typeof dueOn === 'string') {
  //     dueOn = new Date(dueOn);
  //   }
  //   if (dueOn instanceof Date && !isNaN(dueOn.getTime())) {
  //     let newDate = new Date(dueOn);
  //     if (this.DaysradioValue === 'A') {
  //       newDate.setDate(newDate.getDate() - this.newSelectionFormFiedls.dueNumberDays);
  //     } else if (this.DaysradioValue === 'B') {
  //       newDate.setDate(newDate.getDate() + this.newSelectionFormFiedls.dueNumberDays);
  //     }
  //     const formattedDate = newDate.toLocaleDateString('en-US', {
  //       year: 'numeric',
  //       month: 'short',
  //       day: 'numeric'
  //     });
  //     this.dueDateTest = formattedDate;
  //     this.newSelectionFormFiedls.dedlineOn = newDate;

  //   } else {
  //     this.dueDateTest = null;
  //     this.newSelectionFormFiedls.dedlineOn = null;
  //   }
  // }
  updateDueDate() {
    let dueOn = this.newSelectionFormFiedls.dedlineOn;

    if (typeof dueOn === 'string') {
      dueOn = new Date(dueOn);
    }

    if (dueOn instanceof Date && !isNaN(dueOn.getTime())) {
      let newDate = new Date(dueOn);

      if (this.DaysradioValue === 'A') {
        newDate.setDate(newDate.getDate() - this.newSelectionFormFiedls.dueNumberDays);
      } else if (this.DaysradioValue === 'B') {
        newDate.setDate(newDate.getDate() + this.newSelectionFormFiedls.dueNumberDays);
      }

      const formattedDate = newDate.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      });

      this.dueDateTest = formattedDate;
      this.newSelectionFormFiedls.dedlineOn = newDate;

    } else {
      this.dueDateTest = null;
      this.newSelectionFormFiedls.dedlineOn = null;
    }
  }
  onFieldisComplete(field: string, selectedId: number) {
    switch (field) {
      case 'scheduleItemParameterId':
        const selectedScheduleItem = this.scheduleList.find(option => option.Id === selectedId);
        this.scheduleItem = selectedScheduleItem?.Title;
        this.onFieldScheduleItem();
        break;
      default:
    }
  }
  radioButtons() {
    switch (this.radioValue) {
      case 'A':
        this.newSelectionFormFiedls.isLinkToScheduleItem = false
        this.newSelectionFormFiedls.isChooseDate = true;
        break;
      case 'B':
        this.newSelectionFormFiedls.isChooseDate = false
        this.newSelectionFormFiedls.isLinkToScheduleItem = true;
        break;
    }
  }

  resetVendorSelectedModal(): void {
    this.modal.confirm({
      nzTitle: 'Reset Selection to Pending?',
      nzContent: 'Are you sure you want to reset this selection to pending?',
      nzOkText: 'Reset',
      nzOnOk: () => {
        console.log('Okay reset');
        return this.saveSelection('resetToPending')
          .then(() => {
            console.log('Selection reset successfully');
          })
          .catch((error) => {
            console.error('Failed to reset selection:', error);
          })
          .finally(() => {
            // this.fetchSelectionMainResponse(this.newSelectionId);
          });
      },
      nzCancelText: 'Cancel',
      nzOnCancel: () => {
        console.log('Cancel');
        // Fetch activity response when Cancel is clicked, after the modal closes
      },
      nzClassName: 'ant-modal-confirm ant-modal-body',
      nzBodyStyle: { 'border-radius': '30px', 'height': 'auto', 'padding-top': '15px' },
      nzStyle: { top: '200px' },
      nzCloseIcon: '',
      nzIconType: '',
    });
  }







  newCategorySelectionOpen(): void {
    const modalRef = this.modal.create({
      nzContent: CategorySelectComponent,
      nzFooter: null,
      nzTitle: '<h1 nz-typography>Add Selection Category</h1>',
      nzWidth: '35%',
      nzBodyStyle: {
        'background-color': '#f1f4fa',
        padding: '20px',
      },
      nzStyle: { top: '10px' },
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.close();
    });
    modalRef.componentInstance.categorySaved.subscribe((data: LookupNameSetupResponse) => {
      console.log('data', data);
      if (data) {
        this.categoryNZSelectLoad().then(() => {
          this.newSelectionFormFiedls.catagorySetupId = data.id;
        }).catch(error => {
          console.error('Error loading data:', error);
        });
      }
    });
  }

  newCategorySelectionEdit(): void {
    const selectedCategoryId = this.newSelectionFormFiedls?.catagorySetupId;
    const selectedCategory = this.categoryOption.find(option => option.id === selectedCategoryId);
    if (selectedCategoryId > 0 && selectedCategory) {
      const modalRef = this.modal.create({
        nzContent: CategorySelectComponent,
        nzFooter: null,
        nzTitle: '<h1 nz-typography>Edit Selection Category</h1>',
        nzWidth: '35%',
        nzBodyStyle: {
          'background-color': '#f1f4fa',
          padding: '20px',
        },
        nzStyle: { top: '10px' },
        nzClosable: true,
        nzMaskClosable: true,
      });
      modalRef.componentInstance.selectedEditCategory = selectedCategory;
      modalRef.componentInstance.cancel.subscribe(() => {
        modalRef.destroy();
      });
      modalRef.componentInstance.phaseSaved.subscribe(() => {
        this.categoryNZSelectLoad().then(() => {
          modalRef.destroy();
        });
      });
      modalRef.componentInstance.categorySaved.subscribe((data: LookupNameSetupResponse) => {
        console.log('data', data);
        if (data) {
          this.categoryNZSelectLoad().then(() => {
            this.newSelectionFormFiedls.catagorySetupId = data.id;
          }).catch(error => {
            console.error('Error loading data:', error);
          });
        }
      });
      modalRef.componentInstance.categoryDeleted.subscribe(() => {
        this.categoryNZSelectLoad().then(() => {
          this.newSelectionFormFiedls.catagorySetupId = 71389;
          modalRef.destroy();
        }).catch(error => {
          console.error('Error loading data:', error);
        });
      });
    }
  }
  newLocationSelectionOpen(): void {
    const modalRef = this.modal.create({
      nzContent: LocationSelectComponent,
      nzFooter: null,
      nzTitle: '<h1 nz-typography>Add Selection Location</h1>',
      nzWidth: '35%',
      nzBodyStyle: {
        'background-color': '#f1f4fa',
        padding: '20px',
      },
      nzStyle: { top: '10px' },
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.close();
    });
    modalRef.componentInstance.LocationSaved.subscribe((data: LookupNameSetupResponse) => {
      console.log('Darar', data);

      if (data) {
        this.locationNZSelectLoad().then(() => {
          this.newSelectionFormFiedls.locationSetupId = data.id;
        }).catch(error => {
          console.error('Error loading data:', error);
        });
      }
    });
  }

  newLocationSelectionEdit(): void {
    const selectedLocationId = this.newSelectionFormFiedls?.locationSetupId;
    const selectedLocation = this.locationOption.find(option => option.id === selectedLocationId);
    if (selectedLocationId > 0 && selectedLocation) {
      const modalRef = this.modal.create({
        nzContent: LocationSelectComponent,
        nzFooter: null,
        nzTitle: '<h1 nz-typography>Edit Selection Location</h1>',
        nzWidth: '35%',
        nzBodyStyle: {
          'background-color': '#f1f4fa',
          padding: '20px',
        },
        nzStyle: { top: '10px' },
        nzClosable: true,
        nzMaskClosable: true,
      });
      modalRef.componentInstance._selectedEditLocation = selectedLocation;
      modalRef.componentInstance.cancel.subscribe(() => {
        modalRef.destroy();
      });
      modalRef.componentInstance.phaseSaved.subscribe(() => {
        this.locationNZSelectLoad().then(() => {
          modalRef.destroy();
        });
      });
      modalRef.componentInstance.LocationSaved.subscribe((data: LookupNameSetupResponse) => {
        console.log('data', data);
        if (data) {
          this.locationNZSelectLoad().then(() => {
            this.newSelectionFormFiedls.locationSetupId = data.id;
          }).catch(error => {
            console.error('Error loading data:', error);
          });
        }
      });
      modalRef.componentInstance.locationDeleted.subscribe(() => {
        this.categoryNZSelectLoad().then(() => {
          this.newSelectionFormFiedls.locationSetupId = 392;
          modalRef.destroy();
        }).catch(error => {
          console.error('Error loading data:', error);
        });
      });
    }
  }

  categoryNZSelectLoad(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.LookupFilterService.getLookUpValuesByFormNameId(this.pageId).subscribe(
        (res) => {
          this.lookupSetNameResponse = res.result;
          let _CategorySelectionValues = res.result.filter(
            (x) => x.code === this.preDefinedDataCodes.Categories.code
          );
          this.categoryOption = _CategorySelectionValues
          this.setInitialFormFieldValues();
          resolve();
        },
        (error) => {
          console.error('Error loading lookup values:', error);
          reject(error);
        }
      );
    });
  }
  locationNZSelectLoad(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.LookupFilterService.getLookUpValuesByFormNameId(this.pageId).subscribe(
        (res) => {
          this.lookupSetNameResponse = res.result;
          let _LocationSelectionValues = res.result.filter(
            (x) => x.code === this.preDefinedDataCodes.Locations.code
          );
          this.locationOption = _LocationSelectionValues
          this.setInitialFormFieldValues();
          resolve();
        },
        (error) => {
          console.error('Error loading lookup values:', error);
          reject(error);
        }
      );
    });
  }

}

interface ModalTitle {
  title?: string;
  deleteTitle?: string; // Optional property for cases where deleteTitle is not needed
}
interface CardChoice {
  id: number;
  title?: string;
  description?: string;
  ownerPrice?: string;
  attachmentId?: AttachmentResponse[]
  attachmentParameters?: AttachmentParameterResponse[];
}

type SimpleOption = {
  id: string;
  name: string;
};
