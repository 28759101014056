import { Component, OnInit } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { CostCodeCostCategoryComponent } from '../cost-code-cost-category/cost-code-cost-category.component';
import { CosCodeModelComponent } from '../cos-code-model/cos-code-model.component';

@Component({
  selector: 'app-cost-code',
  templateUrl: './cost-code.component.html',
  styleUrls: ['./cost-code.component.css']
})
export class CostCodeComponent implements OnInit {
  listOfData: any[] = [];
  nodes = [];

  constructor(private modal: NzModalService,) { }

  ngOnInit(): void {
  }
  onCategoryOpen(): void {
    const modalRef = this.modal.create({
      nzWidth: '0%',
      nzContent: CostCodeCostCategoryComponent,
      nzFooter: null,
      nzClosable: false,
      nzMask: true,
      nzMaskStyle: {
        display: 'none',
      },
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  onCostCodeOpen(): void {
    const modalRef = this.modal.create({
      nzContent: CosCodeModelComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
}
