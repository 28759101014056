import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DailyLogDashboard } from 'src/Models/Project-Management/projectManagement';

@Component({
  selector: 'app-commment-conversation',
  templateUrl: './commment-conversation.component.html',
  styleUrls: ['./commment-conversation.component.css']
})
export class CommmentConversationComponent implements OnInit {

  @Output() cancel = new EventEmitter<void>();
  JobInfoId: number;
  conversationId: number;
  Messaging_Id: number;



  takeResponseDynamic: any;
  formNameId: number;
  componentTypeDynamic: string;
  componentTitleDynamic: string;
  componentSelectedJobName: string;


  
  constructor() { }

  ngOnInit(): void {

    console.log('JobInfoId Warranty',this.JobInfoId);
    
    console.log(this.takeResponseDynamic);
    this.JobInfoId = this.takeResponseDynamic.JobInformationId;
    this.conversationId = this.takeResponseDynamic.Id;
    this.Messaging_Id = this.takeResponseDynamic.MessagesIds || this.takeResponseDynamic.MessagingId;


  }

  onMessageSaved(): void {
   this.cancel.emit();
  }

  getFormattedTitle(title: string): string {
    const date = new Date(title);
    return isNaN(date.getTime()) ? title : date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  }
  





}


