

<ng-template #extraTemplate>
  <nz-space>
    <button *nzSpaceItem nz-button nzType="default">
      Launch Takeoff
    </button>
    <button *nzSpaceItem nz-button nzType="default" nz-dropdown
      nzTrigger="click" [nzDropdownMenu]="Import">
      Import
      <span nz-icon nzType="down"></span>
    </button>
    <button *nzSpaceItem nz-button nzType="default"
      nz-dropdown
      nzTrigger="click" [nzDropdownMenu]="addForm">Add Form
      <span nz-icon nzType="down"></span>

    </button>
    <button *nzSpaceItem nz-button nzType="primary">Add New Cost</button>
  </nz-space>
  <nz-dropdown-menu #addForm="nzDropdownMenu">
    <ul nz-menu>
      <li nz-menu-item>menu1 1st menu item</li>
      <li nz-menu-item>menu1 2nd menu item</li>
      <li nz-menu-item>menu1 3rd menu item</li>
    </ul>
  </nz-dropdown-menu>
  <nz-dropdown-menu #Import="nzDropdownMenu">
    <ul nz-menu>
      <li nz-menu-item>menu1 1st menu item</li>
      <li nz-menu-item>menu1 2nd menu item</li>
      <li nz-menu-item>menu1 3rd menu item</li>
    </ul>
  </nz-dropdown-menu>

</ng-template>

<nz-card nzTitle="Proposal Worksheet" nzBorderless="true"
  [nzExtra]="extraTemplate">

  <div nz-row nzAlign="middle" class="p-1">
    <nz-col [nzSpan]="3">
      <nz-statistic
        [nzValue]="totalOwnerPrice === '0.00' ? '--' : (totalOwnerPrice | currency)"
        [nzTitle]="'Total Owner Price'">
      </nz-statistic>

    </nz-col>
    <nz-col [nzSpan]="3">
      <nz-statistic
        [nzValue]="totalBuilderCost === '0.00' ? '--' : (totalBuilderCost | currency)"
        [nzTitle]="'Total Builder Cost'">
      </nz-statistic>

    </nz-col>
    <nz-col [nzSpan]="2">
      <nz-statistic
        [nzValue]="totalProfit === '0.00' ? '--' : (totalProfit | currency)"
        [nzTitle]="'Estimated Profit'">
      </nz-statistic>

    </nz-col>
    <nz-col [nzSpan]="1" *ngIf="totalOwnerPrice > '0.00' || totalBuilderCost > '0.00' || totalProfit > '0.00' ">
      <button nz-button nzType="text" nz-popover [nzPopoverContent]="costItemCalculation"  nzPopoverPlacement="bottom"
       ><span
       nzPopoverTrigger="click" nz-icon
       nzType="info-circle" nzTheme="outline" class="fs-5">
       </span></button>
    </nz-col>
  </div>

  <ng-template #costItemCalculation>
    <ul nz-list nzSize="default" style="width: 200px;">

      <li nz-list-item nzNoFlex>
        <div nz-row>
          <div nz-col [nzSpan]="18">Total Builder Cost</div>
          <div nz-col [nzSpan]="6" class="text-end">{{totalBuilderCost | currency}}</div>
        </div>
      </li>

      <!-- Total Markup -->
      <li nz-list-item nzNoFlex>
        <div nz-row>
          <div nz-col [nzSpan]="18">Total Markup</div>
          <div nz-col [nzSpan]="6" class="text-end">{{totalMarkup  | currency}}</div>
        </div>
      </li>

      <!-- Total Owner Price -->
      <li nz-list-item nzNoFlex>
        <strong nz-row>
          <div nz-col [nzSpan]="18">Total Owner Price</div>
          <div nz-col [nzSpan]="6" class="text-end">{{totalOwnerPrice | currency}}</div>
        </strong>
      </li>

      <!-- Total Margin % -->
      <li nz-list-item nzNoFlex>
        <div nz-row>
          <div nz-col [nzSpan]="18">Total Margin %</div>
          <div nz-col [nzSpan]="6" class="text-end">{{totalMargin  | currency}}</div>
        </div>
      </li>
    </ul>
  </ng-template>

  <nz-table
    [nzTitle]="CostItemHeader"
    #rowSelectionTable
    #editRowTable
    #fixedTable
    [nzData]="listOfData"
    nzSize="middle"
    [nzScroll]="{ x: '2100px', y: '700px' }"
    [nzPaginationType]="null"
    [nzPageSizeOptions]="null"
    [nzNoResult]="' '"
    [nzPaginationPosition]="null">

    <thead>
      <tr>
        <th class="align-top"
          nzLeft nzWidth="3%" [nzDisabled]="listOfData?.length === 0"
          [(nzChecked)]="checked"
          [nzIndeterminate]="indeterminate"
          (nzCheckedChange)="onAllChecked($event)">
        </th>
        <th nzWidth="17%" nzLeft>Title</th>
        <th nzWidth="17%">Description</th>
        <th nzWidth="8%">Quantity</th>
        <th nzWidth="6%">Unit</th>
        <th nzWidth="10%">Unit Cost</th>
        <th nzWidth="12%">Cost Type</th>
        <th nzWidth="6%">Builder Cost</th>
        <th nzWidth="8%">Markup</th>
        <th nzWidth="8%">Owner Price</th>
        <th nzWidth="6%">Margin</th>
        <th nzWidth="6%">Profit</th>
        <th nzWidth="6%" nzRight></th>
      </tr>
    </thead>

    <tbody>
      <tr>
        <td colspan="13" class="mt-1">
          <button nz-button nzType="link"
            class="d-flex align-items-center rounded-1" (click)="addRow()">
            <i nz-icon nzType="plus-circle-fill" class="fs-5"
              nzTheme="fill"></i>
            <strong class="ms-1">Item</strong>
          </button>
        </td>

      </tr>
      <tr *ngFor="let data of listOfData" class="editable-row"
        [ngClass]="{ 'new-row': data.isNew, 'error-row-background': hasRowErrors(data.id) }">

        <td nzLeft class="align-top">
          <label nz-checkbox
            [ngModel]="setOfCheckedId.has(data.id)"
            (ngModelChange)="onItemChecked(data.id, $event)">
          </label>
        </td>

        <td nzLeft class="align-top">
          <div class="editable-field">
            <!-- Read-only view for Title and Cost Code if costCodeId > 0 and not in edit mode -->
            <div *ngIf="data.isTitleAndCostCodeReadOnly">
              <a class="TitleAndCostCodeReadonlyCell">
                <b class="editable-cost-item ms-1">{{ data.title || '--'
                  }}</b><br />
                <span class="editable-cost-item"> {{
                  getCostTypeLabel(data.costCodeId) }}</span>
              </a>
            </div>

            <!-- Editable Title field if allowed by isFieldEditable -->
            <div class="editable-field">
              <div *ngIf="!data.isTitleAndCostCodeReadOnly">
                <label [hidden]="editId !== data.id"
                  class="editable-label">Title</label>
                <nz-form-item [hidden]="editId !== data.id">
                  <nz-form-control
                    [nzErrorTip]="getErrorMessage(data.id, 'title')"
                    [nzValidateStatus]="getValidationStatus(data.id, 'title')">
                    <input type="text" nz-input [(ngModel)]="data.title"
                      name="title" (ngModelChange)="validateRow(data)" />
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div *ngIf="!data.isTitleAndCostCodeReadOnly">
                <label [hidden]="editId !== data.id"
                  class="editable-label mt-4">Cost Code <strong
                    class="text-danger">*</strong></label>
                <nz-form-item [hidden]="editId !== data.id">
                  <nz-form-control
                    [nzErrorTip]="getErrorMessage(data.id, 'costCodeId')"
                    [nzValidateStatus]="getValidationStatus(data.id, 'costCodeId')">
                    <nz-select [(ngModel)]="data.costCodeId" nzShowSearch
                      name="costCodeId" required
                      (ngModelChange)="validateRow(data)">
                      <nz-option nzLabel="Jack" [nzValue]="1"></nz-option>
                      <nz-option nzLabel="Lucy" [nzValue]="2"></nz-option>
                      <nz-option nzLabel="Tom" [nzValue]="3"></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
          </div>
        </td>

        <td class="align-top">
          <label *ngIf="editId === data.id"
            class="editable-label">Description</label>
          <div class="editable-cell" [hidden]="editId === data.id"
            (click)="startEdit(data.id,data)">
            {{ data.description || '--' }}
          </div>
          <nz-form-item [hidden]="editId !== data.id">
            <nz-form-control
              [nzErrorTip]="getErrorMessage(data.id, 'description')"
              [nzValidateStatus]="getValidationStatus(data.id, 'description')">
              <textarea
                style="resize: none;"
                [nzAutosize]="{ minRows: 2, maxRows: 8 }"
                nz-input
                [(ngModel)]="data.description"
                name="description"
                (ngModelChange)="validateRow(data)"></textarea>
            </nz-form-control>
          </nz-form-item>

          <div class="editable-space"
            [ngClass]="{ 'hidden': editId !== data.id }"></div>

        </td>

        <td class="align-top">
          <label *ngIf="editId === data.id"
            class="editable-label">Quantity</label>
          <div class="editable-cell" [hidden]="editId === data.id"
            (click)="startEdit(data.id,data)">
            {{ data.quantity }}
          </div>
          <nz-form-item [hidden]="editId !== data.id">
            <nz-form-control
              [nzErrorTip]="getErrorMessage(data.id, 'quantity')"
              [nzValidateStatus]="getValidationStatus(data.id, 'quantity')">
              <nz-input-number
                style="width: 100%;"
                [(ngModel)]="data.quantity"
                name="quantity"
                (ngModelChange)="onQuantityChange(data)"
                (ngModelChange)="validateRow(data)"
                (ngModelChange)="onFieldChange(data)"
                [nzMin]="0.0000"
                [nzMax]="100000000000000.0000"
                [nzStep]="1.0000"
                [nzPrecision]="4"></nz-input-number>
            </nz-form-control>
          </nz-form-item>

          <div class="editable-space"
            [ngClass]="{ 'hidden': editId !== data.id }"></div>
        </td>

        <td class="align-top">
          <label *ngIf="editId === data.id"
            class="editable-label">Unit</label>
          <div class="editable-cell" [hidden]="editId === data.id"
            (click)="startEdit(data.id,data)">
            {{ data.unit || '--' }}
          </div>
          <nz-form-item [hidden]="editId !== data.id">
            <nz-form-control
              [nzErrorTip]="getErrorMessage(data.id, 'unit')"
              [nzValidateStatus]="getValidationStatus(data.id, 'unit')">
              <input type="text" nz-input
                name="unit"
                (ngModelChange)="validateRow(data)"
                [(ngModel)]="data.unit" />
            </nz-form-control>
          </nz-form-item>
          <div class="editable-space"
            [ngClass]="{ 'hidden': editId !== data.id }"></div>
        </td>

        <td class="align-top">
          <label *ngIf="editId === data.id" class="editable-label">Unit
            Cost</label>
          <div class="editable-cell" [hidden]="editId === data.id"
            (click)="startEdit(data.id,data)">
            {{ data.unitCost | currency }}
          </div>
          <nz-form-item *ngIf="editId === data.id">
            <nz-form-control>
              <nz-input-group nzAddOnBefore="$">
                <input
                  appDollarFormatter
                  type="text"
                  nz-input
                  [(ngModel)]="data.unitCost"
                  name="unitCost"
                  (ngModelChange)="onFieldChange(data)" />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>

          <div class="editable-space"
            [ngClass]="{ 'hidden': editId !== data.id }"></div>
        </td>

        <td class="align-top">
          <div class="editable-field ">
            <label *ngIf="editId === data.id" class="editable-label">Cost
              Type</label>
            <div class="editable-cell" [hidden]="editId === data.id"
              (click)="startEdit(data.id,data)">
              {{ getCostTypeLabel(data.costTypeId) }}
              <br />
              <nz-tag>
                <span *ngIf="data.markAsId">{{ getMarkAsLabel(data.markAsId)
                  }}</span>
              </nz-tag>
            </div>

            <nz-form-item [hidden]="editId !== data.id">
              <nz-form-control>
                <nz-select
                  nz-input
                  [(ngModel)]="data.costTypeId"
                  name="costTypeId"
                  nzShowSearch>
                  <nz-option *ngFor="let type of costType"
                    [nzLabel]="type.name" [nzValue]="type.id"></nz-option>

                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div class="editable-field mt-4">
            <label *ngIf="editId === data.id" class="editable-label">Mark
              As</label>
            <nz-form-item [hidden]="editId !== data.id">
              <nz-form-control>
                <nz-select
                  nzPlaceHolder="None"
                  nz-input
                  [(ngModel)]="data.markAsId"
                  name="markAsId"
                  nzShowSearch>
                  <nz-option *ngFor="let mark of markAsMap"
                    [nzLabel]="mark.name"
                    [nzValue]="mark.id"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>

        </td>

        <td class="align-top">
          <label *ngIf="editId === data.id" class="editable-label">Builder
            Cost</label>
          <div class="editable-cell" [hidden]="editId === data.id"
            (click)="startEdit(data.id,data)">
            {{ data.builderCost | currency }}
          </div>
          <nz-form-item [hidden]="editId !== data.id">
            <nz-form-control>
              <div  nz-input
                  [(ngModel)]="data.builderCost"
                  name="builderCost"
                 >
                  <strong> {{ data.builderCost | currency }}</strong>
                </div>
              <!-- <input nz-input disabled
                [(ngModel)]="data.builderCost"
                name="builderCost" /> -->
            </nz-form-control>
          </nz-form-item>
          <div class="editable-space"
            [ngClass]="{ 'hidden': editId !== data.id }"></div>
        </td>

        <td class="align-top">
          <label *ngIf="editId === data.id"
            class="editable-label">Markup</label>
          <div class="editable-cell" [hidden]="editId === data.id"
            (click)="startEdit(data.id,data)">
            {{ data.markupValue }}
          </div>
          <nz-form-item *ngIf="editId === data.id">
            <nz-form-control>
              <nz-input-group nzCompact
                class="d-flex">
                <nz-input-group
                  *ngIf="data.markupId === 2 || data.markupId === 3"
                  nzAddOnBefore="$">
                  <input
                    appMarkup
                    nz-input
                    [(ngModel)]="data.markupValue"
                    name="markup"
                    (ngModelChange)="onFieldChange(data)"
                    [disabled]="isDisabledMarkup(data.markupId)"
                    style="width: 100%;" />
                </nz-input-group>

                <nz-input-group *ngIf="data.markupId === 4" nzAddOnBefore="$">
                  <input
                    appMarkup
                    nz-input
                    [(ngModel)]="data.markupValue"
                    name="markup"
                    (ngModelChange)="onFieldChange(data)"
                    disabled
                    style="width: 100%;" />
                </nz-input-group>
                <nz-input-group
                  *ngIf="data.markupId !== 2 && data.markupId !== 3 && data.markupId !== 4">
                  <input
                    appMarkup
                    nz-input
                    [(ngModel)]="data.markupValue"
                    name="markup"
                    (ngModelChange)="onFieldChange(data)"
                    [disabled]="isDisabledMarkup(data.markupId)"
                    style="width: 100%;" />
                </nz-input-group>
                <nz-select [(ngModel)]="data.markupId"
                  name="markupId"
                  (ngModelChange)="onChangeMarkupDropdown(data,data.markupId)"
                  style="width: 40%;">
                  <nz-option *ngFor="let markup of markupValues"
                    [nzLabel]="markup.name" [nzValue]="markup.id"></nz-option>

                </nz-select>
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>

          <div class="editable-space"
            [ngClass]="{ 'hidden': editId !== data.id }"></div>
        </td>

        <td class="align-top">
          <label *ngIf="editId === data.id" class="editable-label">Owner
            Price</label>
          <div class="editable-cell" [hidden]="editId === data.id"
            (click)="startEdit(data.id,data)">
            {{ data.ownerPrice | currency }}
          </div>
          <nz-form-item *ngIf="editId === data.id">
            <nz-form-control>
              <nz-input-group nzAddOnBefore="$">

                <input
                  name="ownerPrice"
                  nz-input
                  [(ngModel)]="data.ownerPrice"
                  [disabled]="!isDisabledMarkup(data.markupId)"
                  style="width: 100%;" />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
          <div class="editable-space"
            [ngClass]="{ 'hidden': editId !== data.id }"></div>
        </td>
        <td class="align-top">
          <label *ngIf="editId === data.id"
            class="editable-label">Margin</label>
          <div class="editable-cell" [hidden]="editId === data.id"
            (click)="startEdit(data.id,data)">
            {{ data.margin }}%
          </div>
          <nz-form-item *ngIf="editId === data.id">
            <nz-form-control>
              <nz-input-group nzAddOnAfter="%">
                <input
                  appMargin
                  nz-input
                  name="margin"
                  [(ngModel)]="data.margin"
                  [disabled]="isDisabledMarkup(data.markupId)"
                  style="width: 100%;" />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
          <div class="editable-space"
            [ngClass]="{ 'hidden': editId !== data.id }"></div>
        </td>

        <td class="align-top text-center">
          <label *ngIf="editId === data.id"
            class="editable-label">Profit</label>
          <div class="editable-cell" [hidden]="editId === data.id"
            (click)="startEdit(data.id,data)">
            {{ data.profit | currency }}
          </div>

          <nz-form-item [hidden]="editId !== data.id">
            <nz-form-control>
              <div
                  [(ngModel)]="data.profit" name="profit">
                  {{ data.profit | currency }}
                </div>
              <!-- <input
                disabled
                nz-input
                name="profit"
                [(ngModel)]="data.profit"
                style="width: 100%;" /> -->
            </nz-form-control>
          </nz-form-item>
          <div class="editable-space"
            [ngClass]="{ 'hidden': editId !== data.id }"></div>
        </td>

        <td nzRight class="text-center">
          <a nz-popover nzPopoverContent="Delete"
            (click)="ConfirmDeleteItem(data.id)" nz-icon nzType="delete"
            nzTheme="fill" class="fs-5 text-primary"></a>
        </td>
      </tr>
    </tbody>

    <tfoot nzSummary="true" nzFixed nzFooter class="fixed-summary-footer">
      <tr>
        <th nzLeft nzWidth="3%"></th>
        <th nzWidth="17%" nzLeft></th>
        <th nzWidth="17%"></th>
        <th nzWidth="8%"></th>
        <th nzWidth="6%"></th>
        <th nzWidth="10%"></th>
        <th nzWidth="12%"></th>
        <th class="text-end" nzWidth="6%">
          <span class="total-Amount">{{ totalBuilderCost | currency }} </span>
        </th>
        <th nzWidth="8%"></th>
        <th nzWidth="8%">
          <span class="total-Amount">{{ totalOwnerPrice | currency }}</span>
          <!-- Display Total Owner Price -->
        </th>
        <th nzWidth="6%"></th>
        <th class="text-center" nzWidth="6%">
          <span class="total-Amount">{{ totalProfit | currency }}</span>
          <!-- Display Total Profit -->
        </th>
        <th nzWidth="6%" nzRight></th>
      </tr>
    </tfoot>

  </nz-table>

</nz-card>

<ng-template #CostItemHeader>
  <div nz-row nzAlign="middle">
    <div nz-col nzSpan="8">
      <nz-space>
        <button *nzSpaceItem nz-button nzType="default"
          nz-dropdown
          [disabled]="setOfCheckedId.size === 0"
          nzTrigger="click" [nzDropdownMenu]="CheckedAction">Checked Action
          <span nz-icon nzType="down"></span>
        </button>
        <button *nzSpaceItem nz-button nzType="default"
          nz-dropdown
          nzTrigger="click" [nzDropdownMenu]="costItemGroup">Group
          <span nz-icon nzType="down"></span>
        </button>
      </nz-space>
      <nz-dropdown-menu #CheckedAction="nzDropdownMenu">
        <ul nz-menu>
          <li nz-menu-item>Adjust Percentage Markup</li>
          <li nz-menu-item (click)="ConfirmDeleteItemByChecked()">Delete
            Checked</li>
        </ul>
      </nz-dropdown-menu>
      <nz-dropdown-menu #costItemGroup="nzDropdownMenu">
        <ul nz-menu>
          <li nz-menu-item>None</li>
          <li nz-menu-item>Cost Category</li>
          <li nz-menu-item>Custom</li>

        </ul>
      </nz-dropdown-menu>
    </div>
    <div nz-col nzSpan="8" nzOffset="8" class="text-end">
      <nz-space>
        <a nz-popover nzPopoverContent="Download Excel" *nzSpaceItem
          class="fs-4" nz-icon nzType="download" nzTheme="outline"></a>
        <a nz-popover nzPopoverContent="Edit Columns" *nzSpaceItem class="fs-4"
          nz-icon nzType="setting" nzTheme="fill"></a>
      </nz-space>
    </div>
  </div>
</ng-template>

<!-- <ng-template #CostItemFooter>
  <div style="width: 100%; overflow-x: auto;">

  </div>
</ng-template> -->
