<nz-modal class="shadow" nzWidth="90%"
  [(nzVisible)]="selectionVisible"
  nzTitle="Selection"
  [nzFooter]="selectionFooter"
  [nzBodyStyle]="{background: '#f1f4fa',padding: '13px','max-height': '74vh','overflow-x': 'hidden'}"
  (nzOnCancel)="onCancel()" [nzStyle]="{ top: '15px' }">

  <div *nzModalContent>

    <form nz-form  [formGroup]="selectionSetupForm" >

      <nz-card class="mb-3" nzTitle="Default Viewing & Notification Options"
        nzBorderless>
        <div nz-row>
          <div nz-col [nzSpan]="4">
            <label class=" me-2">Default Builder view</label>
            <nz-form-item>
              <nz-form-control>
                <nz-select nzShowSearch formControlName="selectionBuilderViewId">
                  <nz-option *ngFor="let role of DefaultBuilderview"
                    [nzLabel]="role.name" [nzValue]="role.id"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div nz-row>

          <div nz-col [nzSpan]="4">
            <label class=" me-2">Default Owner view</label>
            <nz-form-item>
              <nz-form-control>
                <nz-select nzShowSearch
                formControlName="selectionOwnerViewId">
                  <nz-option *ngFor="let role of DefaultOwnerview"
                    [nzLabel]="role.name" [nzValue]="role.id"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div nz-row>
          <div nz-col [nzSpan]="24">
            <nz-form-item>
              <nz-form-control>
                <label nz-checkbox
                  id="defaultToExpandCategoryLocationView"
                  name="defaultToExpandCategoryLocationView"
                  formControlName="defaultToExpandCategoryLocationView">
                  Default to expand Category & Location view
                </label>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>

        <div nz-row>
          <div nz-col>
            <nz-form-item>
              <nz-form-control>
                <label class="d-flex align-items-center justify-content-center">
                  <span>Alert users</span>
                  <nz-input-number 
                    formControlName="alertUserNumber" 
                    [(ngModel)]="demoValue"
                    [nzMin]="0" 
                    [nzMax]="10"
                    class="mx-2">
                  </nz-input-number>
                  <span>day(s) before upcoming deadline.</span>
                </label>
              </nz-form-control>
              
            </nz-form-item>
          </div>
        </div>
      </nz-card>

      <nz-card class="mb-3" nzTitle="Allowances" nzBorderless>
        <div nz-row>
          <div nz-col [nzSpan]="24">
            <nz-form-item>
              <nz-form-control>
                <label nz-checkbox
                  id="allowancesOnNewJob"
                  name="allowancesOnNewJob"
                  formControlName="allowancesOnNewJob">
                  Allowances on new jobs
                </label>
                <i class="bi bi-info-circle-fill text-secondary ms-1" nz-popover
                  [nzPopoverContent]="setting" nzPopoverPlacement="top"></i>
                <ng-template #setting>
                  <div class="rounded-2">
                    <p style="height: 70px; width: 210px; font-size: 13px;">
                      This setting does not apply when creating a job from a
                      template that has allowances enabled, in which case
                      allowances will be included.
                    </p>
                  </div>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>

        <div nz-row>
          <div nz-col [nzSpan]="24">
            <nz-form-item>
              <nz-form-control>
                <label nz-checkbox
                formControlName="showAllowancesToSubsVendors"
                  id="showAllowancesToSubsVendors"
                  name="showAllowancesToSubsVendors">
                  Show allowances to Subs/Vendors
                </label>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </nz-card>

      <nz-card class="mb-3" nzTitle="Owner Options" nzBorderless>
        <div nz-row>
          <div nz-col [nzSpan]="24">
            <nz-form-item>
              <nz-form-control>
                <label nz-checkbox
                  id="showVendorContactInfoToOwner"
                  formControlName="showVendorContactInfoToOwner"
                  name="showVendorContactInfoToOwner">
                  Show Vendor contact info to Owner
                </label>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>

        <div nz-row>
          <div nz-col [nzSpan]="24">
            <nz-form-item>
              <nz-form-control>
                <label nz-checkbox
                formControlName="showLineItemsToOwner"
                  id="showLineItemsToOwner"
                  name="showLineItemsToOwner">
                  Show Line Items to Owner
                </label>
                <i class="bi bi-info-circle-fill text-secondary ms-1" nz-popover
                  [nzPopoverContent]="enable" nzPopoverPlacement="top"></i>
                <ng-template #enable>
                  <div class="rounded-2">
                    <p style="height: 230px; width: 210px; font-size: 13px;">
                      This setting will enable the "Show Line Items to Owner"
                      flag on all new Selection Choice by default. This can be
                      individually enabled/disabled on each Selection Choice.
                      <br>
                      <br>
                      Description, Quantity, Unit Price and Price will be shown
                      for each line item, but the Owner will Not be permitted to
                      see builder cost Or markup.
                    </p>
                  </div>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>

        <div nz-row>
          <div nz-col [nzSpan]="24">
            <nz-form-item>
              <nz-form-control>
                <label nz-checkbox
                formControlName="defaultToAllowOwnerToPeekAtLockedSelectionsForAllNewJobs"
                  id="defaultToAllowOwnerToPeekAtLockedSelectionsForAllNewJobs"
                  name="defaultToAllowOwnerToPeekAtLockedSelectionsForAllNewJobs">
                  Default To Allow Owner To "Peek" at Locked Selections For All
                  New Jobs
                </label>
                <i class="bi bi-info-circle-fill text-secondary ms-1" nz-popover
                  [nzPopoverContent]="This" nzPopoverPlacement="top"></i>
                <ng-template #This>
                  <div class="rounded-2">
                    <p style="height: 175px; width: 210px; font-size: 13px;">
                      This setting will allow the Owner To view "Locked"
                      Selections on new jobs And can be Set from either the Job
                      Settings Or Selections Setup pages. Allowing Owners To
                      view "Locked" Selections can also be set on an individual
                      job basis On the Job Owner page.
                    </p>
                  </div>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </nz-card>

      <nz-card class="mb-3" nzTitle="Approval Options" nzBorderless>
        <div nz-row>
          <div nz-col [nzSpan]="24">
            <nz-form-item>
              <nz-form-control>
                <label nz-checkbox
                formControlName="onNewJobsUpdateJobRunningTotalWhenASelectionIsApproved"
                  id="onNewJobsUpdateJobRunningTotalWhenASelectionIsApproved"
                  name="onNewJobsUpdateJobRunningTotalWhenASelectionIsApproved">
                  On New Jobs Update Job Running Total When a Selection Is
                  Approved
                </label>
                <i class="bi bi-info-circle-fill text-secondary ms-1" nz-popover
                  [nzPopoverContent]="ChangeOrder" nzPopoverPlacement="top"></i>
                <ng-template #ChangeOrder>
                  <div class="rounded-2">
                    <p style="height: 240px; width: 210px; font-size: 13px;">
                      This setting will be the Default For all Of your New Jobs.
                      If [(ngModel)]="checked" the Job Running Total will update
                      When either a Selection Or a Change Order Is approved
                      throughout the job.
                      <br>
                      <br>
                      If UN [(ngModel)]="checked" the Job Running Total will
                      ONLY be updated by Approved Change Orders. You will need
                      To create a Change Order To account For any selection
                      allowance overages.
                      <br>
                      <br>
                      This setting can be changed For all New jobs In the Jobs
                      Settings Setup Or Selections Setup.
                    </p>
                  </div>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>

        <div nz-row>
          <div nz-col [nzSpan]="5">
            <label class="me-2">Require Signature on Selections</label>
            <nz-form-item>
              <nz-form-control>
                <nz-select nzShowSearch formControlName="requireSignatureOnSelectionSetupId"
                 >
                  <nz-option *ngFor="let role of RequireSignatureonSelections"
                    [nzLabel]="role.name" [nzValue]="role.id"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>

        <div nz-row>
          <div nz-col [nzSpan]="24">
            <label class="me-2">Selections approval disclaimer</label>
            <br>
            <span nzValue="small">I confirm that my action here represents my electronic signature and is binding.</span>
            <br>
            <!-- Change Button (Hidden when Text Area is shown) -->
            <button *ngIf="!showTextArea" nz-button (click)="toggleTextArea()">Change</button>
            <!-- Text Area (Hidden by Default) -->
            <nz-form-item *ngIf="showTextArea">
              <nz-form-control>
                <ckeditor
                formControlName="selectionsApprovalDisclaimer"
                name="selectionsApprovalDisclaimer"
                [editor]="Editor"
                [config]="config"
                class="size"
                id="editor"
              >I confirm that my action here represents my electronic signature and is binding.</ckeditor>
              </nz-form-control>
            </nz-form-item>
        
          </div>
        </div>
        
        

      </nz-card>

      <nz-card class="mb-3" nzTitle="Display On Printout" nzBorderless>
        <div nz-row [nzGutter]="16">
          <div nz-col [nzSpan]="12">
            <nz-form-item>
              <nz-form-control>
                <label nz-checkbox formControlName="approvedChoicesOnly" id="approvedChoicesOnly" name="approvedChoicesOnly">
                  Approved Choices Only
                </label>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="12">
            <nz-form-item>
              <nz-form-control>
                <label nz-checkbox formControlName="pricing" id="pricing" name="pricing">
                  Pricing
                </label>
              </nz-form-control>
            </nz-form-item>
          </div>
      
          <div nz-col [nzSpan]="12">
            <nz-form-item>
              <nz-form-control>
                <label nz-checkbox formControlName="subsVendors" id="subsVendors" name="subsVendors">
                  Subs/Vendors
                </label>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="12">
            <nz-form-item>
              <nz-form-control>
                <label nz-checkbox formControlName="addedBy" id="addedBy" name="addedBy">
                  Added By
                </label>
              </nz-form-control>
            </nz-form-item>
          </div>
      
          <div nz-col [nzSpan]="12">
            <nz-form-item>
              <nz-form-control>
                <label nz-checkbox formControlName="installers" id="installers" name="installers">
                  Installers
                </label>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="12">
            <nz-form-item>
              <nz-form-control>
                <label nz-checkbox formControlName="choiceDescription" id="choiceDescription" name="choiceDescription">
                  Choice Description
                </label>
              </nz-form-control>
            </nz-form-item>
          </div>
      
          <div nz-col [nzSpan]="12">
            <nz-form-item>
              <nz-form-control>
                <label nz-checkbox formControlName="selectionDescription" id="selectionDescription" name="selectionDescription">
                  Selection Description
                </label>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="12">
            <nz-form-item>
              <nz-form-control>
                <label nz-checkbox formControlName="lineItems" id="lineItems" name="lineItems">
                  Line Items
                </label>
              </nz-form-control>
            </nz-form-item>
          </div>
      
          <div nz-col [nzSpan]="12">
            <nz-form-item>
              <nz-form-control>
                <label nz-checkbox formControlName="discussions" id="discussions" name="discussions">
                  Discussions
                </label>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="12">
            <nz-form-item>
              <nz-form-control>
                <label nz-checkbox formControlName="customFields" id="customFields" name="customFields">
                  Custom Fields
                </label>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </nz-card>
      

    </form>
  </div>

  <ng-template #selectionFooter>
    <button nz-button nzType="primary"  (click)="saveSelections()">
      Save
    </button>
  </ng-template>
</nz-modal>
