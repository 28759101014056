import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { CostItemResponseChild, CreateCostItem } from 'src/Models/costitem/costitem.modal';
import { CreateCostItemRequest, CostItemResponse } from 'src/Models/LeadProposal/LeadProposalModels';
import { ResponseModel } from 'src/Models/responseMessage.model';

@Injectable({
  providedIn: 'root'
})
export class CostItemService {

  constructor(private http: HttpClient) {}

  private COST_ITEM_URL: string = environment.apiUrl + 'CostItem/';


  postCostItem(CostItem: CostItemResponse): Observable<ResponseModel<CostItemResponse>> {
    const apiUrl = `${this.COST_ITEM_URL}`;
    return this.http.post<any>(apiUrl, CostItem).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }

  getDataIdCostItem(id: number): Observable<ResponseModel<CostItemResponse>> {
    return this.http.get<ResponseModel<CostItemResponse>>(`${this.COST_ITEM_URL}getBy-Id?id=${id}`);
  }


  getDataIdCostItemCommon(id: number): Observable<ResponseModel<CostItemResponseChild>> {
    return this.http.get<ResponseModel<CostItemResponseChild>>(`${this.COST_ITEM_URL}getBy-Id?id=${id}`);
  }



  postCommonCostItem(postCostItem:CreateCostItem): Observable<any>{
    return this.http.post<CreateCostItem>(this.COST_ITEM_URL, postCostItem);
  }


}
