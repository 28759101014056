<nz-modal
  nzWidth="40%"
  [(nzVisible)]="uploadfiles"
  [nzTitle]="UploadFiles1"
  [nzFooter]="UploadFiles2"
  (nzOnCancel)="UploadFiles()"
  [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '70vh', 'overflow-y': 'auto'}"
  [nzStyle]="{ top: '18px' }"
>
  <ng-template #UploadFiles1>
    <div class="row">
      <h2 class="p-0 m-0 react">Upload Files</h2>
    </div>
  </ng-template>
  <div class="content pb-3" *nzModalContent>
    <div class="card border-0">
      <div class="card-body mb-2">
        <div class="d-flex align-content-center justify-content-center pt-3">
          <span style="font-size: 60px; border-radius: 6px;" nz-icon nzType="file" nzTheme="outline"></span>
        </div>
        <div class="row">
          <div class="d-flex align-content-center justify-content-center pt-2">
            <h3 style="font-size: 16px;" class="react">Drop files here to upload, or</h3>
          </div>
        </div>
        <div class="row">
          <div class="d-flex align-content-center justify-content-center pt-3">
            <div nz-row>
              <div nz-col nzSpan="12">
                <form action="/action_page.php">
                  <button (click)="chooseFile()" nz-button class="button-primary react m-0 p-0"><span>Browse Computer</span></button>
                  <input type="file" id="files" name="files" (change)="onFileSelected($event)" multiple style="display: none;">
                </form>
              </div>
              <div nz-col nzSpan="12">
                <button nz-button class="button-default react ms-0"><span>Browse Buildertrend</span></button>
              </div>
            </div>
          </div>
        </div>
        <div class="row pt-2 pb-2 Content-BT-Header" style="row-gap: 0px;" *ngIf="fileList.length > 0">
          <div class="col-6">
            <span class="react">{{ fileList.length }} File</span>
          </div>
          <div class="col-6 text-end">
            <a nz-button nzType="link" (click)="clearAll()" class="react">Clear All</a>
          </div>
        </div>
        <div class="row" *ngFor="let file of fileList">
          <div class="col-12">
            <div class="row pt-2 pb-2" style="row-gap: 0px;">
              <div class="col-1">
                <span *ngIf="file.loading" nz-icon nzType="loading" nzTheme="outline"></span>
                <span *ngIf="!file.loading && file.error" nz-icon nzType="close-circle" nzTheme="fill" [ngStyle]="{'color': '#d9534f'}"></span>
                <span *ngIf="!file.loading && !file.error" nz-icon nzType="check" nzTheme="outline" [ngStyle]="{'color': '#31ce8d'}"></span>
              </div>
              <div class="col-2">
                <span *ngIf="getFileIcon(file)"
                      style="font-size: 24px;"
                      class="d-flex justify-content-center align-content-center"
                      [ngStyle]="{'color': getFileIcon(file)?.color}"
                      nz-icon [nzType]="getFileIcon(file)?.type"
                      nzTheme="fill"></span>
                <div class="image-wrapper">
                  <img *ngIf="!getFileIcon(file)" [src]="file.thumbUrl" style="max-height: 50px; max-width: 50px; object-fit: cover;" />
                </div>
              </div>
              <div class="col-5">
                <div class="text-overflow-auto">
                  <span class="react"> {{ file.name }}</span>
                </div>
              </div>
              <div class="col-2">
                <div class="text-overflow-auto">
                  <span class="react BTFileUpload-SecondaryText">{{ file.size / 1024 | number: '1.0-2' }} KB</span>
                </div>
              </div>
              <div class="col-1 text-end p-0 m-0">
                <a class="p-0 m-0 react" nz-button nzType="link" (click)="removeFile(file)">Remove</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #UploadFiles2>
    <button nz-button class="button-default react" (click)="UploadFiles()"
            [disabled]="fileList.length < 1"
            [ngClass]="{'no-drop': fileList.length < 1}"
    ><span>Discard</span></button>
    <button nz-button class="button-primary react m-0 p-0 ms-2" 
            (click)="uploadFilesTransferLeadOpp()" 
            [disabled]="fileList.length < 1"
            [ngClass]="{'no-drop': fileList.length < 1}">
      <span>Save</span>
    </button>
  </ng-template>
</nz-modal>
