import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { WProofreader } from '@webspellchecker/wproofreader-ckeditor5';
import { Bold, Essentials, Heading, Indent, IndentBlock, Italic, Link, List, MediaEmbed, Paragraph, Table, Undo, Font, FontSize, FontFamily, FontBackgroundColor, FontColor, BlockQuote, HorizontalLine, UndoEditing, ImageUpload, ClassicEditor } from 'ckeditor5';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Selectionsetupcode } from 'src/app/shared/component/Models/LookUpStandardFilterCodes';
import { CustomField } from 'src/Models/CompanySettings/Selections/CustomFieldId';
import { LookupNameSetupResponse } from 'src/Models/LeadTags';
import { SelectionsService } from 'src/Service/CompanyInFormation/Selections/selections.service';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { LoadingIndicatorService } from 'src/shared/Shared-Services/loading-indicator.service';


@Component({
  selector: 'app-selectiondetup',
  templateUrl: './selectiondetup.component.html',
  styleUrls: ['./selectiondetup.component.css']
})
export class SelectiondetupComponent implements OnInit {
  public Editor = ClassicEditor;
  @Output() cancel = new EventEmitter<void>();
  selectionVisible = true;
  DefaultBuilderview: LookupNameSetupResponse[] = [];
  DefaultOwnerview: LookupNameSetupResponse[] = [];
  RequireSignatureonSelections: LookupNameSetupResponse[] = [];
  lookupSetNameResponse: LookupNameSetupResponse[] = [];
  isInputVisible: boolean = false;
  text: string = '';
  dataset: any;
  selectionSetupForm: FormGroup;

  CustomField: CustomField[] = [];
  form: FormGroup;
  PAGE_ID: number = 50024;
  data: any;
  isSaving = false;
  listOfOption: Array<{ label: string; value: string }> = [];
  date = null;

  demoValue = 0;

  preDefinedDataCodes = {
    DefaultBuilderview: Selectionsetupcode.CSDBV,
    DefaultOwnerview: Selectionsetupcode.CSDOV,
    RequireSignatureonSelections: Selectionsetupcode.CSRSS,
  };
  // formFieldValues: any = {};
  public config = {
    height: '400px',

    toolbar: {
      items: [
        'undo', 'redo', '|',
        'heading', '|', 'bold', 'italic', '|',
        'link', 'insertTable', 'mediaEmbed', '|',
        'bulletedList', 'numberedList', 'indent', 'outdent', '|',
        'fontSize', 'fontFamily', 'wproofreader', 'fontBackgroundColor', 'fontColor', 'blockQuote',
        'image', 'horizontalLine', 'undoEditing'
      ],
    },
    plugins: [

      Bold,
      Essentials,
      Heading,
      Indent,
      IndentBlock,
      Italic,
      Link,
      List,
      MediaEmbed,
      Paragraph,
 
      Undo,
      Font,
      FontSize,
      FontFamily,

      FontBackgroundColor,
     
    



    ],


    fontSize: {
      options: [
        9, 11, 13, 'default', 17, 19, 21, 25, 28, 32, 36, 40, 44, 48, 52, 56, 60
      ]
    },
    fontFamily: {
      options: [
        'default', 'Arial, Helvetica, sans-serif', 'Courier New, Courier, monospace',
        'Georgia, serif', 'Times New Roman, Times, serif', 'Verdana, Geneva, sans-serif'
      ]
    },


    wproofreader: {
      lang: 'en_US',
      serviceId: 'YRY4vsPN7HIGo9kl',
      srcUrl: 'https://svc.webspellchecker.net/spellcheck31/wscbundle/wscbundle.js'
    }
  };


  

  constructor(private fb: FormBuilder,
    private Selectionsservice: SelectionsService,
    private LookupFilterServicee: LookupFilterService,
    private toastssService: NzMessageService,
    private modal: NzModalService,
    private _loadingIndicatorService: LoadingIndicatorService,
  ) { }

  ngOnInit(): void {

    this.selectionSetupForm = this.fb.group({
      selectionBuilderViewId: [''],
      selectionOwnerViewId: [''],
      defaultToExpandCategoryLocationView: [false],
      alertUserNumber: [''],
      allowancesOnNewJob: [false],
      showAllowancesToSubsVendors: [false],
      showVendorContactInfoToOwner: [false],
      showLineItemsToOwner: [false],
      defaultToAllowOwnerToPeekAtLockedSelectionsForAllNewJobs: [false],
      onNewJobsUpdateJobRunningTotalWhenASelectionIsApproved: [false],
      requireSignatureOnSelectionSetupId: [''],
      selectionsApprovalDisclaimer: '',
      approvedChoicesOnly: [false],
      pricing: [false],
      subsVendors: [false],
      addedBy: [false],
      installers: [false],
      choiceDescription: [false],
      selectionDescription: [false],
      lineItems: [false],
      discussions: [false],
      customFields: [false],
    });


    this.initLoad()
    this.fetchCompanySelection();
    const children: Array<{ label: string; value: string }> = [];
    for (let i = 10; i < 36; i++) {
      children.push({ label: i.toString(36) + i, value: i.toString(36) + i });
    }
    this.listOfOption = children;
  }




  initLoad() {
    this.LookupFilterServicee.getLookUpValuesByFormNameId(
      this.PAGE_ID
    ).subscribe((res) => {
      let defaultBuilderview = res.result.filter(
        (x) => x.code === this.preDefinedDataCodes.DefaultBuilderview.code
      );
      let DefaultOwnerview = res.result.filter(
        (x) => x.code === this.preDefinedDataCodes.DefaultOwnerview.code
      );
      let requireSignatureonSelections = res.result.filter(
        (x) => x.code === this.preDefinedDataCodes.RequireSignatureonSelections.code
      );
      this.DefaultBuilderview = defaultBuilderview;
      this.DefaultOwnerview = DefaultOwnerview;
      this.RequireSignatureonSelections = requireSignatureonSelections;

    }
    )
  }

  toggleVisibility() {
    this.isInputVisible = !this.isInputVisible;
  }






  fetchCompanySelection(): void {
    this._loadingIndicatorService.show(); // Show loading indicator

    this.Selectionsservice.getAllselection().subscribe({
      next: (response) => {
        this._loadingIndicatorService.hide(); // Hide loading indicator after fetching data

        if (response && response.result) {
          const dataset = response.result;
          console.log('First item:', dataset);

          // Patch the reactive form with the fetched data
          this.selectionSetupForm.patchValue({
            companyParameterId: dataset?.CompanyParameterId || 1,
            selectionBuilderViewId: dataset?.SelectionBuilderViewId || 0,
            selectionOwnerViewId: dataset?.SelectionOwnerViewId || 0,
            requireSignatureOnSelectionSetupId: dataset?.RequireSignatureOnSelectionSetupId ?? '',
            defaultToExpandCategoryLocationView: dataset?.DefaultToExpandCategoryLocationView ?? false, // boolean
            alertUserNumber: dataset?.AlertUserNumber,
            allowancesOnNewJob: dataset?.AllowancesOnNewJob ?? false, // Directly set as boolean

            showAllowancesToSubsVendors: dataset?.ShowAllowancesToSubsVendors ?? false,
            showVendorContactInfoToOwner: dataset?.ShowVendorContactInfoToOwner ?? false,
            showLineItemsToOwner: dataset?.ShowLineItemsToOwner ?? false,
            defaultToAllowOwnerToPeekAtLockedSelectionsForAllNewJobs: dataset?.DefaultToAllowOwnerToPeekAtLockedSelectionsForAllNewJobs ?? false,
            onNewJobsUpdateJobRunningTotalWhenASelectionIsApproved: dataset?.OnNewJobsUpdateJobRunningTotalWhenASelectionIsApproved ?? false,
            selectionsApprovalDisclaimer: dataset?.SelectionsApprovalDisclaimer,
            approvedChoicesOnly: dataset?.ApprovedChoicesOnly ?? false,
            pricing: dataset?.Pricing ?? false,
            subsVendors: dataset?.SubsVendors ?? false,
            addedBy: dataset?.AddedBy ?? false,
            installers: dataset?.Installers ?? false,
            choiceDescription: dataset?.ChoiceDescription ?? false,
            selectionDescription: dataset?.SelectionDescription ?? false,
            lineItems: dataset?.LineItems ?? false,
            discussions: dataset?.Discussions ?? false,
            customFields: dataset?.CustomFields ?? false,
          });
        } else {
          console.log('No result in response.');
        }
      },
      error: (err) => {
        this._loadingIndicatorService.hide(); // Hide loading indicator on error
        console.error('Error fetching company selection:', err);
      }
    });
  }


  saveSelections() {
    this._loadingIndicatorService.show();
    if (this.selectionSetupForm.valid) {
      this.isSaving = true;
      this.Selectionsservice.postCompanySelection(this.selectionSetupForm.value)
        .subscribe(
          (response) => {
            this.toastssService.success('Selection saved successfully!');
            this.selectionSetupForm.reset();
            this.modal.closeAll();
            this.isSaving = false;
            this._loadingIndicatorService.hide();
          },
          (error) => {
            console.error('Error while saving selection:', error);
            this.toastssService.error('Failed to save selection.');
            this.isSaving = false;
            this._loadingIndicatorService.hide();
          }
        );
    } else {
      this.toastssService.error('Please fill all required fields.');
      this._loadingIndicatorService.hide();
    }
  }

  onCancel(): void {
    this.selectionVisible = false;
    this.cancel.emit();
  }

// Change Text Aria //  showTextArea: boolean = false;

showTextArea: boolean = false;

toggleTextArea(): void {
  this.showTextArea = !this.showTextArea;
}


// Change Text Aria //

}





