<div class="d-flex justify-content-center" style="background: rgb(255, 255, 255); min-height: 324.333px;">
  <div nz-row class="d-flex justify-content-center" style="padding-top: 20px; padding-bottom: 48px; row-gap: 0px;">
    <div nz-col [nzXs]="20" [nzSm]="16" [nzMd]="17" style="row-gap: 0px;">
      <div nz-row class="d-flex justify-content-center">
        <div nz-col [nzXs]="8" [nzLg]="7" [nzXl]="6">
          <!-- <img style="width: 78px;height: 78px;" class="text-dark" src="assets/header/icons/Variations.svg"/> -->
          <span role="img" size="78" data-testid="EmptyStateIcon" data-icon-name="BTIcon" class="anticon EmptyStateIcon" style="font-size: 78px;"><svg width="1em" height="1em" viewBox="0 0 32 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" class=""><path fill-rule="evenodd" clip-rule="evenodd" d="M25.1924 6.80756C20.5589 2.17405 13.201 1.71361 8.02598 5.73333L7.76612 5.94048L7.51284 6.15304C2.92984 10.0988 1.67685 16.7033 4.49631 22.0534L4.60301 22.249L3.53398 25.9931L3.49489 26.1551C3.36626 26.8058 3.56873 27.4829 4.04291 27.9571L4.16555 28.0699L4.29585 28.1713C4.78668 28.5214 5.4159 28.6349 6.00696 28.466L9.74901 27.396L9.94665 27.5038L10.2397 27.6536C15.7322 30.3737 22.4514 28.8859 26.2667 23.974C30.2864 18.7989 29.8259 11.4411 25.1924 6.80756ZM9.25285 7.31282C13.6317 3.91154 19.8575 4.30114 23.7782 8.22178C27.6989 12.1424 28.0885 18.3683 24.6872 22.7471L24.499 22.9828L24.3018 23.2164C20.9032 27.1307 15.2024 28.1469 10.6495 25.6103L10.3211 25.4224L10.2127 25.3762C10.0269 25.3097 9.81715 25.2974 9.60366 25.3584L5.45703 26.5425L6.64165 22.3963L6.67011 22.2628C6.7021 22.0386 6.65712 21.8085 6.54017 21.6114C3.71128 16.8428 4.874 10.7141 9.25285 7.31282ZM16 21C16.8284 21 17.5 21.6716 17.5 22.5C17.5 23.3284 16.8284 24 16 24C15.1716 24 14.5 23.3284 14.5 22.5C14.5 21.6716 15.1716 21 16 21ZM20.5 13.5006C20.5 11.0153 18.4852 9.00056 16 9.00056C13.5147 9.00056 11.5 11.0153 11.5 13.5006C11.5 14.0528 11.9477 14.5006 12.5 14.5006C13.0522 14.5006 13.5 14.0528 13.5 13.5006L13.5053 13.3362C13.5899 12.0321 14.6745 11.0006 16 11.0006C17.3807 11.0006 18.5 12.1199 18.5 13.5006C18.5 14.8813 17.3807 16.0006 16 16.0006C15.4477 16.0006 15 16.4483 15 17.0006V18.0006L15.0067 18.1172C15.0644 18.6145 15.4871 19.0006 16 19.0006L16.1166 18.9938C16.6139 18.9361 17 18.5134 17 18.0006L16.999 17.888L17.2356 17.8288C19.1201 17.2918 20.5 15.5573 20.5 13.5006Z"></path></svg></span>
        </div>
      </div>
      <div nz-row class="d-flex justify-content-center" style="row-gap: 0px;text-align: center;">
        <div nz-col>
          <h2 class="famly-inn ant-typography-crad-Header" style="font-size: 24px;line-height: 38px;">Clarify the unknown with RFIs</h2>
        </div>
      </div>
      <div nz-row class="d-flex justify-content-center" style="row-gap: 0px;text-align: center;">
        <div nz-col>
          <span class="famly-inn" style="color: #202227;overflow-wrap: break-word;">Streamline formal requests for information with Subs/Vendors to keep the project moving.</span>
        </div>
      </div>
      <div nz-row class="d-flex justify-content-center" style="row-gap: 0px;margin-left: -4px;margin-right: -4px;margin-top: 24px !important;">
        <div nz-col style="padding-left: 4px; padding-right: 4px;">
          <button nz-button nzType="default">
            <i class="bi bi-box-arrow-up-right"></i>
            <span class="BTN-Font-Famliy ms-2">Learn How</span>
          </button>
        </div>
        <div nz-col class="text-center" style="padding-left: 4px; padding-right: 4px;">
          <button
            nz-button
            nzType="primary"
            class="align-items-center"
            >
            <span class="BTN-Font-Famliy">
              Add an RFI
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>