import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ChangeDetectorRef } from '@angular/core';
import { LeadOpportunityAttachmentsUploadFilesComponent } from 'src/app/lead-opportunities/Components/Modal/lead-opportunity-attachments-upload-files/lead-opportunity-attachments-upload-files.component';
import { LeadOpportunityAttachmentsUploadFilesViewAllComponent } from 'src/app/lead-opportunities/Components/Modal/lead-opportunity-attachments-upload-files-view-all/lead-opportunity-attachments-upload-files-view-all.component';
import { NzImageService } from 'ng-zorro-antd/image';
import { AttachmentAnnotationComponent } from 'src/app/lead-opportunities/Components/attachment-annotation/attachment-annotation.component';
import { ProjectManagementSiteDiariesCreateFilesComponent } from 'src/app/project-management/components/Site-Diaries/project-management-site-diaries-create-files/project-management-site-diaries-create-files.component';
import { environment } from 'src/environments/environment.prod';
import { AttachmentResponse, CustomUploadFile } from 'src/Models/Attachment_Files_Class/AttachmentFilesClass';
import { AttachmentService } from 'src/Service/Attachment/attachment.service';

@Component({
  selector: 'app-create-attachments-common',
  templateUrl: './create-attachments-common.component.html',
  styleUrls: ['./create-attachments-common.component.css']
})
export class CreateAttachmentsCommonComponent implements OnChanges {
  @Input() TestAttachment__ : AttachmentResponse;
  @Output() filesUploaded: EventEmitter<CustomUploadFile[]> = new EventEmitter<CustomUploadFile[]>();
  @Output() deleteCompleted = new EventEmitter<void>();
  @ViewChild('carouselContainer', { static: false }) carouselContainer!: ElementRef<HTMLDivElement>;
  @Output() attachmentsReset: EventEmitter<void> = new EventEmitter<void>();
  selectedFiles: CustomUploadFile[] = [];
  IMG_BASE: string = environment.IMG_BUCKET_URL;
  showNavButtons = false;
  currentIndex = 0;

  constructor(private modal: NzModalService,
    private cd: ChangeDetectorRef,
    private nzImageService: NzImageService,
    private _AttachmentService: AttachmentService,
  ){}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['TestAttachment__'] && this.TestAttachment__?.attachmentParameters) {
      this.processAttachments();
    }
  }
  processAttachments(): void {
    if (this.TestAttachment__?.attachmentParameters && this.TestAttachment__.attachmentParameters.length > 0) {
      const attachmentParameters = this.TestAttachment__?.attachmentParameters;
      this.selectedFiles = attachmentParameters.map(att => ({
        uid: att.id?.toString() || '',
        name: att.name || '',
        status: 'done',
        url: this.IMG_BASE + att.filePath,
        thumbUrl: this.IMG_BASE + att.filePath,
        type: this.getMimeType(att.extension),
        originFileObj: new File([new Blob()], att.name || '', {
          type: this.getMimeType(att.extension),
          lastModified: new Date(att.modifiedOn).getTime()
        }),
        loading: false,
        error: false,
        addedBy: att.createdBy || 0,
        createdOn: new Date(att.createdOn),
        modifiedOn: new Date(att.modifiedOn),
        id: att.id || 0,
        globalId: att.globalId || ''
      }));
    } else {
      this.selectedFiles = [];
      console.log('No attachments available.');
    }
  }
  getMimeType(extension: string): string {
    switch (extension) {
      case '.pdf':
        return 'application/pdf';
      case '.doc':
      case '.docx':
        return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      case '.json':
        return 'application/json';
      case '.txt':
        return 'text/plain';
      case '.xls':
      case '.xlsx':
        return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      case '.ppt':
      case '.pptx':
        return 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
      case 'jpg':
      case 'jpeg':
        return 'image/jpeg';
      case 'png':
        return 'image/png';
      case 'mp4':
      case 'avi':
        return 'video/mp4';
      default:
        return 'application/octet-stream';
    }
  }
  uploadFiles(): void {
    const modalRef = this.modal.create({
      nzContent: LeadOpportunityAttachmentsUploadFilesComponent,
      nzFooter: null,
    });

    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });

    modalRef.componentInstance.UploadFile.subscribe((data: CustomUploadFile[]) => {
      data.forEach(file => {
        file.globalId = "00000000-0000-0000-0000-000000000000";
      });
      data.filter(file => this.isImageFile(file));
      this.selectedFiles = [...data, ...this.selectedFiles];
      this.filesUploaded.emit(data);
      this.onResize();
    });
  }
  // uploadFiles(): void {
  //   const modalRef = this.modal.create({
  //     nzContent: LeadOpportunityAttachmentsUploadFilesComponent,
  //     nzFooter: null,
  //   });
  //   modalRef.componentInstance.cancel.subscribe(() => {
  //     modalRef.destroy();
  //   });
  //   modalRef.componentInstance.UploadFile.subscribe((data: CustomUploadFile[]) => {
  //     console.log('data',data);
  //     data.filter(file => this.isImageFile(file));
  //     this.selectedFiless = [...data, ...this.selectedFiless];
  //     this.filesUploaded.emit(data);
  //     this.onResize();
  //   });
  // }
  isImageFile(file: CustomUploadFile): boolean {
    const fileName = file.name.toLowerCase();
    const fileType = file.type;

    return (
      fileType === 'image/png' ||
      fileType === 'image/jpeg' ||
      fileName.endsWith('.png') ||
      fileName.endsWith('.jpeg')
    );
  }
  onResize() {
    this.updateNavButtonsVisibility();
  }
  private updateNavButtonsVisibility() {
    requestAnimationFrame(() => {
      const container = this.carouselContainer.nativeElement;
      if (this.selectedFiles.length > 0) {
        this.showNavButtons = container.scrollWidth > container.clientWidth;
      } else {
        this.showNavButtons = false;
      }
      this.cd.detectChanges();
    });
  }
  CreateNewFile(): void {
    const modalRef = this.modal.create({
      nzContent: ProjectManagementSiteDiariesCreateFilesComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  viewAll(): void {
    const modalRef = this.modal.create({
      nzContent: LeadOpportunityAttachmentsUploadFilesViewAllComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.selectedFilessResponse = this.selectedFiles;
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    modalRef.componentInstance.removeFile.subscribe((file: CustomUploadFile) => {
      this.removeFile(file);
    });
    modalRef.componentInstance.Test.subscribe((data: CustomUploadFile[]) => {
      this.selectedFiles = [...data, ...this.selectedFiles];
      this.onResize();
    });
  }
  removeFile(file: CustomUploadFile): void {
    if (file.globalId === '00000000-0000-0000-0000-000000000000') {
      this.selectedFiles = this.selectedFiles.filter(item => item.uid !== file.uid);
      this.filesUploaded.emit(this.selectedFiles);
    } else {
      this._AttachmentService.deleteData(file?.globalId).subscribe(res => {
        this.deleteCompleted.emit();
      });
    }
  }
  previous() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
      this.scrollToCurrentIndex();
    }
  }
  next() {
    const container = this.carouselContainer.nativeElement;
    const maxIndex = this.selectedFiles.length - Math.floor(container.clientWidth / 130);
    if (this.currentIndex < maxIndex) {
      this.currentIndex++;
      this.scrollToCurrentIndex();
    }
  }
  scrollToCurrentIndex() {
    const container = this.carouselContainer.nativeElement;
    const scrollAmount = this.currentIndex * 130;
    container.scrollTo({ left: scrollAmount, behavior: 'smooth' });
  }
  getFileIcon(file: CustomUploadFile): string | null {
    const fileType = file.type;
    if (fileType.includes('pdf')) {
      return 'assets/Attachment/documentPDF.svg';
    } else if (fileType.includes('excel') || fileType.includes('spreadsheet')) {
      return 'assets/Attachment/documentXLS.svg';
    } else if (fileType.includes('word')) {
      return 'assets/Attachment/documentDOC.svg';
    } else if (fileType.includes('zip')) {
      return 'assets/Attachment/documentZIP.svg';
    } else if (fileType.includes('text') || fileType.includes('json')) {
      return 'assets/Attachment/documentGeneric.svg';
    } else if (fileType.includes('ppt') || fileType.includes('presentation')) {
      return 'assets/Attachment/documentPPT.svg';
    } else if (fileType.includes('video')) {
      return null;
    } else if (fileType.includes('image')) {
      return null;
    } else {
      return null;
    }
  }
  onClick(): void {
    let filteredImages = this.selectedFiles.filter(f =>
      f.name.includes('png') || f.name.includes('jpeg')
    );
    const images = filteredImages.map(file => ({
      src: file.thumbUrl,
      alt: file.name
    }));
    this.nzImageService.preview(images, {
      nzZoom: 1.0,
      nzRotate: 0
    });
  }
  playVideo(file: CustomUploadFile): void {
    const videoElement: HTMLVideoElement = this.carouselContainer.nativeElement.querySelector(`#video-${file.id}`);
    if (videoElement) {
      videoElement.style.display = 'block';
      videoElement.play();
    }
  }
  uploadFilesUpdate(fileq: CustomUploadFile): void {
    const modalRef = this.modal.create({
      nzContent: AttachmentAnnotationComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.DrawerImge = fileq;
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    modalRef.componentInstance.imageDataRes.subscribe((data: any) => {
      const newImageUrl = data;
      let test1234 = this.selectedFiles.forEach(activity => {
        activity.thumbUrl = newImageUrl;
      });
    });
  }
  clearAttachment() {
    this.selectedFiles = [];
  }
}
