<nz-modal
  nzWidth="96%"
  [(nzVisible)]="dailyLog"
  [nzTitle]="dailyLogTitle"
  [nzFooter]="dailyLogFooter"
  (nzOnCancel)="closeDailyLog()"
  [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '72vh', 'overflow-y': 'auto'}"
  [nzStyle]="{ top: '18px' }">
  <ng-template #dailyLogTitle>
    <div class="row p-0 m-0">
      <h2 class="p-0 m-0" nz-typography>Daily Logs</h2>
    </div>
  </ng-template>

  <form nz-form [formGroup]="dailyLogForm">
    <div class="cantent" *nzModalContent>

      <nz-card nzBorderless="true" nzTitle="Daily Log Setup" class="mb-3">
        <div nz-row>
          <div nz-col [nzSpan]="24">
            <nz-form-item>
              <nz-form-control>
                <label nz-checkbox formControlName="isStampLocation">Stamp
                  Location</label>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>

        <div nz-row>
          <div nz-col [nzSpan]="24">
            <label class="sub-text">Default Daily Log Notes</label>
            <nz-form-item>
              <nz-form-control>
                <nz-textarea-count [nzMaxCharacterCount]="4000">
                  <textarea
                    formControlName="notes"
                    rows="2"
                    nz-input></textarea>
                </nz-textarea-count>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </nz-card>

      <nz-card nzBorderless="true" nzTitle="Weather" class="mb-3">
        <div nz-row>
          <div nz-col [nzSpan]="24">
            <nz-form-item>
              <nz-form-control>
                <label
                  nz-checkbox
                  formControlName="isIncludeWeatherConditionsDefault">Include
                  Weather Conditions (Default)</label>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div nz-row>
          <div nz-col [nzSpan]="24">
            <nz-form-item>
              <nz-form-control>
                <label
                  nz-checkbox
                  formControlName="isIncludeWeatherConditionNotesDefault">Include
                  Weather Notes (Default)</label>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </nz-card>

      <nz-card nzBorderless="true" znzTitle="Default Daily Log Share Settings" class="mb-3">
        <form [formGroup]="dailyLogForm">
          <div class="row">
            <div class="col-4">
              <div class="row">
                <div class="col-12">
                  <div
                    class="ant-row ant-row-space-between ant-row-middle BTRow-xs ItemRow"
                    style="row-gap: 0px;">
                    <div
                      class="ant-col text-center ant-col-xs-6 ant-col-xs-offset-12 ant-col-md-4 ant-col-md-offset-16">
                      <span class="ant-typography"><strong>Share</strong></span>
                    </div>
                    <div class="ant-col text-center ant-col-xs-6 ant-col-md-4">
                      <span
                        class="ant-typography"><strong>Notify</strong></span>
                    </div>
                  </div>

                  <!-- Internal Users -->
                  <div
                    class="ant-row ant-row-space-between ant-row-middle BTRow-xs ItemRow mt-3"
                    style="
                      margin-left: -4px;
                      background-color: #f1f4fa;
                      padding: 7px;
                      margin-right: -4px;
                      row-gap: 0px;
                    ">
                    <div
                      class="ant-col ant-col-xs-12 ant-col-md-16"
                      style="padding-left: 4px; padding-right: 4px;">
                      <i class="fs-5 ms-1 bi bi-person-fill"></i>Internal Users
                    </div>
                    <div
                      class="ant-col text-center ant-col-xs-6 ant-col-md-4"
                      style="padding-left: 4px; padding-right: 4px;">
                      <label
                        nz-checkbox
                        [formControlName]="'internalUsersShare'"
                        (change)="onIsShareChange('internalUsers', 'Share')"></label>
                    </div>
                    <div
                      class="ant-col text-center ant-col-xs-6 ant-col-md-4"
                      style="padding-left: 4px; padding-right: 4px;">
                      <label
                        nz-checkbox
                        [formControlName]="'internalUsersNotify'"></label>
                    </div>
                  </div>

                  <!-- Subs/Vendors -->
                  <div
                    class="ant-row ant-row-space-between ant-row-middle BTRow-xs ItemRow mt-0"
                    style="margin-left: -4px; background-color: #fff; padding: 7px; margin-right: -4px; row-gap: 0px;">
                    <div class="ant-col ant-col-xs-12 ant-col-md-16"
                      style="padding-left: 4px; padding-right: 4px;">
                      <i class="fs-5 ms-1 bi bi-hammer"></i> Subs/Vendors
                    </div>
                    <div class="ant-col text-center ant-col-xs-6 ant-col-md-4"
                      style="padding-left: 4px; padding-right: 4px;">
                      <label nz-checkbox [formControlName]="'subsVendorsShare'"
                        (change)="onIsShareChange('subsVendors', 'Share')"></label>
                    </div>
                    <div class="ant-col text-center ant-col-xs-6 ant-col-md-4"
                      style="padding-left: 4px; padding-right: 4px;">
                      <label nz-checkbox [formControlName]="'subsVendorsNotify'"
                        (change)="onIsShareChange('subsVendors', 'Notify')"></label>
                    </div>
                  </div>

                  <!-- Owners -->
                  <div
                    class="ant-row ant-row-space-between ant-row-middle BTRow-xs ItemRow mt-0"
                    style="
                      margin-left: -4px;
                      background-color: #f1f4fa;
                      padding: 7px;
                      margin-right: -4px;
                      row-gap: 0px;
                    ">
                    <div
                      class="ant-col ant-col-xs-12 ant-col-md-16"
                      style="padding-left: 4px; padding-right: 4px;">
                      <i class="fs-5 ms-1 bi bi-house-fill"></i>
                      Owners
                    </div>
                    <div
                      class="ant-col text-center ant-col-xs-6 ant-col-md-4"
                      style="padding-left: 4px; padding-right: 4px;">
                      <label
                        nz-checkbox
                        [formControlName]="'ownersShare'"
                        (change)="onIsShareChange('owners', 'Share')"></label>
                    </div>
                    <div
                      class="ant-col text-center ant-col-xs-6 ant-col-md-4"
                      style="padding-left: 4px; padding-right: 4px;">
                      <label
                        nz-checkbox
                        [formControlName]="'ownersNotify'"
                        (change)="onIsShareChange('owners','Notify')"></label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </nz-card>
    </div>
  </form>
</nz-modal>
<ng-template #dailyLogFooter>
  <button nz-button nzType="primary"  [nzLoading]="isSave" class="me-0"
    (click)="saveDailyLog()">
    Save
  </button>
</ng-template>