


import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService, NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';
import { LookupNameSetupResponse } from 'src/Models/LeadTags';
import { CustomerInformationService } from 'src/Service/customer-information.service';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { LeadOpportunitiesService } from 'src/Service/lead-opportunities.service';
import { LeadProposalsService } from 'src/Service/LeadProp/lead-proposals.service';
import { LeadProposalsFService } from 'src/Service/Sales-lead-proposals-F/lead-proposals-f.service';
import { LeadProposalsPreDefinedCodes } from 'src/app/shared/component/Models/LookUpStandardFilterCodes';
import { CostItemParameterResponse, LeadProposalResponse } from 'src/Models/LeadProposal/LeadProposalModels';
import { StatusSetupProposal } from 'src/app/Core/Enums/proposalstatus';
import { SignatureSetupResponse } from 'src/Models/SignaturePad/SignaturePad';
import { CostCodeCategoryResponse } from 'src/Models/InternalUser/applicationUserResponse';
import { EstimatedCostComponent } from '../../estimated-cost/estimated-cost.component';
import { LeadOpportunitiesWorkSheetAddformCatalogComponent } from '../../Catalog-Modal/lead-opportunities-work-sheet-addform-catalog/lead-opportunities-work-sheet-addform-catalog.component';
import { NewProposalsCostCodesComponent } from '../../new-proposals-cost-codes/new-proposals-cost-codes.component';
import { HttpResponse } from '@angular/common/http';
import { Autoformat, BlockQuote, Bold, ClassicEditor, EditorConfig, Essentials, Font, FontBackgroundColor, FontColor, FontFamily, FontSize, Heading, HorizontalLine, Image, ImageUpload, Indent, Italic, Link, List, ListProperties, MediaEmbed, Paragraph, Table, Undo, UndoEditing } from 'ckeditor5';
import { environment } from 'src/environments/environment.prod';
import { AuditDetailsResponse, FilterFooterAudit } from 'src/Models/Footer/footer.model';
import { FooterService } from 'src/Service/Footer/footer.service';
import { NzStatus } from 'ng-zorro-antd/core/types';
import { AttachmentService } from 'src/Service/Attachment/attachment.service';
import { BehaviorsubjectService } from 'src/Service/behaviorsubject/behaviorsubject.service';
import { AttachmentResponse, CustomUploadFile } from 'src/Models/Attachment_Files_Class/AttachmentFilesClass';
import { saveAttachments } from 'src/app/helpers/Attachment_Save';
import { CostItemCommonComponent } from 'src/app/cost-item-common/cost-item-common.component';
import { CompanyParameterChild } from 'src/Models/costitem/costitem.modal';
interface Person {
  key: string;
  name: string;
  age: number;
  address: string;
}
@Component({
  selector: 'app-new-lead-proposal-template',
  templateUrl: './new-lead-proposal-template.component.html',
  styleUrls: ['./new-lead-proposal-template.component.css']
})
export class NewLeadProposalTemplateComponent implements OnInit {
  @Input() getProposalTemplatesDataId: number;
  footerResponse: AuditDetailsResponse;
  getProposalTemplatesData: LeadProposalResponse;
  TestAttachment: AttachmentResponse;
  selectedFilesAttachment: CustomUploadFile[] = [];
  @Output() cancel = new EventEmitter<void>();
  getStatusProposal: StatusSetupProposal;
  leadOpportunitiesTitle: string;
  leadOpportunitiesCost: CostItemParameterResponse[];
  isVisibleProposalTemplateModal = true;
  editorValue: string;
  closingText: string;
  formFieldValues: any = {};
  selectedFiles: File[] = [];


  // @Output() leadProposalSaveResponse = new EventEmitter<LeadProposalResponse[] | LeadProposalResponse>();

  @Output() leadProposalSaveResponse = new EventEmitter();
  @ViewChild('titleInput', { static: false }) titleInput: ElementRef;
  confirmModal?: NzModalRef;
  initialFormFieldValues: any = {};

  // Internal Users Assigner For This Form
  assignedUser: { label: string; value: string; }[] = [];
  isLoading: boolean = false;
  signatureResponse: SignatureSetupResponse;
  signatureResponseDecline: SignatureSetupResponse;
  LeadOpportunitiesNewProposalResponse: LeadProposalResponse;
  markupOption: LookupNameSetupResponse[] = [];
  IMG_BASE: string = environment.IMG_BUCKET_URL;
  attachments: AttachmentResponse[] = [];
  testFile: AttachmentResponse[] = []
  i = 0;
  // Editor: { create(sourceElementOrData: string|HTMLElement,config?: EditorConfig): Promise<Editor>; EditorWatchdog: typeof EditorWatchdog; };
  // config: EditorConfig;
  constructor(private modal: NzModalService,
    private leadProposalsService: LeadProposalsService,
    private LookupFilterService: LookupFilterService,
    private customerInformationService: CustomerInformationService,
    public leadProposalsFService: LeadProposalsFService,
    private toastService: NzMessageService,
    private _AttachmentService: AttachmentService,
    private FooterService: FooterService,
    private _BehaviorsubjectService: BehaviorsubjectService,
    private leadOppService: LeadOpportunitiesService,
    @Inject(NZ_MODAL_DATA) public leadOpportunitySaveResponse: any,
    @Inject(NZ_MODAL_DATA) public leadSelectedData: any,
    @Inject(NZ_MODAL_DATA) public leadProp: any,
    private changeDetectorRef: ChangeDetectorRef,

  ) {


  }
  ngOnInit(): void {

    this.leadProposalGetById();
    this.leadProposalTemplateFormGroup();
    this.ForCombo();
    this.getLeadData();
    localStorage.setItem('isFromLeadProposal', 'true');
    this.ForCombo();
    this.initLoad();
    this.leadOppService.getSelectedFiles().subscribe(files => {
      this.selectedFiles = files;
    });

  }
  getLeadProposalisTemplateData: LeadProposalResponse;

  leadProposalGetById() {
    if (this.getProposalTemplatesDataId) {

      this.isLoading = true;
      this.leadProposalsFService.getleadProposalsId(this.getProposalTemplatesDataId).subscribe(
        (res) => {
          this.getLeadProposalisTemplateData = res.result;

          if (this.getLeadProposalisTemplateData) {
            this.patchFormValues(this.getLeadProposalisTemplateData);
            // this.fetchAttachments(this.getLeadProposalisTemplateData.attachmentId);

            this.isLoading = false;
          }
        },
        (error) => {
          console.error('Error fetching lead opportunity', error);
        }
      );
    } else {
    }
  }

  patchFormValues(LeadOpportunitiesNewProposalResponse: LeadProposalResponse) {
    let DataSet = LeadOpportunitiesNewProposalResponse;
    const param = DataSet?.costItem?.costItemParameters;

    this.formFieldValues['id'] = LeadOpportunitiesNewProposalResponse.id;
    this.formFieldValues['globalId'] = LeadOpportunitiesNewProposalResponse.globalId;
    this.formFieldValues['title'] = LeadOpportunitiesNewProposalResponse.title;
    this.formFieldValues['approvalDeadlineOn'] = DataSet.approvalDeadlineOn;
    this.formFieldValues['salesPersonId'] = DataSet.salesPersonId.toString();
    this.formFieldValues['internalNotes'] = DataSet.internalNotes;
    this.formFieldValues['introductoryText'] = DataSet.introductoryText;
    this.editorValue = DataSet.introductoryText;
    this.closingText = DataSet.closingText;
    this.formFieldValues['closingText'] = DataSet.closingText;

    this.costItemId = LeadOpportunitiesNewProposalResponse.costItemId

    this.createAndModifiedByData(LeadOpportunitiesNewProposalResponse.id); // patch time Calling this Footer
    this.setInitialFormFieldValues();
  }






  createAndModifiedByData(id: number) {
    const payload: FilterFooterAudit = {
      id: id,
      formCode: 'LOPT'
    }
    this.FooterService.createdAndModifyUser(payload).subscribe(res => {
      this.footerResponse = res.result;

    })
  }

  handleCancelLeadProposalTemplate(): void {
    this.leadOppService.clearSelectedFiles();
    this.cancel.emit();
    this.isVisibleProposalTemplateModal = false;
    this.emitData(this.getProposalTemplatesData);
  }

  private isDataEmitted = false; // Flag to check if data has been emitted

  private emitData(data: LeadProposalResponse): void {
    if (!this.isDataEmitted && data) {
      this.leadProposalSaveResponse.emit(data);
      this.isDataEmitted = true;
    }
  }

  // Form Group Start
  formatDate(date: Date): string {
    // Format date as 'MMM d, YYYY'
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    };
    return date.toLocaleDateString('en-US', options);
  }

  leadProposalTemplateFormGroup(): void {
    this.formFieldValues = {
      globalId: '00000000-0000-0000-0000-000000000000',
      title: `Proposal Template ${this.formatDate(new Date())}`,
      approvalDeadlineOn: null,
      IsTemplate: true,
      salesPersonId: 0,
      TempeleteDescription: '',
      closingText: this.closingText,
      introductoryText: this.editorValue,
      leadOpportunityId: 0,
    };
  }

  setInitialFormFieldValues(): void {
    this.initialFormFieldValues = JSON.parse(JSON.stringify(this.formFieldValues));
  }


  // Cost item Methods Start
  tabs = [1, 2, 3];
  ownerPriceTotal: number = 0;
  builderCostTotal: number = 0;
  totalProfit: number = 0.00;
  test: number = 0;
  index: number = 0;
  itemsss: any[] = [];
  leadOptions: CostCodeCategoryResponse[] = [];

  preDefinedDataCodes = {
    costType: LeadProposalsPreDefinedCodes.CostType,
    markup: LeadProposalsPreDefinedCodes.Markup,
    markAs: LeadProposalsPreDefinedCodes.MarkAs,
  };
  proposalOption: LookupNameSetupResponse[] = [];
  markAsOption: LookupNameSetupResponse[] = [];

  showValidationMessage: string;

  listOfData: Person[] = [
    {
      key: '1',
      name: 'John Brown',
      age: 32,
      address: 'New York No. 1 Lake Park'
    },
  ];




  initLoad() {
    this.LookupFilterService.getLookUpValuesByFormNameId(37).subscribe(res => {
      let proposalStatus = res.result.filter(x => x.code === this.preDefinedDataCodes.costType.code);
      let markupValues = res.result.filter((x) => x.code === this.preDefinedDataCodes.markup.code);
      let markAsOptionValues = res.result.filter(x => x.code === this.preDefinedDataCodes.markAs.code);
      this.proposalOption = proposalStatus;
      this.markupOption = markupValues;

      this.markAsOption = markAsOptionValues;

      if (this.getProposalTemplatesDataId) {
      } else {
        const leadmarkup = this.markupOption.find((item) => item.description === '%');
      }
    });
    this.setInitialFormFieldValues();

  }


  getLeadData() {
    this.leadProposalsService.getAllLeadData().subscribe(data => {
      this.leadOptions = data.result;
    });
  }


  // Cost item Methods End

  ForCombo() {
    this.customerInformationService.getDataforcombo().subscribe((res) => {
      const assignedUser = res.result;
      this.assignedUser = assignedUser.map((AUser) => ({
        label: AUser.fullName,
        value: AUser.id.toString(),
      }));

      // Ensure formFieldValues['salesPersonId'] is '0' if not in the list
      if (!this.assignedUser.some(user => user.value === this.formFieldValues['salesPersonId'])) {
        this.formFieldValues['salesPersonId'] = '0';
      }

      this.setInitialFormFieldValues();
    });

  }

  showCatalogModel(flag: String) {
    const modalRef = this.modal.create({
      nzContent: LeadOpportunitiesWorkSheetAddformCatalogComponent,
      nzFooter: null,
      nzData: {
        CompanySetting: flag
      },
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }

  showProposalCostCode() {
    const modalRef = this.modal.create({
      nzContent: NewProposalsCostCodesComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();

    });
  }



  // File Upload Remove Methods Start //






  /// Error For card and Fields

  validationErrors: string[] = [];
  modalErrorMessages: string[] = [];
  isSubmitted = false;

  readonly validationRules = {
    title: {
      required: 'Title is required',
      maxLength: { limit: 100, message: 'Title is too long' }
    },
    salesPersonId: {
      required: 'Required'
    }
  };

  getErrorTip(field: string): string {
    const rules = this.validationRules[field];
    const input = this.formFieldValues[field] || '';

    if (rules.required && !input.trim()) {
      return rules.required;
    }
    if (rules.maxLength && input.length > rules.maxLength.limit) {
      const excessLength = input.length - rules.maxLength.limit;
      return `${excessLength} characters over the limit.`;
    }
    return '';
  }

  getFieldLabel(field: string): string {
    const labels: { [key: string]: string } = {
      title: 'Title',
      salesPersonId: 'Salesperson'
    };
    return labels[field] || field;
  }

  checkErrors() {
    this.validationErrors = [];
    for (const field in this.validationRules) {
      if (this.validationRules.hasOwnProperty(field)) {
        const errorTip = this.getErrorTip(field);
        if (errorTip) {
          this.validationErrors.push(`${this.getFieldLabel(field)}: ${errorTip}`);
        }
      }
    }
    this.updateModalErrorMessages();
  }

  updateModalErrorMessages() {
    this.modalErrorMessages = this.validationErrors.filter(error => error.includes('Title'));
  }

  isFieldInvalid(field: string): boolean {
    return this.validationErrors.some(error => error.includes(this.getFieldLabel(field)));
  }

  onFieldChange(field: string, value: string) {
    this.formFieldValues[field] = value;
    this.isSubmitted = true;
    this.checkErrors();
  }

  // Formatter function to format the number to "1.000"
  formatNumber = (value: number | string): string => {
    if (value === null || value === undefined) return '';
    // Ensure the value is a number
    const numValue = typeof value === 'string' ? parseFloat(value) : value;
    return numValue.toFixed(3); // Format number with 3 decimal places
  };

  // Parser function to parse the formatted string back to a number
  parseNumber = (value: string): string => {
    if (!value) return '';
    // Convert the string to a number and then back to a string to remove commas or other non-numeric characters
    return parseFloat(value.replace(/,/g, '')).toString();
  };





  // Save Method

  saveFileIdToDatabase() { }

  isSave: boolean = false;
  isSaveAndClose: boolean = false;
  isSaveAndNew: boolean = false;
  tableCostTypeFormFieldValues: any = {};
  DisabledNextActivity: boolean = false;



  costItemId: number;
  companyParameter: CompanyParameterChild;
  @ViewChild(CostItemCommonComponent) childComponent!: CostItemCommonComponent;




  async saveLeadProposalTemplate(actionType: 'save' | 'saveAndClose' | 'saveAndNew' = 'save'): Promise<void> {
    if (this.isSave || this.isSaveAndClose || this.isSaveAndNew) {
      return Promise.reject('Already processing');
    }

    this.isSubmitted = true;
    this.validationErrors = [];
    this.checkErrors();

    if (this.validationErrors.length > 0) {
      setTimeout(() => {
        const topElement = document.querySelector('.top-content');
        if (topElement) {
          topElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }
      }, 100);
      this.resetFlags();
      return Promise.reject('Validation errors present');
    }
    // Ensure `companyParameter` is initialized
    if (!this.companyParameter) {
      this.companyParameter = {};
    }
    try {
      const postResponse = await this.leadProposalsService.postLeadProposal(this.formFieldValues).toPromise();
      this.handlePostResponse(postResponse);

      this.getLeadProposalisTemplateData = postResponse.result;
      if (postResponse) {
        this.createAndModifiedByData(postResponse.result.id);
      }

      if (this.selectedFilesAttachment?.length > 0) {
        saveAttachments(this.selectedFilesAttachment, this._AttachmentService, postResponse.result.id, 39);
      }

      await this.childComponent.saveCostItem();

      this.toastService.success('Saved Successfully!');
      this._BehaviorsubjectService.setLeadActivityTypeResponse(postResponse);
      this.leadProposalSaveResponse.emit();

    } catch (error) {
      console.error('Error saving data:', error);
      this.toastService.error('Error saving lead data.');
      throw error;
    } finally {
      this.resetFlags();
    }
  }

  handlePostResponse(postResponse) {
    debugger
    this.getProposalTemplatesData = postResponse.result;
    this.getStatusProposal = postResponse.result.statusSetup;
    this.formFieldValues.globalId = postResponse.result.globalId;

    // Update companyParameter
    // Set values in companyParameter
    Object.assign(this.companyParameter, {
      companyParameterId: 1,
      headerId: postResponse.result.id,
      formNameId: 39,
      isFlatFee: false,
      isLineItems: false,
      builderCost: '0.00',
      ownerPrice: '0.00',
      isSetPriceLater: false,
    });

  }



  onFilesUploaded(files: CustomUploadFile[]): void {
    this.selectedFilesAttachment = files
  }



  saveAndCloseProposalTemplate(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.saveLeadProposalTemplate('saveAndClose')
        .then(() => {
          this.resetForm();
          this.isVisibleProposalTemplateModal = false;
          this.cancel.emit();
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }


  saveAndNewProposalTemplate(): void {
    this.saveLeadProposalTemplate('saveAndNew')
      .then(() => {
        this.resetForm(); // Reset the form only if it's saveAndNew
      })
      .catch((error) => {
        console.error('Error saving proposal template:', error);
      });
  }

  resetFlags() {
    this.isSave = false;
    this.isSaveAndClose = false;
    this.isSaveAndNew = false;
    this.leadOppService.clearSelectedFiles();
  }



  resetForm() {
    this.formFieldValues = {
      globalId: '00000000-0000-0000-0000-000000000000',
      leadOpportunityId: 0,
      title: `Proposal Template ${this.formatDate(new Date())}`,
      approvalDeadlineOn: null,
      IsTemplate: true,
      salesPersonId: 0,
      TempeleteDescription: '',
      closingText: this.closingText,
      introductoryText: this.editorValue,
      createCostItemRequest: {
        companyParameterId: 1,
        formNameId: 39,
        createCostItemParameterRequests: [],
      },
    };
    this.validationErrors = [];
    this.isSubmitted = false;
    this.setInitialFormFieldValues();
    this.ForCombo();
    this.getProposalTemplatesData = null;
  }

  setFlagsBasedOnCallback(callback?: () => void) {
    this.isSave = !callback;
    this.isSaveAndClose = callback?.name === 'saveAndCloseProposalTemplate';
    this.isSaveAndNew = callback?.name === 'saveAndNewProposalTemplate';
  }






  isDeleteLoading: boolean = false;

  deleteLeadProposal(globalId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!globalId) {
        this.toastService.error('No record selected for deletion.');
        this.isDeleteLoading = false;
        reject();
        return;
      }

      this.leadProposalsService.deleteProposal(globalId).subscribe({
        next: (res: HttpResponse<void>) => {
          this.isDeleteLoading = false;
          if (res.status === 200) { // Check for HTTP status code 200

            this.toastService.success('Deleted Successfully!');
            this.leadProposalSaveResponse.emit();
            resolve();
          } else {
            this.toastService.error('Failed to delete the record. Please try again.');
            reject();
          }
        },
        error: () => {
          this.isDeleteLoading = false;
          this.toastService.error('An error occurred while deleting the record. Please try again.');
          reject();
        }
      });
    });
  }



  deleteLeadConfirm(): void {
    if (this.isDeleteLoading) {
      return;
    }

    this.modal.confirm({
      nzTitle: `Delete Lead Proposal?`,
      nzContent: 'Are you sure you want to permanently delete this lead proposal and all line items on it?',
      nzOkText: `Delete Lead Proposal`,
      nzOkDanger: true,
      nzCancelText: 'Cancel',
      nzOnOk: () => {
        this.isDeleteLoading = true;
        return this.deleteLeadProposal(this.formFieldValues.globalId)
          .then(() => {
            this.handleCancelLeadProposalTemplate();
          })
          .catch(() => {

          });
      },
      nzCentered: true,
      nzOkLoading: this.isDeleteLoading,
      nzBodyStyle: { 'border-radius': '50px', 'height': 'auto', 'padding-top': '15' },
      nzCloseIcon: '',
      nzIconType: ''
    });
  }



  async storeInitialFormFieldValues(): Promise<void> {
    this.isLoading = true;
    await new Promise(resolve => setTimeout(() => {
      this.initialFormFieldValues = JSON.parse(JSON.stringify(this.formFieldValues));
      resolve(true);
    }, 0));
    this.isLoading = false;
  }

  isFormDirty(): boolean {
    return JSON.stringify(this.formFieldValues) !== JSON.stringify(this.initialFormFieldValues);
  }

  showConfirmCardProposalTemplate(): void {
    if (this.isFormDirty()) {
      this.confirmModal = this.modal.confirm({
        nzTitle: '<b>Save Changes?</b>',
        nzContent: '<p>This Lead Opportunity has unsaved changes. Do you want to save your changes before closing?</p>',
        nzOkText: 'Save',
        nzCancelText: "Don't Save",
        nzOkLoading: this.isSaveAndClose,
        nzClosable: true,
        nzOnOk: () => {
          this.isSave = false;
          this.isSaveAndClose = false;
          this.isSaveAndNew = false;

          this.isSaveAndClose = true;
          return this.saveAndCloseProposalTemplate()
            .then(() => {
              this.isSaveAndClose = false;
            })
            .catch(() => {
              this.isSaveAndClose = false;
            });
        },
        nzOnCancel: () => {
          this.resetForm();
          this.handleCancelLeadProposalTemplate();
        },
        nzStyle: { top: '250px' },
        nzClassName: 'custom-modal-content',
      });
    } else {
      this.resetForm();
      this.handleCancelLeadProposalTemplate();
    }
  }



  public Editor = ClassicEditor;
  public config: EditorConfig = {
    toolbar: {
      items: [
        'undo', 'redo', '|',
        'heading', '|', 'bold', 'italic', '|',
        'link', 'insertTable', 'mediaEmbed', '|',
        'bulletedList', 'numberedList', 'indent', 'outdent', '|',
        'fontSize', 'fontFamily', 'fontBackgroundColor', 'fontColor', 'blockQuote',
        'image', 'horizontalLine', 'undoEditing', 'ListProperties', 'Autoformat'
      ]
    },
    plugins: [
      Bold,
      Essentials,
      Heading,
      Indent,
      Italic,
      Link,
      List,
      ListProperties,
      MediaEmbed,
      Paragraph,
      Autoformat,
      Table,
      Undo,
      Font,
      FontSize,
      FontFamily,
      FontBackgroundColor,
      FontColor,
      BlockQuote,
      Image,
      HorizontalLine,
      UndoEditing,
      ImageUpload
    ],
    fontSize: {
      options: [
        9, 11, 13, 'default', 17, 19, 21, 25, 28, 32, 36, 40, 44, 48, 52, 56, 60
      ]
    },
    fontFamily: {
      options: [
        'default', 'Arial, Helvetica, sans-serif', 'Courier New, Courier, monospace',
        'Georgia, serif', 'Times New Roman, Times, serif', 'Verdana, Geneva, sans-serif'
      ]
    }
  };

  // _________________________Alisha Start Date 08/29/2024__________________________


  showNavButtons = false;
  currentIndex = 0;

  // Cost Grid methods start

  validationStates: { [key: string]: NzStatus } = {};


  showEstimatedCost() {
    const modalRef = this.modal.create({
      nzContent: EstimatedCostComponent,
      nzFooter: null,
    });

    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });

    modalRef.componentInstance.onSaveEstimatedCostComplete.subscribe(() => {
    });
  }





  leadProposalItemEdit(costItemParameterDataSet: CostItemParameterResponse) {
    const modalRef = this.modal.create({
      nzContent: EstimatedCostComponent,
      nzFooter: null
    });
    modalRef.componentInstance.getProposalItemData = costItemParameterDataSet;
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    modalRef.componentInstance.onSaveEstimatedCostComplete.subscribe(() => {
    });
  }


  leadProposalTabs(tab: number): string {
    switch (tab) {
      case 1:
        return 'Worksheet';
      case 2:
        return 'Format';
      case 3:
        return 'Preview';
      default:
        return '';
    }
  }

  panels = [
    {
      active: false,
      disabled: false
    },
  ];
}





