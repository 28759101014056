import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { AttachmentResponse, CreateAttachmentForDocumentRequest } from 'src/Models/Attachment_Files_Class/AttachmentFilesClass';
import { ResponseModel } from 'src/Models/responseMessage.model';

@Injectable({
  providedIn: 'root'
})
export class AttachmentService {

  constructor(private http: HttpClient) {}
  
  private baseUrls: string = environment.apiUrl + 'Attachment/';
  private base_Url_Attachment_Parameter: string = environment.apiUrl + 'AttachmentParameter/';

  CreateAttachmentForDocument(createAttachmentForDocumentRequest: CreateAttachmentForDocumentRequest): Observable<any> {
    const formData: FormData = new FormData();
  
    if (createAttachmentForDocumentRequest.headerId) {
      formData.append('headerId', createAttachmentForDocumentRequest.headerId.toString());
    }
  
    if (createAttachmentForDocumentRequest.companyParameterId) {
      formData.append('companyParameterId', createAttachmentForDocumentRequest.companyParameterId.toString());
    }
  
    if (createAttachmentForDocumentRequest.formNameId) {
      formData.append('formNameId', createAttachmentForDocumentRequest.formNameId.toString());
    }
  
    if (createAttachmentForDocumentRequest.documentFiles) {
      createAttachmentForDocumentRequest.documentFiles.forEach((file) => {
        if (file && file.name) {
          formData.append('documentFiles', file, file.name);
        } else {
          console.warn('Invalid or undefined file found, skipping file upload.');
        }
      });
    } else {
      console.warn('No document files provided.');
    }
  
    const headers = new HttpHeaders({
      'enctype': 'multipart/form-data',
    });
    const apiUrl = `${this.baseUrls}create-document`;
  
    return this.http.post<any>(apiUrl, formData, { headers });
  }
  getLeadByAttachmentId(id: number): Observable<ResponseModel<AttachmentResponse>> {
    return this.http.get<ResponseModel<AttachmentResponse>>(
      `${this.baseUrls}getBy-Id?id=${id}`
    );
  }
  deleteData(globalId: string): Observable<ResponseModel<AttachmentResponse>> {
    return this.http.delete<ResponseModel<AttachmentResponse>>(
      `${this.base_Url_Attachment_Parameter}?globalId=${globalId}`
    );
  }
}
