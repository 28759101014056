<!-- standard view -->

<nz-modal
  nzWidth="60%"
  [(nzVisible)]="isVisible"
  [nzTitle]="printModalTitles"
  [nzFooter]="printmodalFooters"
  [nzBodyStyle]="{
    background: '#f1f4fa',
    padding: '14px',
    'max-height': '580px',
    'overflow-y': 'auto'
  }"
  [nzStyle]="{ top: '15px' }"
  (nzOnCancel)="handleManageSavedViews()"
>
  <ng-template #printModalTitles>
    <div class="row p-0 m-0">
      <h3 class="p-0 m-0 fw-bold">Manage Saved Views</h3>
    </div>
  </ng-template>

  <div class="content content-set-background mb-3" *nzModalContent>
    <nz-card class="" [nzBordered]="false" [nzTitle]="CardModalTitles">
      <ng-template #CardModalTitles>
        <div>
          <h5>Manage Saved Views</h5>
        </div>
      </ng-template>

      <div nz-row class="mb-3">
        <div nz-col nzSpan="24">
          <p class="">
            Manage your grid views and select your default view for the Lead Activities
            page.
          </p>
        </div>
      </div>

      <nz-card class="p-0 mb-5" style="background-color: #f1f4fa">
        <div class="inner-Content p-0">
          <div nz-row class="mb-3">
            <div nz-col nzSpan="24">
              <h4>Update Current View</h4>
            </div>
          </div>

          <div nz-row class="mb-3">
            <div nz-col nzSpan="24">
              <p class="">
                Change the current columns displayed on the page. This will not
                affect your currently selected saved view settings.
              </p>
            </div>
          </div>

          <div nz-row>
            <span class="sub-text">Columns</span>
          </div>

          <div
            nz-row
            class="row mb-3 d-flex justify-content-center align-items-center align-content-center"
          >
            <div class="col-md-10 col-sm-12">
              <nz-tree-select
                [nzMultiple]="true"
                [nzCheckStrictly]="true"
                nzShowSearch="true"
                nzCheckable="true"
                [(ngModel)]="selectedColumnName"
                [nzNodes]="columnNameDisplay"
                nzShowSearch
                nzMultiple
                nzCheckStrictly
                nzStatus
                nzAllowClear="false"
                nzDropdownMatchSelectWidth="true"
                nzCheckable
                nzVirtualHeight="200px"
                nzAllowClear="false"
                nzPlaceHolder="Select Columns"
                (ngModelChange)="onDropdownChange()"
              ></nz-tree-select>
            </div>

            <div class="col-md-2 col-sm-12">
              <button nz-button nzType="primary" [disabled]="buttonDisabled"  (click)="applyView()">Apply View</button>
            </div>
          </div>
        </div>
      </nz-card>


   <loader-icon *ngIf="isLoading" class="loader-icon"></loader-icon>


      <div nz-row class="d-flex justify-content-center align-items-center mb-1">
        <div nz-col nzSpan="8">
          <h4>Your Saved Views</h4>
        </div>
        <div nz-col nzSpan="8" nzOffset="8" class="text-end">
          <button
            class="d-flex justify-content-center align-items-center"
            *ngIf="!isInputVisible"
            nz-button
            nzType="text"
            class="text-primary border-0"
            (click)="toggleVisibility()"
          >
            <span
              class="fs-5"
              nz-icon
              nzType="plus-circle"
              nzTheme="fill"
            ></span>
            <span>Add New Saved View</span>
          </button>
        </div>
      </div>

      <nz-card
        class="p-0 mb-5"
        style="background-color: #e6f1fc"
        *ngIf="isInputVisible"
      >
        <form>
          <div
            nz-row
            class="d-flex justify-content-center align-items-center p-0"
          >
            <div nz-col nzSpan="8">
              <h4>Edit Saved View</h4>
            </div>
            <div nz-col nzSpan="8" nzOffset="8" class="text-end">
              <button nz-button nzType="text" (click)="closeManageSavedViews()">
                <span nz-icon nzType="close" nzTheme="outline"></span>
              </button>
            </div>
          </div>

          <div nz-row>
            <div nz-col nzSpan="24">
              <label class="mb-1 fw-bold sub-text">Label</label>
              <input
                      [(ngModel)]="standardFormFieldValues['name']"
                      name="name"
                      type="text"
                      class="form-control formField"
                      #nameField="ngModel"
                      [attr.required]="true"
                      [attr.minlength]="3"
                      [attr.maxlength]="25"
                    />
                    <div *ngIf="nameField.invalid && (nameField.dirty || nameField.touched)">
                      <div *ngIf="nameField.errors?.['required']" class="text-danger p-error">Required</div>
                      <div *ngIf="nameField.errors?.['minlength']" class="text-danger p-error">Minimum length is 3 characters</div>
                      <div *ngIf="nameField.errors?.['maxlength']" class="text-danger p-error">Maximum length is 25 characters</div>
                    </div>
            </div>


            <div nz-col nzSpan="24">
              <label class="mb-1 fw-bold sub-text">Columns</label>
              <nz-tree-select
                [nzMultiple]="true"
                [nzCheckStrictly]="true"
                nzShowSearch="true"
                nzCheckable="true"
                [nzNodes]="columnNameOption"
                nzShowSearch
                nzMultiple
                nzCheckStrictly
                nzStatus
                nzAllowClear="false"
                nzDropdownMatchSelectWidth="true"
                nzCheckable
                nzVirtualHeight="200px"
                nzAllowClear="false"
                nzPlaceHolder="Select Columns"
                [(ngModel)]="standardFormFieldValues['createStandardViewParameterRequests']" name="createStandardViewParameterRequests"
              ></nz-tree-select>
            </div>
          </div>
          <div nz-row class="mt-2">
            <div nz-col nzSpan="24">
              <label [(ngModel)]="standardFormFieldValues['isMarkAsPrivate']" name="isMarkAsPrivate"   nz-checkbox
                >Share this view</label
              >
            </div>
          </div>
          <div nz-row>
            <div nz-col nzSpan="24" class="mt-2 mb-2">
              <label [(ngModel)]="standardFormFieldValues['isSetAsDefault']" name="isSetAsDefault"  nz-checkbox
                >Set as default</label
              >
            </div>
          </div>
          <div nz-row class="text-end mb-3">
            <div nz-col nzSpan="24" class="mt-2 mb-1">
              <button
                nz-button
                nzType="primary"
                *ngIf="!isEditFormVisible"
                (click)="saveStandardView()"
                [disabled]="!this.standardFormFieldValues.name">Add
              </button>
              <button nz-button nzType="primary" *ngIf="isEditFormVisible" (click)="updateFilter()" >
                Update
              </button>
            </div>
          </div>
        </form>
      </nz-card>

      <nz-page-header
        class="headset-border p-0 mb-3"
        nzBorderless="false"
        *ngFor="let filter of standardViewList.slice(1); let i = index"
      >
        <!--title-->
        <nz-page-header-title>
          <div class="ms-3" nz-row>
            <div nz-col nzSpan="18">
              <ng-container *ngIf="filter.isSetAsDefault">
                <nz-tag nzColor="#99daf2" class="rounded-5">
                  <span style="color: #353b99; font-size: 10px" class="fw-bold"
                    >DEFAULT FILTER</span
                  >
                </nz-tag>
              </ng-container>
              <div nz-row class="mb-0" style="margin-top: -7px">
                <span style="font-size: 13px">{{ filter.name }}</span>
              </div>
              <div nz-row class="mb-0">
                <span
                  style="
                    opacity: 70%;
                    font-weight: normal;
                    font-size: 13px;
                    margin-top: -10px;
                  "
                >
                  {{ filter.isMarkAsPrivate ? "Shared" : "Private" }}
                </span>
              </div>
            </div>
          </div>
        </nz-page-header-title>

        <!--extra-->
        <nz-page-header-extra
          class="justify-content-center align-content-center"
        >
          <div nz-row>
            <nz-space class="p-3">
              <button
                nz-button (click)="showEditFilterConfirm(filter , 'EDIT')"
                class="text-primary border-0 me-2 align-items-center"
              >

                <span
                  nz-icon
                  nzType="edit"
                  style="font-size: 22px"
                  nzTheme="fill"
                  class="text-primary"
                  nz-popover
                  [nzPopoverContent]="editTemplate"
                  nzPopoverPlacement="top"
                >
                  <ng-template #editTemplate>
                    <div class="rounded-2">
                      <p style="height: auto; width: auto">Edit</p>
                    </div>
                  </ng-template>
                </span>
              </button>
              <button
                nz-button
                class="text-primary border-0 me-2 align-items-center"
                (click)="showDeleteFilterConfirm(filter.globalId)"
              >
                <span
                  nz-icon
                  nzType="delete"
                  style="font-size: 22px"
                  nzTheme="fill"
                  class="text-primary ms-2"
                  nz-popover
                  [nzPopoverContent]="deleteTemplate"
                  nzPopoverPlacement="top"
                >
                  <ng-template #deleteTemplate>
                    <div class="rounded-2">
                      <p style="height: auto; width: auto">Delete</p>
                    </div>
                  </ng-template>
                </span>
              </button>
              <button
                nz-button
                nz-dropdown
                class="text-primary border-0 me-2 align-items-center"
                [nzPlacement]="'bottomRight'"
                [nzTrigger]="'click'"
                [nzDropdownMenu]="menu2"
              >
                <span
                  nz-icon
                  nzType="ellipsis"
                  style="font-size: 22px"
                  s
                  nzTheme="outline"
                  class="text-primary ms-2"
                  nz-popover
                  [nzPopoverContent]="moreTemplate"
                  nzPopoverPlacement="top"
                >
                  <ng-template #moreTemplate>
                    <div class="rounded-2">
                      <p style="height: auto; width: auto">More Action</p>
                    </div>
                  </ng-template>
                </span>
              </button>
              <nz-dropdown-menu #menu2="nzDropdownMenu">
                <ul nz-menu>
                  <li nz-menu-item [nzDisabled]="filter.isSetAsDefault" (click)="setAsDefaultView1(filter)">
                    Set as Default View
                  </li>

                  <li nz-menu-item *ngIf="!isDuplicateSaveButtonVisible" (click)="showEditFilterConfirm(filter , 'DUPLICATE')">
                    <!-- (click)="EditFilter(filter.id)" -->
                    Duplicate Save View
                  </li>
                </ul>
              </nz-dropdown-menu>
            </nz-space>
          </div>
        </nz-page-header-extra>
      </nz-page-header>

      <div nz-row class="mb-3">
        <div nz-col nzSpan="24">
          <h5 class="fw-bold fs-6">Shared Saved Views</h5>
        </div>
      </div>
      <div nz-row class="mb-3">
        <div nz-col nzSpan="24">
          <p>
            These views have been created by other internal users on your
            account
          </p>
        </div>
      </div>

      <ng-container *ngFor="let filter of standardViewList; let i = index">
        <ng-container *ngIf="i === 0">
          <nz-page-header class="headset-border mt-2 p-0" nzBorderless="false">
            <nz-page-header-title>
              <div class="ms-3" nz-row>
                <div nz-col nzSpan="18">
                  <ng-container *ngIf="filter.isSetAsDefault">
                    <nz-tag nzColor="#99daf2" class="rounded-5">
                      <span
                        style="color: #353b99; font-size: 10px"
                        class="fw-bold"
                        >DEFAULT FILTER</span
                      >
                    </nz-tag>
                  </ng-container>
                  <div nz-row class="mb-0" style="margin-top: -7px">
                    <span style="font-size: 13px">{{ filter.name }}</span>
                    <!-- Replace 'name' with the actual property name in your data -->
                  </div>
                </div>
              </div>
            </nz-page-header-title>

            <!--extra-->
            <nz-page-header-extra>
              <nz-space class="p-3">
                <button
                  nz-button
                  nz-dropdown
                  class="text-primary border-0"
                  [nzPlacement]="'bottomRight'"
                  [nzTrigger]="'click'"
                  [nzDropdownMenu]="menu3"
                >
                  <span
                    nz-icon
                    nzType="ellipsis"
                    style="font-size: 22px"
                    nzTheme="outline"
                    class="text-primary ms-2"
                    nz-popover
                    [nzPopoverContent]="moreTemplate"
                    nzPopoverPlacement="top"
                  >
                  </span>
                </button>
                <ng-template #moreTemplate>
                  <div class="rounded-2">
                    <p style="height: auto; width: auto">More Action</p>
                  </div>
                </ng-template>
                <nz-dropdown-menu #menu3="nzDropdownMenu">
                  <ul nz-menu>
                    <li nz-menu-item [nzDisabled]="filter.isSetAsDefault" (click)="setAsDefaultView1(filter)">
                      Set as Default View
                    </li>
                    <li nz-menu-item (click)="showEditFilterConfirm(filter , 'DUPLICATE')">
                      Duplicate Save View
                    </li>
                  </ul>
                </nz-dropdown-menu>
              </nz-space>
            </nz-page-header-extra>
          </nz-page-header>
        </ng-container>
      </ng-container>
    </nz-card>
  </div>



  <ng-template #printmodalFooters> </ng-template>
</nz-modal>
