import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { SelectionComponent } from '../Selection-Common/selection/selection.component';
import { AddchoiceSelectionChoiceComponent } from '../Selection-Common/addchoice-selection-choice/addchoice-selection-choice.component';
import { NewSelectionComponent } from '../hierarchy/Add Chooice List/new-selection/new-selection.component';
import { ImportSelectionComponent } from '../Selection-Common/import-selection/import-selection.component';
import { FiltersName } from 'src/app/Core/Enums/filtersName';
import { FilterSideMenuBarService } from 'src/shared/Shared-Services/filter-side-menu-bar.service';
import { JobInfoComponentInformation } from 'src/Models/Job-List/Job-Information/Jobinformation';
import { JobInformationService } from 'src/Service/Job-List/Job-Information/job-information.service';
import { SelectionService } from 'src/Service/Selection/selection.service';
import { SelectionChoiceParameterResponse, SelectionChoiceResult, SelectionInformationApprove, SelectionInformationApproveResponse, SelectionInformationResponse, SelectionInformations } from 'src/Models/Selection/selectionClasses';
import { StandardColumnSetupResponse, StandardViewResponse } from 'src/Models/LookupStandardview/Standardview';
import { FilterSearchParameter, LookupFilter, LookupFilterResponse, LookupNameSetupResponse } from 'src/app/shared/component/Models/LookupModels';
import { StandardViewForAllService } from 'src/Service/standard-view-for-all.service';
import { AddNewGridStandardViewComponent } from 'src/app/shared/component/Modal/add-new-grid-standard-view/add-new-grid-standard-view.component';
import { ResponseModelArray } from 'src/Models/responseMessage.model';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ColumnsResizePinProperties } from 'src/Models/InternalUser/internalUserColumnsPinResize';
import { Subscription } from 'rxjs';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { CostCodeService } from 'src/Service/Internaluser/cost-code.service';
import { InternalUserService } from 'src/Service/Internaluser/internal-user.service';
import { ProjectManagementSelectioncardViewwPreDefinedCodes, ProjectManagementSelectionListViewwPreDefinedCodes, SelectionStatusCode } from 'src/app/shared/component/Models/LookUpStandardFilterCodes';
import { NewLeadProposalTemplateComponent } from 'src/app/lead-opportunities/Components/Lead-Template/new-lead-proposal-template/new-lead-proposal-template.component';
import { environment } from 'src/environments/environment.prod';
import { JobShortInformation } from 'src/Models/Job-Scratch/JobFromScratch';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-selection-dashbord',
  templateUrl: './selection-dashbord.component.html',
  styleUrls: ['./selection-dashbord.component.css']
})
export class SelectionDashbordComponent implements OnInit  , OnDestroy {
  private subscriptions: Subscription[] = [];
  private SelectionstandardViewFilterSubscription : Subscription
  private displayColumnSubscription: Subscription;
  private Selectionsubscription: Subscription;
  private SelectionFilterSubscription: Subscription;
  jobInfoOptions: JobShortInformation[] = [];
  selectionResponse: SelectionInformations[] = [];
  PAGE_ID: number = -1;
  selectiontabsdashbord = [2];
  demoValue = 0;
  isCollapsed = false;
  jobId: number;
  formFieldValues: any = {};
  selectedFilter: number = -1;
  lookupFilterResponse: LookupFilterResponse[] = [];
  selectionFrom: string = 'DB';
  companyNameInvalid: boolean = false;
  showErrorCards: boolean = false;
  isFilterApplied: boolean = false;
  public mySelection: string[] = [];
  selectedJobId: number = 0;
  constructor(private modal: NzModalService, private filterService: FilterSideMenuBarService, public AccessLocalStorageService: AccessLocalStorageService,
    private jobInfoService: JobInformationService,
    private selectionService: SelectionService,
    private standardViewService: StandardViewForAllService,
    private fb: FormBuilder,
    public toastService: NzMessageService,
    private changeDetectorRef: ChangeDetectorRef,
    private LookupFilterService: LookupFilterService,
    private CostCodeService: CostCodeService,
    private internalUserService: InternalUserService,

  ) {
    if (this.SelectionFilterSubscription) {
      this.SelectionFilterSubscription.unsubscribe();
    }
    if (this.Selectionsubscription) {
        this.Selectionsubscription.unsubscribe();
    }
    if (this.SelectionstandardViewFilterSubscription) {
      this.SelectionstandardViewFilterSubscription.unsubscribe();
    }
    console.clear();
   }

  ngOnInit(): void {
    this.initLoad();
    this.internalUserService.setSelectedFilterId(0);
    if (this.SelectionFilterSubscription) {
      this.SelectionFilterSubscription.unsubscribe();
    }
    if (this.Selectionsubscription) {
        this.Selectionsubscription.unsubscribe();
    }
    if (this.SelectionstandardViewFilterSubscription) {
      this.SelectionstandardViewFilterSubscription.unsubscribe();
    }
    this.PAGE_ID = 30
    this.selectedJobId = this.AccessLocalStorageService.getJobId();
    console.log('this.selectedJobId',this.selectedJobId);

    this.Choiceform();


    this.displayedColumns = [];
    this.displayColumnSubscription = this.standardViewService.$displaySelectedColApply.subscribe(colName => {
      if (colName.length > 0) {
        this.displayedColumns = colName;
        this.changeDetectorRef.detectChanges();
      }
    });
    // Add subscriptions to the array
    this.subscriptions.push(
      this.standardViewService.$displaySelectedColApply.subscribe(colName => {
        if (colName.length > 0) {
          this.displayedColumns = colName;
          this.changeDetectorRef.detectChanges();
        }
      })
    );

    this.subscriptions.push(
      this.standardViewService.$selectedStandardFilterType.subscribe(standardType => {
        this.selectedFilter = standardType != -1 ? standardType : standardType;
      })
    );
    // this.displayedColumns = [];
    // this.displayColumnSubscription = this.standardViewService.$displaySelectedColApply.subscribe(colName => {
    //   if (colName.length > 0) {
    //     this.displayedColumns = colName;
    //     this.changeDetectorRef.detectChanges();
    //   }
    // });

    // this.displayColumnSubscription = this.standardViewService.$selectedStandardFilterType.subscribe(standardType => {
    //   if (standardType != -1) {
    //     this.selectedFilter = standardType;
    //   } else {
    //     this.selectedFilter = standardType;
    //   }
    // });

    this.getStandardFilterByFormPageId(this.PAGE_ID, this.selectionFrom); // New Changes By Aamir Ali - 22-Apr2024
    this.getStandardViewFilterByFormPageId(this.PAGE_ID);

    this.CostCodeGetData();
    this.showErrorCards = false;
    this.companyNameInvalid = false;

    this.selectionService.$cradviewisUserAppliedFilter.subscribe(filter => {
      if (filter) {
        this.isFilterApplied = filter;
      } else {
        this.isFilterApplied = filter;
      }
    });
  }
  editSelectionOpen(SelectionInformation : SelectionInformationResponse){
    const modalRef = this.modal.create({
      nzContent: SelectionComponent,
      nzFooter: null,
      nzData: {
        SelectionInformation: SelectionInformation,
        SelectionInformation2: SelectionInformation.id
      },
  })
  modalRef.componentInstance.cancel.subscribe(() => {
    modalRef.destroy();
  });
 }





  getInstallerNameOrSelect(parameter: any, index: number): string {
    return parameter?.subVendor?.companyName ? parameter.subVendor.companyName : `Select(${index})`;
  }


  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }



  toggle() {
    this.isCollapsed = !this.isCollapsed;
  }
  Choiceform() {
    this.formFieldValues = {
      globalId: '00000000-0000-0000-0000-000000000000',
      selectionInformationId: 0,
      title: '',
      productLink: '',
      isInCludeInBudget: false,
      subVendorId: 0,
      installerId: 0,
      isShowLineItemToOwner: false,
      description: '',
      attachmentId: 0,
      costItemId: 0,
      isFlatFee: false,
      isLineItem: false,
      builderCost: 0,
      ownerPrice: 0,
      isSetPriceLater: false,
      statusId: 0,
      imageUrl: '',
      isApprove: false,
      isDecline: false,
    };
  }

  selectionTabTitle(tab: number): string {
    switch (tab) {
      // case 1:
      //   return 'Card View';
      case 2:
        return 'List';
      // case 3:
      //   return 'Hierarchy';
      // case 4:
      //   return 'Allowances';

      default:
        return '';
    }
  }
  panels = [
    {
      active: true,
      disabled: false,
      name: 'All Choices (1)',
      childPannel: [
        {
          active: false,
          disabled: true,
          name: 'This is panel header 1-1'
        }
      ]
    }
  ];
  selectionModelOpen(select: string): void {
    const jobId = this.AccessLocalStorageService.getJobId();
    // this.isLoading = true;
    this.selectionService.updateSelectionStatus(null);
    this.jobInfoService.getDataId(jobId).subscribe(res => {
      const modalRef = this.modal.create({
        nzStyle: {
          top: '22px',
        },
        nzData: {
          JOB_ID: jobId,
          JOB_NAME: this.AccessLocalStorageService.getJobName(),
          JOB_SELECTED_OBJECT: res.result
        },
        nzContent: SelectionComponent,
        nzFooter: null
      });
      modalRef.componentInstance.selectedJobId = this.selectedJobId;
      modalRef.componentInstance.cancel.subscribe(() => {
        modalRef.destroy();
      });
      modalRef.componentInstance.onSaveComplete.subscribe(() =>{
        this.internalUserGridTableData(1, this.PAGE_ID , this.defaultFilterIdSelected,this.currentPage,this.pageSize);
      });


      // modalRef.componentInstance.initializationComplete.subscribe((res) => {
      //   this.isLoading = res
      // });
    });
  }


  getLeadData() {
    this.jobInfoService.onGetJobShortInformation().subscribe(Data => {
      this.jobInfoOptions = Data.result;
    });
  }
  importSelectionModelOpen(): void {
    const modalRef = this.modal.create({
      nzContent: ImportSelectionComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });

  }
  selectionChoiceModelOpen() {
    const modalRef = this.modal.create({
      nzContent: AddchoiceSelectionChoiceComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });

  }
  openchooice() {
    const modalRef = this.modal.create({
      nzContent: NewSelectionComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });

  }
  PROJECT_MANG_SELEC_CARD_V: string = FiltersName.PROJECT_MANG_SELEC_CARD_V;
  openFilter(component: string, pageId: number) {
    this.selectionFrom = ''; // New Changes By Aamir Ali - 22-Apr2024
    this.isColumnSticky= new Array(this.displayedColumns.length).fill(false);
    this.filterService.toggleFilter(component, pageId);
    this.selectionService.CradVieweduserAppliedFilter(false);
  }
  // _________________________Abdul_Rehman Start___________________

  // _________________________Abdul_Rehman End_____________________
  displayedColumns: string[] = [];
  columnSizes: { columnName: string, widthSize: number }[] = [];
  isColumnSticky: boolean[] = new Array(this.displayedColumns.length).fill(false);
  getColumnWidthSize: number[] = [];
  // selectedFilter: number = -1;
  selected_View_Columns: string[] = [];
  getStandardViewFilterData: StandardViewResponse[] = [];
  // getSelectUserData: SelectionInformationResponse[] = [];
  getSelectUserData: SelectionInformations[] = [];
  filterSearchParameter: FilterSearchParameter;
  totalRecords = 100;
  currentPage = 1;
  pageSize = 10;
  selectedValueControl: any;
  isLoading: boolean = false;
  defaultFilterIdSelected: number = 0;
  selectedColNameSizePin: ColumnsResizePinProperties[] = [];
  getStandardViewColumnFilterData: StandardColumnSetupResponse[] = [];
  fixedSelectedColumnNames: string[] = [];
  CoseCode: { id: number, title: string, detail: string }[] = [];

  toggleColumnSticky(index: number) {
    this.isColumnSticky[index] = !this.isColumnSticky[index];
    if (this.isColumnSticky[index]) {
      for (let i = 0; i < index; i++) {
        this.isColumnSticky[i] = true;
      }
    }
  }
  onRowExpand(event: any) {
    if (event.dataItem.TotalChoice <= 0) {
      event.preventDefault(); // Prevent expanding the row
    }
  }

  onRowCollapse(event: any) {
  }

  saveColumnSizes(event: any) {
    event.forEach(col => {
      const columnName = col.column.field;
      const widthSize = col.newWidth;
      const existingIndex = this.columnSizes.findIndex(item => item.columnName === columnName);

      if (existingIndex !== -1) {
        this.columnSizes[existingIndex].widthSize = widthSize;
      } else {
        this.columnSizes.push({ columnName, widthSize });
      }
    });

  }
  onFilterChange(id: number) {
    this.isColumnSticky = new Array(this.displayedColumns.length).fill(false);

    this.selectedFilter = -1;
    this.displayedColumns = [];
    this.selected_View_Columns = [];
    let selectedView = this.getStandardViewFilterData.find(view => view.id === id).standardViewParameters;
    selectedView.forEach(viewItem => {
      if (viewItem.standardColumnSetup && viewItem.standardColumnSetup.displayColumnName) {
        this.selected_View_Columns.push(viewItem.standardColumnSetup.displayColumnName);
        this.displayedColumns = this.selected_View_Columns;

        const columnIndex = this.displayedColumns.indexOf(viewItem.standardColumnSetup.displayColumnName);
        if (columnIndex !== -1) {
          this.isColumnSticky[columnIndex] = viewItem.isPin;
          this.getColumnWidthSize[columnIndex] = viewItem.resizeColumnWidth;
        }
      }
    });
  }

  internalUserGridTableData(companyId: number, formNameId: number , lookUpFilterId: number, page: number, pageSize: number ){
    this.filterSearchParameter = {
      CompanyParameterId: companyId,
      FormNameId: formNameId,
      LookUpFilterId:lookUpFilterId,
      page: page,
      pageSize: pageSize,
      jobInformationId: this.selectedJobId
    };

    this.selectionService.getAllselectUsersByFilter(this.filterSearchParameter);
    this.Selectionsubscription = this.selectionService.$getSelectionInformation.subscribe(result => {
      if (result) {
        this.totalRecords = result?.result?.rowCount;
        this.getSelectUserData = result?.result?.rows;
        this.selectionResponse = this.getSelectUserData;

      }
    });
  }
  showCollapsePanel: boolean = false;
  saveChoice(selectionId: number) {
     this.formFieldValues.selectionInformationId = selectionId;

    this.selectionService.PostChoice(this.formFieldValues).subscribe(res => {
      const id = this.AccessLocalStorageService.getSelectedFilterId();

      this.internalUserGridTableData(1, this.PAGE_ID, id, this.currentPage, this.pageSize);
      this.formFieldValues = {};
      this.showCollapsePanel = true;
    });
  }
  openStandardViewFilterComponent(isSaveAs?: string) {
    let isInputVisible: boolean = false;
    if (isSaveAs === 'saveAs') {
      isInputVisible = true;
    } else {
      isInputVisible = false;
    }
    this.selectedColNameSizePin = [];
    for (let i = 0; i < this.displayedColumns.length; i++) {
      const item = this.displayedColumns[i];
      const isPinValue = this.isColumnSticky[i];
      let widthSize = 0;
      if (this.columnSizes.length > 0) {
        for (let i = 0; i < this.columnSizes.length; i++) {
          const columnName = this.columnSizes[i].columnName;
          const columnNameSize = this.columnSizes[i].widthSize;
          if (columnName === item) {
            widthSize = columnNameSize;
            break;
          }
        }
      }

      if (widthSize === 0) {
        widthSize = 180;
      }

      this.selectedColNameSizePin.push({
        displayColumnName: item,
        resizeColumnWidth: widthSize, //  "200px", // Set default width here
        isPin: isPinValue == true ? isPinValue : false
      });
    }
    const modalRef = this.modal.create({
      nzContent: AddNewGridStandardViewComponent,
      nzData:
      {
        pageId: this.PAGE_ID,
        column: this.getStandardViewColumnFilterData,
        displayCol: this.displayedColumns,
        viewList: this.getStandardViewFilterData,
        selectedColNameSizePin: this.selectedColNameSizePin,
        selectedStandardView: this.selectedValueControl,
        fixedCol: this.fixedSelectedColumnNames,
        isSaveAs: isInputVisible
      },

    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  reInitReset() {
    this.ngOnInit();
    this.selectedFilter = -1;
  }
  saveEditStandardView() {
    this.standardViewService.$createStandardViewRequest.subscribe(formData => {
      if (formData && formData !== null) {
        this.standardViewService.postData(formData)
          .subscribe(
            (res: ResponseModelArray<StandardViewResponse>) => {
              setTimeout(() => {
                this.isLoading = true;
                this.toastService.success('Standard View Updated Successfully');
                this.standardViewService.getStandardViewFilterByFormPageId(this.PAGE_ID);
                this.selectedFilter = -1;
              }, 10);
            },
            (error) => {
              console.error('Error:', error);
              this.isLoading = false;
            }
          );
      }
    });
  }
  onPageSizeChange(size: number): void {
    this.isColumnSticky = new Array(this.displayedColumns.length).fill(false);
    this.pageSize = size;
    let filterId: number = 0;
    this.selectionService.$cradViewselectedFilterId.subscribe(v => {
      if (v) {
        filterId = v;
      }
    });
    this.isLoading = true;
    setTimeout(() => {
      this.defaultFilterIdSelected = filterId;
      this.internalUserGridTableData(1, this.PAGE_ID, filterId, this.currentPage, this.pageSize);
      this.isLoading = false;

    }, 100);
  }
  onPageChange(page: number): void {
    this.currentPage = page;
    let filterId: number = 0;
    this.selectionService.$cradViewselectedFilterId.subscribe(v => {
      if (v) {
        filterId = v;
      }
    });
    this.isLoading = true;
    setTimeout(() => {
      this.defaultFilterIdSelected = filterId;
      this.internalUserGridTableData(1, this.PAGE_ID, filterId, page, this.pageSize);
      this.isLoading = false;

    }, 100);
  }
  getStandardFilterByFormPageId(pageId: number, type: string) {// New Changes By Aamir Ali - 22-Apr2024
    if (this.SelectionFilterSubscription) {
      this.SelectionFilterSubscription.unsubscribe();
  }
  if (this.Selectionsubscription) {
      this.Selectionsubscription.unsubscribe();
  }
    this.LookupFilterService.getStandardFilterByFormPageId(pageId);
    this.SelectionFilterSubscription = this.LookupFilterService.$getStandardFilter.subscribe(result => {
      if (result) {
        this.lookupFilterResponse = result.result;
        let defaultFilterId = result.result.find(filter => filter.isSetAsDefault === true).id;
        this.selectionService.CradViewedsetSelectedFilterId(defaultFilterId);
        this.selectionService.CradViewedsetSelectedFilterId(defaultFilterId);
        let id = -1;
        if (type === this.selectionFrom) {
          this.defaultFilterIdSelected = defaultFilterId;
          this.AccessLocalStorageService.setSelectedFilterId(defaultFilterId);

          // New Changes By Aamir Ali - 22-Apr2024
          id = this.AccessLocalStorageService.getSelectedFilterId();
        } else {
          id = this.AccessLocalStorageService.getSelectedFilterId();
        }

        if (id !== -1) {
          this.internalUserGridTableData(1, this.PAGE_ID, id, this.currentPage, this.pageSize);
        }

      }
    });
  }
  getStandardViewFilterByFormPageId(pageId: number) {
    if (this.SelectionstandardViewFilterSubscription) {
      this.SelectionstandardViewFilterSubscription.unsubscribe();
    }
    this.displayedColumns = [];
    this.fixedSelectedColumnNames = [];
    this.standardViewService.getStandardViewFilterByFormPageId(pageId);
    this.standardViewService.getStandardViewFilterColumnsByFormPageId(pageId);
    // this.standardViewService.$getStandardViewFilter.subscribe(result => {
    this.SelectionstandardViewFilterSubscription = this.standardViewService.$getStandardViewFilter.subscribe(result => {
      if (result) {
        this.displayedColumns = [];
        this.selected_View_Columns = [];
        this.getStandardViewFilterData = result.result;
        let defaultView = this.getStandardViewFilterData.find(view => view.isSetAsDefault === true)?.standardViewParameters;
        this.selectedValueControl = this.getStandardViewFilterData.find(view => view.isSetAsDefault === true).id;
        defaultView.forEach(viewItem => {
          if (viewItem.standardColumnSetup && viewItem.standardColumnSetup.displayColumnName) {
            this.selected_View_Columns.push(viewItem.standardColumnSetup.displayColumnName);
          }
        });
        this.displayedColumns = this.selected_View_Columns;
      }
    });

    this.standardViewService.$getStandardViewColumn.subscribe(result => {
      if (result) {
        this.getStandardViewColumnFilterData = result.result;
        let fixedCol = this.getStandardViewColumnFilterData.filter(x => x.isSelected === true);
        fixedCol.forEach(x => {
          this.fixedSelectedColumnNames.push(x.displayColumnName);
        });

      }
    });
  }
  CostCodeGetData() {
    this.CostCodeService.getData().subscribe(
      (res: any) => {
        this.CoseCode = res.result.map((costCode: any) => ({
          id: costCode.id,
          title: costCode.title,
          detail: costCode.detail
        }));
      },
      (error) => {
        console.error('Error fetching cost codes:', error);
      }
    );
  }
  VendorsAllUsers = false;
  InstallersAllUsers = false;

  toggleVendors(): void {
    this.VendorsAllUsers = !this.VendorsAllUsers;
  }
  toggleInstallers(): void {
    this.InstallersAllUsers = !this.InstallersAllUsers;
  }
  getSelectedJobId(item: number) {
    this.selectedJobId = item;
    this.selectionResponse = null;
    this.getSelectUserData = null;
    this.getStandardFilterByFormPageId(this.PAGE_ID,this.selectionFrom);
  }

  expandedDetailKeys = new Set<number>();

  toggleDetail(dataItem: any): void {
    const itemId = dataItem.id;
    if (this.expandedDetailKeys.has(itemId)) {
      this.expandedDetailKeys.delete(itemId);
    } else {
      this.expandedDetailKeys.add(itemId);
    }
  }

  public hideExpandIcon: boolean = true;
  public toggleExpandIconVisibility(): void {
    this.hideExpandIcon = !this.hideExpandIcon;
  }
  isExpanded = false;

  toggleExpand() {
    this.isExpanded = !this.isExpanded;
  }
  ngOnDestroy() {
    this.LookupFilterService.getStandardFilter.next(null); // New Changes By Aamir Ali - 31-May-2024
    localStorage.removeItem('Selected_Filter_Id');
    if (this.SelectionFilterSubscription) {
      this.SelectionFilterSubscription.unsubscribe();
    }
    if (this.Selectionsubscription) {
        this.Selectionsubscription.unsubscribe();
    }
    if (this.SelectionstandardViewFilterSubscription) {
      this.SelectionstandardViewFilterSubscription.unsubscribe();
    }
    console.clear();
  }


  isSelectAll: boolean = false; // Initially, the checkbox is unchecked

onSelectAllChange() {
  // Any additional logic when the checkbox changes
  console.log(this.isSelectAll ? 'Checkbox is checked' : 'Checkbox is unchecked');
}
@Input() pageId: number;



deadlineOptions: LookupNameSetupResponse[] = [];
preDefinedDataCodes = {
  Title: ProjectManagementSelectioncardViewwPreDefinedCodes.Sort,
};
lookupSetNameResponse: LookupNameSetupResponse[] = [];
selectedOption: number;

initLoad() {
  this.LookupFilterService.getLookUpValuesByFormNameId(30).subscribe(
    (res) => {
      this.lookupSetNameResponse = res.result;
      this.deadlineOptions = res.result.filter(
        (x) => x.code === this.preDefinedDataCodes.Title.code
      );
      if (this.deadlineOptions.length > 0) {
        this.selectedOption = this.deadlineOptions[0].id;

      }
    },
    (error) => {
      console.error('Error fetching lookup values', error);
}
);
}
currentPageActivityView: number = 1;
pageSizeActivityView: number = 100;
// FormEdit(Id: number): void {
//   const modalRef = this.modal.create({
//     nzContent: SelectionComponent,
//     nzData: {
//       // leadData: this.getLeadProposalisTemplateData
//     },
//     nzFooter: null
//   });
//   modalRef.componentInstance.newSelectionId = Id;

//   modalRef.componentInstance.cancel.subscribe(() => {
//     modalRef.destroy();
//   });

// }



getFileName(filePath: string): string {
  return filePath?.trim().split('/').pop() || '';
}


FormEdit(Id: number): void {
  // this.isLoading = true
  const modalRef = this.modal.create({
    nzContent: SelectionComponent,
    nzFooter: null
  });
  modalRef.componentInstance.newSelectionId = Id;
  modalRef.componentInstance.cancel.subscribe(() => {
    modalRef.destroy();
  });
  modalRef.componentInstance.onSaveComplete.subscribe(() => {
    this.internalUserGridTableData(1, this.PAGE_ID, this.defaultFilterIdSelected, this.currentPageActivityView, this.pageSizeActivityView);
  });
  this.selectionService.Selectionsresponses$.subscribe(
    (response) => {
      if (response?.result?.id) {
        this.internalUserGridTableData(1, this.PAGE_ID, this.defaultFilterIdSelected, this.currentPageActivityView, this.pageSize);
      } else {
        console.error("No activity response ID found");
      }
    },
    (error) => {
      console.error("Error fetching lead activity type response:", error);
    }
  );
  // modalRef.componentInstance.initializationComplete.subscribe((res) => {
  //   this.isLoading = res
  // });
}


fileDetails: { filePath: string; extension: string }[] = [];
holdId : number;
fetchActivityResponse(id: number): void {
  this.selectionService.getChoiceBy(id)?.subscribe((res) => {
    if (res && res.result) {
      const response = res.result[0] as SelectionChoiceResult;

      // Check if FilePath exists and is not empty
      if (response.FilePath) {
        const filePaths = response.FilePath.split(',').map(path => path.trim());
        const fileCount = filePaths.length;
        this.fileDetails = filePaths.map(path => ({
          filePath: `${this.IMG_BASE}${path}`,
          extension: path.split('.').pop() || ''
        }));

        response.fileCount = fileCount;
        this.holdId = response.Id;
        response.FilePath = this.fileDetails[0]?.filePath || '';

        // Update expandedData for the specific row
        const row = this.getSelectUserData.find(item => item.Id === id);
        if (row) {
          row.expandedData = {
            ...response,
            fileCount,
            fileDetails: this.fileDetails
          };
          console.log("show all file paths___", response.FilePath);
        }
      } else {
        // Handle case where FilePath is null or empty
        const row = this.getSelectUserData.find(item => item.Id === id);
        if (row) {
          row.expandedData = {
            ...response,
            fileCount: 0,
            fileDetails: [],
            FilePath: '' // Set an empty path if no files are available
          };
        }
      }
    }
  });
}





// onDetailExpand(event: any): void {
//   const dataItem = event.dataItem;
//   this.expandedRowId = dataItem.Id; // Store expanded row ID
//   this.fetchActivityResponse(dataItem.Id); // Fetch details when expanding
// }

onDetailExpand(event: any): void {
  const dataItem = event.dataItem;

  // Check if TotalChoice is greater than 0
  if (dataItem?.TotalChoice > 0) {
    this.expandedRowId = dataItem.Id; // Store expanded row ID
    this.fetchActivityResponse(dataItem.Id); // Fetch details when expanding
  } else {
    console.log('TotalChoice is 0 or less. Row expansion not allowed.');

    // Prevent the default expansion behavior
    event.preventDefault(); // Prevent the default expand action
  }
}


onDetailCollapse(event: any): void {
  console.log(`Collapsing detail for row ID: ${this.expandedRowId}`); // Log when collapsing a row
  this.expandedRowId = null; // Clear expanded row ID
}

IMG_BASE: string = environment.IMG_BUCKET_URL;
expandedRowId: number | null = null;


SelectionStatusCode = SelectionStatusCode;
statussetupColorMap = {
  [SelectionStatusCode.PendingNoChoice.code]: { backgroundColor: '#fbe9b1', textColor: '#653200' },
  [SelectionStatusCode.UnReleased.code]: { backgroundColor: '#c7d0d9', textColor: '#202227' },
  [SelectionStatusCode.PendingAvailable.code]: { backgroundColor: '#fbe9b1', textColor: '#653200' }, // New color
  [SelectionStatusCode.PriceTBD.code]: { backgroundColor: '#fbe9b1', textColor: '#653200' }, // New color
  [SelectionStatusCode.SubVendorPrice.code]: { backgroundColor: '#fbe9b1', textColor: '#653200' }, // New color
  [SelectionStatusCode.ExpiredAvailable.code]: { backgroundColor: '#f8d7da', textColor: '#721c24' }, // New color
  [SelectionStatusCode.ExpiredNoChoices.code]: { backgroundColor: '#f8d7da', textColor: '#721c24' }, // New color
  [SelectionStatusCode.PendingOverdue.code]: { backgroundColor: '#f5c6cb', textColor: '#721c24' }, // New color
  [SelectionStatusCode.Selected.code]: { backgroundColor: '#c4fae2', textColor: '#005c35' },
  [SelectionStatusCode.ExpiredAvailables.code]: { backgroundColor: '#f8d7da', textColor: '#721c24' },
};



OpenAproveStatus() {
  this.Approve = true;

}
OpenAproveStatuscancel() {
  this.Approve = false;
  // this.cancel.emit()

}

iniliazeFormApproveForm() {
  this.ApproveForm = this.fb.group({
    id: [''],
    globalId: ['00000000-0000-0000-0000-000000000000'],
    ApprovalComments: ['', [Validators.required, Validators.maxLength(50)]],
  });
}

// approve Working
handleApprove() {
  if (this.timeUpdated) {
    this.saveFormWithUpdatedTime();
  } else {
    this.instantlyHandleSelectionResponses();
  }
}
saveFormWithUpdatedTime() {
  console.log("Time has been updated. Saving the form with new time...");
  this.ApproveStatus();
  this.Approve = false;

}
instantlyHandleSelectionResponses() {
  console.log("Time has not been updated. Handling approval...");
  this.ApproveStatus();
  this.Approve = false;
}
ApproveStatus() {
  this.isButtonDisabled = true;

  const selectionData: SelectionInformationApprove = {
    selectionInformationId: this.expandedRowId,
    selectionChoiceParameterId: this.expandedRowId,
    approvalNotes: 'New',
    statusId: 441
  };
  this.selectionService.PostApprove(selectionData).subscribe({
    next: (response) => {

      this.toastService.success('Approval successful');
      this.fetchActivityResponse(this.holdId);
      this.selectionService.getAllselectUsersByFilter(this.filterSearchParameter);
      this.Selectionsubscription = this.selectionService.$getSelectionInformation.subscribe(result => {
        if (result) {
          this.totalRecords = result?.result?.rowCount;
          this.getSelectUserData = result?.result?.rows;
          this.selectionResponse = this.getSelectUserData;

        }
      });
      this.Approvehide = response?.result;
      console.log('Approval successful:', this.Approvehide);
      this.changeDetectorRef.detectChanges();

      this.isApproved = true;
      this.changeDetectorRef.detectChanges();
    },
    error: (error) => {
      console.error('Approval failed:', error);
      this.isButtonDisabled = false;
    }
  });
}
Approvehide: SelectionInformationApprove | any;
isApproved: boolean = false;
isButtonDisabled: boolean = false;
ApproveForm: FormGroup;
public Approve = false;
timeUpdated: boolean = false;

}




