import { AccessLocalStorageService } from './../../../../../Service/access-local-storage.service';
import { DailyLogSetupResponse, DailyLogRelatedItemsResult, WeatherCondition } from './../../../../../Models/Project-Management/projectManagement';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { ProjectManegmentSiteDiriesPreDefinedCodes } from 'src/app/shared/component/Models/LookUpStandardFilterCodes';
import { JobInformationResponse } from 'src/Models/Job-Scratch/JobFromScratch';
import { LookupNameSetupResponse } from 'src/Models/LeadTags';
import { AttachmentService } from 'src/Service/Attachment/attachment.service';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { JobInformationService } from 'src/Service/Job-List/Job-Information/job-information.service';
import { ProjectManagementSiteDiariesAddTagComponent } from '../project-management-site-diaries-add-tag/project-management-site-diaries-add-tag.component';
import { AttachmentResponse } from 'src/Models/Attachment_Files_Class/AttachmentFilesClass';
import { AuditDetailsResponse, FilterFooterAudit } from 'src/Models/Footer/footer.model';
import { FooterService } from 'src/Service/Footer/footer.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { SiteDiariesService } from 'src/Service/Project-Management/site-diaries.service';
import { DailyLogResult, WeatherData } from 'src/Models/Project-Management/projectManagement';
import { LoadingIndicatorService } from 'src/shared/Shared-Services/loading-indicator.service';
import { CreateAttachmentsCommonComponent } from 'src/app/shared/component/create-attachments-common/create-attachments-common.component';
import { saveAttachments } from 'src/app/helpers/Attachment_Save';
import { Emitter } from 'ckeditor5';
import { Subject, Subscription } from 'rxjs';
import { BehaviorsubjectService } from 'src/Service/behaviorsubject/behaviorsubject.service';



interface CustomUploadFile extends NzUploadFile {
  loading?: boolean;
  error?: boolean;
  addedBy: number
  createdOn: Date;
  modifiedOn: Date;
  id: number;
  globalId: string;
}
@Component({
  selector: 'app-project-management-site-diaries-new-daily-log',
  templateUrl: './project-management-site-diaries-new-daily-log.component.html',
  styleUrls: ['./project-management-site-diaries-new-daily-log.component.css']
})


export class ProjectManagementSiteDiariesNewDailyLogComponent implements OnInit {
  @ViewChild(CreateAttachmentsCommonComponent) attachmentsComponent!: CreateAttachmentsCommonComponent;
  DailyLogForm: FormGroup;
  dailyLogVisible = true;
  @Output() cancel = new EventEmitter<Emitter>();
  @Output() cancelForTodo = new EventEmitter<number>();

  tagNodes: NzTreeNodeOptions[] = [];
  jobList: JobInformationResponse[] = [];
  pageId: number = 5;
  lookupSetNameResponse: LookupNameSetupResponse[] = [];
  TestAttachment: AttachmentResponse;
  selectedFilesAttachment: CustomUploadFile[] = [];
  tagLoaded: any;
  tagResponse: any;
  isSingleTagSelected: boolean = false;
  isDeleteLoading: boolean = false;
  selectedDailyLogId: number;
  selectedJobId: number;
  selectedJobName: string;
  selectedJobStreetAddress: string;
  footerResponse: AuditDetailsResponse;
  dailyLogResponse: DailyLogResult;
  confirmModal?: NzModalRef;
  preDefinedDataCodes = {
    tags: ProjectManegmentSiteDiriesPreDefinedCodes.Tags,
  };
  isLoading = true;


  // dynamic Related  Variables Start

  relatedHeaderId: number = 0;
  relatedFormNameId: number = 5;
  selectedTodoNotes: string;
  relatedToDoList: DailyLogRelatedItemsResult[];
  selectedDailyLogNotes: string;
  LocationIconVisiblity: boolean;




  formatDate(dateString: string): string {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      weekday: 'short', // Mon
      year: 'numeric',   // 2024
      month: 'short',    // Oct
      day: 'numeric',    // 7
      hour: 'numeric',   // 5
      minute: 'numeric', // 18
      hour12: true       // PM format
    });
  }


  constructor(private fb: FormBuilder,
    private http: HttpClient,
    private LookupFilterService: LookupFilterService,
    private jobsService: JobInformationService,
    private _AttachmentService: AttachmentService,
    private modal: NzModalService,
    private _footerService: FooterService,
    private _toastService: NzMessageService,
    private _siteDiariesDailyLogService: SiteDiariesService,
    private _loadingIndicatorService: LoadingIndicatorService,
    private _accessLocalStorageService: AccessLocalStorageService,
    private _BehaviorSubjectService: BehaviorsubjectService,
    


  ) { }

  ngOnInit(): void {

    setTimeout(() => {
      this.isLoading = false;
    }, 3000); // 1 second delay

    this.DailyLogSetupChages();


    this._loadingIndicatorService.show();
    this.InitializeForm();

    Promise.all([this.initLoad(), this.setupFormValueChangeHandler()])
      .then(async () => {
        // This part will execute after both promises resolve
        if (this.selectedDailyLogId) {
          const res = await this._siteDiariesDailyLogService.getByIdDailyLog(this.selectedDailyLogId).toPromise();
          console.log('response', res.result);
          if (res.result) {
            await this.patchedDailyLog(res.result); // Await the patched method
          }
        }
        this._loadingIndicatorService.hide(); // Hide the loading indicator here after patching
      })
      .catch((error) => {
        console.error('Error during initial load:', error);
        this._loadingIndicatorService.hide();
      });

    this.DailyLogForm.statusChanges.subscribe(() => {
      if (this.hasAttemptedSubmit) {
        this.errorMessages = this.getFormValidationErrors();
      }
    });
  }

  DailyLogSetupChages() {
    this._siteDiariesDailyLogService.getDailyLogDataByCompany().subscribe(
      (res) => {
        if (res.result) {
          const dailyLog = res.result;


          this.LocationIconVisiblity = res.result.isStampLocation;

          if (!this.selectedDailyLogId) {
            this.DailyLogForm.patchValue({
              notes: dailyLog.notes,
              isIncludeWeatherConditions: dailyLog.isIncludeWeatherConditionsDefault,
              isIncludeWeatherNotes: dailyLog.isIncludeWeatherConditionNotesDefault,
            });
          }
        }
      },
    );
  }

  // IconShowOnCheck() {
  //   this._siteDiariesDailyLogService.getDailyLogDataByCompany().subscribe(
  //     (res) => {
  //       console.log('icon result', res.result);

  //       if (res.result ) {
  //         if(res.result.isStampLocation === true ){
  //           this.LocationIcon = true;
  //         }
  //         else {
  //         this.LocationIcon = false;
  //       }
  //       }
  //     },
  //     (error) => {
  //       console.error('Error:', error);
  //     }
  //   );
  // }










  InitializeForm() {

    const defaultNotes = this.selectedTodoNotes ? `[To-Do Notes]\n${this.selectedTodoNotes}` : ''; // Conditionally set notes

    this.DailyLogForm = this.fb.group({
      id: [],
      globalId: ['00000000-0000-0000-0000-000000000000'],
      jobSetupId: [0],
      jobInformationId: [this.selectedJobId],
      date: [new Date(), Validators.required],
      title: ['', [Validators.maxLength(50)]],
      notes: [defaultNotes, Validators.maxLength(4000)],
      isIncludeWeatherConditions: [false],
      isIncludeWeatherNotes: [false],
      weatherNotes: ['', Validators.maxLength(512)],
      weatherId: [0],
      weatherName: '',
      weatherIconPath: '',
      startDegreeCentigrade: [null],
      endDegreeCentigrade: [null],
      wind: [''],
      humidity: [''],
      totalPrecipitation: [''],
      weatherOn: [null],
      isPermissionInternalUser: [false],
      isPermissionSubVendor: [false],
      isPermissionOwner: [false],
      isPermissionPrivate: [false],
      createDailyLogTagParameterRequests: [null],
      createDailyLogNotifyUserRequest: this.fb.array([]),
    });

  }

  initLoad(): Promise<void> {
    return new Promise((resolve, reject) => {
      // Fetch lookup values
      this.LookupFilterService.getLookUpValuesByFormNameId(this.pageId).subscribe(
        (res) => {
          this.lookupSetNameResponse = res.result;

          // -----------Multi Select --------------
          this.tagResponse = res.result.filter(
            (x) => x.code === this.preDefinedDataCodes.tags.code
          );

          // -----------Single Select --------------
          this.tagNodes = [
            {
              title: 'Select All',
              value: 'select_all',
              key: 'select_all',
              selectable: false,
              isLeaf: false,
              expanded: true,
              children: this.tagResponse.map((tag) => ({
                title: tag.name,
                value: tag.id.toString(),
                key: tag.id.toString(),
                isLeaf: true,
              })),
            },
          ];

          resolve();
        },
        (error) => {
          console.error('Error loading lookup values:', error);
          reject(error);
        }
      );

      this.jobsService.onGetJobShortInformation().subscribe(
        (res: any) => {
          this.jobList = res.result;

          resolve();
        },
        (error) => {
          console.error('Error loading job data:', error);
          reject(error);
        }
      );
    });
  }




  async patchedDailyLog(dailyLog: DailyLogResult): Promise<void> {
    this._loadingIndicatorService.show();
    this.selectedJobName = dailyLog.JobInformationName;

    try {
      if (this.tagNodes && dailyLog.DailyLogTags) {
        const tagIdsArray = dailyLog.DailyLogTags.split(',').map(id => id.trim());
        this.DailyLogForm.patchValue({
          createDailyLogTagParameterRequests: tagIdsArray
        });
      }

      const promises = [];

      if (dailyLog?.AttachmentId) {
        promises.push(this.fetchAttachments(dailyLog.AttachmentId));
      }

      const logDate = new Date(dailyLog.Date);
      if (isNaN(logDate.getTime())) {
        throw new Error('Invalid date');
      }

      this.DailyLogForm.patchValue({
        id: dailyLog.Id,
        globalId: dailyLog.GlobalId,
        jobSetupId: dailyLog.JobSetupId,
        jobInformationId: dailyLog.JobInformationId,
        date: logDate,
        title: dailyLog.Title,
        notes: dailyLog.Notes,
        weatherNotes:dailyLog.WeatherNotes,
        isIncludeWeatherConditions: dailyLog.IsIncludeWeatherConditions,
        isIncludeWeatherNotes: dailyLog.IsIncludeWeatherNotes,
        isPermissionInternalUser: dailyLog.IsPermissionInternalUser,
        isPermissionSubVendor: dailyLog.IsPermissionSubVendor,
        isPermissionOwner: dailyLog.IsPermissionOwner,
        isPermissionPrivate: dailyLog.IsPermissionPrivate,
      });

      this.Messaging_Id = dailyLog.MessagingId;
      this.JobInfoId = dailyLog.JobInformationId;
      this.dailyLogId = dailyLog.Id;
      this.formNameId = 5;
      this.dailyLogResponse = dailyLog;
      // this.relatedHeaderId = dailyLog.Id;

      // Fetch related to-dos and create/modify data if ToDoId is valid
      if (dailyLog.ToDoId > 0) {
        promises.push(this.getAllRelatedToDos(dailyLog.ToDoId));
      }
      promises.push(this.createAndModifiedByData(dailyLog.Id));

      // Wait for all calls to complete
      await Promise.all(promises);
    } catch (error) {
      console.error('Error patching daily log:', error);
    } finally {
      this._loadingIndicatorService.hide();
    }
  }

  getAllRelatedToDos(dailyLogId: number): Promise<void> {
    return new Promise((resolve, reject) => {
      this._siteDiariesDailyLogService.dailyLogRelatedTodo(dailyLogId).subscribe({
        next: (res) => {
          this.relatedToDoList = res.result;
          resolve();
        },
        error: (err) => {
          console.error('Error fetching related to-dos:', err);
          reject(err);
        }
      });
    });
  }


  createAndModifiedByData(id: number): Promise<void> {
    return new Promise((resolve, reject) => {
      const payload: FilterFooterAudit = {
        id: id,
        formCode: 'PMSD'
      };
      this._footerService.createdAndModifyUser(payload).subscribe({
        next: (res) => {
          this.footerResponse = res.result;
          resolve();
        },
        error: (err) => {
          console.error('Error fetching created/modified data:', err);
          reject(err);
        }
      });
    });
  }

  fetchAttachments(AttachmentId: number): Promise<void> {
    return new Promise((resolve) => {
      if (AttachmentId) {
        this._AttachmentService.getLeadByAttachmentId(AttachmentId).subscribe(
          (res) => {
            this.TestAttachment = res.result;
            resolve();
          },
          () => {
            resolve(); // Resolve even if there's an error to prevent hanging
          }
        );
      } else {
        this.TestAttachment = undefined;
        resolve(); // Resolve immediately if no AttachmentId
      }
    });
  }



  onCheckboxChange2(): void {
    const isChecked = this.DailyLogForm.get('isIncludeWeatherNotes')?.value;
    this.DailyLogForm.get('isIncludeWeatherNotes')?.setValue(isChecked);
  }



  checkGetWeather() {
    if (this.DailyLogForm.get('isIncludeWeatherConditions')?.value === true) {
      this.getWeather(); // Call the method that fetches the weather
    }
  }


  weatherData: WeatherData; // To store the full response
  wind: number | undefined;
  precipitation: number | undefined; humidity: number = 0; // To store humidity
  conditionText: string = '';
  isLoadingWeather = false;


  getWeather(): void {
    const city = 'Karachi'; // Example city, you can replace it with dynamic input
    const apiKey = '47c32bf7d8msh56a6e680e754763p155991jsn80a529846e81';
    const apiUrl = `https://weatherapi-com.p.rapidapi.com/current.json?q=${city}`;

    const headers = new HttpHeaders({
      'x-rapidapi-key': apiKey,
      'x-rapidapi-host': 'weatherapi-com.p.rapidapi.com'
    });

    this.isLoadingWeather = true;

    this.http.get<WeatherData>(apiUrl, { headers }).subscribe(
      (data: WeatherData) => {
        // Cast the response to WeatherData
        this.weatherData = data;
        this.isLoadingWeather = false;
        const wind = this.weatherData.current?.wind_kph;
        const precipitation = this.weatherData.current?.precip_mm;
        const humidity = this.weatherData.current?.humidity;
        const conditionText = this.weatherData.current?.condition.text;
        const weatherOn = this.weatherData.current?.last_updated;
        const startDegreeCentigrade = this.weatherData.current?.temp_c;
        const endDegreeCentigrade = this.weatherData.current?.feelslike_c;
        const weatherName = this.weatherData.current?.condition.text;
        const weatherIconPath = this.weatherData.current?.condition.icon;

        // Convert weatherOn to Date object
        const weatherOnDate = new Date(weatherOn.replace(" ", "T") + ":00");

        this.DailyLogForm.patchValue({
          wind: wind?.toString(),
          humidity: humidity?.toString(),
          totalPrecipitation: precipitation?.toString(),
          weatherOn: weatherOnDate,  // Save as Date object
          startDegreeCentigrade: startDegreeCentigrade?.toString(),
          endDegreeCentigrade: endDegreeCentigrade?.toString(),
          weatherName: weatherName?.toString(),
          conditionText: conditionText?.toString(),
          weatherIconPath: weatherIconPath?.toString()
        });

      },
      (error) => {
        this.isLoadingWeather = false;
        console.error('Error fetching weather data', error);
      }
    );

  }



  onFilesUploaded(files: CustomUploadFile[]): void {

    this.selectedFilesAttachment = files;
  }







  // aisha tags work



  onTagChange(selectedValues: string[]): void {
    const checkAllIndex = selectedValues.indexOf('select_all');

    if (checkAllIndex !== -1) {
      const allValues = this.tagNodes[0].children.map(child => child['value']);
      this.DailyLogForm.get('createDailyLogTagParameterRequests')?.setValue(allValues);
      this.isSingleTagSelected = false; // Reset the single tag selection flag
    } else {
      const allValues = this.tagNodes[0].children.map(child => child['value']);
      const filteredValues = selectedValues.filter(value => allValues.includes(value));
      this.DailyLogForm.get('createDailyLogTagParameterRequests')?.setValue(filteredValues);
      this.isSingleTagSelected = filteredValues.length === 1; // Set to true if only one tag is selected
    }
    this.DailyLogForm.get('createDailyLogTagParameterRequests')?.markAsTouched();
    this.DailyLogForm.get('createDailyLogTagParameterRequests')?.updateValueAndValidity();
  }



  newDailyLogTagOpen(): void {
    const modalRef = this.modal.create({
      nzContent: ProjectManagementSiteDiariesAddTagComponent,
      nzFooter: null,
      nzTitle: '<h1 nz-typography>Add Schedule Item Tag</h1>',
      nzWidth: '35%',
      nzBodyStyle: {
        'background-color': '#f1f4fa',
        padding: '20px',
      },
      nzStyle: { top: '10px' },
    });

    modalRef.componentInstance.cancelTag.subscribe(() => {
      modalRef.close();
    });

    modalRef.componentInstance.tagSavedSelected.subscribe((newTag: LookupNameSetupResponse) => {

      this.initLoad().then(() => {

        const newTagNode = {
          title: newTag.name,
          key: newTag.id.toString(),
          value: newTag.id.toString(),
          isLeaf: true,
        };


        const existingTagNode = this.tagNodes[0].children.find(
          (child: any) => child.value === newTag.id.toString()
        );

        if (!existingTagNode) {
          if (this.tagNodes.length > 0 && this.tagNodes[0].children) {
            this.tagNodes[0].children.push(newTagNode);
          }
        }

        const currentValues = this.DailyLogForm.get('createDailyLogTagParameterRequests')?.value || [];
        const updatedValues = [...new Set([...currentValues, newTag.id.toString()])];

        this.DailyLogForm.get('createDailyLogTagParameterRequests')?.setValue(updatedValues);
        this.DailyLogForm.get('createDailyLogTagParameterRequests')?.markAsTouched();
        this.DailyLogForm.get('createDailyLogTagParameterRequests')?.updateValueAndValidity();

        modalRef.destroy();
      }).catch((err) => {
        console.error('Error during initLoad:', err);
      });
    });

  }


  newDailyLogTagEdit(): void {
    const selectedTagIds = this.DailyLogForm.get('createDailyLogTagParameterRequests')?.value;
    if (selectedTagIds && Array.isArray(selectedTagIds) && selectedTagIds.length === 1) {
      const selectedTagId = selectedTagIds[0].toString();
      const selectedTag = this.tagResponse.find((res) => res.id.toString() === selectedTagId);
      if (selectedTag) {
        // Create a modal to edit the selected tag
        const modalRef = this.modal.create({
          nzContent: ProjectManagementSiteDiariesAddTagComponent,
          nzFooter: null,
          nzTitle: '<h1 nz-typography>Edit Daily Log Tag</h1>',
          nzWidth: '35%',
          nzBodyStyle: {
            'background-color': '#f1f4fa',
            padding: '20px',
          },
          nzStyle: { top: '10px' },
        });

        // Pass the selected tag to the modal component
        modalRef.componentInstance.selectedTag = selectedTag;

        modalRef.componentInstance.cancelTag.subscribe(() => {
          modalRef.destroy();

        });

        modalRef.componentInstance.tagSavedSelected.subscribe((newTag: LookupNameSetupResponse) => {
          // Step 1: Call initLoad() and wait for it to complete
          this.initLoad().then(() => {
            // Step 2: Create the new tag node based on the saved tag
            const newTagNode = {
              title: newTag.name,
              key: newTag.id.toString(),
              value: newTag.id.toString(),
              isLeaf: true,
            };

            // Step 3: Add the new tag node to the tagNodes array
            if (this.tagNodes.length > 0 && this.tagNodes[0].children) {
              this.tagNodes[0].children.push(newTagNode);
            }

            // Step 4: Update the form control value
            const updatedValues = [newTag.id.toString()];
            this.DailyLogForm.get('createDailyLogTagParameterRequests')?.patchValue(updatedValues);
            this.DailyLogForm.get('createDailyLogTagParameterRequests')?.markAsTouched();
            this.DailyLogForm.get('createDailyLogTagParameterRequests')?.updateValueAndValidity();

            // Step 5: Close the modal only after initLoad is completed
            modalRef.destroy();
          }).catch((err) => {
            console.error('Error during initLoad:', err);
            // Handle the error, if necessary
          });
        });



        // Subscribe to the tagSaved event when a tag is deleted
        modalRef.componentInstance.tagSaved.subscribe(() => {
          this.initLoad().then(() => {
            this.DailyLogForm.patchValue({ createDailyLogTagParameterRequests: null });
            modalRef.destroy();
          }).catch((err) => {
            console.error('Error during initLoad:', err);
          });
        });

      } else {
        console.error('Tag not found! Ensure the selected ID exists in tagResponse.');
      }
    } else {
      console.error('No valid tag selected for editing! Ensure only one tag is selected.');
    }

  }


  isSingleItemSelected(): boolean {
    const value = this.DailyLogForm.get('createDailyLogTagParameterRequests')?.value;
    return value && Array.isArray(value) && value.length === 1; // Adjusted to check if it's an array with one element
  }


  // Save , save and New and Save Close
  isSaving: boolean = false;
  isSaveAndNew: boolean = false;
  isSaveAndClose: boolean = false;
  DisabledNextActivity: boolean = false;
  weatherNotesErrorMessage: string = '';
  dailyLogId: number;
  Messaging_Id: number;
  formNameId: number = 5;
  JobInfoId: number;
  titleErrorMessage: string = '';
  allNotesErrorMessage: string = '';
  hasAttemptedSubmit = false;
  errorMessages: string[] = [];



  setupFormValueChangeHandler() {
    // Track changes for the notes field
    this.DailyLogForm.get('notes')?.valueChanges.subscribe(value => {
      this.setFieldErrorMessage('notes', value, 4000);  // Max 4000 characters, show error after 3000
    });

    this.DailyLogForm.get('weatherNotes')?.valueChanges.subscribe(value => {
      this.setFieldErrorMessage('weatherNotes', value, 512);  // Max 4000 characters, show error after 3000
    });

    // Track changes for the title field
    this.DailyLogForm.get('title')?.valueChanges.subscribe(value => {
      this.setFieldErrorMessage('title', value, 50);  // Max 50 characters for title
    });
  }

  setFieldErrorMessage(fieldName: string, value: string, maxLength: number): void {
    const currentLength = value ? value.length : 0;
    const overCount = currentLength - maxLength;

    // If characters exceed max length, show error message
    const message = overCount > 0 ? `${overCount} character${overCount > 1 ? 's' : ''} over` : '';

    // Set specific error messages for each field
    switch (fieldName) {
      case 'title':
        this.titleErrorMessage = message;
        break;
      case 'notes':
        this.allNotesErrorMessage = message;
        break;
      case 'weatherNotes':
        this.weatherNotesErrorMessage = message;
        break;
    }
  }

  getFormValidationErrors(): string[] {
    const errorMessages: string[] = [];

    Object.keys(this.DailyLogForm.controls).forEach(key => {
      const control = this.DailyLogForm.get(key);
      const controlErrors = control?.errors;
      const fieldLabel = this.getFieldLabel(key);

      if (controlErrors) {
        if (controlErrors['required']) {
          errorMessages.push(`${fieldLabel}: Required`);
        }
        if (controlErrors['maxlength']) {
          const maxLength = controlErrors['maxlength'].requiredLength;
          const actualLength = controlErrors['maxlength'].actualLength;
          const overCount = actualLength - maxLength;
          errorMessages.push(`${fieldLabel}: ${overCount} character${overCount > 1 ? 's' : ''} over the limit`);
        }
      }
    });

    return errorMessages;
  }

  getFieldLabel(fieldName: string): string {
    const fieldLabels: { [key: string]: string } = {
      title: 'Title',
      notes: 'Notes',
      date: 'Date',
      weatherNotes: 'Weather Notes',

    };
    return fieldLabels[fieldName] || fieldName;
  }



  saveButton(actionType: 'save' | 'saveAndClose' | 'saveAndNew' = 'save'): Promise<void> {
    return new Promise((resolve, reject) => {

      if (this.isSaving || this.isSaveAndClose || this.isSaveAndNew) {
        return reject('Already processing');
      }

      this.hasAttemptedSubmit = true;
      this.errorMessages = [];

      if (this.DailyLogForm.invalid) {
        Object.values(this.DailyLogForm.controls).forEach(control => {
          control.markAsDirty();
          control.updateValueAndValidity();
        });

        this.errorMessages = this.getFormValidationErrors();
        this.scrollToTop();
        return reject('Form is invalid');
      }

      this.DisabledNextActivity = true;

      if (actionType === 'saveAndNew') {
        this.isSaveAndNew = true;
      } else if (actionType === 'saveAndClose') {
        this.isSaveAndClose = true;
      } else {
        this.isSaving = true;
      }

      const selectedTags = this.DailyLogForm.get('createDailyLogTagParameterRequests')?.value || [];
      const tagRequests = Array.isArray(selectedTags) ? selectedTags.map(tagId => ({ dailyLogTagSetupId: tagId })) : [];


      const formValue = { ...this.DailyLogForm.value };
      const payload = {
        formNameId: this.relatedFormNameId,
        jobInformationId: this.selectedJobId,
        companyParameterId: 1,
        headerId: this.relatedHeaderId,
        createDailyLogInformationRequest: {
          ...formValue, // Spread the modified form value
          createDailyLogTagParameterRequests: tagRequests,
        }
      };



      this._siteDiariesDailyLogService.relatedPostDailyLog(payload).subscribe({
        next: (response) => {
          this.isSaving = false;
          this.DisabledNextActivity = false;
          this.dailyLogResponse = response.result;
          this.Messaging_Id = response.result?.MessagingId;
          this.JobInfoId = response.result?.JobInformationId;
          this.dailyLogId = response.result?.DailyLogId;
          // this.relatedHeaderId =  this.dailyLogResponse.Id;
          this.DailyLogForm.patchValue({ globalId: response.result.GlobalId });

          if (this.selectedFilesAttachment?.length > 0) {
            saveAttachments(this.selectedFilesAttachment, this._AttachmentService, response.result?.Id, 5)
              .then((attachmentResponseId) => {
                this._BehaviorSubjectService.setLeadActivityTypeResponse(response.result);
                if (attachmentResponseId > 0) {
                  this.selectedFilesAttachment = []
                }
                resolve();
              })
              .catch((error) => {
                reject(error);
              });
          } else {
            this._BehaviorSubjectService.setLeadActivityTypeResponse(response.result);
            resolve();
          }

          if (response) {
            this.createAndModifiedByData(response.result.Id);
          }

          this.cancelForTodo.emit(this.dailyLogId);

          this._toastService.success('Saved Successfully');
          this.resetFlags();

          // Show the map icon after saving if the address is present

          resolve();
        },
        error: (error) => {
          this.isSaving = false;
          this.DisabledNextActivity = false;
          console.error('Error saving daily log:', error);
          reject('Error saving daily log');
        }
      });
    });
  }





  saveAndNewDailyLog() {
    this.saveButton('saveAndNew')
      .then(() => {
        this.resetForm();
        this.dailyLogResponse = null;
        this.resetAttachments();
        this.DailyLogSetupChages();


        // Call resetAttachments method to handle any additional reset logic
      })
      .catch((error) => {
        console.error('Error during save and new:', error);
      });
  }

  // Method to handle the attachment reset event from the child component
  resetAttachments() {
    this.TestAttachment = null;
    this.selectedFilesAttachment = [];
  }

  saveAndCloseDailyLog() {
    return this.saveButton('saveAndClose')
      .then(() => {
        this.resetForm();
        this.onCancel();
      })
      .catch((error) => {
        console.error('Error during save and close:', error);
      });
  }


  createDailyLogAndOpenTodo() {
    this.saveButton('save')
      .then(() => {
        if (this.relatedHeaderId !== 0) {
          this.openDrawer(null);
        }
      })
      .catch((error) => {
        console.error('Error during save and new:', error);
      });
  }




  isDrawerVisible = false;
  selectedToDoId: number;
  isSaveCompleteEmitted = false;

  openDrawer(selectedToDoId: number): void {
    this.relatedHeaderId = this.dailyLogResponse.Id;
    if (this.relatedHeaderId !== 0) {
      this.isDrawerVisible = true;
      this.selectedJobId = this._accessLocalStorageService.getJobId();
      this.selectedJobName = this._accessLocalStorageService.getJobName();
      this.selectedJobName = this._accessLocalStorageService.getJobName();
      this.selectedToDoId = selectedToDoId; // Set the selectedToDoId
      this.selectedDailyLogNotes = this.dailyLogResponse.Notes;
      this.isSaveCompleteEmitted = false;
    }
  }


  handleSaveComplete(toDoId: number | null | undefined): void {
    this.isSaveCompleteEmitted = true; // Set flag to true when saveComplete is emitted
    if (toDoId) {
      this.getAllRelatedToDos(toDoId);
    } else {
      this.getAllRelatedToDos(this.dailyLogResponse.ToDoId);
       this.relatedHeaderId = null;

    }
  }


  closeDrawer(): void {
    this.isDrawerVisible = false;
       // If saveComplete was not emitted, reset relatedHeaderId to null
       if (!this.isSaveCompleteEmitted) {
        this.relatedHeaderId = null;
      }
    this.getAllRelatedToDos(this.dailyLogResponse.ToDoId);
  }





  resetFlags() {
    this.isSaving = false;
    this.isSaveAndClose = false;
    this.isSaveAndNew = false;
  }


  resetForm(): void {
    const selectedJobId = this.DailyLogForm.get('jobInformationId')?.value;
    this.DailyLogForm.reset({
      id: 0,
      globalId: '00000000-0000-0000-0000-000000000000',
      jobSetupId: 0,
      jobInformationId: selectedJobId,
      date: new Date(),
      title: '',
      notes: '',
      isIncludeWeatherConditions: false,
      isIncludeWeatherNotes: false,
      weatherNotes: '',
      isPermissionInternalUser: false,
      isPermissionSubVendor: false,
      isPermissionOwner: false,
      isPermissionPrivate: false,
      createDailyLogTagParameterRequests: null,
      createDailyLogNotifyUserRequest: [] // Resetting to an empty array
    });
    this.errorMessages = [];
    this.dailyLogResponse = null;
    this.onDailyLogChange();
    this.setupFormValueChangeHandler();
    this.refreshErrorStates();
    this.relatedToDoList = null;
  }

  onDailyLogChange(): void {
    this.attachmentsComponent.clearAttachment();
  }

  refreshErrorStates(): void {
    this.titleErrorMessage = '';
    this.allNotesErrorMessage = '';
    this.hasAttemptedSubmit = false;

    this.errorMessages = [];

    Object.values(this.DailyLogForm.controls).forEach(control => {
      control.markAsPristine(); // Set control to pristine state
      control.markAsUntouched(); // Set control to untouched state
      control.updateValueAndValidity(); // Trigger re-validation
    });
  }

  // Method to scroll to the top of the form
  scrollToTop() {
    const formElement = document.getElementById('dailyLogForm'); // Replace with your form's ID
    if (formElement) {
      formElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }




  /* Delete Daily Log */

  deleteDailyLog() { 
    const globalId = this.DailyLogForm.get('globalId').value;
    this.isDeleteLoading = true; // Start loading

    this._siteDiariesDailyLogService.deleteDailyLog(globalId).subscribe(
      res => {
        this._toastService.success('Deleted Successfully');
        
        this.isDeleteLoading = false; 
        this.cancelForTodo.emit(this.dailyLogId);
        
      },
      err => {
        this._toastService.error('Deletion failed'); 
        this.isDeleteLoading = false;
      }
    );
  }

  showConfirm(): void {
    this.confirmModal = this.modal.confirm({
      nzTitle: 'Delete Daily Log?',
      nzContent: 'Are you sure you want to permanently delete this Daily Log?',
      nzOkDanger: true,
      nzOkText: 'Delete',
      nzOkLoading: this.isDeleteLoading,
      nzCentered: true,
      nzAutofocus: 'cancel',
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          this.isDeleteLoading = true; 
          this.deleteDailyLog(); 

          setTimeout(() => {
            this.isDeleteLoading = false; 
            this.onCancel();
            resolve(); 
          }, 1000); 
        }),
      nzOnCancel: () => console.log('Cancel'),
    });
  }


  onLeadSelect(selectedLeadId: any): void {
    const selectedLead = this.jobList.find(lead => lead.id === selectedLeadId);
    // this.JobData = { JobData: selectedLead };
  }

  onCancel(): void {
    this.cancel.emit();
  }


}



