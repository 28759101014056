<nz-modal
  nzWidth="54%"
  [(nzVisible)]="selectionModelhandle"
  [nzTitle]="istoptionlTitle"
  [nzFooter]="istoptionFooter"
  [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '540px', 'overflow-y': 'auto' }"
  [nzStyle]="{ top: '25px' }"
  (nzOnCancel)="importSelectionCancelEvent()">
    <ng-template #istoptionlTitle>
      <div class="row">
        <h1 class="p-0 m-0 react">Import Data From Template</h1>
      </div>
    </ng-template>

    <div class="content" *nzModalContent>

      <div class="top-of-modal mb-2"></div>
      <!-- *ngIf="isSubmitted && errorFields.length > 0" -->
      <nz-alert
        class="mb-3 error-message"
        nzType="error"
        nzShowIcon
        [nzMessage]="message"
        [nzDescription]="errorDescriptionTemplate"
      ></nz-alert>
      <ng-template #message>
        <p class="mt-1">Please correct the following fields:</p>
      </ng-template>
      <ng-template #errorDescriptionTemplate>
        <ul>
          <li class="sub-text" *ngFor="let error of errorFields">
            <span class="d-flex align-items-center">
              <p>{{ error.label }}:</p>
              <p>{{ error.message }}</p>
            </span>
          </li>
        </ul>
      </ng-template>


      <nz-card [nzSize]="'small'" nzBorderless="true"  [nzTitle]="ImportOptions" >
        <ng-template #ImportOptions>
          <h5 class="p-0 Card-Title-Set">Import Options</h5>
        </ng-template>

        <div class="row">
          <div class="col-12 font">
            <h3 class="">Source</h3>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-6">
            <label class="para-lable">Source Template</label>
            <div class="input-group">
              <nz-select class="rounded-1" nzShowSearch [lang]="true" nzAutoFocus="true"
                nzPlaceHolder="   -- Choose a Template --   " [(ngModel)]="selectedValue">
                <nz-option *ngFor="let option of sourceTemplateOptions" [nzLabel]="option.name" [nzValue]="option.id"></nz-option>
              </nz-select>
          </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <h3 class="fw-bold" style="font-size: 18px;">Items to Copy</h3>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12">
            <h6 class="para-lable">Project Management</h6>
          </div>
        </div>
        <div  class="row mt-2">
          <div   class="col-12">

            <label nz-checkbox [(ngModel)]="checked" (ngModelChange)="dateShow()">Schedule</label>
            <div *ngIf="importDataShowDate" class="row ms-4 mt-3 mb-2">
              <div class="col-3">
                <label class="para-lable">New Start Date</label>
                <nz-date-picker class="rounded-1" [(ngModel)]="date" (ngModelChange)="onChange($event)"></nz-date-picker>
              </div>
            </div>

          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
             <label nz-checkbox >To-Do's</label>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <label nz-checkbox [(ngModel)]="importShowSelectFeild">Selections</label>

            <!-- Conditionally show fields based on importShowSelectFeild -->
            <div *ngIf="importShowSelectFeild" class="row ms-3 mt-2">
              <div class="col-3">
                <label class="para-lable">New DeadLine</label>
                <nz-date-picker class="rounded-1" [(ngModel)]="date" (ngModelChange)="onChange($event)"></nz-date-picker>
              </div>
            </div>

            <div *ngIf="importShowSelectFeild" class="row ms-3 mt-3">
              <div class="col-12">
                <label nz-checkbox>Reset approved choices to pending</label>
              </div>
            </div>

            <div *ngIf="importShowSelectFeild" class="row ms-5 mt-3">
              <div class="col-12">
                <label nz-checkbox>Apply any pending line item updates</label>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-12">
             <label nz-checkbox >Specifications</label>
          </div>
        </div>
        <hr>
        <div class="row mt-4 mb-2">
          <div class="col-12">
            <h6 class="para-lable">Files</h6>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-12">
            <label [(ngModel)]="importShowDocumentFeild" (click)="showDocumentFeild()" nz-checkbox >Document Folders</label>
            <div *ngIf="importShowDocumentFeild" class="row ms-3 mt-3">
              <div class="col-12">
               <label  nz-checkbox >Include Files</label>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <label nz-checkbox >Photo Folders</label>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <label [(ngModel)]="importShowVideoFeild" (click)="showVideoFeild()" nz-checkbox >Video Folders</label>
            <div  *ngIf="importShowVideoFeild" class="row ms-3 mt-3">
              <div class="col-12">
               <label nz-checkbox >Include Files</label>
              </div>
            </div>
          </div>
        </div>
        <hr>
        <div class="row mt-4 mb-1">
          <div class="col-12">
            <h6 class="para-lable">Messaging</h6>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <label nz-checkbox [(ngModel)]="importShowSurveysFeild" (click)="showserveyFeild()" >Surveys</label>
            <div *ngIf="importShowSurveysFeild" class="row ms-3 mt-2">
              <div class="col-3">
                <label class="para-lable fs-10">New Release Date</label>
                <nz-date-picker class="rounded-1" [(ngModel)]="date" (ngModelChange)="onChange($event)"></nz-date-picker>
              </div>
            </div>
          </div>
        </div>
        <hr>
        <div class="row mt-4 mb-2">
          <div class="col-12">
            <h6 class="para-lable">Financial</h6>

          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <label nz-checkbox >Onwer Invoices</label>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <label [(ngModel)]="importShowBidownerFeild"  (click)="showBidOwnerFeild()" nz-checkbox >Bid Packages</label>
               <div *ngIf="importShowBidownerFeild" class="row ms-3 mt-2">
              <div class="col-3">
                <label class="para-lable"> Date</label>
                <nz-date-picker class="rounded-1" [(ngModel)]="date" (ngModelChange)="onChange($event)"></nz-date-picker>
              </div>
              <div class="col-3">
                <label class="para-lable"> Time</label>
                <nz-time-picker
                [(ngModel)]="date"
                name="date"
                nzFormat="HH:mm"
                nzPlaceHolder=" "
              ></nz-time-picker>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <label [(ngModel)]="importShowEstimateFeild" (click)="showEstimateFeild()" nz-checkbox >Estimates</label>
            <div *ngIf="importShowEstimateFeild" class="row mt-2 ms-3">
              <div class="col-12">
                <div><label nz-checkbox >Keep Introductory and Closing Tags in Jobs proposal Details.
                   Text from this template will  be added to the existing text.</label></div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <label [(ngModel)]="importShowPurchaseFeild" (click)="showPurchaseFeild()" nz-checkbox >Purchase Orders</label>
            <div *ngIf="importShowPurchaseFeild" class="row ms-3 mt-3">
              <div class="col-12">
               <label nz-checkbox   [(ngModel)]="checked" >Link Bills</label>
              </div>
            </div>
            <div *ngIf="importShowPurchaseFeild" class="row ms-3 mt-2">
              <div class="col-3">
                <label >Estimated Completion for Purchase Orders</label>
                <nz-date-picker class="rounded-1" [(ngModel)]="date" (ngModelChange)="onChange($event)"></nz-date-picker>
              </div>
            </div>
            <div *ngIf="importShowPurchaseFeild" class="row ms-3 mt-4">
              <div class="col-12">
               <label nz-checkbox >Include PO attachments</label>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <label nz-checkbox >Bills</label>
          </div>
        </div>
      </nz-card>

    </div>

    <ng-template #istoptionFooter>
      <div class="mt-2 align-items-center">
        <button nz-button nzType="primary" class="rounded-1 me-0"
          >Import</button>
      </div>
    </ng-template>

</nz-modal>
