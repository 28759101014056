import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { NzModalService } from 'ng-zorro-antd/modal';
import { LookupNameSetupResponse } from 'src/Models/LeadTags';
import { JobInformationService } from 'src/Service/Job-List/Job-Information/job-information.service';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { CostCodeService } from 'src/Service/Internaluser/cost-code.service';
import { CostCode } from 'src/Models/CostCode/costCode';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';
import { AddEditJobGroupComponent } from 'src/app/shared/component/Modal/add-edit-job-group/add-edit-job-group.component';
import { JobInformationResponse, JobShortInformation } from 'src/Models/Job-Scratch/JobFromScratch';
import { TimeClockSService } from 'src/Service/_Time_Clock/time-clock-s.service';
import { InternalUserService } from 'src/Service/Internaluser/internal-user.service';
import { BreakInformation, ITimeClockSetup, ITimeClockShiftGetById } from 'src/Models/_Time_Clock/_Time_Clock';
import { NzStatus } from 'ng-zorro-antd/core/types';
import { CosCodeModelComponent } from 'src/app/company-settings/cos-code-model/cos-code-model.component';
import { CostCodeResponse } from 'src/Models/InternalUser/applicationUserResponse';


@Component({
  selector: 'app-time-clock-shift',
  templateUrl: './time-clock-shift.component.html',
  styleUrls: ['./time-clock-shift.component.css'],
})
export class TimeClockShiftComponent implements OnInit {
  @Output() cancel = new EventEmitter<void>();
  @Output() ClockInUserOnCompleted = new EventEmitter<ITimeClockShiftGetById>();
  initializationComplete = new EventEmitter<boolean>();
  BreakInformationRes: BreakInformation[] = [];
  isVisibleInClockIn: boolean = true;
  @Input() onJob_Id: number;
  @Input() onClockIn_Id: number;
  jobList: JobShortInformation[] = [];
  CostCodeList: CostCode[] = [];
  PAGE_ID: number = 11;
  UserNodes: NzTreeNodeOptions[] = [];
  TagNodes: NzTreeNodeOptions[] = [];
  onSaveTimeClockTag: any;
  timeClockFormFields: any = {};
  BreakFormFields: any = {};
  tabs = [1, 2, 3, 4];
  shiftTypeOp: LookupNameSetupResponse[] = []
  showDropdown = false;
  endTimeError: boolean = false;
  ITimeClockSetupResponse: ITimeClockSetup;
  OnAdBreak: boolean = false
  isSave: boolean = false;
  isSaveAndClose: boolean = false;
  isSaveAndApprove: boolean = false;
  isDeleteLoading: boolean = false;
  isShiftApproveLoading: boolean = false;
  isResetLoading: boolean = false;
  DisabledNextActivity: boolean = false;
  isSubmitted: boolean = false;
  validationStates: { [key: string]: NzStatus } = {};
  validationMessages: { [key: string]: string } = {};
  touchedFields: { [key: string]: boolean } = {};
  selectedIdsAsNumbers: number[] = [];
  items: any[] = [];
  ITimeClockShiftGetByIdRes: ITimeClockShiftGetById;
  BreakStartTime: boolean = false;
  BreakEndTime : boolean = false;
  timeDifference: { hours: number; minutes: number } ;
  _onCostCodeIdChange: CostCode;
  totalHours: number = 0;
  totalMinutes: number = 0;
  fractionalHours: string = '0';
  dateDifference: number = 0;
  onShiftType: boolean = false;
  ITimeClock_ClockInRes: ITimeClockShiftGetById;
  onBuilderCost: string = '$0.00';
  _OnITimeClockShiftGetById: ITimeClockShiftGetById;

  timeSlots = [
    '12:00 am', '12:30 am', '1:00 am', '1:30 am', '2:00 am', '2:30 am',
    '3:00 am', '3:30 am', '4:00 am', '4:30 am', '5:00 am', '5:30 am',
    '6:00 am', '6:30 am', '7:00 am', '7:30 am', '8:00 am', '8:30 am',
    '9:00 am', '9:30 am', '10:00 am', '10:30 am', '11:00 am', '11:30 am',
    '12:00 pm', '12:30 pm', '1:00 pm', '1:30 pm', '2:00 pm', '2:30 pm',
    '3:00 pm', '3:30 pm', '4:00 pm', '4:30 pm', '5:00 pm', '5:30 pm',
    '6:00 pm', '6:30 pm', '7:00 pm', '7:30 pm', '8:00 pm', '8:30 pm',
    '9:00 pm', '9:30 pm', '10:00 pm', '10:30 pm', '11:00 pm', '11:30 pm'
  ];

  preDefinedDataCodes = {
    tags: { code: 'PMTCT' },
    ShiftType: { code: 'PMTCST' },
  };
 
  constructor(
    private _JobInformationService: JobInformationService,
    public _AccessLocalStorageService: AccessLocalStorageService,
    public _CostCodeService: CostCodeService,
    public _LookupFilterServices: LookupFilterService,
    public modal: NzModalService,
    public fb: FormBuilder,
    public _TimeClockSService: TimeClockSService,
    private _NzMessageService: NzMessageService,
    private _InternalUserService: InternalUserService,
  ) { }

  ngOnInit(): void {
    this.TimeClockFormGroup();
    this.GetAllTags();
    this.GetAll();
    this.GetAllOn_CostCode();
    this.initLoad();
    this.fetchTimeClockSetupForm();
    if(this.onClockIn_Id > 0){
      this.ClockInGetById(this.onClockIn_Id);
    }
  }
  TimeClockFormGroup(): void {
    this.timeClockFormFields = {
      timeClockSetupId: 0,
      jobSetupId: 0,
      jobInformationId: 0,
      startOn: null,
      endOn: null,
      startTime: null,
      endTime: null,
      startDateModifiedOn: null,
      startDateModifiedBy: 0,
      endDateModifiedOn: null,
      endDateModifiedBy: 0,
      timeClockTagSetupId: 0,
      notes: '',
      shiftTypeSetupId: 0,
      shiftHours: 0,
      shiftMinutes: 0,
      overTimeHours: 0,
      overTimeMinutes: 0,
      doubleOvertimeHours: 0,
      doubleOvertimeMinutes: 0,
      costCodeId: 0,
      rate: '',
      hours: 0,
      statusSetupId: 0,
      builderCost: 0,
      isClockIn: false,
      clockOn: null,    
      applicationUserIds: 0,
      createShiftTagParameterRequests: [],
      createShiftInformationBreakParameterRequests: this.items
    }
  }
  GetAll(){
    this._JobInformationService.onGetJobShortInformation().subscribe((res) => {
      this.jobList = res.result;
      if(this.onJob_Id === null){
        this.timeClockFormFields.jobInformationId =  0
      } else {
        this.timeClockFormFields.jobInformationId =  this.onJob_Id
      }
    });
  }
  GetAllOn_CostCode(){
    this._CostCodeService.getCategoryData().subscribe(res => {
      this.CostCodeList = res.result?.filter(item => item?.timeClockLaborCode === true);
      this.timeClockFormFields.costCodeId = this.CostCodeList[0]?.id;
    });
  }
  GetAllTags(){
    this._LookupFilterServices.getLookUpValuesByFormNameId(this.PAGE_ID).subscribe((res) => {
      let tags = res.result?.filter(
        (x) => x.code === this.preDefinedDataCodes.tags.code
      );

      this.TagNodes = [
        {
          title: 'Check All',
          value: 'check_all',
          key: 'check_all',
          selectable: false,
          isLeaf: false,
          expanded: true,
          children: tags?.map((tags) => ({
            title: tags?.name,
            key: tags?.id.toString(),
            value: tags?.id.toString(),
            isLeaf: true,
          }))
        }
      ];
    });
  }
  initLoad() {
    this._InternalUserService.getData().subscribe((res) => {
      const userId = this._AccessLocalStorageService.getUserLoginId();
      this.UserNodes = [
        {
          title: 'Check All',
          value: 'check_all',
          key: 'check_all',
          selectable: false,
          isLeaf: false,
          expanded: true,
          children: res?.result?.map((tags) => ({
            title: tags?.fullName,
            key: tags?.id.toString(),
            value: tags?.id.toString(),
            isLeaf: true,
          }))
        }
      ];
      this.timeClockFormFields.applicationUserIds = userId.toString();
    });
    this._LookupFilterServices.getLookUpValuesByFormNameId(this.PAGE_ID).subscribe((res) => {
      let tags = res.result?.filter(
        (x) => x.code === this.preDefinedDataCodes.tags.code
      );

      let shiftType = res.result?.filter(
        (x) => x.code === this.preDefinedDataCodes.ShiftType.code
      );

      this.shiftTypeOp = shiftType;
      this.timeClockFormFields.shiftTypeSetupId = this.shiftTypeOp[0]?.id;
    });
  }
  TimeClockTagOpen(selectedValue: any, argument: string) {
    this.onSaveTimeClockTag = {
      formNameId: 11,
      lookUpStandardId: 94,
      code: 'PMTCT',
      openArgument: argument,
    }
    const selectedId: number = Number(selectedValue); 
    const modalRef = this.modal.create({
      nzContent: AddEditJobGroupComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.onSaveTag = this.onSaveTimeClockTag;
    modalRef.componentInstance.selectedValue = selectedId;
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    modalRef.componentInstance.TagGroupOnCompleted.subscribe((data: LookupNameSetupResponse | boolean) => {
      this.GetAllTags();
      if (typeof data === 'boolean') {
          if (data === true) {
              this.timeClockFormFields.createShiftTagParameterRequests = null;
          }
      } else {
        if (data.id > 0) {
          this.timeClockFormFields.createShiftTagParameterRequests = data.id.toString();
        } else {
          this.timeClockFormFields.createShiftTagParameterRequests = null;
        }
      }
  });
  }
  isEditButtonDisabled(): boolean {
    const selectedValues = this.timeClockFormFields.createShiftTagParameterRequests;
    return !selectedValues || (Array.isArray(selectedValues) && selectedValues.length !== 1);
  }
  CancelClockIn() {
    this.isVisibleInClockIn = false;
     this.cancel.emit();
  }
  innerCardTabs(tab: number): string {
    switch (tab) {
      case 1:
        return 'General';
      case 2:
        return 'Detailed Hours';
      case 3:
        return 'Breaks';
      case 4:
        return 'Cost';
      default:
        return '';
    }
  }
  onUserChanges(value: number[]) {
    this.selectedIdsAsNumbers = value.map(id => Number(id));
  }
  get breakParameters(): FormArray {
    return this.timeClockFormFields.get('shiftInformationBreakParameters') as FormArray;
  }
  onStartTimeChange(selectedTime: string): void {
    if (selectedTime) {
      this.timeClockFormFields.startOn = new Date();
      this.onFieldChange('startOn',this.timeClockFormFields.startOn);
      this.onFieldChange('startTime',selectedTime);
    }
    if(this.endTimeError === true){
      this.onEndTimeChange(this.timeClockFormFields.endTime);
    } else {
      this.checkTime();
    }
  }
  formatTime(date: Date): string {
    const options: Intl.DateTimeFormatOptions = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };

    return new Intl.DateTimeFormat('en-US', options).format(date);
  }
  formatOn(date: Date): string {
    const monthNames = [
      "January", "February", "March", "April", "May", "June", 
      "July", "August", "September", "October", "November", "December"
    ];
  
    const monthNumber = date.getMonth() + 1;
    const dateNumber = date.getDate();
    const monthName = monthNames[date.getMonth()];
  
    return `${monthName} ${dateNumber}, Month Number: ${monthNumber}`;
  }
  onEndTimeChange(selectedTime: string): void {
    if (selectedTime) {
      this.timeClockFormFields.endOn = new Date();
      this.OnAdBreak = true;
      const startTime = new Date(selectedTime);
      const endTime = new Date(this.timeClockFormFields.startTime);
      const formattedStartTime = this.formatTime(startTime);
      const formattedEndTime = this.formatTime(endTime);
      if(formattedStartTime < formattedEndTime){
        this.endTimeError = true;
      } else {
        this.endTimeError = false;
        this.checkTime();
      }
    } else {
      this.OnAdBreak = false;
    }
  }
  checkTime() {
    if(this.timeClockFormFields.costCodeId){
      this.onCostCodeIdChange(this.timeClockFormFields.costCodeId);
    }
    const startTime = new Date(this.timeClockFormFields.startTime);
    const endTime = new Date(this.timeClockFormFields.endTime);
    const startOn = new Date(this.timeClockFormFields.startOn);
    const endOn = new Date(this.timeClockFormFields.endOn);
  
    const formattedStartTime = this.formatTime(startTime);
    const formattedEndTime = this.formatTime(endTime);
    const formattedStartOn = this.formatOn(startOn);
    const formattedEndOn = this.formatOn(endOn);
    if (formattedStartTime === formattedEndTime && formattedStartOn === formattedEndOn) {
      this.endTimeError = true;
      return
    } else {
      this.calculateTimeDifference(startOn,startTime,endOn,endTime);
      this.endTimeError = false;
    }
  }
  onEndDateChange(EndDate: any): void {
    if (this.timeClockFormFields.startOn === EndDate) {
        this.endTimeError = true;
    } else {
      this.endTimeError = false;
    }
    if (EndDate === null) {
      this.timeClockFormFields.get('endTime')?.setValue(null);
    }
    this.totalHours = 0;
    this.totalMinutes = 0;
    this.fractionalHours = '0';
    this.dateDifference = 0;

    this.checkTime();
  }
  calculateTimeDifference(startOn: Date,startTime: Date,endOn: Date,endTime: Date): void { 
    let calculatedOvertimeHours = 0; 
    let calculatedDoubleOvertimeHours = 0; 

    this.timeClockFormFields.overTimeHours = 0; 
    this.timeClockFormFields.overTimeMinutes = 0;
    this.timeClockFormFields.doubleOvertimeHours = 0;
    this.timeClockFormFields.doubleOvertimeMinutes = 0;

    let on_startOn = new Date(startOn); 
    let on_startTime = new Date(startTime); 
    let on_endOn = new Date(endOn); 
    let on_endTime = new Date(endTime); 

    new Date()
    const startDateTime = new Date(
      on_startOn.getFullYear(),
      on_startOn.getMonth(),
      on_startOn.getDate(),
      on_startTime.getHours(),
      on_startTime.getMinutes()
    );

    const endDateTime = new Date(
      on_endOn.getFullYear(),
      on_endOn.getMonth(),
      on_endOn.getDate(),
      on_endTime.getHours(),
      on_endTime.getMinutes()
    );

    const differenceInMilliseconds = endDateTime.getTime() - startDateTime.getTime();
    const differenceInMinutes = differenceInMilliseconds / (1000 * 60);

    let result = 0;

    if (differenceInMinutes > 0) {
      result = parseFloat((differenceInMinutes / 60).toFixed(2));
    }
    this.timeDifference = {
      hours: result,
      minutes: differenceInMinutes % 60
    };

    if (endDateTime <= startDateTime) {
      this.endTimeError = true;
      return;
    } else {
      this.endTimeError = false;
    }

    const onSetupRes = this.ITimeClockSetupResponse;
    this.totalHours = Math.floor(differenceInMinutes / 60);
    this.totalMinutes = differenceInMinutes % 60;
    
    if (onSetupRes?.isShowOvertime === false && onSetupRes?.isShowDoubleOvertime === false || onSetupRes?.isShowOvertime === true && onSetupRes?.isShowDoubleOvertime === false && onSetupRes?.isEmployeesAccrueDailyOvertime === false) {
        this.timeClockFormFields.shiftHours = this.totalHours;
        this.timeClockFormFields.shiftMinutes = this.totalMinutes;
        return;
    } else if(onSetupRes?.isShowOvertime === true && onSetupRes?.isEmployeesAccrueDailyOvertime === true && onSetupRes?.afterNoOfHoursOverTimeDaily > 0 && onSetupRes?.isShowDoubleOvertime === false){
      if(this.totalHours > onSetupRes?.afterNoOfHoursOverTimeDaily){
        const On_Case_One = this.totalHours - onSetupRes?.afterNoOfHoursOverTimeDaily;
        this.timeClockFormFields.shiftHours = onSetupRes?.afterNoOfHoursOverTimeDaily;
        this.timeClockFormFields.overTimeHours = On_Case_One;
        this.timeClockFormFields.overTimeMinutes = this.totalMinutes;
        return;
      } else if(this.totalHours === onSetupRes?.afterNoOfHoursOverTimeDaily){
        this.timeClockFormFields.shiftHours = this?.totalHours;
        this.timeClockFormFields.shiftMinutes = this?.totalMinutes;
        return;
      } else if(this.totalHours < onSetupRes?.afterNoOfHoursOverTimeDaily){
        this.timeClockFormFields.shiftHours = this?.totalHours;
        this.timeClockFormFields.shiftMinutes = this?.totalMinutes;
      }
    }

    const regularHoursThreshold = onSetupRes?.afterNoOfHoursOverTimeDaily;
    const doubleOvertimeThreshold = onSetupRes?.afterNoOfHoursDoubleOverTimeDaily;

    let hoursWorked = differenceInMinutes / 60;
    if (onSetupRes?.isShowOvertime || onSetupRes?.isEmployeesAccrueDailyOvertime || onSetupRes?.afterNoOfHoursOverTimeDaily > 0) {
        if (hoursWorked > regularHoursThreshold) {
            this.timeClockFormFields.shiftHours = Math.floor(regularHoursThreshold);
            this.timeClockFormFields.shiftMinutes = Math.round((regularHoursThreshold % 1) * 60);

            calculatedOvertimeHours = hoursWorked - regularHoursThreshold;

            if (onSetupRes?.isShowDoubleOvertime || onSetupRes?.isEmployeesAccrueDailyDoubleOvertime || onSetupRes?.afterNoOfHoursDoubleOverTimeDaily > 0) {
                if (calculatedOvertimeHours > doubleOvertimeThreshold - regularHoursThreshold) {
                    this.overTimeHours(doubleOvertimeThreshold - regularHoursThreshold);

                    calculatedDoubleOvertimeHours = calculatedOvertimeHours - (doubleOvertimeThreshold - regularHoursThreshold);
                    calculatedOvertimeHours = doubleOvertimeThreshold - regularHoursThreshold; 
                } else {
                    this.overTimeHours(calculatedOvertimeHours);
                    calculatedDoubleOvertimeHours = 0; 
                }
            } else {
                calculatedDoubleOvertimeHours = 0;
            }
        } else {
            this.timeClockFormFields.shiftHours = Math.floor(hoursWorked);
            this.timeClockFormFields.shiftMinutes = Math.round((hoursWorked % 1) * 60);
            calculatedOvertimeHours = 0;
            calculatedDoubleOvertimeHours = 0;
        }

        this.timeClockFormFields.doubleOvertimeHours = Math.floor(calculatedDoubleOvertimeHours);
        this.timeClockFormFields.doubleOvertimeMinutes = Math.round((calculatedDoubleOvertimeHours % 1) * 60);
    } else {
        this.timeClockFormFields.shiftHours = 0;
        this.timeClockFormFields.shiftMinutes = 0;
        this.timeClockFormFields.overTimeHours = 0;
        this.timeClockFormFields.overTimeMinutes = 0;
        this.timeClockFormFields.doubleOvertimeHours = 0;
        this.timeClockFormFields.doubleOvertimeMinutes = 0;
    }
  }
  overTimeHours(hours: number): number {
    this.timeClockFormFields.overTimeHours = Math.floor(hours);
    this.timeClockFormFields.overTimeMinutes = Math.round((hours % 1) * 60); 
    return hours; 
  }
  doubleOverTimeHours(hours: number): void {
    this.timeClockFormFields.doubleOverTimeHours = Math.floor(hours);
    this.timeClockFormFields.doubleOverTimeMinutes = Math.round((hours % 1) * 60); 
  }
  costCodeOpen(_Id: number): void {
    const modalRef = this.modal.create({
      nzContent: CosCodeModelComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.onCostCode_Id = _Id;
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    modalRef.componentInstance.costCodeonSaveComplete.subscribe(async (data: CostCodeResponse | boolean) => {
      this.GetAllOn_CostCode();
      if (typeof data === 'boolean') {
          if (data === true) {
              this.timeClockFormFields.costCodeId = null;
          }
      } else {
          if (data?.id === 0) {
              this.timeClockFormFields.costCodeId = null;
          } else {
              this.timeClockFormFields.costCodeId = data?.id
          }
      }
    });
  }
  isEditButtonDisabledFormCostCode(): boolean {
    const selectedValues = this.timeClockFormFields.costCodeId;
    return !selectedValues || (Array.isArray(selectedValues) && selectedValues.length !== 1);
  }
  resetShiftValues(): void {
    this.timeClockFormFields.patchValue({
      shiftHours: 0,
      shiftMinutes: 0,
      fractionalHours: '0'
    });
  }
  private parseTime(time: string): [number, number, string] {
    const [timePart, ampm] = time.split(' ');
    let [hour, minute] = timePart.split(':').map(Number);
    if (ampm === 'am' && hour === 12) {
      hour = 0;
    } else if (ampm === 'pm' && hour !== 12) {
      hour += 12;
    }
    return [hour, minute || 0, ampm];
  }
  // checkTimeValidity(): boolean {
  //   const startTime = this.timeClockFormFields.startTime;
  //   const endTime = this.timeClockFormFields.endTime;

  //   const startIndex = this.timeSlots.indexOf(startTime);
  //   const endIndex = this.timeSlots.indexOf(endTime);

  //   if (startIndex > endIndex) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }
  onshiftTypeChange(_id: number){
    if(_id === 10045){
      this.onShiftType = true;
    } else {
      this.onShiftType = false;
    }
  }
  fetchTimeClockSetupForm(): void {
    this._TimeClockSService.getCompanyTimeClockSetup().subscribe(res => {
      this.ITimeClockSetupResponse = res?.result
      this.initializationComplete.emit(false);
    });
  }
  saveShift(actionType: 'save' | 'saveAndClose' |'saveAndApprove' | 'saveAndResetApproval' = 'save'): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.isSave) {
        return reject('Already processing');
      }
      
      if(this.BreakStartTime === true || this.endTimeError){
        this.resetFlags();
        this.DisabledNextActivity = false;
        return reject('Validation errors present');
      }

      if(actionType === 'saveAndApprove'){
        this.timeClockFormFields.statusSetupId = 671;
      } else if(this.ITimeClockShiftGetByIdRes?.StatusSetupId === 671 && actionType === 'save'){
        this.timeClockFormFields.statusSetupId = 671;
      } else if(this.ITimeClockShiftGetByIdRes?.StatusSetupId === 671 && actionType === 'saveAndClose'){
        this.timeClockFormFields.statusSetupId = 671;
      } else {
        this.timeClockFormFields.statusSetupId = 672;
      }
      switch (actionType) {
        case 'saveAndClose':
          this.isSaveAndClose = true;
          break;
        case 'saveAndApprove':
          this.isSaveAndApprove = true;
          break;
        case 'saveAndResetApproval':
          this.isSaveAndApprove = true;
          break;
        case 'save':
          this.isSave = true;
          break;
        default:
          this.isSave = true;
      }
  
      this.DisabledNextActivity = true;
      this.isSubmitted = true;
      this.checkErrors();
  
      if (Object.values(this.validationStates).includes('error')) {
        setTimeout(() => {
          const errorElements = document.querySelectorAll('.error-message');
          if (errorElements.length > 0) {
            const lastErrorElement = errorElements[errorElements.length - 1];
            lastErrorElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        }, 100);
      
        this.resetFlags();
        this.DisabledNextActivity = false;
        return reject('Validation errors present');
      }
      if(this.selectedIdsAsNumbers?.length > 0){
        this.timeClockFormFields.applicationUserIds = this.selectedIdsAsNumbers
      } else {
        this.timeClockFormFields.applicationUserIds = [this.timeClockFormFields.applicationUserIds]
      } 
      
      if(this.timeClockFormFields.endTime || this.timeClockFormFields.endOn){
        this.timeClockFormFields.isClockIn = false;
      } else {
        this.timeClockFormFields.isClockIn = true;
      }
      let selectedAssignees = Array.isArray(this.timeClockFormFields.createShiftTagParameterRequests)
      ? this.timeClockFormFields.createShiftTagParameterRequests.map((id: string) => ({ shiftTagSetupId: id }))
      : [];

      this.timeClockFormFields.builderCost = this.onBuilderCost;
      let formDataToSend = {
        ...this.timeClockFormFields,
        createShiftTagParameterRequests: selectedAssignees,
      };
      this._TimeClockSService.PostTimeClockIn(formDataToSend).subscribe(
        (response) => {
          this.ITimeClock_ClockInRes = response.result;
          this.ClockInGetById(response?.result?.Id);
          this.ClockInUserOnCompleted.emit(response?.result);
          this.DisabledNextActivity = false;
          resolve(); 
        },
        (error) => {
          console.error('Error saving data:', error);
          this._NzMessageService.error('An error has occurred. Please try again.');
          this.DisabledNextActivity = false;
          reject(error);
        }
      ).add(() => {
        this.resetFlags();
        this.isSubmitted = false;
      });
    });
  }
  resetFlags(): void {
    this.isSave = false;
    this.isSaveAndClose = false;
    this.isSaveAndApprove = false;
    this.isResetLoading = false;
  }
  shiftApprovalConfirm(): void {
    if (this.isShiftApproveLoading) {
      return;
    }
  
    this.modal.confirm({
      nzTitle: `Shift Approval`,
      nzContent: `Are you sure you want to approve this shift?`,
      nzOkText: `Approve Shift`,
      nzOkDanger: false,
      nzCancelText: 'Cancel',
      nzOnOk: () => {
        this.isShiftApproveLoading = true;
        return this.saveShiftApprove()
          .then(() => {
            // this.CancelClockIn();
          })
          .catch(() => {
  
          });
      },
      nzCentered: true,
      nzOkLoading: this.isShiftApproveLoading,
      nzBodyStyle: { 'border-radius': '50px', 'height': 'auto', 'padding-top': '15' },
      nzCloseIcon: '',
      nzIconType: ''
    });
  }
  saveShiftApprove(): Promise<void>{
    return this.saveShift('saveAndApprove').then(() => {
    }).catch((error) => {
      console.error('Error during save and close:', error);
    })
  }
  shiftResetConfirm(): void {
    if (this.isResetLoading) {
      return;
    }
  
    this.modal.confirm({
      nzTitle: `Confirm Reset`,
      nzContent: `Are you sure you want to reset this shift's approval?`,
      nzOkText: `Reset Approval`,
      nzOkDanger: false,
      nzCancelText: 'Cancel',
      nzOnOk: () => {
        this.isResetLoading = true;
        return this.resetApproval()
          .then(() => {
            // this.CancelClockIn();
          })
          .catch(() => {
  
          });
      },
      nzCentered: true,
      nzOkLoading: this.isResetLoading,
      nzBodyStyle: { 'border-radius': '50px', 'height': 'auto', 'padding-top': '15' },
      nzCloseIcon: '',
      nzIconType: ''
    });
  }
  resetApproval(): Promise<void>{
    return this.saveShift('saveAndResetApproval').then(() => {
    }).catch((error) => {
      console.error('Error during save and close:', error);
    })
  }
  saveShiftAndClose(): Promise<void>{
    return this.saveShift('saveAndClose').then(() => {
      this.CancelClockIn();
      this.resetForm();
    }).catch((error) => {
      console.error('Error during save and close:', error);
    })
  }
  resetForm(): void {
    this.timeClockFormFields = {}
  }
  readonly validationRules = {
    jobInformationId: {
      required: 'Required',
      maxLength: {message: 'Job' }
    },
    applicationUserIds: {
      required: 'Required',
      maxLength: {message: 'Users' }
    },
    startTime: {
      required: 'Required',
      maxLength: {message: 'Start Time' }
    },
    startOn: {
      required: 'Required',
      maxLength: {message: 'Start Date' }
    },
    notes: {
      maxLength: { limit: 2500, message: 'Notes' }
    },
  };
  getFieldLabel(field: string): string {
    const labels: { [key: string]: string } = {
      jobInformationId: 'Job',
      applicationUserIds: 'Users',
      startTime: 'Start Time',
      startOn: 'Start Date',
      notes: 'notes',
    };
    return labels[field] || field;
  }
  getErrorTip(field: string): string {
   const rules = this.validationRules[field];
   const input = this.timeClockFormFields[field];
   const inputValue = typeof input === 'string' ? input.trim() : '';

   if (rules.required && !input) {
     return 'Required';
   }

    if (rules.maxLength && inputValue.length > rules.maxLength.limit) {
     const excessLength = inputValue.length - rules.maxLength.limit;
     const unit = excessLength === 1 ? 'character' : 'characters';
     return `${excessLength} ${unit} over.`;
   }

    return '';
  }
  get errorFields(): { field: string; label: string; message: string }[] {
    return Object.keys(this.validationStates)
      .filter(field => this.validationStates[field] === 'error')
      .map(field => ({
        field,
        label: this.getFieldLabel(field),
        message: this.validationMessages[field]
      }));
  }
  checkErrors() {
   this.validationStates = {};
   this.validationMessages = {};

   for (let field in this.validationRules) {
     if (this.validationRules.hasOwnProperty(field)) {
       if (this.touchedFields[field] || this.isSubmitted) {
         const errorTip = this.getErrorTip(field);
         if (errorTip) {
           this.validationStates[field] = 'error';
           this.validationMessages[field] = errorTip;
         } else {
           this.validationStates[field] = null;
           this.validationMessages[field] = '';
         }
       }
     }
   }
  }
  onFieldChange(field: string, value: any) {
    if (typeof value === 'string') {
      this.timeClockFormFields[field] = value.trim();
    } else {
      this.timeClockFormFields[field] = value;
    }
    this.touchedFields[field] = true;
    this.checkErrors();
    if (field === 'applicationUserIds') {
      this.onUserChanges(value);
    } 
    if(value?.length === 0){
      this.timeClockFormFields.applicationUserIds = 0
    }
  }
  ClockInGetById(_Id: number){
    if (_Id) {
      this._TimeClockSService.getDataIdChangeOrder(_Id).subscribe(
        (res) => {
          this._OnITimeClockShiftGetById = res?.result;
          this._TimeClockSService.getBreakBy(res?.result?.Id).subscribe(res => {
            this.BreakInformationRes = res.result;
            this.patchBreakItems();
          });
          this.ITimeClockShiftGetByIdRes = res.result;
          this.patchFormValues(res.result);
        },
      )
    }
  }
  patchBreakItems() {
    if (this.BreakInformationRes && this.BreakInformationRes.length > 0) {
      this.items = this.BreakInformationRes.map(breakItem => ({
        breakStartTime: breakItem.BreakStartTime,
        breakStartOn: breakItem.BreakStartOn,
        breakEndTime: breakItem.BreakEndTime,
        breakEndOn: breakItem.BreakEndOn,
      }));
    }
  }
  patchFormValues(ITimeClockShiftGetByIdRes: ITimeClockShiftGetById): void {
    this.timeClockFormFields = {
      globalId: ITimeClockShiftGetByIdRes?.GlobalId,
      applicationUserIds: ITimeClockShiftGetByIdRes?.ApplicationUserId,
      jobInformationId: ITimeClockShiftGetByIdRes?.JobInformationId,
      startOn: ITimeClockShiftGetByIdRes?.StartOn || null,
      endOn: ITimeClockShiftGetByIdRes?.EndOn || null,
      startTime: ITimeClockShiftGetByIdRes?.StartTime || null,
      endTime: ITimeClockShiftGetByIdRes?.EndTime || null,
      notes: ITimeClockShiftGetByIdRes?.Notes || '',
      shiftTypeSetupId: ITimeClockShiftGetByIdRes?.ShiftTypeSetupId,
      shiftHours: ITimeClockShiftGetByIdRes?.ShiftHours,  
      shiftMinutes: ITimeClockShiftGetByIdRes?.ShiftMinutes,
      overTimeHours: ITimeClockShiftGetByIdRes?.OverTimeHours,
      overTimeMinutes: ITimeClockShiftGetByIdRes?.OverTimeMinutes,
      doubleOvertimeHours: ITimeClockShiftGetByIdRes?.DoubleOvertimeHours,
      doubleOvertimeMinutes: ITimeClockShiftGetByIdRes?.DoubleOvertimeMinutes,
      costCodeId: ITimeClockShiftGetByIdRes?.CostCodeId,
      rate: ITimeClockShiftGetByIdRes?.Rate,
      hours: ITimeClockShiftGetByIdRes?.Hours,
      statusSetupId: ITimeClockShiftGetByIdRes?.StatusSetupId,
      builderCost: ITimeClockShiftGetByIdRes?.BuilderCost,
      createShiftTagParameterRequests: ITimeClockShiftGetByIdRes?.TagIds? ITimeClockShiftGetByIdRes.TagIds.split(',').map(id => id.trim()): [],
    };
    if (this.timeClockFormFields.startTime && this.timeClockFormFields.endTime) {
      this.onEndDateChange(this.timeClockFormFields.endTime);
      this.OnAdBreak = true;
    } else {
      this.OnAdBreak = false;
    }
    this.initializationComplete.emit(false);
  }
  deleteJobConfirm(): void {
    if (this.isDeleteLoading) {
      return;
    }
  
    this.modal.confirm({
      nzTitle: `Delete Shift?`,
      nzContent: `Are you sure you want to permanently delete this Shift?`,
      nzOkText: `Delete`,
      nzOkDanger: true,
      nzCancelText: 'Cancel',
      nzOnOk: () => {
        this.isDeleteLoading = true;
        return this.deleteShift(this.timeClockFormFields.globalId)
          .then(() => {
            this.CancelClockIn();
          })
          .catch(() => {
  
          });
      },
      nzCentered: true,
      nzOkLoading: this.isDeleteLoading,
      nzBodyStyle: { 'border-radius': '50px', 'height': 'auto', 'padding-top': '15' },
      nzCloseIcon: '',
      nzIconType: ''
    });
  }
  deleteShift(id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!id) {
        this._NzMessageService.error('No record selected for deletion.');
        this.isDeleteLoading = false;
        reject();
        return;
      }
  
      this._TimeClockSService.deleteData(id).subscribe(
        (res) => {
          // this.isDeleteLoading = false;
          // this._BehaviorsubjectService.setLeadActivityTypeResponse(this.jobInformationResponse);
          // this.onAfterResponseUse();
          this.CancelClockIn();
          this.isDeleteLoading = false;
          this.ClockInUserOnCompleted.emit(res?.result);
          this._NzMessageService.success('Shift deleted successfully');
          resolve();
        },
        (error) => {
          this.isDeleteLoading = false;
          this._NzMessageService.error('An error occurred while deleting the record. Please try again.');
          reject();
        }
      );
    });
  }
  addBreak(): void {
    this.BreakFormFields = {
      breakStartOn: '',
      breakStartTime: '',
      breakEndOn: '',
      breakEndTime: '',
    };
    this.items.push(this.BreakFormFields);
  }
  deleteItem(index: number){
    this.items.splice(index, 1);
  }
  onBreakStartTimeChange(value: string) {
    this.BreakFormFields.breakStartOn = new Date();
    if(value && !this.BreakFormFields.breakEndTime){
      this.BreakStartTime = true;
    } else if(value){
      const breakStartTime = new Date(value);
      const startTime = new Date(this.timeClockFormFields.startTime);
      const formattedStartTime = this.formatTime(breakStartTime);
      const formattedEndTime = this.formatTime(startTime);
      if (formattedStartTime < formattedEndTime) {
        this.BreakStartTime = true;
      } else {
        this.BreakStartTime = false;
      }
    }
  }
  onBreakEndTimeChange(value: string) {
    this.BreakFormFields.breakEndOn = new Date();
    const breakStartTime_1 = new Date(this.BreakFormFields.breakStartTime);
    const startTime_1 = new Date(this.timeClockFormFields.startTime);
    const formattedStartTime_1 = this.formatTime(breakStartTime_1);
    const formattedEndTime_1 = this.formatTime(startTime_1);
    if(formattedEndTime_1 > formattedStartTime_1){
      this.BreakStartTime = true;
    } else {
      if(value && !this.BreakFormFields.breakEndTime){
        this.BreakStartTime = true;
      } else if(value){
        const breakStartTime = new Date(value);
        const startTime = new Date(this.timeClockFormFields.endTime);
        const formattedStartTime = this.formatTime(breakStartTime);
        const formattedEndTime = this.formatTime(startTime);
        if (formattedEndTime < formattedStartTime) {
          this.BreakStartTime = true;
        } else {
          this.BreakStartTime = false;
        }
      }
    }
  }
  onCheck_Time(){
    if(this.BreakFormFields.breakStartTime && !this.BreakFormFields.breakEndTime){
      this.BreakStartTime = true;
      return
    }
  }
  onCostCodeIdChange(value: number) {
    this._onCostCodeIdChange = this.CostCodeList?.find(item => item?.id === value);
    this.timeClockFormFields.rate = this._onCostCodeIdChange?.defaultLaborCost;
    const rate = parseFloat(String(this._onCostCodeIdChange?.defaultLaborCost).replace(/,/g, ''));
    if (rate&& this.timeDifference?.hours) {
      const calculatedCost = rate * this.timeDifference.hours;
      this.onBuilderCost = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(calculatedCost);
    }
  }
  startTime = this.BreakFormFields.breakStartTime
  endTime = this.BreakFormFields.breakEndTime
  breakDuration = this.getBreakDuration(this.startTime, this.endTime);
  getBreakDuration(startTime: Date, endTime: Date){
    // const startDate = startTime;
    // const endDate = endTime;
    
    // const differenceMilliseconds = endDate.getTime() - startDate.getTime();
    
    // const totalMinutes = Math.floor(differenceMilliseconds / (1000 * 60));
    // const hours = Math.floor(totalMinutes / 60);
    // const minutes = totalMinutes % 60;

    // console.log('hours',hours,'minutes',minutes);
    // return { hours, minutes };
  }
}