<nz-modal nzWidth="60%" [(nzVisible)]="CostInformation" [nzFooter]="footer" [nzTitle]="surveys1" (nzOnCancel)="showConfirmCardCost()"
  [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px','height': '74vh',
   'max-height': '74vh', 'overflow-y': 'auto'}" [nzStyle]="{ top: '25px' }">
  <ng-template #surveys1>
    <div class="row p-0 m-0">
      <h1 class="p-0 m-0 famly-inn">Estimated Code Details</h1>
    </div>
  </ng-template>

  <div class="content" *nzModalContent>


    <div class="top-of-modal">
      <nz-alert class="mb-3 error-message" *ngIf="isSubmitted && errorFields.length > 0" nzType="error" nzShowIcon
        [nzMessage]="message" [nzDescription]="errorDescriptionTemplate"></nz-alert>

      <ng-template #message>
        <p class="mt-1">Please correct the following fields:</p>
      </ng-template>

      <ng-template #errorDescriptionTemplate>
        <ul>
          <li class="sub-text" *ngFor="let error of errorFields">
            <span class="d-flex align-items-center">
              <p>{{ error.label }}:</p>
              <p>{{ error.message }}</p>
            </span>
          </li>
        </ul>
      </ng-template>
    </div>



    <nz-card nzTitle="Estimated Cost Details" class="mb-3">





      <div nz-row [nzGutter]="16">
        <div nz-col nzSpan="12">
          <div nz-row class="mb-3">
            <div nz-col nzSpan="24">
              <label class="sub-text">Title</label>
              <span *ngIf="formFieldValues.isCatalog" class="text-danger">*</span>
              <input nz-input [(ngModel)]="formFieldValues.title" name="title" [required]="formFieldValues.isCatalog" 
                (ngModelChange)="onFieldChange('title', $event)" [nzStatus]="validationStates['title']" />
              <div *ngIf="validationStates['title'] === 'error'" class="text-danger">
                {{ validationMessages["title"] }}
              </div>
            </div>
          </div>
          <div nz-row class="mb-3">
            <div nz-col nzSpan="24">
              <label [(ngModel)]="formFieldValues.isCatalog" name="isCatalog" nz-checkbox  (ngModelChange)="onFieldChange('isCatalog', $event)">Include Item In
                Catalog</label>
            </div>
          </div>
          <!-- Cost Code Select Field -->
          <div nz-row class="mb-4" class="d-flex align-items-end">
            <div nz-col nzSpan="16">
              <label class="sub-text">Cost Code <span class="text-danger">*</span></label>
              <nz-select  [(ngModel)]="formFieldValues.costCodeId" [nzStatus]="validationStates['costCodeId']" name="costCodeId"
                (ngModelChange)="onFieldChange('costCodeId', $event)">
                <nz-option *ngFor="let option of leadOptions" [nzLabel]="option.title"
                  [nzValue]="option.id"></nz-option>
              </nz-select>

            </div>


            <!-- Buttons -->
            <div nz-col nzSpan="8" class="d-flex p-0 m-0">
              <button nz-button nzType="link">Add</button>
              <button nz-button nzType="link">Edit</button>
            </div>
            <div *ngIf="validationStates['costCodeId'] === 'error'" class="text-danger">
              {{ validationMessages['costCodeId'] }}
            </div>
          </div>

          <div nz-row class="mb-3">
            <div nz-col nzSpan="24">
              <label class="sub-text">Description</label>
              <textarea [(ngModel)]="formFieldValues.description" name="description"
                [nzAutosize]="{ minRows: 4, maxRows: 8 }" class="ant-input1 formField" nz-input
                (ngModelChange)="onFieldChange('description', $event)"
                [nzStatus]="validationStates['description']"></textarea>
              <div *ngIf="validationStates['description'] === 'error'" class="text-danger">
                {{ validationMessages['description'] }}
              </div>

            </div>
          </div>
          <div nz-row class="mb-3">
            <div nz-col nzSpan="24">
              <label class="sub-text">Internal Notes</label>
              <textarea [(ngModel)]="formFieldValues.internalNotes" name="internalNotes"
                [nzAutosize]="{ minRows: 4, maxRows: 8 }" class="ant-input1 formField" nz-input
                (ngModelChange)="onFieldChange('internalNotes', $event)"
                [nzStatus]="validationStates['internalNotes']"></textarea>
              <div *ngIf="validationStates['internalNotes'] === 'error'" class="text-danger">
                {{ validationMessages['internalNotes'] }}
              </div>
            </div>
          </div>

        </div>

        <!-- second column  -->
        <div nz-col nzSpan="12">
          <div nz-row class="mb-3">
            <div nz-col nzSpan="24">
              <label class="sub-text">Cost Type </label>
              <nz-select  [(ngModel)]="formFieldValues.costTypeId" name="costTypeId">
                <nz-option nzLabel="None" [nzValue]="0">
                </nz-option>
                <nz-option *ngFor="let option of proposalOption" [nzLabel]="option.name"
                  [nzValue]="option.id"></nz-option>

              </nz-select>
            </div>
          </div>

          <div nz-row class="mb-3">
            <div nz-col nzSpan="24">
              <label class="sub-text">Mark As</label>
              <nz-select [(ngModel)]="formFieldValues.markAsId" name="markAsId">
                <nz-option nzLabel="None" [nzValue]="0">
                </nz-option>
                <nz-option *ngFor="let option of markAsOption" [nzLabel]="option.name"
                  [nzValue]="option.id"></nz-option>
              </nz-select>
            </div>
          </div>

          <div nz-row class="mb-4" class="d-flex align-items-end">
            <div nz-col nzSpan="16">
              <label class="sub-text">Group</label>
              <nz-select [(ngModel)]="formFieldValues.groupId" name="groupId">
                <nz-option nzLabel="Unassigned" nzValue="0">
                </nz-option>
                <nz-option *ngFor="let option of markAsOption" [nzLabel]="option.name"
                  [nzValue]="option.id"></nz-option>
              </nz-select>
            </div>

            <!-- Buttons -->
            <div nz-col nzSpan="8" class="d-flex p-0 m-0">
              <button nz-button nzType="link">Add</button>
              <button nz-button nzType="link">Edit</button>
            </div>
          </div>

        </div>
      </div>

    </nz-card>

    <!-- First Card Estimated Code Details End-->

    <!-- Second Card Start  -->

    <nz-card nzTitle="Cost Information">
      <div nz-row [nzGutter]="16">
        <div nz-col nzSpan="12">

          <div nz-row class="mb-3">
            <div nz-col nzSpan="24">
              <label class="sub-text">Unit Cost</label>
              <nz-input-group nzAddOnBefore="$">
                <input appDollarFormatter [(ngModel)]="formFieldValues.unitCost" name="unitCost" nz-input
                  (ngModelChange)="updateBuilderCost()" />
              </nz-input-group>
            </div>
          </div>

          <div nz-row class="mb-3">
            <div nz-col nzSpan="24">
              <label class="sub-text">Unit</label>
              <input type="text" nz-input [(ngModel)]="formFieldValues.unit" name="unit" />
            </div>
          </div>

          <div nz-row class="mb-3">
            <div nz-col nzSpan="24">
              <label class="sub-text">Markup</label>
              <nz-input-group [nzPrefix]="formFieldValues.markupId === 40420 ? suffixTemplateDollar : null"
                [nzAddOnBefore]="formFieldValues.markupId === 40418 || formFieldValues.markupId === 40419 ? addOnBeforeTemplateDollar : null"
                [nzAddOnAfter]="addOnAfterTemplate">

                <!-- Input Field -->
                <input [(ngModel)]="formFieldValues.markupValue" name="markupValue" nz-input appDollarFormatter
                  [disabled]="formFieldValues.markupId === 40420" (ngModelChange)="updateBuilderCost()" />
              </nz-input-group>

              <!-- AddOn After Template -->
              <ng-template #addOnAfterTemplate>
                <nz-select [(ngModel)]="formFieldValues.markupId" name="markupId"
                  (ngModelChange)="onMarkupChange($event)" class="fixed-select-width">
                  <nz-option *ngFor="let option of markupOption" [nzLabel]="option.description"
                    [nzValue]="option.id"></nz-option>
                </nz-select>
              </ng-template>

              <!-- AddOn Before Dollar Template -->
              <ng-template #addOnBeforeTemplateDollar>
                <span>$</span>
              </ng-template>

              <!-- AddOn Before Percentage Template -->
              <ng-template #addOnBeforeTemplatePercentage>
                <span>%</span>
              </ng-template>

              <!-- Suffix Dollar Template -->
              <ng-template #suffixTemplateDollar>
                <span>$</span>
              </ng-template>
            </div>
          </div>



          <div nz-row class="mb-3">
            <div nz-col nzSpan="24">
              <label class="sub-text">Margin</label>
              <nz-input-group nzAddOnAfter="%">
                <input appDollarFormatter [(ngModel)]="formFieldValues.margin" name="margin" type="text" nz-input />
              </nz-input-group>
            </div>
          </div>

        </div>

        <div nz-col nzSpan="12">
          <div nz-row class="mb-3">
            <div nz-col nzSpan="24">
              <label class="sub-text">Quantity</label>
              <nz-input-number style="width: 100%;" [(ngModel)]="formFieldValues.quantity" name="quantity" [nzMin]="1"
                [nzStep]="1" (ngModelChange)="updateBuilderCost()">
              </nz-input-number>
            </div>
          </div>

          <div nz-row class="mb-3">
            <div nz-col nzSpan="24">
              <label class="sub-text">Builder Cost</label>
              <p>{{ formFieldValues.builderCost | currency }}</p>
            </div>
          </div>

          <div nz-row class="mb-3">
            <div nz-col nzSpan="24">
              <label class="sub-text">Owner Price</label>
              <p>{{ formFieldValues.ownerPrice | currency }}</p>
            </div>
          </div>
        </div>
      </div>
    </nz-card>

    <!-- Second Card End  -->

  </div>
  <ng-template #footer>
    <div nz-row  class="justify-content-end d-flex align-item-end ">
      <div nz-col nzSpan="12" >
        <nz-space>
          <ng-container *nzSpaceItem>
            <button
              nz-button
              nzType="primary"
              class="align-items-center"
              [nzLoading]="isSaveAndNew"
              (click)="saveAndNewCost()"
              [disabled]="DisabledNextActivity"
              [class.disabled]="DisabledNextActivity"
            >
              Save & New
            </button>
          </ng-container>
  
          <ng-container *nzSpaceItem>
            <button
              nz-button
              nzType="primary"
              class="align-items-center"
              [nzLoading]="isSave"
              (click)="saveButton()"
              [disabled]="DisabledNextActivity"
              [class.disabled]="DisabledNextActivity"
            >
              Save
            </button>
          </ng-container>
  
          <ng-container *nzSpaceItem>
            <button
              nz-button
              nzType="primary"
              class="align-items-center"
              [nzLoading]="isSaveAndClose"
              (click)="saveAndCloseCost()"
              [disabled]="DisabledNextActivity"
              [class.disabled]="DisabledNextActivity"
            >
              Save & Close
            </button>
          </ng-container>
        </nz-space>
      </div>
  
    </div>

  </ng-template>
</nz-modal>




























<!-- 
<button nz-button style="margin-right: 8px; border-radius: 7px;" nz-dropdown [nzDropdownMenu]="menu2"
nzTrigger="click">
<i class="bi bi-three-dots"></i>
</button>

<nz-button-group >
<button nz-button class="button-Daily" (click)="saveMethodForToDoSave()"  > Save & Close
</button>

<button nz-button class="button-Daily" nz-dropdown [nzDropdownMenu]="menu1" nzTrigger="click">
  <span nz-icon nzType="down"></span>
</button>
</nz-button-group>
<nz-dropdown-menu #menu1="nzDropdownMenu">
<ul nz-menu >
  <li *ngIf="responseToDo" nz-menu-formFieldValues (click)="createMessage()" class="fw-bold">Save Message</li>
  <li nz-menu-formFieldValues class="fw-bold">Save & New</li>
  <li nz-menu-formFieldValues (click)="saveMethodForToDoSave()" class="fw-bold"> Save</li>
</ul> -->
<!-- </nz-dropdown-menu> -->