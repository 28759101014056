import { Component, EventEmitter, Output, ViewChild, ElementRef, AfterViewInit, OnInit } from '@angular/core';
import { NzStatus } from 'ng-zorro-antd/core/types';
import { NzMessageService } from 'ng-zorro-antd/message';
import { SignatureSetupResponse } from 'src/Models/SignaturePad/SignaturePad';
import { LeadOpportunitiesService } from 'src/Service/lead-opportunities.service';

@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.css']
})
export class SignatureComponent implements OnInit, AfterViewInit {
  @Output() cancel = new EventEmitter<void>();
  @Output() SignatureResponse = new EventEmitter<SignatureSetupResponse>();
  @Output() Approve_DeclinedResponse = new EventEmitter<string>(); 
  @Output() ApplicationIdRes: number;
  @Output() Params: string;
  isVisibleInSignature: boolean = true;
  @ViewChild('canvasRef', { static: false }) canvasRef!: ElementRef<HTMLCanvasElement>;
  private ctx!: CanvasRenderingContext2D;
  private drawing = false;
  formFieldValues: any = {};
  isSignatureSaved: boolean = false;
  onGetAllRes: SignatureSetupResponse;
  Approve: boolean = false;
  Decline: boolean = false;
  isApprove: boolean = false;
  isDeclined: boolean = false;
  showErrorMessage = false;
  EnableUpdate: boolean = false;
  Test: boolean = false;

  constructor(
    private _LeadOpportunitiesService: LeadOpportunitiesService,
    public toastsService: NzMessageService,
  ){}
  ngOnInit(): void {
    if(this.Params === 'Approved'){
      this.Approve = true
    } else {
      this.Decline = true
    }
    this.CheckApplicationId(this.ApplicationIdRes);
    this.formFieldValues = {
      globalId: '00000000-0000-0000-0000-000000000000',
      imageSource: '',
      applicationUserId: this.ApplicationIdRes,
    };
    this.Test = false
  }
  CheckApplicationId(_Id: number){
    this._LeadOpportunitiesService.getSignatureSetupByUserId(_Id).subscribe(
      (response) => {
        this.onGetAllRes = response.result
        if (response?.result?.imageSource) {
          this.loadImageOnCanvas(response.result.imageSource);
        }
      });
  }
  ngAfterViewInit(): void {
    const canvas = this.canvasRef.nativeElement;
    this.ctx = canvas.getContext('2d')!;
    canvas.width = canvas.offsetWidth;
    canvas.height = canvas.offsetHeight;
    this.ctx.strokeStyle = '#000000';
    this.ctx.lineWidth = 2; 
  }
  loadImageOnCanvas(base64Image: string): void {
    const canvas = this.canvasRef.nativeElement;
    const img = new Image();
    img.src = base64Image;
    img.onload = () => {
      this.ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    };
  }
  startDrawing(event: MouseEvent): void {
    this.drawing = true;
    const pos = this.getMousePosition(event);
  
    this.ctx.beginPath();
    this.ctx.arc(pos.x, pos.y, 1, 0, 2 * Math.PI);
    this.ctx.fill();
    this.ctx.moveTo(pos.x, pos.y);
  
    this.Test = true;
    this.showErrorMessage = false;
  }
  draw(event: MouseEvent): void {
    if (!this.drawing) return;
    const pos = this.getMousePosition(event);
    this.ctx.lineTo(pos.x, pos.y);
    this.ctx.stroke();
  }
  CheckBoxTest(){
    this.Test = true
  }
  stopDrawing(): void {
    this.drawing = false;
    if (this.Test === true) {
      this.EnableUpdate = true;
    } else {
      this.EnableUpdate = false;
    }
    this.ctx.closePath();
  }
  private getMousePosition(event: MouseEvent): { x: number, y: number } {
    const canvas = this.canvasRef.nativeElement;
    const rect = canvas.getBoundingClientRect();
    const scaleX = canvas.width / rect.width;
    const scaleY = canvas.height / rect.height;

    return {
      x: (event.clientX - rect.left) * scaleX,
      y: (event.clientY - rect.top) * scaleY
    };
  }
  clearCanvas(): void {
    const canvas = this.canvasRef.nativeElement;
    this.ctx.clearRect(0, 0, canvas.width, canvas.height);
    this.EnableUpdate = false;
    this.isSignatureSaved = false;
  }
  Signature(): void {
    const canvas = this.canvasRef.nativeElement;
    const context = canvas.getContext('2d');
    const blankCanvas = document.createElement('canvas');
    blankCanvas.width = canvas.width;
    blankCanvas.height = canvas.height;
    
    if (canvas.toDataURL() === blankCanvas.toDataURL()) {
      this.showErrorMessage = true;
      return;
    } else {
      this.showErrorMessage = false;
    }
  
    const dataURL = canvas.toDataURL();
    this.formFieldValues.imageSource = dataURL;
  
    if (this.isSignatureSaved === true) {
      this.formFieldValues.globalId = this.onGetAllRes?.globalId;
      this.saveSignature();
    } else {
      this.formFieldValues.globalId = '00000000-0000-0000-0000-000000000000';
      this.saveSignature();
    }
  }
  saveSignature(actionType: 'approve' | 'decline' = 'approve'): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.isApprove || this.isDeclined) {
        return reject('Already processing');
      }
  
      if (actionType === 'decline') {
        this.isDeclined = true;
      } else {
        this.isApprove = true;
      }

      this._LeadOpportunitiesService.PostSignaturePad(this.formFieldValues).subscribe(
        (response) => {
          this.loadImageOnCanvas(response.result.imageSource);
          this.SignatureResponse.emit(response.result);
          this.Approve_DeclinedResponse.emit(this.Params);
          this.CancelSignature();
          
          if(this.Params === 'saveAndAndApprove'){
            this.toastsService.success('Change Order approved');
          } else {
            this.toastsService.success('Change Order declined');
          }
          resolve();
        },
        (error) => {
          console.error('Error saving data:', error);
          this.toastsService.error('An error has occurred. Please try again.');
          reject(error);
        }
      ).add(() => {
        this.resetFlags();
      });
    });
   }
   resetFlags() {
    this.isApprove = false; 
    this.isDeclined = false; 
  }
  UpdateSignature(checked: boolean): void {
    this.isSignatureSaved = checked
  }
  CancelSignature() {
    this.isVisibleInSignature = false;
    this.cancel.emit();
  }
}
