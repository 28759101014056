<div class="card border-0 mt-3" style="border-radius: 4px 4px 0px 0px;">
  <div class="card-head border-bottom">
    <div class="row">
      <h2 class="famly-inn ant-typography-crad-Header">Messaging</h2>
    </div>
  </div>
  <div class="card-body">
    <ng-container  *ngIf="header_ID > 0; else MessagingAvailableAfterSave">
      <div class="row">
        <div class="col d-flex align-items-center">
          <span class="famly-inn ant-typography-heading-bold">Comments</span>
          <span nz-icon nzType="eye" nzTheme="fill" class="ms-1" nz-popover [nzPopoverContent]="CommentsContent" nzPopoverPlacement="right"></span>
          <ng-template #CommentsContent>
            <div class="card border-0 mt-0" style="width: 240px; font-size: 14px">
              <div class="card-head border-bottom">
                <div class="row">
                  <h2 class="famly-inn ant-typography-crad-Header">Who Can View?</h2>
                </div>
              </div>
              <div class="card-body">
                <div nz-row>
                  <div nz-col nzSpan="24">
                    <p>
                      22 users can currently view all or some of this conversation (details on the comment).
                    </p>
                  </div>
                </div>
                <div nz-row>
                  <div nz-col nzSpan="24">
                    <span class="famly-inn ant-typography-heading-bold">Internal Users</span>
                  </div>
                </div>
                <div nz-row class="mt-2">
                  <div nz-col nzSpan="24">
                    <p>
                      Abbas Hussain, abdul rehman test 2, Accounts Assistant, Construction Admin, Construction Manager, Ghulam Rasool, Imran Ahmed, Lucas King, mehmood test test, Mesum Hussain, Muhammad Umer, Muhammad Yousuf, Pre-Construction Admin, Sajad Hassani, samiAdd Internal User Rasool, Shabab Haider, Sultan Abdul Hameed , Syed Ahsan Kazmi, test abdul rehman, Umar Farooq, Waseem Ali, Wasi Shah
                    </p>
                  </div>
                </div>
              </div>
            </div>                        
          </ng-template>
        </div>
      </div>
      <form [formGroup]="messagesFormValueForm">
        <div nz-row>
          <div nz-col nzSpan="22">
            <ckeditor
              formControlName="comments"
              name="comments"
              [editor]="Editor"
              [config]="config"
              class="size"
              [ngClass]="{
                'invalid-editor': messagesFormValueForm.controls['comments'].dirty &&  messagesFormValueForm.hasError('required', 'comments')
              }"
              id="messagesEditor">
            </ckeditor>
          </div>
          <div nz-col nzSpan="2" class="d-flex align-items-end">
            <button
            nz-button
            nzType="text"
            (click)="onSaveMessage()"
            [nzLoading]="isSave"
            [disabled]="DisabledNextActivity"
            [class.disabled]="DisabledNextActivity"
            >
            <span nz-icon nzType="send" nzTheme="outline"></span>
          </button>
          </div>
        </div>
        <div nz-row>
          <div nz-col nzSpan="24">
            <small *ngIf="messagesFormValueForm.controls['comments'].dirty &&  messagesFormValueForm.hasError('required', 'comments')" class="text-danger">
              Content must not be empty
            </small>
          </div>
        </div>
      </form>
       <div class="row mt-3">
        <div class="col-12">
          <nz-collapse [nzBordered]="false" class="conection border-0 rounded-2">
            <nz-collapse-panel class="border-0 rounded-2" #p [nzExpandedIcon]="expandedIcon"
              [nzHeader]="collapseHeaderTitle">
              <ng-template #collapseHeaderTitle>
                <div nz-row class="famly-inn">
                  <div nz-col nzSpan="24">
                    <p style="font-size: 14px;margin: 0;">
                      Viewer & Notification Settings
                    </p>
                  </div>
                </div>
              </ng-template>
              <ng-template #expandedIcon let-active>
                {{ active }}
                <span nz-icon nzType="caret-right" class="ant-collapse-arrow"
                  [nzRotate]="p.nzActive ? 90 : -0"></span>
              </ng-template>
  
              <div class="row m-0 p-1" #p>
                <div class="col">
                  <label class="sub-text h5">Show</label>
                </div>
                <div class="col-md-12">
                  <label
                    nz-checkbox name="isCopyActivityAddresses">Owner</label>
                </div>
  
                <div class="col-md-12 mb-3">
                  <label 
                   nz-checkbox
                    name="isCopyActivityAddresses">Subs/Vendors</label>
                </div>
                <div class="col align-items-center">
                  <label class="sub-text h5">Notify
                    <span nz-popover [nzPopoverContent]="CollapseMessage"
                      nzPopoverPlacement="right" nz-icon
                      nzType="info-circle-fill"></span></label>
                </div>
                <div class="col-md-12 mb-1">
                  <div class="input-group">
                    <nz-tree-select
                      [(ngModel)]="messagesFormValue['createMessagingUserNotifyParameterRequests']  "
                      name="createMessagingUserNotifyParameterRequests"
                       style="width: 100%"
                      nzShowSearch nzAllowClear="false" nzCheckable nzCheckAll="true"
                      [nzVirtualHeight]="'80px'">
                    </nz-tree-select>
                  </div>
                </div>
              </div>
              <ng-template #CollapseMessage>
                <div class="rounded-2">
                  <p style="width: 230px; font-size: 13px">
                    Owners will appear in this list if they have
                    access to the entity this comment is
                    associated to.
                    <br>
                    <br>
                    <span>
                      A Sub/Vendor will appear on this list if they
                      have access to the entity this comment is
                      associated to and if they have viewing access
                      to the job.
                    </span>
                    <br>
                    <br>
                    <span>
                      An Internal User will appear in the list if
                      they have access to the entity the comment is
                      associated with, if they have access to the
                      job, and if they have their notifications
                      turned on for new comments on the entity.
                    </span>
                  </p>
                </div>
              </ng-template>
  
  
            </nz-collapse-panel>
          </nz-collapse>
          <nz-divider></nz-divider>
        </div>
      </div>
      <div class="CommentContainer-CommentLoaded">
        <div nz-row *ngFor="let comment of flattenedComments" style="margin: 0px 7px 0px 0px;">
          <div nz-col nzSpan="4"></div>
          <div nz-col nzSpan="20">
            <div class="card border-0 mt-3"  style="background-color: #e6f6ff; margin-top: 5px;border-radius: 5px;">
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <span class="comment-content">
                      <span style="white-space: nowrap;">{{ comment?.CreatedOn }}</span>
                    </span>
                  </div>
                  <div class="col d-flex justify-content-end">
                  <div nz-row [nzGutter]="12">
                    <div nz-col nzSpan="12">
                      <span (click)="deleteComment(comment?.GlobalId)" style="font-size: 1.43em;" class="btn-link" nz-icon nzType="delete" nzTheme="fill"></span>
                    </div>
                    <div nz-col nzSpan="12">
                      <span style="font-size: 1.43em;" class="btn-link" nz-icon nzType="eye" nzTheme="fill"></span>
                    </div>
                  </div>
                  </div>
                </div>
                <div nz-row class="famly-inn comment-content-detail">
                  <div nz-col nzSpan="24">
                    <p style="font-size: 14px; margin: 0;">
                        <!-- {{ comment?.Comments }} -->
                        <span [innerHTML]="comment.Comments"></span>
                    </p>
                  </div>
                </div>
              </div>
            </div>  
          </div>
        </div> 
      </div>
    </ng-container>
    <ng-template #MessagingAvailableAfterSave>
      <div nz-row>
        <div nz-col nzSpan="24">
          <h2 class="famly-inn ant-typography-crad-Header">Messaging available after save</h2>
        </div>
      </div>
     </ng-template>
  </div>
</div>                        