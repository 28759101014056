import { Autoformat, BlockQuote, Image, Bold, ClassicEditor, Essentials, Font, FontBackgroundColor, FontColor, FontFamily, FontSize, Heading, HorizontalLine, ImageUpload, Indent, Italic, Link, List, ListProperties, MediaEmbed, Paragraph, Table, Undo, UndoEditing } from 'ckeditor5';

export const Editor = ClassicEditor;

export const EditorConfig = {
  toolbar: {
    items: [
      'undo', 'redo', '|',
      'heading', '|', 'bold', 'italic', '|',
      'link', 'insertTable', 'mediaEmbed', '|',
      'bulletedList', 'numberedList', 'indent', 'outdent', '|',
      'fontSize', 'fontFamily', 'fontBackgroundColor', 'fontColor', 'blockQuote',
      'image', 'horizontalLine', 'undoEditing', 'ListProperties', 'Autoformat'
    ]
  },
  plugins: [
    Bold,
    Essentials,
    Heading,
    Indent,
    Italic,
    Link,
    List,
    ListProperties,
    MediaEmbed,
    Paragraph,
    Autoformat,
    Table,
    Undo,
    Font,
    FontSize,
    FontFamily,
    FontBackgroundColor,
    FontColor,
    BlockQuote,
    Image,
    HorizontalLine,
    UndoEditing,
    ImageUpload
  ],
  fontSize: {
    options: [
      9, 11, 13, 'default', 17, 19, 21, 25, 28, 32, 36, 40, 44, 48, 52, 56, 60
    ]
  },
  fontFamily: {
    options: [
      'default', 'Arial, Helvetica, sans-serif', 'Courier New, Courier, monospace',
      'Georgia, serif', 'Times New Roman, Times, serif', 'Verdana, Geneva, sans-serif'
    ]
  }
};
export function initializeEditor(EditorInstance: any, config: any): void {
  const editorElement = document.querySelector('#RFIsEditor') as HTMLElement;
  if (editorElement) {
    EditorInstance.create(editorElement, config)
      .then((editor: any) => {
      })
      .catch((error: any) => {
        console.error('Error initializing editor:', error);
      });
  } else {
    console.error('Editor element not found');
  }
}