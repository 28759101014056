import { Component } from '@angular/core';

@Component({
  selector: 'selection-empty',
  templateUrl: './selection-empty.component.html',
  styleUrls: ['./selection-empty.component.css']
})
export class SelectionEmptyComponent {

}
