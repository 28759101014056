import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';
import { WarrantyNewClaimComponent } from './../warranty-new-claim/warranty-new-claim.component';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { LeadOpportunitiesService } from 'src/Service/lead-opportunities.service';
import { FiltersName } from 'src/app/Core/Enums/filtersName';
import { FilterSideMenuBarService } from 'src/shared/Shared-Services/filter-side-menu-bar.service';
import { ProjectManagementSelectDialogBoxComponent } from '../../project-management-select-dialog-box/project-management-select-dialog-box.component';
import { JobInfoComponentInformation } from 'src/Models/Job-List/Job-Information/Jobinformation';
import { JobInformationService } from 'src/Service/Job-List/Job-Information/job-information.service';
import { InternalUserService } from 'src/Service/Internaluser/internal-user.service';
import { FilterSearchParameter, LookupFilterResponse } from 'src/app/shared/component/Models/LookupModels';
import { StandardColumnSetupResponse, StandardViewResponse } from 'src/Models/LookupStandardview/Standardview';
import { ColumnsResizePinProperties } from 'src/Models/InternalUser/internalUserColumnsPinResize';
import { StandardViewForAllService } from 'src/Service/standard-view-for-all.service';
import { ResponseModelArray } from 'src/Models/responseMessage.model';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Subscription } from 'rxjs';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { WarrantyInformationResponse, WarrantyInformations } from 'src/Models/Warranty/warranty';
import { WarrantyService } from 'src/Service/Project-Management/warranty/warranty.service';
import * as XLSX from 'xlsx';
import { AddNewGridStandardViewComponent } from 'src/app/shared/component/Modal/add-new-grid-standard-view/add-new-grid-standard-view.component';
import { SelectionStatusCode, warrntyStatusCode } from 'src/app/shared/component/Models/LookUpStandardFilterCodes';
import { BehaviorsubjectService } from 'src/Service/behaviorsubject/behaviorsubject.service';
import { CommmentConversationComponent } from 'src/app/messaging/Components/Comments/commment-conversation/commment-conversation.component';
import { RfisEditCommonComponent } from '../../Variations/rfis-edit-common/rfis-edit-common.component';
import { LoadingIndicatorService } from 'src/shared/Shared-Services/loading-indicator.service';

@Component({
  selector: 'app-project-management-warranty-db',
  templateUrl: './project-management-warranty-db.component.html',
  styleUrls: ['./project-management-warranty-db.component.css']
})
export class ProjectManagementWarrantyDbComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  private displayColumnSubscription: Subscription;
  private WarrantystandardViewFilterSubscription: Subscription
  private Warrantysubscription: Subscription;
  private WarrantyFilterSubscription: Subscription;
  // @Output() onSaveComplete = new EventEmitter();
  currentPage = 1;
  isLoadingOne = false;
  pageSize = 10;
  totalRecords = 100;
  PAGE_ID: number = -1;
  isCollapsed = false;
  selectedJobId: number = 0;
  selectedJobName: string = '';
  isLoading: boolean = false;
  defaultFilterIdSelected: number = 0;
  displayedColumns: string[] = [];
  isColumnSticky: boolean[] = new Array(this.displayedColumns.length).fill(false);
  jobInfoOptions: JobInfoComponentInformation[] = [];
  PROJECT_MANG_WARRANTY: string = FiltersName.PROJECT_MANG_WARRANTY;
  WarrantyInformationData: WarrantyInformations[] = [];
  selectedValueControl: any;
  selectedFilter: number = -1;
  selected_View_Columns: string[] = [];
  getStandardViewFilterData: StandardViewResponse[] = [];
  getColumnWidthSize: number[] = [];
  selectedColNameSizePin: ColumnsResizePinProperties[] = [];
  columnSizes: { columnName: string, widthSize: number }[] = [];
  getStandardViewColumnFilterData: StandardColumnSetupResponse[] = [];
  fixedSelectedColumnNames: string[] = [];
  HoldJObname: string;
  // GetProjectWarrantyDatagriddata: WarrantyInformationResponse[] = [];
  GetProjectWarrantyDatagriddata: WarrantyInformations[] = [];
  filterSearchParameter: FilterSearchParameter;
  selectionFrom: string = 'DB'; // New Changes By Aamir Ali - 22-Apr2024
  isFilterApplied: boolean = false;
  companyNameInvalid: boolean = false;
  showErrorCards: boolean = false;
  lookupFilterResponse: LookupFilterResponse[] = [];




  constructor(private modal: NzModalService,
    private filterService: FilterSideMenuBarService,
    private jobInfoService: JobInformationService,
    public localStorage: AccessLocalStorageService,
    private internalUserService: InternalUserService,
    public leadOpportunities: LeadOpportunitiesService,
    private standardViewService: StandardViewForAllService,
    private changeDetectorRef: ChangeDetectorRef,
    private LookupFilterService: LookupFilterService,
    private WarrantyService: WarrantyService,
    private accessLocalStorageService: AccessLocalStorageService,
    private _BehaviorsubjectService: BehaviorsubjectService,
    private _LoadingIndicatorService: LoadingIndicatorService,


    public toastService: NzMessageService,) {
    if (this.WarrantyFilterSubscription) {
      this.WarrantyFilterSubscription.unsubscribe();
    }
    if (this.Warrantysubscription) {
      this.Warrantysubscription.unsubscribe();
    }
    if (this.WarrantystandardViewFilterSubscription) {
      this.WarrantystandardViewFilterSubscription.unsubscribe();
    }
    console.clear();
  }

  ngOnInit(): void {
    this.internalUserService.setSelectedFilterId(0);
    if (this.WarrantyFilterSubscription) {
      this.WarrantyFilterSubscription.unsubscribe();
    }
    if (this.Warrantysubscription) {
      this.Warrantysubscription.unsubscribe();
    }
    if (this.WarrantystandardViewFilterSubscription) {
      this.WarrantystandardViewFilterSubscription.unsubscribe();
    }
    this.PAGE_ID = 10002;
    this.selectedJobId = this.localStorage.getJobId();
    this.selectedJobName = this.localStorage.getJobName();
    // this.getLeadData();
    this.localStorage.getJobId();
    this.localStorage.getJobName();
    this.HoldJObname =  this.localStorage.getJobName()

    this.displayedColumns = [];
    this.displayColumnSubscription = this.standardViewService.$displaySelectedColApply.subscribe(colName => {
      if (colName.length > 0) {
        this.displayedColumns = colName;
        this.changeDetectorRef.detectChanges();
      }
    });
    // Add subscriptions to the array
    this.subscriptions.push(
      this.standardViewService.$displaySelectedColApply.subscribe(colName => {
        if (colName.length > 0) {
          this.displayedColumns = colName;
          this.changeDetectorRef.detectChanges();
        }
      })
    );

    this.subscriptions.push(
      this.standardViewService.$selectedStandardFilterType.subscribe(standardType => {
        this.selectedFilter = standardType != -1 ? standardType : standardType;
      })
    );
    // this.displayedColumns = [];
    // this.displayColumnSubscription = this.standardViewService.$displaySelectedColApply.subscribe(colName => {
    //   if(colName.length > 0){
    //     this.displayedColumns = colName;
    //     this.changeDetectorRef.detectChanges();
    //   }
    // });

    // this.displayColumnSubscription = this.standardViewService.$selectedStandardFilterType.subscribe(standardType => {
    //   if(standardType != -1){
    //     this.selectedFilter = standardType;
    //   }else{
    //     this.selectedFilter = standardType;
    //   }

    // });

    this.getStandardFilterByFormPageId(this.PAGE_ID, this.selectionFrom); // New Changes By Aamir Ali - 22-Apr2024
    this.getStandardViewFilterByFormPageId(this.PAGE_ID);

    // this.CostCodeGetData();
    this.showErrorCards = false;
    this.companyNameInvalid = false;

    this.WarrantyService.$isUserAppliedFilter.subscribe(filter => {
      if (filter) {
        this.isFilterApplied = filter;
      } else {
        this.isFilterApplied = filter;
      }
    });
  }




  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }


  Claim(select: string): void {
    this.isLoading = true;
    const jobId = this.localStorage.getJobId();
    if (jobId !== null) {
      const modalRef = this.modal.create({
        nzContent: WarrantyNewClaimComponent,
        nzData: {
          JOB_ID: jobId,
        },
        nzFooter: null
      });
      modalRef.componentInstance.Onselected_JobId = this.selectedJobId; // by Sufiyan
      modalRef.componentInstance.Onselected_JobName = this.selectedJobName; // by Sufiyan
      modalRef.componentInstance.cancel.subscribe(() => {
        modalRef.destroy();
      });
      modalRef.componentInstance.onSaveComplete.subscribe(() => {
        this.ProjectWarrantyGridData(1, this.PAGE_ID, this.defaultFilterIdSelected, this.currentPageActivityView, this.pageSizeActivityView);
      });
      modalRef.componentInstance.initializationComplete.subscribe((res) => {
        this.isLoading = res
      });
    } else {
      const modalRef = this.modal.create({
        nzWidth: '32%',
        nzStyle: {
          top: '36%',
        },
        nzContent: ProjectManagementSelectDialogBoxComponent,
        nzData: {
          type: select,
          leads: this.jobInfoOptions,
          JOB_NAME : this.selectedJobName
        },
        nzFooter: null
      });
      modalRef.componentInstance.argument = select;
      modalRef.componentInstance.cancel.subscribe(() => {
        modalRef.destroy();
      });
      modalRef.componentInstance.initializationComplete.subscribe((res) => {
        this.isLoading = res
      });
    }
    this.onAfterWarrntyReponse();
  }
  onAfterWarrntyReponse(){
    this.Warrantysubscription = this._BehaviorsubjectService.leadActivityTypeResponse$.subscribe(
      (response) => {
        if (response) {
          this.ProjectWarrantyGridData(1, this.PAGE_ID, this.defaultFilterIdSelected, this.currentPageActivityView, this.pageSizeActivityView);
        }
    });
  }


  reInitReset() {
    this.ngOnInit();
    this.selectedFilter = -1;
  }

  // getLeadData() {
  //   this.jobInfoService.getAllLeadData().subscribe(Data => {
  //     this.jobInfoOptions = Data.result;
  //   });
  // }

  ChangeorderListViewData: WarrantyInformationResponse[] = [];

  // Selected Job Id From Left Side Job List
  getSelectedJobId(item: number) {
    this.selectedJobId = item;
    this.ChangeorderListViewData = null;
    // this.getStandardFilterByFormPageId(this.PAGE_ID);
    this.getStandardFilterByFormPageId(this.PAGE_ID, this.selectionFrom); // New Changes By Aamir Ali - 22-Apr2024

    console.log("selectedJobId : ", this.selectedJobId);
  }

  onPageChange(page: number): void {
    this.currentPage = page;
    let filterId: number = 0;
    this.internalUserService.$selectedFilterId.subscribe(v => {
      if (v) {
        filterId = v;
      }
    });
    this.isLoading = true;
    setTimeout(() => {
      this.defaultFilterIdSelected = filterId;
      this.ProjectWarrantyGridData(1, this.PAGE_ID, filterId, page, this.pageSize);
      this.isLoading = false;

    }, 100);
  }

  onPageSizeChange(size: number): void {
    this.isColumnSticky = new Array(this.displayedColumns.length).fill(false);
    this.pageSize = size;
    let filterId: number = 0;
    this.internalUserService.$selectedFilterId.subscribe(v => {
      if (v) {
        filterId = v;
      }
    });
    this.isLoading = true;
    setTimeout(() => {
      this.defaultFilterIdSelected = filterId;
      this.ProjectWarrantyGridData(1, this.PAGE_ID, filterId, this.currentPage, this.pageSize);
      this.isLoading = false;

    }, 100);
  }

  onFilterChange(id: number) {
    this.isColumnSticky = new Array(this.displayedColumns.length).fill(false);

    this.selectedFilter = -1;
    this.displayedColumns = [];
    this.selected_View_Columns = [];
    let selectedView = this.getStandardViewFilterData.find(view => view.id === id).standardViewParameters;
    selectedView.forEach(viewItem => {
      if (viewItem.standardColumnSetup && viewItem.standardColumnSetup.displayColumnName) {
        this.selected_View_Columns.push(viewItem.standardColumnSetup.displayColumnName);
        this.displayedColumns = this.selected_View_Columns;

        const columnIndex = this.displayedColumns.indexOf(viewItem.standardColumnSetup.displayColumnName);
        if (columnIndex !== -1) {
          this.isColumnSticky[columnIndex] = viewItem.isPin;
          this.getColumnWidthSize[columnIndex] = viewItem.resizeColumnWidth;
        }
      }
    });
  }



  openStandardViewFilterComponent(isSaveAs?: string) {
    let isInputVisible: boolean = false;
    if (isSaveAs === 'saveAs') {
      isInputVisible = true;
    } else {
      isInputVisible = false;
    }
    this.selectedColNameSizePin = [];
    for (let i = 0; i < this.displayedColumns.length; i++) {
      const item = this.displayedColumns[i];
      const isPinValue = this.isColumnSticky[i];
      let widthSize = 0;
      if (this.columnSizes.length > 0) {
        for (let i = 0; i < this.columnSizes.length; i++) {
          const columnName = this.columnSizes[i].columnName;
          const columnNameSize = this.columnSizes[i].widthSize;
          if (columnName === item) {
            widthSize = columnNameSize;
            break;
          }
        }
      }

      if (widthSize === 0) {
        widthSize = 180;
      }

      this.selectedColNameSizePin.push({
        displayColumnName: item,
        resizeColumnWidth: widthSize, //  "200px", // Set default width here
        isPin: isPinValue == true ? isPinValue : false
      });
    }
    const modalRef = this.modal.create({
      nzContent: AddNewGridStandardViewComponent,
      nzData:
      {
        pageId: this.PAGE_ID,
        column: this.getStandardViewColumnFilterData,
        displayCol: this.displayedColumns,
        viewList: this.getStandardViewFilterData,
        selectedColNameSizePin: this.selectedColNameSizePin,
        selectedStandardView: this.selectedValueControl,
        fixedCol: this.fixedSelectedColumnNames,
        isSaveAs: isInputVisible
      },

    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }


  getStandardViewFilterByFormPageId(pageId: number) {
    if (this.WarrantystandardViewFilterSubscription) {
      this.WarrantystandardViewFilterSubscription.unsubscribe();
    }
    this.displayedColumns = [];
    this.fixedSelectedColumnNames = [];
    this.standardViewService.getStandardViewFilterByFormPageId(pageId);
    this.standardViewService.getStandardViewFilterColumnsByFormPageId(pageId);
    // this.standardViewService.$getStandardViewFilter.subscribe(result =>{
    this.WarrantystandardViewFilterSubscription = this.standardViewService.$getStandardViewFilter.subscribe(result => {

      if (result) {
        this.displayedColumns = [];
        this.selected_View_Columns = [];
        this.getStandardViewFilterData = result.result;
        let defaultView = this.getStandardViewFilterData.find(view => view.isSetAsDefault === true).standardViewParameters;
        this.selectedValueControl = this.getStandardViewFilterData.find(view => view.isSetAsDefault === true).id;
        defaultView.forEach(viewItem => {
          if (viewItem.standardColumnSetup && viewItem.standardColumnSetup.displayColumnName) {
            this.selected_View_Columns.push(viewItem.standardColumnSetup.displayColumnName);
          }
        });
        this.displayedColumns = this.selected_View_Columns;
      }
    });

    this.standardViewService.$getStandardViewColumn.subscribe(result => {
      if (result) {
        this.getStandardViewColumnFilterData = result.result;
        let fixedCol = this.getStandardViewColumnFilterData.filter(x => x.isSelected === true);
        fixedCol.forEach(x => {
          this.fixedSelectedColumnNames.push(x.displayColumnName);
        });

      }
    });
  }


  ProjectWarrantyGridData(companyId: number, formNameId: number, lookUpFilterId: number, page: number, pageSize: number,) {
    this.filterSearchParameter = {
      CompanyParameterId: companyId,
      FormNameId: formNameId,
      LookUpFilterId: lookUpFilterId,
      page: page,
      pageSize: pageSize,
      jobInformationId: this.selectedJobId
    };

    this.WarrantyService.getProjectWarranty(this.filterSearchParameter);
    this.Warrantysubscription = this.WarrantyService.$geProjecttWarrantyData.subscribe(result => {
      if (result) {
        this.totalRecords = result?.result?.rowCount;
        this.GetProjectWarrantyDatagriddata = result?.result?.rows;
        this.WarrantyInformationData = this.GetProjectWarrantyDatagriddata;
      }
    });
  }


  getStandardFilterByFormPageId(pageId: number, type: string) {// New Changes By Aamir Ali - 22-Apr2024
    if (this.WarrantyFilterSubscription) {
      this.WarrantyFilterSubscription.unsubscribe();
    }
    if (this.Warrantysubscription) {
      this.Warrantysubscription.unsubscribe();
    }
    this.LookupFilterService.getStandardFilterByFormPageId(pageId);
    this.WarrantyFilterSubscription = this.LookupFilterService.$getStandardFilter.subscribe(result => {
      if (result) {
        this.lookupFilterResponse = result.result;
        let defaultFilterId = result.result.find(filter => filter.isSetAsDefault === true).id;
        this.internalUserService.setSelectedFilterId(defaultFilterId);
        let id = -1;
        if (type === this.selectionFrom) {
          this.defaultFilterIdSelected = defaultFilterId;
          this.accessLocalStorageService.setSelectedFilterId(defaultFilterId);

          // New Changes By Aamir Ali - 22-Apr2024
          id = this.accessLocalStorageService.getSelectedFilterId();
        } else {
          id = this.accessLocalStorageService.getSelectedFilterId();
        }

        if (id !== -1) {
          this.ProjectWarrantyGridData(1, this.PAGE_ID, id, this.currentPage, this.pageSize);
        }

      }
    });
  }


  openFilter(component: string, pageId: number) {
    this.selectionFrom = ''; // New Changes By Aamir Ali - 22-Apr2024
    this.isColumnSticky = new Array(this.displayedColumns.length).fill(false);
    this.filterService.toggleFilter(component, pageId);
    this.internalUserService.userAppliedFilter(false);
  }



  saveEditStandardView() {
    this.standardViewService.$createStandardViewRequest.subscribe(formData => {
      if (formData && formData !== null) {
        this.standardViewService.postData(formData)
          .subscribe(
            (res: ResponseModelArray<StandardViewResponse>) => {
              setTimeout(() => {
                this.isLoading = true;
                this.toastService.success('Standard View Updated Successfully');
                this.standardViewService.getStandardViewFilterByFormPageId(this.PAGE_ID);
                this.selectedFilter = -1;
              }, 10);
            },
            (error) => {
              console.error('Error:', error);
              this.isLoading = false;
            }
          );
      }
    });
  }


  saveColumnSizes(event: any) {
    event.forEach(col => {
      const columnName = col.column.field;
      const widthSize = col.newWidth;
      const existingIndex = this.columnSizes.findIndex(item => item.columnName === columnName);

      if (existingIndex !== -1) {
        this.columnSizes[existingIndex].widthSize = widthSize;
      } else {
        this.columnSizes.push({ columnName, widthSize });
      }
    });

  }


  toggleColumnSticky(index: number) {
    this.isColumnSticky[index] = !this.isColumnSticky[index];
    if (this.isColumnSticky[index]) {
      for (let i = 0; i < index; i++) {
        this.isColumnSticky[i] = true;
      }
    }
  }

  currentPageActivityView: number = 1;






  // currentPageActivityView: number = 1;
  pageSizeActivityView: number = 100;
  FormEdit(Id: number): void {
    this.isLoading = true;
    const modalRef = this.modal.create({
      nzContent: WarrantyNewClaimComponent,
      nzFooter: null
    });
    modalRef.componentInstance.warrrantyId = Id;
    modalRef.componentInstance.cancel.subscribe(() => {

      modalRef.destroy();
    });
    modalRef.componentInstance.onSaveComplete.subscribe(() => {
      this.ProjectWarrantyGridData(1, this.PAGE_ID, this.defaultFilterIdSelected, this.currentPageActivityView, this.pageSizeActivityView);
    });

    this.WarrantyService.WarrantyDataresponses$.subscribe(
      (response) => {
        if (response?.result?.id) {
          this.ProjectWarrantyGridData(1, this.PAGE_ID, this.defaultFilterIdSelected, this.currentPageActivityView, this.pageSize);
        } else {
          console.error("No activity response ID found");
        }
      },
      (error) => {
        console.error("Error fetching lead activity type response:", error);

      }
    );
    modalRef.componentInstance.initializationComplete.subscribe((res) => {
      this.isLoading = res
    });
  }


  // exportToExcel(): void {
  //   const currentDate = new Date().toLocaleDateString();
  //   const headings = ['Title', 'Job ','Claim #', 'RFIs', 'Comments', ' Priority', 'Category', 'Added','Follow up','Class','Scheduling Information',
  //   'Feedback','Added Cost','Notes','Claim Status','Description','Original Schedule Item','Original Schedule Assignee','Files','Service Coordinator',];
  //   const dataToExport = [];
  //   dataToExport.push(['Exported on ' + currentDate]);
  //   dataToExport.push([]);
  //   dataToExport.push(headings);

  //   this.GetProjectWarrantyDatagriddata.forEach(row => {
  //     const rowData = [];
  //     headings.forEach(column => {
  //       switch (column) {
  //         case 'Title':
  //           rowData.push(`${row?.title} `);
  //           break;
  //         case 'Job':
  //           rowData.push(`${row?.jobInformationId} `);
  //           break;
  //         case 'Claim #':
  //           rowData.push(row?.claimAutoNumber );
  //           break;
  //         case 'RFIs':
  //           rowData.push(row?.rfiInformationId );
  //           break;
  //         case 'Comments':
  //           rowData.push(row?.internalComments );
  //           break;
  //         case 'Priority':
  //           rowData.push(row?.prioritySetupId ? 'TRUE' : 'FALSE');
  //           break;
  //         case 'Category':
  //           rowData.push(row?.warrantyCategorySetup?.name || '');
  //           break;
  //         case 'Added':
  //           rowData.push(row?.addedCost );
  //           break;
  //         case 'Follow up':
  //           rowData.push(row?.followUpOn );
  //           break;
  //         case 'Class':
  //           rowData.push(row?.classificationSetup?.name );
  //           break;
  //         case 'Scheduling Information':
  //           rowData.push(row?.followUpOn );
  //           break;
  //         case 'Feedback':
  //           rowData.push(row?.addedCost );
  //           break;
  //         case 'Added Cost':
  //           rowData.push(row?.internalComments );
  //           break;
  //         case 'Notes':
  //           rowData.push(row?.addedCost );
  //           break;
  //         case 'Description':
  //           rowData.push(row?.problemDescription );
  //           break;
  //         case 'Original Schedule Item':
  //           rowData.push(row?.problemDescription );
  //           break;
  //         case 'Original Schedule Assignee':
  //           rowData.push(row?.problemDescription );
  //           break;
  //         case 'Files':
  //           rowData.push(row?.attachment?.attachmentParameters );
  //           break;
  //         case 'Service Coordinator':
  //           rowData.push(row?.coordinatorUser?.firstName );
  //           break;
  //         case 'Claim Status':
  //           rowData.push(row?.claimNumber );
  //           break;
  //         default:
  //           rowData.push('');
  //           break;
  //       }
  //     });
  //     dataToExport.push(rowData);
  //   });

  //   const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(dataToExport);
  //   const wb: XLSX.WorkBook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  //   XLSX.writeFile(wb, 'Users.xlsx');
  // }

  ngOnDestroy() {
    this.LookupFilterService.getStandardFilter.next(null); // New Changes By Aamir Ali - 31-May-2024
    localStorage.removeItem('Selected_Filter_Id');
    if (this.WarrantyFilterSubscription) {
      this.WarrantyFilterSubscription.unsubscribe();
    }
    if (this.Warrantysubscription) {
      this.Warrantysubscription.unsubscribe();
    }
    if (this.WarrantystandardViewFilterSubscription) {
      this.WarrantystandardViewFilterSubscription.unsubscribe();
    }
    console.clear();
  }

  getTotalAddedCost(): number {
    return this.GetProjectWarrantyDatagriddata
      .filter(item => item.AddedCost) // Filter out undefined or null values
      .reduce((sum, item) => sum + (parseFloat(item.AddedCost!) || 0), 0); // Convert to number and calculate the sum
  }

  // Conversion Modal

  conversationModelOpen(response: WarrantyInformations): void {
    const modalRef = this.modal.create({
      nzContent: CommmentConversationComponent,
      nzTitle: '<h1 nz-typography>Conversation</h1>',
      nzWidth: '70%',
      nzBodyStyle: { background: '#f1f4fa', padding: '13px', 'max-height': '83vh', 'overflow-x': 'hidden' },
      nzStyle: { top: '20px' },
      nzFooter: null,
    });
    modalRef.componentInstance.takeResponseDynamic = response;
    modalRef.componentInstance.formNameId = 10002;
    modalRef.componentInstance.componentTypeDynamic = 'Warranty';
    modalRef.componentInstance.componentSelectedJobName = this.localStorage.getJobName();
    modalRef.componentInstance.componentTitleDynamic = response.Title;
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    modalRef.afterClose.subscribe(() => {
      modalRef.destroy();
      this.isFilterApplied = true;
      this.ProjectWarrantyGridData(1, this.PAGE_ID, this.defaultFilterIdSelected, this.currentPageActivityView, this.pageSizeActivityView);
    });
  }

  warrnty = warrntyStatusCode;
  warrntystatussetupColorMap = {
    [warrntyStatusCode.NeedsRework.code]: { backgroundColor: '#fbe9b1', textColor: '#653200' },
    [warrntyStatusCode.AwaitingFeedback.code]: { backgroundColor: '#c7d0d9', textColor: '#202227' },
    [warrntyStatusCode.ReadyNextPhase.code]: { backgroundColor: '#fff3cd', textColor: '#856404' },
    [warrntyStatusCode.RescheduleAppt.code]: { backgroundColor: '#f8d7da', textColor: '#721c24' },
  };

  EditRelatedRFIs(Id: number): void {
    this._LoadingIndicatorService.show();
    const modalRef = this.modal.create({
      nzWidth: '0%',
      nzContent: RfisEditCommonComponent,
      nzFooter: null,
      nzClosable: false,
      nzMask: true,
      nzMaskStyle: {
        display: 'none',
      },
    });
    modalRef.componentInstance.header_ID = Id;
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    modalRef.componentInstance.initializationComplete.subscribe((res) => {
      this._LoadingIndicatorService.hide();
    });
    this.onAfterReponse();
  }
  onAfterReponse(){
    this.Warrantysubscription = this._BehaviorsubjectService.leadActivityTypeResponse$.subscribe(
      (response) => {
        if (response) {
          this.ProjectWarrantyGridData(1, this.PAGE_ID, this.defaultFilterIdSelected, this.currentPageActivityView, this.pageSizeActivityView);
        }
    });
  }

}
