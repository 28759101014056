 <div class="row p-0 m-0">
  <h2 class="p-0 m-0" nz-typography>Select a Job</h2>
</div>
<form>
  <div class="row mt-4 mb-4">
    <div class="col-12">
      <label class="sub-text">Please Select a Job</label>
      <nz-select
        nzShowSearch
        nzAutoFocus="true"
        nzPlaceHolder="Select Lead"
        [(ngModel)]="selectedLeadId"
        name="selectedLeadId" (ngModelChange)="onLeadSelect(selectedLeadId)">
        <nz-option class="p-0 m-0"
          *ngFor="let option of jobInfoOptions"
          [nzLabel]="option.name" [nzValue]="option.id"></nz-option>
      </nz-select>
    </div>
  </div>



</form>


