import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, NonNullableFormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { LookupNameSetup, LookupNameSetupResponse } from 'src/Models/LeadTags';
import { TimeClockSService } from 'src/Service/_Time_Clock/time-clock-s.service';
import { LeadOpportunitiesService } from 'src/Service/lead-opportunities.service';

@Component({
  selector: 'app-warranty-new-claim-warranty-category',
  templateUrl: './warranty-new-claim-warranty-category.component.html',
  styleUrls: ['./warranty-new-claim-warranty-category.component.css']
})
export class WarrantyNewClaimWarrantyCategoryComponent {
  @Output() cancel = new EventEmitter<void>();
  @Output()  TagGroupOnCompleted = new EventEmitter();
  @Input() _OnWarranty_Id: number;
  WarrantyNzSelectForm: FormGroup;
  isVisibleInWarrantyCategory: boolean = true;
  _LookupNameSetupResponse: LookupNameSetupResponse[] = [];
  isSave: boolean = false;
  isDeleteLoading: boolean = false;
  DisabledNextActivity: boolean = false;
  TagGroupResponse: LookupNameSetupResponse;

  constructor(private fb: NonNullableFormBuilder,
    private _LeadOpportunitiesService: LeadOpportunitiesService,
    public toastService: NzMessageService,
    private modal: NzModalService,
    private _TimeClockSService: TimeClockSService,
  ) {}
  ngOnInit(): void {
    this.initializeForm();
    this.initLoad();
  }
  onCancel(): void {
    this.cancel.emit(); 
  }
  initializeForm() {
    this.WarrantyNzSelectForm = this.fb.group({
      id: [''],
      globalId: ['00000000-0000-0000-0000-000000000000'],
      name: ['', [Validators.required, Validators.maxLength(50)]],
      companyParameterId: [1],
      formNameId: 10002,
      lookUpStandardId: [89],
      parentCode: ['PMWCG'],
      code: ['PMWCG'],
      description: [''],
      isFieldValue: [false],
    });
  }

  initLoad(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this._TimeClockSService.ShowCode('PMWCG').subscribe(
        (res) => {
          this._LookupNameSetupResponse = res.result?.filter(item => item?.id === this._OnWarranty_Id);
          this.OnWarrantyPatch(this._LookupNameSetupResponse[0]);
          resolve();
        },
        (error) => {
          console.error('Error loading lookup values:', error);
          reject(error);
        }
      );
    });
  }
  OnWarrantyPatch(_LookupNameSetupResponse: LookupNameSetupResponse): void {
    if (_LookupNameSetupResponse?.id > 0) {
      this.WarrantyNzSelectForm.patchValue({
        name: _LookupNameSetupResponse?.name,
        globalId: _LookupNameSetupResponse?.globalId,
        id: _LookupNameSetupResponse?.id
      });
    } else {
      console.error('No matching data found for ID:', this._OnWarranty_Id);
    }
  }
  onInputChange(event: any) {
    const value = event.trim();
    const control = this.WarrantyNzSelectForm.get('name');
    
    control?.setValue(value, { emitEvent: false });
    if (value.length > 0) {
      control?.markAsTouched();
    } else {
      control?.markAsUntouched();
    }
  }
  onSaveButton(actionType: 'save' = 'save'): Promise<void> {
    let DataSet = this.WarrantyNzSelectForm.value;
  
    if (this.WarrantyNzSelectForm.invalid) {
      this.WarrantyNzSelectForm.markAllAsTouched();
      return Promise.reject('Form is invalid');
    }
  
    return new Promise((resolve, reject) => {
      if (this.isSave) {
        return reject('Already processing');
      }
  
      if (actionType === 'save') {
        this.isSave = true;
      }
      this.DisabledNextActivity = true;

      let name = this._LookupNameSetupResponse.map(nn => nn?.name);
      if (name.includes(this.WarrantyNzSelectForm.value.name)) {
        this.toastService.error("This Warranty Category exists.");
        this.DisabledNextActivity = false;
        this.isSave = false;
        reject('Duplicate job group');
        return;
      }

      this._LeadOpportunitiesService.getLookUpNameSetup(DataSet).subscribe(
        (response) => {
          this.TagGroupResponse = response.result;
          this.TagGroupOnCompleted.emit(this.TagGroupResponse);
          const tagName = this.WarrantyNzSelectForm.get('name')?.value;
          this.toastService.success(`${tagName} saved successfully`);
          this.cancel.emit();
          this.isVisibleInWarrantyCategory = false;
          resolve();
        },
        (error) => {
          console.error('Error saving data:', error);
          this.toastService.error('An error has occurred. Please try again.');
          this.DisabledNextActivity = false;
          reject(error);
        }
      ).add(() => {
        this.resetFlags();
      });
    });
  }
  resetFlags() {
    this.isSave = false;
  }
  deleteJobConfirm(): void {
    if (this.isDeleteLoading) {
      return;
    }
  
    this.modal.confirm({
      nzTitle: `Delete job group?`,
      nzContent: `This job group is not currently being used and will be deleted.`,
      nzOkText: `Delete`,
      nzOkDanger: true,
      nzCancelText: 'Cancel',
      nzOnOk: () => {
        this.isDeleteLoading = true;
        return this.deleteSubVendor(this._OnWarranty_Id)
          .then(() => {
            this.SubVendorNewLeadModelCancel();
          })
          .catch(() => {
  
          });
      },
      nzCentered: true,
      nzOkLoading: this.isDeleteLoading,
      nzBodyStyle: { 'border-radius': '50px', 'height': 'auto', 'padding-top': '15' },
      nzCloseIcon: '',
      nzIconType: ''
    });
  }
  deleteSubVendor(id: number): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!id) {
        this.toastService.error('No record selected for deletion.');
        this.isDeleteLoading = false;
        reject();
        return;
      }
      resolve();
      
      this._LeadOpportunitiesService.deleteDataLookupNameSetup(this.WarrantyNzSelectForm.value.globalId).subscribe(
        (res) => {
          this.TagGroupOnCompleted.emit(res?.result);
          this.onCancel();
          this.isDeleteLoading = false;
        },
        (error) => {
          this.isDeleteLoading = false;
          this.toastService.error('An error occurred while deleting the record. Please try again.');
          reject();
        }
      );
    });
  }
  SubVendorNewLeadModelCancel(): void {
    this.onCancel();
    this.isVisibleInWarrantyCategory = false;
    this._LeadOpportunitiesService.clearSelectedFiles();
  }
}
