import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { BehaviorSubject, Observable, Subject, catchError, tap, throwError } from 'rxjs';
import { scheduleItemByJob, ScheduleItemParameter, ScheduleItemParameterResponse } from 'src/Models/Project-Management/projectManagement';
import { ResponseModel, ResponseModelArray, TablePageResponse } from 'src/Models/responseMessage.model';
import { CreateLookupFilterRequest, FilterSearchParameter } from 'src/app/shared/component/Models/LookupModels';
import { ScheduleSetup, ScheduleSetupResponse } from 'src/Models/CompanySettings/Schedule/PhaseSetup/Company-Settings-Schedule';
import { CreateSchedulePostRequest, FormDataRemovePredecessor, ResponseScheduleItemResult, ScheduleGridResponse, ScheduleItemLinkDataResult, ScheduleLink, UpdateCompleteRequest } from 'src/Models/Project-Management/schedule';

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {

  private SCHEDULE_URL: string = environment.apiUrl + 'ScheduleItemParameter/';
  private SCHEDULE_DYNAMICS_URL: string = environment.apiUrl + 'ScheduleItem/';

  private Url_LookUpFilter: string = environment.apiUrl + 'LookupFilter/';
  private Company_Settings_Schedule_URL: string = environment.apiUrl + 'ScheduleSetup/';
  private Schdule_CheckBox_Circle: string = environment.apiUrl + '/Scheduleltem Parameter/Update-IsComplete/';


  getScheduleData = new BehaviorSubject<ResponseModel<TablePageResponse<ScheduleGridResponse>>>(null);
  $getScheduleItemParameters = this.getScheduleData.asObservable();

  getScheduleDataAppliedFilter = new BehaviorSubject<boolean>(false);
  $getScheduleDataAppliedFilter = this.getScheduleDataAppliedFilter.asObservable();

  getScheduleDataselectedFilterId = new BehaviorSubject<number>(0);
  $getScheduleDataselectedFilterId = this.getScheduleDataselectedFilterId.asObservable();

  //



  constructor(private http: HttpClient) { }

  postSchedule(scheduleParams: CreateSchedulePostRequest): Observable<ResponseModel<ResponseScheduleItemResult>> {
    const apiUrl = `${this.SCHEDULE_DYNAMICS_URL}`;
    return this.http.post<ResponseModel<ResponseScheduleItemResult>>(apiUrl, scheduleParams).pipe(
      catchError(this.handleError),
      tap(response => {
        console.log('Schedule Posted Successfully:');
      })
    );
  }

  scheduleuserAppliedFilter(filter: boolean) {
    this.getScheduleDataAppliedFilter.next(filter);
  }

  schedulesetSelectedFilterId(filterId: number) {
    this.getScheduleDataselectedFilterId.next(filterId);
  }




  /**
   * Centralized error handling for HTTP requests.
   * @param error The HTTP error response
   * @returns Throws a user-friendly error or the original error if unknown
   */
  private handleError(error: HttpErrorResponse): Observable<never> {
    let errorMessage: string;

    if (error.error instanceof ErrorEvent) {
      // Client-side or network error
      errorMessage = `Client-side error: ${error.error.message}`;
    } else {
      // Server-side error
      errorMessage = `Server returned code: ${error.status}, message: ${error.message}`;
    }

    // Log the full error details for debugging
    console.error('HTTP Error:', error);

    // Return an observable with a user-facing error message
    return throwError(() => new Error(errorMessage));
  }





  getData(): Observable<ResponseModelArray<ScheduleItemParameterResponse>> {
    return this.http.get<ResponseModelArray<ScheduleItemParameterResponse>>(`${this.SCHEDULE_URL}getAll`);
  }


  getAllScheduleDataListViewyFilter(filterSearchParameter: FilterSearchParameter) {
    const apiUrl = `${this.Url_LookUpFilter}GetAll-ByFilter`;
    return this.http.post<any>(apiUrl, filterSearchParameter).subscribe(
      (data) => {
        this.getScheduleData.next(data);
      },
      (error) => {
        console.error('Error fetching internal users:', error);
      }
    );
  }


  // Company Setting Methods

  postScheduleCompanySettings(jScheduleSetupFormData: ScheduleSetup): Observable<ResponseModel<ScheduleSetupResponse>> {
    const apiUrl = `${this.Company_Settings_Schedule_URL}`;
    return this.http.post<any>(apiUrl, jScheduleSetupFormData).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );

  }



  private Schedule_Setup_Fetch: string = environment.apiUrl + 'ScheduleSetup/getData-ByCompany/';

  getCompanySchedule(): Observable<ResponseModel<ScheduleSetupResponse>> {
    return this.http.get<ResponseModel<ScheduleSetupResponse>>(this.Schedule_Setup_Fetch);
  }


  getAllInternalUsersByFilter(filterSearchParameter: FilterSearchParameter) {
    const apiUrl = `${this.Url_LookUpFilter}GetAll-ByFilter`;
    return this.http.post<any>(apiUrl, filterSearchParameter).subscribe(
      (data) => {
        this.getScheduleData.next(data);
      },
      (error) => {
        console.error('Error fetching internal users:', error);
      }
    );
  }


  getAppliedFilterData(appliedFilter: CreateLookupFilterRequest[]) {
    const apiUrl = `${this.Url_LookUpFilter}GetAll-ByLookupFilter`;
    return this.http.post<any>(apiUrl, appliedFilter).subscribe(
      (data) => {
        this.getScheduleData.next(data);
      },
      (error) => {
        console.error('Error fetching internal users:', error);
      }
    );
  }
  private URL_SDUL_ITEM_BASE: string = environment.apiUrl + 'ScheduleItemParameter/getPredecessorBy-Job?JobInformationId=';

  getAllPredecessorsDataScheduleItem(id: number): Observable<ResponseModelArray<scheduleItemByJob>> {
    return this.http.get<ResponseModelArray<scheduleItemByJob>>(`${this.URL_SDUL_ITEM_BASE}${id}`);
  }


  deleteSchedule(globalId: string): Observable<ResponseModel<any>> {
    return this.http.delete<ResponseModel<any>>(`${this.SCHEDULE_URL}?globalId=${globalId}`);
  }

  getPredecessorLinkedData(scheduleItemParameterId: number): Observable<ResponseModel<ScheduleItemLinkDataResult[]>> {
    const endpoint = `${this.SCHEDULE_URL}GetPredecessorLinkedData-ScheduleId?scheduleItemParameterId=${scheduleItemParameterId}`;
    return this.http.get<ResponseModel<ScheduleItemLinkDataResult[]>>(endpoint);
  }


  // POST method for Update-IsComplete
  updateIsComplete(requestBody: any): Observable<any> {
    const endpoint = `${this.SCHEDULE_URL}Update-IsComplete`;
    return this.http.post(endpoint, requestBody);
  }



  scheduleGetById(id: number) {
    return this.http.get<ResponseModel<ResponseScheduleItemResult>>(`${this.SCHEDULE_URL}getBy-Id?id=${id}`);
  }

  // ScheduleItemParameter/Move-Predecessor

  scheduleItemParameterMovePredecessor(requestBody: ScheduleLink): Observable<any> {
    const endpoint = `${this.SCHEDULE_URL}Move-Predecessor`;
    return this.http.post(endpoint, requestBody);
  }

  scheduleItemParameterRemovePredecessor(requestBody: { globalId: string; formType: string }[]): Observable<any> {
    const endpoint = `${this.SCHEDULE_URL}Remove-Predecessor`;
    return this.http.post(endpoint, requestBody); // Send the array directly
  }


}
