import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, Renderer2, SimpleChanges } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { PercentageMarkupComponent } from '../lead-opportunities/Components/new-proposal/percentage-markup/percentage-markup.component';
import { CompanyParameterChild, CostItemParameterRequest, CreateCostItem } from 'src/Models/costitem/costitem.modal';
import { CostItemService } from 'src/Service/Cost_Item/cost-item.service';

interface ItemData {
  categoryId?: number;
  category?: string;
  globalId: string;
  isNew: boolean;
  id: number;
  title: string;
  description: string;
  quantity: string;
  unit: string;
  unitCost: string;
  costTypeId: number;
  builderCost: string;
  markupValue: string;
  markupId: number;
  ownerPrice: string;
  margin: string;
  profit: string;
  costCodeId: number;
  markAsId: number;
  internalNotes?: string;
  includeQuantity?: boolean;
  includeOwnerPrice?: boolean;
  isCatalog?: boolean;
  isTitleAndCostCodeReadOnly?: boolean;
}




interface MarkAsClass {
  id: number;
  name: string;
}

@Component({
  selector: 'cost-item-common',
  templateUrl: './cost-item-common.component.html',
  styleUrls: ['./cost-item-common.component.css']
})
export class CostItemCommonComponent implements OnInit, OnDestroy, OnChanges {

  i = 0;
  editId: number | null = null;
  listOfData: ItemData[] = [];
  setOfCheckedId = new Set<number>();
  checked = false;
  indeterminate = false;
  confirmModal?: NzModalRef;
  private outsideClickListener: () => void;

  markAsMap: MarkAsClass[] = [
    { id: 1, name: 'Bid' },
    { id: 2, name: 'Allowance' },
  ];

  costType: MarkAsClass[] = [
    { id: 0, name: 'None' },
    { id: 1, name: 'Labor' },
    { id: 2, name: 'Material' },
    { id: 3, name: 'Equipment' },
    { id: 4, name: 'Subcontractor' },
    { id: 5, name: 'Other' },

  ];

  costCode: MarkAsClass[] = [
    { id: 1, name: 'Cost' },
    { id: 2, name: 'Type' },
    { id: 3, name: 'Equipment' },
    { id: 4, name: 'Subcontractor' },
    { id: 5, name: 'Other' },

  ];

  totalBuilderCost: string = '0.00';
  totalOwnerPrice: string = '0.00';
  totalProfit: string = '0.00';
  totalMargin: string = '0.00';
  totalMarkup: string = '0.00';



  constructor(
    private modal: NzModalService,
    private _toastMessageService: NzMessageService,
    private renderer: Renderer2, private elementRef: ElementRef,
    private _costItemService: CostItemService
  ) {

    this.outsideClickListener = this.renderer.listen('document', 'click', this.onClickOutside.bind(this));
  }


  ngOnDestroy() {
    // Clean up the listener when the component is destroyed
    if (this.outsideClickListener) {
      this.outsideClickListener();
    }
  }


  private onClickOutside(event: MouseEvent) {
    const clickedInside = this.elementRef.nativeElement.contains(event.target);
    const isDropdown = event.target instanceof HTMLElement && event.target.closest('.ant-select-dropdown');

    if (!clickedInside && !isDropdown && this.editId !== null) {
      const currentRow = this.listOfData.find(row => row.id === this.editId);
      if (currentRow) {
        this.stopEdit(this.editId, currentRow);
      }
    }
  }


  ngOnInit(): void {

  }


  addRow(customData?: Partial<ItemData>): void {
    this.listOfData.forEach(row => this.validateRow(row));
    const hasErrors = this.listOfData.some(row => this.hasRowError(row.id));
    if (hasErrors) {
      return; // Prevent adding a new row if there are errors
    }

    // Create a new row with a valid initial quantity as a number
    const newRow: ItemData = {
      globalId: '00000000-0000-0000-0000-000000000000',
      id: this.i++,
      title: '',
      description: '',
      quantity: '1.0000', // Ensure it's a number
      unit: '',
      unitCost: '0.0000',
      costTypeId: 0,
      builderCost: '0.00',
      markupValue: '0.00',
      markupId: 1,
      ownerPrice: '0.00',
      margin: '0.00',
      profit: '0.00',
      costCodeId: 0,
      markAsId: 1,
      ...customData,
      isNew: true,
      isTitleAndCostCodeReadOnly: false
    };

    this.listOfData.unshift(newRow);
    this.startEdit(newRow.id, newRow); // Open edit mode for the new row
    this.refreshCheckedStatus(); // Update checkbox status
    this.calculateTotals();
  }


  // Method to check if a row has validation errors
  hasRowError(rowId: number): boolean {
    return !!this.validationErrors[rowId] && Object.keys(this.validationErrors[rowId]).length > 0;
  }



  startEdit(id: number, customData: ItemData): void {
    // Check if there are any validation errors in existing rows
    const hasErrors = Object.keys(this.validationErrors).some(
      rowId => Object.keys(this.validationErrors[rowId]).length > 0
    );

    // If any row has validation errors, do not start editing
    if (hasErrors) {
      return;
    }

    // Stop editing the currently edited row if there is one
    if (this.editId !== null) {
      const previousRow = this.listOfData.find(row => row.id === this.editId);
      if (previousRow) {
        this.stopEdit(this.editId, previousRow);
      }
    }

    // Set the current row to edit mode if no validation errors exist
    this.editId = id;
    customData.isNew = true;
    customData.isTitleAndCostCodeReadOnly = customData.costCodeId > 0;
  }



  stopEdit(id: number, customData: ItemData): void {
    // Validate the row immediately before attempting to stop edit mode
    this.validateRow(customData);

    // Check if there are any errors for this row
    if (this.hasRowErrors(customData.id)) {
      // If there are errors, do not stop editing
      return;
    }

    // If no errors, proceed to stop editing
    if (this.editId === id) {
      this.editId = null; // Clear the edit ID
      customData.isNew = false; // Mark the row as no longer new
    }

    customData.isTitleAndCostCodeReadOnly = customData.costCodeId > 0;
  }


  onCostCodeChange(value: any, data: any): void {
    data.costCodeId = value;
  }



  isFieldEditable(data: ItemData, fieldName: string): boolean {
    if (fieldName === 'title' || fieldName === 'costCodeId') {
      return data.costCodeId <= 0 && this.editId === data.id;
    }
    return this.editId === data.id;
  }

  // Error Methods Start
  validationErrors: { [key: number]: { [field: string]: string } } = {};


  validateRow(row: ItemData): void {
    const errors: { [field: string]: string } = {};

    // Required field validation
    if (!row.costCodeId || row.costCodeId === 0) {
      errors['costCodeId'] = 'Required.';
    }

    // Character length validation for title
    const titleExcess = row.title.length - 150;
    if (titleExcess > 0) {
      errors['title'] = titleExcess === 1 ? '1 character over.' : `${titleExcess} characters over.`;
    }

    const unitExcess = row.unit.length - 8;
    if (unitExcess > 0) {
      errors['unit'] = unitExcess === 1 ? '1 character over.' : `${unitExcess} characters over.`;
    }

    // Character length validation for description
    const descriptionExcess = row.description.length - 2500;
    if (descriptionExcess > 0) {
      errors['description'] = descriptionExcess === 1 ? '1 character over.' : `${descriptionExcess} characters over.`;
    }

    // Quantity validation (max 20 characters)
    if (row.quantity && row.quantity.toString().length > 15) {
      errors['quantity'] = 'Quantity must be 14 characters or less.';
    }
    // Store errors for the row if any validation errors exist
    if (Object.keys(errors).length > 0) {
      this.validationErrors[row.id] = errors;
    } else {
      delete this.validationErrors[row.id]; // Clear errors if validation passes
    }
  }



  getValidationStatus(rowId: number, field: string): string {
    const errors = this.validationErrors[rowId] || {};
    if (errors[field]) {
      return 'error';
    }
    return 'valid';
  }

  getErrorMessage(rowId: number, field: string): string {
    const errors = this.validationErrors[rowId] || {};
    if (errors[field]) {
      return errors[field];
    }
    return '';
  }

  hasRowErrors(rowId: number): boolean {
    return !!this.validationErrors[rowId];
  }

  // Error Methods End




  // mark as field and costType value shows Start
  getMarkAsLabel(markAsId: number): string {
    const markAs = this.markAsMap.find(item => item.id === markAsId);
    return markAs ? markAs.name : 'Unknown';
  }

  getCostTypeLabel(costTypeId: number): string {
    const costType = this.costType.find(item => item.id === costTypeId);
    return costType ? costType.name : '--';
  }

  getCostCodeLabel(costCodeId: number): string {
    const costCode = this.costCode.find(item => item.id === costCodeId);
    return costCode ? costCode.name : '--';
  }
  // mark as field value shows End



  // Markup Drop down Change -- option selected markup field disable and Margin Enable

  isDisabledMarkup(markupId: number): boolean {
    return markupId === 4;
  }



  // CheckBox Methods Table Start

  onItemChecked(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
    this.refreshCheckedStatus();
  }

  onAllChecked(checked: boolean): void {
    if (checked) {
      this.listOfData.forEach(data => this.setOfCheckedId.add(data.id));
    } else {
      this.setOfCheckedId.clear();
    }
    this.refreshCheckedStatus();
  }

  refreshCheckedStatus(): void {
    this.checked = this.listOfData.length > 0 && this.listOfData.every(data => this.setOfCheckedId.has(data.id));
    this.indeterminate = this.listOfData.some(data => this.setOfCheckedId.has(data.id)) && !this.checked;
  }

  // CheckBox Methods Table End



  // Single Cost Item Delete Start

  ConfirmDeleteItem(itemId: number): void {
    this.confirmModal = this.modal.confirm({
      nzTitle: 'Delete Item?',
      nzContent: 'Are you sure you want to delete the selected Lead Proposal item?',
      nzOkDanger: true,
      nzClosable: false,
      nzCentered: true,
      nzWidth: '30%',
      nzIconType: ' ',
      nzOkText: 'Delete',
      nzOnOk: () => this.deleteItem(itemId)
    });
  }




  deleteItem(itemId: number): void {
    this.listOfData = this.listOfData.filter(item => item.id !== itemId);
    this.setOfCheckedId.delete(itemId);
    delete this.validationErrors[itemId];
    this._toastMessageService.success('Item Deleted');
    this.refreshCheckedStatus();
    this.calculateTotals();
  }


  // Single Cost Item Delete End




  // delete Item Cost by checkbox Start


  ConfirmDeleteItemByChecked(): void {
    this.confirmModal = this.modal.confirm({
      nzTitle: 'Delete Selected Items?',
      nzContent: 'Are you sure you want to delete the selected items?',
      nzOkDanger: true,
      nzClosable: false,
      nzCentered: true,
      nzWidth: '30%',
      nzIconType: ' ',
      nzOkText: 'Delete',
      nzOnOk: () => this.deleteItemsByChecked(),
    });
  }

  // Delete all checked items


  deleteItemsByChecked(): void {
    this.listOfData = this.listOfData.filter(item => {
      const shouldDelete = this.setOfCheckedId.has(item.id);
      if (shouldDelete) {
        delete this.validationErrors[item.id];
      }
      return !shouldDelete;
    });

    this.setOfCheckedId.clear();
    this._toastMessageService.success('Item Deleted');
    this.refreshCheckedStatus();
    this.calculateTotals();
  }

  // delete Item Cost by checkbox End




  onQuantityChange(row: ItemData): void {

    if (!row.quantity || isNaN(parseFloat(row.quantity))) {
      row.quantity = '0.0000';
    } else {
      row.quantity = this.formatQuantity(row.quantity);
    }

    if (!this.isValidQuantity(row.quantity)) {
      row.quantity = this.getPreviousValidQuantity(row.id);
    }

    this.validateRow(row);
    this.calculateTotals();
  }

  formatQuantity(quantity: string): string {
    const quantityNum = parseFloat(quantity);
    return quantityNum.toFixed(4);
  }

  isValidQuantity(quantity: string): boolean {
    const integerPart = quantity.split('.')[0];
    return integerPart.length <= 15;
  }

  getPreviousValidQuantity(id: number): string {
    const row = this.listOfData.find(item => item.id === id);
    return row ? row.quantity : '1.0000';
  }



  // Cost Item table Formula Methods Start

  // Method to calculate builderCost
  calculateBuilderCost(data: ItemData): void {
    const quantity = parseFloat(data.quantity) || 0;
    const unitCost = parseFloat(data.unitCost) || 0;
    data.builderCost = (quantity * unitCost).toFixed(2); // Convert to string with two decimal places
  }

  // Method to calculate ownerPrice
  calculateOwnerPrice(data: ItemData): void {
    const builderCost = parseFloat(data.builderCost) || 0;
    const markup = parseFloat(data.markupValue) || 0;
    data.ownerPrice = (builderCost + markup).toFixed(2); // Convert to string with two decimal places
  }

  // Method to calculate margin
  calculateMargin(data: ItemData): void {
    const builderCost = parseFloat(data.builderCost) || 0;
    const ownerPrice = parseFloat(data.ownerPrice) || 0;

    if (ownerPrice !== 0) {
      data.margin = (((ownerPrice - builderCost) / ownerPrice) * 100).toFixed(2); // Convert to string with two decimal places
    } else {
      data.margin = '0.00';
    }
  }

  // Method to calculate profit
  calculateProfit(data: ItemData): void {
    const builderCost = parseFloat(data.builderCost) || 0;
    const ownerPrice = parseFloat(data.ownerPrice) || 0;
    data.profit = (ownerPrice - builderCost).toFixed(2);

  }

  // Central method to update all calculated fields
  updateCostItem(data: ItemData): void {
    this.calculateBuilderCost(data);
    this.calculateOwnerPrice(data);
    this.calculateMargin(data);
    this.calculateProfit(data);
    this.calculateTotals();


  }

  // Method to handle on change events
  onFieldChange(data: ItemData): void {
    this.updateCostItem(data);
    this.calculateTotals();
  }

  // Cost Item table Formula Methods End

  // Markup values
  markupValues: MarkAsClass[] = [
    { id: 1, name: '%' },
    { id: 2, name: '$' },
    { id: 3, name: '$/Unit' },
    { id: 4, name: '--' },
  ];


  calculateMarkup(data: ItemData): void {

    const builderCost = parseFloat(data.builderCost) || 0;
    const ownerPrice = parseFloat(data.ownerPrice) || 0;
    const unitCost = parseFloat(data.unitCost) || 0;

    switch (data.markupId) {
      case 1:
        data.markupValue = (parseFloat(data.markupValue) || 0).toFixed(2);
        break;
      case 2: // markupValue in dollar amount (based on builder cost)
        data.markupValue = (builderCost * (parseFloat(data.markupValue) / 100 || 0)).toFixed(2);
        break;
      case 3: // markupValue in $ per unit cost
        data.markupValue = (unitCost * (parseFloat(data.markupValue) / 100 || 0)).toFixed(2); // Dollar per unit calculation
        break;
      default:
        data.markupValue = '0.00'; // Default to 0 if no valid markupId
        break;
    }

    // Optional: Add logging for debugging

    this.onFieldChange(data); // Trigger recalculation of other fields (e.g., OwnerPrice, Margin, etc.)
  }

  // Method to handle on change markup dropdown
  onChangeMarkupDropdown(data: ItemData, id: number): void {
    data.markupId = id; // Update the selected markup type
    this.calculateMarkup(data); // Recalculate the markup based on the selected type
  }



  // Method to calculate totals for Builder Cost, Owner Price, and Profit
  calculateTotals(): void {
    // Reset totals
    this.totalBuilderCost = '0.00';
    this.totalOwnerPrice = '0.00';
    this.totalProfit = '0.00';
    this.totalMargin = '0.00';
    this.totalMarkup = '0.00';



    // Calculate totals by iterating through the data
    this.listOfData.forEach(row => {
      // Ensure that the required fields are parsed as numbers and handle possible NaN
      const builderCost = parseFloat(row.builderCost) || 0;
      const ownerPrice = parseFloat(row.ownerPrice) || 0;
      const profit = parseFloat(row.profit) || 0;
      const margin = parseFloat(row.margin) || 0;
      const markup = parseFloat(row.markupValue) || 0;



      this.totalBuilderCost = (parseFloat(this.totalBuilderCost) + builderCost).toFixed(2);
      this.totalOwnerPrice = (parseFloat(this.totalOwnerPrice) + ownerPrice).toFixed(2);
      this.totalProfit = (parseFloat(this.totalProfit) + profit).toFixed(2);
      this.totalMargin = (parseFloat(this.totalMargin) + margin).toFixed(2);
      this.totalMarkup = (parseFloat(this.totalMarkup) + markup).toFixed(2);


    });
  }


  // Save Cost Item

  @Input() companyParameter: CompanyParameterChild;
  @Input() costItemId: number;


  ngOnChanges(changes: SimpleChanges): void {
    if (changes['companyParameter'] && this.companyParameter) {
    }

    if (changes['costItemId'] && changes['costItemId'].currentValue !== changes['costItemId'].previousValue) {
      // Check if costItemId is greater than 0 before calling CostItemsFetch
      if (this.costItemId > 0) {
        this.CostItemsFetch(this.costItemId);
      }
    }
  }

  createCostItemFromData(data: any[]): CreateCostItem {
    const createCostItemParameterRequests = data.map(item =>
      new CostItemParameterRequest(
        item.globalId,
        item.title,
        item.description,
        item.internalNotes,
        item.quantity,
        item.unit,
        item.unitCost,
        item.builderCost,
        item.markupValue,
        item.markupId,
        item.ownerPrice,
        item.margin,
        item.profit,
        item.costCodeId,
        item.costTypeId,
        item.markAsId,
        item.includeQuantity,
        item.includeOwnerPrice,
        item.isCatalog,
        item.isTitleAndCostCodeReadOnly,
        item.isNew
      )
    );

    return new CreateCostItem(
      this.companyParameter.companyParameterId, // Accessing companyParameterId from the object
      this.companyParameter.formNameId, // formNameId
      this.companyParameter.headerId, // headerId
      this.companyParameter.isFlatFee, // isFlatFee
      this.companyParameter.isLineItems, // isLineItems
      this.companyParameter.builderCost, // builderCost (string)
      this.companyParameter.ownerPrice, // ownerPrice (string)
      this.companyParameter.isSetPriceLater, // isSetPriceLater
      createCostItemParameterRequests
    );

  }


  saveCostItem(): Promise<void> {
    return new Promise((resolve, reject) => {
      const createCostItem = this.createCostItemFromData(this.listOfData);
      this._costItemService.postCommonCostItem(createCostItem).subscribe({
        next: (response) => {
          resolve(); // Resolve on success
        },
        error: (error) => {
          console.error('Error saving cost item:', error);
          reject(error); // Reject on error
        },
      });
    });
  }



  CostItemsFetch(costItemId: number): void {
    this._costItemService.getDataIdCostItemCommon(costItemId).subscribe(
      (response) => {
        const data = response?.result;

        if (data) {
          // Set company parameter data if it exists
          this.companyParameter = {
            companyParameterId: data.companyParameterId,
            formNameId: data.formNameId,
            headerId: data.id,
            isFlatFee: data.isFlatFee,
            isLineItems: data.isLineItems,
            builderCost: data.builderCost,
            ownerPrice: data.ownerPrice,
            isSetPriceLater: data.isSetPriceLater
          };

          // Assign cost item parameters to listOfData for grid
          this.listOfData = data.costItemParameters.map(item => ({
            globalId: item.globalId,
            title: item.title,
            description: item.description,
            internalNotes: item.internalNotes,
            quantity: item.quantity,
            unit: item.unit,
            unitCost: item.unitCost,
            builderCost: item.builderCost,
            markupValue: item.markupValue,
            markupId: item.markupId,
            ownerPrice: item.ownerPrice,
            margin: item.margin,
            profit: item.profit,
            costCodeId: item.costCodeId,
            costTypeId: item.costTypeId,
            markAsId: item.markAsId,
            includeQuantity: item.includeQuantity,
            includeOwnerPrice: item.includeOwnerPrice,
            isCatalog: item.isCatalog,
            isTitleAndCostCodeReadOnly: item.costCodeId > 0,
            isNew: item.isNew,
            id: item.id,
          }));


          this.calculateTotals();
          const createCostItem = this.createCostItemFromData(this.listOfData);

          // Perform any additional calculations or updates as needed
          this.calculateTotals();
        } else {
          // this._toastMessageService.error('Cost item not found');
        }
      },
      (error) => {
        // this._toastMessageService.error('Failed to load cost item');
        console.error(error);
      }
    );
  }



  onAdjustMarkup(): void {
    const modalRef = this.modal.create({
      nzContent: PercentageMarkupComponent,
      nzTitle: null,
      nzFooter: null,
    });

    // Listen for the percentage value when the user applies it
    modalRef.componentInstance.apply.subscribe((percentage: number) => {
      modalRef.destroy();
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }



}
