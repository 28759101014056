import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LeadActivityResponse } from 'src/Models/LeadActivity/leadActivityClass';
import { LeadActivityTemplateResponse, LeadActivityTemplateResponses } from 'src/Models/LeadActivityTemplate/LeadActivityTemplate';
import { LeadActivityTemplateResponseMetaData, LeadProposalsMetaDataResponse } from 'src/Models/LeadTags';
import { ResponseModel, TablePageResponse } from 'src/Models/responseMessage.model';
import { FilterSearchParameter, CreateLookupFilterRequest } from 'src/app/shared/component/Models/LookupModels';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class LeadActivityTemplatesService {
  private base_Url: string = environment.apiUrl + 'LookupFilter/';

  constructor(private http: HttpClient) { }

  getRLeadUserData = new BehaviorSubject<ResponseModel<TablePageResponse<LeadActivityTemplateResponseMetaData>>>(null);
  $getsctivityUsers = this.getRLeadUserData.asObservable();

  LeadActivitySelectedFilterId = new BehaviorSubject<number>(0);
  $LeadSelectedFilterId = this.LeadActivitySelectedFilterId.asObservable();

  LeadActivityIsUserAppliedFilter = new BehaviorSubject<boolean>(false);
  $LeadIsUserAppliedFilter = this.LeadActivityIsUserAppliedFilter.asObservable();
  getLeadActivity(filterSearchParameter: FilterSearchParameter) {
    const apiUrl = `${this.base_Url}GetAll-ByFilter`;
    return this.http.post<any>(apiUrl, filterSearchParameter).subscribe(
      (data) => {
        this.getRLeadUserData.next(data);
      },
      (error) => {
        console.error('Error fetching internal users:', error);
      }
    );
  }
  getAppliedFilterDataLeadActivity(appliedFilter: CreateLookupFilterRequest[]) {
    const apiUrl = `${this.base_Url}GetAll-ByLookupFilter`;
    return this.http.post<any>(apiUrl, appliedFilter).subscribe(
      (data) => {
        this.getRLeadUserData.next(data);
      },
      (error) => {
        console.error('Error fetching internal users:', error);
      }
    );
  }
  LeadActivitysetSelectedFilterId(filterId: number) {
    this.LeadActivitySelectedFilterId.next(filterId);
  }
  LeadActivityuserAppliedFilter(filter: boolean) {
    this.LeadActivityIsUserAppliedFilter.next(filter);
  }

  //CREATED BY MEHMOOD GRID GET BY ID ACTIVITY TEMPLATE

  private baseUrl: string = environment.apiUrl + 'LeadActivityTemplate/';
  private Activity_baseUrl: string = environment.apiUrl + 'LeadActivity/';


  getLeadTemplateById(Id: number): Observable<ResponseModel<LeadActivityTemplateResponses>> {
    return this.http.get<ResponseModel<LeadActivityTemplateResponses>>(
      `${this.baseUrl}getBy-Id?id=${Id}`
    );
  }
  getTemplateId(LeadActivityTemplateId: number): Observable<ResponseModel<LeadActivityResponse[]>> {
    return this.http.get<ResponseModel<LeadActivityResponse[]>>(
      `${this.Activity_baseUrl}getAll-ByTemplateId?LeadActivityTemplateId=${LeadActivityTemplateId}`
    );
  }





}
