import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './Core/guard/auth.guard';
import { MainLayoutComponent } from './Layouts/main-layout/main-layout.component';
import { AuthLayoutComponent } from './Layouts/auth-layout/auth-layout.component';
import { CustomerContactsDashboardComponent } from './customer-infromation/Components/customer-contacts-dashboard/customer-contacts-dashboard.component';
import { SalesreportDetailComponent } from './reports/components/reports/salesreport-detail/salesreport-detail.component';
import { ReportsComponent } from './reports/components/reports/reports.component';
import { ProjectManagmentDetailComponent } from './reports/components/reports/project-managment-detail/project-managment-detail.component';
import { FinancialReportsDetailComponent } from './reports/components/reports/financial-reports-detail/financial-reports-detail.component';
import { CostItemCommonComponent } from './cost-item-common/cost-item-common.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'Jobs',
    pathMatch : 'full'
  },
  {
    path: 'Authentication',
    component: AuthLayoutComponent,
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'app/Settings',
    canActivate: [AuthGuard],
    component: MainLayoutComponent,
    loadChildren: () =>
      import('./company-settings/company-settings.module').then(
        (m) => m.CompanySettingsModule
      ),
  },
  {
    path: 'Sales',
    canActivate: [AuthGuard],
    component: MainLayoutComponent,
    loadChildren: () =>
      import('./lead-opportunities/lead-opportunities.module').then(
        (m) => m.LeadOpportunitiesModule
      ),
  },
  {
    path: 'Project-Management',
    canActivate: [AuthGuard],
    component: MainLayoutComponent,
    loadChildren: () =>
      import('./project-management/project-management.module').then(
        (m) => m.ProjectManagementModule
      ),
  },
  {
    path: 'Messaging',
    canActivate: [AuthGuard],
    component: MainLayoutComponent,
    loadChildren: () =>
      import('./messaging/messaging.module').then(
        (m) => m.MessagingModule
      ),
  },
  {
    path: 'Financial',
    canActivate: [AuthGuard],
    component: MainLayoutComponent,
    loadChildren: () =>
      import('./financial/financial.module').then(
        (m) => m.FinancialModule
      ),
  },
  {
    path: 'Files',
    canActivate: [AuthGuard],
    component: MainLayoutComponent,
    loadChildren: () =>
      import('./files/files.module').then(
        (m) => m.FilesModule
      ),
  },

  // {
  //   path: 'Customer',
  //   canActivate: [AuthGuard],
  //   component: MainLayoutComponent,
  //   loadChildren: () =>
  //     import('./customer-infromation/customer-infromation.module').then(
  //       (m) => m.CustomerInfromationModule
  //     ),
  // },
  {
    path: 'Jobs',
    canActivate: [AuthGuard],
    component: MainLayoutComponent,
    loadChildren: () =>
      import('./jobs-management/jobs-management.module').then(
        (m) => m.JobsManagementModule)
  },

  {
    path: 'internal-user',
    canActivate: [AuthGuard],
    component: MainLayoutComponent,
    loadChildren: () =>
      import('./internal-user/internal-user.module').then(
        (m) => m.InternalUserModule)
      },

  { path: 'Customer-Information', component: CustomerContactsDashboardComponent },
  { path: 'Users/SubsList', component: MainLayoutComponent,loadChildren: () => import('./subs-vendors-information/subs-vendors-information.module').then((m) => m.SubsVendorsInformationModule), },
  { path: 'cost-item', component: CostItemCommonComponent },

  // {
  //   path: 'reports',
  //   canActivate: [AuthGuard],
  //   component: MainLayoutComponent,
  //   children: [
  //     {
  //       path: '',
  //       component: ReportsComponent,
  //     },
  //     {
  //       path: 'salesDetailReports',
  //       component: SalesreportDetailComponent,
  //     },
  //     {
  //       path: 'ProjectManagmentReports',
  //       component: ProjectManagmentDetailComponent,
  //     },
  //     {
  //       path: 'FinancialReports',
  //       component: FinancialReportsDetailComponent,
  //     },
  //   ],
  // },
  { path: '**', redirectTo: 'reports' },
  {
    path: 'CostCodes',
    canActivate: [AuthGuard],
    component: MainLayoutComponent,
    loadChildren: () =>
      import('./company-settings/company-settings-routing.module').then(
        (m) => m.CompanySettingsRoutingModule)
  },
]
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
