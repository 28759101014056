import { Component, EventEmitter, Output } from '@angular/core';
import { NzStatus } from 'ng-zorro-antd/core/types';
import { NzMessageService } from 'ng-zorro-antd/message';
import { JobInformationResponse } from 'src/Models/Job-Scratch/JobFromScratch';
import { JobInformationService } from 'src/Service/Job-List/Job-Information/job-information.service';

@Component({
  selector: 'app-import-selection',
  templateUrl: './import-selection.component.html',
  styleUrls: ['./import-selection.component.css']
})
export class ImportSelectionComponent {
  @Output() cancel = new EventEmitter<void>();
  selectedValue = null;
  importDataShowDate=true;
  importShowSelectFeild=false;
  importShowSurveysFeild=false;
  importShowDocumentFeild=false;
  importShowVideoFeild=false;
  importShowEstimateFeild=false;
  importShowPurchaseFeild=false;
  importShowBidownerFeild=false;
  selectionModelhandle=true; //cancel property import-selection
  date = null;

  ngOnInit() {
    this.getAllJobTemplateData();
  }
  constructor(private jobInformationService: JobInformationService,


  ){

  }

  dateShow(){
    this.importDataShowDate = this.checked;
  }
  onChange(result: Date): void {
    console.log('onChange: ', result);
  }
  showSelectFeild(){
    this.importShowSelectFeild= !this.importShowSelectFeild;
  }
  showBidOwnerFeild(){
    this.importShowBidownerFeild= !this.importShowBidownerFeild;
  }
  showDocumentFeild(){
    this.importShowDocumentFeild = !this.importShowDocumentFeild;
  }
  showserveyFeild(){
    this.importShowSurveysFeild = !this.importShowSurveysFeild;
  }
  showVideoFeild(){
    this.importShowVideoFeild =!this.importShowVideoFeild;
  }
  showEstimateFeild(){
    this.importShowEstimateFeild =!this.importShowEstimateFeild;
  }
  showPurchaseFeild(){
    this.importShowPurchaseFeild =!this.importShowPurchaseFeild;
  }

  importSelectionCancelEvent(): void {
  this.cancel.emit();
  }

  checked = true;
  showchecked = true;

  sourceTemplateOptions:JobInformationResponse[] = [];
  getAllJobTemplateData(){
    this.jobInformationService.getDataAllJobTemplate().subscribe((response)=>{
      this.sourceTemplateOptions = response.result;
    })
  }
  isSubmitted: boolean = false;
  validationStates: { [key: string]: NzStatus } = {};
  validationMessages: { [key: string]: string } = {};
  changedFields: Set<string> = new Set<string>();
  formFieldValues: any = {};

  get errorFields(): { field: string; label: string; message: string }[] {
    return Object.keys(this.validationStates)
      .filter(field => this.validationStates[field] === 'error')
      .map(field => ({
        field,
        label: this.getFieldLabel(field),
        message: this.validationMessages[field]
      }));
  }
  getFieldLabel(field: string): string {
    const labels: { [key: string]: string } = {
      name: 'Template Name ',
    };
    return labels[field] || field;
  }
  readonly validationRules = {
    name: {
      required: 'Required',
      maxLength: { limit: 50, message: 'Template Name exceeds the limit.' }
    }
  };
  checkErrors(validateAllFields = false) {
    this.validationStates = {};
    this.validationMessages = {};
    const fieldsToValidate = validateAllFields ? Object.keys(this.validationRules) : Array.from(this.changedFields);
    for (const field of fieldsToValidate) {
      if (this.validationRules.hasOwnProperty(field)) {
        const errorTip = this.getErrorTip(field);
        if (errorTip) {
          this.validationStates[field] = 'error';
          this.validationMessages[field] = errorTip;
        } else {
          this.validationStates[field] = null;
          this.validationMessages[field] = '';
        }
      }
    }
  }
  getErrorTip(field: string): string {
    const rules = this.validationRules[field];
    const input = this.formFieldValues[field] || '';
    if (rules.required && !input.trim()) {
      return `Required.`;
    }
    if (rules.maxLength && input.length > rules.maxLength.limit) {
      const excessLength = input.length - rules.maxLength.limit;
      const unit = excessLength === 1 ? 'character' : 'characters';
      return `${excessLength} ${unit} over.`;
    }
    return '';
  }
    validateChangedFields(field: string) {
    this.changedFields.add(field); // Add field to the changed fields set
    this.checkErrors(); // Validate changed fields
  }
  onFieldChange(field: string, value: string) {
    this.formFieldValues[field] = value;
    this.changedFields.add(field);
    this.checkErrors();
  }
}
