import { Component } from '@angular/core';

@Component({
  selector: 'app-change-orders-empty',
  templateUrl: './change-orders-empty.component.html',
  styleUrls: ['./change-orders-empty.component.css']
})
export class ChangeOrdersEmptyComponent {

}
