import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ProjectManagementSiteDiariesNewDailyLogComponent } from '../project-management-site-diaries-new-daily-log/project-management-site-diaries-new-daily-log.component';
import { FilterSideMenuBarService } from 'src/shared/Shared-Services/filter-side-menu-bar.service';
import { FiltersName } from 'src/app/Core/Enums/filtersName';
import { LeadOpportunitiesService } from 'src/Service/lead-opportunities.service';
import { ProjectManagementSelectDialogBoxComponent } from '../../project-management-select-dialog-box/project-management-select-dialog-box.component';
import { JobInfoComponentInformation } from 'src/Models/Job-List/Job-Information/Jobinformation';
import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';
import { DailyLogDashboard } from 'src/Models/Project-Management/projectManagement';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { FilterSearchParameter, LookupFilterResponse } from 'src/app/shared/component/Models/LookupModels';
import { SiteDiariesService } from 'src/Service/Project-Management/site-diaries.service';
import { LeadOpportunityAttachmentsUploadFilesViewAllComponent } from 'src/app/lead-opportunities/Components/Modal/lead-opportunity-attachments-upload-files-view-all/lead-opportunity-attachments-upload-files-view-all.component';
import { Subscription, take } from 'rxjs';
import { StandardViewForAllService } from 'src/Service/standard-view-for-all.service';
import { StandardColumnSetupResponse, StandardViewResponse } from 'src/Models/LookupStandardview/Standardview';
import { InternalUserService } from 'src/Service/Internaluser/internal-user.service';
import { LoadingIndicatorService } from 'src/shared/Shared-Services/loading-indicator.service';
import { AttachmentService } from 'src/Service/Attachment/attachment.service';
import { AttachmentResponse, CustomUploadFile } from 'src/Models/Attachment_Files_Class/AttachmentFilesClass';
import { CommmentConversationComponent } from 'src/app/messaging/Components/Comments/commment-conversation/commment-conversation.component';
import { Job } from 'src/Models/InternalUser/InternalUser';
import { DailyLogsComponent } from 'src/app/company-settings/daily-logs/daily-logs.component';
import { BehaviorsubjectService } from 'src/Service/behaviorsubject/behaviorsubject.service';

@Component({
  selector: 'app-project-management-site-diaries',
  templateUrl: './project-management-site-diaries.component.html',
  styleUrls: ['./project-management-site-diaries.component.css']
})
export class ProjectManagementSiteDiariesComponent implements OnInit, OnDestroy {
  @Output() cancel = new EventEmitter<void>();

  private subscriptions: Subscription[] = [];
  private SiteDiariessubscription: Subscription;
  private SiteDiariesFilterSubscription: Subscription;
  private SitediariesstandardViewFilterSubscription: Subscription;
  private displayColumnSubscription: Subscription;
  pageSizeOptions = [10, 20, 50, 75, 100, 250];

  dailyLogResponse: DailyLogDashboard[] = [];
  filterSearchParameter: FilterSearchParameter;
  lookupFilterResponse: LookupFilterResponse[] = [];
  defaultFilterIdSelected: number = 0;
  selectionFrom: string = 'DB';
  isLoading: boolean = false;
  totalRecords = 100;
  currentPage = 1;
  pageSize = 10;
  fixedSelectedColumnNames: string[] = [];
  selected_View_Columns: string[] = [];
  getStandardViewFilterData: StandardViewResponse[] = [];
  selectedValueControl: any;
  getStandardViewColumnFilterData: StandardColumnSetupResponse[] = [];
  jobInfoOptions: JobInfoComponentInformation[] = [];
  job: Job | null = null; // Initialize the job property
  displayedColumns: string[] = [];
  selectedFilter: number = -1;
  isFilterApplied: boolean = false;
  PAGE_ID: number = -1;
  PROJECT_MANG_SITE_DIAR_DAILY_L: string = FiltersName.PROJECT_MANG_SITE_DIAR_DAILY_L;
  selectiontabsdashbord = [1, 2, 3, 4];
  demoValue = 0;
  isCollapsed = false;
  selectedJobId: number = 0;
  isColumnSticky: boolean[] = new Array(this.displayedColumns?.length).fill(false);
  private resetSubscription: Subscription;
  private Schedulesubscription: Subscription;

  constructor(
    private modal: NzModalService,
    private filterService: FilterSideMenuBarService,
    private leadOppService: LeadOpportunitiesService,
    public localStorage: AccessLocalStorageService,
    private LookupFilterService: LookupFilterService,
    private SiteDiariesService: SiteDiariesService,
    private standardViewService: StandardViewForAllService,
    private changeDetectorRef: ChangeDetectorRef,
    private internalUserService: InternalUserService,
    private _loadingIndicatorService: LoadingIndicatorService,
    private _AttachmentService: AttachmentService,
    private _BehaviorsubjectService: BehaviorsubjectService,
  ) {
    this.clearSubscriptions();
  }

  ngOnInit(): void {
    this.initializeComponent();
    this.getStandardFilterByFormPageId(this.PAGE_ID, this.selectionFrom)
  }

  private clearSubscriptions(): void {
    // Unsubscribe from previous subscriptions to prevent memory leaks
    if (this.SiteDiariesFilterSubscription) {
      this.SiteDiariesFilterSubscription.unsubscribe();
    }
    if (this.SiteDiariessubscription) {
      this.SiteDiariessubscription.unsubscribe();
    }
    if (this.SitediariesstandardViewFilterSubscription) {
      this.SitediariesstandardViewFilterSubscription.unsubscribe();
    }
    console.clear();
  }

  private initializeComponent(): void {
    // Reset filter ID on load
    this.internalUserService.setSelectedFilterId(0);

    // Set page ID
    this.PAGE_ID = 5;
    this._loadingIndicatorService.show(); // Show loading indicator on component load

    // Subscribe to displayed columns and detect changes
    this.displayColumnSubscription = this.standardViewService.$displaySelectedColApply.subscribe(colName => {
      if (colName?.length > 0) {
        this.displayedColumns = colName;
        this.changeDetectorRef.detectChanges();
      }
    });

    // Add subscriptions to an array for easy management
    this.subscriptions.push(
      this.standardViewService.$selectedStandardFilterType.subscribe(standardType => {
        this.selectedFilter = standardType !== -1 ? standardType : standardType;
      })
    );

    // Fetch standard filters and handle loading state
    this.getStandardFilterByFormPageId(this.PAGE_ID, this.selectionFrom)
      .then(() => true)
      .catch(() => true);

    // Subscribe to the filter application state
    this.SiteDiariesService.$isUserAppliedFilter.subscribe(filter => {
      this.isFilterApplied = !!filter;
    });

    // Fetch selected job ID
    this.selectedJobId = this.localStorage.getJobId();
    this.localStorage.getJobName();
  }

  async getSelectedJobId(item: number): Promise<void> {

    try {
      this.selectedJobId = item;
      this.dailyLogResponse = null;
      await this.getStandardFilterByFormPageId(this.PAGE_ID, this.selectionFrom);

      // Filter daily log response if it's available
      if (this.dailyLogResponse) {
        this.dailyLogResponse = this.dailyLogResponse.filter(entry => entry.JobInformationId === this.selectedJobId);
      }
    } catch (error) {
      console.error("Error occurred while processing job selection: ", error);
    } finally {
    }
}

async getStandardFilterByFormPageId(pageId: number, type: string): Promise<void> {
  this._loadingIndicatorService.show(); // Ensure loading indicator is shown

  try {
      this.SiteDiariesFilterSubscription?.unsubscribe();

      this.LookupFilterService.getStandardFilterByFormPageId(pageId);

      this.SiteDiariesFilterSubscription = this.LookupFilterService.$getStandardFilter.subscribe(
          async (result) => {
              if (result) {
                  this.lookupFilterResponse = result.result;

                  const defaultFilter = result.result.find(filter => filter.isSetAsDefault);
                  const defaultFilterId = defaultFilter ? defaultFilter.id : -1;
                  this.SiteDiariesService.setSelectedFilterId(defaultFilterId);

                  const id = (type === this.selectionFrom) ? (this.localStorage.setSelectedFilterId(defaultFilterId), defaultFilterId) : this.localStorage.getSelectedFilterId();

                  if (id !== -1) {
                      // Await fetching grid data after getting the filter ID
                      await this.SiteDiariesGridTableData(1, this.PAGE_ID, id, this.currentPage, this.pageSize);
                  } else {
                      console.warn("No valid ID found to fetch grid data.");
                  }
              } else {
                  console.warn("Result is null or undefined.");
              }
          },
          error => {
              console.error("Error fetching standard filters: ", error);
          }
      );
  } catch (error) {
      console.error("Error occurred while fetching filters: ", error);
  } finally {
  }
}



  ngOnDestroy() {
    this.LookupFilterService.getStandardFilter.next(null); // New Changes By Aamir Ali - 31-May-2024
    localStorage.removeItem('Selected_Filter_Id'); // New Changes By Aamir Ali - 31-May-2024
    if (this.SiteDiariesFilterSubscription) {
      this.SiteDiariesFilterSubscription.unsubscribe();
    }
    if (this.SiteDiariessubscription) {
      this.SiteDiariessubscription.unsubscribe();
    }
    if (this.SitediariesstandardViewFilterSubscription) {
      this.SitediariesstandardViewFilterSubscription.unsubscribe();
    }
    // Unsubscribe from all subscriptions to prevent memory leaks
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.clearSubscriptions(); // Ensure all relevant subscriptions are cleared
    console.clear();
  }



  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }



  openFilter(component: string, pageId: number) {
    this.filterService.toggleFilter(component, pageId);
  }


  async SiteDiariesGridTableData(companyId: number, formNameId: number, lookUpFilterId: number, page: number, pageSize: number): Promise<void> {
    this._loadingIndicatorService.show(); // Show loading indicator
    try {
      this.filterSearchParameter = {
        CompanyParameterId: companyId,
        FormNameId: formNameId,
        LookUpFilterId: lookUpFilterId,
        page: page,
        pageSize: pageSize,
        jobInformationId: this.selectedJobId
      };

      // Unsubscribe from previous subscription if it exists
      this.SiteDiariessubscription?.unsubscribe();

      await new Promise<void>((resolve, reject) => {
        this.SiteDiariesService.getAllSiteDiariesByFilter(this.filterSearchParameter);
        this.SiteDiariessubscription = this.SiteDiariesService.$getSitedata.subscribe(
          result => {
            if (result) {
              this.totalRecords = result?.result?.rowCount;
              this.dailyLogResponse = result.result.rows;
              this.currentPage = page; // Set current page

              // Fetch attachments once data is received
              this.fetchAllAttachments().then(() => {
                this._loadingIndicatorService.hide();
                resolve();
              }).catch((error) => {
                console.error("Error fetching attachments:", error);
                reject(error);
              });
            } else {
              console.warn("No data received for the site diaries.");
              this._loadingIndicatorService.hide();
              resolve();
            }
          },
          error => {
            console.error("Error fetching site diaries data: ", error);
            this._loadingIndicatorService.hide();
            reject(error);
          }
        );
      });
    } catch (error) {
      console.error("An error occurred while fetching site diaries grid data: ", error);
    } finally {
      this._loadingIndicatorService.hide(); // Hide loading indicator in case of error
    }
  }

  EditSiteDiariesDailyLog(id: number, JobInformationId: number, jobName: string): void {
    if (id) {
      const modalRef = this.modal.create({
        nzContent: ProjectManagementSiteDiariesNewDailyLogComponent,
        nzFooter: null,
      });
      modalRef.componentInstance.selectedDailyLogId = id;
      modalRef.componentInstance.selectedJobId = JobInformationId;
      modalRef.componentInstance.selectedJobName = jobName;

      // Refresh grid data only after modal is closed
      modalRef.componentInstance.cancel.subscribe(() => {
        modalRef.destroy();
        this.onAfterResponse();
    const lookUpFilter = this.localStorage.getSelectedFilterId();
        if (this.selectedJobId) {
          this.SiteDiariesGridTableData(1, this.PAGE_ID, lookUpFilter, this.currentPage, this.pageSize);
        }
      });
    }  
 
  }


  onAfterResponse(): void {
    const lookUpFilter = this.localStorage.getSelectedFilterId();
    this._BehaviorsubjectService.leadActivityTypeResponse$.pipe(
      take(1) // Use take(1) to only get the next response and unsubscribe automatically
    ).subscribe((response) => {
      if (response) {
        this.SiteDiariesGridTableData(1, this.PAGE_ID, lookUpFilter, this.currentPage, this.pageSize);
      }
    });
}


  async fetchAllAttachments(): Promise<void> {
    if (!this.dailyLogResponse || !this.dailyLogResponse.length) {
      console.warn("No daily log response to fetch attachments for.");
      return; 
    }

    const attachmentPromises = this.dailyLogResponse.map(log => {
      if (log.AttachmentId) {
        return this.fetchAttachments(log.AttachmentId);
      }
      return Promise.resolve();
    });

    await Promise.all(attachmentPromises);
  }

  fetchAttachments(AttachmentId: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (AttachmentId) {
        this._AttachmentService.getLeadByAttachmentId(AttachmentId).subscribe(
          (res) => {
            // Store the fetched attachment in the TestAttachments object using AttachmentId as the key
            this.TestAttachments[AttachmentId] = res.result;
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
      } else {
        resolve();
      }
    });
  }



  viewAll(): void {
    const modalRef = this.modal.create({
      nzContent: LeadOpportunityAttachmentsUploadFilesViewAllComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }




  onPageChangeDailyLog(page: number): void {
    this.currentPage = page;
    let filterId: number = 0;
    if (this.resetSubscription) {
      this.resetSubscription.unsubscribe();
    }
    this.Schedulesubscription =
      this.SiteDiariesService.$selectedFilterId.subscribe((v) => {
        if (v) {
          filterId = v;
        }
      });
    this.defaultFilterIdSelected = filterId;
    this.SiteDiariesGridTableData(1, this.PAGE_ID, filterId, page, this.pageSize);
    this.isLoading = true;
  }

  onPageSizeChangeDailyLog(size: number): void {
    this.isColumnSticky = new Array(this.displayedColumns?.length).fill(false);
    this.pageSize = size;
    let filterId: any = 0;
    if (this.resetSubscription) {
      this.resetSubscription.unsubscribe();
    }
    this.Schedulesubscription =
      this.SiteDiariesService.$selectedFilterId.subscribe((v) => {
        if (v) {
          filterId = v;
        }
        this.defaultFilterIdSelected = filterId;
        this.SiteDiariesGridTableData(
          1,
          this.PAGE_ID,
          filterId,
          this.currentPage,
          this.pageSize
        );
        this.isLoading = true;
      });
  }


  isAllDataShownSchedule(): boolean {
    return this.totalRecords <= this.pageSize;
  }

  TestAttachment: AttachmentResponse;
  TestAttachments: { [key: number]: AttachmentResponse } = {}; // To store attachments by AttachmentId
  selectedFilesAttachment: CustomUploadFile[] = []; // To store uploaded files

  // Handle file uploads for attachment
  onFilesUploaded(files: CustomUploadFile[]): void {
    this.selectedFilesAttachment = files;
  }




  // getStandardViewFilterByFormPageId(pageId: number) {
  //   if (this.SitediariesstandardViewFilterSubscription) {
  //     this.SitediariesstandardViewFilterSubscription.unsubscribe();
  //   }
  //   this.displayedColumns = [];
  //   this.fixedSelectedColumnNames = [];
  //   this.standardViewService.getStandardViewFilterByFormPageId(pageId);
  //   this.standardViewService.getStandardViewFilterColumnsByFormPageId(pageId);
  //   // this.standardViewService.$getStandardViewFilter.subscribe(result => {
  //   this.SitediariesstandardViewFilterSubscription = this.standardViewService.$getStandardViewFilter.subscribe(result => {
  //     if (result) {
  //       this.displayedColumns = [];
  //       this.selected_View_Columns = [];
  //       this.getStandardViewFilterData = result.result;
  //       let defaultView = this.getStandardViewFilterData.find(view => view.isSetAsDefault === true)?.standardViewParameters;
  //       this.selectedValueControl = this.getStandardViewFilterData?.find(view => view?.isSetAsDefault === true)?.id;
  //       defaultView.forEach(viewItem => {
  //         if (viewItem.standardColumnSetup && viewItem.standardColumnSetup.displayColumnName) {
  //           this.selected_View_Columns.push(viewItem.standardColumnSetup.displayColumnName);
  //         }
  //       });
  //       this.displayedColumns = this.selected_View_Columns;
  //     }
  //   });

  //   this.standardViewService.$getStandardViewColumn.subscribe(result => {
  //     if (result) {
  //       this.getStandardViewColumnFilterData = result.result;
  //       let fixedCol = this.getStandardViewColumnFilterData.filter(x => x.isSelected === true);
  //       fixedCol.forEach(x => {
  //         this.fixedSelectedColumnNames.push(x.displayColumnName);
  //       });

  //     }
  //   });
  // }



  selectionTabTitle(tab: number): string {
    switch (tab) {
      case 1:
        return 'Card View';
      case 2:
        return 'List';
      case 3:
        return 'Hierarchy';
      case 4:
        return 'Allowances';

      default:
        return '';
    }
  }

  panels = [
    {
      active: true,
      disabled: false,
      name: 'All Choices (1)',
      childPannel: [
        {
          active: false,
          disabled: true,
          name: 'This is panel header 1-1'
        }
      ]
    }
  ];

  newDailyLogModelOpen(select: string): void {
    const jobId = this.localStorage.getJobId();
    const modalConfig = {
      nzFooter: null,
      nzTitle: null,
    };

    if (jobId) {
      const modalRef = this.modal.create({
        ...modalConfig,
        nzContent: ProjectManagementSiteDiariesNewDailyLogComponent,
      });
      modalRef.componentInstance.selectedJobId = jobId;
      modalRef.componentInstance.selectedJobName = this.localStorage.getJobName();
      modalRef.componentInstance.selectedJobStreetAddress = this.localStorage.getJobAddress();

      modalRef.componentInstance.cancel.subscribe(() => {
        modalRef.destroy();
        this.onAfterResponse();
      });
    } else {
      const modalRef = this.modal.create({
        nzWidth: '32%',
        nzStyle: { top: '36%' },
        ...modalConfig,
        nzContent: ProjectManagementSelectDialogBoxComponent,
        nzData: {
          type: select,
          leads: this.jobInfoOptions,
        },
      });
      modalRef.componentInstance.argument = select;
      modalRef.componentInstance.cancel.subscribe(() => {
        modalRef.destroy();
      });
    }
  }


  DailyLogSetting(): void {
    const modalRef = this.modal.create({
      nzContent: DailyLogsComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    this.onAfterResponse();

  }


  conversationModelOpen(response: DailyLogDashboard): void {
    const modalRef = this.modal.create({
      nzContent: CommmentConversationComponent,
      nzTitle: '<h1 nz-typography>Conversation</h1>',
      nzWidth: '62%',
      nzBodyStyle : { background: '#f1f4fa', padding: '13px', 'max-height': '80vh', 'overflow-x': 'hidden' },
      nzStyle: { top: '20px' },
      nzFooter: null,
    });

    modalRef.componentInstance.takeResponseDynamic = response;
    modalRef.componentInstance.formNameId = 5;
    modalRef.componentInstance.componentTypeDynamic = 'Daily Log';
    modalRef.componentInstance.componentSelectedJobName = this.localStorage.getJobName();
    modalRef.componentInstance.componentTitleDynamic = response.Date;

    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });


    modalRef.afterClose.subscribe(() => {

      this._loadingIndicatorService.show();
      modalRef.destroy();
      this.isFilterApplied = true; // Set the flag to true after the first call
      const lookUpFilter = this.localStorage.getSelectedFilterId();
      const companyId = 1;
      const formNameId = this.PAGE_ID;
      const lookUpFilterId = lookUpFilter;
      const page = this.currentPage;
      const pageSize = this.pageSize;

      this.SiteDiariesGridTableData(companyId, formNameId, lookUpFilterId, page, pageSize);

      this._loadingIndicatorService.hide();
    });
  }






}


