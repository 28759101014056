import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';
import { LeadOpportunitiesService } from 'src/Service/lead-opportunities.service';
import { Autoformat, BlockQuote, Image, Bold, ClassicEditor, EditorConfig, Essentials, Font, FontBackgroundColor, FontColor, FontFamily, FontSize, Heading, HorizontalLine, ImageUpload, Indent, Italic, Link, List, ListProperties, MediaEmbed, Paragraph, Table, Undo, UndoEditing } from 'ckeditor5';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import validateForm from 'src/app/helpers/validationForm';
import { MessagingResponse } from 'src/Models/Project-Management/projectManagement';
import { NzModalService } from 'ng-zorro-antd/modal';
import { LoadingIndicatorService } from 'src/shared/Shared-Services/loading-indicator.service';
@Component({
  selector: 'messaging-common',
  templateUrl: './messaging-common.component.html',
  styleUrls: ['./messaging-common.component.css']
})
export class MessagingCommonComponent implements OnInit{
  @Input() Job_ID: number;
  @Input() Messaging_ID: number;
  @Input() header_ID : number;
  @Input() Form_Name_ID : number;
  messagesFormValue: any = {};
  messagesFormValueForm!: FormGroup;
  @Input() Message_headerSet_ID : number;
  flattenedComments: MessagingResponse[] = [];
  isSave: boolean = false;
  DisabledNextActivity: boolean = false;
  public Editor = ClassicEditor;
  @Output() messageSaved: EventEmitter<void> = new EventEmitter<void>();
  constructor(
    private fb: FormBuilder,
    public localStorage: AccessLocalStorageService,
    private leadOppService: LeadOpportunitiesService,
    public toastsService: NzMessageService,
    private modal: NzModalService,
    private _loadingIndicatorService: LoadingIndicatorService,

  ){}

  ngOnInit(){
    this.createChangeOrderForm();
    if(this.Messaging_ID){
      this.getAllCommentsMessages(this.Messaging_ID);

    }
  }
  createChangeOrderForm() {
    this.messagesFormValueForm = this.fb.group({
      globalId: ['00000000-0000-0000-0000-000000000000'],
      headerId: [this.header_ID],
      formNameId: [this.Form_Name_ID],
      comments: ['', Validators.required],
      JobInformationId: [this.Job_ID],
      MessageFormTypeId: [70829],
    });
  }
  onSaveMessage(actionType: 'save' = 'save'): Promise<void> {
    return new Promise((resolve, reject) => {
      let data = this.messagesFormValueForm;

      if (data.valid) {

        if (this.isSave) {
          return reject('Already processing');
        }
        if (actionType === 'save') {
          this.isSave = true;
        }

        this.DisabledNextActivity = true;
        this.leadOppService.PostMessage(data.value).subscribe(
          (response) => {
            this.getAllCommentsMessages(response.result?.MessagingId);
            this.messagesFormValueForm.get('comments')?.reset();
            this.DisabledNextActivity = false;
            this.messageSaved.emit();
          },
          (error) => {
            console.error('Error saving data:', error);
            validateForm.validateAllFormFields(data);
            this.DisabledNextActivity = false;
            reject(error);
          }
        ).add(() => {
          this.isSave = false;
        });
      } else {
      validateForm.validateAllFormFields(data);
      reject('Form is invalid');
      }
    });
  }
  getAllCommentsMessages(MessagingId: number): void {
    this.leadOppService.getAllmessageingData(MessagingId).subscribe(
      (response) => {
        this.flattenedComments = this.flattenArray(response.result).map(comment => ({
          ...comment,
          // Comments: this.stripHtmlTags(comment.Comments)
        }));
      },
      (error) => {
        console.error('Error fetching messages', error);
      },
      () => {
        // This callback runs after both success and error handlers
        this._loadingIndicatorService.hide();
      }
    );
  }
  flattenArray(arr: MessagingResponse[][]): MessagingResponse[] {
    return arr.reduce((acc, val) => acc.concat(val), []);
  }
  stripHtmlTags(html: string): string {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent || div.innerText || '';
  }
  deleteComment(globalId: string): void {
    this.modal.confirm({
      nzTitle: `Delete Comment?`,
      nzContent: `Are you sure you want to permanently delete this Comment?`,
      nzOkText: `Delete`,
      nzOkDanger: true,
      nzCancelText: 'Cancel',
      nzOnOk: () => {
        return this.deleteCommentConfirm(globalId)
          .then(() => {})
          .catch(() => {});
      },
      nzCentered: true,
      nzBodyStyle: { 'border-radius': '50px', 'height': 'auto', 'padding-top': '15' },
      nzCloseIcon: '',
      nzIconType: ''
    });
  }
  deleteCommentConfirm(globalId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!globalId) {
        this.toastsService.error('No record selected for deletion.');
        reject();
        return;
      }

      this.leadOppService.deleteDatabymessagingCommments(globalId).subscribe(
        (res) => {
          this.flattenedComments = this.flattenedComments.filter(comment => comment.GlobalId !== globalId);
          resolve();
        },
        (error) => {
          this.toastsService.error('An error occurred while deleting the record. Please try again.');
          reject();
        }
      );
    });
  }
  public config: EditorConfig = {
    toolbar: {
      items: [
        'undo', 'redo', '|',
        'heading', '|', 'bold', 'italic', '|',
        'link', 'insertTable', 'mediaEmbed', '|',
        'bulletedList', 'numberedList', 'indent', 'outdent', '|',
        'fontSize', 'fontFamily', 'fontBackgroundColor', 'fontColor', 'blockQuote',
        'image', 'horizontalLine', 'undoEditing', 'ListProperties', 'Autoformat'
      ]
    },
    plugins: [
      Paragraph,
      Bold,
      Essentials,
      Heading,
      Indent,
      Italic,
      Link,
      List,
      ListProperties,
      MediaEmbed,
      Autoformat,
      Table,
      Undo,
      Font,
      FontSize,
      FontFamily,
      FontBackgroundColor,
      FontColor,
      BlockQuote,
      Image,
      HorizontalLine,
      UndoEditing,
      ImageUpload,
    ],
    fontSize: {
      options: [
        9, 11, 13, 'default', 17, 19, 21, 25, 28, 32, 36, 40, 44, 48, 52, 56, 60
      ]
    },
    fontFamily: {
      options: [
        'default', 'Arial, Helvetica, sans-serif', 'Courier New, Courier, monospace',
        'Georgia, serif', 'Times New Roman, Times, serif', 'Verdana, Geneva, sans-serif'
      ]
    }
  };
}
