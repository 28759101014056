import { ToDoSetup } from './../../../Models/Project-Management/projectManagement';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ToDoInformationService } from 'src/Service/Project-Management/to-do-information.service';
import { ToDoSetupResponse } from 'src/Models/Project-Management/projectManagement';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CompanyParameter } from 'src/Models/LeasSalesPeople';
import { todoCompanyParameter } from 'src/Models/Warranty/warranty';
import { LoadingIndicatorService } from 'src/shared/Shared-Services/loading-indicator.service';


@Component({
  selector: 'app-to-dos',
  templateUrl: './to-dos.component.html',
  styleUrls: ['./to-dos.component.css']
})
export class ToDosComponent implements OnInit {
  todoInformationRequest: ToDoSetupResponse;
  isSaving: boolean = false; // Initialize flag
  todoForms: FormGroup;
  @Output() cancel = new EventEmitter<void>();
  selectedValue = null;
  customfieldinfo: boolean = false;
  dataset: any;
  toDosVisibility: boolean = true;
  toDoCompSetRes: ToDoSetupResponse;

  constructor(
    private modal: NzModalService,
    private todoService: ToDoInformationService,
    private _toastsService: NzMessageService,
    private _fb: FormBuilder,
    private _loadingIndicatorService: LoadingIndicatorService

  ) { }


  ngOnInit(): void {
    this.todoForms = this._fb.group({
      globalId: ['00000000-0000-0000-0000-000000000000'],
      companyParameterId: [1],
      sendPastDueToDoReminderUpToDay: [0],
      requireAllChecklistItemsToBeCompleteInOrderToCompleteAtoDo: [false],
      moveCompletedChecklistItemsToTheBottom: [false],
    });

    this.fetchCompanyJobsetup();
  }


  closeToDos(): void {
    this.cancel.emit();
  }

  saveButton(): void {
    if (this.isSaving) {
      return;
    }
    this.isSaving = true;
    this.todoService.postDataToDoToDoSetupCompSet(this.todoForms.value).subscribe(
      (response) => {
        this.toDoCompSetRes = response.result;
        this._toastsService.success('Saved Successfully!');
        this.toDosVisibility = false;
        this.cancel.emit();
      },
      (error) => {
        this._toastsService.error('Failed!');
      },
      () => {
        this.isSaving = false;
      }
    );
  }


  fetchCompanyJobsetup(): void {

    this._loadingIndicatorService.show();
    this.todoService.getCompanytodo().subscribe({
      next: (response) => {
        if (response && response.result) {
          const firstItem = response.result;
          console.log('First item:', firstItem);

          // Patch form values
          this.todoForms.patchValue({
            globalId: firstItem.globalId || '00000000-0000-0000-0000-000000000000',
            companyParameterId: firstItem.companyParameterId,
            sendPastDueToDoReminderUpToDay: firstItem.sendPastDueToDoReminderUpToDay.toString(),
            requireAllChecklistItemsToBeCompleteInOrderToCompleteAtoDo: firstItem.requireAllChecklistItemsToBeCompleteInOrderToCompleteAtoDo,
            moveCompletedChecklistItemsToTheBottom: firstItem.moveCompletedChecklistItemsToTheBottom,
          });
          this._loadingIndicatorService.hide();

        } else {
          this._loadingIndicatorService.hide();

          console.log('No result in response.');
        }
      },
      error: (err) => {
        this._loadingIndicatorService.hide();

        console.error('Error fetching company todo:', err);
      }
    });
  }



}

