<nz-layout>
  <nz-sider class="border" nzCollapsible [nzCollapsedWidth]="10" [(nzCollapsed)]="isCollapsed" [nzWidth]="280"
    [nzTrigger]="null">
    <div class="row">
      <app-side-menu-bar [isAllJobOption]="true" (getSelectedJobId)="getSelectedJobId($event)"
        (toggleCollapse)="toggleCollapse()"></app-side-menu-bar>
    </div>
  </nz-sider>

  <nz-layout>
    <nz-page-header>
      <nz-page-header-title>
        <div nz-row>
          <div nz-col>
            <nz-breadcrumb class="text-start" nz-page-header-breadcrumb>
              <h1 class="bds-header-info--job-name">{{_AccessLocalStorageService.getJobName()}}</h1>
            </nz-breadcrumb>
          </div>
        </div>
        <div nz-row>
          <div nz-col>
            <nz-breadcrumb class="text-start" nz-page-header-breadcrumb>
              <h2 class="p-0 m-0 famly-inn ant-typography-Modal">Time Clock</h2>
            </nz-breadcrumb>
          </div>
        </div>
      </nz-page-header-title>
    </nz-page-header>
    <div nz-row class="radio-button" style="padding: 0px 24px;">
      <div nz-col nzSpan="12">
        <nz-radio-group>
          <label nz-radio-button nzValue="A">Reports</label>
          <label nz-radio-button nzValue="B">Shifts Map</label>
        </nz-radio-group>
      </div>
      <div nz-col nzSpan="12" class="d-flex justify-content-end">
        <nz-page-header-extra style="align-self: flex-end;">
          <nz-space>
            <button nz-button nzType="default" class="ms-2"
              nz-dropdown
              nzTrigger="click"
              [nzDropdownMenu]="menu"
              nzPlacement="bottomLeft">
              <span nz-icon nzType="ellipsis" nzTheme="outline"></span>
            </button>
            <nz-dropdown-menu #menu="nzDropdownMenu">
              <ul nz-menu>
                <li nz-menu-item (click)="Clock('ClockInUser')">Clock In User</li>
                <li nz-menu-item (click)="RecordShift(_AccessLocalStorageService.getJobId(), null)">Record Shift</li>
              </ul>
            </nz-dropdown-menu>
            <button nz-button nzType="default" class="ms-2 rounded-1"
              (click)="openFilter(PROJECT_MANG_TIMECLOCK, PAGE_ID)">
              <i class="bi bi-funnel"></i>&nbsp;&nbsp;Filter
            </button>
            <ng-container *ngIf="ITimeClock_ClockInRes?.IsClockIn === true; else endTime_Date">
              <button (click)="Clock('ClockOut')" nz-button nzType="primary" class="ms-2 button-Daily d-flex align-items-center">
                <span nz-icon nzType="clock-circle" nzTheme="outline"></span>
                <span class="BTN-Font-Famliy ms-2">Clock Out</span>
              </button>
            </ng-container>
            <ng-template #endTime_Date>
              <button (click)="Clock('ClockIn')" nz-button nzType="primary" class="ms-2 button-Daily d-flex align-items-center">
                <span nz-icon nzType="clock-circle" nzTheme="outline"></span>
                <span class="BTN-Font-Famliy ms-2">Clock In</span>
              </button>
            </ng-template>
          </nz-space>
        </nz-page-header-extra>
      </div>
    </div>
    <nz-content class="custom-content" *ngIf="isLandingLoading !== true">
      <app-time-clock-crads [_ShiftInformationResponse]="this.ITimeClock_ClockInRes" (onStartTime_Changes)="onStartBreak($event)"></app-time-clock-crads>
      <ng-container *ngIf="getShiftInData?.length > 0 || null; else _GetProjecTimeClockgriddata">
        <div class="kendo-grid-container">
          <div class="kendo-grid-scrollable">
            <kendo-grid
            [data]="GetProjecTimeClockgriddata"
            [sortable]="true"
            [resizable]="true"
            [reorderable]="true"
            [scrollable]="'scrollable'"
          >
            <kendo-grid-column
              [reorderable]="false"
              [resizable]="false"
              [width]="50"
              [headerStyle]="{}"
              [headerClass]="'checkbox-header'"
              [field]="'checkbox'"
              [title]="'Select'"
            >
              <ng-template kendoGridHeaderTemplate>
                <div class="checkbox-container">
                  <label nz-checkbox
                    [ngModel]="allSelected"
                    (ngModelChange)="onHeaderCheckboxChange($event)">
                  </label>
                </div>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem>
                <div class="checkbox-container-list">
                  <label nz-checkbox
                    [(ngModel)]="dataItem.selected"
                    (ngModelChange)="onCheckboxChange()">
                  </label>
                </div>
              </ng-template>
            </kendo-grid-column>
          
            <kendo-grid-column
            *ngFor="let column of displayedColumns"
            [field]="column"
            [title]="column"
            [width]="getColumnWidth(column)"
            [locked]="isColumnLocked(column)"
            [headerStyle]="{
              'background': '#f1f1f1',
              'border-bottom': '1px solid #ddd'
            }"
            [headerClass]="{ 'locked-column': isColumnLocked(column) }">
              <ng-template kendoGridHeaderTemplate>
                <span>{{ column }}</span>
                <span 
                  nz-icon 
                  nzType="pushpin" 
                  nzTheme="outline" 
                  (click)="toggleColumnLock(column)" 
                  [ngClass]="{ 'locked-icon': isColumnLocked(column), 'unlocked-icon': !isColumnLocked(column) }"
                ></span>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem>
                <ng-container [ngSwitch]="column">
                  <ng-container *ngSwitchCase="'Date'">
                    <span (click)="RecordShift(_AccessLocalStorageService.getJobId(), dataItem?.Id)" class="text-overflow-auto Click-Btn">{{ dataItem?.StartOn }}</span>
                  </ng-container>
                  <ng-container *ngSwitchCase="'Job'">
                    <span  class="text-overflow-auto">{{ dataItem?.JobName }}</span>
                  </ng-container>
                  <ng-container *ngSwitchCase="'Name'">
                    <span  class="text-overflow-auto">{{ dataItem?.ApplicationUserName }}</span>
                  </ng-container>
                  <ng-container *ngSwitchCase="'Tags'">
                    <span *ngIf="dataItem?.TagNames?.length > 0" class="ant-tag">{{ dataItem?.TagNames }}</span>
                  </ng-container>
                  <ng-container *ngSwitchCase="'Time In'">
                    <span  class="text-overflow-auto">{{ dataItem?.StartTime | date: 'hh:mm a' }}</span>
                  </ng-container>
                  <ng-container *ngSwitchCase="'Time Out'">
                    <ng-container *ngIf="dataItem?.StartTime && !dataItem?.EndTime; else _EndTime">
                      <div nz-row>
                        <div nz-col class="text-center" style="padding-left: 4px; padding-right: 4px;">
                          <button
                            nz-button
                            nzType="primary"
                            class="align-items-center"
                            (click)="Clock('ClockOut')"
                          >
                            <span class="BTN-Font-Famliy">
                              Clock Out
                            </span>
                          </button>
                        </div>
                      </div>
                    </ng-container>
                    <ng-template #_EndTime>
                      <span>{{ dataItem?.EndTime | date: 'hh:mm a' }}</span>
                    </ng-template>
                  </ng-container>
                  <ng-container *ngSwitchCase="'Break Time'">
                    <ng-container *ngIf="dataItem?.DiffBreakTime !== '00:00' && (!_AccessLocalStorageService.onGetStartTime() || dataItem?.EndTime)">
                      <span>{{ dataItem?.DiffBreakTime }}</span>
                    </ng-container>
                  
                    <!-- Agar StartTime hai aur EndTime nahi hai toh button display hoga -->
                    <ng-container *ngIf="_AccessLocalStorageService.onGetStartTime() && !dataItem?.EndTime">
                      <div nz-row>
                        <div nz-col class="text-center" style="padding-left: 4px; padding-right: 4px;">
                          <button
                            nz-button
                            nzType="primary"
                            class="align-items-center"
                            (click)="onEndTime()"
                          >
                            <span class="BTN-Font-Famliy">
                              End Break
                            </span>
                          </button>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngSwitchCase="'Regular Time'">
                    <span>{{ dataItem?.RegularTime }}</span>
                  </ng-container>
                  <ng-container *ngSwitchCase="'OT'">
                    <span>{{ dataItem?.OverTime }}</span>
                  </ng-container>
                  <ng-container *ngSwitchCase="'Total Work Time'">
                    <span class="text-overflow-auto">{{ dataItem?.TotalWorkTime }}</span>
                  </ng-container>
                  <ng-container *ngSwitchCase="'Edited'">
                    <span class="status-text badge pending">{{ dataItem?.Edited }}</span>
                  </ng-container>
                  <ng-container *ngSwitchCase="'Approved Status'">
                    <ng-container *ngIf="dataItem?.ApprovedStatus === 'Pending Approval'">
                      <span class="status-text badge pending">{{ dataItem?.ApprovedStatus }}</span>
                    </ng-container>
                    <ng-container *ngIf="dataItem?.ApprovedStatus === 'Approved'">
                      <span class="status-text badge success">{{ dataItem?.ApprovedStatus }}</span>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngSwitchCase="'Builder Cost'">
                    <span  class="text-overflow-auto">{{ dataItem?.BuilderCost }}</span>
                  </ng-container>
                  <ng-container *ngSwitchCase="'Cost Codes'">
                    <span  class="text-overflow-auto">{{ dataItem?.CostCodes }}</span>
                  </ng-container>
                  <ng-container *ngSwitchCase="'Created By'">
                    <div nz-row style="justify-content: space-between;" class="IsCompleted_Assignee">
                      <div nz-col>
                        <div style="display: flex; align-items: center; gap: 10px;">
                            <div nz-col class="ant-avt-Assingee">
                                <nz-avatar [nzText]="dataItem?.CreatedBy"></nz-avatar>
                            </div>
                        </div>
                      </div> 
                    </div>
                  </ng-container>
                  <ng-container *ngSwitchCase="'Notes'">
                    <span  class="text-overflow-auto">{{ dataItem?.Notes }}</span>
                  </ng-container>
                  <ng-container *ngSwitchCase="'2OT'">
                    <span>{{ dataItem?.DoubleOverTime }}</span>
                  </ng-container>
                  <ng-container *ngSwitchCase="'Related Owner Invoices'">
                  </ng-container>
                  <ng-container *ngSwitchCase="'PTO'">
                    <span>{{ dataItem?.PTo }}</span>
                  </ng-container>
                  <ng-container *ngSwitchCase="'Created Date'">
                    <span>{{ dataItem?.CreatedOn | date: 'MMMM d, yyyy h:mm a' }}</span>
                  </ng-container>
                </ng-container>
              </ng-template>
            </kendo-grid-column>
            </kendo-grid>
          </div>
        </div>
      </ng-container>
      <ng-template #_GetProjecTimeClockgriddata>
        <app-time-clock-empty></app-time-clock-empty>
      </ng-template>
      <nz-footer class="custom-footer">
        <div class="StickyFooterWrapper react">
          <div class="StickyFooter react" style="left: -13px; right: -8px;padding: 0px 40px 0px 40px;">
             <div class="ListActionBar bottom StickyLayoutHeader react" style="left: -13px; right: -8px;">
              <div nz-row style="margin-top: 10px;">
                <div nz-col nzSpan="14">
                  <ng-container *ngIf="PAGE_ID">
                    <div nz-row>
                      <div nz-col nzSpan="7">
                        <nz-select class="mt-1" (ngModelChange)="onFilterChange($event)" [(ngModel)]="selectedValueControl" nzShowSearch>
                          <nz-option *ngFor="let standard of getStandardViewFilterData" [nzLabel]="standard.name" [nzValue]="standard.id"></nz-option>
                        </nz-select>
                      </div>
                      <div nz-col nzSpan="1">
                        <a nz-popover [nzPopoverContent]="contentTemplate" nzPopoverTrigger="hover" class="react" nz-button nzType="link" (click)="openStandardViewFilterComponent()">
                          <i style="font-size: 1.43em;" class="bx bx-dots-horizontal-rounded mt-2"></i>
                        </a>
                        <ng-template #contentTemplate>
                          <p>Manage Saved Views</p>
                        </ng-template>
                      </div>
                    </div>
                  </ng-container>
                </div>
                <div nz-col nzSpan="10">
                  <div class="d-flex justify-content-end align-items-end">
                    <nz-pagination style="float: right;" [nzPageIndex]="currentPage" [nzTotal]="totalRecords"
                      [nzPageSize]="pageSize" nzShowSizeChanger (nzPageIndexChange)="onPageChange($event)"
                      (nzPageSizeChange)="onPageSizeChange($event)" [nzPageSizeOptions]="[10, 20, 50, 75, 100]">
                    </nz-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nz-footer>
    </nz-content>
  </nz-layout>
</nz-layout>
