import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ScheduleSetupPhaseParameter, ScheduleSetupPhaseParameterResponse } from 'src/Models/CompanySettings/Schedule/PhaseSetup/Company-Settings-Schedule';
import { PhaseSetup } from 'src/Models/CompanySettings/Schedule/PhaseSetup/PhaseSetup';
import { ResponseModelArray } from 'src/Models/responseMessage.model';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class PhaseService {

  private baseUrl: string = environment.apiUrl + 'ScheduleSetup/create-PhaseSetup';
  private URL_PHASE_SETUP: string = environment.apiUrl + 'ScheduleSetup/GetAll-PhaseSetup';
  private BASE_Url: string = environment.apiUrl + 'ScheduleSetup';
  


  constructor(private http: HttpClient) { }

  postData(data: ScheduleSetupPhaseParameter): Observable<ResponseModelArray<ScheduleSetupPhaseParameterResponse>> {
    return this.http.post<any>(`${this.baseUrl}`, data);
  }

  getData(): Observable<ResponseModelArray<ScheduleSetupPhaseParameterResponse>> {
    return this.http.get<ResponseModelArray<ScheduleSetupPhaseParameterResponse>>(`${this.URL_PHASE_SETUP}`);
  }

  updateData(data: ScheduleSetupPhaseParameter): Observable<ScheduleSetupPhaseParameterResponse> {
    return this.http.put<ScheduleSetupPhaseParameterResponse>(`${this.baseUrl}`, data);
  }

  deleteData(globalId: string): Observable<ScheduleSetupPhaseParameterResponse> {
    return this.http.delete<ScheduleSetupPhaseParameterResponse>(
      `${this.BASE_Url}?globalId=${globalId}`
    );
  }

  // Service method to delete a phase setup based on its global ID
  deletePhase(globalId: string): Observable<ScheduleSetupPhaseParameterResponse> {
    const deleteUrl = `${this.BASE_Url}/deletePhaseSetup-globalId?globalId=${globalId}`;
    return this.http.delete<ScheduleSetupPhaseParameterResponse>(deleteUrl);
  }


  getDataId(id: number): Observable<ScheduleSetupPhaseParameterResponse> {
    return this.http.get<ScheduleSetupPhaseParameterResponse>(
      `${this.baseUrl}getBy-Id?id=${id}`
    );
  }

    UpdateOrderNumber(data: any): Observable<ResponseModelArray<any>> {
      return this.http.post<any>(`${this.BASE_Url}/update-DisplayOrder`, data);
    }
  

  
}