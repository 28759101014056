import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { ApiResponseModel } from 'src/Models/apiResponse.model';
import { UpdatePayload } from 'src/Models/CustomerInformation';

import { environment } from 'src/environments/environment.prod';
import { ResponseModel, ResponseModelArray } from 'src/Models/responseMessage.model';
import { CostCode } from 'src/Models/CostCode/costCode';
import {  CategoryResponse, CostCodeCategoryResponse, CostCodeResponse } from 'src/Models/InternalUser/applicationUserResponse';

@Injectable({
  providedIn: 'root'
})
export class CostCodeService {

  private CostCode: string = environment.apiUrl + 'CostCode/GetAll/';
  private baseUrl: string = environment.apiUrl + 'CostCode/';
  private costCodeUrl: string = environment.apiUrl + 'CostCode/Create-CostCode/';
  private costCategoryUrl: string = environment.apiUrl + 'CostCode/Create-Category/';


  constructor(private http: HttpClient) {}

  postData(data: any): Observable<any> {
    return this.http.post<any>(this.baseUrl, data).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }
  

  getData(): Observable<ApiResponseModel[]> {
    return this.http.get<ApiResponseModel[]>(`${this.baseUrl}getAll`);
  }

  updateData(data: UpdatePayload): Observable<any> {
    const headers = { 'Content-Type': 'application/json' };
    const options = { headers: new HttpHeaders(headers) };
    return this.http.put<any>(`${this.baseUrl}`, data, options);
  }
  

  deleteData(globalId: string): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl}?globalId=${globalId}`).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }

  
  getDataId(id: any): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}getBy-Id?id=${id}`);
  }
  getDataGlobalId(globalId: string): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}getBy-globalId=${globalId}`);
  }

  getCategoryData():Observable<ResponseModelArray<CostCode>>{
    return this.http.get<ResponseModelArray<CostCode>>(`${this.CostCode}`);
  }
  
  postCostCode(costCodeFormData: CostCodeResponse): Observable<ResponseModel<CostCodeResponse>> {
    const apiUrl = `${this.costCodeUrl}`;
    return this.http.post<any>(apiUrl, costCodeFormData).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }
  postCostCategory(costCategoryFormData: CategoryResponse): Observable<ResponseModel<CategoryResponse>> {
    const apiUrl = `${this.costCodeUrl}`;
    return this.http.post<ResponseModel<CategoryResponse>>(apiUrl, costCategoryFormData).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }
  getDataIdCostCode(id: number): Observable<ResponseModel<CostCodeResponse>> {
    return this.http.get<ResponseModel<CostCodeResponse>>(`${this.baseUrl}getBy-Id?id=${id}`);
  }
}
