import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, catchError, throwError } from 'rxjs';
import { AttachmentResponse } from 'src/Models/Attachment_Files_Class/AttachmentFilesClass';
import { ScheduleItemParameterResponse, ToDoResponse } from 'src/Models/Project-Management/projectManagement';
import { AllowanceInformationResponse, CreateAllowanceInformationRequest } from 'src/Models/Selection/AllowanceClasses';
import {  CreateSelectionChoiceParameterRequest, CreateSelectionInformationRequest, SelectionChoiceParameterResponse, SelectionChoiceResult, SelectionInformationApprove, SelectionInformationApproveResponse, SelectionInformationResponse, SelectionInformations, SelectionResponse } from 'src/Models/Selection/selectionClasses';
import { ResponseModel, ResponseModelArray, TablePageResponse } from 'src/Models/responseMessage.model';
import { CreateLookupFilterRequest, FilterSearchParameter } from 'src/app/shared/component/Models/LookupModels';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class SelectionService {

  private URL_SDUL_ITEM_BASE: string = environment.apiUrl + 'ScheduleItemParameter/';
  private URL_POST_SELECT: string = environment.apiUrl + 'SelectionInformation/';
  private URL_ALLOW_INFORM_GET_BASE: string = environment.apiUrl + 'AllowanceInformation/GetAll/';
  private select_Get_all_URL: string = environment.apiUrl + 'SelectionInformation/GetAll';
  private base_Url: string = environment.apiUrl + 'LookupFilter/';
  private selectionInformationUrl: string = environment.apiUrl + 'SelectionInformation/';
  private AllowanceInformation_Url: string = environment.apiUrl + 'AllowanceInformation/';
  private baseUrl: string = environment.apiUrl + 'JobInformation/';


  constructor(private http: HttpClient) {}


   // ------------------getdataScheduleItemParameter/GetAll by sufyan------------------------

  getAllDataScheduleItem(): Observable<ResponseModelArray<ScheduleItemParameterResponse>> {
    return this.http.get<ResponseModelArray<ScheduleItemParameterResponse>>(`${this.URL_SDUL_ITEM_BASE}GetAll `);
  }
  // ------------------getdataScheduleItemParameter/GetAll by sufyan------------------------

  getAllDataAllowanceInformation(): Observable<ResponseModelArray<AllowanceInformationResponse>> {
    return this.http.get<ResponseModelArray<AllowanceInformationResponse>>(`${this.URL_ALLOW_INFORM_GET_BASE}`);
  }

  PostDataSelectionForm(createSelection:CreateSelectionInformationRequest): Observable<ResponseModel<SelectionResponse>> {
    const apiUrl = `${this.URL_POST_SELECT}`;
    return this.http.post<ResponseModel<SelectionResponse>>(apiUrl, createSelection).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }

  PostApprove(createSelection:SelectionInformationApprove): Observable<ResponseModel<SelectionInformationApproveResponse>> {
    const apiUrl = `${this.URL_POST_SELECT}updateStatusFor-Selection`;
    return this.http.post<ResponseModel<SelectionInformationApproveResponse>>(apiUrl, createSelection).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }


postSelectionInformation(createLead: CreateSelectionInformationRequest): Observable<ResponseModel<SelectionInformationResponse>> {
    const apiUrl = `${this.selectionInformationUrl}`;
    return this.http.post<ResponseModel<SelectionInformationResponse>>(apiUrl, createLead).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }
postAllowanceInformation(createLead: CreateAllowanceInformationRequest): Observable<ResponseModel<AllowanceInformationResponse>> {
    const apiUrl = `${this.AllowanceInformation_Url}`;
    return this.http.post<ResponseModel<AllowanceInformationResponse>>(apiUrl, createLead).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }




  getDataAllSelectionInfo(): Observable<ResponseModelArray<SelectionInformationResponse>> {
    return this.http.get<ResponseModelArray<SelectionInformationResponse>>(`${this.select_Get_all_URL}`);
  }
  getDataId(id: number): Observable<ResponseModelArray<SelectionInformationResponse>> {
    const url = `${this.baseUrl}getSelectionsBy-JobInformationId?jobInformationId=${id}`;
    console.log('Request URL:', url); // Log the URL
    return this.http.get<any>(url);
  }

  PostChoice(createSelection:CreateSelectionChoiceParameterRequest): Observable<ResponseModel<SelectionChoiceParameterResponse>> {
    const apiUrl = `${this.URL_POST_SELECT}create-ChoiceParameter`;
    return this.http.post<ResponseModel<SelectionChoiceParameterResponse>>(apiUrl, createSelection).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }

  getChoice(): Observable<ResponseModelArray<SelectionChoiceParameterResponse>> {
    return this.http.get<ResponseModelArray<SelectionChoiceParameterResponse>>(`${this.select_Get_all_URL}`);
  }
  getSelectionData = new BehaviorSubject<ResponseModel<TablePageResponse<SelectionInformations>>>(null);
  $getSelectionInformation = this.getSelectionData.asObservable();

  cradViewselectedFilterId = new BehaviorSubject<number>(0);
  $cradViewselectedFilterId = this.cradViewselectedFilterId.asObservable();

  cradViewisUserAppliedFilter = new BehaviorSubject<boolean>(false);
  $cradviewisUserAppliedFilter = this.cradViewisUserAppliedFilter.asObservable();


  getAllselectUsersByFilter(filterSearchParameter: FilterSearchParameter) {
    const apiUrl = `${this.base_Url}GetAll-ByFilter`;
    return this.http.post<any>(apiUrl, filterSearchParameter).subscribe(
      (data) => {
        this.getSelectionData.next(data);
      },
      (error) => {
        console.error('Error fetching internal users:', error);
      }
    );
  }
  getAppliedFilterData(appliedFilter: CreateLookupFilterRequest[]) {
    const apiUrl = `${this.base_Url}GetAll-ByLookupFilter`;
    return this.http.post<any>(apiUrl, appliedFilter).subscribe(
      (data) => {
        this.getSelectionData.next(data);
      },
      (error) => {
        console.error('Error fetching internal users:', error);
      }
    );
  }
  CradViewedsetSelectedFilterId(filterId: number) {
    this.cradViewselectedFilterId.next(filterId);
  }

  CradVieweduserAppliedFilter(filter: boolean) {
    this.cradViewisUserAppliedFilter.next(filter);
  }

  // Noor//

SelectionGetbyId(id: number): Observable<ResponseModel<SelectionResponse>> {
    return this.http.get<ResponseModel<SelectionResponse>>(
      `${this.URL_POST_SELECT}getBy-Id?id=${id}`
    );
  }


  getChoiceBy(id: number): Observable<ResponseModel<SelectionChoiceResult>> {
    return this.http.get<ResponseModel<SelectionChoiceResult>>(
      `${this.URL_POST_SELECT}getChoiceBy-selectionId?id=${id}`
    );
  }

  getChoiceSelectionBy(id: number): Observable<ResponseModel<SelectionChoiceParameterResponse>> {
    return this.http.get<ResponseModel<SelectionChoiceParameterResponse>>(
      `${this.URL_POST_SELECT}getChoiceParameterBy-Id?id=${id}`
    );
  }



  private Selectionsresponse = new Subject<any>();
  Selectionsresponses$ = this.Selectionsresponse.asObservable();


  setselectionResponse(response: any) {
    this.Selectionsresponse.next(response);
  }

  deleteSelectionData(globalId: string): Observable<any> {
    return this.http.delete<any>(`${this.URL_POST_SELECT}?globalId=${globalId}`);
  }
  deleteSelectionChoice(globalId: string): Observable<any> {
    return this.http.delete<any>(`${this.URL_POST_SELECT}deleteChoice-globalId?globalId=${globalId}`);
  }

// sufiyan Working !!
private ChoiceSelectionDataSubject = new BehaviorSubject<any>(null);
private attachmentResponseSubject = new BehaviorSubject<AttachmentResponse | null>(null);
public ChoiceSelectionAttachmentResponse = this.attachmentResponseSubject.asObservable();
private ChoiceSelectionCostItemDataSubject = new BehaviorSubject<any>(null);

updateChoiceSelectionData(data: any) {
  this.ChoiceSelectionDataSubject.next(data);
}
getChoiceSelectionDatas() {
  return this.ChoiceSelectionDataSubject.asObservable();
}
updateChoiceSelectionDataAttachment(data: AttachmentResponse) {
  // Emit a value somewhere in your code:
this.attachmentResponseSubject.next(data);
}
getChoiceSelectionDataAttachment() {
  return this.attachmentResponseSubject.asObservable();
}
updateChoiceSelectionDataCostItem(data: any) {
  this.ChoiceSelectionCostItemDataSubject.next(data);
}
getChoiceSelectionDataCostItem() {
  return this.ChoiceSelectionCostItemDataSubject.asObservable();
}

private cards: any[] = [];
private cardss: any[] = [];
private cardsSubject = new Subject<any[]>();
private cardsSubjects = new Subject<any[]>();

addCard(card: any) {
  this.cards.push(card);
  this.cardsSubject.next(this.cards);
}

getCards() {
  return this.cardsSubject.asObservable();
}

addCardChoice(card: any) {
  this.cardss.push(card);
  this.cardsSubjects.next(this.cards);
}

getCardsChoice() {
  return this.cardsSubjects.asObservable();
}





// new Card Set in Choice

// private SelectionDataSubject = new BehaviorSubject<SelectionChoiceResult[]>(null);
// updateselectionData(data: any) {
//   this.SelectionDataSubject.next(data);
// }
// getselectionDatas() {
//   return this.SelectionDataSubject.asObservable();
// }
//
// new Card Set in Choice



// new Card Set in Choice

// Noor Add 01-11-2024//
private selectionStatusSource = new BehaviorSubject<any>(null);
selectionStatus$ = this.selectionStatusSource.asObservable();

updateSelectionStatus(status: any) {
  console.log('updateSelectionStatus called with:', status);
  this.selectionStatusSource.next(status);
}


}
