<div class="d-flex justify-content-center" style="background: rgb(255, 255, 255); min-height: 324.333px;">
    <div nz-row class="d-flex justify-content-center" style="padding-top: 20px; padding-bottom: 48px; row-gap: 0px;">
      <div nz-col [nzXs]="20" [nzSm]="16" [nzMd]="17" style="row-gap: 0px;">
        <div nz-row class="d-flex justify-content-center">
          <div nz-col [nzXs]="8" [nzLg]="7" [nzXl]="6">
            <!-- <img style="width: 78px;height: 78px;" class="text-dark" src="assets/header/icons/Variations.svg"/> -->
            <span role="img" size="78" data-testid="EmptyStateIcon" data-icon-name="BTIcon" class="anticon EmptyStateIcon" style="font-size: 78px;"><svg width="1em" height="1em" viewBox="0 0 32 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" class=""><path fill-rule="evenodd" clip-rule="evenodd" d="M16 3C23.1797 3 29 8.8203 29 16C29 23.1797 23.1797 29 16 29C8.8203 29 3 23.1797 3 16C3 8.8203 8.8203 3 16 3ZM16 5C9.92487 5 5 9.92487 5 16C5 22.0751 9.92487 27 16 27C22.0751 27 27 22.0751 27 16C27 9.92487 22.0751 5 16 5ZM16.9933 8.88338C16.9355 8.38604 16.5128 8 16 8C15.4477 8 15 8.44772 15 9V16L15.0067 16.1166C15.0645 16.614 15.4872 17 16 17H23L23.1166 16.9933C23.614 16.9355 24 16.5128 24 16L23.9933 15.8834C23.9355 15.386 23.5128 15 23 15H17V9L16.9933 8.88338Z"></path></svg></span>
          </div>
        </div>
        <div nz-row class="d-flex justify-content-center" style="row-gap: 0px;text-align: center;">
          <div nz-col>
            <h2 class="famly-inn ant-typography-crad-Header" style="font-size: 24px;line-height: 38px;">Simplify timekeeping with Time Clock</h2>
          </div>
        </div>
        <div nz-row class="d-flex justify-content-center" style="row-gap: 0px;text-align: center;">
          <div nz-col>
            <span class="famly-inn" style="color: #202227;overflow-wrap: break-word;">Manage time, cost, and other project changes more efficiently with Change Orders.</span>
          </div>
        </div>
        <div nz-row class="d-flex justify-content-center" style="row-gap: 0px;margin-left: -4px;margin-right: -4px;margin-top: 24px !important;">
          <div nz-col style="padding-left: 4px; padding-right: 4px;">
            <button nz-button nzType="default">
              <i class="bi bi-box-arrow-up-right"></i>
              <span class="BTN-Font-Famliy ms-2">Learn How</span>
            </button>
          </div>
          <div nz-col class="text-center" style="padding-left: 4px; padding-right: 4px;">
            <button
              nz-button
              nzType="primary"
              class="align-items-center"
              >
              <span class="BTN-Font-Famliy">
                Clock In
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>