import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, throwError } from 'rxjs';
import { CreateShiftInformationRequest, CreateTimeClockSetupRequest, ShiftInformationResponse, TimeClockSetupResponse } from 'src/Models/Time-Clock/clock-in';
import { ResponseModel, ResponseModelArray, TablePageResponse } from 'src/Models/responseMessage.model';
import { FilterSearchParameter } from 'src/app/shared/component/Models/LookupModels';
import { environment } from 'src/environments/environment.prod';
import {  CreateSelectionChoiceParameterRequest, CreateSelectionInformationRequest, SelectionChoiceParameterResponse, SelectionChoiceResult, SelectionInformationResponse, SelectionInformations, SelectionResponse } from 'src/Models/Selection/selectionClasses';
import { SelectionService } from 'src/Service/Selection/selection.service';
@Injectable({
  providedIn: 'root'
})
export class TimeClockService {

  private baseUrl: string = environment.apiUrl + 'ShiftInformation/';
  private baseUrl2: string = environment.apiUrl + 'TimeClockSetup/';
  private Url_LookUpFilter: string = environment.apiUrl + 'LookupFilter/';

  selectedFilterId = new BehaviorSubject<number>(0);
  $selectedFilterId = this.selectedFilterId.asObservable();

  isTimeClockAppliedFilter = new BehaviorSubject<boolean>(false);
  $istimeAppliedFilter = this.isTimeClockAppliedFilter.asObservable();

  getTimeClock = new BehaviorSubject<ResponseModel<TablePageResponse<ShiftInformationResponse>>>(null);
  $getProjectTimeClock = this.getTimeClock.asObservable();





  constructor(private http: HttpClient,private selectionService: SelectionService) { }

  PostTimeClock(jobInformationFormData: CreateShiftInformationRequest): Observable<ResponseModel<ShiftInformationResponse>> {
    const apiUrl = `${this.baseUrl}`;
    return this.http.post<ResponseModel<ShiftInformationResponse>>(apiUrl, jobInformationFormData).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }

  getAllData(applicatonUserId: number): Observable<ResponseModel<ShiftInformationResponse>> {
    const apiUrl = `${this.baseUrl}GetData-ByApplicationId?applicationUserId=${applicatonUserId}`;
    // Send an empty object as the request body
    return this.http.post<ResponseModel<ShiftInformationResponse>>(apiUrl, {});
  }

  PostTimeClockOut(globalId: string): Observable<ResponseModelArray<ShiftInformationResponse>> {
    const apiUrl = `${this.baseUrl}ShiftInformation${globalId}`;
    return this.http.post<ResponseModelArray<ShiftInformationResponse>>(apiUrl, {});
  }

  PostShiftIn(jobInformationFormData: CreateShiftInformationRequest): Observable<ResponseModel<ShiftInformationResponse>>  {
    const apiUrl = `${this.baseUrl}Update-SwitchJobClock`;
    return this.http.post<ResponseModel<ShiftInformationResponse>>(apiUrl, jobInformationFormData);
  }

  getAllClockinClockOut(): Observable<ResponseModel<ShiftInformationResponse>> {
    return this.http.get<ResponseModel<ShiftInformationResponse>>(`${this.baseUrl}GetAll`);
  }


  setSelectedFilterId(filterId: number) {
    this.selectedFilterId.next(filterId);
  }


  getTimeClockShift(filterSearchParameter: FilterSearchParameter) {
    const apiUrl = `${this.Url_LookUpFilter}GetAll-ByFilter`;
    return this.http.post<any>(apiUrl, filterSearchParameter).subscribe(
      (data) => {
        this.getTimeClock.next(data);
      },
      (error) => {
        console.error('Error fetching internal users:', error);
      }
    );
  }


  // postCompanyTimeClock(companyTimClockData: CreateTimeClockSetupRequest): Observable<ResponseModel<TimeClockSetupResponse>>  {
  //   const apiUrl = `${this.baseUrl2}TimeClockSetup`;
  //   return this.http.post<ResponseModel<TimeClockSetupResponse>>(apiUrl, companyTimClockData);
  // }

  postCompanyTimeClock(jobInformationFormData: CreateTimeClockSetupRequest): Observable<ResponseModel<TimeClockSetupResponse>> {
    const apiUrl = `${this.baseUrl2}`;
    return this.http.post<ResponseModel<TimeClockSetupResponse>>(apiUrl, jobInformationFormData).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }

  getAllCompanyClock(): Observable<ResponseModelArray<TimeClockSetupResponse>> {
    return this.http.get<ResponseModelArray<TimeClockSetupResponse>>(`${this.baseUrl2}getData-ByCompany`);
  }

  clockAppliedFilter(filter: boolean) {
    this.isTimeClockAppliedFilter.next(filter);
  }



 

}
