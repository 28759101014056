import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ToDosImportDataFromTemplateComponent } from '../to-dos-import-data-from-template/to-dos-import-data-from-template.component';
import { ToDosComponent } from 'src/app/company-settings/to-dos/to-dos.component';
import { FiltersName } from 'src/app/Core/Enums/filtersName';
import { FilterSideMenuBarService } from 'src/shared/Shared-Services/filter-side-menu-bar.service';
import { ProjectManagementSelectDialogBoxComponent } from '../../project-management-select-dialog-box/project-management-select-dialog-box.component';
import { JobInfoComponentInformation } from 'src/Models/Job-List/Job-Information/Jobinformation';
import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';
import { Subscription } from 'rxjs';
import { StandardViewForAllService } from 'src/Service/standard-view-for-all.service';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { FilterSearchParameter, LookupFilterResponse } from 'src/app/shared/component/Models/LookupModels';
import { StandardColumnSetupResponse, StandardViewResponse } from 'src/Models/LookupStandardview/Standardview';
import { ToDoInformationService } from 'src/Service/Project-Management/to-do-information.service';
import { CreateIsCompletedRequest, TodoGridResponse, ToDoRelatedItemsResult, ToDoResponse } from 'src/Models/Project-Management/projectManagement';
import { ColumnsResizePinProperties } from 'src/Models/InternalUser/internalUserColumnsPinResize';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AddNewGridStandardViewComponent } from 'src/app/shared/component/Modal/add-new-grid-standard-view/add-new-grid-standard-view.component';
import { SVGIcon, fileExcelIcon } from "@progress/kendo-svg-icons";
import { CopyToDosComponent } from '../copy-to-dos/copy-to-dos.component';
import { InternalUserService } from 'src/Service/Internaluser/internal-user.service';
import { LoadingIndicatorService } from 'src/shared/Shared-Services/loading-indicator.service';
import { ProjectManagementSiteDiariesNewDailyLogComponent } from '../../Site-Diaries/project-management-site-diaries-new-daily-log/project-management-site-diaries-new-daily-log.component';
import { JobShortInformation } from 'src/Models/Job-Scratch/JobFromScratch';
import { CommmentConversationComponent } from 'src/app/messaging/Components/Comments/commment-conversation/commment-conversation.component';
import { NewscheduleItemComponent } from '../../schedules-Components/schedule-common/newschedule-item/newschedule-item.component';
@Component({
  selector: 'app-project-management-to-dos-db',
  templateUrl: './project-management-to-dos-db.component.html',
  styleUrls: ['./project-management-to-dos-db.component.css']
})
export class ProjectManagementToDosDbComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  private ToDosubscription: Subscription;
  private ToDoFilterSubscription: Subscription;
  private displayColumnSubscription: Subscription;
  private TodostandardViewFilterSubscription: Subscription
  jobInfoOptions: JobInfoComponentInformation[] = [];
  isCollapsed = false;
  selectedJobId: number = 0;
  selectedJobName: string; // Declare job name variable
  PAGE_ID: number = -1;
  PROJECT_MANG_Todos: string = FiltersName.PROJECT_MANG_Todos;
  displayedColumns: string[] = [];
  selectedFilter: number = -1;
  isFilterApplied: boolean = false;
  lookupFilterResponse: LookupFilterResponse[] = [];
  defaultFilterId_selected: number = 0
  filterSearchParameter: FilterSearchParameter;
  currentPage = 1;
  pageSize = 10;
  isExpanded = false;
  TodoGridData: TodoGridResponse[] = [];
  fixedSelectedColumnNames: string[] = [];
  selected_View_Columns: string[] = [];
  getStandardViewColumnFilterData: StandardColumnSetupResponse[] = [];
  getStandardViewFilterData: StandardViewResponse[] = [];
  selectedValueControl: any;
  public mySelection: string[] = [];

  expandedRows: number[] = [];
  isColumnSticky: boolean[] = new Array(this.displayedColumns.length).fill(false);
  columnSizes: { columnName: string, widthSize: number }[] = [];
  getColumnWidthSize: number[] = [];
  isLoading: boolean = false;
  defaultFilterIdSelected: number = 0;
  selectedToDoId: number;

  selectionFrom: string = 'DB';
  totalRecords = 100;
  pageSizeOptions = [10, 20, 50, 75, 100, 250];

  ids: number[] = [];


  selectedColNameSizePin: ColumnsResizePinProperties[] = [];
  public fileExcelIcon: SVGIcon = fileExcelIcon;
  constructor(private modal: NzModalService, private filterService: FilterSideMenuBarService,
    public localStorage: AccessLocalStorageService,
    private standardViewService: StandardViewForAllService,
    private changeDetectorRef: ChangeDetectorRef,
    private LookupFilterService: LookupFilterService,
    private toDoService: ToDoInformationService,
    private toastService: NzMessageService,
    private internalUserService: InternalUserService,
    private _nzMessageService: NzMessageService,
    private _loadingIndicatorService: LoadingIndicatorService,
    // public resetSubscription: Subscription


  ) {
    if (this.ToDoFilterSubscription) {
      this.ToDoFilterSubscription.unsubscribe();
    }
    if (this.ToDosubscription) {
      this.ToDosubscription.unsubscribe();
    }
    if (this.TodostandardViewFilterSubscription) {
      this.TodostandardViewFilterSubscription.unsubscribe();
    }
    console.clear();
  }


  ngOnInit(): void {
    this.internalUserService.setSelectedFilterId(0);
    this.unsubscribeFromPreviousSubscriptions();

    this.PAGE_ID = 6;
    this.selectedJobId = this.localStorage.getJobId();
    this.localStorage.getJobName();

    this.displayedColumns = [];

    // Subscribe to display column updates
    this.displayColumnSubscription = this.standardViewService.$displaySelectedColApply.subscribe(colName => {
      if (colName.length > 0) {
        this.displayedColumns = colName;
        this.changeDetectorRef.detectChanges();
      }
    });

    this.subscribeToFilterTypeUpdates();

    // Fetch filters and data
    this.getStandardFilterByFormPageId(this.PAGE_ID, this.selectionFrom);
    this.getStandardViewFilterByFormPageId(this.PAGE_ID);

    this.toDoService.$isUserAppliedFilter.subscribe(filter => {
      this.isFilterApplied = filter ? filter : filter;
    });

  }

  private unsubscribeFromPreviousSubscriptions() {
    if (this.ToDoFilterSubscription) {
      this.ToDoFilterSubscription.unsubscribe();
    }
    if (this.ToDosubscription) {
      this.ToDosubscription.unsubscribe();
    }
    if (this.TodostandardViewFilterSubscription) {
      this.TodostandardViewFilterSubscription.unsubscribe();
    }
  }

  private subscribeToFilterTypeUpdates() {
    this.subscriptions.push(
      this.standardViewService.$selectedStandardFilterType.subscribe(standardType => {
        this.selectedFilter = standardType != -1 ? standardType : standardType;
      })
    );
  }


  // Selected Job Id From Left Side Job List
  // getSelectedJobId(item: number) {
  //   if (this.selectedJobId !== item) { // Check if the selected job ID has changed
  //     this.selectedJobId = item;
  //     this.TodoGridData = null;
  //     this.getStandardFilterByFormPageId(this.PAGE_ID, this.selectionFrom);
  //     console.log("selectedJobId : ", this.selectedJobId);
  //   }
  // }




  // Selected Job Id From Left Side Job List
  getSelectedJobId(item: number) {
    if (this.selectedJobId !== item) { // Check if the selected job ID has changed
      this.selectedJobId = item;
      this.TodoGridData = null;
      this.getStandardFilterByFormPageId(this.PAGE_ID, this.selectionFrom);
      console.log("selectedJobId : ", this.selectedJobId);
    }
  }



  getStandardFilterByFormPageId(pageId: number, type: string) {
    this.unsubscribeFromPreviousSubscriptions();
    this._loadingIndicatorService.show(); // Show the loading indicator

    this.LookupFilterService.getStandardFilterByFormPageId(pageId);
    this.LookupFilterService.$getStandardFilter.subscribe(result => {
      if (result) {
        this.lookupFilterResponse = result.result;

        // Set the default filter
        const defaultFilter = this.lookupFilterResponse.find(filter => filter.isSetAsDefault);
        if (defaultFilter) {
          this.toDoService.setSelectedFilterId(defaultFilter.id);
          this.defaultFilterId_selected = defaultFilter.id;

          // Check and set the selected filter ID from local storage
          const id = this.localStorage.getSelectedFilterId() || defaultFilter.id;
          this.toDoGridTableData(1, this.PAGE_ID, this.defaultFilterId_selected, this.currentPage, this.pageSize, this.selectedJobId);
        }
      }
      this._loadingIndicatorService.hide(); // Hide loading indicator when done
    });
  }




  toDoGridTableData(companyId: number, formNameId: number, lookUpFilterId: number, page: number, pageSize: number, jobInformationId?: number) {
    this.filterSearchParameter = {
      CompanyParameterId: companyId,
      FormNameId: formNameId,
      LookUpFilterId: lookUpFilterId,
      page: page,
      pageSize: pageSize,
      jobInformationId: jobInformationId ?? this.selectedJobId
    };

    this.toDoService.getAlltodoUsersByFilter(this.filterSearchParameter);
    this.ToDosubscription = this.toDoService.$getToDoInformation.subscribe(result => {
      if (result) {
        this.totalRecords = result?.result?.rowCount;
        this.TodoGridData = result?.result?.rows;

        this.TodoGridData?.forEach(item => {
          (item as TodoGridResponse).relatedItemsParsed = this.parseRelatedItems(item.RelatedItems);
          (item as TodoGridResponse).parsedAttachments = this.parseAttachments(item.Attachments);
        });
      }
    });
  }





  getStandardViewFilterByFormPageId(pageId: number) {
    this.unsubscribeFromPreviousSubscriptions();

    this.displayedColumns = [];
    this.fixedSelectedColumnNames = [];

    this._loadingIndicatorService.show(); // Show the loading indicator
    this.standardViewService.getStandardViewFilterByFormPageId(pageId);
    this.standardViewService.getStandardViewFilterColumnsByFormPageId(pageId);

    this.TodostandardViewFilterSubscription = this.standardViewService.$getStandardViewFilter.subscribe(result => {
      this._loadingIndicatorService.hide(); // Hide the loading indicator after data is received
      if (result) {
        this.displayedColumns = [];
        this.selected_View_Columns = [];
        this.getStandardViewFilterData = result.result;

        console.warn('columns', result.result);

        let defaultView = this.getStandardViewFilterData.find(view => view.isSetAsDefault === true).standardViewParameters;
        this.selectedValueControl = this.getStandardViewFilterData.find(view => view.isSetAsDefault === true).id;

        defaultView.forEach(viewItem => {
          if (viewItem.standardColumnSetup && viewItem.standardColumnSetup.displayColumnName) {
            this.selected_View_Columns.push(viewItem.standardColumnSetup.displayColumnName);
          }
        });
        this.displayedColumns = this.selected_View_Columns;
      }
    });

    this.standardViewService.$getStandardViewColumn.subscribe(result => {
      if (result) {
        this.getStandardViewColumnFilterData = result.result;
        let fixedCol = this.getStandardViewColumnFilterData.filter(x => x.isSelected === true);

        fixedCol.forEach(x => {
          this.fixedSelectedColumnNames.push(x.displayColumnName);
        });
      }
    });
  }




  toggleColumnSticky(index: number) {
    this.isColumnSticky[index] = !this.isColumnSticky[index];
    if (this.isColumnSticky[index]) {
      for (let i = 0; i < index; i++) {
        this.isColumnSticky[i] = true;
      }
    }
  }


  splitRelatedItems(relatedItems: string): string[] {
    return relatedItems ? relatedItems.split(',') : [];
  }



  toggleExpand() {
    this.isExpanded = !this.isExpanded; // Toggle the expanded state
  }

  saveColumnSizes(event: any) {
    event.forEach(col => {
      const columnName = col.column.field;
      const widthSize = col.newWidth;
      const existingIndex = this.columnSizes.findIndex(item => item.columnName === columnName);

      if (existingIndex !== -1) {
        this.columnSizes[existingIndex].widthSize = widthSize;
      } else {
        this.columnSizes.push({ columnName, widthSize });
      }
    });

  }




  reInitReset() {
    this.ngOnInit();
    this.selectedFilter = -1;
  }


  saveEditStandardView() {
    this.standardViewService.$createStandardViewRequest.subscribe(formData => {
      if (formData && formData !== null) {
        this.standardViewService.postData(formData)
          .subscribe(
            () => {
              setTimeout(() => {
                // this.loadingIndicateService.hide();
                this.isLoading = true;
                this.toastService.success('Standard View Updated Successfully');
                this.standardViewService.getStandardViewFilterByFormPageId(this.PAGE_ID);
                this.selectedFilter = -1;
              }, 2000);
            },
            (error) => {
              console.error('Error:', error);
              //this.loadingIndicateService.hide();
              this.isLoading = false;
            }
          );
      }
    });
  }


  onFilterChange(id: number) {

    this.selectedFilter = -1;
    this.displayedColumns = [];
    this.selected_View_Columns = [];
    let selectedView = this.getStandardViewFilterData.find(view => view.id === id).standardViewParameters;
    selectedView.forEach(viewItem => {
      if (viewItem.standardColumnSetup && viewItem.standardColumnSetup.displayColumnName) {
        this.selected_View_Columns.push(viewItem.standardColumnSetup.displayColumnName);
        this.displayedColumns = this.selected_View_Columns;
      }
    });
  }



  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }


  toDosModalOpen(select: string): void {
    const jobId = this.localStorage.getJobId();
    if (jobId !== null) {
      // modalRef.componentInstance.cancel.subscribe(() => {
      //   modalRef.destroy();
      // });
    } else {
      const modalRef = this.modal.create({
        nzWidth: '32%',
        nzStyle: {
          top: '36%',
        },
        nzContent: ProjectManagementSelectDialogBoxComponent,
        nzData: {
          type: select,
          leads: this.jobInfoOptions
        },
        nzFooter: null
      });
      modalRef.componentInstance.cancel.subscribe(() => {
        modalRef.destroy();
      });
    }
  }





  isDrawerVisible = false;

  // Method to open the drawer
  openDrawer(select: string, toDoId: number | null): void {
    const jobId = this.localStorage.getJobId();

    // Check both conditions: jobId is not a number and toDoId is null
    if (typeof jobId !== 'number' && toDoId === null) {
      // Open the ProjectManagementSelectDialogBoxComponent modal
      const modalRef = this.modal.create({
        nzWidth: '32%',
        nzStyle: { top: '36%' },
        nzContent: ProjectManagementSelectDialogBoxComponent,
        nzFooter: null,
      });

      // Pass the `select` argument to the component
      modalRef.componentInstance.argument = select;

      // Handle modal close events
      modalRef.componentInstance.cancel.subscribe(() => {
        modalRef.destroy();
      });

      modalRef.componentInstance.cancelWithData.subscribe((res: JobShortInformation) => {
        modalRef.destroy();
        // Set drawer data based on the selected job information
        this.isDrawerVisible = true;
        this.selectedJobId = res.id;
        this.selectedJobName = res.name;
        this.selectedToDoId = toDoId; // Set the selectedToDoId
      });
    } else {
      // Open the drawer directly if the conditions are not met
      this.isDrawerVisible = true;
      this.selectedJobId = this.localStorage.getJobId();
      this.selectedJobName = this.localStorage.getJobName();
      this.selectedToDoId = toDoId; // Set the selectedToDoId
    }
  }




  closeDrawer(): void {
    this.isDrawerVisible = false;
    let filterId: number = 0;
    this.toDoService.$selectedFilterId.subscribe(v => {
      if (v) {
        filterId = v;
      }
    });
    this.isLoading = true;
    setTimeout(() => {
      this.defaultFilterIdSelected = filterId;
      this.toDoGridTableData(1, this.PAGE_ID, filterId, this.currentPage, this.pageSize, this.selectedJobId);
      this.isLoading = false;
    }, 100);
  }





  ImportModelOpen(): void {
    const modalRef = this.modal.create({
      nzContent: ToDosImportDataFromTemplateComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  ToDodsSetting(): void {
    const modalRef = this.modal.create({
      nzContent: ToDosComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }

  openFilter(component: string, pageId: number) {
    this.filterService.toggleFilter(component, pageId);
  }






  // footer methods

  openStandardViewFilterComponent(isSaveAs?: string) {
    let isInputVisible: boolean = false;
    if (isSaveAs === 'saveAs') {
      isInputVisible = true;
    } else {
      isInputVisible = false;
    }
    this.selectedColNameSizePin = [];
    for (let i = 0; i < this.displayedColumns.length; i++) {
      const item = this.displayedColumns[i];
      const isPinValue = this.isColumnSticky[i];
      let widthSize = 0;
      if (this.columnSizes.length > 0) {
        for (let i = 0; i < this.columnSizes.length; i++) {
          const columnName = this.columnSizes[i].columnName;
          const columnNameSize = this.columnSizes[i].widthSize;
          if (columnName === item) {
            widthSize = columnNameSize;
            break;
          }
        }
      }

      if (widthSize === 0) {
        widthSize = 180;
      }

      this.selectedColNameSizePin.push({
        displayColumnName: item,
        resizeColumnWidth: widthSize, //  "200px", // Set default width here
        isPin: isPinValue == true ? isPinValue : false
      });
    }
    const modalRef = this.modal.create({
      nzContent: AddNewGridStandardViewComponent,
      nzData:
      {
        pageId: this.PAGE_ID,
        column: this.getStandardViewColumnFilterData,
        displayCol: this.displayedColumns,
        viewList: this.getStandardViewFilterData,
        selectedColNameSizePin: this.selectedColNameSizePin,
        selectedStandardView: this.selectedValueControl,
        fixedCol: this.fixedSelectedColumnNames,
        isSaveAs: isInputVisible
      },

    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }




  EditSiteDiariesDailyLog(id: number, JobInformationId: number, jobName: string): void {
    if (id) {
      const modalRef = this.modal.create({
        nzContent: ProjectManagementSiteDiariesNewDailyLogComponent,
        nzFooter: null,
      });
      modalRef.componentInstance.selectedDailyLogId = id;
      modalRef.componentInstance.selectedJobId = JobInformationId;
      modalRef.componentInstance.selectedJobName = jobName;



      modalRef.componentInstance.cancel.subscribe(() => {
        modalRef.destroy();
        this.isFilterApplied = true; // Set the flag to true after the first call
        const lookUpFilter = this.localStorage.getSelectedFilterId();
        const companyId = 1;
        const formNameId = this.PAGE_ID;
        const lookUpFilterId = lookUpFilter;
        const page = this.currentPage;
        const pageSize = this.pageSize;

        // Call grid data refresh method
        this.toDoGridTableData(companyId, formNameId, lookUpFilterId, page, pageSize);
      });
    }

  }



  clearSelection(): void {
    this.mySelection = [];
    this.ids = [];
  }




  // Check box Selected then show button Copy open New Form and Against of Jop Create toDo

  copyToDos(): void {
    const modalRef = this.modal.create({
      nzContent: CopyToDosComponent,
      nzFooter: null,
      nzTitle: null,
      nzCentered: true
    });
    modalRef.componentInstance.ids = this.ids
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    modalRef.componentInstance.onSaveEmit.subscribe(() => {
      this.clearSelection();
      let filterId: number = 0;
      this.toDoService.$selectedFilterId.subscribe(v => {
        if (v) {
          filterId = v;
        }
      });
      this.isLoading = true;
      setTimeout(() => {
        this.defaultFilterIdSelected = filterId;
        this.toDoGridTableData(1, this.PAGE_ID, filterId, this.currentPage, this.pageSize, this.selectedJobId);
        this.isLoading = false;
      }, 100);
    });
  }
  ngOnDestroy() {
    this.LookupFilterService.getStandardFilter.next(null);
    localStorage.removeItem('Selected_Filter_Id');
    if (this.ToDoFilterSubscription) {
      this.ToDoFilterSubscription.unsubscribe();
    }
    if (this.ToDosubscription) {
      this.ToDosubscription.unsubscribe();
    }
    if (this.TodostandardViewFilterSubscription) {
      this.TodostandardViewFilterSubscription.unsubscribe();
    }
    console.clear();
  }



  // Circle Check Box Is Complete
  getPriorityStatus(priority: string): string {
    switch (priority) {
      case 'LOW':
        return 'low';
      case 'HIGH':
        return 'high';
      case 'HIGHEST':
        return 'Highest';
      default:
        return '';
    }
  }

  showAllTags = false;
  showAllAssign = false;

  toggleTags() {
    this.showAllTags = !this.showAllTags;
  }

  toggleAssign() {
    this.showAllAssign = !this.showAllAssign; // Toggle the visibility of all assigned users
  }

  circleCheckBox(globalId: string, isComplete: boolean) {
    const progressPayload = {
      isCompleted: isComplete,
    };

    this._loadingIndicatorService.show();

    this.toDoService.updateIsComplete(globalId, progressPayload).subscribe(
      (res) => {
        this._loadingIndicatorService.hide();
        console.info('IsComplete', res.result);

        if (res.result) {
          if (isComplete === true) {
            // If the item is marked complete
            this._nzMessageService.success('To-Do Marked as Complete!');
          } else if (isComplete === false) {
            this._nzMessageService.success('To-Do Marked as Incomplete!');
          }

          // Reload the grid after success
          this.toDoGridTableData(
            1,
            this.PAGE_ID,
            6,
            this.currentPage,
            this.pageSize
          );
        }
      },
    );
  }

  // Conversion Modal

  conversationModelOpen(response: TodoGridResponse): void {
    const modalRef = this.modal.create({
      nzContent: CommmentConversationComponent,
      nzTitle: '<h1 nz-typography>Conversation</h1>',
      nzWidth: '62%',
      nzBodyStyle: { background: '#f1f4fa', padding: '13px', 'max-height': '80vh', 'overflow-x': 'hidden' },
      nzStyle: { top: '20px' },
      nzFooter: null,
    });

    modalRef.componentInstance.takeResponseDynamic = response;
    modalRef.componentInstance.formNameId = 6;
    modalRef.componentInstance.componentTypeDynamic = 'Todo';
    modalRef.componentInstance.componentSelectedJobName = this.localStorage.getJobName();
    modalRef.componentInstance.componentTitleDynamic = response.Title;

    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });


    modalRef.afterClose.subscribe(() => {

      this._loadingIndicatorService.show();
      modalRef.destroy();
      this.isFilterApplied = true; // Set the flag to true after the first call
      const lookUpFilter = this.localStorage.getSelectedFilterId();
      const companyId = 1;
      const formNameId = this.PAGE_ID;
      const lookUpFilterId = 0;
      const page = this.currentPage;
      const pageSize = this.pageSize;

      // Call grid data refresh method
      this.toDoGridTableData(companyId, formNameId, lookUpFilterId, page, pageSize);
      this._loadingIndicatorService.hide();
    });

  }








  isAllDataShownTodoPagination(): boolean {
    return this.totalRecords <= this.pageSize;
  }


  // Pagination Workh Haseb

  onPageChange(page: number): void {
    this.currentPage = page;
    let filterId: number = 0;
    this.toDoService.$selectedFilterId.subscribe(v => {
      if (v) {
        filterId = v;
      }
    });
    this.isLoading = true;
    setTimeout(() => {
      this.defaultFilterIdSelected = filterId;
      this.toDoGridTableData(1, this.PAGE_ID, filterId, page, this.pageSize, this.selectedJobId);
      this.isLoading = false;

    }, 100);
  }


  onPageSizeChange(size: number): void {
    this.isColumnSticky = new Array(this.displayedColumns.length).fill(false);
    this.pageSize = size;
    let filterId: number = 0;
    this.toDoService.$selectedFilterId.subscribe(v => {
      if (v) {
        filterId = v;
      }
    });
    this.isLoading = true;
    setTimeout(() => {
      this.defaultFilterIdSelected = filterId;
      this.toDoGridTableData(1, this.PAGE_ID, filterId, this.currentPage, this.pageSize, this.selectedJobId);
      this.isLoading = false;

    }, 100);



    this.toDoService.$selectedFilterId.subscribe(v => {
      if (v) {
        filterId = v;
      }
    });
    this.isLoading = true;
    setTimeout(() => {
      this.defaultFilterIdSelected = filterId;
      this.toDoGridTableData(1, this.PAGE_ID, filterId, this.currentPage, this.pageSize, this.selectedJobId);
      this.isLoading = false;
    }, 100);




  }



  parsedAttachments: { id: number; name: string; extension: string; size: string; filePath: string }[] = [];

  // Method to parse the attachments JSON string
  // Modify parseAttachments to always return an array
  parseAttachments(attachmentsString: string): { id: number; name: string; extension: string; size: string; filePath: string }[] {
    if (attachmentsString) {
      try {
        return JSON.parse(attachmentsString);
      } catch (error) {
        console.error('Error parsing attachments:', error);
      }
    }
    return []; // Always return an array, even if parsing fails
  }


  // Method to populate parsedAttachments based on dataItem.Attachments
  populateParsedAttachments(dataItem: any) {
    if (dataItem.Attachments) {
      this.parseAttachments(dataItem.Attachments);
    } else {
      this.parsedAttachments = [];
    }
  }


  getKeys(item: any): string[] {
    return Object.keys(item);
  }

  parseRelatedItems(relatedItemsString: string) {
    if (relatedItemsString) {
      return JSON.parse(relatedItemsString);
    }
    return []; // Return an empty array if no related items are found
  }


  EditSiteDiariesDailyLogByRelatedItemsDropDown(id: number, type: string) {
    if (id && type === 'Daily Log') {
      const modalRef = this.modal.create({
        nzContent: ProjectManagementSiteDiariesNewDailyLogComponent,
        nzFooter: null,
      });
      modalRef.componentInstance.selectedDailyLogId = id;
      modalRef.componentInstance.selectedJobId = this.localStorage.getJobId();
      modalRef.componentInstance.selectedJobName = this.localStorage.getJobName();

      modalRef.componentInstance.cancel.subscribe(() => {
        modalRef.destroy();
        let filterId: number = 0;
        this.toDoService.$selectedFilterId.subscribe(v => {
          if (v) {
            filterId = v;
          }
        });
        this.isLoading = true;
        setTimeout(() => {
          this.defaultFilterIdSelected = filterId;
          this.toDoGridTableData(1, this.PAGE_ID, filterId, this.currentPage, this.pageSize, this.selectedJobId);
          this.isLoading = false;
        }, 100);
      });
    }

    else {
      const modalRef = this.modal.create({
        nzContent: NewscheduleItemComponent,
        nzFooter: null,
        nzTitle: null,
        nzStyle: { top: '20px' },
      });

      // Pass data to the modal instance
      modalRef.componentInstance.scheduleId = id;
      modalRef.componentInstance.selectedJobId =
        this.localStorage.getJobId();
      modalRef.componentInstance.selectedJobName =
        this.localStorage.getJobName();
      modalRef.componentInstance.selectedColorId =
        this.localStorage.getJobColorId();

      // Subscribe to cancel event to destroy modal
      modalRef.componentInstance.cancel.subscribe(() => {
        modalRef.destroy();
        let filterId: number = 0;
        this.toDoService.$selectedFilterId.subscribe(v => {
          if (v) {
            filterId = v;
          }
        });
        this.isLoading = true;
        setTimeout(() => {
          this.defaultFilterIdSelected = filterId;
          this.toDoGridTableData(1, this.PAGE_ID, filterId, this.currentPage, this.pageSize, this.selectedJobId);
          this.isLoading = false;
        }, 100);
      });
    }


  }



  getFileIcon(extension: string): string {
    const fileIcons: { [key: string]: string } = {
      '.png': 'fa-solid fa-file-image text-success',      // Image file icon
      '.jpg': 'fa-solid fa-file-image text-success',
      '.jpeg': 'fa-solid fa-file-image text-success',
      '.gif': 'fa-solid fa-file-image text-success',
      '.pdf': 'fa-solid fa-file-pdf text-danger',        // PDF file icon
      '.doc': 'fa-solid fa-file-word text-primary',       // Word file icon
      '.docx': 'fa-solid fa-file-word text-primary',
      '.xls': 'fa-solid fa-file-excel text-success',      // Excel file icon
      '.xlsx': 'fa-solid fa-file-excel text-success',
      '.txt': 'fa-solid fa-file-alt text-muted',          // Text file icon
      '.mp4': 'fa-solid fa-file-video text-warning',      // Video file icon
      '.avi': 'fa-solid fa-file-video text-warning',
      // Add more file types and icons as needed
    };

    return fileIcons[extension.toLowerCase()] || 'fa-solid fa-file text-secondary'; // Default icon for unknown file types
  }



  // showDeleteConfirm(): void {
  //   const selectedCount = this.mySelection.length;
  //   this.modal.confirm({
  //     nzTitle: `<h1>Delete ${selectedCount} To-Do${selectedCount > 1 ? 's' : ''}?</h1>`,
  //     nzOkText: `Delete ${selectedCount} To-Do${selectedCount > 1 ? 's' : ''}`,
  //     nzOkDanger: true,
  //     nzOnOk: () => this.deleteRowTodo(),
  //     nzCancelText: 'Cancel',
  //     nzOnCancel: () => this.clearSelection(),
  //     nzCentered: true,
  //     nzWidth: '500px',
  //     nzBodyStyle: { 'border-radius': '30px', 'height': 'auto', 'padding': '45px' },
  //     nzCloseIcon: '',
  //     nzIconType: '',

  //   });
  // }

  // selectionModelOpen(select: string): void {
  //   const modalRef = this.modal.create({
  //     nzContent: ProjectManagementSelectDialogBoxComponent,
  //     nzData: {
  //       type: select,
  //       leads: this.jobInfoOptions

  //     },
  //     nzFooter: null
  //   });
  //   modalRef.componentInstance.cancel.subscribe(() => {
  //     modalRef.destroy();
  //   });

  // }

  // selectionChange(event: any): void {
  //   if (event && event.length > 0) {
  //     this.globalIds = [];
  //     this.ids = [];
  //     event.forEach((id: number) => {
  //       const selectedRow = this.TodoGridData.find(row => row.Id === Id);
  //       if (selectedRow) {
  //         this.globalIds.push(selectedRow.toDoParameters[0]?.GlobalId); // Add globalId to array
  //         this.ids.push(selectedRow.toDoParameters[0]?.Id); // Add globalId to array
  //         console.log('Selected row data', selectedRow);
  //         console.log('Selected globalIds', this.globalIds);
  //         console.log('Selected ids', this.ids);
  //       }
  //     });

  //   } else {
  //     console.log('No rows selected.');
  //     this.clearSelection();
  //   }
  // }



  // deleteRowTodo() {
  //   this.toDoService.deleteRowTodoRange(this.globalIds).subscribe(res => {
  //     this.toastService.success('To-Do Deleted!');
  //     this.clearSelection();
  //     let filterId: number = 0;
  //     this.toDoService.$selectedFilterId.subscribe(v => {
  //       if (v) {
  //         filterId = v;
  //       }
  //     });
  //     this.isLoading = true;
  //     setTimeout(() => {
  //       this.defaultFilterIdSelected = filterId;
  //       this.toDoGridTableData(1, this.PAGE_ID, filterId, this.currentPage, this.pageSize, this.selectedJobId);
  //       this.isLoading = false;
  //     }, 100);
  //   })
  // }




}


