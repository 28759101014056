import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SelectionService } from './selection.service';
import { SelectionChoiceResult } from 'src/Models/Selection/selectionClasses';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SelectionChoiceServService {

  constructor(
    private http: HttpClient,
    private selectionService: SelectionService
  ) { }

  private selectionDataSubject = new BehaviorSubject<SelectionChoiceResult | null>(null);
  public selectionData$: Observable<SelectionChoiceResult | null> = this.selectionDataSubject.asObservable();

  fetchChoiceSelectionRervResponse(newSelectionId: number): void {
    this.selectionService.getChoiceBy(newSelectionId)?.subscribe((res) => {
      if (res && res.result) {
        console.log("API response:", res);
        const selectionResponses = res.result; 
        this.selectionDataSubject.next(selectionResponses); 
      } else {
        console.error('Invalid response from API:', res);
      }
    });
  }

  updateSelectionData(selectionData: SelectionChoiceResult): void {
    this.selectionDataSubject.next(selectionData);
  }


}
