<nz-modal
  nzWidth="80%"
  [(nzVisible)]="isActivityTemVisible"
  [nzTitle]="modalTitle"
  [nzFooter]="modalFooter"
  [nzBodyStyle]="{
    background: '#f1f4fa',
    padding: '13px',
    'max-height': '75vh',
    'overflow-y': 'auto'
  }"
  [nzStyle]="{ top: '30px' }"
  (nzOnCancel)="showConfirmCardLeadActivityTemplate()"
>
  <!-- <form nz-form [formGroup]="validateForm" (ngSubmit)="submitForm()"> -->

  <!--
      <ng-template #errorDescriptionTemplate>
        <ul>
          <li class="sub-text" *ngFor="let error of errorFields">
            <span class="d-flex align-items-center">
              <p>{{ error.label }}:</p>
              <p>{{ error.message }}</p>
            </span>
          </li>
        </ul>
      </ng-template> -->

  <ng-template #modalTitle>
    <div class="row">
      <h1 class="p-0 m-0 react">Lead Activity Template</h1>
    </div>
  </ng-template>
  <div class="content" *nzModalContent>
    <div class="top-of-modal mb-2"></div>
    <nz-alert
      class="mb-3 error-message"
      *ngIf="isSubmitted && errorFields.length > 0"
      nzType="error"
      nzShowIcon
      [nzMessage]="message"
      [nzDescription]="errorDescriptionTemplate"
    ></nz-alert>
    <ng-template #message>
      <p class="mt-1">Please correct the following fields:</p>
    </ng-template>
    <ng-template #errorDescriptionTemplate>
      <ul>
        <li class="sub-text" *ngFor="let error of errorFields">
          <span class="d-flex align-items-center">
            <p>{{ error.label }}:</p>
            <p>{{ error.message }}</p>
          </span>
        </li>
      </ul>
    </ng-template>
      <div class="spinner-wrapper" *ngIf="isLoading">
        <nz-spin nzSimple [nzSpinning]="true" [nzSize]="'large'"></nz-spin>
      </div>

    <nz-card nzBorderless class="mb-2" [nzTitle]="activity">
      <ng-template #activity>
        <h5 class="famly-inn Card-Title-Set">General Information</h5>
      </ng-template>
      <div class="row">
        <div class="col-md-6">
          <label class="sub-text" nzFor="name"
            >Template Name <span class="text-danger">*</span></label
          >
          <nz-form-control>
            <input
              #titleInput
              nz-input
              [(ngModel)]="formFieldValues.name"
              name="name"
              required
              (ngModelChange)="onFieldChange('name', $event)"
              [nzStatus]="validationStates['name']"
              autocomplete="off"
              class="formField"
            />
          </nz-form-control>
          <div *ngIf="validationStates['name'] === 'error'" class="text-danger">
            {{ validationMessages["name"] }}
          </div>
          </div>
          <div class="col-md-6">
            <label class="sub-text" nzFor="applicationUserId"
              >SalesPerson</label
            >
            <nz-form-item>
              <nz-form-control [nzErrorTip]="'Sales Person is required'">
                <nz-select
                nzShowSearch
                [nzCustomTemplate]="defaultTemplate"
                [(ngModel)]="formFieldValues['applicationUserId']"
                name="applicationUserId"
                [style.width.%]="100"
                (ngModelChange)="onUserChanges($event)"
                nzPlaceHolder="Select Application User"
              >
                <nz-option
                  nzCustomContent
                  *ngFor="let option of assignedUser"
                  [nzLabel]="option.fullName"
                  [nzValue]="option.id"
                >
                  <span class="initials-circle">
                    {{ getInitias(option.fullName) }} <!-- Pass fullName directly -->
                  </span>
                  <ng-container>
                    <span class="user-name">{{ option.fullName }}</span>
                  </ng-container>
                </nz-option>
              </nz-select>

              <ng-template #defaultTemplate let-selected>
                <div class="color-item d-flex align-items-center">
                  <span class="initials-circle">
                    <!-- Pass the selected full name string here -->
                    {{ getInitias(selected.nzLabel) }}
                  </span>
                  <span class="user-name">{{ selected.nzLabel }}</span>
                </div>
              </ng-template>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <label class="sub-text" nzFor="description"
              >Template Description</label
            >
            <nz-form-item>
              <nz-form-control>
                <ckeditor
                [(ngModel)]="formFieldValues['description']"
                name="description"
                [editor]="Editor"
                [config]="config"
                class="size"
                id="editor"
              >
              </ckeditor>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </nz-card>
      <ng-template #LeadActivityCard>
        <h3 nz-typography>Lead Activities</h3>
      </ng-template>
        <nz-card
          class="border-0"
          [nzTitle]="LeadActivityCard"
          [nzExtra]="leadActivityExtraCard"
        >
        <ng-template #ShowCardInitialTime>
          <div class="row mb-0 mt-1">
            <div class="col-lg-8 offset-lg-2">
              <div class="col text-center mb-2">
                <svg width="5em" height="5em" viewBox="0 0 32 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" class=""><path fill-rule="evenodd" clip-rule="evenodd" d="M4 10C4 6.47671 6.61863 3.50236 10.1202 3.05489C11.0031 2.94805 11.8502 3.43795 12.1979 4.25644L14.7079 10.1132C14.9718 10.7289 14.9099 11.4358 14.5431 11.9963L12.463 15.173L12.62 15.479C13.5148 17.1479 14.8859 18.513 16.5534 19.3976L16.865 19.555L19.9945 17.4686C20.51 17.1248 21.1527 17.0411 21.7348 17.2346L21.8919 17.2943L27.7435 19.8022C28.5621 20.1498 29.052 20.9969 28.9443 21.8864C28.4977 25.3814 25.5233 28 22 28C12.0589 28 4 19.9411 4 10ZM10.358 5.04002L10.1419 5.07352C7.75891 5.48776 6 7.55955 6 10C6 18.8366 13.1634 26 22 26C24.5167 26 26.6414 24.1295 26.9597 21.6414L21.104 19.1326L17.9791 21.2163C17.396 21.6098 16.6486 21.6663 16.0059 21.3617C13.679 20.2366 11.7969 18.3631 10.6579 16.0344C10.3549 15.4027 10.4062 14.6582 10.7901 14.0785L12.8696 10.9011L10.358 5.04002Z"></path></svg>
              </div>
              <span class="text-center">
                <h1 class="fw-medium famly-inn mb-4">
                  Engage your leads with activities
                </h1>
              </span>
              <p class="text-center mb-4" style="font-size: 16px">
                Track calls, emails, and meeting notes for leads in
                one place.
              </p>
              <div class="col mt-2 text-center">
                <button
                  nz-button
                  nzType="default"
                  class="me-2 align-items-center"
                >
                  <i class="bi bi-box-arrow-up-right fs-6 me-2"></i
                  >Learn How
                </button>
                <button
                  nz-button
                  class=" "
                  (click)="openConfirmDialogActivity(false, 'ACTIVITY')"
                  nzType="primary"
                  >
                  Add a Lead Activity
                </button>
              </div>
            </div>
          </div>
          </ng-template>
            <div class="row p-0">
                <ng-container
                *ngIf="
                  GirdBindTemplateActivity &&
                  GirdBindTemplateActivity &&
                  GirdBindTemplateActivity?.length > 0;
                  else ShowCardInitialTime
                "
              >
                <div class="activity-table-container">


                <nz-table
                #rowSelectionTable
                nzShowSizeChanger
                [nzShowPagination]="false"
                  [nzData]="GirdBindTemplateActivity"

                  nzSize="middle"
                  >
                  <!-- (nzCurrentPageDataChange)="onCurrentPageDataChange($event)" -->
                  <thead style="background-color: #f1f4fa;">
                    <tr>
                      <th
                        nzWidth="5%"
                        [autofocus]=""
                        [(nzChecked)]="checked"
                        [nzIndeterminate]="indeterminate"
                        (nzCheckedChange)="onAllChecked($event)"
                      ></th>
                      <th nzWidth="5%"></th>
                      <th nzWidth="5%"></th>
                      <th nzWidth="20%">Type</th>
                      <th>Communication</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let activity of GirdBindTemplateActivity">
                    <tr >
                      <td nzWidth="5%">
                        <span
                          nz-icon
                          nzType="caret-right"
                          *ngIf="activity?.Description && !expandSet.has(activity.Id)"
                          (click)="onExpandChange(activity.Id)"
                        ></span>
                        <span
                          nz-icon
                          nzType="caret-down"
                          *ngIf="activity?.Description && expandSet.has(activity.Id)"
                          (click)="onExpandChange(activity.Id)"
                        ></span>
                      </td>





                      <td
                        nzWidth="5%"
                        [nzChecked]="setOfCheckedId.has(activity.Id)"
                        (nzCheckedChange)="onItemChecked(activity.Id, $event)"
                      ></td>
                      <td nzWidth="5%">
                            <span class="color-circle" [style.background-color]="activity?.LeadActivityTypeColorCollection">
                              <span class="setIconGrid" [class]="activity?.LeadActivityTypIconSource"></span>
                            </span>
                      </td>
                      <td nzWidth="20%">
                            <a class="text-over-Flow" (click)="OnClickActivity(activity)">{{ activity?.ActivityTypeName }}</a>
                      </td>
                        <td >
                          <span class="text-primary">
                            <svg
                              width="16"
                              height="16"
                              class="me-3"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M15.3134 2.94568L11.8118 3.36756C11.6958 3.38162 11.6466 3.52399 11.7292 3.60661L12.7733 4.64899L8.00615 9.41793C6.59118 10.8557 4.76587 12.8679 3.09647 14.4406C3.07029 14.4671 3.05561 14.5028 3.05561 14.5399C3.05561 14.5771 3.07029 14.6128 3.09647 14.6393L3.891 15.4303C3.91743 15.4564 3.95312 15.4711 3.99031 15.4711C4.02751 15.4711 4.0632 15.4564 4.08963 15.4303L9.74803 9.7754C10.8851 8.65635 12.3921 6.91992 13.7665 5.64215L14.8089 6.68454C14.8276 6.70312 14.8511 6.7161 14.8768 6.72198C14.9025 6.72786 14.9293 6.72642 14.9542 6.71781C14.9791 6.7092 15.0011 6.69378 15.0177 6.67329C15.0342 6.6528 15.0447 6.62807 15.048 6.60192L15.4698 3.10037C15.4821 3.01072 15.4048 2.93338 15.3134 2.94568Z"
                                fill="#26292E"
                              ></path>
                            </svg>
                            <span
                              style="
                                font-weight: medium;
                                color: #202227;
                              "
                              >{{ activity?.CreatedByUser }}</span
                            >
                            <span
                              style="
                                color: #202227;
                                margin-right: 3px;
                                margin-left: 3px;
                              "
                              >to</span
                            >
                            <span
                              style="
                                font-weight: medium;
                                color: #004fd6;
                                text-transform: capitalize;
                              "
                            >
                              <a>
                                {{
                                  activity?.CustomerDisplayName
                                }}
                              </a>
                              <span
                                *ngIf="
                                 !activity?.CustomerDisplayName &&
                                 LeadOpportunitiesActivityTemplatesResponses?.Name
                                "
                              >
                                {{ LeadOpportunitiesActivityTemplatesResponses?.Name}}
                              </span>
                            </span>
                          </span>
                        </td>
                        <td>{{ activity?.ActivityOn}}</td>
                    </tr>

                    <tr *ngIf="expandSet.has(activity.Id)">
                      <td colspan="5" style="background-color: #f5f5f5; padding: 10px;">
                   {{ activity.Description }}
                      </td>
                    </tr>
                  </ng-container>

                  </tbody>
                </nz-table>

                <nz-pagination
                [(nzPageIndex)]="currentPage"
                [nzTotal]="totalCount"
                [nzPageSize]="pageSize"
                (nzPageIndexChange)="onPageChange($event)"
              ></nz-pagination>

                </div>
                </ng-container>
            </div>
        </nz-card>
      <ng-template #leadActivityExtraCard>
        <nz-space>
          <button
          [nzTrigger]="'click'"
          class="ms-3"
          nz-button
          *ngIf="LeadOpportunitiesActivityTemplatesResponse || LeadOpportunitiesActivityTemplatesResponses"
          nzType="default"
          nz-dropdown
          [nzDropdownMenu]="JobMenu"
        >
          Template Actions
          <span nz-icon nzType="down"></span>
        </button>

        <nz-dropdown-menu #JobMenu="nzDropdownMenu">
          <ul nz-menu>
            <li nz-menu-item>Import Activity</li>
            <li
              nz-menu-item
              [nzDisabled]="!LeadOpportunitiesActivityTemplatesResponse"
            >
              Copy To Activity Template
            </li>
          </ul>
        </nz-dropdown-menu>
          <button
            nz-button
            nzType="default"
            class="ms-2 d-flex align-items-center justify-content-center"
          >
            <i class="bi bi-funnel fs-5"></i>Filter
          </button>
          <button
            nz-button
            nzType="primary"
            class="ms-2"
            (click)="openConfirmDialogActivity(false, 'ACTIVITY')"
            >
            Schedule New Activity
          </button>
        </nz-space>

      </ng-template>
    </div>
    <ng-template #modalFooter>

      <div nz-row  class="mt-2 justify-content-end">
        <div
        nz-col
        nzSpan="12"
        class="footerContent"
        *ngIf="LeadOpportunitiesActivityTemplatesResponse || LeadOpportunitiesActivityTemplatesResponses"
      >
      <span *ngIf="footerResponse">
        <app-footer-label
        [footerData]="footerResponse"
        ></app-footer-label>
      </span>
      </div>
      <div nz-col nzSpan="12">
        <nz-space>
          <ng-container
            *ngIf="LeadOpportunitiesActivityTemplatesResponse || LeadOpportunitiesActivityTemplatesResponses"
          >
            <button
              nz-button
              nzType="default"
              class="rounded-1 me-0"
              (click)="
                ConfirmDelete(
                  LeadOpportunitiesActivityTemplatesResponses?.GlobalId
                )
              "
              [nzLoading]="isDeleteLoading"
              *nzSpaceItem
            >
              Delete
            </button>
          </ng-container>

          <nz-button-group *nzSpaceItem>
            <button
             [nzLoading]="isSaveAndNew"
             (click)="saveAndNewLeadAcitivitytemplate()"
             nz-button
             class="button-Daily"
             [disabled]="DisabledNextActivity"
             [class.disabled]="DisabledNextActivity"
             >Save & New</button>
            <button
              nz-button
              class="button-Daily"
              nz-dropdown
              [nzDropdownMenu]="menu1"
              nzTrigger="click"
            >
              <span nz-icon nzType="down"></span>
            </button>
          </nz-button-group>
          <nz-dropdown-menu #menu1="nzDropdownMenu">
            <ul nz-menu>
              <li (click)="saveAndCloseLeadAcitivitytemplate()" nz-menu-item class="fw-bold">Save & Close</li>
            </ul>
          </nz-dropdown-menu>
          <button
            nz-button
            *nzSpaceItem
            nzType="primary"
            [nzLoading]="isSave"
            (click)="saveActivityTemplate()"
          >
            Save
          </button>
        </nz-space>
      </div>
    </div>

  </ng-template>

</nz-modal>

<nz-modal
  nzWidth="27%"
  [(nzVisible)]="conformCardShow"
  [nzTitle]="null"
  [nzContent]="conformContentFirstModal"
  [nzFooter]="null"
  [nzClosable]="false"
  (nzOnCancel)="conformhandleCancel()"
  [nzBodyStyle]="{
    background: '#ffffff',
    padding: '10px 30px 30px',
    'max-height': '430px',
    'padding-top': '7px',
    'overflow-y': 'auto',
    'border-radius': '7px'
  }"
  [nzStyle]="{ top: '280px' }"
>
  <ng-template #conformContentFirstModal>
    <h3 class="mb-2 famly-inn" style="margin-top: 20px">
      Create New Activity?
    </h3>
    <div class="row mb-3">
      <div class="col-12">
        <p class="fw-medium famly-inn">
          You must save the Lead before creating an Activity. Would you like to
          save the Lead now and create the Activity?
        </p>
      </div>
    </div>
    <div class="row" style="margin-bottom: -10px">
      <div class="col-12 text-end">
        <nz-space>
          <button
            *nzSpaceItem
            nz-button
            class=""
            nzType="default"
            (click)="conformhandleCancel()"
          >
            Close
          </button>
          <button
            *nzSpaceItem
            nz-button
            class="me-2"
            nzType="primary"
            (click)="savecreateScheduleActivity()"
          >
            Save & Create
          </button>
        </nz-space>
      </div>
    </div>
  </ng-template>
</nz-modal>
