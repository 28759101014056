import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { warn } from '@ant-design/icons-angular';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { Subject } from 'rxjs';
import { CompanySettingSchduleRes, ScheduleSetupPhaseParameter, ScheduleSetupPhaseParameterResponse, ScheduleSetupResponse } from 'src/Models/CompanySettings/Schedule/PhaseSetup/Company-Settings-Schedule';
import { PhaseSetup } from 'src/Models/CompanySettings/Schedule/PhaseSetup/PhaseSetup';
import { ColorResponse } from 'src/Models/Job-List/Job-Information/Jobinformation';
import { LookupNameSetupResponse } from 'src/Models/LeadTags';
import { PhaseService } from 'src/Service/CompanyInFormation/ScheDule/Phase/phase.service';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { colorService } from 'src/Service/Job-List/Job-Information/color.service';
import { ScheduleService } from 'src/Service/Project-Management/schedule.service';
import { InnerTabPhasesPhaseSetupComponent } from 'src/app/project-management/components/schedules-Components/schedule-common/Inner-Related-Items/inner-tab-phases-phase-setup/inner-tab-phases-phase-setup.component';
import { CompanySettingsSchedulePreDefineCodes, ProjectManegmentScheduleItemDetailsPreDefinedCodes } from 'src/app/shared/component/Models/LookUpStandardFilterCodes';
import { LoadingIndicatorService } from 'src/shared/Shared-Services/loading-indicator.service';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.css']
})
export class ScheduleComponent implements OnInit {

  @Output() cancel = new EventEmitter<void>();
  private phasesLoaded = new Subject<void>();


  isLargeScreen = true;
  colors: ColorResponse[] = [];
  updateModalVisible = false;
  schedule: boolean = true;
  phaseList: PhaseSetup[] = [];
  previousIndex: number | null = null;
  scheduleCompSetRes: ScheduleSetupResponse [];

  lookupSetNameResponse: LookupNameSetupResponse[] = [];
  pageId: number = 30010;
  formNameId: number = 8;
  preDefinedDataCodes = {
    defaultBuilderView: CompanySettingsSchedulePreDefineCodes.defaultBuilderView,
    defaultOwnerView: CompanySettingsSchedulePreDefineCodes.defaultOwnerView,
    defaultScheduleItemReminders: ProjectManegmentScheduleItemDetailsPreDefinedCodes.Reminder,
  };

  builder: LookupNameSetupResponse[] = [];
  owner: LookupNameSetupResponse[] = [];
  scheduleItemReminder: LookupNameSetupResponse[] = [];
  addphase: boolean = false;
  headerId: number = -1;
  scheduleForm: FormGroup;
  dataset: any;
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    const screenWidth = window.innerWidth;

    if (screenWidth >= 1600) {
      this.isLargeScreen = true; // Consider this as an extra-large screen
    } else if (screenWidth >= 1200) {
      this.isLargeScreen = true; // Consider this as a large screen
    } else if (screenWidth >= 768 && screenWidth < 1200) {
      this.isLargeScreen = false; // Consider this as a medium screen, adjust as needed
    } else {
      this.isLargeScreen = false; // Consider this as a small screen, adjust as needed
    }
  }

  constructor(
    private LookupFilterService: LookupFilterService,
    private CompanyScheduleService: ScheduleService,
    private toasterService: NzMessageService,
    private fb: FormBuilder,
    private modal: NzModalService,
    private phaseService: PhaseService,
    private _loadingIndicatorService: LoadingIndicatorService

  ) {

  }
  ngOnInit(): void {
    this.fetchScheduleSetup();
    this.scheduleForm = this.fb.group({
      companyParameterId: [1],
      builderViewId: [0],
      ownerViewId: [0],
      isPhases: [false],
      isScheduleItem: [false],
      scheduleItemReminderId: [0],
      sendConfirmationRequestWithScheduleItemReminders: [false],
      defaultNotifyLinkedSubsVendor: [false],
      showTimesForHourlyItemsOnCalendarMonthView: [false],
      showJobNameOnCalendarMonthWeekDayViewsForAllListedJobs: [false],
      automaticallyMarkItemsComplete: [false],
      newScheduleItemsDefaultToShowSubsVendors: [false],
      includeHeaderOnSchedulePdfExports: [false],
    });
    this.initLoad();
    this.getPhases();
  }





  initLoad() {
    this.LookupFilterService.getLookUpValuesByFormNameId(this.pageId).subscribe(
      (res) => {
        this.lookupSetNameResponse = res.result;
        // -----------Multi Select ---------------

        let defaultBuilderViewValue = res.result.filter(
          (x: { code: string; }) => x.code === this.preDefinedDataCodes.defaultBuilderView.code
        );
        let defaultOwnerViewValue = res.result.filter(
          (x: { code: string; }) => x.code === this.preDefinedDataCodes.defaultOwnerView.code
        );
        // -----------single Select ---------------
        this.builder = defaultBuilderViewValue;
        this.owner = defaultOwnerViewValue;

      }
    );
    this.LookupFilterService.getLookUpValuesByFormNameId(this.formNameId).subscribe(
      (res) => {
        this.lookupSetNameResponse = res.result;
        // -----------Multi Select ---------------

        let defaultScheduleItemReminders = res.result.filter(
          (x: { code: string; }) => x.code === this.preDefinedDataCodes.defaultScheduleItemReminders.code
        );
        // -----------single Select ---------------
        this.scheduleItemReminder = defaultScheduleItemReminders;

      }
    );
  }





  newSchedulePhaseOpen(): void {
    const modalRef = this.modal.create({
      nzContent: InnerTabPhasesPhaseSetupComponent,
      nzFooter: null,
      nzTitle: '<h1 nz-typography>Add New Phase</h1>',
      nzWidth: '30%',
      nzBodyStyle: {
        'background-color': '#f1f4fa',
        'padding': '20px',
      },
      nzStyle: { top: '10px' },
      nzClosable: true,
      nzMaskClosable: true,
      nzOnCancel: () => {
        modalRef.componentInstance.onCancel();
      }
    });

    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
      this.getPhases();
    });



  }

  newSchedulePhaseEdit(id: number): void {
    const selectedPhase = this.phaseList.find(res => res.Id === id);

    const modalRef = this.modal.create({
      nzContent: InnerTabPhasesPhaseSetupComponent,
      nzFooter: null,
      nzTitle: '<h1 nz-typography>Edit Phase</h1>',
      nzWidth: '30%',
      nzBodyStyle: {
        'background-color': '#f1f4fa',
        'padding': '20px',
      },
      nzStyle: { top: '10px' },
      nzClosable: true,
      nzMaskClosable: true,
      nzOnCancel: () => {
        modalRef.componentInstance.onCancel();
      }
    });

    // Pass the selected phase to the child component
    modalRef.componentInstance.editPhase = selectedPhase;
    modalRef.componentInstance.cancel.subscribe(() => {
      this.getPhases(); // Fetch the updated phases
      modalRef.destroy();
    });

    modalRef.componentInstance.phaseSaved.subscribe(() => {
      this.getPhases(); // Fetch the updated phases
      this.phasesLoaded.subscribe(() => {
        modalRef.destroy();
      })

    });



  }


  trackByFn(index: number, item: any): any {
    return item.id; // Assuming each item has a unique 'id' field
  }

  drop(event: CdkDragDrop<any[]>) {
    // When dropping, move the item in the array
    moveItemInArray(this.phaseList, event.previousIndex, event.currentIndex);
    this.previousIndex = null;

    this.phaseList.forEach((item, index) => {
      item.DisplayOrder = index + 1;
    });

    this.updatePhaseOrder(this.phaseList);

  }

  updatePhaseOrder(updatedPhaseList: PhaseSetup[]) {
    // Call your backend service to update the order in the database
    this.phaseService.UpdateOrderNumber(updatedPhaseList).subscribe(
      (response) => {
        this.toasterService.success('Phase Order saved');
      },
      (error) => {
        this.toasterService.error('Failed to saved order.');
      }
    );
  }

  onDragStarted(index: number) {
    // Store the previous index of the row being replaced
    this.previousIndex = index;
  }
  getPhases() {
    this.phaseService.getData().subscribe((res: any) => {
      this.phaseList = res.result.sort((a: any, b: any) => a.DisplayOrder - b.DisplayOrder);
      this.phasesLoaded.next(); // Emit an event when phases are loaded

    });
  }







  // saveScheduleCompanySetting(): void {
  //   if (this.scheduleForm.valid) {
  //     const formValues = this.scheduleForm.value; // Get form values

  //     // Display the form values in an alert
  //     alert(`companyParameterId: ${formValues.companyParameterId},
  //            builderViewId: ${formValues.builderViewId},
  //            ownerViewId: ${formValues.ownerViewId},
  //            isPhases: ${formValues.isPhases},
  //            isScheduleItem: ${formValues.isScheduleItem},
  //            scheduleItemReminderId: ${formValues.scheduleItemReminderId},
  //            sendConfirmationRequestWithScheduleItemReminders: ${formValues.sendConfirmationRequestWithScheduleItemReminders},
  //            defaultNotifyLinkedSubsVendor: ${formValues.defaultNotifyLinkedSubsVendor},
  //            showTimesForHourlyItemsOnCalendarMonthView: ${formValues.showTimesForHourlyItemsOnCalendarMonthView},
  //            showJobNameOnCalendarMonthWeekDayViewsForAllListedJobs: ${formValues.showJobNameOnCalendarMonthWeekDayViewsForAllListedJobs},
  //            automaticallyMarkItemsComplete: ${formValues.automaticallyMarkItemsComplete},
  //            newScheduleItemsDefaultToShowSubsVendors: ${formValues.newScheduleItemsDefaultToShowSubsVendors},
  //            includeHeaderOnSchedulePdfExports: ${formValues.includeHeaderOnSchedulePdfExports}`);
  //   } else {
  //     alert('Form is invalid!');
  //   }
  // }









  saveScheduleCompanySetting() {
    const FormFields = this.scheduleForm.value
    console.warn('Schdule-Data',FormFields);


    this.CompanyScheduleService.postScheduleCompanySettings(FormFields).subscribe(
      (res) => {
        // Show success message
        this.headerId = res.result.id;
        this.toasterService.success('Schedule settings saved successfully!');
        // this.cancel.emit();
      },
      (error) => {
        // Show error message
        this.toasterService.error('Failed to save schedule settings. Please try again.');
        console.error('Error:', error);
      }
    );
  }



  closeSchedule() {
    this.schedule = false;
    this.cancel.emit();
  }





  fetchScheduleSetup(): void {
    this._loadingIndicatorService.show(); // Show loading indicator
    this.CompanyScheduleService.getCompanySchedule().subscribe({
      next: (response) => {
        if (response && response.result) {
          const dataset = response.result;
          console.log('First item:', dataset);
          // Patch the reactive form with the fetched data
          this.scheduleForm.patchValue({
            companyParameterId: dataset.companyParameterId,
            builderViewId: dataset.builderViewId,
            ownerViewId: dataset.ownerViewId,
            isPhases: dataset.isPhases,
            isScheduleItem: dataset.isScheduleItem,
            scheduleItemReminderId: dataset.scheduleItemReminderId,
            sendConfirmationRequestWithScheduleItemReminders: dataset.sendConfirmationRequestWithScheduleItemReminders,
            defaultNotifyLinkedSubsVendor: dataset.defaultNotifyLinkedSubsVendor,
            showTimesForHourlyItemsOnCalendarMonthView: dataset.showTimesForHourlyItemsOnCalendarMonthView,
            showJobNameOnCalendarMonthWeekDayViewsForAllListedJobs: dataset.showJobNameOnCalendarMonthWeekDayViewsForAllListedJobs,
            automaticallyMarkItemsComplete: dataset.automaticallyMarkItemsComplete,
            newScheduleItemsDefaultToShowSubsVendors: dataset.newScheduleItemsDefaultToShowSubsVendors,
            includeHeaderOnSchedulePdfExports: dataset.includeHeaderOnSchedulePdfExports,
          });

          this._loadingIndicatorService.hide(); // Hide loading indicator after success
        } else {
          this._loadingIndicatorService.hide(); // Hide loading indicator if no result
          console.log('No result in response.');
        }
      },
      error: (err) => {
        this._loadingIndicatorService.hide(); // Hide loading indicator on error
        console.error('Error fetching schedule setup:', err);
      }
    });
  }









  // getAllPhase(){
  //   this.PhaseService.getData().subscribe(res => {
  //      this.PhaseSet = res.result
  //      console.log('phases',this.PhaseSet);
  //   })
  // }

}
