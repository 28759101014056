import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, NonNullableFormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { LookupNameSetup, LookupNameSetupResponse } from 'src/Models/LeadTags';
import { LeadOpportunitiesService } from 'src/Service/lead-opportunities.service';

@Component({
  selector: 'app-to-do-tags',
  templateUrl: './to-do-tags.component.html',
  styleUrls: ['./to-do-tags.component.css']
})
export class ToDoTagsComponent implements OnInit{
  @Output() tagSaved = new EventEmitter<void>();

  @Input() selectedTag: LookupNameSetupResponse;
  @Output() cancelTag = new EventEmitter<void>();
  Tags: boolean = false;
  pageId: number = 6;
  isLoadingOne: boolean = false;
  maxNameLength = 50;
  deleteLoading = false;
  @Output() tagSavedSelected = new EventEmitter<LookupNameSetupResponse>();

  constructor(
    private fb: NonNullableFormBuilder,
    private leadOppService: LeadOpportunitiesService,
    public toastService: NzMessageService,
    private modal: NzModalService // Inject NzModalService
  ) {}

  TagsForm: FormGroup<{
    id: FormControl<string>;
    globalId: FormControl<string>;
    name: FormControl<string>;
    companyParameterId: FormControl<number>;
    formNameId: FormControl<number>;
    lookUpStandardId: FormControl<number>;
    parentCode: FormControl<string>;
    code: FormControl<string>;
    description: FormControl<string>;
    isFieldValue: FormControl<boolean>;
  }>;

  ngOnInit(): void {
    this.initializeForm();
    if (this.selectedTag) {
      this.patchFormValues(this.selectedTag); // Patch form with selectedTag values if available
    }
  }

  initializeForm() {
    this.TagsForm = this.fb.group({
      id: [''],
      globalId: ['00000000-0000-0000-0000-000000000000'],
      name: [
        '',
        [Validators.required, Validators.maxLength(this.maxNameLength)],
      ],
      companyParameterId: [1],
      formNameId: this.pageId,
      lookUpStandardId: [60494],
      parentCode: ['PMTDT'],
      code: ['PMTDT'],
      description: [''],
      isFieldValue: [false],
    });
  }

  // Method to patch form values based on the selected tag
  patchFormValues(selectedTag: any): void {
    this.TagsForm.patchValue({
      id: selectedTag.id.toString(),
      globalId: selectedTag.globalId,
      name: selectedTag.name,
      companyParameterId: selectedTag.companyParameterId ?? 1,
      formNameId: selectedTag.formNameId,
      lookUpStandardId: selectedTag.lookUpStandardId,
      parentCode: selectedTag.parentCode,
      code: selectedTag.code,
      description: selectedTag.description,
      isFieldValue: selectedTag.isFieldValue,
    });
  }

  get nameControl(): FormControl<string> {
    return this.TagsForm.get('name') as FormControl<string>;
  }

  getOverCharacters(): number {
    const value = this.nameControl.value || '';
    return value.length - this.maxNameLength;
  }

  // Method to check if the character count exceeds the limit
  isOverLimit(): boolean {
    return this.nameControl.value.length > this.maxNameLength;
  }

  SaveTagData() {
    if (this.TagsForm.valid) {
      this.isLoadingOne = true;
      this.leadOppService
        .getLookUpNameSetup(this.TagsForm.value as LookupNameSetup)
        .subscribe(
          (response) => {
            this.initializeForm(); // Reset the form on success
            this.isLoadingOne = false;
            this.toastService.success('Saved successfully');
            this.tagSavedSelected.emit(response.result); // Ensure this emits the correct data
            this.cancelTag.emit(); // Emit the cancel event
          },
          (error) => {
            this.isLoadingOne = false;
            if (error.status === 500) {
              this.toastService.error(
                'Internal Server Error. Please try again later.'
              );
            } else if (error.status === 400) {
              this.toastService.error('Bad Request. Please check your input.');
            } else {
              this.toastService.error(
                'Failed to save trade. Please try again.'
              );
            }
          }
        );
    } else {
      Object.values(this.TagsForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  updateConfirmValidator(): void {
    Promise.resolve().then(() =>
      this.TagsForm.controls.name.updateValueAndValidity()
    );
  }

  confirmationValidator: ValidatorFn = (
    control: AbstractControl
  ): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true };
    } else if (control.value !== this.TagsForm.controls.name.value) {
      return { confirm: true, error: true };
    }
    return {};
  };

  AddNewPhaseCancel() {
    this.Tags = false;
  }

  showConfirmDelete(): void {
    this.modal.confirm({
      nzTitle: 'Delete DailyLog Item Tag?',
      nzContent: 'Are you sure you want to delete this Schedule Item Tag',
      nzOkText: 'Delete',
      nzCentered: true,
      nzOkDanger: true,
      nzOkLoading: this.deleteLoading,
      nzOnOk: () => {
        this.deleteLoading = true;
        return this.onDelete()
          .then(() => {
            this.deleteLoading = false;
          })
          .catch(() => {
            this.deleteLoading = false;
          });
      },
      nzCancelText: 'Cancel',
      nzOnCancel: () => console.log('Delete action canceled'),
    });
  }

  onDelete(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.selectedTag && this.selectedTag.globalId) {
        this.leadOppService
          .deleteDataLookupNameSetup(this.selectedTag.globalId)
          .subscribe(
            () => {
              this.toastService.success('Deleted successfully');
              this.cancelTag.emit();
              this.tagSaved.emit();
              resolve();
            },
            (err) => {
              this.toastService.error('Failed to delete phase');
              reject(err);
            }
          );
      } else {
        reject('No phase selected to delete');
      }
    });
  }
}
