<nz-modal
  nzWidth="95%"
  [(nzVisible)]="isVisibleProposalTemplateModal"
  nzTitle="Lead Proposal Template"
  (nzOnCancel)="handleCancelLeadProposalTemplate()"
  [nzFooter]="LeadProposalTemplateFooter"
  [nzBodyStyle]="{
    background: '#f1f4fa',
    padding: '14px',
    'max-height': '75vh',
    'overflow-y': 'auto'
  }"
  [nzStyle]="{ top: '38px' }"
>


  <div class="content top-content" *nzModalContent>
    <div class="spinner-wrapper" *ngIf="isLoading">
      <nz-spin nzSimple [nzSpinning]="true" [nzSize]="'large'"></nz-spin>
    </div>

    <!-- Error Card Start -->
    <nz-alert
      class="rounded-3 mb-3"
      *ngIf="validationErrors.length > 0"
      nzType="error"
      nzShowIcon
      [nzMessage]="message"
      [nzDescription]="errorDescriptionTemplate"
    ></nz-alert>

    <ng-template #message>
      <p class="mt-1">Please correct the following fields:</p>
    </ng-template>

    <ng-template #errorDescriptionTemplate>
      <ul>
        <li class="sub-text" *ngFor="let error of validationErrors">
          {{ error }}
        </li>
      </ul>
    </ng-template>
    <!-- Error Card End -->
    <ng-template #extraTemplate>
      <div class="row p-0">
        <div class="bg-body-secondary p-3">
          <strong>Un Release</strong>
        </div>
      </div>
    </ng-template>


    <nz-card nzTitle="Lead Proposal Details" nzBorderless class="rounded-2 mb-3">
      <div class="row">
        <div class="col-12">
          <div class="row align-items-center mb-3">
            <div class="col-8">
              <label class="sub-text mb-1" for="title">
                Title
                <span class="text-danger p-error fw-bolder">*</span>
              </label>
              <nz-form-item>
                <nz-form-control
                  [nzErrorTip]="isSubmitted ? getErrorTip('title') : null"
                  [nzValidateStatus]="
                    isSubmitted && getErrorTip('title') ? 'error' : null
                  "
                >
                  <input
                    #titleInput
                    nz-input
                    [(ngModel)]="formFieldValues.title"
                    name="title"
                    class="form-control formField"
                    required
                    (ngModelChange)="onFieldChange('title', $event)"
                  />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div class="col-3">
              <label class="sub-text mb-1" for="salesPersonId">
                Salesperson
                <span
                  nz-popover
                  [nzPopoverContent]="contentTemplate"
                  class="bi bi-info-circle-fill"
                ></span>
              </label>
              <ng-template #contentTemplate>
                <p style="height: 90px; width: 250px">
                  Only the selected salesperson will have access to this
                  template. Select <strong>Unassigned</strong> to make it
                  available to all.
                </p>
              </ng-template>
              <nz-form-item>
                <nz-form-control
                  [nzErrorTip]="
                    isSubmitted ? getErrorTip('salesPersonId') : null
                  "
                  [nzValidateStatus]="
                    isSubmitted && getErrorTip('salesPersonId') ? 'error' : null
                  "
                >
                  <nz-select
                    [(ngModel)]="formFieldValues['salesPersonId']"
                    name="salesPersonId"
                    nzShowSearch
                    (ngModelChange)="onFieldChange('salesPersonId', $event)"
                  >
                    <nz-option
                      nzLabel="--Unassigned--"
                      nzValue="0"
                      nzSelected
                    ></nz-option>
                    <nz-option
                      *ngFor="let option of assignedUser"
                      [nzLabel]="option.label"
                      [nzValue]="option.value"
                    ></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <nz-collapse>
                <nz-collapse-panel
                  #p
                  [ngStyle]="{'background': '#f1f4fa',
             'margin-bottom': '24px',
             }"
                  [nzExpandedIcon]="expandedIcon"
                  [nzHeader]="tileetwe"
                >
                  <div class="row mt-0" #p>
                    <div class="col-12">
                      <div class="row mt-2">
                        <div class="col-10">
                          <label class="sub-text">Template Description</label>
                          <textarea
                            [(ngModel)]="formFieldValues.TempeleteDescription"
                            name="TempeleteDescription"
                            nz-input
                            [nzAutosize]="{ minRows: 2, maxRows: 10 }"
                            class="formField"
                          ></textarea>
                        </div>
                      </div>
                      <div class="row mt-2">
                        <div class="col-10">
                          <label class="sub-text">Introductory Text</label>
                          <div class="row">
                            <nz-form-item>
                              <nz-form-control>
                                <ckeditor
                                  [(ngModel)]="
                                    formFieldValues['introductoryText']
                                  "
                                  name="introductoryText"
                                  [editor]="Editor"
                                  [config]="config"
                                  class="size"
                                  id="editor"
                                >
                                </ckeditor>
                              </nz-form-control>
                            </nz-form-item>

                            <div class="row mt-2">
                              <div class="col-10" style="width: 1300px">
                                <label class="sub-text">Closing Text</label>
                                <div class="row">
                                  <nz-form-item>
                                    <nz-form-control>
                                      <ckeditor
                                        [(ngModel)]="
                                          formFieldValues['closingText']
                                        "
                                        name="closingText"
                                        [editor]="Editor"
                                        [config]="config"
                                        class="size"
                                        id="editor"
                                      >
                                      </ckeditor>
                                    </nz-form-control>
                                  </nz-form-item>

                                  <!-- Document Card For Lead Proposal Template Start  Date 08/29/2024-->
                                  <nz-card nzBorderless nzTitle="Attachments">
                                    <div class="row p-2">
                                      <div class="col-12">
                                        <app-create-attachments-common
                                          [TestAttachment__]="TestAttachment"
                                          (filesUploaded)="onFilesUploaded($event)"
                                          (attachmentsReset)="resetForm()"></app-create-attachments-common>
                                      </div>

                                    </div>
                                  </nz-card>
                                  <!-- Document Card For Lead Proposal Template End -->
                                </div>
                              </div>
                              <ng-template #tileetwe>
                                <strong class="ms-3 fS-5">More Details</strong>
                              </ng-template>
                              <ng-template #expandedIcon let-active>
                                {{ active }}
                                <span
                                  nz-icon
                                  nzType="caret-right"
                                  class="ant-collapse-arrow"
                                  [nzRotate]="p.nzActive ? 90 : -0"
                                ></span>
                              </ng-template>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </nz-collapse-panel>
              </nz-collapse>
            </div>
          </div>
        </div>
      </div>
    </nz-card>

    <!-- Second Card Start -->
    <!-- tabs -->
    <div class="card-container">
      <nz-tabset nzType="card">
        <nz-tab *ngFor="let tab of tabs" [nzTitle]="leadProposalTabs(tab)">
          <ng-container *ngIf="tab === 1">
           <cost-item-common  [companyParameter]="companyParameter" [costItemId]="costItemId"></cost-item-common>
          </ng-container>

        </nz-tab>
      </nz-tabset>
    </div>
    <!-- tabs -->

    <!-- Second Card End -->
  </div>





  <ng-template #LeadProposalTemplateFooter>
    <div class="container-fluid p-0 PSCustomFooter mt-2">
      <div class="row">
        <div
          class="col text-start justify-content-start align-items-start"
          *ngIf="getLeadProposalisTemplateData"
        >
          <span *ngIf="footerResponse">
            <app-footer-label [footerData]="footerResponse"></app-footer-label>
          </span>
        </div>
        <div class="col text-end">
          <nz-space>
            <ng-container *nzSpaceItem>
              <button

                nz-button
                *ngIf="
                  isVisibleProposalTemplateModal &&
                  getLeadProposalisTemplateData
                "
                nzType="default"
                [nzLoading]="isDeleteLoading"
                (click)="deleteLeadConfirm()"
              >
                Delete
              </button>
            </ng-container>
            <button

              *nzSpaceItem
              nz-button
              nzType="primary"
              [nzLoading]="isSaveAndNew"
              (click)="saveAndNewProposalTemplate()"
            >
              Save & New
            </button>
            <button

              *nzSpaceItem
              nz-button
              nzType="primary"
              [nzLoading]="isSave"
              (click)="saveLeadProposalTemplate()"
            >
              Save
            </button>
            <button

              *nzSpaceItem
              (click)="saveAndCloseProposalTemplate()"
              nz-button
              nzType="primary"
              [nzLoading]="isSaveAndClose"
            >
              Save & Close
            </button>
          </nz-space>
        </div>
        <!-- <div class="col-md-4 text-start">
      <span>Updated by <nz-avatar nzSize="small" nzText="MM" style="color:#f56a00; background-color:#fde3cf;"></nz-avatar> <span class="ms-1"><strong>Muhammad Ahmed</strong></span> on Oct 31, 2023, 7:27 AM</span>
    </div> -->
      </div>
    </div>
  </ng-template>
</nz-modal>
