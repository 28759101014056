<nz-modal nzWidth="48%" [(nzVisible)]="VisibleInCostcodeSetup" [nzTitle]="modalTitleInCostcodeSetup"
  [nzFooter]="modalFooterInCostcodeSetup" (nzOnCancel)="closeModalInCostcodeSetup()"
  [nzBodyStyle]="{'background': '#f1f4fa', 'padding': '14px', 'max-height': '73vh', 'overflow-y': 'auto', 'overflow-x': 'hidden'}"
  [nzStyle]="{ top: '20px' }">
  <ng-template #modalTitleInCostcodeSetup>
    <div class="row p-0 m-0">
      <h2 class="p-0 m-0 famly-inn ant-typography-Modal">Cost code</h2>
    </div>
  </ng-template>
  <form nz-form [formGroup]="costCodeFormField">
    <div class="content" *nzModalContent>
      <div class="card border-0 mt-0">
        <div class="card-body">
          <div class="row gx-2">
            <div class="col-6">
              <label class="sub-text">Title<span class="ms-0 text-danger fw-bold">*</span></label>
              <input nz-input formControlName="title" name="title" type="text" class="formField" />
            </div>
            <div class="col-6">
              <label class="sub-text">Category</label>
              <nz-select nzShowSearch formControlName="costCodeId" name="costCodeId">
                <nz-option [nzLabel]="'-- Please Select --'" [nzValue]="0"></nz-option>
                <nz-option *ngFor="let option of costCategory" [nzLabel]="option.title"
                  [nzValue]="option.id"></nz-option>
              </nz-select>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <label for="text" class="sub-text">Sub Code Of</label>
              <nz-select nzShowSearch formControlName="subCostCodeId" name="subCostCodeId"
                (ngModelChange)="subCodeChanged()">
                <nz-option [nzLabel]="'-- No Parent --'" [nzValue]="0"></nz-option>
                <nz-option *ngFor="let option of costSubCode" [nzLabel]="option.title"
                  [nzValue]="option.id"></nz-option>
              </nz-select>
            </div>
          </div>
          <div class="row mt-3 border-bottom pb-5">
            <div class="col">
              <label nz-checkbox class="sub-text" formControlName="timeClockLaborCode" (ngModelChange)="toggleFields()">
                Default Labor Cost
                <i class="bi bi-info-circle-fill text-secondary ms-1" nz-popover [nzPopoverContent]="JobTypes"
                  nzPopoverPlacement="top"></i>
              </label>
              <ng-template #JobTypes>
                <p class="p-2" style="height: 70px; width: 210px; font-size: 13px">
                  Checking this box will make this code available on the Time Clock.
                </p>
              </ng-template>
            </div>
            <div class="col" *ngIf="checked">
              <label for="text" class="sub-text" formControlName="defaultLaborCost">Default Labor Cost</label>
              <i class="bi bi-info-circle-fill text-secondary ms-2" nz-popover [nzPopoverContent]="contact"
                nzPopoverPlacement="top"></i>
              <ng-template #contact>
                <div class="rounded-2">
                  <p style="height: 100px; width: 210px; font-size: 13px">
                    This allows you to associate a fixed cost rate to a specific cost code. Hourly Rate Tracking needs
                    to be set to "By Cost Code" within Time Clock Setup.
                  </p>
                </div>
              </ng-template>
              <span class="text-danger ms-1">*</span>
              <nz-input-group nzAddOnBefore="$">
                <input formControlName="defaultLaborCost" nz-input appDollarFormatter class="form-control formField" />
              </nz-input-group>
            </div>
          </div>
          <div class="row mt-3 border-bottom pb-5">
            <div class="col-12">
              <label for="text" class="sub-text">Details</label>
              <textarea nz-input [nzAutosize]="{ minRows: 3, maxRows: 8 }" formControlName="detail"
                class="form-control formField"></textarea>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12">
              <label nz-checkbox formControlName="isStatus" class="pira-label">
                isActive
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-template #modalFooterInCostcodeSetup>
      <div class="mt-2 mb-2 align-items-center">
        <nz-button-group>
          <button nzType="primary" dizable nz-button (click)="saveCostCodeAndClose()">Save & close</button>
          <button nzTrigger="click" nzType="primary" nz-button nz-dropdown [nzDropdownMenu]="menu1" nzPlacement="bottomRight">
            <span nz-icon nzType="down" nzTheme="outline" style="color: white;"></span>
          </button>
          <nz-dropdown-menu #menu1="nzDropdownMenu">
            <ul nz-menu>
              <li nz-menu-item (click)="saveCostCode()">Save</li>
              <li nz-menu-item (click)="saveCostCodeAndNew()">Save & New</li>
            </ul>
          </nz-dropdown-menu>
        </nz-button-group>
      </div>
      </ng-template>
  </form>
</nz-modal>