<nz-modal [(nzVisible)]="isVisibleInRFIs" [nzTitle]="InRFIs" [nzFooter]="modalFooterInRFIs" [nzBodyStyle]="{
    background: '#f1f4fa',
    padding: '14px',
    maxHeight: '69vh',
    'overflow-y': 'auto',
    'overflow-x': 'hidden',
  }" [nzStyle]="{ top: '25px'}" [nzWidth]="'93%'" (nzOnCancel)="CancelRFIs()">
    <ng-template #InRFIs>
      <div class="row">
        <div class="ModalHeaderJobName famly-inn"><div>{{ onJobShortInformation?.name }}</div></div>
        <h2 class="famly-inn ant-typography-Modal p-0">RFI</h2>
      </div>
    </ng-template>
        <div class="content modal-card" *nzModalContent>
            <nz-alert class="rounded-3 mb-3 mt-2 error-message" *ngIf="isSubmitted && errorFields.length > 0" nzType="error" nzShowIcon [nzMessage]="message" [nzDescription]="errorDescriptionTemplate"></nz-alert>
            <ng-template #message>
              <p class="mt-1">Please correct the following fields:</p>
            </ng-template>
            <ng-template #errorDescriptionTemplate>
              <ul>
                <li class="famly-inn p-0 m-0" *ngFor="let error of errorFields">
                  <span class="d-flex align-items-center">
                    <span>{{ error.label }}:</span>
                    <span class="ms-1"> {{ error.message }}</span>
                  </span>
                </li>
              </ul>
            </ng-template>
            <div class="card-container">
              <div class="card border-0 mt-0">
                <div class="card-head border-bottom pr-0 mr-0">
                  <div nz-row>
                    <div nz-col nzSpan="12" class="d-flex align-items-center">
                      <h2 [ngClass]="{ 'card-Header': !onTagPastDue }" class="famly-inn ant-typography-crad-Header">RFI Information</h2>
                    </div>
                    <div nz-col nzSpan="12" class="d-flex justify-content-end align-items-center">
                      <ng-container *ngIf="onTagPastDue === true">
                        <div class="status-text status-tag danger">Past Due Date</div>
                      </ng-container>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                    <div class="row gx-4">
                        <div class="col-6">
                            <nz-row>
                                <div nz-col nzSpan="24">
                                    <label class="sub-text">Title</label>
                                    <nz-form-item *ngIf="un_Display === true">
                                        <nz-form-control>
                                          <input  #titleInput nz-input [(ngModel)]="RFIsFormFieldsValue.createRFIInformationRequest.title" name="title"
                                            (ngModelChange)="onFieldChange('title', $event)"
                                            [nzStatus]="validationStates['title']" class="formField" />
                                        </nz-form-control>
                                        <div *ngIf="validationStates['title'] === 'error'" class="text-danger">
                                          {{ validationMessages['title'] }}
                                        </div>
                                      </nz-form-item>
                                </div>
                            </nz-row>
                            <nz-row *ngIf="un_Display === false">
                              <div nz-col nzSpan="24">
                                <ng-container *ngIf="onGetByIdRes?.Title?.length > 0; else Title">
                                  <span>{{ onGetByIdRes?.Title }}</span>
                                </ng-container>
                                <ng-template #Title>
                                  <div class="ValueDisplay">--</div>
                                </ng-template>
                              </div>
                            </nz-row>
                            <nz-row>
                                <div nz-col nzSpan="24">
                                    <label class="sub-text">ID #</label>
                                    <nz-form-item *ngIf="un_Display === true">
                                        <nz-form-control>
                                          <div class="input-group">
                                            <nz-input-group [nzAddOnBefore]="test">
                                              <input 
                                                placeholder="(Auto Assign)"
                                                nz-input 
                                                [(ngModel)]="RFIsFormFieldsValue.createRFIInformationRequest.autoAssignNumber" name="autoAssignNumber"
                                                (ngModelChange)="onFieldChange('autoAssignNumber', $event)"
                                                [nzStatus]="validationStates['autoAssignNumber']"
                                                class="formField" />
                                            </nz-input-group>
                                            <ng-template #test>
                                              <ng-container *ngIf="onJobShortInformation?.JobPrefix?.length > 0">
                                                <span>{{ onJobShortInformation?.JobPrefix }}</span>
                                              </ng-container>
                                            </ng-template>
                                          </div>
                                        </nz-form-control>
                                        <div *ngIf="validationStates['autoAssignNumber'] === 'error'" class="text-danger">
                                          {{ validationMessages['autoAssignNumber'] }}
                                        </div>
                                    </nz-form-item>
                                </div>
                            </nz-row>
                            <nz-row *ngIf="un_Display === false">
                              <div nz-col nzSpan="24">
                                  <div class="ValueDisplay">Test Ghulam - <span>{{ onGetByIdRes?.AutoAssignNumber }}</span></div>
                              </div>
                            </nz-row>
                            <nz-row class="pt-2" *ngIf="onGetByIdRes !== undefined">
                                <div nz-col nzSpan="24">
                                  <label class="sub-text">Completed</label>
                                </div>
                            </nz-row>
                            <nz-row *ngIf="onGetByIdRes !== undefined">
                                <div nz-col nzSpan="24">
                                  <label nz-checkbox [(ngModel)]="RFIsFormFieldsValue.createRFIInformationRequest.isCompleted">
                                    RFI is complete
                                  </label>
                                </div>
                            </nz-row>
                            <nz-row *ngIf="un_Display === false">
                              <div nz-col nzSpan="24">
                                  <div class="ValueDisplay">By Muhammad Yousuf on Oct 10, 2024, 5:38 AM</div>
                              </div>
                            </nz-row>
                            <div nz-row>
                                <div nz-col nzSpan="23">
                                  <label class="sub-text">Assignee</label>
                                </div>
                                <div nz-col nzSpan="1">
                                </div>
                            </div>
                            <div nz-row style="justify-content: space-between;" class="IsCompleted_Assignee" *ngIf="un_Display === false">
                              <div nz-col>
                                <div style="display: flex; align-items: center; gap: 10px;">
                                    <div nz-col class="ant-avt-Assingee">
                                        <nz-avatar [nzText]="onGetByIdRes?.AssingeeShorName"></nz-avatar>
                                    </div>
                                    <div nz-col>
                                        <div class="text-overflow-auto BTUser isCurrentUser" style="font-size: 14px;">{{ onGetByIdRes?.AssingeeName }}</div>
                                    </div>
                                </div>
                              </div> 
                            </div>
                            <div nz-row class="Test" *ngIf="un_Display === true">
                              <div nz-col nzSpan="23">
                                <nz-select
                                  [nzCustomTemplate]="defaultTemplate"
                                  nzShowSearch
                                  [(ngModel)]="test"
                                  [compareWith]="compareFn"
                                  (ngModelChange)="onAssigneeChange($event)">
                                  <nz-option nzValue="me" nzLabel="Me"></nz-option>
                                  <ng-container *ngFor="let group of selectedUsers">
                                    <nz-option-group [nzLabel]="group.groupName">
                                      <ng-container *ngFor="let user of group.users">
                                        <nz-option
                                          [nzValue]="{ id: user.id, groupName: group.groupName }"
                                          [nzLabel]="user.name"
                                        >
                                          <span class="color-name">{{ user.name }}</span>
                                        </nz-option>
                                      </ng-container>
                                    </nz-option-group>
                                  </ng-container>
                                </nz-select>
                                <ng-template #defaultTemplate let-selected>
                                  <div class="color-item d-flex align-items-center">
                                    <nz-avatar 
                                      [nzText]="initials"
                                      style="color:#f56a00; background-color:#fde3cf;">
                                    </nz-avatar>
                                    <span class="color-name famly-inn">{{ selected.nzLabel }}</span>
                                  </div>
                                </ng-template>
                              </div>
                                <div nz-col nzSpan="1">
                                    <i *ngIf="onGetByIdRes !== undefined" class="fa-solid fa-paper-plane ms-1" nz-popover [nzPopoverContent]="onNotifyTooltipContent" nzPopoverPlacement="right"></i>
                                    <ng-template #onNotifyTooltipContent>
                                        <div style="border-radius: 10px; width: 210px; font-size: 13px; line-height: 20px;">
                                          <div nz-row>
                                            <div nz-col nzSpan="24">
                                              <div class="famly-inn">
                                                This will send a one-time instant reminder to the assigned user
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                    </ng-template>
                                </div>
                            </div>
                            <div nz-row>
                                <div nz-col nzSpan="24">
                                    <label class="sub-text">Viewers</label>
                                    <nz-tree-select
                                      [(ngModel)]="RFIsFormFieldsValue.createRFIInformationRequest.createRFIViewerParameterRequests"
                                      name="createRFIViewerParameterRequests"
                                      [nzNodes]="ViewersGroupNodes"
                                      style="width: 100%"
                                      [nzShowSearch]="true"
                                      [nzAllowClear]="true"
                                      nzCheckable>
                                    </nz-tree-select>
                                </div>
                              </div>
                         </div>
                        <div class="col-6">
                          <div nz-row>
                            <div nz-col nzSpan="24">
                                <label class="sub-text">Due Date</label>
                            </div>
                          </div>
                          <div nz-row *ngIf="un_Display === true">
                            <div nz-col nzSpan="24">
                                <nz-radio-group [(ngModel)]="radioValue" (ngModelChange)="radioButtons()">
                                  <label nz-radio-button nzValue="A">Choose Date</label>
                                  <label nz-radio-button nzValue="B">Link to Schedule Item</label>
                                </nz-radio-group>
                            </div>
                          </div>
                            <ng-container *ngIf="un_Display === false && this.RFIsFormFieldsValue.createRFIInformationRequest.scheduleItemParameterId > 0 && this.RFIsInformationResponse?.isLinkToScheduleItem === true">
                              <div nz-row [ngClass]="{'to-padding-Schedule': !un_Display}">
                                <div nz-col nzSpan="24">
                                    <label class="sub-text">Schedule Item</label>
                                </div>
                              </div>
                              <div nz-row>
                                <div nz-col nzSpan="24">
                                  <div class="ValueDisplay">{{ scheduleItem }}</div>
                                </div>
                              </div>
                              <div nz-row>
                                <div nz-col nzSpan="24">
                                    <label class="sub-text">Due</label>
                                </div>
                              </div>
                              <div nz-row>
                                <div nz-col nzSpan="24">
                                  <div class="ValueDisplay"><span>{{ this.onGetByIdRes?.dueNumberDays }}</span><span class="ms-2">Days</span>
                                    <span class="ms-2" *ngIf="un_Display === false && this.onGetByIdRes?.before === true">Before</span>
                                    <span class="ms-2" *ngIf="un_Display === false && this.onGetByIdRes?.after === true">After</span>
                                  </div>
                                </div>
                              </div>
                            </ng-container>
                            <ng-container *ngIf="un_Display === false">
                              <div nz-row  [ngClass]="{'to-padding-Schedule': !un_Display && this.onGetByIdRes?.IsChooseDate}">
                                <div nz-col nzSpan="24">
                                    <label class="sub-text">Due Date</label>
                                </div>
                              </div>
                              <div nz-row>
                                <div nz-col nzSpan="24">
                                  <div class="ValueDisplay">{{ this.onGetByIdRes?.DueOn | date: 'MMM d, yyyy' }}</div>
                                </div>
                              </div>
                            </ng-container>
                            <div nz-row class="pt-1" *ngIf="un_Display === false">
                              <div nz-col nzSpan="24">
                                  <label class="sub-text">Reminder</label>
                              </div>
                            </div>
                            <ng-container *ngIf="!onReminderDirective; else Reminder">
                              <div nz-row class="Tag_Custome pt-1" *ngIf="un_Display === false">
                                <div nz-col nzSpan="24">
                                  <nz-tag>None</nz-tag>
                                </div>
                              </div>
                            </ng-container>
                            <ng-template #Reminder>
                              <div nz-row class="Tag_Custome pt-1" *ngIf="un_Display === false">
                                <div nz-col nzSpan="24">
                                  <nz-tag>{{ onReminderTag }}</nz-tag>
                                </div>
                              </div>
                            </ng-template>
                            <div nz-row  *ngIf="radioValue === 'A'">
                              <div nz-col nzSpan="24" *ngIf="un_Display === true">
                                  <label class="sub-text"><span>Due Date</span><span class="text-danger fw-bolder ms-1">*</span></label>
                                  <nz-form-control>
                                    <nz-date-picker
                                      [(ngModel)]="RFIsFormFieldsValue.createRFIInformationRequest.dueOn"
                                      name="dueOn"
                                      (ngModelChange)="onFieldChange('dueOn', $event)"
                                      [nzStatus]="validationStates['dueOn']"
                                      nzSuffixIcon="false"
                                      [nzFormat]="'MMM d, YYYY'"
                                      nzPlaceHolder=" "
                                      autocomplete="off">
                                    </nz-date-picker>
                                  </nz-form-control>
                                  <div *ngIf="validationStates['dueOn'] === 'error'" class="text-danger">
                                    {{ validationMessages["dueOn"] }}
                                  </div>
                              </div>
                            </div>
                          <ng-container *ngIf="un_Display === true">
                            <div nz-row *ngIf="radioValue === 'B'">
                              <div nz-col nzSpan="24">
                                  <label class="sub-text">Schedule Item<span class="text-danger fw-bolder ms-1">*</span></label>
                              </div>
                            </div>
                            <div nz-row *ngIf="radioValue === 'B'">
                              <div nz-col nzSpan="20">
                                <nz-select nzShowSearch [(ngModel)]="RFIsFormFieldsValue.createRFIInformationRequest.scheduleItemParameterId" name="scheduleItemParameterId"  (ngModelChange)="onFieldisComplete('scheduleItemParameterId', $event)">
                                  <nz-option *ngFor="let option of selectionRes" [nzLabel]="option.title" [nzValue]="option.id"></nz-option>
                                </nz-select>
                              </div>
                              <div nz-col nzSpan="2" class="d-flex justify-content-center align-items-center">
                                <span class="BT-Link famly-inn" (click)="onOpenScheduleItem(null)">Add</span>
                              </div>
                              <div nz-col nzSpan="2" class="d-flex justify-content-center align-items-center">
                                <span
                                  [class.disabled]="isEditButtonDisabled()"
                                  class="BT-Link famly-inn"
                                  (click)="!isEditButtonDisabled() && onOpenScheduleItem(RFIsFormFieldsValue.createRFIInformationRequest.scheduleItemParameterId)">
                                  Edit
                                </span>
                              </div>
                            </div>
                            <div nz-row *ngIf="radioValue === 'B'">
                              <div nz-col nzSpan="24">
                                <label class="sub-text">Due</label>
                              </div>
                            </div>
                            <div nz-row [nzGutter]="3" *ngIf="radioValue === 'B'">
                              <div nz-col nzSpan="4">
                                <nz-input-number [(ngModel)]="RFIsFormFieldsValue.createRFIInformationRequest.dueNumberDays" (ngModelChange)="onDaysChange($event)" name="dueNumberDays"></nz-input-number>
                              </div>
                              <div nz-col nzSpan="2">
                                <label class="famly-inn">Days</label>
                              </div>
                              <div nz-col nzSpan="8" *ngIf="radioValue === 'B'">
                                <nz-radio-group [(ngModel)]="DaysradioValue" (ngModelChange)="DaysradioButtons()">
                                  <div nz-row>
                                    <div nz-col nzSpan="12">
                                      <label nz-radio nzValue="A"><span class="famly-inn">Before</span></label>
                                    </div>
                                    <div nz-col nzSpan="12">
                                      <label nz-radio nzValue="B"><span class="famly-inn">After</span></label>
                                    </div>
                                  </div>
                                </nz-radio-group>
                              </div>
                            </div>
                            <div nz-row *ngIf="radioValue === 'B'">
                              <div nz-col nzSpan="24">
                                <label class="sub-text">Due Date</label>
                              </div>
                            </div>
                            <ng-container *ngIf="ScheduleItem_start_end_On === true">
                              <div nz-row *ngIf="radioValue === 'B'">
                                <div nz-col nzSpan="24">
                                    <div class="ValueDisplay">{{ dueDateTest }}</div>
                                </div>
                              </div>
                            </ng-container>
                            <ng-container *ngIf="ScheduleItem_start_end_On === false">
                              <div nz-row *ngIf="radioValue === 'B'">
                                <div nz-col nzSpan="24">
                                    <div class="ValueDisplay">{{ dueDate || '--' }}</div>
                                </div>
                              </div>
                            </ng-container>
                          </ng-container>
                          <ng-container *ngIf="un_Display === true">
                            <div nz-row *ngIf="radioValue === 'A' || radioValue === 'B'">
                              <div nz-col nzSpan="24">
                                  <label class="sub-text">Reminder</label>
                                  <nz-select nzShowSearch [(ngModel)]="RFIsFormFieldsValue.createRFIInformationRequest.reminderSetupId" name="reminderSetupId" (ngModelChange)="onFieldisComplete('reminderSetupId',$event)">
                                      <nz-option *ngFor="let option of reminder" [nzLabel]="option.name" [nzValue]="option.id"></nz-option>
                                  </nz-select>
                              </div>
                            </div>
                          </ng-container>
                          <ng-container *ngIf="!(un_Display === false && RFIsFormFieldsValue.createRFIInformationRequest.relatedTypeId === 0)">
                              <div nz-row *ngIf="radioValue === 'A' || radioValue === 'B'">
                                <div nz-col nzSpan="24">
                                    <label class="sub-text">Related</label>
                                </div>
                              </div>
                              <ng-container *ngIf="RFIsFormFieldsValue.createRFIInformationRequest.relatedTypeId === 0; else relateItemBTN">
                                <div nz-row class="ms-3">
                                  <div nz-col nzSpan="24" *ngIf="radioValue === 'A' || radioValue === 'B'">
                                      <a (click)="createRelateItem()" class="BT-Link fs-5" nz-icon nzType="plus-circle" nzTheme="fill"></a>
                                      <a (click)="createRelateItem()" class="BT-Link ms-2 famly-inn">Add a Related Item</a>
                                  </div>
                                </div>
                              </ng-container>
                            </ng-container>
                            <ng-template #relateItemBTN>
                              <div nz-row>
                                <div nz-col nzSpan="24">
                                  <div
                                  role="button"
                                  data-testid="related-item-tag"
                                  class="RelatedItemTag BTPopover PopoverSpacing"
                                  [ngClass]="{
                                    'RelatedItemTag-disabled': onGetByIdRes === undefined,
                                    'RelatedItemTag-actionable': onGetByIdRes !== undefined
                                  }"
>
                                  <div (click)="onGetByIdRes?.JobInformationId > 0 && EditChangeOrders(RFIsFormFieldsValue.createRFIInformationRequest.relatedItemId)">
                                    <span
                                      role="img"
                                      title="Change Order"
                                      data-icon-name="BTIcon"
                                      class="anticon RelatedItemTag--icon"
                                    >
                                      <span role="img">
                                        <img
                                          width="20"
                                          class="text-dark"
                                          src="assets/header/icons/Variations.svg"
                                        />
                                      </span>
                                    </span>
                                    <div class="RelatedItemTag--label">
                                      {{ RFIsFormFieldsValue.createRFIInformationRequest.relatedTypeName }}:
                                    </div>
                                    <div class="RelatedItemTag--subject">
                                      {{ RFIsFormFieldsValue.createRFIInformationRequest.relatedItemName }}
                                    </div>
                                  </div>
                                  <div class="RelatedItemTag--remove" tabindex="0" *ngIf="un_Display === true">
                                    <span
                                      class="anticon BTIconCloseOutlined RelatedItemTag--remove--icon"
                                      style="font-size: 10px;"
                                    >
                                      <span
                                        (click)="closerelateItemB()"
                                        nz-icon
                                        nzType="close"
                                        nzTheme="outline"
                                      ></span>
                                    </span>
                                  </div>
                                  </div>
                                </div>
                              </div>

                              
                              <!-- <div nz-row>
                                <div nz-col nzSpan="24">
                                  <div role="button" data-testid="related-item-tag" class="RelatedItemTag RelatedItemTag-disabled BTPopover BTPopover PopoverSpacing PopoverSpacing">
                                    <span role="img" title="Change Order" data-icon-name="BTIcon" class="anticon RelatedItemTag--icon">
                                      <span _ngcontent-ng-c29252568="" role="img">
                                        <img _ngcontent-ng-c29252568="" width="20" class="text-dark" src="assets/header/icons/Variations.svg">
                                      </span>
                                    </span>
                                    <div class="RelatedItemTag--label">{{ this.RFIsFormFieldsValue.createRFIInformationRequest.relatedTypeName }}:</div>
                                    <div class="RelatedItemTag--subject">{{ this.RFIsFormFieldsValue.createRFIInformationRequest.relatedItemName }}</div>
                                    <div class="RelatedItemTag--remove" tabindex="0">
                                      <span class="anticon BTIconCloseOutlined RelatedItemTag--remove--icon" style="font-size: 10px;">
                                        <span (click)="closerelateItemB()" nz-icon nzType="close" nzTheme="outline"></span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>   -->
                            </ng-template>
                         </div>
                    </div>
                </div>
              </div>  
              <div class="card border-0 mt-3" *ngIf="GetByRe === false">
                <div class="card-head border-bottom">
                  <div class="row">
                    <h2 class="famly-inn ant-typography-crad-Header">Question</h2>
                  </div>
                </div>
                <div class="card-body">
                  <div nz-row>
                    <div nz-col nzSpan="24">
                      <label class="sub-text">Question<span class="text-danger fw-bolder ms-1">*</span></label>
                    </div>
                  </div>
                  <div nz-row>
                    <div nz-col nzSpan="24">
                      <nz-form-item>
                        <nz-form-control [nzValidateStatus]="validationStates['question']">
                          <div [ngClass]="{'invalid-editor': validationStates['question'] === 'error'}">
                            <ckeditor
                              (ngModelChange)="onFieldChange('question', $event)"
                              [(ngModel)]="RFIsFormFieldsValue.createRFIInformationRequest.question"
                              name="question"
                              [editor]="Editor"
                              [config]="config"
                              class="size RFIsEditor"
                            >
                            </ckeditor>
                          </div>
                          <div *ngIf="validationStates['question'] === 'error'" class="text-danger">
                            {{ validationMessages['question'] }}
                          </div>
                        </nz-form-control>
                      </nz-form-item>
                    </div>
                  </div>
                  <div nz-row>
                    <div nz-col nzSpan="24">
                      <label class="sub-text">Attachments</label>
                    </div>
                  </div>
                  <app-create-attachments-common 
                  [TestAttachment__]="TestAttachment__" 
                  (filesUploaded)="onFilesUploaded($event)" 
                  (deleteCompleted)="deleteFile()">
                </app-create-attachments-common>
                </div>
              </div>
              <ng-container *ngIf="GetByRe === true">
                <div class="card border-0 mt-3">
                  <div class="card-head border-bottom">
                    <div class="row">
                      <h2 class="famly-inn ant-typography-crad-Header">Question</h2>
                    </div>
                  </div>
                  <div class="" style="padding: 16px 16px 4px 16px">
                    <div nz-row style="justify-content: space-between;">
                      <div nz-col>
                        <div style="display: flex; align-items: center; gap: 10px;">
                            <div nz-col class="ant-avt">
                                <nz-avatar [nzText]="onGetByIdRes?.CreatedByShortName"></nz-avatar>
                            </div>
                            <div nz-col>
                                <div class="text-overflow-auto BTUser isCurrentUser" style="font-size: 14px;">{{ onGetByIdRes?.CreatedBy }}</div>
                            </div>
                            <span style="white-space: nowrap;">{{ onGetByIdRes?.CreatedOn }}</span>
                        </div>
                      </div>   
                      <div nz-col>
                        <div nz-row  [nzGutter]="12" *ngIf="!onGetByIdRes?.IsCompleted === true">
                            <div nz-col>
                                <a (click)="RFIsGetById(onGetByIdRes?.Id, true)" class="BT-Link fs-5" nz-icon nzType="edit" nzTheme="fill"></a>                        
                            </div>
                        </div>   
                      </div>
                    </div>
                    <div nz-row style="padding-top: 12px; row-gap: 0px;">
                        <div nz-col>
                            <div class="CKEditorDisplay" style="word-break: break-word;"><div [innerHTML]="onGetByIdRes?.question"></div></div>
                        </div>
                    </div>
                    <ng-container *ngIf="selectedFilesAttachment?.length > 0">
                      <div nz-row style="padding-top: 16px; row-gap: 0px;">
                          <span class="famly-inn ant-typography-heading-bold">RFI Attachments</span>
                      </div>
                      <div nz-row style="padding-top: 4px; row-gap: 0px;" >
                          <div nz-col  nzSpan="24">
                              <label class="sub-text">Attachments</label>
                          </div>
                      </div>
                      <div nz-row style="padding-top: 16px; row-gap: 0px;">
                          <div nz-col  nzSpan="24">
                              <div class="card-body bt-file-viewer-card p-0 m-0">
                                  <div class="carousel-container" #carouselContainer>
                                    <div class="carousel-view" *ngFor="let File of selectedFilesAttachment">
                                      <div class="bt-file-viewer">
                                        <img class="img-1" *ngIf="getFileIcon(File)" [src]="getFileIcon(File)" />
                                        <img class="img-2" *ngIf="!getFileIcon(File) && !File.type.includes('video')" [src]="File.thumbUrl" />
                                        <!-- <ng-container *ngIf="File.type.includes('video')">
                                          <div class="video-container">
                                            <i nz-icon nzType="play-circle" nzTheme="outline" class="video-play-icon" (click)="playVideo(File)"></i>
                                            <video #videoPlayer id="video-{{ File.id }}" [src]="File.thumbUrl" (click)="playVideo(File)"></video>
                                          </div>
                                        </ng-container> -->
                                        <div nz-row class="action-row">
                                          <div nz-col nzSpan="24" class="text-start">
                                            <label class="BTFileUpload-SecondaryText ms-2">{{ File.name }}</label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                    </ng-container>
                </div>
                </div>  
              </ng-container>
              <!-- <ng-container *ngIf="anserResponse?.RFIId > 0"> -->
                <ng-container *ngIf="onGetByIdRes !== undefined && anserResponse?.RFIId > 0">
                  <app-rfi-new-answer 
                    [test_You]="this.anserResponse" 
                    (_onApprovedAndIsComplete)="saveRFIsAndUnApproveReopen()"
                    (RFIs_Response_Is_Complete)="on_Test_Case_One($event)"
                    ></app-rfi-new-answer>
                </ng-container>
              <!-- </ng-container> -->
              <nz-collapse [nzBordered]="false" class="According mt-3" *ngIf="onGetByIdRes !== undefined">
                <nz-collapse-panel #p [ngStyle]="{background: '#ffffff', 'border-radius': '4px', 'margin-bottom': '0px', 'margin-top': '0px',border: '0px'}" nzTitle="Custom Column" [nzExpandedIcon]="expandedIcon" [nzHeader]="HeaderTitle">
                  <hr class="p-0 m-0"/>
                  <ng-template #HeaderTitle>
                    <div class="row" style="padding: 4px 0px 0px 0px;">
                      <h2 class="ms-3 famly-inn ant-typography-crad-Header">History</h2>
                    </div>
                  </ng-template>
                  <ng-template #expandedIcon let-active>
                    {{ active }}
                    <span nz-icon nzType="caret-right" class="ant-collapse-arrow" [nzRotate]="p.nzActive ? 90 : -0"></span>
                  </ng-template>
                </nz-collapse-panel>
              </nz-collapse>
            </div>
        </div>
        <ng-template #modalFooterInRFIs>
          <div nz-row class="p-1 justify-content-end">
            <div nz-col nzSpan="24">
              <nz-space>
                <ng-container *nzSpaceItem>
                  <button 
                  *ngIf="onGetByIdRes !== undefined"
                  nz-button 
                  nzType="default" 
                  class="align-items-center"
                  [nzLoading]="isDeleteLoading"
                  [disabled]="DisabledNextActivity"
                  [class.disabled]="DisabledNextActivity"
                  (click)="deleteRFIConfirm()">
                  <span>Delete</span>
                </button>
                </ng-container>
                <ng-container *nzSpaceItem>
                  <button 
                  *ngIf="onGetByIdRes !== undefined"
                  nz-button 
                  nzType="default" 
                  class="align-items-center"
                  [disabled]="DisabledNextActivity"
                  [class.disabled]="DisabledNextActivity">
                  <span>Print</span>
                </button>
                </ng-container>
                <ng-container *nzSpaceItem>
                  <button
                  *ngIf="onGetByIdRes === undefined"
                    nz-button
                    nzType="primary"
                    class="align-items-center"
                    [nzLoading]="isSaveAndRelease"
                    (click)="saveRFIsAndRelease()"
                    [disabled]="DisabledNextActivity"
                    [class.disabled]="DisabledNextActivity"
                    >
                    Save & Release
                  </button>
                </ng-container>
                <ng-container *nzSpaceItem>
                  <button
                    *ngIf="onGetByIdRes !== undefined"
                    nz-button
                    nzType="primary"
                    class="align-items-center"
                    [nzLoading]="isSave"
                    (click)="saveRFIs()"
                    [disabled]="DisabledNextActivity"
                    [class.disabled]="DisabledNextActivity"
                    >
                    Save
                  </button>
                </ng-container>
                <ng-container *nzSpaceItem>
                  <button
                    *ngIf="onGetByIdRes !== undefined"
                    nz-button
                    nzType="primary"
                    class="align-items-center"
                    [nzLoading]="isSaveAndClose"
                    (click)="saveRFIsAndClose()"
                    [disabled]="DisabledNextActivity"
                    [class.disabled]="DisabledNextActivity"
                    >
                    Save & Close
                  </button>
                </ng-container>
              </nz-space>
            </div>
          </div>
        </ng-template>
</nz-modal>