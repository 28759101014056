import { Component, EventEmitter, Output } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { MessagingRFIsPreDefinedCodes } from 'src/app/shared/component/Models/LookUpStandardFilterCodes';
import { LookupNameSetupResponse } from 'src/Models/LeadTags';
import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';
import { AttachmentService } from 'src/Service/Attachment/attachment.service';
import { ChangeOrderInformationService } from 'src/Service/Change_Order/change-order-information.service';
import { SubVendorService } from 'src/Service/CompanyInFormation/Selections/sub-vendor.service';
import { InternalUserService } from 'src/Service/Internaluser/internal-user.service';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { WarrantyService } from 'src/Service/Project-Management/warranty/warranty.service';
import { SelectionService } from 'src/Service/Selection/selection.service';

@Component({
  selector: 'app-relate-item',
  templateUrl: './relate-item.component.html',
  styleUrls: ['./relate-item.component.css']
})
export class RelateItemComponent {
  @Output() cancel = new EventEmitter<any>();
  realtedTypeList: LookupNameSetupResponse[] = [];
  FormTitle: any[] = [];
  RelateItemFormFieldsValue: any = {};
  selectedTitle: number = null;
  preDefinedDataCodes = {
    relatedTypeField: MessagingRFIsPreDefinedCodes.RelatedTypes,
  };

  constructor(
    public localStorageService: AccessLocalStorageService,
    private _ChangeOrderInformationService: ChangeOrderInformationService,
    private _WarrantyService: WarrantyService,
    public toastsService: NzMessageService,
    private LookupFilterService: LookupFilterService,
    private selectionService: SelectionService,
  ){ }

  ngOnInit(): void {
    this.createRelateItemForm();
    this.initLoad();
  }

  createRelateItemForm() {
    this.RelateItemFormFieldsValue = {
      relatedTypeId: 0
    }
  }
  initLoad() {
    this.LookupFilterService.getLookUpValuesByFormNameId(26).subscribe((res) => {
      let realtedTypeOptions = res.result.filter(
        (x) => x.code === this.preDefinedDataCodes.relatedTypeField.code
      );
      this.realtedTypeList = realtedTypeOptions;
    });
  }
  getChangeOrder() {
    const jobId = this.localStorageService.getJobId();
    this._ChangeOrderInformationService.ChangeOrdergetData().subscribe(res => {
      let Data = res.result?.filter(zz => zz?.jobInformationId === jobId);
      if (Data?.length > 0) {
        this.FormTitle = Data.map(user => ({
          id: user?.id,
          name: user?.title,
        }));
      } else {
        this.FormTitle = [];
      }
    });
  }
  getWarranty() {
    const jobId = this.localStorageService.getJobId();
    this._WarrantyService.WarrantygetData().subscribe(res => {
      let Data = res.result?.filter(zz => zz?.jobInformationId === jobId);
      if (Data?.length > 0) {
        this.FormTitle = Data.map(user => ({
          id: user?.id,
          name: user?.title,
        }));
      } else {
        this.FormTitle = [];
      }
    });
  }
  getScheduleItem() {
    const jobId = this.localStorageService.getJobId();
    this.selectionService.getAllDataScheduleItem().subscribe(res => {
      let Data = res.result?.filter(zz => zz?.jobInformationId === jobId);
      if (Data?.length > 0) {
        this.FormTitle = Data.map(user => ({
          id: user?.id,
          name: user?.title,
        }));
      } else {
        this.FormTitle = [];
      }
    });
  }
  getToDos() {
    const jobId = this.localStorageService.getJobId();
    this.selectionService.getAllDataScheduleItem().subscribe(res => {
      let Data = res.result?.filter(zz => zz?.jobInformationId === jobId);
      if (Data?.length > 0) {
        this.FormTitle = Data.map(user => ({
          id: user?.id,
          name: user?.title,
        }));
      } else {
        this.FormTitle = [];
      }
    });
  }
  FilterForm(_Id: number) {
    switch (_Id) {
      case 737:
        this.FormTitle = []
        this.getChangeOrder();
        break;
      case 738:
        this.FormTitle = []
        break;
      case 739:
        this.FormTitle = []
        this.getWarranty();
        break;
      case 740:
        this.FormTitle = []
        this.getScheduleItem();
        break;
      case 741:
        this.FormTitle = []
        this.getToDos();
        break;
      default:
        console.log("No matching case for Id");
    }
  }
  CancelRelateItem(selectedId: any) {
    const selectedRelatedTypeId = this.realtedTypeList.find(option => option.id === this.RelateItemFormFieldsValue.relatedTypeId);
    const selectedTitle = this.FormTitle.find(option => option.id === selectedId);
    if (selectedRelatedTypeId && selectedTitle) {
        this.cancel.emit({
          TypeId: selectedRelatedTypeId?.id,
          TypeName: selectedRelatedTypeId?.name,
          ItemId: selectedTitle?.id,
          ItemName: selectedTitle?.name,
        });
    } else {
        console.log('No matching Type or Title found');
      }
  }
}