<form nz-form [formGroup]="LocationNzSelectForm" >
    <nz-card nzTitle="Add Selection Location" nzBorderless>
      <div class="content">
        <!-- Title Field -->
        <nz-form-item>
          <label  class="sub-text"  >Title</label><span class="text-danger">*</span>
          <nz-form-control [nzSm]="24" [nzXs]="24" class="ant-form-item-explain-error"        [nzErrorTip]="
          nameControl.errors?.['required']
            ? 'Required'
            : nameControl.errors?.['maxlength']
            ?  + getOverCharacters() + (getOverCharacters() > 1 ? ' characters over' : ' character over')
            : null">
            <input nz-input formControlName="name" name="name" id="name" />
          </nz-form-control>
        </nz-form-item>
      </div>
    </nz-card>
  
    <!-- Form Footer with Buttons -->
    <div class="form-footer" nz-row nzJustify="end">
      <div nz-col>
        <nz-space>
          <button *ngIf="_selectedEditLocation" nz-button nzType="default" type="button" (click)="showLocationConfirmDelete()">Delete</button>
          <button nz-button nzType="primary" type="submit" [nzLoading]="isLoadingOne" [disabled]="isLoadingOne" (click)="LocationNzSelectDropdownSaveMethod()" >
            Save
          </button>
        </nz-space>
      </div>
    </div>
  </form>