<nz-layout>
  <nz-sider
    class="border"
    nzCollapsible
    [nzCollapsedWidth]="10"
    [(nzCollapsed)]="isCollapsed"
    [nzWidth]="280"
    [nzTrigger]="null"
  >
    <div class="row">
      <app-side-menu-bar
      [isAllJobOption]="true"
      (getSelectedJobId)="getSelectedJobId($event)"
        (toggleCollapse)="toggleCollapse()"
      ></app-side-menu-bar>
    </div>
  </nz-sider>
  <nz-layout>
    <nz-page-header>
      <nz-page-header-title>
        <nz-breadcrumb class="text-start" nz-page-header-breadcrumb>
          <h1 class="bds-header-info--job-name">{{localStorage.getJobName()}}</h1>
        </nz-breadcrumb>
        <h2 class="react p-0 m-0">Warranties</h2>
      </nz-page-header-title>
      <nz-page-header-extra style="align-self: flex-end;">
        <nz-space>
          <button
            *ngIf="GetProjectWarrantyDatagriddata?.length > 0"
            nz-button
            nzType="default"
            style="padding: 5px 10px 30px 10px;"
            class="ms-2 rounded-2"
            nz-dropdown
            [nzTrigger]="'click'"
            [nzDropdownMenu]="templateDrop"
          >
            <span style="font-size: 16px;"><i class="bi bi-three-dots"></i></span>
          </button>
          <nz-dropdown-menu #templateDrop="nzDropdownMenu">
            <ul nz-menu>
              <li nz-menu-item>Export</li>
            </ul>
          </nz-dropdown-menu>
          <button
            (click)="openFilter(PROJECT_MANG_WARRANTY, PAGE_ID)"
            nz-button
            nzType="default"
            style="padding: 5px 10px 30px 15px;"
            class="ms-2 rounded-2"
          >
            <span>
              <i class="bi bi-funnel"></i>
            </span>
            <span style="font-size: 16px;">Filter</span>
          </button>
          <button
            (click)="Claim('Warranties')"
            nz-button
            nzType="primary"
            style="padding: 0px 10px 35px 12px;"
            class="ms-2 button-Daily rounded-2"
          >
          <span> <i style="font-size: 22px;" class="bi bi-plus me-2"></i> </span>
          <span style="font-size: 16px;">Claim</span>
          </button>
        </nz-space>
      </nz-page-header-extra>
    </nz-page-header>

      <nz-content class="custom-content ">
        <div class="spinner-wrapper" *ngIf="isLoading">
          <img src="/assets/Loder/new-ajax-loader.gif">
        </div>
        <div class="container h-100" style="margin-top: 40px;" *ngIf="this.GetProjectWarrantyDatagriddata === null || this.GetProjectWarrantyDatagriddata?.length === 0; else _GetWarrantyFromGridData">
          <div class="col" style="text-align: center;">
            <span role="img" size="78" data-testid="EmptyStateIcon" data-icon-name="BTIcon" class="anticon EmptyStateIcon" style="font-size: 78px;"><svg width="1em" height="1em" viewBox="0 0 32 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" class=""><path fill-rule="evenodd" clip-rule="evenodd" d="M15.5896 2.08808C15.8133 1.98742 16.0648 1.97304 16.2964 2.04494L16.4104 2.08808L17.2895 2.47681C21.1742 4.16314 24.0951 5 26 5C27.0544 5 27.9182 5.81588 27.9945 6.85074L28 7V14.3333C28 19.2851 26.1484 23.1714 22.9982 26.0716C20.9308 27.975 18.6383 29.1853 16.6325 29.8502C16.2226 29.9913 15.7774 29.9913 15.3783 29.8538C13.3617 29.1853 11.0692 27.975 9.00177 26.0716C5.94703 23.2593 4.11341 19.5197 4.00509 14.7806L4 14.3333V7C4 5.89543 4.89543 5 6 5C7.83434 5 10.6108 4.22398 14.2829 2.66068L14.7105 2.47681L15.5896 2.08808ZM16 4.094L15.4821 4.32224C11.5284 6.03725 8.4672 6.93236 6.25186 6.99631L6 7V14.3333C6 18.7018 7.60213 22.0645 10.3564 24.6003C12.0732 26.1808 13.9831 27.2313 15.6816 27.8426L15.999 27.952L16.3272 27.8397C17.7916 27.3124 19.4214 26.4531 20.9476 25.2044L21.2978 24.9093L21.6436 24.6003C24.309 22.1463 25.8954 18.9179 25.995 14.753L26 14.3333V7L25.7481 6.99631C23.6214 6.93492 20.7151 6.10753 16.9881 4.52411L16.5179 4.32224L16 4.094ZM22.2747 13.1325C22.5895 12.7479 22.5759 12.1802 22.2246 11.8108C21.8439 11.4106 21.211 11.3948 20.8108 11.7754L14.668 17.618L11.6905 14.7766L11.5944 14.6957C11.1951 14.3998 10.6287 14.4408 10.2766 14.8095C9.89531 15.209 9.91003 15.842 10.3095 16.2234L13.9761 19.7234L14.0689 19.8018C14.4542 20.0897 14.999 20.064 15.3558 19.7246L22.1892 13.2246L22.2747 13.1325Z"></path></svg></span>
            <div class="row">
              <div class="col mt-2">
                <h1 class="ant-typography-crad-Header">Track warranty claims</h1>
              </div>
            </div>
            <div class="col mt-3">
              <p>Manage claims from creation through completion with
              <br> warranties.</p>
            </div>
            <div class="col mt-4"
              style="display: flex; justify-content: center; align-items: center;">
              <nz-space>
                <button *nzSpaceItem nz-button nzType="default" class="rounded-1"><i
                    class="bi bi-box-arrow-up-right me-2"></i><span class="BTN-Font-Famliy">Learn How</span></button>
                <button  class="ms-2 button-Daily rounded-1" *nzSpaceItem nz-button nzType="primary" (click)="Claim('Warranties')">Add a warranty claim</button>
              </nz-space>
            </div>
          </div>
        </div>
        <ng-template #_GetWarrantyFromGridData>
          <div  class="container-fluid">
            <div class="row">
            <kendo-grid (columnResize)="saveColumnSizes($event)" [data]="GetProjectWarrantyDatagriddata" [pageSize]="5"
            [sortable]="true" [resizable]="true" [reorderable]="true">
            <kendo-grid-column *ngFor="let column of displayedColumns; let i = index" [title]="column" [field]="column"
              [minResizableWidth]="150" [maxResizableWidth]="800" [width]="getColumnWidthSize[i]"
              [sticky]="isColumnSticky[i]" [headerClass]="'pinned-header'">
              <ng-template kendoGridHeaderTemplate let-columnIndex="columnIndex">
                <div class="d-flex align-items-center">
                  <span>{{ column }}</span>
                  <button (click)="toggleColumnSticky(columnIndex)" class="btn btn-link">
                    <i class="bi" [ngClass]="{
                                  'bi-pin-fill': isColumnSticky[columnIndex],
                                  'bi-pin': !isColumnSticky[columnIndex]
                                }"></i>
                  </button>
                </div>
              </ng-template>
              <ng-template kendoGridColumnMenuTemplate let-columnIndex="columnIndex">
                <button (click)="toggleColumnSticky(columnIndex)" class="btn btn-link">
                  <i class="bi" [ngClass]="{
                                'bi-pin-fill': isColumnSticky[columnIndex],
                                'bi-pin': !isColumnSticky[columnIndex]
                              }"></i>
                </button>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <ng-container *ngIf="column === 'Title'">
                  <a  nz-button nzType="link" (click)="FormEdit(dataItem.Id)">
                    <span class="underlineWarnTitle text-overflow-auto">{{ dataItem?.Title }}</span>
                  </a>
                </ng-container>
                <ng-container *ngIf="column === 'Claim #'">{{ dataItem?.ClaimNumber }}</ng-container>

                <!-- <ng-container *ngIf="column === 'RFIs'">{{ dataItem?.rfi?.rfiInformations.length }}</ng-container> -->
                <ng-container *ngIf="column === 'RFIs'" >
                  <a nz-button nzType="link">
                    <!-- (click)="EditRelatedRFIs(dataItem?.Id)" -->
                    <span class="underlineWarnTitle">
                      {{ dataItem?.TotalRfi}}
                    </span>
                  </a>
                  </ng-container>
                <ng-container *ngIf="column === 'Job'">
                  {{ dataItem?.JobName }}
              </ng-container>
                <ng-container *ngIf="column === 'Comments'">
                  <a nz-button nzType="link" (click)="conversationModelOpen(dataItem)">
                    <span class="underlineWarnTitle">
                      {{ dataItem?.TotalMessaging }}
                    </span>
                  </a>
                </ng-container>

                <!-- <ng-container *ngIf="column === 'Email'">
                  <span>{{ dataItem.email }} </span>
                  <span *ngFor="let email of dataItem.applicationUserEmailParameters; let last = last">
                    <span *ngIf="!last && isExpanded(rowIndex)">{{ email?.email }}, </span>
                    <span *ngIf="last && isExpanded(rowIndex)">{{ email?.email }}</span>
                    <span style="cursor: pointer;"
                      *ngIf="last && !isExpanded(rowIndex) && dataItem.applicationUserEmailParameters.length > 0"
                      (click)="toggleAdditionalEmails(rowIndex)">...</span>
                  </span>
                </ng-container> -->
                <ng-container *ngIf="column === 'Added'">
                  <span class="text-decoration-none">{{ dataItem?.AddedCost }}</span>
                  <ng-template kendoGridFooterTemplate>
                    <strong>{{ getTotalAddedCost() | currency:'USD':'symbol' }}</strong>
                  </ng-template>
                </ng-container>

                <ng-container *ngIf="column === 'Files'">
                  <ng-container *ngIf="dataItem?.TotalAttachment > 0">
                    <a nz-button nzType="link" class="p-0 m-0" nz-dropdown [nzDropdownMenu]="menu4" nzTrigger="click">
                      <svg viewBox="64 64 896 896" focusable="false" data-icon="paper-clip" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                        <path d="M779.3 196.6c-94.2-94.2-247.6-94.2-341.7 0l-261 260.8c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0012.7 0l261-260.8c32.4-32.4 75.5-50.2 121.3-50.2s88.9 17.8 121.2 50.2c32.4 32.4 50.2 75.5 50.2 121.2 0 45.8-17.8 88.8-50.2 121.2l-266 265.9-43.1 43.1c-40.3 40.3-105.8 40.3-146.1 0-19.5-19.5-30.2-45.4-30.2-73s10.7-53.5 30.2-73l263.9-263.8c6.7-6.6 15.5-10.3 24.9-10.3h.1c9.4 0 18.1 3.7 24.7 10.3 6.7 6.7 10.3 15.5 10.3 24.9 0 9.3-3.7 18.1-10.3 24.7L372.4 653c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0012.7 0l215.6-215.6c19.9-19.9 30.8-46.3 30.8-74.4s-11-54.6-30.8-74.4c-41.1-41.1-107.9-41-149 0L463 364 224.8 602.1A172.22 172.22 0 00174 724.8c0 46.3 18.1 89.8 50.8 122.5 33.9 33.8 78.3 50.7 122.7 50.7 44.4 0 88.8-16.9 122.6-50.7l309.2-309C824.8 492.7 850 432 850 367.5c.1-64.6-25.1-125.3-70.7-170.9z"></path>
                      </svg>
                      <span>{{ dataItem?.TotalAttachment }}</span>
                      <span nz-icon nzType="down"></span>
                    </a>

                    <nz-dropdown-menu #menu4="nzDropdownMenu">
                      <ul nz-menu>
                        <li *ngFor="let fileName of dataItem?.FileNames.split(','); let i = index" nz-menu-item>
                          {{ fileName?.trim() }} <!-- Trimming in case of extra spaces -->
                        </li>
                      </ul>
                    </nz-dropdown-menu>
                  </ng-container>
                </ng-container>




                <ng-container *ngIf="column === 'Priority'">
                  <span class="status-text badge" [ngClass]="{ 'low': dataItem?.PriorityName === 'Low', 'medium': dataItem?.PriorityName === 'Medium', 'high': dataItem?.PriorityName === 'High','emergency': dataItem?.PriorityName === 'Emergency' }">{{ dataItem?.PriorityName }}</span>
                </ng-container>

              <ng-container *ngIf="column === 'Category'">
                <span class="text-decoration-none"> {{ dataItem?.CategoryName }}</span>
              </ng-container>
              <ng-container *ngIf="column === 'Scheduling Information '">
                <td colspan="1" data-testid="cell-warrantyScheduleList" style="text-align: left;">
                  <div>
                    <span *ngIf="dataItem?.SchedulingInformationStatus">
                      {{ dataItem?.SchedulingInformationStatus }}
                    </span>
                    <span *ngIf="dataItem?.SchedulingInformationStatus === 'TBD'">
                      ,
                      <img src="assets/warrantyImages/tool.png" alt="Tool Icon" class="custom-icon">
                      <span class="icon-alert">!</span>,
                      <img src="assets/warrantyImages/ownerIconhome.png" alt="Home Icon" class="custom-icon">
                      <span class="icon-alert">!</span>
                    </span>
                  </div>
                  <div *ngIf="dataItem?.SchedulingInformationStatus !== 'TBD'">
                    {{ dataItem?.SchedulingInformationOn }},
                  </div>
                  <div>{{ dataItem?.SchedulingInformationAssignee }}</div>
                  <div *ngIf="dataItem?.ServiceCount ">
                    <a class="BTLink underline" (click)="FormEdit(dataItem.Id)">
                      {{ dataItem?.ServiceCount }} more
                    </a>
                  </div>
                </td>
              </ng-container>

                <ng-container *ngIf="column === 'Description'">

                  <a class="text-decoration-none">{{ dataItem?.ProblemDescription }}</a>
                </ng-container>

                <ng-container *ngIf="column === 'Created Date'">
                    {{ dataItem?.CreatedOn | date : "MMM d, yyyy" }}

            </ng-container>
            <ng-container *ngIf="column === 'Follow up '">
                {{ dataItem?.FollowUpOn | date : "MMM d, yyyy" }}
            </ng-container>
       
            <ng-container *ngIf="column === 'Feedback '">
              <nz-tag *ngIf="dataItem?.ServiceCount"
              [nzColor]="warrntystatussetupColorMap?.[dataItem?.LastFeedBackName]?.backgroundColor"
              [ngStyle]="{
                color: warrntystatussetupColorMap?.[dataItem?.LastFeedBackName]?.textColor
              }"
              style="
                background: #c7d0d9;
                vertical-align: middle;
                text-align: center;
                white-space: nowrap;
                padding: .2em .6em;
                min-width: 16px;
                line-height: 16px;
                font-size: 12px;
                border-radius: 12px;
                align-items: center;
                font-weight: 600;
                text-overflow: unset;
              "
            >
              {{ dataItem?.LastFeedBackName }}, {{ dataItem?.ServiceCount }}
            </nz-tag>

                </ng-container>


            <ng-container *ngIf="column === 'Notes'">
              <!-- Replace 'phoneNumber' with the appropriate property containing creator information -->
              <a class="text-decoration-none">{{ dataItem?.Notes }}</a>
            </ng-container>
            <ng-container *ngIf="column === 'Service Coordinator'">
              {{ dataItem?.ServiceCoordinator }}
            </ng-container>
            <ng-container *ngIf="column === 'Class'">
              {{ dataItem?.Class }}
            </ng-container>

            <ng-container *ngIf="column === 'Claim Status'">
              {{ dataItem?.StatusName }}
          </ng-container>
            <ng-container *ngIf="column === 'Created By'">
              {{ dataItem?.CreatedByUser }}
          </ng-container>
            <ng-container *ngIf="column === 'Original Schedule Item'">
              {{ dataItem?.OriginalScheduleItem }}
          </ng-container>
            <ng-container *ngIf="column === 'Original Schedule Assignee'">
              {{ dataItem?.SchedulingInformationAssignee }}
          </ng-container>




              </ng-template>


            </kendo-grid-column>


          </kendo-grid>
          </div>
          </div>
        </ng-template>
    </nz-content>

<nz-footer class="custom-footer">
  <div class="StickyFooterWrapper react">
    <div class="StickyFooter react" style="left: -13px; right: -8px;padding: 0px 40px 0px 40px;">
       <div class="ListActionBar bottom StickyLayoutHeader react" style="left: -13px; right: -8px;">
        <div nz-row style="margin-top: 10px;">
          <div nz-col nzSpan="14">
            <ng-container *ngIf="PAGE_ID">
              <div class="row d-flex justify-content-start mb-1 ">
                <div class="col-md-4">
                  <div class="input-group">
                    <nz-select [(ngModel)]="selectedValueControl" name="standardFilter" (ngModelChange)="onFilterChange($event)" nzShowSearch [nzAutoFocus]="true" nzPlaceHolder="Standard Filter" >
                      <nz-option  *ngFor="let standard of getStandardViewFilterData"
                        [nzLabel]="standard.name + (standard.isSetAsDefault ? ' ' : '')"
                        [nzValue]="standard.id"
                        nzCustomContent>
                        <div class="option-content">
                          <span *ngIf="standard.icon" nz-icon [nzType]="standard.icon"></span>
                          <span>{{standard.name}}</span>
                          <span *ngIf="standard.isSetAsDefault" class="status-text badge info margin-left-xss" style="margin-left: 22px;">Default</span>
                        </div>
                      </nz-option>
                    </nz-select>
                  </div>
                </div>
                <div class="col-md-1">
                  <button nz-button class="border-0"
                     nz-popover
                     [nzPopoverContent]="contentTemplate"
                     [nzPopoverPlacement]="'top'" (click)="openStandardViewFilterComponent()">
                    <span nz-popover class="text-primary text-dark fw-bold fs-5" nz-icon nzType="ellipsis" nzTheme="outline"></span>
                  </button>
                </div>
                <div class="col-md-2">
                  <div class="mt-2" *ngIf="selectedFilter === 1">
                    <p>Your filter has unsaved changes.</p>
                  </div>
                  <div class="mt-2" *ngIf="selectedFilter === 0">
                    <p>Your filter has unsaved changes.</p>
                  </div>
                </div>
                <div class="col-md-3">
                  <button (click)="openStandardViewFilterComponent('saveAs')" type="button" nz-button nzType="default"
                   class="me-2" style="font-size: 12px;" *ngIf="selectedFilter === 1">Save As</button>
                  <button (click)="reInitReset()" nz-button nzType="default" style="font-size: 12px;"
                   *ngIf="selectedFilter === 1" type="button">Reset</button>
                  <button (click)="saveEditStandardView()" nz-button nzType="primary" type="button" class="me-2"
                   style="font-size: 12px;" *ngIf="selectedFilter === 0">Save</button>
                  <button (click)="openStandardViewFilterComponent('saveAs')" nz-button nzType="default" class="me-2"
                   style="font-size: 12px;" *ngIf="selectedFilter === 0" type="button">Save As</button>
                  <button (click)="reInitReset()" nz-button nzType="default" style="font-size: 12px;"
                   *ngIf="selectedFilter === 0" type="button">Reset</button>
                </div>
              </div>
            </ng-container>
          </div>
          <div nz-col nzSpan="10">
            <div class="d-flex justify-content-end align-items-end">
              <nz-pagination style="float: right;" [nzPageIndex]="currentPage" [nzTotal]="totalRecords"
              [nzPageSize]="pageSize" nzShowSizeChanger (nzPageIndexChange)="onPageChange($event)"
              (nzPageSizeChange)="onPageSizeChange($event)" [nzPageSizeOptions]="[10, 20, 50, 75, 100]"></nz-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</nz-footer>
</nz-layout>
</nz-layout>


<ng-template #contentTemplate>
  <div>
    <p>Manage Saved Views</p>

  </div>
</ng-template>


