import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { NZ_MODAL_DATA, NzModalService } from 'ng-zorro-antd/modal';
import { LeadOpportunityResponse, LeadOpportunityResult } from 'src/Models/LeadTags';
import { ShiftInformationResponse } from 'src/Models/Time-Clock/clock-in';
import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';
import { LeadOpportunitiesService } from 'src/Service/lead-opportunities.service';
import { LeadActivityFormComponent } from '../lead-activity-form/lead-activity-form.component';
import { LoadingIndicatorService } from 'src/shared/Shared-Services/loading-indicator.service';
import { LeadOpportunitiesNewLeadOpportunitiesComponent } from '../Modal/lead-opportunities-new-lead-opportunities/lead-opportunities-new-lead-opportunities.component';

@Component({
  selector: 'app-lead-opportunity-select-dialog-box',
  templateUrl: './lead-opportunity-select-dialog-box.component.html',
  styleUrls: ['./lead-opportunity-select-dialog-box.component.css']
})
export class LeadOpportunitySelectDialogBoxComponent implements OnInit {
  @Output() cancel = new EventEmitter<void>();
  @Output() leadActivityOnSavedCompleted = new EventEmitter<void>(); // Add thi
  @Output() onSaveCompleteLeadPro  = new EventEmitter();
  leadOptions: LeadOpportunityResponse [] = [];
  leadOptionsNew: LeadOpportunityResult;
  selectedLead: LeadOpportunityResponse;
  selectedLeadId: any;
  holdResponse: ShiftInformationResponse[];
  isContentExceedsTwoLines: boolean = false;
  constructor(
    private modal: NzModalService,
    private leadOpportunitiesService: LeadOpportunitiesService,
    private leadOppService: LeadOpportunitiesService,
    private loadService: LoadingIndicatorService,
    @Inject(NZ_MODAL_DATA) public data: any,
    @Inject(NZ_MODAL_DATA) public markComplete___True: any,
     public accessLocalStorageService:AccessLocalStorageService){

  }
  ngOnInit(){
    this.leadOpportunitiesService.getAllLeadData().subscribe(Data => {
      this.leadOptions = Data.result;
      // console.log("show all data for lead",this.leadOptions);

    });
  }
  onLeadSelect($event: any): void {
    let id = this.leadOptions.find(x => x.id === $event);
    let _Id  = id.id
    this.loadService.show();
    this.leadOppService.getLeadById(_Id).subscribe(Data => {
      this.leadOptionsNew = Data.result;
      // console.log("show all data for leadOptionsNew",this.leadOptionsNew);

    this.loadService.hide();

      this.openLeadActivityFormModal(id);
    });
     this.cancel.emit();
  }
  openLeadActivityFormModal(selectedLead :LeadOpportunityResponse ) {


    if(this.data.type === 'ACTIVITY'){
      let IsTrue = this.markComplete___True.isMark
      const modalRef = this.modal.create({
        nzContent: LeadActivityFormComponent,
        nzData:{
          leadData: selectedLead,
          isMark: IsTrue,
          isOther: true,
          leadOptionsNew: this.leadOptionsNew,


      } ,
        nzFooter: null
      });
      modalRef.componentInstance.LeadOpportunityResponse = this.leadOptionsNew;

      modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
      this.selectedLead = null;
      });
      modalRef.componentInstance.leadActivityOnSavedCompleted.subscribe(() => {
        this.leadActivityOnSavedCompleted.emit(); // Emit event when activity is saved
      });
    }else{
      const modalRefs = this.modal.create({
        nzContent: LeadOpportunitiesNewLeadOpportunitiesComponent,
        nzFooter: null,
        nzTitle: null
      });
      modalRefs.componentInstance.leadOpportunityResponseId = this.leadOptionsNew.Id;
      modalRefs.componentInstance.flagDialogBoxForProposalTabActive = 11;
      modalRefs.componentInstance.cancel.subscribe(() => {
        modalRefs.destroy();
        });
      // const modalRef = this.modal.create({
      //   nzContent: NewProposalComponent,
      //   nzFooter: null,
      //   nzTitle: null
      // });

      // modalRef.componentInstance.leadOpportunityProposalSaveResponse = this.leadOptionsNew;
      // modalRef.componentInstance.cancel.subscribe(() => {
      //   modalRef.destroy();
      //   this.selectedLead = null;
      // });
    }



  }
}
