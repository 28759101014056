<nz-modal [(nzVisible)]="isVisibleInTimeClock" [nzTitle]="InChangeOrder" [nzFooter]="modalFooterInTimeClock" [nzBodyStyle]="{
    background: '#f1f4fa',
    padding: '14px',
    maxHeight: '69vh',
    'overflow-y': 'auto',
    'overflow-x': 'hidden',
  }" [nzStyle]="{ top: '25px'}" [nzWidth]="'96%'" (nzOnCancel)="CancelTimeClock()">
    <ng-template #InChangeOrder>
      <div class="row">
        <h2 class="p-0 m-0 famly-inn ant-typography-Modal">Time Clock</h2>
      </div>
    </ng-template>
    <form nz-form [formGroup]="timeClockSetupFormFields">
        <div class="content" *nzModalContent>
          <div class="card-container">
              <div class="card border-0 mt-0">
                <div class="card-head border-bottom">
                  <div class="row pt-2 pb-2">
                    <h2 class="ms-3 famly-inn ant-typography-crad-Header">General Defaults</h2>
                  </div>
                </div>
                <div class="card-body">
                    <div nz-row>
                        <div nz-col [nzXs]="4">
                            <label class="sub-text">Week Start</label>
                            <nz-select nzShowSearch formControlName="weekStartId" name="weekStartId">
                                <nz-option *ngFor="let option of WeekStart" [nzLabel]="option.name" [nzValue]="option.id"></nz-option>
                            </nz-select>
                        </div>
                    </div>
                    <div nz-row>
                        <div nz-col [nzXs]="4">
                            <label class="sub-text">Hourly Rate Tracking</label>
                            <nz-select nzShowSearch formControlName="hourlyRateTrackingId" name="hourlyRateTrackingId">
                                <nz-option *ngFor="let option of hourlyRateTracking" [nzLabel]="option.name" [nzValue]="option.id"></nz-option>
                            </nz-select>
                        </div>
                    </div>
                    <div nz-row class="pt-2">
                        <label nz-checkbox formControlName="isUseFormattingOTimeClockTotals" name="isUseFormattingOTimeClockTotals">Use HH:MM formatting on time clock totals.
                            <i class="bi bi-info-circle-fill text-secondary ms-1" nz-popover [nzPopoverContent]="useFormattingOTimeClockTotals" nzPopoverPlacement="top"></i>
                        </label>
                    </div>
                    <div nz-row class="pt-2">
                        <label nz-checkbox formControlName="isWhenAJobIsDeletedPromptToDeleteCorrespondingTimeCardItems" name="isWhenAJobIsDeletedPromptToDeleteCorrespondingTimeCardItems">When a job is deleted, prompt to delete corresponding time card items.
                            <i class="bi bi-info-circle-fill text-secondary ms-1" nz-popover [nzPopoverContent]="whenAJobIsDeletedPromptToDeleteCorrespondingTimeCardItems" nzPopoverPlacement="top"></i>
                        </label>
                    </div>
                    <div nz-row class="pt-2">
                        <label nz-checkbox formControlName="isSendOneTimeNotificationToInternalUsersThatHaveBeenClockedInForOverHours" name="isSendOneTimeNotificationToInternalUsersThatHaveBeenClockedInForOverHours">Send one time notification to internal users that have been clocked in for over 12 hours.</label>
                    </div>
                    <div nz-row class="pt-2">
                        <label nz-checkbox formControlName="isAutomaticallyEndBreaksAfterMinutesNo" name="isAutomaticallyEndBreaksAfterMinutesNo">Automatically end breaks after minutes.</label>
                        <nz-input-number formControlName="automaticallyEndBreaksAfterMinutesNo" name="automaticallyEndBreaksAfterMinutesNo" style="width: 100px; border-radius: 4px;" [nzMin]="1"></nz-input-number>
                        <i class="bi bi-info-circle-fill text-secondary ms-1" nz-popover [nzPopoverContent]="automaticallyEndBreaksAfterMinutesNo" nzPopoverPlacement="top"></i>
                    </div>
                    <div nz-row class="pt-2">
                        <label nz-checkbox formControlName="isShowGeneralJob" name="isShowGeneralJob">Show General Job
                            <i class="bi bi-info-circle-fill text-secondary ms-1" nz-popover [nzPopoverContent]="showGeneralJob" nzPopoverPlacement="top"></i>
                        </label>
                    </div>  
                </div>
              </div>
              <div class="card border-0 mt-3">
                <div class="card-head border-bottom">
                  <div class="row pt-2 pb-2">
                    <h2 class="ms-3 famly-inn ant-typography-crad-Header">Overtime Defaults</h2>
                  </div>
                </div>
                <div class="card-body">
                    <div nz-row class="pt-2">
                        <label nz-checkbox formControlName="isShowOvertime" name="isShowOvertime" (ngModelChange)="onShowOvertimeChange($event)">Show overtime</label>
                    </div>
                    <ng-container *ngIf="showOvertimeContent === true">
                        <div nz-row>
                            <div nz-col [nzXs]="14" [nzOffset]="1">
                                <div nz-row class="pt-2">
                                    <label nz-checkbox formControlName="isEmployeesAccrueDailyOvertime" name="isEmployeesAccrueDailyOvertime" (ngModelChange)="onShowEmployeesvertimeChange($event)">Employees accrue daily overtime</label>
                                </div>
                                <div nz-row *ngIf="ShowEmployeesvertime === true">
                                    <div nz-col [nzOffset]="2">
                                        <div nz-row class="padding-remove">
                                            <div nz-col [nzXs]="24">
                                                <label class="sub-text">After # of Hours<span class="ms-2 text-danger text-bold">*</span></label>
                                                <nz-form-item>
                                                  <nz-form-control [nzValidateStatus]="Test_Case_One ? 'error' : null">
                                                    <nz-input-number 
                                                      formControlName="afterNoOfHoursOverTimeDaily" 
                                                      name="afterNoOfHoursOverTimeDaily" 
                                                      [nzMin]="1"
                                                      style="width: 100px; border-radius: 4px;" 
                                                      (ngModelChange)="OnAfterNoOfHoursOverTimeDaily($event)">
                                                    </nz-input-number>
                                                  </nz-form-control>
                                                </nz-form-item>
                                                <div *ngIf="Test_Case_One" class="text-danger">
                                                  Required
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>    
                            </div>
                        </div>
                        <div nz-row>
                            <div nz-col [nzXs]="14" [nzOffset]="1">
                                <div nz-row class="pt-2">
                                    <label nz-checkbox formControlName="isEmployeesAccrueWeeklyOvertime" name="isEmployeesAccrueWeeklyOvertime" (ngModelChange)="onShowEmployeesWeeklyOvertimeChange($event)">Employees accrue weekly overtime</label>
                                </div>
                                <div nz-row *ngIf="ShowEmployeesWeeklyOvertime === true">
                                    <div nz-col [nzOffset]="2">
                                        <div nz-row class="padding-remove">
                                            <div nz-col [nzXs]="24">
                                                <label class="sub-text">After # of Hours<span class="ms-2 text-danger text-bold">*</span></label>
                                                <nz-form-item>
                                                  <nz-form-control [nzValidateStatus]="Test_Case_Two ? 'error' : null">
                                                    <nz-input-number 
                                                      formControlName="afterNoOfHoursOverTimeWeekly" 
                                                      name="afterNoOfHoursOverTimeWeekly" 
                                                      [nzMin]="1"
                                                      style="width: 100px; border-radius: 4px;" 
                                                      (ngModelChange)="OnAfterNoOfHoursOverTimeWeekly($event)">
                                                    </nz-input-number>
                                                  </nz-form-control>
                                                </nz-form-item>
                                                <div *ngIf="Test_Case_Two" class="text-danger">
                                                  Required
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>    
                            </div>
                        </div>
                        <div nz-row>
                            <div nz-col [nzXs]="10" [nzOffset]="1">
                                <div nz-row>
                                    <div nz-col>
                                        <label class="sub-text">Overtime Days<i class="bi bi-info-circle-fill text-secondary ms-1"></i></label>
                                    </div>
                                </div>
                                <div nz-row>
                                    <div nz-col [nzXs]="15">
                                        <nz-tree-select
                                          formControlName="overtimeDays" name="overtimeDays"
                                          style="width: 100%"
                                          [nzNodes]="overtimeDaysGroupNodes"
                                          [nzShowSearch]="true"
                                          [nzAllowClear]="true"
                                          (ngModelChange)="onOvertimeDaysChanges($event)"
                                          nzCheckable>
                                        </nz-tree-select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <div nz-row class="pt-2">
                        <label nz-checkbox formControlName="isShowDoubleOvertime" name="isShowDoubleOvertime" (ngModelChange)="onShowOverdoubletimeChange($event)">Show double overtime</label>
                    </div>
                    <ng-container *ngIf="showOverdoubletimeContent === true">
                        <div nz-row>
                            <div nz-col [nzXs]="14" [nzOffset]="1">
                                <div nz-row class="pt-2">
                                    <label nz-checkbox formControlName="isEmployeesAccrueDailyDoubleOvertime" name="isEmployeesAccrueDailyDoubleOvertime" (ngModelChange)="onShowEmployeesAccrueDailyDoubleOverTimeChange($event)">Employees accrue daily double overtime</label>
                                </div>
                                <div nz-row *ngIf="ShowEmployeesAccrueDailyDoubleOvertime === true">
                                    <div nz-col [nzOffset]="2">
                                        <div nz-row class="padding-remove">
                                            <div nz-col [nzXs]="24">
                                                <label class="sub-text">After # of Hours<span class="ms-2 text-danger text-bold">*</span></label>
                                                <nz-form-item>
                                                  <nz-form-control [nzValidateStatus]="Test_Case_Three ? 'error' : null || on_MustGreaterThanValue_Daily ? 'error' : null">
                                                    <nz-input-number 
                                                      formControlName="afterNoOfHoursDoubleOverTimeDaily" 
                                                      name="afterNoOfHoursDoubleOverTimeDaily" 
                                                      [nzMin]="1"
                                                      style="width: 100px; border-radius: 4px;" 
                                                      (ngModelChange)="OnAfterNoOfHoursDoubleOverTimeDaily($event)">
                                                    </nz-input-number>
                                                  </nz-form-control>
                                                </nz-form-item>
                                                <div *ngIf="Test_Case_Three" class="text-danger">
                                                  Required
                                                </div>
                                                <div *ngIf="on_MustGreaterThanValue_Daily" class="text-danger">
                                                  Must be greater than daily value
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>    
                            </div>
                        </div>
                        <div nz-row>
                            <div nz-col [nzXs]="14" [nzOffset]="1">
                                <div nz-row class="pt-2">
                                    <label nz-checkbox formControlName="isEmployeesAccrueWeeklyDoubleOvertime" name="isEmployeesAccrueWeeklyDoubleOvertime" (ngModelChange)="onShowEmployeesAccrueWeeklyDoubleOverTimeChange($event)">Employees accrue weekly double overtime</label>
                                </div>
                                <div nz-row *ngIf="ShowEmployeesAccrueWeeklyDoubleOvertime === true">
                                    <div nz-col [nzOffset]="2">
                                        <div nz-row class="padding-remove">
                                            <div nz-col [nzXs]="24">
                                                <label class="sub-text">After # of Hours<span class="ms-2 text-danger text-bold">*</span></label>
                                                <nz-form-item>
                                                  <nz-form-control [nzValidateStatus]="Test_Case_Four ? 'error' : null || on_MustGreaterThanValue_Weekly ? 'error' : null">
                                                    <nz-input-number 
                                                      formControlName="afterNoOfHoursDoubleOverTimeWeekly" 
                                                      name="afterNoOfHoursDoubleOverTimeWeekly" 
                                                      [nzMin]="1"
                                                      style="width: 100px; border-radius: 4px;" 
                                                      (ngModelChange)="OnAfterNoOfHoursDoubleOverTimeWeekly($event)">
                                                    </nz-input-number>
                                                  </nz-form-control>
                                                </nz-form-item>
                                                <div *ngIf="Test_Case_Four" class="text-danger">
                                                  Required
                                                </div>
                                                <div *ngIf="on_MustGreaterThanValue_Weekly" class="text-danger">
                                                  Must be greater than daily value
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>    
                            </div>
                        </div>
                        <div nz-row>
                            <div nz-col [nzXs]="10" [nzOffset]="1">
                                <div nz-row>
                                    <div nz-col>
                                        <label class="sub-text">Double Overtime Days<i class="bi bi-info-circle-fill text-secondary ms-1"></i></label>
                                    </div>
                                </div>
                                <div nz-row class="padding-remove">
                                    <div nz-col [nzXs]="15">
                                      <nz-form-item>
                                        <nz-form-control [nzValidateStatus]="on_Error_DoubleOvertimeDays ? 'error' : null">
                                          <nz-tree-select
                                            formControlName="doubleOvertimeDays" name="doubleOvertimeDays"
                                            style="width: 100%"
                                            [nzNodes]="overtimeDaysGroupNodes"
                                            [nzShowSearch]="true"
                                            [nzAllowClear]="true"
                                            (ngModelChange)="onDoubleOvertimeDaysChanges($event)"
                                            nzCheckable>
                                          </nz-tree-select>
                                        </nz-form-control>
                                      </nz-form-item>
                                      <div *ngIf="on_Error_DoubleOvertimeDays" class="text-danger">
                                        Days selected for double overtime cannot also be selected for overStime
                                      </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
              </div>
              <div class="card border-0 mt-3">
                <div class="card-head border-bottom">
                  <div class="row pt-2 pb-2">
                    <h2 class="ms-3 famly-inn ant-typography-crad-Header">Geofencing</h2>
                  </div>
                </div>
                <div class="card-body">
                    <div nz-row>
                        <label class="sub-text">Set a geofence radius and receive notifications when a user clocks in or out of a job beyond the geofence. See Internal User details to turn this on for each user.</label>
                    </div>
                    <div nz-row>
                        <div nz-col [nzXs]="4">
                            <label class="sub-text">Select Radius
                                <i class="bi bi-info-circle-fill text-secondary ms-1" nz-popover [nzPopoverContent]="_SelectRadius" nzPopoverPlacement="top"></i>
                            </label>
                        </div>
                    </div>
                    <div nz-row [nzGutter]="5">
                        <div nz-col [nzXs]="8">
                            <nz-select nzShowSearch formControlName="radiusId" name="radiusId">
                                <nz-option *ngFor="let option of selectRadius" [nzLabel]="option.name" [nzValue]="option.id"></nz-option>
                            </nz-select>
                        </div>
                        <div nz-col [nzXs]="8">
                            <nz-select nzShowSearch formControlName="radiusUnitId" name="radiusUnitId" (ngModelChange)="onSelectKMChanges($event)">
                                <nz-option *ngFor="let option of selectRadiusKMT" [nzLabel]="option.name" [nzValue]="option.id"></nz-option>
                            </nz-select>
                        </div>
                    </div>
                    <div nz-row class="pt-2">
                        <label nz-checkbox formControlName="isDefaultTimeClockGeofencingToOnForAllNewJobs" name="isDefaultTimeClockGeofencingToOnForAllNewJobs">Default Time Clock Geofencing to ON for all new jobs</label>
                    </div>
                </div>
              </div>
          </div>
        </div>
        <ng-template #modalFooterInTimeClock>
          <div nz-row class="p-1 justify-content-end">
            <div nz-col nzSpan="24">
              <nz-space>
                <ng-container *nzSpaceItem>
                  <button
                    nz-button
                    nzType="primary"
                    class="align-items-center"
                    [nzLoading]="isSave"
                    [disabled]="DisabledNextActivity"
                    [class.disabled]="DisabledNextActivity"
                    (click)="saveTimeClockSetup()">
                    Save
                  </button>
                </ng-container>
              </nz-space>
            </div>
          </div>
        </ng-template>
    </form>
  </nz-modal>
<ng-template #useFormattingOTimeClockTotals>
    <div style="border-radius: 10px; width: 210px; font-size: 13px; line-height: 25px;">
      <div nz-row>
        <div nz-col nzSpan="24">
          <div class="famly-inn">
            The HH:MM format will display time clock totals as 25:45. Otherwise totals will display in decimal format 25.75 (hrs).
          </div>
        </div>
      </div>
    </div>
</ng-template>
<ng-template #whenAJobIsDeletedPromptToDeleteCorrespondingTimeCardItems>
    <div style="border-radius: 10px; width: 210px; font-size: 13px; line-height: 25px;">
      <div nz-row>
        <div nz-col nzSpan="24">
          <div class="famly-inn">
            When unchecked, any recorded shifts on deleted job will be transferred to General.
          </div>
        </div>
      </div>
    </div>
</ng-template>
<ng-template #automaticallyEndBreaksAfterMinutesNo>
    <div style="border-radius: 10px; width: 210px; font-size: 13px; line-height: 25px;">
      <div nz-row>
        <div nz-col nzSpan="24">
          <div class="famly-inn">
            When checked, time clock breaks will automatically end. User will choose to take a break and the break will end after the set break length expires and their work time will resume. This option will allow users to quickly indicate when they are taking an unpaid break without having to manually end the break.
          </div>
        </div>
      </div>
    </div>
</ng-template>
<ng-template #showGeneralJob>
    <div style="border-radius: 10px; width: 210px; font-size: 13px; line-height: 25px;">
      <div nz-row>
        <div nz-col nzSpan="24">
          <div class="famly-inn">
            Any shifts in the General Job will remain and can be accessible by viewing all jobs list.
          </div>
        </div>
      </div>
    </div>
</ng-template>
<ng-template #_SelectRadius>
    <div style="border-radius: 10px; width: 210px; font-size: 13px; line-height: 25px;">
      <div nz-row>
        <div nz-col nzSpan="24">
          <div class="famly-inn">
            Changes made will only apply to any new Time Clock shifts
          </div>
        </div>
      </div>
    </div>
</ng-template>