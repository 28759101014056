import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { WProofreader } from '@webspellchecker/wproofreader-ckeditor5';
import { SelectionService } from 'src/Service/Selection/selection.service';
// import { BlockQuote, Bold, ClassicEditor, Essentials, Font, FontBackgroundColor, FontColor, FontFamily, FontSize, Heading, HorizontalLine, ImageUpload, Indent, IndentBlock, Italic, Link, List, MediaEmbed, Paragraph, Table, Undo, UndoEditing } from 'ckeditor5';
import { Bold, ClassicEditor, Essentials, Font, Heading, Indent, IndentBlock, Italic, Link, List, MediaEmbed, Mention, Paragraph, Table, Undo, FontSize, FontFamily, Style, FontBackgroundColor, FontColor, BlockQuoteCommand, BlockQuote, Image, HorizontalLine, UndoEditing, ImageUpload } from 'ckeditor5';
import { NzButtonGroupSize } from 'ng-zorro-antd/button';
import { NZ_MODAL_DATA, NzModalService } from 'ng-zorro-antd/modal';
import { SelectionChoiceParameterResponse, SelectionChoiceResult, SelectionInformationApprove } from 'src/Models/Selection/selectionClasses';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { LeadOpportunityAttachmentsUploadFilesComponent } from 'src/app/lead-opportunities/Components/Modal/lead-opportunity-attachments-upload-files/lead-opportunity-attachments-upload-files.component';
import { LeadOpportunityAttachmentsUploadFilesViewAllComponent } from 'src/app/lead-opportunities/Components/Modal/lead-opportunity-attachments-upload-files-view-all/lead-opportunity-attachments-upload-files-view-all.component';
import { NzImageService } from 'ng-zorro-antd/image';
import { AttachmentAnnotationComponent } from 'src/app/lead-opportunities/Components/attachment-annotation/attachment-annotation.component';
import { LeadOpportunitiesService } from 'src/Service/lead-opportunities.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { environment } from 'src/environments/environment';
import { NzStatus } from 'ng-zorro-antd/core/types';
import { CostCodeCategoryResponse } from 'src/Models/InternalUser/applicationUserResponse';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { LeadProposalsPreDefinedCodes, SelectionStatusCode } from 'src/app/shared/component/Models/LookUpStandardFilterCodes';
import { LookupNameSetupResponse } from 'src/Models/LeadTags';
import { LeadProposalsService } from 'src/Service/LeadProp/lead-proposals.service';
import { CostItemParameterResponse, CostItemResponse, CreateCostItemRequest, CreateCostItemRequestchoice } from 'src/Models/LeadProposal/LeadProposalModels';
import { AttachmentService } from 'src/Service/Attachment/attachment.service';
import { CostItemService } from 'src/Service/Cost_Item/cost-item.service';
import { saveAttachments } from 'src/app/helpers/Attachment_Save';
import { AttachmentParameterResponse, AttachmentResponse, CustomUploadFile } from 'src/Models/Attachment_Files_Class/AttachmentFilesClass';
import { SubVendorResponse } from 'src/Models/SubVendor/SubvendorResponse.model';
import { SubVendersService } from 'src/Service/SubVendorsServices/sub-venders.service';
import { TimeClockService } from 'src/Service/TimeClock/time-clock.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Editor, EditorConfig, initializeEditor } from 'src/app/helpers/ckeditor-config';
import { SelectionChoiceServService } from 'src/Service/Selection/selection-choice-serv.service';
import { AuditDetailsResponse, FilterFooterAudit } from 'src/Models/Footer/footer.model';
import { FooterService } from 'src/Service/Footer/footer.service';
interface Person {
  key: string;
  name: string;
  age: number;
  address: string;
}
@Component({
  selector: 'app-addchoice-selection-choice',
  templateUrl: './addchoice-selection-choice.component.html',
  styleUrls: ['./addchoice-selection-choice.component.css']
})
export class AddchoiceSelectionChoiceComponent implements OnInit {

  @Output() cancel = new EventEmitter<void>();
  @Output() ChoiceSelectionResponse = new EventEmitter<SelectionChoiceParameterResponse>();
  @Output() onSaveComplete = new EventEmitter<SelectionChoiceParameterResponse>();
  @Output() ChoiceSelectionAttachmentResponse = new EventEmitter<AttachmentResponse>();
  @Output() ChoiceSelectionCostItemResponse = new EventEmitter<CostItemParameterResponse>();
  initializationComplete = new EventEmitter<boolean>();
  @ViewChild('carouselContainer', { static: false }) carouselContainer!: ElementRef<HTMLDivElement>;
  @Input() newSeletChoiceId: number;
  @Input() _newSeletChoiceId: number;
  @Input() CategorySendChoice: string;
  @Input() GetStatusId: number;
  @Input() LocationSendChoice: string
  @Input() SelectTitleSendChoice: string
  @Input() SelectIdAddChoice: number
  @Input() SelectIdByChoice: number;



  selectedValue = null;
  inputValue?: string;
  currentIndex = 0;
  isSave: boolean = false;
  isLoading: boolean = false;
  showSiriDiv: boolean = true;
  showPriceDiv: boolean = false;
  selectionChoicevisible = true;
  isSubmitted: boolean = false;
  isSaveAndNew: boolean = false;
  LineItemsBUtton: boolean = false;
  FlateFeeBUtton: boolean = false;
  isInputDisabled: boolean = false;
  SubsVendorsForm: boolean = false;
  isSetPriceLater: boolean = false;
  isSaveAndClose: boolean = false;
  showNavButtons: boolean = false;
  rowAddItemGrid: boolean = false;
  isDeleteLoading: boolean = false;
  DisabledNextActivity: boolean = false;


  items: any[] = [];
  costItemRequest: any = {};
  CostItemFormFieldsValue: any = {};
  costItemGridFormFieldsvalues: any = {};
  choiceSelectionFormFieldValues: any = {};
  statusCode: { code: string; description: string; id?: number } | null = null;
  selectedFiless: CustomUploadFile[] = [];
  JobGroupNodes: NzTreeNodeOptions[] = [];
  leadOptions: CostCodeCategoryResponse[] = [];
  markupOption: LookupNameSetupResponse[] = [];
  changedFields: Set<string> = new Set<string>();
  validationStates: { [key: string]: NzStatus } = {};
  validationMessages: { [key: string]: string } = {};
  public mySelection: string[] = [];
  touchedFields: { [key: string]: boolean } = {};
  touchedFieldsItem: { [key: string]: boolean } = {};
  validationMessagesItem: { [key: string]: string } = {};
  validationStatesItem: { [key: string]: NzStatus } = {};
  vendersNodes: NzTreeNodeOptions[] = [];
  vendorList: SubVendorResponse[] = [];
  vendorHoldDateByChoice = new Date();
  selectedFilesAttachment: CustomUploadFile[] = [];
  TestAttachments: AttachmentParameterResponse[];

  IMG_BASE: string = environment.IMG_BUCKET_URL;
  SelectionStatusCode = SelectionStatusCode;

  TestAttachment: AttachmentResponse;
  // public Editor = ClassicEditor;
  public Editor = Editor;
  public config = EditorConfig;
  totalOwnerPrice: number = 0;
  totalBuilderCost: number = 0;
  estimatedProfit: number = 0;
  builderCostFeild: any = '0.00';
  ownerPriceFeild: any = '0.00';
  priceInformationradioValue = 'B';
  size: NzButtonGroupSize = 'small';
  currentSelection: string = 'lineItems';
  FileSetName: string[];
  titlebySelection: string;
  categoryBySelection: string;
  locationBySelection: string;
  choiceGetbyIdSelectionCategory: string;
  choiceGetbyIdSelectionTitle: string;
  choiceGetbyIdSelectionLocation: string;
  choiceSelectionResID: number;
  _PassStatus_Selection: string;
  choiceSelectionResGlobalId: string;
  choiceSelectionRes: SelectionChoiceParameterResponse;
  attachID: AttachmentResponse;
  choiceResponses: SelectionChoiceParameterResponse;
  imageSource: string;
  HoldId: number;
  footerResponse: AuditDetailsResponse;

  public Approve: boolean = false;
  isFlatFeeConfirmed: boolean = false;
  status: number;
  statusColor: {
    color: string; // Text color
    backgroundColor: string; // Background color
  };

  constructor(
    private modal: NzModalService,
    private cd: ChangeDetectorRef,
    private nzImageService: NzImageService,
    private toastService: NzMessageService,
    private selectionService: SelectionService,
    private LookupFilterService: LookupFilterService,
    private leadProposalsService: LeadProposalsService,
    private _CostItemService: CostItemService,
    private _AttachmentService: AttachmentService,
    public toastsService: NzMessageService,
    private subVendorsService: SubVendersService,
    private _SelectionChoiceServe: SelectionChoiceServService,
    private cdr: ChangeDetectorRef,
    private FooterService: FooterService,
    private fb: FormBuilder
  ) { }

  ngAfterViewInit(): void {
    initializeEditor(this.Editor, this.config);
  }

  ngOnInit(): void {
    console.log('checking id', this._newSeletChoiceId)
    this.priceInformationradioButtons(this.priceInformationradioValue);
    this.choiceSelectionFormGroupFields();
    this.onGetCostType();
    this.getLeadData();
    this.iniliazeFormApproveForm();
    this.subVendorsGetAll();
    if (this.newSeletChoiceId) {
      this.selectionService.SelectionGetbyId(this.newSeletChoiceId)?.subscribe((res) => {
        console.log("API response:", res?.result);
        const resData = res?.result;
        this.titlebySelection = resData?.Title
        this.categoryBySelection = resData?.CategorySetupName
        this.locationBySelection = resData?.LocationSetupName
      });
    }

    //markup
    this.LookupFilterService.getLookUpValuesByFormNameId(37).subscribe(
      (res) => {
        let markupValues = res.result.filter((x) => x.code === this.preDefinedDataCodes.markup.code);
        this.markupOption = markupValues;
      });

    if (this._newSeletChoiceId > 0) {
      this.fetchActivityResponse(this._newSeletChoiceId);
    }
  }

  statussetupColorMap = {
    [SelectionStatusCode.PendingNoChoice.code]: { backgroundColor: '#fbe9b1', textColor: '#653200' },
    [SelectionStatusCode.ExpiredAvailable.code]: { backgroundColor: '#fbe9b1', textColor: '#653200' },
    [SelectionStatusCode.UnReleased.code]: { backgroundColor: '#c7d0d9', textColor: '#202227' },
    [SelectionStatusCode.PriceTBD.code]: { backgroundColor: '#c7d0d9', textColor: '#202227' },
    [SelectionStatusCode.SubVendorPrice.code]: { backgroundColor: '#c7d0d9', textColor: '#202227' },
    [SelectionStatusCode.ExpiredNoChoices.code]: { backgroundColor: '#fbe9b1', textColor: '#653200' },
  };
  preDefinedDataCodes = {
    costType: LeadProposalsPreDefinedCodes.CostType,
    markup: LeadProposalsPreDefinedCodes.Markup,
  };

  subVendorsGetAll() {
    this.subVendorsService.getData().subscribe(res => {
      this.vendorList = res.result.filter(data => data.automaticallyPermitAccessToNewJobs === true);
      this.vendersNodes = this.vendorList.map((data) => ({
        title: data.companyName,
        value: data.id.toString(),
        key: data.id.toString(),
        isLeaf: true,
      }));
    });
  }


  openWebsite() {
    let url = this.choiceSelectionFormFieldValues.productLink;
    if (url) {
      if (!url.startsWith('http://') && !url.startsWith('https://')) {
        url = 'http://' + url;
      }
      console.log('Opening URL:', url); // For debugging purposes
      window.open(url, '_blank');
    } else {
      this.toastService.error('Please enter a URL!');
    }
  }

  priceInformationradioButtons(value: any) {

    if (value === 'A') {
      this.modal.confirm({
        nzTitle: 'Switch to Flat Fee?',
        nzContent: 'All line items will be removed permanently upon Save.',
        nzOkText: 'Use Flat Fee',
        nzCancelText: 'Cancel',
        nzIconType: '',
        nzClassName: 'ant-modal-confirm-custom ,ant-modal-body',
        nzStyle: { top: '150px' },
        nzOnOk: () => {
          this.FlateFeeBUtton = true;
          this.isFlatFeeConfirmed = true;
        },
        nzOnCancel: () => {
          this.isFlatFeeConfirmed = false;
          this.LineItemsBUtton = true;
          this.priceInformationradioValue = 'B';
        }
      });
    } else if (value === 'B') {
      this.isFlatFeeConfirmed = false;
      this.priceInformationradioValue = 'B';
    }
    else if (value === 'C') {
      this.isFlatFeeConfirmed = true;
      this.priceInformationradioValue = 'C';
    }
    this.initializationComplete.emit(false);

  }

  choiceSelectionFormGroupFields() {
    this.choiceSelectionFormFieldValues = {
      globalId: "00000000-0000-0000-0000-000000000000",
      selectionInformationId: this.newSeletChoiceId,
      title: "",
      productLink: "",
      isInCludeInBudget: false,
      subVendorId: 0,
      installerId: 789,
      isShowLineItemToOwner: true,
      description: "",
      attachmentId: 0,
      costItemId: 0,
      statusId: 0,
      imageUrl: "",
      isApprove: true,
      isDecline: false,
      isRequestFromSubVendor: false,
      isSetPriceLater: false,
    };
    this.choiceSelectionFormFieldValues['statusId'] = this.choiceSelectionRes?.StatusId;  //
  }


  fetchActivityResponseChoice(): void {
    this.selectionService.getChoiceBy(this._newSeletChoiceId)?.subscribe((res) => {
      console.log('this.newSeletChoiceId.newSeletChoiceId', res?.result);
    });
  }

  onFilesUploaded(files: CustomUploadFile[]): void {
    this.selectedFilesAttachment = files
  }

  private fetchActivityResponse(_IdChoiceFetch: number): void {
    this.selectionService.getChoiceSelectionBy(_IdChoiceFetch)?.subscribe((res) => {
      this.choiceGetbyIdSelectionTitle = res?.result?.SelectionTitle
      this.choiceGetbyIdSelectionCategory = res?.result?.CatagorySetupName
      this.choiceGetbyIdSelectionLocation = res?.result?.LocationSetupName
      if (res && res.result) {
        console.log("API response:", res);
        this.HoldId = res.result.Id;
        console.log('show HoldId', this.HoldId);
        this.choiceResponses = res.result as SelectionChoiceParameterResponse;
        this.NewpacthChoice(this.choiceResponses);
        this.choiceCostItemGetById(this.choiceResponses?.CostItemId);
        this._createAndModifiedByData(this.HoldId)
        this.initializationComplete.emit(false);

        // this.fetchAttachments();
        this.choiceSelectionFormFieldValues.patchValue(this.choiceSelectionFormFieldValues.value);
      } else {
        console.error('Invalid response from API:', res);
      }
    });
  }
  choiceCostItemGetById(_Id: number) {
    if (this._newSeletChoiceId) {
      this._CostItemService.getDataIdCostItem(_Id).subscribe(
        (res) => {
          if (res.result) {
            this.patchCostItemFormValues(res.result);
            console.log('show all patch value ofisSetPriceLater ', res.result);

          }
        },
      )
    }
  }
  patchCostItemFormValues(costItemResponse: CostItemResponse) {
    if (!costItemResponse) {
      console.error('No selection response data available for patching.');
      return;
    }
    if (costItemResponse?.isFlatFee === true) {
      this.priceInformationradioValue = 'A'
      this.isFlatFeeConfirmed = true;
    } else {
      this.priceInformationradioValue = 'B'
      this.isFlatFeeConfirmed = false;
    }
    this.builderCostFeild = costItemResponse?.builderCost
    this.ownerPriceFeild = costItemResponse?.ownerPrice
    this.isSetPriceLater = costItemResponse?.isSetPriceLater
  }


  ConfirmDelete(): void {
    if (this.isDeleteLoading) {
      return;
    }
    this.modal.confirm({
      nzTitle: `Delete Selection?`,
      nzContent: 'Are you sure you want to permanently delete this Selection?',
      nzOkText: `Delete Selection `,
      nzOkDanger: true,
      nzCancelText: 'Cancel',
      nzOnOk: () => {
        this.isDeleteLoading = true;
        return this.deleteChoice(this.choiceResponses?.GlobalId)
          .then(() => {
            this.selectionChoiceModelCancel();
            // this.fetchActivityResponseChoice(this._newSeletChoiceId)
            this.fetchActivityResponse(this._newSeletChoiceId);
          })
          .catch(() => {

          });
      },
      nzCentered: true,
      nzOkLoading: this.isDeleteLoading,
      nzBodyStyle: { 'border-radius': '50px', 'height': 'auto', 'padding-top': '15' },
      nzCloseIcon: '',
      nzIconType: ''
    });
  }

  deleteChoice(globalId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!globalId) {
        this.toastsService.error('No record selected for deletion.');
        this.isDeleteLoading = false;
        reject();
        return;
      }
      // Ensure you are passing the correct GlobalId
      this.selectionService.deleteSelectionChoice(globalId).subscribe(
        () => {
          this.isDeleteLoading = false;
          this.toastsService.success(`User Deleted Choice!`);
          this.onSaveComplete.emit(this.choiceResponses);
          this.cdr.detectChanges();
          this.selectionChoiceModelCancel();
          resolve();  // Resolve the promise after success
        },
        () => {
          this.isDeleteLoading = false;
          this.toastsService.error('An error occurred while deleting the record. Please try again.');
          reject();  // Reject the promise on error
        }
      );
    });
  }




  async addChoiceSaveMechanism(actionType: 'save' | 'saveAndClose' | 'saveAndNew' = 'save'): Promise<void> {
    try {
      if (this.isSave || this.isSaveAndClose || this.isSaveAndNew) {
        throw new Error('Already processing');
      }

      switch (actionType) {
        case 'saveAndNew':
          this.isSaveAndNew = true;
          break;
        case 'saveAndClose':
          this.isSaveAndClose = true;
          break;
        default:
          this.isSave = true;
      }
      this.DisabledNextActivity = true;
      this.isSubmitted = true;
      this.checkErrors();

      if (this.rowAddItemGrid) {
        this.checkErrorsItem();
      }
      if (Object.values(this.validationStates).includes('error')) {
        setTimeout(() => {
          const errorElements = document.querySelectorAll('.error-message');
          if (errorElements.length > 0) {
            const lastErrorElement = errorElements[errorElements.length - 1];
            lastErrorElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        }, 100);
        this.resetFlags();
        this.DisabledNextActivity = false;
        return Promise.reject('Validation errors present');
      }

      if (this.choiceSelectionFormFieldValues.isSetPriceLater) {
        this.choiceSelectionFormFieldValues.statusId = 434;
      }
      else if (this.choiceSelectionFormFieldValues?.isRequestFromSubVendor) {
        this.choiceSelectionFormFieldValues.statusId = 436;
      }
      else if (this.Approvehide?.selectionChoiceParameterStatus) {
        this.choiceSelectionFormFieldValues.statusId = 441;
      }
      else {
        this.choiceSelectionFormFieldValues.statusId = 431;
      }

      let editorContent = this.choiceSelectionFormFieldValues.description;
      editorContent = editorContent.replace(/<br\s*\/?>/gi, '\n').replace(/&nbsp;/g, ' ');
      this.choiceSelectionFormFieldValues.description = editorContent.replace(/<[^>]+>/g, '');

      const res = await
        this.selectionService.PostChoice(this.choiceSelectionFormFieldValues)
          .toPromise();
      this.choiceSelectionRes = res.result;
      this.toastService.success('Selection Choice Saved');
      // this.choiceSelectionFormFieldValues.statusId = res.result?.StatusId ?? this.choiceSelectionFormFieldValues.statusId ?? 0;
      this.choiceSelectionFormFieldValues.statusId = res.result?.StatusId || this.choiceSelectionFormFieldValues.statusId;
      this.setStatusInfo(this.choiceSelectionRes?.StatusId);
      this.vendorHoldDateByChoice = this.choiceSelectionRes?.RequestFromSubVendorOn;
      this.choiceSelectionResID = res.result.Id;
      this.choiceSelectionResGlobalId = res.result.GlobalId;
      this._PassStatus_Selection = res.result.SelectionStatus;
      this.selectionService.updateSelectionStatus(this._PassStatus_Selection);
      this.choiceSelectionFormFieldValues.globalId = res.result.GlobalId;
      if (res) {
        this._createAndModifiedByData(res?.result?.Id);
      }
      this.cdr.detectChanges();

      const costItemRequest: CostItemResponse = {
        globalId: this.CostItemFormFieldsValue.globalId,
        headerId: res.result?.Id,
        companyParameterId: 1,
        formNameId: 50023,
        isFlatFee: this.FlateFeeBUtton,
        isLineItems: this.LineItemsBUtton,
        builderCost: this.builderCostFeild,
        ownerPrice: this.ownerPriceFeild,
        isSetPriceLater: this.isSetPriceLater,
        costItemParameters: this.items,
      };

      const costItemResponse = await
        this._CostItemService.postCostItem(costItemRequest)
          .toPromise();
      console.log('Cost Item Response:', costItemResponse);

      if (this.selectedFilesAttachment?.length > 0) {
        try {
          const attachmentResponseId = await saveAttachments(this.selectedFilesAttachment, this._AttachmentService, this.choiceSelectionResID, 50023);
          if (attachmentResponseId > 0) this.fetchActivityResponse(this.choiceSelectionResID);
        } catch (error) {
          console.error('Error saving attachments:', error);
          throw error;
        }
      } else {
        this.fetchActivityResponse(this.choiceSelectionResID);
        this.choiceSelectionFormFieldValues.attachmentId = 0;
      }

      const newCard = {
        id: this.choiceSelectionResID,
        globalId: this.choiceSelectionResGlobalId,
        title: this.choiceSelectionFormFieldValues?.title,
        description: this.choiceSelectionFormFieldValues.description,
        ownerPrice: this.ownerPriceFeild,
        attachmentId: this.attachID,

      };

      this.selectionService.addCard(newCard);
      this._SelectionChoiceServe.fetchChoiceSelectionRervResponse(this.newSeletChoiceId || this._newSeletChoiceId);
      this.selectionService.updateChoiceSelectionData(res.result);
      this.ChoiceSelectionResponse.emit(this.choiceSelectionRes);
      this.DisabledNextActivity = false;
      this.selectionChoicevisible = true;
      this.isSave = false;
    }
    catch (error) {
      console.error('Error saving data:', error);
      this.toastService.error(error instanceof Error ? error.message : 'An error has occurred. Please try again.');
      this.DisabledNextActivity = false;
      this.resetFlags();
      this.isSubmitted = false;
    }
  }

  private getStatusCode(status: string) {
    switch (status) {
      case "UnReleased":
        return SelectionStatusCode.UnReleased;
      case "PriceTBD":
        return SelectionStatusCode.PriceTBD;
      case "SubVendorPrice":
        return SelectionStatusCode.SubVendorPrice;
      default:
        return null; // Handle other statuses as needed
    }
  }

  _createAndModifiedByData(id: number) {
    const payload: FilterFooterAudit = {
      id: id,
      formCode: 'PMSCV',
    }
    this.FooterService.createdAndModifyUser(payload).subscribe(res => {
      this.footerResponse = res.result;
    })
  }

  statusText: string = '';
  statusClass: string = '';

  setStatusInfo(statusId: number) {
    this.status = statusId;

    switch (this.status) {
      case 441: // Pending
        this.Approvehide.selectionChoiceParameterStatus = this.Approvehide.selectionChoiceParameterStatus;
        this.statusClass = 'Selected'; // Adjust based on your logic
        break;
      case 434: // Pending: Overdue
        this.choiceSelectionRes.StatusName = this.choiceSelectionRes.StatusName;
        this.statusClass = 'Pending-TBD';
        break;
      case 431: // Unreleased
        this.choiceSelectionRes.StatusName = this.choiceSelectionRes.StatusName;
        this.statusClass = 'Unreleased';
        break;
      // case 437: // Pending: Available
      // this.choiceSelectionRes.StatusName = this.choiceSelectionRes.StatusName;
      //   this.statusClass = 'Pending-Available';
      //   break;
      default:
        this.choiceSelectionRes.StatusName = '';
        this.statusClass = ''; // Reset class
        break;
    }
  }

  saveAndCloseAddChoice(): Promise<void> {
    return this.addChoiceSaveMechanism('saveAndClose')
      .then(() => {
        this.resetForm();
        this.selectionChoiceModelCancel();
        this.resetFlags();
      })
      .catch((error) => {
        if (error === 'Validation errors present') {
          console.warn('Validation errors detected. Form will not close.');
        } else {
          console.error('Error during save and close:', error);
        }
        this.resetFlags();
      });
  }


  saveAndNewAddChoice() {
    this.addChoiceSaveMechanism('saveAndNew').then(() => {
      this.choiceSelectionRes = null;
      this.resetForm();
      this.resetAttachments();
    }).catch((error) => {
      console.error('Error during save and new:', error);
    });
  }

  resetFlags() {
    this.isSave = false;
    this.isSaveAndClose = false;
    this.isSaveAndNew = false;
    this.cd.detectChanges();
  }

  resetForm(): void {
    const savedStatusSetupId = this.choiceSelectionFormFieldValues.statusId;

    this.choiceSelectionFormFieldValues = {
      subVendorId: null,
      statusId: savedStatusSetupId ?? null,
      globalId: '',
    };
    this.choiceSelectionFormFieldValues = {}
    this.priceInformationradioValue = 'B';
    this.isSubmitted = false;
    this.validationStates = {};
    this.validationMessages = {};
    this.touchedFields = {};
    this.cdr.detectChanges();
    this.checkErrors = null;
  }
  resetAttachments() {
    this.TestAttachment = null;
    this.selectedFilesAttachment = [];
  }


  NewpacthChoice(choiceResponses: SelectionChoiceParameterResponse) {
    if (!choiceResponses) {
      console.error('No selection response data available for patching.');
      return;
    }
    const DataSet = choiceResponses;
    this.choiceSelectionFormFieldValues = {
      ...this.choiceSelectionFormFieldValues,
      selectionInformationId: DataSet?.SelectionInformationId,
      globalId: DataSet?.GlobalId || '00000000-0000-0000-0000-000000000000',
      title: DataSet?.Title,
      productLink: DataSet?.ProductLink,
      isInCludeInBudget: DataSet?.IsInCludeInBudget,
      subVendorId: DataSet?.SubVendorId,
      installerId: DataSet?.InstallerId,
      isShowLineItemToOwner: DataSet?.IsShowLineItemToOwner,
      description: DataSet?.Description,
      attachmentId: DataSet?.AttachmentId,
      costItemId: DataSet?.CostItemId,
      isFlatFee: DataSet?.IsFlatFee,
      isLineItem: DataSet?.IsLineItem,
      builderCost: DataSet?.BuilderCost,
      ownerPrice: DataSet?.OwnerPrice,
      isSetPriceLater: DataSet?.IsSetPriceLater,
      statusId: DataSet?.StatusId,
      imageUrl: DataSet?.ImageUrl,
      isApprove: DataSet?.IsApprove,
      isDecline: DataSet?.IsDecline
    };
    this.fetchAttachments(DataSet?.AttachmentId);
  }

  saveFileIdToDatabase() {
  }

  // fetchAttachments(AttachmentId: number) {
  //   if (AttachmentId) {
  //     this._AttachmentService.getLeadByAttachmentId(AttachmentId).subscribe(
  //       (res) => {
  //         this.TestAttachment = res.result
  //         this.TestAttachments = res.result?.attachmentParameters
  //         const setFilePath = this.IMG_BASE + res.result?.attachmentParameters?.map(x => x.filePath)
  //         this.FileSetName = setFilePath
  //       },
  //     );
  //   } else {
  //     this.TestAttachment = undefined;
  //   }
  // }


  fetchAttachments(AttachmentId: number) {
    if (AttachmentId) {
      this._AttachmentService.getLeadByAttachmentId(AttachmentId).subscribe(
        (res) => {
          this.TestAttachment = res.result;
          this.TestAttachments = res.result?.attachmentParameters;

          // Ensure attachmentParameters exist and handle comma-separated file paths
          const filePaths = this.TestAttachments?.map(param => param.filePath.split(',').map(path => path.trim()));
          this.FileSetName = filePaths?.flat().map(filePath => this.IMG_BASE + filePath);
          this.imageSource = this.FileSetName.join(',');
          this.selectedFilesAttachment = this.FileSetName.map((filePath, index) => ({
            thumbUrl: filePath,
            uid: `${index}`,
            name: `File_${index}`,
            globalId: `global_${index}`,
            id: index,
            addedBy: 123, // Example user ID, replace with actual value if available
            createdOn: new Date(),
            modifiedOn: new Date(),
            loading: false,
            error: false
          }));
        },
        (error) => {
          console.error('Error fetching attachments:', error);
          this.TestAttachment = undefined;
          this.selectedFilesAttachment = [];
        }
      );
    } else {
      this.TestAttachment = undefined;
      this.selectedFilesAttachment = [];
    }
  }

  selectionChoiceModelCancel(): void {
    this.cancel.emit();
  }

  onVendorSelection(selectedVendorId: any): void {
    if (selectedVendorId) {
      // Vendor selected, show the appropriate modal
      this.showVendorSelectedModal();
    } else {
      this.showVandorsConfirm();
    }
  }

  handleVendorAction(): void {
    if (this.choiceSelectionFormFieldValues.subVendorId) {
      // No vendor selected, show the initial modal
      this.showVendorSelectedModal();
    } else {
      this.showVandorsConfirm();
    }
  }

  showVandorsConfirm(): void {
    this.modal.info({
      nzTitle: '',
      nzContent: '<p>Please assign a Sub/Vendor to this Choice before requesting a price.</p>',
      nzOnOk: () => console.log('Info OK'),
      nzClassName: 'ant-modal-confirm-custom',
      nzBodyStyle: { 'border-radius': '30px', 'height': 'auto', 'padding-top': '15px' },
      nzStyle: { top: '200px' },
      nzCloseIcon: '',
      nzIconType: '',
    });
  }

  // Show modal when a vendor is selected
  showVendorSelectedModal(): void {
    this.modal.confirm({
      nzTitle: 'Switch to Request From Sub/Vendor',
      nzContent: 'All line items will be removed permanently upon Save.',
      nzOkText: 'Request From Sub/Vendors',
      nzOnOk: () => {
        console.log('Vendor selected confirmed');
        this.choiceSelectionFormFieldValues.isRequestFromSubVendor = true; // Disable the input field
      },
      nzCancelText: 'Cancel',
      nzOnCancel: () => console.log('Cancel'),
      nzClassName: 'ant-modal-confirm-custom ,ant-modal-body',
      nzBodyStyle: { 'border-radius': '30px', 'height': 'auto', 'padding-top': '15px' },
      nzStyle: { top: '200px' },
      nzCloseIcon: '',
      nzIconType: '',
    });
  }

  // Function to show the delete confirmation modal
  showDeleteConfirm(): void {
    this.modal.confirm({
      nzTitle: 'Switch to Flat Fee?',
      nzContent: 'All line items will be removed permanently upon Save.',
      nzOkText: 'Use Flat Fee',
      nzOkType: 'primary',
      nzOnOk: () => this.handleFlatFeeSelection(),
      nzCancelText: 'Cancel',
      nzOnCancel: () => console.log('Cancel'),
      nzClassName: 'ant-modal-confirm-custom',
      nzBodyStyle: { 'border-radius': '30px', 'height': 'auto', 'padding-top': '15px' },
      nzStyle: { top: '250px' },
      nzCloseIcon: '',
      nzIconType: '',
      nzClosable: false,
      nzMaskClosable: false
    });
  }

  resetVendorSelectedModal(): void {
    this.modal.confirm({
      nzTitle: '',
      nzContent: 'Are you sure you want to reset the Builder Price request?',
      nzOkText: 'Reset',
      nzOnOk: () => {
        console.log('OKay reset');
        // Trigger the API call
        this.choiceSelectionFormFieldValues.isRequestFromSubVendor = false;
        this.selectionService.PostChoice(this.choiceSelectionFormFieldValues).subscribe(res => {
          this.choiceSelectionRes = res.result;
          this.cdr.detectChanges();
          this.priceInformationradioValue = 'A';
          if (this.choiceSelectionFormFieldValues.isRequestFromSubVendor === false) {
            this.choiceResponses.IsRequestFromSubVendor = false;
          }
        });
      },
      nzCancelText: 'Cancel',
      nzOnCancel: () => console.log('Cancel'),
      nzClassName: 'ant-modal-confirm ,ant-modal-body',
      nzBodyStyle: { 'border-radius': '30px', 'height': 'auto', 'padding-top': '15px' },
      nzStyle: { top: '200px' },
      nzCloseIcon: '',
      nzIconType: '',
    });
  }

  handleFlatFeeSelection(): void {
    this.showSiriDiv = false;
    this.showPriceDiv = true;
    this.currentSelection = 'flatFee';
  }
  handleLineItemsSelection(): void {
    this.showSiriDiv = true;
    this.showPriceDiv = false;
    this.currentSelection = 'lineItems';
  }
  uploadFiles(): void {
    const modalRef = this.modal.create({
      nzContent: LeadOpportunityAttachmentsUploadFilesComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    modalRef.componentInstance.UploadFile.subscribe((data: CustomUploadFile[]) => {
      data.filter(file => this.isImageFile(file));
      this.selectedFiless = [...data, ...this.selectedFiless];
      this.onResize();
    });
  }

  isImageFile(file: CustomUploadFile): boolean {
    const fileName = file.name.toLowerCase();
    const fileType = file.type;

    return (
      fileType === 'image/png' ||
      fileType === 'image/jpeg' ||
      fileName.endsWith('.png') ||
      fileName.endsWith('.jpeg')
    );
  }

  onResize() {
    this.updateNavButtonsVisibility();
  }

  private updateNavButtonsVisibility() {
    requestAnimationFrame(() => {
      const container = this.carouselContainer.nativeElement;
      if (this.selectedFiless.length > 0) {
        this.showNavButtons = container.scrollWidth > container.clientWidth;
      } else {
        this.showNavButtons = false;
      }
      this.cd.detectChanges();
    });
  }

  viewAll(): void {
    const modalRef = this.modal.create({
      nzContent: LeadOpportunityAttachmentsUploadFilesViewAllComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.selectedFilessResponse = this.selectedFiless;
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    modalRef.componentInstance.removeFile.subscribe((file: CustomUploadFile) => {
      this.removeFile(file);
    });
    modalRef.componentInstance.Test.subscribe((data: CustomUploadFile[]) => {
      this.selectedFiless = [...data, ...this.selectedFiless];
      this.onResize();
    });
  }

  removeFile(file: CustomUploadFile): void {
    this.selectedFiless = this.selectedFiless.filter(item => item.uid !== file.uid);
  }

  previous() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
      this.scrollToCurrentIndex();
    }
  }

  next() {
    const container = this.carouselContainer.nativeElement;
    const maxIndex = this.selectedFiless.length - Math.floor(container.clientWidth / 130);
    if (this.currentIndex < maxIndex) {
      this.currentIndex++;
      this.scrollToCurrentIndex();
    }
  }

  scrollToCurrentIndex() {
    const container = this.carouselContainer.nativeElement;
    const scrollAmount = this.currentIndex * 130; // 120px (width) + 10px (margin)
    container.scrollTo({ left: scrollAmount, behavior: 'smooth' });
  }

  getFileIcon(file: CustomUploadFile): string | null {
    const fileType = file.type;
    if (fileType.includes('pdf')) {
      return 'assets/Attachment/documentPDF.svg';
    } else if (fileType.includes('excel') || fileType.includes('spreadsheet')) {
      return 'assets/Attachment/documentXLS.svg';
    } else if (fileType.includes('word')) {
      return 'assets/Attachment/documentDOC.svg';
    } else if (fileType.includes('zip')) {
      return 'assets/Attachment/documentZIP.svg';
    } else if (fileType.includes('text') || fileType.includes('json')) {
      return 'assets/Attachment/documentGeneric.svg';
    } else if (fileType.includes('ppt') || fileType.includes('presentation')) {
      return 'assets/Attachment/documentPPT.svg';
    } else if (fileType.includes('video')) {
      return null;
    } else if (fileType.includes('image')) {
      return null;
    } else {
      return null;
    }
  }

  onClick(): void {
    let filteredImages = this.selectedFiless.filter(f =>
      f.name.includes('png') || f.name.includes('jpeg')
    );
    const images = filteredImages.map(file => ({
      src: file.thumbUrl,
      alt: file.name
    }));
    this.nzImageService.preview(images, {
      nzZoom: 1.0,
      nzRotate: 0
    });
  }

  playVideo(file: CustomUploadFile): void {
    const videoElement: HTMLVideoElement = this.carouselContainer.nativeElement.querySelector(`#video-${file.id}`);
    if (videoElement) {
      videoElement.style.display = 'block'; // Show the video player
      videoElement.play();
    }
  }

  uploadFilesUpdate(fileq: CustomUploadFile): void {
    const modalRef = this.modal.create({
      nzContent: AttachmentAnnotationComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.DrawerImge = fileq;
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    modalRef.componentInstance.imageDataRes.subscribe((data: any) => {
      const newImageUrl = data;
      let test1234 = this.selectedFiless.forEach(activity => {
        activity.thumbUrl = newImageUrl;
      });
      console.log(test1234);
    });
  }
  // grid working

  listOfData: Person[] = [
    {
      key: '1',
      name: 'John Brown',
      age: 32,
      address: 'New York No. 1 Lake Park'
    },
  ];

  getLeadData() {
    this.leadProposalsService.getAllLeadData().subscribe(data => {
      this.leadOptions = data.result;
    });
  }

  onGetCostType() {
    this.LookupFilterService.getLookUpValuesByFormNameId(37).subscribe(
      (res) => {
        let JobGroupValues = res.result.filter(
          (x) => x.code === this.preDefinedDataCodes.costType.code
        );
        let markupValues = res.result.filter((x) => x.code === this.preDefinedDataCodes.markup.code);
        this.markupOption = markupValues;
        // this.items.map(mk => mk.markup) = this.markupOption[0].id
        // console.log(this.items.markup,this.items.markup);
        this.JobGroupNodes = [
          {
            title: 'Check All',
            value: 'check_all',
            key: 'check_all',
            selectable: false,
            isLeaf: false,
            expanded: true,
            children: JobGroupValues.map((JobGroup) => ({
              title: JobGroup.name,
              key: JobGroup.id.toString(),
              value: JobGroup.id.toString(),
              isLeaf: true,
            }))
          }
        ];
      });
  }

  deleteRow(index: number): void {
    this.modal.confirm({
      nzTitle: `Delete Line Item?`,
      nzContent: `You have unsaved changes, do you want to save your changes before closing?`,
      nzOkText: `Delete`,
      nzOkDanger: true,
      nzCancelText: 'Cancel',
      nzOnOk: () => {
        return this.deleteItem(index)
      },
      nzCentered: true,
      nzBodyStyle: { 'border-radius': '50px', 'height': 'auto', 'padding-top': '15' },
      nzCloseIcon: '',
      nzIconType: ''
    });
  }

  deleteItem(index: number) {
    this.items.splice(index, 1);
  }

  addNewRow(): void {
    this.rowAddItemGrid = true;
    this.CostItemFormFieldsValue = {
      globalId: '00000000-0000-0000-0000-000000000000',
      title: '',
      description: '',
      internalNotes: '',
      quantity: 0,
      unit: 0,
      unitCost: 0,
      builderCost: 0,
      markupValue: 0,
      markupId: 0,
      ownerPrice: 0,
      margin: 0,
      profit: 0,
      costCodeId: 0,
      costTypeId: 0,
      markAsId: 0,
      costTypeItemParameters: []
    };
    console.log('this.CostItemFormFieldsValueewqeqwe', this.CostItemFormFieldsValue);
    this.items.push(this.CostItemFormFieldsValue);
  }

  updateBuilderCost(item: any): void {
    const unitCost = parseFloat(item.unitCost) || 0;
    const quantity = item.quantity || 1;
    const markupValue = parseFloat(item.markupValue) || 0;

    item.builderCost = unitCost * quantity;

    if (item.markupId === 40417) {
      item.ownerPrice = item.builderCost + (item.builderCost * (markupValue / 100));
    } else if (item.markupId === 40418 || item.markupId === 40419) {
      item.ownerPrice = item.builderCost + markupValue;
    } else {
      item.ownerPrice = item.builderCost;
    }

    item.margin = item.ownerPrice > 0
      ? ((item.ownerPrice - item.builderCost) / item.ownerPrice) * 100
      : 0;

    item.margin = parseFloat(item.margin.toFixed(2));

    item.profit = item.ownerPrice - item.builderCost;
    if (item.profit < 0) {
      item.profit = 0;
    }

    this.updateTotals();
  }
  updateTotals(): void {
    this.totalOwnerPrice = this.items.reduce((sum, item) => sum + item.ownerPrice, 0);
    this.totalBuilderCost = this.items.reduce((sum, item) => sum + item.builderCost, 0);
    this.estimatedProfit = this.items.reduce((sum, item) => sum + item.profit, 0);
  }

  readonly validationRules = {
    title: {
      required: 'Required',
      maxLength: { limit: 50, message: 'Title' }
    },
    isLineItems: {
      message: 'Line Items field must have at least 1 items',
    },
  };

  getFieldLabel(field: string): string {
    const labels: { [key: string]: string } = {
      title: 'Title',
      isLineItems: 'Line Items',
      isFlatFee: '',
    };
    return labels[field] || field;
  }

  getErrorTip(field: string): string {
    const rules = this.validationRules[field];
    const input = this.choiceSelectionFormFieldValues[field];
    const inputValue = typeof input === 'string' ? input.trim() : '';

    if (rules.required && !input) {
      return 'Required';
    }

    if (rules.maxLength && inputValue.length > rules.maxLength.limit) {
      const excessLength = inputValue.length - rules.maxLength.limit;
      const unit = excessLength === 1 ? 'character' : 'characters';
      return `${excessLength} ${unit} over.`;
    }
    if (field === 'isLineItems') {
      return rules?.message;
    }

    return '';
  }

  get errorFields(): { field: string; label: string; message: string }[] {
    return Object.keys(this.validationStates)
      .filter(field => this.validationStates[field] === 'error')
      .map(field => ({
        field,
        label: this.getFieldLabel(field),
        message: this.validationMessages[field]
      }));
  }

  checkErrors() {
    this.validationStates = {};
    this.validationMessages = {};

    for (let field in this.validationRules) {
      if (this.validationRules.hasOwnProperty(field)) {
        if (this.touchedFields[field] || this.isSubmitted) {
          const errorTip = this.getErrorTip(field);
          if (errorTip) {
            this.validationStates[field] = 'error';
            this.validationMessages[field] = errorTip;
          } else {
            this.validationStates[field] = null;
            this.validationMessages[field] = '';
          }
        }
      }
    }
    this.onErrorNull();
  }

  onErrorNull() {
    if (this.priceInformationradioValue === 'A' || this.priceInformationradioValue === 'C' || this.rowAddItemGrid === true) {
      delete this.validationStates['isLineItems'];
      delete this.validationMessages['isLineItems'];
    }
  }

  onFieldChange(field: string, value: any) {
    if (typeof value === 'string') {
      this.choiceSelectionFormFieldValues[field] = value.trim();
    } else {
      this.choiceSelectionFormFieldValues[field] = value;
    }
    this.touchedFields[field] = true;
    this.checkErrors();
    if (this.rowAddItemGrid === true) {
      this.checkErrorsItem();
    }
  }

  readonly validationRulesItem = {
    title: {
      maxLength: { limit: 150, message: 'Title' }
    },
    costCodeId: {
      required: 'Required',
      maxLength: { message: 'Cost Code' }
    },
  };

  getFieldLabelItem(field: string): string {
    const labels: { [key: string]: string } = {
      costCodeId: 'Cost Code',
      title: 'Title',
    };
    return labels[field] || field;
  }

  getErrorTipItem(field: string, index: number): string {
    const rules = this.validationRulesItem[field];
    const input = this.items[index][field];
    const inputValue = typeof input === 'string' ? input.trim() : '';

    if (rules.required && !input) {
      return 'Required';
    }

    if (rules.maxLength && inputValue.length > rules.maxLength.limit) {
      const excessLength = inputValue.length - rules.maxLength.limit;
      const unit = excessLength === 1 ? 'character' : 'characters';
      return `${excessLength} ${unit} over.`;
    }

    return '';
  }

  get errorFieldsItem(): { label: string; message: string }[] {
    return Object.keys(this.validationStatesItem)
      .filter(field => this.validationStatesItem[field] === 'error')
      .map(field => ({
        label: '',
        message: this.validationMessagesItem[field]
      }));
  }

  checkErrorsItem() {
    this.validationStatesItem = {};
    this.validationMessagesItem = {};

    this.items.forEach((item, index) => {
      for (let field in this.validationRulesItem) {
        if (this.validationRulesItem.hasOwnProperty(field)) {
          if (this.touchedFieldsItem[field + '_' + index] || this.isSubmitted) {
            const errorTip = this.getErrorTipItem(field, index);
            if (errorTip) {
              this.validationStatesItem[field + '_' + index] = 'error';
              this.validationMessagesItem[field + '_' + index] = errorTip; // Remove the index part

            }
          }
        }
      }
    });
  }


  // validationStatesItem: { [key: string]: string } = {};
  // validationMessagesItem: { [key: string]: string } = {};
  wordCountExceeded: { [key: number]: boolean } = {};
  // touchedFieldsItem: { [key: string]: boolean } = {};

  // Original onFieldChangeItem method with word count validation added
  onFieldChangeItem(field: string, value: any, index: number) {
    // Update the field value
    if (typeof value === 'string') {
      this.items[index][field] = value.trim();
    } else {
      this.items[index][field] = value;
    }

    // Mark the field as touched
    this.touchedFieldsItem[field + '_' + index] = true;

    // Check word count if the value is a string (for 'title' field)
    if (typeof value === 'string' && field === 'title') {
      const wordCount = this.countWords(value);

      // Set validation error if word limit is exceeded
      if (wordCount > 150) {
        this.wordCountExceeded[index] = true;
        this.validationStatesItem[field + '_' + index] = 'error';
        this.validationMessagesItem[field + '_' + index] = 'Word limit exceeded';
      } else {
        this.wordCountExceeded[index] = false;
        this.validationStatesItem[field + '_' + index] = '';
      }
    }

    // Call checkErrorsItem method to handle other validations
    this.checkErrorsItem();
  }

  // Word count helper method
  countWords(text: string): number {
    return text.trim().split(/\s+/).length;
  }

  quantityLengthExceeded: { [key: number]: boolean } = {};

  onQuantityChange(value: number, index: number) {
    // Convert the value to a string to check its length
    const quantityStr = value ? value.toString() : '';

    // Check the length of the quantity
    if (quantityStr.length > 14) {
      this.quantityLengthExceeded[index] = true; // Set the flag for length exceeded
    } else {
      this.quantityLengthExceeded[index] = false; // Reset the flag if within limit
    }
  }


  unitLengthExceeded: { [key: number]: boolean } = {}; // Track unit length errors

  // Method to handle changes in the unit input
  onUnitChange(value: string, index: number) {
    // Check the length of the input value
    if (value.length > 8) {
      this.unitLengthExceeded[index] = true; // Set the flag for length exceeded
    } else {
      this.unitLengthExceeded[index] = false; // Reset the flag if within limit
    }

    // Update the unit in the items array
    this.items[index].unit = value; // Update the value in the items array
  }

  onUnitCostChange(unitCost: string) {
    this.dollarAmount = unitCost; // Update the Owner Price
    this.items.forEach((item) => {
      item.builderCost = unitCost; // Update the Builder Cost
    });
  }
  dollarAmount: string = '';

  OpenAproveStatus() {
    this.Approve = true;

  }
  OpenAproveStatuscancel() {
    this.Approve = false;
    // this.cancel.emit()

  }

  iniliazeFormApproveForm() {
    this.ApproveForm = this.fb.group({
      id: [''],
      globalId: ['00000000-0000-0000-0000-000000000000'],
      ApprovalComments: ['', [Validators.required, Validators.maxLength(50)]],
    });
  }

  // approve Working
  handleApprove() {
    if (this.timeUpdated) {
      this.saveFormWithUpdatedTime();
    } else {
      this.instantlyHandleSelectionResponses();
    }
  }
  saveFormWithUpdatedTime() {
    console.log("Time has been updated. Saving the form with new time...");
    this.choiceSelectionRes = this.choiceSelectionFormFieldValues?.isApproved; // Assuming 'time' is a field in selectionResponses
    this.ApproveStatus();
    this.Approve = false;

  }
  instantlyHandleSelectionResponses() {
    console.log("Time has not been updated. Handling approval...");
    this.ApproveStatus();
    this.Approve = false;
  }
  ApproveForm: FormGroup;
  timeUpdated: boolean = false;
  isButtonDisabled: boolean = false;
  Approvehide: SelectionInformationApprove | any;
  selectionInformationStatus: string = '';
  selectionChoiceParameterStatus: string = '';
  isApproved: boolean = false;


  ApproveStatus() {
    this.isButtonDisabled = true;
    const selectionData: SelectionInformationApprove = {
      selectionInformationId: this.SelectIdByChoice || this.newSeletChoiceId || this._newSeletChoiceId,
      selectionChoiceParameterId: this.HoldId,
      approvalNotes: this.ApproveForm.value.ApprovalComments,
      statusId: 441
    };
    this.selectionService.PostApprove(selectionData).subscribe({
      next: (response) => {
        this.toastService.success('Approval successful');
        this.Approvehide = response?.result;
        console.log('Approval successful:', this.Approvehide);
        this.selectionInformationStatus = response.result.selectionInformationStatus;
        this.selectionChoiceParameterStatus = response.result.selectionChoiceParameterStatus;
        this.isApproved = true;
      },
      error: (error) => {
        console.error('Approval failed:', error);
        this.isButtonDisabled = false;
      }
    });
  }

}


