import { Component, EventEmitter, NgModuleRef, OnInit, Output } from '@angular/core';
import { ToDoResponse } from 'src/Models/Project-Management/projectManagement';
import { JobInformationResponse } from 'src/Models/Job-Scratch/JobFromScratch';
import { JobInformationService } from 'src/Service/Job-List/Job-Information/job-information.service';
import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';
import { ToDoInformationService } from 'src/Service/Project-Management/to-do-information.service';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-copy-to-dos',
  templateUrl: './copy-to-dos.component.html',
  styleUrls: ['./copy-to-dos.component.css']
})
export class CopyToDosComponent implements OnInit {

  jobList: JobInformationResponse[] = [];
  formFieldValues: any = {};
  isLoadingOne = false;
  copyToDos: boolean = true;
  @Output() cancel = new EventEmitter<void>();
  @Output() onSaveEmit = new EventEmitter<void>();
  ids: number[]=[];

  constructor(
    private jobsService: JobInformationService,
    private accessLocalStorageService: AccessLocalStorageService,
    private toDosService: ToDoInformationService,
    private toastService: NzMessageService,

  ) { }

  ngOnInit(): void {
    console.log('Ids Recived by grid:',this.ids);
    this.formFieldValues = {
      toDoParameterIds: this.ids,
      jobInformationId: 0,
      companyParameterId: 1,
      formNameId: 6,
      newDeadLineDate: null
    }
    this.jobsService.onGetJobShortInformation().subscribe((res: any) => {
      this.jobList = res.result;
      const matchedJob = this.jobList.find(job => job.id === this.accessLocalStorageService.getJobId());
      if (matchedJob) {
        this.formFieldValues.jobInformationId = matchedJob.id;
      }
    });

  }


  closeCopToDo() {
    this.copyToDos = false;
    this.cancel.emit();
  }

  savecopyToDos(){
    console.log('PayLoad :',this.formFieldValues);
    this.toDosService.postCopyToDos(this.formFieldValues).subscribe(res => {
      if(res.result){
        this.toastService.success('To-Do Created');
        this.cancel.emit();
        this.onSaveEmit.emit();
      }
    })
    
  }
  
  loadOne(): void {
    this.isLoadingOne = true;
    setTimeout(() => {
      this.isLoadingOne = false;
    }, 1000);
  }


}
