import { TimeClockShiftComponent } from './../time-clock-shift/time-clock-shift.component';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { TimeClockClockInComponent } from '../time-clock-clock-in/time-clock-clock-in.component';
import { FiltersName } from 'src/app/Core/Enums/filtersName';
import { FilterSideMenuBarService } from 'src/shared/Shared-Services/filter-side-menu-bar.service';
import { AccessLocalStorageService } from 'src/Service/access-local-storage.service';
import { ShiftInformationResponse } from 'src/Models/Time-Clock/clock-in';
import { TimeClockService } from 'src/Service/TimeClock/time-clock.service';
import { DatePipe } from '@angular/common';
import { FilterSearchParameter, LookupFilterResponse, } from 'src/app/shared/component/Models/LookupModels';
import { Subscription } from 'rxjs';
import { StandardViewForAllService } from 'src/Service/standard-view-for-all.service';
import { LookupFilterService } from 'src/Service/Internaluser/lookup-filter.service';
import { StandardColumnSetupResponse, StandardViewResponse, } from 'src/Models/LookupStandardview/Standardview';
import { AddNewGridStandardViewComponent } from 'src/app/shared/component/Modal/add-new-grid-standard-view/add-new-grid-standard-view.component';
import { ColumnsResizePinProperties } from 'src/Models/InternalUser/internalUserColumnsPinResize';
import { ResponseModelArray } from 'src/Models/responseMessage.model';
import { NzMessageService } from 'ng-zorro-antd/message';
import { InternalUserService } from 'src/Service/Internaluser/internal-user.service';
import { ITimeClock_ClockIn, ITimeClockSetup, ITimeClockShiftGetById } from 'src/Models/_Time_Clock/_Time_Clock';
import { TimeClockSService } from 'src/Service/_Time_Clock/time-clock-s.service';
import { TimeClockCradsComponent } from '../time-clock-crads/time-clock-crads.component';
import { LoadingIndicatorService } from 'src/shared/Shared-Services/loading-indicator.service';
@Component({
  selector: 'app-project-management-time-clock-db',
  templateUrl: './project-management-time-clock-db.component.html',
  styleUrls: ['./project-management-time-clock-db.component.css'],
})
export class ProjectManagementTimeClockDbComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  private TimeClockstandardViewFilterSubscription : Subscription
  private displayColumnSubscription: Subscription;
  private TimeClocksubscription: Subscription; 
  private TimeClockFilterSubscription: Subscription; 
  @Output() onSaveComplete = new EventEmitter();
  @Input() clockInResponse: ShiftInformationResponse;
  @Output() cancel = new EventEmitter<void>();
  PROJECT_MANG_TIMECLOCK: string = FiltersName.PROJECT_MANG_TIMECLOCK;
  displayedColumns: string[] = [];
  fixedSelectedColumnNames: string[] = [];
  selected_View_Columns: string[] = [];
  timeClockShiftData: ShiftInformationResponse[] = [];
  lookupFilterResponse: LookupFilterResponse[] = [];
  GetProjecTimeClockgriddata: ShiftInformationResponse[] = [];
  getStandardViewColumnFilterData: StandardColumnSetupResponse[] = [];
  columnSizes: { columnName: string; widthSize: number }[] = [];
  getColumnWidthSize: number[] = [];
  expandedRows: number[] = [];
  // isLoading: boolean = false;
  isLandingLoading: boolean = false;
  isFilterApplied: boolean = false;
  totalRecords = 100;
  companyNameInvalid: boolean = false;
  getShiftInData: ShiftInformationResponse[] = [];
  getStandardViewFilterData: StandardViewResponse[] = [];
  selectedColNameSizePin: ColumnsResizePinProperties[] = [];
  selectedValueControl: any;
  defaultFilterIdSelected: number = 0;
  filterSearchParameter: FilterSearchParameter;
  currentPage = 1;
  pageSize = 10;
  PAGE_ID: number = -1;
  selectedFilter: number = -1;
  clockInButtonVisible: boolean = false;
  clockOutButtonVisible: boolean = false;
  selectedJobId: number;
  isCollapsed = false;
  showClockInOutButton: boolean = false;
  showBreakStartEnd: boolean = true;
  holdResponse: ShiftInformationResponse;
  inTimeJobName: string;
  startTime: Date;
  currentTime: Date;
  diffTime: Date;
  formattedDate: string;
  timeDifference: string;
  selectionFrom: string = 'DB';
  public mySelection: ShiftInformationResponse[] = [];
  timeClockForm: any = {};
  globalIds: string[] = [];
  shiftconformCardShow = false;
  ITimeClock_ClockInRes: ITimeClockShiftGetById;
  allSelected: boolean = false;
  columnLockState: { [key: string]: boolean } = {};
  StartBreakGetById: ITimeClockShiftGetById;
  ITimeClockSetupResponse: ITimeClockSetup;
  isColumnSticky: boolean[] = new Array(this.displayedColumns.length).fill(
    false
  );

  constructor(
    private datePipe: DatePipe,
    private modal: NzModalService,
    private filterService: FilterSideMenuBarService,
    private userTimeClockSer: TimeClockService,
    public _AccessLocalStorageService: AccessLocalStorageService,
    private standardViewService: StandardViewForAllService,
    private changeDetectorRef: ChangeDetectorRef,
    private LookupFilterService: LookupFilterService,
    private internalUserService: InternalUserService,
    public toastService: NzMessageService,
    public _TimeClockSService: TimeClockSService,
    private _LoadingIndicatorService: LoadingIndicatorService,
  ) {
    if (this.TimeClockFilterSubscription) {
      this.TimeClockFilterSubscription.unsubscribe();
    }
    if (this.TimeClocksubscription) {
        this.TimeClocksubscription.unsubscribe();
    }
    if (this.TimeClockstandardViewFilterSubscription) {
      this.TimeClockstandardViewFilterSubscription.unsubscribe();
    }
    console.clear();
  }

  ngOnInit(): void {
    this.isLandingLoading = true;
    this.internalUserService.setSelectedFilterId(0);
    if (this.TimeClockFilterSubscription) {
      this.TimeClockFilterSubscription.unsubscribe();
    }
    if (this.TimeClocksubscription) {
        this.TimeClocksubscription.unsubscribe();
    }
    if (this.TimeClockstandardViewFilterSubscription) {
      this.TimeClockstandardViewFilterSubscription.unsubscribe();
    }
    this.PAGE_ID = 11
    this.selectedJobId = this._AccessLocalStorageService.getJobId();
    this._AccessLocalStorageService.getJobId();

    this.displayedColumns = [];
    this.displayColumnSubscription = this.standardViewService.$displaySelectedColApply.subscribe(colName => {
      if (colName.length > 0) {
        this.displayedColumns = colName;
        this.changeDetectorRef.detectChanges();
      }
    });
    this.subscriptions.push(
      this.standardViewService.$displaySelectedColApply.subscribe(colName => {
        if (colName.length > 0) {
          this.displayedColumns = colName;
          this.changeDetectorRef.detectChanges();
        }
      })
    );

    this.subscriptions.push(
      this.standardViewService.$selectedStandardFilterType.subscribe(standardType => {
        this.selectedFilter = standardType != -1 ? standardType : standardType;
      })
    );
    this.getStandardFilterByFormPageId(this.PAGE_ID, this.selectionFrom); // New Changes By Aamir Ali - 22-Apr2024
    this.getStandardViewFilterByFormPageId(this.PAGE_ID);

    this.companyNameInvalid = false;

    this.userTimeClockSer.isTimeClockAppliedFilter.subscribe((filter) => {
      if (filter) {
        this.isFilterApplied = filter;
      } else {
        this.isFilterApplied = filter;
      }
    });
    const userId = this._AccessLocalStorageService.getUserLoginId();
    this.userTimeClockSer.getAllData(userId).subscribe((response) => {
      this.holdResponse = response.result;
      this.inTimeJobName = this.holdResponse.jobInformation.name;
      this.startTime = this.holdResponse.startTime;
      let startTimeStr = this.startTime.toString();
      this.formattedDate = this.formatDateTime(this.startTime);

      const startDate = this.parseDateString(startTimeStr);
      if (startDate instanceof Date) {
        this.timeDifference = this.calculateTimeDifference(startDate);
      } else {
        this.timeDifference = 'Invalid Date';
      }
      if (response == null) {
        this.showClockInOutButton = true;
      } else {
        this.showClockInOutButton = false;
      }
    });
    this.onGetClockOut();
  }
  shouldShowClockOutButton(dataItem: any): boolean {
    return (
      (!dataItem?.endTime && !this.showClockInOutButton) || dataItem?.endTime
    );
  }
  startBreak(){
    this.showBreakStartEnd = true
  }
  formatDateTime(dateTimeString: Date): string {
    const date = new Date(dateTimeString);
    return this.datePipe.transform(date, 'h:mm a, MMM d, y');
  }
  parseDateString(dateString: string): Date | null {
    const parsedDate = new Date(dateString);
    return isNaN(parsedDate.getTime()) ? null : parsedDate;
  }
  calculateTimeDifference(startDate: Date): string {
    const now = new Date();
    const diffMs = now.getTime() - startDate.getTime();
    const diffHours = Math.floor(diffMs / 3600000);
    const diffMinutes = Math.floor((diffMs % 3600000) / 60000);
    return `${diffHours}:${diffMinutes < 10 ? '0' : ''}${diffMinutes}`;
  }
  @ViewChild(TimeClockCradsComponent) onInUserChanges!: TimeClockCradsComponent;
  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }
  ClockInUsers(): void {
    const modalRef = this.modal.create({
      nzContent: TimeClockClockInComponent,
      nzFooter: null,
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  openFilter(component: string, pageId: number) {
    this.selectionFrom = '';
    this.isColumnSticky = new Array(this.displayedColumns.length).fill(false);
    this.filterService.toggleFilter(component, pageId);
    this.userTimeClockSer.clockAppliedFilter(false);
  }
  getSelectedJobId(item: number) {
    this.selectedJobId = item;
    this.timeClockShiftData = null;
    this.mySelection = [];
    this.getStandardFilterByFormPageId(this.PAGE_ID, this.selectionFrom);
  }
  timeClockgrid(
    companyId: number,
    formNameId: number,
    lookUpFilterId: number,
    page: number,
    pageSize: number
  ) {
    this.filterSearchParameter = {
      CompanyParameterId: companyId,
      FormNameId: formNameId,
      LookUpFilterId: lookUpFilterId,
      page: page,
      pageSize: pageSize,
      jobInformationId: this.selectedJobId,
    };

    this.userTimeClockSer.getTimeClockShift(this.filterSearchParameter);
    this.TimeClocksubscription = this.userTimeClockSer.$getProjectTimeClock.subscribe((result) => {
      if (result) {
        this.totalRecords = result?.result?.rowCount;
        this.GetProjecTimeClockgriddata = result?.result?.rows;
        this.getShiftInData = this.GetProjecTimeClockgriddata;
        this.isLandingLoading = false;
      }
    });
  }
  onPageSizeChange(size: number): void {
    this.isColumnSticky = new Array(this.displayedColumns.length).fill(false);
    this.pageSize = size;
    let filterId: number = 0;
    this.userTimeClockSer.$selectedFilterId.subscribe((v) => {
      if (v) {
        filterId = v;
      }
    });
    this._LoadingIndicatorService.show();
    setTimeout(() => {
      this.defaultFilterIdSelected = filterId;
      this.timeClockgrid(
        1,
        this.PAGE_ID,
        filterId,
        this.currentPage,
        this.pageSize
      );
      this._LoadingIndicatorService.hide();
    }, 100);
  }
  getStandardFilterByFormPageId(pageId: number, type: string) {
    if (this.TimeClockFilterSubscription) {
      this.TimeClockFilterSubscription.unsubscribe();
    }
    if (this.TimeClocksubscription) {
        this.TimeClocksubscription.unsubscribe();
    }
    this.LookupFilterService.getStandardFilterByFormPageId(pageId);
    this.TimeClockFilterSubscription = this.LookupFilterService.$getStandardFilter.subscribe((result) => {
      if (result) {
        this.lookupFilterResponse = result.result;
        let defaultFilterId = result.result.find(
          (filter) => filter.isSetAsDefault === true
        ).id;
        this.userTimeClockSer.setSelectedFilterId(defaultFilterId);
        let id = -1;
        if (type === this.selectionFrom) {
          this.defaultFilterIdSelected = defaultFilterId;
          this._AccessLocalStorageService.setSelectedFilterId(defaultFilterId);
          id = this._AccessLocalStorageService.getSelectedFilterId();
        } else {
          id = this._AccessLocalStorageService.getSelectedFilterId();
        }

        if (id !== -1) {
          this.timeClockgrid(
            1,
            this.PAGE_ID,
            id,
            this.currentPage,
            this.pageSize
          );
        }
      }
    });
  }
  onPageChange(page: number): void {
    this.currentPage = page;
    let filterId: number = 0;
    this.userTimeClockSer.$selectedFilterId.subscribe((v) => {
      if (v) {
        filterId = v;
      }
    });
    this._LoadingIndicatorService.show();
    setTimeout(() => {
      this.defaultFilterIdSelected = filterId;
      this.timeClockgrid(1, this.PAGE_ID, filterId, page, this.pageSize);
      this._LoadingIndicatorService.hide();
    }, 100);
  }
  onFilterChange(id: number) {
    this.isColumnSticky = new Array(this.displayedColumns.length).fill(false);

    this.selectedFilter = -1;
    this.displayedColumns = [];
    this.selected_View_Columns = [];
    let selectedView = this.getStandardViewFilterData.find(
      (view) => view.id === id
    ).standardViewParameters;
    selectedView.forEach((viewItem) => {
      if (
        viewItem.standardColumnSetup &&
        viewItem.standardColumnSetup.displayColumnName
      ) {
        this.selected_View_Columns.push(
          viewItem.standardColumnSetup.displayColumnName
        );
        this.displayedColumns = this.selected_View_Columns;

        const columnIndex = this.displayedColumns.indexOf(
          viewItem.standardColumnSetup.displayColumnName
        );
        if (columnIndex !== -1) {
          this.isColumnSticky[columnIndex] = viewItem.isPin;
          this.getColumnWidthSize[columnIndex] = viewItem.resizeColumnWidth;
        }
      }
    });
  }
  openStandardViewFilterComponent(isSaveAs?: string) {
    let isInputVisible: boolean = false;
    if (isSaveAs === 'saveAs') {
      isInputVisible = true;
    } else {
      isInputVisible = false;
    }
    this.selectedColNameSizePin = [];
    for (let i = 0; i < this.displayedColumns.length; i++) {
      const item = this.displayedColumns[i];
      const isPinValue = this.isColumnSticky[i];
      let widthSize = 0;
      if (this.columnSizes.length > 0) {
        for (let i = 0; i < this.columnSizes.length; i++) {
          const columnName = this.columnSizes[i].columnName;
          const columnNameSize = this.columnSizes[i].widthSize;
          if (columnName === item) {
            widthSize = columnNameSize;
            break;
          }
        }
      }
      if (widthSize === 0) {
        widthSize = 180;
      }
      this.selectedColNameSizePin.push({
        displayColumnName: item,
        resizeColumnWidth: widthSize,
        isPin: isPinValue == true ? isPinValue : false,
      });
    }
    const modalRef = this.modal.create({
      nzContent: AddNewGridStandardViewComponent,
      nzData: {
        pageId: this.PAGE_ID,
        column: this.getStandardViewColumnFilterData,
        displayCol: this.displayedColumns,
        viewList: this.getStandardViewFilterData,
        selectedColNameSizePin: this.selectedColNameSizePin,
        selectedStandardView: this.selectedValueControl,
        fixedCol: this.fixedSelectedColumnNames,
        isSaveAs: isInputVisible,
      },
    });
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  getStandardViewFilterByFormPageId(pageId: number) {
    if (this.TimeClockstandardViewFilterSubscription) {
      this.TimeClockstandardViewFilterSubscription.unsubscribe();
    }
    this.displayedColumns = [];
    this.fixedSelectedColumnNames = [];
    this.standardViewService.getStandardViewFilterByFormPageId(pageId);
    this.standardViewService.getStandardViewFilterColumnsByFormPageId(pageId);
    this.TimeClockstandardViewFilterSubscription = this.standardViewService.$getStandardViewFilter.subscribe(result => {
      if (result) {
        this.displayedColumns = [];
        this.selected_View_Columns = [];
        this.getStandardViewFilterData = result.result;
        let defaultView = this.getStandardViewFilterData.find(
          (view) => view.isSetAsDefault === true
        ).standardViewParameters;
        this.selectedValueControl = this.getStandardViewFilterData.find(
          (view) => view.isSetAsDefault === true
        ).id;
        defaultView.forEach((viewItem) => {
          if (
            viewItem.standardColumnSetup &&
            viewItem.standardColumnSetup.displayColumnName
          ) {
            this.selected_View_Columns.push(
              viewItem.standardColumnSetup.displayColumnName
            );
          }
        });
        this.displayedColumns = this.selected_View_Columns;
      }
    });
    this.standardViewService.$getStandardViewColumn.subscribe((result) => {
      if (result) {
        this.getStandardViewColumnFilterData = result.result;
        let fixedCol = this.getStandardViewColumnFilterData.filter(
          (x) => x.isSelected === true
        );
        fixedCol.forEach((x) => {
          this.fixedSelectedColumnNames.push(x.displayColumnName);
        });
      }
    });
  }
  saveEditStandardView() {
    this.standardViewService.$createStandardViewRequest.subscribe(
      (formData) => {
        if (formData && formData !== null) {
          this.standardViewService.postData(formData).subscribe(
            (res: ResponseModelArray<StandardViewResponse>) => {
              setTimeout(() => {
                this._LoadingIndicatorService.show();
                this.toastService.success('Standard View Updated Successfully');
                this.standardViewService.getStandardViewFilterByFormPageId(
                  this.PAGE_ID
                );
                this.selectedFilter = -1;
              }, 10);
            },
            (error) => {
              console.error('Error:', error);
              this._LoadingIndicatorService.hide();
            }
          );
        }
      }
    );
  }
  saveColumnSizes(event: any) {
    event.forEach((col) => {
      const columnName = col.column.field;
      const widthSize = col.newWidth;
      const existingIndex = this.columnSizes.findIndex(
        (item) => item.columnName === columnName
      );

      if (existingIndex !== -1) {
        this.columnSizes[existingIndex].widthSize = widthSize;
      } else {
        this.columnSizes.push({ columnName, widthSize });
      }
    });
  }
  reInitReset() {
    this.ngOnInit();
    this.selectedFilter = -1;
  }
  toggleColumnSticky(index: number) {
    this.isColumnSticky[index] = !this.isColumnSticky[index];
    if (this.isColumnSticky[index]) {
      for (let i = 0; i < index; i++) {
        this.isColumnSticky[i] = true;
      }
    }
  }
  EditInternalUser(data: ShiftInformationResponse): void {
    const modalRef = this.modal.create({
      nzWidth: '75%',
      nzBodyStyle: {
        background: '#f1f4fa',
        padding: '0',
        margin: '0',
        'overflow-x': 'hidden',
      },
      nzContent: TimeClockShiftComponent,
      nzStyle: {
        top: '22px',
      },
      nzFooter: null,
    });
    // modalRef.componentInstance.shiftGridResponse = data;
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
  }
  selectionChange(event: any): void {
    if (event && event.length > 0) {
      console.log('Selected Row(s):');
      this.globalIds = [];
      event.forEach((id: number) => {
        const selectedRow = this.timeClockShiftData.find(
          (row) => row.id === id
        );
        if (selectedRow) {
          this.globalIds.push(selectedRow.globalId);
          console.log('Selected row data', selectedRow);
        }
      });
      this.onSaveComplete.emit();
    } else {
      console.log('No rows selected.');
    }
  }
  conformhandleCancel(): void {
    this.shiftconformCardShow = false;
  }
  approvalShift() {
    this.shiftconformCardShow = true;
  }
  ngOnDestroy() {
    this.LookupFilterService.getStandardFilter.next(null);
    localStorage.removeItem('Selected_Filter_Id');
    if (this.TimeClockFilterSubscription) {
      this.TimeClockFilterSubscription.unsubscribe();
    } 
    if (this.TimeClocksubscription) {
        this.TimeClocksubscription.unsubscribe();
    }
    if (this.TimeClockstandardViewFilterSubscription) {
      this.TimeClockstandardViewFilterSubscription.unsubscribe();
    }
    console.clear();
  }
  tabs = [1, 2];
  innerCardTabs(tab: number): string {
    switch (tab) {
      case 1:
        return 'Reports';
      default:
        return 'Shift Map';
    }
  }
  Clock(argument: string): void {
    if(argument === 'ClockOut'){
      this.onGetClockOut();
    }
    this._LoadingIndicatorService.show();
    const modalRef = this.modal.create({
      nzWidth: '0%',
      nzContent: TimeClockClockInComponent,
      nzFooter: null,
      nzClosable: false,
      nzMask: true,
      nzMaskStyle: {
        display: 'none',
      },
    });
    modalRef.componentInstance.onJob_Id = this._AccessLocalStorageService.getJobId();
    modalRef.componentInstance.ITimeClock_ClockInRes = this.ITimeClock_ClockInRes;
    modalRef.componentInstance.argument = argument;
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    modalRef.componentInstance.initializationComplete.subscribe((res) => {
      this._LoadingIndicatorService.hide();
    });
    modalRef.componentInstance.ClockInUserOnCompleted.subscribe((data: ITimeClock_ClockIn) =>{
      this.onAfterResponse();
      this.onGetClockOut();
      this.onTest_ResponsePass();
    });
  }
  // EndTimeError: boolean = false;
  RecordShift(_Id: number, _IdClockIn: number): void {
    this._LoadingIndicatorService.show();
    const modalRef = this.modal.create({
      nzWidth: '0%',
      nzContent: TimeClockShiftComponent,
      nzFooter: null,
      nzClosable: false,
      nzMask: true,
      nzMaskStyle: {
        display: 'none',
      },
    });
    modalRef.componentInstance.onClockIn_Id = _IdClockIn;
    modalRef.componentInstance.onJob_Id = _Id;
    modalRef.componentInstance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    modalRef.componentInstance.ClockInUserOnCompleted.subscribe((data: ITimeClockShiftGetById | boolean) =>{
      if (typeof data === 'boolean') {
        if (data === true) {
          this.Test_Case();
        }
      }
      this.onGetClockOut();
      this.timeClockgrid(1, this.PAGE_ID, this.defaultFilterIdSelected, this.currentPage, this.pageSize)
    });
    modalRef.componentInstance.initializationComplete.subscribe((res) => {
      if(res === false){
        this._LoadingIndicatorService.hide();
      } else {
        this._LoadingIndicatorService.show();
      }
    });
  }
  @ViewChild(TimeClockCradsComponent) _Test!: TimeClockCradsComponent;
  Test_Case(){
    this._Test.OnTest();
  }
  onGetClockOut(){
    this._TimeClockSService.GetClockOut().subscribe(res => {
      this.ITimeClock_ClockInRes = res.result
    });
  }  
  getColumnWidth(column: string): number {
    switch (column) {
      case 'Date':
        return 200;
      case 'Job':
        return 200;
      case 'Name':
        return 170;
      case 'Tags':
        return 150;
      case 'Time In':
        return 150;
      case 'Time Out':
        return 150;
      case 'Break Time':
        return 150;
      case 'Regular Time':
        return 150;
      case 'OT':
        return 150;
      case '20T':
        return 150;
      case 'Total Work Time':
        return 150;
      case 'Edited':
        return 150;
      case 'Approved Status':
        return 150;
      case 'Builder Cost':
        return 150;
      case 'Cost Codes':
        return 150;
      case 'Created By':
        return 150;
      case 'Notes':
        return 150;
      case '20T':
        return 150;
      case 'Created Date':
        return 250;
      case 'Related Owner Invoices':
        return 150;
      default:
        return 100;
    }
  }
  isColumnLocked(column: string): boolean {
    return this.columnLockState[column];
  }
  toggleColumnLock(column: string) {
    this.columnLockState[column] = !this.columnLockState[column];
  }
  onHeaderCheckboxChange(checked: boolean) {
    this.allSelected = checked;
    this.GetProjecTimeClockgriddata.forEach(item => item.selected = checked);
  }
  onCheckboxChange() {
    const selectedCount = this.GetProjecTimeClockgriddata.filter(item => item.selected).length;
    this.allSelected = selectedCount === this.GetProjecTimeClockgriddata.length;
  }
  onStartBreak(Data: ITimeClockShiftGetById): void {
    this.StartBreakGetById = Data;
    this.onAfterResponse();
  }
  @ViewChild(TimeClockCradsComponent) attachmentsComponent!: TimeClockCradsComponent;
  onEndTime(): void {
    this.attachmentsComponent.onStart_Time('End_Break');
  }
  onAfterResponse(){
    this.timeClockgrid(1, this.PAGE_ID, this.defaultFilterIdSelected, this.currentPage, this.pageSize)
  }
  onTest_ResponsePass(): void {
    this.attachmentsComponent.onGetClockOut();
  }
  fetchTimeClockSetupForm(): void {
    this._TimeClockSService.getCompanyTimeClockSetup().subscribe(res => {
      this.ITimeClockSetupResponse = res?.result
    });
  }
}
