import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { ChangeOrderGetById, ChangeOrderInformationResponse, ChangeOrderSetup, CreateChangeOrderInformationRequest } from 'src/Models/ChangeOrder/ChangeOrder';
import { AutoNumberExisting, ChangeOrderSetupResponse, CreateChangeOrderSetupRequest, ResponseModelInChangeOrder } from 'src/Models/CompanySettings/CompanyInformation';
import { ResponseModel, ResponseModelArray } from 'src/Models/responseMessage.model';

@Injectable({
  providedIn: 'root'
})
export class ChangeOrderInformationService {

  constructor(private http: HttpClient) {}


  private ChangeOrderUrl: string = environment.apiUrl + 'ChangeOrderInformation/';
  private This_Url_Change_Order_Setup: string = environment.apiUrl + 'ChangeOrderSetup/';
  private Change_Order_Setup_Fetch: string = environment.apiUrl + 'ChangeOrderSetup/getData-ByCompany/';

  PostChangeOrder(createLead: ChangeOrderGetById): Observable<ResponseModel<ChangeOrderGetById>> {
    const apiUrl = `${this.ChangeOrderUrl}`;
    return this.http.post<ResponseModel<ChangeOrderGetById>>(apiUrl, createLead).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }
  getDataIdChangeOrder(id: number): Observable<ResponseModel<ChangeOrderGetById>> {
    return this.http.get<ResponseModel<ChangeOrderGetById>>(`${this.ChangeOrderUrl}getBy-Id?id=${id}`);
  }
  deleteData(id: number): Observable<ChangeOrderInformationResponse> {
    return this.http.delete<ChangeOrderInformationResponse>(
      `${this.ChangeOrderUrl}?globalId=${id}`
    );
  }
  ChangeOrdergetData(): Observable<ResponseModelArray<ChangeOrderInformationResponse>> {
    return this.http.get<ResponseModelArray<ChangeOrderInformationResponse>>(`${this.ChangeOrderUrl}GetAll`);
  }
  PostChangeOrderSetup(createCompanyChangeorder: CreateChangeOrderSetupRequest): Observable<ResponseModel<ChangeOrderSetup>> {
    const apiUrl = `${this.This_Url_Change_Order_Setup}`;
    return this.http.post<ResponseModel<ChangeOrderSetup>>(apiUrl, createCompanyChangeorder).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }
  getCompanyChangeOrder(): Observable<ResponseModel<ChangeOrderSetupResponse>> {
    return this.http.get<ResponseModel<ChangeOrderSetupResponse>>(this.Change_Order_Setup_Fetch);
  }
  PostChangeOrderAndAutoNumberExisting(createAutoNumberExisting: AutoNumberExisting): Observable<ResponseModelInChangeOrder> {
    const apiUrl = `${this.ChangeOrderUrl}AutoNumber-Exsist`;
    return this.http.post<ResponseModelInChangeOrder>(apiUrl, createAutoNumberExisting).pipe(
        catchError((error: any) => {
            console.error('Error:', error);
            return throwError(error);
        })
    );
  }
  onChangeOrderGetByTooltipValue(_JobId: number): Observable<ResponseModel<any>> {
    return this.http.get<ResponseModel<any>>(`${this.ChangeOrderUrl}Get-Tooltip-Value?JobInformationId=${_JobId}`);
  }
}
