
<nz-modal
  nzWidth="97%"
  [(nzVisible)]="isVisibleLead"
  [nzTitle]="LeadproposalTitle"

  [nzFooter]="LeadproposalFooter"
  [nzBodyStyle]="{
    background: '#f1f4fa',
    padding: '14px',
    'max-height': '71vh',
    'overflow-y': 'auto'
  }"
  [nzStyle]="{ top: '38px' }"
  (nzOnCancel)="showConfirmCardLeadProposal()"
>
  <ng-template #LeadproposalTitle>

    <div class="row" *ngIf="leadOpportunityProposalSaveResponse">
      <p nz-typography class="p-0 m-0">
        {{ leadOpportunityProposalSaveResponse?.Title }}
      </p>
    </div>
    <div>
      <div class="row">
        <h1 nz-typography class="p-0 m-0">Lead Proposal</h1>
      </div>
    </div>
  </ng-template>




  <div class="content" *nzModalContent>
    <div class="spinner-wrapper" *ngIf="isLoading">
      <nz-spin nzSimple [nzSpinning]="true" [nzSize]="'large'"></nz-spin>
    </div>

    <nz-alert
      class="mb-3 error-message"
      *ngIf="isSubmitted && errorFields.length > 0"
      nzType="error"
      nzShowIcon
      [nzMessage]="message"
      [nzDescription]="errorDescriptionTemplate"
    ></nz-alert>

    <ng-template #message>
      <p class="mt-1">Please correct the following fields:</p>
    </ng-template>

    <ng-template #errorDescriptionTemplate>
      <ul>
        <li class="sub-text" *ngFor="let error of errorFields">
          <span class="d-flex align-items-center">
            <p>{{ error.label }}:</p>
            <p>{{ error.message }}</p>
          </span>
        </li>
      </ul>
    </ng-template>



    <nz-card
      class="mb-3"
      nzTitle="Lead proposal Details"
      [nzExtra]="leadProposalExtra"
    >
      <div class="card-body">
        <div class="row">
          <div class="col-8">
            <label class="sub-text">Title</label>
          </div>
          <div class="col-2">
            <span >
              <label class="sub-text">Approval Deadline</label>
            </span>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-8">
            <input
              #titleInput
              nz-input
              [(ngModel)]="formFieldValues.title"
              name="title"
              required
              (ngModelChange)="onFieldChange('title', $event)"
              [nzStatus]="validationStates['title']"
              class="formField"
            />
            <div
              *ngIf="validationStates['title'] === 'error'"
              class="text-danger"
            >
              {{ validationMessages["title"] }}
            </div>
          </div>
          <div class="col-2">
            <nz-date-picker
              *ngIf="
                getStatusProposal?.code === ProposalStatusEnum.Unreleased ||
                getStatusProposal == null
              "
              [(ngModel)]="formFieldValues.approvalDeadlineOn"
              name="approvalDeadlineOn"
              [nzPlaceHolder]="' '"
              nzSuffixIcon="false"
              [nzFormat]="'MMM d, YYYY'"
              (ngModelChange)="onFieldChange('approvalDeadlineOn', $event)"
              [nzStatus]="validationStates['approvalDeadlineOn']"
            ></nz-date-picker>
            <div
              *ngIf="validationStates['approvalDeadlineOn'] === 'error'"
              class="text-danger"
            >
              {{ validationMessages["approvalDeadlineOn"] }}
            </div>
            <p
              class="mt-1"
              *ngIf="
                getStatusProposal?.code === ProposalStatusEnum.Released ||
                statusUpdateForDecline?.code === ProposalStatusEnum.Declined
              "
            >
              {{
                formFieldValues.approvalDeadlineOn
                  ? (formFieldValues.approvalDeadlineOn | date : "MMM d, YYYY")
                  : "--"
              }}
            </p>
          </div>
        </div>
        <div class="row">
          <div
            [ngClass]="{
              'col-12': !signatureResponse && !signatureResponseDecline,
              'col-8': signatureResponse || signatureResponseDecline
            }"
            class="signature-container"
          >
          <nz-collapse nzAccordion>
              <nz-collapse-panel
                #p
                [nzHeader]="'More Details'"
                [nzExpandedIcon]="expandedIcon"
                [ngStyle]="customStyle"
              >

              <ng-template #expandedIcon let-active>
                {{ active }}
                <span nz-icon nzType="caret-right" class="ant-collapse-arrow" [nzRotate]="p.nzActive ? 90 : -90"></span>
              </ng-template>

                <div class="row mt-0" #p>
                  <div class="col-12">
                    <div class="row mt-2">
                      <div class="col-10">
                        <label class="sub-text">Internal Notes</label>
                        <textarea
                          [(ngModel)]="formFieldValues.internalNotes"
                          name="internalNotes"
                          nz-input
                          [nzAutosize]="{ minRows: 2, maxRows: 10 }"
                          class="formField"
                          (ngModelChange)="
                            onFieldChange('internalNotes', $event)
                          "
                          [nzStatus]="validationStates['internalNotes']"
                        ></textarea>
                        <div
                          *ngIf="
                            validationStates['internalNotes'] === 'error'
                          "
                          class="text-danger"
                        >
                          {{ validationMessages["internalNotes"] }}
                        </div>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-10">
                        <label class="sub-text">Introductory Text</label>
                        <div class="row">
                          <ckeditor
                            [(ngModel)]="formFieldValues['introductoryText']"
                            name="introductoryText"
                            [editor]="Editor"
                            [config]="config"
                            class="size"
                            id="editor"
                          >
                          </ckeditor>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-10">
                        <label class="sub-text">Closing Text</label>
                        <div class="row">
                          <ckeditor
                            [(ngModel)]="formFieldValues['closingText']"
                            name="closingText"
                            [editor]="Editor"
                            [config]="config"
                            class="size"
                            id="editor"
                          >
                          </ckeditor>
                        </div>
                      </div>
                    </div>
                  </div>

                 <!-- Document Card For Lead Proposal Start  Date 08/29/2024-->
                 <nz-card nzBorderless nzTitle="Attachments">
                  <div class="row p-2">
                    <div class="col-12">
                      <app-create-attachments-common
                        [TestAttachment__]="TestAttachment"
                        (filesUploaded)="onFilesUploaded($event)"
                        (attachmentsReset)="resetForm()"></app-create-attachments-common>
                    </div>

                  </div>
                </nz-card>
                 <!-- Document Card For Lead Proposal End -->
                </div>
              </nz-collapse-panel>
            </nz-collapse>
          </div>
          <div
            class="col-4"
            *ngIf="signatureResponse || signatureResponseDecline"
          >
            <div class="signatureImage" *ngIf="signatureResponse">
              <img
                [src]="signatureResponse?.imageSource"
                alt="Signature"
                class="signature-img"
              />
            </div>
            <div
              class="signatureImage"
              *ngIf="getStatusProposal?.code === ProposalStatusEnum.Released"
            >
              <img
                [src]="signatureResponseDecline?.imageSource"
                alt="Signature"
                class="signature-img"
              />
            </div>
          </div>
        </div>
      </div>
    </nz-card>



    <ng-template #leadProposalExtra >

      <!-- Display statusUpdateForDecline if present -->
      <div
        *ngIf="statusUpdateForDecline; else statusProposalTemplate"
        [ngClass]="{
          declined: statusUpdateForDecline?.description === 'Declined'
        }"
        class="status-text d-flex justify-content-center align-items-center"
      >
        <strong class="fs-6">{{ statusUpdateForDecline?.description }}</strong>
      </div>

      <!-- Template for getStatusProposal -->
      <ng-template #statusProposalTemplate>
        <!-- Conditionally show this paragraph based on the status -->
        <p class="me-2" *ngIf="getStatusProposal?.description !== 'Unreleased'">
          <!-- Display appropriate text based on the status -->
          <span *ngIf="getStatusProposal?.description === 'Released'">
            Released by {{ footerResponse?.fullName }} on {{ footerResponse?.createdOnFormatted }}
          </span>
          <span *ngIf="getStatusProposal?.description === 'Declined'">
            Declined by {{ footerResponse?.fullName }} on {{ footerResponse?.createdOnFormatted }}
          </span>
          <span *ngIf="getStatusProposal?.description === 'Approved'">
            Approved by {{ footerResponse?.fullName }} on {{ footerResponse?.createdOnFormatted }}
          </span>
          <span *ngIf="getStatusProposal?.description === 'Pending'">
            Pending approval since {{ footerResponse?.createdOnFormatted }}
          </span>
          <span *ngIf="getStatusProposal?.description === 'Expired'">
            Expired on {{ footerResponse?.createdOnFormatted }}
          </span>
        </p>

        <!-- Status text with dynamic classes based on the description -->
        <div
          *ngIf="getStatusProposal"
          [ngClass]="{
            pending: getStatusProposal?.description === 'Pending',
            unreleased: getStatusProposal?.description === 'Unreleased',
            approved: getStatusProposal?.description === 'Approved',
            declined: getStatusProposal?.description === 'Declined',
            'declined-not-released': getStatusProposal?.description === 'Declined Not Released',
            expired: getStatusProposal?.description === 'Expired',
            released: getStatusProposal?.description === 'Released'
          }"
          class="status-text d-flex justify-content-center align-items-center"
        >
          <strong class="fs-6">{{ getStatusProposal?.description }}</strong>
        </div>
      </ng-template>

    </ng-template>



    <!-- tabs -->
    <div class="card-container">
      <nz-tabset nzType="card">
        <nz-tab *ngFor="let tab of tabs" [nzTitle]="leadproposalTabs(tab)">
          <ng-container *ngIf="tab === 1">

           <cost-item-common [companyParameter]="companyParameter" [costItemId]="costItemId"></cost-item-common>

          </ng-container>

        </nz-tab>
      </nz-tabset>
    </div>
    <!-- tabs -->
     <!-- Second Phase Payment Card Start -->

    <div class="card border-0 mb-3 mt-3">
      <div class="card-head border-bottom">
        <div class="row py-2">
          <div class="col-12">
            <h3 class="ms-3" nz-typography>Online Payment Details</h3>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <div>
              <span>Payment Status: </span> <span>No Payment Requested</span>
            </div>
          </div>
        </div>
      </div>
    </div>

<!-- Second Phase Payment Card End -->
  </div>
  <ng-template #LeadproposalFooter>
    <div class="container-fluid p-0 PSCustomFooter mt-2">
      <div class="row">
          <div class="col-4 text-start  justify-content-start align-items-start">
            <span *ngIf="footerResponse">
              <app-footer-label [footerData]="footerResponse"></app-footer-label>
            </span>
        </div>
        <div class="col-md-8 text-end">
          <nz-space>
            <ng-container *nzSpaceItem>
              <button
                class=""
                nz-button
                nzType="default"
                *ngIf="
                  approveAllowButtons() &&
                  declineAllowButtons() &&
                  getProposalTemplatesData
                "
                (click)="deleteLeadConfirm()"
                [disabled]="DisabledNextActivity"
                 [class.disabled]="DisabledNextActivity"
              >
                Delete
              </button>
            </ng-container>
            <ng-container *nzSpaceItem>
              <button
                class=""
                nz-button
                nzType="default"
                *ngIf="getProposalTemplatesData"
                [disabled]="DisabledNextActivity"
          [class.disabled]="DisabledNextActivity"
              >
                Print
              </button>
            </ng-container>
            <ng-container *nzSpaceItem>
              <button
                class=""
                nz-button
                nzType="default"
                *ngIf="
                  getStatusProposal?.code === ProposalStatusEnum.Approved ||
                  statusUpdateForDecline?.code === ProposalStatusEnum.Declined
                "
                [disabled]="DisabledNextActivity"
                [class.disabled]="DisabledNextActivity"
                (click)="showConfirmResetModal()"
              >
                Reset
              </button>
            </ng-container>
            <ng-container *nzSpaceItem>
              <button
                *ngIf="
                  approveAllowButtons() &&
                  declineAllowButtons() &&
                  getProposalTemplatesData &&
                  getStatusProposal?.code === ProposalStatusEnum.Released
                "
                class=""
                (click)="UnReleaseConfirmCard()"
                nz-button
                nzType="default"
                [disabled]="DisabledNextActivity"
                [class.disabled]="DisabledNextActivity"
              >
                Unrelease
              </button>
            </ng-container>

            <ng-container *nzSpaceItem>
              <button
                class=""
                *ngIf="
                  approveAllowButtons() &&
                  declineAllowButtons() &&
                  getStatusProposal?.code === ProposalStatusEnum.Released
                "
                nz-button
                nzType="default"
                [disabled]="DisabledNextActivity"
          [class.disabled]="DisabledNextActivity"
              >
                Resend to Owner
              </button>
            </ng-container>
            <ng-container *nzSpaceItem>
              <button
                class=""
                *ngIf="
                  approveAllowButtons() &&
                  getProposalTemplatesData &&
                  declineAllowButtons()
                "
                nz-button
                nzType="default"
                (click)="declineProposal()"
                [nzLoading]="isDecline"
                [disabled]="DisabledNextActivity"
          [class.disabled]="DisabledNextActivity"
              >
                Decline
              </button>
            </ng-container>
            <ng-container *nzSpaceItem>
              <button
                class=""
                *ngIf="
                  approveAllowButtons() &&
                  getProposalTemplatesData &&
                  declineAllowButtons()
                "
                nz-button
                nzType="primary"
                (click)="saveAndApproveProposal()"
                [nzLoading]="isSaveAndApproval"
                [disabled]="DisabledNextActivity"
                [class.disabled]="DisabledNextActivity"
              >
                Save & Approve
              </button>
            </ng-container>
            <ng-container *nzSpaceItem>
              <button
                *ngIf="
                  approveAllowButtons() &&
                  getStatusProposal?.code === ProposalStatusEnum.Unreleased &&
                  declineAllowButtons()
                "
                class=""
                nz-button
                nzType="primary"
                (click)="ReleaseConfirmCard()"
                [nzLoading]="isSaveAndRelease"
                [disabled]="DisabledNextActivity"
               [class.disabled]="DisabledNextActivity"
              >
                Save & Release
              </button>
            </ng-container>
            <button
              class=""
              (click)="saveLeadProposal(null)"
              *nzSpaceItem
              nz-button
              nzType="primary"
              [nzLoading]="isSave"
              [disabled]="DisabledNextActivity"
              [class.disabled]="DisabledNextActivity"
            >
              Save
            </button>
            <button
              class=""
              (click)="saveAndCloseProposal()"
              *nzSpaceItem
              nz-button
              nzType="primary"
              [nzLoading]="isSaveAndClose"
              [disabled]="DisabledNextActivity"
             [class.disabled]="DisabledNextActivity"
            >
              Save & Close
            </button>
          </nz-space>
        </div>
        <!-- <div class="col-md-4 text-start">
            <span>Updated by <nz-avatar nzSize="small" nzText="MM" style="color:#f56a00; background-color:#fde3cf;"></nz-avatar>  <span class="ms-1"><strong>Muhammad Ahmed</strong></span>  on Oct 31, 2023, 7:27 AM</span>
        </div> -->
      </div>
    </div>
  </ng-template>
</nz-modal>





<nz-modal [(nzVisible)]="isResetVisible"
[nzFooter]="resetLeadproposalFooter"
nzTitle="Reset Lead Proposal Status"
[nzBodyStyle]="{
  background: '#f1f4fa',
  padding: '14px',
  'max-height': '71vh',
  'overflow-y': 'auto'
}"
[nzStyle]="{ top: '35px' }"
(nzOnCancel)="handleCancel()">
  <ng-container *nzModalContent>
    <nz-card nzTitle="Reset Proposal">
      <div nz-row>
        <div nz-col nzSpan="24"  class="mb-3">
          <p>This will reset the proposal status to Released.</p>
        </div>
      </div>
      <div nz-row  class="mb-3">
        <div nz-col nzSpan="24">
          <label nz-checkbox [(ngModel)]="resetFormFields.saveApprovedProposalAsAPdf">
            Save approved proposal as a PDF
          </label>
        </div>
      </div>

      <!-- Conditionally show this row only if the checkbox is true -->
      <div nz-row class="mb-3" *ngIf="resetFormFields.saveApprovedProposalAsAPdf">
        <div nz-col nzSpan="24">
          <label class="sub-text">Title</label>
          <input
            nz-input
            name="title"
            [(ngModel)]="resetFormFields.pdfTitle"
            required
            [placeholder]="'Approved Proposal' + getFormattedDateTime()"
          />
        </div>
      </div>

      <div nz-row>
        <div nz-col nzSpan="24">
          <label nz-checkbox [(ngModel)]="resetFormFields.resetAllProposalsForThisLead">
            Reset all proposals for this lead
          </label>
        </div>
      </div>
    </nz-card>
  </ng-container>

  <ng-template #resetLeadproposalFooter>
    <nz-space>
      <button nz-button nzType="default" (click)="handleCancel()">Cancel</button>
      <button nz-button nzType="primary" (click)="resetProposal()">Reset</button>
    </nz-space>
  </ng-template>
</nz-modal>
