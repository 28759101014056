import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { SiteDiariesService } from 'src/Service/Project-Management/site-diaries.service';
import { LoadingIndicatorService } from 'src/shared/Shared-Services/loading-indicator.service';

@Component({
  selector: 'app-daily-logs',
  templateUrl: './daily-logs.component.html',
  styleUrls: ['./daily-logs.component.css']
})
export class DailyLogsComponent implements OnInit {

  @Output() cancel = new EventEmitter<void>();
  @Output() dailyLogOnCompleted = new EventEmitter();
  dailyLog: boolean = true;
  dailyLogForm: FormGroup;
  changeOrderFormFields: FormGroup;
  isSave = false;

  constructor(private fb: FormBuilder,
    public toastsService: NzMessageService,
    private SiteDiariesService: SiteDiariesService,
    private _loadingIndicatorService: LoadingIndicatorService
  ) {
    this.dailyLogForm = this.fb.group({

      isStampLocation: false,
      notes: [''],
      isIncludeWeatherConditionsDefault: false,
      isIncludeWeatherConditionNotesDefault: false,
      internalUsersNotify: [{ value: false, disabled: false }],
      internalUsersShare: [{ value: true, disabled: true }],
      subsVendorsShare: [false],
      subsVendorsNotify: [{ value: false, disabled: false }],
      ownersShare: [false],
      ownersNotify: [{ value: false, disabled: false }],
    });
  }

  ngOnInit() {
    // Subscribe to the share controls to enable/disable the notify controls
    this.dailyLogForm.get('internalUsersShare')?.valueChanges.subscribe(checked => this.toggleNotify('internalUsers', checked));
    this.dailyLogForm.get('subsVendorsShare')?.valueChanges.subscribe(checked => this.toggleNotify('subsVendors', checked));
    this.dailyLogForm.get('ownersShare')?.valueChanges.subscribe(checked => this.toggleNotify('owners', checked));
    this.fetchDailyLogData();
  }





  saveDailyLog() {
    this.isSave = true; // Set the flag to true to indicate the save operation is in progress
    const formValues = this.dailyLogForm.value;

    console.log('Original form values:', formValues);

    this.SiteDiariesService.postDailyLogData(formValues).subscribe(
      (response) => {


        // this.dailyLogOnCompleted.emit(response.result);
        this.cancel.emit();
        this.isSave = false;

      },
      (error) => {
        console.error('Error saving daily log:', error);
        this.toastsService.error('An error has occurred. Please try again.');
        this.isSave = false;
      }
    );
  }

  fetchDailyLogData(): void {
    this._loadingIndicatorService.show();
    this.SiteDiariesService.getDailyLogDataByCompany().subscribe(
      (res) => {
        console.log('API Response:', res.result);
        if (res.result) {
          const dailyLog = res.result;
          this.dailyLogForm.patchValue({
            isStampLocation: dailyLog.isStampLocation,
            notes: dailyLog.notes,
            isIncludeWeatherConditionsDefault: dailyLog.isIncludeWeatherConditionsDefault,
            isIncludeWeatherConditionNotesDefault: dailyLog.isIncludeWeatherConditionNotesDefault,

          });
          this._loadingIndicatorService.hide();


        } else {
          console.warn('The response does not contain an array with data.');
        }
      }
    );
  }





  onIsShareChange(section: string, checkbox: string) {
    const shareControl = this.dailyLogForm.get(`${section}Share`);
    const notifyControl = this.dailyLogForm.get(`${section}Notify`);
    if (checkbox === 'Share') {
      if (shareControl?.value) {
        notifyControl?.setValue(false);
      }
    }
    if (checkbox === 'Notify') {
      if (notifyControl?.value) {
        shareControl?.setValue(true);
      }
    }
    if (shareControl?.value && !notifyControl?.value) {
      notifyControl?.setValue(false);
    }

    if (notifyControl?.value && !shareControl?.value) {
      shareControl?.setValue(true);
    }
    if (!shareControl?.value && notifyControl?.value) {
      notifyControl?.setValue(false);
    }

    if (!notifyControl?.value && shareControl?.value) {
      notifyControl?.setValue(false);
    }

  }


  toggleNotify(section: string, isShareChecked: boolean) {
    const notifyControl = this.dailyLogForm.get(`${section}Notify`);
    if (isShareChecked) {
      notifyControl?.enable();
      notifyControl?.setValue(true);
    } else {
      notifyControl?.setValue(false);
    }
  }


  closeDailyLog(): void {
    this.cancel.emit();
  }


}



// const createDefaultDailyLogShareParameterRequests = [
//   {
//     userTypeId: 1, // Internal Users
//     isShare: formValues.internalUsersShare,
//     isNotify: formValues.internalUsersNotify
//   },
//   {
//     userTypeId: 2, // Subs/Vendors
//     isShare: formValues.subsVendorsShare,
//     isNotify: formValues.subsVendorsNotify
//   },
//   {
//     userTypeId: 3, // Owners
//     isShare: formValues.ownersShare,
//     isNotify: formValues.ownersNotify
//   }
// ];