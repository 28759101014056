import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, throwError } from 'rxjs';

import { environment } from 'src/environments/environment.prod';
import { CreateApplicationBulkUserRequest, CreateApplicationUserRequest, UpdateApplicationUserImageRequest } from 'src/Models/InternalUser/InternalUser';
import { ResponseModel, ResponseModelArray, TablePageResponse, TablePageResponseSingle } from 'src/Models/responseMessage.model';
import { ApplicationUserResponse } from 'src/Models/InternalUser/applicationUserResponse';
import { CreateLookupFilterRequest, FilterSearchParameter, LookupFilter, LookupNameSetupResponse } from 'src/app/shared/component/Models/LookupModels';
import { RFIInformationResponse } from 'src/Models/_RFIs/RFIs_Infor';

@Injectable({
  providedIn: 'root'
})
export class InternalUserService {
  private baseUrl: string = environment.apiUrl + 'ApplicationUser/';
  private Url_ApplicationUser: string = environment.apiUrl + 'ApplicationUser';
  private base_Url: string = environment.apiUrl + 'LookupFilter/';
  getUserData = new BehaviorSubject<ResponseModel<TablePageResponse<ApplicationUserResponse>>>(null);
  $getInternalUsers = this.getUserData.asObservable();

  getrfiUserData = new BehaviorSubject<ResponseModel<TablePageResponse<RFIInformationResponse>>>(null);
  $getrfISnalUsers = this.getrfiUserData.asObservable();



  selectedFilterId = new BehaviorSubject<number>(0);
  $selectedFilterId = this.selectedFilterId.asObservable();

  isUserAppliedFilter = new BehaviorSubject<boolean>(false);
  $isUserAppliedFilter = this.isUserAppliedFilter.asObservable();


  constructor(private http: HttpClient) { }

  getData(): Observable<ResponseModelArray<ApplicationUserResponse>> {
    return this.http.get<ResponseModelArray<ApplicationUserResponse>>(`${this.baseUrl}getAll`);
  }
  getDataEmailSide(): Observable<ResponseModel<ApplicationUserResponse>> {
    return this.http.get<ResponseModel<ApplicationUserResponse>>(`${this.baseUrl}getAll`);
  }
  postData(internalUserFormData: CreateApplicationUserRequest): Observable<ResponseModel<ApplicationUserResponse>> {
    const apiUrl = `${this.Url_ApplicationUser}`;
    return this.http.post<any>(apiUrl, internalUserFormData).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }
  postData1(lookupFilterFormData: LookupFilter): Observable<ResponseModel<any>> {
    const apiUrl = `${this.baseUrl}LookupFilter`;
    return this.http.post<any>(apiUrl, lookupFilterFormData).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }

  updateData(internalUserFormData: CreateApplicationUserRequest): Observable<ResponseModel<ApplicationUserResponse>> {
    const headers = { 'Content-Type': 'application/json' };
    const options = { headers: new HttpHeaders(headers) };
    const apiUrl = `${this.baseUrl}Update-UserByForm`;
    let response = this.http.put<any>(apiUrl, internalUserFormData, options);

    return response;
  }

  // updateInternalUserSignature(updateInternalUserImage: UpdateApplicationUserImageRequest): Observable<any> {
  //   const formData: FormData = new FormData();
  //   formData.append('GlobalId', updateInternalUserImage.GlobalId);
  //   if (updateInternalUserImage?.ImageFile && updateInternalUserImage?.ImageFile.length > 0) {
  //     // Append each file from the ImageFile array
  //     updateInternalUserImage.ImageFile.forEach((file, index) => {
  //       formData.append('ImageFile', file, file?.name);  // `file` is of type `File`, and we pass `file.name` for the file field
  //     });
  //   } else {
  //     console.error('No image file provided for upload');
  //   }

  //   const apiUrl = `${this.baseUrl}Update-UserImage`;
  //   return this.http.put<any>(apiUrl, formData);
  // }

  updateInternalUserSignature(updateInternalUserImage: UpdateApplicationUserImageRequest): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('GlobalId', updateInternalUserImage.GlobalId);
    if (updateInternalUserImage?.ImageFile && updateInternalUserImage?.ImageFile.length > 0) {
        // Append each file from the ImageFile array
        updateInternalUserImage.ImageFile.forEach((file, index) => {
            if (file instanceof File) {
                formData.append('ImageFile', file, file.name); // `file` is of type `File`, and we pass `file.name` for the file field
            }
        });
    } else {
        console.error('No image file provided for upload');
    }

    const apiUrl = `${this.baseUrl}Update-UserImage`;
    return this.http.put<any>(apiUrl, formData);
}

  

  deleteData(globalId: string): Observable<ResponseModel<ApplicationUserResponse>> {
    return this.http.delete<any>(`${this.baseUrl}?globalId=${globalId}`).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        return throwError(error);
      })
    );
  }


  getDataId(id: number): Observable<ResponseModel<ApplicationUserResponse>> {
    return this.http.get<ResponseModel<ApplicationUserResponse>>(`${this.baseUrl}getBy-Id?id=${id}`);
  }
  getDataGlobalId(globalId: string): Observable<ResponseModel<ApplicationUserResponse>> {
    return this.http.get<ResponseModel<ApplicationUserResponse>>(`${this.baseUrl}getBy-globalId=${globalId}`);
  }

  saveBulkInterUserData(internalUserFormData: CreateApplicationBulkUserRequest[]): Observable<any> {
    const apiUrl = `${this.baseUrl}Create-BulkInternalUserByForm`;
    const headers = { 'Content-Type': 'application/json' };
    const options = { headers: new HttpHeaders(headers) };

    return this.http.post<any>(apiUrl, internalUserFormData, options).pipe(
      catchError((error: any) => {
        console.error('Error:', error);
        console.error('Server Response:', error.error);
        return throwError(error);
      })
    );
  }

  getAllInternalUsers() {
    this.http.get<ResponseModel<TablePageResponse<ApplicationUserResponse>>>(`${this.baseUrl}getAll`)
      .subscribe(
        (data) => {
          this.getUserData.next(data);
        },
        (error) => {
          console.error('Error fetching internal users:', error);
        }
      );
  }

  getAllInternalUsersByFilter(filterSearchParameter: FilterSearchParameter) {
    const apiUrl = `${this.base_Url}GetAll-ByFilter`;
    return this.http.post<any>(apiUrl, filterSearchParameter).subscribe(
      (data) => {
        this.getUserData.next(data);
      },
      (error) => {
        console.error('Error fetching internal users:', error);
      }
    );
  }



  

  getAppliedFilterData(appliedFilter: CreateLookupFilterRequest[]) {
    const apiUrl = `${this.base_Url}GetAll-ByLookupFilter`;
    return this.http.post<any>(apiUrl, appliedFilter).subscribe(
      (data) => {
        this.getUserData.next(data);
      },
      (error) => {
        console.error('Error fetching internal users:', error);
      }
    );
  }

  

  setSelectedFilterId(filterId: number) {
    this.selectedFilterId.next(filterId);
  }

  userAppliedFilter(filter: boolean) {
    this.isUserAppliedFilter.next(filter);
  }








  private baseUrl1: string = environment.apiUrl;

  showCode(formNameId: number): Observable<ResponseModelArray<LookupNameSetupResponse>> {
    const url = `${this.baseUrl1}LookupNameSetup/getAll-byFormNameId?formNameId=${formNameId}`;
    return this.http.post<ResponseModelArray<LookupNameSetupResponse>>(url, { formNameId });
  }







  deleteSingal(globalId: string | number): Observable<ResponseModel<boolean>> {
    const apiUrl = `${this.baseUrl}delete-User-Image?globalId=${globalId}`;
    console.log("API URL:", apiUrl); 
    return this.http.delete<ResponseModel<boolean>>(apiUrl).pipe(
      catchError((error: any) => {
        console.error('Error deleting image:', error); 
        return throwError(error); 
      })
    );
  }



}
