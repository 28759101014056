<ng-container *ngIf="RFIsInfoAnswerRes !== undefined">
  <ng-container *ngIf="seaFilteredData !== undefined">
    <div class="card border-0 mt-3">
      <div class="card-head border-bottom">
        <div class="row">
          <h2 class="famly-inn ant-typography-crad-Header">Approved Answer</h2>
        </div>
      </div>
      <div class="" style="padding: 16px 16px 4px 16px">
        <div nz-row style="justify-content: space-between;">
          <div nz-col>
            <div style="display: flex; align-items: center; gap: 10px;">
                <div nz-col class="ant-avt">
                    <nz-avatar [nzText]="seaFilteredData?.CreatedByInitials"></nz-avatar>
                </div>
                <div nz-col>
                    <div class="text-overflow-auto BTUser isCurrentUser" style="font-size: 14px;">{{ seaFilteredData?.CreatedByName }}</div>
                </div>
                <span style="white-space: nowrap;">{{ seaFilteredData?.CreatedOnFormatted }}</span>
            </div>
          </div>   
          <div nz-col>
            <div nz-row  [nzGutter]="12">
                <div nz-col>
                  <div class="ant-col padding-bottom-sm" style="padding-left: 4px; padding-right: 4px;">
                    <span class="status-text badge success">Approved</span>
                    <span class="famly-inn"> by 
                      <span class="famly-inn ms-1 me-2">{{ seaFilteredData?.ApprovedByName }}</span>on
                      <span class="famly-inn ms-1 me-2">{{ seaFilteredData?.ApprovedOnFormatted }}</span>
                    </span>
                  </div>
                </div>
            </div>   
          </div>
        </div>
        <div nz-row style="padding-top: 12px; row-gap: 0px;">
          <div nz-col>
              <div class="CKEditorDisplay" style="word-break: break-word;"><div [innerHTML]="seaFilteredData?.Response"></div></div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="selectedFilesAttachment?.length > 0">
        <div nz-row style="padding-top: 16px; row-gap: 0px;">
            <span class="famly-inn ant-typography-heading-bold">RFI Attachments</span>
        </div>
        <div nz-row style="padding-top: 4px; row-gap: 0px;" >
            <div nz-col  nzSpan="24">
                <label class="sub-text">Attachments</label>
            </div>
        </div>
        <div nz-row style="padding-top: 16px; row-gap: 0px;">
            <div nz-col  nzSpan="24">
                <div class="card-body bt-file-viewer-card p-0 m-0">
                    <div class="carousel-container" #carouselContainer>
                      <div class="carousel-view" *ngFor="let File of selectedFilesAttachment">
                        <div class="bt-file-viewer">
                          <img class="img-1" *ngIf="getFileIcon(File)" [src]="getFileIcon(File)" />
                          <img class="img-2" *ngIf="!getFileIcon(File) && !File.type.includes('video')" [src]="File.thumbUrl" />
                          <!-- <ng-container *ngIf="File.type.includes('video')">
                            <div class="video-container">
                              <i nz-icon nzType="play-circle" nzTheme="outline" class="video-play-icon" (click)="playVideo(File)"></i>
                              <video #videoPlayer id="video-{{ File.id }}" [src]="File.thumbUrl" (click)="playVideo(File)"></video>
                            </div>
                          </ng-container> -->
                          <div nz-row class="action-row">
                            <div nz-col nzSpan="24" class="text-start">
                              <label class="BTFileUpload-SecondaryText ms-2">{{ File.name }}</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
      </ng-container>
    </div>  
  </ng-container>
    <div class="card border-0 mt-3" *ngFor="let data of RFIsInfoAnswerRes; let i = index">
        <div class="" style="padding: 16px 16px 4px 16px;">
            <div nz-row style="justify-content: space-between;">
              <div nz-col>
                <div style="display: flex; align-items: center; gap: 10px;">
                    <div nz-col class="ant-avt">
                        <nz-avatar [nzText]="data?.CreatedByInitials"></nz-avatar>
                    </div>
                    <div nz-col>
                        <div class="text-overflow-auto BTUser isCurrentUser" style="font-size: 14px;">{{ data?.CreatedByName }}</div>
                    </div>
                    <span style="white-space: nowrap;">{{ data?.CreatedOnFormatted }}</span>
                </div>
              </div>   
              <div nz-col>
                <div nz-row  [nzGutter]="12">
                    <div nz-col style="line-height: 26px;" *ngIf="!seaFIsApproved">
                        <span (click)="saveRFIsAndApprove('saveAndApprove', data?.GlobalId)" class="BT-Link famly-inn">Approve</span>
                    </div>
                    <div nz-col></div>
                    <div nz-col>
                        <a (click)="deleteChangeOrderConfirm(data?.GlobalId)" class="BT-Link fs-5" nz-icon nzType="delete" nzTheme="fill"></a>                        
                    </div>
                </div>   
              </div>
            </div>
            <div nz-row style="padding-top: 12px; row-gap: 0px;">
                <div nz-col>
                    <div class="CKEditorDisplay" style="word-break: break-word;"><div [innerHTML]="data?.Response"></div></div>
                </div>
            </div>
            <ng-container *ngIf="data.isApprovedMessageVisible === true">
              <div nz-row style="padding-top: 16px; row-gap: 0px;">
                  <span class="famly-inn ant-typography-heading-bold">Response Attachments</span>
              </div>
              <div nz-row style="padding-top: 4px; row-gap: 0px;">
                  <div nz-col  nzSpan="24">
                      <label class="sub-text">Attachments</label>
                  </div>
              </div>
              <div nz-row style="padding-top: 16px; row-gap: 0px;">
                <div nz-col  nzSpan="24">
                    <div class="card-body bt-file-viewer-card p-0 m-0">
                        <div class="carousel-container" #carouselContainer>
                          <div class="carousel-view" *ngFor="let File of data?.attachmentData">
                            <div class="bt-file-viewer">
                              <img class="img-1" *ngIf="getFileIcon(File)" [src]="getFileIcon(File)" />
                              <img class="img-2" *ngIf="!getFileIcon(File) && !File.type.includes('video')" [src]="File.thumbUrl" />
                              <!-- <ng-container *ngIf="File.type.includes('video')">
                                <div class="video-container">
                                  <i nz-icon nzType="play-circle" nzTheme="outline" class="video-play-icon" (click)="playVideo(File)"></i>
                                  <video #videoPlayer id="video-{{ File.id }}" [src]="File.thumbUrl" (click)="playVideo(File)"></video>
                                </div>
                              </ng-container> -->
                              <div nz-row class="action-row">
                                <div nz-col nzSpan="24" class="text-start">
                                  <label class="BTFileUpload-SecondaryText ms-2">{{ File.name }}</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
              </div>
            </ng-container>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="!test_You?.IsComplete">
  <div class="card border-0 mt-3">
    <div class="card-head border-bottom">
      <div class="row">
        <h2 class="famly-inn ant-typography-crad-Header">New Answer</h2>
      </div>
    </div>
    <div class="card-body">
      <div nz-row>
        <div nz-col nzSpan="24">
          <label class="sub-text">Reassign RFI to</label>
        </div>
    </div>
    <div nz-row class="Test">
        <div nz-col nzSpan="24">
          <nz-select
          [(ngModel)]="test"
          [nzCustomTemplate]="defaultTemplate"
          nzShowSearch
          [compareWith]="compareFn"
          (ngModelChange)="onAssigneeChange($event)">
          <nz-option nzValue="me" nzLabel="Me"></nz-option>
          <ng-container *ngFor="let group of selectedUsers">
            <nz-option-group [nzLabel]="group.groupName">
              <ng-container *ngFor="let user of group.users">
                <nz-option
                  [nzValue]="{ id: user.id, groupName: group.groupName }"
                  [nzLabel]="user.name"
                >
                  <span class="color-name">{{ user.name }}</span>
                </nz-option>
              </ng-container>
            </nz-option-group>
          </ng-container>
        </nz-select>
        <ng-template #defaultTemplate let-selected>
          <div class="color-item d-flex align-items-center Reassign-RFI-to">
            <nz-avatar 
              [nzText]="initials"
              >
            </nz-avatar>
            <span style="padding-left: 10px" class="famly-inn">{{ selected.nzLabel }}</span>
          </div>
        </ng-template>
        </div>
    </div>
    <form [formGroup]="RFIsAnswerFormValueForm">
      <div nz-row>
        <div nz-col nzSpan="24">
          <label class="sub-text">Response<span class="text-danger fw-bolder ms-1">*</span></label>
        </div>
      </div>
      <div nz-row>
        <div nz-col nzSpan="24">
          <ckeditor
          formControlName="response"
          name="response"
          [editor]="Editor"
          [config]="config"
          class="size"
          (ngModelChange)="onFieldChange()"
          [ngClass]="{
            'invalid-editor': RFIsAnswerFormValueForm.controls['response'].dirty &&  RFIsAnswerFormValueForm.hasError('required', 'response')
          }"
          id="messagesEditor">
        </ckeditor>
        </div>
      </div>
      <div nz-row>
        <div nz-col nzSpan="24">
          <small *ngIf="RFIsAnswerFormValueForm.controls['response'].dirty &&  RFIsAnswerFormValueForm.hasError('required', 'response')" class="text-danger">
            Required
          </small>
        </div>
      </div>
      <div nz-row  class="pb-2">
        <div nz-col nzSpan="24">
          <label class="sub-text">Attachments</label>
        </div>
      </div>
      <app-create-attachments-common 
        (filesUploaded)="onFilesUploaded($event)" 
        (deleteCompleted)="deleteFile()">
      </app-create-attachments-common>
      <div nz-row  class="p2-2">
        <div nz-col nzSpan="24" class="d-flex justify-content-end">
          <button
          [nzLoading]="isSaveYourAnswer"
          (click)="saveYourAnswer()"
          [disabled]="DisabledNextPostAnswer"
          [class.disabled]="DisabledNextPostAnswer"
          nz-button
          nzType="primary"
          class="align-items-center">
          Post Your Answer
        </button>
        </div>
      </div>
    </form>
    </div>
  </div>
</ng-container>